import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import VehicleCreateUpdate, { VehicleCreateUpdateProps } from "./VehicleCreateUpdate";

export interface OwnProps {
  createUpdateProps: VehicleCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function VehicleCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps.entityId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} vehicle
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <VehicleCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
