import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { PartyDto, UserPartiesMembershipDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

interface PartiesState {
  loading: {
    getUserPartiesMembership?: boolean;
  };
  userPartiesMembership?: UserPartiesMembershipDto;
}

const initialState: PartiesState = {
  loading: {},
  userPartiesMembership: undefined,
};

const slice = createSlice({
  name: "Parties",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<PartiesState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _userPartiesMembershipFetched: (state, action: PayloadAction<UserPartiesMembershipDto>) => {
      state.userPartiesMembership = action.payload;
    },
  },
});

export const { setLoading, _userPartiesMembershipFetched } = slice.actions;

export const partiesReducer = slice.reducer;

export const getUserPartiesMembership =
  (
    ...arg: Parameters<typeof apiClient.partiesApi.apiV1PartiesUserMembershipGet>
  ): AppThunk<Promise<UserPartiesMembershipDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getUserPartiesMembership: true,
      }),
    );
    try {
      const response = await apiClient.partiesApi.apiV1PartiesUserMembershipGet(...arg);
      await dispatch(_userPartiesMembershipFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getUserPartiesMembership: false,
        }),
      );
    }
  };
