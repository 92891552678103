import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AssessmentFlowPaginatedList from "@/common/components/Entity/AssessmentFlow/ListView/AssessmentFlowPaginatedList";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface AssessmentFlowsPageQueryParams extends GeneralQueryParams {
  vehicleId?: string;
  contractId?: string;
  customerId?: string;
}

export default function AssessmentFlowsPage() {
  const queryParams = useQueryParams<AssessmentFlowsPageQueryParams>();

  return (
    <>
      <AssessmentFlowPaginatedList defaultValues={{ ...queryParams }} />
    </>
  );
}
