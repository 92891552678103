import { DialogContent, Typography } from "@mui/material";
import { ReactElement, useState } from "react";

import { PartyDto, PartyReferenceDto } from "@/core/api/generated";

import FieldValue from "../../Form/Display/FieldValue";
import AppModal from "../../Modals/AppModal";
import AppModalTitle from "../../Modals/AppModalTitle";

interface Props {
  trigger: ((isMenuOpen: boolean) => ReactElement) | ReactElement;
  party?: PartyDto | PartyReferenceDto;
}

const PartyInfoModalWithTrigger = ({ party, trigger }: Props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const handleOpen = () => {
    setIsModalOpened(true);
  };
  const handleClose = () => {
    setIsModalOpened(false);
  };

  return (
    <AppModal trigger={trigger} onClose={handleClose} onOpen={handleOpen} open={isModalOpened}>
      <AppModalTitle title='Party info' withCloseIcon onCloseClicked={handleClose} />
      <DialogContent sx={{ width: "200px" }}>
        <FieldValue label={"Name"} sx={{ mb: 1 }}>
          <Typography component='span' fontSize='inherit' fontWeight='inherit'>
            {party?.name || "-"}
          </Typography>
        </FieldValue>

        <FieldValue label={"Type"} sx={{ mb: 1 }}>
          <Typography component='span' fontSize='inherit' fontWeight='inherit'>
            {party?.type}
          </Typography>
        </FieldValue>

        <FieldValue label={"Is system"} sx={{ mb: 1 }}>
          <Typography component='span' fontSize='inherit' fontWeight='inherit'>
            {party?.isSystem ? "true" : "false"}
          </Typography>
        </FieldValue>
      </DialogContent>
    </AppModal>
  );
};

export default PartyInfoModalWithTrigger;
