import { Stack, Typography } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { AppPermission, UserDto } from "@/core/api/generated";

import UserSuspensionInfoDisplay from "../../UserSuspensionInfoDisplay";

interface Props {
  user: UserDto;
}

export default function SuspensionInfoTabContent({ user }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Suspension info"} />}
      >
        <Stack direction='column' spacing={2}>
          <AuthorizedElement permissions={[AppPermission.TenantUserManage]}>
            <Stack spacing={1}>
              {!user.suspension && <NoDataAlert />}
              {user.suspension && <UserSuspensionInfoDisplay info={user.suspension} />}
            </Stack>
          </AuthorizedElement>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
