import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import RepairMaterialView from "@/common/components/Entity/RepairMaterial/View/RepairMaterialView";
import { apiClient } from "@/core/api/ApiClient";

function RepairMaterialViewPage() {
  const { repairMaterialId } = useParams<{ repairMaterialId?: string }>();

  return (
    <Stack direction='column'>
      <RepairMaterialView
        repairMaterialId={repairMaterialId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        getFunc={async (params) =>
          await apiClient.adminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdGet({
            repairMaterialId: params.repairMaterialId,
          })
        }
        deleteFunc={async (params) =>
          await apiClient.adminRepairMaterialsApi.apiV1AdminRepairMaterialsRepairMaterialIdDelete({
            repairMaterialId: params.repairMaterialId,
          })
        }
      />
    </Stack>
  );
}

export default RepairMaterialViewPage;
