import { FormControl, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { TenantDto } from "@/core/api/generated";

interface Props {
  tenantId?: string;
  selectProps?: SelectProps;
  onChange?: (newValue?: TenantDto) => void;
}

export default function TenantSelector({ tenantId, selectProps, onChange }: Props) {
  const tenantsRequest = useApiRequest(apiClient.profileApi.apiV1ProfileTenantsGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const tenants = tenantsRequest?.data;

  return (
    <FormControl sx={{ minWidth: "100px" }} fullWidth>
      <InputLabel>Company</InputLabel>
      <Select
        label='Company'
        {...selectProps}
        value={tenantId || ""}
        onChange={(e) => {
          const newTenantId = e.target.value;
          const tenant = tenants?.find((x) => x.id === newTenantId) || undefined;
          onChange && onChange(tenant);
        }}
      >
        {tenants?.map((tenant) => (
          <MenuItem key={tenant.id} value={tenant.id!}>
            {tenant.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
