import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  EntityType,
  WebhookActivationGetPaginatedDto,
  WebhookDto,
} from "@/core/api/generated";

interface Props {
  webhook: WebhookDto;
}

export default function WebhookActivationsTabContent({ webhook }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const { hasPermissions } = useAuthorizationInfo();
  const commonRequestParams = useCommonRequestParams<WebhookActivationGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.WebhookActivation,
    },
  });

  const paginatedActivationsRequest = useApiRequest(
    apiClient.webhooksApi.apiV1WebhooksActivationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookActivationGetPaginatedDto: {
        webhookId: webhook.id!,
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [webhook.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedActivations = paginatedActivationsRequest?.data;

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Webhook activations"} />}
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "webhook.name",
                title: "Webhook",
                flex: 1,
                renderCell: (item) => (
                  <AppTypography variant='body1'>{item.webhook?.name}</AppTypography>
                ),
              },
              {
                field: "eventType",
                title: "Event",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValue type='WebhookEventType' value={item.eventType} />
                ),
              },
              {
                field: "status",
                title: "Status",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValue type='WebhookActivationStatus' value={item.status} />
                ),
              },
              {
                field: "error",
                title: "Error",
                flex: 1,
                renderCell: (item) => (
                  <AppTypography variant='body1' {...{ color: item.error ? "error" : undefined }}>
                    {item.error || "-"}
                  </AppTypography>
                ),
              },
              {
                field: "createdAt",
                title: "Created at",
                flex: 1,
                renderCell: (item) => <Datetime datetime={item.createdAt} />,
              },
              {
                field: "updatedAt",
                title: "Updated at",
                flex: 1,
                renderCell: (item) => <Datetime datetime={item.updatedAt} />,
              },
            ]}
            isLoading={paginatedActivationsRequest.isLoading}
            rows={paginatedActivations?.items}
            getRowId={(item) => item.id!}
            rowTo={
              hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantRead])
                ? (item) => ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW(item.id)
                : undefined
            }
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
