import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, EntityType } from "@/core/api/generated";

import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: DamageDetectionDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function DamageDetectionsDeleteModal({ entities, ...otherProps }: Props) {
  const [isDeleteVehicleDamages, setIsDeleteVehicleDamages] = useState(false);

  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      bodyAfter={
        <FormControlLabel
          control={
            <Checkbox
              checked={isDeleteVehicleDamages}
              onChange={(e) => setIsDeleteVehicleDamages(e.target.checked)}
            />
          }
          label='Delete vehicle damages'
        />
      }
      entityType={EntityType.DamageDetection}
      entities={entities}
      deleteFunc={(params) => {
        apiClient.damageDetectionsApi.apiV1DamageDetectionsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          damageDetectionBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
            isDeleteVehicleDamages: isDeleteVehicleDamages,
          },
        });
      }}
      {...otherProps}
    />
  );
}
