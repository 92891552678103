import { Divider, Grid, Stack } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import DepartmentLink from "@/common/components/Entity/Department/DepartmentLink";
import LocationLink from "@/common/components/Entity/Location/LocationLink";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ProfileDto } from "@/core/api/generated";

interface Props {
  profile: ProfileDto;
}

export default function MyDepartmentsTabContent({ profile }: Props) {
  const userDepartmentsRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentGetPaginatedDto: {
        ids: profile?.departments?.map((x) => x.id!) || [],
      },
    },
    {
      deps: [profile?.departments],
      skip: !profile || profile.departments?.length === 0,
    },
  );

  const userLocationsRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsSearchPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationSearchPaginatedDto: {
        ids: profile?.locations?.map((x) => x.id!) || [],
      },
    },
    {
      deps: [profile?.locations],
      skip: !profile || profile.locations?.length === 0,
    },
  );

  const fullUserDepartments = useMemo(() => {
    if (!profile) {
      return [];
    }
    return (userDepartmentsRequest.data?.items || []).map((x) => ({
      id: x.id,
      name: x.name,
      locations: (userLocationsRequest.data?.items || []).filter((y) =>
        x.locationIds?.includes(y.id!),
      ),
    }));
  }, [
    userDepartmentsRequest.data,
    userLocationsRequest.data,
    profile?.locations,
    profile?.departments,
  ]);

  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"My Departments"} />

        {_.isEmpty(fullUserDepartments) && <NoDataAlert title='No departments' />}

        {fullUserDepartments.map((d, dIdx) => (
          <Stack key={dIdx} spacing={1}>
            <DepartmentLink entityId={d.id} />
            <Divider />
            <Grid container spacing={0.5} sx={{ pl: 2 }}>
              {d.locations?.map((l, lIdx) => (
                <Grid item key={lIdx} xxs={12} md={6}>
                  <LocationLink entityId={l.id} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </Stack>
    </PageTabContent>
  );
}
