import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairSparePart from "@/common/components/Entity/RepairSparePart/CreateUpdateRepairSparePart";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function CreateUpdateRepairSparePartPage() {
  const { repairSparePartId } = useParams<{ repairSparePartId?: string }>();
  const isCreate = !repairSparePartId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new repair spare part" : "Edit repair spare part"}
        />
      }
    >
      <CreateUpdateRepairSparePart
        repairSparePartId={repairSparePartId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.REPAIR_SPARE_PART_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
