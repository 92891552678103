import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { useEffect, useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import AppAvatar, { AppAvatarProps } from "@/common/components/Avatar/AppAvatar";
import AppLink from "@/common/components/Link/AppLink";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserDto, GeneralInspectorDto, UserDto } from "@/core/api/generated";

import UserPopover from "./UserPopover";

export interface InlineUserProps {
  /** User info is loaded if only id is specified. */
  userId?: string | null;
  user?: UserDto;
  inspector?: GeneralInspectorDto | null;
  withAvatar?: boolean;
  withText?: boolean;
  withLink?: boolean;
  withLoading?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: string;
  withPoper?: boolean;
  avatarProps?: Partial<AppAvatarProps>;
  renderBefore?: (userInfoFound: boolean) => React.ReactNode;
  sx?: SxProps<Theme>;
}

/** Inline brief information about the user. */
export default function InlineUser({
  userId,
  user,
  inspector,
  withAvatar = false,
  withText = true,
  withLink = false,
  withLoading = true,
  withTooltip = false,
  tooltipTitle = "User",
  withPoper = false,
  avatarProps = {},
  renderBefore,
  sx,
}: InlineUserProps) {
  const { hasFleetAppAccess, hasAdminAppAccess } = useAuthorizationInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [userById, setUserById] = useState<UserDto | AdminUserDto | undefined>(undefined);

  const [poperOpenAnchorEl, setPoperOpenAnchorEl] = useState<HTMLElement | null>(null);
  const isPoperOpen = Boolean(poperOpenAnchorEl);

  const userComputed = user || userById;
  const text =
    userById?.personName?.name ||
    userById?.email ||
    user?.personName?.name ||
    user?.email ||
    inspector?.personName?.name ||
    inspector?.email;
  const userInfoFound = !!text && text.length > 0;

  const avatarSize = 20;

  useEffect(() => {
    (async () => {
      if (userId && !userById) {
        setIsLoading(true);
        try {
          if (hasAdminAppAccess) {
            const response = await apiClient.adminUsersApi.apiV1AdminUsersUserIdGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              userId,
            });
            setUserById(response.data);
          } else if (hasFleetAppAccess) {
            const response = await apiClient.usersApi.apiV1UsersUserIdGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              userId,
            });
            setUserById(response.data);
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [userId, userById, hasFleetAppAccess, hasAdminAppAccess]);

  if (isLoading) {
    return <CircularProgress size={avatarSize} />;
  }
  if (!userInfoFound) {
    return null;
  }

  return (
    <AppTooltip
      enabled={withTooltip}
      title={tooltipTitle}
      wrapperProps={{ component: "span" }}
      sx={{ ...sx }}
    >
      <Box
        component='span'
        sx={{ overflow: "hidden", display: "inline-flex", alignItems: "center" }}
        onMouseEnter={(e) => withPoper && setPoperOpenAnchorEl(e.currentTarget)}
        onMouseLeave={() => withPoper && setPoperOpenAnchorEl(null)}
      >
        {renderBefore && renderBefore(userInfoFound)}
        {userInfoFound && (
          <AppLink
            enabled={withLink}
            to={ROUTE_PATH.USER_VIEW(userById?.id || user?.id || inspector?.userId)}
            underline='none'
            sx={{ display: "inline-flex", alignItems: "center", width: "auto" }}
          >
            {withAvatar && (
              <AppAvatar
                size={avatarSize}
                user={userById || user}
                inspector={inspector}
                isLoading={isLoading}
                withPopover={false}
                sx={{ mr: 0.5 }}
                {...avatarProps}
              />
            )}

            {withText && (
              <AppTypography ellipsing={{ enabled: true }} component='span'>
                {text}
              </AppTypography>
            )}
          </AppLink>
        )}

        {withPoper && userComputed && (
          <UserPopover
            user={userComputed}
            open={isPoperOpen}
            anchorEl={poperOpenAnchorEl}
            onClose={() => setPoperOpenAnchorEl(null)}
          />
        )}
      </Box>
    </AppTooltip>
  );
}
