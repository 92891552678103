import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto, EntityType } from "@/core/api/generated";

import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: AssetSubscriptionDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function AssetSubscriptionsDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.AssetSubscription}
      entities={entities}
      deleteFunc={(params) =>
        apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
          },
        })
      }
      {...otherProps}
    />
  );
}
