import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import GeneralHistoryCreateUpdate, {
  GeneralHistoryCreateUpdateProps,
} from "./GeneralHistoryCreateUpdate";

export interface OwnProps {
  createUpdateProps: GeneralHistoryCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function GeneralHistoryCreateUpdateModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.generalHistoryId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} history record
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <GeneralHistoryCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
