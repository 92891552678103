import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterValueType } from "@/core/api/generated";

import BooleanSelect from "../../Form/Input/BooleanSelect";

interface Props extends FilterValueInputProps {}

export default function FilterBooleanValueInput({ item, selectProps, onChange }: Props) {
  if (item.valueType !== FilterValueType.Boolean) {
    console.error(`Invalid value type '${item.valueType}'.`);
    return null;
  }

  return (
    <BooleanSelect
      label='Value'
      {...selectProps}
      value={item.valueAsBoolean()}
      onChange={(newValue) => onChange(newValue)}
    />
  );
}
