import { multitenancyConfig } from "@/config/config";

import { APP_TENANT_IDENTIFIERS, RESERVED_TENANT_IDENTIFIERS } from "../constants/multitenancy";
import { UrlHelper } from "../helpers/url";

export interface ITenantInfo {
  identifier: string;
}

export type TenantResolveStrategy = "host" | "basepath";

export class TenantService {
  private _defaultTenantIdentifiers: Record<TenantResolveStrategy, string> = {
    host: APP_TENANT_IDENTIFIERS.APP,
    basepath: APP_TENANT_IDENTIFIERS.APP_BASE_PATH,
  };

  public get strategy(): TenantResolveStrategy {
    return multitenancyConfig.tenantResolveStrategy;
  }

  public get defaultTenantIdentifier(): string {
    return this._defaultTenantIdentifiers[this.strategy];
  }

  public isReservedTenantIdentifier(identifier?: string): boolean {
    return !!identifier && RESERVED_TENANT_IDENTIFIERS.includes(identifier);
  }

  public resolveTenant(
    strategy?: TenantResolveStrategy,
    includeReserved: boolean = false,
  ): ITenantInfo | null {
    strategy = strategy || this.strategy;

    const tenantInfo =
      strategy === "host" ? this.resolveTenantFromHost() : this.resolveTenantFromBasePath();

    const isReserved = tenantInfo && RESERVED_TENANT_IDENTIFIERS.includes(tenantInfo.identifier);
    if (!includeReserved && isReserved) {
      return null;
    }

    return tenantInfo;
  }

  /** Resolves tenant identifier from page host
   *  {SubDomain=tenantIdentifier}.{RootDomain}.{TopLevelDomain}
   *  e.g.: app.qunatafleet.com, mytenant.qunatafleet.com, app.localhost:3000, mytenant.localhost:3000
   */
  public resolveTenantFromHost(): ITenantInfo | null {
    const subdomain = UrlHelper.getSubdomainFromUrl(window.location.href);
    if (!subdomain) {
      return null;
    }
    return {
      identifier: subdomain,
    };
  }

  /** Resolves tenant identifier from base path segment
   *  https://mydomain.com/{TenantIdentifier}/something/abc/ccc
   *  e.g.: app.quantafleet.com/mytenant/app, localhost:3000/mytenant/app
   */
  public resolveTenantFromBasePath(): ITenantInfo | null {
    if (this.strategy !== "basepath") {
      return null;
    }

    const basePath = UrlHelper.getUrlBasePath(window.location.href);
    if (basePath === "/") {
      return null;
    }

    return {
      identifier: basePath.replace("/", ""), // remove leading slash
    };
  }

  /** On app init redirect to correct tenant URL */
  public initRedirectToTenantUrl() {
    switch (this.strategy) {
      case "host":
        // ensure on localhost, domain name is similar to production style domain: {SubDomain}.{RootDomain}.{TopLevelDomain}
        if (window.location.hostname === "localhost") {
          window.location = `http://quantafleet.localhost:${UrlHelper.getUrlPort(
            window.location.href,
          )}` as unknown as Location;
        }
        // ensure app is accessed at {tenantIdentifier}.{host} or app.{host}
        else if (!UrlHelper.getSubdomainFromUrl(window.location.href)) {
          this.redirectToDefaultTenantUrl();
        }
        break;
      case "basepath":
        if (UrlHelper.getUrlBasePath(window.location.href) === "/") {
          this.redirectToDefaultTenantUrl();
        }
        break;
      default:
        throw new Error("Tenant strategy not set or invalid.");
    }
  }

  public getTenantUrl(
    tenantIdentifier?: string | null,
    preservePathname: boolean = true,
    preserveQueryString: boolean = true,
  ) {
    tenantIdentifier = tenantIdentifier || this._defaultTenantIdentifiers[this.strategy];
    let url: string;

    switch (this.strategy) {
      case "host":
        url = UrlHelper.updateUrlSubdomain(window.location.href, tenantIdentifier);
        if (!preservePathname) {
          url = UrlHelper.updateUrlPathname(url, "/");
        }
        if (!preserveQueryString) {
          url = UrlHelper.deleteUrlSearchParams(url);
        }
        return url;
      case "basepath":
        url = UrlHelper.updateUrlBasePath(window.location.href, "/" + tenantIdentifier);
        if (!preservePathname) {
          url = UrlHelper.updateUrlPathname(url, "/" + tenantIdentifier);
        }
        if (!preserveQueryString) {
          url = UrlHelper.deleteUrlSearchParams(url);
        }
        return url;
      default:
        throw new Error("Tenant strategy not set or invalid.");
    }
  }

  public getDefaultTenantUrl(preservePathname: boolean = true) {
    return this.getTenantUrl(undefined, preservePathname);
  }

  public redirectToTenantUrl(tenantIdentifier?: string) {
    tenantIdentifier = tenantIdentifier || this._defaultTenantIdentifiers[this.strategy];
    const tenantUrl = this.getTenantUrl(tenantIdentifier);
    console.log(`Redirecting to tenant URL (identifier: ${tenantIdentifier}): ${tenantUrl}`);
    window.location = tenantUrl as unknown as Location;
  }

  public redirectToTenantRootUrl() {
    const tenantInfo = this.resolveTenant();
    const tenantUrl = this.getTenantUrl(tenantInfo?.identifier);
    console.log(
      `Redirecting to tenant root URL (identifier: ${tenantInfo?.identifier}): ${tenantUrl}`,
    );
    window.location = tenantUrl as unknown as Location;
  }

  public redirectToDefaultTenantUrl() {
    this.redirectToTenantUrl();
  }

  public formatNameAsTenantIdentifier(name?: string) {
    return name?.toLowerCase().replaceAll(/\.|\*|&|#|@|\^|!| /g, "-") || "";
  }
}

export const tenantService = new TenantService();
