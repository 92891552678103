import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import Datetime from "@/common/components/Datetime/Datetime";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import FileListView from "@/common/components/Files/FileListView";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { DamagePointsViewer } from "@/common/components/Images/DamagePointsViewer";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDamageDto } from "@/core/api/generated";

import ContractLink from "../../../Contract/ContractLink";
import DamageDetectionLink from "../../../DamageDetection/DamageDetectionLink";
import GeneralInspectorDisplay from "../../../General/Display/GeneralInspectorDisplay";
import VehicleVisualModelLink from "../../../VehicleVisualModel/VehicleVisualModelLink";
import { VehicleDamageStatus } from "../../VehicleDamageStatus";
import { VehicleDamageViewProps } from "../VehicleDamageView";

interface Props {
  vehicleDamage: VehicleDamageDto;
  displayProps: VehicleDamageViewProps["displayProps"];
}

export default function OverviewTabContent({ vehicleDamage, displayProps }: Props) {
  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleDamage?.visualModel?.id || "",
    },
    {
      deps: [vehicleDamage?.visualModel?.id],
      skip: !vehicleDamage?.visualModel?.id,
    },
  );
  const visualModel = visualModelRequest.data;

  const oldVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicleDamage?.oldVisualModel?.id || "",
    },
    {
      deps: [vehicleDamage?.oldVisualModel?.id],
      skip: !vehicleDamage?.oldVisualModel?.id,
    },
  );
  const oldVisualModel = oldVisualModelRequest.data;

  const visualModelImageUrl = useMemo(
    () =>
      visualModel?.images?.find((model) => model.projection === vehicleDamage?.projection)?.file
        ?.url,
    [vehicleDamage, visualModel],
  );
  const oldVisualModelImageUrl = useMemo(
    () =>
      oldVisualModel?.images?.find((model) => model.projection === vehicleDamage?.projection)?.file
        ?.url,
    [vehicleDamage, oldVisualModel],
  );

  return (
    <PageTabContent>
      <Stack direction='column' spacing={1}>
        <PageTabHeader
          title={"Details"}
          subtitle2={<EntityCreatedByInfoDisplay entity={vehicleDamage} />}
        />

        <Stack direction='column' spacing={1}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xxs: "1fr",
                lg: "3fr 2fr",
              },
              columnGap: 2,
              rowGap: 2,
            }}
          >
            <Stack spacing={1}>
              {vehicleDamage?.localNumber && (
                <FieldValue label='Local number'>{vehicleDamage?.localNumber}</FieldValue>
              )}
              <FieldValue label='Part type'>{vehicleDamage?.partType?.name}</FieldValue>
              <FieldValue label='Damage type'>{vehicleDamage?.damageType?.name}</FieldValue>
              <FieldValue label='Projection'>{vehicleDamage?.projection}</FieldValue>
              <FieldValue label='Area'>{vehicleDamage?.area}</FieldValue>
              <FieldValue label='State' isEmpty={!vehicleDamage?.state}>
                <VehicleDamageStatus vehicleDamage={vehicleDamage} />
              </FieldValue>
              {vehicleDamage?.inspectedAt && (
                <FieldValue label='Inspection date'>
                  <Datetime datetime={vehicleDamage?.inspectedAt} withDurationFromNow />
                </FieldValue>
              )}
              {displayProps?.inspector && vehicleDamage?.inspector && (
                <FieldValue label='Inspector'>
                  <GeneralInspectorDisplay
                    inspector={vehicleDamage?.inspector}
                    inlineUserProps={{
                      withAvatar: true,
                      withLink: true,
                    }}
                  />
                </FieldValue>
              )}

              {displayProps?.damageDetection && (
                <FieldValue label='Damage Detection' isEmpty={!vehicleDamage?.damageDetection}>
                  <DamageDetectionLink entity={vehicleDamage?.damageDetection} />
                </FieldValue>
              )}
              {displayProps?.contract && (
                <FieldValue label='Contract' isEmpty={!vehicleDamage?.contract}>
                  <ContractLink entity={vehicleDamage?.contract} />
                </FieldValue>
              )}
              {vehicleDamage?.notes && (
                <FieldValue label='Notes'>{vehicleDamage?.notes}</FieldValue>
              )}
              {!_.isEmpty(vehicleDamage?.attachments) && (
                <Box sx={{ mt: 2 }}>
                  <FileListView
                    files={FileItem.createManyFrom(vehicleDamage?.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={100}
                    itemHeight={100}
                  />
                </Box>
              )}
            </Stack>

            <Box>
              {/* Visual model & damage */}
              {displayProps?.visualModel && (
                <Stack spacing={1}>
                  <Typography variant='h2'>Visual model</Typography>

                  <Box>
                    {visualModelRequest.isLoading && <LinearProgress />}

                    {visualModel && (
                      <Box>
                        <VehicleVisualModelLink entity={visualModel} withIcon withTooltip />
                      </Box>
                    )}

                    {visualModel && visualModelImageUrl && (
                      <DamagePointsViewer
                        imageUrl={visualModelImageUrl}
                        containerSx={{
                          height: `400px`,
                          width: `400px`,
                        }}
                        pointsInfos={[vehicleDamage]}
                        onPointClicked={() => {
                          return;
                        }}
                      />
                    )}
                  </Box>

                  {oldVisualModel && (
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        label: <Typography variant='subtitle1'>View old visual model</Typography>,
                      }}
                    >
                      <Box>
                        {oldVisualModelRequest.isLoading && <LinearProgress />}
                        {oldVisualModel && oldVisualModelImageUrl && (
                          <DamagePointsViewer
                            imageUrl={oldVisualModelImageUrl}
                            containerSx={{
                              height: `400px`,
                              width: `400px`,
                            }}
                            pointsInfos={[{ ...vehicleDamage, point: vehicleDamage?.oldPoint }]}
                            onPointClicked={() => {
                              return;
                            }}
                          />
                        )}
                      </Box>
                    </FoldableBlock>
                  )}
                </Stack>
              )}
            </Box>
          </Box>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}
