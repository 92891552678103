import { useParams } from "react-router-dom";

import TeslaConnectionView from "@/common/components/Entity/TeslaConnection/View/TeslaConnectionView";

export default function TeslaConnectionViewPage() {
  const { teslaConnectionId } = useParams<{ teslaConnectionId?: string }>();

  return (
    <>
      <TeslaConnectionView
        teslaConnectionId={teslaConnectionId}
        teslaConnection={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
