import { SxProps, Theme } from "@mui/material";

import { GeneralStrictEntityRelationDto } from "@/core/api/generated";

import { BaseEntityInlineInheritableProps } from "../../components/BaseEntityInline";
import EntityInline from "../../components/EntityInline";

interface Props {
  value: GeneralStrictEntityRelationDto | null | undefined;
  inlineProps?: Partial<BaseEntityInlineInheritableProps>;
  sx?: SxProps<Theme>;
}

export default function GeneralStrictEntityRelationInline({ value, inlineProps, sx }: Props) {
  if (!value) {
    return null;
  }

  return (
    <EntityInline
      entityType={value.entityType}
      entityId={value.entityId}
      entity={
        value.vehicle ??
        value.asset ??
        value.vehicleDamage ??
        value.contract ??
        value.accessory ??
        value.accessoryCheck ??
        value.visualInspection ??
        value.damageDetection ??
        value.damageDetectionAggregate ??
        value.damageCostEvaluation ??
        value.damageCostEvaluationAggregate ??
        value.repairOperation ??
        value.wheelOperation ??
        value.assetSubscriptionPlan ??
        value.assetSubscription ??
        value.tenantConnectionRequest ??
        value.tenantConnection ??
        value.tenantRequest ??
        value.customer ??
        value.supplier ??
        value.assessmentFlow
      }
      inlineProps={inlineProps}
      sx={sx}
    />
  );
}
