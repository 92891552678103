import { apiClient } from "@/core/api/ApiClient";
import { VehicleVisualModelType } from "@/core/api/generated";

import FileUploader, { FileUploaderProps } from "./FileUploader";

interface Props
  extends Pick<
    FileUploaderProps,
    "defaultFiles" | "disabled" | "onChange" | "onUploadStarted" | "onUploadFinished"
  > {
  modelType?: VehicleVisualModelType;
}

/** File upload used in vehicle visual model only. */
function VehicleVisualModelImageUploader({
  defaultFiles = [],
  disabled,
  onChange,
  modelType,
  onUploadStarted,
  onUploadFinished,
}: Props) {
  return (
    <FileUploader
      multiple={false}
      maxFiles={1}
      accept='image/svg+xml'
      defaultFiles={defaultFiles}
      hideUploadAreaIfCantUploadMore
      disabled={!modelType || modelType === VehicleVisualModelType.None || disabled}
      onChange={onChange}
      uploadFilesFunc={async (requestParameters) => {
        const response = await apiClient.filesApi.apiV1FilesVehicleVisualModelTypeImagesUploadPost({
          ...requestParameters,
          type: modelType!,
        });
        if (response.request.status !== 200) {
          throw response;
        }
        return response.data;
      }}
      onUploadStarted={onUploadStarted}
      onUploadFinished={onUploadFinished}
    />
  );
}

export default VehicleVisualModelImageUploader;
