import { Breakpoint, useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";

import { ResponsiveStyleValueCustom } from "./types";

/**
 * Resolves current value of a "ResponsiveStyleValueCustom" type, according to the current breakpoint(current screen size).
 * Example1: value="value1" -> "value1"
 * Example2: value="value2" -> "value2"
 * Example3: value={"xxs": "value1", "desktop": "value2"} AND  xxs < screen size < desktop -> "value1"
 * Example4: value={"xxs": "value1", "desktop": "value2"} AND screen size > desktop -> "value2"
 */
export function useResponsiveValueResolver<T>(
  value: ResponsiveStyleValueCustom<T>,
  fallbackValue: T,
): T {
  type ReduceType = {
    breakpoint: Breakpoint;
    value: T;
  };

  const theme = useTheme();
  let selectedVariant: T = fallbackValue;
  if (typeof value === "string") {
    selectedVariant = value;
  } else if (_.isObject(value)) {
    selectedVariant = Object.entries(value).reduce<ReduceType>(
      (prevVariant, [entryKey, entryValue]) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matched = useMediaQuery(theme.breakpoints.up(entryKey as Breakpoint));
        return matched
          ? theme.breakpoints.values[entryKey as Breakpoint] >
            theme.breakpoints.values[prevVariant.breakpoint]
            ? {
                breakpoint: entryKey as Breakpoint,
                value: entryValue as T,
              }
            : prevVariant
          : prevVariant;
      },
      { breakpoint: "xxs", value: fallbackValue },
    ).value;
  }

  return selectedVariant;
}
