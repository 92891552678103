import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import RepairMaterialMenu from "@/common/components/Entity/RepairMaterial/RepairMaterialMenu";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  RepairMaterialDto,
  RepairMaterialGetPaginatedDto,
} from "@/core/api/generated";

import InlineUser from "../../components/User/InlineUser";
import ImportFromRepairCatalogModal from "../RepairCatalog/components/ImportFromRepairCatalogModal";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
  import: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function RepairMaterialsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<RepairMaterialGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.RepairMaterial,
    },
  });

  const [isImportFromRepairCatalogModalOpen, setIsImportFromRepairCatalogModalOpen] =
    useState(false);

  const paginatedRepairMaterialsRequest = useApiRequest(
    apiClient.repairMaterialsApi.apiV1RepairMaterialsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairMaterialGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedRepairMaterials = paginatedRepairMaterialsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairMaterial)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRepairMaterialsRequest.handleEntityChanged(data);
    },
  });
  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Materials'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.REPAIR_MATERIAL_CREATE}
                >
                  Create new
                </Button>
              </AuthorizedElement>
            )
          }
          /* secondaryActions={
              displayProps?.import && (
                <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='import' />}
                    onClick={() => setIsImportFromRepairCatalogModalOpen(true)}
                  >
                    Import
                  </Button>
                </AuthorizedElement>
              )
            } */
        />
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<RepairMaterialDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<RepairMaterialDto>().name.$path,
            title: "Name",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().description.$path,
            title: "Description",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.description || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().brandName.$path,
            title: "Brand name",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.brandName || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().isDividable.$path,
            title: "Dividable?",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isDividable} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().unit.$path,
            title: "Unit",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <InlineApiEnumValue type='MeasurementUnit' value={item.unit} />,
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.MeasurementUnit,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().currency.code.$path,
            title: "Currency",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.CurrencyCode,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().price.$path,
            title: "Price",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <CurrencyValue value={item.price} currency={item.currency} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().subTotal.$path,
            title: "Sub total",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <CurrencyValue value={item.subTotal} currency={item.currency} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().discount.$path,
            title: "Discount",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <GeneralDiscountDisplay
                discount={item.discount}
                currency={item.currency}
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: getTypedPath<RepairMaterialDto>().subTotalIncDiscount.$path,
            title: "Sub total (inc. discount)",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().tax.$path,
            title: "Tax",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
          },
          {
            field: getTypedPath<RepairMaterialDto>().total.$path,
            title: "Total",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <AppPopover
                hoverBehavior={{}}
                trigger={
                  <AppTypography decoration={{ variant: "helpText" }}>
                    <CurrencyValue value={item.total} currency={item.currency} />
                  </AppTypography>
                }
              >
                <AppPopoverContent>
                  <GeneralPriceSummaryDisplay
                    direction='column'
                    summary={{
                      currency: item.currency,
                      subTotal: item.subTotal,
                      discount: item.discount || undefined,
                      tax: item.tax || undefined,
                      total: item.total || 0,
                    }}
                  />
                </AppPopoverContent>
              </AppPopover>
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<RepairMaterialDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
        ]}
        rows={paginatedRepairMaterials?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.REPAIR_MATERIAL_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <RepairMaterialMenu
            entity={item}
            onDelete={() => paginatedRepairMaterialsRequest.refetch()}
            onUpdate={() => paginatedRepairMaterialsRequest.refetch()}
          />
        )}
        isLoading={paginatedRepairMaterialsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />

      <ImportFromRepairCatalogModal
        open={isImportFromRepairCatalogModalOpen}
        onClose={() => setIsImportFromRepairCatalogModalOpen(false)}
        onImportEnd={() => paginatedRepairMaterialsRequest.refetch()}
      />
    </ViewLayoutV2>
  );
}
