import { Alert, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

export interface NoPermissionsAlertProps {
  sx?: SxProps<Theme>;
}

export default function NoPermissionsAlert({ sx }: NoPermissionsAlertProps): ReactNode {
  return (
    <Alert
      sx={sx}
      severity='error'
    >{`You don't have enough permissions to access the resource!`}</Alert>
  );
}
