import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, RepairSpecDto, RepairSpecReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import RepairSpecInline, { RepairSpecInlineProps } from "./RepairSpecInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: RepairSpecDto | RepairSpecReferenceDto;
  entityId?: string | null;
  inlineProps?: Partial<RepairSpecInlineProps>;
}

export default function RepairSpecLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])}
      to={ROUTE_PATH.REPAIR_SPEC_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={<RepairSpecInline entity={entity} entityId={entityId} {...inlineProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair spec"}
    />
  );
}
