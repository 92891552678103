import {
  CreateRepairSpecDto,
  RepairMaterialDto,
  RepairSpecDto,
  RepairSpecSparePartDto,
  RepairWorkDto,
  UpdateRepairSpecDto,
} from "@/core/api/generated";

export class RepairSpecHelper {
  /** Checks if there is any currency mismatch between RepairSpec and RepairMaterial/RepairWork. */
  public static isCurrencyMismatch({
    repairSpec,
    repairMaterial,
    repairWork,
    repairSparePart,
  }: {
    repairSpec: RepairSpecDto | CreateRepairSpecDto | UpdateRepairSpecDto | null | undefined;
    repairMaterial?: RepairMaterialDto | null;
    repairWork?: RepairWorkDto | null;
    repairSparePart?: RepairSpecSparePartDto | null;
  }): boolean {
    const currency1 = repairSpec?.currency;
    const currency2 = repairMaterial?.currency || repairWork?.currency || repairSparePart?.currency;

    return (currency1 && currency2 && currency1.code !== currency2.code) ?? false;
  }
}
