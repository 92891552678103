import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import SpreadsheetDataView from "./SpreadsheetDataView";

interface Props extends DialogProps {
  importId: string;
}

export default function SpreadsheetDataViewModal({ importId, ...dialogProps }: Props) {
  return (
    <Dialog maxWidth='desktop' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Spreadsheet
      </AppModalTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Stack sx={{ py: 1 }} spacing={1}>
          <SpreadsheetDataView importId={importId} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
