import { Box } from "@mui/material";

import { EnumDto } from "@/core/api/generated";

import InlineCode from "../Code/InlineCode";
import DataTabular, { TabularProps } from "../DataTabular/DataTabular";
import AppTypography from "../Text/AppTypography";

const defaultDisplayProps = {
  valueColumn: true,
  nameColumn: true,
  descriptionColumn: true,
  groupNameColumn: true,
  shortNameColumn: true,
};

interface OwnProps {
  values: EnumDto[];
  displayProps?: Partial<typeof defaultDisplayProps>;
  dataListProps?: Partial<TabularProps<EnumDto>>;
}

type Props = OwnProps;

export default function ApiEnumDtoDataList({
  values,
  displayProps = defaultDisplayProps,
  dataListProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  return (
    <Box>
      <DataTabular
        columns={[
          {
            field: "value",
            title: "Value",
            flex: 1,
            if: !!displayProps?.valueColumn,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div'>
                <InlineCode>{item.value}</InlineCode>
              </AppTypography>
            ),
          },
          {
            field: "groupName",
            title: "Group name",
            flex: 1,
            if: !!displayProps?.groupNameColumn,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div'>
                {item.groupName}
              </AppTypography>
            ),
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            if: !!displayProps?.nameColumn,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div'>
                {item.name}
              </AppTypography>
            ),
          },
          {
            field: "description",
            title: "Description",
            flex: 1,
            if: !!displayProps?.descriptionColumn,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                {item.description}
              </AppTypography>
            ),
          },
          {
            field: "shortName",
            title: "Short name",
            flex: 1,
            if: !!displayProps?.shortNameColumn,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }} component='div'>
                {item.shortName}
              </AppTypography>
            ),
          },
        ]}
        rows={values}
        getRowId={(item) => item.value!}
        pagination={{
          initialPaginationInfo: undefined,
          currentPaginationInfo: { offset: 0, limit: values.length, totalCount: values.length },
          onChange: () => {},
        }}
        {...dataListProps}
      />
    </Box>
  );
}
