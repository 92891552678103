import { Stack, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import GoBackButton from "@/common/components/Button/GoBackButton";
import CreateUpdateRepairWork from "@/common/components/Entity/RepairWork/CreateUpdateRepairWork";
import { useAppDispatch } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";

export default function CreateUpdateRepairWorkPage() {
  const { repairWorkId } = useParams<{ repairWorkId?: string }>();
  const isCreate = !repairWorkId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new repair work" : "Edit repair work"} />
      }
    >
      <Stack spacing={2}>
        <CreateUpdateRepairWork
          repairWorkId={repairWorkId}
          onSave={(newValue) => {
            history.goBack();
          }}
          createFunc={async (params) => {
            return await apiClient.adminRepairWorkApi.apiV1AdminRepairWorkPost({
              createRepairWorkDto: params.dto,
            });
          }}
          updateFunc={async (params) => {
            return await apiClient.adminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdPut({
              repairWorkId: params.repairWorkId,
              updateRepairWorkDto: params.dto,
            });
          }}
        />
      </Stack>
    </CreateUpdatePageLayout>
  );
}
