import { useEffect, useRef } from "react";

import useMounted from "../hooks/mount/useMounted";

interface Props {
  onMounted?: () => void;
  children?: React.ReactNode;
}

/** Facade for custom or lib implementation.
 *  Sensor component that notifies when it is mounted/unmounted.
 */
export default function AppMountedSensor({ onMounted, children }: Props) {
  const prevMounted = useRef(false);
  const mounted = useMounted();

  useEffect(() => {
    const isChanged = mounted.current !== prevMounted.current;
    prevMounted.current = mounted.current;
    if (isChanged) {
      mounted.current && onMounted && onMounted();
    }
  }, [mounted.current, onMounted]);

  return <>{children}</>;
}
