import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import TenantConnectionRequestView from "@/common/components/Entity/TenantConnectionRequest/View/TenantConnectionRequestView";

export default function TenantConnectionRequestViewPage() {
  const { tenantConnectionRequestId } = useParams<{ tenantConnectionRequestId?: string }>();

  return (
    <>
      <TenantConnectionRequestView
        tenantConnectionRequestId={tenantConnectionRequestId}
        tenantConnectionRequest={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
