import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { ArrayHelper } from "@/common/helpers/array";
import { AdminTenantDto, AdminUserDto } from "@/core/api/generated";

import TenantAutocomplete from "../../components/Entity/Tenant/TenantAutocomplete";
import AdminUserAutocomplete from "../../components/Entity/User/UserAutocomplete";
import { CreateNotificationFormValues } from "../CreateForm";

const CreateNotificationFormInAppInputs = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreateNotificationFormValues>();

  const onUserChanged = useCallback((index: number, user?: AdminUserDto) => {
    setFieldValue(`inApp.users[${index}].userId`, user?.id);
    setFieldValue(`inApp.users[${index}].tenantId`, "");
  }, []);
  const onTenantChanged = useCallback((index: number, tenant?: AdminTenantDto) => {
    setFieldValue(`inApp.users[${index}].tenantId`, tenant?.id);
  }, []);
  const onTitleChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`inApp.title`, e.target.value);
    },
    [],
  );
  const onBodyChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`inApp.body`, e.target.value);
    },
    [],
  );

  return (
    <>
      <Stack spacing={1}>
        <Typography component='div' variant='subtitle1'>
          Users
        </Typography>
        <Stack direction='column' spacing={1}>
          {values.inApp?.users?.map((item, index) => {
            return (
              <Card key={index} sx={{ overflow: "unset" }}>
                <CardContent>
                  <FormControl fullWidth margin='dense'>
                    <AdminUserAutocomplete
                      fullWidth
                      userId={item.userId}
                      onChange={(user) => onUserChanged(index, user)}
                      error={Boolean(
                        getIn(touched, `inApp.users[${index}].userId`) &&
                          getIn(errors, `inApp.users[${index}].userId`),
                      )}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `inApp.users[${index}].userId`) &&
                          getIn(errors, `inApp.users[${index}].userId`),
                      )}
                    >
                      {getIn(errors, `inApp.users[${index}].userId`)}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth margin='dense'>
                    <TenantAutocomplete
                      entityId={item.tenantId}
                      searchFilters={{
                        userId: item.userId || undefined,
                      }}
                      disabled={!item.userId}
                      fullWidth
                      onChange={(tenant) => onTenantChanged(index, tenant)}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `inApp.users[${index}].tenantId`) &&
                          getIn(errors, `inApp.users[${index}].tenantId`),
                      )}
                    >
                      {getIn(errors, `inApp.users[${index}].tenantId`)}
                    </FormHelperText>
                  </FormControl>

                  <Grid item xxs={12} sm={12} md={12} lg={12}>
                    {/* Controls */}
                    <Stack
                      direction='row'
                      sx={{
                        flex: 1,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() =>
                            setFieldValue(
                              "users",
                              ArrayHelper.removeByIndex(values.inApp?.users, index),
                            )
                          }
                        >
                          <AppIcon of='delete' />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Stack>

        {/* Add item */}
        <Button
          sx={{ flex: 0, alignSelf: "flex-start" }}
          variant='outlined'
          color='text'
          size='small'
          startIcon={<AppIcon of='add' />}
          onClick={() => {
            setFieldValue("inApp.users", [
              ...(values.inApp?.users || []),
              { userId: null, tenantId: null },
            ]);
          }}
        >
          Add user
        </Button>
      </Stack>

      <FormControl fullWidth margin='dense' sx={{ mt: 2 }}>
        <TextField
          error={Boolean(getIn(touched, "inApp.title") && getIn(errors, "inApp.title"))}
          variant='outlined'
          fullWidth
          label='Title'
          value={values?.inApp?.title || ""}
          onChange={onTitleChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "inApp.title") && getIn(errors, "inApp.title"))}
        >
          {getIn(errors, "inApp.title")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(getIn(touched, "inApp.body") && getIn(errors, "inApp.body"))}
          variant='outlined'
          fullWidth
          label='Body'
          value={values?.inApp?.body || ""}
          onChange={onBodyChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "inApp.body") && getIn(errors, "inApp.body"))}
        >
          {getIn(errors, "inApp.body")}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CreateNotificationFormInAppInputs;
