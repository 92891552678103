import { Box, Chip, SxProps, Theme } from "@mui/material";

import ContactCard from "@/common/components/Contact/ContactCard";
import { CustomerContactDto } from "@/core/api/generated";

import GeneralNationalIdentityNumberDisplay from "../General/Display/GeneralNationalIdentityNumberDisplay";

interface Props {
  contact?: CustomerContactDto | null;
  sx?: SxProps<Theme>;
}

export default function CustomerContactCard({ contact, sx }: Props) {
  if (!contact) {
    return null;
  }

  return (
    <ContactCard
      sx={sx}
      contact={{
        name: (
          <>
            {contact.personName?.name}{" "}
            {contact.isPrimary && (
              <Chip
                size='small'
                color='primary'
                variant={"filled"}
                label='Primary'
                sx={{ ml: 1 }}
              />
            )}
          </>
        ),
        email: contact.email,
        phoneNumber: contact.phoneNumber,
      }}
    >
      <Box>
        <GeneralNationalIdentityNumberDisplay
          value={contact.nationalIdentityNumber}
          withIcon
          withCountryCode
          withCountryIcon
          withTooltip
        />
      </Box>
    </ContactCard>
  );
}
