import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function VehicleSideIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M21.739 10.921C20.392 10.531 19.854 10.383 18.187 10C18.187 10 15.808 7.641 15.355 7.184C14.787 6.612 14.312 6 12.406 6H4.512C4.001 6 3.776 6.547 4.442 7C3.7 7.602 2.823 8.38 2.184 9.027C0.749 10.482 0 11.412 0 13.282C0 15.042 1.042 17 3.174 17H3.184C3.597 18.162 4.696 19 6 19C7.304 19 8.403 18.162 8.816 17H15.183C15.596 18.162 16.695 19 17.999 19C19.303 19 20.402 18.162 20.815 17H21.5C23.494 17 24 15.224 24 13.835C24 11.794 22.877 11.251 21.739 10.921ZM6 17.2C5.338 17.2 4.8 16.662 4.8 16C4.8 15.338 5.338 14.8 6 14.8C6.662 14.8 7.2 15.338 7.2 16C7.2 16.662 6.662 17.2 6 17.2ZM9.576 11C8.505 11 6.076 10.894 4.357 10.25C4.935 9.5 5.355 9.028 5.627 8.714C5.945 8.346 6.5 8 7.188 8H9.576V11ZM10.576 8H12.411C13.293 8 13.839 8.493 14.433 9.105C14.885 9.571 16.165 11 16.165 11H10.577L10.576 8ZM18 17.2C17.338 17.2 16.8 16.662 16.8 16C16.8 15.338 17.338 14.8 18 14.8C18.662 14.8 19.2 15.338 19.2 16C19.2 16.662 18.662 17.2 18 17.2Z' />
    </SvgIcon>
  );
});
