import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import SpotPaginatedList from "@/common/components/Entity/Spot/ListView/SpotPaginatedList";

export default function SpotsPage() {
  return (
    <>
      <SpotPaginatedList />
    </>
  );
}
