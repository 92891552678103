import { useParams } from "react-router-dom";

import WashView from "@/common/components/Entity/Wash/View/WashView";

export default function WashViewPage() {
  const { washId } = useParams<{ washId?: string }>();

  return (
    <>
      <WashView
        washId={washId}
        wash={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
