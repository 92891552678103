import { Box, FormControlLabel, FormGroup, FormHelperText, Stack, Switch } from "@mui/material";
import _ from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";

import { GeneralDeleteEntityDto } from "@/core/api/generated";

import ConfirmationModal, { ConfirmationModalProps } from "./ConfirmationModal";

export type GeneralDeleteConfirmationModalProps = Omit<ConfirmationModalProps, "onConfirm"> & {
  onConfirm?: (deleteDto: GeneralDeleteEntityDto) => void | Promise<void>;
};

/** Modal with cancel/confirm buttons. */
export default function GeneralDeleteConfirmationModal({
  onConfirm,
  ...props
}: GeneralDeleteConfirmationModalProps) {
  const [isDeleteAllRelatedData, setIsDeleteAllRelatedData] = useState(false);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      await onConfirm({
        isDeleteAllRelatedData,
      });
    }
  }, [onConfirm, isDeleteAllRelatedData]);

  return (
    <ConfirmationModal
      {...props}
      title={props.title || "Delete the entity?"}
      body={
        <>
          <Stack direction='column' spacing={2}>
            {props.content && <Box>{props.content}</Box>}

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color={isDeleteAllRelatedData ? "error" : undefined}
                      checked={isDeleteAllRelatedData}
                      onChange={(e) => setIsDeleteAllRelatedData(e.target.checked)}
                    />
                  }
                  label='Delete all related data?'
                />
                <FormHelperText>
                  By enabling this option you confirm that you want to delete all the related data.
                </FormHelperText>
              </FormGroup>
            </Box>
          </Stack>
        </>
      }
      onConfirm={handleConfirm}
    />
  );
}
