import { Box, Chip, ChipProps, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import _ from "lodash";
import { Fragment, useMemo } from "react";

import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { TextHelper } from "@/common/helpers/text";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { AppPermission, IBaseEntityDto } from "@/core/api/generated";

import IconChip, { IconChipProps } from "../Chip/IconChip";
import AssetInline from "../Entity/Asset/AssetInline";
import TenantInline from "../Entity/Tenant/TenantInline";
import TenantRequestInline from "../Entity/TenantRequest/TenantRequestInline";
import InlineApiEnumValueList from "../Enum/InlineApiEnumValueList";
import AppIcon from "../Icons/AppIcon";
import AppLink from "../Link/AppLink";

interface Props {
  entity: IBaseEntityDto | null | undefined;
  variant?: "normal" | "compact";
  chipProps?: Partial<ChipProps>;
  withLinks?: boolean;
  sx?: SxProps<Theme>;
}

/** Shows all entity chips. */
export default function EntityChipList({ entity, variant = "normal", chipProps, sx }: Props) {
  const currentTenant = useCurrentTenant();
  const { hasPermissions } = useAuthorizationInfo();

  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);

  const chipPropsComputed = useMemo<Partial<ChipProps>>(
    () => ({ variant: "outlined", size: "small", color: "primary", ...chipProps }),
    [chipProps],
  );

  const iconChipPropsComputed = useMemo<Partial<IconChipProps>>(
    () => ({ variant: "outlined", size: "small", color: "primary", ...chipProps }),
    [chipProps],
  );

  const components = useMemo(
    () =>
      [
        entityMeta?.importMeta ? (
          <Tooltip title='This entity was imported.'>
            {variant === "normal" ? (
              <Chip {...chipPropsComputed} icon={<AppIcon of='import' />} label='Imported' />
            ) : (
              <IconChip {...iconChipPropsComputed} icon={<AppIcon of='import' />} />
            )}
          </Tooltip>
        ) : undefined,

        entityMeta?.assetMeta?.assetId ? (
          <Tooltip
            title={
              <>
                This entity has asset{" "}
                <strong>
                  <AssetInline
                    entity={undefined}
                    entityId={entityMeta.assetMeta?.assetId}
                    withIcon={false}
                    withTooltip={false}
                  />
                </strong>
              </>
            }
          >
            <AppLink
              enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.AssetRead])}
              to={ROUTE_PATH.ASSET_VIEW(entityMeta.assetMeta?.assetId)}
            >
              {variant === "normal" ? (
                <Chip {...chipPropsComputed} icon={<AppIcon of='asset' />} label={<>Asset</>} />
              ) : (
                <IconChip {...iconChipPropsComputed} icon={<AppIcon of='asset' />} />
              )}
            </AppLink>
          </Tooltip>
        ) : undefined,

        entityMeta?.tenantRequestsMeta?.tenantRequestIds &&
        !_.isEmpty(entityMeta.tenantRequestsMeta?.tenantRequestIds) ? (
          <Tooltip
            title={
              <>
                This entity is linked to {entityMeta.tenantRequestsMeta?.tenantRequestIds.length}{" "}
                company{" "}
                {TextHelper.pluralizeManual(
                  "request",
                  entityMeta.tenantRequestsMeta?.tenantRequestIds.length,
                  "requests",
                )}
                :{" "}
                {entityMeta.tenantRequestsMeta?.tenantRequestIds.map((tenantRequestId, i) => (
                  <Fragment key={i}>
                    {i !== 0 && ", "}
                    <strong>
                      <TenantRequestInline
                        entity={undefined}
                        entityId={tenantRequestId}
                        withIcon={false}
                        withTooltip={false}
                      />
                    </strong>
                  </Fragment>
                ))}
                .
              </>
            }
          >
            {variant === "normal" ? (
              <Chip
                {...chipPropsComputed}
                icon={<AppIcon of='tenantRequest' />}
                label={
                  <>
                    {entityMeta.tenantRequestsMeta?.tenantRequestIds?.length} company{" "}
                    {TextHelper.pluralizeManual(
                      "request",
                      entityMeta.tenantRequestsMeta?.tenantRequestIds?.length ?? 0,
                      "requests",
                    )}
                  </>
                }
              />
            ) : (
              <IconChip {...iconChipPropsComputed} icon={<AppIcon of='tenantRequest' />} />
            )}
          </Tooltip>
        ) : undefined,

        entityMeta?.tenantRequestsMeta?.appliedResultOfTenantRequestId ? (
          <Tooltip
            title={
              <>
                This entity is created by applying result of the company request{" "}
                <strong>
                  <TenantRequestInline
                    entity={undefined}
                    entityId={entityMeta?.tenantRequestsMeta?.appliedResultOfTenantRequestId}
                    withIcon={false}
                    withTooltip={false}
                  />
                </strong>
                .
              </>
            }
          >
            {variant === "normal" ? (
              <Chip
                {...chipPropsComputed}
                icon={<AppIcon of='tenantRequestAppliedResult' />}
                label={<>Company request result</>}
              />
            ) : (
              <IconChip
                {...iconChipPropsComputed}
                icon={<AppIcon of='tenantRequestAppliedResult' />}
              />
            )}
          </Tooltip>
        ) : undefined,

        entityMeta?.grantsMeta ? (
          <Tooltip
            title={
              <Stack spacing={0}>
                <Box>
                  {entityMeta.grantsMeta.issuerTenantId === currentTenant?.id && (
                    <>
                      This entity is shared by you via Data Grant with these tenants:{" "}
                      <strong>
                        {Object.keys(entityMeta.grantsMeta.consumerTenantIdsMap || {}).map(
                          (consumerTenantId, i) => (
                            <TenantInline
                              key={i}
                              entity={undefined}
                              entityId={consumerTenantId}
                              withIcon={false}
                              withTooltip={false}
                            />
                          ),
                        )}
                      </strong>
                      .
                    </>
                  )}
                  {entityMeta.grantsMeta.consumerTenantIdsMap &&
                    entityMeta.grantsMeta.consumerTenantIdsMap[currentTenant?.id || ""] && (
                      <>
                        This entity is shared with you via data grant by company{" "}
                        <strong>
                          <TenantInline
                            entity={undefined}
                            entityId={entityMeta.grantsMeta.issuerTenantId}
                            withIcon={false}
                            withTooltip={false}
                          />
                        </strong>
                        .
                      </>
                    )}
                </Box>

                <Box>
                  Permissions:{" "}
                  <InlineApiEnumValueList
                    type='DataGrantPermission'
                    values={
                      (entityMeta.grantsMeta.tenantPermissions &&
                        entityMeta.grantsMeta.tenantPermissions[currentTenant?.id || ""]) ||
                      []
                    }
                  />
                </Box>
              </Stack>
            }
          >
            <AppLink
              enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.DataGrantRead])}
              to={ROUTE_PATH.DATA_GRANTS({
                grantEntityType: entityMeta.grantsMeta.grantEntityType,
                entityId: entity?.id,
              })}
            >
              {variant === "normal" ? (
                <Chip {...chipPropsComputed} icon={<AppIcon of='dataGrant' />} label='Shared' />
              ) : (
                <IconChip {...iconChipPropsComputed} icon={<AppIcon of='dataGrant' />} />
              )}
            </AppLink>
          </Tooltip>
        ) : undefined,
      ]
        .filter((x) => !_.isNil(x))
        .map((x) => x!),
    [entity],
  );

  if (!entity || _.isEmpty(components)) {
    return null;
  }

  return (
    <Stack
      component='span'
      direction='row'
      sx={{ display: "flex", minWidth: 0, flexWrap: "wrap", gap: 0.5, ...sx }}
    >
      {components.map((component, i) => (
        <span key={i} style={{ display: "flex" }}>
          {component}
        </span>
      ))}
    </Stack>
  );
}
