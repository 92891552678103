import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ChangeEventHandler, DragEventHandler, ReactNode, useRef, useState } from "react";

import { FileUploadSpecDto } from "@/core/api/generated";

import AppIconButton from "../Button/AppIconButton";
import AppIcon from "../Icons/AppIcon";
import FileUploadSpecDisplayModal from "./FileUploadSpecDisplayModal";

export interface FileUploadAreaProps {
  /** Allow uploading multiple files simultaneously. */
  multiple?: boolean;
  /** Comma-separated list of unique file type specifiers.
   *  A unique file type specifier is a string that describes a type of file that may be selected by the user in an <input> element of type file.
   *  E.g. .pdf,.docx,image/png,image/jpeg,image/*.
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  accept?: string;
  acceptText?: string;
  maxFiles?: number;
  disabled?: boolean;
  title?: ReactNode;
  fileUploadSpec?: FileUploadSpecDto | null | undefined;
  sx?: SxProps<Theme>;
  onChange?: (files: File[]) => void;
}

export default function FileUploadArea({
  multiple = false,
  accept,
  acceptText,
  maxFiles,
  disabled,
  title,
  fileUploadSpec,
  sx,
  onChange,
}: FileUploadAreaProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [areaHighlighted, setAreaHighlighted] = useState(false);
  const [isFileUploadSpecDisplayModalOpen, setIsFileUploadSpecDisplayModalOpen] = useState(false);

  const handleChange = (files: File[]) => {
    onChange && onChange(files);
  };

  const handleClickArea = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    let files = Array.from(fileInputRef.current?.files || []);

    // ensure correct mime types
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
    const acceptSeparator = ",";
    if (accept) {
      const allowedContentTypes = (accept || "").split(acceptSeparator).map((x) => x.trim());
      files = files.filter(
        (x) =>
          x.type === accept ||
          new RegExp(accept, "i").test(x.type) ||
          allowedContentTypes.some(
            (allowedType) => allowedType === x.type || new RegExp(allowedType, "i").test(x.type),
          ),
      );
    }

    handleChange(files);

    // clear file select cache, to be able to take previous file again
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAreaHighlighted(true);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAreaHighlighted(true);
  };

  const handleDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAreaHighlighted(false);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const evt = e;
    e.preventDefault();
    e.stopPropagation();
    setAreaHighlighted(false);

    handleChange(Array.from(evt.dataTransfer?.files || []));
  };

  return (
    <>
      <input
        type='file'
        hidden
        disabled={disabled}
        multiple={multiple}
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />

      {/* Area */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 3,
          cursor: "pointer",
          border: "2px",
          borderStyle: "dashed",
          borderRadius: "15px",
          borderColor: (theme) =>
            areaHighlighted ? theme.palette.primary.main : theme.palette.secondary.light,
          opacity: disabled ? 0.7 : 1,
          pointerEvents: disabled ? "none" : "initial",
          ...sx,
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClickArea}
      >
        <AppIcon of='export' fontSize='medium' sx={{ fill: (th) => th.palette.primary.main }} />

        <Typography component='div' variant='subtitle1' color='primary'>
          {title || "Browse files"}
        </Typography>

        <Typography component='div'>Drag and drop file(s) here</Typography>

        {maxFiles !== undefined && (
          <Typography component='div' variant='caption' color='text.light'>
            {maxFiles === 1
              ? "You can upload only one file"
              : `You can upload up to ${maxFiles} file(s)`}
          </Typography>
        )}

        {acceptText && (
          <Typography component='div' variant='caption' color='text.light'>
            {acceptText}
          </Typography>
        )}

        <Box sx={{ position: "absolute", bottom: 0, right: 0, p: 1 }}>
          <AppIconButton
            color='text'
            size='small'
            tooltipProps={{
              title: "View file upload spec",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsFileUploadSpecDisplayModalOpen(true);
            }}
          >
            <AppIcon of='info' />
          </AppIconButton>
        </Box>
      </Box>

      <FileUploadSpecDisplayModal
        displayProps={{
          spec: fileUploadSpec,
        }}
        open={isFileUploadSpecDisplayModalOpen}
        onClose={() => setIsFileUploadSpecDisplayModalOpen(false)}
      />
    </>
  );
}
