import { useMemo } from "react";

import {
  BaseHighlightProps,
  HighlightHelper,
  HighlightPropsInQueryString,
} from "../helpers/highlight";
import { useQueryParams } from "./useQueryParams";

/** Reads highlight props from URL query string.
 *  Highlight props can be provided only by FE for now.
 */
export const useHighlightPropsFromQueryString = <HighlightProps extends BaseHighlightProps>():
  | HighlightProps
  | undefined => {
  const queryParams = useQueryParams<HighlightPropsInQueryString>();

  return useMemo(() => {
    const props = Object.keys(queryParams).length !== 0 ? queryParams : undefined;
    if (!props || !props.highlightPropsEnc) {
      return undefined;
    }

    return HighlightHelper.decodePropsFromQueryString(props.highlightPropsEnc);
  }, [queryParams]);
};
