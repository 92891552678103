import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import DamageCostEvaluationLink from "@/common/components/Entity/DamageCostEvaluation/DamageCostEvaluationLink";
import DamageCostEvaluationAggregateDeleteModal from "@/common/components/Entity/DamageCostEvaluationAggregate/DamageCostEvaluationAggregateDeleteModal";
import GeneralApprovalStatusInfoDisplay from "@/common/components/Entity/General/Display/GeneralApprovalStatusInfoDisplay";
import GeneralInspectorDisplay from "@/common/components/Entity/General/Display/GeneralInspectorDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import LoadingMenuItem from "@/common/components/Menu/LoadingMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import StrikethroughText from "@/common/components/Text/StrikethroughText";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import { TextHelper } from "@/common/helpers/text";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageCostEvaluationAggregateDto,
  DamageCostEvaluationAggregateItemDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import RepairOperationCreateUpdateModal from "../../RepairOperation/RepairOperationCreateUpdateModal";
import BaseEntityView from "../../components/BaseEntityView";
import DamageCostEvaluationAggregateItemDeleteModal from "../DamageCostEvaluationAggregateItemDeleteModal";

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  vehicle: true,
  contract: true,
  items: true,
  attachments: true,
  sourceItems: true,
  customSections: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultHeaderProps = {
  withLink: false,
  title: undefined as
    | ReactNode
    | ((entity?: DamageCostEvaluationAggregateDto | null) => ReactNode)
    | undefined,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

interface OwnProps {
  aggregateId?: string | null;
  aggregate?: DamageCostEvaluationAggregateDto | null;
  withRealtimeDataUpdates?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  highlightProps?: {
    /** Item to highlight when mounted. */
    itemId?: string | null;
  };
  customSections?: {
    afterHeader?: ReactNode;
  };
  onEntityChange?: () => void;
}

export type DamageCostEvaluationAggregateViewProps = OwnProps;

export default function DamageCostEvaluationAggregateView({
  aggregateId,
  aggregate,
  withRealtimeDataUpdates = true,
  displayProps = defaultDisplayProps,
  headerProps,
  highlightProps,
  customSections,
  onEntityChange,
}: DamageCostEvaluationAggregateViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();

  const request = useApiRequest(
    apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      aggregateId: aggregateId!,
    },
    {
      deps: [aggregateId],
      skip: !aggregateId,
    },
  );
  aggregate = request?.data || aggregate;

  const dataUpdatesSub = useRealtimeDataUpdates({
    enabled: withRealtimeDataUpdates,
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.DamageCostEvaluationAggregate,
        aggregateId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        aggregate && {
          idValue: aggregate?.id || "",
          newTitle: aggregate?.localNumber || "",
        }) ||
      undefined,
  });

  // menu, dialogs
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<
    DamageCostEvaluationAggregateItemDto | undefined
  >(undefined);
  const [isItemDeleteModalOpen, setIsItemDeleteModalOpen] = useState(false);
  const [isCreateRepairOperationModalOpen, setIsCreateRepairOperationModalOpen] = useState(false);

  return (
    <BaseEntityView
      entityType={EntityType.DamageCostEvaluationAggregate}
      entityId={aggregateId}
      entity={aggregate}
      entityRequest={request}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          <>
            {displayProps.header && (
              <DetailedViewPageHeader
                image={undefined}
                title={
                  <Box>
                    {headerProps?.title &&
                      _.isFunction(headerProps?.title) &&
                      headerProps.title(aggregate)}
                    {headerProps?.title && !_.isFunction(headerProps?.title) && headerProps.title}
                    {!headerProps?.title && (
                      <Box>
                        Damage cost evaluation aggregate {aggregate?.localNumber}{" "}
                        {aggregate && (
                          <Typography component='span' variant='body2'>
                            ({aggregate?.items?.length}{" "}
                            {TextHelper.pluralize("item", aggregate?.items?.length ?? 0)})
                          </Typography>
                        )}{" "}
                        <EntityChipList entity={aggregate} variant='normal' />
                      </Box>
                    )}
                  </Box>
                }
                titleProps={{
                  to: headerProps?.withLink
                    ? ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATE_VIEW(aggregate?.id)
                    : undefined,
                  typographyProps: headerProps?.typographyProps,
                }}
                subtitle={
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <GeneralAttachedTagsDisplay
                      tags={aggregate?.tags}
                      defaultIsFolded={false}
                      edit={{
                        entityType: TagEntityType.DamageCostEvaluationAggregate,
                        entityId: aggregate?.id,
                        onSaved: (newValue) => {
                          request.updateData((x) => {
                            x.tags = newValue || undefined;
                          });
                        },
                      }}
                    />
                  </AuthorizedElement>
                }
                secondaryActions={
                  displayProps.actions &&
                  aggregate && (
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <MenuWithTrigger
                        trigger={
                          <IconButton sx={{ ml: "auto" }}>
                            <AppIcon of='moreVert' />
                          </IconButton>
                        }
                      >
                        {({ handleClose }) => [
                          <LoadingMenuItem
                            key='downloadPdf'
                            onClick={async () => {
                              const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                                apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdDownloadPdfGet(
                                  {
                                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                    aggregateId: aggregate?.id || "",
                                  },
                                  {
                                    responseType: "blob",
                                  },
                                ),
                              );
                              FileHelper.downloadBlobFromApiResult(downloadResult);
                              handleClose();
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='download' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Download PDF</ListItemText>
                          </LoadingMenuItem>,
                          // NB: temporary disabled
                          // <MenuItem
                          //   key='documents'
                          //   component={RouterLink}
                          //   to={ROUTE_PATH.DOCUMENTS({
                          //     sourceType: EntitySourceType.DamageCostEvaluationAggregate,
                          //     sourceId: aggregate?.id,
                          //   })}
                          // >
                          //   <ListItemIcon>
                          //     <AppIcon of='documents' fontSize='small' />
                          //   </ListItemIcon>
                          //   <ListItemText>Documents</ListItemText>
                          // </MenuItem>,
                          !aggregate?.contract && (
                            <AuthorizedMenuItem
                              permissions={[AppPermission.DamageCostEvaluationManage]}
                              key='delete'
                              onClick={() => setIsDeleteModalOpen(true)}
                            >
                              <ListItemIcon>
                                <AppIcon of='delete' fontSize='small' />
                              </ListItemIcon>
                              <ListItemText>Delete</ListItemText>
                            </AuthorizedMenuItem>
                          ),

                          <HeaderMenuItem key='quickActions' primaryTitle='Quick actions' />,
                          <AuthorizedMenuItem
                            key='repair'
                            permissionsAny={[
                              AppPermission.RepairOperationPerform,
                              AppPermission.RepairOperationManage,
                            ]}
                            onClick={() => {
                              setIsCreateRepairOperationModalOpen(true);
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='repair' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>New repair operation</ListItemText>
                          </AuthorizedMenuItem>,
                        ]}
                      </MenuWithTrigger>
                    </AuthorizedElement>
                  )
                }
              />
            )}
          </>
        }
      >
        <ViewContentBlock>
          {displayProps?.customSections && customSections?.afterHeader && (
            <Box>{customSections.afterHeader}</Box>
          )}

          {(displayProps?.vehicle || displayProps?.contract) && (
            <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              {displayProps?.vehicle && (
                <VehicleBlock
                  sx={{ maxWidth: { md: 400 } }}
                  variant='reference'
                  vehicle={aggregate?.vehicle}
                  withDetailsToggle
                  withLink
                />
              )}

              {displayProps?.contract && aggregate?.contract && (
                <ContractBlock
                  sx={{ maxWidth: { md: 400 } }}
                  variant='reference'
                  contract={aggregate?.contract}
                  withDetailsToggle
                  withLink
                />
              )}
            </Stack>
          )}

          {aggregate?.notes && (
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xxs={12} md minWidth={200}>
                <Stack direction='column' spacing={1}>
                  {aggregate?.notes && <FieldValue label='Notes'>{aggregate?.notes}</FieldValue>}
                </Stack>
              </Grid>
              <Grid item xxs={12} md minWidth={200}>
                <Stack direction='column' spacing={1}></Stack>
              </Grid>
            </Grid>
          )}

          {displayProps?.attachments && aggregate?.attachments?.length !== 0 && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(aggregate?.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}

          {displayProps?.items && (
            <DataTabular
              sx={{ mt: 2 }}
              columns={[
                {
                  field: "item.damage",
                  title: "Damage",
                  flex: 2,
                  renderCell: (item) => (
                    <Stack spacing={1}>
                      {item.item?.damages?.map((damage, i) => (
                        <Stack key={i} direction='row' spacing={1} alignItems='center'>
                          <VehicleDamageLink
                            vehicleId={damage.damage?.vehicleId}
                            entity={damage.damage}
                          />
                          <Typography component='div' variant='body2' color='text.secondary'>
                            (<InlineApiEnumValue type='VehicleArea' value={damage.damage?.area} /> /{" "}
                            {(damage.damage?.projection && (
                              <InlineApiEnumValue
                                type='VehicleProjection'
                                value={damage.damage?.projection}
                              />
                            )) ||
                              "-"}
                            )
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  ),
                },
                {
                  field: "item.notes",
                  title: "Notes",
                  flex: 0,
                  renderCell: (item) =>
                    item.item?.notes ? (
                      <AppTypography ellipsing={{ enabled: true }}>
                        {item.item?.notes}
                      </AppTypography>
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "attachments",
                  title: "Attachments",
                  flex: 0,
                  renderCell: (item) =>
                    !TypeHelper.isEmpty(item.item?.attachments) ? (
                      <FileListView
                        files={FileItem.createManyFrom(item.item?.attachments)}
                        maxVisibleFiles={2}
                        itemWidth={45}
                      />
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "damageCostEvaluation.inspectedAt",
                  title: "Inspected",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack>
                      <GeneralInspectorDisplay
                        inspector={item.damageCostEvaluation?.inspector}
                        inlineUserProps={{
                          withAvatar: true,
                          withLink: true,
                        }}
                      />
                      <Datetime datetime={item.damageCostEvaluation?.inspectedAt} />
                    </Stack>
                  ),
                },

                {
                  field: "item.subTotal",
                  title: "Sub total",
                  flex: 0,
                  renderCell: (item) => (
                    <Box>
                      <StrikethroughText
                        sx={{ mr: 1 }}
                        enabled={item.item?.isNegotiatedNewSubTotal}
                        component='span'
                        variant='crossSingle'
                        lineProps={{
                          style: "solid",
                          color: (th) => th.palette.error.dark,
                          thickness: "2px",
                        }}
                      >
                        <AppTooltip variant='helpText' title='Initial subtotal' isInline>
                          <CurrencyValue
                            value={item.item?.initialSubTotal}
                            currency={aggregate?.currency}
                          />
                        </AppTooltip>
                      </StrikethroughText>

                      {item.item?.isNegotiatedNewSubTotal && (
                        <AppTooltip variant='helpText' title='Negotiated subtotal' isInline>
                          <CurrencyValue
                            value={item.item?.subTotal}
                            currency={item.item?.negotiatedPrice?.currency || aggregate?.currency}
                          />
                        </AppTooltip>
                      )}
                    </Box>
                  ),
                },
                {
                  field: "item.discount",
                  title: "Discount",
                  flex: 0,
                  renderCell: (item) => (
                    <Box>
                      <StrikethroughText
                        sx={{ mr: 1 }}
                        enabled={item.item?.isNegotiatedNewDiscount}
                        component='span'
                        variant='crossSingle'
                        lineProps={{
                          style: "solid",
                          color: (th) => th.palette.error.dark,
                          thickness: "2px",
                        }}
                      >
                        <AppTooltip variant='helpText' title='Initial discount' isInline>
                          <GeneralDiscountDisplay
                            discount={item.item?.initialDiscount}
                            currency={aggregate?.currency}
                          />
                        </AppTooltip>
                      </StrikethroughText>

                      {item.item?.isNegotiatedNewDiscount && (
                        <AppTooltip variant='helpText' title='Negotiated discount' isInline>
                          <GeneralDiscountDisplay
                            discount={item.item?.discount}
                            currency={aggregate?.currency}
                          />
                        </AppTooltip>
                      )}
                    </Box>
                  ),
                },
                {
                  field: "item.tax",
                  title: "Tax",
                  flex: 0,
                  renderCell: (item) => (
                    <Box>
                      <StrikethroughText
                        sx={{ mr: 1 }}
                        enabled={item.item?.isNegotiatedNewTax}
                        component='span'
                        variant='crossSingle'
                        lineProps={{
                          style: "solid",
                          color: (th) => th.palette.error.dark,
                          thickness: "2px",
                        }}
                      >
                        <AppTooltip variant='helpText' title='Initial tax' isInline>
                          <GeneralTaxDisplay
                            tax={item.item?.initialTax}
                            currency={aggregate?.currency}
                          />
                        </AppTooltip>
                      </StrikethroughText>

                      {item.item?.isNegotiatedNewTax && (
                        <AppTooltip variant='helpText' title='Negotiated tax' isInline>
                          <GeneralTaxDisplay tax={item.item?.tax} currency={aggregate?.currency} />
                        </AppTooltip>
                      )}
                    </Box>
                  ),
                },
                {
                  field: "item.total",
                  title: "Total",
                  flex: 0,
                  renderCell: (item) => (
                    <Box>
                      <StrikethroughText
                        sx={{ mr: 1 }}
                        enabled={item.item?.isNegotiatedAnyNew}
                        component='span'
                        variant='crossSingle'
                        lineProps={{
                          style: "solid",
                          color: (th) => th.palette.error.dark,
                          thickness: "2px",
                        }}
                      >
                        <AppTooltip variant='helpText' title='Initial total' isInline>
                          <CurrencyValue
                            value={item.item?.initialTotal}
                            currency={aggregate?.currency}
                          />
                        </AppTooltip>
                      </StrikethroughText>

                      {item.item?.isNegotiatedAnyNew && (
                        <AppTooltip variant='helpText' title='Negotiated total' isInline>
                          <CurrencyValue
                            value={item.item?.total}
                            currency={item.item?.negotiatedPrice?.currency || aggregate?.currency}
                          />
                        </AppTooltip>
                      )}
                    </Box>
                  ),
                },
                {
                  field: "approval",
                  title: "Approval status",
                  flex: 1,
                  renderCell: (item) => (
                    <GeneralApprovalStatusInfoDisplay approval={item.approval} />
                  ),
                },
              ]}
              rows={aggregate?.items}
              getRowId={(item) => item.id!}
              borderVariant='bordered'
              getRowHeight={() => "auto"}
              rowHeight={65}
              isColumnSelectorEnabled={false}
              isColumnMenuEnabled={false}
              isPaginationEnabled={false}
              // rowHighlightPropsGetter={(item) =>
              //   (item.id === highlightProps?.itemId && {
              //     isHighlighted: true,
              //     durationMs: 5000,
              //     withAutoScroll: true,
              //   }) ||
              //   {}
              // }
              renderRowAction={
                (displayProps?.actions &&
                  (({ item }) => (
                    <>
                      <MenuWithTrigger
                        withAuthCloseOnClick
                        trigger={
                          <IconButton>
                            <AppIcon of='moreVert' />
                          </IconButton>
                        }
                      >
                        <AuthorizedMenuItem
                          permissions={[AppPermission.DamageCostEvaluationManage]}
                          onClick={() => {
                            setItemToDelete(item);
                            setIsItemDeleteModalOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='delete' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Delete item</ListItemText>
                        </AuthorizedMenuItem>
                      </MenuWithTrigger>
                    </>
                  ))) ||
                undefined
              }
            />
          )}
        </ViewContentBlock>

        {/* Summary */}
        <ViewContentBlock direction='row' sx={{ alignSelf: "flex-end" }}>
          {aggregate?.items?.length !== 0 && (
            <GeneralPriceSummaryDisplay
              summary={{
                currency: aggregate?.currency,
                subTotal: aggregate?.subTotal,
                subTotalIncDiscount: aggregate?.subTotalIncDiscount,
                discount: aggregate?.discount,
                tax: aggregate?.tax,
                total: aggregate?.total,
              }}
            />
          )}
        </ViewContentBlock>

        {displayProps?.sourceItems && !_.isEmpty(aggregate?.sourceDamageCostEvaluations) && (
          <ViewContentBlock>
            <ViewContentBlockHeader>Source damage cost evaluations</ViewContentBlockHeader>
            <Stack direction='column'>
              {aggregate?.sourceDamageCostEvaluations?.map((source, i) => (
                <DamageCostEvaluationLink key={i} entity={source} />
              ))}
            </Stack>
          </ViewContentBlock>
        )}

        {/* Delete modal */}
        {aggregate && (
          <DamageCostEvaluationAggregateDeleteModal
            entity={aggregate}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => onEntityChange && onEntityChange()}
          />
        )}

        {/* Delete item modal */}
        {aggregate && itemToDelete && (
          <DamageCostEvaluationAggregateItemDeleteModal
            entity={aggregate}
            item={itemToDelete}
            open={isItemDeleteModalOpen}
            onClose={() => setIsItemDeleteModalOpen(false)}
            onDelete={() => {
              setIsItemDeleteModalOpen(false);
              onEntityChange && onEntityChange();
              request.refetch();
            }}
          />
        )}

        {/* Create RepairOperation */}
        {aggregate && (
          <RepairOperationCreateUpdateModal
            open={isCreateRepairOperationModalOpen}
            onClose={() => setIsCreateRepairOperationModalOpen(false)}
            createUpdateProps={{
              defaultValues: {
                vehicleId: aggregate?.vehicle?.id,
                contractId: aggregate?.contract?.id,
                vehicleDamageIds: _.flatten(
                  aggregate.items
                    ?.map((x) => x.item?.damages?.map((y) => y.damage?.id || "") || [])
                    ?.filter(Boolean),
                ),
              },
              onSave: (newValue) => {
                setIsCreateRepairOperationModalOpen(false);
                history.push(ROUTE_PATH.REPAIR_OPERATION_VIEW(newValue.id));
              },
            }}
          />
        )}
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
