import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormHelperText,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

import FoldableBlock from "@/common/components/Display/FoldableBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import AppModal from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import TextLineSkeleton from "@/common/components/Skeleton/TextLineSkeleton";
import { deepCompareEquals } from "@/common/helpers/comparison";
import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralTaxInputDto,
  VehicleTaxCalcRequestDto,
  VehicleTaxCalcResponseDto,
} from "@/core/api/generated";

import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";
import VehicleTaxCalcRequestParamsDisplay from "./VehicleTaxCalcRequestParamsDisplay";
import VehicleTaxDisplay from "./VehicleTaxDisplay";

export interface VehicleInlineProps {
  tax: GeneralTaxInputDto | null | undefined;
  taxAppliesTo:
    | {
        subTotal: number;
      }
    | undefined;
  vehicleTaxCalcRequestParams: VehicleTaxCalcRequestDto | null | undefined;
  onChange?: (newValue?: GeneralTaxInputDto | null) => void;
  sx?: SxProps<Theme>;
}

export default function VehicleTaxInputHelper({
  tax,
  taxAppliesTo,
  vehicleTaxCalcRequestParams,
  onChange,
  sx,
}: VehicleInlineProps) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [taxCalcResult, setTaxCalcResult] = useState<VehicleTaxCalcResponseDto | undefined>(
    undefined,
  );
  const [oldTax, setOldTax] = useState<GeneralTaxInputDto | null | undefined>(undefined);
  // const [newTax, setNewTax] = useState<GeneralTaxInputDto | null | undefined>(undefined);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isOldTaxApplied, setIsOldTaxApplied] = useState(true);
  const [isNewTaxApplied, setIsNewTaxApplied] = useState(false);

  const newTax = taxCalcResult?.newTax;
  const canApplyOldTax = !isOldTaxApplied || isNewTaxApplied;
  const canApplyNewTax = !isNewTaxApplied || isOldTaxApplied;
  const canRefreshCalcResult = !!taxCalcResult;

  const calcVehicleTax = useCallback(
    async (
      currentTax: GeneralTaxInputDto | null | undefined,
      vehicleTaxCalcRequestParams2: VehicleTaxCalcRequestDto | null | undefined,
    ) => {
      if (!currentTax || !vehicleTaxCalcRequestParams2) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await apiClient.vehiclesApi.apiV1VehiclesTaxCalcPost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleTaxCalcRequestDto: {
            ...vehicleTaxCalcRequestParams2,
          },
        });
        const calcResult = response.data;
        if (calcResult.newTax) {
          setTaxCalcResult(calcResult);
          setOldTax(currentTax);
          setIsOldTaxApplied(true);
          setIsNewTaxApplied(false);
        }
      } catch (err) {
        // don't show errors not needed for user
        console.error(err);
        // const validation2 = ValidationHelper.handleApiErrorResponse(err);
        // validation2.hasErrors &&
        //   enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );
  const calcVehicleTaxDebounce = useCallback(
    _.debounce(calcVehicleTax, 500, { leading: false, trailing: true }),
    [calcVehicleTax],
  );

  const refreshCalcResult = useCallback(() => {
    calcVehicleTaxDebounce(tax, vehicleTaxCalcRequestParams);
  }, [tax, vehicleTaxCalcRequestParams, canRefreshCalcResult]);

  const applyOldTax = useCallback(() => {
    onChange && onChange(oldTax);
    setIsOldTaxApplied(true);
    setIsNewTaxApplied(false);
  }, [taxCalcResult, oldTax, newTax, isOldTaxApplied]);

  const applyNewTax = useCallback(() => {
    onChange && onChange(newTax);
    setIsOldTaxApplied(false);
    setIsNewTaxApplied(true);
  }, [taxCalcResult, oldTax, newTax, isNewTaxApplied]);

  // when tax changes from outside, calc new tax based on the request params
  useEffect(() => {
    const isChanged = !deepCompareEquals(tax, oldTax) && !deepCompareEquals(tax, newTax);
    if (isChanged) {
      calcVehicleTaxDebounce(tax, vehicleTaxCalcRequestParams);
    }
  }, [tax, taxAppliesTo, oldTax, vehicleTaxCalcRequestParams]);

  useEffectWithDeepCompare(() => {
    calcVehicleTaxDebounce(tax, vehicleTaxCalcRequestParams);
  }, [taxAppliesTo]);

  if (!tax || !vehicleTaxCalcRequestParams) {
    return null;
  }

  return (
    <Box sx={sx}>
      <FormHelperText>
        {isLoading && <TextLineSkeleton />}

        {!isLoading && (
          <Stack spacing={0.25}>
            <Box>Vehicle tax is applicable here.</Box>

            <Box>
              <AppLink
                to='#'
                isInheritStyles
                onClick={(e) => {
                  e.preventDefault();
                  setIsDetailsModalOpen(true);
                }}
              >
                Details
              </AppLink>
              {canRefreshCalcResult && (
                <>
                  {" "}
                  |{" "}
                  <AppLink
                    to='#'
                    isInheritStyles
                    onClick={(e) => {
                      e.preventDefault();
                      refreshCalcResult();
                    }}
                  >
                    Refresh
                  </AppLink>
                </>
              )}
            </Box>

            {taxCalcResult && oldTax && newTax && (
              <Box>
                {canApplyOldTax && (
                  <AppLink
                    to='#'
                    isInheritStyles
                    onClick={(e) => {
                      e.preventDefault();
                      applyOldTax();
                    }}
                  >
                    Revert to old tax - <GeneralTaxDisplay tax={oldTax} detailsPlacement='inline' />
                  </AppLink>
                )}
                {canApplyNewTax && (
                  <AppLink
                    to='#'
                    isInheritStyles
                    onClick={(e) => {
                      e.preventDefault();
                      applyNewTax();
                    }}
                  >
                    Apply new tax - <GeneralTaxDisplay tax={newTax} detailsPlacement='inline' />
                  </AppLink>
                )}
              </Box>
            )}
          </Stack>
        )}
      </FormHelperText>

      {/* Details modal */}
      <AppModal
        open={isDetailsModalOpen}
        fullWidth
        maxWidth='md'
        onClose={() => {
          setIsDetailsModalOpen(false);
        }}
      >
        <AppModalTitle
          title={"Vehicle tax details"}
          withCloseIcon
          onCloseClicked={() => {
            setIsDetailsModalOpen(false);
          }}
        />

        <DialogContent>
          <Stack spacing={2}>
            {isLoading && <LinearProgress />}

            <Stack spacing={1}>
              <FieldValue label='Old tax' isEmpty={!oldTax}>
                <GeneralTaxDisplay tax={oldTax} detailsPlacement='inline' />
                {canApplyOldTax && (
                  <>
                    {" "}
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      disabled={isLoading}
                      onClick={() => applyOldTax()}
                    >
                      Revert to old tax
                    </Button>
                  </>
                )}
              </FieldValue>

              <FieldValue label='New tax' isEmpty={!newTax}>
                <Stack spacing={1}>
                  <Box>
                    <GeneralTaxDisplay tax={newTax} detailsPlacement='inline' />
                    {canApplyNewTax && (
                      <>
                        {" "}
                        <Button
                          variant='contained'
                          size='small'
                          color='primary'
                          disabled={isLoading}
                          onClick={() => applyNewTax()}
                        >
                          Apply new tax
                        </Button>
                      </>
                    )}
                  </Box>

                  {taxCalcResult?.newTaxCalcExplanationText && (
                    <Typography variant='caption' sx={{ whiteSpace: "pre-line" }}>
                      {taxCalcResult?.newTaxCalcExplanationText}
                    </Typography>
                  )}
                </Stack>
              </FieldValue>
            </Stack>

            <FoldableBlock
              defaultIsFolded
              trigger={{
                label: <Typography variant='h6'>Vehicle tax calculation parameters</Typography>,
              }}
            >
              <VehicleTaxCalcRequestParamsDisplay
                vehicleTaxCalcRequestParams={vehicleTaxCalcRequestParams}
              />
            </FoldableBlock>

            {taxCalcResult?.vehicleTax && (
              <FoldableBlock
                defaultIsFolded
                trigger={{
                  label: <Typography variant='h6'>Suitable vehicle tax</Typography>,
                }}
              >
                <VehicleTaxDisplay vehicleTax={taxCalcResult.vehicleTax} />
              </FoldableBlock>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          <Button
            variant='outlined'
            size='small'
            color='text'
            startIcon={<AppIcon of='refresh' />}
            disabled={isLoading}
            onClick={() => refreshCalcResult()}
          >
            Refresh
          </Button>
        </DialogActions>
      </AppModal>
    </Box>
  );
}
