import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { WebhookDto } from "@/core/api/generated";

interface Props {
  webhook: WebhookDto;
}

export default function OverviewTabContent({ webhook }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={webhook} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Event Types'>
              <InlineApiEnumValueList type='WebhookEventType' values={webhook?.eventTypes} />
            </FieldValue>
            <FieldValue label='Name'>{webhook?.name}</FieldValue>
            <FieldValue label='Notes'>{webhook?.notes}</FieldValue>
            <FieldValue label='Endpoint' withCopyButton>
              {webhook?.url}
            </FieldValue>
            <FieldValue label='Enabled'>{webhook?.isEnabled ? "Yes" : "No"}</FieldValue>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
