import { useMemo } from "react";
import { Route, RouteProps } from "react-router-dom";

import { UrlHelper } from "@/common/helpers/url";

type AppRouteProps = RouteProps;

/** Facade on Route component. */
export default function AppRoute({ path, ...routeProps }: AppRouteProps) {
  // remove query string from route path
  const pathCleaned = useMemo(
    () => (path && UrlHelper.deleteArbitraryUrlSearchParams(path.toString())) || undefined,
    [path],
  );

  return <Route path={pathCleaned} {...routeProps} />;
}
