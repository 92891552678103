import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";

type Props = DialogProps;

export default function ClearSystemCacheModal({ ...modalProps }: Props) {
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const openStatus = useModalOpenStatus(modalProps);

  const [isClearCacheLoading, setIsClearCacheLoading] = useState(false);
  const [isUserConfirmed, setIsUserConfirmed] = useState(false);
  const [isClearAll, setIsClearAll] = useState(false);

  useEffect(() => {
    if (openStatus.isClosing) {
      setIsUserConfirmed(false);
      setIsClearAll(false);
    }
  }, [openStatus]);

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose({}, "escapeKeyDown");
  };

  const handleSave = async () => {
    setIsClearCacheLoading(true);
    try {
      const result = await apiClient.adminSystemCacheApi.apiV1AdminSystemCacheClearDelete({
        adminClearCacheDto: {
          isClearAll,
        },
      });
      enqueueSnackbar("Cache cleared.", {
        variant: "success",
      });
      handleClose();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsClearCacheLoading(false);
    }
  };

  return (
    <Dialog maxWidth='sm' fullWidth {...modalProps}>
      <AppModalTitle
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      >
        Clear system cache?
      </AppModalTitle>
      <DialogContent>
        <DialogContentText>
          You&apos;re going to clear cached data used by the system.
        </DialogContentText>
        <DialogContentText>
          Once cache is cleared, data will be re-cached (usually this happens on demand).
        </DialogContentText>
        <DialogContentText>Please confirm the action before proceed.</DialogContentText>

        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isUserConfirmed}
                  onChange={(e) => setIsUserConfirmed(e.target.checked)}
                />
              }
              label={"I confirm that I understand what I'm going to do"}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch checked={isClearAll} onChange={(e) => setIsClearAll(e.target.checked)} />
              }
              label={"Clear the whole cache"}
            />
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='text' onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          color='error'
          loading={isClearCacheLoading}
          disabled={!isUserConfirmed}
          type='submit'
          variant='contained'
          onClick={handleSave}
        >
          Clear
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
