import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { IBaseEntityDto } from "@/core/api/generated";

import TenantStructureMembershipOfEntityEdit, {
  TenantStructureMembershipOfEntityEditProps,
} from "./TenantStructureMembershipOfEntityEdit";

export interface OwnProps<TEntity extends IBaseEntityDto> {
  tenantStructureMembershipOfEntityEditProps: TenantStructureMembershipOfEntityEditProps<TEntity>;
}

type Props<TEntity extends IBaseEntityDto> = OwnProps<TEntity> & DialogProps;

export default function TenantStructureMembershipOfEntityEditModal<TEntity extends IBaseEntityDto>({
  tenantStructureMembershipOfEntityEditProps,
  ...dialogProps
}: Props<TEntity>) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >{`Add entities to a department and location`}</AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <TenantStructureMembershipOfEntityEdit {...tenantStructureMembershipOfEntityEditProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
