import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import VehicleDamageView from "@/common/components/Entity/VehicleDamage/View/VehicleDamageView";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface VehicleDamageViewPageRouteParams {
  vehicleId: string | undefined;
  vehicleDamageId: string | undefined;
}

export interface VehicleDamageViewPageAltRouteParams {
  vehicleDamageId: string | undefined;
}

export type VehicleDamageViewPageQueryParams = GeneralQueryParams;

export default function VehicleDamageViewPage() {
  const { vehicleId, vehicleDamageId } = useParams<VehicleDamageViewPageRouteParams>();
  const queryParams = useQueryParams<VehicleDamageViewPageQueryParams>();

  return (
    <Box>
      <VehicleDamageView vehicleId={vehicleId} vehicleDamageId={vehicleDamageId} />
    </Box>
  );
}
