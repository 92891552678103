import { FormikProps, getIn } from "formik";

export type CustomFormikSubProps<SubValues> = Pick<
  FormikProps<SubValues>,
  "values" | "errors" | "touched" | "setFieldError"
> & {
  /** Manually set sub values object (values -> sub values -> fields).  */
  setValues: (values: any, shouldValidate?: boolean) => void;
  /** Set value of form field directly. */
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export class FormikHelper {
  /** Creates facade on base FormikProps<Values> for nested form object. */
  static getSubProps<Values, SubValues>(
    formikProps: FormikProps<Values> | CustomFormikSubProps<Values | null | undefined>,
    subPath: string,
    getSubValues: (values: Values) => SubValues, // Exclude<SubValues, null | undefined>,
  ): CustomFormikSubProps<SubValues> {
    return {
      values: getIn(formikProps.values, subPath) || undefined,
      errors: getIn(formikProps.errors, subPath) || undefined,
      touched: getIn(formikProps.touched, subPath) || undefined,
      setValues: (values: any, shouldValidate?: boolean) =>
        formikProps.setFieldValue(subPath, values, shouldValidate),
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) =>
        formikProps.setFieldValue([subPath, field].join("."), value, shouldValidate),
      setFieldError: (field: string, message: string | undefined) =>
        formikProps.setFieldError([subPath, field].join("."), message),
    };
  }
}
