import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, ContractDto } from "@/core/api/generated";

import AuthorizedMenuItem from "../../Auth/AuthorizedMenuItem";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import HeaderMenuItem from "../../Menu/HeaderMenuItem";
import ContractDeleteModal from "./ContractDeleteModal";
import ContractReminderSettingsModal from "./ContractReminderSettingsModal";

enum ActionFlags {
  Assessments = "Assessments",
  Edit = "Edit",
  CustomerCommunication = "CustomerCommunication",
  ContractRemainders = "ContractRemainders",
  Delete = "Delete",
  //quick action
  CreateVisualInspection = "CreateVisualInspection",
  CreateDamageDetection = "CreateDamageDetection",
  CreateDamageCostEvaluation = "CreateDamageCostEvaluation",
  CreateRepairOperation = "CreateRepairOperation",
  CreateAccessoryCheck = "CreateAccessoryCheck",
}

const defaultDisplayProps = {
  actions: {
    assessments: true,
    edit: true,
    customerCommunication: true,
    contractRemainders: true,
    eventLog: true,
    delete: true,
    //quick action
    createVisualInspection: true,
    createDamageDetection: true,
    createDamageCostEvaluation: true,
    createRepairOperation: true,
    createAccessoryCheck: true,
  },
};

interface TriggersProps {
  entity?: ContractDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: ContractDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: ContractDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode | Element;
}

export default function ContractMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.assessments && (
            <MenuItem
              component={RouterLink}
              to={ROUTE_PATH.ASSESSMENT_FLOWS({
                contractId: entity?.id,
              })}
            >
              <ListItemIcon>
                <AppIcon of='view' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Assessments</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.edit && (
            <AuthorizedMenuItem
              permissions={[AppPermission.ContractManage]}
              component={RouterLink}
              to={ROUTE_PATH.CONTRACT_EDIT(entity?.id)}
            >
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.customerCommunication && (
            <MenuItem onClick={() => startAction(ActionFlags.CustomerCommunication)}>
              <ListItemIcon>
                <AppIcon of='contacted' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Customer communication</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.contractRemainders && (
            <MenuItem onClick={() => startAction(ActionFlags.ContractRemainders)}>
              <ListItemIcon>
                <AppIcon of='reminder' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Contract reminders</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.FleetAppAccess, AppPermission.ContractManage]}
              onClick={() => startAction(ActionFlags.Delete)}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </AuthorizedMenuItem>
          )}
          {(displayProps?.actions?.createVisualInspection ||
            displayProps?.actions?.createDamageDetection ||
            displayProps?.actions?.createDamageCostEvaluation ||
            displayProps?.actions?.createRepairOperation ||
            displayProps?.actions?.createAccessoryCheck) && (
            <HeaderMenuItem primaryTitle='Quick actions' />
          )}
          {displayProps?.actions?.createVisualInspection && (
            <AuthorizedMenuItem
              permissionsAny={[
                AppPermission.VisualInspectionPerform,
                AppPermission.VisualInspectionManage,
              ]}
              component={AppLink}
              to={ROUTE_PATH.VISUAL_INSPECTION_CREATE({
                vehicleId: entity?.vehicle?.id,
                contractId: entity?.id,
              })}
              disabled={!entity?.canPerformVisualInspection}
            >
              <ListItemIcon>
                <AppIcon of='visualInspection' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New visual inspection</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.createDamageDetection && (
            <AuthorizedMenuItem
              permissionsAny={[
                AppPermission.DamageDetectionPerform,
                AppPermission.DamageDetectionManage,
              ]}
              component={AppLink}
              to={ROUTE_PATH.DAMAGE_DETECTION_CREATE({
                vehicleId: entity?.vehicle?.id,
                contractId: entity?.id,
              })}
              disabled={!entity?.canPerformAnyOperation}
            >
              <ListItemIcon>
                <AppIcon of='damageDetection' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New damage detection</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.createDamageCostEvaluation && (
            <AuthorizedMenuItem
              permissionsAny={[
                AppPermission.DamageCostEvaluationPerform,
                AppPermission.DamageCostEvaluationManage,
              ]}
              component={AppLink}
              to={ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE({
                vehicleId: entity?.vehicle?.id,
                contractId: entity?.id,
              })}
              disabled={!entity?.canPerformAnyOperation}
            >
              <ListItemIcon>
                <AppIcon of='damageCostEvaluation' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New damage cost evaluation</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.createRepairOperation && (
            <AuthorizedMenuItem
              permissionsAny={[
                AppPermission.RepairOperationPerform,
                AppPermission.RepairOperationManage,
              ]}
              component={AppLink}
              to={ROUTE_PATH.REPAIR_OPERATION_CREATE({
                vehicleId: entity?.vehicle?.id,
                contractId: entity?.id,
              })}
              disabled={!entity?.canPerformAnyOperation}
            >
              <ListItemIcon>
                <AppIcon of='repairOperation' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New repair operation</ListItemText>
            </AuthorizedMenuItem>
          )}
          {displayProps?.actions?.createAccessoryCheck && (
            <AuthorizedMenuItem
              permissions={[AppPermission.AssetManage]}
              component={AppLink}
              to={ROUTE_PATH.ACCESSORY_CHECK_CREATE({
                vehicleId: entity?.vehicle?.id,
                contractId: entity?.id,
              })}
              disabled={!entity?.canPerformAnyOperation}
            >
              <ListItemIcon>
                <AppIcon of='accessoryCheck' fontSize='small' />
              </ListItemIcon>
              <ListItemText>New accessory check</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <ContractDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}

          {/* Reminders */}
          {entity && (
            <ContractReminderSettingsModal
              reminderSettingsDisplayProps={{
                contract: entity,
                onUpdate: (newValue) => {
                  onUpdate && onUpdate(null);
                },
              }}
              open={currentAction === ActionFlags.ContractRemainders}
              onClose={() => cancelAction()}
            />
          )}
        </Stack>
      )}
    />
  );
}
