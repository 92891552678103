import { Container, LinearProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import InviteInfo from "@/App/MainAppView/components/Invite/InviteInfo";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { authService } from "@/common/services/auth";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { apiClient } from "@/core/api/ApiClient";

export type InviteStartPageQueryParams = GeneralQueryParams;

export default function InviteStartPage() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { ...generalQueryParams } = useQueryParams<InviteStartPageQueryParams>();

  const history = useHistory();

  const [isCheckingAuthenticated, setIsCheckingAuthenticated] = useState(true);

  const inviteRequest = useApiRequest(
    apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet,
    {
      inviteId: inviteId!,
    },
    {
      skip: !inviteId,
    },
  );
  const invite = inviteRequest?.data;

  const userExistsRequest = useApiRequest(
    apiClient.invitesApi.apiV1InvitesUserExistsAnonymouslyPost,
    {
      inviteCheckUserExistsDto: {
        inviteId: invite?.id,
        email: invite?.userInfo?.email,
      },
    },
    {
      skip: !invite?.userInfo?.email,
    },
  );

  const redirectUrl = invite?.redirectUrl || generalQueryParams.redirectUrl;

  useEffect(() => {
    (async () => {
      if (invite) {
        // if logged in and already answered
        if ((await authService.isAuthenticated()) && invite.isIAlreadyAnswered === true) {
          console.log("Redirect to already answered on invite...");
          history.push(
            ROUTE_PATH.AUTH_INVITE_ALREADY_ANSWERED(inviteId, {
              ...generalQueryParams,
            }),
          );
          return;
        }

        // if logged in, redirect to accept page
        if (await authService.isAuthenticated()) {
          console.log("Redirect to invite accept (user is logged in)...");
          history.push(
            ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
              ...generalQueryParams,
            }),
          );
          return;
        }

        // if user already exists, redirect to accept page
        if (userExistsRequest?.data?.exists === true) {
          console.log("Redirect to existing user login (user exists)...");
          authService.loginWithRedirect({
            loginHint: invite?.userInfo?.email,
            appState: {
              spaRedirectUrl: ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
                ...generalQueryParams,
              }),
            },
          });
          return;
        }

        setIsCheckingAuthenticated(false);
      }
    })();
  }, [invite, userExistsRequest]);

  if (isCheckingAuthenticated) {
    return <LinearProgress />;
  }

  return (
    <Container maxWidth='md'>
      <Stack spacing={2}>
        {!isCheckingAuthenticated && (
          <Redirect
            to={ROUTE_PATH.AUTH_INVITE_ENTER_EMAIL(inviteId, {
              ...generalQueryParams,
            })}
          />
        )}
        {inviteRequest.isLoading && <LinearProgress />}
        {invite && (
          // <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
          //   <AppIcon of='info' fontSize='large' color='secondary' />

          //   <Box sx={{ textAlign: "center" }}>
          //     <Typography component='div' variant='h6'>
          //       {renderIf()
          //         .if(invite.userType === InviteUserType.Customer)
          //         .then(
          //           <>
          //             You have been invited to join <strong>{invite.tenant?.name}</strong>.
          //           </>,
          //         )
          //         .else(
          //           <>
          //             You have been invited to join the <strong>{invite.tenant?.name}</strong>{" "}
          //             company.
          //           </>,
          //         )
          //         .render()}
          //     </Typography>
          //     <Typography component='div' variant='body1'>
          //       {`Please complete simple registration first.`}
          //     </Typography>
          //     {redirectUrl && (
          //       <Typography component='div' variant='body1'>
          //         You will be redirected to the destination page after registration.
          //       </Typography>
          //     )}
          //   </Box>

          //   <Box sx={{ width: "100%" }}>
          //     <Button
          //       sx={{ mt: 2 }}
          //       variant='contained'
          //       color='primary'
          //       fullWidth
          //       component={AppLink}
          //       to={ROUTE_PATH.AUTH_INVITE_ENTER_EMAIL(inviteId, { ...generalQueryParams })}
          //     >{`Let's go`}</Button>
          //   </Box>
          // </Stack>
          <InviteInfo
            invite={invite}
            hasRedirectUrl={!!redirectUrl}
            redirectToOnClick={ROUTE_PATH.AUTH_INVITE_ENTER_EMAIL(inviteId, {
              ...generalQueryParams,
            })}
          />
        )}
      </Stack>
    </Container>
  );
}
