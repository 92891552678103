import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface Props<TItem> {
  name: string;
  items: TItem[];
  children: (item: TItem) => JSX.Element;
  action?: JSX.Element;
  actionAsItem?: JSX.Element;
}

export default function CategoryBlock<TItem>({
  name,
  items,
  action,
  actionAsItem,
  children,
}: Props<TItem>) {
  return (
    <Box>
      <Stack direction='row' spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Typography
          component='div'
          sx={{ ml: 1, mr: 2, width: "auto", whiteSpace: "nowrap" }}
          variant='subtitle2'
        >
          {name}
        </Typography>
        <Box
          sx={{
            height: "2px",
            width: "100%",
            backgroundColor: (theme) => theme.palette.background.gray,
          }}
        />
      </Stack>
      <Grid sx={{ pb: 1 }} container columnSpacing={2} rowSpacing={1}>
        {items.map((item, i) => (
          <Grid
            key={i}
            item
            xxs={12}
            xs={6}
            md={4}
            lg={3}
            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {children(item)}
          </Grid>
        ))}
        {actionAsItem && actionAsItem}
      </Grid>
      {action && action}
    </Box>
  );
}
