import { TextField } from "@mui/material";

import { FilterValueInputProps } from "@/common/ts/filters";

interface Props extends FilterValueInputProps {}

/** Placeholder for filter operator that doesn't need an input. */
export default function FilterValueInputPlaceholder({ textFieldProps }: Props) {
  return <TextField label='' placeholder='' {...textFieldProps} type='text' disabled />;
}
