import { Chip, ChipProps, SxProps, Theme } from "@mui/material";
import { ReactNode, forwardRef } from "react";

export interface IconChipProps extends Omit<ChipProps, "icon" | "label"> {
  icon: ReactNode;
  sx?: SxProps<Theme>;
}

/** Chips that contains icon only.  */
export default forwardRef<HTMLDivElement, IconChipProps>(function IconChip(
  { icon, size, sx, ...otherProps }: IconChipProps,
  ref,
) {
  return (
    <Chip
      ref={ref}
      sx={{
        height: "auto",
        width: "auto",
        borderRadius: "90%",
        fontSize: size === "small" ? "small" : "medium",
        ".MuiChip-label": {
          display: "inline-flex",
          p: size === "small" ? 0.5 : 1,
        },
        ...sx,
      }}
      size={size}
      label={icon}
      {...otherProps}
    />
  );
});
