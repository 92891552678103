import { useEffect, useRef, useState } from "react";

import { ApiConnectionStatus, apiClient } from "@/core/api/ApiClient";

export type InternetConnectionStatus = ApiConnectionStatus;
export const InternetConnectionStatus = ApiConnectionStatus;

export interface IInternetConnectionStatusInfo {
  status: InternetConnectionStatus;
  isConnected: boolean;
  isDisconnected: boolean;
  isJustConnected: boolean;
  isJustDisconnected: boolean;
  getStatus: () => InternetConnectionStatus;
}

const getInternetStatus = () =>
  window.navigator.onLine
    ? InternetConnectionStatus.Connected
    : InternetConnectionStatus.Disconnected;

/** Returns status of internet connection. */
export const useInternetConnectionStatus = (): IInternetConnectionStatusInfo => {
  const [status, setStatus] = useState<InternetConnectionStatus>(getInternetStatus());
  const prevStatusRef = useRef<InternetConnectionStatus>(getInternetStatus());

  useEffect(() => {
    const onlineHandler = () => {
      prevStatusRef.current = status;
      setStatus(InternetConnectionStatus.Connected);
    };
    const offlineHandler = () => {
      prevStatusRef.current = status;
      setStatus(InternetConnectionStatus.Disconnected);
    };

    // https://caniuse.com/online-status
    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return {
    status,
    isConnected: status === InternetConnectionStatus.Connected,
    isDisconnected: status === InternetConnectionStatus.Disconnected,
    isJustConnected:
      status === InternetConnectionStatus.Connected &&
      prevStatusRef.current === InternetConnectionStatus.Disconnected,
    isJustDisconnected:
      status === InternetConnectionStatus.Disconnected &&
      prevStatusRef.current === InternetConnectionStatus.Connected,
    getStatus: () => getInternetStatus(),
  };
};
