import { Alert, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { AdminUserCompanyDto, AdminUserDto } from "@/core/api/generated";

interface Props {
  user: AdminUserDto;
  selectedUserProfile: AdminUserDto | AdminUserCompanyDto;
}

export default function OverviewTabContent({ user, selectedUserProfile }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Details"} />

        {!selectedUserProfile.isPersonalInfoComplete && (
          <Alert severity='warning'>Personal info is incomplete!</Alert>
        )}

        <Stack direction={{ xxs: "column", md: "row" }} spacing={2}>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <FieldValue label='First name'>{selectedUserProfile.personName?.firstName}</FieldValue>
            <AuthorizedElement permissions={[]}>
              <FieldValue label='Last name'>{selectedUserProfile.personName?.lastName}</FieldValue>
              <FieldValue label='Email'>{user.email}</FieldValue>
              <FieldValue label='Phone'>{selectedUserProfile.phoneNumber}</FieldValue>
            </AuthorizedElement>
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <AuthorizedElement permissions={[]}>
              <FieldValue label='Status'>
                <InlineApiEnumValue
                  type='UserStatus'
                  value={selectedUserProfile.status}
                  withDescription
                  direction='column'
                />
              </FieldValue>

              <FieldValue label='Customer user?'>
                <BooleanValue value={user.isCustomerUser} />
              </FieldValue>

              <FieldValue label='Time zone'>{selectedUserProfile.settings?.tzId}</FieldValue>

              <FieldValue label='Culture (locale)'>
                {selectedUserProfile.settings?.culture?.displayName} |{" "}
                {selectedUserProfile.settings?.culture?.name}
              </FieldValue>
            </AuthorizedElement>
          </Stack>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}
