import { FilterOperator } from "@/core/api/generated";

import { TypeHelper } from "../helpers/type";
import { FilterFieldType, FilterSpecFieldTypeMeta } from "../ts/filters";
import { FilterCatalog } from "./filterCatalog";
import { FilterFieldOperatorSpec } from "./filterFieldOperatorSpec";

export class FilterFieldSpec {
  public readonly field: string;
  public readonly title: string;
  public readonly fieldType: FilterFieldType;
  public readonly fieldTypeMeta?: FilterSpecFieldTypeMeta;
  public readonly operators: FilterFieldOperatorSpec[];
  public readonly isUseSingleFilter?: boolean;

  constructor(params: {
    field: string;
    title: string;
    fieldType: FilterFieldType;
    /** Info about the field type. Some field types like `Enum` require additional info. */
    fieldTypeMeta?: FilterSpecFieldTypeMeta;
    /** If empty and `isUseDefaultOperators` is `true`, auto-infers operators based on the field type. */
    operators?: FilterFieldOperatorSpec[];
    /** If `true`, auto-infers operators based on the field type. */
    isUseDefaultOperators?: boolean;
    /** If `true`, user can make only one filter for target field */
    isUseSingleFilter?: boolean;
  }) {
    this.field = params.field;
    this.title = params.title;
    this.fieldType = params.fieldType;
    this.fieldTypeMeta = params.fieldTypeMeta;
    this.operators =
      params.operators ??
      (params.isUseDefaultOperators ? FilterCatalog.getOperatorsForFieldSpec(this) : []);
    this.isUseSingleFilter = params.isUseSingleFilter;
    this.validateAndLog();
  }

  public get isValid(): boolean {
    return (
      !TypeHelper.isEmpty(this.field) &&
      !TypeHelper.isEmpty(this.fieldType) &&
      !TypeHelper.isEmpty(this.title) &&
      !TypeHelper.isEmpty(this.operators) &&
      (this.fieldType === FilterFieldType.Enum
        ? !TypeHelper.isEmpty(this.fieldTypeMeta?.enum?.enumName)
        : true) &&
      (this.fieldType === FilterFieldType.ArrayOfEnum
        ? !TypeHelper.isEmpty(this.fieldTypeMeta?.enum?.enumName)
        : true)
    );
  }

  public validateAndLog(): void {
    if (
      this.fieldType === FilterFieldType.Enum &&
      TypeHelper.isEmpty(this.fieldTypeMeta?.enum?.enumName)
    ) {
      console.error(`${FilterFieldType.Enum} field requires field type meta (enum name).`, this);
    }
    if (
      this.fieldType === FilterFieldType.ArrayOfEnum &&
      TypeHelper.isEmpty(this.fieldTypeMeta?.enum?.enumName)
    ) {
      console.error(
        `${FilterFieldType.ArrayOfEnum} field requires field type meta (enum name).`,
        this,
      );
    }
  }

  public getOperator(operator: FilterOperator): FilterFieldOperatorSpec | undefined {
    return this.operators.find((x) => x.operator === operator) || undefined;
  }

  public getOperatorOrThrow(operator: FilterOperator): FilterFieldOperatorSpec {
    const operatorSpec = this.getOperator(operator);
    if (!operatorSpec) {
      throw new Error(`Unable to find operator spec for '${operator}'.`);
    }
    return operatorSpec;
  }

  public getFirstOperator(): FilterFieldOperatorSpec | undefined {
    return this.operators.at(0);
  }

  public getFirstOperatorOrThrow(): FilterFieldOperatorSpec {
    const result = this.getFirstOperator();
    if (!result) {
      throw new Error(`Unable to get first operator spec.`);
    }
    return result;
  }
}
