import GeneralStatusHistoryPaginatedList from "@/common/components/Entity/GeneralStatus/GeneralStatusHistory/ListView/GeneralStatusHistoryPaginatedList";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { EntityType } from "@/core/api/generated";

export interface GeneralStatusHistoryListPageQueryParams extends GeneralQueryParams {
  subjectEntityType?: EntityType | null;
  subjectEntityId?: string | null;
}

export default function GeneralStatusHistoryListPage() {
  const queryParams = useQueryParams<GeneralStatusHistoryListPageQueryParams>();

  return (
    <>
      <GeneralStatusHistoryPaginatedList
        defaultValues={{
          subjectEntityType: queryParams.subjectEntityType || undefined,
          subjectEntityId: queryParams.subjectEntityId || undefined,
        }}
      />
    </>
  );
}
