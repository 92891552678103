import { TenantRequestDto } from "@/core/api/generated";

import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import TenantRequestCreateUpdate, {
  TenantRequestCreateUpdateProps,
} from "./TenantRequestCreateUpdate";
import TenantRequestCreateUpdateModal from "./TenantRequestCreateUpdateModal";
import TenantRequestsAutocomplete, {
  TenantRequestsAutocompleteProps,
} from "./TenantRequestsAutocomplete";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  TenantRequestDto,
  TenantRequestCreateUpdateProps["defaultValues"],
  TenantRequestsAutocompleteProps,
  Pick<TenantRequestCreateUpdateProps, "defaultValues">
>;

export default function SelectOrCreateTenantRequest({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      TenantRequestDto,
      TenantRequestCreateUpdateProps["defaultValues"],
      TenantRequestsAutocompleteProps,
      Pick<TenantRequestCreateUpdateProps, "defaultValues">
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <TenantRequestsAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new company request "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <TenantRequestCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <TenantRequestCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}
