import { useParams } from "react-router-dom";

import FileView from "../components/Entity/File/View/FileView";

export default function FileViewPage() {
  const { fileId } = useParams<{ fileId?: string }>();

  return (
    <>
      <FileView
        fileId={fileId}
        file={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
