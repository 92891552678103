import TextField, { TextFieldProps } from "@mui/material/TextField";
import React, { useCallback } from "react";

interface Props extends Omit<TextFieldProps, "type" | "onChange"> {
  value: number | string;
  onChange: (newValue: React.ChangeEvent<HTMLInputElement>) => void;
  mode?: "numeric" | "decimal";
}

const numericRegex = /^[0-9]*$/;
const decimalRegex = /^[0-9]*\.?[0-9]*$/;

export default function NumberInput({ value, onChange, mode = "numeric", ...props }: Props) {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value.replace(",", ".");

      if (mode === "numeric" && numericRegex.test(input)) {
        const normalizedEvent = {
          ...event,
          target: { ...event.target, value: input },
        };
        onChange(normalizedEvent as React.ChangeEvent<HTMLInputElement>);
      } else if (mode === "decimal" && decimalRegex.test(input)) {
        const normalizedEvent = {
          ...event,
          target: { ...event.target, value: input },
        };
        onChange(normalizedEvent as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [onChange],
  );

  return (
    <TextField
      {...props}
      value={value}
      onChange={handleInputChange}
      inputProps={{
        inputMode: mode,
        pattern: "[0-9]*\\.?[0-9]*", // Hint for browsers with support pattern
      }}
    />
  );
}
