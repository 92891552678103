import { useParams } from "react-router-dom";

import RepairSparePartView from "@/common/components/Entity/RepairSparePart/View/RepairSparePartView";

export default function RepairSparePartViewPage() {
  const { repairSparePartId } = useParams<{ repairSparePartId?: string }>();

  return (
    <>
      <RepairSparePartView
        repairSparePartId={repairSparePartId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
