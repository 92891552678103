import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, SpotDto, SpotReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import SpotInline, { SpotInlineProps } from "./SpotInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: SpotDto | SpotReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<SpotInlineProps>;
}

export default function SpotLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.SpotRead])}
      to={ROUTE_PATH.SPOT_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={<SpotInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Spot"}
    />
  );
}
