import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { AxiosResponse } from "axios";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
  BaseRequestParams,
  EntitySearchResponseData,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { IBaseEntityDto } from "@/core/api/generated";

interface PlaceholderDto extends IBaseEntityDto {
  placeholder?: string;
}

type PlaceHolderAutocompleteOption = BaseAutocompleteOption<PlaceholderDto>;

const entityToOption = (data: PlaceholderDto): PlaceHolderAutocompleteOption => ({
  id: data.id || "",
  title: "",
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface BaseEntityAutocompletePlaceholderProps
  extends BaseEntitySearchAutocompleteInheritableProps<PlaceholderDto> {}

export default function BaseEntityAutocompletePlaceholder({
  ...otherProps
}: BaseEntityAutocompletePlaceholderProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      request={{
        requestFunc: async (params?: BaseRequestParams) =>
          [] as unknown as AxiosResponse<EntitySearchResponseData<PlaceholderDto>>,
        parameters: {},
        combineParameters: (params, newParams) => ({
          ...params,
          ...newParams,
        }),
        deps: [],
      }}
      withCreate={false}
      label={otherProps?.label || "Entity"}
      placeholder='Search for entity...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='data' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
