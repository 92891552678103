import _ from "lodash";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { TenantRequestDto, TenantRequestStage, TenantRequestType } from "@/core/api/generated";

import AppButton, { AppButtonProps } from "../../Button/AppButton";
import AppIconButton from "../../Button/AppIconButton";
import TenantRequestApplyResultModal from "./TenantRequestApplyResultModal";

interface Props {
  tenantRequest: TenantRequestDto;
  /** Either normal or icon buttons are rendered. */
  variant?: "normal" | "compact";
  isForOperation?: boolean;
  buttonProps?: Partial<AppButtonProps>;
  onEntityUpdate?: (newValue: TenantRequestDto) => void;
}

export default function TenantRequestApplyResultControl({
  tenantRequest,
  variant = "normal",
  isForOperation,
  buttonProps,
  onEntityUpdate,
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const isSentByMe = currentTenant?.id === tenantRequest?.senderTenant?.id;
  const isReceivedByMe = currentTenant?.id === tenantRequest?.receiverTenant?.id;
  const isVisible =
    isSentByMe &&
    tenantRequest?.stageHistory?.stage === TenantRequestStage.Closed &&
    (_.isNil(isForOperation) ||
      (isForOperation && tenantRequest.result?.type === TenantRequestType.Operation));

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {isVisible &&
        (variant === "normal" ? (
          <AppButton
            variant='contained'
            color='primary'
            size='small'
            fullWidth
            startIcon={<AppIcon of='apply' />}
            {...buttonProps}
            onClick={() => setIsModalOpen(true)}
          >
            Apply result
          </AppButton>
        ) : (
          <AppIconButton
            variant='outlined'
            color='primary'
            size='small'
            tooltipProps={{ title: "Apply result" }}
            {...buttonProps}
            onClick={() => setIsModalOpen(true)}
          >
            <AppIcon of='apply' />
          </AppIconButton>
        ))}

      <TenantRequestApplyResultModal
        applyProps={{
          tenantRequest: tenantRequest,
          onSave: (newValue) => {
            onEntityUpdate && onEntityUpdate(newValue);
            setIsModalOpen(false);
          },
        }}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
