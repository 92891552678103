import { Alert, Box, Button, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import FoldableBlock, {
  FoldableBlockProps,
  FoldableBlockTriggerProps,
} from "@/common/components/Display/FoldableBlock";
import AppIcon from "@/common/components/Icons/AppIcon";

const exampleContent = (
  <Stack spacing={1}>
    <Alert>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci eaque enim exercitationem
      quaerat aut odit cumque numquam ab. Animi aut neque, ipsum magni distinctio perspiciatis
      obcaecati. Ex quo magni numquam.
    </Alert>

    <Box>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci eaque enim exercitationem
      quaerat aut odit cumque numquam ab. Animi aut neque, ipsum magni distinctio perspiciatis
      obcaecati. Ex quo magni numquam.
    </Box>
  </Stack>
);

export default function DevFoldableBlock() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        FoldableBlock
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          <InlineCode>Default</InlineCode>
        </Typography>

        <Stack spacing={2}>
          <FoldableBlock>{exampleContent}</FoldableBlock>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          <InlineCode>Trigger variants</InlineCode>
        </Typography>

        <Stack spacing={2}>
          {(["contained", "outlined", "text"] as Array<FoldableBlockTriggerProps["variant"]>).map(
            (variant, i) => (
              <FoldableBlock
                key={i}
                trigger={{
                  variant,
                  label: variant,
                }}
              >
                {exampleContent}
              </FoldableBlock>
            ),
          )}
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          <InlineCode>Trigger sizes</InlineCode>
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
          {(["contained", "outlined", "text"] as Array<FoldableBlockTriggerProps["variant"]>).map(
            (variant, i) => (
              <Stack key={i} spacing={2}>
                {(
                  ["extraSmall", "small", "medium", "large"] as Array<
                    FoldableBlockTriggerProps["size"]
                  >
                ).map((size, j) => (
                  <FoldableBlock
                    key={j}
                    trigger={{
                      variant,
                      size,
                      label: size,
                    }}
                  >
                    {exampleContent}
                  </FoldableBlock>
                ))}
              </Stack>
            ),
          )}
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          <InlineCode>Trigger colors</InlineCode>
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
          {(["contained", "outlined", "text"] as Array<FoldableBlockTriggerProps["variant"]>).map(
            (variant, i) => (
              <Stack key={i} spacing={2}>
                {(
                  [
                    "inherit",
                    "primary",
                    "secondary",
                    // "success",
                    // "error",
                    // "info",
                    // "warning",
                  ] as Array<FoldableBlockTriggerProps["color"]>
                ).map((color, j) => (
                  <FoldableBlock
                    key={j}
                    trigger={{
                      variant,
                      color,
                      label: color,
                    }}
                  >
                    {exampleContent}
                  </FoldableBlock>
                ))}
              </Stack>
            ),
          )}
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          <InlineCode>Trigger icon</InlineCode>
        </Typography>

        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
          {(["contained", "outlined", "text"] as Array<FoldableBlockTriggerProps["variant"]>).map(
            (variant, i) => (
              <Stack key={i} spacing={2}>
                {(
                  ["extraSmall", "small", "medium", "large"] as Array<
                    FoldableBlockTriggerProps["size"]
                  >
                ).map((size, j) => (
                  <FoldableBlock
                    key={j}
                    trigger={{
                      variant,
                      size,
                      label: size,
                      icon: <AppIcon of='user' />,
                    }}
                  >
                    {exampleContent}
                  </FoldableBlock>
                ))}
              </Stack>
            ),
          )}
        </Box>
      </Stack>

      {/* <Stack spacing={2}>
        <Stack spacing={2}>
          <Box>
            <Typography component='div' variant='subtitle1'>
              <InlineCode>Trigger variants</InlineCode>
            </Typography>
            <Stack spacing={2}>
              <Stack
                direction='row'
                spacing={4}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <InlineCode>button</InlineCode>
                <FoldableBlock triggerVariant='button' titleContent={<>Title content</>}>
                  Foldable content
                </FoldableBlock>
              </Stack>
              <Stack
                direction='row'
                spacing={4}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <InlineCode>iconButton</InlineCode>
                <FoldableBlock triggerVariant='iconButton' titleContent={<>Title content</>}>
                  Foldable content
                </FoldableBlock>
              </Stack>
              <Stack
                direction='row'
                spacing={4}
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <InlineCode>icon</InlineCode>
                <FoldableBlock triggerVariant='icon' titleContent={<>Title content</>}>
                  Foldable content
                </FoldableBlock>
              </Stack>
            </Stack>
          </Box>

          <Box>
            <Typography component='div' variant='subtitle1'>
              <InlineCode>Trigger placement</InlineCode>
            </Typography>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
              <Stack spacing={2}>
                <Typography component='div' variant='subtitle1'>
                  Vertical
                </Typography>

                <FoldableBlock triggerLabel='Default'>Foldable content</FoldableBlock>

                <FoldableBlock triggerLabel='Trigger at the start' triggerVerticalPlacement='start'>
                  Foldable content
                </FoldableBlock>

                <FoldableBlock triggerLabel='Trigger at the end' triggerVerticalPlacement='end'>
                  Foldable content
                </FoldableBlock>

                <FoldableBlock
                  triggerLabel='Trigger at the startAndEnd'
                  triggerVerticalPlacement='startAndEnd'
                >
                  Foldable content
                </FoldableBlock>
              </Stack>

              <Stack spacing={2}>
                <Typography component='div' variant='subtitle1'>
                  Horizontal
                </Typography>

                <FoldableBlock triggerLabel='Default'>Foldable content</FoldableBlock>

                <FoldableBlock
                  triggerLabel='Trigger at the start'
                  triggerHorizontalPlacement='start'
                >
                  Foldable content
                </FoldableBlock>

                <FoldableBlock triggerLabel='Trigger at the end' triggerHorizontalPlacement='end'>
                  Foldable content
                </FoldableBlock>
              </Stack>
            </Box>
          </Box>

          <FoldableBlock trigger={<Button>Custom trigger</Button>}>Foldable content</FoldableBlock>
        </Stack>
      </Stack> */}
    </Stack>
  );
}
