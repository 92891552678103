import { Box, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import PercentValue from "@/common/components/Form/Display/PercentValue";
import { PriceHelper } from "@/common/helpers/price";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { enumService } from "@/common/services/enum";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralDiscountDto,
  GeneralDiscountInputDto,
} from "@/core/api/generated";

import CurrencyValue from "../../../Form/Display/CurrencyValue";

interface Props {
  forValue: {
    subTotal: number;
  };
  discount: GeneralDiscountDto | GeneralDiscountInputDto | null | undefined;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  sx?: SxProps<Theme>;
}

export default function GeneralDiscountExplanation({
  forValue,
  discount,
  currency,
  currencyCode,
  sx,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const currencyComputed: GeneralCurrencyDto | undefined =
    currency || (currencyCode && { code: currencyCode }) || discount?.currency || currentCurrency;

  const explanation = useMemo(() => {
    return forValue && discount
      ? PriceHelper.getDiscountExplanation({ forValue, discount, currency: currencyComputed })
      : undefined;
  }, [forValue, discount]);

  return (
    <Stack sx={sx}>
      {discount && (
        <Box component='span'>
          {enumService.getEnumValueName("DiscountType", discount.type)} discount
        </Box>
      )}

      {/* calced value */}
      {explanation && (
        <Box component='span'>
          <CurrencyValue value={explanation.subTotal} currency={explanation.currency} /> -{" "}
          <PercentValue value={explanation.discountPercent} /> /{" "}
          <CurrencyValue value={explanation.discountValue} currency={explanation.currency} />{" "}
          (discount) ={" "}
          <CurrencyValue value={explanation.subTotalIncDiscount} currency={explanation.currency} />
        </Box>
      )}
    </Stack>
  );
}
