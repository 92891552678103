import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { useMemo } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

interface Props {
  latitude: number;
  longitude: number;
}

export default function GeolocationMap({ latitude, longitude }: Props) {
  const defaultIcon = useMemo(() => L.icon({ iconUrl: markerIcon }), []);
  return (
    <MapContainer
      center={[latitude, longitude]}
      zoom={13}
      scrollWheelZoom={true}
      // Important! Make sure to set the height and width of the MapContainer otherwise the map won’t render
      style={{ height: "500px", width: "1000px" }}
    >
      <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
      <Marker position={[latitude, longitude]} icon={defaultIcon} />
    </MapContainer>
  );
}
