import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { ProfileDto } from "@/core/api/generated";

interface Props {
  profile: ProfileDto;
}

export default function PersonalInfoTabContent({ profile }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={1}>
        <Stack spacing={1}>
          <FieldValue label='Time zone'>{profile?.settings?.tzId || ""}</FieldValue>
          <FieldValue label='Culture'>{profile?.settings?.culture?.name || ""}</FieldValue>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}
