import { Box } from "@mui/material";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { apiClient } from "@/core/api/ApiClient";
import { UserStatus } from "@/core/api/generated";
import * as profileSlice from "@/store/profile/slice";

export type CheckUserStatusQueryParams = GeneralQueryParams;

export default function CheckUserStatus() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { ...generalQueryParams } = useQueryParams<CheckUserStatusQueryParams>();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  useEffect(() => {
    (async () => {
      try {
        const data = await thunkDispatch(profileSlice.getProfile());
        const { data: inviteInfo } = await apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet({
          inviteId: inviteId,
        });
        const redirectUrl = inviteInfo.redirectUrl || generalQueryParams.redirectUrl;

        if (data.status === UserStatus.InfoIncomplete) {
          history.push(
            ROUTE_PATH.AUTH_COMPLETE_INFO({
              ...generalQueryParams,
              redirectUrl,
            }),
          );
          return;
        } else if (redirectUrl) {
          UrlHelper.redirectToUrl(redirectUrl);
          return;
        }
        history.push("/");
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return <Box>Checking user status...</Box>;
}
