import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, FormHelperText, IconButton, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";

import FileUploadList from "./FileUploadList";
import FileUploadWrappingArea from "./FileUploadWrappingArea";
import {
  FileUploaderProps,
  UploadFilesFunc,
  UploadFilesFuncRequestParameters,
  defaultMaxFiles,
} from "./FileUploader";

interface Props extends Pick<FileUploaderProps, "defaultFiles" | "onChange" | "maxFiles"> {
  chatId: string;
  children: (params: {
    uploadedFiles: FileItem[];
    renderUploadButton: () => React.ReactNode;
    renderUploadList: () => React.ReactNode;
    getUploadedFiles: () => FileItem[];
    handlePastedFiles: (newFiles: File[]) => void;
    clearUploads: () => void;
  }) => React.ReactNode;
}

/** File upload used in chats only. */
function ChatFileUploader({ defaultFiles = [], onChange, maxFiles, chatId, children }: Props) {
  maxFiles = Math.min(maxFiles || 999, defaultMaxFiles);

  const [files, setFiles] = useState<FileItem[]>(defaultFiles || []);
  const [uploadValidation, setUploadValidation] = useState<ValidationInfo | undefined>(undefined);

  const uploadedFiles = useMemo(() => files.filter((x) => !!x.file), [files]);

  const uploadFilesFunc: UploadFilesFunc = async (
    requestParameters: UploadFilesFuncRequestParameters,
  ) => {
    const response = await apiClient.filesApi.apiV1FilesChatsChatIdAttachmentsUploadPost({
      chatId,
      ...requestParameters,
    });
    if (response.request.status !== 200) {
      throw response;
    }
    return response.data;
  };
  const canUploadCount = Math.abs(maxFiles - files.length);
  const canUploadMore = files.length < maxFiles;

  useEffect(() => {
    onChange && onChange(files.filter((x) => !!x.file));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleFileUpload = async (fls: File[]) => {
    const filesToUpload = fls
      .filter((x) => !files.some((y) => y.fileName === x.name))
      .slice(0, canUploadCount);
    if (filesToUpload.length == 0) {
      return;
    }

    const newFiles = [
      ...files,
      ...FileItem.createManyFrom(filesToUpload.slice(0, canUploadCount), true),
    ];
    setFiles(newFiles);
    setUploadValidation(undefined);

    try {
      const uploadResult = await uploadFilesFunc({ files: filesToUpload });

      const newFiles2 = newFiles.map((x) => {
        const uploadedFile = uploadResult.files!.find((y) => y.originalFileName === x.fileName);
        if (uploadedFile) {
          x.setUploadedFile(uploadedFile);
        }
        return x;
      });
      setFiles(newFiles2);
    } catch (err) {
      const validation = ValidationHelper.handleApiErrorResponse(err);
      console.error("File upload error:", err, validation);

      const newFiles2 = newFiles.map((x) => {
        if (filesToUpload.some((y) => y.name === x.fileName)) {
          x.isUploading = false;
        }
        return x;
      });
      setFiles(newFiles2);
      setUploadValidation(validation);
    }
  };

  const handlePastedFiles = (newFiles: File[]) => {
    handleFileUpload(newFiles);
  };

  const handleRemove = (file: FileItem) => {
    setFiles(files.filter((f) => f.id !== file.id));
  };

  const clearUploads = () => {
    setFiles([]);
    setUploadValidation(undefined);
  };

  return (
    <Box>
      <FileUploadWrappingArea
        multiple
        accept={undefined}
        disabled={!canUploadMore}
        maxFiles={maxFiles}
        onChange={handleFileUpload}
      >
        {({ triggerFileInput }) => (
          <>
            {children({
              uploadedFiles,
              renderUploadButton: () => (
                <Tooltip title='Attach files'>
                  <IconButton size='small' onClick={triggerFileInput}>
                    <AttachFileIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              ),
              renderUploadList: () => (
                <FileUploadList
                  files={files}
                  itemActions={(item) => ({
                    enabled: true,
                    remove: true,
                    onRemove: () => handleRemove(item),
                  })}
                />
              ),
              handlePastedFiles: handlePastedFiles,
              getUploadedFiles: () => files.filter((x) => !!x.file),
              clearUploads: clearUploads,
            })}
          </>
        )}
      </FileUploadWrappingArea>

      {uploadValidation && uploadValidation.hasErrors && (
        <Box sx={{ p: 1 }}>
          <FormHelperText sx={{ m: 0, p: 0 }} error>
            {uploadValidation.generalError}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}

export default ChatFileUploader;
