import {
  CookieChangedCustomEvent,
  CookieChangedCustomEventDetail,
} from "../events/CookieChangedCustomEvent";
import { CookieHelper } from "./cookie";

/** Define custom typing for built-in CookieChangeEvent because Cookie Store API doesn't have TS typings yet */
interface CustomCookieChangeEvent {
  isTrusted: boolean;
  bubbles: boolean;
  cancelBubble: boolean;
  cancelable: boolean;
  changed: CustomCookieChangeEventCookie[];
  composed: boolean;
  currentTarget: any;
  defaultPrevented: boolean;
  deleted: CustomCookieChangeEventCookie[];
  eventPhase: number;
  path: any[];
  returnValue: boolean;
  srcElement: any;
  target: any;
  timeStamp: number;
  type: string;
}
interface CustomCookieChangeEventCookie {
  domain: string | null;
  name: string;
  path: string;
  sameSite?: string;
  secure: boolean;
  value?: string;
}

/** Listens for cookie chnages on current document */
export class CookieListener {
  private static isListening = false;
  private static lastCookie = "";

  static customCookieChangeEventName = "customcookiechange";

  /** Starts listening for changes to document.cookie.
   * Dispatches 'customcookiechange' event of type CookieChangedCustomEvent.
   * Listen for events: document.addEventListener('customcookiechange', (e: Event) => { });
   */
  static listenCookieChanges(): void {
    if (this.isListening) {
      return;
    }

    // try to use Cookie Store API first (which is not supported by all browsers),
    // and fallback to Polling + API Interception.
    // See: https://caniuse.com/?search=cookie%20store%20api
    try {
      // Cookie Store API react on every cookie change (even Set-Cookie: header)
      this.listenCookieChangesViaCookieStoreApi();
    } catch (err: any) {
      // NB: APi interception approach can't track cookie changed made via header Set-Cookie:,
      // it react only on changing document.cookie,
      // that why we need to use Polling in addition
      console.log("Fallback to Polling + API Interception. Error:", err.message);
      this.listenCookieChangesViaApiInterception();
      this.listenCookieChangesViaPolling();
    }
    this.isListening = true;
  }

  private static listenCookieChangesViaCookieStoreApi() {
    if (this.isListening) {
      return;
    }

    const cookieStore: any = (window as any).cookieStore; // no TS typings so use 'as any'
    if (!cookieStore) {
      throw new Error("Cookie Store API is not supported by the browser.");
    }

    this.lastCookie = document.cookie;

    cookieStore.addEventListener("change", (e: CustomCookieChangeEvent) => {
      const cookie = document.cookie;
      if (cookie !== this.lastCookie) {
        try {
          this.handleCookieChange(this.lastCookie, cookie);
        } finally {
          this.lastCookie = cookie;
        }
      }
    });
  }

  private static listenCookieChangesViaPolling(intervalMs = 10000) {
    if (this.isListening) {
      return;
    }

    this.lastCookie = document.cookie;

    setInterval(() => {
      const cookie = document.cookie;
      if (cookie !== this.lastCookie) {
        try {
          this.handleCookieChange(this.lastCookie, cookie);
        } finally {
          this.lastCookie = cookie;
        }
      }
    }, intervalMs);
  }

  private static listenCookieChangesViaApiInterception() {
    if (this.isListening) {
      return;
    }

    const expando = "_cookie";
    if ((document as any)[expando]) {
      return;
    }

    this.lastCookie = document.cookie;

    // rename document.cookie to document._cookie, and redefine document.cookie
    const self = this;
    const nativeCookieDesc = Object.getOwnPropertyDescriptor(Document.prototype, "cookie");
    Object.defineProperty(Document.prototype, expando, nativeCookieDesc!);
    Object.defineProperty(Document.prototype, "cookie", {
      enumerable: true,
      configurable: true,
      get() {
        // this the document object
        return this[expando];
      },
      set(value) {
        this[expando] = value;

        // check cookie change
        const cookie = this[expando];
        if (cookie !== self.lastCookie) {
          try {
            self.handleCookieChange(self.lastCookie, cookie);
          } finally {
            self.lastCookie = cookie;
          }
        }
      },
    });
  }

  private static handleCookieChange(oldCookie: string, newCookie: string) {
    // build list of changed cookies and emit event for each of them separately
    const oldCookies = CookieHelper.getAll(oldCookie);
    const newCookies = CookieHelper.getAll(newCookie);
    const oldCookieNames = Object.keys(oldCookies);
    const newCookieNames = Object.keys(newCookies);

    const addedCookieNames = newCookieNames.filter((x) => !oldCookieNames.includes(x));
    const deletedCookieNames = oldCookieNames.filter((x) => !newCookieNames.includes(x));
    const updatedCookieNames = oldCookieNames.filter(
      (x) =>
        !addedCookieNames.includes(x) &&
        !deletedCookieNames.includes(x) &&
        CookieHelper.get(x, newCookie) !== CookieHelper.get(x, oldCookie),
    );

    // dispatch cookie-change events
    for (const cookieName of addedCookieNames) {
      document.dispatchEvent(
        new CookieChangedCustomEvent(this.customCookieChangeEventName, {
          detail: {
            name: cookieName,
            oldValue: undefined,
            newValue: newCookies[cookieName],
            status: "added",
          },
        }),
      );
    }
    for (const cookieName of deletedCookieNames) {
      document.dispatchEvent(
        new CookieChangedCustomEvent(this.customCookieChangeEventName, {
          detail: {
            name: cookieName,
            oldValue: oldCookies[cookieName],
            newValue: undefined,
            status: "deleted",
          },
        }),
      );
    }
    for (const cookieName of updatedCookieNames) {
      document.dispatchEvent(
        new CookieChangedCustomEvent(this.customCookieChangeEventName, {
          detail: {
            name: cookieName,
            oldValue: oldCookies[cookieName],
            newValue: newCookies[cookieName],
            status: "updated",
          },
        }),
      );
    }
  }

  static onCookieChange(
    name: string,
    callback: (detail: CookieChangedCustomEventDetail, e?: CookieChangedCustomEvent) => void,
  ): void {
    document.addEventListener(this.customCookieChangeEventName, (e: Event) => {
      const customEvent = e as CookieChangedCustomEvent;
      if (customEvent && customEvent.detail.name === name) {
        callback(customEvent.detail, customEvent);
      }
    });
  }
}
