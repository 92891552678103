import _ from "lodash";

import {
  ChatMessageNodeDto,
  ChatMessageNodeType,
  ChatMessageRootNodeDto,
} from "@/core/api/generated";

import { ChatMessageNodeBuilder } from "../../builders/chatMessageNode";
import { StringHelper } from "../string";

interface NodeIntersectionWithSelection extends CustomCursorSelection {
  isIntersects: boolean;
  isFullyIntersects: boolean;
  isPartiallyIntersects: boolean;
  isIntersectsInTheStart: boolean;
  isIntersectsInTheEnd: boolean;
  isIntersectsInTheMiddle: boolean;
}

class RootNodeTextChangeResult {
  addedNodes: ChatMessageNodeDto[];
  deletedNodes: ChatMessageNodeDto[];
  updatedNodes: ChatMessageNodeDto[];

  constructor() {
    this.addedNodes = [];
    this.deletedNodes = [];
    this.updatedNodes = [];
  }

  public get isHandled(): boolean {
    return (
      this.addedNodes.length !== 0 ||
      this.deletedNodes.length !== 0 ||
      this.updatedNodes.length !== 0 ||
      false
    );
  }

  public mergeWith(other: RootNodeTextChangeResult) {
    this.addedNodes = _.uniqBy([...this.addedNodes, ...other.addedNodes], (x) => x.id);
    this.deletedNodes = _.uniqBy([...this.deletedNodes, ...other.deletedNodes], (x) => x.id);
    this.updatedNodes = _.uniqBy([...this.updatedNodes, ...other.updatedNodes], (x) => x.id);
  }
}

export class ChatMessageHelper {
  public static detectTextChangeFromSelections(
    oldSelection: CustomCursorSelection,
    newSelection: CustomCursorSelection,
  ) {
    const isTextEntered = newSelection.endIndex! > oldSelection.startIndex!;
    const isTextRangeEntered =
      isTextEntered && Math.abs(newSelection.endIndex! - oldSelection.endIndex!) > 1;
    const isTextDeleted = newSelection.startIndex! < oldSelection.endIndex!;
    const isTextRangeWasSelected = oldSelection.startIndex! < oldSelection.endIndex!;

    const enteredTextLength = isTextEntered
      ? Math.abs(newSelection.endIndex! - oldSelection.endIndex!)
      : 0;

    return {
      isTextEntered,
      isTextRangeEntered,
      isTextDeleted,
      isTextRangeWasSelected,
      enteredTextLength,
    };
  }

  /** Builds text from all nodes' text. */
  public static buildRootNodeFullText(rootNode: ChatMessageRootNodeDto): string {
    return rootNode.nodes!.map((x) => x.text!.text).join("");
  }

  public static handleTextEntered({
    rootNode,
    oldSelection,
    newSelection,
    change,
    insertTextNodeIfNone = true,
    onlyForNodeTypes = undefined, // any by default
    updateTextOnlyForNodeTypes, // any by default
  }: {
    rootNode: ChatMessageRootNodeDto;
    oldSelection: CustomCursorSelection;
    newSelection: CustomCursorSelection;
    change: {
      value: string;
    };
    insertTextNodeIfNone?: boolean;
    onlyForNodeTypes?: ChatMessageNodeType[];
    updateTextOnlyForNodeTypes?: ChatMessageNodeType[];
  }): RootNodeTextChangeResult {
    const result = new RootNodeTextChangeResult();

    // Note:
    // when text is entered in between nodes we consider it's entered for prev node:
    // [node1][entered text][node2] -> abc[entered 'd' here is added to 'abc']123
    const selection: CustomCursorSelection = {
      startIndex: oldSelection.startIndex,
      endIndex: newSelection.endIndex,
    };
    const searchSelection: CustomCursorSelection = {
      startIndex: Math.max(oldSelection.startIndex! - 1, 0),
      endIndex: oldSelection.endIndex,
    };
    const enteredText = change.value.substring(selection.startIndex!, selection.endIndex!);
    const indexShift = enteredText.length;

    const intersectionNodes = rootNode.nodes!.filter(
      (node) => this.getNodeIntersectionWithSelection(node, searchSelection).isIntersects,
    );
    const affectedNodes = intersectionNodes.filter(
      (node) =>
        (onlyForNodeTypes ? onlyForNodeTypes.includes(node.type!) : true) &&
        (updateTextOnlyForNodeTypes ? updateTextOnlyForNodeTypes.includes(node.type!) : true),
    );
    const nodesToUpdate = affectedNodes;

    // console.log("add1", {
    //   rootNode: _.cloneDeep(rootNode),
    //   oldSelection,
    //   newSelection,
    //   change,
    //   selection,
    //   searchSelection,
    //   enteredText,
    //   intersectionNodes: _.cloneDeep(intersectionNodes),
    //   nodesToUpdate: _.cloneDeep(nodesToUpdate),
    // });

    if (nodesToUpdate.length === 0) {
      const insertSelection = {
        startIndex: oldSelection.startIndex!,
        endIndex: oldSelection.startIndex! + enteredText.length,
      };
      const canInsert =
        insertTextNodeIfNone &&
        !intersectionNodes.some(
          (node) => this.getNodeIntersectionWithSelection(node, insertSelection).isIntersects,
        );
      if (canInsert) {
        const nodeToUpdate = ChatMessageNodeBuilder.newTextNode()
          .withIndexRange({
            startIndex: insertSelection.startIndex!,
            endIndex: insertSelection.endIndex!,
          })
          .withText({
            text: enteredText,
          })
          .build();
        nodesToUpdate.push(nodeToUpdate);
        rootNode.nodes!.push(nodeToUpdate);
        ChatMessageHelper.sortNodes(rootNode);
        result.addedNodes!.push(nodeToUpdate);

        // shift indexes for next nodes
        const isFirst = nodeToUpdate.startIndex === 0;
        this.normalizeNodeIndexes(rootNode, nodeToUpdate, "next", indexShift, isFirst);
      }
    } else {
      nodesToUpdate.forEach((nodeToUpdate) => {
        const intersection = this.getNodeIntersectionWithSelection(nodeToUpdate, searchSelection);
        const textNodeTextIntersection = this.getTextNodeTextIntersectionWithSelection(
          nodeToUpdate,
          intersection,
        );
        const insertAt = textNodeTextIntersection.startIndex! + 1;

        nodeToUpdate.text!.text = StringHelper.insertAt(
          nodeToUpdate.text!.text!,
          insertAt,
          enteredText,
        );
        nodeToUpdate.endIndex! += enteredText.length;
        result.updatedNodes!.push(nodeToUpdate);

        // shift indexes for next nodes
        this.normalizeNodeIndexes(rootNode, nodeToUpdate, "next", indexShift);
      });
    }

    // console.log("add2", {
    //   rootNode,
    //   nodesToUpdate,
    //   indexShift,
    // });

    return result;
  }

  public static handleTextDeleted({
    rootNode,
    oldSelection,
    newSelection,
    change,
    onlyForNodeTypes, // any by default
    updateTextOnlyForNodeTypes, // any by default
    deleteOnlyNodeTypes, // any by default
    rightAwayDeleteNodeTypesExcept, // none by default
  }: {
    rootNode: ChatMessageRootNodeDto;
    oldSelection: CustomCursorSelection;
    newSelection: CustomCursorSelection;
    change: {
      value: string;
    };
    onlyForNodeTypes?: ChatMessageNodeType[];
    updateTextOnlyForNodeTypes?: ChatMessageNodeType[];
    deleteOnlyNodeTypes?: ChatMessageNodeType[];
    rightAwayDeleteNodeTypesExcept?: ChatMessageNodeType[];
  }): RootNodeTextChangeResult {
    const result = new RootNodeTextChangeResult();

    const deleteSelection: CustomCursorSelection = {
      startIndex: Math.min(oldSelection.startIndex!, newSelection.startIndex!),
      endIndex: Math.max(oldSelection.endIndex!, newSelection.endIndex!),
    };
    const affectedNodes = rootNode.nodes!.filter(
      (node) =>
        (onlyForNodeTypes ? onlyForNodeTypes.includes(node.type!) : true) &&
        this.getNodeIntersectionWithSelection(node, deleteSelection).isIntersects,
    );

    const nodesToDelete = [
      // delete nodes with full intersection
      ...affectedNodes.filter(
        (node) =>
          this.getNodeIntersectionWithSelection(node, deleteSelection).isFullyIntersects &&
          (deleteOnlyNodeTypes ? deleteOnlyNodeTypes.includes(node.type!) : true),
      ),

      // right away delete nodes with partial intersection
      ...affectedNodes.filter((x) =>
        rightAwayDeleteNodeTypesExcept ? !rightAwayDeleteNodeTypesExcept.includes(x.type!) : false,
      ),
    ];
    nodesToDelete.forEach((nodeToDelete) => {
      const index = rootNode.nodes!.findIndex((x) => x.id === nodeToDelete.id);
      if (index === -1) {
        return;
      }

      rootNode.nodes!.splice(index, 1);
      result.deletedNodes.push(nodeToDelete);

      // shift indexes for next nodes
      const indexShift = -this.getNodeIndexRange(nodeToDelete).length;
      this.normalizeNodeIndexes(rootNode, nodeToDelete, "next", indexShift);
    });

    // update text for nodes with partial intersection
    const nodesToUpdate = affectedNodes.filter(
      (node) =>
        this.getNodeIntersectionWithSelection(node, deleteSelection).isPartiallyIntersects &&
        (updateTextOnlyForNodeTypes ? updateTextOnlyForNodeTypes.includes(node.type!) : true),
    );
    nodesToUpdate.forEach((nodeToUpdate) => {
      const intersection = this.getNodeIntersectionWithSelection(nodeToUpdate, deleteSelection);
      const textNodeTextIntersection = this.getTextNodeTextIntersectionWithSelection(
        nodeToUpdate,
        intersection,
      );
      const charRemoveCount = Math.abs(
        textNodeTextIntersection.endIndex! - textNodeTextIntersection.startIndex!,
      );

      nodeToUpdate.text!.text = StringHelper.replaceAtRange(
        nodeToUpdate.text!.text!,
        textNodeTextIntersection.startIndex!,
        textNodeTextIntersection.endIndex!,
        "",
      );
      nodeToUpdate.endIndex = Math.max(
        nodeToUpdate.endIndex! - charRemoveCount,
        nodeToUpdate.startIndex!,
      );

      // shift indexes for next nodes
      const indexShift = -charRemoveCount;
      this.normalizeNodeIndexes(rootNode, nodeToUpdate, "next", indexShift);
    });
    result.updatedNodes = [...result.updatedNodes!, ...nodesToUpdate];

    // console.log("delete2", {
    //   oldSelection,
    //   newSelection,
    //   deleteSelection,
    //   affectedNodes,
    //   nodesToUpdate,
    //   rootNode,
    //   result,
    // });

    return result;
  }

  public static handleWebLinkNodeChange({
    rootNode,
    webLinkNode,
    oldSelection,
    newSelection,
    change,
  }: {
    rootNode: ChatMessageRootNodeDto;
    webLinkNode?: ChatMessageNodeDto | null;
    oldSelection: CustomCursorSelection;
    newSelection: CustomCursorSelection;
    change: {
      value: string;
    };
  }): RootNodeTextChangeResult {
    const result = new RootNodeTextChangeResult();

    if (!webLinkNode) {
      return result;
    }

    const { isTextEntered, isTextDeleted } = this.detectTextChangeFromSelections(
      oldSelection,
      newSelection,
    );

    if (isTextDeleted) {
      const result2 = this.handleTextDeleted({
        rootNode,
        oldSelection,
        newSelection,
        change,
        onlyForNodeTypes: [ChatMessageNodeType.WebLink],
        updateTextOnlyForNodeTypes: [ChatMessageNodeType.WebLink],
        deleteOnlyNodeTypes: [ChatMessageNodeType.WebLink],
        rightAwayDeleteNodeTypesExcept: undefined,
      });
      result.mergeWith(result2);
    }

    if (isTextEntered) {
      const result2 = this.handleTextEntered({
        rootNode,
        oldSelection,
        newSelection,
        change,
        insertTextNodeIfNone: false,
        onlyForNodeTypes: [ChatMessageNodeType.WebLink],
        updateTextOnlyForNodeTypes: [ChatMessageNodeType.WebLink],
      });
      result.mergeWith(result2);
    }

    return result;
  }

  public static handleTagChange({
    rootNode,
    tagNodeId,
    oldSelection,
    newSelection,
    change,
  }: {
    rootNode: ChatMessageRootNodeDto;
    tagNodeId?: string | null;
    oldSelection: CustomCursorSelection;
    newSelection: CustomCursorSelection;
    change: {
      value: string;
    };
  }): RootNodeTextChangeResult {
    const result = new RootNodeTextChangeResult();

    const tagNode = rootNode.nodes!.find((x) => x.id === tagNodeId);
    if (!tagNode) {
      return result;
    }

    const { isTextEntered, isTextDeleted } = this.detectTextChangeFromSelections(
      oldSelection,
      newSelection,
    );

    if (isTextDeleted) {
      const result2 = this.handleTextDeleted({
        rootNode,
        oldSelection,
        newSelection,
        change,
        onlyForNodeTypes: [ChatMessageNodeType.Tag],
        updateTextOnlyForNodeTypes: [ChatMessageNodeType.Tag],
        deleteOnlyNodeTypes: [ChatMessageNodeType.Tag],
        rightAwayDeleteNodeTypesExcept: undefined,
      });
      result.mergeWith(result2);
    }

    if (isTextEntered) {
      const result2 = this.handleTextEntered({
        rootNode,
        oldSelection,
        newSelection,
        change,
        insertTextNodeIfNone: false,
        onlyForNodeTypes: [ChatMessageNodeType.Tag],
        updateTextOnlyForNodeTypes: [ChatMessageNodeType.Tag],
      });
      result.mergeWith(result2);
    }

    return result;
  }

  /** Handles text input change and mirrors changes into root node. */
  public static handleTextChange({
    rootNode,
    oldSelection,
    newSelection,
    change,
    onlyForNodeTypes,
    updateTextOnlyForNodeTypes = [ChatMessageNodeType.Text],
    deleteOnlyNodeTypes = undefined, // any by default
    rightAwayDeleteNodeTypesExcept = [ChatMessageNodeType.Text],
  }: {
    rootNode: ChatMessageRootNodeDto;
    oldSelection: CustomCursorSelection;
    newSelection: CustomCursorSelection;
    change: {
      value: string;
    };
    onlyForNodeTypes?: ChatMessageNodeType[];
    updateTextOnlyForNodeTypes?: ChatMessageNodeType[];
    deleteOnlyNodeTypes?: ChatMessageNodeType[];
    rightAwayDeleteNodeTypesExcept?: ChatMessageNodeType[];
  }): RootNodeTextChangeResult {
    const result = new RootNodeTextChangeResult();

    const { isTextEntered, isTextDeleted } = this.detectTextChangeFromSelections(
      oldSelection,
      newSelection,
    );

    // when range selected and value is entered/deleted, we actually delete selection first
    if (isTextDeleted) {
      const result2 = this.handleTextDeleted({
        rootNode,
        oldSelection,
        newSelection,
        change,
        onlyForNodeTypes,
        updateTextOnlyForNodeTypes,
        deleteOnlyNodeTypes,
        rightAwayDeleteNodeTypesExcept,
      });
      result.mergeWith(result2);
    }

    if (isTextEntered) {
      const result2 = this.handleTextEntered({
        rootNode,
        oldSelection,
        newSelection,
        change,
        onlyForNodeTypes,
        insertTextNodeIfNone: true,
        updateTextOnlyForNodeTypes,
      });
      result.mergeWith(result2);
    }

    return result;
  }

  public static cloneRootNode(rootNode: ChatMessageRootNodeDto): ChatMessageRootNodeDto {
    return _.cloneDeep(rootNode);
  }

  public static cloneNode(node: ChatMessageNodeDto): ChatMessageRootNodeDto {
    return _.cloneDeep(node);
  }

  public static normalizeNodeIndexes(
    rootNode: ChatMessageRootNodeDto,
    anchorNode: ChatMessageNodeDto,
    targetNodesDirection: "prev" | "next" | "prevAndNext",
    indexShift: number,
    including = false,
  ) {
    if (indexShift === 0) {
      return;
    }

    const targetNodes = [
      ...(targetNodesDirection === "prev" || targetNodesDirection === "prevAndNext"
        ? this.getPrevNodesByStartIndex(rootNode, anchorNode, including)
        : []),
      ...(targetNodesDirection === "next" || targetNodesDirection === "prevAndNext"
        ? this.getNextNodesByStartIndex(rootNode, anchorNode, including)
        : []),
    ];

    targetNodes.forEach((x) => {
      x.startIndex = Math.max(x.startIndex! + indexShift, 0);
      x.endIndex = Math.max(x.endIndex! + indexShift, 0);
    });

    this.sortNodes(rootNode);
  }

  public static replaceNode(
    rootNode: ChatMessageRootNodeDto,
    oldNodeId: string,
    newNode: ChatMessageNodeDto,
  ): void {
    if (_.isNil(newNode.text?.text)) {
      throw new Error("Node text must be set.");
    }

    const index = rootNode.nodes!.findIndex((x) => x.id === oldNodeId);
    if (index !== -1) {
      const oldNode = rootNode.nodes![index];

      const oldNodeIndexRange = this.getNodeIndexRange(oldNode);
      const newNodeIndexRange = this.getNodeIndexRange(newNode);
      const indexShift = newNodeIndexRange.length - oldNodeIndexRange.length; // positive/negative
      this.normalizeNodeIndexes(rootNode, oldNode, "next", indexShift);

      rootNode.nodes!.splice(index, 1, newNode);
    }
  }

  public static insertNode(rootNode: ChatMessageRootNodeDto, newNode: ChatMessageNodeDto): void {
    if (_.isNil(newNode.text?.text)) {
      throw new Error("Node text must be set.");
    }

    rootNode.nodes!.push(newNode);
    ChatMessageHelper.sortNodes(rootNode);
  }

  public static insertNodeAfter(
    rootNode: ChatMessageRootNodeDto,
    afterNodeId: string,
    newNode: ChatMessageNodeDto,
  ): void {
    if (_.isNil(newNode.text?.text)) {
      throw new Error("Node text must be set.");
    }

    const afterNodeIndex = rootNode.nodes!.findIndex((x) => x.id === afterNodeId);
    if (afterNodeIndex === -1) {
      throw new Error("After node not found.");
    }

    const afterNode = rootNode.nodes![afterNodeIndex];
    const newNodeLength = newNode.text!.text!.length;
    newNode.startIndex = afterNode.endIndex!;
    newNode.endIndex = afterNode.endIndex! + newNodeLength;

    // shift indexes for next nodes
    const indexShift = newNodeLength;
    this.normalizeNodeIndexes(rootNode, afterNode, "next", indexShift);

    rootNode.nodes!.splice(afterNodeIndex + 1, 0, newNode);
  }

  public static insertNodeAtTheEnd(
    rootNode: ChatMessageRootNodeDto,
    newNode: ChatMessageNodeDto,
  ): void {
    if (_.isNil(newNode.text?.text)) {
      throw new Error("Node text must be set.");
    }

    const afterNode = this.getLastNode(rootNode);
    const startIndex = afterNode?.endIndex || 0;

    const newNodeLength = newNode.text!.text!.length;
    newNode.startIndex = startIndex;
    newNode.endIndex = startIndex + newNodeLength;

    rootNode.nodes!.push(newNode);
  }

  /** Splits node by selection and creates two new nodes with text split.
   *  E.g. node + selection -> node 1 range - selection range - node 2 range
   */
  public static splitNodeBySelection(
    rootNode: ChatMessageRootNodeDto,
    node: ChatMessageNodeDto,
    selection: CustomCursorSelection,
  ) {
    const index = rootNode.nodes!.findIndex((x) => x.id === node.id);
    if (index === -1) {
      throw new Error("Node to split not found.");
    }

    const intersection = this.getNodeIntersectionWithSelection(node, selection);
    const textIntersection = this.getTextNodeTextIntersectionWithSelection(node, intersection);
    if (!intersection.isIntersects) {
      throw new Error("Node to split must intersect with the selection.");
    }

    const gapLength = Math.abs(selection.endIndex! - selection.startIndex!);
    const text = node.text?.text || "";
    const textLength = node.text?.text?.length || 0;
    const text1Length = Math.max(Math.abs(node.startIndex! - selection.startIndex!), 0);
    const text2Length = Math.max(textLength - text1Length, 0);

    const node1 = ChatMessageNodeBuilder.newFromNode(node)
      .withIndexRange({ startIndex: node.startIndex, endIndex: node.startIndex! + text1Length })
      .withText({
        text: text.slice(0, text1Length),
      })
      .build();
    const node2 = ChatMessageNodeBuilder.newFromNode(node)
      .withIndexRange({
        startIndex: selection.endIndex,
        endIndex: selection.endIndex! + text2Length,
      })
      .withText({
        text: text.slice(text1Length, text.length),
      })
      .build();

    this.replaceNode(rootNode, node.id!, node1);
    this.insertNodeAfter(rootNode, node1.id!, node2);

    return {
      node1,
      node2,
    };
  }

  public static sortNodes(rootNode: ChatMessageRootNodeDto): void {
    rootNode.nodes = _.orderBy(rootNode.nodes!, (x) => x.startIndex, "asc");
  }

  public static getFirstNode(rootNode: ChatMessageRootNodeDto): ChatMessageNodeDto | undefined {
    return (
      _.chain(rootNode.nodes!)
        .orderBy((x) => x.startIndex, "asc")
        .first()
        .value() || undefined
    );
  }

  public static getLastNode(rootNode: ChatMessageRootNodeDto): ChatMessageNodeDto | undefined {
    return (
      _.chain(rootNode.nodes!)
        .orderBy((x) => x.startIndex, "asc")
        .last()
        .value() || undefined
    );
  }

  public static getPrevNodesByStartIndex(
    rootNode: ChatMessageRootNodeDto,
    anchorNode: ChatMessageNodeDto,
    including = false,
  ) {
    return rootNode.nodes!.filter(
      (x) =>
        x.id !== anchorNode.id &&
        (including
          ? x.startIndex! <= anchorNode.startIndex!
          : x.startIndex! < anchorNode.startIndex!),
    );
  }

  public static getNextNodesByStartIndex(
    rootNode: ChatMessageRootNodeDto,
    anchorNode: ChatMessageNodeDto,
    including = false,
  ) {
    return rootNode.nodes!.filter(
      (x) =>
        x.id !== anchorNode.id &&
        (including
          ? x.startIndex! >= anchorNode.startIndex!
          : x.startIndex! > anchorNode.startIndex!),
    );
  }

  public static getNodeIndexRange(node: ChatMessageNodeDto) {
    return {
      startIndex: node.startIndex,
      endIndex: node.endIndex,
      length: Math.abs((node.endIndex || 0) - (node.startIndex || 0)),
    };
  }

  public static getNodeIntersectionWithSelection(
    node: ChatMessageNodeDto,
    selection: CustomCursorSelection,
  ): NodeIntersectionWithSelection {
    if (
      _.isNil(node.startIndex) ||
      _.isNil(node.endIndex) ||
      _.isNil(selection.startIndex) ||
      _.isNil(selection.endIndex)
    ) {
      return {
        isIntersects: false,
        isFullyIntersects: false,
        isPartiallyIntersects: false,
        isIntersectsInTheStart: false,
        isIntersectsInTheEnd: false,
        isIntersectsInTheMiddle: false,
        startIndex: -1,
        endIndex: -1,
      };
    }

    const isFullyIntersects =
      node.startIndex! >= selection.startIndex! &&
      node.startIndex! < selection.endIndex! &&
      node.endIndex! >= selection.startIndex! &&
      node.endIndex! <= selection.endIndex!;

    const isIntersectsInTheStart =
      !isFullyIntersects &&
      node.startIndex! >= selection.startIndex! &&
      node.startIndex! < selection.endIndex! &&
      node.endIndex! >= selection.endIndex!;

    const isIntersectsInTheEnd =
      !isFullyIntersects &&
      node.startIndex! < selection.startIndex! &&
      node.endIndex! > selection.startIndex! &&
      node.endIndex! <= selection.endIndex!;

    const isIntersectsInTheMiddle =
      !isFullyIntersects &&
      node.startIndex! < selection.startIndex! &&
      node.endIndex! > selection.startIndex! &&
      node.startIndex! < selection.endIndex! &&
      node.endIndex! > selection.endIndex!;

    const isPartiallyIntersects =
      isIntersectsInTheStart || isIntersectsInTheEnd || isIntersectsInTheMiddle;
    const isIntersects = isFullyIntersects || isPartiallyIntersects;

    return {
      isIntersects,
      isFullyIntersects,
      isPartiallyIntersects,
      isIntersectsInTheStart,
      isIntersectsInTheEnd,
      isIntersectsInTheMiddle,

      startIndex: Math.max(node.startIndex!, selection.startIndex!),
      endIndex: Math.min(node.endIndex!, selection.endIndex!),
    };
  }

  /** Returns text node text index range that intersects with the selection. */
  public static getTextNodeTextIntersectionWithSelection(
    textNode: ChatMessageNodeDto,
    intersection: NodeIntersectionWithSelection,
  ): CustomCursorSelection {
    const textLength = textNode.text!.text!.length;
    const intersectedCharCount = Math.max(intersection.endIndex! - intersection.startIndex!, 0);

    if (intersection.isFullyIntersects) {
      return {
        startIndex: 0,
        endIndex: textLength,
      };
    }

    if (intersection.isPartiallyIntersects) {
      if (intersection.isIntersectsInTheStart) {
        return {
          startIndex: 0,
          endIndex: intersectedCharCount,
        };
      } else if (intersection.isIntersectsInTheEnd) {
        return {
          startIndex: Math.max(textLength - intersectedCharCount, 0),
          endIndex: textLength,
        };
      } else if (intersection.isIntersectsInTheMiddle) {
        const shiftFromStartOfString = Math.abs(textNode.startIndex! - intersection.startIndex!);
        const shiftFromEndOfString = Math.abs(textNode.endIndex! - intersection.endIndex!);

        return {
          startIndex: shiftFromStartOfString,
          endIndex: textLength - shiftFromEndOfString,
        };
      }
    }

    return {
      startIndex: -1,
      endIndex: -1,
    };
  }

  /** Prepares final root node that represents made changes.
   *  Clean ups and transforms intermediate nodes.
   */
  public static buildFinalRootNode(
    rootNode: ChatMessageRootNodeDto,
    options?: { resetTempIds?: boolean },
  ) {
    const finalRootNode = this.cloneRootNode(rootNode);

    if (options?.resetTempIds) {
      if (ChatMessageNodeBuilder.isTempNodeId(finalRootNode.id)) {
        finalRootNode.id = undefined;
      }
      finalRootNode
        .nodes!.filter((x) => ChatMessageNodeBuilder.isTempNodeId(x.id))
        .forEach((x) => (x.id = undefined));
    }

    return finalRootNode;
  }
}
