import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { DataGrantDto, DataGrantReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import DataGrantEntityInline from "./DataGrantEntityInline";

export interface DataGrantInlineProps extends BaseEntityInlineInheritableProps {
  entity: DataGrantDto | DataGrantReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function DataGrantInline({ entity, entityId, ...otherProps }: DataGrantInlineProps) {
  const request = useApiRequest(
    apiClient.dataGrantsApi.apiV1DataGrantsDataGrantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      dataGrantId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DataGrantDto;
  const ref = entity as DataGrantReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='dataGrant' inText />}
      content={
        <DataGrantEntityInline
          value={entity}
          inlineProps={{ withIcon: false, withTooltip: false }}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Data grant"}
    />
  );
}
