import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { ProductLocationDto, ProductLocationSearchPaginatedDto } from "@/core/api/generated";

export type ProductLocationAutocompleteOption = BaseAutocompleteOption<ProductLocationDto>;

export const getProductLocationTitle = (data: ProductLocationDto): string =>
  StringHelper.joinIntoString([data.name || data.localNumber], ", ", {
    skipEmpty: true,
  });

export const productLocationToOption = (
  data: ProductLocationDto,
): ProductLocationAutocompleteOption => ({
  id: data.id!,
  title: getProductLocationTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface ProductLocationAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<ProductLocationDto> {
  searchFilters?: Partial<Omit<ProductLocationSearchPaginatedDto, "search" | "includeIds">>;
}

export default function ProductLocationAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: ProductLocationAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={productLocationToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.productLocationsApi.apiV1ProductLocationsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          productLocationSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          productLocationSearchPaginatedDto: {
            ...params.productLocationSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Sales location'
      placeholder='Search for sales location...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='productLocation' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option.data?.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
