import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import TenantConnectionView, { TenantConnectionViewProps } from "./TenantConnectionView";

export interface OwnProps {
  viewProps: TenantConnectionViewProps;
}

type Props = OwnProps & DialogProps;

export default function TenantConnectionViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      ></AppModalTitle>
      <AppModalContent>
        <TenantConnectionView
          {...viewProps}
          displayProps={{
            ...viewProps?.displayProps,
            breadcrumbs: false,
            viewVariant: ViewLayoutVariant.Modal,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
