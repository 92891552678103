import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  RepairSpecDetalizationType,
  RepairSpecDto,
  RepairSpecGetPaginatedDto,
} from "@/core/api/generated";

export default function RepairSpecsPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<RepairSpecGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.RepairSpec),
    },
  });

  const [repairSpecToDelete, setRepairSpecToDelete] = useState<RepairSpecDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedSpecsRequest = useApiRequest(
    apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsGetPost,
    {
      repairSpecGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedSpecs } = paginatedSpecsRequest;

  const handleDeleteConfirmed = useCallback(async () => {
    if (!repairSpecToDelete) {
      return;
    }
    try {
      await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdDelete({
        repairSpecId: repairSpecToDelete.id!,
      });
      enqueueSnackbar("Item was successfully deleted.", { variant: "success" });
      setIsDeleteModalOpen(false);
      paginatedSpecsRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [repairSpecToDelete]);

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_REPAIR_SPEC_CREATE}
          >
            Create new
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <Stack spacing={1}>
        <DataTabular
          columns={[
            {
              field: "localNumber",
              title: "Number",
              flex: 1,
              renderCell: (item) => item.localNumber,
            },
            {
              field: "damageType",
              title: "Damage type",
              flex: 1,
              renderCell: (item) => item.damageType?.name,
            },
            {
              field: "partType",
              title: "Part type",
              flex: 1,
              renderCell: (item) => item.partType?.name,
            },
            {
              field: "detalization",
              title: "Detalization",
              flex: 1,
              renderCell: (item) => (
                <>
                  {" "}
                  <InlineApiEnumValue
                    type='RepairSpecDetalizationType'
                    value={item.detalization?.type}
                  />{" "}
                  {item.detalization?.type !== RepairSpecDetalizationType.General && (
                    <>
                      (
                      {[
                        enumService.getEnumValueName("VehicleSize", item.detalization?.vehicleSize),
                        enumService.getEnumValueName(
                          "VehicleBodyType",
                          item.detalization?.bodyType,
                        ),
                        item.detalization?.make?.name,
                        item.detalization?.model?.name,
                        item.detalization?.generation?.name,
                        item.detalization?.modification?.name,
                      ]
                        .filter((x) => !!x)
                        .join(", ")}
                      )
                    </>
                  )}
                </>
              ),
            },
            {
              field: "items",
              title: "Items",
              flex: 1,
              renderCell: (item) => <>{item.items!.length}</>,
            },
            {
              field: "total",
              title: "Total",
              flex: 1,
              renderCell: (item) => <CurrencyValue value={item.total} currency={item.currency} />,
            },
          ]}
          rows={paginatedSpecs?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_REPAIR_SPEC_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <>
              <MenuWithTrigger
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                {({ handleClose }) => [
                  <MenuItem
                    key='view'
                    component={RouterLink}
                    to={ROUTE_PATH.ADMIN_REPAIR_SPEC_VIEW(item.id)}
                  >
                    <ListItemIcon>
                      <AppIcon of='view' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>View</ListItemText>
                  </MenuItem>,
                  <MenuItem
                    key='edit'
                    component={RouterLink}
                    to={ROUTE_PATH.ADMIN_REPAIR_SPEC_EDIT(item.id)}
                  >
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>,
                  <MenuItem
                    key='delete'
                    onClick={() => {
                      setRepairSpecToDelete(item);
                      setIsDeleteModalOpen(true);
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>,
                ]}
              </MenuWithTrigger>
            </>
          )}
          isLoading={paginatedSpecsRequest.isLoading}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
        />
      </Stack>

      {/* Delete confirmation */}
      {repairSpecToDelete && (
        <ConfirmationModal
          title='Delete the repair spec?'
          body={
            <>
              {`You're going to delete the repair spec`}{" "}
              <strong>
                {repairSpecToDelete.damageType?.name} - {repairSpecToDelete.partType?.name}
              </strong>
              . {`This action can't be undone.`}
            </>
          }
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </ListPageLayout>
  );
}
