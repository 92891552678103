import _ from "lodash";

import {
  IAssetSourceEntityDto,
  IBaseEntityDto,
  IHasGlobalNumberDto,
  IHasLocalNumberDto,
  IImportedEntityDto,
  IMightHaveDataGrantsBriefDto,
  IMightHaveDataGrantsDto,
  IMightHaveDepartmentsDto,
  IMightHaveLocationsDto,
  IMightHavePoolsDto,
  IMightHaveTagsDto,
  IMightHaveTenantRequestsDto,
  TenantDto,
} from "@/core/api/generated";
import store from "@/store";

export type EntityMeta = IBaseEntityDto &
  IHasGlobalNumberDto &
  IHasLocalNumberDto &
  IImportedEntityDto &
  ((IMightHaveDataGrantsDto | IMightHaveDataGrantsBriefDto) & {
    grantsMeta?: (
      | IMightHaveDataGrantsDto["grantsMeta"]
      | IMightHaveDataGrantsBriefDto["grantsMeta"]
    ) & {
      isIssuedByMe: boolean;
      isConsumedByMe: boolean;
    };
  }) &
  IMightHaveTenantRequestsDto &
  IAssetSourceEntityDto &
  // todo: remove department when Dtos will have departmentIds
  IMightHaveDepartmentsDto &
  // todo: remove location when Dtos will have locationIds
  IMightHaveLocationsDto &
  IMightHavePoolsDto &
  IMightHaveTagsDto;

export class EntityHelper {
  public static getEntityMeta(
    entity: IBaseEntityDto | null | undefined,
    currentTenant?: TenantDto | null,
  ): EntityMeta | null {
    if (!entity) {
      return null;
    }

    const currentTenantComputed = currentTenant || store.getState().tenants.currentTenant;

    const hasGlobalNumber = entity as IHasGlobalNumberDto;
    const hasLocalNumber = entity as IHasLocalNumberDto;
    const imported = entity as IImportedEntityDto;
    const mightHaveDataGrants = entity as IMightHaveDataGrantsDto | IMightHaveDataGrantsBriefDto;
    const mightHaveTenantRequests = entity as IMightHaveTenantRequestsDto;
    const assetSourceEntity = entity as IAssetSourceEntityDto;
    const mightHaveDepartments = entity as IMightHaveDepartmentsDto;
    const mightHaveLocations = entity as IMightHaveLocationsDto;
    const mightHavePools = entity as IMightHavePoolsDto;
    const mightHaveTags = entity as IMightHaveTagsDto;

    // directly assign each property for clarity of what's included in the meta
    return {
      id: entity.id,
      globalNumber: hasGlobalNumber.globalNumber,
      localNumber: hasLocalNumber.localNumber,
      importMeta: imported.importMeta,
      grantsMeta: mightHaveDataGrants.grantsMeta
        ? {
            ...mightHaveDataGrants.grantsMeta,
            isIssuedByMe:
              !!currentTenantComputed?.id &&
              currentTenantComputed.id === mightHaveDataGrants.grantsMeta?.issuerTenantId,
            isConsumedByMe:
              !!currentTenantComputed?.id &&
              !!mightHaveDataGrants.grantsMeta?.consumerTenantIdsMap &&
              mightHaveDataGrants.grantsMeta.consumerTenantIdsMap[currentTenantComputed.id],
          }
        : undefined,
      tenantRequestsMeta: mightHaveTenantRequests.tenantRequestsMeta,
      assetMeta: assetSourceEntity.assetMeta,
      poolsMeta: mightHavePools.poolsMeta,
      departmentIds: mightHaveDepartments.departmentIds,
      locationIds: mightHaveLocations.locationIds,
      tags: mightHaveTags.tags,

      // todo: remove temporal when Dtos will have departmentIds
      //department: mightHaveDepartments.department,
      // todo: remove temporal when Dtos will have locationIds
      //location: mightHaveLocations.location,
    };
  }
  public static filterEntitiesByIds(entities: IBaseEntityDto[], ids: string[]): IBaseEntityDto[] {
    return ids
      .map((id) => entities.find((x) => x.id === id))
      .filter((x) => !_.isEmpty(x)) as IBaseEntityDto[];
  }
}
