import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { TenantRequestDto, TenantRequestReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getTenantRequestOptionTitle } from "./TenantRequestAutocomplete";

export interface TenantRequestInlineProps extends BaseEntityInlineInheritableProps {
  entity: TenantRequestDto | TenantRequestReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function TenantRequestInline({
  entity,
  entityId,
  ...otherProps
}: TenantRequestInlineProps) {
  const request = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as TenantRequestDto;
  const ref = entity as TenantRequestReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='tenantRequest' inText />}
      content={<>{full && getTenantRequestOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company request"}
    />
  );
}
