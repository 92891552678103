import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import PoolItemCreateMany, { PoolItemCreateManyProps } from "./PoolItemCreateMany";

export interface OwnProps {
  createManyProps: PoolItemCreateManyProps;
}

type Props = OwnProps & DialogProps;

export default function PoolItemCreateManyModal({ createManyProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Create many pool items
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <PoolItemCreateMany {...createManyProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
