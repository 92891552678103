import { Box, Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import UpdateMileageModal from "@/App/MainAppView/Vehicles/UpdateMileageModal";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import Datetime from "@/common/components/Datetime/Datetime";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import GeneralPlateNoDisplay from "@/common/components/Entity/General/Display/GeneralPlateNoDisplay";
import EntityCreatedByInfoCard from "@/common/components/EntityInfo/EntityCreatedByInfoCard";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import AppIcon from "@/common/components/Icons/AppIcon";
import { VisualModelImagesList } from "@/common/components/Images/VisualModelImagesList";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, VehicleDto } from "@/core/api/generated";

import ContractLink from "../../../Contract/ContractLink";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralStatusMetaDisplayAndInputOfVehicle from "../../../GeneralStatus/common/GeneralStatusMetaDisplayAndInputOfVehicle";
import GeneralStatusMetaDisplayOfAvailabilityStatus from "../../../GeneralStatus/common/GeneralStatusMetaDisplayOfAvailabilityStatus";
import GeneralStatusMetaDisplayOfOperabilityStatus from "../../../GeneralStatus/common/GeneralStatusMetaDisplayOfOperabilityStatus";
import GeneralStatusMetaDisplayOfUtilizationStatus from "../../../GeneralStatus/common/GeneralStatusMetaDisplayOfUtilizationStatus";
import TenantLink from "../../../Tenant/TenantLink";
import VehiclePriceDisplay from "../../VehiclePriceDisplay";
import VehicleSpecDisplay from "../../VehicleSpecDisplay";

interface Props {
  vehicle: VehicleDto;
  onVehicleUpdated: (newValue: VehicleDto | undefined) => any | void;
}

export default function OverviewTabContent({ vehicle, onVehicleUpdated }: Props) {
  const [isUpdateMileageModalOpened, setIsUpdateMileageModalOpened] = useState(false);
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  const defaultVehicleVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: vehicle.defaultVisualModel?.id || "",
    },
    {
      deps: [vehicle.defaultVisualModel?.id],
      skip: !vehicle.defaultVisualModel?.id,
    },
  );
  const defaultVehicleVisualModel = defaultVehicleVisualModelRequest?.data;

  return (
    <PageTabContent>
      <ViewContentBlock>
        <PageTabHeader title={"Details"} subtitle2={<EntityCreatedByInfoCard entity={vehicle} />} />

        <Box
          sx={{
            mt: 1,
            display: "grid",
            gridTemplateColumns: {
              xxs: "1fr",
              sm: "repeat(auto-fill, minmax(20%, 1fr))",
              md: "repeat(auto-fill, minmax(20%, 1fr))",
              lg: "repeat(auto-fill, minmax(20%, 1fr))",
            },
            gridTemplateRows: "auto",
            rowGap: 1,
            columnGap: 1,
          }}
        >
          <InfoTile label='Plate No' iconOf='plateNo' isEmpty={!vehicle.plateNo}>
            <GeneralPlateNoDisplay plateNo={vehicle.plateNo} withIcon={false} withCountryCode />
          </InfoTile>

          <InfoTile label='ID number' iconOf='VIN' isEmpty={!vehicle.identificationNumber}>
            {vehicle.identificationNumber}
          </InfoTile>

          <InfoTile label='Make' iconOf='vehicle'>
            {vehicle.spec?.make?.name}
          </InfoTile>

          <InfoTile label='Model' iconOf='vehicleSide'>
            {vehicle.spec?.model?.name}
          </InfoTile>

          <InfoTile label='Generation' iconOf='clock' isEmpty={!vehicle.spec?.generation}>
            {vehicle.spec?.generation?.name}
          </InfoTile>

          <InfoTile
            label='Modification'
            iconOf='modification'
            isEmpty={!vehicle.spec?.modification}
          >
            {vehicle.spec?.modification?.name}
          </InfoTile>

          <InfoTile
            label='Manufacture year'
            iconOf='document'
            isEmpty={!vehicle.spec?.manufactureYear}
          >
            {vehicle.spec?.manufactureYear}
          </InfoTile>

          <InfoTile label='Type' iconOf='data'>
            <InlineApiEnumValue type='VehicleType' value={vehicle.spec?.type} />
          </InfoTile>

          <InfoTile label='Size' iconOf='data'>
            <InlineApiEnumValue type='VehicleSize' value={vehicle.spec?.size} />
          </InfoTile>

          <InfoTile label='Body type' iconOf='repairCar'>
            <InlineApiEnumValue type='VehicleBodyType' value={vehicle.spec?.bodyType} />
          </InfoTile>

          <InfoTile label='Fuel type' iconOf='fuel'>
            <InlineApiEnumValue type='VehicleFuelType' value={vehicle.spec?.fuelType} />
          </InfoTile>

          <InfoTile label='Mileage' iconOf='gauge'>
            <>
              {vehicle.mileage}
              <UpdateMileageModal
                vehicle={vehicle}
                open={isUpdateMileageModalOpened}
                onOpen={() => setIsUpdateMileageModalOpened(true)}
                onClose={() => setIsUpdateMileageModalOpened(false)}
                onMileageChanged={() => onVehicleUpdated(undefined)}
                trigger={
                  <AppIconButton
                    tooltipProps={{ title: "Update mileage" }}
                    sx={{ p: "2px", ml: 0.5 }}
                  >
                    <AppIcon of='edit' sx={{ width: "0.8em", height: "0.8em" }} />
                  </AppIconButton>
                }
              />
            </>
          </InfoTile>

          <InfoTile label='Actual damages' iconOf='damage'>
            {vehicle.actualDamagesCount}
          </InfoTile>

          <InfoTile label='Repaired damages' iconOf='damage'>
            {vehicle.repairedDamagesCount}
          </InfoTile>

          <InfoTile
            label='Attachments'
            iconOf='attachments'
            isEmpty={!vehicle.attachments || vehicle.attachments?.length === 0}
          >
            {vehicle.attachments?.length}
          </InfoTile>

          <InfoTile
            label='Last damage detection'
            iconOf='damageTime'
            isEmpty={!vehicle.lastDamageDetectionAt}
          >
            <Datetime datetime={vehicle.lastDamageDetectionAt} />
          </InfoTile>

          <InfoTile
            label='Last accessory check'
            iconOf='accessory'
            isEmpty={!vehicle.lastAccessoryCheckAt}
          >
            <Datetime datetime={vehicle.lastAccessoryCheckAt} />
          </InfoTile>

          <InfoTile
            label='Last mileage update'
            iconOf='gaugeTime'
            isEmpty={!vehicle.lastMileageUpdatedAt}
          >
            <Datetime datetime={vehicle.lastMileageUpdatedAt} />
          </InfoTile>

          <InfoTile label='Last contract' iconOf='contract' isEmpty={!vehicle.lastContract}>
            <ContractLink entity={vehicle.lastContract} />
          </InfoTile>

          <Grid item xs={12} md={3}>
            <InfoTile
              label='Purchase price'
              iconOf='price'
              isEmpty={_.isNil(vehicle.spec?.purchasePrice?.price ?? vehicle.spec?.purchasingCost)}
            >
              <VehiclePriceDisplay
                price={vehicle.spec?.purchasePrice}
                fallbackPrice={{ price: vehicle.spec?.purchasingCost ?? undefined }}
              />
            </InfoTile>
          </Grid>

          <InfoTile label='Status' iconOf='data' isEmpty={!vehicle.status}>
            <GeneralStatusMetaDisplayAndInputOfVehicle
              vehicle={vehicle}
              onVehicleUpdated={onVehicleUpdated}
            />
          </InfoTile>

          <InfoTile label='Availability status' iconOf='data'>
            <GeneralStatusMetaDisplayOfAvailabilityStatus
              value={vehicle.availabilityStatus}
              subjectEntity={vehicle}
              onMetaUpdated={(newValue) => {
                onVehicleUpdated({
                  ...vehicle,
                  availabilityStatus: newValue,
                });
              }}
            />
          </InfoTile>

          <InfoTile label='Utilization status' iconOf='data'>
            <GeneralStatusMetaDisplayOfUtilizationStatus
              value={vehicle.utilizationStatus}
              subjectEntity={vehicle}
              onMetaUpdated={(newValue) => {
                onVehicleUpdated({
                  ...vehicle,
                  utilizationStatus: newValue,
                });
              }}
            />
          </InfoTile>

          <InfoTile label='Operability status' iconOf='data'>
            <GeneralStatusMetaDisplayOfOperabilityStatus
              value={vehicle.operabilityStatus}
              subjectEntity={vehicle}
              onMetaUpdated={(newValue) => {
                onVehicleUpdated({
                  ...vehicle,
                  operabilityStatus: newValue,
                });
              }}
            />
          </InfoTile>
        </Box>
      </ViewContentBlock>

      {/* Spec */}
      <ViewContentBlock>
        <FoldableBlock
          sx={{ my: 1 }}
          defaultIsFolded
          trigger={{
            label: <ViewContentBlockHeader component='span'>Full spec</ViewContentBlockHeader>,
          }}
        >
          <VehicleSpecDisplay spec={vehicle.spec} />
        </FoldableBlock>
      </ViewContentBlock>

      {/* Affiliation */}
      <ViewContentBlock>
        <ViewContentBlockHeader>
          Affiliation{" "}
          <AppIconButton
            tooltipProps={{ title: "Edit entity affiliation" }}
            onClick={() => setIsUpdateAffiliationModalOpened(true)}
          >
            <AppIcon of='edit' fontSize='small' />
          </AppIconButton>
        </ViewContentBlockHeader>

        <Stack direction={{ xxs: "column", md: "row" }} spacing={1} sx={{ width: "100%" }}>
          <AffiliationInfoDisplay
            tenantId={vehicle.tenantId}
            departmentIds={vehicle.departmentIds}
            locationIds={vehicle.locationIds}
            sx={{ flex: 1, mt: 1 }}
          />

          <Stack sx={{ flex: 1 }}>
            {vehicle.ownerTenantId && (
              <FieldValue
                labelIcon={<AppIcon of='person' inText />}
                direction='column'
                label='Owner'
                isEmpty={TypeHelper.isEmpty(vehicle.ownerTenantId)}
              >
                <TenantLink entity={undefined} entityId={vehicle.ownerTenantId} />
              </FieldValue>
            )}

            <FieldValue
              labelIcon={<AppIcon of='notes' inText />}
              direction='column'
              label='Notes'
              isEmpty={TypeHelper.isEmpty(vehicle.notes)}
            >
              {vehicle.notes}
            </FieldValue>
          </Stack>
        </Stack>

        <UpdateEntityAffiliationCascadeModal
          open={isUpdateAffiliationModalOpened}
          onClose={() => setIsUpdateAffiliationModalOpened(false)}
          entityType={EntityType.Vehicle}
          entityId={vehicle.id}
          onUpdate={() => onVehicleUpdated(undefined)}
        />
      </ViewContentBlock>

      <ViewContentBlock>
        <ViewContentBlockHeader>Default visual model</ViewContentBlockHeader>
        <Typography variant='body2'>(Used by default for all new damage detections)</Typography>

        {!vehicle.defaultVisualModel && <NoDataAlert title='No default visual model set.' />}

        {vehicle.defaultVisualModel && defaultVehicleVisualModel && (
          <Box>
            <Box>
              <VisualModelImagesList
                visualModel={defaultVehicleVisualModel}
                imageContainerSx={{ width: `100px`, height: `auto` }}
              />
            </Box>
          </Box>
        )}
      </ViewContentBlock>
    </PageTabContent>
  );
}
