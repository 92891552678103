import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ContractVehicleCheckOut, { ContractVehicleCheckOutProps } from "./ContractVehicleCheckOut";

export interface OwnProps {
  checkOutProps: ContractVehicleCheckOutProps;
}

type Props = OwnProps & DialogProps;

export default function ContractVehicleCheckOutModal({ checkOutProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Check-out vehicle
      </AppModalTitle>
      <DialogContent>
        <ContractVehicleCheckOut {...checkOutProps} />
      </DialogContent>
    </Dialog>
  );
}
