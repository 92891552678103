import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name: Icon/Fill/arrow-ios-down
export default forwardRef<SVGSVGElement, SvgIconProps>(function ExpandLessIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='17'
      height='16'
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99709 5.33333C9.14909 5.33333 9.30042 5.38467 9.42376 5.488L13.4238 8.82133C13.7064 9.05667 13.7451 9.47733 13.5091 9.76C13.2738 10.0427 12.8538 10.0807 12.5704 9.84533L8.98976 6.86133L5.41509 9.738C5.12842 9.96867 4.70842 9.92333 4.47776 9.63667C4.24709 9.35 4.29242 8.93067 4.57909 8.69933L8.57909 5.48067C8.70109 5.38267 8.84909 5.33333 8.99709 5.33333Z'
      />
      <mask id='mask0_1_835' maskUnits='userSpaceOnUse' x='4' y='5' width='10' height='5'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.99709 5.33333C9.14909 5.33333 9.30042 5.38467 9.42376 5.488L13.4238 8.82133C13.7064 9.05667 13.7451 9.47733 13.5091 9.76C13.2738 10.0427 12.8538 10.0807 12.5704 9.84533L8.98976 6.86133L5.41509 9.738C5.12842 9.96867 4.70842 9.92333 4.47776 9.63667C4.24709 9.35 4.29242 8.93067 4.57909 8.69933L8.57909 5.48067C8.70109 5.38267 8.84909 5.33333 8.99709 5.33333Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_835)'>
        <rect x='16.9971' y='16' width='16' height='16' transform='rotate(-180 16.9971 16)' />
      </g>
    </SvgIcon>
  );
});
