import DataGrantPaginatedList from "@/common/components/Entity/DataGrant/ListView/DataGrantPaginatedList";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { DataGrantGetPaginatedDto } from "@/core/api/generated";

export type DataGrantsPageQueryParams = GeneralQueryParams &
  Partial<Pick<DataGrantGetPaginatedDto, "grantEntityType" | "entityType" | "entityId">>;

export default function DataGrantsPage() {
  const queryParams = useQueryParams<DataGrantsPageQueryParams>();

  return (
    <>
      <DataGrantPaginatedList
        displayProps={{
          breadcrumbs: true,
          filters: true,
        }}
        defaultValues={{
          ...queryParams,
        }}
      />
    </>
  );
}
