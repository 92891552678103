import { Box, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { RepairMaterialDto } from "@/core/api/generated";
import { RepairMaterialSearchPaginatedDto } from "@/core/api/generated";

import AppTypography from "../../Text/AppTypography";

export type RepairMaterialAutocompleteOption = BaseAutocompleteOption<RepairMaterialDto>;

export const getRepairMaterialOptionTitle = (data: RepairMaterialDto): string =>
  StringHelper.joinIntoString([data.name || data.localNumber], ", ", {
    skipEmpty: true,
  });

export const repairMaterialToOption = (
  data: RepairMaterialDto,
): RepairMaterialAutocompleteOption => ({
  id: data.id!,
  title: getRepairMaterialOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairMaterialAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairMaterialDto> {
  searchFilters?: Partial<Omit<RepairMaterialSearchPaginatedDto, "search" | "includeIds">>;
}

export default function RepairMaterialAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: RepairMaterialAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={repairMaterialToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.repairMaterialsApi.apiV1RepairMaterialsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairMaterialSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          repairMaterialSearchPaginatedDto: {
            ...params.repairMaterialSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Repair material'
      placeholder='Search for repair material...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='repairMaterial' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <AppTypography
                    ellipsing={{ enabled: true }}
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </AppTypography>
                </>
              }
              secondary={
                option.data && (
                  <Box>
                    <AppTypography ellipsing={{ enabled: true }} variant='body2'>
                      {option.data?.description}
                    </AppTypography>
                  </Box>
                )
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
