import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { GetChatUserSettingsResultDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

export interface ChatUserSettingsState {
  /** chatId: data */
  chatUserSettingsMap: Record<string, GetChatUserSettingsResultDto | undefined>;
}

const initialState: ChatUserSettingsState = {
  chatUserSettingsMap: {},
};

const chatUserSettingsSlice = createSlice({
  name: "chatUserSettings",
  initialState,
  reducers: {
    _chatUserSettingsFetched: (state, action: PayloadAction<GetChatUserSettingsResultDto>) => {
      state.chatUserSettingsMap[action.payload.requestedForChatId || ""] = action.payload;
    },
    resetChatUserSettings: (state, action: PayloadAction<{ chatId?: string | null }>) => {
      state.chatUserSettingsMap[action.payload.chatId || ""] = undefined;
    },
  },
});

export const { _chatUserSettingsFetched, resetChatUserSettings } = chatUserSettingsSlice.actions;

export const chatUserSettingsReducer = chatUserSettingsSlice.reducer;

export const getChatUserSettings =
  (
    ...arg: Parameters<typeof apiClient.chatUserSettingsApi.apiV1ChatUserSettingsChatIdGet>
  ): AppThunk<Promise<GetChatUserSettingsResultDto>> =>
  async (dispatch) => {
    const response = await apiClient.chatUserSettingsApi.apiV1ChatUserSettingsChatIdGet(...arg);
    await dispatch(_chatUserSettingsFetched(response.data));
    return response.data;
  };

export const updateChatUserSettings =
  (
    ...arg: Parameters<typeof apiClient.chatUserSettingsApi.apiV1ChatUserSettingsPut>
  ): AppThunk<Promise<GetChatUserSettingsResultDto>> =>
  async (dispatch) => {
    const response = await apiClient.chatUserSettingsApi.apiV1ChatUserSettingsPut(...arg);
    await dispatch(_chatUserSettingsFetched(response.data));
    return response.data;
  };
