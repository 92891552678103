import { styled } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/react";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
// import reportWebVitals from './reportWebVitals';
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router";

import { authService } from "@/common/services/auth";
import { tenantService } from "@/common/services/tenant";
import "@/common/ts/globals";

import App from "./App/App";
import { CustomEnvName } from "./common/constants/customEnvName";
import { BreadcrumbsContextProvider } from "./common/contexts/breadcrumbs";
import { NavDisplayProvider } from "./common/contexts/useNavDisplay";
import { I18nHelper } from "./common/helpers/i18n";
import { chatHubService } from "./common/realtime/chatHubService";
import { dataUpdatesHubService } from "./common/realtime/dataUpdatesHubService";
import { notificationHubService } from "./common/realtime/notificationHubService";
import { enumService } from "./common/services/enum";
import { Stopwatch } from "./common/stopwatch";
import { appCommonConfig, clarityConfig, sentryConfig } from "./config/config";
import { apiClient } from "./core/api/ApiClient";
import i18n from "./i18n";
// #region Fonts
import "./index.css";
// #endregion
import store, { history } from "./store";
import { prerenderConfigurationsStarted } from "./store/appCommon/slice";

Sentry.init({
  dsn: sentryConfig.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.captureConsoleIntegration(),
    Sentry.extraErrorDataIntegration(),
  ],
  // Tracing
  tracesSampleRate: sentryConfig.tracesSampleRate,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // It should not be allowed to propagate traces to all URLs such as OAuth provider because authentication may fail
  tracePropagationTargets: sentryConfig.tracePropagationTargets,

  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
  // Set environment
  environment: appCommonConfig.customEnv,
  // Disable Sentry if running locally
  enabled: appCommonConfig.customEnv !== CustomEnvName.DevelopmentLocalhost,
});

const MaterialDesignContentStyled = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.main,
  },
}));

(async () => {
  const stopwatch = Stopwatch.startNew();

  // log app info on startup
  console.log("App info:", {
    appName: appCommonConfig.appName,
    version: appCommonConfig.version,
    customEnv: appCommonConfig.customEnv,
  });

  tenantService.initRedirectToTenantUrl();
  if (clarityConfig.enabled) {
    clarity.init(clarityConfig.trackingCode);
  }

  await store.dispatch(prerenderConfigurationsStarted());

  // configure API client with local user i18n settings
  const i18nSettings = I18nHelper.getUserI18nSettingsLocal();
  if (i18nSettings) {
    apiClient.updateUserI18nSettings(i18nSettings);
  }

  authService.on("authenticationStateChange", async ({ isAuthenticated }) => {
    console.log("on authenticationStateChange.", { isAuthenticated });

    if (isAuthenticated) {
      chatHubService.connect();
      notificationHubService.connect();
      dataUpdatesHubService.connect();
      enumService.handleEnumCatalogOnAppInit();
    } else {
      chatHubService.disconnect();
      notificationHubService.disconnect();
      dataUpdatesHubService.disconnect();
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  root.render(
    // https://react.dev/reference/react/StrictMode
    <React.StrictMode>
      <ReduxProvider store={store}>
        <Router history={history}>
          <I18nextProvider i18n={i18n}>
            {/* https://mui.com/x/react-date-pickers/getting-started/#setup-your-date-library-adapter  */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SnackbarProvider
                Components={{
                  success: MaterialDesignContentStyled,
                  info: MaterialDesignContentStyled,
                  warning: MaterialDesignContentStyled,
                  error: MaterialDesignContentStyled,
                }}
                maxSnack={4}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <BreadcrumbsContextProvider>
                  <NavDisplayProvider>
                    <App />
                  </NavDisplayProvider>
                </BreadcrumbsContextProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </I18nextProvider>
        </Router>
      </ReduxProvider>
    </React.StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();

  stopwatch.stop();
  console.info(`Startup. App started.`, {
    elapsedMilliseconds: stopwatch.elapsedMilliseconds,
    elapsedSeconds: stopwatch.elapsedSeconds,
  });
})();
