import Box from "@mui/material/Box";

import { ROUTE_PATH } from "@/common/constants/routing";
import { GeneralInspectorDto, UserDto, UserReferenceDto } from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import { AppEntityLinkProps } from "../../Link/types";

interface Props extends AppEntityLinkProps {
  userId?: string | null;
  user?: UserReferenceDto | UserDto | null;
  text?: string | null;
  inspector?: GeneralInspectorDto;
}

export default function UserLink({
  userId,
  user,
  text,
  inspector,
  withIcon = true,
  withTooltip = true,
  ...otherProps
}: Props) {
  const _userId = userId || user?.id || inspector?.userId;
  const _text =
    text ||
    user?.personName?.name ||
    user?.email ||
    inspector?.personName?.name ||
    inspector?.email;

  return (
    <AppLink
      to={ROUTE_PATH.USER_VIEW(_userId)}
      withTooltip={withTooltip}
      tooltipTitle='User'
      {...otherProps}
    >
      {withIcon && <AppIcon of='user' fontSize='inherit' color='inherit' sx={{ mr: 1 }} />}
      <Box component='span'>{_text}</Box>
    </AppLink>
  );
}
