import uuid4 from "uuid4";

export const AutocompleteOptionType = {
  Normal: "Normal",
  /** Dynamic option for creation of new option. */
  DynamicCreateNew: "DynamicCreateNew",
  /** Option for render infinite scroll anchor. */
  Sentry: "Sentry",
  /** Dynamic option for helper text "No options". */
  NoOptions: "NoOptions",
};
export type AutocompleteOptionType =
  (typeof AutocompleteOptionType)[keyof typeof AutocompleteOptionType];

export interface BaseAutocompleteOption<TOptionData = any> {
  /** Option id */
  id: string;
  title: string;
  optionType: AutocompleteOptionType;
  /** Is set for dynamic options. */
  inputValue?: string;
  /** Value to group options by */
  groupBy?: string;
  data?: TOptionData | null;
}

export type AutocompleteOption<TOptionData = any> = BaseAutocompleteOption<TOptionData>;

export type EntityToAutocompleteOptionFunc<TEntity> = (
  entity: TEntity,
) => AutocompleteOption<TEntity>;

export type EntitiesToAutocompleteOptionsFunc<TEntity> = (
  entities: TEntity[] | null | undefined,
) => AutocompleteOption<TEntity>[];

export function createOptionFromInputValue({
  inputValue,
  title,
}: {
  inputValue: string | null | undefined;
  title?: string;
}): BaseAutocompleteOption {
  return {
    id: uuid4(),
    title: title || `Add "${inputValue}"`,
    optionType: AutocompleteOptionType.DynamicCreateNew,
    inputValue: inputValue || "",
    data: undefined,
  };
}

export function createOptionForNoOptions(params?: { title?: string }): BaseAutocompleteOption {
  return {
    id: uuid4(),
    title: params?.title || `No options`,
    optionType: AutocompleteOptionType.NoOptions,
    data: undefined,
  };
}
