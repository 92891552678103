import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { EntityType, WashDto } from "@/core/api/generated";

interface Props {
  wash: WashDto;
}

export default function CommentsTabContent({ wash }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat entityId={wash.id!} entityType={EntityType.Wash} />
      </Stack>
    </PageTabContent>
  );
}
