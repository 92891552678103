import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import SupplierCreateUpdate from "@/common/components/Entity/Supplier/SupplierCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function SupplierCreateUpdatePage() {
  const { supplierId } = useParams<{ supplierId?: string }>();
  const isCreate = !supplierId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new supplier" : "Edit supplier"} />}
    >
      <SupplierCreateUpdate
        supplierId={supplierId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.SUPPLIER_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
