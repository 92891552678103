import { Favorite, FavoriteBorder } from "@mui/icons-material";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { MiscHelper } from "@/common/helpers/misc";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";

export default function DevCheckboxes() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        MUI Checkbox
      </Typography>

      <Stack direction='column' spacing={2}>
        <Typography component='div' variant='h6'>
          Size
        </Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          {(["small", "medium"] as Array<CheckboxProps["size"]>).map((size, i) => (
            <Checkbox key={i} size={size} />
          ))}
        </Stack>
      </Stack>

      <Stack direction='column' spacing={2}>
        <Typography component='div' variant='h6'>
          Color
        </Typography>
        <Stack direction='row' spacing={2} alignItems='center'>
          {(
            ["primary", "secondary", "error", "info", "success", "warning", "default"] as Array<
              CheckboxProps["color"]
            >
          ).map((color, i) => (
            <Checkbox key={i} defaultChecked color={color} />
          ))}
        </Stack>
      </Stack>

      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Checkbox defaultChecked />
          <Checkbox />
          <Checkbox disabled />
          <Checkbox disabled checked />
        </Stack>

        <Stack direction='row' spacing={1}>
          <FormGroup>
            <FormControlLabel control={<Checkbox defaultChecked />} label='Label' />
            <FormControlLabel disabled control={<Checkbox />} label='Disabled' />
          </FormGroup>
        </Stack>

        <Stack direction='row' spacing={1}>
          <Checkbox defaultChecked size='small' />
          <Checkbox defaultChecked />
          <Checkbox defaultChecked sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }} />
        </Stack>

        <Stack direction='row' spacing={1}>
          <div>
            <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
            <Checkbox
              icon={<AppIcon of='bookmarkBorder' />}
              checkedIcon={<AppIcon of='bookmark' />}
            />
          </div>
        </Stack>
        <Stack direction='row' spacing={1}>
          <FormControlLabel label='Parent' control={<Checkbox />} />
        </Stack>
        <Stack direction='row' spacing={1}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Label placement</FormLabel>
            <FormGroup aria-label='position' row>
              <FormControlLabel
                value='top'
                control={<Checkbox />}
                label='Top'
                labelPlacement='top'
              />
              <FormControlLabel
                value='start'
                control={<Checkbox />}
                label='Start'
                labelPlacement='start'
              />
              <FormControlLabel
                value='bottom'
                control={<Checkbox />}
                label='Bottom'
                labelPlacement='bottom'
              />
              <FormControlLabel
                value='end'
                control={<Checkbox />}
                label='End'
                labelPlacement='end'
              />
            </FormGroup>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
}
