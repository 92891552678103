import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ContractCreateUpdate from "@/common/components/Entity/Contract/ContractCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface ContractCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  customerId?: string | null;
}

export default function ContractCreateUpdatePage() {
  const { contractId } = useParams<{ contractId?: string }>();
  const { vehicleId, customerId } = useQueryParams<ContractCreateUpdateQueryParams>();
  const isCreate = !contractId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new contract" : "Edit contract"} />}
    >
      <ContractCreateUpdate
        contractId={contractId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
          customerId: customerId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.CONTRACT_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
