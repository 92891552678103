/* tslint:disable */
/* eslint-disable */
/**
 * WebApi
 * NexusFleet SaaS
 *
 * The version of the OpenAPI document: 0.1-demo
 * Contact: info@nexusops.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { AxiosInstance, AxiosPromise, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from './base';
import { DUMMY_BASE_URL, assertParamExists, createRequestFunction, serializeDataIfNeeded, setBearerAuthToObject, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessoryChangedDto
 */
export interface AccessoryChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AccessoryChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AccessoryChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AccessoryDto}
     * @memberof AccessoryChangedDto
     */
    'entity': AccessoryDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckChangedDto
 */
export interface AccessoryCheckChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AccessoryCheckChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AccessoryCheckChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AccessoryCheckDto}
     * @memberof AccessoryCheckChangedDto
     */
    'entity': AccessoryCheckDto;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckContentDto
 */
export interface AccessoryCheckContentDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckCreatedInAppDataDto
 */
export interface AccessoryCheckCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof AccessoryCheckCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckCreatedInAppDataDto
     */
    'accessoryCheckId'?: string;
}


/**
 * 
 * @export
 * @interface AccessoryCheckDataDto
 */
export interface AccessoryCheckDataDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AccessoryCheckDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AccessoryCheckDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof AccessoryCheckDataDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof AccessoryCheckDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {Array<AccessoryCheckItemDataDto>}
     * @memberof AccessoryCheckDataDto
     */
    'items'?: Array<AccessoryCheckItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDataDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckDto
 */
export interface AccessoryCheckDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryCheckDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof AccessoryCheckDto
     */
    'appType'?: FrontEndAppType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'localNumber'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof AccessoryCheckDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AccessoryCheckDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AccessoryCheckDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<AccessoryCheckItemDto>}
     * @memberof AccessoryCheckDto
     */
    'items'?: Array<AccessoryCheckItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryCheckDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof AccessoryCheckDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AccessoryCheckDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckItemDataDto
 */
export interface AccessoryCheckItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof AccessoryCheckItemDataDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof AccessoryCheckItemDataDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDataDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckItemDto
 */
export interface AccessoryCheckItemDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof AccessoryCheckItemDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryStatus}
     * @memberof AccessoryCheckItemDto
     */
    'status'?: AccessoryStatus;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AccessoryCheckItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemDto
     */
    'notes'?: string | null;
}


/**
 * 
 * @export
 * @interface AccessoryCheckItemReferenceDto
 */
export interface AccessoryCheckItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'accessoryCheckId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckReferenceDto
 */
export interface AccessoryCheckReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryCheckResultDto
 */
export interface AccessoryCheckResultDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryCheckResultDto
     */
    'accessoryCheckId'?: string;
}
/**
 * 
 * @export
 * @interface AccessoryDto
 */
export interface AccessoryDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessoryDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AccessoryDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof AccessoryDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AccessoryDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof AccessoryDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AccessoryDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoryDto
     */
    'isForAllVehicles'?: boolean;
}


/**
 * 
 * @export
 * @interface AccessoryFullReferenceDto
 */
export interface AccessoryFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryFullReferenceDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof AccessoryFullReferenceDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof AccessoryFullReferenceDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof AccessoryFullReferenceDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessoryFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessoryFullReferenceDto
     */
    'isForAllVehicles'?: boolean;
}


/**
 * 
 * @export
 * @interface AccessoryReferenceDto
 */
export interface AccessoryReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AccessoryType}
     * @memberof AccessoryReferenceDto
     */
    'type'?: AccessoryType;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoryReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessoryStatus = {
    None: 'None',
    InOrder: 'InOrder',
    Missing: 'Missing',
    Damaged: 'Damaged'
} as const;

export type AccessoryStatus = typeof AccessoryStatus[keyof typeof AccessoryStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessoryType = {
    None: 'None',
    SpareKey: 'SpareKey',
    ChargerCable: 'ChargerCable',
    TrunkCover: 'TrunkCover',
    AutopassUnit: 'AutopassUnit',
    PortableTireInflator: 'PortableTireInflator',
    ChildSeat: 'ChildSeat',
    DashCam: 'DashCam',
    PhoneHolder: 'PhoneHolder',
    PhoneCharger: 'PhoneCharger'
} as const;

export type AccessoryType = typeof AccessoryType[keyof typeof AccessoryType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AllocationStatus = {
    None: 'None',
    NotAllocated: 'NotAllocated',
    Allocating: 'Allocating',
    Allocated: 'Allocated'
} as const;

export type AllocationStatus = typeof AllocationStatus[keyof typeof AllocationStatus];


/**
 * Describes paid parts of the system that user can access
 * @export
 * @enum {string}
 */

export const AppModule = {
    None: 'None',
    FleetManagment: 'FleetManagment',
    NafAssessment: 'NafAssessment',
    Reports: 'Reports',
    TestFeature1: 'TestFeature1',
    TestFeature2: 'TestFeature2',
    TestPremiumFeature: 'TestPremiumFeature'
} as const;

export type AppModule = typeof AppModule[keyof typeof AppModule];


/**
 * 
 * @export
 * @enum {string}
 */

export const AppPermission = {
    None: 'None',
    AdminAppAccess: 'AdminAppAccess',
    FleetAppAccess: 'FleetAppAccess',
    FleetCustomerAppAccess: 'FleetCustomerAppAccess',
    AdminUserManage: 'AdminUserManage',
    AdminTenantManage: 'AdminTenantManage',
    AdminSubscriptionPlanManage: 'AdminSubscriptionPlanManage',
    AdminSubscriptionManage: 'AdminSubscriptionManage',
    AdminInvoiceManage: 'AdminInvoiceManage',
    AdminNotificationsManage: 'AdminNotificationsManage',
    AdminReferenceDataRead: 'AdminReferenceDataRead',
    AdminReferenceDataManage: 'AdminReferenceDataManage',
    AdminInviteRead: 'AdminInviteRead',
    AdminInviteManage: 'AdminInviteManage',
    AdminDomainEventsManage: 'AdminDomainEventsManage',
    GlobalPermissionRead: 'GlobalPermissionRead',
    GlobalPermissionManage: 'GlobalPermissionManage',
    ScopedPermissionRead: 'ScopedPermissionRead',
    ScopedPermissionManage: 'ScopedPermissionManage',
    TenantPermissionRead: 'TenantPermissionRead',
    TenantPermissionManage: 'TenantPermissionManage',
    GlobalRoleRead: 'GlobalRoleRead',
    GlobalRoleManage: 'GlobalRoleManage',
    GlobalRoleAssignmentManage: 'GlobalRoleAssignmentManage',
    RoleAssignmentManage: 'RoleAssignmentManage',
    TenantRoleRead: 'TenantRoleRead',
    TenantRoleManage: 'TenantRoleManage',
    TenantRead: 'TenantRead',
    TenantCreate: 'TenantCreate',
    TenantManage: 'TenantManage',
    TenantSubscriptionRead: 'TenantSubscriptionRead',
    TenantSubscriptionManage: 'TenantSubscriptionManage',
    TenantConnectionRead: 'TenantConnectionRead',
    TenantConnectionManage: 'TenantConnectionManage',
    TenantRequestRead: 'TenantRequestRead',
    TenantRequestManage: 'TenantRequestManage',
    DataGrantRead: 'DataGrantRead',
    DataGrantManage: 'DataGrantManage',
    ImportRead: 'ImportRead',
    ImportManage: 'ImportManage',
    ExportManage: 'ExportManage',
    InvoiceRead: 'InvoiceRead',
    TenantUserRead: 'TenantUserRead',
    TenantUserManage: 'TenantUserManage',
    TenantInviteRead: 'TenantInviteRead',
    TenantInviteManage: 'TenantInviteManage',
    FleetRead: 'FleetRead',
    FleetManage: 'FleetManage',
    DepartmentRead: 'DepartmentRead',
    DepartmentManage: 'DepartmentManage',
    LocationRead: 'LocationRead',
    LocationManage: 'LocationManage',
    SpotRead: 'SpotRead',
    SpotManage: 'SpotManage',
    ProductRead: 'ProductRead',
    ProductManage: 'ProductManage',
    ProductLocationRead: 'ProductLocationRead',
    ProductLocationManage: 'ProductLocationManage',
    AssetSubscriptionPlanRead: 'AssetSubscriptionPlanRead',
    AssetSubscriptionPlanManage: 'AssetSubscriptionPlanManage',
    AssetSubscriptionRead: 'AssetSubscriptionRead',
    AssetSubscriptionManage: 'AssetSubscriptionManage',
    CustomerRead: 'CustomerRead',
    CustomerManage: 'CustomerManage',
    CustomerContactsRead: 'CustomerContactsRead',
    CustomerContactsManage: 'CustomerContactsManage',
    ContractRead: 'ContractRead',
    ContractManage: 'ContractManage',
    VisualInspectionPerform: 'VisualInspectionPerform',
    VisualInspectionRead: 'VisualInspectionRead',
    VisualInspectionManage: 'VisualInspectionManage',
    DamageDetectionPerform: 'DamageDetectionPerform',
    DamageDetectionRead: 'DamageDetectionRead',
    DamageDetectionManage: 'DamageDetectionManage',
    DamageCostEvaluationPerform: 'DamageCostEvaluationPerform',
    DamageCostEvaluationRead: 'DamageCostEvaluationRead',
    DamageCostEvaluationManage: 'DamageCostEvaluationManage',
    AccessoryRead: 'AccessoryRead',
    AccessoryManage: 'AccessoryManage',
    VehicleRead: 'VehicleRead',
    VehicleManage: 'VehicleManage',
    VehicleHistoryRead: 'VehicleHistoryRead',
    VehicleDamageRead: 'VehicleDamageRead',
    VehicleDamageManage: 'VehicleDamageManage',
    AccessoryCheckPerform: 'AccessoryCheckPerform',
    AccessoryCheckRead: 'AccessoryCheckRead',
    AccessoryCheckManage: 'AccessoryCheckManage',
    RepairOperationPerform: 'RepairOperationPerform',
    RepairOperationRead: 'RepairOperationRead',
    RepairOperationManage: 'RepairOperationManage',
    DocumentRead: 'DocumentRead',
    DocumentManage: 'DocumentManage',
    RepairCatalogRead: 'RepairCatalogRead',
    RepairCatalogManage: 'RepairCatalogManage',
    PoolRead: 'PoolRead',
    PoolManage: 'PoolManage',
    AssetRead: 'AssetRead',
    AssetManage: 'AssetManage',
    CustomTagRead: 'CustomTagRead',
    CustomTagManage: 'CustomTagManage',
    WebhookRead: 'WebhookRead',
    WebhookManage: 'WebhookManage',
    GeneralHistoryRead: 'GeneralHistoryRead',
    GeneralHistoryManage: 'GeneralHistoryManage',
    ChatRead: 'ChatRead',
    ChatManage: 'ChatManage',
    NegotiationRead: 'NegotiationRead',
    NegotiationManage: 'NegotiationManage',
    IntegrationApiClientRead: 'IntegrationApiClientRead',
    IntegrationApiClientManage: 'IntegrationApiClientManage',
    TeslaConnectionRead: 'TeslaConnectionRead',
    TeslaConnectionManage: 'TeslaConnectionManage',
    FlowRead: 'FlowRead',
    FlowManage: 'FlowManage',
    TestRead: 'TestRead',
    TestManage: 'TestManage',
    TestFeature1Access: 'TestFeature1Access',
    TestFeature2Access: 'TestFeature2Access',
    TestPremiumFeatureAccess: 'TestPremiumFeatureAccess'
} as const;

export type AppPermission = typeof AppPermission[keyof typeof AppPermission];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApprovalResponseType = {
    None: 'None',
    Approve: 'Approve',
    Decline: 'Decline',
    ForceApprove: 'ForceApprove',
    ForceDecline: 'ForceDecline'
} as const;

export type ApprovalResponseType = typeof ApprovalResponseType[keyof typeof ApprovalResponseType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    None: 'None',
    Approved: 'Approved',
    Declined: 'Declined',
    ForceApproved: 'ForceApproved',
    ForceDeclined: 'ForceDeclined'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface AssessmentFlowChangedDto
 */
export interface AssessmentFlowChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssessmentFlowChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssessmentFlowChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AssessmentFlowDto}
     * @memberof AssessmentFlowChangedDto
     */
    'entity': AssessmentFlowDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AssessmentFlowDto
 */
export interface AssessmentFlowDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof AssessmentFlowDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssessmentFlowStateDto}
     * @memberof AssessmentFlowDto
     */
    'state'?: AssessmentFlowStateDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AssessmentFlowDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'damageDetectionAggregateId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'damageCostEvaluationAggregateId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'accessoryCheckIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'damageNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssessmentFlowDto
     */
    'damageCostNegotiationIds'?: Array<string>;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof AssessmentFlowDto
     */
    'negotiationRefs'?: Array<NegotiationReferenceDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowDto
     */
    'damageNegotiationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof AssessmentFlowDto
     */
    'damageCostNegotiationIdsMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface AssessmentFlowReferenceDto
 */
export interface AssessmentFlowReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'contractId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowReferenceDto
     */
    'customerId'?: string;
}
/**
 * 
 * @export
 * @interface AssessmentFlowStateDto
 */
export interface AssessmentFlowStateDto {
    /**
     * 
     * @type {string}
     * @memberof AssessmentFlowStateDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isProcessingDamages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isProcessingDamageCosts'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isDamageCostEvaluating'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentFlowStateDto
     */
    'isDamageCostEvaluated'?: boolean;
    /**
     * 
     * @type {DamageDetectionStatusInContract}
     * @memberof AssessmentFlowStateDto
     */
    'damageDetectionStatus'?: DamageDetectionStatusInContract;
    /**
     * 
     * @type {DamageCostEvaluationStatusInContract}
     * @memberof AssessmentFlowStateDto
     */
    'damageCostEvaluationStatus'?: DamageCostEvaluationStatusInContract;
    /**
     * 
     * @type {ContractAssessmentFlowStateFilterType}
     * @memberof AssessmentFlowStateDto
     */
    'filterState'?: ContractAssessmentFlowStateFilterType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssessmentFlowStateDto
     */
    'lastActivityAt'?: string;
}


/**
 * 
 * @export
 * @interface AssetChangedDto
 */
export interface AssetChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AssetDto}
     * @memberof AssetChangedDto
     */
    'entity': AssetDto;
    /**
     * 
     * @type {string}
     * @memberof AssetChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AssetDto
 */
export interface AssetDto {
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetDto
     */
    'spec'?: AssetSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof AssetDto
     */
    'accessories'?: Array<AccessoryReferenceDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {AssetSettingsDto}
     * @memberof AssetDto
     */
    'settings'?: AssetSettingsDto;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof AssetDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof AssetDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetDto
     */
    'updatedBy'?: string | null;
}


/**
 * Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const AssetEntityType = {
    None: 'None',
    Vehicle: 'Vehicle',
    Accessory: 'Accessory'
} as const;

export type AssetEntityType = typeof AssetEntityType[keyof typeof AssetEntityType];


/**
 * 
 * @export
 * @interface AssetFullReferenceDto
 */
export interface AssetFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetFullReferenceDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetFullReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetFullReferenceDto
     */
    'spec'?: AssetSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof AssetFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetFullReferenceDto
     */
    'poolIds'?: Array<string>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetFullReferenceDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface AssetMetaDto
 */
export interface AssetMetaDto {
    /**
     * 
     * @type {string}
     * @memberof AssetMetaDto
     */
    'assetId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetMetaDto
     */
    'hasAsset'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetReferenceDto
 */
export interface AssetReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetReferenceDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof AssetReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
}


/**
 * 
 * @export
 * @interface AssetSettingsDto
 */
export interface AssetSettingsDto {
    /**
     * Whether asset spec should be synced with the source entity spec when it updates.
     * @type {boolean}
     * @memberof AssetSettingsDto
     */
    'isSyncSpec'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSpecDto
 */
export interface AssetSpecDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof AssetSpecDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof AssetSpecDto
     */
    'vehicle'?: VehicleSpecDto;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionChangedDto
 */
export interface AssetSubscriptionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetSubscriptionChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetSubscriptionChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AssetSubscriptionDto}
     * @memberof AssetSubscriptionChangedDto
     */
    'entity': AssetSubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionDto
 */
export interface AssetSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof AssetSubscriptionDto
     */
    'plan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoDto}
     * @memberof AssetSubscriptionDto
     */
    'checkOutSpotInfo'?: AssetSubscriptionSpotInfoDto;
    /**
     * 
     * @type {AssetSubscriptionSpotInfoDto}
     * @memberof AssetSubscriptionDto
     */
    'checkInSpotInfo'?: AssetSubscriptionSpotInfoDto;
    /**
     * 
     * @type {SubscriptionAssetDto}
     * @memberof AssetSubscriptionDto
     */
    'asset'?: SubscriptionAssetDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof AssetSubscriptionDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionDto
     */
    'basePrice'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionDto
     */
    'price'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {SubscriptionPlanDurationDto}
     * @memberof AssetSubscriptionDto
     */
    'duration'?: SubscriptionPlanDurationDto;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionDto>}
     * @memberof AssetSubscriptionDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionDto> | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof AssetSubscriptionDto
     */
    'prevStatus'?: SubscriptionStatus;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof AssetSubscriptionDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'endsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'unpaidAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pastDueAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'pausedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'canceledAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionDto
     */
    'mustConfirmAllocation'?: boolean;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionPlanChangedDto
 */
export interface AssetSubscriptionPlanChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {AssetSubscriptionPlanDto}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'entity': AssetSubscriptionPlanDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface AssetSubscriptionPlanDto
 */
export interface AssetSubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'tenantId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<AssetSubscriptionPlanProductLocationDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'productLocations'?: Array<AssetSubscriptionPlanProductLocationDto> | null;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AssetSpecDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'assetSpec'?: AssetSpecDto;
    /**
     * 
     * @type {SubscriptionPlanSettingsDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'settings'?: SubscriptionPlanSettingsDto;
    /**
     * 
     * @type {AssetSubscriptionPlanSettingsDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'settings2'?: AssetSubscriptionPlanSettingsDto;
    /**
     * 
     * @type {GeneralAttachmentDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'image'?: GeneralAttachmentDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'images'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {GeneralCountryDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'country'?: GeneralCountryDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof AssetSubscriptionPlanDto
     */
    'basePrice'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'alternativePrices'?: Array<SubscriptionPlanPriceDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanDurationDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'durations'?: Array<SubscriptionPlanDurationDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanIncludedOptionDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'includedOptions'?: Array<SubscriptionPlanIncludedOptionDto> | null;
    /**
     * 
     * @type {Array<SubscriptionPlanExtraOptionDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'extraOptions'?: Array<SubscriptionPlanExtraOptionDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetSubscriptionPlanDto
     */
    'poolIds'?: Array<string>;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'prices'?: Array<SubscriptionPlanPriceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'canAddPools'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanDto
     */
    'canRemovePools'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof AssetSubscriptionPlanDto
     */
    'allAttachments'?: Array<GeneralAttachmentDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanProductLocationDto
 */
export interface AssetSubscriptionPlanProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof AssetSubscriptionPlanProductLocationDto
     */
    'pool'?: PoolReferenceDto;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanReferenceDto
 */
export interface AssetSubscriptionPlanReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionPlanReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionPlanSettingsDto
 */
export interface AssetSubscriptionPlanSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AssetSubscriptionPlanSettingsDto
     */
    'isEnsurePoolBelongToSinglePlan'?: boolean;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionReferenceDto
 */
export interface AssetSubscriptionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionSpotInfoDto
 */
export interface AssetSubscriptionSpotInfoDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {SpotFullReferenceDto}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'spot'?: SpotFullReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionSpotInfoDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface AssetSubscriptionUpdateDto
 */
export interface AssetSubscriptionUpdateDto {
    /**
     * Number of external contract, agreement, etc.
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetSubscriptionUpdateDto
     */
    'locationId'?: string | null;
}
/**
 * Describes a way of creating something in terms of automation.
 * @export
 * @enum {string}
 */

export const AutomationCreateMode = {
    None: 'None',
    Manual: 'Manual',
    Automatic: 'Automatic'
} as const;

export type AutomationCreateMode = typeof AutomationCreateMode[keyof typeof AutomationCreateMode];


/**
 * 
 * @export
 * @interface BaseEntityChangedDto
 */
export interface BaseEntityChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEntityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof BaseEntityChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {string}
     * @memberof BaseEntityChangedDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface BaseInAppNotificationDataDto
 */
export interface BaseInAppNotificationDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof BaseInAppNotificationDataDto
     */
    'type'?: InAppNotificationType;
}


/**
 * 
 * @export
 * @interface BaseSignalRServerDto
 */
export interface BaseSignalRServerDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseSignalRServerDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BaseSignalRServerDto
     */
    'groupName'?: string | null;
}
/**
 * Describes time period of billing for goods.  In the end of every billing period, customer should pay.
 * @export
 * @enum {string}
 */

export const BillingPeriod = {
    None: 'None',
    Month: 'Month',
    Year: 'Year'
} as const;

export type BillingPeriod = typeof BillingPeriod[keyof typeof BillingPeriod];


/**
 * 
 * @export
 * @interface ChatAcknowledgedDto
 */
export interface ChatAcknowledgedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatAcknowledgedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ChatMessageAcknowledgementDto}
     * @memberof ChatAcknowledgedDto
     */
    'acknowledgement'?: ChatMessageAcknowledgementDto;
}
/**
 * 
 * @export
 * @interface ChatAcknowledgedInAppDataDto
 */
export interface ChatAcknowledgedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatAcknowledgedInAppDataDto
     */
    'acknowledgedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatActivityCategory = {
    None: 'None',
    Chat: 'Chat',
    Message: 'Message',
    Negotiation: 'Negotiation'
} as const;

export type ChatActivityCategory = typeof ChatActivityCategory[keyof typeof ChatActivityCategory];


/**
 * 
 * @export
 * @interface ChatActivityChangedDto
 */
export interface ChatActivityChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatActivityChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatActivityChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatActivityDto}
     * @memberof ChatActivityChangedDto
     */
    'entity': ChatActivityDto;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatActivityDto
 */
export interface ChatActivityDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventReferenceDto}
     * @memberof ChatActivityDto
     */
    'createdFromEvent'?: ChatEventReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ChatActivityDto
     */
    'performedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {ChatActivityCategory}
     * @memberof ChatActivityDto
     */
    'category'?: ChatActivityCategory;
    /**
     * 
     * @type {ChatActivityType}
     * @memberof ChatActivityDto
     */
    'type'?: ChatActivityType;
    /**
     * 
     * @type {boolean}
     * @memberof ChatActivityDto
     */
    'isSystem'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityDto
     */
    'performedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatActivityOverviewDto
 */
export interface ChatActivityOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'chatId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastActivityAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastEventAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewDto
     */
    'lastMessageAt'?: string | null;
    /**
     * 
     * @type {Array<ChatActivityOverviewParticipantInfoDto>}
     * @memberof ChatActivityOverviewDto
     */
    'participants'?: Array<ChatActivityOverviewParticipantInfoDto>;
    /**
     * 
     * @type {ChatActivityOverviewParticipantInfoDto}
     * @memberof ChatActivityOverviewDto
     */
    'currentParticipant'?: ChatActivityOverviewParticipantInfoDto;
}
/**
 * 
 * @export
 * @interface ChatActivityOverviewParticipantInfoDto
 */
export interface ChatActivityOverviewParticipantInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'participantId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastActivityAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastEventAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatActivityOverviewParticipantInfoDto
     */
    'lastMessageAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatActivityPerformedDto
 */
export interface ChatActivityPerformedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatActivityPerformedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatActivityPerformedDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatActivityDto}
     * @memberof ChatActivityPerformedDto
     */
    'activity'?: ChatActivityDto;
    /**
     * 
     * @type {ChatActivityOverviewDto}
     * @memberof ChatActivityPerformedDto
     */
    'activityOverview'?: ChatActivityOverviewDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatActivityType = {
    None: 'None',
    ChatAcknowledged: 'ChatAcknowledged',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven'
} as const;

export type ChatActivityType = typeof ChatActivityType[keyof typeof ChatActivityType];


/**
 * 
 * @export
 * @interface ChatChangedDto
 */
export interface ChatChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatChangedDto
     */
    'entity': ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatDto
 */
export interface ChatDto {
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof ChatDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {Array<NegotiationReferenceDto>}
     * @memberof ChatDto
     */
    'negotiations'?: Array<NegotiationReferenceDto>;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatDto
     */
    'type'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {ChatSettingsDto}
     * @memberof ChatDto
     */
    'settings'?: ChatSettingsDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isResolved'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'resolvedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'resolvedByParticipantId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'reopenedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatDto
     */
    'reopenedByParticipantId'?: string | null;
    /**
     * 
     * @type {Array<ChatParticipantReferenceDto>}
     * @memberof ChatDto
     */
    'participants'?: Array<ChatParticipantReferenceDto>;
    /**
     * 
     * @type {number}
     * @memberof ChatDto
     */
    'pinnedItemCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isScoped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatDto
     */
    'isRestrictedParticipants'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatEventCategory = {
    None: 'None',
    Chat: 'Chat',
    Participant: 'Participant',
    Message: 'Message',
    Negotiation: 'Negotiation'
} as const;

export type ChatEventCategory = typeof ChatEventCategory[keyof typeof ChatEventCategory];


/**
 * 
 * @export
 * @interface ChatEventChangedDto
 */
export interface ChatEventChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatEventChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatEventChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatEventChangedDto
     */
    'entity': ChatEventDto;
    /**
     * 
     * @type {string}
     * @memberof ChatEventChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatEventDto
 */
export interface ChatEventDto {
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatEventCategory}
     * @memberof ChatEventDto
     */
    'category'?: ChatEventCategory;
    /**
     * 
     * @type {ChatEventType}
     * @memberof ChatEventDto
     */
    'type'?: ChatEventType;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatEventDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatEventDto
     */
    'body'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatEventDto
     */
    'raisedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatEventDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatEventReceivedDto
 */
export interface ChatEventReceivedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatEventReceivedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReceivedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReceivedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatEventReceivedDto
     */
    'event'?: ChatEventDto;
}
/**
 * 
 * @export
 * @interface ChatEventReferenceDto
 */
export interface ChatEventReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatEventCategory}
     * @memberof ChatEventReferenceDto
     */
    'category'?: ChatEventCategory;
    /**
     * 
     * @type {ChatEventType}
     * @memberof ChatEventReferenceDto
     */
    'type'?: ChatEventType;
    /**
     * 
     * @type {string}
     * @memberof ChatEventReferenceDto
     */
    'body'?: string;
}


/**
 * NB: Web FE reads   Common.Attributes.EnumLinkedToOneAttribute`1 and  Common.Attributes.EnumLinkedToManyAttribute`1.
 * @export
 * @enum {string}
 */

export const ChatEventType = {
    None: 'None',
    ChatCreated: 'ChatCreated',
    ChatUpdated: 'ChatUpdated',
    ChatDeleted: 'ChatDeleted',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    ChatHistoryItemPinned: 'ChatHistoryItemPinned',
    ChatHistoryItemUnpinned: 'ChatHistoryItemUnpinned',
    ParticipantJoined: 'ParticipantJoined',
    ParticipantLeft: 'ParticipantLeft',
    ParticipantAdded: 'ParticipantAdded',
    ParticipantRemoved: 'ParticipantRemoved',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    NegotiationCreated: 'NegotiationCreated',
    NegotiationUpdated: 'NegotiationUpdated',
    NegotiationDeleted: 'NegotiationDeleted',
    NegotiationOpened: 'NegotiationOpened',
    NegotiationReopened: 'NegotiationReopened',
    NegotiationProposalCreated: 'NegotiationProposalCreated',
    NegotiationProposalUpdated: 'NegotiationProposalUpdated',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven',
    NegotiationProposalClosed: 'NegotiationProposalClosed',
    NegotiationResolved: 'NegotiationResolved'
} as const;

export type ChatEventType = typeof ChatEventType[keyof typeof ChatEventType];


/**
 * 
 * @export
 * @interface ChatHistoryItemChangedDto
 */
export interface ChatHistoryItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatHistoryItemChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatHistoryItemChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemChangedDto
     */
    'entity': ChatHistoryItemDto;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatHistoryItemCreatedDto
 */
export interface ChatHistoryItemCreatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemCreatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemCreatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemCreatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemCreatedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @interface ChatHistoryItemDeletedDto
 */
export interface ChatHistoryItemDeletedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemDeletedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDeletedDto
     */
    'itemId'?: string;
}
/**
 * 
 * @export
 * @interface ChatHistoryItemDto
 */
export interface ChatHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatHistoryItemType}
     * @memberof ChatHistoryItemDto
     */
    'type'?: ChatHistoryItemType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatHistoryItemDto
     */
    'message'?: ChatMessageDto;
    /**
     * 
     * @type {ChatEventDto}
     * @memberof ChatHistoryItemDto
     */
    'event'?: ChatEventDto;
    /**
     * 
     * @type {NegotiationProposalDto}
     * @memberof ChatHistoryItemDto
     */
    'negotiationProposal'?: NegotiationProposalDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryItemDto
     */
    'isPinned'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'pinnedByParticipant'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatHistoryItemDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface ChatHistoryItemPinStatusChangedDto
 */
export interface ChatHistoryItemPinStatusChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemPinStatusChangedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHistoryItemType = {
    None: 'None',
    ChatMessage: 'ChatMessage',
    ChatEvent: 'ChatEvent',
    NegotiationProposal: 'NegotiationProposal'
} as const;

export type ChatHistoryItemType = typeof ChatHistoryItemType[keyof typeof ChatHistoryItemType];


/**
 * 
 * @export
 * @interface ChatHistoryItemUpdatedDto
 */
export interface ChatHistoryItemUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatHistoryItemDto}
     * @memberof ChatHistoryItemUpdatedDto
     */
    'item'?: ChatHistoryItemDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHubClientMethodName = {
    TestNotify: 'TestNotify',
    Broadcast: 'Broadcast',
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    ChatActivityPerformed: 'ChatActivityPerformed',
    ChatUpdated: 'ChatUpdated',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    UserAddedToChat: 'UserAddedToChat',
    ParticipantConnected: 'ParticipantConnected',
    ParticipantDisconnected: 'ParticipantDisconnected',
    ParticipantStatusUpdated: 'ParticipantStatusUpdated',
    ParticipantJoined: 'ParticipantJoined',
    ParticipantLeft: 'ParticipantLeft',
    ParticipantsAdded: 'ParticipantsAdded',
    ParticipantRemoved: 'ParticipantRemoved',
    MessageSent: 'MessageSent',
    MessageUpdated: 'MessageUpdated',
    MessageDeleted: 'MessageDeleted',
    MessageAcknowledged: 'MessageAcknowledged',
    MessagesRead: 'MessagesRead',
    ChatEventReceived: 'ChatEventReceived',
    ChatHistoryItemCreated: 'ChatHistoryItemCreated',
    ChatHistoryItemUpdated: 'ChatHistoryItemUpdated',
    ChatHistoryItemDeleted: 'ChatHistoryItemDeleted',
    ChatHistoryItemPinStatusChanged: 'ChatHistoryItemPinStatusChanged'
} as const;

export type ChatHubClientMethodName = typeof ChatHubClientMethodName[keyof typeof ChatHubClientMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatHubServerMethodName = {
    Echo: 'Echo',
    ConnectToChat: 'ConnectToChat',
    DisconnectFromChat: 'DisconnectFromChat',
    ReportMyChatParticipantStatus: 'ReportMyChatParticipantStatus',
    SubscribeOnChatActivityUpdates: 'SubscribeOnChatActivityUpdates',
    UnsubscribeFromChatActivityUpdates: 'UnsubscribeFromChatActivityUpdates'
} as const;

export type ChatHubServerMethodName = typeof ChatHubServerMethodName[keyof typeof ChatHubServerMethodName];


/**
 * 
 * @export
 * @interface ChatMessageAcknowledgedDto
 */
export interface ChatMessageAcknowledgedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageAcknowledgedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedDto
     */
    'messageId'?: string;
    /**
     * 
     * @type {ChatMessageAcknowledgementDto}
     * @memberof ChatMessageAcknowledgedDto
     */
    'acknowledgement'?: ChatMessageAcknowledgementDto;
}
/**
 * 
 * @export
 * @interface ChatMessageAcknowledgedInAppDataDto
 */
export interface ChatMessageAcknowledgedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgedInAppDataDto
     */
    'acknowledgedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatMessageAcknowledgementDto
 */
export interface ChatMessageAcknowledgementDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageAcknowledgementDto
     */
    'isAcknowledged'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageAcknowledgementDto
     */
    'acknowledgedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageChangedDto
 */
export interface ChatMessageChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatMessageChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatMessageChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageChangedDto
     */
    'entity': ChatMessageDto;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatMessageDeletedDto
 */
export interface ChatMessageDeletedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageDeletedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDeletedDto
     */
    'messageId'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageDto
 */
export interface ChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatMessageDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {ChatMessageRootNodeDto}
     * @memberof ChatMessageDto
     */
    'rootNode'?: ChatMessageRootNodeDto;
    /**
     * 
     * @type {Array<ChatMessageAcknowledgementDto>}
     * @memberof ChatMessageDto
     */
    'acknowledgements'?: Array<ChatMessageAcknowledgementDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof ChatMessageDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {Array<ChatMessageReadByInfoDto>}
     * @memberof ChatMessageDto
     */
    'readByInfo'?: Array<ChatMessageReadByInfoDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ChatMessageDto
     */
    'bodyTags'?: GeneralAttachedTagsDto;
}
/**
 * 
 * @export
 * @interface ChatMessageNodeDto
 */
export interface ChatMessageNodeDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageNodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {ChatMessageNodeType}
     * @memberof ChatMessageNodeDto
     */
    'type'?: ChatMessageNodeType;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeDto
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMessageNodeDto
     */
    'endIndex'?: number;
    /**
     * 
     * @type {ChatMessageTextNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'text'?: ChatMessageTextNodeDataDto;
    /**
     * 
     * @type {ChatMessageWebLinkNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'webLink'?: ChatMessageWebLinkNodeDataDto;
    /**
     * 
     * @type {ChatMessageTagNodeDataDto}
     * @memberof ChatMessageNodeDto
     */
    'tag'?: ChatMessageTagNodeDataDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChatMessageNodeType = {
    None: 'None',
    Root: 'Root',
    Text: 'Text',
    WebLink: 'WebLink',
    Tag: 'Tag'
} as const;

export type ChatMessageNodeType = typeof ChatMessageNodeType[keyof typeof ChatMessageNodeType];


/**
 * 
 * @export
 * @interface ChatMessageReadByInfoDto
 */
export interface ChatMessageReadByInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ChatMessageReadByInfoDto
     */
    'who'?: GeneralByWhoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'readAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageReadByInfoDto
     */
    'computedId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageRootNodeDto
 */
export interface ChatMessageRootNodeDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageRootNodeDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ChatMessageNodeDto>}
     * @memberof ChatMessageRootNodeDto
     */
    'nodes'?: Array<ChatMessageNodeDto>;
}
/**
 * 
 * @export
 * @interface ChatMessageSentDto
 */
export interface ChatMessageSentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageSentDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSentDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageSentDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageSentDto
     */
    'message'?: ChatMessageDto;
}
/**
 * 
 * @export
 * @interface ChatMessageTagNodeDataDto
 */
export interface ChatMessageTagNodeDataDto {
    /**
     * 
     * @type {GeneralTagDto}
     * @memberof ChatMessageTagNodeDataDto
     */
    'tag'?: GeneralTagDto;
}
/**
 * 
 * @export
 * @interface ChatMessageTextNodeDataDto
 */
export interface ChatMessageTextNodeDataDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageTextNodeDataDto
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessageUpdatedDto
 */
export interface ChatMessageUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ChatMessageUpdatedDto
     */
    'message'?: ChatMessageDto;
}
/**
 * 
 * @export
 * @interface ChatMessageWebLinkNodeDataDto
 */
export interface ChatMessageWebLinkNodeDataDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageWebLinkNodeDataDto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ChatMessagesReadDto
 */
export interface ChatMessagesReadDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessagesReadDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesReadDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessagesReadDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessagesReadDto
     */
    'messageIds'?: Array<string>;
    /**
     * 
     * @type {ChatMessageReadByInfoDto}
     * @memberof ChatMessagesReadDto
     */
    'readByInfo'?: ChatMessageReadByInfoDto;
}
/**
 * 
 * @export
 * @interface ChatNegotiationProposalCreatedInAppDataDto
 */
export interface ChatNegotiationProposalCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalCreatedInAppDataDto
     */
    'createdByParticipantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatNegotiationProposalResponseGivenInAppDataDto
 */
export interface ChatNegotiationProposalResponseGivenInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatNegotiationProposalResponseGivenInAppDataDto
     */
    'respondedParticipantId'?: string | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantAddedInAppDataDto
 */
export interface ChatParticipantAddedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantAddedInAppDataDto
     */
    'addedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantChangedDto
 */
export interface ChatParticipantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatParticipantChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatParticipantChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantChangedDto
     */
    'entity': ChatParticipantDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantConnectedDto
 */
export interface ChatParticipantConnectedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantConnectedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantConnectedDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface ChatParticipantDisconnectedDto
 */
export interface ChatParticipantDisconnectedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantDisconnectedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDisconnectedDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface ChatParticipantDto
 */
export interface ChatParticipantDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {Array<PartyAndMemberReferenceDto>}
     * @memberof ChatParticipantDto
     */
    'parties'?: Array<PartyAndMemberReferenceDto> | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ChatParticipantDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantDto
     */
    'isAcknowledged'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'acknowledgedAt'?: string | null;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof ChatParticipantDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'addedByParticipantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'removedByParticipantId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'updatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'joinedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'leftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ChatParticipantDto
     */
    'lastConnectedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantDto
     */
    'isSystem'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatParticipantJoinedDto
 */
export interface ChatParticipantJoinedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantJoinedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantJoinedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantJoinedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantJoinedDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantLeftDto
 */
export interface ChatParticipantLeftDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantLeftDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantLeftDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantLeftDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantLeftDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantMentionedInAppDataDto
 */
export interface ChatParticipantMentionedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantMentionedInAppDataDto
     */
    'mentionedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantReferenceDto
 */
export interface ChatParticipantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof ChatParticipantReferenceDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantReferenceDto
     */
    'isSystem'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatParticipantRemovedDto
 */
export interface ChatParticipantRemovedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantRemovedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof ChatParticipantRemovedDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface ChatParticipantRemovedInAppDataDto
 */
export interface ChatParticipantRemovedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantRemovedInAppDataDto
     */
    'removedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatParticipantStatusUpdatedDto
 */
export interface ChatParticipantStatusUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantOnlineStatus}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'onlineStatus'?: ParticipantOnlineStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ChatParticipantStatusUpdatedDto
     */
    'isReportBack'?: boolean | null;
}


/**
 * 
 * @export
 * @interface ChatParticipantsAddedDto
 */
export interface ChatParticipantsAddedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatParticipantsAddedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsAddedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatParticipantsAddedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {Array<ChatParticipantDto>}
     * @memberof ChatParticipantsAddedDto
     */
    'participants'?: Array<ChatParticipantDto>;
}
/**
 * 
 * @export
 * @interface ChatPartyLimit
 */
export interface ChatPartyLimit {
    /**
     * From less to more privileged.  Usually parties are processed according to the sort order,   because they might have different level of privileges, which affects business logic.
     * @type {number}
     * @memberof ChatPartyLimit
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {PartyReference}
     * @memberof ChatPartyLimit
     */
    'party'?: PartyReference;
}
/**
 * 
 * @export
 * @interface ChatReferenceDto
 */
export interface ChatReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatReferenceDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatReferenceDto
     */
    'type'?: ChatType;
}


/**
 * 
 * @export
 * @interface ChatReopenedDto
 */
export interface ChatReopenedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatReopenedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatReopenedDto
     */
    'chat'?: ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedDto
     */
    'reopenedByParticipantId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatReopenedInAppDataDto
 */
export interface ChatReopenedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatReopenedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatReopenedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatReopenedInAppDataDto
     */
    'reopenedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatResolvedDto
 */
export interface ChatResolvedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatResolvedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatResolvedDto
     */
    'chat'?: ChatDto;
}
/**
 * 
 * @export
 * @interface ChatResolvedInAppDataDto
 */
export interface ChatResolvedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof ChatResolvedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedInAppDataDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatType}
     * @memberof ChatResolvedInAppDataDto
     */
    'chatType'?: ChatType;
    /**
     * 
     * @type {string}
     * @memberof ChatResolvedInAppDataDto
     */
    'resolvedByParticipantId'?: string;
}


/**
 * 
 * @export
 * @interface ChatSettingsDto
 */
export interface ChatSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowJoin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowLeave'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowPartyMemberJoin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowParticipantManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowResolution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowAcknowledgement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowMessageAcknowledgement'?: boolean;
    /**
     * 
     * @type {Array<ChatPartyLimit>}
     * @memberof ChatSettingsDto
     */
    'limitedToParties'?: Array<ChatPartyLimit>;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowEdit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowPinnedItems'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChatSettingsDto
     */
    'maxPinnedItems'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'isPinNegotiations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowSendMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowMessageAttachments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowEditMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowDeleteMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'allowNegotiations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatSettingsDto
     */
    'isNotifyAboutNewMessageViaEmail'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChatType = {
    None: 'None',
    Personal: 'Personal',
    Group: 'Group',
    Comment: 'Comment',
    Negotiation: 'Negotiation'
} as const;

export type ChatType = typeof ChatType[keyof typeof ChatType];


/**
 * 
 * @export
 * @interface ChatUpdatedDto
 */
export interface ChatUpdatedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatUpdatedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatDto}
     * @memberof ChatUpdatedDto
     */
    'chat'?: ChatDto;
    /**
     * 
     * @type {string}
     * @memberof ChatUpdatedDto
     */
    'participantId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsChangedDto
 */
export interface ChatUserSettingsChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatUserSettingsChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ChatUserSettingsChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ChatUserSettingsChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ChatUserSettingsDto}
     * @memberof ChatUserSettingsChangedDto
     */
    'entity': ChatUserSettingsDto;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ChatUserSettingsDto
 */
export interface ChatUserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'chatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatUserSettingsDto
     */
    'participantId'?: string | null;
    /**
     * 
     * @type {ChatUserSettingsEvent}
     * @memberof ChatUserSettingsDto
     */
    'event'?: ChatUserSettingsEvent;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsEvent
 */
export interface ChatUserSettingsEvent {
    /**
     * 
     * @type {ChatUserSettingsEventDisabledCategoriesMap}
     * @memberof ChatUserSettingsEvent
     */
    'disabledCategoriesMap'?: ChatUserSettingsEventDisabledCategoriesMap;
    /**
     * 
     * @type {ChatUserSettingsEventDisabledTypesMap}
     * @memberof ChatUserSettingsEvent
     */
    'disabledTypesMap'?: ChatUserSettingsEventDisabledTypesMap;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsEventDisabledCategoriesMap
 */
export interface ChatUserSettingsEventDisabledCategoriesMap {
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'Chat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'Participant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'Message'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'Negotiation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledCategoriesMap
     */
    'Unknown'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatUserSettingsEventDisabledTypesMap
 */
export interface ChatUserSettingsEventDisabledTypesMap {
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatCreated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatResolved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatReopened'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatAcknowledged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatHistoryItemPinned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ChatHistoryItemUnpinned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ParticipantJoined'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ParticipantLeft'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ParticipantAdded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'ParticipantRemoved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'MessageSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'MessageUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'MessageDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'MessageAcknowledged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationCreated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationOpened'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationReopened'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationProposalCreated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationProposalUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationProposalResponseGiven'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationProposalClosed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'NegotiationResolved'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'Unknown'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatUserSettingsEventDisabledTypesMap
     */
    'UserAddedToChat'?: boolean;
}
/**
 * 
 * @export
 * @interface ConnectToChatDto
 */
export interface ConnectToChatDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectToChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectToChatDto
     */
    'participantId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConsensusType = {
    None: 'None',
    Consensus: 'Consensus',
    ForcedConsensus: 'ForcedConsensus',
    NoConsensus: 'NoConsensus'
} as const;

export type ConsensusType = typeof ConsensusType[keyof typeof ConsensusType];


/**
 * Describes types of communication channels between company and customer.
 * @export
 * @enum {string}
 */

export const ContactChannel = {
    None: 'None',
    InPerson: 'InPerson',
    Email: 'Email',
    VoiceCall: 'VoiceCall',
    Sms: 'Sms',
    Chat: 'Chat'
} as const;

export type ContactChannel = typeof ContactChannel[keyof typeof ContactChannel];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractAssessmentFlowStateFilterType = {
    None: 'None',
    All: 'All',
    Pending: 'Pending',
    ActionRequired: 'ActionRequired'
} as const;

export type ContractAssessmentFlowStateFilterType = typeof ContractAssessmentFlowStateFilterType[keyof typeof ContractAssessmentFlowStateFilterType];


/**
 * 
 * @export
 * @interface ContractChangedDto
 */
export interface ContractChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ContractChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ContractChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ContractDto}
     * @memberof ContractChangedDto
     */
    'entity': ContractDto;
    /**
     * 
     * @type {string}
     * @memberof ContractChangedDto
     */
    'tenantId': string | null;
}


/**
 * Describes subject in communication (in scope of a contract) between company and customer.
 * @export
 * @enum {string}
 */

export const ContractCommunicationSubjectType = {
    None: 'None',
    DamageDetection: 'DamageDetection',
    DamageCostEvaluation: 'DamageCostEvaluation',
    Other: 'Other'
} as const;

export type ContractCommunicationSubjectType = typeof ContractCommunicationSubjectType[keyof typeof ContractCommunicationSubjectType];


/**
 * 
 * @export
 * @interface ContractCustomerCommunicationDto
 */
export interface ContractCustomerCommunicationDto {
    /**
     * 
     * @type {Array<ContractCustomerCommunicationEntryDto>}
     * @memberof ContractCustomerCommunicationDto
     */
    'entries'?: Array<ContractCustomerCommunicationEntryDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContacted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContactedOnDamageDetection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationDto
     */
    'isContactedOnDamageCostEvaluation'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedOnDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationDto
     */
    'contactedOnDamageCostEvaluationAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ContractCustomerCommunicationEntryDto
 */
export interface ContractCustomerCommunicationEntryDto {
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'customerContactId'?: string;
    /**
     * 
     * @type {ContactChannel}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'contactChannel'?: ContactChannel;
    /**
     * 
     * @type {Array<ContractCommunicationSubjectType>}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'subjectTypes'?: Array<ContractCommunicationSubjectType>;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'notes'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'contactedAt'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'handledBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {boolean}
     * @memberof ContractCustomerCommunicationEntryDto
     */
    'isAuto'?: boolean;
}


/**
 * 
 * @export
 * @interface ContractDto
 */
export interface ContractDto {
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContractDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof ContractDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof ContractDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof ContractDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfContractStage}
     * @memberof ContractDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfContractStage;
    /**
     * 
     * @type {AllocationStatus}
     * @memberof ContractDto
     */
    'allocationStatus'?: AllocationStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof ContractDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {ContractSettingsDto}
     * @memberof ContractDto
     */
    'settings'?: ContractSettingsDto;
    /**
     * 
     * @type {ContractReminderSettingsDto}
     * @memberof ContractDto
     */
    'reminderSettings'?: ContractReminderSettingsDto;
    /**
     * 
     * @type {ContractCustomerCommunicationDto}
     * @memberof ContractDto
     */
    'customerCommunication'?: ContractCustomerCommunicationDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractDto
     */
    'checkInSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof ContractDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isExternal'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'completedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustConfirmAllocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustPerformVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'mustPerformVisualInspectionBeforeCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isPreparedForCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isPreparedForCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isCheckedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'isCheckedIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canPerformAnyOperation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDto
     */
    'canPerformVisualInspection'?: boolean;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractDto
     */
    'filterType'?: ContractFilterType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractFilterType = {
    None: 'None',
    All: 'All',
    CheckOut: 'CheckOut',
    CheckIn: 'CheckIn'
} as const;

export type ContractFilterType = typeof ContractFilterType[keyof typeof ContractFilterType];


/**
 * 
 * @export
 * @interface ContractFullReferenceDto
 */
export interface ContractFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof ContractFullReferenceDto
     */
    'visualInspectionBeforeCheckOut'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'externalNumber'?: string | null;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractFullReferenceDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfContractStage}
     * @memberof ContractFullReferenceDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfContractStage;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'endsAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof ContractFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {ContractSettingsDto}
     * @memberof ContractFullReferenceDto
     */
    'settings'?: ContractSettingsDto;
    /**
     * 
     * @type {ContractReminderSettingsDto}
     * @memberof ContractFullReferenceDto
     */
    'reminderSettings'?: ContractReminderSettingsDto;
    /**
     * 
     * @type {ContractCustomerCommunicationDto}
     * @memberof ContractFullReferenceDto
     */
    'customerCommunication'?: ContractCustomerCommunicationDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractFullReferenceDto
     */
    'checkOutSpotInfo'?: ContractSpotInfoDto;
    /**
     * 
     * @type {ContractSpotInfoDto}
     * @memberof ContractFullReferenceDto
     */
    'checkInSpotInfo'?: ContractSpotInfoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isExternal'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'draftAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'completedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractFullReferenceDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canReallocate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustConfirmAllocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustPerformVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'mustPerformVisualInspectionBeforeCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isPreparedForCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isPreparedForCheckIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isCheckedOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'isCheckedIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canPerformAnyOperation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractFullReferenceDto
     */
    'canPerformVisualInspection'?: boolean;
    /**
     * 
     * @type {ContractFilterType}
     * @memberof ContractFullReferenceDto
     */
    'filterType'?: ContractFilterType;
}


/**
 * 
 * @export
 * @interface ContractReferenceDto
 */
export interface ContractReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractType}
     * @memberof ContractReferenceDto
     */
    'type'?: ContractType;
    /**
     * 
     * @type {string}
     * @memberof ContractReferenceDto
     */
    'externalNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface ContractReminderDto
 */
export interface ContractReminderDto {
    /**
     * 
     * @type {string}
     * @memberof ContractReminderDto
     */
    'id'?: string;
    /**
     * 
     * @type {ContractReminderType}
     * @memberof ContractReminderDto
     */
    'type'?: ContractReminderType;
    /**
     * 
     * @type {boolean}
     * @memberof ContractReminderDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContractReminderDto
     */
    'jobId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractReminderDto
     */
    'isFired'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractReminderDto
     */
    'nextFireAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractReminderDto
     */
    'previousFireAt'?: string | null;
}


/**
 * 
 * @export
 * @interface ContractReminderSettingsDto
 */
export interface ContractReminderSettingsDto {
    /**
     * 
     * @type {ContractReminderDto}
     * @memberof ContractReminderSettingsDto
     */
    'ofDamageDetectionToCustomer'?: ContractReminderDto;
    /**
     * 
     * @type {ContractReminderDto}
     * @memberof ContractReminderSettingsDto
     */
    'ofDamageCostEvaluationToCustomer'?: ContractReminderDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContractReminderType = {
    None: 'None',
    DamageDetectionToCustomer: 'DamageDetectionToCustomer',
    DamageCostEvaluationToCustomer: 'DamageCostEvaluationToCustomer'
} as const;

export type ContractReminderType = typeof ContractReminderType[keyof typeof ContractReminderType];


/**
 * 
 * @export
 * @interface ContractSettingsDto
 */
export interface ContractSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsDto
     */
    'isRequireVisualInspectionBeforeCheckOut'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSettingsDto
     */
    'isRequireVisualInspectionBeforeCheckIn'?: boolean;
}
/**
 * 
 * @export
 * @interface ContractSpotInfoDto
 */
export interface ContractSpotInfoDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'productLocationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'visualInspectionId'?: string | null;
    /**
     * 
     * @type {SpotFullReferenceDto}
     * @memberof ContractSpotInfoDto
     */
    'spot'?: SpotFullReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof ContractSpotInfoDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContractSpotInfoDto
     */
    'isCompleted'?: boolean;
}
/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const ContractStage = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Active: 'Active',
    Completed: 'Completed',
    Closed: 'Closed'
} as const;

export type ContractStage = typeof ContractStage[keyof typeof ContractStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContractType = {
    None: 'None',
    Rental: 'Rental',
    Leasing: 'Leasing',
    Carsharing: 'Carsharing',
    Proxy: 'Proxy',
    Subscription: 'Subscription'
} as const;

export type ContractType = typeof ContractType[keyof typeof ContractType];


/**
 * ISO 4217 currency code.
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
    None: 'None',
    Usd: 'USD',
    Eur: 'EUR',
    Nok: 'NOK',
    Sek: 'SEK',
    Dkk: 'DKK'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];


/**
 * Additional object to be added under \"debug\" key to Microsoft.AspNetCore.Mvc.ProblemDetails.
 * @export
 * @interface CustomProblemDetailsDebugInfoDto
 */
export interface CustomProblemDetailsDebugInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CustomProblemDetailsDebugInfoDto
     */
    'exceptionMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomProblemDetailsDebugInfoDto
     */
    'stackTrace'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomTagChangedDto
 */
export interface CustomTagChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomTagChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof CustomTagChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof CustomTagChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {CustomTagDto}
     * @memberof CustomTagChangedDto
     */
    'entity': CustomTagDto;
    /**
     * 
     * @type {string}
     * @memberof CustomTagChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface CustomTagDto
 */
export interface CustomTagDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesDto}
     * @memberof CustomTagDto
     */
    'styles'?: TagStylesDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomTagDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomTagDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomTagFullReferenceDto
 */
export interface CustomTagFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TagStylesDto}
     * @memberof CustomTagFullReferenceDto
     */
    'styles'?: TagStylesDto;
}
/**
 * 
 * @export
 * @interface CustomTagReferenceDto
 */
export interface CustomTagReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTagReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CustomerChangedDto
 */
export interface CustomerChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof CustomerChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof CustomerChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {CustomerDto}
     * @memberof CustomerChangedDto
     */
    'entity': CustomerDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface CustomerContactDto
 */
export interface CustomerContactDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'id'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof CustomerContactDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerContactDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralNationalIdentityNumberDto}
     * @memberof CustomerContactDto
     */
    'nationalIdentityNumber'?: GeneralNationalIdentityNumberDto;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerContactDto
     */
    'isPrimary'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<CustomerContactDto>}
     * @memberof CustomerDto
     */
    'contacts'?: Array<CustomerContactDto>;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof CustomerDto
     */
    'address'?: GeneralAddressDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof CustomerDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomerDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {CustomerContactDto}
     * @memberof CustomerDto
     */
    'contact'?: CustomerContactDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface CustomerReferenceDto
 */
export interface CustomerReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {CustomerType}
     * @memberof CustomerReferenceDto
     */
    'type'?: CustomerType;
    /**
     * 
     * @type {string}
     * @memberof CustomerReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {CustomerContactDto}
     * @memberof CustomerReferenceDto
     */
    'contact'?: CustomerContactDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerType = {
    None: 'None',
    B2C: 'B2C',
    B2B: 'B2B'
} as const;

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];


/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateChangedDto
 */
export interface DamageCostEvaluationAggregateChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DamageCostEvaluationAggregateDto}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'entity': DamageCostEvaluationAggregateDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateDataDto
 */
export interface DamageCostEvaluationAggregateDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageCostEvaluationReferenceDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'sourceDamageCostEvaluations'?: Array<DamageCostEvaluationReferenceDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationAggregateItemDataDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'items'?: Array<DamageCostEvaluationAggregateItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'total'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDataDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateDto
 */
export interface DamageCostEvaluationAggregateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageCostEvaluationReferenceDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'sourceDamageCostEvaluations'?: Array<DamageCostEvaluationReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationAggregateItemDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'items'?: Array<DamageCostEvaluationAggregateItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'total'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemDataDto
 */
export interface DamageCostEvaluationAggregateItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DamageCostEvaluationAggregateItemDataDto
     */
    'item'?: DamageCostEvaluationItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemDto
 */
export interface DamageCostEvaluationAggregateItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DamageCostEvaluationAggregateItemDto
     */
    'item'?: DamageCostEvaluationItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateItemReferenceDto
 */
export interface DamageCostEvaluationAggregateItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'damageCostEvaluationAggregateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateReferenceDto
 */
export interface DamageCostEvaluationAggregateReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationAggregateUpdatedInAppDataDto
 */
export interface DamageCostEvaluationAggregateUpdatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationAggregateUpdatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationAggregateUpdatedInAppDataDto
     */
    'damageCostEvaluationAggregateId'?: string;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationChangedDto
 */
export interface DamageCostEvaluationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageCostEvaluationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DamageCostEvaluationDto}
     * @memberof DamageCostEvaluationChangedDto
     */
    'entity': DamageCostEvaluationDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationCompletedInAppDataDto
 */
export interface DamageCostEvaluationCompletedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationCompletedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationCompletedInAppDataDto
     */
    'damageCostEvaluationId'?: string;
}


/**
 * 
 * @export
 * @interface DamageCostEvaluationContentDto
 */
export interface DamageCostEvaluationContentDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationContentDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationContentDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationContentDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationDataDto
 */
export interface DamageCostEvaluationDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationDataDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDamageCostEvaluationStage;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'inspectedAt'?: string | null;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDataDto>}
     * @memberof DamageCostEvaluationDataDto
     */
    'items'?: Array<DamageCostEvaluationItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDataDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationDto
 */
export interface DamageCostEvaluationDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageCostEvaluationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageCostEvaluationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageCostEvaluationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DamageCostEvaluationDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDamageCostEvaluationStage}
     * @memberof DamageCostEvaluationDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDamageCostEvaluationStage;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof DamageCostEvaluationDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'inspectedAt'?: string | null;
    /**
     * 
     * @type {GeneralInspector}
     * @memberof DamageCostEvaluationDto
     */
    'inspector'?: GeneralInspector;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDto>}
     * @memberof DamageCostEvaluationDto
     */
    'items'?: Array<DamageCostEvaluationItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageCostEvaluationDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageCostEvaluationDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DamageCostEvaluationDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageCostEvaluationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDamageDto
 */
export interface DamageCostEvaluationItemDamageDto {
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageCostEvaluationItemDamageDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDataDto
 */
export interface DamageCostEvaluationItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'name'?: string | null;
    /**
     * v2.
     * @type {Array<DamageCostEvaluationItemDamageDto>}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageDto>;
    /**
     * 
     * @type {RepairSpecDataDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'repairSpec'?: RepairSpecDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'negotiatedTax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialTax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialSubTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'initialTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewSubTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewDiscount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedNewTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDataDto
     */
    'isNegotiatedAnyNew'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemDto
 */
export interface DamageCostEvaluationItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<DamageCostEvaluationItemDamageDto>}
     * @memberof DamageCostEvaluationItemDto
     */
    'damages'?: Array<DamageCostEvaluationItemDamageDto>;
    /**
     * 
     * @type {RepairSpecDataDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'repairSpec'?: RepairSpecDataDto;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isRepairSpecChanged'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageCostEvaluationItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'negotiatedTax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialDiscount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialTax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialSubTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'initialTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof DamageCostEvaluationItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageCostEvaluationItemDto
     */
    'total'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewSubTotal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewDiscount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedNewTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageCostEvaluationItemDto
     */
    'isNegotiatedAnyNew'?: boolean;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationItemReferenceDto
 */
export interface DamageCostEvaluationItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'damageCostEvaluationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationReferenceDto
 */
export interface DamageCostEvaluationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageCostEvaluationResultDto
 */
export interface DamageCostEvaluationResultDto {
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationResultDto
     */
    'damageCostEvaluationId'?: string;
}
/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const DamageCostEvaluationStage = {
    None: 'None',
    Draft: 'Draft',
    InProgress: 'InProgress',
    Completed: 'Completed'
} as const;

export type DamageCostEvaluationStage = typeof DamageCostEvaluationStage[keyof typeof DamageCostEvaluationStage];


/**
 * 
 * @export
 * @interface DamageCostEvaluationStartedInAppDataDto
 */
export interface DamageCostEvaluationStartedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'damageCostEvaluationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageCostEvaluationStartedInAppDataDto
     */
    'localNumber'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DamageCostEvaluationStatusInContract = {
    None: 'None',
    NoDamageCost: 'NoDamageCost',
    EvaluatingDamageCost: 'EvaluatingDamageCost',
    DamageCostEvaluated: 'DamageCostEvaluated',
    NegotiatingDamageCost: 'NegotiatingDamageCost',
    DamageCostChanged: 'DamageCostChanged',
    DamageCostAgreed: 'DamageCostAgreed',
    DamageCostForceApproved: 'DamageCostForceApproved'
} as const;

export type DamageCostEvaluationStatusInContract = typeof DamageCostEvaluationStatusInContract[keyof typeof DamageCostEvaluationStatusInContract];


/**
 * 
 * @export
 * @interface DamageDetectedDataDto
 */
export interface DamageDetectedDataDto {
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof DamageDetectedDataDto
     */
    'damage'?: VehicleDamageReferenceDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateChangedDto
 */
export interface DamageDetectionAggregateChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DamageDetectionAggregateDto}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'entity': DamageDetectionAggregateDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionAggregateDataDto
 */
export interface DamageDetectionAggregateDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionAggregateDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionAggregateDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageDetectionReferenceDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'sourceDamageDetections'?: Array<DamageDetectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageDetectionAggregateItemDataDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'items'?: Array<DamageDetectionAggregateItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionAggregateDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDataDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDataDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateDto
 */
export interface DamageDetectionAggregateDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionAggregateDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionAggregateDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionAggregateDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {Array<DamageDetectionReferenceDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'sourceDamageDetections'?: Array<DamageDetectionReferenceDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<DamageDetectionAggregateItemDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'items'?: Array<DamageDetectionAggregateItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionAggregateDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageDetectionAggregateDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageDetectionAggregateDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'updatedBy'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateDto
     */
    'lastInspectedAt'?: string | null;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateDto
     */
    'isSentToCustomer'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemDataDto
 */
export interface DamageDetectionAggregateItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isCostEvaluationStarted'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'costEvaluationStartedAt'?: string | null;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DamageDetectionAggregateItemDataDto
     */
    'item'?: DamageDetectionItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemDto
 */
export interface DamageDetectionAggregateItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isCostEvaluationStarted'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionAggregateItemDto
     */
    'costEvaluationStartedAt'?: string | null;
    /**
     * 
     * @type {GeneralApprovalStatusInfoDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'approval'?: GeneralApprovalStatusInfoDto;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionAggregateItemDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DamageDetectionAggregateItemDto
     */
    'item'?: DamageDetectionItemDataDto;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateItemReferenceDto
 */
export interface DamageDetectionAggregateItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'damageDetectionAggregateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionAggregateReferenceDto
 */
export interface DamageDetectionAggregateReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionAggregateReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionChangedDto
 */
export interface DamageDetectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageDetectionChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageDetectionChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DamageDetectionDto}
     * @memberof DamageDetectionChangedDto
     */
    'entity': DamageDetectionDto;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionContentDto
 */
export interface DamageDetectionContentDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionCreatedInAppDataDto
 */
export interface DamageDetectionCreatedInAppDataDto {
    /**
     * 
     * @type {InAppNotificationType}
     * @memberof DamageDetectionCreatedInAppDataDto
     */
    'type'?: InAppNotificationType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionCreatedInAppDataDto
     */
    'damageDetectionId'?: string;
}


/**
 * 
 * @export
 * @interface DamageDetectionDataDto
 */
export interface DamageDetectionDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDataDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof DamageDetectionDataDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageDetectionDataDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDataDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDataDto
     */
    'fuelLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDataDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<DamageDetectionItemDataDto>}
     * @memberof DamageDetectionDataDto
     */
    'items'?: Array<DamageDetectionItemDataDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface DamageDetectionDto
 */
export interface DamageDetectionDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageDetectionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof DamageDetectionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof DamageDetectionDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof DamageDetectionDto
     */
    'oldVehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof DamageDetectionDto
     */
    'appType'?: FrontEndAppType;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'localNumber'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof DamageDetectionDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamageDetectionDto
     */
    'fuelLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isNoDamagesDetected'?: boolean;
    /**
     * Whether to send to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isSendToCustomer'?: boolean | null;
    /**
     * Whether already sent to the contract customer.
     * @type {boolean}
     * @memberof DamageDetectionDto
     */
    'isSentToCustomer'?: boolean | null;
    /**
     * 
     * @type {Array<DamageDetectionItemDto>}
     * @memberof DamageDetectionDto
     */
    'items'?: Array<DamageDetectionItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof DamageDetectionDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DamageDetectionDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DamageDetectionDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface DamageDetectionItemDataDto
 */
export interface DamageDetectionItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionItemDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof DamageDetectionItemDataDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof DamageDetectionItemDataDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDataDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageDetectionItemDataDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}


/**
 * 
 * @export
 * @interface DamageDetectionItemDto
 */
export interface DamageDetectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageDetectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleArea}
     * @memberof DamageDetectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof DamageDetectionItemDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof DamageDetectionItemDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof DamageDetectionItemDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
}


/**
 * 
 * @export
 * @interface DamageDetectionItemReferenceDto
 */
export interface DamageDetectionItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'damageDetectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionItemReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionReferenceDto
 */
export interface DamageDetectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface DamageDetectionResultDto
 */
export interface DamageDetectionResultDto {
    /**
     * 
     * @type {string}
     * @memberof DamageDetectionResultDto
     */
    'damageDetectionId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DamageDetectionStatusInContract = {
    None: 'None',
    NoDamage: 'NoDamage',
    NewDamageDetected: 'NewDamageDetected',
    NegotiatingDamage: 'NegotiatingDamage',
    DamageAgreed: 'DamageAgreed',
    DamageForceApproved: 'DamageForceApproved'
} as const;

export type DamageDetectionStatusInContract = typeof DamageDetectionStatusInContract[keyof typeof DamageDetectionStatusInContract];


/**
 * 
 * @export
 * @interface DamageRepairedDataDto
 */
export interface DamageRepairedDataDto {
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof DamageRepairedDataDto
     */
    'damage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof DamageRepairedDataDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
}
/**
 * General category of a damage.  Damage should belong to one of these categories for correct mapping to appropriate vehicle parts, for instance.
 * @export
 * @enum {string}
 */

export const DamageTypeCategory = {
    None: 'None',
    Damaged: 'Damaged',
    Scratch: 'Scratch',
    StoneChip: 'StoneChip',
    GlassCrack: 'GlassCrack',
    Dent: 'Dent',
    Paint: 'Paint',
    Smell: 'Smell',
    AbnormalSound: 'AbnormalSound',
    Torn: 'Torn',
    Malfunction: 'Malfunction',
    Stain: 'Stain',
    Missing: 'Missing'
} as const;

export type DamageTypeCategory = typeof DamageTypeCategory[keyof typeof DamageTypeCategory];


/**
 * 
 * @export
 * @interface DamageTypeChangedDto
 */
export interface DamageTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DamageTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DamageTypeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DamageTypeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DamageTypeDto}
     * @memberof DamageTypeChangedDto
     */
    'entity': DamageTypeDto;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DamageTypeDto
 */
export interface DamageTypeDto {
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof DamageTypeDto
     */
    'partType'?: VehiclePartType;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DamageTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DamageTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DamageTypeDto
     */
    'examplePictures'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface DamageTypeReferenceDto
 */
export interface DamageTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {DamageTypeCategory}
     * @memberof DamageTypeReferenceDto
     */
    'category'?: DamageTypeCategory;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamageTypeReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface DataGrantChangedDto
 */
export interface DataGrantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DataGrantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DataGrantChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DataGrantDto}
     * @memberof DataGrantChangedDto
     */
    'entity': DataGrantDto;
    /**
     * 
     * @type {string}
     * @memberof DataGrantChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DataGrantDto
 */
export interface DataGrantDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDto
     */
    'isAuto'?: boolean;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof DataGrantDto
     */
    'issuerTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'issuerUserId'?: string | null;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof DataGrantDto
     */
    'consumerTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantDto
     */
    'permissions'?: Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'expiresAt'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DataGrantDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof DataGrantDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DataGrantDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantDto
     */
    'userIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {DataGrantDtoPermissionsMap}
     * @memberof DataGrantDto
     */
    'permissionsMap'?: DataGrantDtoPermissionsMap;
}


/**
 * 
 * @export
 * @interface DataGrantDtoPermissionsMap
 */
export interface DataGrantDtoPermissionsMap {
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDtoPermissionsMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDtoPermissionsMap
     */
    'Read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDtoPermissionsMap
     */
    'Write'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataGrantDtoPermissionsMap
     */
    'Delete'?: boolean;
}
/**
 * Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const DataGrantEntityType = {
    None: 'None',
    Vehicle: 'Vehicle',
    VehicleHistory: 'VehicleHistory',
    VehicleDamage: 'VehicleDamage',
    Accessory: 'Accessory',
    AccessoryCheck: 'AccessoryCheck',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    RepairOperation: 'RepairOperation'
} as const;

export type DataGrantEntityType = typeof DataGrantEntityType[keyof typeof DataGrantEntityType];


/**
 * Brief info about DataGrants stored in shared entity.
 * @export
 * @interface DataGrantMeta
 */
export interface DataGrantMeta {
    /**
     * 
     * @type {string}
     * @memberof DataGrantMeta
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantMeta
     */
    'type': DataGrantType;
    /**
     * 
     * @type {TenantReference}
     * @memberof DataGrantMeta
     */
    'issuerTenant'?: TenantReference;
    /**
     * 
     * @type {TenantReference}
     * @memberof DataGrantMeta
     */
    'consumerTenant'?: TenantReference;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantMeta
     */
    'grantEntityType': DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantMeta
     */
    'entityId': string;
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof DataGrantMeta
     */
    'permissions': Array<DataGrantPermission>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DataGrantMeta
     */
    'expiresAt': string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantMeta
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityTypeEntityIdPair}
     * @memberof DataGrantMeta
     */
    'entityTypeEntityId'?: EntityTypeEntityIdPair;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataGrantPermission = {
    None: 'None',
    Read: 'Read',
    Write: 'Write',
    Delete: 'Delete'
} as const;

export type DataGrantPermission = typeof DataGrantPermission[keyof typeof DataGrantPermission];


/**
 * 
 * @export
 * @interface DataGrantReferenceDto
 */
export interface DataGrantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {DataGrantType}
     * @memberof DataGrantReferenceDto
     */
    'type'?: DataGrantType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'consumerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantReferenceDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantReferenceDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantReferenceDto
     */
    'entityType'?: EntityType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DataGrantType = {
    None: 'None',
    TenantToTenant: 'TenantToTenant'
} as const;

export type DataGrantType = typeof DataGrantType[keyof typeof DataGrantType];


/**
 * 
 * @export
 * @interface DataGrantsMetaBriefDto
 */
export interface DataGrantsMetaBriefDto {
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaBriefDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantsMetaBriefDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'grantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'consumerTenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: Array<DataGrantPermission>; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'tenantPermissions'?: { [key: string]: Array<DataGrantPermission>; };
    /**
     * 
     * @type {{ [key: string]: DataGrantDtoPermissionsMap; }}
     * @memberof DataGrantsMetaBriefDto
     */
    'tenantPermissionsMap'?: { [key: string]: DataGrantDtoPermissionsMap; };
}


/**
 * 
 * @export
 * @interface DataGrantsMetaDto
 */
export interface DataGrantsMetaDto {
    /**
     * 
     * @type {Array<DataGrantMeta>}
     * @memberof DataGrantsMetaDto
     */
    'grants': Array<DataGrantMeta>;
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaDto
     */
    'issuerTenantId'?: string;
    /**
     * 
     * @type {DataGrantEntityType}
     * @memberof DataGrantsMetaDto
     */
    'grantEntityType'?: DataGrantEntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof DataGrantsMetaDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof DataGrantsMetaDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaDto
     */
    'grantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof DataGrantsMetaDto
     */
    'consumerTenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: Array<DataGrantPermission>; }}
     * @memberof DataGrantsMetaDto
     */
    'tenantPermissions'?: { [key: string]: Array<DataGrantPermission>; };
    /**
     * 
     * @type {{ [key: string]: DataGrantDtoPermissionsMap; }}
     * @memberof DataGrantsMetaDto
     */
    'tenantPermissionsMap'?: { [key: string]: DataGrantDtoPermissionsMap; };
}


/**
 * SignalR client methods that can be called by the server.
 * @export
 * @enum {string}
 */

export const DataUpdatesHubClientMethodName = {
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    EntityChanged: 'EntityChanged'
} as const;

export type DataUpdatesHubClientMethodName = typeof DataUpdatesHubClientMethodName[keyof typeof DataUpdatesHubClientMethodName];


/**
 * SignalR server methods that can be called by clients.
 * @export
 * @enum {string}
 */

export const DataUpdatesHubServerMethodName = {
    Echo: 'Echo',
    SubscribeOnDataUpdates: 'SubscribeOnDataUpdates',
    UnsubscribeFromDataUpdates: 'UnsubscribeFromDataUpdates'
} as const;

export type DataUpdatesHubServerMethodName = typeof DataUpdatesHubServerMethodName[keyof typeof DataUpdatesHubServerMethodName];


/**
 * 
 * @export
 * @interface DateRangeDto
 */
export interface DateRangeDto {
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeDto
     */
    'from'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DateRangeDto
     */
    'to'?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentChangedDto
 */
export interface DepartmentChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DepartmentChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DepartmentChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DepartmentDto}
     * @memberof DepartmentChangedDto
     */
    'entity': DepartmentDto;
    /**
     * 
     * @type {string}
     * @memberof DepartmentChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DepartmentDto
 */
export interface DepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof DepartmentDto
     */
    'address'?: GeneralAddressDto;
    /**
     * 
     * @type {GeneralBrandingDto}
     * @memberof DepartmentDto
     */
    'branding'?: GeneralBrandingDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepartmentDto
     */
    'locationIds'?: Array<string>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DepartmentDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DepartmentDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentReferenceDto
 */
export interface DepartmentReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DisconnectFromChatDto
 */
export interface DisconnectFromChatDto {
    /**
     * 
     * @type {string}
     * @memberof DisconnectFromChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DisconnectFromChatDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountType = {
    None: 'None',
    Trade: 'Trade',
    Bulk: 'Bulk',
    PromptPayment: 'PromptPayment'
} as const;

export type DiscountType = typeof DiscountType[keyof typeof DiscountType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DiscountValueType = {
    None: 'None',
    Percent: 'Percent',
    Value: 'Value'
} as const;

export type DiscountValueType = typeof DiscountValueType[keyof typeof DiscountValueType];


/**
 * 
 * @export
 * @interface DocumentChangedDto
 */
export interface DocumentChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof DocumentChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof DocumentChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {DocumentDto}
     * @memberof DocumentChangedDto
     */
    'entity': DocumentDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'prevDocumentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'contractId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof DocumentDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'sourceLastUpdatedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentDto
     */
    'isLatestForSource'?: boolean;
    /**
     * 
     * @type {Array<DocumentSourcingStrategy>}
     * @memberof DocumentDto
     */
    'sourcingStrategies'?: Array<DocumentSourcingStrategy>;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfDocumentStage}
     * @memberof DocumentDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfDocumentStage;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof DocumentDto
     */
    'status'?: DocumentStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'date'?: string;
    /**
     * 
     * @type {Array<DocumentSectionDto>}
     * @memberof DocumentDto
     */
    'sections'?: Array<DocumentSectionDto>;
    /**
     * 
     * @type {Array<DocumentItemDto>}
     * @memberof DocumentDto
     */
    'items'?: Array<DocumentItemDto>;
    /**
     * 
     * @type {DocumentGeneralDataDto}
     * @memberof DocumentDto
     */
    'generalData'?: DocumentGeneralDataDto;
    /**
     * 
     * @type {DamageDetectionDataDto}
     * @memberof DocumentDto
     */
    'damageDetection'?: DamageDetectionDataDto;
    /**
     * 
     * @type {DamageDetectionAggregateDataDto}
     * @memberof DocumentDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateDataDto;
    /**
     * 
     * @type {DamageCostEvaluationDataDto}
     * @memberof DocumentDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationDataDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateDataDto}
     * @memberof DocumentDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateDataDto;
    /**
     * 
     * @type {AccessoryCheckDataDto}
     * @memberof DocumentDto
     */
    'accessoryCheck'?: AccessoryCheckDataDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof DocumentDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof DocumentDto
     */
    'scopeInfo'?: GeneralScopeInfoDto;
    /**
     * 
     * @type {Array<GeneralScopeInfoDto>}
     * @memberof DocumentDto
     */
    'allScopeInfo'?: Array<GeneralScopeInfoDto>;
}


/**
 * 
 * @export
 * @interface DocumentGeneralDataDto
 */
export interface DocumentGeneralDataDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentGeneralDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DocumentGeneralDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * 
 * @export
 * @interface DocumentItemDto
 */
export interface DocumentItemDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemDto
     */
    'sectionId'?: string | null;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentItemDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentItemDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof DocumentItemDto
     */
    'status'?: DocumentStatus;
    /**
     * 
     * @type {DocumentItemGeneralDataDto}
     * @memberof DocumentItemDto
     */
    'generalData'?: DocumentItemGeneralDataDto;
    /**
     * 
     * @type {DamageDetectionItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageDetectionItem'?: DamageDetectionItemDataDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemDataDto;
    /**
     * 
     * @type {DamageCostEvaluationItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemDataDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemDataDto}
     * @memberof DocumentItemDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemDataDto;
    /**
     * 
     * @type {AccessoryCheckItemDataDto}
     * @memberof DocumentItemDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemDataDto;
    /**
     * 
     * @type {GeneralScopeInfoDto}
     * @memberof DocumentItemDto
     */
    'scopeInfo'?: GeneralScopeInfoDto;
}


/**
 * 
 * @export
 * @interface DocumentItemGeneralDataDto
 */
export interface DocumentItemGeneralDataDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentItemGeneralDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof DocumentItemGeneralDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * 
 * @export
 * @interface DocumentReferenceDto
 */
export interface DocumentReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentReferenceDto
     */
    'type'?: DocumentType;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof DocumentReferenceDto
     */
    'source'?: GeneralEntitySourceDto;
}


/**
 * 
 * @export
 * @interface DocumentSectionDto
 */
export interface DocumentSectionDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentSectionDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentSectionDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSectionDto
     */
    'itemIds'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentSourcingStrategy = {
    None: 'None',
    CreateOnCreate: 'CreateOnCreate',
    UpdateOnUpdate: 'UpdateOnUpdate',
    CreateOnUpdate: 'CreateOnUpdate',
    DeleteOnDelete: 'DeleteOnDelete',
    UpdateOnDelete: 'UpdateOnDelete'
} as const;

export type DocumentSourcingStrategy = typeof DocumentSourcingStrategy[keyof typeof DocumentSourcingStrategy];


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const DocumentStage = {
    None: 'None',
    Draft: 'Draft',
    Negotiating: 'Negotiating',
    Processed: 'Processed',
    Signed: 'Signed',
    Archived: 'Archived'
} as const;

export type DocumentStage = typeof DocumentStage[keyof typeof DocumentStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentStatus = {
    None: 'None'
} as const;

export type DocumentStatus = typeof DocumentStatus[keyof typeof DocumentStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    None: 'None',
    General: 'General',
    Typed: 'Typed'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EmailProviderType = {
    None: 'None',
    Default: 'Default',
    SendGrid: 'SendGrid',
    Mailgun: 'Mailgun',
    AmazonSes: 'AmazonSes',
    Mailchimp: 'Mailchimp',
    Microsoft365: 'Microsoft365',
    AzureCs: 'AzureCs',
    Smtp: 'Smtp'
} as const;

export type EmailProviderType = typeof EmailProviderType[keyof typeof EmailProviderType];


/**
 * Describes type of already applied change.
 * @export
 * @enum {string}
 */

export const EntityChangeType = {
    None: 'None',
    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted'
} as const;

export type EntityChangeType = typeof EntityChangeType[keyof typeof EntityChangeType];


/**
 * 
 * @export
 * @interface EntityChangedDtoOfTEntityDto
 */
export interface EntityChangedDtoOfTEntityDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entityId': string;
    /**
     * 
     * @type {object}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'entity': object;
    /**
     * 
     * @type {string}
     * @memberof EntityChangedDtoOfTEntityDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface EntityImportMetaDto
 */
export interface EntityImportMetaDto {
    /**
     * 
     * @type {string}
     * @memberof EntityImportMetaDto
     */
    'importId'?: string;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof EntityImportMetaDto
     */
    'changeType'?: EntityChangeType;
}


/**
 * Contains metadata related to results of matching/searching an entity by some criteria.
 * @export
 * @interface EntityMatchMetaDto
 */
export interface EntityMatchMetaDto {
    /**
     * Match score.  The higher value the better match.
     * @type {number}
     * @memberof EntityMatchMetaDto
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntitySourceSubType = {
    None: 'None',
    DamageDetection: 'DamageDetection',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheckItem: 'AccessoryCheckItem'
} as const;

export type EntitySourceSubType = typeof EntitySourceSubType[keyof typeof EntitySourceSubType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EntitySourceType = {
    None: 'None',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Document: 'Document'
} as const;

export type EntitySourceType = typeof EntitySourceType[keyof typeof EntitySourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EntityType = {
    None: 'None',
    User: 'User',
    Role: 'Role',
    Company: 'Company',
    Department: 'Department',
    Location: 'Location',
    Spot: 'Spot',
    Vehicle: 'Vehicle',
    VehicleHistory: 'VehicleHistory',
    VehicleDamage: 'VehicleDamage',
    Contract: 'Contract',
    Customer: 'Customer',
    Accessory: 'Accessory',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Document: 'Document',
    DocumentItem: 'DocumentItem',
    Chat: 'Chat',
    ChatParticipant: 'ChatParticipant',
    ChatMessage: 'ChatMessage',
    ChatHistoryItem: 'ChatHistoryItem',
    ChatEvent: 'ChatEvent',
    ChatActivity: 'ChatActivity',
    ChatUserSettings: 'ChatUserSettings',
    Negotiation: 'Negotiation',
    NegotiationProposal: 'NegotiationProposal',
    File: 'File',
    Invoice: 'Invoice',
    Invite: 'Invite',
    VehicleType: 'VehicleType',
    VehicleMake: 'VehicleMake',
    VehicleModel: 'VehicleModel',
    VehicleGeneration: 'VehicleGeneration',
    VehicleModification: 'VehicleModification',
    VehicleBodyType: 'VehicleBodyType',
    VehicleFuelType: 'VehicleFuelType',
    VehiclePartType: 'VehiclePartType',
    VehicleVisualModel: 'VehicleVisualModel',
    DamageType: 'DamageType',
    RepairCatalog: 'RepairCatalog',
    RepairWork: 'RepairWork',
    RepairMaterial: 'RepairMaterial',
    RepairSpec: 'RepairSpec',
    RepairSparePart: 'RepairSparePart',
    SubscriptionPlan: 'SubscriptionPlan',
    Subscription: 'Subscription',
    AssetSubscriptionPlan: 'AssetSubscriptionPlan',
    AssetSubscription: 'AssetSubscription',
    ProductLocation: 'ProductLocation',
    Pool: 'Pool',
    PoolItem: 'PoolItem',
    Asset: 'Asset',
    IntegrationApiClient: 'IntegrationApiClient',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest',
    DataGrant: 'DataGrant',
    AssessmentFlow: 'AssessmentFlow',
    UserSecret: 'UserSecret',
    CustomIdentity: 'CustomIdentity',
    CustomSession: 'CustomSession',
    FlowInstance: 'FlowInstance',
    FlowSpec: 'FlowSpec',
    FlowStepSpec: 'FlowStepSpec',
    GeneralEventLog: 'GeneralEventLog',
    Import: 'Import',
    ImportMappingTemplate: 'ImportMappingTemplate',
    NumberRegistryItem: 'NumberRegistryItem',
    Party: 'Party',
    PartyMember: 'PartyMember',
    TestDamageAssessmentReport: 'TestDamageAssessmentReport',
    Test: 'Test',
    UserVerificationRequest: 'UserVerificationRequest',
    DeviceToken: 'DeviceToken',
    Notification: 'Notification',
    NotificationGroup: 'NotificationGroup',
    NotificationGroupMember: 'NotificationGroupMember',
    NotificationProfile: 'NotificationProfile',
    CustomTag: 'CustomTag',
    Webhook: 'Webhook',
    WebhookActivation: 'WebhookActivation',
    GeneralHistory: 'GeneralHistory',
    TeslaConnection: 'TeslaConnection'
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @interface EntityTypeEntityIdPair
 */
export interface EntityTypeEntityIdPair {
    /**
     * 
     * @type {EntityType}
     * @memberof EntityTypeEntityIdPair
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof EntityTypeEntityIdPair
     */
    'entityId': string;
}


/**
 * 
 * @export
 * @interface FileChangedDto
 */
export interface FileChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FileChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof FileChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof FileChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {FileDto}
     * @memberof FileChangedDto
     */
    'entity': FileDto;
    /**
     * 
     * @type {string}
     * @memberof FileChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'originalFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {Array<FileThumbnailDto>}
     * @memberof FileDto
     */
    'thumbnails'?: Array<FileThumbnailDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'uploadedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {MimeBaseType}
     * @memberof FileDto
     */
    'mimeBaseType'?: MimeBaseType;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FileReferenceDto
 */
export interface FileReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'originalFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'contentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileReferenceDto
     */
    'sizeBytes'?: number;
    /**
     * 
     * @type {Array<FileThumbnailDto>}
     * @memberof FileReferenceDto
     */
    'thumbnails'?: Array<FileThumbnailDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'uploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {MimeBaseType}
     * @memberof FileReferenceDto
     */
    'mimeBaseType'?: MimeBaseType;
    /**
     * 
     * @type {string}
     * @memberof FileReferenceDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileReferenceDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FileThumbnailDto
 */
export interface FileThumbnailDto {
    /**
     * 
     * @type {ThumbnailSizeType}
     * @memberof FileThumbnailDto
     */
    'sizeType'?: ThumbnailSizeType;
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof FileThumbnailDto
     */
    'size'?: GeneralImageSizeDto;
    /**
     * 
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'url'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'urlLifetime'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof FileThumbnailDto
     */
    'urlExpiresAt'?: string | null;
}


/**
 * 
 * @export
 * @interface FilterDefinitionDto
 */
export interface FilterDefinitionDto {
    /**
     * 
     * @type {Array<FilterDefinitionItemDto>}
     * @memberof FilterDefinitionDto
     */
    'items'?: Array<FilterDefinitionItemDto> | null;
}
/**
 * 
 * @export
 * @interface FilterDefinitionItemDto
 */
export interface FilterDefinitionItemDto {
    /**
     * 
     * @type {FilterType}
     * @memberof FilterDefinitionItemDto
     */
    'type'?: FilterType;
    /**
     * 
     * @type {string}
     * @memberof FilterDefinitionItemDto
     */
    'field'?: string;
    /**
     * 
     * @type {FilterOperator}
     * @memberof FilterDefinitionItemDto
     */
    'operator'?: FilterOperator;
    /**
     * 
     * @type {FilterValueType}
     * @memberof FilterDefinitionItemDto
     */
    'valueType'?: FilterValueType;
    /**
     * 
     * @type {any}
     * @memberof FilterDefinitionItemDto
     */
    'value'?: any | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterOperator = {
    None: 'None',
    Equal: 'Equal',
    NotEqual: 'NotEqual',
    Contain: 'Contain',
    NotContain: 'NotContain',
    LessThan: 'LessThan',
    LessThanOrEqual: 'LessThanOrEqual',
    GreaterThan: 'GreaterThan',
    GreaterThanOrEqual: 'GreaterThanOrEqual',
    AnyOf: 'AnyOf',
    NoneOf: 'NoneOf',
    Empty: 'Empty',
    NotEmpty: 'NotEmpty'
} as const;

export type FilterOperator = typeof FilterOperator[keyof typeof FilterOperator];


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterType = {
    None: 'None',
    Static: 'Static',
    Dynamic: 'Dynamic'
} as const;

export type FilterType = typeof FilterType[keyof typeof FilterType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterValueType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    String: 'String',
    Number: 'Number',
    Boolean: 'Boolean',
    Date: 'Date',
    Enum: 'Enum',
    Id: 'Id',
    ArrayOfString: 'ArrayOfString',
    ArrayOfEnum: 'ArrayOfEnum',
    ArrayOfId: 'ArrayOfId'
} as const;

export type FilterValueType = typeof FilterValueType[keyof typeof FilterValueType];


/**
 * Describes params of the action to be automatically performed on the FE page when user opens it.
 * @export
 * @interface FrontEndActionParamsInQueryStringDto
 */
export interface FrontEndActionParamsInQueryStringDto {
    /**
     * 
     * @type {FrontEndActionTypeInQueryString}
     * @memberof FrontEndActionParamsInQueryStringDto
     */
    'actionType'?: FrontEndActionTypeInQueryString;
}


/**
 * Describes type of action to be automatically performed on the FE page when user opens it.
 * @export
 * @enum {string}
 */

export const FrontEndActionTypeInQueryString = {
    None: 'None',
    ApproveAllDamageNegotiationProposals: 'ApproveAllDamageNegotiationProposals',
    DeclineAllDamageNegotiationProposals: 'DeclineAllDamageNegotiationProposals',
    ApproveAllDamageCostNegotiationProposals: 'ApproveAllDamageCostNegotiationProposals',
    DeclineAllDamageCostNegotiationProposals: 'DeclineAllDamageCostNegotiationProposals'
} as const;

export type FrontEndActionTypeInQueryString = typeof FrontEndActionTypeInQueryString[keyof typeof FrontEndActionTypeInQueryString];


/**
 * 
 * @export
 * @enum {string}
 */

export const FrontEndAppType = {
    None: 'None',
    Web: 'Web',
    Mobile: 'Mobile'
} as const;

export type FrontEndAppType = typeof FrontEndAppType[keyof typeof FrontEndAppType];


/**
 * Describes auth params that FE app should apply when loaded.
 * @export
 * @interface FrontEndAuthParamsInQueryStringDto
 */
export interface FrontEndAuthParamsInQueryStringDto {
    /**
     * Auth0 connection to be used when logging in.
     * @type {string}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'preferredAuth0Connection'?: string | null;
    /**
     * The user\'s email address or other identifier.  Used during login to prefill inputs.
     * @type {string}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'auth0LoginHint'?: string | null;
    /**
     * Whether to prefer quick login - skip SPA auth pages (like login, logged out, etc) and redirect   directly to Auth0 login page if user is not authenticated.
     * @type {boolean}
     * @memberof FrontEndAuthParamsInQueryStringDto
     */
    'isPreferQuickLogin'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GeneralAddressDto
 */
export interface GeneralAddressDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAddressDto
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAddressDto
     */
    'isEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralApprovalStatusInfoDto
 */
export interface GeneralApprovalStatusInfoDto {
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof GeneralApprovalStatusInfoDto
     */
    'status'?: ApprovalStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralApprovalStatusInfoDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface GeneralAttachedTagsDto
 */
export interface GeneralAttachedTagsDto {
    /**
     * 
     * @type {Array<GeneralTagDto>}
     * @memberof GeneralAttachedTagsDto
     */
    'tags'?: Array<GeneralTagDto> | null;
}
/**
 * 
 * @export
 * @interface GeneralAttachmentDto
 */
export interface GeneralAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralAttachmentDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralAttachmentDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAttachmentDto
     */
    'isAttachment'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralAvatarDto
 */
export interface GeneralAvatarDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralAvatarDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralAvatarDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralAvatarDto
     */
    'isAvatar'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralBrandingDto
 */
export interface GeneralBrandingDto {
    /**
     * 
     * @type {GeneralLogoResizedDto}
     * @memberof GeneralBrandingDto
     */
    'logo'?: GeneralLogoResizedDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'fullLegalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralBrandingDto
     */
    'disclaimer'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralByWhoDto
 */
export interface GeneralByWhoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {WhoType}
     * @memberof GeneralByWhoDto
     */
    'type'?: WhoType;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof GeneralByWhoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralByWhoDto
     */
    'phone'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralCountryDto
 */
export interface GeneralCountryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'alpha2Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'alpha3Code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'numericCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'officialName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCountryDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GeneralCultureDto
 */
export interface GeneralCultureDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'nativeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'englishName'?: string;
    /**
     * The same as name.
     * @type {string}
     * @memberof GeneralCultureDto
     */
    'id'?: string;
}
/**
 * Represents ISO 4217 currency.  https://en.wikipedia.org/wiki/ISO_4217
 * @export
 * @interface GeneralCurrency
 */
export interface GeneralCurrency {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrency
     */
    'code'?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrency
     */
    'name'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralCurrencyDto
 */
export interface GeneralCurrencyDto {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrencyDto
     */
    'code'?: CurrencyCode;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrencyDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCurrencyDto
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface GeneralCurrencyInputDto
 */
export interface GeneralCurrencyInputDto {
    /**
     * 
     * @type {CurrencyCode}
     * @memberof GeneralCurrencyInputDto
     */
    'code'?: CurrencyCode;
}


/**
 * Represents a percentage/amount from goods price that is deducted from the final price.  Stored as positive number and is always deducted from SubTotal (adding discount to SubTotal is not allowed).
 * @export
 * @interface GeneralDiscount
 */
export interface GeneralDiscount {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscount
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscount
     */
    'valueType'?: DiscountValueType;
    /**
     * Percents [0; 1].
     * @type {number}
     * @memberof GeneralDiscount
     */
    'percent'?: number | null;
    /**
     * Amount in some currency representation.
     * @type {number}
     * @memberof GeneralDiscount
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralDiscount
     */
    'currency'?: GeneralCurrency;
    /**
     * Discount that is a result of merging N discounts into one.
     * @type {boolean}
     * @memberof GeneralDiscount
     */
    'isCompound'?: boolean;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.General.GeneralDiscount\" />
 * @export
 * @interface GeneralDiscountDto
 */
export interface GeneralDiscountDto {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscountDto
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscountDto
     */
    'valueType'?: DiscountValueType;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.Percent\" />
     * @type {number}
     * @memberof GeneralDiscountDto
     */
    'percent'?: number | null;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.Value\" />
     * @type {number}
     * @memberof GeneralDiscountDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralDiscountDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.General.GeneralDiscount.IsCompound\" />
     * @type {boolean}
     * @memberof GeneralDiscountDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralDiscountInputDto
 */
export interface GeneralDiscountInputDto {
    /**
     * 
     * @type {DiscountType}
     * @memberof GeneralDiscountInputDto
     */
    'type'?: DiscountType;
    /**
     * 
     * @type {DiscountValueType}
     * @memberof GeneralDiscountInputDto
     */
    'valueType'?: DiscountValueType;
    /**
     * 
     * @type {number}
     * @memberof GeneralDiscountInputDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralDiscountInputDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralDiscountInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralDiscountInputDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralEntitySourceDto
 */
export interface GeneralEntitySourceDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'sourceSubId'?: string | null;
    /**
     * 
     * @type {EntitySourceType}
     * @memberof GeneralEntitySourceDto
     */
    'type'?: EntitySourceType;
    /**
     * 
     * @type {EntitySourceSubType}
     * @memberof GeneralEntitySourceDto
     */
    'subType'?: EntitySourceSubType;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {DocumentReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'document'?: DocumentReferenceDto;
    /**
     * 
     * @type {DamageDetectionItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionItem'?: DamageDetectionItemReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {AccessoryCheckItemReferenceDto}
     * @memberof GeneralEntitySourceDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'globalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEntitySourceDto
     */
    'localNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralEntitySubTypeDto
 */
export interface GeneralEntitySubTypeDto {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'entitySubType'?: EntityType;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'assetEntityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof GeneralEntitySubTypeDto
     */
    'poolItemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface GeneralEventLogChangedDto
 */
export interface GeneralEventLogChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralEventLogChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof GeneralEventLogChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralEventLogChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {GeneralEventLogDto}
     * @memberof GeneralEventLogChangedDto
     */
    'entity': GeneralEventLogDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface GeneralEventLogDto
 */
export interface GeneralEventLogDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof GeneralEventLogDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralEventLogType}
     * @memberof GeneralEventLogDto
     */
    'type'?: GeneralEventLogType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof GeneralEventLogDto
     */
    'raisedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof GeneralEventLogDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralEventLogDto
     */
    'body'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralEventLogType = {
    None: 'None',
    NegotiationCreated: 'NegotiationCreated',
    NegotiationUpdated: 'NegotiationUpdated',
    NegotiationOpened: 'NegotiationOpened',
    NegotiationReopened: 'NegotiationReopened',
    NegotiationResolved: 'NegotiationResolved',
    NegotiationProposalCreated: 'NegotiationProposalCreated',
    NegotiationProposalUpdated: 'NegotiationProposalUpdated',
    NegotiationProposalResponseGiven: 'NegotiationProposalResponseGiven',
    NegotiationProposalAutoClosed: 'NegotiationProposalAutoClosed',
    NegotiationProposalClosed: 'NegotiationProposalClosed'
} as const;

export type GeneralEventLogType = typeof GeneralEventLogType[keyof typeof GeneralEventLogType];


/**
 * 
 * @export
 * @interface GeneralHistoryChangedDto
 */
export interface GeneralHistoryChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralHistoryChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof GeneralHistoryChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistoryChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {GeneralHistoryDto}
     * @memberof GeneralHistoryChangedDto
     */
    'entity': GeneralHistoryDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface GeneralHistoryDto
 */
export interface GeneralHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {GeneralHistoryType}
     * @memberof GeneralHistoryDto
     */
    'type'?: GeneralHistoryType;
    /**
     * 
     * @type {AutomationCreateMode}
     * @memberof GeneralHistoryDto
     */
    'autoCreateMode'?: AutomationCreateMode;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'raisedAt'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof GeneralHistoryDto
     */
    'raisedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralHistorySubjectDto}
     * @memberof GeneralHistoryDto
     */
    'subject'?: GeneralHistorySubjectDto;
    /**
     * 
     * @type {GeneralHistoryLogDto}
     * @memberof GeneralHistoryDto
     */
    'log'?: GeneralHistoryLogDto;
    /**
     * 
     * @type {GeneralHistoryEventDto}
     * @memberof GeneralHistoryDto
     */
    'event'?: GeneralHistoryEventDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof GeneralHistoryDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof GeneralHistoryDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof GeneralHistoryDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventDto
 */
export interface GeneralHistoryEventDto {
    /**
     * 
     * @type {GeneralHistoryEventType}
     * @memberof GeneralHistoryEventDto
     */
    'type'?: GeneralHistoryEventType;
    /**
     * 
     * @type {GeneralHistoryEventOfArbitraryDto}
     * @memberof GeneralHistoryEventDto
     */
    'arbitrary'?: GeneralHistoryEventOfArbitraryDto;
    /**
     * 
     * @type {GeneralHistoryEventOfVehicleDto}
     * @memberof GeneralHistoryEventDto
     */
    'vehicle'?: GeneralHistoryEventOfVehicleDto;
    /**
     * 
     * @type {GeneralHistoryEventOfVehicleDamageDto}
     * @memberof GeneralHistoryEventDto
     */
    'vehicleDamage'?: GeneralHistoryEventOfVehicleDamageDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventOfArbitraryDto
 */
export interface GeneralHistoryEventOfArbitraryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryEventOfArbitraryDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralHistoryEventOfVehicleDamageDto
 */
export interface GeneralHistoryEventOfVehicleDamageDto {
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicleDamage}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'type'?: GeneralHistoryEventTypeOfVehicleDamage;
    /**
     * 
     * @type {VehicleDamageDetectedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'damageDetected'?: VehicleDamageDetectedEventDataDto;
    /**
     * 
     * @type {VehicleDamageRepairedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDamageDto
     */
    'damageRepaired'?: VehicleDamageRepairedEventDataDto;
}


/**
 * 
 * @export
 * @interface GeneralHistoryEventOfVehicleDto
 */
export interface GeneralHistoryEventOfVehicleDto {
    /**
     * 
     * @type {GeneralHistoryEventTypeOfVehicle}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'type'?: GeneralHistoryEventTypeOfVehicle;
    /**
     * 
     * @type {VehicleMileageChangedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'mileageChanged'?: VehicleMileageChangedEventDataDto;
    /**
     * 
     * @type {VehicleDamageDetectedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageDetected'?: VehicleDamageDetectedEventDataDto;
    /**
     * 
     * @type {VehicleDamageRepairedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageRepaired'?: VehicleDamageRepairedEventDataDto;
    /**
     * 
     * @type {VehicleDamageDeletedEventDataDto}
     * @memberof GeneralHistoryEventOfVehicleDto
     */
    'damageDeleted'?: VehicleDamageDeletedEventDataDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Vehicle: 'Vehicle',
    VehicleDamage: 'VehicleDamage'
} as const;

export type GeneralHistoryEventType = typeof GeneralHistoryEventType[keyof typeof GeneralHistoryEventType];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventTypeOfVehicle = {
    None: 'None',
    MileageChanged: 'MileageChanged',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired',
    DamageDeleted: 'DamageDeleted'
} as const;

export type GeneralHistoryEventTypeOfVehicle = typeof GeneralHistoryEventTypeOfVehicle[keyof typeof GeneralHistoryEventTypeOfVehicle];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryEventTypeOfVehicleDamage = {
    None: 'None',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired'
} as const;

export type GeneralHistoryEventTypeOfVehicleDamage = typeof GeneralHistoryEventTypeOfVehicleDamage[keyof typeof GeneralHistoryEventTypeOfVehicleDamage];


/**
 * 
 * @export
 * @interface GeneralHistoryLogDto
 */
export interface GeneralHistoryLogDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralHistoryLogDto
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface GeneralHistorySubjectDto
 */
export interface GeneralHistorySubjectDto {
    /**
     * 
     * @type {GeneralHistorySubjectType}
     * @memberof GeneralHistorySubjectDto
     */
    'type': GeneralHistorySubjectType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'parentIdentifier'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySubjectDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'entityId'?: string | null;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralHistorySubjectDto
     */
    'parentEntityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralHistorySubjectDto
     */
    'parentEntityId'?: string | null;
}


/**
 * Describes object types to which history can be linked.
 * @export
 * @enum {string}
 */

export const GeneralHistorySubjectType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Entity: 'Entity'
} as const;

export type GeneralHistorySubjectType = typeof GeneralHistorySubjectType[keyof typeof GeneralHistorySubjectType];


/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralHistoryType = {
    None: 'None',
    Log: 'Log',
    Event: 'Event'
} as const;

export type GeneralHistoryType = typeof GeneralHistoryType[keyof typeof GeneralHistoryType];


/**
 * Enumerates client method names that should support all hubs.
 * @export
 * @enum {string}
 */

export const GeneralHubClientMethodName = {
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred'
} as const;

export type GeneralHubClientMethodName = typeof GeneralHubClientMethodName[keyof typeof GeneralHubClientMethodName];


/**
 * 
 * @export
 * @interface GeneralImageSizeDto
 */
export interface GeneralImageSizeDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralImageSizeDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralImageSizeDto
     */
    'height'?: number;
}
/**
 * Represents a person who inspected something.
 * @export
 * @interface GeneralInspector
 */
export interface GeneralInspector {
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {GeneralPersonName}
     * @memberof GeneralInspector
     */
    'personName'?: GeneralPersonName;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspector
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralInspectorDto
 */
export interface GeneralInspectorDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'locationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof GeneralInspectorDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralInspectorDto
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralInsurance
 */
export interface GeneralInsurance {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsurance
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralInsurance
     */
    'currency'?: GeneralCurrency;
}
/**
 * 
 * @export
 * @interface GeneralInsuranceDto
 */
export interface GeneralInsuranceDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsuranceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralInsuranceDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface GeneralInsuranceInputDto
 */
export interface GeneralInsuranceInputDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralInsuranceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralInsuranceInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface GeneralLogoResizedDto
 */
export interface GeneralLogoResizedDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralLogoResizedDto
     */
    'id'?: string;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralLogoResizedDto
     */
    'file'?: FileReferenceDto;
    /**
     * 
     * @type {Array<GeneralLogoResizedImageDto>}
     * @memberof GeneralLogoResizedDto
     */
    'logos'?: Array<GeneralLogoResizedImageDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralLogoResizedDto
     */
    'isLogo'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralLogoResizedImageDto
 */
export interface GeneralLogoResizedImageDto {
    /**
     * 
     * @type {LogoSizeType}
     * @memberof GeneralLogoResizedImageDto
     */
    'sizeType'?: LogoSizeType;
    /**
     * 
     * @type {GeneralImageSizeDto}
     * @memberof GeneralLogoResizedImageDto
     */
    'size'?: GeneralImageSizeDto;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof GeneralLogoResizedImageDto
     */
    'file'?: FileReferenceDto;
}


/**
 * 
 * @export
 * @interface GeneralNationalIdentityNumberDto
 */
export interface GeneralNationalIdentityNumberDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {GeneralCountryDto}
     * @memberof GeneralNationalIdentityNumberDto
     */
    'country'?: GeneralCountryDto;
}
/**
 * 
 * @export
 * @interface GeneralPersonName
 */
export interface GeneralPersonName {
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonName
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPersonNameDto
 */
export interface GeneralPersonNameDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralPersonNameDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPlateNoDto
 */
export interface GeneralPlateNoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralPlateNoDto
     */
    'plateNo'?: string;
}
/**
 * 
 * @export
 * @interface GeneralPriceDto
 */
export interface GeneralPriceDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralPriceDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface GeneralPriceInputDto
 */
export interface GeneralPriceInputDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceInputDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralPriceInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}
/**
 * 
 * @export
 * @interface GeneralPriceSummaryDto
 */
export interface GeneralPriceSummaryDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralPriceSummaryDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {GeneralDiscount}
     * @memberof GeneralPriceSummaryDto
     */
    'discount'?: GeneralDiscount;
    /**
     * 
     * @type {GeneralTax}
     * @memberof GeneralPriceSummaryDto
     */
    'tax'?: GeneralTax;
    /**
     * 
     * @type {GeneralInsurance}
     * @memberof GeneralPriceSummaryDto
     */
    'insurance'?: GeneralInsurance;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GeneralPriceSummaryInputDto
 */
export interface GeneralPriceSummaryInputDto {
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryInputDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {GeneralDiscountInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'discount'?: GeneralDiscountInputDto;
    /**
     * 
     * @type {GeneralTaxInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'tax'?: GeneralTaxInputDto;
    /**
     * 
     * @type {GeneralInsuranceInputDto}
     * @memberof GeneralPriceSummaryInputDto
     */
    'insurance'?: GeneralInsuranceInputDto;
    /**
     * 
     * @type {number}
     * @memberof GeneralPriceSummaryInputDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface GeneralScopeDto
 */
export interface GeneralScopeDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeDto
     */
    'type'?: GeneralScopeType;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeDto
     */
    'parentIdentifier'?: string | null;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralScopeDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralScopeDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralScopeDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralScopeDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
}


/**
 * 
 * @export
 * @interface GeneralScopeInfoDto
 */
export interface GeneralScopeInfoDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeInfoDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralScopeInfoDto
     */
    'subIdentifier'?: string | null;
    /**
     * 
     * @type {GeneralScopeType}
     * @memberof GeneralScopeInfoDto
     */
    'type'?: GeneralScopeType;
}


/**
 * Helps link chat to its scope.  For instance, link comments chat to report entity to be able to update report based on chat events or actions.
 * @export
 * @enum {string}
 */

export const GeneralScopeType = {
    None: 'None',
    Dynamic: 'Dynamic',
    Vehicle: 'Vehicle',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheck: 'AccessoryCheck',
    AccessoryCheckItem: 'AccessoryCheckItem',
    RepairOperation: 'RepairOperation',
    Document: 'Document',
    DocumentItem: 'DocumentItem',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest'
} as const;

export type GeneralScopeType = typeof GeneralScopeType[keyof typeof GeneralScopeType];


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfContractStage
 */
export interface GeneralStageHistoryDtoOfContractStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'id'?: string;
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfContractStage>}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfContractStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfContractStage>}
     * @memberof GeneralStageHistoryDtoOfContractStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfContractStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageHistoryDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'id'?: string;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfDamageCostEvaluationStage>}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfDamageCostEvaluationStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfDamageCostEvaluationStage>}
     * @memberof GeneralStageHistoryDtoOfDamageCostEvaluationStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfDamageCostEvaluationStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfDocumentStage
 */
export interface GeneralStageHistoryDtoOfDocumentStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfDocumentStage>}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfDocumentStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfDocumentStage>}
     * @memberof GeneralStageHistoryDtoOfDocumentStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfDocumentStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfRepairOperationStage
 */
export interface GeneralStageHistoryDtoOfRepairOperationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'id'?: string;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfRepairOperationStage>}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfRepairOperationStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfRepairOperationStage>}
     * @memberof GeneralStageHistoryDtoOfRepairOperationStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfRepairOperationStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryDtoOfTenantRequestStage
 */
export interface GeneralStageHistoryDtoOfTenantRequestStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'id'?: string;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {Array<GeneralStageHistoryItemDtoOfTenantRequestStage>}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'items'?: Array<GeneralStageHistoryItemDtoOfTenantRequestStage>;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<GeneralStageInfoDtoOfTenantRequestStage>}
     * @memberof GeneralStageHistoryDtoOfTenantRequestStage
     */
    'allStagesInfo'?: Array<GeneralStageInfoDtoOfTenantRequestStage>;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfContractStage
 */
export interface GeneralStageHistoryItemDtoOfContractStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'id'?: string;
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfContractStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageHistoryItemDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'id'?: string;
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDamageCostEvaluationStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfDocumentStage
 */
export interface GeneralStageHistoryItemDtoOfDocumentStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'id'?: string;
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfDocumentStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfRepairOperationStage
 */
export interface GeneralStageHistoryItemDtoOfRepairOperationStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'id'?: string;
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfRepairOperationStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageHistoryItemDtoOfTenantRequestStage
 */
export interface GeneralStageHistoryItemDtoOfTenantRequestStage {
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'id'?: string;
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isCurrent'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'firstStartedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'firstEndedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'endReason'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'startCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isEnded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStageHistoryItemDtoOfTenantRequestStage
     */
    'isLast'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfContractStage
 */
export interface GeneralStageInfoDtoOfContractStage {
    /**
     * 
     * @type {ContractStage}
     * @memberof GeneralStageInfoDtoOfContractStage
     */
    'stage'?: ContractStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfContractStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfDamageCostEvaluationStage
 */
export interface GeneralStageInfoDtoOfDamageCostEvaluationStage {
    /**
     * 
     * @type {DamageCostEvaluationStage}
     * @memberof GeneralStageInfoDtoOfDamageCostEvaluationStage
     */
    'stage'?: DamageCostEvaluationStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfDamageCostEvaluationStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfDocumentStage
 */
export interface GeneralStageInfoDtoOfDocumentStage {
    /**
     * 
     * @type {DocumentStage}
     * @memberof GeneralStageInfoDtoOfDocumentStage
     */
    'stage'?: DocumentStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfDocumentStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfRepairOperationStage
 */
export interface GeneralStageInfoDtoOfRepairOperationStage {
    /**
     * 
     * @type {RepairOperationStage}
     * @memberof GeneralStageInfoDtoOfRepairOperationStage
     */
    'stage'?: RepairOperationStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfRepairOperationStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStageInfoDtoOfTenantRequestStage
 */
export interface GeneralStageInfoDtoOfTenantRequestStage {
    /**
     * 
     * @type {TenantRequestStage}
     * @memberof GeneralStageInfoDtoOfTenantRequestStage
     */
    'stage'?: TenantRequestStage;
    /**
     * 
     * @type {number}
     * @memberof GeneralStageInfoDtoOfTenantRequestStage
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface GeneralStrictEntityRelationDto
 */
export interface GeneralStrictEntityRelationDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralStrictEntityRelationDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof GeneralStrictEntityRelationDto
     */
    'parentEntityIdMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {GeneralStrictEntityRelationDtoParentEntityTypeMap}
     * @memberof GeneralStrictEntityRelationDto
     */
    'parentEntityTypeMap'?: GeneralStrictEntityRelationDtoParentEntityTypeMap;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {SpotReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'spot'?: SpotReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {VehicleFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleFullRef'?: VehicleFullReferenceDto;
    /**
     * 
     * @type {VehicleHistoryItemReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleHistory'?: VehicleHistoryItemReferenceDto;
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleDamage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'contractFullRef'?: ContractFullReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {AccessoryFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessoryFullRef'?: AccessoryFullReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {NegotiationProposalReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'proposal'?: NegotiationProposalReferenceDto;
    /**
     * 
     * @type {DocumentReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'document'?: DocumentReferenceDto;
    /**
     * 
     * @type {InvoiceReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'invoice'?: InvoiceReferenceDto;
    /**
     * 
     * @type {VehicleTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleType'?: VehicleTypeReferenceDto;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleMake'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleModel'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleGeneration'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleModification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleBodyTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleBodyType'?: VehicleBodyTypeReferenceDto;
    /**
     * 
     * @type {VehicleFuelTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleFuelType'?: VehicleFuelTypeReferenceDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehiclePartType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'vehicleVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairCatalogReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairCatalog'?: RepairCatalogReferenceDto;
    /**
     * 
     * @type {RepairWorkReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairWork'?: RepairWorkReferenceDto;
    /**
     * 
     * @type {RepairMaterialReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairMaterial'?: RepairMaterialReferenceDto;
    /**
     * 
     * @type {RepairSpecReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'repairSpec'?: RepairSpecReferenceDto;
    /**
     * 
     * @type {SubscriptionPlanReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'subscriptionPlan'?: SubscriptionPlanReferenceDto;
    /**
     * 
     * @type {SubscriptionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'subscription'?: SubscriptionReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetSubscriptionPlan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {AssetFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'assetFullRef'?: AssetFullReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {PoolItemReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'poolItem'?: PoolItemReferenceDto;
    /**
     * 
     * @type {PoolItemFullReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'poolItemFullRef'?: PoolItemFullReferenceDto;
    /**
     * 
     * @type {IntegrationApiClientReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'integrationApiClient'?: IntegrationApiClientReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralStrictEntityRelationDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationDto
     */
    'nameComputed'?: string | null;
}


/**
 * 
 * @export
 * @interface GeneralStrictEntityRelationDtoParentEntityTypeMap
 */
export interface GeneralStrictEntityRelationDtoParentEntityTypeMap {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'User'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Role'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Company'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Department'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Location'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Spot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Vehicle'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleHistory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleDamage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Contract'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Customer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Accessory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VisualInspection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DamageDetection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DamageDetectionAggregate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DamageCostEvaluation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DamageCostEvaluationAggregate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'AccessoryCheck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairOperation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Document'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DocumentItem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Chat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatParticipant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatHistoryItem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatEvent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatActivity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ChatUserSettings'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Negotiation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'NegotiationProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'File'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Invoice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Invite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleMake'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleModel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleGeneration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleModification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleBodyType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleFuelType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehiclePartType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'VehicleVisualModel'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DamageType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairCatalog'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairWork'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairMaterial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairSpec'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'RepairSparePart'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'SubscriptionPlan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Subscription'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'AssetSubscriptionPlan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'AssetSubscription'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ProductLocation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Pool'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'PoolItem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Asset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'IntegrationApiClient'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'TenantConnectionRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'TenantConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'TenantRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DataGrant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'AssessmentFlow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'UserSecret'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'CustomIdentity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'CustomSession'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'FlowInstance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'FlowSpec'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'FlowStepSpec'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'GeneralEventLog'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Import'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'ImportMappingTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'NumberRegistryItem'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Party'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'PartyMember'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'TestDamageAssessmentReport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Test'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'UserVerificationRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'DeviceToken'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Notification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'NotificationGroup'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'NotificationGroupMember'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'NotificationProfile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'CustomTag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'Webhook'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'WebhookActivation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'GeneralHistory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'TeslaConnection'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'SecretValueKey'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralStrictEntityRelationDtoParentEntityTypeMap
     */
    'CompanyRequest'?: boolean;
}
/**
 * 
 * @export
 * @interface GeneralStrictEntityRelationInputDto
 */
export interface GeneralStrictEntityRelationInputDto {
    /**
     * 
     * @type {EntityType}
     * @memberof GeneralStrictEntityRelationInputDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GeneralStrictEntityRelationInputDto
     */
    'entityId'?: string;
}


/**
 * 
 * @export
 * @interface GeneralSvgPointDto
 */
export interface GeneralSvgPointDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'xp'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'yp'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSvgPointDto
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidateDto
 */
export interface GeneralTagCandidateDto {
    /**
     * 
     * @type {GeneralTagDto}
     * @memberof GeneralTagCandidateDto
     */
    'tag'?: GeneralTagDto;
    /**
     * 
     * @type {Array<GeneralTagCandidateDto>}
     * @memberof GeneralTagCandidateDto
     */
    'subCandidates'?: Array<GeneralTagCandidateDto> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidateDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesAccessoryCheckRequestDto
 */
export interface GeneralTagCandidatesAccessoryCheckRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesAccessoryCheckRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesByScopeRequestDto
 */
export interface GeneralTagCandidatesByScopeRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof GeneralTagCandidatesByScopeRequestDto
     */
    'scope'?: GeneralScopeDto;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesChatParticipantRequestDto
 */
export interface GeneralTagCandidatesChatParticipantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesChatParticipantRequestDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto
 */
export interface GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageCostEvaluationRequestDto
 */
export interface GeneralTagCandidatesDamageCostEvaluationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageCostEvaluationRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageDetectionAggregateRequestDto
 */
export interface GeneralTagCandidatesDamageDetectionAggregateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageDetectionAggregateRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesDamageDetectionRequestDto
 */
export interface GeneralTagCandidatesDamageDetectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesDamageDetectionRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesRepairOperationRequestDto
 */
export interface GeneralTagCandidatesRepairOperationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRepairOperationRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesRequestDto
 */
export interface GeneralTagCandidatesRequestDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetSubId'?: string | null;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'targetSubType'?: TagSubTargetType;
    /**
     * 
     * @type {GeneralTagCandidatesChatParticipantRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'chatParticipant'?: GeneralTagCandidatesChatParticipantRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesVehicleDamageRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'vehicleDamage'?: GeneralTagCandidatesVehicleDamageRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesVisualInspectionRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'visualInspection'?: GeneralTagCandidatesVisualInspectionRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageDetectionRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageDetection'?: GeneralTagCandidatesDamageDetectionRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageDetectionAggregateRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageDetectionAggregate'?: GeneralTagCandidatesDamageDetectionAggregateRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageCostEvaluationRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageCostEvaluation'?: GeneralTagCandidatesDamageCostEvaluationRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'damageCostEvaluationAggregate'?: GeneralTagCandidatesDamageCostEvaluationAggregateRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesAccessoryCheckRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'accessoryCheck'?: GeneralTagCandidatesAccessoryCheckRequestDto;
    /**
     * 
     * @type {GeneralTagCandidatesRepairOperationRequestDto}
     * @memberof GeneralTagCandidatesRequestDto
     */
    'repairOperation'?: GeneralTagCandidatesRepairOperationRequestDto;
}


/**
 * 
 * @export
 * @interface GeneralTagCandidatesVehicleDamageRequestDto
 */
export interface GeneralTagCandidatesVehicleDamageRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesVehicleDamageRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagCandidatesVisualInspectionRequestDto
 */
export interface GeneralTagCandidatesVisualInspectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagCandidatesVisualInspectionRequestDto
     */
    'vehicleId'?: string | null;
}
/**
 * 
 * @export
 * @interface GeneralTagDto
 */
export interface GeneralTagDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'id'?: string;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagDto
     */
    'subTargetType'?: TagSubTargetType;
    /**
     * 
     * @type {VisibilityStatus}
     * @memberof GeneralTagDto
     */
    'visibilityStatus'?: VisibilityStatus;
    /**
     * 
     * @type {GeneralTagTargetDto}
     * @memberof GeneralTagDto
     */
    'target'?: GeneralTagTargetDto;
    /**
     * 
     * @type {GeneralTagSubTargetDto}
     * @memberof GeneralTagDto
     */
    'subTarget'?: GeneralTagSubTargetDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof GeneralTagDto
     */
    'taggedAt'?: string;
    /**
     * 
     * @type {GeneralTagTaggedByDto}
     * @memberof GeneralTagDto
     */
    'taggedBy'?: GeneralTagTaggedByDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'computedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'subTargetId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralTagDto
     */
    'isPersonTagged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagDto
     */
    'title'?: string;
}


/**
 * 
 * @export
 * @interface GeneralTagInputDto
 */
export interface GeneralTagInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagInputDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {TagTargetType}
     * @memberof GeneralTagInputDto
     */
    'targetType'?: TagTargetType;
    /**
     * 
     * @type {TagSubTargetType}
     * @memberof GeneralTagInputDto
     */
    'subTargetType'?: TagSubTargetType;
    /**
     * 
     * @type {VisibilityStatus}
     * @memberof GeneralTagInputDto
     */
    'visibilityStatus'?: VisibilityStatus;
    /**
     * 
     * @type {GeneralTagTargetInputDto}
     * @memberof GeneralTagInputDto
     */
    'target'?: GeneralTagTargetInputDto;
    /**
     * 
     * @type {GeneralTagSubTargetInputDto}
     * @memberof GeneralTagInputDto
     */
    'subTarget'?: GeneralTagSubTargetInputDto;
}


/**
 * 
 * @export
 * @interface GeneralTagSubTargetDto
 */
export interface GeneralTagSubTargetDto {
    /**
     * 
     * @type {DamageDetectionItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageDetectionItem'?: DamageDetectionItemReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageDetectionAggregateItem'?: DamageDetectionAggregateItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageCostEvaluationItem'?: DamageCostEvaluationItemReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'damageCostEvaluationAggregateItem'?: DamageCostEvaluationAggregateItemReferenceDto;
    /**
     * 
     * @type {AccessoryCheckItemReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'accessoryCheckItem'?: AccessoryCheckItemReferenceDto;
    /**
     * 
     * @type {NegotiationProposalReferenceDto}
     * @memberof GeneralTagSubTargetDto
     */
    'proposal'?: NegotiationProposalReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetDto
     */
    'subTargetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagSubTargetInputDto
 */
export interface GeneralTagSubTargetInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagSubTargetInputDto
     */
    'subTargetId'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagTaggedByDto
 */
export interface GeneralTagTaggedByDto {
    /**
     * 
     * @type {UserReferenceDto}
     * @memberof GeneralTagTaggedByDto
     */
    'user'?: UserReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof GeneralTagTaggedByDto
     */
    'chatParticipant'?: ChatParticipantReferenceDto;
}
/**
 * 
 * @export
 * @interface GeneralTagTargetDto
 */
export interface GeneralTagTargetDto {
    /**
     * 
     * @type {CustomTagFullReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'customTag'?: CustomTagFullReferenceDto;
    /**
     * 
     * @type {UserReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'user'?: UserReferenceDto;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * 
     * @type {CustomerReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'customer'?: CustomerReferenceDto;
    /**
     * 
     * @type {VehicleDamageReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'vehicleDamage'?: VehicleDamageReferenceDto;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {VisualInspectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'visualInspection'?: VisualInspectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {DamageDetectionAggregateReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageDetectionAggregate'?: DamageDetectionAggregateReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationReferenceDto;
    /**
     * 
     * @type {DamageCostEvaluationAggregateReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'damageCostEvaluationAggregate'?: DamageCostEvaluationAggregateReferenceDto;
    /**
     * 
     * @type {AccessoryCheckReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'accessoryCheck'?: AccessoryCheckReferenceDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'chatParticipant'?: ChatParticipantReferenceDto;
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionPlanReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assetSubscriptionPlan'?: AssetSubscriptionPlanReferenceDto;
    /**
     * 
     * @type {AssetSubscriptionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'assetSubscription'?: AssetSubscriptionReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantConnectionRequest'?: TenantConnectionRequestReferenceDto;
    /**
     * 
     * @type {TenantConnectionReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantConnection'?: TenantConnectionReferenceDto;
    /**
     * 
     * @type {TenantRequestReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'tenantRequest'?: TenantRequestReferenceDto;
    /**
     * 
     * @type {DataGrantReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'dataGrant'?: DataGrantReferenceDto;
    /**
     * 
     * @type {PoolReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'pool'?: PoolReferenceDto;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {SpotReferenceDto}
     * @memberof GeneralTagTargetDto
     */
    'spot'?: SpotReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetDto
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface GeneralTagTargetInputDto
 */
export interface GeneralTagTargetInputDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralTagTargetInputDto
     */
    'targetId'?: string;
}
/**
 * Represents a percentage/amount from goods price that should be paid as tax.  Stored as positive number and is always added to SubTotal.
 * @export
 * @interface GeneralTax
 */
export interface GeneralTax {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTax
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTax
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0; N].
     * @type {number}
     * @memberof GeneralTax
     */
    'percent'?: number | null;
    /**
     * Amount in some currency representation.
     * @type {number}
     * @memberof GeneralTax
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrency}
     * @memberof GeneralTax
     */
    'currency'?: GeneralCurrency;
    /**
     * Tax that is a result of merging N taxes into one.
     * @type {boolean}
     * @memberof GeneralTax
     */
    'isCompound'?: boolean;
    /**
     * 
     * @type {GeneralTax}
     * @memberof GeneralTax
     */
    'original'?: GeneralTax;
}


/**
 * 
 * @export
 * @interface GeneralTaxDto
 */
export interface GeneralTaxDto {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTaxDto
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTaxDto
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0, 1]
     * @type {number}
     * @memberof GeneralTaxDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralTaxDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof GeneralTaxDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralTaxDto
     */
    'isCompound'?: boolean;
}


/**
 * 
 * @export
 * @interface GeneralTaxInputDto
 */
export interface GeneralTaxInputDto {
    /**
     * 
     * @type {TaxType}
     * @memberof GeneralTaxInputDto
     */
    'type'?: TaxType;
    /**
     * 
     * @type {TaxValueType}
     * @memberof GeneralTaxInputDto
     */
    'valueType'?: TaxValueType;
    /**
     * Percents [0, 1]
     * @type {number}
     * @memberof GeneralTaxInputDto
     */
    'percent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralTaxInputDto
     */
    'value'?: number | null;
    /**
     * 
     * @type {GeneralCurrencyInputDto}
     * @memberof GeneralTaxInputDto
     */
    'currency'?: GeneralCurrencyInputDto;
}


/**
 * 
 * @export
 * @interface GeneralTimeZoneDto
 */
export interface GeneralTimeZoneDto {
    /**
     * IANA time zone id.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'displayName'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'minOffset'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof GeneralTimeZoneDto
     */
    'maxOffset'?: string;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IAssetSourceEntity\" />
 * @export
 * @interface IAssetSourceEntityDto
 */
export interface IAssetSourceEntityDto {
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof IAssetSourceEntityDto
     */
    'assetMeta'?: AssetMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IBaseEntity\" />
 * @export
 * @interface IBaseEntityDto
 */
export interface IBaseEntityDto {
    /**
     * 
     * @type {string}
     * @memberof IBaseEntityDto
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface IEntityChangedDto
 */
export interface IEntityChangedDto {
    /**
     * Optional list of connection ids of client/clients that initiated this server to client SignalR method call.
     * @type {Array<string>}
     * @memberof IEntityChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * SignalR group name to which server sends the message.
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof IEntityChangedDto
     */
    'changeType'?: EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof IEntityChangedDto
     */
    'entityType'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof IEntityChangedDto
     */
    'tenantId'?: string | null;
}


/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IHasGlobalNumber\" />
 * @export
 * @interface IHasGlobalNumberDto
 */
export interface IHasGlobalNumberDto {
    /**
     * Number unique across the whole system.
     * @type {string}
     * @memberof IHasGlobalNumberDto
     */
    'globalNumber'?: string;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IHasLocalNumber\" />
 * @export
 * @interface IHasLocalNumberDto
 */
export interface IHasLocalNumberDto {
    /**
     * Number unique across a tenant.
     * @type {string}
     * @memberof IHasLocalNumberDto
     */
    'localNumber'?: string;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IImportedEntity\" />
 * @export
 * @interface IImportedEntityDto
 */
export interface IImportedEntityDto {
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof IImportedEntityDto
     */
    'importMeta'?: EntityImportMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDataGrants\" />
 * @export
 * @interface IMightHaveDataGrantsBriefDto
 */
export interface IMightHaveDataGrantsBriefDto {
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof IMightHaveDataGrantsBriefDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDataGrants\" />
 * @export
 * @interface IMightHaveDataGrantsDto
 */
export interface IMightHaveDataGrantsDto {
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof IMightHaveDataGrantsDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveDepartments\" />
 * @export
 * @interface IMightHaveDepartmentsDto
 */
export interface IMightHaveDepartmentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IMightHaveDepartmentsDto
     */
    'departmentIds'?: Array<string> | null;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveLocations\" />
 * @export
 * @interface IMightHaveLocationsDto
 */
export interface IMightHaveLocationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IMightHaveLocationsDto
     */
    'locationIds'?: Array<string> | null;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHavePools\" />
 * @export
 * @interface IMightHavePoolsDto
 */
export interface IMightHavePoolsDto {
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof IMightHavePoolsDto
     */
    'poolsMeta'?: PoolsMetaDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveTags\" />
 * @export
 * @interface IMightHaveTagsDto
 */
export interface IMightHaveTagsDto {
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof IMightHaveTagsDto
     */
    'tags'?: GeneralAttachedTagsDto;
}
/**
 * <inheritdoc cref=\"T:Domain.Interfaces.IMightHaveTenantRequests\" />
 * @export
 * @interface IMightHaveTenantRequestsDto
 */
export interface IMightHaveTenantRequestsDto {
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof IMightHaveTenantRequestsDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
}
/**
 * 
 * @export
 * @interface InAppChannelInfoDto
 */
export interface InAppChannelInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InAppChannelInfoDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof InAppChannelInfoDto
     */
    'body'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof InAppChannelInfoDto
     */
    'data'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface InAppNotificationReceivedDto
 */
export interface InAppNotificationReceivedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InAppNotificationReceivedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InAppNotificationReceivedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {NotificationDto}
     * @memberof InAppNotificationReceivedDto
     */
    'notification'?: NotificationDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InAppNotificationType = {
    None: 'None',
    DamageDetectionCreated: 'DamageDetectionCreated',
    DamageCostEvaluationStarted: 'DamageCostEvaluationStarted',
    DamageCostEvaluationCompleted: 'DamageCostEvaluationCompleted',
    DamageCostEvaluationAggregateUpdated: 'DamageCostEvaluationAggregateUpdated',
    AccessoryCheckCreated: 'AccessoryCheckCreated',
    ChatResolved: 'ChatResolved',
    ChatReopened: 'ChatReopened',
    ChatAcknowledged: 'ChatAcknowledged',
    ChatParticipantAdded: 'ChatParticipantAdded',
    ChatParticipantRemoved: 'ChatParticipantRemoved',
    ChatParticipantMentioned: 'ChatParticipantMentioned',
    ChatMessageAcknowledged: 'ChatMessageAcknowledged',
    ChatNegotiationProposalCreated: 'ChatNegotiationProposalCreated',
    ChatNegotiationProposalResponseGiven: 'ChatNegotiationProposalResponseGiven'
} as const;

export type InAppNotificationType = typeof InAppNotificationType[keyof typeof InAppNotificationType];


/**
 * 
 * @export
 * @interface IntegrationApiClientChangedDto
 */
export interface IntegrationApiClientChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IntegrationApiClientChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof IntegrationApiClientChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof IntegrationApiClientChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {IntegrationApiClientDto}
     * @memberof IntegrationApiClientChangedDto
     */
    'entity': IntegrationApiClientDto;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientDto
 */
export interface IntegrationApiClientDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {IntegrationApiScopeDto}
     * @memberof IntegrationApiClientDto
     */
    'scope'?: IntegrationApiScopeDto;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'expiresAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'expiredAt'?: string | null;
    /**
     * 
     * @type {Array<IntegrationApiClientKeyDto>}
     * @memberof IntegrationApiClientDto
     */
    'keys'?: Array<IntegrationApiClientKeyDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface IntegrationApiClientKeyDto
 */
export interface IntegrationApiClientKeyDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'name'?: string;
    /**
     * 
     * @type {IntegrationApiKeyStatus}
     * @memberof IntegrationApiClientKeyDto
     */
    'status'?: IntegrationApiKeyStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'expiresAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'revokedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof IntegrationApiClientKeyDto
     */
    'expiredAt'?: string | null;
}


/**
 * 
 * @export
 * @interface IntegrationApiClientReferenceDto
 */
export interface IntegrationApiClientReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiClientReferenceDto
     */
    'localNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationApiKeyStatus = {
    None: 'None',
    Active: 'Active',
    Revoked: 'Revoked',
    Expired: 'Expired'
} as const;

export type IntegrationApiKeyStatus = typeof IntegrationApiKeyStatus[keyof typeof IntegrationApiKeyStatus];


/**
 * 
 * @export
 * @interface IntegrationApiScopeDto
 */
export interface IntegrationApiScopeDto {
    /**
     * 
     * @type {IntegrationApiScopeType}
     * @memberof IntegrationApiScopeDto
     */
    'type'?: IntegrationApiScopeType;
    /**
     * 
     * @type {string}
     * @memberof IntegrationApiScopeDto
     */
    'tenantId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationApiScopeType = {
    None: 'None',
    Global: 'Global',
    Tenant: 'Tenant',
    Combined: 'Combined'
} as const;

export type IntegrationApiScopeType = typeof IntegrationApiScopeType[keyof typeof IntegrationApiScopeType];


/**
 * 
 * @export
 * @interface InviteChangedDto
 */
export interface InviteChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof InviteChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof InviteChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {InviteDto}
     * @memberof InviteChangedDto
     */
    'entity': InviteDto;
    /**
     * 
     * @type {string}
     * @memberof InviteChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface InviteDto
 */
export interface InviteDto {
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {InviteType}
     * @memberof InviteDto
     */
    'type'?: InviteType;
    /**
     * 
     * @type {InviteUserType}
     * @memberof InviteDto
     */
    'userType'?: InviteUserType;
    /**
     * 
     * @type {InviteUserInfo}
     * @memberof InviteDto
     */
    'userInfo'?: InviteUserInfo;
    /**
     * 
     * @type {string}
     * @memberof InviteDto
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDto
     */
    'isExpired'?: boolean;
    /**
     * 
     * @type {InviteTenantDto}
     * @memberof InviteDto
     */
    'tenant'?: InviteTenantDto;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDto
     */
    'isIAlreadyAnswered'?: boolean | null;
}


/**
 * 
 * @export
 * @interface InviteTenantDto
 */
export interface InviteTenantDto {
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteTenantDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InviteType = {
    None: 'None',
    Personal: 'Personal',
    Anonymous: 'Anonymous'
} as const;

export type InviteType = typeof InviteType[keyof typeof InviteType];


/**
 * 
 * @export
 * @interface InviteUserInfo
 */
export interface InviteUserInfo {
    /**
     * 
     * @type {GeneralPersonName}
     * @memberof InviteUserInfo
     */
    'personName'?: GeneralPersonName;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserInfo
     */
    'phoneNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InviteUserType = {
    None: 'None',
    Tenant: 'Tenant',
    Customer: 'Customer'
} as const;

export type InviteUserType = typeof InviteUserType[keyof typeof InviteUserType];


/**
 * 
 * @export
 * @interface InvoiceBusinessInfoDto
 */
export interface InvoiceBusinessInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceBusinessInfoDto
     */
    'webSiteUrl'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof InvoiceBusinessInfoDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface InvoiceChangedDto
 */
export interface InvoiceChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof InvoiceChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof InvoiceChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {InvoiceDto}
     * @memberof InvoiceChangedDto
     */
    'entity': InvoiceDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface InvoiceCustomerBusinessInfoDto
 */
export interface InvoiceCustomerBusinessInfoDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'businessName'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof InvoiceCustomerBusinessInfoDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof InvoiceDto
     */
    'status'?: InvoiceStatus;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof InvoiceDto
     */
    'paymentMethod'?: PaymentMethod;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof InvoiceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {InvoiceBusinessInfoDto}
     * @memberof InvoiceDto
     */
    'businessInfo'?: InvoiceBusinessInfoDto;
    /**
     * 
     * @type {InvoiceCustomerBusinessInfoDto}
     * @memberof InvoiceDto
     */
    'customerBusinessInfo'?: InvoiceCustomerBusinessInfoDto;
    /**
     * 
     * @type {Array<InvoiceLineItemDto>}
     * @memberof InvoiceDto
     */
    'lineItems'?: Array<InvoiceLineItemDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'date'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'dueAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'paidAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof InvoiceDto
     */
    'cancelledAt'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof InvoiceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof InvoiceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface InvoiceLineItemDto
 */
export interface InvoiceLineItemDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceLineItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof InvoiceLineItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof InvoiceLineItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceLineItemDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface InvoiceReferenceDto
 */
export interface InvoiceReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceStatus = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Paid: 'Paid',
    Overdue: 'Overdue',
    Cancelled: 'Cancelled'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


/**
 * 
 * @export
 * @interface JoinChatDto
 */
export interface JoinChatDto {
    /**
     * 
     * @type {string}
     * @memberof JoinChatDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface LeaveChatDto
 */
export interface LeaveChatDto {
    /**
     * 
     * @type {string}
     * @memberof LeaveChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveChatDto
     */
    'participantId'?: string;
}
/**
 * 
 * @export
 * @interface LocationChangedDto
 */
export interface LocationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof LocationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof LocationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {LocationDto}
     * @memberof LocationChangedDto
     */
    'entity': LocationDto;
    /**
     * 
     * @type {string}
     * @memberof LocationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof LocationDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof LocationDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof LocationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof LocationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface LocationReferenceDto
 */
export interface LocationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LogoSizeType = {
    None: 'None',
    Logo32: 'Logo32',
    Logo48: 'Logo48',
    Logo128: 'Logo128',
    Logo250x50: 'Logo250x50'
} as const;

export type LogoSizeType = typeof LogoSizeType[keyof typeof LogoSizeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MeasurementSystemType = {
    None: 'None',
    Metric: 'Metric',
    Imperial: 'Imperial',
    UsCustomary: 'USCustomary'
} as const;

export type MeasurementSystemType = typeof MeasurementSystemType[keyof typeof MeasurementSystemType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MeasurementUnit = {
    None: 'None',
    Hour: 'Hour',
    Period: 'Period',
    Liter: 'Liter',
    Piece: 'Piece',
    Gram: 'Gram',
    Millimeter: 'Millimeter'
} as const;

export type MeasurementUnit = typeof MeasurementUnit[keyof typeof MeasurementUnit];


/**
 * 
 * @export
 * @interface MileageChangedDataDto
 */
export interface MileageChangedDataDto {
    /**
     * 
     * @type {number}
     * @memberof MileageChangedDataDto
     */
    'oldMileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof MileageChangedDataDto
     */
    'newMileage'?: number;
    /**
     * 
     * @type {string}
     * @memberof MileageChangedDataDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof MileageChangedDataDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}
/**
 * Describes base type (or just type) of some MIME type.  Base type is a first part that goes before first slash:  \"type \"/\" [tree \".\"] subtype [\"+\" suffix]* [\";\" parameter]\".
 * @export
 * @enum {string}
 */

export const MimeBaseType = {
    None: 'None',
    Unknown: 'Unknown',
    Application: 'Application',
    Audio: 'Audio',
    Image: 'Image',
    Message: 'Message',
    Model: 'Model',
    Multipart: 'Multipart',
    Text: 'Text',
    Video: 'Video'
} as const;

export type MimeBaseType = typeof MimeBaseType[keyof typeof MimeBaseType];


/**
 * 
 * @export
 * @interface NegotiationChangedDto
 */
export interface NegotiationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof NegotiationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof NegotiationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof NegotiationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {NegotiationDto}
     * @memberof NegotiationChangedDto
     */
    'entity': NegotiationDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface NegotiationDto
 */
export interface NegotiationDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'localNumber'?: string | null;
    /**
     * 
     * @type {Array<ChatReferenceDto>}
     * @memberof NegotiationDto
     */
    'chats'?: Array<ChatReferenceDto>;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof NegotiationDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof NegotiationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {NegotiationType}
     * @memberof NegotiationDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {NegotiationValueType}
     * @memberof NegotiationDto
     */
    'valueType'?: NegotiationValueType;
    /**
     * 
     * @type {NegotiationStatus}
     * @memberof NegotiationDto
     */
    'status'?: NegotiationStatus;
    /**
     * 
     * @type {Array<PartyReferenceDto>}
     * @memberof NegotiationDto
     */
    'moderatorParties'?: Array<PartyReferenceDto>;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationDto
     */
    'initiatorParty'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationDto
     */
    'targetParty'?: PartyReferenceDto;
    /**
     * 
     * @type {NegotiationSettingsDto}
     * @memberof NegotiationDto
     */
    'settings'?: NegotiationSettingsDto;
    /**
     * 
     * @type {Array<NegotiationProposalDto>}
     * @memberof NegotiationDto
     */
    'proposals'?: Array<NegotiationProposalDto>;
    /**
     * 
     * @type {NegotiationResultDto}
     * @memberof NegotiationDto
     */
    'result'?: NegotiationResultDto;
}


/**
 * 
 * @export
 * @interface NegotiationProposalDto
 */
export interface NegotiationProposalDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'negotiationId'?: string;
    /**
     * 
     * @type {GeneralScopeDto}
     * @memberof NegotiationProposalDto
     */
    'scope'?: GeneralScopeDto;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'partyMember'?: PartyMemberReferenceDto;
    /**
     * 
     * @type {ChatParticipantReferenceDto}
     * @memberof NegotiationProposalDto
     */
    'participant'?: ChatParticipantReferenceDto;
    /**
     * 
     * @type {NegotiationProposalSettingsDto}
     * @memberof NegotiationProposalDto
     */
    'settings'?: NegotiationProposalSettingsDto;
    /**
     * 
     * @type {ProposalStatus}
     * @memberof NegotiationProposalDto
     */
    'status'?: ProposalStatus;
    /**
     * 
     * @type {ConsensusType}
     * @memberof NegotiationProposalDto
     */
    'consensusType'?: ConsensusType;
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof NegotiationProposalDto
     */
    'approvalStatus'?: ApprovalStatus;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'statement'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isInitial'?: boolean;
    /**
     * 
     * @type {NegotiationProposalValueDto}
     * @memberof NegotiationProposalDto
     */
    'value'?: NegotiationProposalValueDto;
    /**
     * 
     * @type {Array<NegotiationProposalResponseDto>}
     * @memberof NegotiationProposalDto
     */
    'responses'?: Array<NegotiationProposalResponseDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'openedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyOpenStatus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyClosedStatus'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalDto
     */
    'isAnyConsensusType'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalDto
     */
    'participantId'?: string | null;
}


/**
 * 
 * @export
 * @interface NegotiationProposalReferenceDto
 */
export interface NegotiationProposalReferenceDto {
    /**
     * 
     * @type {NegotiationReferenceDto}
     * @memberof NegotiationProposalReferenceDto
     */
    'negotiation'?: NegotiationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'partyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalReferenceDto
     */
    'statement'?: string;
}
/**
 * 
 * @export
 * @interface NegotiationProposalResponseDto
 */
export interface NegotiationProposalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof NegotiationProposalResponseDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof NegotiationProposalResponseDto
     */
    'partyMember'?: PartyMemberReferenceDto;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof NegotiationProposalResponseDto
     */
    'responseType'?: ApprovalResponseType;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalResponseDto
     */
    'isAuto'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalResponseDto
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface NegotiationProposalSettingsDto
 */
export interface NegotiationProposalSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalSettingsDto
     */
    'allowChangeProposalResponse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalSettingsDto
     */
    'requireProposalConsensus'?: boolean;
}
/**
 * 
 * @export
 * @interface NegotiationProposalValueDto
 */
export interface NegotiationProposalValueDto {
    /**
     * 
     * @type {NegotiationValueType}
     * @memberof NegotiationProposalValueDto
     */
    'valueType'?: NegotiationValueType;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationProposalValueDto
     */
    'bool'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof NegotiationProposalValueDto
     */
    'string'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'int32'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'double'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValueDto
     */
    'decimal'?: number | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationProposalValueDto
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValueDto
     */
    'price'?: NegotiationProposalValuePriceDto;
}


/**
 * 
 * @export
 * @interface NegotiationProposalValuePriceDto
 */
export interface NegotiationProposalValuePriceDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'price'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'minPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralPriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'maxPrice'?: GeneralPriceDto;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'initial'?: NegotiationProposalValuePriceDto;
    /**
     * 
     * @type {NegotiationProposalValuePriceDto}
     * @memberof NegotiationProposalValuePriceDto
     */
    'prev'?: NegotiationProposalValuePriceDto;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof NegotiationProposalValuePriceDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface NegotiationReferenceDto
 */
export interface NegotiationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {NegotiationType}
     * @memberof NegotiationReferenceDto
     */
    'type'?: NegotiationType;
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof NegotiationReferenceDto
     */
    'localNumber'?: string | null;
}


/**
 * 
 * @export
 * @interface NegotiationResultDto
 */
export interface NegotiationResultDto {
    /**
     * 
     * @type {ConsensusType}
     * @memberof NegotiationResultDto
     */
    'consensusType'?: ConsensusType;
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof NegotiationResultDto
     */
    'approvalStatus'?: ApprovalStatus;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationResultDto
     */
    'isForced'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationResultDto
     */
    'isAuto'?: boolean;
    /**
     * 
     * @type {NegotiationProposalValueDto}
     * @memberof NegotiationResultDto
     */
    'value'?: NegotiationProposalValueDto;
}


/**
 * 
 * @export
 * @interface NegotiationSettingsDto
 */
export interface NegotiationSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowCreateNewProposal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'requireProposalResolution'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowReopen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'allowChangeProposalResponse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NegotiationSettingsDto
     */
    'requireProposalConsensus'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationSettingsDto
     */
    'deadlineAt'?: string | null;
    /**
     * 
     * @type {ApprovalResponseType}
     * @memberof NegotiationSettingsDto
     */
    'autoResponseTypeOnDeadline'?: ApprovalResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NegotiationSettingsDto
     */
    'deadlineHandledAt'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationStatus = {
    None: 'None',
    Open: 'Open',
    Escalated: 'Escalated',
    Reopened: 'Reopened',
    Resolved: 'Resolved'
} as const;

export type NegotiationStatus = typeof NegotiationStatus[keyof typeof NegotiationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationType = {
    None: 'None',
    Approval: 'Approval',
    Value: 'Value'
} as const;

export type NegotiationType = typeof NegotiationType[keyof typeof NegotiationType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NegotiationValueType = {
    None: 'None',
    Bool: 'Bool',
    String: 'String',
    Int32: 'Int32',
    Double: 'Double',
    Decimal: 'Decimal',
    DateTime: 'DateTime',
    Price: 'Price'
} as const;

export type NegotiationValueType = typeof NegotiationValueType[keyof typeof NegotiationValueType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationChannel = {
    None: 'None',
    InApp: 'InApp',
    Email: 'Email',
    Sms: 'Sms',
    Push: 'Push',
    WebPush: 'WebPush'
} as const;

export type NotificationChannel = typeof NotificationChannel[keyof typeof NotificationChannel];


/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {NotificationChannel}
     * @memberof NotificationDto
     */
    'channel'?: NotificationChannel;
    /**
     * 
     * @type {NotificationImportance}
     * @memberof NotificationDto
     */
    'importance'?: NotificationImportance;
    /**
     * 
     * @type {InAppChannelInfoDto}
     * @memberof NotificationDto
     */
    'inApp'?: InAppChannelInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isRead'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'readAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'sentAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof NotificationDto
     */
    'createdAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationHubClientMethodName = {
    TestNotify: 'TestNotify',
    Broadcast: 'Broadcast',
    Echo: 'Echo',
    ServerErrorOccurred: 'ServerErrorOccurred',
    InAppNotificationReceived: 'InAppNotificationReceived'
} as const;

export type NotificationHubClientMethodName = typeof NotificationHubClientMethodName[keyof typeof NotificationHubClientMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationHubServerMethodName = {
    Echo: 'Echo'
} as const;

export type NotificationHubServerMethodName = typeof NotificationHubServerMethodName[keyof typeof NotificationHubServerMethodName];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationImportance = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;

export type NotificationImportance = typeof NotificationImportance[keyof typeof NotificationImportance];


/**
 * 
 * @export
 * @interface PaginationDtoOfTItem
 */
export interface PaginationDtoOfTItem {
    /**
     * 
     * @type {Array<object>}
     * @memberof PaginationDtoOfTItem
     */
    'items'?: Array<object>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTItem
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTeslaConnectionDto
 */
export interface PaginationDtoOfTeslaConnectionDto {
    /**
     * 
     * @type {Array<TeslaConnectionDto>}
     * @memberof PaginationDtoOfTeslaConnectionDto
     */
    'items'?: Array<TeslaConnectionDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTeslaConnectionDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationDtoOfTeslaVehicleListItemDto
 */
export interface PaginationDtoOfTeslaVehicleListItemDto {
    /**
     * 
     * @type {Array<TeslaVehicleListItemDto>}
     * @memberof PaginationDtoOfTeslaVehicleListItemDto
     */
    'items'?: Array<TeslaVehicleListItemDto>;
    /**
     * 
     * @type {PaginationInfoDto}
     * @memberof PaginationDtoOfTeslaVehicleListItemDto
     */
    'pagination'?: PaginationInfoDto;
}
/**
 * 
 * @export
 * @interface PaginationInfoDto
 */
export interface PaginationInfoDto {
    /**
     * Total number of records in the requested dataset.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'totalCount'?: number;
    /**
     * Zero-based offset.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'offset'?: number;
    /**
     * The same as page size.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'limit'?: number;
    /**
     * Current page zero-based index.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'currentPage'?: number;
    /**
     * Total number of pages.
     * @type {number}
     * @memberof PaginationInfoDto
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParticipantOnlineStatus = {
    None: 'None',
    Offline: 'Offline',
    Online: 'Online'
} as const;

export type ParticipantOnlineStatus = typeof ParticipantOnlineStatus[keyof typeof ParticipantOnlineStatus];


/**
 * 
 * @export
 * @interface PartyAndMemberReferenceDto
 */
export interface PartyAndMemberReferenceDto {
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyAndMemberReferenceDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberReferenceDto}
     * @memberof PartyAndMemberReferenceDto
     */
    'member'?: PartyMemberReferenceDto;
}
/**
 * 
 * @export
 * @interface PartyChangedDto
 */
export interface PartyChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PartyChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PartyChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PartyChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {PartyDto}
     * @memberof PartyChangedDto
     */
    'entity': PartyDto;
    /**
     * 
     * @type {string}
     * @memberof PartyChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface PartyDto
 */
export interface PartyDto {
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyDto
     */
    'parentParty'?: PartyReferenceDto;
    /**
     * 
     * @type {PartySourceDto}
     * @memberof PartyDto
     */
    'source'?: PartySourceDto;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyDto
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartyDto
     */
    'memberCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartyDto
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartyMemberChangedDto
 */
export interface PartyMemberChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PartyMemberChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PartyMemberChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PartyMemberChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {PartyMemberDto}
     * @memberof PartyMemberChangedDto
     */
    'entity': PartyMemberDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface PartyMemberDto
 */
export interface PartyMemberDto {
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyMemberDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {PartyMemberSourceDto}
     * @memberof PartyMemberDto
     */
    'source'?: PartyMemberSourceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {UserMembershipType}
     * @memberof PartyMemberDto
     */
    'membershipType'?: UserMembershipType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberDto
     */
    'isSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberDto
     */
    'comparisonId'?: string;
}


/**
 * 
 * @export
 * @interface PartyMemberReferenceDto
 */
export interface PartyMemberReferenceDto {
    /**
     * 
     * @type {PartyReferenceDto}
     * @memberof PartyMemberReferenceDto
     */
    'party'?: PartyReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberReferenceDto
     */
    'isInferred'?: boolean;
    /**
     * 
     * @type {PartyMemberSourceDto}
     * @memberof PartyMemberReferenceDto
     */
    'source'?: PartyMemberSourceDto;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartyMemberReferenceDto
     */
    'isSystem'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberReferenceDto
     */
    'comparisonId'?: string;
}
/**
 * 
 * @export
 * @interface PartyMemberSourceDto
 */
export interface PartyMemberSourceDto {
    /**
     * 
     * @type {PartyMemberSourceType}
     * @memberof PartyMemberSourceDto
     */
    'sourceType'?: PartyMemberSourceType;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'systemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerContactId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartyMemberSourceDto
     */
    'customerUserId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartyMemberSourceType = {
    None: 'None',
    System: 'System',
    TenantUser: 'TenantUser',
    DepartmentUser: 'DepartmentUser',
    User: 'User',
    UserGroupUser: 'UserGroupUser',
    CustomerContact: 'CustomerContact',
    CustomerContactUser: 'CustomerContactUser'
} as const;

export type PartyMemberSourceType = typeof PartyMemberSourceType[keyof typeof PartyMemberSourceType];


/**
 * 
 * @export
 * @interface PartyReference
 */
export interface PartyReference {
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyReference
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyReference
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartyReference
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartyReferenceDto
 */
export interface PartyReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {PartyType}
     * @memberof PartyReferenceDto
     */
    'type'?: PartyType;
    /**
     * 
     * @type {string}
     * @memberof PartyReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartyReferenceDto
     */
    'isSystem'?: boolean;
}


/**
 * 
 * @export
 * @interface PartySourceDto
 */
export interface PartySourceDto {
    /**
     * 
     * @type {PartySourceType}
     * @memberof PartySourceDto
     */
    'sourceType'?: PartySourceType;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'systemIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'userGroupIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartySourceDto
     */
    'customerId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PartySourceType = {
    None: 'None',
    System: 'System',
    Tenant: 'Tenant',
    Department: 'Department',
    User: 'User',
    UserGroup: 'UserGroup',
    Customer: 'Customer'
} as const;

export type PartySourceType = typeof PartySourceType[keyof typeof PartySourceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PartyType = {
    None: 'None',
    System: 'System',
    Tenant: 'Tenant',
    Department: 'Department',
    User: 'User',
    UserGroup: 'UserGroup',
    Customer: 'Customer'
} as const;

export type PartyType = typeof PartyType[keyof typeof PartyType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    None: 'None',
    Manual: 'Manual',
    Stripe: 'Stripe'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentRegularityType = {
    None: 'None',
    OneTime: 'OneTime',
    Recurring: 'Recurring'
} as const;

export type PaymentRegularityType = typeof PaymentRegularityType[keyof typeof PaymentRegularityType];


/**
 * 
 * @export
 * @interface PoolChangedDto
 */
export interface PoolChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PoolChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {PoolDto}
     * @memberof PoolChangedDto
     */
    'entity': PoolDto;
    /**
     * 
     * @type {string}
     * @memberof PoolChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface PoolDto
 */
export interface PoolDto {
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolDto
     */
    'itemEntityType'?: PoolItemEntityType;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolDto
     */
    'itemEntityTypes'?: Array<PoolItemEntityType> | null;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolDto
     */
    'itemEntitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolSettingsDto}
     * @memberof PoolDto
     */
    'settings'?: PoolSettingsDto;
    /**
     * 
     * @type {PoolStateDto}
     * @memberof PoolDto
     */
    'state'?: PoolStateDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface PoolItemChangedDto
 */
export interface PoolItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof PoolItemChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof PoolItemChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {PoolItemDto}
     * @memberof PoolItemChangedDto
     */
    'entity': PoolItemDto;
    /**
     * 
     * @type {string}
     * @memberof PoolItemChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface PoolItemDto
 */
export interface PoolItemDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolItemDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolItemDto
     */
    'entitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemDto
     */
    'usedByEntity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {PoolItemSettingsDto}
     * @memberof PoolItemDto
     */
    'settings'?: PoolItemSettingsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemDto
     */
    'itemId'?: string;
}


/**
 * Describes entity types that are suitable for pools.  Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const PoolItemEntityType = {
    None: 'None',
    Asset: 'Asset',
    Vehicle: 'Vehicle',
    Accessory: 'Accessory'
} as const;

export type PoolItemEntityType = typeof PoolItemEntityType[keyof typeof PoolItemEntityType];


/**
 * 
 * @export
 * @interface PoolItemFullReferenceDto
 */
export interface PoolItemFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemFullReferenceDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemFullReferenceDto
     */
    'entityType'?: PoolItemEntityType;
    /**
     * 
     * @type {GeneralEntitySubTypeDto}
     * @memberof PoolItemFullReferenceDto
     */
    'entitySubType'?: GeneralEntitySubTypeDto;
    /**
     * 
     * @type {PoolItemStatus}
     * @memberof PoolItemFullReferenceDto
     */
    'status'?: PoolItemStatus;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'statusReason'?: string | null;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemFullReferenceDto
     */
    'entity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {GeneralStrictEntityRelationDto}
     * @memberof PoolItemFullReferenceDto
     */
    'usedByEntity'?: GeneralStrictEntityRelationDto;
    /**
     * 
     * @type {PoolItemSettingsDto}
     * @memberof PoolItemFullReferenceDto
     */
    'settings'?: PoolItemSettingsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PoolItemFullReferenceDto
     */
    'itemId'?: string;
}


/**
 * 
 * @export
 * @interface PoolItemMeta
 */
export interface PoolItemMeta {
    /**
     * 
     * @type {string}
     * @memberof PoolItemMeta
     */
    'poolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemMeta
     */
    'poolItemId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoolItemMeta
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * 
 * @export
 * @interface PoolItemReferenceDto
 */
export interface PoolItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolItemReferenceDto
     */
    'poolId'?: string;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolItemReferenceDto
     */
    'type'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolItemReferenceDto
     */
    'entityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface PoolItemSettingsDto
 */
export interface PoolItemSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PoolItemSettingsDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PoolItemStatus = {
    None: 'None',
    Pending: 'Pending',
    Available: 'Available',
    Locked: 'Locked',
    Reserved: 'Reserved',
    Unavailable: 'Unavailable'
} as const;

export type PoolItemStatus = typeof PoolItemStatus[keyof typeof PoolItemStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const PoolItemType = {
    None: 'None',
    Specific: 'Specific',
    Abstract: 'Abstract'
} as const;

export type PoolItemType = typeof PoolItemType[keyof typeof PoolItemType];


/**
 * 
 * @export
 * @interface PoolReferenceDto
 */
export interface PoolReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PoolReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {PoolStructureType}
     * @memberof PoolReferenceDto
     */
    'structureType'?: PoolStructureType;
    /**
     * 
     * @type {PoolItemType}
     * @memberof PoolReferenceDto
     */
    'itemType'?: PoolItemType;
    /**
     * 
     * @type {PoolItemEntityType}
     * @memberof PoolReferenceDto
     */
    'itemEntityType'?: PoolItemEntityType;
}


/**
 * 
 * @export
 * @interface PoolSettingsDto
 */
export interface PoolSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsDto
     */
    'minItems'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolSettingsDto
     */
    'maxItems'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PoolSettingsDto
     */
    'isEnsureEntityBelongToSinglePool'?: boolean;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolSettingsDto
     */
    'allowedEntityTypes'?: Array<PoolItemEntityType> | null;
}
/**
 * 
 * @export
 * @interface PoolStateDto
 */
export interface PoolStateDto {
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {PoolStateDtoCountByStatusMap}
     * @memberof PoolStateDto
     */
    'countByStatusMap'?: PoolStateDtoCountByStatusMap;
    /**
     * 
     * @type {PoolStateDtoCountByEntityTypeMap}
     * @memberof PoolStateDto
     */
    'countByEntityTypeMap'?: PoolStateDtoCountByEntityTypeMap;
    /**
     * 
     * @type {Array<PoolItemEntityType>}
     * @memberof PoolStateDto
     */
    'entityTypes'?: Array<PoolItemEntityType>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof PoolStateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasAny'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasUniformItems'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoolStateDto
     */
    'hasDiverseItems'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'pendingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'availableCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'lockedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDto
     */
    'unavailableCount'?: number;
}
/**
 * 
 * @export
 * @interface PoolStateDtoCountByEntityTypeMap
 */
export interface PoolStateDtoCountByEntityTypeMap {
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByEntityTypeMap
     */
    'None'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByEntityTypeMap
     */
    'Asset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByEntityTypeMap
     */
    'Vehicle'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByEntityTypeMap
     */
    'Accessory'?: number;
}
/**
 * 
 * @export
 * @interface PoolStateDtoCountByStatusMap
 */
export interface PoolStateDtoCountByStatusMap {
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'None'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'Pending'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'Available'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'Locked'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'Reserved'?: number;
    /**
     * 
     * @type {number}
     * @memberof PoolStateDtoCountByStatusMap
     */
    'Unavailable'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PoolStructureType = {
    None: 'None',
    Uniform: 'Uniform',
    Diverse: 'Diverse'
} as const;

export type PoolStructureType = typeof PoolStructureType[keyof typeof PoolStructureType];


/**
 * 
 * @export
 * @interface PoolsMetaDto
 */
export interface PoolsMetaDto {
    /**
     * 
     * @type {Array<PoolItemMeta>}
     * @memberof PoolsMetaDto
     */
    'pools'?: Array<PoolItemMeta> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PoolsMetaDto
     */
    'hasPool'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaDto
     */
    'poolIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PoolsMetaDto
     */
    'poolItemIds'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolsMetaDto
     */
    'poolIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PoolsMetaDto
     */
    'poolItemIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof PoolsMetaDto
     */
    'isEnsureEntityBelongToSinglePoolSetForAny'?: boolean;
}
/**
 * Describes how price summary is entered/calculated.
 * @export
 * @enum {string}
 */

export const PriceSummaryCalcType = {
    None: 'None',
    BasedOnSubTotal: 'BasedOnSubTotal',
    BasedOnTotal: 'BasedOnTotal'
} as const;

export type PriceSummaryCalcType = typeof PriceSummaryCalcType[keyof typeof PriceSummaryCalcType];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductLocationChangedDto
 */
export interface ProductLocationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof ProductLocationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof ProductLocationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {ProductLocationDto}
     * @memberof ProductLocationChangedDto
     */
    'entity': ProductLocationDto;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface ProductLocationDto
 */
export interface ProductLocationDto {
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductLocationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof ProductLocationDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ProductLocationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof ProductLocationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductLocationReferenceDto
 */
export interface ProductLocationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocationReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProposalStatus = {
    None: 'None',
    Open: 'Open',
    Closed: 'Closed',
    AutoClosed: 'AutoClosed'
} as const;

export type ProposalStatus = typeof ProposalStatus[keyof typeof ProposalStatus];


/**
 * 
 * @export
 * @interface RepairCatalogChangedDto
 */
export interface RepairCatalogChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairCatalogChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairCatalogChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairCatalogChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairCatalogDto}
     * @memberof RepairCatalogChangedDto
     */
    'entity': RepairCatalogDto;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RepairCatalogDto
 */
export interface RepairCatalogDto {
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogDto
     */
    'isDefault'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairCatalogDto
     */
    'isGlobal'?: boolean;
}
/**
 * 
 * @export
 * @interface RepairCatalogReferenceDto
 */
export interface RepairCatalogReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairCatalogReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @interface RepairMaterialChangedDto
 */
export interface RepairMaterialChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairMaterialChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairMaterialChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairMaterialChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairMaterialDto}
     * @memberof RepairMaterialChangedDto
     */
    'entity': RepairMaterialDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RepairMaterialDto
 */
export interface RepairMaterialDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'sourceMaterialId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairMaterialDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairMaterialDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairMaterialDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairMaterialDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairMaterialFullReferenceDto
 */
export interface RepairMaterialFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialFullReferenceDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairMaterialFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialFullReferenceDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairMaterialFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairMaterialFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairMaterialFullReferenceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairMaterialReferenceDto
 */
export interface RepairMaterialReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairMaterialReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairOperationChangedDto
 */
export interface RepairOperationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairOperationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairOperationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairOperationDto}
     * @memberof RepairOperationChangedDto
     */
    'entity': RepairOperationDto;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RepairOperationContentDto
 */
export interface RepairOperationContentDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationContentDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationContentDto
     */
    'vehicleDamageIds'?: Array<string>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairOperationContentDto
     */
    'currency'?: GeneralCurrencyDto;
}
/**
 * 
 * @export
 * @interface RepairOperationDto
 */
export interface RepairOperationDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOperationDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof RepairOperationDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof RepairOperationDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfRepairOperationStage}
     * @memberof RepairOperationDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfRepairOperationStage;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'description'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'date'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof RepairOperationDto
     */
    'responsibleUser'?: GeneralByWhoDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairOperationDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {Array<RepairOperationItemDto>}
     * @memberof RepairOperationDto
     */
    'items'?: Array<RepairOperationItemDto>;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairOperationDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof RepairOperationDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof RepairOperationDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof RepairOperationDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairOperationDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOperationDto
     */
    'canBeUpdated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOperationDto
     */
    'canBeDeleted'?: boolean;
    /**
     * 
     * @type {GeneralDiscount}
     * @memberof RepairOperationDto
     */
    'discount'?: GeneralDiscount;
    /**
     * 
     * @type {GeneralTax}
     * @memberof RepairOperationDto
     */
    'tax'?: GeneralTax;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RepairOperationItemDto
 */
export interface RepairOperationItemDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleDamageFullReferenceDto}
     * @memberof RepairOperationItemDto
     */
    'damage'?: VehicleDamageFullReferenceDto;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'cost'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationItemDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairOperationItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairOperationItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairOperationItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOperationItemDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RepairOperationReferenceDto
 */
export interface RepairOperationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOperationReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface RepairOperationResultDto
 */
export interface RepairOperationResultDto {
    /**
     * 
     * @type {string}
     * @memberof RepairOperationResultDto
     */
    'repairOperationId'?: string;
}
/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const RepairOperationStage = {
    None: 'None',
    Draft: 'Draft',
    InProgress: 'InProgress',
    Completed: 'Completed'
} as const;

export type RepairOperationStage = typeof RepairOperationStage[keyof typeof RepairOperationStage];


/**
 * 
 * @export
 * @interface RepairSparePartChangedDto
 */
export interface RepairSparePartChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSparePartChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairSparePartChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairSparePartChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairSparePartDto}
     * @memberof RepairSparePartChangedDto
     */
    'entity': RepairSparePartDto;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartDetalizationDto
 */
export interface RepairSparePartDetalizationDto {
    /**
     * 
     * @type {RepairSparePartDetalizationType}
     * @memberof RepairSparePartDetalizationDto
     */
    'type'?: RepairSparePartDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSparePartDetalizationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSparePartDetalizationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof RepairSparePartDetalizationDto
     */
    'modification'?: VehicleModificationReferenceDto;
}


/**
 * Detalizations go from more general to more specific.  Priority: lower = more general, higher = more specific. Specific has priority.
 * @export
 * @enum {string}
 */

export const RepairSparePartDetalizationType = {
    None: 'None',
    General: 'General',
    VehicleSize: 'VehicleSize',
    BodyType: 'BodyType',
    Make: 'Make',
    Model: 'Model',
    Generation: 'Generation',
    Modification: 'Modification'
} as const;

export type RepairSparePartDetalizationType = typeof RepairSparePartDetalizationType[keyof typeof RepairSparePartDetalizationType];


/**
 * 
 * @export
 * @interface RepairSparePartDto
 */
export interface RepairSparePartDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSparePartDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSparePartDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationDto>}
     * @memberof RepairSparePartDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairSparePartDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSparePartDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSparePartDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSparePartDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'subTotal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'subTotalIncDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartDto
     */
    'total'?: number | null;
    /**
     * 
     * @type {EntityMatchMetaDto}
     * @memberof RepairSparePartDto
     */
    'matchMeta'?: EntityMatchMetaDto;
}


/**
 * 
 * @export
 * @interface RepairSparePartFullReferenceDto
 */
export interface RepairSparePartFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'partNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'brandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSparePartFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSparePartFullReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {Array<RepairSparePartDetalizationDto>}
     * @memberof RepairSparePartFullReferenceDto
     */
    'detalizations'?: Array<RepairSparePartDetalizationDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof RepairSparePartFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSparePartFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSparePartFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'subTotal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'subTotalIncDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RepairSparePartFullReferenceDto
     */
    'total'?: number | null;
}


/**
 * 
 * @export
 * @interface RepairSparePartReferenceDto
 */
export interface RepairSparePartReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSparePartReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RepairSpecChangedDto
 */
export interface RepairSpecChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairSpecChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairSpecChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairSpecChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairSpecDto}
     * @memberof RepairSpecChangedDto
     */
    'entity': RepairSpecDto;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecChangedDto
     */
    'tenantId': string | null;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.Repair.RepairSpec.RepairSpecData\" />
 * @export
 * @interface RepairSpecDataDto
 */
export interface RepairSpecDataDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecDataDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecDataDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSpecDataDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof RepairSpecDataDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairSpecDetalizationDto}
     * @memberof RepairSpecDataDto
     */
    'detalization'?: RepairSpecDetalizationDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemDataDto>}
     * @memberof RepairSpecDataDto
     */
    'items'?: Array<RepairSpecItemDataDto>;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDataDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDataDto
     */
    'isCreatedOnTheSpot'?: boolean;
    /**
     * 
     * @type {RepairType}
     * @memberof RepairSpecDataDto
     */
    'repairType'?: RepairType;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDataDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecDetalizationDto
 */
export interface RepairSpecDetalizationDto {
    /**
     * 
     * @type {RepairSpecDetalizationType}
     * @memberof RepairSpecDetalizationDto
     */
    'type'?: RepairSpecDetalizationType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof RepairSpecDetalizationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof RepairSpecDetalizationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof RepairSpecDetalizationDto
     */
    'modification'?: VehicleModificationReferenceDto;
}


/**
 * Detalizations go from more general to more specific.  Priority: lower = more general, higher = more specific. Specific has priority.
 * @export
 * @enum {string}
 */

export const RepairSpecDetalizationType = {
    None: 'None',
    General: 'General',
    VehicleSize: 'VehicleSize',
    BodyType: 'BodyType',
    Make: 'Make',
    Model: 'Model',
    Generation: 'Generation',
    Modification: 'Modification'
} as const;

export type RepairSpecDetalizationType = typeof RepairSpecDetalizationType[keyof typeof RepairSpecDetalizationType];


/**
 * 
 * @export
 * @interface RepairSpecDto
 */
export interface RepairSpecDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'sourceSpecId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof RepairSpecDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof RepairSpecDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof RepairSpecDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {RepairSpecDetalizationDto}
     * @memberof RepairSpecDto
     */
    'detalization'?: RepairSpecDetalizationDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {Array<RepairSpecItemDto>}
     * @memberof RepairSpecDto
     */
    'items'?: Array<RepairSpecItemDto>;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.Repair.RepairSpec.RepairSpecEntity.LastReferenceSyncAt\" />
     * @type {string}
     * @memberof RepairSpecDto
     */
    'lastReferenceSyncAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSpecDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairSpecDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {RepairType}
     * @memberof RepairSpecDto
     */
    'repairType'?: RepairType;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecDto
     */
    'total'?: number;
    /**
     * 
     * @type {EntityMatchMetaDto}
     * @memberof RepairSpecDto
     */
    'matchMeta'?: EntityMatchMetaDto;
}


/**
 * 
 * @export
 * @interface RepairSpecItemCustomItemDto
 */
export interface RepairSpecItemCustomItemDto {
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemCustomItemDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemCustomItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemCustomItemDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemCustomItemDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemCustomItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemCustomItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemCustomItemDto
     */
    'total'?: number;
}


/**
 * <inheritdoc cref=\"T:Domain.Entities.Repair.RepairSpec.RepairSpecItemData\" />
 * @export
 * @interface RepairSpecItemDataDto
 */
export interface RepairSpecItemDataDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDataDto
     */
    'id'?: string;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemDataDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {RepairMaterialFullReferenceDto}
     * @memberof RepairSpecItemDataDto
     */
    'material'?: RepairMaterialFullReferenceDto;
    /**
     * 
     * @type {RepairWorkFullReferenceDto}
     * @memberof RepairSpecItemDataDto
     */
    'work'?: RepairWorkFullReferenceDto;
    /**
     * 
     * @type {RepairSpecSparePartDto}
     * @memberof RepairSpecItemDataDto
     */
    'sparePart'?: RepairSpecSparePartDto;
    /**
     * 
     * @type {RepairSpecItemCustomItemDto}
     * @memberof RepairSpecItemDataDto
     */
    'custom'?: RepairSpecItemCustomItemDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'spotFactor'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDataDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemDataDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDataDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemDataDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemDataDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDataDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairSpecItemDto
 */
export interface RepairSpecItemDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {RepairSpecItemType}
     * @memberof RepairSpecItemDto
     */
    'type'?: RepairSpecItemType;
    /**
     * 
     * @type {RepairMaterialFullReferenceDto}
     * @memberof RepairSpecItemDto
     */
    'material'?: RepairMaterialFullReferenceDto;
    /**
     * 
     * @type {RepairWorkFullReferenceDto}
     * @memberof RepairSpecItemDto
     */
    'work'?: RepairWorkFullReferenceDto;
    /**
     * 
     * @type {RepairSpecSparePartDto}
     * @memberof RepairSpecItemDto
     */
    'sparePart'?: RepairSpecSparePartDto;
    /**
     * 
     * @type {RepairSpecItemCustomItemDto}
     * @memberof RepairSpecItemDto
     */
    'custom'?: RepairSpecItemCustomItemDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'spotFactor'?: number;
    /**
     * <inheritdoc cref=\"P:Domain.Entities.Repair.RepairSpec.RepairSpecItem.LastReferenceSyncAt\" />
     * @type {string}
     * @memberof RepairSpecItemDto
     */
    'lastReferenceSyncAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDto
     */
    'isDetailsHidden'?: boolean;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairSpecItemDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSpecItemDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecItemDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecItemDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecItemDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RepairSpecItemType = {
    None: 'None',
    Material: 'Material',
    SparePart: 'SparePart',
    Work: 'Work',
    Custom: 'Custom'
} as const;

export type RepairSpecItemType = typeof RepairSpecItemType[keyof typeof RepairSpecItemType];


/**
 * 
 * @export
 * @interface RepairSpecReferenceDto
 */
export interface RepairSpecReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairSpecReferenceDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {RepairSpecDetalizationType}
     * @memberof RepairSpecReferenceDto
     */
    'detalizationType'?: RepairSpecDetalizationType;
}


/**
 * 
 * @export
 * @interface RepairSpecSparePartDto
 */
export interface RepairSpecSparePartDto {
    /**
     * 
     * @type {RepairSparePartFullReferenceDto}
     * @memberof RepairSpecSparePartDto
     */
    'sparePart'?: RepairSparePartFullReferenceDto;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairSpecSparePartDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairSpecSparePartDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairSpecSparePartDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairSpecSparePartDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RepairType = {
    None: 'None',
    Spot: 'Spot',
    Full: 'Full'
} as const;

export type RepairType = typeof RepairType[keyof typeof RepairType];


/**
 * 
 * @export
 * @interface RepairWorkChangedDto
 */
export interface RepairWorkChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairWorkChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RepairWorkChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RepairWorkChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RepairWorkDto}
     * @memberof RepairWorkChangedDto
     */
    'entity': RepairWorkDto;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RepairWorkDto
 */
export interface RepairWorkDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'sourceWorkId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairWorkDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairWorkDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairWorkDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairWorkDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairWorkDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RepairWorkDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkDto
     */
    'isExpendablesIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairWorkFullReferenceDto
 */
export interface RepairWorkFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'repairCatalogId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {MeasurementUnit}
     * @memberof RepairWorkFullReferenceDto
     */
    'unit'?: MeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'expendablesPercent'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof RepairWorkFullReferenceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isGlobal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isDividable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairWorkFullReferenceDto
     */
    'isExpendablesIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairWorkFullReferenceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface RepairWorkReferenceDto
 */
export interface RepairWorkReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairWorkReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReportMyChatParticipantStatusDto
 */
export interface ReportMyChatParticipantStatusDto {
    /**
     * 
     * @type {string}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'participantId'?: string;
    /**
     * 
     * @type {ParticipantOnlineStatus}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'onlineStatus'?: ParticipantOnlineStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'onlyToConnectionIds'?: Array<string> | null;
    /**
     * If true, receiving participant should report own status to the sender.
     * @type {boolean}
     * @memberof ReportMyChatParticipantStatusDto
     */
    'isReportBack'?: boolean | null;
}


/**
 * 
 * @export
 * @interface RoleChangedDto
 */
export interface RoleChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof RoleChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof RoleChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {RoleDto}
     * @memberof RoleChangedDto
     */
    'entity': RoleDto;
    /**
     * 
     * @type {string}
     * @memberof RoleChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'builtInRole'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isScoped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'isTest'?: boolean;
    /**
     * 
     * @type {Array<AppPermission>}
     * @memberof RoleDto
     */
    'permissions'?: Array<AppPermission>;
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'sortOrder'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RoleDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof RoleDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'canBeEdited'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleDto
     */
    'canBeDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface RoleReferenceDto
 */
export interface RoleReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * 
 * @export
 * @interface Size
 */
export interface Size {
    /**
     * 
     * @type {boolean}
     * @memberof Size
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof Size
     */
    'height'?: number;
}
/**
 * Defines sorting on one or multiple fields.
 * @export
 * @interface SortDefinitionDto
 */
export interface SortDefinitionDto {
    /**
     * Comma-separated list of fields to sort by.
     * @type {string}
     * @memberof SortDefinitionDto
     */
    'sortBy'?: string | null;
    /**
     * Comma-separated list of sort orders, with indexes corresponding to fields.  See values in SortOrder enum.
     * @type {string}
     * @memberof SortDefinitionDto
     */
    'sortOrder'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortOrder = {
    None: 'None',
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];


/**
 * 
 * @export
 * @interface SpotChangedDto
 */
export interface SpotChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SpotChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SpotChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SpotChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {SpotDto}
     * @memberof SpotChangedDto
     */
    'entity': SpotDto;
    /**
     * 
     * @type {string}
     * @memberof SpotChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface SpotDto
 */
export interface SpotDto {
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof SpotDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof SpotDto
     */
    'address'?: GeneralAddressDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SpotDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SpotDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface SpotFullReferenceDto
 */
export interface SpotFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotFullReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {ProductLocationReferenceDto}
     * @memberof SpotFullReferenceDto
     */
    'productLocation'?: ProductLocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {Array<SpotType>}
     * @memberof SpotFullReferenceDto
     */
    'types'?: Array<SpotType>;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotFullReferenceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {GeneralAddressDto}
     * @memberof SpotFullReferenceDto
     */
    'address'?: GeneralAddressDto;
}
/**
 * 
 * @export
 * @interface SpotReferenceDto
 */
export interface SpotReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof SpotReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotReferenceDto
     */
    'localNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SpotType = {
    None: 'None',
    Delivery: 'Delivery',
    CheckOut: 'CheckOut',
    CheckIn: 'CheckIn',
    Pickup: 'Pickup',
    Return: 'Return',
    Service: 'Service'
} as const;

export type SpotType = typeof SpotType[keyof typeof SpotType];


/**
 * 
 * @export
 * @interface SubscribeOnChatActivityUpdatesDto
 */
export interface SubscribeOnChatActivityUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeOnChatActivityUpdatesDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface SubscribeOnDataUpdatesDto
 */
export interface SubscribeOnDataUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'channelName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'channelNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscribeOnDataUpdatesDto
     */
    'allChannelNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface SubscriptionAssetDto
 */
export interface SubscriptionAssetDto {
    /**
     * 
     * @type {AssetReferenceDto}
     * @memberof SubscriptionAssetDto
     */
    'asset'?: AssetReferenceDto;
    /**
     * 
     * @type {AssetEntityType}
     * @memberof SubscriptionAssetDto
     */
    'entityType'?: AssetEntityType;
    /**
     * 
     * @type {PoolItemReferenceDto}
     * @memberof SubscriptionAssetDto
     */
    'poolItem'?: PoolItemReferenceDto;
}


/**
 * 
 * @export
 * @interface SubscriptionChangedDto
 */
export interface SubscriptionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SubscriptionChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SubscriptionChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {SubscriptionDto}
     * @memberof SubscriptionChangedDto
     */
    'entity': SubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    'isCurrent'?: boolean;
    /**
     * 
     * @type {SubscriptionSelectedPlanDto}
     * @memberof SubscriptionDto
     */
    'plan'?: SubscriptionSelectedPlanDto;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof SubscriptionDto
     */
    'status'?: SubscriptionStatus;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof SubscriptionDto
     */
    'period'?: BillingPeriod;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'currentPeriodStartsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'currentPeriodEndsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'startsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'endsAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'activatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'pastDueAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'activatedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'lastInvoiceId'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionDto
     */
    'updatedBy'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanChangedDto
 */
export interface SubscriptionPlanChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof SubscriptionPlanChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof SubscriptionPlanChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {SubscriptionPlanDto}
     * @memberof SubscriptionPlanChangedDto
     */
    'entity': SubscriptionPlanDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanDto
 */
export interface SubscriptionPlanDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanDto
     */
    'isFree'?: boolean;
    /**
     * 
     * @type {Array<SubscriptionPlanPriceDto>}
     * @memberof SubscriptionPlanDto
     */
    'prices'?: Array<SubscriptionPlanPriceDto>;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof SubscriptionPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionPlanDto
     */
    'limits'?: object;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanDurationDto
 */
export interface SubscriptionPlanDurationDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanDurationDto
     */
    'id'?: string;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanDurationDto
     */
    'minDuration'?: string | null;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanDurationDto
     */
    'discount'?: GeneralDiscountDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanExtraOptionDto
 */
export interface SubscriptionPlanExtraOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionDto}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'option'?: SubscriptionPlanOptionDto;
    /**
     * 
     * @type {PaymentRegularityType}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'regularityType'?: PaymentRegularityType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanExtraOptionDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanIncludedOptionDto
 */
export interface SubscriptionPlanIncludedOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanIncludedOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionDto}
     * @memberof SubscriptionPlanIncludedOptionDto
     */
    'option'?: SubscriptionPlanOptionDto;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanOptionDto
 */
export interface SubscriptionPlanOptionDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'id'?: string;
    /**
     * 
     * @type {SubscriptionPlanOptionType}
     * @memberof SubscriptionPlanOptionDto
     */
    'type'?: SubscriptionPlanOptionType;
    /**
     * 
     * @type {AccessoryReferenceDto}
     * @memberof SubscriptionPlanOptionDto
     */
    'accessory'?: AccessoryReferenceDto;
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleDto}
     * @memberof SubscriptionPlanOptionDto
     */
    'forVehicle'?: SubscriptionPlanOptionForVehicleDto;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanOptionDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanOptionForVehicleDto
 */
export interface SubscriptionPlanOptionForVehicleDto {
    /**
     * 
     * @type {SubscriptionPlanOptionForVehicleType}
     * @memberof SubscriptionPlanOptionForVehicleDto
     */
    'type'?: SubscriptionPlanOptionForVehicleType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanOptionForVehicleDto
     */
    'mileage'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionPlanOptionForVehicleType = {
    None: 'None',
    Mileage: 'Mileage',
    FullTankAtReceive: 'FullTankAtReceive',
    FullTankAtReturn: 'FullTankAtReturn',
    DeliverToHome: 'DeliverToHome'
} as const;

export type SubscriptionPlanOptionForVehicleType = typeof SubscriptionPlanOptionForVehicleType[keyof typeof SubscriptionPlanOptionForVehicleType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionPlanOptionType = {
    None: 'None',
    Accessory: 'Accessory',
    ForVehicle: 'ForVehicle'
} as const;

export type SubscriptionPlanOptionType = typeof SubscriptionPlanOptionType[keyof typeof SubscriptionPlanOptionType];


/**
 * 
 * @export
 * @interface SubscriptionPlanPriceDto
 */
export interface SubscriptionPlanPriceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanPriceDto
     */
    'id'?: string;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof SubscriptionPlanPriceDto
     */
    'period'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionPlanPriceDto
     */
    'isBase'?: boolean;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * 
     * @type {GeneralInsuranceDto}
     * @memberof SubscriptionPlanPriceDto
     */
    'insurance'?: GeneralInsuranceDto;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPlanPriceDto
     */
    'total'?: number;
}


/**
 * 
 * @export
 * @interface SubscriptionPlanReferenceDto
 */
export interface SubscriptionPlanReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionPlanSettingsDto
 */
export interface SubscriptionPlanSettingsDto {
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'minDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'maxDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'exactDuration'?: string | null;
    /**
     * Check out how C# TimeSpan is serialized to string for more info.
     * @type {string}
     * @memberof SubscriptionPlanSettingsDto
     */
    'defaultDuration'?: string | null;
}
/**
 * 
 * @export
 * @interface SubscriptionReferenceDto
 */
export interface SubscriptionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface SubscriptionSelectedPlanDto
 */
export interface SubscriptionSelectedPlanDto {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionSelectedPlanDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionSelectedPlanDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionSelectedPlanDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {SubscriptionPlanPriceDto}
     * @memberof SubscriptionSelectedPlanDto
     */
    'price'?: SubscriptionPlanPriceDto;
    /**
     * 
     * @type {Array<AppModule>}
     * @memberof SubscriptionSelectedPlanDto
     */
    'modules'?: Array<AppModule>;
    /**
     * 
     * @type {object}
     * @memberof SubscriptionSelectedPlanDto
     */
    'limits'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionStatus = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Unpaid: 'Unpaid',
    Active: 'Active',
    PastDue: 'PastDue',
    Ended: 'Ended',
    Paused: 'Paused',
    Canceled: 'Canceled'
} as const;

export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];


/**
 * 
 * @export
 * @interface TagStylesDto
 */
export interface TagStylesDto {
    /**
     * 
     * @type {string}
     * @memberof TagStylesDto
     */
    'backgroundColor'?: string | null;
}
/**
 * What/who can be tagged.
 * @export
 * @enum {string}
 */

export const TagSubTargetType = {
    None: 'None',
    DamageDetectionItem: 'DamageDetectionItem',
    DamageDetectionAggregateItem: 'DamageDetectionAggregateItem',
    DamageCostEvaluationItem: 'DamageCostEvaluationItem',
    DamageCostEvaluationAggregateItem: 'DamageCostEvaluationAggregateItem',
    AccessoryCheckItem: 'AccessoryCheckItem',
    NegotiationProposal: 'NegotiationProposal'
} as const;

export type TagSubTargetType = typeof TagSubTargetType[keyof typeof TagSubTargetType];


/**
 * What/who can be tagged.  Must contain only values from Domain.Enums.EntityType.
 * @export
 * @enum {string}
 */

export const TagTargetType = {
    None: 'None',
    CustomTag: 'CustomTag',
    User: 'User',
    Vehicle: 'Vehicle',
    Asset: 'Asset',
    Contract: 'Contract',
    AssessmentFlow: 'AssessmentFlow',
    Customer: 'Customer',
    VehicleDamage: 'VehicleDamage',
    Accessory: 'Accessory',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageDetectionAggregate: 'DamageDetectionAggregate',
    DamageCostEvaluation: 'DamageCostEvaluation',
    DamageCostEvaluationAggregate: 'DamageCostEvaluationAggregate',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation',
    Chat: 'Chat',
    ChatParticipant: 'ChatParticipant',
    Negotiation: 'Negotiation',
    AssetSubscriptionPlan: 'AssetSubscriptionPlan',
    AssetSubscription: 'AssetSubscription',
    TenantConnectionRequest: 'TenantConnectionRequest',
    TenantConnection: 'TenantConnection',
    TenantRequest: 'TenantRequest',
    DataGrant: 'DataGrant',
    Pool: 'Pool',
    Department: 'Department',
    Location: 'Location',
    Spot: 'Spot'
} as const;

export type TagTargetType = typeof TagTargetType[keyof typeof TagTargetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaxType = {
    None: 'None',
    Custom: 'Custom',
    Vat: 'Vat'
} as const;

export type TaxType = typeof TaxType[keyof typeof TaxType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaxValueType = {
    None: 'None',
    Percent: 'Percent',
    Value: 'Value'
} as const;

export type TaxValueType = typeof TaxValueType[keyof typeof TaxValueType];


/**
 * 
 * @export
 * @interface TenantChangedDto
 */
export interface TenantChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {TenantDto}
     * @memberof TenantChangedDto
     */
    'entity': TenantDto;
    /**
     * 
     * @type {string}
     * @memberof TenantChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionChangedDto
 */
export interface TenantConnectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantConnectionChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantConnectionChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {TenantConnectionDto}
     * @memberof TenantConnectionChangedDto
     */
    'entity': TenantConnectionDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionDto
 */
export interface TenantConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'requestId'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionDto
     */
    'connectedTenant1'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionDto
     */
    'connectedTenant2'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantConnectionDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {TenantConnectionStatus}
     * @memberof TenantConnectionDto
     */
    'status'?: TenantConnectionStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'statusUpdatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'activeAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'endedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof TenantConnectionDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
}


/**
 * 
 * @export
 * @interface TenantConnectionReferenceDto
 */
export interface TenantConnectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @interface TenantConnectionRequestChangedDto
 */
export interface TenantConnectionRequestChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantConnectionRequestChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantConnectionRequestChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {TenantConnectionRequestDto}
     * @memberof TenantConnectionRequestChangedDto
     */
    'entity': TenantConnectionRequestDto;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface TenantConnectionRequestDto
 */
export interface TenantConnectionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'senderTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'receiverTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantConnectionRequestDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {TenantConnectionRequestStatus}
     * @memberof TenantConnectionRequestDto
     */
    'status'?: TenantConnectionRequestStatus;
    /**
     * 
     * @type {TenantConnectionRequestResponseType}
     * @memberof TenantConnectionRequestDto
     */
    'responseType'?: TenantConnectionRequestResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'respondedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'statusUpdatedAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'pendingAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'acceptedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'declinedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'notRespondedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'revokedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof TenantConnectionRequestDto
     */
    'tenantIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {boolean}
     * @memberof TenantConnectionRequestDto
     */
    'isResponded'?: boolean;
}


/**
 * 
 * @export
 * @interface TenantConnectionRequestReferenceDto
 */
export interface TenantConnectionRequestReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantConnectionRequestReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionRequestResponseType = {
    None: 'None',
    Accept: 'Accept',
    Decline: 'Decline'
} as const;

export type TenantConnectionRequestResponseType = typeof TenantConnectionRequestResponseType[keyof typeof TenantConnectionRequestResponseType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionRequestStatus = {
    None: 'None',
    Pending: 'Pending',
    Accepted: 'Accepted',
    Declined: 'Declined',
    NotResponded: 'NotResponded',
    Revoked: 'Revoked'
} as const;

export type TenantConnectionRequestStatus = typeof TenantConnectionRequestStatus[keyof typeof TenantConnectionRequestStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConnectionStatus = {
    None: 'None',
    Active: 'Active',
    Ended: 'Ended'
} as const;

export type TenantConnectionStatus = typeof TenantConnectionStatus[keyof typeof TenantConnectionStatus];


/**
 * 
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDto
     */
    'name'?: string;
    /**
     * 
     * @type {GeneralBrandingDto}
     * @memberof TenantDto
     */
    'branding'?: GeneralBrandingDto;
}
/**
 * 
 * @export
 * @interface TenantReference
 */
export interface TenantReference {
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReference
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenantReferenceDto
 */
export interface TenantReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantReferenceDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface TenantRequestAppliedResultDto
 */
export interface TenantRequestAppliedResultDto {
    /**
     * When result was applied to sender tenant.
     * @type {string}
     * @memberof TenantRequestAppliedResultDto
     */
    'appliedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestAppliedResultDto
     */
    'appliedBy'?: string | null;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof TenantRequestAppliedResultDto
     */
    'createdEntityTags'?: GeneralAttachedTagsDto;
}
/**
 * 
 * @export
 * @interface TenantRequestChangedDto
 */
export interface TenantRequestChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof TenantRequestChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof TenantRequestChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {TenantRequestDto}
     * @memberof TenantRequestChangedDto
     */
    'entity': TenantRequestDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface TenantRequestContentDto
 */
export interface TenantRequestContentDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestContentDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {object}
     * @memberof TenantRequestContentDto
     */
    'arbitrary'?: object;
    /**
     * 
     * @type {TenantRequestOperationContentDto}
     * @memberof TenantRequestContentDto
     */
    'operation'?: TenantRequestOperationContentDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestContentDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof TenantRequestContentDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
}


/**
 * 
 * @export
 * @interface TenantRequestDataGrantSettingsDto
 */
export interface TenantRequestDataGrantSettingsDto {
    /**
     * 
     * @type {Array<DataGrantPermission>}
     * @memberof TenantRequestDataGrantSettingsDto
     */
    'permissions'?: Array<DataGrantPermission>;
}
/**
 * 
 * @export
 * @interface TenantRequestDto
 */
export interface TenantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantRequestDto
     */
    'senderTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {TenantReferenceDto}
     * @memberof TenantRequestDto
     */
    'receiverTenant'?: TenantReferenceDto;
    /**
     * 
     * @type {ChatReferenceDto}
     * @memberof TenantRequestDto
     */
    'chat'?: ChatReferenceDto;
    /**
     * 
     * @type {GeneralStageHistoryDtoOfTenantRequestStage}
     * @memberof TenantRequestDto
     */
    'stageHistory'?: GeneralStageHistoryDtoOfTenantRequestStage;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {TenantRequestContentDto}
     * @memberof TenantRequestDto
     */
    'content'?: TenantRequestContentDto;
    /**
     * 
     * @type {TenantRequestDataGrantSettingsDto}
     * @memberof TenantRequestDto
     */
    'dataGrantSettings'?: TenantRequestDataGrantSettingsDto;
    /**
     * 
     * @type {TenantRequestResponseType}
     * @memberof TenantRequestDto
     */
    'responseType'?: TenantRequestResponseType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'respondedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'responseReason'?: string | null;
    /**
     * 
     * @type {TenantRequestResultDto}
     * @memberof TenantRequestDto
     */
    'result'?: TenantRequestResultDto;
    /**
     * 
     * @type {TenantRequestAppliedResultDto}
     * @memberof TenantRequestDto
     */
    'appliedResult'?: TenantRequestAppliedResultDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof TenantRequestDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof TenantRequestDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {AssessmentFlowReferenceDto}
     * @memberof TenantRequestDto
     */
    'assessmentFlow'?: AssessmentFlowReferenceDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TenantRequestDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestDto
     */
    'isResponded'?: boolean;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationContentDto
 */
export interface TenantRequestOperationContentDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationContentDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'visualInspection'?: VisualInspectionContentDto;
    /**
     * 
     * @type {DamageDetectionContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'damageDetection'?: DamageDetectionContentDto;
    /**
     * 
     * @type {DamageCostEvaluationContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationContentDto;
    /**
     * 
     * @type {AccessoryCheckContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'accessoryCheck'?: AccessoryCheckContentDto;
    /**
     * 
     * @type {RepairOperationContentDto}
     * @memberof TenantRequestOperationContentDto
     */
    'repairOperation'?: RepairOperationContentDto;
}


/**
 * 
 * @export
 * @interface TenantRequestOperationResultDto
 */
export interface TenantRequestOperationResultDto {
    /**
     * 
     * @type {TenantRequestOperationType}
     * @memberof TenantRequestOperationResultDto
     */
    'operationType'?: TenantRequestOperationType;
    /**
     * 
     * @type {VisualInspectionResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'visualInspection'?: VisualInspectionResultDto;
    /**
     * 
     * @type {DamageDetectionResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'damageDetection'?: DamageDetectionResultDto;
    /**
     * 
     * @type {DamageCostEvaluationResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'damageCostEvaluation'?: DamageCostEvaluationResultDto;
    /**
     * 
     * @type {AccessoryCheckResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'accessoryCheck'?: AccessoryCheckResultDto;
    /**
     * 
     * @type {RepairOperationResultDto}
     * @memberof TenantRequestOperationResultDto
     */
    'repairOperation'?: RepairOperationResultDto;
}


/**
 * Describes types of operations (from business logic perspective) that can be performed by tenants.
 * @export
 * @enum {string}
 */

export const TenantRequestOperationType = {
    None: 'None',
    VisualInspection: 'VisualInspection',
    DamageDetection: 'DamageDetection',
    DamageCostEvaluation: 'DamageCostEvaluation',
    AccessoryCheck: 'AccessoryCheck',
    RepairOperation: 'RepairOperation'
} as const;

export type TenantRequestOperationType = typeof TenantRequestOperationType[keyof typeof TenantRequestOperationType];


/**
 * 
 * @export
 * @interface TenantRequestReferenceDto
 */
export interface TenantRequestReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof TenantRequestReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestReferenceDto
     */
    'globalNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TenantRequestResponseType = {
    None: 'None',
    Accept: 'Accept',
    Decline: 'Decline'
} as const;

export type TenantRequestResponseType = typeof TenantRequestResponseType[keyof typeof TenantRequestResponseType];


/**
 * 
 * @export
 * @interface TenantRequestResultDto
 */
export interface TenantRequestResultDto {
    /**
     * 
     * @type {TenantRequestType}
     * @memberof TenantRequestResultDto
     */
    'type'?: TenantRequestType;
    /**
     * 
     * @type {TenantRequestOperationResultDto}
     * @memberof TenantRequestResultDto
     */
    'operation'?: TenantRequestOperationResultDto;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestResultDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof TenantRequestResultDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
}


/**
 * NB: use Common.Attributes.SortOrderAttribute to specify the order in which stages can be activated.
 * @export
 * @enum {string}
 */

export const TenantRequestStage = {
    None: 'None',
    Draft: 'Draft',
    Pending: 'Pending',
    Declined: 'Declined',
    Accepted: 'Accepted',
    InProgress: 'InProgress',
    Completed: 'Completed',
    Reporting: 'Reporting',
    Closed: 'Closed'
} as const;

export type TenantRequestStage = typeof TenantRequestStage[keyof typeof TenantRequestStage];


/**
 * 
 * @export
 * @enum {string}
 */

export const TenantRequestType = {
    None: 'None',
    Arbitrary: 'Arbitrary',
    Operation: 'Operation'
} as const;

export type TenantRequestType = typeof TenantRequestType[keyof typeof TenantRequestType];


/**
 * 
 * @export
 * @interface TenantRequestsMetaDto
 */
export interface TenantRequestsMetaDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantRequestsMetaDto
     */
    'tenantRequestIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TenantRequestsMetaDto
     */
    'appliedResultOfTenantRequestId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TenantRequestsMetaDto
     */
    'hasTenantRequest'?: boolean;
}
/**
 * 
 * @export
 * @interface TeslaAuthAuthorizationCodeExchangeRequestDto
 */
export interface TeslaAuthAuthorizationCodeExchangeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthAuthorizationCodeExchangeRequestDto
     */
    'scope'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthAuthorizationCodeExchangeRequestDto
     */
    'redirectUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthAuthorizationCodeExchangeRequestDto
     */
    'authorizationCode'?: string;
}
/**
 * 
 * @export
 * @interface TeslaAuthInfoDto
 */
export interface TeslaAuthInfoDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthInfoDto
     */
    'teslaConnectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthInfoDto
     */
    'vehicleId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaAuthInfoDto
     */
    'isAccountConnected'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaAuthInfoDto
     */
    'isAccessTokenExpired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaAuthInfoDto
     */
    'isAuthenticated'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TeslaAuthTokenType = {
    None: 'None',
    Partner: 'Partner',
    ThirdParty: 'ThirdParty',
    OpenSource: 'OpenSource'
} as const;

export type TeslaAuthTokenType = typeof TeslaAuthTokenType[keyof typeof TeslaAuthTokenType];


/**
 * 
 * @export
 * @interface TeslaAuthorizationCodeExchangeResponseDto
 */
export interface TeslaAuthorizationCodeExchangeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'id_token': string;
    /**
     * Set if using the offline_access scope.
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'refresh_token'?: string | null;
    /**
     * Usually it\'s 8 hours.
     * @type {number}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'expires_in': number;
    /**
     * 
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'state': string;
    /**
     * The type of token (e.g., Bearer) - always \"Bearer\" in this case.
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'token_type': string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaAuthorizationCodeExchangeResponseDto
     */
    'expiresAt'?: string;
}
/**
 * 
 * @export
 * @interface TeslaConnectionAuthTokensInfoDto
 */
export interface TeslaConnectionAuthTokensInfoDto {
    /**
     * 
     * @type {TeslaAuthTokenType}
     * @memberof TeslaConnectionAuthTokensInfoDto
     */
    'type'?: TeslaAuthTokenType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaConnectionAuthTokensInfoDto
     */
    'refreshedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaConnectionAuthTokensInfoDto
     */
    'refreshAccessTokenAfter'?: string;
}


/**
 * 
 * @export
 * @interface TeslaConnectionDto
 */
export interface TeslaConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'userEmail'?: string;
    /**
     * 
     * @type {TeslaConnectionAuthTokensInfoDto}
     * @memberof TeslaConnectionDto
     */
    'tokens'?: TeslaConnectionAuthTokensInfoDto;
    /**
     * 
     * @type {TeslaConnectionAuthTokensInfoDto}
     * @memberof TeslaConnectionDto
     */
    'prevTokens'?: TeslaConnectionAuthTokensInfoDto;
    /**
     * 
     * @type {Array<TeslaConnectionVehicleDto>}
     * @memberof TeslaConnectionDto
     */
    'vehicles'?: Array<TeslaConnectionVehicleDto>;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'vehiclesUpdatedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionDto
     */
    'updatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface TeslaConnectionGetPaginatedDto
 */
export interface TeslaConnectionGetPaginatedDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'search'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'includeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'excludeIds'?: Array<string> | null;
    /**
     * 
     * @type {DateRangeDto}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'range'?: DateRangeDto;
    /**
     * 
     * @type {SortDefinitionDto}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'sortDefinition'?: SortDefinitionDto;
    /**
     * 
     * @type {FilterDefinitionDto}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'filterDefinition'?: FilterDefinitionDto;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionGetPaginatedDto
     */
    'vin'?: string | null;
}
/**
 * 
 * @export
 * @interface TeslaConnectionVehicleDto
 */
export interface TeslaConnectionVehicleDto {
    /**
     * Local ID for Tesla Fleet API (id field).
     * @type {number}
     * @memberof TeslaConnectionVehicleDto
     */
    'vehicleRecordId'?: number;
    /**
     * Global vehicle ID (vehicle_id field).
     * @type {number}
     * @memberof TeslaConnectionVehicleDto
     */
    'vehicleId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionVehicleDto
     */
    'vin'?: string;
}
/**
 * 
 * @export
 * @interface TeslaFeatureInfoDto
 */
export interface TeslaFeatureInfoDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaFeatureInfoDto
     */
    'environmentName': string;
    /**
     * 
     * @type {string}
     * @memberof TeslaFeatureInfoDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TeslaFeatureInfoDto
     */
    'userEmail': string;
    /**
     * 
     * @type {string}
     * @memberof TeslaFeatureInfoDto
     */
    'vehicleId': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaFeatureInfoDto
     */
    'isEnabledForEnvironment': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaFeatureInfoDto
     */
    'isEnabledForUser': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaFeatureInfoDto
     */
    'isEnabledForVehicle': boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaFeatureInfoDto
     */
    'isEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface TeslaVehicleCommandResultDto
 */
export interface TeslaVehicleCommandResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleCommandResultDto
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleCommandResultDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface TeslaVehicleDataChargeStateDto
 */
export interface TeslaVehicleDataChargeStateDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'battery_level'?: number;
    /**
     * In miles.
     * @type {number}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'battery_range'?: number;
    /**
     * Values: Disconnected
     * @type {string}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'charging_state'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'timestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'batteryRangeMi'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'batteryRangeKm'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleDataChargeStateDto
     */
    'timestampAsDate'?: string;
}
/**
 * 
 * @export
 * @interface TeslaVehicleDataDriveStateDto
 */
export interface TeslaVehicleDataDriveStateDto {
    /**
     * Timestamp in secs.
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'gps_as_of'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'native_latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'native_longitude'?: number | null;
    /**
     * Values: wgs
     * @type {string}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'native_type'?: string | null;
    /**
     * This property indicates the direction in which the car is currently facing,  measured in degrees (where 0 or 360 is North, 90 is East, 180 is South, and 270 is West).
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'heading'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'power'?: number | null;
    /**
     * Values: P, R, N, D
     * @type {string}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'shift_state'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'speed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'timestamp'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'timestampAsDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataDriveStateDto
     */
    'isEngineOn'?: boolean;
}
/**
 * 
 * @export
 * @interface TeslaVehicleDataDto
 */
export interface TeslaVehicleDataDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDto
     */
    'id'?: number;
    /**
     * The id_s field is a string version of the id field (for cases when JSON parser doesn\'t support large numbers (>32 bit)).
     * @type {string}
     * @memberof TeslaVehicleDataDto
     */
    'id_s'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDto
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDto
     */
    'vehicle_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDataDto
     */
    'vin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDataDto
     */
    'access_type'?: string;
    /**
     * Values: offline, asleep, online, driving, parked
     * @type {string}
     * @memberof TeslaVehicleDataDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataDto
     */
    'api_version'?: number;
    /**
     * 
     * @type {TeslaVehicleDataChargeStateDto}
     * @memberof TeslaVehicleDataDto
     */
    'charge_state'?: TeslaVehicleDataChargeStateDto;
    /**
     * 
     * @type {TeslaVehicleDataDriveStateDto}
     * @memberof TeslaVehicleDataDto
     */
    'drive_state'?: TeslaVehicleDataDriveStateDto;
    /**
     * 
     * @type {TeslaVehicleDataVehicleStateDto}
     * @memberof TeslaVehicleDataDto
     */
    'vehicle_state'?: TeslaVehicleDataVehicleStateDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleDataDto
     */
    'fetchedAt'?: string;
    /**
     * 
     * @type {TeslaVehicleStateCustom}
     * @memberof TeslaVehicleDataDto
     */
    'stateCustom'?: TeslaVehicleStateCustom;
}


/**
 * 
 * @export
 * @interface TeslaVehicleDataVehicleStateDto
 */
export interface TeslaVehicleDataVehicleStateDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'api_version'?: number;
    /**
     * Firmware version.  E.g. 2024.26.7 b7e14964b659
     * @type {string}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'car_version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'locked'?: boolean;
    /**
     * In miles.
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'odometer'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'timestamp'?: number;
    /**
     * Indicates the state of the vehicle\'s four doors.   If 0, the door is closed. If 1, the door is open.
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'df'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'dr'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'pf'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'pr'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'mileageMi'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'mileageKm'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'timestampAsDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isUnlocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isAnyDoorOpen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isAllDoorsClosed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isDriverFrontDoorOpen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isDriverRearDoorOpen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isPassengerFrontDoorOpen'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleDataVehicleStateDto
     */
    'isPassengerRearDoorOpen'?: boolean;
}
/**
 * https://developer.tesla.com/docs/fleet-api/endpoints/vehicle-endpoints#vehicle
 * @export
 * @interface TeslaVehicleDto
 */
export interface TeslaVehicleDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDto
     */
    'id'?: number;
    /**
     * The id_s field is a string version of the id field (for cases when JSON parser doesn\'t support large numbers (>32 bit)).
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'id_s'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDto
     */
    'vehicle_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'vin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'access_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleDto
     */
    'api_version'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleDto
     */
    'fetchedAt'?: string;
    /**
     * 
     * @type {TeslaVehicleStateCustom}
     * @memberof TeslaVehicleDto
     */
    'stateCustom'?: TeslaVehicleStateCustom;
}


/**
 * 
 * @export
 * @interface TeslaVehicleGetPaginatedDto
 */
export interface TeslaVehicleGetPaginatedDto {
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleGetPaginatedDto
     */
    'teslaConnectionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleGetPaginatedDto
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleGetPaginatedDto
     */
    'limit'?: number;
}
/**
 * https://developer.tesla.com/docs/fleet-api/endpoints/vehicle-endpoints#list
 * @export
 * @interface TeslaVehicleListItemDto
 */
export interface TeslaVehicleListItemDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleListItemDto
     */
    'id'?: number;
    /**
     * The id_s field is a string version of the id field (for cases when JSON parser doesn\'t support large numbers (>32 bit)).
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'id_s'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleListItemDto
     */
    'vehicle_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'vin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'access_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'state'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleListItemDto
     */
    'api_version'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleListItemDto
     */
    'fetchedAt'?: string;
}
/**
 * Confirmed typed states computed from string state from Tesla API.
 * @export
 * @enum {string}
 */

export const TeslaVehicleStateCustom = {
    None: 'None',
    Offline: 'Offline',
    Asleep: 'Asleep',
    Online: 'Online'
} as const;

export type TeslaVehicleStateCustom = typeof TeslaVehicleStateCustom[keyof typeof TeslaVehicleStateCustom];


/**
 * https://developer.tesla.com/docs/fleet-api/endpoints/vehicle-endpoints#wake-up
 * @export
 * @interface TeslaVehicleWakeUpResultDto
 */
export interface TeslaVehicleWakeUpResultDto {
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'id'?: number;
    /**
     * The id_s field is a string version of the id field (for cases when JSON parser doesn\'t support large numbers (>32 bit)).
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'id_s'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'vehicle_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'vin'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'access_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'state'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'in_service'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'calendar_enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'api_version'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof TeslaVehicleWakeUpResultDto
     */
    'fetchedAt'?: string;
}
/**
 * 
 * @export
 * @interface TestNullableSwaggerClass
 */
export interface TestNullableSwaggerClass {
    /**
     * 
     * @type {number}
     * @memberof TestNullableSwaggerClass
     */
    'int'?: number;
    /**
     * 
     * @type {number}
     * @memberof TestNullableSwaggerClass
     */
    'intNullable'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestNullableSwaggerClass
     */
    'bool'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestNullableSwaggerClass
     */
    'boolNullable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TestNullableSwaggerClass
     */
    'string'?: string;
    /**
     * 
     * @type {string}
     * @memberof TestNullableSwaggerClass
     */
    'stringNullable'?: string | null;
    /**
     * 
     * @type {VehicleType}
     * @memberof TestNullableSwaggerClass
     */
    'enum'?: VehicleType;
    /**
     * 
     * @type {VehicleType}
     * @memberof TestNullableSwaggerClass
     */
    'enumNullable'?: VehicleType;
    /**
     * 
     * @type {Size}
     * @memberof TestNullableSwaggerClass
     */
    'struct'?: Size;
    /**
     * 
     * @type {Size}
     * @memberof TestNullableSwaggerClass
     */
    'structNullable'?: Size;
    /**
     * 
     * @type {TestSwaggerClass}
     * @memberof TestNullableSwaggerClass
     */
    'class'?: TestSwaggerClass;
    /**
     * 
     * @type {TestSwaggerClass}
     * @memberof TestNullableSwaggerClass
     */
    'classNullable'?: TestSwaggerClass;
}


/**
 * 
 * @export
 * @interface TestSwaggerClass
 */
export interface TestSwaggerClass {
    /**
     * 
     * @type {string}
     * @memberof TestSwaggerClass
     */
    'string'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ThumbnailSizeType = {
    None: 'None',
    ExtraSmall: 'ExtraSmall',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    ExtraLarge: 'ExtraLarge'
} as const;

export type ThumbnailSizeType = typeof ThumbnailSizeType[keyof typeof ThumbnailSizeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnitOfTime = {
    None: 'None',
    Year: 'Year',
    Month: 'Month',
    Week: 'Week',
    Day: 'Day',
    Hour: 'Hour',
    Minute: 'Minute',
    Second: 'Second',
    Millisecond: 'Millisecond'
} as const;

export type UnitOfTime = typeof UnitOfTime[keyof typeof UnitOfTime];


/**
 * 
 * @export
 * @interface UnsubscribeFromChatActivityUpdatesDto
 */
export interface UnsubscribeFromChatActivityUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFromChatActivityUpdatesDto
     */
    'chatId'?: string;
}
/**
 * 
 * @export
 * @interface UnsubscribeFromDataUpdatesDto
 */
export interface UnsubscribeFromDataUpdatesDto {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'channelName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'channelNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnsubscribeFromDataUpdatesDto
     */
    'allChannelNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UserActivateDto
 */
export interface UserActivateDto {
    /**
     * 
     * @type {string}
     * @memberof UserActivateDto
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAddedToChatDto
 */
export interface UserAddedToChatDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAddedToChatDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddedToChatDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAddedToChatDto
     */
    'chatId'?: string;
    /**
     * 
     * @type {ChatParticipantDto}
     * @memberof UserAddedToChatDto
     */
    'participant'?: ChatParticipantDto;
}
/**
 * 
 * @export
 * @interface UserChangedDto
 */
export interface UserChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof UserChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof UserChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {UserDto}
     * @memberof UserChangedDto
     */
    'entity': UserDto;
    /**
     * 
     * @type {string}
     * @memberof UserChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface UserDepartmentDto
 */
export interface UserDepartmentDto {
    /**
     * 
     * @type {string}
     * @memberof UserDepartmentDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDepartmentDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {UserStatus}
     * @memberof UserDto
     */
    'status'?: UserStatus;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof UserDto
     */
    'personName'?: GeneralPersonNameDto;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {UserSettingsDto}
     * @memberof UserDto
     */
    'settings'?: UserSettingsDto;
    /**
     * 
     * @type {Array<UserDepartmentDto>}
     * @memberof UserDto
     */
    'departments'?: Array<UserDepartmentDto>;
    /**
     * 
     * @type {GeneralAvatarDto}
     * @memberof UserDto
     */
    'avatar'?: GeneralAvatarDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<UserRoleDto>;
    /**
     * 
     * @type {UserSuspensionInfoDto}
     * @memberof UserDto
     */
    'suspension'?: UserSuspensionInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isPersonalInfoComplete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isCustomerUser'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserMembershipType = {
    None: 'None',
    Account: 'Account',
    NoAccount: 'NoAccount',
    Anonymous: 'Anonymous'
} as const;

export type UserMembershipType = typeof UserMembershipType[keyof typeof UserMembershipType];


/**
 * 
 * @export
 * @interface UserReferenceDto
 */
export interface UserReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'departmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserReferenceDto
     */
    'email'?: string;
    /**
     * 
     * @type {GeneralPersonNameDto}
     * @memberof UserReferenceDto
     */
    'personName'?: GeneralPersonNameDto;
}
/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {RoleReferenceDto}
     * @memberof UserRoleDto
     */
    'role'?: RoleReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'assignedBy'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserRoleDto
     */
    'assignedAt'?: string;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'tzId'?: string;
    /**
     * 
     * @type {GeneralCultureDto}
     * @memberof UserSettingsDto
     */
    'culture'?: GeneralCultureDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatus = {
    None: 'None',
    InviteIncomplete: 'InviteIncomplete',
    InfoIncomplete: 'InfoIncomplete',
    Active: 'Active',
    Suspended: 'Suspended'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * 
 * @export
 * @interface UserSuspensionInfoDto
 */
export interface UserSuspensionInfoDto {
    /**
     * 
     * @type {UserStatus}
     * @memberof UserSuspensionInfoDto
     */
    'statusBeforeSuspension'?: UserStatus;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendedAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'suspendReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSuspensionInfoDto
     */
    'unsuspendReason'?: string | null;
}


/**
 * 
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; };
}
/**
 * Describes basic vehicle areas.
 * @export
 * @enum {string}
 */

export const VehicleArea = {
    None: 'None',
    Interior: 'Interior',
    Exterior: 'Exterior',
    Internal: 'Internal'
} as const;

export type VehicleArea = typeof VehicleArea[keyof typeof VehicleArea];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleBodyType = {
    None: 'None',
    Sedan: 'Sedan',
    Hatchback: 'Hatchback',
    Wagon: 'Wagon',
    Van: 'Van',
    Minivan: 'Minivan',
    Cuv: 'CUV',
    Suv: 'SUV',
    Coupe: 'Coupe',
    Convertible: 'Convertible',
    Roadster: 'Roadster',
    Pickup: 'Pickup',
    ChassisCab: 'ChassisCab',
    PlatformCab: 'PlatformCab',
    PassengerVan: 'PassengerVan',
    DoubleChassisCab: 'DoubleChassisCab',
    DoubleCabPickUp: 'DoubleCabPickUp',
    Microcar: 'Microcar',
    MiniMpv: 'MiniMPV',
    Targa: 'Targa',
    Other: 'Other',
    PlatforbCab: 'PlatforbCab'
} as const;

export type VehicleBodyType = typeof VehicleBodyType[keyof typeof VehicleBodyType];


/**
 * 
 * @export
 * @interface VehicleBodyTypeChangedDto
 */
export interface VehicleBodyTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleBodyTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleBodyTypeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleBodyTypeDto}
     * @memberof VehicleBodyTypeChangedDto
     */
    'entity': VehicleBodyTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeDto
 */
export interface VehicleBodyTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleBodyTypeDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleBodyTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleBodyTypeReferenceDto
 */
export interface VehicleBodyTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {string}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleBodyTypeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleChangedDto
 */
export interface VehicleChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleDto}
     * @memberof VehicleChangedDto
     */
    'entity': VehicleDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleDamageChangedDto
 */
export interface VehicleDamageChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDamageChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleDamageChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleDamageChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleDamageDto}
     * @memberof VehicleDamageChangedDto
     */
    'entity': VehicleDamageDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleDamageDeletedEventDataDto
 */
export interface VehicleDamageDeletedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDeletedEventDataDto
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDeletedEventDataDto
     */
    'vehicleDamageId': string;
}
/**
 * 
 * @export
 * @interface VehicleDamageDetectedEventDataDto
 */
export interface VehicleDamageDetectedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'vehicleDamageId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDetectedEventDataDto
     */
    'damageDetectionId': string;
}
/**
 * 
 * @export
 * @interface VehicleDamageDto
 */
export interface VehicleDamageDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof VehicleDamageDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof VehicleDamageDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDamageDto
     */
    'visualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDamageDto
     */
    'oldVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {GeneralEntitySourceDto}
     * @memberof VehicleDamageDto
     */
    'source'?: GeneralEntitySourceDto;
    /**
     * 
     * @type {DamageDetectionReferenceDto}
     * @memberof VehicleDamageDto
     */
    'damageDetection'?: DamageDetectionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'damageDetectionItemId'?: string;
    /**
     * 
     * @type {VehicleDamageState}
     * @memberof VehicleDamageDto
     */
    'state'?: VehicleDamageState;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof VehicleDamageDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VehicleDamageDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof VehicleDamageDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof VehicleDamageDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleDamageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleDamageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageDto
     */
    'oldPoint'?: GeneralSvgPointDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDamageDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'notes'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageDto
     */
    'repairedAt'?: string | null;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof VehicleDamageDto
     */
    'repairedBy'?: GeneralByWhoDto;
    /**
     * 
     * @type {RepairOperationReferenceDto}
     * @memberof VehicleDamageDto
     */
    'repairOperation'?: RepairOperationReferenceDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleDamageDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleDamageDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
}


/**
 * 
 * @export
 * @interface VehicleDamageFullReferenceDto
 */
export interface VehicleDamageFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'visualModelId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDamageFullReferenceDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {VehiclePartTypeReferenceDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'partType'?: VehiclePartTypeReferenceDto;
    /**
     * 
     * @type {DamageTypeReferenceDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'damageType'?: DamageTypeReferenceDto;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleDamageFullReferenceDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleDamageFullReferenceDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {GeneralSvgPointDto}
     * @memberof VehicleDamageFullReferenceDto
     */
    'point'?: GeneralSvgPointDto;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDamageFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VehicleDamageReferenceDto
 */
export interface VehicleDamageReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VehicleDamageRepairedEventDataDto
 */
export interface VehicleDamageRepairedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'vehicleDamageId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDamageRepairedEventDataDto
     */
    'repairOperationId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleDamageState = {
    None: 'None',
    Actual: 'Actual',
    Repaired: 'Repaired'
} as const;

export type VehicleDamageState = typeof VehicleDamageState[keyof typeof VehicleDamageState];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleDrivetrainType = {
    None: 'None',
    Fwd: 'FWD',
    Rwd: 'RWD',
    Awd: 'AWD',
    FourWd: 'FourWD'
} as const;

export type VehicleDrivetrainType = typeof VehicleDrivetrainType[keyof typeof VehicleDrivetrainType];


/**
 * 
 * @export
 * @interface VehicleDto
 */
export interface VehicleDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof VehicleDto
     */
    'lastContract'?: ContractFullReferenceDto;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof VehicleDto
     */
    'spec'?: VehicleSpecDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof VehicleDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleDto
     */
    'defaultVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleDto
     */
    'plateNo'?: GeneralPlateNoDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {object}
     * @memberof VehicleDto
     */
    'car'?: object;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'totalDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'actualDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleDto
     */
    'repairedDamagesCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastVisualInspectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastAccessoryCheckAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'lastMileageUpdatedAt'?: string | null;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof VehicleDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof VehicleDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof VehicleDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VehicleDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleDto
     */
    'departmentIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleDto
     */
    'locationIdsMap'?: { [key: string]: boolean; };
}
/**
 * 
 * @export
 * @interface VehicleFuelConsumptionDto
 */
export interface VehicleFuelConsumptionDto {
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPer100Km'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPerKm'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'lPer10Km'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelConsumptionDto
     */
    'kmPerL'?: number;
    /**
     * 
     * @type {VehicleFuelConsumptionImperial}
     * @memberof VehicleFuelConsumptionDto
     */
    'uk'?: VehicleFuelConsumptionImperial;
    /**
     * 
     * @type {VehicleFuelConsumptionImperial}
     * @memberof VehicleFuelConsumptionDto
     */
    'us'?: VehicleFuelConsumptionImperial;
}
/**
 * For UK and US in imperial units.  NB: UK gallon differs from US gallon.
 * @export
 * @interface VehicleFuelConsumptionImperial
 */
export interface VehicleFuelConsumptionImperial {
    /**
     * 
     * @type {MeasurementSystemType}
     * @memberof VehicleFuelConsumptionImperial
     */
    'type'?: MeasurementSystemType;
    /**
     * Aka MPG (Miles per gallon).
     * @type {number}
     * @memberof VehicleFuelConsumptionImperial
     */
    'miPerGal'?: number;
    /**
     * The opposite of MGP.
     * @type {number}
     * @memberof VehicleFuelConsumptionImperial
     */
    'galPerMi'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleFuelType = {
    None: 'None',
    Gasoline: 'Gasoline',
    Diesel: 'Diesel',
    Biodiesel: 'Biodiesel',
    Cng: 'CNG',
    Lpg: 'LPG',
    Alcohol: 'Alcohol',
    Ethanol: 'Ethanol',
    Methanol: 'Methanol',
    E85: 'E85',
    Mhev: 'MHEV',
    Hev: 'HEV',
    Phev: 'PHEV',
    Bev: 'BEV',
    Reev: 'REEV',
    Fcev: 'FCEV',
    Hydrogen: 'Hydrogen',
    Other: 'Other'
} as const;

export type VehicleFuelType = typeof VehicleFuelType[keyof typeof VehicleFuelType];


/**
 * 
 * @export
 * @interface VehicleFuelTypeChangedDto
 */
export interface VehicleFuelTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleFuelTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleFuelTypeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleFuelTypeDto}
     * @memberof VehicleFuelTypeChangedDto
     */
    'entity': VehicleFuelTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeDto
 */
export interface VehicleFuelTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleFuelTypeDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleFuelTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleFuelTypeReferenceDto
 */
export interface VehicleFuelTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFuelTypeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleFullReferenceDto
 */
export interface VehicleFullReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'ownerTenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {ContractFullReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'lastContract'?: ContractFullReferenceDto;
    /**
     * 
     * @type {VehicleSpecDto}
     * @memberof VehicleFullReferenceDto
     */
    'spec'?: VehicleSpecDto;
    /**
     * 
     * @type {DepartmentReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'department'?: DepartmentReferenceDto;
    /**
     * 
     * @type {LocationReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'location'?: LocationReferenceDto;
    /**
     * 
     * @type {Array<AccessoryReferenceDto>}
     * @memberof VehicleFullReferenceDto
     */
    'accessories'?: Array<AccessoryReferenceDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehicleFullReferenceDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {VehicleVisualModelReferenceDto}
     * @memberof VehicleFullReferenceDto
     */
    'defaultVisualModel'?: VehicleVisualModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleFullReferenceDto
     */
    'plateNo'?: GeneralPlateNoDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'mileage'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof VehicleFullReferenceDto
     */
    'car'?: object;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'actualDamagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleFullReferenceDto
     */
    'repairedDamagesCount'?: number;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastDamageDetectionAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastAccessoryCheckAt'?: string | null;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'lastMileageUpdatedAt'?: string | null;
    /**
     * 
     * @type {PoolsMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'poolsMeta'?: PoolsMetaDto;
    /**
     * 
     * @type {AssetMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'assetMeta'?: AssetMetaDto;
    /**
     * 
     * @type {EntityImportMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'importMeta'?: EntityImportMetaDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleFullReferenceDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VehicleFullReferenceDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleFullReferenceDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleFullReferenceDto
     */
    'vehicleSize'?: VehicleSize;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleGearboxType = {
    None: 'None',
    Manual: 'Manual',
    Automatic: 'Automatic',
    Amt: 'AMT',
    DualClutch: 'DualClutch',
    TorqueConverter: 'TorqueConverter',
    Cvt: 'CVT'
} as const;

export type VehicleGearboxType = typeof VehicleGearboxType[keyof typeof VehicleGearboxType];


/**
 * 
 * @export
 * @interface VehicleGenerationChangedDto
 */
export interface VehicleGenerationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleGenerationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleGenerationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleGenerationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleGenerationDto}
     * @memberof VehicleGenerationChangedDto
     */
    'entity': VehicleGenerationDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleGenerationDto
 */
export interface VehicleGenerationDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleGenerationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleGenerationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationDto
     */
    'startYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleGenerationDto
     */
    'endYear'?: number | null;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleGenerationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleGenerationDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleGenerationDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleGenerationReferenceDto
 */
export interface VehicleGenerationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleGenerationReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleGenerationReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleHistoryItemChangedDto
 */
export interface VehicleHistoryItemChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleHistoryItemChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleHistoryItemChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleHistoryItemDto}
     * @memberof VehicleHistoryItemChangedDto
     */
    'entity': VehicleHistoryItemDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleHistoryItemDto
 */
export interface VehicleHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'tenantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'vehicleId'?: string;
    /**
     * 
     * @type {GeneralByWhoDto}
     * @memberof VehicleHistoryItemDto
     */
    'who'?: GeneralByWhoDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleHistoryItemDto
     */
    'date'?: string;
    /**
     * 
     * @type {VehicleHistoryItemType}
     * @memberof VehicleHistoryItemDto
     */
    'type'?: VehicleHistoryItemType;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VehicleHistoryItemDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * 
     * @type {MileageChangedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'mileageChanged'?: MileageChangedDataDto;
    /**
     * 
     * @type {DamageDetectedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'damageDetected'?: DamageDetectedDataDto;
    /**
     * 
     * @type {DamageRepairedDataDto}
     * @memberof VehicleHistoryItemDto
     */
    'damageRepaired'?: DamageRepairedDataDto;
    /**
     * 
     * @type {DataGrantsMetaBriefDto}
     * @memberof VehicleHistoryItemDto
     */
    'grantsMeta'?: DataGrantsMetaBriefDto;
}


/**
 * 
 * @export
 * @interface VehicleHistoryItemReferenceDto
 */
export interface VehicleHistoryItemReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'vehicleId'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'date'?: string;
    /**
     * 
     * @type {VehicleHistoryItemType}
     * @memberof VehicleHistoryItemReferenceDto
     */
    'type'?: VehicleHistoryItemType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleHistoryItemType = {
    None: 'None',
    MileageChanged: 'MileageChanged',
    DamageDetected: 'DamageDetected',
    DamageRepaired: 'DamageRepaired',
    DamageDeleted: 'DamageDeleted'
} as const;

export type VehicleHistoryItemType = typeof VehicleHistoryItemType[keyof typeof VehicleHistoryItemType];


/**
 * 
 * @export
 * @interface VehicleMakeChangedDto
 */
export interface VehicleMakeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleMakeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleMakeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleMakeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleMakeDto}
     * @memberof VehicleMakeChangedDto
     */
    'entity': VehicleMakeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleMakeDto
 */
export interface VehicleMakeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleMakeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleMakeReferenceDto
 */
export interface VehicleMakeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleMakeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMakeReferenceDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleMakeReferenceDto
     */
    'isBuiltIn'?: boolean;
}


/**
 * 
 * @export
 * @interface VehicleMileageChangedEventDataDto
 */
export interface VehicleMileageChangedEventDataDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'vehicleId': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'visualInspectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'damageDetectionId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'oldMileage': number;
    /**
     * 
     * @type {number}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'newMileage': number;
    /**
     * 
     * @type {string}
     * @memberof VehicleMileageChangedEventDataDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface VehicleModelChangedDto
 */
export interface VehicleModelChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModelChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleModelChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleModelChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleModelDto}
     * @memberof VehicleModelChangedDto
     */
    'entity': VehicleModelDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleModelDto
 */
export interface VehicleModelDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModelDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleModelDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModelDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleModelDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleModelReferenceDto
 */
export interface VehicleModelReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModelReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModelReferenceDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VehicleModelReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModelReferenceDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModelReferenceDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModelReferenceDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleModificationChangedDto
 */
export interface VehicleModificationChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleModificationChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleModificationChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleModificationChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleModificationDto}
     * @memberof VehicleModificationChangedDto
     */
    'entity': VehicleModificationDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleModificationDto
 */
export interface VehicleModificationDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleModificationDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleModificationDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationDto
     */
    'name'?: string;
    /**
     * 
     * @type {VehicleFuelTypeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'fuelType'?: VehicleFuelTypeReferenceDto;
    /**
     * 
     * @type {VehicleBodyTypeReferenceDto}
     * @memberof VehicleModificationDto
     */
    'bodyType'?: VehicleBodyTypeReferenceDto;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleModificationDto
     */
    'vehicleSize'?: VehicleSize;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleModificationDto
     */
    'vehicleType'?: VehicleType;
}


/**
 * 
 * @export
 * @interface VehicleModificationReferenceDto
 */
export interface VehicleModificationReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleModificationReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleModificationReferenceDto
     */
    'isBuiltIn'?: boolean;
}
/**
 * Describes general/basic categories of vehicle parts.   Vehicle part should belong to one of these categories for correct mapping and suggestions.   For instance, when mapping parts to damages and repair methods.
 * @export
 * @enum {string}
 */

export const VehiclePartCategory = {
    None: 'None',
    Body: 'Body',
    Wheels: 'Wheels',
    Glass: 'Glass',
    Lighting: 'Lighting',
    ExteriorAccessories: 'ExteriorAccessories',
    Seats: 'Seats',
    AirVentilation: 'AirVentilation',
    Dashboard: 'Dashboard',
    Upholstery: 'Upholstery',
    SteeringWheel: 'SteeringWheel',
    InteriorLighting: 'InteriorLighting',
    InteriorControls: 'InteriorControls',
    AudioSystem: 'AudioSystem',
    InteriorAccessories: 'InteriorAccessories'
} as const;

export type VehiclePartCategory = typeof VehiclePartCategory[keyof typeof VehiclePartCategory];


/**
 * Describes the final vehicle part.  It\'s not an identifier as multiple parts may have the same descriptor.
 * @export
 * @enum {string}
 */

export const VehiclePartDescriptor = {
    None: 'None',
    LicencePlateFront: 'LicencePlate_Front',
    LicencePlateRear: 'LicencePlate_Rear',
    LicencePlateLightFront: 'LicencePlateLight_Front',
    LicencePlateLightRear: 'LicencePlateLight_Rear',
    BumperFront: 'Bumper_Front',
    BumperRear: 'Bumper_Rear',
    HeadlightLeft: 'Headlight_Left',
    HeadlightRight: 'Headlight_Right',
    TailLightLeft: 'TailLight_Left',
    TailLightRight: 'TailLight_Right',
    FogLightFrontLeft: 'FogLight_Front_Left',
    FogLightFrontRight: 'FogLight_Front_Right',
    FogLightRearLeft: 'FogLight_Rear_Left',
    FogLightRearRight: 'FogLight_Rear_Right',
    TurnSignalFrontLeft: 'TurnSignal_Front_Left',
    TurnSignalFrontRight: 'TurnSignal_Front_Right',
    TurnSignalRearLeft: 'TurnSignal_Rear_Left',
    TurnSignalRearRight: 'TurnSignal_Rear_Right',
    TurnSignalSideLeft: 'TurnSignal_Side_Left',
    TurnSignalSideRight: 'TurnSignal_Side_Right',
    FenderFront: 'Fender_Front',
    FenderRear: 'Fender_Rear',
    FenderFrontLeft: 'Fender_Front_Left',
    FenderFrontRight: 'Fender_Front_Right',
    FenderRearLeft: 'Fender_Rear_Left',
    FenderRearRight: 'Fender_Rear_Right',
    FenderLinerFront: 'FenderLiner_Front',
    FenderLinerRear: 'FenderLiner_Rear',
    FenderLinerFrontLeft: 'FenderLiner_Front_Left',
    FenderLinerFrontRight: 'FenderLiner_Front_Right',
    FenderLinerRearLeft: 'FenderLiner_Rear_Left',
    FenderLinerRearRight: 'FenderLiner_Rear_Right',
    SideMirrorLeft: 'SideMirror_Left',
    SideMirrorRight: 'SideMirror_Right',
    DoorFront: 'Door_Front',
    DoorRear: 'Door_Rear',
    DoorFrontLeft: 'Door_Front_Left',
    DoorFrontRight: 'Door_Front_Right',
    DoorRearLeft: 'Door_Rear_Left',
    DoorRearRight: 'Door_Rear_Right',
    DoorHandleFront: 'DoorHandle_Front',
    DoorHandleRear: 'DoorHandle_Rear',
    DoorHandleFrontLeft: 'DoorHandle_Front_Left',
    DoorHandleFrontRight: 'DoorHandle_Front_Right',
    DoorHandleRearLeft: 'DoorHandle_Rear_Left',
    DoorHandleRearRight: 'DoorHandle_Rear_Right',
    DoorGlassFront: 'DoorGlass_Front',
    DoorGlassRear: 'DoorGlass_Rear',
    DoorGlassFrontLeft: 'DoorGlass_Front_Left',
    DoorGlassFrontRight: 'DoorGlass_Front_Right',
    DoorGlassRearLeft: 'DoorGlass_Rear_Left',
    DoorGlassRearRight: 'DoorGlass_Rear_Right',
    QuarterGlassFront: 'QuarterGlass_Front',
    QuarterGlassRear: 'QuarterGlass_Rear',
    QuarterGlassFrontLeft: 'QuarterGlass_Front_Left',
    QuarterGlassFrontRight: 'QuarterGlass_Front_Right',
    QuarterGlassRearLeft: 'QuarterGlass_Rear_Left',
    QuarterGlassRearRight: 'QuarterGlass_Rear_Right',
    DoorLockFront: 'DoorLock_Front',
    DoorLockRear: 'DoorLock_Rear',
    DoorLockFrontLeft: 'DoorLock_Front_Left',
    DoorLockFrontRight: 'DoorLock_Front_Right',
    DoorLockRearLeft: 'DoorLock_Rear_Left',
    DoorLockRearRight: 'DoorLock_Rear_Right',
    SeatFrontLeft: 'Seat_Front_Left',
    SeatFrontRight: 'Seat_Front_Right',
    SeatRearLeft: 'Seat_Rear_Left',
    SeatRearRight: 'Seat_Rear_Right',
    SeatRearMiddle: 'Seat_Rear_Middle',
    SeatThirdRowLeft: 'Seat_ThirdRow_Left',
    SeatThirdRowMiddle: 'Seat_ThirdRow_Middle',
    SeatThirdRowRight: 'Seat_ThirdRow_Right',
    HeadrestFrontLeft: 'Headrest_Front_Left',
    HeadrestFrontRight: 'Headrest_Front_Right',
    HeadrestRearLeft: 'Headrest_Rear_Left',
    HeadrestRearRight: 'Headrest_Rear_Right',
    HeadrestRearMiddle: 'Headrest_Rear_Middle',
    SeatAdjustmentControlsFrontLeft: 'SeatAdjustmentControls_Front_Left',
    SeatAdjustmentControlsFrontRight: 'SeatAdjustmentControls_Front_Right',
    SeatFoldingMechanismRear: 'SeatFoldingMechanism_Rear',
    SeatBeltFrontLeft: 'SeatBelt_Front_Left',
    SeatBeltFrontRight: 'SeatBelt_Front_Right',
    SeatBeltRearLeft: 'SeatBelt_Rear_Left',
    SeatBeltRearRight: 'SeatBelt_Rear_Right',
    SeatBeltRearMiddle: 'SeatBelt_Rear_Middle',
    SeatBeltBuckleFrontLeft: 'SeatBeltBuckle_Front_Left',
    SeatBeltBuckleFrontRight: 'SeatBeltBuckle_Front_Right',
    SeatBeltBuckleRearLeft: 'SeatBeltBuckle_Rear_Left',
    SeatBeltBuckleRearRight: 'SeatBeltBuckle_Rear_Right',
    SeatBeltBuckleRearMiddle: 'SeatBeltBuckle_Rear_Middle',
    SeatBeltBuckleThirdRowLeft: 'SeatBeltBuckle_ThirdRow_Left',
    SeatBeltBuckleThirdRowMiddle: 'SeatBeltBuckle_ThirdRow_Middle',
    SeatBeltBuckleThirdRowRight: 'SeatBeltBuckle_ThirdRow_Right',
    FloorMatFrontLeft: 'FloorMat_Front_Left',
    FloorMatFrontRight: 'FloorMat_Front_Right',
    FloorMatRearLeft: 'FloorMat_Rear_Left',
    FloorMatRearRight: 'FloorMat_Rear_Right',
    FloorMatRearMiddle: 'FloorMat_Rear_Middle',
    FloorMatTrunk: 'FloorMat_Trunk',
    MatCoverTrunk: 'MatCover_Trunk',
    DoorPanelFrontLeft: 'DoorPanel_Front_Left',
    DoorPanelFrontRight: 'DoorPanel_Front_Right',
    DoorPanelRearLeft: 'DoorPanel_Rear_Left',
    DoorPanelRearRight: 'DoorPanel_Rear_Right',
    DoorSwitchPanelFrontLeft: 'DoorSwitchPanel_Front_Left',
    DoorSwitchPanelFrontRight: 'DoorSwitchPanel_Front_Right',
    DoorSwitchPanelRearLeft: 'DoorSwitchPanel_Rear_Left',
    DoorSwitchPanelRearRight: 'DoorSwitchPanel_Rear_Right',
    AirVentilationFrontLeft: 'AirVentilation_Front_Left',
    AirVentilationFrontRight: 'AirVentilation_Front_Right',
    AirVentilationFrontMiddle: 'AirVentilation_Front_Middle',
    AirVentilationRearMiddle: 'AirVentilation_Rear_Middle',
    ArmrestFront: 'Armrest_Front',
    ArmrestRear: 'Armrest_Rear',
    SunVisorLeft: 'SunVisor_Left',
    SunVisorRight: 'SunVisor_Right',
    SunVisorLightingLeft: 'SunVisorLighting_Left',
    SunVisorLightingRight: 'SunVisorLighting_Right',
    InteriorDoorHandleLightingFrontLeft: 'InteriorDoorHandleLighting_Front_Left',
    InteriorDoorHandleLightingFrontRight: 'InteriorDoorHandleLighting_Front_Right',
    InteriorDoorHandleLightingRearLeft: 'InteriorDoorHandleLighting_Rear_Left',
    InteriorDoorHandleLightingRearRight: 'InteriorDoorHandleLighting_Rear_Right',
    DoorPanelLightingFrontLeft: 'DoorPanelLighting_Front_Left',
    DoorPanelLightingFrontRight: 'DoorPanelLighting_Front_Right',
    DoorPanelLightingRearLeft: 'DoorPanelLighting_Rear_Left',
    DoorPanelLightingRearRight: 'DoorPanelLighting_Rear_Right',
    SpeakerFront: 'Speaker_Front',
    SpeakerRear: 'Speaker_Rear',
    SpeakerTrunk: 'Speaker_Trunk'
} as const;

export type VehiclePartDescriptor = typeof VehiclePartDescriptor[keyof typeof VehiclePartDescriptor];


/**
 * Further detalization of Domain.Enums.Vehicle.VehiclePartCategory.  Describes general types of vehicle parts.
 * @export
 * @enum {string}
 */

export const VehiclePartType = {
    None: 'None',
    LicencePlate: 'LicencePlate',
    LicencePlateLight: 'LicencePlateLight',
    MarkerLight: 'MarkerLight',
    WindowFrame: 'WindowFrame',
    Bumper: 'Bumper',
    Grille: 'Grille',
    Headlight: 'Headlight',
    TailLight: 'TailLight',
    FogLight: 'FogLight',
    TurnSignal: 'TurnSignal',
    Hood: 'Hood',
    CowlPanel: 'CowlPanel',
    Windshield: 'Windshield',
    WindshieldWiper: 'WindshieldWiper',
    RearWindshield: 'RearWindshield',
    RearWindshieldWiper: 'RearWindshieldWiper',
    Wheel: 'Wheel',
    Tire: 'Tire',
    Rim: 'Rim',
    Hubcap: 'Hubcap',
    Fender: 'Fender',
    FenderLiner: 'FenderLiner',
    SideMirror: 'SideMirror',
    Door: 'Door',
    TrunkLid: 'TrunkLid',
    DoorHandle: 'DoorHandle',
    DoorGlass: 'DoorGlass',
    QuarterGlass: 'QuarterGlass',
    DoorLock: 'DoorLock',
    FuelTankLid: 'FuelTankLid',
    FuelTankCap: 'FuelTankCap',
    SideSkirt: 'SideSkirt',
    RockerPanel: 'RockerPanel',
    RockerPanelCover: 'RockerPanelCover',
    ExhaustPipeTip: 'ExhaustPipeTip',
    Spoiler: 'Spoiler',
    Roof: 'Roof',
    RoofMarkerLight: 'RoofMarkerLight',
    RoofHatch: 'RoofHatch',
    GlassRoofHatch: 'GlassRoofHatch',
    RoofHatchGlass: 'RoofHatchGlass',
    RoofRack: 'RoofRack',
    RoofRail: 'RoofRail',
    Seat: 'Seat',
    Headrest: 'Headrest',
    SeatAdjustmentControls: 'SeatAdjustmentControls',
    SeatFoldingMechanism: 'SeatFoldingMechanism',
    SeatBelt: 'SeatBelt',
    SeatBeltBuckle: 'SeatBeltBuckle',
    DoorPanel: 'DoorPanel',
    DoorSwitchPanel: 'DoorSwitchPanel',
    AirVentilation: 'AirVentilation',
    ClimateControl: 'ClimateControl',
    GloveCompartment: 'GloveCompartment',
    CentralDisplay: 'CentralDisplay',
    LighterSocket: 'LighterSocket',
    Speedometer: 'Speedometer',
    Tachometer: 'Tachometer',
    FuelGauge: 'FuelGauge',
    TemperatureGauge: 'TemperatureGauge',
    SteeringWheel: 'SteeringWheel',
    SteeringWheelControls: 'SteeringWheelControls',
    Horn: 'Horn',
    InteriorLightingControls: 'InteriorLightingControls',
    OverheadLighting: 'OverheadLighting',
    SunVisorLighting: 'SunVisorLighting',
    AmbientLighting: 'AmbientLighting',
    FootwellLighting: 'FootwellLighting',
    InteriorDoorHandleLighting: 'InteriorDoorHandleLighting',
    DoorPanelLighting: 'DoorPanelLighting',
    CupHolderLighting: 'CupHolderLighting',
    DashboardLighting: 'DashboardLighting',
    InteriorRoofLighting: 'InteriorRoofLighting',
    InteriorTrunkLighting: 'InteriorTrunkLighting',
    RoofLiner: 'RoofLiner',
    IgnitionSwitch: 'IgnitionSwitch',
    IgnitionKey: 'IgnitionKey',
    ParkingBrake: 'ParkingBrake',
    ElectronicParkingBrake: 'ElectronicParkingBrake',
    GearStick: 'GearStick',
    GearSelector: 'GearSelector',
    AcceleratorPedal: 'AcceleratorPedal',
    BreakPedal: 'BreakPedal',
    ClutchPedal: 'ClutchPedal',
    WindshieldWiperControl: 'WindshieldWiperControl',
    FloorMat: 'FloorMat',
    MatCover: 'MatCover',
    Armrest: 'Armrest',
    SunVisor: 'SunVisor',
    RearViewMirror: 'RearViewMirror',
    Navigator: 'Navigator',
    ProjectionNavigator: 'ProjectionNavigator',
    VideoRecorder: 'VideoRecorder',
    AudioSystem: 'AudioSystem',
    Speaker: 'Speaker',
    TrunkOrganizer: 'TrunkOrganizer',
    TrunkDivider: 'TrunkDivider',
    TrunkHiddenStorage: 'TrunkHiddenStorage',
    TrunkNet: 'TrunkNet',
    TrunkLiner: 'TrunkLiner',
    TireRepairKit: 'TireRepairKit',
    TireInflator: 'TireInflator',
    SpareWheel: 'SpareWheel',
    Jack: 'Jack'
} as const;

export type VehiclePartType = typeof VehiclePartType[keyof typeof VehiclePartType];


/**
 * 
 * @export
 * @interface VehiclePartTypeChangedDto
 */
export interface VehiclePartTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehiclePartTypeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehiclePartTypeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehiclePartTypeDto}
     * @memberof VehiclePartTypeChangedDto
     */
    'entity': VehiclePartTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeDto
 */
export interface VehiclePartTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehiclePartTypeDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {VehiclePartDescriptor}
     * @memberof VehiclePartTypeDto
     */
    'descriptor'?: VehiclePartDescriptor;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehiclePartTypeDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDto
     */
    'projections'?: Array<VehicleProjection>;
    /**
     * 
     * @type {VehiclePartTypeDtoProjectionsPerArea}
     * @memberof VehiclePartTypeDto
     */
    'projectionsPerArea'?: VehiclePartTypeDtoProjectionsPerArea;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isCategoryRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isTypeRoot'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehiclePartTypeDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VehiclePartTypeDto
     */
    'isEnabledForDamageDetection'?: boolean;
    /**
     * 
     * @type {Array<DamageTypeCategory>}
     * @memberof VehiclePartTypeDto
     */
    'damageCategories'?: Array<DamageTypeCategory> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VehiclePartTypeDto
     */
    'damageTypeIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VehiclePartTypeDto
     */
    'examplePictures'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VehiclePartTypeDtoProjectionsPerArea
 */
export interface VehiclePartTypeDtoProjectionsPerArea {
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDtoProjectionsPerArea
     */
    'None'?: Array<VehicleProjection>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDtoProjectionsPerArea
     */
    'Interior'?: Array<VehicleProjection>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDtoProjectionsPerArea
     */
    'Exterior'?: Array<VehicleProjection>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeDtoProjectionsPerArea
     */
    'Internal'?: Array<VehicleProjection>;
}
/**
 * 
 * @export
 * @interface VehiclePartTypeReferenceDto
 */
export interface VehiclePartTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehiclePartTypeReferenceDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehiclePartTypeReferenceDto
     */
    'category'?: VehiclePartCategory;
    /**
     * 
     * @type {VehiclePartType}
     * @memberof VehiclePartTypeReferenceDto
     */
    'type'?: VehiclePartType;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehiclePartTypeReferenceDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehiclePartTypeReferenceDto
     */
    'projections'?: Array<VehicleProjection>;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehiclePartTypeReferenceDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehiclePartTypeReferenceDto
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface VehiclePriceDto
 */
export interface VehiclePriceDto {
    /**
     * 
     * @type {GeneralCurrencyDto}
     * @memberof VehiclePriceDto
     */
    'currency'?: GeneralCurrencyDto;
    /**
     * 
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {GeneralDiscountDto}
     * @memberof VehiclePriceDto
     */
    'discount'?: GeneralDiscountDto;
    /**
     * 
     * @type {GeneralTaxDto}
     * @memberof VehiclePriceDto
     */
    'tax'?: GeneralTaxDto;
    /**
     * Total before any discount or tax is applied
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'subTotal'?: number;
    /**
     * Total after discount is applied, but before any tax is applied.
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'subTotalIncDiscount'?: number;
    /**
     * Total after discounts and taxes.
     * @type {number}
     * @memberof VehiclePriceDto
     */
    'total'?: number;
}
/**
 * Describes side of a vehicle in 3D space.
 * @export
 * @enum {string}
 */

export const VehicleProjection = {
    None: 'None',
    Any: 'Any',
    Front: 'Front',
    Back: 'Back',
    Top: 'Top',
    Bottom: 'Bottom',
    Left: 'Left',
    Right: 'Right'
} as const;

export type VehicleProjection = typeof VehicleProjection[keyof typeof VehicleProjection];


/**
 * 
 * @export
 * @interface VehicleReferenceDto
 */
export interface VehicleReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleSpecReferenceDto}
     * @memberof VehicleReferenceDto
     */
    'spec'?: VehicleSpecReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleReferenceDto
     */
    'identificationNumber'?: string | null;
    /**
     * 
     * @type {GeneralPlateNoDto}
     * @memberof VehicleReferenceDto
     */
    'plateNo'?: GeneralPlateNoDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleSize = {
    None: 'None',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    ExtraLarge: 'ExtraLarge'
} as const;

export type VehicleSize = typeof VehicleSize[keyof typeof VehicleSize];


/**
 * 
 * @export
 * @interface VehicleSpecDto
 */
export interface VehicleSpecDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleSpecDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleSpecDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleSpecDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof VehicleSpecDto
     */
    'modification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {VehicleSize}
     * @memberof VehicleSpecDto
     */
    'size'?: VehicleSize;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecDto
     */
    'bodyType'?: VehicleBodyType;
    /**
     * 
     * @type {VehicleFuelType}
     * @memberof VehicleSpecDto
     */
    'fuelType'?: VehicleFuelType;
    /**
     * 
     * @type {VehicleGearboxType}
     * @memberof VehicleSpecDto
     */
    'gearboxType'?: VehicleGearboxType;
    /**
     * 
     * @type {VehicleDrivetrainType}
     * @memberof VehicleSpecDto
     */
    'drivetrainType'?: VehicleDrivetrainType;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'manufactureYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'numberOfSeats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'numberOfDoors'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'bootCapacityL'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'cO2EmissionsGKm'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'horsepower'?: number | null;
    /**
     * 
     * @type {VehicleFuelConsumptionDto}
     * @memberof VehicleSpecDto
     */
    'fuelConsumption'?: VehicleFuelConsumptionDto;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'batteryCapacityKWh'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'electricRangeKm'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasAirConditioner'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowHook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowBar'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleSpecDto
     */
    'hasTowRope'?: boolean | null;
    /**
     * v1. Preserved for backward compatibility.
     * @type {number}
     * @memberof VehicleSpecDto
     */
    'purchasingCost'?: number | null;
    /**
     * 
     * @type {VehiclePriceDto}
     * @memberof VehicleSpecDto
     */
    'purchasePrice'?: VehiclePriceDto;
}


/**
 * 
 * @export
 * @interface VehicleSpecReferenceDto
 */
export interface VehicleSpecReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleSpecReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleMakeReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'make'?: VehicleMakeReferenceDto;
    /**
     * 
     * @type {VehicleModelReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'model'?: VehicleModelReferenceDto;
    /**
     * 
     * @type {VehicleGenerationReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'generation'?: VehicleGenerationReferenceDto;
    /**
     * 
     * @type {VehicleModificationReferenceDto}
     * @memberof VehicleSpecReferenceDto
     */
    'modification'?: VehicleModificationReferenceDto;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleSpecReferenceDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {VehicleBodyType}
     * @memberof VehicleSpecReferenceDto
     */
    'bodyType'?: VehicleBodyType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleType = {
    None: 'None',
    Car: 'Car'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];


/**
 * 
 * @export
 * @interface VehicleTypeChangedDto
 */
export interface VehicleTypeChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleTypeChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleTypeChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleTypeChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleTypeDto}
     * @memberof VehicleTypeChangedDto
     */
    'entity': VehicleTypeDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleTypeDto
 */
export interface VehicleTypeDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleTypeDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleTypeDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VehicleTypeDto
     */
    'sortOrder'?: number;
}


/**
 * 
 * @export
 * @interface VehicleTypeReferenceDto
 */
export interface VehicleTypeReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleTypeReferenceDto
     */
    'type'?: VehicleType;
    /**
     * 
     * @type {string}
     * @memberof VehicleTypeReferenceDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelChangedDto
 */
export interface VehicleVisualModelChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VehicleVisualModelChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VehicleVisualModelChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VehicleVisualModelChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VehicleVisualModelDto}
     * @memberof VehicleVisualModelChangedDto
     */
    'entity': VehicleVisualModelDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelDto
 */
export interface VehicleVisualModelDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'versionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isLatestVersion'?: boolean;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {Array<VehicleType>}
     * @memberof VehicleVisualModelDto
     */
    'vehicleTypes'?: Array<VehicleType> | null;
    /**
     * 
     * @type {Array<VehicleBodyType>}
     * @memberof VehicleVisualModelDto
     */
    'bodyTypes'?: Array<VehicleBodyType> | null;
    /**
     * 
     * @type {Array<VehicleMakeReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'makes'?: Array<VehicleMakeReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModelReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'models'?: Array<VehicleModelReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleGenerationReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'generations'?: Array<VehicleGenerationReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleModificationReferenceDto>}
     * @memberof VehicleVisualModelDto
     */
    'modifications'?: Array<VehicleModificationReferenceDto> | null;
    /**
     * 
     * @type {Array<VehicleVisualModelImageDto>}
     * @memberof VehicleVisualModelDto
     */
    'images'?: Array<VehicleVisualModelImageDto>;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isBuiltIn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDto
     */
    'isEnabled'?: boolean;
    /**
     * 
     * @type {VehicleVisualModelDtoVehicleTypesMap}
     * @memberof VehicleVisualModelDto
     */
    'vehicleTypesMap'?: VehicleVisualModelDtoVehicleTypesMap;
    /**
     * 
     * @type {VehicleVisualModelDtoBodyTypesMap}
     * @memberof VehicleVisualModelDto
     */
    'bodyTypesMap'?: VehicleVisualModelDtoBodyTypesMap;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'makeIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'modelIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'generationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VehicleVisualModelDto
     */
    'modificationIdsMap'?: { [key: string]: boolean; };
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VehicleVisualModelDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {Array<VehicleProjection>}
     * @memberof VehicleVisualModelDto
     */
    'projections'?: Array<VehicleProjection>;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelDtoBodyTypesMap
 */
export interface VehicleVisualModelDtoBodyTypesMap {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Sedan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Hatchback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Wagon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Van'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Minivan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'CUV'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'SUV'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Coupe'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Convertible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Roadster'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Pickup'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'ChassisCab'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'PlatformCab'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'PassengerVan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'DoubleChassisCab'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'DoubleCabPickUp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Microcar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'MiniMPV'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Targa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'Other'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoBodyTypesMap
     */
    'PlatforbCab'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleVisualModelDtoVehicleTypesMap
 */
export interface VehicleVisualModelDtoVehicleTypesMap {
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoVehicleTypesMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoVehicleTypesMap
     */
    'Car'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleVisualModelDtoVehicleTypesMap
     */
    'Any'?: boolean;
}
/**
 * 
 * @export
 * @interface VehicleVisualModelImageDto
 */
export interface VehicleVisualModelImageDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelImageDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleVisualModelImageDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleVisualModelImageDto
     */
    'projection'?: VehicleProjection;
    /**
     * 
     * @type {FileReferenceDto}
     * @memberof VehicleVisualModelImageDto
     */
    'file'?: FileReferenceDto;
}


/**
 * 
 * @export
 * @interface VehicleVisualModelReferenceDto
 */
export interface VehicleVisualModelReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleVisualModelType}
     * @memberof VehicleVisualModelReferenceDto
     */
    'type'?: VehicleVisualModelType;
    /**
     * 
     * @type {string}
     * @memberof VehicleVisualModelReferenceDto
     */
    'name'?: string;
}


/**
 * Describes data object that is stored in VehicleVisualModel SVG file -> \"<path />\" -> \"<desc />\" metadata node.
 * @export
 * @interface VehicleVisualModelSvgPathMetadataDto
 */
export interface VehicleVisualModelSvgPathMetadataDto {
    /**
     * 
     * @type {VehicleVisualModelSvgPathType}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'pathType'?: VehicleVisualModelSvgPathType;
    /**
     * 
     * @type {VehicleType}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleType'?: VehicleType;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleArea'?: VehicleArea;
    /**
     * 
     * @type {VehicleProjection}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehicleProjection'?: VehicleProjection;
    /**
     * 
     * @type {VehiclePartCategory}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartCategory'?: VehiclePartCategory;
    /**
     * 
     * @type {Array<VehiclePartType>}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartTypes'?: Array<VehiclePartType> | null;
    /**
     * 
     * @type {Array<VehiclePartDescriptor>}
     * @memberof VehicleVisualModelSvgPathMetadataDto
     */
    'vehiclePartDescriptors'?: Array<VehiclePartDescriptor> | null;
}


/**
 * Describes SVG path types in VehicleVisualModel SVG file.
 * @export
 * @enum {string}
 */

export const VehicleVisualModelSvgPathType = {
    None: 'None',
    VehiclePart: 'VehiclePart'
} as const;

export type VehicleVisualModelSvgPathType = typeof VehicleVisualModelSvgPathType[keyof typeof VehicleVisualModelSvgPathType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VehicleVisualModelType = {
    None: 'None',
    TwoDimensionalDivided: 'TwoDimensionalDivided'
} as const;

export type VehicleVisualModelType = typeof VehicleVisualModelType[keyof typeof VehicleVisualModelType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VisibilityStatus = {
    None: 'None',
    Visible: 'Visible',
    Hidden: 'Hidden'
} as const;

export type VisibilityStatus = typeof VisibilityStatus[keyof typeof VisibilityStatus];


/**
 * 
 * @export
 * @interface VisualInspectionAreaSettingsDto
 */
export interface VisualInspectionAreaSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'minAttachmentCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionAreaSettingsDto
     */
    'minPhotoCount'?: number | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionChangedDto
 */
export interface VisualInspectionChangedDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionChangedDto
     */
    'connectionIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {EntityChangeType}
     * @memberof VisualInspectionChangedDto
     */
    'changeType': EntityChangeType;
    /**
     * 
     * @type {EntityType}
     * @memberof VisualInspectionChangedDto
     */
    'entityType': EntityType;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'entityId': string;
    /**
     * 
     * @type {VisualInspectionDto}
     * @memberof VisualInspectionChangedDto
     */
    'entity': VisualInspectionDto;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionChangedDto
     */
    'tenantId': string | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionContentDto
 */
export interface VisualInspectionContentDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionContentDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionDto
 */
export interface VisualInspectionDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionDto
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VisualInspectionDto
     */
    'locationIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {VehicleReferenceDto}
     * @memberof VisualInspectionDto
     */
    'vehicle'?: VehicleReferenceDto;
    /**
     * 
     * @type {ContractReferenceDto}
     * @memberof VisualInspectionDto
     */
    'contract'?: ContractReferenceDto;
    /**
     * 
     * @type {FrontEndAppType}
     * @memberof VisualInspectionDto
     */
    'appType'?: FrontEndAppType;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'inspectedAt'?: string;
    /**
     * 
     * @type {GeneralInspectorDto}
     * @memberof VisualInspectionDto
     */
    'inspector'?: GeneralInspectorDto;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionDto
     */
    'mileage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionDto
     */
    'fuelLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<VisualInspectionItemDto>}
     * @memberof VisualInspectionDto
     */
    'items'?: Array<VisualInspectionItemDto>;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VisualInspectionDto
     */
    'attachments'?: Array<GeneralAttachmentDto> | null;
    /**
     * 
     * @type {VisualInspectionSettingsDto}
     * @memberof VisualInspectionDto
     */
    'settings'?: VisualInspectionSettingsDto;
    /**
     * 
     * @type {DataGrantsMetaDto}
     * @memberof VisualInspectionDto
     */
    'grantsMeta'?: DataGrantsMetaDto;
    /**
     * 
     * @type {TenantRequestsMetaDto}
     * @memberof VisualInspectionDto
     */
    'tenantRequestsMeta'?: TenantRequestsMetaDto;
    /**
     * 
     * @type {GeneralAttachedTagsDto}
     * @memberof VisualInspectionDto
     */
    'tags'?: GeneralAttachedTagsDto;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'createdAt'?: string;
    /**
     * Date-time string in <a href=\"https://en.wikipedia.org/wiki/ISO_8601#UTC\\\">ISO 8601 format</a>.
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionDto
     */
    'updatedBy'?: string | null;
    /**
     * 
     * @type {Array<VehicleArea>}
     * @memberof VisualInspectionDto
     */
    'areas'?: Array<VehicleArea>;
    /**
     * 
     * @type {VisualInspectionDtoAreasMap}
     * @memberof VisualInspectionDto
     */
    'areasMap'?: VisualInspectionDtoAreasMap;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof VisualInspectionDto
     */
    'tenantRequestIdsMap'?: { [key: string]: boolean; } | null;
}


/**
 * 
 * @export
 * @interface VisualInspectionDtoAreasMap
 */
export interface VisualInspectionDtoAreasMap {
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionDtoAreasMap
     */
    'None'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionDtoAreasMap
     */
    'Interior'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionDtoAreasMap
     */
    'Exterior'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VisualInspectionDtoAreasMap
     */
    'Internal'?: boolean;
}
/**
 * 
 * @export
 * @interface VisualInspectionItemDto
 */
export interface VisualInspectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionItemDto
     */
    'id'?: string;
    /**
     * 
     * @type {VehicleArea}
     * @memberof VisualInspectionItemDto
     */
    'area'?: VehicleArea;
    /**
     * 
     * @type {number}
     * @memberof VisualInspectionItemDto
     */
    'minPhotos'?: number;
    /**
     * 
     * @type {Array<GeneralAttachmentDto>}
     * @memberof VisualInspectionItemDto
     */
    'attachments'?: Array<GeneralAttachmentDto>;
}


/**
 * 
 * @export
 * @interface VisualInspectionReferenceDto
 */
export interface VisualInspectionReferenceDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'globalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'localNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionReferenceDto
     */
    'vehicleId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionResultDto
 */
export interface VisualInspectionResultDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionResultDto
     */
    'visualInspectionId'?: string;
}
/**
 * 
 * @export
 * @interface VisualInspectionSettingsDto
 */
export interface VisualInspectionSettingsDto {
    /**
     * 
     * @type {string}
     * @memberof VisualInspectionSettingsDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<VisualInspectionAreaSettingsDto>}
     * @memberof VisualInspectionSettingsDto
     */
    'perArea'?: Array<VisualInspectionAreaSettingsDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WhoType = {
    None: 'None',
    System: 'System',
    User: 'User'
} as const;

export type WhoType = typeof WhoType[keyof typeof WhoType];



/**
 * MobileTeslaFeatureInfoApi - axios parameter creator
 * @export
 */
export const MobileTeslaFeatureInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileProvidersTeslaFeatureInfoGet: async (nexusOpsTenant: string, vehicleId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileProvidersTeslaFeatureInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/mobile/providers/tesla/feature-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileTeslaFeatureInfoApi - functional programming interface
 * @export
 */
export const MobileTeslaFeatureInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileTeslaFeatureInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileProvidersTeslaFeatureInfoGet(nexusOpsTenant: string, vehicleId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaFeatureInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileProvidersTeslaFeatureInfoGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileTeslaFeatureInfoApi.apiV01DemoMobileProvidersTeslaFeatureInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileTeslaFeatureInfoApi - factory interface
 * @export
 */
export const MobileTeslaFeatureInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileTeslaFeatureInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileProvidersTeslaFeatureInfoGet(requestParameters: MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaFeatureInfoDto> {
            return localVarFp.apiV01DemoMobileProvidersTeslaFeatureInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoMobileProvidersTeslaFeatureInfoGet operation in MobileTeslaFeatureInfoApi.
 * @export
 * @interface MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest
 */
export interface MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGet
     */
    readonly vehicleId?: string
}

/**
 * MobileTeslaFeatureInfoApi - object-oriented interface
 * @export
 * @class MobileTeslaFeatureInfoApi
 * @extends {BaseAPI}
 */
export class MobileTeslaFeatureInfoApi extends BaseAPI {
    /**
     * 
     * @summary Enabled regardless of the corresponding feature flag enabled.
     * @param {MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileTeslaFeatureInfoApi
     */
    public apiV01DemoMobileProvidersTeslaFeatureInfoGet(requestParameters: MobileTeslaFeatureInfoApiApiV01DemoMobileProvidersTeslaFeatureInfoGetRequest, options?: RawAxiosRequestConfig) {
        return MobileTeslaFeatureInfoApiFp(this.configuration).apiV01DemoMobileProvidersTeslaFeatureInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MobileVehicleProviderTeslaApi - axios parameter creator
 * @export
 */
export const MobileVehicleProviderTeslaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaVehicleGetPaginatedDto} [teslaVehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaGetPost: async (nexusOpsTenant: string, teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teslaVehicleGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/door-lock`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/door-unlock`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/flash-lights`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/honk-horn`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/remote-start-drive`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/commands/wake-up`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet: async (nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIgnoreCache !== undefined) {
                localVarQueryParameter['isIgnoreCache'] = isIgnoreCache;
            }

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet: async (nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/mobile/vehicles/providers/tesla/{vehicleId}/vehicle-data`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIgnoreCache !== undefined) {
                localVarQueryParameter['isIgnoreCache'] = isIgnoreCache;
            }

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileVehicleProviderTeslaApi - functional programming interface
 * @export
 */
export const MobileVehicleProviderTeslaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileVehicleProviderTeslaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaVehicleGetPaginatedDto} [teslaVehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaGetPost(nexusOpsTenant: string, teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTeslaVehicleListItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaGetPost(nexusOpsTenant, teslaVehicleGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleWakeUpResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(nexusOpsTenant, vehicleId, isIgnoreCache, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(nexusOpsTenant, vehicleId, isIgnoreCache, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MobileVehicleProviderTeslaApi.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MobileVehicleProviderTeslaApi - factory interface
 * @export
 */
export const MobileVehicleProviderTeslaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileVehicleProviderTeslaApiFp(configuration)
    return {
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaGetPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTeslaVehicleListItemDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaVehicleGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleWakeUpResultDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleDataDto> {
            return localVarFp.apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaGetPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TeslaVehicleGetPaginatedDto}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPost
     */
    readonly teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet
     */
    readonly isIgnoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet operation in MobileVehicleProviderTeslaApi.
 * @export
 * @interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest
 */
export interface MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet
     */
    readonly isIgnoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet
     */
    readonly isUseDemoData?: boolean
}

/**
 * MobileVehicleProviderTeslaApi - object-oriented interface
 * @export
 * @class MobileVehicleProviderTeslaApi
 * @extends {BaseAPI}
 */
export class MobileVehicleProviderTeslaApi extends BaseAPI {
    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaGetPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaGetPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaVehicleGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorLockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsDoorUnlockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsFlashLightsPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsHonkHornPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsRemoteStartDrivePost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPostRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdCommandsWakeUpPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileVehicleProviderTeslaApi
     */
    public apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(requestParameters: MobileVehicleProviderTeslaApiApiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGetRequest, options?: RawAxiosRequestConfig) {
        return MobileVehicleProviderTeslaApiFp(this.configuration).apiV01DemoMobileVehiclesProvidersTeslaVehicleIdVehicleDataGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeslaAuthApi - axios parameter creator
 * @export
 */
export const TeslaAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exchanges the authorization code (in authorization_code grant flow) for a Tesla third-party access token.  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaAuthAuthorizationCodeExchangeRequestDto} [teslaAuthAuthorizationCodeExchangeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost: async (nexusOpsTenant: string, teslaAuthAuthorizationCodeExchangeRequestDto?: TeslaAuthAuthorizationCodeExchangeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/providers/tesla/auth/code-exchange/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teslaAuthAuthorizationCodeExchangeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaAuthInfoGet: async (nexusOpsTenant: string, vehicleId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaAuthInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/providers/tesla/auth/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeslaAuthApi - functional programming interface
 * @export
 */
export const TeslaAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeslaAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exchanges the authorization code (in authorization_code grant flow) for a Tesla third-party access token.  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaAuthAuthorizationCodeExchangeRequestDto} [teslaAuthAuthorizationCodeExchangeRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(nexusOpsTenant: string, teslaAuthAuthorizationCodeExchangeRequestDto?: TeslaAuthAuthorizationCodeExchangeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaAuthorizationCodeExchangeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(nexusOpsTenant, teslaAuthAuthorizationCodeExchangeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaAuthApi.apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaAuthInfoGet(nexusOpsTenant: string, vehicleId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaAuthInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaAuthInfoGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaAuthApi.apiV01DemoProvidersTeslaAuthInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeslaAuthApi - factory interface
 * @export
 */
export const TeslaAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeslaAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Exchanges the authorization code (in authorization_code grant flow) for a Tesla third-party access token.  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
         * @param {TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(requestParameters: TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaAuthorizationCodeExchangeResponseDto> {
            return localVarFp.apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(requestParameters.nexusOpsTenant, requestParameters.teslaAuthAuthorizationCodeExchangeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaAuthInfoGet(requestParameters: TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaAuthInfoDto> {
            return localVarFp.apiV01DemoProvidersTeslaAuthInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost operation in TeslaAuthApi.
 * @export
 * @interface TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest
 */
export interface TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TeslaAuthAuthorizationCodeExchangeRequestDto}
     * @memberof TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPost
     */
    readonly teslaAuthAuthorizationCodeExchangeRequestDto?: TeslaAuthAuthorizationCodeExchangeRequestDto
}

/**
 * Request parameters for apiV01DemoProvidersTeslaAuthInfoGet operation in TeslaAuthApi.
 * @export
 * @interface TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest
 */
export interface TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGet
     */
    readonly vehicleId?: string
}

/**
 * TeslaAuthApi - object-oriented interface
 * @export
 * @class TeslaAuthApi
 * @extends {BaseAPI}
 */
export class TeslaAuthApi extends BaseAPI {
    /**
     * 
     * @summary Exchanges the authorization code (in authorization_code grant flow) for a Tesla third-party access token.  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
     * @param {TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaAuthApi
     */
    public apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(requestParameters: TeslaAuthApiApiV01DemoProvidersTeslaAuthCodeExchangeRequestPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaAuthApiFp(this.configuration).apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost(requestParameters.nexusOpsTenant, requestParameters.teslaAuthAuthorizationCodeExchangeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaAuthApi
     */
    public apiV01DemoProvidersTeslaAuthInfoGet(requestParameters: TeslaAuthApiApiV01DemoProvidersTeslaAuthInfoGetRequest, options?: RawAxiosRequestConfig) {
        return TeslaAuthApiFp(this.configuration).apiV01DemoProvidersTeslaAuthInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeslaConnectionsApi - axios parameter creator
 * @export
 */
export const TeslaConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaConnectionGetPaginatedDto} [teslaConnectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsGetPost: async (nexusOpsTenant: string, teslaConnectionGetPaginatedDto?: TeslaConnectionGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/providers/tesla/connections/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teslaConnectionGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost: async (nexusOpsTenant: string, teslaConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'teslaConnectionId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost', 'teslaConnectionId', teslaConnectionId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/connections/{teslaConnectionId}/auth-tokens/refresh`
                .replace(`{${"teslaConnectionId"}}`, encodeURIComponent(String(teslaConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete: async (nexusOpsTenant: string, teslaConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'teslaConnectionId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete', 'teslaConnectionId', teslaConnectionId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/connections/{teslaConnectionId}`
                .replace(`{${"teslaConnectionId"}}`, encodeURIComponent(String(teslaConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet: async (nexusOpsTenant: string, teslaConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'teslaConnectionId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet', 'teslaConnectionId', teslaConnectionId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/connections/{teslaConnectionId}`
                .replace(`{${"teslaConnectionId"}}`, encodeURIComponent(String(teslaConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost: async (nexusOpsTenant: string, teslaConnectionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'teslaConnectionId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost', 'teslaConnectionId', teslaConnectionId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/connections/{teslaConnectionId}/vehicles/refresh`
                .replace(`{${"teslaConnectionId"}}`, encodeURIComponent(String(teslaConnectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeslaConnectionsApi - functional programming interface
 * @export
 */
export const TeslaConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeslaConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaConnectionGetPaginatedDto} [teslaConnectionGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaConnectionsGetPost(nexusOpsTenant: string, teslaConnectionGetPaginatedDto?: TeslaConnectionGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTeslaConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaConnectionsGetPost(nexusOpsTenant, teslaConnectionGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(nexusOpsTenant: string, teslaConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(nexusOpsTenant, teslaConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(nexusOpsTenant: string, teslaConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(nexusOpsTenant, teslaConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(nexusOpsTenant: string, teslaConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(nexusOpsTenant, teslaConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} teslaConnectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(nexusOpsTenant: string, teslaConnectionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(nexusOpsTenant, teslaConnectionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaConnectionsApi.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeslaConnectionsApi - factory interface
 * @export
 */
export const TeslaConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeslaConnectionsApiFp(configuration)
    return {
        /**
         * 
         * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsGetPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTeslaConnectionDto> {
            return localVarFp.apiV01DemoProvidersTeslaConnectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaConnectionDto> {
            return localVarFp.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaConnectionDto> {
            return localVarFp.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaConnectionDto> {
            return localVarFp.apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoProvidersTeslaConnectionsGetPost operation in TeslaConnectionsApi.
 * @export
 * @interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest
 */
export interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TeslaConnectionGetPaginatedDto}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPost
     */
    readonly teslaConnectionGetPaginatedDto?: TeslaConnectionGetPaginatedDto
}

/**
 * Request parameters for apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost operation in TeslaConnectionsApi.
 * @export
 * @interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest
 */
export interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost
     */
    readonly teslaConnectionId: string
}

/**
 * Request parameters for apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete operation in TeslaConnectionsApi.
 * @export
 * @interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest
 */
export interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete
     */
    readonly teslaConnectionId: string
}

/**
 * Request parameters for apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet operation in TeslaConnectionsApi.
 * @export
 * @interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest
 */
export interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet
     */
    readonly teslaConnectionId: string
}

/**
 * Request parameters for apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost operation in TeslaConnectionsApi.
 * @export
 * @interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest
 */
export interface TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost
     */
    readonly teslaConnectionId: string
}

/**
 * TeslaConnectionsApi - object-oriented interface
 * @export
 * @class TeslaConnectionsApi
 * @extends {BaseAPI}
 */
export class TeslaConnectionsApi extends BaseAPI {
    /**
     * 
     * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaConnectionsApi
     */
    public apiV01DemoProvidersTeslaConnectionsGetPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsGetPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaConnectionsApiFp(this.configuration).apiV01DemoProvidersTeslaConnectionsGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaConnectionsApi
     */
    public apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaConnectionsApiFp(this.configuration).apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdAuthTokensRefreshPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaConnectionsApi
     */
    public apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TeslaConnectionsApiFp(this.configuration).apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdDelete(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaConnectionsApi
     */
    public apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGetRequest, options?: RawAxiosRequestConfig) {
        return TeslaConnectionsApiFp(this.configuration).apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdGet(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaConnectionsApi
     */
    public apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(requestParameters: TeslaConnectionsApiApiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaConnectionsApiFp(this.configuration).apiV01DemoProvidersTeslaConnectionsTeslaConnectionIdVehiclesRefreshPost(requestParameters.nexusOpsTenant, requestParameters.teslaConnectionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeslaFeatureInfoApi - axios parameter creator
 * @export
 */
export const TeslaFeatureInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaFeatureInfoGet: async (nexusOpsTenant: string, vehicleId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaFeatureInfoGet', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/providers/tesla/feature-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vehicleId !== undefined) {
                localVarQueryParameter['vehicleId'] = vehicleId;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeslaFeatureInfoApi - functional programming interface
 * @export
 */
export const TeslaFeatureInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeslaFeatureInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} [vehicleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaFeatureInfoGet(nexusOpsTenant: string, vehicleId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaFeatureInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaFeatureInfoGet(nexusOpsTenant, vehicleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaFeatureInfoApi.apiV01DemoProvidersTeslaFeatureInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeslaFeatureInfoApi - factory interface
 * @export
 */
export const TeslaFeatureInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeslaFeatureInfoApiFp(configuration)
    return {
        /**
         * 
         * @summary Enabled regardless of the corresponding feature flag enabled.
         * @param {TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaFeatureInfoGet(requestParameters: TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaFeatureInfoDto> {
            return localVarFp.apiV01DemoProvidersTeslaFeatureInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoProvidersTeslaFeatureInfoGet operation in TeslaFeatureInfoApi.
 * @export
 * @interface TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest
 */
export interface TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGet
     */
    readonly vehicleId?: string
}

/**
 * TeslaFeatureInfoApi - object-oriented interface
 * @export
 * @class TeslaFeatureInfoApi
 * @extends {BaseAPI}
 */
export class TeslaFeatureInfoApi extends BaseAPI {
    /**
     * 
     * @summary Enabled regardless of the corresponding feature flag enabled.
     * @param {TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaFeatureInfoApi
     */
    public apiV01DemoProvidersTeslaFeatureInfoGet(requestParameters: TeslaFeatureInfoApiApiV01DemoProvidersTeslaFeatureInfoGetRequest, options?: RawAxiosRequestConfig) {
        return TeslaFeatureInfoApiFp(this.configuration).apiV01DemoProvidersTeslaFeatureInfoGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TeslaVehiclesApi - axios parameter creator
 * @export
 */
export const TeslaVehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaVehicleGetPaginatedDto} [teslaVehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesGetPost: async (nexusOpsTenant: string, teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesGetPost', 'nexusOpsTenant', nexusOpsTenant)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(teslaVehicleGetPaginatedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/door-lock`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/door-unlock`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/flash-lights`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/honk-horn`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/remote-start-drive`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost: async (nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/commands/wake-up`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdGet: async (nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIgnoreCache !== undefined) {
                localVarQueryParameter['isIgnoreCache'] = isIgnoreCache;
            }

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet: async (nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nexusOpsTenant' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet', 'nexusOpsTenant', nexusOpsTenant)
            // verify required parameter 'vehicleId' is not null or undefined
            assertParamExists('apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet', 'vehicleId', vehicleId)
            const localVarPath = `/api/v0.1-demo/providers/tesla/vehicles/{vehicleId}/vehicle-data`
                .replace(`{${"vehicleId"}}`, encodeURIComponent(String(vehicleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isIgnoreCache !== undefined) {
                localVarQueryParameter['isIgnoreCache'] = isIgnoreCache;
            }

            if (isUseDemoData !== undefined) {
                localVarQueryParameter['isUseDemoData'] = isUseDemoData;
            }

            if (nexusOpsTenant != null) {
                localVarHeaderParameter['NexusOps-Tenant'] = String(nexusOpsTenant);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeslaVehiclesApi - functional programming interface
 * @export
 */
export const TeslaVehiclesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeslaVehiclesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {TeslaVehicleGetPaginatedDto} [teslaVehicleGetPaginatedDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesGetPost(nexusOpsTenant: string, teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginationDtoOfTeslaVehicleListItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesGetPost(nexusOpsTenant, teslaVehicleGetPaginatedDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesGetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleCommandResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(nexusOpsTenant: string, vehicleId: string, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleWakeUpResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(nexusOpsTenant, vehicleId, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdGet(nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdGet(nexusOpsTenant, vehicleId, isIgnoreCache, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} nexusOpsTenant   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
         * @param {string} vehicleId 
         * @param {boolean} [isIgnoreCache] 
         * @param {boolean} [isUseDemoData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(nexusOpsTenant: string, vehicleId: string, isIgnoreCache?: boolean, isUseDemoData?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeslaVehicleDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(nexusOpsTenant, vehicleId, isIgnoreCache, isUseDemoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TeslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TeslaVehiclesApi - factory interface
 * @export
 */
export const TeslaVehiclesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeslaVehiclesApiFp(configuration)
    return {
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesGetPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PaginationDtoOfTeslaVehicleListItemDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaVehicleGetPaginatedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleCommandResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleWakeUpResultDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdGet(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TeslaVehicleDataDto> {
            return localVarFp.apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesGetPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {TeslaVehicleGetPaginatedDto}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPost
     */
    readonly teslaVehicleGetPaginatedDto?: TeslaVehicleGetPaginatedDto
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdGet operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGet
     */
    readonly isIgnoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGet
     */
    readonly isUseDemoData?: boolean
}

/**
 * Request parameters for apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet operation in TeslaVehiclesApi.
 * @export
 * @interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest
 */
export interface TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest {
    /**
     *   Tenant identifier, which specifies in a context of what tenant request is made.     When calling the endpoint via API client generated from Open API (Swagger), you can pass empty value or undefined,   and set tenant identifier header value manually.   For instance, in TS API client generated via openapi-generator-cli, you can configure default HTTP headers globally and avoid passing actual   header value on each request.  
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet
     */
    readonly nexusOpsTenant: string

    /**
     * 
     * @type {string}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet
     */
    readonly vehicleId: string

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet
     */
    readonly isIgnoreCache?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet
     */
    readonly isUseDemoData?: boolean
}

/**
 * TeslaVehiclesApi - object-oriented interface
 * @export
 * @class TeslaVehiclesApi
 * @extends {BaseAPI}
 */
export class TeslaVehiclesApi extends BaseAPI {
    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesGetPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesGetPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesGetPost(requestParameters.nexusOpsTenant, requestParameters.teslaVehicleGetPaginatedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorLockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsDoorUnlockPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsFlashLightsPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsHonkHornPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsRemoteStartDrivePost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPostRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdCommandsWakeUpPost(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdGet(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdGetRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeslaVehiclesApi
     */
    public apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(requestParameters: TeslaVehiclesApiApiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGetRequest, options?: RawAxiosRequestConfig) {
        return TeslaVehiclesApiFp(this.configuration).apiV01DemoProvidersTeslaVehiclesVehicleIdVehicleDataGet(requestParameters.nexusOpsTenant, requestParameters.vehicleId, requestParameters.isIgnoreCache, requestParameters.isUseDemoData, options).then((request) => request(this.axios, this.basePath));
    }
}



