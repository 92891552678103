import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { PermissionDto, RoleDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

interface TenantAccountState {
  loading: {
    getRoles?: boolean;
  };
  permissions?: PermissionDto[];
  roles?: RoleDto[];
  role?: RoleDto;
}

const initialState: TenantAccountState = {
  loading: {},
  permissions: undefined,
  roles: undefined,
  role: undefined,
};

const tenantsSlice = createSlice({
  name: "tenantAccount",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<TenantAccountState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _roleCreated: (state, action: PayloadAction<RoleDto>) => {
      if (state.roles) {
        state.roles.push(action.payload);
      }
    },
    _roleUpdated: (state, action: PayloadAction<RoleDto>) => {
      if (state.roles) {
        const index = state.roles.findIndex((x) => x.id === action.payload.id);
        index !== -1 && state.roles.splice(index, 1, action.payload);
      }
      if (state.role?.id === action.payload.id) {
        state.role = action.payload;
      }
    },
    _roleDeleted: (state, action: PayloadAction<{ roleId: string }>) => {
      if (state.roles) {
        const index = state.roles.findIndex((x) => x.id === action.payload.roleId);
        index !== -1 && state.roles.splice(index, 1);
      }
    },
    resetTenantRole: (state, action: PayloadAction<undefined>) => {
      state.role = undefined;
    },
  },
});

export const { setLoading, _roleCreated, _roleUpdated, _roleDeleted, resetTenantRole } =
  tenantsSlice.actions;

export const tenantAccountReducer = tenantsSlice.reducer;

export const createTenantRole =
  (
    ...args: Parameters<typeof apiClient.tenantAccountApi.apiV1TenantAccountRolesPost>
  ): AppThunk<Promise<RoleDto>> =>
  async (dispatch) => {
    const response = await apiClient.tenantAccountApi.apiV1TenantAccountRolesPost(...args);
    dispatch(_roleCreated(response.data));
    return response.data;
  };

export const updateTenantRole =
  (
    ...args: Parameters<typeof apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdPut>
  ): AppThunk<Promise<RoleDto>> =>
  async (dispatch) => {
    const response = await apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdPut(...args);
    dispatch(_roleUpdated(response.data));
    return response.data;
  };

export const deleteTenantRole =
  (
    ...args: Parameters<typeof apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdDelete>
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await apiClient.tenantAccountApi.apiV1TenantAccountRolesRoleIdDelete(...args);
    dispatch(_roleDeleted({ roleId: args[0].roleId }));
  };
