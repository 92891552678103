import { apiClient } from "@/core/api/ApiClient";
import { VehicleDto, VehicleStatus } from "@/core/api/generated";

import GeneralStatusMetaDisplayAndInput from "./GeneralStatusMetaDisplayAndInput";

interface Props {
  vehicle: VehicleDto;
  onVehicleUpdated: (newValue: VehicleDto) => void | Promise<void>;
}

export default function GeneralStatusMetaDisplayAndInputOfVehicle({
  vehicle,
  onVehicleUpdated,
}: Props) {
  return (
    <GeneralStatusMetaDisplayAndInput
      value={vehicle.status}
      subjectEntity={vehicle}
      onMetaUpdated={(newValue) => {
        onVehicleUpdated({
          ...vehicle,
          status: newValue,
        });
      }}
      onStatusChange={async (newValue) => {
        const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdStatusPut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleId: vehicle.id || "",
          generalStatusInputTypedDtoOfVehicleStatus: {
            ...newValue,
            status: newValue.status as VehicleStatus,
          },
        });
        onVehicleUpdated(response.data);
      }}
    />
  );
}
