import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppIcon from "@/common/components/Icons/AppIcon";
import { makePasswordPolicyErrorIfExists } from "@/common/helpers/passwordPolicy";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { authService } from "@/common/services/auth";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { PasswordPolicyDto, PasswordValidateResultDto } from "@/core/api/generated";
import * as authSlice from "@/store/auth/slice";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    width: "30%",
    height: "fit-content",
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export default function TenantRegisterCreatePasswordPage() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const [passwordPolicy, setPasswordPolicy] = useState<PasswordPolicyDto | undefined>(undefined);
  const [passwordValidationResult, setPasswordValidationResult] = useState<
    PasswordValidateResultDto | undefined
  >(undefined);
  const [passwordPolicyError, setPasswordPolicyError] = useState<string>("");
  const queryParams = useQueryParams();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(false);
  const thunkDispatch = useAppThunkDispatch();
  const { savedCompanyRegistrationInfo, isAuthenticated } = useAppSelector((x) => x.auth);

  const { enqueueSnackbar } = useAppSnackbar();

  useEffect(() => {
    // redirect to login if logged in or user already has an account
    if (isAuthenticated || savedCompanyRegistrationInfo?.userId) {
      setIsRedirecting(true);
      if (!isAuthenticated) {
        enqueueSnackbar("Registration succeeded. Please, sign in.", {
          variant: "success",
        });
      }
      localStorage.removeItem("savedCompanyRegistrationInfo");

      setTimeout(async () => {
        await authService.loginWithRedirect({
          appState: {
            redirectUrl: queryParams.redirectUrl,
          },
          loginHint: savedCompanyRegistrationInfo?.email,
        });
      }, 2000);
      return;
    }
  }, []);

  useEffect(() => {
    thunkDispatch(authSlice.getPasswordPolicy()).then((res) => {
      setPasswordPolicy(res);
    });
  }, []);

  const validateAccountPasswordDebounce = useCallback(
    _.debounce(
      async (...args: Parameters<typeof authSlice.validateAccountPassword>) =>
        thunkDispatch(authSlice.validateAccountPassword(...args)).then((result) => {
          const message = makePasswordPolicyErrorIfExists(result, passwordPolicy);
          setPasswordPolicyError(message);
        }),
      1000,
    ),
    [],
  );

  if (isRedirecting) {
    return <CircularProgress size='50px' color='primary' />;
  }

  return (
    <Formik<BaseFormikValues & { password?: string | null; confirmPassword?: string | null }>
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string()
          .required("Confirm password is required")
          .oneOf([Yup.ref("password")], "Passwords must be equal"),
      })}
      onSubmit={async (values, { setErrors, setFieldError, setStatus, setSubmitting }) => {
        try {
          await thunkDispatch(
            authSlice.completeRegistration({
              email: savedCompanyRegistrationInfo?.email,
              password: values.password || undefined,
              confirmPassword: values.confirmPassword || undefined,
            }),
          );
          enqueueSnackbar("Registration succeeded. Redirecting to sign in...", {
            variant: "success",
          });
          setTimeout(async () => {
            await authService.loginWithRedirect({
              appState: {
                redirectUrl: queryParams.redirectUrl,
              },
              loginHint: savedCompanyRegistrationInfo?.email,
            });
          }, 2000);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: Error | any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldError,
      }) => (
        <StyledForm noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              borderRadius: (theme) => theme.shape.borderRadius,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 2,
              width: "100%",
              mt: "auto",
            }}
          >
            <Typography component='div' variant='h1'>
              {t("auth.createPassword")}
            </Typography>
          </Box>

          <TextField
            error={Boolean(touched.password && passwordPolicyError)}
            fullWidth
            helperText={touched.password && passwordPolicyError}
            label={t("auth.password")}
            margin='normal'
            name='password'
            onBlur={(e) => handleBlur(e)}
            onChange={async (e) => {
              handleChange(e);
              validateAccountPasswordDebounce({
                password: e.target.value,
              });
            }}
            type={isPasswordHidden ? "password" : "text"}
            value={values.password}
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ position: "relative" }}>
                  <IconButton onClick={() => setIsPasswordHidden((flag) => !flag)}>
                    <AppIcon of='visibility' />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label={t("auth.confirmPassword")}
            margin='normal'
            name='confirmPassword'
            onBlur={handleBlur}
            onChange={handleChange}
            type={isPasswordHidden ? "password" : "text"}
            value={values.confirmPassword}
            variant='outlined'
          />

          <GeneralValidationError sx={{ my: 1 }} errors={errors} />

          <Button
            sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
            color='primary'
            disabled={isSubmitting || !values.password || !values.confirmPassword}
            fullWidth
            size='medium'
            type='submit'
            variant='contained'
          >
            {t("common.buttons.submit")}
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}
