import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function NotFoundErrorPage() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        Not found
      </Typography>
      <Box>{`The requested resource can't be found.`}.</Box>
      <Box>
        <Link component={RouterLink} to={"/"}>
          Go home
        </Link>
      </Box>
    </Box>
  );
}
