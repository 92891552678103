import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

/** Handles navigation to root route '/'. */
export default function RootRouteEntry() {
  const generalQueryParams = useQueryParams<GeneralQueryParams>();
  const history = useHistory();

  useEffect(() => {
    // redirect to dashboard by default
    history.push(ROUTE_PATH.ADMIN_DASHBOARD);
  }, []);

  return null;
}
