import EventEmitter from "events";

/** [event name]: event type */
export type EventMap = Record<string, any>;

export type EventKey<TEventMap extends EventMap> = string & keyof TEventMap;
export type EventReceiver<T> = (params: T) => void;

interface OnCustomResult<TEventMap extends EventMap, TKey extends EventKey<TEventMap>> {
  eventName: TKey;
  listener: EventReceiver<TEventMap[TKey]>;
  /** Helper function to quickly unsubscribe/off from the event. */
  off: () => void;
}

export interface ITypedEventEmitter<TEventMap extends EventMap> {
  /** Traditional. */
  on<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): void;
  /** Custom. Returns passed event handler function for ease of unsubscribe. */
  on2<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): OnCustomResult<TEventMap, TKey>;
  /** Traditional. */
  off<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): void;
  /** Traditional. */
  emit<TKey extends EventKey<TEventMap>>(eventName: TKey, params: TEventMap[TKey]): void;
}

export class TypedEventEmitter<TEventMap extends EventMap>
  implements ITypedEventEmitter<TEventMap>
{
  private emitter = new EventEmitter();

  on<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): void {
    this.emitter.on(eventName, listener);
  }

  on2<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): OnCustomResult<TEventMap, TKey> {
    this.emitter.on(eventName, listener);
    return {
      eventName,
      listener,
      off: () => {
        this.emitter.off(eventName, listener);
      },
    };
  }

  off<TKey extends EventKey<TEventMap>>(
    eventName: TKey,
    listener: EventReceiver<TEventMap[TKey]>,
  ): void {
    this.emitter.off(eventName, listener);
  }

  emit<TKey extends EventKey<TEventMap>>(eventName: TKey, params: TEventMap[TKey]): void {
    this.emitter.emit(eventName, params);
  }
}
