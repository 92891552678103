import { Box, IconButton, ListItemIcon, ListItemText, MenuList, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import DropdownButton from "@/common/components/Button/DropdownButton";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ApiEnumName, enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import FieldValue from "../../Form/Display/FieldValue";
import ApiEnumIcon from "../../Icons/ApiEnumIcon";
import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import TextLineListSkeleton from "../../Skeleton/TextLineListSkeleton";
import AppTypography from "../../Text/AppTypography";

interface Props {
  assetSubscription: AssetSubscriptionDto | undefined | null;
  withDetailsPopover?: boolean;
  onUpdated: (newValue: AssetSubscriptionDto) => void | Promise<void>;
}

export default function AssetSubscriptionStatusDisplayAndInput({
  assetSubscription,
  withDetailsPopover = true,
  onUpdated,
}: Props) {
  const currentStatus = assetSubscription?.status;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const enumTransitionSpecRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsTransitionSpecGet,
    {
      enumTypeName: ApiEnumName.SubscriptionStatus,
    },
    {
      deps: [isDropdownOpen],
      skip: !isDropdownOpen,
    },
  );
  const enumTransitionSpec = enumTransitionSpecRequest.data;

  const newStatusCandidates = useMemo(
    () =>
      currentStatus && enumTransitionSpec
        ? enumService.getEnumValues(ApiEnumName.SubscriptionStatus).filter((status) =>
            enumService.canTransit({
              enumName: ApiEnumName.SubscriptionStatus,
              fromEnumValue: currentStatus,
              toEnumValue: status,
              transitionSpec: enumTransitionSpec,
            }),
          )
        : undefined,
    [currentStatus, enumTransitionSpec],
  );

  if (!assetSubscription) {
    return null;
  }

  return (
    <Box>
      <DropdownButton
        size='small'
        autoCloseOnClick={false}
        buttonProps={{
          color: "inherit",
          size: "small",
          variant: "text",
          sx: { p: 0, minWidth: "auto", height: "auto" },
          startIcon: (
            <ApiEnumIcon
              type='SubscriptionStatus'
              value={assetSubscription.status}
              withTooltip={false}
            />
          ),
        }}
        dropdownContentWrapperProps={{
          minWidth: 200,
          maxHeight: {
            xxs: "90vh",
            md: 300,
          },
        }}
        onOpen={() => {
          setIsDropdownOpen(true);
        }}
        dropdownContent={(params) => (
          <Stack spacing={1}>
            {enumTransitionSpecRequest.isLoading && (
              <Stack sx={{ p: 1 }}>
                <TextLineListSkeleton itemCount={5} />
              </Stack>
            )}

            {!enumTransitionSpecRequest.isLoading && enumTransitionSpec && (
              <MenuList>
                {!TypeHelper.isEmpty(newStatusCandidates) && (
                  <HeaderMenuItem primaryTitle='Update status to:' />
                )}
                {TypeHelper.isEmpty(newStatusCandidates) && (
                  <HeaderMenuItem
                    primaryTitle={`Can't update status when it's ${enumService.getEnumValueName(
                      "SubscriptionStatus",
                      currentStatus,
                    )}.`}
                  />
                )}

                {newStatusCandidates?.map((newStatusCandidate, i) => (
                  <AppMenuItem
                    key={i}
                    onClick={async () => {
                      try {
                        const response =
                          await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdStatusPut(
                            {
                              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                              assetSubscriptionId: assetSubscription!.id!,
                              assetSubscriptionUpdateStatusDto: {
                                newStatus: newStatusCandidate,
                              },
                            },
                          );
                        onUpdated && onUpdated(response.data);
                        params.handleClose();
                      } catch (err) {
                        const validation = ValidationHelper.handleApiErrorResponse(err);
                        enqueueSnackbar(validation.getErrorsAsString(), {
                          variant: "error",
                        });
                      }
                    }}
                  >
                    <ListItemIcon>
                      <ApiEnumIcon
                        type='SubscriptionStatus'
                        value={newStatusCandidate}
                        fontSize='small'
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <InlineApiEnumValue type='SubscriptionStatus' value={newStatusCandidate} />
                    </ListItemText>
                  </AppMenuItem>
                ))}
              </MenuList>
            )}
          </Stack>
        )}
      >
        <InlineApiEnumValue
          type='SubscriptionStatus'
          value={assetSubscription.status}
          withHelperTooltip
        />
      </DropdownButton>

      {withDetailsPopover && (
        <AppPopover
          clickBehavior={{}}
          trigger={
            <AppTooltip title='View details'>
              <IconButton size='extraSmall' sx={{ ml: 0.25 }}>
                <AppIcon of='details' />
              </IconButton>
            </AppTooltip>
          }
        >
          <AppPopoverContent sx={{ minWidth: 300 }}>
            <Stack spacing={1}>
              <AppTypography variant='h5'>Status details</AppTypography>

              <FieldValue
                label='Status'
                direction='row'
                isEmpty={TypeHelper.isEmpty(assetSubscription.status)}
              >
                <InlineApiEnumValue
                  type='SubscriptionStatus'
                  value={assetSubscription.status}
                  withHelperTooltip
                />
              </FieldValue>

              <FieldValue
                label='Previous status'
                direction='row'
                isEmpty={TypeHelper.isEmpty(assetSubscription.prevStatus)}
              >
                <InlineApiEnumValue
                  type='SubscriptionStatus'
                  value={assetSubscription.prevStatus}
                  withHelperTooltip
                />
              </FieldValue>

              <FieldValue
                label='Pending at'
                direction='row'
                isEmpty={!assetSubscription?.pendingAt}
              >
                <Datetime datetime={assetSubscription.pendingAt} withDurationFromNow />
              </FieldValue>

              <FieldValue label='Unpaid at' direction='row' isEmpty={!assetSubscription?.unpaidAt}>
                <Datetime datetime={assetSubscription.unpaidAt} withDurationFromNow />
              </FieldValue>

              <FieldValue label='Active at' direction='row' isEmpty={!assetSubscription?.activeAt}>
                <Datetime datetime={assetSubscription.activeAt} withDurationFromNow />
              </FieldValue>

              <FieldValue
                label='Past due at'
                direction='row'
                isEmpty={!assetSubscription?.pastDueAt}
              >
                <Datetime datetime={assetSubscription.pastDueAt} withDurationFromNow />
              </FieldValue>

              <FieldValue label='Paused at' direction='row' isEmpty={!assetSubscription?.pausedAt}>
                <Datetime datetime={assetSubscription.pausedAt} withDurationFromNow />
              </FieldValue>

              <FieldValue label='Ended at' direction='row' isEmpty={!assetSubscription?.endedAt}>
                <Datetime datetime={assetSubscription.endedAt} withDurationFromNow />
              </FieldValue>

              <FieldValue
                label='Canceled at'
                direction='row'
                isEmpty={!assetSubscription?.canceledAt}
              >
                <Datetime datetime={assetSubscription.canceledAt} withDurationFromNow />
              </FieldValue>
            </Stack>
          </AppPopoverContent>
        </AppPopover>
      )}
    </Box>
  );
}
