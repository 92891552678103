import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import SpotCreateUpdate from "@/common/components/Entity/Spot/SpotCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface SpotCreateUpdatePageQueryParams extends GeneralQueryParams {
  locationId?: string | null;
  productLocationId?: string | null;
}

export default function SpotCreateUpdatePage() {
  const { spotId } = useParams<{ spotId?: string }>();
  const queryParams = useQueryParams<SpotCreateUpdatePageQueryParams>();
  const history = useHistory();

  const isCreate = !spotId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new spot" : "Edit spot"} />}
    >
      <SpotCreateUpdate
        spotId={spotId}
        defaultValues={{
          ...queryParams,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.SPOT_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
