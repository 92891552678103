import { apiClient } from "@/core/api/ApiClient";
import { EntityType, SupplierDto } from "@/core/api/generated";

import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: SupplierDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function SuppliersDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.Supplier}
      entities={entities}
      deleteFunc={(params) => {
        apiClient.suppliersApi.apiV1SuppliersBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
          },
        });
      }}
      {...otherProps}
    />
  );
}
