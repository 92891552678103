import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import ServerBaseErrorModel from "@/common/models/api/ServerBaseErrorModel";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { InviteDto } from "@/core/api/generated";

export default function InviteExpiredCheck() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const generalQueryParams = useQueryParams<GeneralQueryParams>(); // can be passed outside of React app

  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const [isInviteInfoLoading, setIsInviteInfoLoading] = useState(true);
  const [isInviteNotFoundResponse, setIsInviteNotFoundResponse] = useState(false);
  const [inviteInfo, setInviteInfo] = useState<InviteDto | null>(null);

  const isNotFound = (!inviteId && !inviteInfo && !isInviteInfoLoading) || isInviteNotFoundResponse;

  useEffect(() => {
    (async () => {
      setIsInviteInfoLoading(true);
      console.log("Checking if invite expired...");

      try {
        const invite = await apiClient.invitesApi
          .apiV1InvitesInviteIdAnonymouslyGet({ inviteId: inviteId })
          .then((r) => r.data);

        if (invite.isExpired) {
          console.log("Redirect to invite expired...");
          history.push(
            ROUTE_PATH.AUTH_INVITE_EXPIRED(inviteId, {
              ...generalQueryParams,
            }),
          );
        } else {
          console.log("Redirect to invite start...");
          history.push(ROUTE_PATH.AUTH_INVITE_START(inviteId, { ...generalQueryParams }));
        }

        setInviteInfo(invite);
      } catch (err) {
        const baseError = err as ServerBaseErrorModel;
        if (baseError.status === 404) {
          setIsInviteNotFoundResponse(true);
        } else {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          validation2.hasErrors &&
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      } finally {
        setIsInviteInfoLoading(false);
      }
    })();
  }, []);

  if (isInviteInfoLoading) {
    return <LinearProgress />;
  }

  if (isNotFound) {
    console.log("Redirect to invite not found...");
    return (
      <Redirect
        to={ROUTE_PATH.AUTH_INVITE_NOT_FOUND(inviteId, {
          ...generalQueryParams,
        })}
      />
    );
  }

  // if (!!inviteInfo && !inviteInfo?.isExpired) {
  //   console.log("Redirect to invite enter email...");
  //   return (
  //     <Redirect
  //       to={ROUTE_PATH.AUTH_INVITE_ENTER_EMAIL(inviteId, {
  //         email: inviteInfo.userInfo?.email,
  //         ...generalQueryParams,
  //       })}
  //     />
  //   );
  // }

  return null;
}
