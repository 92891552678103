import { IconButton, Stack } from "@mui/material";
import { ReactNode } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import { AppPermission, GeneralStatusMetaDto, IBaseEntityDto } from "@/core/api/generated";

import GeneralStatusEnumValueInline from "./GeneralStatusEnumValueInline";
import GeneralStatusMetaDetailsPopover, {
  GeneralStatusMetaDetailsPopoverProps,
} from "./GeneralStatusMetaDetailsPopover";

export interface GeneralStatusMetaDisplayProps {
  value: GeneralStatusMetaDto | undefined | null;
  subjectEntity: IBaseEntityDto | undefined;
  withDetailsPopover?: boolean;
  detailsPopoverProps?: Partial<GeneralStatusMetaDetailsPopoverProps>;
  mainContent?: ReactNode;
  onMetaUpdated: (newValue: GeneralStatusMetaDto) => void;
}

export default function GeneralStatusMetaDisplay({
  value,
  subjectEntity,
  withDetailsPopover = true,
  detailsPopoverProps,
  mainContent,
  onMetaUpdated,
}: GeneralStatusMetaDisplayProps) {
  if (!value) {
    return null;
  }

  return (
    <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
      {mainContent || (
        <GeneralStatusEnumValueInline
          statusEnumTypeName={value.statusEnumTypeName}
          status={value.status}
        />
      )}

      {withDetailsPopover && (
        <AuthorizedElement permissions={[AppPermission.GeneralStatusHistoryRead]}>
          <GeneralStatusMetaDetailsPopover
            {...detailsPopoverProps}
            clickBehavior={{}}
            trigger={
              <AppTooltip title='View details'>
                <IconButton size='extraSmall' sx={{ ml: 0.25 }}>
                  <AppIcon of='details' />
                </IconButton>
              </AppTooltip>
            }
            value={value}
            subjectEntity={subjectEntity}
            onMetaUpdated={onMetaUpdated}
          />
        </AuthorizedElement>
      )}
    </Stack>
  );
}
