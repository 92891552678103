import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  ChatParticipantDto,
  ChatParticipantReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import ChatParticipantInline, { ChatParticipantInlineProps } from "./ChatParticipantInline";

interface Props extends BaseEntityLinkInheritableProps {
  chatId: string | null | undefined;
  entity: ChatParticipantDto | ChatParticipantReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<ChatParticipantInlineProps>;
}
export default function ChatParticipantLink({
  chatId,
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.ChatRead])}
      to={ROUTE_PATH.TENANT_REQUEST_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <ChatParticipantInline
          chatId={chatId}
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Chat participant"}
    />
  );
}
