import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function ChatIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6663 11.9159C14.1603 11.9159 13.7496 11.5052 13.7496 10.9992C13.7496 10.4932 14.1603 10.0826 14.6663 10.0826C15.1723 10.0826 15.583 10.4932 15.583 10.9992C15.583 11.5052 15.1723 11.9159 14.6663 11.9159ZM10.9996 11.9159C10.4936 11.9159 10.083 11.5052 10.083 10.9992C10.083 10.4932 10.4936 10.0826 10.9996 10.0826C11.5056 10.0826 11.9163 10.4932 11.9163 10.9992C11.9163 11.5052 11.5056 11.9159 10.9996 11.9159ZM7.33298 11.9159C6.82698 11.9159 6.41631 11.5052 6.41631 10.9992C6.41631 10.4932 6.82698 10.0826 7.33298 10.0826C7.83898 10.0826 8.24964 10.4932 8.24964 10.9992C8.24964 11.5052 7.83898 11.9159 7.33298 11.9159ZM17.4814 4.51749C15.3877 2.42382 12.4901 1.48974 9.53023 1.94716C5.79298 2.53016 2.69464 5.54049 1.99523 9.26857C1.65881 11.0634 1.85223 12.8912 2.55531 14.5532C2.64514 14.764 2.67264 14.962 2.63689 15.1389L1.85131 19.0696C1.79081 19.3702 1.88523 19.681 2.10156 19.8973C2.27481 20.0706 2.50856 20.1659 2.74964 20.1659C2.80923 20.1659 2.86973 20.1604 2.92931 20.1476L6.85539 19.3629C7.08089 19.3198 7.29998 19.3831 7.44573 19.4436C9.10856 20.1467 10.9364 20.3392 12.7303 20.0037C16.4584 19.3042 19.4687 16.2059 20.0517 12.4687C20.5128 9.50966 19.576 6.61207 17.4814 4.51749Z'
      />
      <mask
        id='mask0_1_466'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='1'
        width='20'
        height='20'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.6663 11.9159C14.1603 11.9159 13.7496 11.5052 13.7496 10.9992C13.7496 10.4932 14.1603 10.0826 14.6663 10.0826C15.1723 10.0826 15.583 10.4932 15.583 10.9992C15.583 11.5052 15.1723 11.9159 14.6663 11.9159ZM10.9996 11.9159C10.4936 11.9159 10.083 11.5052 10.083 10.9992C10.083 10.4932 10.4936 10.0826 10.9996 10.0826C11.5056 10.0826 11.9163 10.4932 11.9163 10.9992C11.9163 11.5052 11.5056 11.9159 10.9996 11.9159ZM7.33298 11.9159C6.82698 11.9159 6.41631 11.5052 6.41631 10.9992C6.41631 10.4932 6.82698 10.0826 7.33298 10.0826C7.83898 10.0826 8.24964 10.4932 8.24964 10.9992C8.24964 11.5052 7.83898 11.9159 7.33298 11.9159ZM17.4814 4.51749C15.3877 2.42382 12.4901 1.48974 9.53023 1.94716C5.79298 2.53016 2.69464 5.54049 1.99523 9.26857C1.65881 11.0634 1.85223 12.8912 2.55531 14.5532C2.64514 14.764 2.67264 14.962 2.63689 15.1389L1.85131 19.0696C1.79081 19.3702 1.88523 19.681 2.10156 19.8973C2.27481 20.0706 2.50856 20.1659 2.74964 20.1659C2.80923 20.1659 2.86973 20.1604 2.92931 20.1476L6.85539 19.3629C7.08089 19.3198 7.29998 19.3831 7.44573 19.4436C9.10856 20.1467 10.9364 20.3392 12.7303 20.0037C16.4584 19.3042 19.4687 16.2059 20.0517 12.4687C20.5128 9.50966 19.576 6.61207 17.4814 4.51749Z'
        />
      </mask>
      <g mask='url(#mask0_1_466)'>
        <rect width='22' height='22' />
      </g>
    </SvgIcon>
  );
});
