import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { PaginationDtoOfChatActivityDto } from "@/core/api/generated";

import { AppThunk } from "..";

export interface ChatActivityState {
  loading: {
    getPaginated?: boolean;
  };
  paginatedActivities?: PaginationDtoOfChatActivityDto;
}

const initialState: ChatActivityState = {
  loading: {},
};

const chatActivitySlice = createSlice({
  name: "chatActivity",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatActivityState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _chatActivitiesFetched: (state, action: PayloadAction<PaginationDtoOfChatActivityDto>) => {
      state.paginatedActivities = action.payload;
    },
    resetChatActivities: (state, action: PayloadAction<undefined>) => {
      state.paginatedActivities = undefined;
    },
  },
});

export const { _chatActivitiesFetched, setLoading, resetChatActivities } =
  chatActivitySlice.actions;
export const chatActivityReducer = chatActivitySlice.reducer;

export const getChatActivities =
  (
    ...arg: Parameters<typeof apiClient.chatActivityApi.apiV1ChatActivityByChatChatIdGetPost>
  ): AppThunk<Promise<PaginationDtoOfChatActivityDto>> =>
  async (dispatch) => {
    const response = await apiClient.chatActivityApi.apiV1ChatActivityByChatChatIdGetPost(...arg);
    dispatch(_chatActivitiesFetched(response.data));
    return response.data;
  };
