import { DependencyList, useEffect, useRef } from "react";

type CallbackType = () => void | Promise<void>;

/** Executes given callback on unmount.
 *  Re-saves callback (without calling it) when deps array change.
 *  NB: callback might be executed multiple times.
 */
export const useUnmountEffect = (callback: CallbackType, deps?: DependencyList): void => {
  const callbackRef = useRef<CallbackType | undefined>(undefined);

  // listen for deps
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback, ...(deps || [])]);

  // execute
  useEffect(() => {
    return () => {
      callbackRef.current && callbackRef.current();
    };
  }, []);
};
