import { Divider, Typography } from "@mui/material";

interface Props {
  dividerColor?: string;
  textColor?: string;
  textAlign?: "center" | "right" | "left";
  children?: React.ReactNode;
}

function ChatHistoryDivider({
  dividerColor = "divider",
  textColor = "text.secondary",
  textAlign = "center",
  children,
}: Props) {
  return (
    <Divider
      sx={{
        borderColor: dividerColor,
        "&::before, &::after": {
          borderColor: dividerColor,
          flex: 1,
        },
        ".MuiDivider-wrapper": {
          maxWidth: "80%",
          whiteSpace: "normal",
        },
      }}
      textAlign={textAlign}
    >
      <Typography
        component='span'
        variant='caption'
        color={textColor}
        sx={{ display: "inline-block" }}
      >
        {children}
      </Typography>
    </Divider>
  );
}

export default ChatHistoryDivider;
