import { Box } from "@mui/material";
import { useEffect } from "react";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import supportedBrowsers from "@/supportedBrowsers";

function BrowserNotSupportedChecker() {
  const { enqueueSnackbar } = useAppSnackbar();

  useEffect(() => {
    if (!supportedBrowsers.test(navigator.userAgent)) {
      enqueueSnackbar(
        <Box>
          <Box textAlign={"center"}>Your browser is not fully supported.</Box>
          <Box>
            Your browser isn&#39;t supported by the application. You may experience issues working
            with the application in this browser. You can try using the latest version of any
            popular browser.
          </Box>
        </Box>,
        {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        },
      );
    }
  }, []);

  return <></>;
}

export default BrowserNotSupportedChecker;
