import GroupIcon from "@mui/icons-material/Group";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  FormHelperText,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ChatEventsModal from "@/common/components/Entity/ChatEvent/ChatEventsModal";
import ChatParticipantsModal from "@/common/components/Entity/ChatParticipant/ChatParticipantsModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useChatContext } from "@/common/contexts/chat";
import { TextHelper } from "@/common/helpers/text";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import {
  AppPermission,
  ChatAcknowledgeResultDto,
  ChatDto,
  ChatParticipantDto,
  ChatType,
  TagEntityType,
} from "@/core/api/generated";
import * as chatParticipantsSlice from "@/store/communication/chatParticipantsSlice";
import * as chatsSlice from "@/store/communication/chatsSlice";

import AppVisibilitySensor from "../../AppVisibilitySensor";
import AuthorizedElement from "../../Auth/AuthorizedElement";
import RealtimeChatConnectionStatusIndicator from "../../Connection/RealtimeChatConnectionStatusIndicator";
import ChatFileUploader from "../../Files/ChatFileUploader";
import AppIcon from "../../Icons/AppIcon";
import ChatTypeIcon from "../../Icons/ChatTypeIcon";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import ChatActivitiesModal from "../ChatActivity/ChatActivitiesModal";
import ChatHistory, { ChatHistoryProps } from "../ChatHistory/ChatHistory";
import ChatMessageSender from "../ChatMessage/ChatMessageSender";
import UpdateChatUserSettingsModal from "../ChatUserSettings/UpdateChatUserSettingsModal";
import GeneralAttachedTagsDisplay from "../General/GeneralTag/GeneralAttachedTagsDisplay";
import GeneralAttachedTagsOfEntityEditModal from "../General/GeneralTag/GeneralAttachedTagsOfEntityEditModal";
import ChatInteractionTracker from "./ChatInteractionTracker";
import ChatNegotiationsModal from "./ChatNegotiation/ChatNegotiationsModal";
import ChatPinnedNegotiations from "./ChatNegotiation/ChatPinnedNegotiations";
import ChatPinnedItems from "./ChatPinnedItems";
import UpdateChatModal from "./UpdateChatModal";

const defaultDisplayProps = {
  contentHeader: false,
  contentFolding: false,
};

export type ChatDisplayProps = Partial<typeof defaultDisplayProps>;

export type ChatSize = "small" | "medium";

interface OwnProps {
  allowPin?: boolean;
  allowClose?: boolean;
  allowLeave?: boolean;
  size?: ChatSize;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxHeight?: string | number;
  chatHistoryProps?: ChatHistoryProps;
  displayProps?: ChatDisplayProps;
  onPin?: (chat: ChatDto) => void;
  onClose?: (chat: ChatDto) => void;
}

interface Props extends OwnProps {
  chat: ChatDto;
  currentParticipant?: ChatParticipantDto;
  participants?: ChatParticipantDto[];
  hasPinnedItems?: boolean;
  resolveChat: (...arg: Parameters<typeof chatsSlice.resolveChat>) => Promise<ChatDto>;
  reopenChat: (...arg: Parameters<typeof chatsSlice.reopenChat>) => Promise<ChatDto>;
  acknowledgeChat: (
    ...arg: Parameters<typeof chatsSlice.acknowledgeChat>
  ) => Promise<ChatAcknowledgeResultDto>;
  leaveChat: (
    ...arg: Parameters<typeof chatParticipantsSlice.leaveChat>
  ) => Promise<ChatParticipantDto>;
}

function ChatDisplay({
  chat,
  currentParticipant,
  participants,
  hasPinnedItems,
  resolveChat,
  reopenChat,
  acknowledgeChat,
  leaveChat,
  allowPin,
  allowClose,
  allowLeave,
  size = "medium",
  fullWidth,
  fullHeight,
  maxHeight,
  chatHistoryProps,
  displayProps = defaultDisplayProps,
  onPin,
  onClose,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const isNegotiationChat = chat.type === ChatType.Negotiation;

  const { enqueueSnackbar } = useAppSnackbar();
  const chatContext = useChatContext();

  const [chatValidation, setChatValidation] = useState<ValidationInfo>(new ValidationInfo());
  const [isResolveLoading, setIsResolveLoading] = useState(false);

  const topAnchorRef = useRef<HTMLElement | null>(null);
  const bottomAnchorRef = useRef(null);
  const chatStartAnchorRef = useRef<HTMLElement | null>(null);
  const chatEndAnchorRef = useRef<HTMLElement | null>(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isChatContentFolded, setIsChatContentFolded] = useState(false);
  const [isChatStartAnchorVisible, setIsChatStartAnchorVisible] = useState(false);
  const [isChatEndAnchorVisible, setIsChatEndAnchorVisible] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isChatEventsModalOpen, setIsChatEventsModalOpen] = useState(false);
  const [isChatActivitiesModalOpen, setIsChatActivitiesModalOpen] = useState(false);
  const [isViewParticipantsModalOpen, setIsViewParticipantsModalOpen] = useState(false);
  const [isManageParticipantsModalOpen, setIsManageParticipantsModalOpen] = useState(false);
  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(false);
  const [isViewNegotiationsModalOpen, setIsViewNegotiationsModalOpen] = useState(false);
  const [isLeaveChatConfirmationModalOpen, setIsLeaveChatConfirmationModalOpen] = useState(false);
  const [isChatUserSettingsModalOpen, setIsChatUserSettingsModalOpen] = useState(false);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleUpdate = useCallback(() => {
    setIsUpdateModalOpen(true);
    handleMenuClose();
  }, []);

  const handleViewParticipants = useCallback(() => {
    setIsViewParticipantsModalOpen(true);
    handleMenuClose();
  }, []);

  const handleManageParticipants = useCallback(() => {
    setIsManageParticipantsModalOpen(true);
    handleMenuClose();
  }, []);

  const handleResolve = useCallback(async () => {
    try {
      setIsResolveLoading(true);
      await resolveChat({ nexusOpsTenant: EMPTY_TENANT_IDENTIFIER, chatId: chat.id! });
      setChatValidation(new ValidationInfo());
      onClose && onClose(chat);
    } catch (err) {
      setChatValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      setIsResolveLoading(false);
      handleMenuClose();
    }
  }, [chat, resolveChat]);

  const handleReopen = useCallback(async () => {
    try {
      setIsResolveLoading(true);
      await reopenChat({ nexusOpsTenant: EMPTY_TENANT_IDENTIFIER, chatId: chat.id! });
      setChatValidation(new ValidationInfo());
    } catch (err) {
      setChatValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      setIsResolveLoading(false);
      handleMenuClose();
    }
  }, [chat, resolveChat]);

  const handleAcknowledge = useCallback(async () => {
    try {
      await acknowledgeChat({ nexusOpsTenant: EMPTY_TENANT_IDENTIFIER, chatId: chat.id! });
      setChatValidation(new ValidationInfo());
    } catch (err) {
      setChatValidation(ValidationHelper.handleApiErrorResponse(err));
    } finally {
      handleMenuClose();
    }
  }, [chat, acknowledgeChat]);

  const handlePin = () => {
    onPin && onPin(chat);
    handleMenuClose();
  };

  const handleViewEventsHistory = () => {
    setIsChatEventsModalOpen(true);
    handleMenuClose();
  };

  const handleViewActivities = () => {
    setIsChatActivitiesModalOpen(true);
    handleMenuClose();
  };

  const handleClose = () => {
    onClose && onClose(chat);
    handleMenuClose();
  };

  const handleLeave = useCallback(async () => {
    setIsLeaveChatConfirmationModalOpen(true);
  }, []);

  const handleLeaveConfirmed = useCallback(async () => {
    try {
      await leaveChat({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId: chat.id!,
      });
      enqueueSnackbar("You left the chat.", { variant: "success" });
      setIsLeaveChatConfirmationModalOpen(false);
    } catch (err) {
      const newValidation = ValidationHelper.handleApiErrorResponse(err);
      setChatValidation(newValidation);
      if (newValidation.generalError) {
        enqueueSnackbar(newValidation.generalError, { variant: "error" });
      }
    } finally {
      onClose && onClose(chat);
      handleMenuClose();
    }
  }, [chat, acknowledgeChat]);

  const handleViewNegotiations = () => {
    setIsViewNegotiationsModalOpen(true);
    handleMenuClose();
  };

  const maxWidthValue = fullWidth ? "100%" : "400px";
  const maxHeightValue = maxHeight || undefined;

  return (
    <Box
      sx={{
        maxWidth: maxWidthValue,
        maxHeight: maxHeightValue,
      }}
    >
      <ChatInteractionTracker chatId={chat.id!}>
        <Card
          sx={{
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            maxWidth: maxWidthValue,
            maxHeight: maxHeightValue,
          }}
        >
          <AppVisibilitySensor
            onChange={(isVisible) => {
              if (isVisible !== isChatStartAnchorVisible) {
                setIsChatStartAnchorVisible(isVisible);
              }
            }}
          >
            {(observableProps) => <Box {...observableProps} ref={chatStartAnchorRef}></Box>}
          </AppVisibilitySensor>

          <CardHeader
            sx={{ py: 1, px: 2 }}
            action={
              <Box>
                {chat.settings!.allowResolution && (
                  <>
                    {!chat.isResolved && (
                      <LoadingButton
                        variant='text'
                        color='text'
                        loading={isResolveLoading}
                        onClick={handleResolve}
                      >
                        Resolve
                      </LoadingButton>
                    )}

                    {chat.isResolved && (
                      <LoadingButton
                        variant='text'
                        color='text'
                        loading={isResolveLoading}
                        onClick={handleReopen}
                      >
                        Re-open
                      </LoadingButton>
                    )}
                  </>
                )}

                {/* Chat actions */}
                <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
                  <AppIcon of='moreVert' />
                </IconButton>
                <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                  <ListItem sx={{ pt: 0 }}>
                    <ListItemText
                      sx={{ m: 0, p: 0 }}
                      primary={
                        <Typography
                          component='div'
                          variant='body2'
                          color='text.secondary'
                          sx={{ m: 0, p: 0 }}
                        >
                          Chat
                        </Typography>
                      }
                    />
                  </ListItem>
                  <MenuItem
                    dense
                    component={RouterLink}
                    to={ROUTE_PATH.CHAT_VIEW(chat.id, { chatType: chat.type })}
                    target='_blank'
                  >
                    <ListItemIcon>
                      <AppIcon of='openInNew' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Open in new tab</ListItemText>
                  </MenuItem>
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    {chat.settings!.allowEdit && (
                      <MenuItem dense onClick={handleUpdate}>
                        <ListItemIcon>
                          <AppIcon of='edit' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </MenuItem>
                    )}
                  </AuthorizedElement>
                  {!chat.settings!.allowParticipantManagement && (
                    <MenuItem dense onClick={handleViewParticipants}>
                      <ListItemIcon>
                        <GroupIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Participants</ListItemText>
                    </MenuItem>
                  )}
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    {chat.settings!.allowParticipantManagement && (
                      <MenuItem dense onClick={handleManageParticipants}>
                        <ListItemIcon>
                          <GroupAddIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Participants</ListItemText>
                      </MenuItem>
                    )}
                  </AuthorizedElement>
                  {chat.settings!.allowAcknowledgement && (
                    <MenuItem dense onClick={handleAcknowledge}>
                      <ListItemIcon>
                        <AppIcon of='acknowledge' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Acknowledge</ListItemText>
                    </MenuItem>
                  )}
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <MenuItem
                      dense
                      onClick={() => {
                        setIsEditTagsModalOpen(true);
                        handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='tag' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit tags</ListItemText>
                    </MenuItem>
                  </AuthorizedElement>
                  {allowPin && (
                    <MenuItem dense onClick={handlePin}>
                      <ListItemIcon>
                        <AppIcon of='pin' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Pin</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem dense onClick={handleViewEventsHistory}>
                    <ListItemIcon>
                      <AppIcon of='history' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Event history</ListItemText>
                  </MenuItem>
                  <MenuItem dense onClick={handleViewActivities}>
                    <ListItemIcon>
                      <AppIcon of='history' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Chat activity</ListItemText>
                  </MenuItem>
                  <MenuItem dense onClick={() => setIsChatUserSettingsModalOpen(true)}>
                    <ListItemIcon>
                      <AppIcon of='settings' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>My chat settings</ListItemText>
                  </MenuItem>
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    {allowClose && (
                      <MenuItem dense onClick={handleClose}>
                        <ListItemIcon>
                          <AppIcon of='close' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Close</ListItemText>
                      </MenuItem>
                    )}
                  </AuthorizedElement>

                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    {isNegotiationChat && <Divider />}
                    {isNegotiationChat && (
                      <ListItem sx={{ pt: 0 }}>
                        <ListItemText
                          sx={{ m: 0, p: 0 }}
                          primary={
                            <Typography
                              component='div'
                              variant='body2'
                              color='text.secondary'
                              sx={{ m: 0, p: 0 }}
                            >
                              Negotiations
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    {isNegotiationChat && (
                      <MenuItem dense onClick={handleViewNegotiations}>
                        <ListItemIcon>
                          <AppIcon of='negotiation' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>View negotiations</ListItemText>
                      </MenuItem>
                    )}
                  </AuthorizedElement>

                  {allowLeave && chat.settings?.allowLeave && <Divider />}
                  {allowLeave && chat.settings?.allowLeave && (
                    <MenuItem dense onClick={handleLeave}>
                      <ListItemIcon>
                        <AppIcon of='leave' fontSize='small' color='error' />
                      </ListItemIcon>
                      <ListItemText sx={{ color: (theme) => theme.palette.error.main }}>
                        Leave
                      </ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            }
            title={
              <Typography component='div' variant='subtitle1'>
                <Box component='span'>{chat.name}</Box>{" "}
                <Typography component='span' variant='body2' color='text.secondary' sx={{ ml: 1 }}>
                  <ChatTypeIcon chatType={chat.type} withTooltip inText />
                </Typography>
              </Typography>
            }
            subheader={
              <Typography component='div' variant='body2' color='text.secondary'>
                <Box>{chat.description}</Box>

                <Box sx={{ cursor: "pointer" }} onClick={handleViewParticipants}>
                  {participants?.length || 0}{" "}
                  {TextHelper.pluralizeManual(
                    "participant",
                    participants?.length || 0,
                    "participants",
                  )}
                </Box>

                {/* Tags */}
                {chat.tags && (
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <GeneralAttachedTagsDisplay tags={chat.tags} defaultIsFolded />
                  </AuthorizedElement>
                )}
              </Typography>
            }
          />
          {chatValidation.generalError && (
            <FormHelperText error={!!chatValidation.generalError} sx={{ py: 1, px: 2 }}>
              {chatValidation.generalError}
            </FormHelperText>
          )}
          <Divider />

          {/* Pinned items */}
          {hasPinnedItems && (
            <>
              <CardContent sx={{ py: 0.5 }}>
                <ChatPinnedItems chat={chat} participants={participants} />
              </CardContent>
              <Divider />
            </>
          )}

          {/* Pinned negotiations */}
          {chat.type === ChatType.Negotiation && chat.settings!.isPinNegotiations && (
            <>
              <CardContent sx={{ py: 1 }}>
                <ChatPinnedNegotiations chat={chat} />
              </CardContent>
              <Divider />
            </>
          )}

          {/* Chat main content */}
          <ChatFileUploader chatId={chat.id!}>
            {({
              uploadedFiles,
              renderUploadButton,
              renderUploadList,
              handlePastedFiles,
              getUploadedFiles,
              clearUploads,
            }) => (
              <>
                {/* Content header */}
                {displayProps?.contentHeader && (
                  <CardContent sx={{ flex: 1 }}>
                    <Stack direction='row' spacing={2}>
                      <Box>
                        {displayProps?.contentFolding && (
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => setIsChatContentFolded(!isChatContentFolded)}
                          >
                            <AppIcon
                              of={isChatContentFolded ? "expandMore" : "expandLess"}
                              fontSize='small'
                            />
                          </IconButton>
                        )}{" "}
                        <Typography component='span' variant='subtitle1'>
                          Chat
                        </Typography>
                      </Box>

                      <Stack
                        direction='row'
                        spacing={1}
                        sx={{ flex: 1, justifyContent: "flex-end" }}
                      >
                        <Tooltip title='My chat settings'>
                          <IconButton
                            size='small'
                            onClick={() => setIsChatUserSettingsModalOpen(true)}
                          >
                            <AppIcon of='settings' />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>

                    <Fade in={!isChatContentFolded} unmountOnExit>
                      <Divider sx={{ mt: 1 }} />
                    </Fade>
                    <Box></Box>
                  </CardContent>
                )}

                <Fade in={!isChatContentFolded} unmountOnExit>
                  <Box>
                    {/* Chat history */}
                    <CardContent sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          maxHeight: fullHeight ? "100%" : "400px",
                          display: "flex",
                        }}
                      >
                        <Box ref={topAnchorRef}></Box>
                        <ChatHistory
                          chat={chat}
                          participants={participants}
                          {...chatHistoryProps}
                        />
                        <Box ref={bottomAnchorRef}></Box>
                      </Box>
                    </CardContent>

                    <RealtimeChatConnectionStatusIndicator />

                    {/* Message sender */}
                    {/* <Divider /> */}
                    <Box sx={{ py: 1.5, px: 2, backgroundColor: "transparent" }}>
                      <ChatMessageSender
                        chat={chat}
                        uploadedAttachments={uploadedFiles}
                        size={size}
                        secondaryActions={[renderUploadButton()]}
                        renderAttachments={() => renderUploadList()}
                        onPasteFiles={handlePastedFiles}
                        getUploadedAttachments={getUploadedFiles}
                        clearUploadedAttachments={clearUploads}
                      />
                      <Box></Box>
                    </Box>
                  </Box>
                </Fade>
              </>
            )}
          </ChatFileUploader>

          <AppVisibilitySensor
            onChange={(isVisible) => {
              if (isVisible !== isChatEndAnchorVisible) {
                setIsChatEndAnchorVisible(isVisible);
              }
            }}
          >
            {(observableProps) => <Box {...observableProps} ref={chatEndAnchorRef}></Box>}
          </AppVisibilitySensor>

          <Box></Box>
        </Card>
      </ChatInteractionTracker>

      {/* Scroll to chat button */}
      {!isChatEndAnchorVisible && (
        <Button
          variant='contained'
          color='text'
          startIcon={<AppIcon of='arrowDownward' />}
          sx={{
            position: "fixed",
            bottom: "5%",
            left: "50%",
            transform: "translateX(-50%)",
            opacity: 0.8,
            boxShadow: (th) => th.shadows[7],
          }}
          onClick={() => chatContext.requestScrollToMessageInputAndFocus()}
        >
          Scroll to the chat
        </Button>
      )}

      {/* Update chat */}
      <UpdateChatModal
        open={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        onUpdated={() => setIsUpdateModalOpen(false)}
        chat={chat}
      />

      {/* Chat event history */}
      {chat && (
        <ChatEventsModal
          open={isChatEventsModalOpen}
          onClose={() => setIsChatEventsModalOpen(false)}
          chatId={chat.id!}
        />
      )}

      {chat && (
        <ChatActivitiesModal
          open={isChatActivitiesModalOpen}
          onClose={() => setIsChatActivitiesModalOpen(false)}
          chatId={chat.id!}
        />
      )}

      {/* View participants */}
      <ChatParticipantsModal
        open={isViewParticipantsModalOpen}
        onClose={() => setIsViewParticipantsModalOpen(false)}
        onUpdated={() => setIsViewParticipantsModalOpen(false)}
        chat={chat}
        allowManage={false}
      />

      {/* Manage participants */}
      <ChatParticipantsModal
        open={isManageParticipantsModalOpen}
        onClose={() => setIsManageParticipantsModalOpen(false)}
        onUpdated={() => setIsManageParticipantsModalOpen(false)}
        chat={chat}
        allowManage
      />

      {/* Edit tags */}
      <GeneralAttachedTagsOfEntityEditModal
        open={isEditTagsModalOpen}
        onClose={() => setIsEditTagsModalOpen(false)}
        entityType={TagEntityType.Chat}
        entityId={chat.id!}
      />

      {/* Update chat user settings */}
      {isChatUserSettingsModalOpen && (
        <UpdateChatUserSettingsModal
          open={isChatUserSettingsModalOpen}
          onClose={() => setIsChatUserSettingsModalOpen(false)}
          updateChatUserSettingsProps={{
            chatId: chat.id!,
            onSave: () => setIsChatUserSettingsModalOpen(false),
          }}
        />
      )}

      {/* Negotiations */}
      {chat && (
        <ChatNegotiationsModal
          chatId={chat.id!}
          open={isViewNegotiationsModalOpen}
          onClose={() => setIsViewNegotiationsModalOpen(false)}
        />
      )}

      {/* Chat leave confirmation */}
      <ConfirmationModal
        title='Leave the chat?'
        body={
          <>
            {`You're going to leave the chat `}
            {chat.name ? (
              <Typography component='span' fontWeight='bold'>
                {chat.name}
              </Typography>
            ) : (
              ""
            )}
            . <Typography component='span'>Please confirm the action.</Typography>
          </>
        }
        open={isLeaveChatConfirmationModalOpen}
        onClose={() => setIsLeaveChatConfirmationModalOpen(false)}
        onCancel={() => setIsLeaveChatConfirmationModalOpen(false)}
        onConfirm={handleLeaveConfirmed}
      />
    </Box>
  );
}

export default ChatDisplay;
