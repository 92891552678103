import { Box, Card, CardActionArea, CardContent, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission } from "@/core/api/generated";
import { ResponsiveStyleValueCustom } from "@/theme/types";

import AppLink from "../Link/AppLink";

interface OwnProps {
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  childrenLinks?: Array<{
    icon?: ReactNode;
    title: ReactNode;
    description?: ReactNode;
    to: string;
    permissions?: AppPermission[];
  }>;
}

type Props = OwnProps;

/** Unified content for index pages of route category.
 * E.g. /operations is category, sub routes /operations/a, /operations/b, /operations/c
 */
export default function RouteCategoryIndex({ direction = "row", childrenLinks }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <Box>
      <Stack
        direction={direction}
        spacing={1}
        sx={{ ...(direction === "row" && { flexWrap: "wrap" }) }}
      >
        {childrenLinks
          ?.filter((x) => (!_.isEmpty(x.permissions) ? hasPermissions(x.permissions!) : true))
          ?.map((x, i) => (
            <Box key={i} sx={{ flex: 1, ...(direction === "row" && { minWidth: 250 }) }}>
              <Card key={i} sx={{ ...(direction === "row" && { mb: 1 }) }}>
                <CardActionArea component={AppLink} to={x.to}>
                  <CardContent>
                    <Stack
                      direction={direction === "row" ? "column" : "row"}
                      spacing={1}
                      sx={{
                        ...(direction === "row" && {
                          justifyContent: "center",
                          alignItems: "center",
                        }),
                        ...(direction === "column" && {
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }),
                      }}
                    >
                      {x.icon && <Box sx={{ display: "flex", fontSize: "2rem" }}>{x.icon}</Box>}

                      <Typography component='div' variant='h6'>
                        {x.title}
                      </Typography>

                      {x.description && (
                        <Typography component='div' variant='body2' color='text.secondary'>
                          {x.description}
                        </Typography>
                      )}
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          ))}
      </Stack>
    </Box>
  );
}
