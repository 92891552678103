import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useMemo } from "react";

import CustomerContactCard from "@/common/components/Entity/Customer/CustomerContactCard";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CustomerDto, InviteCustomerAsUserDto } from "@/core/api/generated";

interface OwnProps {
  customer: CustomerDto;
  onSaved?: () => void;
}

type Props = OwnProps & DialogProps;

export default function InviteCustomerAsUserModal({ customer, onSaved, ...dialogProps }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const modalOpenStatus = useModalOpenStatus(dialogProps);

  const formik = useFormik<BaseFormikValues & InviteCustomerAsUserDto>({
    enableReinitialize: true,
    initialValues: {
      contactIds: [],
      submit: "",
    },
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting }) => {
      try {
        await apiClient.tenantCustomerInvitesApi.apiV1CustomersCustomerIdInvitesPost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          customerId: customer.id!,
          inviteCustomerAsUserDto: {
            contactIds: values.contactIds,
          },
        });
        enqueueSnackbar("Customer invited.", {
          variant: "success",
        });

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }

        onSaved && onSaved();
        dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
      } catch (err) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }

      setSubmitting(false);
    },
  });

  const { values, errors, setFieldValue, handleSubmit, isSubmitting } = formik;

  const canSubmit = useMemo(
    () => values?.contactIds?.length !== 0 && !!customer?.id,
    [values.contactIds, customer],
  );

  useEffect(() => {
    if (modalOpenStatus.isClosing) {
      setFieldValue("contactIds", []);
    }
  }, [modalOpenStatus]);

  return (
    <Dialog {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Invite the customer to become a user in the system
      </AppModalTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Select contacts that receive invite link.
        </DialogContentText>

        <Box>
          <form noValidate onSubmit={handleSubmit}>
            <Stack direction='column' spacing={2}>
              {customer.contacts?.map((contact, index) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.contactIds?.includes(contact.id!) || false}
                        onChange={(e) => {
                          setFieldValue(
                            "contactIds",
                            e.target.checked
                              ? [...(values.contactIds || []), contact.id!]
                              : (values.contactIds || []).filter((x) => x !== contact.id!),
                          );
                        }}
                      />
                    }
                    label={
                      <CustomerContactCard
                        contact={contact}
                        sx={{ width: "100%", minWidth: 300 }}
                      />
                    }
                  />
                </FormGroup>
              ))}
            </Stack>
            <FormHelperText error={Boolean(errors.contactIds)}>
              {_.isString(errors.contactIds) && errors.contactIds}
            </FormHelperText>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              sx={{ flex: 1, mt: 2 }}
              fullWidth
              color='primary'
              loading={isSubmitting}
              disabled={!canSubmit}
              type='submit'
              variant='contained'
            >
              Invite
            </LoadingButton>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
