import { Box, SxProps, Theme } from "@mui/material";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { ApiEnumName, ApiEnumValue } from "@/common/services/enum";

export interface GeneralStatusDisplayProps {
  statusEnumTypeName: Nil<string>;
  status: Nil<string>;
  sx?: SxProps<Theme>;
}

export default function GeneralStatusEnumValueInline({
  statusEnumTypeName,
  status,
  sx,
}: GeneralStatusDisplayProps) {
  if (!statusEnumTypeName || !status) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      <InlineApiEnumValue
        type={statusEnumTypeName as ApiEnumName}
        value={status as ApiEnumValue<ApiEnumName>}
        withHelperTooltip
      />
    </Box>
  );
}
