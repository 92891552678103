import { Box } from "@mui/material";

import { TypeHelper } from "@/common/helpers/type";
import { GeneralAttachmentDto } from "@/core/api/generated";

import AttachmentsBriefInfo from "../../Files/AttachmentsBriefInfo";

export interface Props {
  attachments: GeneralAttachmentDto[] | undefined | null;
}

export default function TableCellContentOfAttachments({ attachments }: Props) {
  return (
    <Box>
      {TypeHelper.isEmpty(attachments) ? (
        "-"
      ) : (
        <AttachmentsBriefInfo attachments={attachments || []} />
      )}
    </Box>
  );
}
