import { Box, Theme } from "@mui/material";
import { useState } from "react";

import { EntityChangedByInfoFromDto } from "@/common/ts/dto";

import EntityChangedByPopover from "./EntityChangedByPopover";

type Entity = EntityChangedByInfoFromDto & Record<string, any>;

export interface OwnProps {
  entity: Entity | null | undefined;
}

type Props = OwnProps;

/** Shows who changed the entity (created, updated, deleted). */
export default function InlineEntityChangedByInfoDisplay({ entity, ...popoverProps }: Props) {
  const hasAnyInfo =
    entity && (entity.createdAt || entity.createdBy || entity.updatedAt || entity.updatedBy);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Box component='span'>
      {!hasAnyInfo && "-"}
      {hasAnyInfo && (
        <>
          <Box
            sx={{
              textDecoration: "underline dotted 2px",
              textDecorationColor: (th: Theme) => th.palette.divider,
            }}
            component='span'
            onMouseEnter={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
              setAnchorEl(e.currentTarget)
            }
            onMouseLeave={() => setAnchorEl(null)}
          >
            {/* <AppIcon of='view' /> */}
            View
          </Box>

          {/* On hover popover */}
          <EntityChangedByPopover
            entity={entity}
            sx={{
              pointerEvents: "none",
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => setAnchorEl(null)}
            disableRestoreFocus
          />
        </>
      )}
    </Box>
  );
}
