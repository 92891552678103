import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import IntegrationApiClientCreateUpdateKey, {
  IntegrationApiClientCreateUpdateKeyProps,
} from "./IntegrationApiClientCreateUpdateKey";

export interface OwnProps {
  createUpdateProps: IntegrationApiClientCreateUpdateKeyProps;
}

type Props = OwnProps & DialogProps;

export default function IntegrationApiClientCreateUpdateKeyModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.clientKey?.id;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new " : "Edit "} API Key
      </AppModalTitle>
      <DialogContent>
        <Stack sx={{ py: 1 }} spacing={1}>
          <IntegrationApiClientCreateUpdateKey {...createUpdateProps} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
