import { useEffect } from "react";

import {
  EventKey,
  EventMap,
  EventReceiver,
  ITypedEventEmitter,
} from "../eventEmmiters/typedEventEmitter";

/** Simplifies on/off for specific event and handler.
 *  Resubscribes only on eventName change, handler changes are ignored.
 */
export const useEventEmitterSubscription = <
  TEventMap extends EventMap,
  TEventKey extends EventKey<TEventMap>,
>(
  eventEmitter: ITypedEventEmitter<TEventMap>,
  eventName: TEventKey,
  handler: EventReceiver<TEventMap[TEventKey]>,
): void => {
  useEffect(() => {
    const handlerLocal = handler;
    eventEmitter.on(eventName, handlerLocal);

    return () => {
      eventEmitter.off(eventName, handlerLocal);
    };
  }, [eventName]);
};
