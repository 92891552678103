import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { UserDto, UserSearchPaginatedDto } from "@/core/api/generated";

export type UserAutocompleteOption = BaseAutocompleteOption<UserDto>;

export const getUserOptionTitle = (data: UserDto): string =>
  StringHelper.joinIntoString([data.personName?.name || data.email], ", ", {
    skipEmpty: true,
  });

export const userToOption = (data: UserDto): UserAutocompleteOption => ({
  id: data.id!,
  title: getUserOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface UserAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<UserDto> {
  searchFilters?: Partial<Omit<UserSearchPaginatedDto, "search" | "includeIds">>;
}

export default function UserAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: UserAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={userToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.usersApi.apiV1UsersSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          userSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          userSearchPaginatedDto: {
            ...params.userSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='User'
      placeholder='Search for user...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='user' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  {option.title}
                </Typography>
              }
              secondary={
                option.data && (
                  <Typography component='div' variant='body2'>
                    {option.data?.email} / {option.data?.phoneNumber}
                  </Typography>
                )
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
