import { useParams } from "react-router-dom";

import AssessmentFlowView from "@/common/components/Entity/AssessmentFlow/View/AssessmentFlowView";

export default function AssessmentFlowViewPage() {
  const { assessmentFlowId } = useParams<{
    assessmentFlowId?: string;
  }>();

  return (
    <>
      <AssessmentFlowView assessmentFlowId={assessmentFlowId} assessmentFlow={undefined} />
    </>
  );
}
