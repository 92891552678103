import { Box, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactElement | string;
}

export default function BaseContactCard({ sx, children }: Props): JSX.Element {
  return (
    <Box
      sx={{
        width: "fit-content",
        p: 1.5,
        borderRadius: 8,
        bgcolor: "rgba(173, 223, 255, 0.2)",
        ...sx,
      }}
    >
      {children && children}
    </Box>
  );
}
