import { Chip, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { TenantConnectionDto } from "@/core/api/generated";

import TenantInline from "../../../Tenant/TenantInline";

interface Props {
  tenantConnection: TenantConnectionDto;
}

export default function OverviewTabContent({ tenantConnection }: Props) {
  const currentTenant = useCurrentTenant();

  return (
    <PageTabContent>
      <ViewLayoutV2 displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <ViewContentBlock>
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={tenantConnection} />}
          />
          <FieldValue label='Number'>{tenantConnection?.globalNumber}</FieldValue>

          <FieldValue label='Company 1'>
            <TenantInline entity={tenantConnection?.connectedTenant1} />{" "}
            {currentTenant?.id === tenantConnection?.connectedTenant1?.id && (
              <Chip size='small' variant='outlined' color='default' label='You' />
            )}
          </FieldValue>

          <FieldValue label='Company 2'>
            <TenantInline entity={tenantConnection?.connectedTenant2} />{" "}
            {currentTenant?.id === tenantConnection?.connectedTenant2?.id && (
              <Chip size='small' variant='outlined' color='default' label='You' />
            )}
          </FieldValue>

          <FieldValue label='Status'>
            <InlineApiEnumValue
              type='TenantConnectionStatus'
              value={tenantConnection?.status}
              direction='column'
              withDescription
            />
          </FieldValue>

          <FieldValue label='Connection request'>
            <AppLink to={ROUTE_PATH.TENANT_CONNECTION_REQUEST_VIEW(tenantConnection?.requestId)}>
              View
            </AppLink>
          </FieldValue>
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
