import { Alert } from "@mui/material";

import { apiClient } from "@/core/api/ApiClient";
import { DamageCostEvaluationAggregateDto, EntityType } from "@/core/api/generated";

import ContractLink from "../Contract/ContractLink";
import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageCostEvaluationAggregateDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageCostEvaluationAggregateDeleteModal({
  entity,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageCostEvaluationAggregate}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) => {
        apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            aggregateId: params.entityId,
          },
        );
      }}
      bodyAfter={
        entity.contract && (
          <Alert severity='warning'>
            This aggregate is linked to the contract <ContractLink entity={entity.contract} /> and
            might be used in assessment flow. Recheck before deletion.
          </Alert>
        )
      }
      {...otherProps}
    />
  );
}
