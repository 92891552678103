// should be moved in different file?
type showCountMaxOrDefaultProps = {
  count?: number;
  max?: number;
  defaultCount?: number;
};

/** Helps displaying counters by handling default value and limiting max value (MAX+). */
export const showCountMaxOrDefault = ({
  count,
  max = 99,
  defaultCount = 0,
}: showCountMaxOrDefaultProps): string => {
  const countOrDefault = count ?? defaultCount;
  return countOrDefault > max ? `${max}+` : `${countOrDefault}`;
};
