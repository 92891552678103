import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import UserAddDepartmentModal from "@/App/MainAppView/Management/Users/components/UserAddDepartmentModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto, UserDto } from "@/core/api/generated";

import GeneralAddressDisplay from "../../../General/Display/GeneralAddressDisplay";

interface Props {
  user: UserDto;
}

export default function DepartmentsTabContent({ user }: Props) {
  const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
  const [isRemoveDepartmentModalOpen, setIsRemoveDepartmentModalOpen] = useState(false);
  const [department, setDepartment] = useState<DepartmentDto | undefined>(undefined);
  const { enqueueSnackbar } = useAppSnackbar();

  const userDepartmentsRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdDepartmentsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: user.id!,
    },
    {
      skip: !user?.id,
    },
  );
  const userDepartments = userDepartmentsRequest?.data;

  const handleUserDeletedFromDepartment = useCallback(async () => {
    if (!user || !department) {
      return;
    }
    try {
      await apiClient.usersApi.apiV1UsersUserIdDepartmentsDepartmentIdDelete({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        userId: user.id!,
        departmentId: department.id!,
      });
      enqueueSnackbar("User was successfully removed from the department.", { variant: "success" });
      setIsRemoveDepartmentModalOpen(false);
      setDepartment(undefined);
      setTimeout(() => userDepartmentsRequest.refetch(), 1000);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [user, department]);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Departments"}
            secondaryAction={
              <Button
                variant='contained'
                onClick={() => {
                  setIsAddDepartmentModalOpen(true);
                }}
              >
                Add department
              </Button>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "localNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => <>{item.localNumber}</>,
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => <>{item.name}</>,
              },
              {
                field: "address",
                title: "Address",
                flex: 2,
                renderCell: (item) => (
                  <AppTypography ellipsing={{ enabled: true }} component='div'>
                    <GeneralAddressDisplay address={item.address} direction='row' />
                  </AppTypography>
                ),
              },
            ]}
            rows={userDepartments}
            rowTo={(item) => ROUTE_PATH.DEPARTMENT_VIEW(item.id!)}
            getRowId={(item) => item.id!}
            renderRowAction={({ item }) => (
              <MenuWithTrigger
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem component={AppLink} to={ROUTE_PATH.DEPARTMENT_VIEW(item.id!)}>
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <AppIcon of='remove' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText
                    onClick={() => {
                      setDepartment(item);
                      setIsRemoveDepartmentModalOpen(true);
                    }}
                  >
                    Remove from department
                  </ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )}
          />

          {/* Add user to department */}
          {user && (
            <UserAddDepartmentModal
              open={isAddDepartmentModalOpen}
              user={user}
              onClose={() => {
                setIsAddDepartmentModalOpen(false);
              }}
              onAdded={() => {
                userDepartmentsRequest.refetch();
              }}
            />
          )}
          {/* Remove user from department */}
          {user && department && (
            <ConfirmationModal
              title='Remove user from the department?'
              body={
                <>
                  {`You're going to remove user`}{" "}
                  <strong>{user.personName?.name || user.email}</strong> from the department{" "}
                  <strong>{department.name}</strong>. {`This action can't be undone.`}
                </>
              }
              open={isRemoveDepartmentModalOpen}
              onClose={() => setIsRemoveDepartmentModalOpen(false)}
              onCancel={() => setIsRemoveDepartmentModalOpen(false)}
              onConfirm={async () => {
                await handleUserDeletedFromDepartment();
                userDepartmentsRequest.refetch();
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
