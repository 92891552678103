import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleVisualModelDto, VehicleVisualModelReferenceDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";
import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getVehicleVisualModelOptionTitle } from "./VehicleVisualModelAutocomplete";

export interface VehicleVisualModelInlineProps extends BaseEntityInlineInheritableProps {
  entity: VehicleVisualModelDto | VehicleVisualModelReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function VehicleVisualModelInline({
  entity,
  entityId,
  ...otherProps
}: VehicleVisualModelInlineProps) {
  const request = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as VehicleVisualModelDto;
  const ref = entity as VehicleVisualModelReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='vehicleVisualModel' inText />}
      content={<>{full && getVehicleVisualModelOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Vehicle visual model"}
    />
  );
}
