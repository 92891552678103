import { ProfileUserLocationDto, UserDepartmentDto } from "@/core/api/generated";

import { useUserProfile } from "./useUserProfile";

export const useUserAffiliation = (): {
  departments: UserDepartmentDto[] | undefined;
  locations: ProfileUserLocationDto[] | undefined;
} => {
  const profile = useUserProfile();
  return { departments: profile?.departments, locations: profile?.locations };
};
