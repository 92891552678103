import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import { DamageTypeDto, DamageTypeReferenceDto } from "@/core/api/generated";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    vehicle?: boolean;
    customer?: boolean;
  };
  detailedDataProps?: {
    vehicle?: boolean;
    customer?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; damageType?: DamageTypeDto } & BaseProps)
  | ({ variant: "reference"; damageType?: DamageTypeReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  vehicle: true,
  customer: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  vehicle: true,
  customer: true,
};

/** DamageType info as block element. */
export default function DamageTypeBlock({
  variant,
  damageType,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const { hasPermissions } = useAuthorizationInfo();

  const [fetchedDamageType, setFetchedDamageType] = useState<DamageTypeDto | null>(null);

  useEffect(() => {
    (async () => {
      if (!!damageType && variant === "reference") {
        try {
          const response =
            await apiClient.damageTypesApi.apiV1ReferenceDataDamageTypesDamageTypeIdGet({
              damageTypeId: damageType.id!,
            });
          setFetchedDamageType(response.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [damageType && damageType.id]);

  if (!damageType) {
    return null;
  }

  const fullDto = fetchedDamageType || (damageType as DamageTypeDto | undefined);

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      // TODO
      // to={
      //   withLink &&
      //   hasPermissions([AppPermission.FleetAppAccess, AppPermission.DamageTypeRead])
      //     ? ROUTE_PATH.CONTRACT_VIEW(damageType.id)
      //     : undefined
      // }
      title={
        <>
          <AppIcon of='damageType' inText /> Damage type
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {damageType?.name}
          </Typography>
          {damageType?.description && (
            <Typography component='div' variant='body2'>
              {damageType?.description}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              {damageType.globalNumber && (
                <FieldValue
                  labelIcon={<AppIcon of='item' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Global number'
                >
                  {damageType.globalNumber}
                </FieldValue>
              )}
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Name'
              >
                {damageType.name}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Description'
              >
                {damageType.description}
              </FieldValue>
              {fullDto && (
                <FieldValue
                  labelIcon={<AppIcon of='item' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Built-in'
                >
                  <BooleanValue value={fullDto.isBuiltIn} />
                </FieldValue>
              )}
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Category'
              >
                <InlineApiEnumValue
                  type='DamageTypeCategory'
                  value={damageType.category}
                  withDescription
                />
              </FieldValue>
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}
