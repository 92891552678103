import _ from "lodash";
import moment from "moment";

import { UnitOfTime } from "@/core/api/generated";

import { UnitOfTimeTimeSpan } from "./enums";

export type DurationInput = Partial<Record<UnitOfTime, number | undefined>>;

export const millisecondsInUnit = {
  [UnitOfTime.None]: 0,
  [UnitOfTime.Year]: 365 * 24 * 60 * 60 * 1000, // 31536000000 (365 days)
  [UnitOfTime.Month]: 30 * 24 * 60 * 60 * 1000, // 2592000000 (30 days)
  [UnitOfTime.Week]: 7 * 24 * 60 * 60 * 1000, // 604800000 (7 days)
  [UnitOfTime.Day]: 24 * 60 * 60 * 1000, // 86400000
  [UnitOfTime.Hour]: 60 * 60 * 1000, // 3600000
  [UnitOfTime.Minute]: 60 * 1000, // 60000
  [UnitOfTime.Second]: 1000,
  [UnitOfTime.Millisecond]: 1,
} as const;

const millisecondsInUnitList = Object.entries(millisecondsInUnit).map(([unit, ms]) => ({
  unit: unit as UnitOfTime,
  ms,
}));
const millisecondsInUnitListDesc = _.orderBy(millisecondsInUnitList, (x) => x.ms, "desc");

const timeSpanRegex =
  /^((?<days>\d+)\.)?(?<hours>\d+):(?<minutes>\d+):(?<seconds>\d+)(\.(?<ticks>\d+))?$/;

/** Custom JS implementation of .NET TimeSpan.
 *  TimeSpan stores only units of time from days to milliseconds (months, years are not stored).
 *  String format: {Days}.{Hours}:{Minutes}:{Seconds}.{FractionalSeconds - [0, 9_999_999]}
 *  NB:
 *  Week: 7 days
 *  Month: 30 days
 *  Year: 365 days
 */
export class TimeSpan {
  public readonly isTimeSpan: boolean = true;
  public days: number = 0;
  public hours: number = 0;
  public minutes: number = 0;
  public seconds: number = 0;
  public milliseconds: number = 0;

  /** Accepts milliseconds or units of time.  */
  constructor(params?: number | Partial<Record<UnitOfTimeTimeSpan, number>>) {
    if (_.isNumber(params)) {
      this.milliseconds = params;
    } else {
      this.days = params?.day || 0;
      this.hours = params?.hour || 0;
      this.minutes = params?.minute || 0;
      this.seconds = params?.second || 0;
      this.milliseconds = params?.millisecond || 0;
    }
    this._adjustTime();
  }

  public get totalMilliseconds(): number {
    return (
      this.days * 24 * 60 * 60 * 1000 +
      this.hours * 60 * 60 * 1000 +
      this.minutes * 60 * 1000 +
      this.seconds * 1000 +
      this.milliseconds
    );
  }

  /** Returns string in format like: 7.23:59:59.999.
   *  Format: {Days}.{Hours}:{Minutes}:{Seconds}.{Ticks}
   */
  public toString() {
    const time = [
      String(this.hours).padStart(2, "0"),
      String(this.minutes).padStart(2, "0"),
      String(this.seconds).padStart(2, "0"),
    ]
      .filter((x) => !!x)
      .join(":");

    return `${this.days !== 0 ? `${this.days}.` : ""}${time}${
      this.milliseconds !== 0
        ? `.${String(TimeSpan.convertMillisecondsToTicks(this.milliseconds)).padStart(7, "0")}`
        : ""
    }`;
  }

  /** Converts TimeSpan to duration number in specified units.
   *  E.g. to N hours, N minutes.
   */
  public toNumber(unitOfTime: UnitOfTime): number {
    const msIn1Unit = millisecondsInUnit[unitOfTime];
    return Math.ceil(this.totalMilliseconds / msIn1Unit);
    // return this.totalMilliseconds / msIn1Unit;
  }

  /** Suggest the most suitable units for current duration value.
   *  E.g. 60 mins -> hours, 24 hours -> day.
   */
  public suggestUnitOfTime(fallbackUnitOfTime = UnitOfTime.Minute): UnitOfTime {
    const exactMatchUnit = millisecondsInUnitList.find(
      (x) => x.ms === this.totalMilliseconds,
    )?.unit;
    if (exactMatchUnit) {
      return exactMatchUnit;
    }

    const matchUnit =
      millisecondsInUnitListDesc.find(
        (x) => this.totalMilliseconds >= x.ms && this.totalMilliseconds % x.ms === 0,
      )?.unit ?? fallbackUnitOfTime;

    return matchUnit;
  }

  // #region Static methods

  /** The optional fractional portion of a second.
   *  Its value can range from "0000001" (one tick, or one ten-millionth of a second) to "9999999" (9,999,999 ten-millionths of a second, or one second less one tick).
   *  https://learn.microsoft.com/en-us/dotnet/standard/base-types/standard-timespan-format-strings#the-constant-c-format-specifier
   */
  public static convertMillisecondsToTicks(milliseconds: number): number {
    return milliseconds * 1000;
  }

  /** The optional fractional portion of a second.
   *  Its value can range from "0000001" (one tick, or one ten-millionth of a second) to "9999999" (9,999,999 ten-millionths of a second, or one second less one tick).
   *  https://learn.microsoft.com/en-us/dotnet/standard/base-types/standard-timespan-format-strings#the-constant-c-format-specifier
   */
  public static convertTicksToMilliseconds(ticks: number): number {
    return ticks / 1000;
  }

  public static fromMomentDuration(duration: moment.Duration): TimeSpan {
    const durationWithoutTime = moment.duration({
      milliseconds:
        duration.as("milliseconds") -
        duration.hours() * 3600 -
        duration.minutes() * 3600 -
        duration.seconds() * 1000 -
        duration.milliseconds(),
    });

    return new TimeSpan({
      day: durationWithoutTime.asDays(),
      hour: duration.hours(),
      minute: duration.minutes(),
      second: duration.seconds(),
      millisecond: duration.milliseconds(),
    });
  }

  public static fromDurationInput(duration: DurationInput): TimeSpan {
    const days = [
      duration[UnitOfTime.Year] ? (duration[UnitOfTime.Year] || 0) * 365 : 0,
      duration[UnitOfTime.Month] ? (duration[UnitOfTime.Month] || 0) * 30 : 0,
      duration[UnitOfTime.Week] ? (duration[UnitOfTime.Week] || 0) * 7 : 0,
      duration[UnitOfTime.Day] ? duration[UnitOfTime.Day] || 0 : 0,
    ].reduce((prev, curr) => prev + curr, 0);

    return new TimeSpan({
      day: days,
      hour: duration[UnitOfTime.Hour] ? duration[UnitOfTime.Hour] || 0 : 0,
      minute: duration[UnitOfTime.Minute] ? duration[UnitOfTime.Minute] || 0 : 0,
      second: duration[UnitOfTime.Second] ? duration[UnitOfTime.Second] || 0 : 0,
      millisecond: duration[UnitOfTime.Millisecond] ? duration[UnitOfTime.Millisecond] || 0 : 0,
    });
  }

  public static fromDurationValue(duration: string): TimeSpan {
    const matched = duration.match(timeSpanRegex);
    if (!matched || !matched.groups) {
      return new TimeSpan({});
    }

    const ticks = !_.isEmpty(matched.groups["ticks"]) ? +matched.groups["ticks"] : 0;
    const durationInput: DurationInput = {
      [UnitOfTime.Day]: !_.isEmpty(matched.groups["days"]) ? +matched.groups["days"] : 0,
      [UnitOfTime.Hour]: !_.isEmpty(matched.groups["hours"]) ? +matched.groups["hours"] : 0,
      [UnitOfTime.Minute]: !_.isEmpty(matched.groups["minutes"]) ? +matched.groups["minutes"] : 0,
      [UnitOfTime.Second]: !_.isEmpty(matched.groups["seconds"]) ? +matched.groups["seconds"] : 0,
      [UnitOfTime.Millisecond]: this.convertTicksToMilliseconds(ticks),
    };
    return this.fromDurationInput(durationInput);
  }

  // #endregion

  // #region Private methods

  /** Adjust values so, for instance, TimeSpan doesn't store invalid time value */
  private _adjustTime() {
    if (this.milliseconds >= 1000) {
      this.seconds += (this.milliseconds - (this.milliseconds % 1000)) / 1000;
      this.milliseconds = this.milliseconds % 1000;
    }
    if (this.seconds >= 60) {
      this.minutes += (this.seconds - (this.seconds % 60)) / 60;
      this.seconds = this.seconds % 60;
    }
    if (this.minutes >= 60) {
      this.hours += (this.minutes - (this.minutes % 60)) / 60;
      this.minutes = this.minutes % 60;
    }
    if (this.hours >= 24) {
      this.days += (this.hours - (this.hours % 24)) / 24;
      this.hours = this.hours % 24;
    }
  }

  // #endregion
}
