import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AccessoryDto,
  AppPermission,
  AssetEntityType,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolItemEntityType,
  TagEntityType,
} from "@/core/api/generated";

import AssetCreateUpdateFromEntityModal from "../../Asset/AssetCreateUpdateFromEntityModal";
import { entitySpecToAssetSpecDto } from "../../Asset/AssetSpecInput";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import PoolItemCreateFromEntityModal from "../../PoolItem/PoolItemCreateFromEntityModal";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import AccessoryDeleteModal from "../AccessoryDeleteModal";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import PoolsTabContent from "./Tabs/PoolsTabContent";

export enum AccessoryViewPageTabs {
  Overview = "Overview",
  Pools = "Pools",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<AccessoryDto> {
  accessoryId: string | null | undefined;
  accessory: AccessoryDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function AccessoryView({
  accessoryId,
  accessory,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Accessory),
      viewVariant: displayProps.viewVariant,
      isEnabled: false,
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateAssetFromEntityModalOpen, setIsCreateAssetFromEntityModalOpen] = useState(false);
  const [isAddToPoolModalOpen, setIsAddToPoolModalOpen] = useState(false);

  const accessoryRequest = useApiRequest(
    apiClient.accessoriesApi.apiV1AccessoriesAccessoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      accessoryId: accessoryId!,
    },
    {
      deps: [accessoryId],
      skip: !accessoryId || !!accessory,
    },
  );
  accessory = accessory || accessoryRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Accessory, accessory?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      accessoryRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        accessory && {
          idValue: accessory.id!,
          newTitle: accessory.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Accessory}
      entityId={accessoryId}
      entity={accessory}
      entityRequest={accessoryRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={
                <>
                  {`Accessory ${accessory?.name} (${accessory?.localNumber})`}{" "}
                  <EntityChipList entity={accessory} variant='normal' />
                </>
              }
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.ACCESSORY_VIEW(accessory?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={accessory?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.Accessory,
                      entityId: accessory?.id,
                      onSaved: (newValue) => {
                        accessoryRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.AccessoryManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.ACCESSORY_EDIT(accessory?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                accessory && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <AuthorizedMenuItem
                      permissions={[AppPermission.AccessoryManage]}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AuthorizedMenuItem>

                    <HeaderMenuItem primaryTitle='Quick actions' />
                    <AuthorizedMenuItem
                      permissions={[AppPermission.AssetManage]}
                      onClick={() => setIsCreateAssetFromEntityModalOpen(true)}
                    >
                      <ListItemIcon>
                        <AppIcon of='asset' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>New asset</ListItemText>
                    </AuthorizedMenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.PoolManage]}
                      onClick={() => {
                        setIsAddToPoolModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='pool' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Add to pool</ListItemText>
                    </AuthorizedMenuItem>
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={AccessoryViewPageTabs}
            defaultTabId={AccessoryViewPageTabs.Overview}
            isTabManagementEnabled={false}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: AccessoryViewPageTabs.Overview, isHideable: false },
              { label: "Pools", value: AccessoryViewPageTabs.Pools },
              { label: "History", value: AccessoryViewPageTabs.GeneralHistory },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              accessory && (
                <>
                  {activeTab === AccessoryViewPageTabs.Overview && (
                    <OverviewTabContent accessory={accessory} />
                  )}
                  {activeTab === AccessoryViewPageTabs.Pools && (
                    <PoolsTabContent accessory={accessory} />
                  )}
                  {activeTab === AccessoryViewPageTabs.GeneralHistory && (
                    <GeneralHistoryTabContent accessory={accessory} />
                  )}
                </>
              )
            }
          </PageTabs>

          {/* Delete */}
          {accessory && (
            <AccessoryDeleteModal
              entity={accessory}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => {
                history.goBack();
              }}
            />
          )}

          {/* Create asset from entity */}
          {accessory && (
            <AssetCreateUpdateFromEntityModal
              open={isCreateAssetFromEntityModalOpen}
              onClose={() => setIsCreateAssetFromEntityModalOpen(false)}
              entity={{
                entityType: AssetEntityType.Accessory,
                entityId: accessory.id!,
              }}
              spec={entitySpecToAssetSpecDto(AssetEntityType.Accessory, null)}
              createUpdateProps={{
                onSave: (asset) => {
                  setIsCreateAssetFromEntityModalOpen(false);
                  history.push(ROUTE_PATH.ASSET_VIEW(asset.id));
                },
              }}
            />
          )}

          {/* Add to pool */}
          {accessory && (
            <PoolItemCreateFromEntityModal
              open={isAddToPoolModalOpen}
              onClose={() => setIsAddToPoolModalOpen(false)}
              createProps={{
                entityType: PoolItemEntityType.Accessory,
                entity: accessory,
                onSave: (newValue) => {
                  setIsAddToPoolModalOpen(false);
                  accessoryRequest.refetch();
                },
              }}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
