import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "../Modals/AppModalTitle";
import TotalPriceExplanation, { TotalPriceExplanationProps } from "./TotalPriceExplanation";

export interface OwnProps {
  totalPriceExplanationProps: TotalPriceExplanationProps;
}

type Props = OwnProps & DialogProps;

export default function TotalPriceExplanationModal({
  totalPriceExplanationProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Total price explanation
      </AppModalTitle>
      <DialogContent>
        <TotalPriceExplanation {...totalPriceExplanationProps} />
      </DialogContent>
    </Dialog>
  );
}
