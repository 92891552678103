import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import FileUsageLockTabular from "@/common/components/Entity/File/FileUsageLockTabular";
import { AdminFileDto } from "@/core/api/generated";

interface Props {
  file: AdminFileDto;
}

export default function UsageLocksTabContent({ file }: Props) {
  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Usage locks"} />}
      >
        <ViewContentBlock>
          <FileUsageLockTabular usageLocks={file.usageLocks} />
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
