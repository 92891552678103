import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import TenantConnectionRequestPaginatedList from "@/common/components/Entity/TenantConnectionRequest/ListView/TenantConnectionRequestPaginatedList";

interface Props {}

export default function RequestsTabContent({}: Props) {
  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack direction='column' spacing={2}>
          <TenantConnectionRequestPaginatedList
            displayProps={{
              breadcrumbs: true,
              filters: true,
              create: true,
              viewVariant: ViewLayoutVariant.Tab,
            }}
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
