import { useParams } from "react-router-dom";

import MaintenanceView from "@/common/components/Entity/Maintenance/View/MaintenanceView";

export default function MaintenanceViewPage() {
  const { maintenanceId } = useParams<{ maintenanceId?: string }>();

  return (
    <>
      <MaintenanceView
        maintenanceId={maintenanceId}
        maintenance={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
