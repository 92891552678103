import { Collapse, IconButton, Stack, StackProps, TextField } from "@mui/material";
import { useState } from "react";

import AppTooltip from "../AppTooltip";
import AppIcon from "../Icons/AppIcon";

interface Props {
  onSearchValueChange?: (value: string) => void;
  showSearchButton?: boolean;
  showAdvancedOptionsButton?: boolean;
  sx?: StackProps["sx"];
  advancedContent?: JSX.Element;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
}

export default function ListOptions({
  onSearchValueChange,
  showSearchButton = true,
  showAdvancedOptionsButton = true,
  sx,
  advancedContent,
  startAdornment,
  endAdornment,
}: Props) {
  const [isAdvancedOptionsOpened, setIsAdvancedOptionsOpened] = useState(false);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  return (
    <Stack spacing={1} sx={{ background: (t) => t.palette.background.paper, ...sx }}>
      <Stack direction='row' justifyContent='end' sx={{ width: "100%" }} spacing={1}>
        {startAdornment}

        {isSearchOpened && (
          <TextField
            label='Search'
            size='small'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              onSearchValueChange?.(e.target.value);
            }}
            autoFocus
            sx={{ flex: 1, "& .MuiInputBase-root": { pr: 0 }, minWidth: 0 }}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    onClick={() => {
                      setIsSearchOpened(false);
                      setSearchValue("");
                      onSearchValueChange?.("");
                    }}
                  >
                    <AppIcon of='close' />
                  </IconButton>
                </>
              ),
            }}
          />
        )}

        {showSearchButton && !isSearchOpened && (
          <AppTooltip title={"Search"}>
            <IconButton
              sx={{
                border: (theme) => `1px solid ${theme.palette.secondary.light}`,
                height: "min-content",
              }}
              onClick={() => setIsSearchOpened(true)}
            >
              <AppIcon of='search' />
            </IconButton>
          </AppTooltip>
        )}

        {showAdvancedOptionsButton && (
          <AppTooltip title={isAdvancedOptionsOpened ? "Less options" : "More options"}>
            <IconButton
              sx={{
                color: (theme) => (isAdvancedOptionsOpened ? theme.palette.primary.main : ""),
                border: (theme) =>
                  `1px solid ${
                    isAdvancedOptionsOpened
                      ? theme.palette.primary.main
                      : theme.palette.secondary.light
                  }`,
                height: "min-content",
              }}
              onClick={() => setIsAdvancedOptionsOpened(!isAdvancedOptionsOpened)}
            >
              <AppIcon of='management' />
            </IconButton>
          </AppTooltip>
        )}

        {endAdornment}
      </Stack>

      <Collapse in={isAdvancedOptionsOpened} unmountOnExit>
        <Stack
          direction='row'
          gap={0.5}
          sx={{
            borderRadius: "20px",
            background: (theme) => theme.palette.primary.light,
            py: 1.5,
            px: 2,
            "& .MuiInputBase-root": {
              borderRadius: "10px",
              background: (theme) => theme.palette.background.default,
            },
          }}
        >
          {advancedContent}
        </Stack>
      </Collapse>
    </Stack>
  );
}
