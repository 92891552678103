import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "../../Modals/AppModalTitle";
import CreateUpdateRepairSparePart, {
  CreateUpdateRepairSparePartProps,
} from "./CreateUpdateRepairSparePart";

export interface OwnProps {
  createUpdateRepairSparePartProps: CreateUpdateRepairSparePartProps;
}

type Props = OwnProps & DialogProps;

export default function CreateUpdateRepairSparePartModal({
  createUpdateRepairSparePartProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateRepairSparePartProps.repairSparePartId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} repair spare part
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CreateUpdateRepairSparePart {...createUpdateRepairSparePartProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
