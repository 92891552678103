import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateMaintenanceDto,
  EntityType,
  MaintenanceDto,
  MaintenanceScheduleType,
  UpdateMaintenanceDto,
} from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumSelect from "../../Enum/ApiEnumSelect";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import AppIcon from "../../Icons/AppIcon";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import SupplierAutocompleteOrCreate from "../Supplier/SupplierAutocompleteOrCreate";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: string;
  vehicleId?: CreateMaintenanceDto["vehicleId"];
  supplierId?: CreateMaintenanceDto["supplierId"];
  departmentId?: CreateMaintenanceDto["departmentId"];
  locationId?: CreateMaintenanceDto["locationId"];
  tenantRequestsMeta?: CreateMaintenanceDto["tenantRequestsMeta"];
  currency?: CreateMaintenanceDto["currency"];
  scheduleType?: CreateMaintenanceDto["scheduleType"];
};

export interface MaintenanceCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<MaintenanceDto, DefaultValues> {
  maintenanceId?: string;
}

export type MaintenanceCreateUpdateProps = MaintenanceCreateUpdateOwnProps;

export default function MaintenanceCreateUpdate({
  maintenanceId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: MaintenanceCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const history = useHistory();
  const isCreate = !maintenanceId;
  const isEdit = !!maintenanceId;
  const currentCurrency = useCurrentCurrency();

  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const maintenanceRequest = useApiRequest(
    apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      maintenanceId: maintenanceId!,
    },
    {
      deps: [maintenanceId],
      skip: !maintenanceId,
    },
  );
  const maintenance = maintenanceRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: maintenance && {
      idValue: maintenance.id!,
      newTitle: maintenance.localNumber || "",
    },
  });

  const vehicleIdComputed = defaultValues?.vehicleId || maintenance?.vehicle?.id;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Maintenance}
      entityId={maintenanceId}
      entity={maintenance}
      entityRequest={maintenanceRequest}
    >
      <Formik<
        CreateMaintenanceDto &
          UpdateMaintenanceDto &
          BaseFormikValues & {
            vehicle?: MaintenanceDto["vehicle"];
            supplier?: MaintenanceDto["supplier"];
            initialAttachments: MaintenanceDto["attachments"];
            total?: number;
          }
      >
        initialValues={{
          // temporary
          departmentId:
            defaultValues?.departmentId ||
            (maintenance?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          // temporary
          locationId:
            defaultValues?.locationId ||
            (maintenance?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          name: maintenance?.name || defaultValues?.name || undefined,
          vehicleId: maintenance?.vehicle?.id || defaultValues?.vehicleId || undefined,
          supplierId: maintenance?.supplier?.id || defaultValues?.vehicleId || undefined,
          scheduleType: maintenance?.scheduleType || defaultValues?.scheduleType || undefined,
          scheduledAt: maintenance?.scheduledAt || undefined,
          responsibleUser: {
            isCurrentUser: true,
            ...(maintenance?.responsibleUser || {}),
          },
          mileage: maintenance?.mileage ?? undefined,
          fuelLevel: maintenance?.fuelLevel ?? undefined,
          notes: maintenance?.notes || "",
          currency:
            defaultValues?.currency || maintenance?.currency || currentCurrency || undefined,
          price: maintenance?.price || 0,
          discount: maintenance?.discount || undefined,
          tax: maintenance?.tax || undefined,
          initialAttachments: maintenance?.attachments || undefined,
          attachments: !_.isEmpty(maintenance?.attachments) ? maintenance?.attachments : undefined,
          tenantRequestsMeta:
            maintenance?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: maintenance?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            scheduledAt: values.scheduledAt ? moment(values.scheduledAt).utc().format() : undefined,
          };

          try {
            if (isCreate) {
              const response = await apiClient.maintenancesApi.apiV1MaintenancesPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createMaintenanceDto: { ...values2 },
              });
              enqueueSnackbar("Maintenance created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response = await apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                maintenanceId,
                updateMaintenanceDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Maintenance updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    {!isCreate && (
                      <TextField
                        fullWidth
                        disabled
                        label='Inspection number'
                        margin='dense'
                        type='text'
                        value={maintenance?.localNumber}
                        variant='outlined'
                      />
                    )}

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />

                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !isCreate || !_.isEmpty(vehicleIdComputed),
                          entityId: values.vehicleId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            if (newValue?.id) {
                              if (!values.departmentId) {
                                setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                              }
                              if (!values.locationId) {
                                setFieldValue("locationId", newValue?.locationIds?.at(0));
                              }
                            }
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          if (newValue?.id) {
                            if (!values.departmentId) {
                              setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                            }
                            if (!values.locationId) {
                              setFieldValue("locationId", newValue?.locationIds?.at(0));
                            }
                          }
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    <FormControl margin='dense' fullWidth error={Boolean(errors.supplierId)}>
                      <SupplierAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          entityId: values.supplierId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`supplierId`, newValue?.id);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`supplierId`, newValue?.id);
                        }}
                      />
                      <FormHelperText>{errors.supplierId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />

                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(touched.scheduleType && errors.scheduleType)}
                    >
                      <InputLabel required>Schedule type</InputLabel>
                      <ApiEnumSelect
                        type='MaintenanceScheduleType'
                        value={values.scheduleType}
                        onChange={(newValue) => {
                          setFieldValue("scheduleType", newValue),
                            setFieldValue("scheduledAt", undefined);
                        }}
                        selectProps={{
                          label: "ScheduleType",
                          required: true,
                        }}
                      />
                      <FormHelperText>{touched.scheduleType && errors.scheduleType}</FormHelperText>
                    </FormControl>

                    {values.scheduleType === MaintenanceScheduleType.Scheduled && (
                      <FormControl
                        margin='dense'
                        fullWidth
                        error={Boolean(touched.scheduledAt && errors.scheduledAt)}
                      >
                        <MobileDateTimePicker
                          ampm={false}
                          label='Scheduled at'
                          value={(values.scheduledAt && moment(values.scheduledAt)) || null}
                          format={DATETIME_FORMATS.DISPLAY_DATETIME}
                          onChange={(newValue: Moment | null) => {
                            setFieldValue("scheduledAt", newValue?.format() || null);
                          }}
                          slots={{ textField: (params) => <TextField {...params} /> }}
                        />
                        <FormHelperText>{touched.scheduledAt && errors.scheduledAt}</FormHelperText>
                      </FormControl>
                    )}

                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel component='legend' required>
                        Responsible user
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled
                              required
                              checked={values.responsibleUser?.isCurrentUser}
                              onChange={handleChange}
                              name='responsibleUser.isCurrentUser'
                            />
                          }
                          label={profile?.personName?.name}
                        />
                      </FormGroup>
                    </FormControl>

                    <FormControl fullWidth margin='dense'>
                      <PriceSummaryInput
                        values={{
                          currency: values.currency,
                          subTotal: values.price,
                          discount: values.discount,
                          tax: values.tax,
                          total: values.total,
                        }}
                        formikProps={{
                          errors: {
                            subTotal: errors.price,
                            discount: errors.discount,
                            tax: errors.tax,
                            total: errors.total,
                          },
                        }}
                        displayProps={{
                          insurance: false,
                          calcExplanation: true,
                        }}
                        inputsProps={{
                          all: {
                            margin: "none",
                          },
                          subTotal: {
                            label: "Price",
                            required: true,
                          },
                          total: {
                            required: true,
                          },
                        }}
                        onChange={(newValue) => {
                          setFieldValue(`currency`, newValue?.currency);
                          setFieldValue(`price`, newValue?.subTotal);
                          setFieldValue(`discount`, newValue?.discount);
                          setFieldValue(`tax`, newValue?.tax);
                          setFieldValue(`total`, newValue?.total);
                        }}
                      />
                    </FormControl>

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.mileage && errors.mileage)}
                    >
                      <Typography component='div' gutterBottom>
                        Mileage
                      </Typography>
                      <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
                        <AppIcon of='addRoad' />
                        <Input
                          name='mileage'
                          value={values.mileage || ""}
                          size='small'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 10000000,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                      <FormHelperText>{touched.mileage && errors.mileage}</FormHelperText>
                    </FormControl>

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                    >
                      <Typography component='div' gutterBottom>
                        Fuel level
                      </Typography>

                      <FuelLevelInput
                        onBlur={handleBlur}
                        onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                        value={values.fuelLevel ?? undefined}
                      />
                      <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                    </FormControl>
                  </Box>

                  {/* Other */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography component='span' variant='h6'>
                          Other
                        </Typography>
                      ),
                    }}
                  >
                    <TextField
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      multiline
                      rows={2}
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes}
                      variant='outlined'
                    />

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                      />
                    </FormControl>
                  </FoldableBlock>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  disabled={isAttachmentFilesUploading}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
