import { Stack } from "@mui/material";
import _ from "lodash";
import { useHistory } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppSelector } from "@/common/hooks/redux";
import { apiClient } from "@/core/api/ApiClient";
import { selectSubscriptionPlan } from "@/store/management/subscriptionPlans/slice";

import Plan from "./Plan/Plan";

function SubscriptionPlans() {
  const history = useHistory();
  const paymentProfile = useAppSelector((x) => x.management.payments.paymentProfile);

  const getCurrentSubscriptionRequest = useApiRequest(
    apiClient.subscriptionsApi.apiV1SubscriptionsCurrentGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const currentSubscription = getCurrentSubscriptionRequest.data!;

  const getSubscriptionPlansRequest = useApiRequest(
    apiClient.subscriptionPlansApi.apiV1SubscriptionsPlansGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const subscriptionPlans = getSubscriptionPlansRequest.data;
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Subscription plans' />}>
      <Stack direction={{ mobile: "column", laptop: "row" }} spacing={2}>
        {_.isEmpty(subscriptionPlans) && <NoDataAlert />}

        {subscriptionPlans?.map((sp, index) => {
          return (
            <Plan
              selectSubscriptionPlan={selectSubscriptionPlan}
              plan={sp}
              key={index}
              currentSubscription={currentSubscription}
              getCurrentSubscription={getCurrentSubscriptionRequest.refetch}
              paymentProfile={paymentProfile}
              redirectToAppPaymentProfileEdit={() =>
                history.push(ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE_EDIT)
              }
            />
          );
        })}
      </Stack>
    </ViewLayoutV2>
  );
}

export default SubscriptionPlans;
