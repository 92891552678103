import { useParams } from "react-router-dom";

import WheelOperationView from "@/common/components/Entity/WheelOperation/View/WheelOperationView";

export default function WheelOperationViewPage() {
  const { wheelOperationId } = useParams<{ wheelOperationId?: string }>();

  return (
    <>
      <WheelOperationView
        wheelOperationId={wheelOperationId}
        wheelOperation={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
