import _ from "lodash";
import moment, { MomentInput } from "moment";

import { TypeHelper } from "./type";

export class ArrayHelper {
  public static isArray(value: any): value is any[] {
    return Array.isArray(value);
  }

  /** Checks if array is empty.
   *  NB: _.isEmpty is not used as it treats number, boolean, Date as empty values.
   */
  public static isEmpty<T, TArray extends Array<T>>(arr: Nil<TArray>): boolean {
    return TypeHelper.isEmptyArray(arr);
  }

  /** Sorts array using Array.sort(). */
  public static sortByDatetime<TItem>(
    arr?: TItem[] | null,
    field?: keyof TItem,
    order: "asc" | "desc" = "asc",
  ): void {
    if (!arr || !field) {
      return;
    }

    arr.sort((a, b) => {
      if (order === "asc") {
        return moment(a[field] as MomentInput).isSameOrBefore(moment(b[field] as MomentInput))
          ? -1
          : 1;
      } else if (order === "desc") {
        return moment(a[field] as MomentInput).isSameOrAfter(moment(b[field] as MomentInput))
          ? -1
          : 1;
      } else {
        return 0;
      }
    });
  }

  /** Replaces array element by predicate (changes the original array). */
  public static replaceByPredicate<TItem>(
    arr: TItem[] | null | undefined,
    predicate: (item: TItem) => boolean,
    newItem: TItem,
  ): TItem[] | null | undefined {
    if (_.isNil(arr)) {
      return arr;
    }
    const index = arr.findIndex(predicate);
    index >= 0 && arr.splice(index, 1, newItem);
    return arr;
  }

  /** Replaces array element by index (changes the original array). */
  public static replaceByIndex<TItem>(
    arr: TItem[] | null | undefined,
    index: number,
    newItem: TItem,
  ): TItem[] | null | undefined {
    if (_.isNil(arr)) {
      return arr;
    }
    index >= 0 && arr.splice(index, 1, newItem);
    return arr;
  }

  /** Removes array element by reference equality (changes the original array). */
  public static remove<TItem>(
    arr: TItem[] | null | undefined,
    item: TItem,
  ): TItem[] | null | undefined {
    if (_.isNil(arr)) {
      return arr;
    }
    const index = arr.indexOf(item);
    if (index >= 0) {
      arr.splice(index, 1);
    }
    return arr;
  }

  /** Removes array element by index (changes the original array). */
  public static removeByIndex<TItem>(
    arr: TItem[] | null | undefined,
    index: number,
  ): TItem[] | null | undefined {
    if (_.isNil(arr)) {
      return arr;
    }
    if (index >= 0) {
      arr.splice(index, 1);
    }
    return arr;
  }

  /** Removes first matched array element by predicate (changes the original array). */
  public static removeByPredicate<TItem>(
    arr: TItem[] | null | undefined,
    predicate: (item: TItem) => boolean,
  ): TItem[] | null | undefined {
    if (_.isNil(arr)) {
      return arr;
    }
    const index = arr.findIndex(predicate);
    index >= 0 && arr.splice(index, 1);
    return arr;
  }

  public static arrayToMap<TItem extends keyof any>(
    arr?: TItem[],
  ): Partial<Record<TItem, boolean>> | undefined {
    return ((arr &&
      _.mapValues(
        _.keyBy(arr, (x) => x),
        (x) => true,
      )) ||
      undefined) as Partial<Record<TItem, boolean>> | undefined;
  }

  /** Checks if the array contains elements that are all the same (all equal) by specified key. */
  public static containsAllTheSameBy<TItem>(
    arr: TItem[],
    keySelector: (item: TItem) => any,
  ): boolean {
    return arr.length == 0 || _.uniq(arr.map((x) => keySelector(x))).length == 1;
  }
}
