import { useRef } from "react";

import { deepCompareEquals } from "../../helpers/comparison";

/** Returns the newest memorized value (uses deep comparison).
 *  Analog of useMemo for single value
 *  Source: https://stackoverflow.com/a/54096391/5168794
 */
export function useValueMemoWithDeepCompare<TValue>(value: TValue): TValue {
  const ref = useRef<TValue>();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current || value;
}
