import { Box, Stack, SxProps, Theme } from "@mui/material";

import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import AppTypography from "@/common/components/Text/AppTypography";
import { StringHelper } from "@/common/helpers/string";
import { GeneralCountryDto } from "@/core/api/generated";

interface Props {
  country: GeneralCountryDto | null | undefined;
  withDetailsPopover?: boolean;
  sx?: SxProps<Theme>;
}

export default function GeneralCountryDisplay({ country, withDetailsPopover = true, sx }: Props) {
  if (!country) {
    return null;
  }

  return (
    <Box sx={sx}>
      <AppPopover
        enabled={withDetailsPopover}
        hoverBehavior={{}}
        trigger={
          <AppTypography
            decoration={{
              variant: "helpText",
            }}
          >
            {StringHelper.joinIntoString([country.name, `(${country.alpha2Code})`], " ", {
              skipEmpty: true,
            })}
          </AppTypography>
        }
      >
        <AppPopoverContent>
          <Stack spacing={1}>
            <FieldValue label='Name' isEmpty={!country?.name}>
              {country?.name}
            </FieldValue>
            <FieldValue label='Official name' isEmpty={!country?.officialName}>
              {country?.officialName}
            </FieldValue>
            <FieldValue label='Code (alpha2)' isEmpty={!country?.alpha2Code}>
              {country?.alpha2Code}
            </FieldValue>
            <FieldValue label='Code (alpha3)' isEmpty={!country?.alpha3Code}>
              {country?.alpha3Code}
            </FieldValue>
            <FieldValue label='Numeric code' isEmpty={!country?.numericCode}>
              {country?.numericCode}
            </FieldValue>
          </Stack>
        </AppPopoverContent>
      </AppPopover>
    </Box>
  );
}
