import { Chip, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { SpotDto, SpotSearchPaginatedDto } from "@/core/api/generated";

import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";
import { spotToOption } from "./SpotAutocomplete";

export interface SpotsAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<SpotDto> {
  searchFilters?: Partial<Omit<SpotSearchPaginatedDto, "search" | "includeIds">>;
}

export default function SpotsAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: SpotsAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={spotToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.spotsApi.apiV1SpotsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          spotSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          spotSearchPaginatedDto: {
            ...params.spotSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Spots'
      placeholder='Search for spots...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='spot' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}{" "}
                    {option.data && (
                      <Chip
                        variant='outlined'
                        size='small'
                        color='default'
                        label={
                          <InlineApiEnumValueList
                            type='SpotType'
                            values={option.data?.types}
                            direction='row'
                          />
                        }
                      />
                    )}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option.data?.description}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
