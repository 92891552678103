import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import LocationCreateUpdate from "@/common/components/Entity/Location/LocationCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function LocationCreateUpdatePage() {
  const { locationId } = useParams<{ locationId?: string }>();
  const history = useHistory();

  const isCreate = !locationId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new location" : "Edit location"} />}
    >
      <LocationCreateUpdate
        locationId={locationId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.LOCATION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
