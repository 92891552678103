import { Alert, Box, Stack, SxProps, Theme } from "@mui/material";

import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { PoolAvailabilityDto } from "@/core/api/generated";

import SimpleCardListSkeleton from "../../Skeleton/SimpleCardListSkeleton";

const defaultPropagatedDeps = {
  refetch: {},
};
export type PoolAvailabilityDisplayPropagatedDepsMap = typeof defaultPropagatedDeps;

interface Props {
  poolId: string | null | undefined;
  availability: PoolAvailabilityDto | null | undefined;
  isLoad?: boolean;
  propagatedDeps?: PropagatedDeps<PoolAvailabilityDisplayPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export default function PoolAvailabilityDisplay({
  poolId,
  availability,
  isLoad = false,
  propagatedDeps,
  sx,
}: Props) {
  const availabilityRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdAvailabilityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId || "",
    },
    {
      skip: !poolId || !isLoad,
      deps: [poolId, isLoad, propagatedDeps?.depsMap["refetch"]],
    },
  );
  const availabilityComputed = availabilityRequest?.data || availability;
  const poolComputed = availabilityComputed?.pool;

  return (
    <Stack spacing={1} sx={sx}>
      {availabilityRequest.isLoading && (
        <SimpleCardListSkeleton itemCount={1} sx={{ height: 30 }} />
      )}

      {!availabilityRequest.isLoading && availabilityComputed && (
        <Stack spacing={2}>
          {availabilityComputed && availabilityComputed.requestedCount === 0 && (
            <Alert severity='warning'>
              <Box>
                No{" "}
                {TextHelper.pluralize(
                  enumService.getEnumValueName("PoolItemEntityType", poolComputed?.itemEntityType),
                  2,
                ).toLocaleLowerCase()}{" "}
                left!
              </Box>
            </Alert>
          )}

          {availabilityComputed && availabilityComputed.requestedCount !== 0 && (
            <Alert severity='success'>
              <Box>
                Available{" "}
                {TextHelper.pluralize(
                  enumService.getEnumValueName("PoolItemEntityType", poolComputed?.itemEntityType),
                  2,
                ).toLocaleLowerCase()}
                : {availabilityComputed.requestedCount} / {availabilityComputed.totalCount}.
              </Box>
            </Alert>
          )}
        </Stack>
      )}
    </Stack>
  );
}
