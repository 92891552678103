import { useParams } from "react-router-dom";

import { useHighlightPropsFromQueryString } from "@/common/hooks/useHighlightPropsFromQueryString";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

import DamageCostEvaluationAggregateView, {
  DamageCostEvaluationAggregateViewProps,
} from "../../../common/components/Entity/DamageCostEvaluationAggregate/View/DamageCostEvaluationAggregateView";

export type DamageCostEvaluationAggregateViewPageHighlightProps = Exclude<
  DamageCostEvaluationAggregateViewProps["highlightProps"],
  null | undefined
>;

export type DamageCostEvaluationAggregateViewPageQueryParams = GeneralQueryParams;

export default function DamageCostEvaluationAggregateViewPage() {
  const { aggregateId } = useParams<{ aggregateId?: string }>();
  const highlightProps =
    useHighlightPropsFromQueryString<DamageCostEvaluationAggregateViewPageHighlightProps>();

  return (
    <>
      <DamageCostEvaluationAggregateView
        aggregateId={aggregateId}
        withRealtimeDataUpdates
        headerProps={{
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
        highlightProps={highlightProps}
      />
    </>
  );
}
