import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  SxProps,
  Theme,
} from "@mui/material";

import AppModalTitle from "../../../Modals/AppModalTitle";
import TagList, { TagListProps } from "./TagList";

export interface OwnProps {
  title?: string;
  tagListProps: TagListProps;
  sx?: SxProps<Theme>;
}

type Props = OwnProps & DialogProps;

export default function TagListModal({ title, tagListProps, sx, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {title || "Tags"}
      </AppModalTitle>
      <DialogContent>
        <TagList {...tagListProps} />
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          onClick={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
