import { Alert, AlertTitle, Box, Card, CardContent, Stack, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import FoldableBlock from "../../Display/FoldableBlock";
import FieldValue from "../../Form/Display/FieldValue";
import GeneralStrictEntityRelationLink from "../General/Display/GeneralStrictEntityRelationLink";
import PoolLink from "../Pool/PoolLink";
import PoolItemLink from "../PoolItem/PoolItemLink";

export interface AssetSubscriptionPlanInlineProps {
  poolIds: string[] | null | undefined;
}

export default function AssetSubscriptionPlanPoolsIntersectionAlert({
  poolIds,
}: AssetSubscriptionPlanInlineProps) {
  const request = useApiRequest(
    apiClient.poolsApi.apiV1PoolsIntersectionGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolIds: poolIds || undefined,
    },
    {
      deps: [poolIds],
      skip: _.isEmpty(poolIds),
    },
  );
  const result = request?.data;

  if (!result || !result.isAnyIntersection) {
    return null;
  }

  return (
    <Alert severity='error' sx={{ ".MuiAlert-message": { flex: 1 } }}>
      <Stack spacing={2}>
        <AlertTitle>Detected pools intersection!</AlertTitle>

        <Stack spacing={2}>
          <Box>
            <Box>
              Some of the specified pools intersect (contains the same items). For instance, the
              same vehicle/asset belong to multiple pools.
            </Box>
            <Box>You need to select non-intersecting pools.</Box>
          </Box>

          <Stack spacing={1}>
            {result.intersections?.map((intersection, i) => (
              <Card key={i}>
                <CardContent>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xxs: "1fr",
                        md: "repeat(2, 1fr)",
                      },
                      gap: 2,
                    }}
                  >
                    <FieldValue label='Pools' direction='column'>
                      <Stack>
                        {intersection.pools?.map((pool, k) => (
                          <PoolLink key={k} entity={pool} entityId={pool.id} />
                        ))}
                      </Stack>
                    </FieldValue>

                    <FieldValue label='Intersecting entities' direction='column'>
                      <Stack spacing={1}>
                        {intersection.entries?.map((entry, k) => (
                          <Card key={k}>
                            <CardContent>
                              <Stack spacing={0} direction='column'>
                                <GeneralStrictEntityRelationLink value={entry.entity} />

                                <FoldableBlock
                                  defaultIsFolded
                                  trigger={{
                                    label: (
                                      <Typography component='span' variant='body2'>
                                        Pool items
                                      </Typography>
                                    ),
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: {
                                        xxs: "1fr",
                                        md: "repeat(2, 1fr)",
                                      },
                                      gap: 2,
                                    }}
                                  >
                                    {entry.poolItems?.map((poolItem, n) => (
                                      <React.Fragment key={n}>
                                        <FieldValue label='Pool' direction='column'>
                                          <PoolLink
                                            key={n}
                                            entity={undefined}
                                            entityId={poolItem.poolId || ""}
                                          />
                                        </FieldValue>
                                        <FieldValue label='Pool item' direction='column'>
                                          <PoolItemLink key={n} entity={poolItem} />
                                        </FieldValue>
                                      </React.Fragment>
                                    ))}
                                  </Box>
                                </FoldableBlock>
                              </Stack>
                            </CardContent>
                          </Card>
                        ))}
                      </Stack>
                    </FieldValue>
                  </Box>

                  {/* {intersection.entries?.map((entry, j) => (
                    <Stack key={j} spacing={1}>
                      <Box>
                        <FieldValue label='Pools' direction='column'>
                          <Stack>
                            {intersection.pools?.map((pool, k) => (
                              <PoolLink key={k} entity={pool} entityId={pool.id} />
                            ))}
                          </Stack>
                        </FieldValue>
                        The entity <GeneralStrictEntityRelationLink value={entry.entity} /> belongs
                        to all these pools:
                      </Box>

                      <Stack spacing={2}>
                        {entry.poolItems?.map((poolItem, k) => (
                          <Box
                            key={k}
                            sx={{
                              display: "grid",
                              gridTemplateColumns: {
                                xxs: "1fr",
                                md: "repeat(3, 1fr)",
                              },
                              gap: 2,
                            }}
                          >
                            <FieldValue label='Pool' direction='column'>
                              <PoolLink
                                entity={intersection.pools?.find((x) => x.id === poolItem.poolId)}
                                entityId={poolItem.poolId}
                              />
                            </FieldValue>

                            <FieldValue label='Pool item' direction='column'>
                              <PoolItemLink poolId={poolItem.poolId || ""} entity={poolItem} />
                            </FieldValue>

                            <FieldValue label='Entity' direction='column'>
                              <GeneralStrictEntityRelationLink value={entry.entity} />
                            </FieldValue>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  ))} */}
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Alert>
  );
}
