import { CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import AppLink from "@/common/components/Link/AppLink";
import { UrlHelper } from "@/common/helpers/url";
import { tenantService } from "@/common/services/tenant";
import { CustomTeslaAuth0AppState, teslaService } from "@/common/services/tesla";

const redirectToReturnUrlTimeoutMs = 3000;

/** Tesla Auth redirects to this page after successful login by a Tesla user. */
export default function TeslaAuthCallbackHandler() {
  const logPrefix = "TeslaAuthCallbackHandler.";
  const history = useHistory();

  const isHandlingRef = useRef(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isRedirectingToReturnUrl, setIsRedirectingToReturnUrl] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [restoredAppState, setRestoredAppState] = useState<CustomTeslaAuth0AppState | undefined>(
    undefined,
  );

  useEffect(() => {
    (async () => {
      if (isHandlingRef.current) {
        return;
      }

      try {
        console.log(logPrefix, "Start.");
        isHandlingRef.current = true;
        const result = await teslaService.handleAuthRedirectCallback();
        console.log(logPrefix, " Result:", result, JSON.stringify(result, undefined, 4));

        // handle custom appState
        const appState = result?.appState;
        setRestoredAppState(appState);
        if (appState) {
          if (appState.redirectUrl) {
            console.log(logPrefix, "Redirect to redirectUrl: ", appState.redirectUrl);
            // redirect after small timeout to give user time to read success message
            setIsRedirectingToReturnUrl(true);
            setTimeout(() => {
              UrlHelper.redirectToUrl(appState.redirectUrl || "");
            }, redirectToReturnUrlTimeoutMs);
            return;
          }

          // TODO: refactor as the same logic is in Auth0CallbackHandler
          if (appState.spaRedirectUrl) {
            // restore tenant
            const tenantInfo = tenantService.resolveTenant();
            if (appState.tenantIdentifier && appState.tenantIdentifier !== tenantInfo?.identifier) {
              let tenantUrl = tenantService.getTenantUrl(appState.tenantIdentifier, false);
              tenantUrl = UrlHelper.deleteUrlSearchParams(tenantUrl);
              const tempFullSpaRedirectUrl = `https://temp.com${appState.spaRedirectUrl}`;
              const pathname = UrlHelper.getUrlPathname(tempFullSpaRedirectUrl);
              const queryParams = UrlHelper.getUrlSearchParams(tempFullSpaRedirectUrl);
              let resultRedirectUrl = UrlHelper.addUrlPathname(tenantUrl, pathname);
              resultRedirectUrl = UrlHelper.updateUrlSearchParams(resultRedirectUrl, queryParams);
              console.log(
                logPrefix,
                `Redirect to spaRedirectUrl (for tenant ${appState.tenantIdentifier}): `,
                resultRedirectUrl,
              );

              // redirect after small timeout to give user time to read success message
              setIsRedirectingToReturnUrl(true);
              setTimeout(() => {
                UrlHelper.redirectToUrl(resultRedirectUrl || "");
              }, redirectToReturnUrlTimeoutMs);
              return;
            }

            // redirect via react router
            console.log("Redirect to spaRedirectUrl: ", appState.spaRedirectUrl);
            // localStorage.setItem("presavedSpaRedirect", appState.spaRedirectUrl);

            // redirect after small timeout to give user time to read success message
            setIsRedirectingToReturnUrl(true);
            setTimeout(() => {
              history.push(appState.spaRedirectUrl || "");
            }, redirectToReturnUrlTimeoutMs);
            return;
          }
        }

        history.push("/");
      } catch (err) {
        console.error("TeslaAuthCallbackHandler. Error:", err);
        setIsError(true);
        // history.push("/");
      } finally {
        isHandlingRef.current = false;
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Box sx={{ m: "auto" }}>
      {isLoading && !isRedirectingToReturnUrl && <CircularProgress size='50px' color='primary' />}

      {isRedirectingToReturnUrl && (
        <Box>
          <Typography>Successfully authorized with your Tesla account.</Typography>
          <Typography>Redirecting to your previous location...</Typography>
        </Box>
      )}

      {isError && (
        <Stack spacing={2}>
          <Typography color='error'>
            Error occurred while processing Tesla authorization.
          </Typography>

          {errorMessage && <Typography color='error'>{errorMessage}</Typography>}

          <Stack>
            <AppLink to='/'>Go home</AppLink>

            {restoredAppState?.redirectUrl && (
              <AppLink to={restoredAppState.redirectUrl} href={restoredAppState.redirectUrl}>
                Go back
              </AppLink>
            )}
            {restoredAppState?.spaRedirectUrl && (
              <AppLink to={restoredAppState.spaRedirectUrl}>Go back</AppLink>
            )}
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
