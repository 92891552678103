import { Typography, TypographyProps } from "@mui/material";

import { pageTabHeaderTypographyProps } from "./PageBody/PageTabHeader";

interface Props extends TypographyProps {}

const ViewContentBlockHeader = ({ children, ...otherProps }: Props) => {
  return (
    <Typography variant={pageTabHeaderTypographyProps.title.variant} {...otherProps}>
      {children}
    </Typography>
  );
};

export default ViewContentBlockHeader;
