import { Button, Stack, SxProps, Theme } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
  WheelOperationDto,
  WheelOperationGetPaginatedDto,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import VehicleInline from "../../Vehicle/VehicleInline";
import WheelOperationMenu from "../WheelOperationMenu";
import WheelOperationsDeleteModal from "../WheelOperationsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Delete = "Delete",
}
const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type WheelOperationPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

type WheelOperationGetPaginatedInputDto = Omit<WheelOperationGetPaginatedDto, "tags"> & {
  tags?: TagStaticFilterInputDto;
};
export interface WheelOperationPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
  };
  propagatedDeps?: PropagatedDeps<WheelOperationPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type WheelOperationPaginatedListProps = WheelOperationPaginatedListOwnProps;

export default function WheelOperationPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: WheelOperationPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<WheelOperationGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.WheelOperation,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<WheelOperationDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedWheelOperationsRequest = useApiRequest(
    apiClient.wheelOperationsApi.apiV1WheelOperationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      wheelOperationGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<WheelOperationDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedWheelOperations = useMemo(
    () => paginatedWheelOperationsRequest?.data,
    [paginatedWheelOperationsRequest?.data],
  );

  const wheelOperationIds = useMemo(
    () => paginatedWheelOperations?.items?.map((item) => item.id!) || [],
    [paginatedWheelOperations],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.WheelOperation)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedWheelOperationsRequest.handleEntityChanged(data);
    },
  });

  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps?.header && (
            <SimpleViewPageHeader
              title='Wheel operations'
              primaryActions={
                displayProps?.create && (
                  // <AuthorizedElement
                  //   permissionsAny={[
                  //     AppPermission.WheelOperationPerform,
                  //     AppPermission.WheelOperationManage,
                  //   ]}
                  // >
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.WHEEL_OPERATION_CREATE({
                      vehicleId: commonRequestParams.params?.vehicleId,
                    })}
                  >
                    Create new wheel operation
                  </Button>
                  // </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: getTypedPath<WheelOperationDto>().id.$path,
                title: "ID",
                width: 250,
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.id,
                filters: {
                  fieldType: FilterFieldType.Id,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().localNumber.$path,
                title: "Number",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <Stack direction='row' spacing={0.5}>
                    <span>{item.localNumber}</span>{" "}
                    <EntityChipList entity={item} variant='compact' />
                  </Stack>
                ),
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().stageHistory.stage.$path,
                title: "Stage",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.stageHistory?.stage,
              },
              {
                field: getTypedPath<WheelOperationDto>().vehicle.id.$path,
                title: "Vehicle",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => <VehicleInline entity={item.vehicle} />,
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().scheduleType.$path,
                title: "Schedule type",
                width: 100,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => (
                  <InlineApiEnumValue type='ScheduleType' value={item.scheduleType} />
                ),
              },
              {
                field: getTypedPath<WheelOperationDto>().scheduledAt.$path,
                title: "Schedule At",
                width: 150,
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.scheduledAt ? <Datetime datetime={item.scheduledAt} /> : "-",
              },
              {
                field: getTypedPath<WheelOperationDto>().mileage.$path,
                title: "Mileage",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.mileage ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().fuelLevel.$path,
                title: "Fuel level",
                isVisible: true,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) => item.fuelLevel ?? "-",
                filters: {
                  fieldType: FilterFieldType.Number,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().notes.$path,
                title: "Notes",
                width: 100,
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) => item.notes || "-",
                filters: {
                  fieldType: FilterFieldType.String,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().createdAt.$path,
                title: "Created at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().updatedAt.$path,
                title: "Updated at",
                isVisible: false,
                isHideable: true,
                isSortable: true,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
                filters: {
                  fieldType: FilterFieldType.Date,
                  isUseDefaultOperators: true,
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().createdBy.$path,
                title: "Created by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().updatedBy.$path,
                title: "Updated by",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                renderCell: (item) =>
                  item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
                filters: {
                  fieldType: FilterFieldType.Id,
                  operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                },
              },
              {
                field: getTypedPath<WheelOperationDto>().attachments.$path,
                title: "Attachments",
                isVisible: false,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <TableCellContentOfAttachments attachments={item.attachments} />
                ),
              },
              {
                field: "affiliation",
                title: "Affiliation",
                description: "Affiliation to departments & locations",
                width: 120,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <AffiliationInfoDisplay
                    variant='compact'
                    tenantId={item.tenantId}
                    departmentIds={item.departmentIds}
                    locationIds={item.locationIds}
                  />
                ),
              },
              {
                field: getTypedPath<WheelOperationDto>().tags.$path,
                title: "Tags",
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: true,
                isColumnMenuDisabled: false,
                isToDisabled: true,
                renderCell: (item) => (
                  <EntityTagsInfoDisplay
                    entityType={TagEntityType.WheelOperation}
                    entity={item}
                    noDataPlaceholder='-'
                    edit={{
                      onSaved: (newValue) =>
                        paginatedWheelOperationsRequest.updateData((data) => {
                          data.items?.forEach((item2) => {
                            if (item2.id === item.id) {
                              item2.tags = newValue || undefined;
                            }
                          });
                        }),
                    }}
                  />
                ),
                filters: {
                  fieldType: FilterFieldType.Arbitrary,
                  isUseSingleFilter: true,
                  operators: FilterCatalog.getOperatorsForTagsField(),
                },
              },
            ]}
            rows={paginatedWheelOperations?.items}
            isLoading={paginatedWheelOperationsRequest.isLoading}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.WHEEL_OPERATION_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <WheelOperationMenu
                entity={item}
                onDelete={() => paginatedWheelOperationsRequest.refetch()}
                onUpdate={() => paginatedWheelOperationsRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: displayProps?.edit || false,
                    delete: displayProps?.delete || false,
                  },
                }}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Update tags" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTags)}
                  >
                    <AppIcon of='tag' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() => startAction(BulkActionFlags.Delete)}
                  >
                    <AppIcon of='delete' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
                const selectedEntities = EntityHelper.filterEntitiesByIds(
                  paginatedWheelOperations?.items || [],
                  selectedIds as string[],
                );
                return (
                  <>
                    <TenantStructureMembershipOfEntityEditModal
                      open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                      onClose={() => cancelAction()}
                      tenantStructureMembershipOfEntityEditProps={{
                        entityType: EntityType.WheelOperation,
                        entities: selectedEntities,
                        onSave: () => {
                          completeAction();
                          paginatedWheelOperationsRequest.refetch();
                        },
                      }}
                    />
                    <GeneralAttachedTagsOfEntitiesEditModal
                      tagEntityType={TagEntityType.WheelOperation}
                      entities={selectedEntities}
                      open={currentAction === BulkActionFlags.UpdateTags}
                      onClose={() => cancelAction()}
                      onSaved={() => {
                        completeAction();
                        paginatedWheelOperationsRequest.refetch();
                      }}
                    />
                    <WheelOperationsDeleteModal
                      entities={EntityHelper.filterEntitiesByIds(
                        paginatedWheelOperations?.items || [],
                        selectedIds as string[],
                      )}
                      open={currentAction === BulkActionFlags.Delete}
                      onClose={() => cancelAction()}
                      onDelete={() => {
                        completeAction();
                        paginatedWheelOperationsRequest.refetch();
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </Stack>
      </ViewLayout>
    </Box>
  );
}
