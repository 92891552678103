import { StringHelper } from "./string";

interface SetItemInLocalStorageOptions {
  /** Ignore any validations/limits and force set the item. */
  isForce?: boolean;
}

export class LocalStorageHelper {
  /** Max size of the whole local storage per origin (browser limit).
   * https://developer.mozilla.org/en-US/docs/Web/API/Storage_API/Storage_quotas_and_eviction_criteria#web_storage */
  public static maxTotalSizeInBytes = 5 * 1024 * 1024; // 5 MiB
  /** Max size of a single item in the local storage (custom limit). */
  public static maxItemSizeInBytes = 100 * 1024; // 100 KiB

  public static getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /** Reads string value, parses JSON and returns obtained value. */
  public static getJsonItem(key: string): any | null {
    const serialized = localStorage.getItem(key);
    if (!serialized) {
      return null;
    }
    try {
      const value = JSON.parse(serialized);
      return value;
    } catch (err) {
      console.error(
        "LocalStorageHelper. Unable to parse JSON value from local storage.",
        serialized,
        err,
      );
      return null;
    }
  }

  /** Reads string value, parses JSON and returns obtained typed value. */
  public static getTypedJsonItem<TItem>(key: string): TItem | null {
    return this.getJsonItem(key) as TItem;
  }

  public static setItem(key: string, value: string, options?: SetItemInLocalStorageOptions): void {
    if (!options?.isForce) {
      this.isValidItemOrThrow(value);
    }
    localStorage.setItem(key, value);
  }

  /** Serializes the value to JSON and sets it. */
  public static setJsonItem(key: string, value: any, options?: SetItemInLocalStorageOptions): void {
    if (value) {
      const json = JSON.stringify(value);
      this.setItem(key, json, options);
    }
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /** Checks item is valid to be saved to the local storage. */
  public static isValidItem(value: string): boolean {
    const sizeBytes = StringHelper.getSizeInBytes(value);
    const isSizeValid = sizeBytes <= this.maxItemSizeInBytes;
    return isSizeValid;
  }

  /** Checks item is valid to be saved to the local storage. */
  public static isValidItemOrThrow(value: string): void {
    if (!this.isValidItem(value)) {
      console.error(
        "LocalStorageHelper. Invalid item to be saved to local storage:",
        {
          sizeBytes: StringHelper.getSizeInBytes(value),
          sizeKiB: StringHelper.getSizeInKiB(value),
          sizeMiB: StringHelper.getSizeInMiB(value),
          maxTotalSizeInBytes: this.maxTotalSizeInBytes,
          maxItemSizeInBytes: this.maxItemSizeInBytes,
        },
        value,
      );
      throw new Error("Invalid item to be saved to local storage.");
    }
  }
}
