import HelpIcon from "@mui/icons-material/Help";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import VehicleGenerationsAutocomplete from "@/common/components/Entity/ReferenceData/VehicleGeneration/VehicleGenerationsAutocomplete";
import VehicleMakesAutocomplete from "@/common/components/Entity/ReferenceData/VehicleMake/VehicleMakesAutocomplete";
import VehicleModelsAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModel/VehicleModelsAutocomplete";
import VehicleModificationsAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModification/VehicleModificationsAutocomplete";
import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import ApiEnumMultiselect from "@/common/components/Enum/ApiEnumMultiselect";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import VehicleVisualModelImageUploader from "@/common/components/Files/VehicleVisualModelImageUploader";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateVehicleVisualModelDto,
  AdminCreateVehicleVisualModelImageDto,
  AdminUpdateVehicleVisualModelDto,
  AdminUpdateVehicleVisualModelImageDto,
  AdminVehicleVisualModelImageDto,
  VehicleArea,
  VehicleProjection,
  VehicleVisualModelType,
} from "@/core/api/generated";

import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

type ImageArrayItemType = AdminCreateVehicleVisualModelImageDto &
  AdminUpdateVehicleVisualModelImageDto & {
    initialFile?: AdminVehicleVisualModelImageDto["file"];
  };

export default function VehicleVisualModelCreateUpdatePage() {
  const { visualModelId } = useParams<{ visualModelId?: string }>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const isCreate = !visualModelId;
  const isEdit = !!visualModelId;

  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmUpdateModalOpen, setConfirmUpdateModalOpen] = useState(false);
  const updateParamsRef = useRef<
    | {
        visualModelId: string;
        currentVersion: string;
        nextVersion: string;
        data: AdminUpdateVehicleVisualModelDto;
      }
    | undefined
  >(undefined);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const visualModelRequest = useApiRequest(
    apiClient.adminVehicleVisualModelsApi
      .apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdGet,
    {
      visualModelId: visualModelId!,
    },
    {
      skip: !visualModelId,
    },
  );
  const visualModel = visualModelRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: visualModel && {
      idValue: visualModel.id!,
      newTitle: visualModel.name || visualModel.globalNumber || "",
    },
  });

  const handleUpdate = async (forceUpdateConfirmed?: boolean) => {
    try {
      setIsUpdating(true);
      await apiClient.adminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdPut(
        {
          visualModelId: updateParamsRef.current!.visualModelId,
          adminUpdateVehicleVisualModelDto: {
            ...updateParamsRef.current!.data,
            isForceUpdate: updateParamsRef.current!.data.isForceUpdate && forceUpdateConfirmed,
          },
        },
      );
      enqueueSnackbar("Visual model updated.", { variant: "success" });
      setConfirmUpdateModalOpen(false);
      history.goBack();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box>
      <Formik<
        BaseFormikValues &
          AdminCreateVehicleVisualModelDto &
          AdminUpdateVehicleVisualModelDto & {
            images: Array<ImageArrayItemType>;
            version?: string | null;
          }
      >
        enableReinitialize={true}
        initialValues={{
          isForceUpdate: false,
          type: visualModel?.type || VehicleVisualModelType.TwoDimensionalDivided,
          vehicleTypes: visualModel?.vehicleTypes || undefined,
          bodyTypes: visualModel?.bodyTypes || undefined,
          makeIds: visualModel?.makes?.map((x) => x.id!) || undefined,
          modelIds: visualModel?.models?.map((x) => x.id!) || undefined,
          generationIds: visualModel?.generations?.map((x) => x.id!) || undefined,
          modificationIds: visualModel?.modifications?.map((x) => x.id!) || undefined,
          name: visualModel?.name || undefined,
          isDefault: visualModel?.isDefault || false,
          isEnabled: visualModel?.isEnabled || false,
          images:
            visualModel?.images?.map((x) => ({
              ...x,
              initialFile: x?.file || undefined,
            })) ||
            [
              VehicleProjection.Front,
              VehicleProjection.Back,
              VehicleProjection.Left,
              VehicleProjection.Right,
              VehicleProjection.Top,
            ].map((projection) => ({
              initialFile: undefined,
              file: undefined,
              area: VehicleArea.Exterior,
              projection: projection,
            })),

          version: visualModel?.version || "1.0.0",
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // type: Yup.string().required("Vehicle type is required"),
          // name: Yup.string().required("Name is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              await apiClient.adminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsPost(
                {
                  adminCreateVehicleVisualModelDto: {
                    ...values,
                  },
                },
              );
              enqueueSnackbar("Visual model created.", { variant: "success" });
              history.goBack();
            } else {
              const updateData = {
                ...values,
              };

              // ask user for confirmation if version will change after update
              const nextVersion = (
                await apiClient.adminVehicleVisualModelsApi.apiV1AdminReferenceDataVehiclesVisualModelsVisualModelIdUpdateNextVersionPost(
                  {
                    visualModelId,
                    adminUpdateVehicleVisualModelDto: updateData,
                  },
                )
              ).data;

              updateParamsRef.current = {
                visualModelId,
                currentVersion: visualModel!.version!,
                nextVersion: nextVersion.version!,
                data: updateData,
              };

              if (nextVersion.version !== visualModel?.version || values.isForceUpdate) {
                setConfirmUpdateModalOpen(true);
              } else {
                await handleUpdate();
              }
            }

            if (mounted.current) {
              setStatus({ success: true });
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
          submitForm,
        }) => {
          const canEditImages = values.type && values.type !== VehicleVisualModelType.None;
          return (
            <CreateUpdatePageLayout
              header={
                <SimpleViewPageHeader
                  title={isCreate ? "Create new vehicle visual model" : "Edit vehicle visual model"}
                />
              }
            >
              {visualModelRequest.isLoading && <LinearProgress sx={{ mb: 2 }} />}

              <form noValidate onSubmit={handleSubmit}>
                {visualModel && isEdit && (
                  <ReferenceDataSyncStopAlert
                    sx={{ mb: 2 }}
                    isBuiltIn={visualModel.isBuiltIn ?? false}
                    car2DbId={undefined}
                  />
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    width: "100%",
                    minHeight: 0,
                    overflow: "auto",
                  }}
                >
                  <TextField
                    required
                    disabled
                    error={Boolean(errors.version)}
                    fullWidth
                    helperText={errors.version}
                    label='Version'
                    margin='dense'
                    name='version'
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    type='text'
                    value={values.version}
                    variant='outlined'
                  />

                  <FormControl
                    sx={{ minWidth: 200 }}
                    margin='dense'
                    error={Boolean(touched.type && errors.type)}
                  >
                    <InputLabel required>Type</InputLabel>
                    <ApiEnumSelect
                      type='VehicleVisualModelType'
                      value={values.type}
                      onChange={(newValue) => setFieldValue("type", newValue)}
                      selectProps={{
                        required: true,
                        label: "Type",
                      }}
                    />
                    <FormHelperText>{touched.type && errors.type}</FormHelperText>
                  </FormControl>

                  <TextField
                    autoFocus
                    required
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  <TextField
                    autoFocus
                    rows={2}
                    error={Boolean(touched.sortOrder && errors.sortOrder)}
                    fullWidth
                    helperText={touched.sortOrder && errors.sortOrder}
                    label='SortOrder'
                    margin='dense'
                    name='sortOrder'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputMode='numeric'
                    value={values.sortOrder || 0}
                    variant='outlined'
                    InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                  />

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='isDefault'
                          checked={values.isDefault}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label={
                        <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
                          <Typography component='span'>Default model</Typography>
                          <Tooltip title='Default model is used when visual model is requested without specific parameters/filters.'>
                            <IconButton size='extraSmall'>
                              <HelpIcon fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      }
                    />
                  </FormGroup>

                  <Divider sx={{ my: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        size='medium'
                        error={Boolean(touched.vehicleTypes && errors.vehicleTypes)}
                      >
                        <ApiEnumMultiselect
                          type='VehicleType'
                          label='Vehicle types'
                          size='medium'
                          values={values.vehicleTypes}
                          onChange={(newValues) => setFieldValue("vehicleTypes", newValues)}
                        />
                        <FormHelperText>Leave empty to match all vehicle types</FormHelperText>
                        <FormHelperText>
                          {touched.vehicleTypes && errors.vehicleTypes}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl
                        sx={{ minWidth: 200 }}
                        fullWidth
                        margin='dense'
                        size='medium'
                        error={Boolean(touched.bodyTypes && errors.bodyTypes)}
                      >
                        <ApiEnumMultiselect
                          type='VehicleBodyType'
                          label='Body types'
                          size='medium'
                          values={values.bodyTypes}
                          onChange={(newValues) => setFieldValue("bodyTypes", newValues)}
                        />
                        <FormHelperText>Leave empty to match all body types</FormHelperText>
                        <FormHelperText>{touched.bodyTypes && errors.bodyTypes}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xxs={12} md={6}>
                      <FormControl margin='dense' fullWidth error={Boolean(errors.makeIds)}>
                        <VehicleMakesAutocomplete
                          isPreload
                          entityIds={values.makeIds}
                          entities={undefined}
                          searchFilters={{
                            vehicleTypes: values.vehicleTypes || undefined,
                          }}
                          onChange={(newValues) => {
                            setFieldValue(
                              "makeIds",
                              newValues?.map((x) => x.id!),
                            );
                            setFieldValue("modelIds", undefined);
                            setFieldValue("generationIds", undefined);
                            setFieldValue("modificationIds", undefined);
                          }}
                          disabled={!values.type || !values.vehicleTypes}
                          textFieldProps={{
                            error: Boolean(errors.makeIds),
                            helperText: ValidationHelper.getFormikErrorsAsString(errors.makeIds),
                          }}
                        />
                        <FormHelperText>Leave empty to match all makes</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl margin='dense' fullWidth error={Boolean(errors.modelIds)}>
                        <VehicleModelsAutocomplete
                          isPreload
                          entityIds={values.modelIds}
                          entities={undefined}
                          searchFilters={{
                            vehicleTypes: values.vehicleTypes,
                            makeIds: values.makeIds,
                          }}
                          onChange={(newValues) => {
                            setFieldValue(
                              "modelIds",
                              newValues?.map((x) => x.id),
                            );
                            setFieldValue("generationIds", undefined);
                            setFieldValue("modificationIds", undefined);
                          }}
                          disabled={
                            !values.type ||
                            TypeHelper.isEmpty(values.vehicleTypes) ||
                            TypeHelper.isEmpty(values.makeIds)
                          }
                        />
                        <FormHelperText>Leave empty to match all models</FormHelperText>
                        <FormHelperText>{errors.modelIds}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xxs={12} md={6}>
                      <FormControl margin='dense' fullWidth error={Boolean(errors.generationIds)}>
                        <VehicleGenerationsAutocomplete
                          isPreload
                          entityIds={values.generationIds}
                          entities={undefined}
                          searchFilters={{
                            vehicleTypes: values.vehicleTypes!,
                            makeIds: values.makeIds!,
                            modelIds: values.modelIds!,
                          }}
                          onChange={(newValues) => {
                            setFieldValue(
                              "generationIds",
                              newValues?.map((x) => x.id),
                            );
                            setFieldValue("modificationIds", undefined);
                          }}
                          disabled={
                            !values.type ||
                            TypeHelper.isEmpty(values.vehicleTypes) ||
                            TypeHelper.isEmpty(values.makeIds) ||
                            TypeHelper.isEmpty(values.modelIds)
                          }
                        />
                        <FormHelperText>Leave empty to match all generations</FormHelperText>
                        <FormHelperText>{errors.generationIds}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      <FormControl margin='dense' fullWidth error={Boolean(errors.modificationIds)}>
                        <VehicleModificationsAutocomplete
                          isPreload
                          entityIds={values.modificationIds}
                          entities={undefined}
                          searchFilters={{
                            vehicleTypes: values.vehicleTypes!,
                            makeIds: values.makeIds!,
                            modelIds: values.modelIds!,
                            generationIds: values.generationIds!,
                          }}
                          onChange={(newValues) => {
                            setFieldValue(
                              "modificationIds",
                              newValues?.map((x) => x.id!),
                            );
                          }}
                          disabled={
                            !values.type ||
                            TypeHelper.isEmpty(values.vehicleTypes) ||
                            TypeHelper.isEmpty(values.makeIds) ||
                            TypeHelper.isEmpty(values.modelIds) ||
                            TypeHelper.isEmpty(values.generationIds)
                          }
                          textFieldProps={{
                            error: Boolean(errors.modificationIds),
                            helperText: ValidationHelper.getFormikErrorsAsString(
                              errors.modificationIds,
                            ),
                          }}
                        />
                        <FormHelperText>Leave empty to match all modifications</FormHelperText>
                        <FormHelperText>{errors.modificationIds}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {/* Images */}
                <Divider sx={{ my: 2 }} />
                <Typography component='div' variant='h6' sx={{ mb: 2 }}>
                  Images ({values.images?.length || 0})
                </Typography>
                <Stack direction='column' spacing={1}>
                  {values.images?.map((item, index) => (
                    <Card key={index}>
                      <CardContent>
                        <Stack
                          direction={{ xxs: "column", xs: "column", sm: "column", md: "row" }}
                          spacing={1}
                        >
                          <Typography
                            component='div'
                            variant='caption'
                            color='text.secondary'
                            sx={{ mb: 1 }}
                          >
                            Image {index + 1}
                          </Typography>

                          <Stack
                            direction={{ xxs: "column", md: "row" }}
                            spacing={1}
                            sx={{ flex: 1, justifyContent: { xs: "stretch", md: "flex-end" } }}
                          >
                            <Button
                              sx={{ marginTop: "-6px" }}
                              variant='text'
                              size='small'
                              onClick={() =>
                                setFieldValue(
                                  "images",
                                  values.images!.filter((x, j) => j !== index),
                                )
                              }
                            >
                              Remove image
                            </Button>
                          </Stack>
                        </Stack>

                        <Grid container spacing={2}>
                          <Grid item xxs={12} md={6}>
                            <FormControl margin='dense' fullWidth>
                              <ApiEnumAutocomplete
                                type='VehicleArea'
                                onlyEnumValues={[VehicleArea.Exterior]}
                                value={item.area}
                                required
                                label='Area'
                                textFieldProps={{
                                  error: Boolean(getIn(errors, `images[${index}].area`)),
                                  helperText: ValidationHelper.getFormikErrorsAsString(
                                    getIn(errors, `images[${index}].area`),
                                  ),
                                }}
                                onChange={(newValue) =>
                                  setFieldValue(`images[${index}].area`, newValue)
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xxs={12} md={6}>
                            <FormControl margin='dense' fullWidth>
                              <ApiEnumAutocomplete
                                type='VehicleProjection'
                                onlyEnumValues={[
                                  VehicleProjection.Front,
                                  VehicleProjection.Back,
                                  VehicleProjection.Left,
                                  VehicleProjection.Right,
                                  VehicleProjection.Top,
                                ]}
                                value={item.projection}
                                required
                                label='Projection'
                                textFieldProps={{
                                  error: Boolean(getIn(errors, `images[${index}].projection`)),
                                  helperText: ValidationHelper.getFormikErrorsAsString(
                                    getIn(errors, `images[${index}].projection`),
                                  ),
                                }}
                                onChange={(newValue) =>
                                  setFieldValue(`images[${index}].projection`, newValue)
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        {((item as ImageArrayItemType).initialFile ||
                          isCreate ||
                          (item as ImageArrayItemType).initialFile === null) && (
                          <FormControl fullWidth margin='dense'>
                            <VehicleVisualModelImageUploader
                              disabled={!canEditImages}
                              defaultFiles={
                                (item as ImageArrayItemType).initialFile
                                  ? FileItem.createManyFrom([
                                      (item as ImageArrayItemType).initialFile!,
                                    ])
                                  : []
                              }
                              onChange={(files) =>
                                setFieldValue(
                                  `images[${index}].file`,
                                  files.length !== 0
                                    ? FileItem.toUploadedFileDto(files[0])
                                    : undefined,
                                )
                              }
                              onUploadStarted={() => {
                                setIsAttachmentFilesUploading(true);
                              }}
                              onUploadFinished={() => {
                                setIsAttachmentFilesUploading(false);
                              }}
                              modelType={values.type}
                            />
                            <FormHelperText error>
                              {getIn(errors, `images[${index}].file`)}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
                <Box sx={{ mt: 2 }}>
                  <Button
                    disabled={!canEditImages}
                    variant='text'
                    onClick={() =>
                      setFieldValue("images", [
                        ...(values.images || []),
                        {
                          area: VehicleArea.Exterior,
                          initialFile: null,
                        },
                      ])
                    }
                  >
                    Add image
                  </Button>
                </Box>
                <FormHelperText error={!!errors.images && _.isString(errors.images)}>
                  {!!errors.images && _.isString(errors.images) ? errors.images.toString() : ""}
                </FormHelperText>

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>

                <Divider sx={{ my: 2 }} />

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Stack direction={{ xxs: "column", md: "row" }} spacing={1}>
                  <LoadingButton
                    sx={{ flex: 1 }}
                    color='primary'
                    disabled={!values.name || isAttachmentFilesUploading}
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                  {!isCreate && (
                    <LoadingButton
                      color='primary'
                      disabled={!values.name || isAttachmentFilesUploading}
                      loading={isSubmitting}
                      type='button'
                      variant='contained'
                      onClick={() => {
                        setFieldValue("isForceUpdate", true);
                        submitForm();
                      }}
                    >
                      Force save!
                    </LoadingButton>
                  )}
                </Stack>
              </form>
            </CreateUpdatePageLayout>
          );
        }}
      </Formik>

      {/* Confirm update */}
      <Dialog open={confirmUpdateModalOpen} onClose={() => setConfirmUpdateModalOpen(false)}>
        <AppModalTitle onCloseClicked={() => setConfirmUpdateModalOpen(false)}>
          {updateParamsRef.current?.data?.isForceUpdate
            ? "Force update visual model?"
            : "Update visual model?"}
        </AppModalTitle>
        <DialogContent>
          {updateParamsRef.current?.data?.isForceUpdate && (
            <>
              <DialogContentText>
                {`Confirm you want to force save the changes you made to the current version of the model (new version won't be created).`}
              </DialogContentText>
              <DialogContentText>
                {`Your changes might break components using the current version.
                We recommend to proceed only if you totally acknowledge the action and the consequences.
                `}
              </DialogContentText>
              <DialogContentText>
                {`Current version: v${updateParamsRef.current?.currentVersion}.`}
              </DialogContentText>
              <DialogContentText>{`New version: v${updateParamsRef.current?.nextVersion}.`}</DialogContentText>
            </>
          )}
          {!updateParamsRef.current?.data?.isForceUpdate && (
            <>
              <DialogContentText>
                {`You made breaking changes to the visual model v${updateParamsRef.current?.currentVersion}. 
                If you update the model, a new v${updateParamsRef.current?.nextVersion} model will be created with the updated data. 
                `}
              </DialogContentText>
              <DialogContentText
                sx={{ mt: 1 }}
                fontSize='small'
              >{`Use "Force save" if you don't want a new version to be created".`}</DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='text' onClick={() => setConfirmUpdateModalOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            loading={isUpdating}
            onClick={() => handleUpdate(updateParamsRef.current?.data?.isForceUpdate || false)}
            autoFocus
          >
            Yes, update!
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
