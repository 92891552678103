import { Box, Stack, Typography } from "@mui/material";

import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import StrikethroughText from "@/common/components/Text/StrikethroughText";
import { NegotiationDto, NegotiationProposalDto, NegotiationType } from "@/core/api/generated";

import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";

interface OwnProps {
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
}

type Props = OwnProps;

export default function NegotiationProposalValueInline({ negotiation, proposal }: Props) {
  return (
    <Box component='span'>
      {negotiation.type === NegotiationType.Value && proposal.value && (
        <>
          {/* Price */}
          {proposal.value.price && (
            <Box component='span'>
              {/* Initial price */}
              {proposal.value.price.initial && (
                <AppPopover
                  hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
                  trigger={
                    <AppTypography
                      sx={{ mr: 1 }}
                      component='span'
                      decoration={{
                        enabled: true,
                        variant: "helpText",
                      }}
                    >
                      <StrikethroughText
                        component='span'
                        variant='crossSingle'
                        lineProps={{
                          style: "solid",
                          color: (th) => th.palette.error.dark,
                          thickness: "2px",
                        }}
                      >
                        <GeneralPriceDisplay
                          price={{
                            price: proposal.value.price.initial?.total,
                            currency: proposal.value.price.initial?.currency,
                          }}
                          withTooltip={false}
                        />
                      </StrikethroughText>
                    </AppTypography>
                  }
                >
                  <AppPopoverContent>
                    <Stack spacing={2}>
                      <Typography variant='h6'>Initial price</Typography>

                      <GeneralPriceSummaryDisplay
                        summary={{
                          currency:
                            proposal?.value?.price?.initial?.currency ||
                            proposal?.value?.price?.initial?.price?.currency,
                          subTotal: proposal?.value?.price?.initial?.subTotal,
                          discount: proposal?.value?.price?.initial?.discount,
                          tax: proposal?.value?.price?.initial?.tax,
                          total: proposal?.value?.price?.initial?.total,
                        }}
                        displayProps={{
                          calcExplanation: true,
                        }}
                      />
                    </Stack>
                  </AppPopoverContent>
                </AppPopover>
              )}

              {/* Current price */}
              <AppPopover
                hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
                trigger={
                  <AppTypography
                    component='span'
                    decoration={{
                      enabled: true,
                      variant: "helpText",
                    }}
                  >
                    <GeneralPriceDisplay
                      price={{
                        price: proposal.value.price.total,
                        currency: proposal.value.price.currency,
                      }}
                      withTooltip={false}
                    />
                  </AppTypography>
                }
              >
                <AppPopoverContent>
                  <Stack spacing={2}>
                    <Typography variant='h6'>Current price</Typography>

                    <GeneralPriceSummaryDisplay
                      summary={{
                        currency:
                          proposal?.value?.price?.currency ||
                          proposal?.value?.price?.price?.currency,
                        subTotal: proposal?.value?.price?.subTotal,
                        discount: proposal?.value?.price?.discount,
                        tax: proposal?.value?.price?.tax,
                        total: proposal?.value?.price?.total,
                      }}
                      displayProps={{
                        calcExplanation: true,
                      }}
                    />

                    {proposal?.value?.price?.prev && (
                      <>
                        <Typography variant='h6'>Prev price</Typography>

                        <GeneralPriceSummaryDisplay
                          summary={{
                            currency:
                              proposal?.value?.price?.prev?.currency ||
                              proposal?.value?.price?.prev?.price?.currency,
                            subTotal: proposal?.value?.price?.prev?.subTotal,
                            discount: proposal?.value?.price?.prev?.discount,
                            tax: proposal?.value?.price?.prev?.tax,
                            total: proposal?.value?.price?.prev?.total,
                          }}
                          displayProps={{
                            calcExplanation: true,
                          }}
                        />
                      </>
                    )}
                  </Stack>
                </AppPopoverContent>
              </AppPopover>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
