export class EncodingHelper {
  public static toBase64(value: string): string {
    return btoa(value);
    // return Buffer.from(value).toString("base64");
  }

  public static fromBase64(base64Value: string): string {
    return atob(base64Value);
    // return Buffer.from(base64Value, "base64").toString();
  }
}
