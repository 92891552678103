import { Tab, TabProps, alpha, styled, tabClasses } from "@mui/material";
import { forwardRef } from "react";

export interface AppTabProps extends TabProps {
  /** Custom variant. */
  variant2?: "default" | "contained" | "outlined";
  size?: "extraSmall" | "small" | "medium";
}

const TabStyled = styled(Tab)<AppTabProps>(({ theme, variant2, size }) => ({
  borderTopLeftRadius: theme.shapeCustom.borderRadiusTab,
  borderTopRightRadius: theme.shapeCustom.borderRadiusTab,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  ...(variant2 === "default" ? {} : {}),

  ...(variant2 === "contained"
    ? {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        border: "none",
        "&:hover": {
          backgroundColor: theme.palette.secondary.dark,
        },
        "&:disabled": {
          color: theme.palette.action.disabled,
          backgroundColor: theme.palette.action.disabledBackground,
          borderColor: theme.palette.action.disabled,
        },
        [`&.${tabClasses.selected}`]: {
          color: theme.palette.secondary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      }
    : {}),

  ...(variant2 === "outlined"
    ? {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: alpha(theme.palette.secondary.main, 0.5),
        "&:hover": {
          borderColor: alpha(theme.palette.secondary.main, 1),
        },
        "&:disabled": {
          color: theme.palette.action.disabled,
          backgroundColor: "transparent",
          borderColor: theme.palette.action.disabled,
        },
        [`&.${tabClasses.selected}`]: {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      }
    : {}),

  ...(size === "extraSmall"
    ? {
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
        minWidth: 50,
        minHeight: "unset",
        ...theme.typography.body2,
      }
    : {}),

  ...(size === "small"
    ? {
        padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        minWidth: 70,
        minHeight: "unset",
        ...theme.typography.button,
      }
    : {}),

  ...(size === "medium"
    ? {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        minWidth: 90,
        minHeight: "unset",
        ...theme.typography.button,
      }
    : {}),
}));

/** Extends MUI Tab. */
export default forwardRef<HTMLDivElement, AppTabProps>(function AppTab(
  { variant2 = "default", size = "medium", children, ...otherProps }: AppTabProps,
  ref,
) {
  return (
    <TabStyled ref={ref} variant2={variant2} size={size} {...otherProps}>
      {children}
    </TabStyled>
  );
});
