import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, LocationDto, LocationReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import LocationInline, { LocationInlineProps } from "./LocationInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: LocationDto | LocationReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<LocationInlineProps>;
}

export default function LocationLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.LocationRead])}
      to={ROUTE_PATH.LOCATION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <LocationInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Location"}
    />
  );
}
