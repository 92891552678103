import { Box, Dialog, DialogContent, DialogProps } from "@mui/material";

import TenantRequestView, { TenantRequestViewProps } from "./TenantRequestView";

export interface OwnProps {
  viewProps: TenantRequestViewProps;
}

type Props = OwnProps & DialogProps;

export default function TenantRequestViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <DialogContent>
        <Box sx={{ pb: 2 }}>
          <TenantRequestView {...viewProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
