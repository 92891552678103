import { Box, SxProps, Theme } from "@mui/material";
import _ from "lodash";

interface Props {
  value?: boolean | null;
  /** Of container. */
  sx?: SxProps<Theme>;
}

/** Displays boolean value. */
export default function BooleanValue({ value, sx }: Props): JSX.Element {
  return (
    <Box component='span' sx={sx}>
      {_.isNil(value) && "-"}
      {!_.isNil(value) ? (value && "yes") || "no" : ""}
    </Box>
  );
}
