import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import * as Yup from "yup";

import { useChatUserSetting } from "@/common/hooks/communication/useChatUserSetting";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { enumService } from "@/common/services/enum";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import {
  ChatEventCategory,
  ChatEventType,
  ChatUserSettingsDto,
  GetChatUserSettingsResultDto,
  UpdateChatUserSettingsDto,
} from "@/core/api/generated";
import * as chatUserSettingsSlice from "@/store/communication/chatUserSettingsSlice";

import FoldableBlock from "../../Display/FoldableBlock";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";

export interface UpdateChatUserSettingsOwnProps {
  chatId: string;
  onUpdate?: (newValue: GetChatUserSettingsResultDto) => void;
  onSave?: (newValue: GetChatUserSettingsResultDto) => void;
}

export type UpdateChatUserSettingsProps = UpdateChatUserSettingsOwnProps;

export default function UpdateChatUserSettings({
  chatId,
  onUpdate,
  onSave,
}: UpdateChatUserSettingsProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();
  const chatUserSettings = useChatUserSetting({ chatId, refetchIfFetchedBefore: true });

  type EnabledCategoriesAndTypesMap = Record<
    ChatEventCategory,
    {
      category: ChatEventCategory;
      enabled: boolean;
      categoryTypes: ChatEventType[];
      categoryTypesMap: Record<
        ChatEventType,
        { category: ChatEventCategory; type: ChatEventType; enabled: boolean }
      >;
    }
  >;

  const calculateInitialEnabledCategoriesAndTypesMap = useCallback(
    (settings: ChatUserSettingsDto | undefined) => {
      const newMap: EnabledCategoriesAndTypesMap = {} as EnabledCategoriesAndTypesMap;

      const categories = enumService.getEnumValues("ChatEventCategory", {
        except: [ChatEventCategory.None],
      });
      categories.forEach((category) => {
        const categoryTypes = enumService.getEnumValues("ChatEventType", {
          except: [ChatEventType.None],
          linkedTo: { type: "ChatEventCategory", value: category },
        });

        const isCategoryEnabled = (settings?.event?.disabledCategoriesMap || {})[category] !== true;
        const isCategoryEnabledComputed =
          isCategoryEnabled &&
          // category is enabled when all types are enabled
          categoryTypes.every((type) => (settings?.event?.disabledTypesMap || {})[type] !== true);

        newMap[category] = {
          category,
          enabled: isCategoryEnabledComputed,
          categoryTypes: categoryTypes,
          categoryTypesMap: _.chain(categoryTypes)
            .keyBy((type) => type)
            .mapValues((type) => ({
              category: category,
              type: type,
              enabled: (settings?.event?.disabledTypesMap || {})[type] !== true,
            }))
            .value() as Record<
            ChatEventType,
            { category: ChatEventCategory; type: ChatEventType; enabled: boolean }
          >,
        };
      });

      return newMap;
    },
    [],
  );

  const recalculateEnabledCategoriesAndTypesMap = useCallback(
    (
      map: EnabledCategoriesAndTypesMap,
      whatChanged: "category" | "type",
    ): EnabledCategoriesAndTypesMap => {
      const newMap: EnabledCategoriesAndTypesMap = _.cloneDeep(map);

      if (whatChanged == "category") {
        Object.values(newMap).forEach((categoryData) => {
          const isCategoryEnabled = categoryData.enabled;

          if (isCategoryEnabled) {
            Object.values(categoryData.categoryTypesMap).forEach((typeData) => {
              typeData.enabled = true;
            });
          } else {
            Object.values(categoryData.categoryTypesMap).forEach((typeData) => {
              typeData.enabled = false;
            });
          }
        });
      } else if (whatChanged == "type") {
        Object.values(newMap).forEach((categoryData) => {
          const isAllSubTypesEnabled = Object.values(categoryData.categoryTypesMap).every(
            (x) => x.enabled === true,
          );
          const isAnySubTypeDisabled = Object.values(categoryData.categoryTypesMap).some(
            (x) => x.enabled === false,
          );

          if (isAllSubTypesEnabled) {
            categoryData.enabled = true;
          } else if (isAnySubTypeDisabled) {
            categoryData.enabled = false;
          }
        });
      }

      return newMap;
    },
    [],
  );

  const initialEnabledCategoriesAndTypesMap = useMemo(() => {
    return calculateInitialEnabledCategoriesAndTypesMap(chatUserSettings?.settings);
  }, [chatUserSettings]);

  return (
    <Formik<
      UpdateChatUserSettingsDto &
        BaseFormikValues & {
          enabledCategoriesAndTypesMap: EnabledCategoriesAndTypesMap;
        }
    >
      enableReinitialize
      initialValues={{
        chatId: chatId,
        isGlobal: false,
        settingsId: chatUserSettings?.settings?.id || undefined,
        event: chatUserSettings?.settings?.event || undefined,

        enabledCategoriesAndTypesMap: initialEnabledCategoriesAndTypesMap,
        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const result = await thunkDispatch(
            chatUserSettingsSlice.updateChatUserSettings({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              updateChatUserSettingsDto: {
                chatId: values.chatId,
                isGlobal: values.isGlobal,
                settingsId: values.settingsId,
                event: {
                  // convert enabled map to disabled map
                  disabledCategoriesMap: _.chain(Object.values(values.enabledCategoriesAndTypesMap))
                    // pick disabled only
                    .pickBy((v) => v.enabled === false)
                    .keyBy((v) => v.category)
                    .mapValues((v, k) => true)
                    .value(),
                  disabledTypesMap: _.chain(Object.values(values.enabledCategoriesAndTypesMap))
                    //.pickBy((v, k) => v.enabled === true) // skip disabled categories
                    .map((x) => Object.values(x.categoryTypesMap))
                    .flatten()
                    .pickBy(
                      (v, k) =>
                        v.enabled === false &&
                        values.enabledCategoriesAndTypesMap[v.category].enabled === false,
                    )
                    .keyBy((v) => v.type)
                    .mapValues((v, k) => true)
                    .value(),
                },
              },
            }),
          );
          enqueueSnackbar("Settings updated.", { variant: "success" });
          onUpdate && onUpdate(result);
          onSave && onSave(result);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isGlobal}
                      onChange={(e) => {
                        const isGlobal = e.target.checked;
                        const newSettings = isGlobal
                          ? chatUserSettings?.globalSettings
                          : chatUserSettings?.chatSettings;

                        setFieldValue("isGlobal", isGlobal);
                        setFieldValue(
                          "enabledCategoriesAndTypesMap",
                          calculateInitialEnabledCategoriesAndTypesMap(newSettings),
                        );
                      }}
                    />
                  }
                  label={values.isGlobal ? "For all chats (global)" : "Only for this chat (local)"}
                />
              </FormGroup>

              <FoldableBlock
                defaultIsFolded={false}
                trigger={{
                  label: (
                    <Typography component='div' variant='subtitle1'>
                      Chat events
                    </Typography>
                  ),
                }}
              >
                <Stack spacing={1}>
                  <FormHelperText>Configure which events you want to see in chat.</FormHelperText>

                  <Box>
                    <Stack direction='row' spacing={1}>
                      <Button
                        variant='outlined'
                        color='text'
                        size='small'
                        onClick={() => {
                          Object.values(values.enabledCategoriesAndTypesMap).forEach(
                            (categoryData) => (categoryData.enabled = true),
                          );
                          setFieldValue(
                            "enabledCategoriesAndTypesMap",
                            recalculateEnabledCategoriesAndTypesMap(
                              values.enabledCategoriesAndTypesMap,
                              "category",
                            ),
                          );
                        }}
                      >
                        Enable all
                      </Button>
                      <Button
                        variant='outlined'
                        color='text'
                        size='small'
                        onClick={() => {
                          Object.values(values.enabledCategoriesAndTypesMap).forEach(
                            (categoryData) => (categoryData.enabled = false),
                          );
                          setFieldValue(
                            "enabledCategoriesAndTypesMap",
                            recalculateEnabledCategoriesAndTypesMap(
                              values.enabledCategoriesAndTypesMap,
                              "category",
                            ),
                          );
                        }}
                      >
                        Disable all
                      </Button>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack spacing={2}>
                      {Object.values(values.enabledCategoriesAndTypesMap).map((categoryData, i) => (
                        <Box key={i}>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{ p: 0.5 }}
                                  size='small'
                                  checked={
                                    values.enabledCategoriesAndTypesMap[categoryData.category]
                                      .enabled || false
                                  }
                                  onChange={(e) => {
                                    values.enabledCategoriesAndTypesMap[
                                      categoryData.category
                                    ].enabled = e.target.checked;
                                    setFieldValue(
                                      "enabledCategoriesAndTypesMap",
                                      recalculateEnabledCategoriesAndTypesMap(
                                        values.enabledCategoriesAndTypesMap,
                                        "category",
                                      ),
                                    );
                                  }}
                                />
                              }
                              label={
                                <InlineApiEnumValue
                                  type='ChatEventCategory'
                                  value={categoryData.category}
                                />
                              }
                            />
                          </Box>

                          <Stack sx={{ pl: 4 }}>
                            {Object.values(categoryData.categoryTypesMap).map((typeData, j) => (
                              <Box key={j}>
                                <Box>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{ p: 0.5 }}
                                        size='small'
                                        checked={
                                          values.enabledCategoriesAndTypesMap[categoryData.category]
                                            .categoryTypesMap[typeData.type].enabled || false
                                        }
                                        onChange={(e) => {
                                          values.enabledCategoriesAndTypesMap[
                                            categoryData.category
                                          ].categoryTypesMap[typeData.type].enabled =
                                            e.target.checked;
                                          setFieldValue(
                                            "enabledCategoriesAndTypesMap",
                                            recalculateEnabledCategoriesAndTypesMap(
                                              values.enabledCategoriesAndTypesMap,
                                              "type",
                                            ),
                                          );
                                        }}
                                      />
                                    }
                                    label={
                                      <InlineApiEnumValue
                                        type='ChatEventType'
                                        value={typeData.type}
                                      />
                                    }
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </FoldableBlock>
            </Box>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <FormActions>
              <LoadingButton
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </FormActions>
          </form>
        );
      }}
    </Formik>
  );
}
