import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Palette,
  PaletteColor,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import AppIcon from "@/common/components/Icons/AppIcon";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { DatetimeHelper } from "@/common/helpers/datetime";
import { notificationHubService } from "@/common/realtime/notificationHubService";
import { NotificationDto, NotificationImportance } from "@/core/api/generated";

import AppTypography from "../Text/AppTypography";

interface Props {
  notification: NotificationDto;
  onMarkNotificationAsRead: (notification: NotificationDto) => void;
  onDeleteNotification: (notification: NotificationDto) => void;
  onActionExecuted?: () => void;
}

export default function NotificationListItem({
  notification,
  onMarkNotificationAsRead,
  onDeleteNotification,
  onActionExecuted,
}: Props) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const markAsRead = () => {
    onMarkNotificationAsRead(notification);
    closeMenu();
  };

  const clear = () => {
    onDeleteNotification(notification);
    closeMenu();
  };

  if (!notification) {
    return null;
  }

  const importanceColor = {
    [NotificationImportance.None]: "secondary",
    [NotificationImportance.Low]: "secondary",
    [NotificationImportance.Medium]: "info",
    [NotificationImportance.High]: "error",
  }[notification.importance!] as any as keyof Palette;

  const notificationAction = notificationHubService.getInAppNotificationAction(notification);

  const handleClick = () => {
    if (notificationAction.hasAction && notificationAction.executeAction) {
      notificationAction.executeAction(notification);
    }
    markAsRead();
    onActionExecuted && onActionExecuted();
  };

  return (
    <ListItem
      disablePadding
      key={notification.id!}
      selected={notification.isRead === false}
      secondaryAction={
        <IconButton
          edge='end'
          aria-label='actions'
          onClick={(e) => {
            setMenuAnchorEl(e.currentTarget);
          }}
        >
          <AppIcon of='moreVert' />
        </IconButton>
      }
      sx={{
        borderLeftStyle: "solid",
        borderLeftWidth: 3,
        borderLeftColor: (theme) => (theme.palette[importanceColor] as PaletteColor)?.main,
      }}
    >
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        <MenuItem dense disabled={notification.isRead} onClick={markAsRead}>
          Mark as read
        </MenuItem>
        <MenuItem dense onClick={clear}>
          Clear
        </MenuItem>
      </Menu>

      <ListItemButton
        role={undefined}
        component={notificationAction.routeUrl ? RouterLink : Box}
        to={notificationAction.routeUrl}
        onClick={handleClick}
        sx={{ cursor: notificationAction.hasAction ? "pointer" : "default" }}
      >
        <ListItemAvatar>
          <Avatar>{/* <FolderIcon /> */}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={notification.inApp?.title}
          primaryTypographyProps={{ component: AppTypography }}
          secondary={
            <Stack direction='column' component='span'>
              <AppTypography ellipsing={{ enabled: true }}>
                {notification.inApp?.body}
              </AppTypography>
              <Typography component='span' variant='caption' color='text.secondary'>
                {DatetimeHelper.humanizeDateRangeDuration(notification.createdAt, moment())} ago at{" "}
                {notification.createdAt &&
                  moment(notification.createdAt).format(DATETIME_FORMATS.DISPLAY_DATETIME)}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
