import { LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import { entityAffiliationEventEmitter } from "@/common/eventEmmiters/entityAffiliationEventEmitter";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  IBaseEntityDto,
  PoolItemDto,
  PoolItemEntityType,
  PoolItemType,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { PoolAutocompleteProps } from "../Pool/PoolAutocomplete";
import PoolLink from "../Pool/PoolLink";
import PoolItemCreateUpdate from "./PoolItemCreateUpdate";

export interface PoolItemCreateFromEntityOwnProps {
  entityType: PoolItemEntityType;
  entity: IBaseEntityDto;
  searchFilters?: {
    pool?: PoolAutocompleteProps["searchFilters"];
  };
  onSave?: (newValue: PoolItemDto | null | undefined) => void;
}

export type PoolItemCreateFromEntityProps = PoolItemCreateFromEntityOwnProps;

export default function PoolItemCreateFromEntity({
  entityType,
  entity,
  searchFilters,
  onSave,
}: PoolItemCreateFromEntityProps) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);

  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsAssetIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetId: entityMeta?.assetMeta?.assetId || "",
    },
    {
      deps: [entityMeta?.assetMeta?.assetId],
      skip: !entityMeta?.assetMeta?.assetId,
    },
  );
  const asset = assetRequest?.data;

  const assetPoolsPaginatedRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: EntityType.Asset,
      entityId: asset?.id || "",
    },
    {
      deps: [asset?.id],
      skip: !asset?.id,
    },
  );
  const assetPoolsPaginated = assetPoolsPaginatedRequest?.data;

  const isLoading = assetRequest?.isLoading && !assetRequest.isEnded;

  const anyItemEntityTypes = useMemo(
    () =>
      _.uniq([entityType, ...(entityMeta?.assetMeta?.assetId ? [PoolItemEntityType.Asset] : [])]),
    [entityType, entityMeta?.assetMeta?.assetId],
  );
  const poolIdsComputed = useMemo(
    () => _.uniq([...(entityMeta?.poolsMeta?.poolIds || []), ...(asset?.poolsMeta?.poolIds || [])]),
    [entityMeta?.poolsMeta?.poolIds, asset?.poolsMeta?.poolIds],
  );

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        {/* {entityMeta?.assetId && (
          <Alert severity='info'>
            Showing also pools for the asset{" "}
            <AssetLink entity={undefined} entityId={entityMeta.assetId} withTooltip />.
          </Alert>
        )} */}

        <Stack spacing={1}>
          <Typography variant='subtitle1'>
            <InlineApiEnumValue type='EntityType' value={entityType} /> pools:
          </Typography>
          {_.isEmpty(entityMeta?.poolsMeta?.poolIds) && (
            <NoDataAlert variant='inline' title='No pools' />
          )}
          {!_.isEmpty(entityMeta?.poolsMeta?.poolIds) && (
            <Stack>
              {entityMeta?.poolsMeta?.poolIds?.map((id, i) => (
                <PoolLink key={i} entity={undefined} entityId={id} />
              ))}
            </Stack>
          )}
        </Stack>

        {entityMeta?.assetMeta?.assetId && (
          <Stack spacing={1}>
            <Typography variant='subtitle1'>Asset pools:</Typography>
            {assetPoolsPaginatedRequest.isLoading && <LinearProgress />}
            {!assetPoolsPaginatedRequest.isLoading && _.isEmpty(assetPoolsPaginated?.items) && (
              <NoDataAlert variant='inline' title='No pools' />
            )}
            {!_.isEmpty(assetPoolsPaginated?.items) && (
              <Stack>
                {assetPoolsPaginated?.items?.map((pool, i) => <PoolLink key={i} entity={pool} />)}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>

      {isLoading && <LinearProgress />}

      {!isLoading && (
        <PoolItemCreateUpdate
          defaultValues={{
            poolId: undefined,
            type: PoolItemType.Specific,
            entityType: entityType,
            entity: {
              entityType: entityType,
              entityId: entity.id,
            },
          }}
          searchFilters={{
            ...searchFilters,
            pool: {
              ...searchFilters?.pool,
              anyItemEntityTypes: _.isEmpty(anyItemEntityTypes) ? undefined : anyItemEntityTypes,
              excludeIds: poolIdsComputed,
            },
          }}
          onSave={(newValue) => {
            entityAffiliationEventEmitter.emit("entityPoolAffiliationChanged", newValue);
            onSave && onSave(newValue);
          }}
        />
      )}
    </Stack>
  );
}
