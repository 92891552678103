import { useParams } from "react-router-dom";

import GeneralStatusHistoryView from "@/common/components/Entity/GeneralStatus/GeneralStatusHistory/View/GeneralStatusHistoryView";

export default function GeneralStatusHistoryViewPage() {
  const { generalStatusHistoryId } = useParams<{ generalStatusHistoryId?: string }>();

  return (
    <>
      <GeneralStatusHistoryView
        generalStatusHistoryId={generalStatusHistoryId}
        generalStatusHistory={undefined}
      />
    </>
  );
}
