import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import TenantConnectionRequestCreateUpdate from "@/common/components/Entity/TenantConnectionRequest/TenantConnectionRequestCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function TenantConnectionRequestCreateUpdatePage() {
  const { tenantConnectionRequestId } = useParams<{ tenantConnectionRequestId?: string }>();
  const isCreate = !tenantConnectionRequestId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={
            isCreate ? "Create new company connection request" : "Edit company connection request"
          }
        />
      }
    >
      <TenantConnectionRequestCreateUpdate
        tenantConnectionRequestId={tenantConnectionRequestId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.TENANT_CONNECTION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
