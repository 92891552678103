import { useParams } from "react-router";

import ProductLocationView from "@/common/components/Entity/ProductLocation/View/ProductLocationView";

export default function ProductLocationViewPage() {
  const { productLocationId } = useParams<{ productLocationId?: string }>();

  return (
    <>
      <ProductLocationView
        productLocationId={productLocationId}
        productLocation={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
