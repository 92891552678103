import { Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  GeneralStatusHistoryDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../../components/BaseEntityView";
import GeneralStatusHistoryMenu from "../GeneralStatusHistoryMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum GeneralStatusHistoryViewPageTabs {
  Overview = "Overview",
}

const defaultHeaderProps = {
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  isForSubjectEntity: false,
  viewVariant: ViewLayoutVariant.Page,
};
export type GeneralStatusHistoryViewDisplayProps = typeof defaultDisplayProps;

interface OwnProps extends BaseEntityViewInheritableProps<GeneralStatusHistoryDto> {
  generalStatusHistoryId: string | null | undefined;
  generalStatusHistory: GeneralStatusHistoryDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onUpdated?: (newValue: GeneralStatusHistoryDto) => void;
  onDeleted?: () => void;
}

export type GeneralStatusHistoryViewProps = OwnProps;

export default function GeneralStatusHistoryView({
  generalStatusHistoryId,
  generalStatusHistory,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onUpdated,
  onDeleted,
}: GeneralStatusHistoryViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.GeneralStatusHistory),
      viewVariant: displayProps.viewVariant,
    },
  });

  const generalStatusHistoryRequest = useApiRequest(
    apiClient.generalStatusHistoryApi.apiV1GeneralStatusHistoryGeneralStatusHistoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalStatusHistoryId: generalStatusHistoryId!,
    },
    {
      deps: [generalStatusHistoryId],
      skip: !generalStatusHistoryId || !!generalStatusHistory,
    },
  );
  generalStatusHistory = generalStatusHistory || generalStatusHistoryRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        undefined,
        EntityType.GeneralStatusHistory,
        generalStatusHistoryId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      generalStatusHistoryRequest.handleEntityChanged(data);
    },
  });

  // useBreadcrumbReplacements({
  //   waitTimeout: 10_000,
  //   idBreadcrumb:
  //     (displayProps?.breadcrumbs &&
  //       generalStatusHistory && {
  //         idValue: generalStatusHistory.id!,
  //         newTitle: generalStatusHistory.localNumber || "",
  //       }) ||
  //     undefined,
  // });

  return (
    <BaseEntityView
      entityType={EntityType.GeneralStatusHistory}
      entityId={generalStatusHistoryId}
      entity={generalStatusHistory}
      entityRequest={generalStatusHistoryRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header &&
          generalStatusHistory && (
            <SimpleViewPageHeader
              title={
                <>
                  Status history record{" "}
                  <EntityChipList entity={generalStatusHistory} variant='normal' />
                </>
              }
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={generalStatusHistory?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.GeneralStatusHistory,
                      entityId: generalStatusHistory?.id,
                      onSaved: (newValue) => {
                        generalStatusHistoryRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              secondaryActions={
                displayProps.actions && (
                  <GeneralStatusHistoryMenu
                    entity={generalStatusHistory}
                    displayProps={{
                      actions: {
                        view: false,
                        viewInNewTab: true,
                      },
                    }}
                    onUpdated={(newValue) => {
                      generalStatusHistoryRequest.replaceData(newValue);
                      onUpdated && onUpdated(newValue);
                    }}
                    onDeleted={(newValue) => {
                      onDeleted && onDeleted();
                      history.goBack();
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabsV2
            tabIdsDefinition={GeneralStatusHistoryViewPageTabs}
            defaultTabId={GeneralStatusHistoryViewPageTabs.Overview}
            viewVariant={displayProps?.viewVariant}
            commonViewParams={commonViewParams}
            tabs={[
              {
                label: "Overview",
                value: GeneralStatusHistoryViewPageTabs.Overview,
                isHideable: false,
              },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              generalStatusHistory && (
                <>
                  {activeTab === GeneralStatusHistoryViewPageTabs.Overview && (
                    <OverviewTabContent
                      generalStatusHistory={generalStatusHistory}
                      displayProps={displayProps}
                    />
                  )}
                </>
              )
            }
          </PageTabsV2>
        </Stack>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
