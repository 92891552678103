import { MutableRefObject, useRef } from "react";

/** Extends useRef with factory method for initial value (similar to useMemo).
 *  Factory method allows to create dynamically the initial value once and skip creation on every subsequent render.
 */
export function useRefWithFactory<T>(factory: () => T): MutableRefObject<T> {
  const ref = useRef<T>(undefined as T);

  // create initial value only once
  const isInited = useRef(false);
  if (!isInited.current) {
    ref.current = factory();
    isInited.current = true;
  }

  return ref;
}
