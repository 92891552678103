import GeneralPriceDisplay from "@/common/components/Entity/General/GeneralPrice/GeneralPriceDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  SubscriptionPlanExtraOptionDto,
} from "@/core/api/generated";

import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "../../components/BaseEntityInline";
import SubscriptionPlanOptionInline from "./SubscriptionPlanOptionInline";

interface Props extends BaseEntityInlineInheritableProps {
  extraOption: SubscriptionPlanExtraOptionDto | null | undefined;
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
}

/** Inline brief information about the entity. */
export default function SubscriptionPlanExtraOptionInline({
  extraOption,
  currency,
  ...otherProps
}: Props) {
  return (
    <BaseEntityInline
      entity={extraOption}
      icon={<AppIcon of='extraOption' inText />}
      content={
        <>
          <SubscriptionPlanOptionInline
            option={extraOption?.option}
            withIcon={false}
            withTooltip={false}
          />{" "}
          |{" "}
          <GeneralPriceDisplay
            price={{ price: extraOption?.total, currency: currency || undefined }}
            withIcon
          />{" "}
          (<InlineApiEnumValue type='PaymentRegularityType' value={extraOption?.regularityType} />)
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Subscription plan extraOption"}
    />
  );
}
