import { useParams } from "react-router-dom";

import CustomerView from "@/common/components/Entity/Customer/View/CustomerView";

export default function CustomerViewPage() {
  const { customerId } = useParams<{ customerId?: string }>();

  return (
    <>
      <CustomerView
        customerId={customerId}
        customer={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
