import { Button, FormControlLabel, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";

export default function DevTooltips() {
  const [isEnabled, setIsEnabled] = useState(true);

  return (
    <Stack spacing={4}>
      <Typography component='div' variant='h4'>
        Tooltips
      </Typography>

      <Stack spacing={1}>
        <Typography component='div' variant='h5'>
          <InlineCode>AppTooltip</InlineCode> (based on MUI Tooltip)
        </Typography>
        <Stack spacing={1} alignItems='flex-start'>
          <AppLink to='https://mui.com/material-ui/react-tooltip/'>MUI docs</AppLink>

          <FormControlLabel
            control={
              <Switch checked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
            }
            label='Enabled'
          />

          <AppTooltip
            enabled={isEnabled}
            title='Lorem ipsum, dolor sit amet consectetur adipisicing elit.'
          >
            <IconButton>
              <AppIcon of='info' />
            </IconButton>
          </AppTooltip>

          <AppTooltip
            enabled={isEnabled}
            title='Lorem ipsum, dolor sit amet consectetur adipisicing elit.'
          >
            <Button variant='contained'>Help!</Button>
          </AppTooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
