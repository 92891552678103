import { Alert, Box, Chip, Stack, SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionPlanAvailabilityDto,
  AssetSubscriptionPlanDto,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import AppTooltip from "../../AppTooltip";
import FieldValue from "../../Form/Display/FieldValue";
import SimpleCardListSkeleton from "../../Skeleton/SimpleCardListSkeleton";
import PoolAvailabilityDisplay from "../Pool/PoolAvailabilityDisplay";
import PoolLink from "../Pool/PoolLink";
import ProductLocationLink from "../ProductLocation/ProductLocationLink";

interface Props {
  plan: AssetSubscriptionPlanDto;
  availability: AssetSubscriptionPlanAvailabilityDto | null | undefined;
  variant?: "normal" | "compact";
  isLoad?: boolean;
  sx?: SxProps<Theme>;
}

export default function AssetSubscriptionPlanAvailabilityDisplay({
  plan,
  availability,
  variant = "normal",
  isLoad = false,
  sx,
}: Props) {
  const availabilityRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi
      .apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdAvailabilityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: plan?.id || "",
    },
    {
      skip: !plan?.id || !isLoad,
      deps: [plan?.id, isLoad],
    },
  );
  const availabilityComputed = availabilityRequest?.data || availability;

  const primaryPoolId = plan.pool?.id;
  const primaryPool = useMemo(
    () => availabilityComputed?.perPool?.find((x) => x.pool?.id === primaryPoolId)?.pool,
    [plan, availabilityComputed, primaryPoolId],
  );
  const primaryPoolAvailability = useMemo(
    () => ({
      productLocation: undefined,
      availability: availabilityComputed?.perPool?.find((x) => x.pool?.id === primaryPoolId),
    }),
    [availabilityComputed, primaryPool],
  );

  const secondaryPoolAvailabilities = useMemo(
    () =>
      plan?.productLocations?.map((x) => ({
        productLocation: x,
        availability: availabilityComputed?.perPool?.find((y) => y.pool?.id === x.pool?.id),
      })),
    [plan, primaryPoolId, availabilityComputed],
  );

  return (
    <Stack spacing={1} sx={sx}>
      {availabilityRequest.isLoading && <SimpleCardListSkeleton itemCount={1} />}

      {!availabilityRequest.isLoading && availabilityComputed && (
        <Stack spacing={2}>
          {/* Composite */}
          <Stack spacing={2}>
            {variant === "normal" && (
              <AppTooltip
                variant='helpText'
                title='Aggregated is for the whole plan and all pools.'
              >
                <Typography variant='h6'>Aggregated</Typography>
              </AppTooltip>
            )}

            {availabilityComputed && availabilityComputed.requestedCount === 0 && (
              <Alert severity='warning'>
                <Box>
                  No{" "}
                  {TextHelper.pluralize(
                    enumService.getEnumValueName("AssetEntityType", plan.assetSpec?.entityType),
                    2,
                  ).toLocaleLowerCase()}{" "}
                  left!
                </Box>
              </Alert>
            )}

            {availabilityComputed && availabilityComputed.requestedCount !== 0 && (
              <Alert severity='success'>
                <Box>
                  Available{" "}
                  {TextHelper.pluralize(
                    enumService.getEnumValueName("AssetEntityType", plan.assetSpec?.entityType),
                    2,
                  ).toLocaleLowerCase()}
                  : {availabilityComputed.requestedCount} / {availabilityComputed.totalCount}.
                </Box>
              </Alert>
            )}
          </Stack>

          {/* Per pool */}
          {variant === "normal" && (
            <Stack spacing={2}>
              {/* Primary pool */}
              <Stack spacing={1}>
                <AppTooltip
                  variant='helpText'
                  title='The primary pool is used by the whole plan by default.'
                >
                  <Typography variant='h6'>Primary pool</Typography>
                </AppTooltip>

                {_.isEmpty(availabilityComputed.perPool) && <NoDataAlert />}

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      xxs: "1fr",
                      md: "repeat(2, 1fr)",
                    },
                    gap: 2,
                  }}
                >
                  <FieldValue
                    label='Pool'
                    direction='column'
                    isEmpty={!primaryPoolAvailability?.availability?.pool}
                  >
                    <PoolLink entity={primaryPoolAvailability?.availability?.pool} />{" "}
                    <Chip variant='filled' color='primary' size='small' label='Primary' />
                  </FieldValue>

                  <FieldValue
                    label='Availability'
                    direction='column'
                    isEmpty={!primaryPoolAvailability}
                  >
                    <PoolAvailabilityDisplay
                      poolId={primaryPoolAvailability?.availability?.pool?.id}
                      availability={primaryPoolAvailability?.availability}
                      sx={{ mt: 0.5 }}
                    />
                  </FieldValue>
                </Box>
              </Stack>

              {/* Secondary pools */}
              <Stack spacing={1}>
                <AppTooltip
                  variant='helpText'
                  title='The secondary pools are assigned per sales location and used only for those
                  locations. If pool is not set for some sales location, then primary pool is used for that sales location.'
                >
                  <Typography variant='h6'>Secondary pools</Typography>
                </AppTooltip>

                {secondaryPoolAvailabilities?.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xxs: "1fr",
                        md: "repeat(3, 1fr)",
                      },
                      gap: 2,
                    }}
                  >
                    <FieldValue
                      label='Sales location'
                      direction='column'
                      isEmpty={!item?.productLocation?.productLocation}
                    >
                      <ProductLocationLink
                        entity={item?.productLocation?.productLocation}
                        entityId={item?.productLocation?.productLocation?.id}
                      />
                    </FieldValue>

                    <FieldValue label='Pool' direction='column' isEmpty={!item.availability?.pool}>
                      <PoolLink entity={item.availability?.pool} />{" "}
                      <Chip variant='outlined' color='default' size='small' label='Secondary' />
                    </FieldValue>

                    <FieldValue
                      label='Availability'
                      direction='column'
                      isEmpty={!item.availability}
                    >
                      <PoolAvailabilityDisplay
                        poolId={item.availability?.pool?.id}
                        availability={item.availability}
                        sx={{ mt: 0.5 }}
                      />
                    </FieldValue>
                  </Box>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}
