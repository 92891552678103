import {
  Alert,
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, AssetSubscriptionDto, PoolItemStatus } from "@/core/api/generated";

import AssetLink from "../../../Asset/AssetLink";
import AssetSubscriptionPlanLink from "../../../AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import PoolInline from "../../../Pool/PoolInline";
import PoolView from "../../../Pool/View/PoolView";
import PoolItemInline from "../../../PoolItem/PoolItemInline";
import PoolItemView from "../../../PoolItem/View/PoolItemView";
import AssetSubscriptionLink from "../../AssetSubscriptionLink";

interface Props {
  assetSubscription: AssetSubscriptionDto;
  onUpdated: (newValue: AssetSubscriptionDto) => void;
}

export default function PoolTabContent({ assetSubscription, onUpdated }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: assetSubscription?.asset?.poolItem?.poolId || "",
    },
    {
      deps: [assetSubscription?.asset?.poolItem?.poolId],
      skip: !assetSubscription?.asset?.poolItem?.poolId,
    },
  );
  const pool = poolRequest?.data;

  const poolItemRequest = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolItemsPoolItemIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolItemId: assetSubscription?.asset?.poolItem?.id || "",
    },
    {
      deps: [assetSubscription?.asset?.poolItem?.id],
      skip: !assetSubscription?.asset?.poolItem?.id,
    },
  );
  const poolItem = poolItemRequest?.data;

  const canReleasePoolItem =
    assetSubscription?.asset?.poolItem?.id &&
    poolItem?.id &&
    assetSubscription?.asset?.poolItem?.id === poolItem.id &&
    poolItem.usedByEntity?.entityId === assetSubscription.id &&
    poolItem?.status !== PoolItemStatus.Available;

  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack direction='column' spacing={2}>
          {!assetSubscription?.asset?.asset?.id && <NoDataAlert />}

          {assetSubscription?.asset?.asset?.id && (
            <>
              <Alert severity='info'>
                Pool and pool item of the plan{" "}
                <AssetSubscriptionPlanLink
                  entity={undefined}
                  entityId={assetSubscription.plan?.id}
                />{" "}
                used to reserve asset{" "}
                <AssetLink entity={undefined} entityId={assetSubscription?.asset?.asset?.id} /> for
                the subscription <AssetSubscriptionLink entity={assetSubscription} />.
              </Alert>

              {assetSubscription?.asset?.poolItem && (
                <>
                  {/* Pool */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography variant='subtitle1'>
                          <PoolInline
                            entity={undefined}
                            entityId={assetSubscription?.asset?.poolItem?.poolId}
                          />
                        </Typography>
                      ),
                    }}
                  >
                    <PoolView
                      poolId={assetSubscription?.asset?.poolItem?.poolId}
                      pool={pool}
                      displayProps={{
                        header: false,
                        actions: false,
                        viewVariant: ViewLayoutVariant.Tab,
                      }}
                    />
                  </FoldableBlock>

                  {/* PoolItem */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography component='span' variant='subtitle1'>
                          <PoolItemInline
                            entity={undefined}
                            entityId={assetSubscription?.asset?.poolItem?.id}
                          />
                        </Typography>
                      ),
                    }}
                  >
                    <PoolItemView
                      poolId={assetSubscription?.asset?.poolItem?.poolId || ""}
                      poolItemId={assetSubscription?.asset?.poolItem?.id}
                      poolItem={poolItem}
                      displayProps={{
                        header: false,
                      }}
                    />
                  </FoldableBlock>

                  {canReleasePoolItem && (
                    <AuthorizedElement permissions={[AppPermission.AssetSubscriptionManage]}>
                      <Box>
                        <ConfirmationModalWithTrigger
                          trigger={
                            <Button variant='outlined' size='medium' color='text'>
                              Force release the asset
                            </Button>
                          }
                          title='Release the asset in the pool?'
                          body={({ error }) => (
                            <Stack spacing={1}>
                              <DialogContentText>
                                {`You are going to force release the asset linked to this asset subscription and make it available in the pool. Please confirm the action.`}
                              </DialogContentText>

                              {error && (
                                <FormHelperText error>
                                  {ValidationHelper.getErrorsAsString(error)}
                                </FormHelperText>
                              )}
                            </Stack>
                          )}
                          onConfirm={async () => {
                            const response =
                              await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdAssetReleasePost(
                                {
                                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                  assetSubscriptionId: assetSubscription.id || "",
                                },
                              );
                            enqueueSnackbar("The asset has been released.", {
                              variant: "success",
                            });
                            onUpdated(response.data);
                            poolRequest.refetch();
                            poolItemRequest.refetch();
                          }}
                        />
                      </Box>
                    </AuthorizedElement>
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
