import { Skeleton, SkeletonProps, SxProps, Theme } from "@mui/material";

interface Props {
  contentProps?: SkeletonProps;
  sx?: SxProps<Theme>;
}

export default function SimpleCardSkeleton({ contentProps, sx }: Props) {
  return <Skeleton sx={sx} variant='rectangular' width={"100%"} height={40} {...contentProps} />;
}
