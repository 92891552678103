import { Box, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { useCallback } from "react";

import { ImportEntityType } from "@/core/api/generated";

interface ImportEntityTypeSelectProps {
  importEntityType?: ImportEntityType | null;
  selectProps?: SelectProps;
  required?: boolean;
  onChange?: (
    e: SelectChangeEvent<unknown>,
    importEntityType?: ImportEntityType | undefined,
  ) => void;
}

function ImportEntityTypeSelect({
  importEntityType,
  selectProps,
  required = false,
  onChange,
}: ImportEntityTypeSelectProps) {
  const importEntityTypes = [ImportEntityType.Accessory, ImportEntityType.Vehicle];

  const _onChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const entityType = (importEntityTypes || []).find((x) => x === e.target.value);
      onChange && onChange(e, entityType);
    },
    [onChange],
  );

  return (
    <Box>
      <Select
        label='Import entity type'
        required={required}
        displayEmpty={false}
        fullWidth
        value={importEntityType || ""}
        onChange={_onChange}
        {...selectProps}
      >
        {importEntityTypes &&
          importEntityTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
}

export default ImportEntityTypeSelect;
