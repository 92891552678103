import { Box, IconButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserDto, AppPermission } from "@/core/api/generated";

interface Props {
  tenantId: string;
}

export default function UsersTabContent({ tenantId }: Props) {
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<AdminUserDto | undefined>();

  const tenantUsersRequest = useApiRequest(
    apiClient.adminUsersApi.apiV1AdminUsersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      adminUserGetPaginatedDto: {
        tenantId: tenantId,
      },
    },
    {
      deps: [tenantId],
    },
  );
  const tenantUsers = tenantUsersRequest.data?.items || [];

  const handleUserRemoveFromTenant = useCallback(async () => {
    if (!userToRemove) {
      return;
    }
    await apiClient.adminUsersApi.apiV1AdminUsersTargetUserIdTenantTenantIdDelete({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantId: tenantId,
      targetUserId: userToRemove.id!,
    });
    setUserToRemove(undefined);
    setIsRemoveUserModalOpen(false);
    tenantUsersRequest.refetch();
  }, [userToRemove, tenantId]);

  return (
    <Stack>
      <Stack spacing={1}>
        <DataTabular<AdminUserDto>
          columns={[
            {
              field: "person.name",
              title: "Name",
              flex: 1,
              renderCell: (item) => (
                <Stack direction='row' sx={{ alignItems: "center" }} spacing={1}>
                  <AppAvatar user={item} size='small' />
                  <Stack>
                    <Box>{item.personName?.name}</Box>
                    <Typography component='div' variant='body2'>
                      {item.email}
                    </Typography>
                  </Stack>
                </Stack>
              ),
            },
            {
              field: "status",
              title: "Status",
              flex: 1,
              renderCell: (item) => <InlineApiEnumValue type='UserStatus' value={item.status} />,
            },
            {
              field: "phoneNumber",
              title: "Phone",
              flex: 1,
              renderCell: (item) => item.phoneNumber,
            },
            {
              field: "roles",
              title: "Roles",
              flex: 1,
              renderCell: (item) => item.roles?.map((x) => x.role!.name)?.join(", "),
            },
          ]}
          rows={tenantUsers}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_USER_VIEW(item.id)}
          renderRowAction={(actionParams) => (
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedMenuItem
                permissions={[AppPermission.AdminTenantManage]}
                onClick={() => {
                  setUserToRemove(actionParams.item);
                  setIsRemoveUserModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Remove user from tenant</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          )}
        />
      </Stack>
      <ConfirmationModal
        title='Delete user from tenant?'
        body={
          <>
            {`You're going to remove user `}
            <strong>{userToRemove?.personName?.name}</strong>
            {` from tenant. This action can't be undone.`}
          </>
        }
        open={isRemoveUserModalOpen}
        onClose={() => setIsRemoveUserModalOpen(false)}
        onCancel={() => setIsRemoveUserModalOpen(false)}
        onConfirm={handleUserRemoveFromTenant}
      />
    </Stack>
  );
}
