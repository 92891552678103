import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { memo, useEffect, useState } from "react";

import { usePropagateRef } from "@/common/hooks/usePropagateRef";

/**
 * https://medium.com/@javiasilis/improving-formik-performance-when-its-slow-material-ui-a6e52a019fd1
 */
export type PerformantTextFieldProps = Omit<TextFieldProps, "name"> & {
  name: string;
  /**
   * IF false, it will use the traditional method for disabling performance
   */
  isPerformanceEnabled?: boolean;
  loading?: boolean;
  min?: number;
  max?: number;
};

const PerformantTextFieldForFormikUnstable = (props: PerformantTextFieldProps) => {
  const [field, meta] = useField(props.name); // should refactor for use inside Formik context by FormikHelper
  const error = !!meta.error && meta.touched;
  /**
   * For performance reasons (possible due to CSS in JS issues), heavy views
   * affect re-renders (Formik changes state in every re-render), bringing keyboard
   * input to its knees. To control this, we create a setState that handles
   * the field's inner (otherwise you wouldn't be able to type) and then
   * propagate the change to Formik onBlur and onFocus.
   */
  const [fieldValue, setFieldValue] = useState<string | number>(field.value);
  const { isPerformanceEnabled = false, loading, ...otherProps } = props;
  usePropagateRef({
    setFieldValue,
    name: props.name,
    value: field.value,
  });
  /**
   * Using this useEffect guarantees us that pre-filled forms and sync values
   * such as passwords work.
   */
  useEffect(() => {
    if (meta.touched) {
      return;
    }
    if (field.value !== fieldValue) {
      setFieldValue(field.value);
    }
    if (otherProps.value !== fieldValue) {
      setFieldValue((otherProps?.value as string | number) || "");
    }
  }, [field.value]);

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(evt.target.value);
  };

  const onBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const val = evt.target.value || ""; // If input value is empty, set val to an empty string.

    window.setTimeout(() => {
      // Delay the field.onChange call to ensure React has completed its internal event handling.
      field.onChange({
        target: {
          name: props.name, // Set the target name to the 'name' prop passed to the component.
          value: props.type === "number" ? parseInt(val, 10) : val, // If the input type is "number", parse the value to an integer.
        },
      });
      props.onChange && props.onChange(evt);
    }, 0); // The setTimeout with 0 ensures this runs after any other synchronous code has completed.
  };

  const performanceProps = isPerformanceEnabled
    ? {
        ...field,
        onChange,
        onBlur,
        onFocus: onBlur,
      }
    : {
        ...field,
      };
  return (
    <TextField
      {...otherProps}
      InputProps={{
        ...((props.type === "number" && {
          inputProps: { min: props?.min, max: props?.max },
        }) ||
          undefined),
        ...props.InputProps,
      }}
      error={error}
      helperText={meta.touched && meta.error}
      {...performanceProps}
      value={fieldValue || props.value || ""}
    />
  );
};
export default memo(PerformantTextFieldForFormikUnstable);
