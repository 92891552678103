import { Alert, Stack, Typography } from "@mui/material";

export default function Dashboard() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h4'>
        Customer dashboard
      </Typography>
      <Alert severity='info'>
        You will receive any info regarding your vehicle via Email or SMS.
      </Alert>
    </Stack>
  );
}
