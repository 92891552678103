import {
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import NoDataAlert from "../AppAlerts/NoDataAlert";

export interface Props<TCard> {
  items?: TCard[];
  header: ReactNode;
  footer?: ReactNode;
  isLoading?: boolean;
  isFolded?: boolean;
  /** List items. */
  children: (item: TCard, index: number) => ReactNode;
  sx?: SxProps<Theme>;
}

export default function CardList<TCard>({
  header,
  footer,
  items,
  isLoading,
  isFolded,
  children,
}: Props<TCard>) {
  return (
    <Card
      sx={{ minWidth: 0, height: "fit-content", border: (t) => `1px solid ${t.palette.divider}` }}
    >
      {/* Header */}
      <CardContent
        sx={{
          borderBottomLeftRadius: "0 !important",
          borderBottomRightRadius: "0 !important",
        }}
      >
        {header}
      </CardContent>

      <Divider />

      {/* Content */}
      <Stack>
        {/* Loading */}
        {_.isEmpty(items) && isLoading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}

        {/* No data */}
        {_.isEmpty(items) && !isLoading && !isFolded && (
          <CardContent>
            <NoDataAlert />
          </CardContent>
        )}

        {/* Items */}
        {!isFolded &&
          items?.map((item, index) => (
            <CardContent
              key={index}
              sx={{
                "&:not(:last-child)": { borderBottom: (t) => `1px solid ${t.palette.divider}` },
              }}
            >
              {children(item, index)}
            </CardContent>
          ))}
      </Stack>

      {/* Footer */}
      {footer && (
        <Stack
          sx={{
            minHeight: 20,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
        >
          <CardContent>{footer}</CardContent>
        </Stack>
      )}
      {!footer && (
        <Paper
          sx={{
            minHeight: 20,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
          }}
          elevation={0}
        >
          {footer}
        </Paper>
      )}
    </Card>
  );
}
