import { useCallback, useRef } from "react";

// React Router does not have any opinions about
// how you should parse URL query strings.
//
// If you use simple key=value query strings and
// you do not need to support IE 11, you can use
// the browser's built-in URLSearchParams API.
//
// If your query strings contain array or object
// syntax, you'll probably need to bring your own
// query parsing function.

interface Props {
  /** Sets listen mode to URL query parameters.
   * `initial` - doesn't listen for URL changes and return first captured value.
   * `current` - listen for URL changes and always current value.
   * @default initial
   */
  mode?: "initial" | "current";
}

/** A custom hook that builds on useLocation to parse the query string. */
export const useQueryParams = <
  QueryParams extends { [K in keyof QueryParams]?: string | null } = any,
>({ mode = "initial" }: Props = {}): QueryParams => {
  // const { search } = useLocation();

  const getCurrentQueryParams = useCallback(() => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const queryParams: any = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams as QueryParams;
  }, []);

  const initialQueryParamsRef = useRef<QueryParams>(getCurrentQueryParams());
  const currentQueryParams = mode === "current" ? getCurrentQueryParams() : undefined;

  return currentQueryParams || initialQueryParamsRef.current;
};
