import { Box, Button, Stack, TypographyProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import Image from "@/common/components/Images/Image";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { MakeLogoHelper } from "@/common/helpers/makeLogo";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  RepairOperationDto,
  RepairOperationStage,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import RepairOperationMenu from "../RepairOperationMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import ContractTabContent from "./Tabs/ContractTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import RelatedOperationsTabContent from "./Tabs/RelatedOperationsTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export type RepairOperationViewPageHighlightProps = {
  /** Item to highlight when mounted. */
  itemId?: string | null;
};

export enum RepairOperationViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Contract = "Contract",
  RelatedOperations = "RelatedOperations",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<RepairOperationDto> {
  repairOperationId: string | null | undefined;
  repairOperation: RepairOperationDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function RepairOperationView({
  repairOperationId,
  repairOperation,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.RepairOperation),
      viewVariant: displayProps.viewVariant,
    },
  });

  const repairOperationRequest = useApiRequest(
    apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairOperationId: repairOperationId!,
    },
    {
      deps: [repairOperationId],
      skip: !repairOperationId || !!repairOperation,
    },
  );
  repairOperation = repairOperation || repairOperationRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.RepairOperation,
        repairOperation?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      repairOperationRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        repairOperation && {
          idValue: repairOperation.id!,
          newTitle: repairOperation.localNumber || "",
        }) ||
      undefined,
  });

  const handleUpdateStage = useCallback(
    async (newStage: RepairOperationStage) => {
      try {
        await apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdStagePut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairOperationId: repairOperationId!,
          generalStageUpdateDtoOfRepairOperationStage: {
            newStage,
          },
        });
        repairOperationRequest.refetch();
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        throw err;
      }
    },
    [repairOperationId],
  );
  const vehicleMakeUrl = useMemo(() => {
    return MakeLogoHelper.getMakeLogoByIdentifier(repairOperation?.vehicle?.spec?.make?.identifier);
  }, [repairOperation]);

  return (
    <BaseEntityView
      entityType={EntityType.RepairOperation}
      entityId={repairOperationId}
      entity={repairOperation}
      entityRequest={repairOperationRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={
                // vehicleMakeUrl because RepairOperationDto doesn't have vehicle attachments
                <>
                  {vehicleMakeUrl && (
                    <Image
                      src={vehicleMakeUrl}
                      sx={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "6px",
                        objectFit: "contain",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: (th) => th.palette.divider,
                      }}
                    />
                  )}
                </>
              }
              title={
                <>
                  {repairOperation?.vehicle?.spec?.make?.name}
                  {repairOperation?.vehicle?.spec?.model?.name}{" "}
                  <EntityChipList entity={repairOperation?.vehicle} variant='normal' />
                </>
              }
              subtitle={
                <Stack spacing={0.5}>
                  <Box>
                    {StringHelper.joinIntoString(
                      [
                        repairOperation?.vehicle?.plateNo?.plateNo,
                        repairOperation?.vehicle?.identificationNumber,
                      ],
                      " • ",
                      { skipEmpty: true },
                    )}
                  </Box>

                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <GeneralAttachedTagsDisplay
                      tags={repairOperation?.tags}
                      defaultIsFolded={false}
                      edit={{
                        entityType: TagEntityType.RepairOperation,
                        entityId: repairOperation?.id,
                        onSaved: (newValue) => {
                          repairOperationRequest.updateData((x) => {
                            x.tags = newValue || undefined;
                          });
                        },
                      }}
                    />
                  </AuthorizedElement>
                </Stack>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.REPAIR_OPERATION_VIEW(repairOperation?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.REPAIR_OPERATION_EDIT(repairOperation?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                repairOperation && (
                  <RepairOperationMenu
                    entity={repairOperation}
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue
                        ? repairOperationRequest.replaceData(newValue)
                        : repairOperationRequest.refetch()
                    }
                    displayProps={{
                      actions: {
                        edit: false,
                        delete: true,
                      },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={RepairOperationViewPageTabs}
          defaultTabId={RepairOperationViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: RepairOperationViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: RepairOperationViewPageTabs.Vehicle },
            ...(repairOperation?.contract?.id
              ? [
                  {
                    label: "Contract",
                    value: RepairOperationViewPageTabs.Contract,
                  },
                ]
              : []),
            {
              label: "Related operations",
              value: RepairOperationViewPageTabs.RelatedOperations,
            },
            { label: "History", value: RepairOperationViewPageTabs.GeneralHistory },
            { label: "Comments", value: RepairOperationViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            repairOperation && (
              <>
                {activeTab === RepairOperationViewPageTabs.Overview && (
                  <OverviewTabContent
                    handleUpdateStage={handleUpdateStage}
                    repairOperation={repairOperation}
                    onUpdate={repairOperationRequest.refetch}
                  />
                )}
                {activeTab === RepairOperationViewPageTabs.Vehicle && (
                  <VehicleTabContent repairOperation={repairOperation} />
                )}
                {activeTab === RepairOperationViewPageTabs.Contract && (
                  <ContractTabContent repairOperation={repairOperation} />
                )}
                {activeTab === RepairOperationViewPageTabs.RelatedOperations && (
                  <RelatedOperationsTabContent repairOperation={repairOperation} />
                )}
                {activeTab === RepairOperationViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent repairOperation={repairOperation} />
                )}
                {activeTab === RepairOperationViewPageTabs.Comments && (
                  <CommentsTabContent repairOperation={repairOperation} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
