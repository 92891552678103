import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairSparePart from "@/common/components/Entity/RepairSparePart/CreateUpdateRepairSparePart";
import { apiClient } from "@/core/api/ApiClient";

export default function CreateUpdateRepairSparePartPage() {
  const { repairSparePartId } = useParams<{ repairSparePartId?: string }>();
  const isCreate = !repairSparePartId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new repair spare part" : "Edit repair spare part"}
        />
      }
    >
      <Stack spacing={2}>
        <CreateUpdateRepairSparePart
          repairSparePartId={repairSparePartId}
          onSave={(newValue) => {
            history.goBack();
          }}
          createFunc={async (params) => {
            return await apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsPost({
              createRepairSparePartDto: params.dto,
            });
          }}
          updateFunc={async (params) => {
            return await apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdPut(
              {
                repairSparePartId: params.repairSparePartId,
                updateRepairSparePartDto: params.dto,
              },
            );
          }}
        />
      </Stack>
    </CreateUpdatePageLayout>
  );
}
