import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";

interface OwnProps {
  isLoading?: boolean;
  loadingText?: string;
  onClick?: MenuItemProps["onClick"] | (() => void | Promise<void>);
  children?: MenuItemProps["children"];
}

export type LoadingMenuItemProps = OwnProps & Omit<MenuItemProps, "onClick" | "children">;

/** Show loader if loading is passed or while onClick is being processed. */
export default function LoadingMenuItem({
  isLoading,
  loadingText,
  onClick,
  children,
  ...menuItemProps
}: LoadingMenuItemProps) {
  const [_isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!_.isNil(isLoading)) {
      setIsLoading(isLoading);
    }
  }, [isLoading]);

  return (
    <MenuItem
      onClick={async (e) => {
        if (!onClick) {
          return;
        }

        setIsLoading(true);
        try {
          await onClick(e);
        } finally {
          setIsLoading(false);
        }
      }}
      {...menuItemProps}
    >
      {!_isLoading && children}
      {_isLoading && (
        <>
          <ListItemIcon>
            <CircularProgress color='inherit' size={20} />
          </ListItemIcon>
          <ListItemText>{loadingText || "Loading..."}</ListItemText>
        </>
      )}
    </MenuItem>
  );
}
