import {
  Box,
  Card,
  CardContent,
  CardHeader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import ListItemGroup, { ListItemGroupProps } from "@/common/components/List/ListItemGroup";

export default function DevListItemGroup() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h1'>
        ListItemGroup
      </Typography>

      <Stack spacing={2}>
        <Box sx={{ display: "flex", mb: 2 }}>
          <Stack spacing={2}>
            {(
              [
                {
                  variant: "normal",
                  direction: "column",
                  maxItems: 3,
                },
                {
                  variant: "normal",
                  direction: "row",
                  maxItems: 3,
                },
                {
                  variant: "compact",
                  direction: "column",
                  maxItems: 3,
                },
                {
                  variant: "compact",
                  direction: "row",
                  maxItems: 3,
                },
              ] as ListItemGroupProps[]
            ).map((props, index) => (
              <Stack key={index} spacing={2}>
                <Typography variant='subtitle1'>
                  <InlineCode>{JSON.stringify(props)}</InlineCode>
                </Typography>

                <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
                  <ListItemGroup {...props}>
                    {Array.from({ length: 10 }).map((x, i) => (
                      <ListItem key={i}>
                        <ListItemIcon>
                          <AppIcon of='vehicle' />
                        </ListItemIcon>
                        <ListItemText>ListItem #{i + 1}</ListItemText>
                      </ListItem>
                    ))}
                  </ListItemGroup>

                  <ListItemGroup {...props}>
                    {Array.from({ length: 10 }).map((x, i) => (
                      <AppLink key={i} to='#'>
                        Link #{i + 1}
                      </AppLink>
                    ))}
                  </ListItemGroup>

                  <ListItemGroup {...props}>
                    {Array.from({ length: 10 }).map((x, i) => (
                      <Card key={i}>
                        <CardHeader>Card #{i + 1}</CardHeader>
                        <CardContent>asd asdas da sda sdas das das dasd </CardContent>
                      </Card>
                    ))}
                  </ListItemGroup>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
