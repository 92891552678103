import { Box, Chip, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { SpotDto, SpotSearchPaginatedDto } from "@/core/api/generated";

import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";
import LocationInline from "../Location/LocationInline";
import ProductLocationInline from "../ProductLocation/ProductLocationInline";

export type SpotAutocompleteOption = BaseAutocompleteOption<SpotDto>;

export const getSpotOptionTitle = (data: SpotDto): string =>
  StringHelper.joinIntoString(
    [
      data.name || data.localNumber,
      data.types &&
        StringHelper.joinIntoString(
          data.types.map((x) => enumService.getEnumValueName("SpotType", x)),
          ", ",
        ),
    ],
    " | ",
    {
      skipEmpty: true,
    },
  );

export const spotToOption = (data: SpotDto): SpotAutocompleteOption => ({
  id: data.id!,
  title: getSpotOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface SpotAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<SpotDto> {
  searchFilters?: Partial<Omit<SpotSearchPaginatedDto, "search" | "includeIds">>;
}

export default function SpotAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: SpotAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={spotToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.spotsApi.apiV1SpotsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          spotSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          spotSearchPaginatedDto: {
            ...params.spotSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Spot'
      placeholder='Search for spot...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='spot' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}{" "}
                    {option.data && (
                      <Chip
                        variant='outlined'
                        size='small'
                        color='default'
                        label={
                          <InlineApiEnumValueList
                            type='SpotType'
                            values={option.data?.types}
                            direction='row'
                          />
                        }
                      />
                    )}
                  </Typography>
                </>
              }
              secondary={
                option.data && (
                  <>
                    <Box>
                      <Typography component='div' variant='body2'>
                        {option.data?.description}
                      </Typography>
                    </Box>

                    <Stack>
                      {option.data?.location && <LocationInline entity={option.data?.location} />}
                      {option.data?.productLocation && (
                        <ProductLocationInline entity={option.data?.productLocation} />
                      )}
                    </Stack>
                  </>
                )
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
