import { Box, Stack } from "@mui/material";

import { PermissionDto } from "@/core/api/generated";

import PermissionDisplay from "./PermissionDisplay";

interface Props {
  permissions?: PermissionDto[];
}

function PermissionList({ permissions }: Props) {
  return (
    <Box>
      <Stack spacing={1}>
        {permissions?.map((permission, i) => (
          <PermissionDisplay key={i} variant='block' permission={permission} withIcon />
        ))}
      </Stack>
    </Box>
  );
}

export default PermissionList;
