import { Dialog, DialogContent, DialogProps, Stack, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useState } from "react";

import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { IBasePageLayout } from "@/common/ts/layout";

export enum ViewLayoutVariant {
  Page = "page",
  Tab = "tab",
  Modal = "modal",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface Props extends IBasePageLayout {
  sx?: SxProps<Theme>;
  displayProps?: Partial<typeof defaultDisplayProps>;
  dialogProps?: DialogProps;
}

export default function ViewLayout({
  breadcrumbs,
  header,
  body,
  footer,
  sx,
  displayProps,
  dialogProps,
  children,
}: Props) {
  const bodyComputed = children || body;
  const displayPropsComputed = { ...defaultDisplayProps, ...displayProps };
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Page && (
        <Stack direction='column' spacing={2} sx={{ height: "100%", pb: 10, ...sx }}>
          <Stack
            sx={{
              px: 3,
              py: 1.5,
              background: (t) => t.palette.background.pageHeader,
            }}
            spacing={2}
          >
            {displayPropsComputed?.breadcrumbs && (
              <AppBreadcrumbs replacements={breadcrumbs?.replacements} />
            )}
            {header && header}
          </Stack>

          <Stack
            sx={{
              mx: "24px !important",
              px: 2,
              py: 1,
              borderRadius: "10px",
              background: (t) => t.palette.background.paper,
            }}
          >
            {bodyComputed && bodyComputed}

            {footer && footer}
          </Stack>
        </Stack>
      )}

      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Tab && (
        <Stack direction='column' sx={{ height: "100%", ...sx }}>
          {header && (
            <Stack
              sx={{
                backgroundColor: (t) => t.palette.background.paper,
              }}
            >
              {header}
            </Stack>
          )}

          <Stack
            sx={{
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              // TRICK: to deal with cases when DataTabular wrapped in Stack for some reason
              "& > .MuiStack-root": {
                borderRadius: "inherit",
              },
            }}
          >
            {bodyComputed && bodyComputed}
            {footer && footer}
          </Stack>
        </Stack>
      )}

      {displayPropsComputed?.viewVariant === ViewLayoutVariant.Modal && (
        <Stack direction='column' sx={{ ...sx }}>
          {header && (
            <Stack
              sx={{
                backgroundColor: (t) => t.palette.background.paper,
              }}
            >
              {header}
            </Stack>
          )}

          {bodyComputed}

          {footer && footer}
        </Stack>
      )}
    </>
  );
}
