import { Box, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { VehicleTaxDto } from "@/core/api/generated";

import GeneralCountryDisplay from "../../General/Display/GeneralCountryDisplay";
import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";

interface Props {
  vehicleTax: VehicleTaxDto | null | undefined;
  sx?: SxProps<Theme>;
}

export default function VehicleTaxDisplay({ vehicleTax, sx }: Props) {
  if (!vehicleTax) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Stack spacing={1}>
        <FieldValue label='Enabled'>
          <BooleanValue value={vehicleTax?.isEnabled} />
        </FieldValue>

        <FieldValue label='Vehicle tax type' isEmpty={!vehicleTax?.type}>
          <InlineApiEnumValue
            type='VehicleTaxType'
            value={vehicleTax?.type}
            withDescription
            direction='column'
          />
        </FieldValue>

        <FieldValue label='Countries' isEmpty={_.isEmpty(vehicleTax?.countries)}>
          <Stack>
            {vehicleTax?.countries?.map((x, i) => <GeneralCountryDisplay key={i} country={x} />)}
          </Stack>
        </FieldValue>

        <FieldValue label='Tax types' isEmpty={_.isEmpty(vehicleTax?.taxTypes)}>
          <InlineApiEnumValueList type='TaxType' values={vehicleTax?.taxTypes} direction='column' />
        </FieldValue>

        <FieldValue label='Fuel types' isEmpty={_.isEmpty(vehicleTax?.fuelTypes)}>
          <InlineApiEnumValueList
            type='VehicleFuelType'
            values={vehicleTax?.fuelTypes}
            direction='column'
          />
        </FieldValue>

        {/* <Typography variant='subtitle1'>
          Settings for {`"${enumService.getEnumValueName("VehicleTaxType", values?.type)}"`}
        </Typography> */}

        {(vehicleTax?.reducedForPurchase || vehicleTax?.reducedForRental) && (
          <FieldValue label='Reduced tax'>
            <GeneralTaxDisplay
              tax={
                vehicleTax?.reducedForPurchase?.reducedTax ||
                vehicleTax?.reducedForRental?.reducedTax
              }
            />
          </FieldValue>
        )}

        {(vehicleTax?.fixedFreeValueForPurchase || vehicleTax?.fixedFreeValueForRental) && (
          <FieldValue label='Fixed tax free value'>
            <GeneralPriceDisplay
              price={
                vehicleTax?.fixedFreeValueForPurchase?.fixedFreeValue ||
                vehicleTax?.fixedFreeValueForRental?.fixedFreeValue
              }
            />
          </FieldValue>
        )}
      </Stack>
    </Box>
  );
}
