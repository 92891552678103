import { DialogProps } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

export interface IModalOpenStatus {
  isOpening: boolean;
  isOpen: boolean;
  isClosing: boolean;
  isClosed: boolean;
}

type DialogPropsPicked = Pick<DialogProps, "open">;

/** Simplifies tracking status of model open/opening/close/closing. */
export const useModalOpenStatus = (
  openOrDialogProps: boolean | DialogPropsPicked,
): IModalOpenStatus => {
  const isModalOpen = _.isBoolean(openOrDialogProps)
    ? Boolean(openOrDialogProps)
    : _.isObject(openOrDialogProps)
      ? openOrDialogProps?.open
      : false;

  const prevOpenRef = useRef(false);
  const [modalStatus, setModalStatus] = useState<IModalOpenStatus>({
    isOpening: false,
    isOpen: false,
    isClosing: false,
    isClosed: false,
  });

  useEffect(() => {
    const newValue: IModalOpenStatus = {
      isOpening: !prevOpenRef.current && isModalOpen,
      isOpen: isModalOpen,
      isClosing: prevOpenRef.current && !isModalOpen,
      isClosed: !isModalOpen,
    };

    setModalStatus(newValue);

    // make sure opening/closing is set to false after short period of time
    if (newValue.isOpening || newValue.isClosing) {
      setTimeout(() => {
        setModalStatus({ ...newValue, isOpening: false, isClosing: false });
      }, 100);
    }

    prevOpenRef.current = isModalOpen;
  }, [isModalOpen]);

  return modalStatus;
};
