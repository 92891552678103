import { ForwardedRef, forwardRef } from "react";

import { AllocationStatus } from "@/core/api/generated";

import AppIcon, { AppIconProps } from "./AppIcon";

type AppIconPropsLocal = Omit<AppIconProps, "of">;

interface Props extends AppIconPropsLocal {
  status: AllocationStatus | null | undefined;
}

const iconMap: Record<
  AllocationStatus,
  (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => JSX.Element | null
> = {
  None: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='none' ref={ref} {...props} />
  ),
  [AllocationStatus.NotAllocated]: (
    props: AppIconPropsLocal,
    ref?: ForwardedRef<SVGSVGElement>,
  ) => <AppIcon of='none' ref={ref} {...props} />,
  [AllocationStatus.Allocating]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='pending' ref={ref} {...props} />
  ),
  [AllocationStatus.Allocated]: (props: AppIconPropsLocal, ref?: ForwardedRef<SVGSVGElement>) => (
    <AppIcon of='done' ref={ref} {...props} />
  ),
};

export default forwardRef<SVGSVGElement, Props>(function AllocationStatusIcon(
  { status, ...other }: Props,
  ref,
) {
  const renderIcon = status ? iconMap[status] : iconMap[AllocationStatus.None];
  const iconEl = renderIcon(other, ref);
  if (!iconEl) {
    return null;
  }
  return iconEl;
});
