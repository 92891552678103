import { Alert, LinearProgress, Stack } from "@mui/material";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { PoolItemEntityType, VehicleDto } from "@/core/api/generated";

import AssetLink from "../../../Asset/AssetLink";
import PoolPaginatedList from "../../../Pool/ListView/PoolPaginatedList";

interface Props {
  vehicle: VehicleDto;
}

export default function PoolsTabContent({ vehicle }: Props) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(vehicle), [vehicle]);

  const paginatedForEntityRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: PoolItemEntityType.Vehicle,
      entityId: vehicle?.id || "",
      isIncludeAssetPools: true,
    },
    {
      deps: [vehicle.id],
    },
  );
  const paginatedForEntity = paginatedForEntityRequest?.data;

  return (
    <PageTabContent spacing={0}>
      <Stack
        sx={{ p: 2, backgroundColor: (t) => t.palette.background.paper }}
        direction='column'
        spacing={1}
      >
        {entityMeta?.assetMeta?.assetId && (
          <Alert severity='info'>
            Showing also pools for the asset{" "}
            <AssetLink entity={undefined} entityId={entityMeta.assetMeta?.assetId} withTooltip />.
          </Alert>
        )}

        {paginatedForEntityRequest.isLoading && <LinearProgress />}
      </Stack>

      <PoolPaginatedList
        shouldFetch={false}
        paginated={paginatedForEntity}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
          breadcrumbs: false,
          header: false,
        }}
      />
    </PageTabContent>
  );
}
