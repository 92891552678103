import { Skeleton, SkeletonProps, SxProps, Theme, useTheme } from "@mui/material";

interface Props {
  contentProps?: SkeletonProps;
  sx?: SxProps<Theme>;
}

export default function TextLineSkeleton({ contentProps, sx }: Props) {
  const theme = useTheme();

  return (
    <Skeleton
      sx={{
        display: "block",
        // let the skeleton to inherit font size so it has the same size as the target text
        color: "transparent",
        ...sx,
      }}
      component='span'
      variant='rectangular'
      width={"100%"}
      {...contentProps}
    >
      PLACEHOLDER
    </Skeleton>
  );
}
