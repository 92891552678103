import _ from "lodash";
import * as Yup from "yup";

import { apiClient } from "@/core/api/ApiClient";
import { ValidationProblemDetails } from "@/core/api/generated";

import { ProblemDetailsHelper } from "../helpers/problemDetails";
import { GeneralErrorKey, backEndGeneralErrorKey, generalErrorKeys } from "../ts/error";
import { ValidationProblemDetailsWithExtensions } from "../ts/problemDetails";

export class ValidationInfo {
  public static generalErrorKeys: GeneralErrorKey[] = generalErrorKeys;

  /** Helps to understand if object is ValidationInfo. */
  public readonly isValidationInfo: boolean = true;

  /** E.g. form-level error, not related to specific field. */
  public generalError: string = "";

  /** ProblemDetails from the BE error. */
  public problemDetails: ValidationProblemDetailsWithExtensions | undefined = undefined;

  // [fieldName]: error list,
  // error with key "" is a general validation error (e.g., form-level)
  private clientErrors: { [key: string]: string[] } = {};
  private serverErrors: { [key: string]: string[] } = {};
  public errors: { [key: string]: string[] } = {};

  public get hasErrors(): boolean {
    return Object.keys(this.errors).length !== 0;
  }

  public getFieldError(field: string): string {
    return (this.errors[field] || []).join(", ");
  }

  public getErrorsAsList(): string[] {
    if (!this.hasErrors) {
      return [];
    }

    const errorList = [
      this.generalError,
      ...Object.keys(this.errors).map((field) => this.getFieldError(field)),
    ].filter((x) => !!x);
    return _.uniq(errorList);
  }

  public getErrorsAsString(): string {
    return this.getErrorsAsList().join(". ");
  }

  public setServerErrors(problemDetails: ValidationProblemDetails) {
    const errors = _.cloneDeep(problemDetails.errors) || {};
    this.problemDetails = ProblemDetailsHelper.getWithExtensions(problemDetails);
    this.serverErrors = errors;
    if (!errors[backEndGeneralErrorKey]) {
      const serverGeneralError = problemDetails.detail || problemDetails.title || "";
      if (serverGeneralError) {
        generalErrorKeys.forEach((key) => (this.serverErrors[key] = [serverGeneralError]));
      }
    }
    this.buildErrors();
  }

  public clear(): ValidationInfo {
    this.generalError = "";
    this.clientErrors = {};
    this.serverErrors = {};
    this.errors = {};
    return this;
  }

  /** Handle server-side error/validation response. */
  public handleApiErrorResponse(err: any): void {
    const problemDetails = apiClient.getProblemDetails(err);
    if (!problemDetails) {
      return;
    }
    this.setServerErrors(problemDetails);
  }

  /** Do client-side validation. */
  public validate(schema: Yup.ObjectSchema<any>, obj: any): boolean {
    try {
      schema.validateSync(obj, {
        abortEarly: false,
        stripUnknown: false,
        recursive: true,
      });
      this.clientErrors = {};
      this.buildErrors();
      return true;
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const yupError = err as Yup.ValidationError;

        for (const innerError of yupError.inner) {
          const field = innerError.path!;
          this.clientErrors[field] = [innerError.message];
        }

        this.buildErrors();
        return false;
      }

      return false;
    }
  }

  /** Builds errors from client-side and server-side errors. */
  private buildErrors() {
    const result: any = {};
    const clientKeys = Object.keys(this.clientErrors);
    const serverKeys = Object.keys(this.serverErrors);
    _.uniq([...clientKeys, ...serverKeys]).forEach((key) => {
      const clientValue: string[] = this.clientErrors[key] || [];
      const serverValue: string[] = this.serverErrors[key] || [];

      result[key] = [...clientValue, ...serverValue];
      result[key] = _.uniq(result[key]);
    });

    this.errors = result;

    const generalErrors =
      generalErrorKeys.map((key) => this.errors[key] || []).find((x) => x.length !== 0) || [];
    this.generalError = generalErrors.join(", ") || "";
  }
}
