import { Box, IconButton, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowHeightReturnValue } from "@mui/x-data-grid";
import _ from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { FilterSpec } from "@/common/filters/filterSpec";
import { TabularHelper } from "@/common/helpers/tabular";
import { SortSpec } from "@/common/sorting/sortSpec";
import { GridRowHeightParamsCustom } from "@/common/ts/dataGrid";
import {
  TabularBorderVariant,
  TabularColumnModel,
  TabularColumnPurpose,
  TabularDisplayVariantType,
  TabularFilterProps,
  TabularPaginationProps,
  TabularQuickFilterProps,
  TabularRefetchProps,
  TabularRenderRowActionFunc,
  TabularRowActionsProps,
  TabularRowModel,
  TabularRowSelectionMap,
  TabularSortProps,
  TabularState,
  TabularStatePersistenceProps,
  TabularStatePersistenceStrategy,
  TabularTabsProps,
} from "@/common/ts/dataTabular";
import { useResponsiveValueResolver } from "@/theme/hooks";
import { ResponsiveStyleValueCustom } from "@/theme/types";

import AppVisibilitySensor from "../AppVisibilitySensor";
import DataGrid, { gridInternalFields } from "../DataGrid/DataGrid";
import { DataListDefinition } from "../DataList/DataList";
import AppIcon from "../Icons/AppIcon";
import BulkActions, { BulkActionsExternalProps } from "./BulkActions";
import TableCellContent from "./TableCellContent";

const rowCheckboxSelectionField = "__check__";
const rowNumberField = "__rowNumber__";
const rowActionsField = "__rowActions__";
export const tabularInternalFields = [rowCheckboxSelectionField, rowNumberField, rowActionsField];
export const tabularInternalFieldsMap = _.chain(tabularInternalFields)
  .keyBy((x) => x)
  .mapValues((x) => true)
  .value();

export const tabularAndGridInternalFields = _.uniq([
  ...tabularInternalFields,
  ...gridInternalFields,
]);
export const tabularAndGridInternalFieldsMap = _.chain(tabularAndGridInternalFields)
  .keyBy((x) => x)
  .mapValues((x) => true)
  .value();

const defaultColumnProps: Partial<TabularColumnModel<any>> = {
  minWidth: 100,
  width: 100,
  maxWidth: 600,
};

export const controlFields = {
  controls: "controls",
  bulkActionControl: "bulkActionControl",
};

const defaultStatePersistenceProps: Partial<TabularStatePersistenceProps> = {
  isEnabled: false,
  strategy: TabularStatePersistenceStrategy.LocalStorage,
};

export interface TabularProps<TItem extends TabularRowModel> {
  variant?: ResponsiveStyleValueCustom<TabularDisplayVariantType>;
  columns: TabularColumnModel<TItem>[];
  rows?: TItem[] | null;
  /** If rows are loading.
   * @default false
   */
  isLoading?: boolean;
  /** Row link. */
  rowTo?: string | ((item: TItem) => string);
  /** v2. Configurable. Suits for any case. */
  rowActions?: TabularRowActionsProps<TItem>;
  tabs?: TabularTabsProps;
  pagination?: TabularPaginationProps;
  sort?: TabularSortProps;
  quickFilter?: TabularQuickFilterProps;
  filters?: TabularFilterProps;
  refetch?: TabularRefetchProps;
  /** Sets the height in pixel of a row in the grid. */
  rowHeight?: number;
  /** Enables scroll helpers - scroll to the start, scroll to the end.
   * @default true
   */
  isWithScrollHelpers?: boolean;
  /** Enables row numbers.
   * @default true
   */
  isRowNumbersEnabled?: boolean;
  /** Enables column menu.
   * @default true
   */
  isColumnMenuEnabled?: boolean;
  /** Enables column resize.
   * @default true
   */
  isColumnSelectorEnabled?: boolean;
  /** Enables column resize.
   * @default true
   */
  isColumnResizeEnabled?: boolean;
  /** Enables columns sort.
   * @default true
   */
  isColumnSortEnabled?: boolean;
  /** Enables column filter.
   * @default true
   */
  isColumnFilterEnabled?: boolean;
  /** Enables pagination.
   * @default true
   */
  isPaginationEnabled?: boolean;
  bulkActions?: BulkActionsExternalProps<string>;
  /** Enabled if non-empty object is passed. */
  statePersistence?: TabularStatePersistenceProps;
  /** @default none */
  borderVariant?: TabularBorderVariant;
  sx?: SxProps<Theme>;
  /** Resolves unique row id. */
  getRowId: (item: TItem) => string;
  /** Function that sets the row height per row. */
  getRowHeight?: (params: GridRowHeightParamsCustom<TItem>) => GridRowHeightReturnValue;
  onRowClick?: DataListDefinition<TItem>["onRowClick"];
  /** v1. Not configurable. Suits for single icon button action. */
  renderRowAction?: TabularRenderRowActionFunc<TItem>;

  // DataList required props
  // DataListProps?: Omit<
  //   DataListPropsType<TItem>,
  //   | "columns"
  //   | "rows"
  //   | "getRowId"
  //   | "onSortModelChange"
  //   | "defaultSortModel"
  //   | "onPaginationChange"
  //   | "paginationInfo"
  //   | "isLoading"
  //   | "defaultLimit"
  //   | "rowTo"
  //   | "onRowClick"
  //   | "renderRowAction"
  //   | "tabs"
  // >;
  // DataGridProps?: Pick<DataGridPropsType<TItem>, "sx" | "rowHeight" | "getRowHeight">;
}

export default function DataTabular<TItem extends TabularRowModel>({
  sx,
  // variant = { xxs: "datalist", desktop: "datagrid" },
  variant = { xxs: "datagrid", desktop: "datagrid" }, // use datagrid for now (until datalist is fixed)
  columns,
  rows,
  isLoading,
  rowTo,
  rowActions,
  rowHeight,
  isWithScrollHelpers = true,
  isRowNumbersEnabled = true,
  isColumnMenuEnabled = true,
  isColumnSelectorEnabled = true,
  isColumnResizeEnabled = true,
  isColumnSortEnabled = true,
  isColumnFilterEnabled = true,
  isPaginationEnabled = true,
  tabs,
  pagination,
  sort,
  quickFilter,
  filters,
  refetch,
  bulkActions,
  statePersistence,
  borderVariant = "none",
  // DataListProps,
  // DataGridProps,
  getRowId,
  getRowHeight,
  renderRowAction,
  onRowClick,
}: TabularProps<TItem>) {
  bulkActions = bulkActions ? { ...bulkActions, enabled: bulkActions.enabled ?? true } : undefined;

  const variantComputed = useResponsiveValueResolver(variant, "datagrid");
  const [rowSelectionMap, setRowSelectionMap] = useState<TabularRowSelectionMap>({});

  const statePersistenceComputed = useMemo(
    () =>
      statePersistence
        ? {
            ...defaultStatePersistenceProps,
            ...statePersistence,
            isEnabled: statePersistence.isEnabled ?? true,
          }
        : undefined,
    [],
  );

  // initial state - get persisted or compute
  const defaultTabularInitialState = useMemo<TabularState>(
    () => ({
      column: {
        visibilityMap: _.chain(
          columns
            .map((x) => ({ field: x.field, isVisible: x.isVisible }))
            .filter((x) => !_.isNil(x.isVisible)),
        )
          .keyBy((x) => x.field)
          .mapValues((x) => x.isVisible ?? false)
          .value(),
        widthMap: undefined,
      },
    }),
    [],
  );
  const tabularPersistedState = useMemo<TabularState | undefined>(
    () =>
      statePersistenceComputed?.isEnabled
        ? TabularHelper.getPersistedState(
            statePersistenceComputed.persistenceKey,
            statePersistenceComputed.strategy,
          )
        : undefined,
    [],
  );
  const tabularInitialState = useMemo<TabularState>(
    () => tabularPersistedState || defaultTabularInitialState,
    [],
  );

  const [tabularState, setTabularState] = useState<TabularState>(tabularInitialState);

  const rowSelectionStatuses = useMemo(() => {
    const rowSelectionMapCount = Object.keys(rowSelectionMap).length;
    return {
      isAnyRowSelected: rowSelectionMapCount !== 0,
      isAllRowsSelected: rowSelectionMapCount !== 0 && rowSelectionMapCount === rows?.length,
    };
  }, [rowSelectionMap, rows]);

  const startAnchorRef = useRef<HTMLElement | null>(null);
  const endAnchorRef = useRef<HTMLElement | null>(null);
  const [isStartAnchorVisible, setIsStartAnchorVisible] = useState(true);
  const [isEndAnchorVisible, setIsEndAnchorVisible] = useState(false);

  const isScrollHelpersEnabled = useMemo(
    () => isWithScrollHelpers && rows && rows?.length > 10,
    [rows, isWithScrollHelpers],
  );
  const isHelpScrollToStart = useMemo(
    () => isScrollHelpersEnabled && !isStartAnchorVisible,
    [isScrollHelpersEnabled, isStartAnchorVisible],
  );
  const isHelpScrollToEnd = useMemo(
    () => isScrollHelpersEnabled && !isEndAnchorVisible,
    [isScrollHelpersEnabled, isEndAnchorVisible],
  );
  const isScrollHelpersVisible = useMemo(
    () => isHelpScrollToStart || isHelpScrollToEnd,
    [isHelpScrollToStart, isHelpScrollToEnd],
  );

  // columns must be memorized once and do not change between renders
  const columnsComputed = useMemo<TabularColumnModel<TItem>[]>(() => {
    const newColumns: TabularColumnModel<TItem>[] = columns
      .map((column) => ({
        ...defaultColumnProps,
        ...column,
        isResizable: column.isResizable ?? true,
        isHideable: column.isHideable ?? true,
        isSortable: column.isSortable ?? false,
        isFilterable:
          column.isFilterable ??
          (filters?.filterSpec?.hasField(column.field) || (column.filters ? true : undefined)) ??
          false,
        width: tabularInitialState.column?.widthMap
          ? (tabularInitialState.column?.widthMap[column.field] ?? column.width)
          : column.width,
      }))
      .filter((x) => x.if || _.isNil(x.if));

    const isAnyColumnHasPositiveFlexWidth = newColumns.some((x) => !_.isNil(x.flex) && x.flex >= 1);

    // checkbox column for bulk actions in datalist
    // if (variantComputed === "datalist" && bulkActions?.enabled) {
    //   tempColumns.unshift({
    //     renderTitle: () => (
    //       <Checkbox
    //         indeterminate={
    //           rowSelectionStatuses.isAnyRowSelected && !rowSelectionStatuses.isAllRowsSelected
    //         }
    //         checked={rowSelectionStatuses.isAllRowsSelected}
    //         onChange={() => {
    //           if (rowSelectionMap && rows) {
    //             setRowSelectionMap((prev) =>
    //               !ObjectHelper.isEmpty(prev)
    //                 ? {}
    //                 : _.zipObject(
    //                     rows.map((x) => x.id),
    //                     _.fill(Array(rows.length), true),
    //                   ),
    //             );
    //           }
    //         }}
    //       />
    //     ),
    //     field: controlFields.bulkActionControl,
    //     type: undefined,
    //     isSortable: false,
    //     hideable: false,
    //     isColumnMenuDisabled: true,
    //     isToDisabled: true,
    //     isIgnoreCellOverflow: true,
    //     width: 70,
    //     renderCell: (item: TItem) => (
    //       <Checkbox
    //         key={item.id}
    //         checked={rowSelectionMap[item.id] ? true : false}
    //         onChange={(e) => {
    //           setRowSelectionMap(
    //             ObjectHelper.setOrRemoveKey(
    //               { ...rowSelectionMap },
    //               item.id || "",
    //               e.target.checked,
    //             ),
    //           );
    //         }}
    //       />
    //     ),
    //   });
    // }

    // if variant is datagrid and actions specified - push new column named Controls to the end of columns array
    if (variantComputed === "datagrid") {
      if (renderRowAction) {
        // v1
        newColumns.push({
          purpose: TabularColumnPurpose.Actions,
          title: "",
          field: rowActionsField,
          // type: undefined,
          isSortable: false,
          isHideable: false,
          isResizable: false,
          isColumnMenuDisabled: true,
          isToDisabled: true,
          isIgnoreCellOverflow: true,
          // let actions take all the free space and be aligned to the row end if there are no other flex columns
          width: 60,
          minWidth: 60,
          maxWidth: isAnyColumnHasPositiveFlexWidth ? 60 : undefined,
          flex: isAnyColumnHasPositiveFlexWidth ? undefined : 1,
          renderCell: (item: TItem) => {
            return (
              <Stack
                sx={{ width: "100%", flex: 1, justifyContent: "flex-end", alignItems: "center" }}
                direction='row'
              >
                {renderRowAction({
                  item,
                })}
              </Stack>
            );
          },
        });
      }
      // v2
      else if (rowActions) {
        newColumns.push({
          purpose: TabularColumnPurpose.Actions,
          title: "",
          // let actions take all the free space and be aligned to the row end if there are no other flex columns
          ...(!rowActions.variant || rowActions.variant === "iconButton"
            ? {
                width: 60,
                minWidth: 60,
                maxWidth: isAnyColumnHasPositiveFlexWidth ? 60 : undefined,
                flex: isAnyColumnHasPositiveFlexWidth ? undefined : 1,
              }
            : {}),
          ...(rowActions.variant === "arbitrary"
            ? { width: undefined, minWidth: 60, maxWidth: undefined, flex: 1 }
            : {}),

          ...rowActions.columnProps,
          field: rowActionsField,
          // type: undefined,
          isSortable: false,
          isHideable: false,
          isResizable: false,
          isColumnMenuDisabled: true,
          isToDisabled: true,
          isIgnoreCellOverflow: true,
          renderCell: (item: TItem) => {
            return (
              <Box sx={{ width: "100%", flex: 1 }}>
                {rowActions.renderActions({
                  item,
                })}
              </Box>
            );
          },
        });
      }
    }

    return newColumns;
  }, []);

  // columns must be memorized once and do not change between renders
  const dataGridColumnsComputed = useMemo<GridColDef<TItem>[]>(
    () =>
      columnsComputed.map((column) => {
        const renderCellCasted = function (params: GridRenderCellParams<TItem>) {
          return column.renderCell(params.row);
        };

        const newColumn: GridColDef<TItem> = {
          field: column.field,
          type: undefined,
          headerName: column.title,
          description: column.description,
          width: column.width,
          minWidth: column.minWidth,
          maxWidth: column.maxWidth,
          flex: column.flex,
          resizable: column.isResizable, // DataGrid Pro
          hideable: column.isHideable,
          sortable: column.isSortable,
          filterable: column.isFilterable,
          disableColumnMenu: column.isColumnMenuDisabled,
          // wrap each cell content
          renderCell: (params: GridRenderCellParams<TItem>) => {
            // render link if:
            // - link is set and not disabled
            // - cell holds data
            const cellTo = column.to ?? rowTo;
            const cellToComputed = _.isFunction(cellTo) ? cellTo(params.row) : cellTo;
            const isToEnabled =
              (_.isNil(column.purpose) || column.purpose === TabularColumnPurpose.Data) &&
              !column.isToDisabled;
            return (
              <TableCellContent
                isHandleOverflow={!column.isIgnoreCellOverflow}
                to={isToEnabled ? cellToComputed : undefined}
                renderContent={() => renderCellCasted(params)}
                renderDetails={
                  column.renderCellDetails
                    ? () => column.renderCellDetails && column.renderCellDetails(params.row)
                    : undefined
                }
              />
            );
          },
        };
        return newColumn;
      }),
    [],
  );

  const paginationComputed = useMemo<TabularPaginationProps | undefined>(
    () =>
      pagination
        ? {
            ...pagination,
            onChange: (newValue) => {
              // console.log("Tabular. pagination.onChange.", { newValue });
              pagination?.onChange && pagination?.onChange(newValue);
              setRowSelectionMap({});
              startAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            },
          }
        : undefined,
    [pagination?.currentPaginationInfo],
  );

  const sortSpecComputed = useMemo<SortSpec | undefined>(
    () => sort?.sortSpec || TabularHelper.buildSortSpecFromColumns(columnsComputed),
    [],
  );

  const sortComputed = useMemo<TabularSortProps | undefined>(
    () =>
      sort
        ? {
            ...sort,
            sortSpec: sortSpecComputed,
          }
        : undefined,
    [sort?.sortDefinition],
  );

  const filterSpecComputed = useMemo<FilterSpec | undefined>(() => {
    const inferredFilterSpec = TabularHelper.buildFilterSpecFromColumns(columnsComputed);
    const combinedSpec = FilterSpec.combineSpecs(filters?.filterSpec, inferredFilterSpec);
    return combinedSpec;
  }, []);

  const filtersComputed = useMemo<TabularFilterProps | undefined>(
    () =>
      filters
        ? {
            ...filters,
            filterSpec: filterSpecComputed,
          }
        : undefined,
    [filters?.filterDefinition],
  );

  const persistState = useCallback((newState: TabularState) => {
    if (statePersistenceComputed?.isEnabled) {
      // console.log(`Tabular. Persist state.`, { statePersistenceComputed, newState });
      TabularHelper.persistState(
        statePersistenceComputed.persistenceKey,
        newState,
        statePersistenceComputed.strategy,
      );
    }
  }, []);

  const persistStateDebounce = useCallback(
    _.debounce(persistState, 500, { leading: false, trailing: true }),
    [persistState],
  );

  // console.log("Tabular.", {
  //   statePersistenceComputed,
  //   defaultTabularInitialState,
  //   tabularPersistedState,
  //   tabularInitialState,
  //   tabularState,
  //   filterSpecComputed,
  //   filtersComputed,
  //   persistedWidthMapJson: JSON.stringify(tabularPersistedState?.column?.widthMap),
  //   initialWidthMapJson: JSON.stringify(tabularInitialState?.column?.widthMap),
  //   widthMapJson: JSON.stringify(tabularState?.column?.widthMap),
  //   tabularIdColJson: JSON.stringify(columnsComputed.find((x) => x.field === "id")),
  //   gridIdColJson: JSON.stringify(dataGridColumnsComputed.find((x) => x.field === "id")),
  // });

  return (
    <>
      <Box sx={{ position: "relative", borderRadius: "inherit" }}>
        {/* Start visibility detector */}
        <AppVisibilitySensor
          onChange={(isVisible) => {
            if (isVisible !== isStartAnchorVisible) {
              setIsStartAnchorVisible(isVisible);
            }
          }}
        >
          {(observableProps) => <Box {...observableProps} ref={startAnchorRef}></Box>}
        </AppVisibilitySensor>

        {/* TODO */}
        {/* {variantComputed === "datalist" && (
          <DataList<TItem>
            sx={sx}
            columns={columnsComputed}
            rows={rows}
            getRowId={getRowId}
            // defaultSortModel={defaultSortModel}
            onSortModelChange={onSortModelChange}
            onPaginationChange={(newValue) => {
              onPaginationChange && onPaginationChange(newValue);
              setRowSelectionMap({});
              startAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
            paginationInfo={currentPaginationInfo ?? initialPaginationInfo}
            isLoading={isLoading}
            filterValues={filterValues}
            rowTo={rowTo}
            onRowClick={onRowClick}
            renderRowAction={renderRowAction}
            rowActions={rowActions}
            tabs={tabs}
            contentProps={{
              itemColor: (item) => (rowSelectionMap[item.id] ? "selected" : "none"),
            }}
            {...DataListProps}
          />
        )} */}

        {variantComputed === "datagrid" && (
          <DataGrid<TItem>
            sx={{ ...sx }}
            columns={dataGridColumnsComputed}
            rows={rows}
            getRowId={getRowId}
            rowTo={rowTo}
            rowHeight={rowHeight}
            getRowHeight={getRowHeight}
            rowSelectionModel={TabularHelper.mapTabularRowSelectionMapToGridRowSelectionModel(
              rowSelectionMap,
            )}
            isLoading={isLoading}
            isRowCheckboxSelectionEnabled={bulkActions?.enabled}
            isRowNumbersEnabled={isRowNumbersEnabled}
            isColumnMenuEnabled={isColumnMenuEnabled}
            isColumnSelectorEnabled={isColumnSelectorEnabled}
            isColumnResizeEnabled={isColumnResizeEnabled}
            isColumnSortEnabled={isColumnSortEnabled}
            isColumnFilterEnabled={isColumnFilterEnabled}
            isPaginationEnabled={isPaginationEnabled}
            tabs={tabs}
            columnVisibility={{
              initialMap: tabularInitialState?.column?.visibilityMap,
              onChange: (newValue) => {
                // ignore internal fields
                Object.keys(tabularAndGridInternalFieldsMap).forEach((internalField) => {
                  delete newValue[internalField];
                });

                const newTabularState: TabularState = {
                  ...tabularState,
                  column: {
                    ...tabularState.column,
                    visibilityMap: newValue,
                  },
                };
                // console.log("Tabular. columnVisibility.onChange.", { newValue, newTabularState });
                setTabularState(newTabularState);
                persistStateDebounce(newTabularState);
              },
            }}
            columnWidth={{
              initialMap: tabularInitialState?.column?.widthMap,
              currentMap: tabularState.column?.widthMap,
              onChange: (newValue) => {
                // ignore internal fields
                if (!tabularAndGridInternalFieldsMap[newValue.field]) {
                  const newTabularState: TabularState = {
                    ...tabularState,
                    column: {
                      ...tabularState.column,
                      widthMap: {
                        ...tabularState.column?.widthMap,
                        [newValue.field]: newValue.width,
                      },
                    },
                  };
                  // console.log("Tabular. columnWidth.onChange.", { newValue, newTabularState });
                  setTabularState(newTabularState);
                  persistStateDebounce(newTabularState);
                }
              },
            }}
            pagination={paginationComputed}
            sort={sortComputed}
            quickFilter={quickFilter}
            filters={filtersComputed}
            refetch={refetch}
            borderVariant={borderVariant}
            onRowClick={onRowClick}
            onRowSelectionModelChange={(newValue) =>
              setRowSelectionMap(
                TabularHelper.mapGridRowSelectionModelToTabularRowSelectionMap(newValue),
              )
            }
            // onStateChange={}
            // {...DataGridProps}
          />
        )}

        {/* Scroll to top/bottom helper button */}
        {isScrollHelpersVisible && (isHelpScrollToStart || isHelpScrollToEnd) && (
          <Tooltip title={`Scroll to ${isHelpScrollToStart ? "top" : "bottom"}`}>
            <IconButton
              color='text'
              size='medium'
              sx={{
                position: "fixed",
                bottom: "2%",
                right: "2%",
                opacity: 1,
                zIndex: (th) => th.zIndex.fab,
                boxShadow: (th) => th.shadows[1],
                backgroundColor: (th) => th.palette.background.paper,
                "&:hover": {
                  backgroundColor: (th) => th.palette.background.paper,
                },
              }}
              onClick={() => {
                if (isHelpScrollToStart) {
                  startAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
                } else {
                  endAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {isHelpScrollToStart ? <AppIcon of='arrowUpward' /> : <AppIcon of='arrowDownward' />}
            </IconButton>
          </Tooltip>
        )}

        {/* End visibility detector */}
        <AppVisibilitySensor
          onChange={(isVisible) => {
            if (isVisible !== isEndAnchorVisible) {
              setIsEndAnchorVisible(isVisible);
            }
          }}
        >
          {(observableProps) => <Box {...observableProps} ref={endAnchorRef}></Box>}
        </AppVisibilitySensor>
      </Box>

      {/** setRowSelectionMap for default actions handles */}
      {bulkActions && (
        <BulkActions
          rowSelection={{
            rowSelectionMap: rowSelectionMap,
            setRowSelectionMap: setRowSelectionMap,
          }}
          {...bulkActions}
        />
      )}
    </>
  );
}
