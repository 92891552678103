import { Moment } from "moment";
import moment from "moment-timezone";

import { CustomTimezone, CustomUtcOffset } from "../ts/datetime";

export class TimeZoneHelper {
  /** Returns list of all time zones. */
  public static getTzList(): CustomTimezone[] {
    return moment.tz.names().map((tz) => ({
      id: tz,
      displayName: tz,
    }));
  }

  /** Guesses user time zone. */
  public static guessTz(): CustomTimezone {
    const tz = moment.tz.guess();
    return {
      id: tz,
      displayName: tz,
    };
  }

  /** Calculates offset of provided tz and date relatively to UTC time zone. */
  public static getTzUtcOffset(tz: string, forDate?: string | Moment): CustomUtcOffset {
    forDate = forDate ? moment(forDate).tz(tz) : moment.tz(tz);

    const offset = forDate.utcOffset();
    const offsetStr =
      offset === 0
        ? "+00:00"
        : forDate.format().match(/(?<offset>(\+|-)\d\d:\d\d)/)?.groups?.offset ||
          ("+00:00" as string);

    return {
      offset,
      offsetStr: offsetStr,
    };
  }
}
