import { Box, SxProps, Theme } from "@mui/material";

interface Props {
  value?: string | null;
  sx?: SxProps<Theme>;
}

export default function VersionDisplay({ value, sx }: Props) {
  if (!value) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {value.toLowerCase().startsWith("v") ? value : `v${value}`}
    </Box>
  );
}
