import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import InlineCode from "@/common/components/Code/InlineCode";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { enumService } from "@/common/services/enum";

export default function ApplicationEnumsPage() {
  const enumCatalog = enumService.getOrRestoreEnumCatalog();

  const [search, setSearch] = useState("");

  const enumList = useMemo(
    () =>
      _.chain(enumCatalog?.catalog?.enumsMap || {})
        .entries()
        .filter(([enumName, dtos]) =>
          search ? enumName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true,
        )
        .map(([enumName, dtos]) => ({
          id: enumName,
          enumName: _.first(dtos)?.enumTypeName || enumName,
          dtos,
        }))
        .flatten()
        .value(),
    [enumCatalog, search],
  );

  const handleChangeSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        label='Search'
        margin='normal'
        size='small'
        type='text'
        variant='outlined'
        value={search || ""}
        onChange={handleChangeSearch}
        sx={{ margin: 0, mr: 1, mb: 2, minWidth: 200 }}
      />

      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: "enumName",
              title: "EnumTypeName",
              flex: 1,
              renderCell: (item) => <InlineCode>{item.enumName}</InlineCode>,
            },
            {
              field: "dtos.length",
              title: "Values",
              flex: 1,
              renderCell: (item) => <>{item.dtos?.length}</>,
            },
          ]}
          rows={enumList}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_SYSTEM_APPLICATION_ENUM_VIEW(item.enumName)}
          pagination={{
            initialPaginationInfo: {
              offset: 0,
              limit: enumList.length,
              totalCount: enumList.length,
            },
            currentPaginationInfo: undefined,
            onChange: () => {},
          }}
          renderRowAction={({ item }) => (
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <MenuItem
                component={RouterLink}
                to={ROUTE_PATH.ADMIN_SYSTEM_APPLICATION_ENUM_VIEW(item.enumName)}
              >
                <ListItemIcon>
                  <AppIcon of='view' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </MenuWithTrigger>
          )}
        />
      </Stack>
    </Box>
  );
}
