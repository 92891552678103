import { Alert, AlertTitle, Box, SxProps, Theme } from "@mui/material";

interface Props {
  isBuiltIn: boolean;
  car2DbId: string | null | undefined;
  sx?: SxProps<Theme>;
}

export default function ReferenceDataSyncStopAlert({ isBuiltIn, car2DbId, sx }: Props) {
  if (!isBuiltIn) {
    return null;
  }

  return (
    <Alert severity='warning' sx={sx}>
      <AlertTitle>Sync stop notice!</AlertTitle>
      <Box>
        This reference data entity is built-in (seeded and synced by the system automatically). If
        you update it manually the sync will stop.
      </Box>
      {car2DbId && (
        <Box>
          It also sourced and synced from Car2DB (#{car2DbId}). If you update it manually the sync
          will stop.
        </Box>
      )}
      <Box>{`Please ensure you're sure what you're doing.`}</Box>
    </Alert>
  );
}
