import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/** useEffect that runs one time on mount. */
export const useEffectOnce = (effect: EffectCallback, deps?: DependencyList): void => {
  const isRunRef = useRef(false);

  useEffect(() => {
    if (!isRunRef.current) {
      isRunRef.current = true;
      effect();
    }
  }, deps);
};
