import _ from "lodash";
import uuid4 from "uuid4";

import {
  ChatMessageNodeDto,
  ChatMessageNodeType,
  ChatMessageTagNodeDataDto,
  ChatMessageTextNodeDataDto,
  ChatMessageWebLinkNodeDataDto,
  GeneralTagCandidateDto,
} from "@/core/api/generated";

import { ChatMessageHelper } from "../helpers/entity/chatMessage";

export class ChatMessageNodeBuilder {
  private node: ChatMessageNodeDto;

  constructor(type?: ChatMessageNodeType) {
    this.node = {
      id: this.generateTempNodeId(),
      type,
      text: {
        text: "",
      },
    };
  }

  public static newTextNode() {
    return new ChatMessageNodeBuilder(ChatMessageNodeType.Text);
  }

  public static newTextNodeForWhiteSpace() {
    return new ChatMessageNodeBuilder(ChatMessageNodeType.Text)
      .withType(ChatMessageNodeType.Text)
      .withIndexRange({
        startIndex: -1,
        endIndex: -1,
      })
      .withText({
        text: " ",
      });
  }

  public static newWebLinkNode() {
    return new ChatMessageNodeBuilder(ChatMessageNodeType.WebLink);
  }

  public static newTagNode() {
    return new ChatMessageNodeBuilder(ChatMessageNodeType.Tag);
  }

  public static newTagNodeFromCandidate(candidate: GeneralTagCandidateDto) {
    return new ChatMessageNodeBuilder(ChatMessageNodeType.Tag)
      .withType(ChatMessageNodeType.Tag)
      .withIndexRange({
        startIndex: -1,
        endIndex: -1,
      })
      .withText({
        text: candidate.tag?.title,
      })
      .withTag({
        tag: candidate.tag,
      });
  }

  public static newFromNode(otherNode: ChatMessageNodeDto) {
    return new ChatMessageNodeBuilder().fromNode(otherNode);
  }

  public static isTempNodeId(id?: string | null) {
    if (!id) {
      return false;
    }
    return id.startsWith("tempid-");
  }

  private generateTempNodeId(): string {
    return `tempid-${uuid4()}`;
  }

  public fromNode(otherNode: ChatMessageNodeDto) {
    this.node = {
      ...ChatMessageHelper.cloneNode(otherNode),
      id: this.node.id,
    };
    return this;
  }

  public withType(type: ChatMessageNodeType) {
    this.node.type = type;
    return this;
  }

  public withIndexRange(range: { startIndex?: number | null; endIndex?: number | null }) {
    this.node.startIndex = _.isNil(range.startIndex) ? undefined : range.startIndex!;
    this.node.endIndex = _.isNil(range.endIndex) ? undefined : range.endIndex!;
    return this;
  }

  public withText(text: ChatMessageTextNodeDataDto) {
    this.node.text = {
      ...text,
      text: text.text || "",
    };
    return this;
  }

  public withWebLink(webLink: ChatMessageWebLinkNodeDataDto) {
    this.node.webLink = {
      ...webLink,
    };
    return this;
  }

  public withTag(tag: ChatMessageTagNodeDataDto) {
    this.node.tag = {
      ...tag,
    };
    return this;
  }

  public build(): ChatMessageNodeDto {
    if (!this.node.type) {
      throw new Error("Node type must be set.");
    }
    return this.node;
  }
}
