import { Box, Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import _ from "lodash";

import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { VehicleDamageDto } from "@/core/api/generated";

import { ExtendedDamageType } from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import VehicleDamageList from "../Entity/VehicleDamage/VehicleDamageList";
import AppModalTitle from "../Modals/AppModalTitle";
import DamagePointsByVisualModelViewer from "./DamagePointsByVisualModelViewer";

export interface OwnProps {
  damages: Array<ExtendedDamageType>;
}
type Props = OwnProps & DialogProps;

export default function DamagePointsByVisualModelViewerModal({
  damages,
  children,
  ...dialogProps
}: Props) {
  const openStatus = useModalOpenStatus(dialogProps);
  const groupedByVisualModel = _.groupBy(damages, (d) => {
    return d.visualModel?.id;
  });
  return (
    <Box>
      <Dialog fullWidth maxWidth='lg' {...dialogProps}>
        <DialogContent>
          <AppModalTitle
            onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
          >
            <Typography component='div' variant='h1' sx={{ display: "flex", alignItems: "center" }}>
              Vehicle damages
            </Typography>
          </AppModalTitle>
          {openStatus.isOpen &&
            Object.keys(groupedByVisualModel).map((visualModelId) => {
              return (
                <DamagePointsByVisualModelViewer
                  key={visualModelId}
                  visualModelId={visualModelId}
                  damages={groupedByVisualModel[visualModelId]}
                />
              );
            })}
          <VehicleDamageList
            vehicleDamages={damages as VehicleDamageDto[]} // intentional use of 'as' to go easy way with type checking
            displayProps={{
              vehicleColumn: true,
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
