import { UserPartiesMembershipDto } from "@/core/api/generated";

export class PartyHelper {
  /** Checks if negotiation deadline can be edit now. */
  public static isMyParty(
    partiesMembership?: UserPartiesMembershipDto | null,
    partyId?: string,
  ): boolean {
    return (
      (partiesMembership && partyId && partiesMembership.membershipByPartyMap![partyId] === true) ||
      false
    );
  }
}
