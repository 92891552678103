import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import ContractAssessmentFlowStateFilterTypeIcon from "@/App/MainAppView/Flows/Assessment/components/ContractAssessmentFlowStateFilterTypeIcon";
import SendAssessmentFlowToCustomerModal from "@/App/MainAppView/Flows/Assessment/components/SendAssessmentFlowToCustomerModal";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import GeneralEventLogsModal from "@/common/components/Entity/GeneralEventLog/GeneralEventLogsModal";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssessmentFlowDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import ContractCustomerCommunicationInfoModal from "../../Contract/ContractCustomerCommunicationInfoModal";
import ContractReminderSettingsModal from "../../Contract/ContractReminderSettingsModal";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import ApprovalsTabContent from "./Tabs/ApprovalsTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum AssessmentFlowViewPageTabs {
  Overview = "Overview",
  Approvals = "Approvals",
}

export interface AssessmentFlowViewPageQueryParams extends GeneralQueryParams {
  tab?: AssessmentFlowViewPageTabs;
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
};

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

interface OwnProps extends BaseEntityViewInheritableProps<AssessmentFlowDto> {
  assessmentFlowId: string | null | undefined;
  assessmentFlow: AssessmentFlowDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function AssessmentFlowView({
  assessmentFlowId,
  assessmentFlow,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.AssessmentFlow),
    },
  });

  const currentTenant = useCurrentTenant();
  const { hasFleetAppAccess, hasFleetCustomerAppAccess } = useAuthorizationInfo();

  // menu, dialogs
  const [isSendToCustomerModalOpen, setIsSendToCustomerModalOpen] = useState(false);
  const [isCommunicationInfoModalOpen, setIsCommunicationInfoModalOpen] = useState(false);
  const [isContractRemindersModalOpen, setIsContractRemindersModalOpen] = useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);

  const assessmentFlowRequest = useApiRequest(
    apiClient.assessmentFlowsApi.apiV1FlowsAssessmentFlowsAssessmentFlowIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assessmentFlowId: assessmentFlowId!,
    },
    {
      deps: [assessmentFlowId],
      skip: !assessmentFlowId,
      // use polling to ensure user will get fresh data after background operations completed.
      // shortPolling: {
      //   intervalMs: 5000,
      //   if: (context) =>
      //     context.data?.state?.isProcessingDamages ||
      //     context.data?.state?.isProcessingDamageCosts ||
      //     context.data?.state?.isDamageCostEvaluating ||
      //     false,
      // },
    },
  );
  assessmentFlow = assessmentFlow || assessmentFlowRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.AssessmentFlow,
        assessmentFlowId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      // refetch as DTO contains additional computed fields
      assessmentFlowRequest.refetch();
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        assessmentFlow && {
          idValue: assessmentFlow.id!,
          newTitle: `${assessmentFlow?.localNumber} (contract ${
            assessmentFlow?.contract?.externalNumber || assessmentFlow?.contract?.localNumber
          })`,
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.AssessmentFlow}
      entityId={assessmentFlowId}
      entity={assessmentFlow}
      entityRequest={assessmentFlowRequest}
    >
      <ViewLayoutV2
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={
                <Stack direction='row' alignItems='center'>
                  {renderIf()
                    .if(hasFleetAppAccess)
                    .then(
                      <span>
                        Damages & Approvals{" "}
                        {assessmentFlow?.contract?.externalNumber ||
                        assessmentFlow?.contract?.localNumber
                          ? `for contract #${assessmentFlow?.contract?.externalNumber || assessmentFlow?.contract?.localNumber}`
                          : ""}
                      </span>,
                    )
                    .elseif(hasFleetCustomerAppAccess)
                    .then(<span>Vehicle Damages & Approvals</span>)
                    .render()}
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <ContractAssessmentFlowStateFilterTypeIcon
                      assessmentFlowId={assessmentFlow?.id || ""}
                      value={assessmentFlow?.state?.filterState}
                      isEnableStateRecompute
                      fontSize='medium'
                      withTooltip
                      onUpdated={(newValue) => assessmentFlowRequest.replaceData(newValue)}
                      sx={{ ml: 1 }}
                    />
                  </AuthorizedElement>
                </Stack>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.CONTRACT_VIEW(assessmentFlow?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={assessmentFlow?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.AssessmentFlow,
                      entityId: assessmentFlow?.id,
                      onSaved: (newValue) => {
                        assessmentFlowRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              secondaryActions={
                displayProps?.actions && (
                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <MenuWithTrigger
                      withAuthCloseOnClick
                      trigger={
                        <IconButton sx={{ ml: "auto" }}>
                          <AppIcon of='moreVert' />
                        </IconButton>
                      }
                    >
                      <MenuItem
                        component={RouterLink}
                        to={ROUTE_PATH.CONTRACT_VIEW(assessmentFlow?.contract?.id)}
                      >
                        <ListItemIcon>
                          <AppIcon of='contract' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>View contract</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsSendToCustomerModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='email' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Send to customer</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='contacted' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Customer communication</ListItemText>
                      </MenuItem>
                      <MenuItem onClick={() => setIsContractRemindersModalOpen(true)}>
                        <ListItemIcon>
                          <AppIcon of='reminder' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Contract reminders</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setIsEventLogModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='history' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Event log (contract)</ListItemText>
                      </MenuItem>
                      <ListItem sx={{ pt: 0 }}>
                        <ListItemText
                          sx={{ m: 0, p: 0 }}
                          primary={
                            <Typography
                              component='div'
                              variant='body2'
                              color='text.secondary'
                              sx={{ m: 0, p: 0 }}
                            >
                              Quick actions
                            </Typography>
                          }
                        />
                      </ListItem>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.DamageDetectionPerform,
                          AppPermission.DamageDetectionManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.DAMAGE_DETECTION_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='damageDetection' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New damage detection</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.DamageCostEvaluationPerform,
                          AppPermission.DamageCostEvaluationManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='damageCostEvaluation' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New damage cost evaluation</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                      <AuthorizedElement
                        permissions={[
                          AppPermission.AccessoryCheckPerform,
                          AppPermission.AccessoryCheckManage,
                        ]}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={ROUTE_PATH.ACCESSORY_CHECK_CREATE({
                            vehicleId: assessmentFlow?.vehicle?.id,
                            contractId: assessmentFlow?.contract?.id,
                          })}
                        >
                          <ListItemIcon>
                            <AppIcon of='accessoryCheck' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>New accessory check</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    </MenuWithTrigger>
                  </AuthorizedElement>
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={AssessmentFlowViewPageTabs}
          defaultTabId={AssessmentFlowViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: AssessmentFlowViewPageTabs.Overview, isHideable: false },
            { label: "Approvals", value: AssessmentFlowViewPageTabs.Approvals, isHideable: false },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            assessmentFlow && (
              <>
                {activeTab === AssessmentFlowViewPageTabs.Overview && (
                  <OverviewTabContent
                    assessmentFlow={assessmentFlow}
                    setIsCommunicationInfoModalOpen={setIsCommunicationInfoModalOpen}
                    onContractReminderSettingsUpdated={(newValue) => {
                      assessmentFlowRequest.updateData((fetchedData) => {
                        fetchedData.contract = newValue.contract;
                      });
                    }}
                  />
                )}

                {activeTab === AssessmentFlowViewPageTabs.Approvals && (
                  <ApprovalsTabContent assessmentFlow={assessmentFlow} />
                )}
              </>
            )
          }
        </PageTabsV2>

        {/* Send to customer */}
        {assessmentFlow && (
          <SendAssessmentFlowToCustomerModal
            assessmentFlow={assessmentFlow}
            open={isSendToCustomerModalOpen}
            onClose={() => setIsSendToCustomerModalOpen(false)}
          />
        )}

        {/* Communication info */}
        {assessmentFlow?.contract && (
          <ContractCustomerCommunicationInfoModal
            contractCustomerCommunicationInfoProps={{
              contract: assessmentFlow.contract,
            }}
            open={isCommunicationInfoModalOpen}
            onClose={() => setIsCommunicationInfoModalOpen(false)}
          />
        )}

        {/* Reminders */}
        {assessmentFlow?.contract && (
          <ContractReminderSettingsModal
            reminderSettingsDisplayProps={{
              contract: assessmentFlow.contract,
              onUpdate: (newValue) => {
                assessmentFlowRequest.updateData((fetchedData) => {
                  fetchedData.contract = newValue.contract;
                });
              },
            }}
            open={isContractRemindersModalOpen}
            onClose={() => setIsContractRemindersModalOpen(false)}
          />
        )}

        {/* Event log */}
        {assessmentFlow && (
          <GeneralEventLogsModal
            open={isEventLogModalOpen}
            onClose={() => setIsEventLogModalOpen(false)}
            entityId={assessmentFlow.contract!.id!}
          />
        )}
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
