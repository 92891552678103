import { Button, Container, FormHelperText, Stack } from "@mui/material";
import { useState } from "react";

import ClearSystemCacheModal from "./components/ClearSystemCacheModal";

export default function SystemCachePage() {
  const [isClearCacheModalOpen, setIsClearCacheModalOpen] = useState(false);

  return (
    <Container maxWidth='lg'>
      <Stack spacing={2} sx={{ alignItems: "center" }}>
        <Button variant='contained' color='error' onClick={() => setIsClearCacheModalOpen(true)}>
          Clear system cache
        </Button>
        <FormHelperText>You will be asked what should be cleared.</FormHelperText>
      </Stack>

      <ClearSystemCacheModal
        open={isClearCacheModalOpen}
        onClose={() => setIsClearCacheModalOpen(false)}
      />
    </Container>
  );
}
