import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputProps,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import { GeneralAddressDto, GeneralAddressInputDto } from "@/core/api/generated";

interface Props {
  value?: GeneralAddressDto | GeneralAddressInputDto | undefined;
  action?: JSX.Element & ReactNode;
  errors?: GeneralAddressDto | GeneralAddressInputDto;
  touched?: GeneralAddressDto | GeneralAddressInputDto;
  onChange?: (value: GeneralAddressInputDto | undefined) => void;
  inputsProps?: {
    /** Applied to all inputs */
    all?: {
      size?: InputProps["size"];
      onBlur?: InputProps["onBlur"];
    };
  } & Partial<
    Record<
      keyof GeneralAddressInputDto,
      {
        name?: InputProps["name"];
        onChange?: InputProps["onChange"];
        onBlur?: InputProps["onBlur"];
      }
    >
  >;
  sx?: SxProps<Theme>;
}

export default function GeneralAddressInput({
  value,
  action,
  onChange,
  inputsProps,
  sx,
  errors,
  touched,
}: Props) {
  const generalError = _.isString(errors) ? errors?.toString() : undefined;
  const typedErrors = _.isObject(errors) ? errors : undefined;

  return (
    <Stack direction='column' spacing={1} sx={sx}>
      <Card variant='outlined'>
        <CardContent>
          <Stack direction='row' spacing={1}>
            <Stack direction='column' spacing={2} sx={{ flex: 1 }}>
              <Box>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.country)}
                      helperText={typedErrors?.country}
                      required
                      fullWidth
                      label='Country'
                      margin='none'
                      type='text'
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            country: e.target.value,
                          });
                      }}
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      value={value?.country || ""}
                      variant='outlined'
                      {...inputsProps?.country}
                    />
                  </Grid>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.state)}
                      helperText={typedErrors?.state}
                      fullWidth
                      label='State'
                      margin='none'
                      type='text'
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            state: e.target.value,
                          });
                      }}
                      value={value?.state || ""}
                      variant='outlined'
                      {...inputsProps?.state}
                    />
                  </Grid>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.city)}
                      helperText={typedErrors?.city}
                      required
                      fullWidth
                      label='City'
                      margin='none'
                      type='text'
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            city: e.target.value,
                          });
                      }}
                      value={value?.city || ""}
                      variant='outlined'
                      {...inputsProps?.city}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.line1)}
                      helperText={typedErrors?.line1}
                      required
                      fullWidth
                      label='Line1'
                      margin='none'
                      type='text'
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            line1: e.target.value,
                          });
                      }}
                      value={value?.line1 || ""}
                      variant='outlined'
                      {...inputsProps?.line1}
                    />
                  </Grid>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.line2)}
                      helperText={typedErrors?.line2}
                      fullWidth
                      label='Line2'
                      margin='none'
                      type='text'
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            line2: e.target.value,
                          });
                      }}
                      value={value?.line2 || ""}
                      variant='outlined'
                      {...inputsProps?.line2}
                    />
                  </Grid>
                  <Grid item xxs={12} md={4}>
                    <TextField
                      error={Boolean(typedErrors?.postalCode)}
                      helperText={typedErrors?.postalCode}
                      required
                      fullWidth
                      label='Postal code'
                      margin='none'
                      type='text'
                      size={inputsProps?.all?.size || "small"}
                      onBlur={inputsProps?.all?.onBlur}
                      onChange={(e) => {
                        onChange &&
                          onChange({
                            ...value,
                            postalCode: e.target.value,
                          });
                      }}
                      value={value?.postalCode || ""}
                      variant='outlined'
                      {...inputsProps?.postalCode}
                    />
                  </Grid>
                </Grid>
              </Box>

              {generalError && <FormHelperText error>{generalError}</FormHelperText>}
            </Stack>
            <Stack direction='column' sx={{ justifyContent: "flex-start" }}>
              {action && action}
            </Stack>
          </Stack>
          <Button onClick={() => onChange && onChange(undefined)} sx={{ mt: 0.5 }}>
            Clear address
          </Button>
        </CardContent>
      </Card>
    </Stack>
  );
}
