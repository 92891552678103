import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionPlanDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: AssetSubscriptionPlanDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function AssetSubscriptionPlanDeleteModal({
  entity,
  onDelete,
  ...otherProps
}: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.AssetSubscriptionPlan}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) => {
        apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            assetSubscriptionPlanId: params.entityId,
            isDeleteCascade: params.isDeleteCascade,
          },
        );
      }}
      {...otherProps}
    />
  );
}
