import { Box, Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useState } from "react";

import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionDto, EntityType } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: DamageDetectionDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function DamageDetectionDeleteModal({ entity, ...otherProps }: Props) {
  const [isDeleteVehicleDamages, setIsDeleteVehicleDamages] = useState(false);

  return (
    <BaseEntityDeleteModal
      entityType={EntityType.DamageDetection}
      entity={entity}
      entityId={entity.id}
      bodyAfter={
        <Box>
          <FormControl fullWidth margin='dense'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDeleteVehicleDamages}
                  onChange={(e) => setIsDeleteVehicleDamages(e.target.checked)}
                />
              }
              label={"Delete related damages in vehicle history"}
            />
          </FormControl>
        </Box>
      }
      deleteFunc={(params) => {
        apiClient.damageDetectionsApi.apiV1DamageDetectionsDamageDetectionIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          damageDetectionId: params.entityId,
          isDeleteCascade: params.isDeleteCascade,
          damageDetectionDeleteDto: {
            isDeleteVehicleDamages: isDeleteVehicleDamages,
          },
        });
      }}
      {...otherProps}
    />
  );
}
