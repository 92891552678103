import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, SupplierDto, SupplierReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import SupplierInline, { SupplierInlineProps } from "./SupplierInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: SupplierDto | SupplierReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<SupplierInlineProps>;
}

export default function SupplierLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.SupplierRead])}
      to={ROUTE_PATH.SUPPLIER_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <SupplierInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Supplier"}
    />
  );
}
