import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function PersonIdIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_281_9792)'>
        <path d='M19 3H14.95V2C14.95 1.73478 14.8446 1.48043 14.6571 1.29289C14.4696 1.10536 14.2152 1 13.95 1H9.95C9.68478 1 9.43043 1.10536 9.24289 1.29289C9.05536 1.48043 8.95 1.73478 8.95 2V3H5C4.73478 3 4.48043 3.10536 4.29289 3.29289C4.10536 3.48043 4 3.73478 4 4V22C4 22.2652 4.10536 22.5196 4.29289 22.7071C4.48043 22.8946 4.73478 23 5 23H19C19.2652 23 19.5196 22.8946 19.7071 22.7071C19.8946 22.5196 20 22.2652 20 22V4C20 3.73478 19.8946 3.48043 19.7071 3.29289C19.5196 3.10536 19.2652 3 19 3ZM18 21H6V19.6C7.82588 18.5517 9.89456 18 12 18C14.1054 18 16.1741 18.5517 18 19.6V21ZM18 17.35C16.1249 16.4605 14.0754 15.9991 12 15.9991C9.92459 15.9991 7.87515 16.4605 6 17.35V5H11V3H13V5H18V17.35Z' />
        <path d='M8 11C8 11.7911 8.2346 12.5645 8.67412 13.2223C9.11365 13.8801 9.73836 14.3928 10.4693 14.6955C11.2002 14.9983 12.0044 15.0775 12.7804 14.9231C13.5563 14.7688 14.269 14.3878 14.8284 13.8284C15.3878 13.269 15.7688 12.5563 15.9231 11.7804C16.0775 11.0044 15.9983 10.2002 15.6955 9.46927C15.3928 8.73836 14.8801 8.11365 14.2223 7.67412C13.5645 7.2346 12.7911 7 12 7C10.9391 7 9.92172 7.42143 9.17157 8.17157C8.42143 8.92172 8 9.93913 8 11ZM14 11C14 11.3956 13.8827 11.7822 13.6629 12.1111C13.4432 12.44 13.1308 12.6964 12.7654 12.8478C12.3999 12.9991 11.9978 13.0387 11.6098 12.9616C11.2219 12.8844 10.8655 12.6939 10.5858 12.4142C10.3061 12.1345 10.1156 11.7781 10.0384 11.3902C9.96126 11.0022 10.0009 10.6001 10.1522 10.2346C10.3036 9.86918 10.56 9.55682 10.8889 9.33706C11.2178 9.1173 11.6044 9 12 9C12.5304 9 13.0391 9.21071 13.4142 9.58579C13.7893 9.96086 14 10.4696 14 11Z' />
      </g>
      <defs>
        <clipPath id='clip0_281_9792'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
