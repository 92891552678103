import _ from "lodash";
import { useMemo } from "react";

import { authService } from "@/common/services/auth";
import { AppPermission, AuthorizationProfileDto } from "@/core/api/generated";

import { useAppSelector } from "../redux";
import { useAuthenticationInfo } from "./useAuthenticationInfo";

export interface AuthorizationInfo {
  isPermissionsReady: boolean;
  authorizationProfile?: AuthorizationProfileDto;
  userPermissions?: AppPermission[];
  userPermissionsMap: Record<AppPermission, boolean>;
  hasFleetAppAccess: boolean;
  hasFleetCustomerAppAccess: boolean;
  hasAdminAppAccess: boolean;
  hasPermissions: (permissions: AppPermission[]) => boolean;
  hasPermissionsAny: (permissions: AppPermission[]) => boolean;
  hasPermissionsAllOrAny: (params: {
    permissions: AppPermission[] | null | undefined;
    permissionsAny: AppPermission[] | null | undefined;
  }) => boolean;
}

/** Returns authorization info for currently authenticated user. */
export const useAuthorizationInfo = (): AuthorizationInfo => {
  const { isAuthenticated } = useAuthenticationInfo();
  const authorizationProfile = useAppSelector((x) => x.auth.authorizationProfile);

  const isPermissionsReady = authService.isPermissionsReady;
  const userPermissions = authService.userPermissions;

  const userPermissionsMap = useMemo(
    () =>
      _.chain(authService.userPermissions)
        .keyBy((x) => x)
        .mapValues((v, k) => true)
        .value() as Record<AppPermission, boolean>,
    [authService.userPermissions],
  );

  return {
    isPermissionsReady,
    authorizationProfile,
    userPermissions,
    userPermissionsMap: userPermissionsMap,
    hasFleetAppAccess: authService.userPermissions?.includes(AppPermission.FleetAppAccess) || false,
    hasFleetCustomerAppAccess:
      authService.userPermissions?.includes(AppPermission.FleetCustomerAppAccess) || false,
    hasAdminAppAccess: authService.userPermissions?.includes(AppPermission.AdminAppAccess) || false,
    hasPermissions: (permissions: AppPermission[]) => authService.userHasPermissions(permissions),
    hasPermissionsAny: (permissions: AppPermission[]) =>
      authService.userHasPermissionsAny(permissions),
    hasPermissionsAllOrAny: (params: {
      permissions: AppPermission[] | null | undefined;
      permissionsAny: AppPermission[] | null | undefined;
    }) => {
      if (!isPermissionsReady || !userPermissions) {
        return false;
      }
      if (isAuthenticated === undefined || isAuthenticated === false) {
        return false;
      }

      const hasPermissionsRequired =
        _.isEmpty(params.permissions) ||
        (params.permissions || []).every((x) => userPermissions.includes(x));
      const hasPermissionsAny =
        _.isEmpty(params.permissionsAny) ||
        (params.permissionsAny || []).some((x) => userPermissions.includes(x));
      const hasPermissions = hasPermissionsRequired && hasPermissionsAny;
      return hasPermissions;
    },
  };
};
