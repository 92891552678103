import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useHistory } from "react-router";

import AppTooltip from "@/common/components/AppTooltip";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AssetSubscriptionDto } from "@/core/api/generated";

interface Props {
  item: AssetSubscriptionDto;
}

export default function AssetSubscriptionCard({ item, ...other }: Props) {
  const history = useHistory();

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: item.contract?.vehicleId || "",
    },
    {
      deps: [item],
    },
  );
  const vehicle = vehicleRequest.data;

  const handleCardClick = useCallback(
    (assetSubscriptionId: string) => {
      history.push(ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW(assetSubscriptionId));
    },
    [history],
  );

  return (
    <Stack
      spacing={0.5}
      onClick={() => handleCardClick(item.id!)}
      sx={{ cursor: "pointer" }}
      {...other}
    >
      <Stack direction='row' sx={{ justifyContent: "flex-end" }}>
        <Typography color='primary'>
          {vehicle?.spec?.make?.name} {vehicle?.spec?.model?.name}
        </Typography>
      </Stack>

      <AppTooltip title='Customer'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='personOutline' inText />}
          ellipsing={{ enabled: true }}
        >
          {item.customer?.name}
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Contract'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='document' inText />}
          ellipsing={{ enabled: true }}
        >
          {item.externalNumber || item.contract?.externalNumber || item.contract?.localNumber}
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Check-out date'>
        <FieldValue size='compact' labelIcon={<AppIcon of='calendar' inText />}>
          {item.checkOutSpotInfo?.date && (
            <Datetime sx={{ fontWeight: 500 }} datetime={item.checkOutSpotInfo?.date} />
          )}
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Check-out address'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='pinLocation' inText />}
          labelAlight={{
            vertical: "start",
          }}
        >
          {item.checkOutSpotInfo?.spot?.address && (
            <GeneralAddressDisplay address={item.checkOutSpotInfo?.spot?.address} />
          )}
        </FieldValue>
      </AppTooltip>
    </Stack>
  );
}
