import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import AccessoryCreateUpdate, { AccessoryCreateUpdateProps } from "./AccessoryCreateUpdate";

export interface OwnProps {
  createUpdateProps?: AccessoryCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function AccessoryCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps?.entityId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} accessory
      </AppModalTitle>
      <DialogContent>
        <AccessoryCreateUpdate {...createUpdateProps} />
      </DialogContent>
    </Dialog>
  );
}
