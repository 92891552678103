import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import RepairSparePartView from "@/common/components/Entity/RepairSparePart/View/RepairSparePartView";
import { apiClient } from "@/core/api/ApiClient";

function RepairSparePartViewPage() {
  const { repairSparePartId } = useParams<{ repairSparePartId?: string }>();

  return (
    <Stack direction='column'>
      <RepairSparePartView
        repairSparePartId={repairSparePartId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        getFunc={async (params) =>
          await apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdGet({
            repairSparePartId: params.repairSparePartId,
          })
        }
        deleteFunc={async (params) =>
          await apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdDelete(
            {
              repairSparePartId: params.repairSparePartId,
            },
          )
        }
      />
    </Stack>
  );
}

export default RepairSparePartViewPage;
