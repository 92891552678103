import { LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, IBaseEntityDto, PoolItemEntityType } from "@/core/api/generated";

import NoDataAlert from "../AppAlerts/NoDataAlert";
import FoldableBlock from "../Display/FoldableBlock";
import GeneralStrictEntityRelationInline from "../Entity/General/Display/GeneralStrictEntityRelationInline";
import PoolLink from "../Entity/Pool/PoolLink";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";

export interface EntityPoolsInfoFoldableBlockOwnProps {
  entityType: PoolItemEntityType;
  entity: IBaseEntityDto;
}

export type EntityPoolsInfoFoldableBlockProps = EntityPoolsInfoFoldableBlockOwnProps;

export default function EntityPoolsInfoFoldableBlock({
  entityType,
  entity,
}: EntityPoolsInfoFoldableBlockProps) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);

  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsAssetIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetId: entityMeta?.assetMeta?.assetId || "",
    },
    {
      deps: [entityMeta?.assetMeta?.assetId],
      skip: !entityMeta?.assetMeta?.assetId,
    },
  );
  const asset = assetRequest?.data;

  const assetPoolsPaginatedRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: EntityType.Asset,
      entityId: asset?.id || "",
    },
    {
      deps: [asset?.id],
      skip: !asset?.id,
    },
  );
  const assetPoolsPaginated = assetPoolsPaginatedRequest?.data;

  const isLoading = assetRequest?.isLoading && !assetRequest.isEnded;

  const entityHaveSomePools = useMemo(
    () => !_.isEmpty(entityMeta?.poolsMeta?.poolIds) || !_.isEmpty(assetPoolsPaginated?.items),
    [entityMeta, assetPoolsPaginated],
  );
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack sx={{ width: "fit-content" }}>
          <FoldableBlock
            trigger={{
              size: "small",
              label: (
                <Typography variant='subtitle1'>
                  <Stack spacing={1} direction='row'>
                    <GeneralStrictEntityRelationInline
                      value={{
                        entityType: entityType,
                        entityId: entity.id,
                      }}
                      inlineProps={{
                        withIcon: true,
                      }}
                    />
                  </Stack>
                </Typography>
              ),
            }}
          >
            {!entityHaveSomePools && <NoDataAlert variant='inline' title='No pools' />}

            {entityHaveSomePools && (
              <>
                {/** {entityType} pools */}
                {!_.isEmpty(entityMeta?.poolsMeta?.poolIds) && (
                  <>
                    <Typography variant='body1'>
                      <InlineApiEnumValue type='EntityType' value={entityType} /> pools:
                    </Typography>
                    <Stack direction='column'>
                      {entityMeta?.poolsMeta?.poolIds?.map((id, i) => (
                        <PoolLink key={i} entity={undefined} entityId={id} />
                      ))}
                    </Stack>
                  </>
                )}
                {/** asset pools */}
                {!_.isEmpty(assetPoolsPaginated?.items) && (
                  <Stack>
                    <Typography variant='body1'>Asset pools:</Typography>
                    {assetPoolsPaginatedRequest.isLoading && <LinearProgress />}
                    <Stack direction='column'>
                      {assetPoolsPaginated?.items?.map((pool, i) => (
                        <PoolLink key={i} entity={pool} />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </FoldableBlock>
        </Stack>
      </Stack>

      {isLoading && <LinearProgress />}
    </Stack>
  );
}
