import _ from "lodash";

import { TypeHelper } from "../helpers/type";
import { SortFieldSpec } from "./sortFieldSpec";

/** Spec of sortable fields. */
export class SortSpec {
  public fields: SortFieldSpec[];
  private fieldsMap: Record<string, SortFieldSpec>;

  constructor(params: { fields: SortFieldSpec[] }) {
    this.fields = params.fields;
    this.fieldsMap = this.computeFieldsMap();
    this.cleanup();
  }

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.fields) && this.fields.every((x) => x.isValid);
  }

  public hasField(field: string): boolean {
    return !!this.fieldsMap[field];
  }

  public getField(field: string): SortFieldSpec | undefined {
    return this.fieldsMap[field];
  }

  public getFieldOrThrow(field: string): SortFieldSpec {
    const result = this.getField(field);
    if (!result) {
      throw new Error(`Unable to get field spec for '${field}'.`);
    }
    return result;
  }

  public getFirstField(): SortFieldSpec | undefined {
    return this.fields.at(0);
  }

  public getFirstFieldOrThrow(): SortFieldSpec {
    const result = this.getFirstField();
    if (!result) {
      throw new Error(`Unable to get first field spec.`);
    }
    return result;
  }

  /** Removes empty field specs to make sure the spec is relevant and valid. */
  public cleanup(): void {
    const invalidFields = this.fields.filter((x) => !x.isValid);
    if (!TypeHelper.isEmpty(invalidFields)) {
      console.warn(
        `Found ${invalidFields.length} invalid field spec during cleanup. Invalid fields:`,
        invalidFields,
      );
    }

    this.fields = this.fields.filter((x) => x.isValid);
  }

  private computeFieldsMap(): Record<string, SortFieldSpec> {
    this.fieldsMap = _.chain(this.fields)
      .keyBy((x) => x.field)
      .mapValues((x) => x)
      .value();
    return this.fieldsMap;
  }
}
