import { Box, SxProps, Theme } from "@mui/material";
import { forwardRef } from "react";

import { StringHelper } from "@/common/helpers/string";
import {
  GeneralNationalIdentityNumberDto,
  GeneralNationalIdentityNumberInputDto,
} from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import AppIcon from "../../../Icons/AppIcon";
import CountryFlagIcon from "../../../Icons/CountryFlagIcon";

interface Props {
  value?: GeneralNationalIdentityNumberInputDto | GeneralNationalIdentityNumberDto | null;
  withIcon?: boolean;
  withCountryCode?: boolean;
  withCountryIcon?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
  sx?: SxProps<Theme>;
}

export default forwardRef<HTMLSpanElement, Props>(function GeneralNationalIdentityNumberDisplay(
  {
    value,
    withIcon = false,
    withCountryCode = true,
    withCountryIcon = false,
    withTooltip = false,
    tooltipText = `National identity number (${StringHelper.joinIntoString(
      [(value as GeneralNationalIdentityNumberDto)?.country?.name, value?.countryCode],
      " ",
      { skipEmpty: true },
    )})`,
    sx,
  }: Props,
  ref,
) {
  if (!value) {
    return null;
  }

  return (
    <AppTooltip
      enabled={withTooltip && !!tooltipText}
      title={tooltipText || ""}
      wrapperProps={{
        component: "span",
      }}
    >
      <Box ref={ref} component='span' sx={sx}>
        {withIcon && <AppIcon of='personId' inText />}
        {value.number}
        {(withCountryCode || withCountryIcon) && (
          <>
            {" "}
            (
            {withCountryIcon && (
              <CountryFlagIcon countryCode={value.countryCode} sx={{ width: 24 }} />
            )}
            {withCountryIcon && withCountryCode && " "}
            {withCountryCode && `${value.countryCode}`})
          </>
        )}
      </Box>
    </AppTooltip>
  );
});
