import { Box, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { useApiConnectionStatus } from "@/common/hooks/useApiConnectionStatus";
import { tenantService } from "@/common/services/tenant";

export default function ApiConnectionErrorPage() {
  const history = useHistory();
  const apiStatus = useApiConnectionStatus({ withRecurringCheck: true });

  // redirect to root if connection is present
  useEffect(() => {
    if (apiStatus.isJustConnected) {
      tenantService.redirectToTenantRootUrl(); // redirect with full app reload
    } else if (apiStatus.isConnected) {
      history.push(ROUTE_PATH.ROOT);
    }
  }, [apiStatus]);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        Network error
      </Typography>
      <Box>Unable to connect to API servers. The problem is on our side.</Box>
      <Box>Connection status: {apiStatus.status}</Box>
      <Box>
        <Link component={RouterLink} to={"/"}>
          Go home
        </Link>
      </Box>
    </Box>
  );
}
