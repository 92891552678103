import { apiClient } from "@/core/api/ApiClient";
import { VehicleSpecDto, VehicleSpecInputDto, VehicleVisualModelDto } from "@/core/api/generated";

export class VehicleVisualModelHelper {
  /** Returns the most suitable or default VehicleVisualModel for provided VehicleSpec. */
  public static async getSuggestedModel(
    spec: VehicleSpecDto | VehicleSpecInputDto | null | undefined,
  ): Promise<VehicleVisualModelDto | null> {
    const spec2 = spec as VehicleSpecDto | undefined;
    const specInput = spec as VehicleSpecInputDto | undefined;

    const response =
      await apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet(
        {
          vehicleType: spec?.type || undefined,
          bodyType: spec?.bodyType || undefined,
          makeId: spec2?.make?.id || specInput?.makeId || undefined,
          modelId: spec2?.model?.id || specInput?.modelId || undefined,
          generationId: spec2?.generation?.id || specInput?.generationId || undefined,
          modificationId: spec2?.modification?.id || specInput?.modificationId || undefined,
        },
      );
    return response.data;
  }
}
