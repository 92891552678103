import { ActionCreatorWithPayload, PayloadAction } from "@reduxjs/toolkit";

/** Infers PayloadAction from ActionCreatorWithPayload */
export type InferredPayloadAction<
  TActionCreator extends ActionCreatorWithPayload<TPayload, string>,
  TPayload = any,
> = PayloadAction<ReturnType<TActionCreator>["payload"], string>;

/** Infers payload from ActionCreatorWithPayload */
export type InferredPayload<
  TActionCreator extends ActionCreatorWithPayload<TPayload, string>,
  TPayload = any,
> = ReturnType<TActionCreator>["payload"];

/** Returns typed action by inferring type from action creator. */
export function GetTypedPayloadAction<TPayload>(
  action: any,
  actionCreator: ActionCreatorWithPayload<TPayload, string>,
): PayloadAction<ReturnType<typeof actionCreator>["payload"], string> {
  type ActionType = ReturnType<typeof actionCreator>;
  type PayloadType = ActionType["payload"];

  return action as PayloadAction<PayloadType, string>;
}

/** Returns typed action payload by inferring type from action creator. */
export function GetTypedPayload<TPayload>(
  action: any,
  actionCreator: ActionCreatorWithPayload<TPayload, string>,
): ReturnType<typeof actionCreator>["payload"] {
  type ActionType = ReturnType<typeof actionCreator>;
  type PayloadType = ActionType["payload"];

  return action?.payload as PayloadType;
}
