import {
  Badge,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  Menu,
  MenuItem,
  Popover,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import TenantSelector from "@/App/MainAppView/components/Tenant/TenantSelector";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { notificationService } from "@/common/services/notification";
import { apiClient } from "@/core/api/ApiClient";
import { NotificationDto, NotificationImportance } from "@/core/api/generated";

import NotificationListItem from "./NotificationListItem";

const maxVisibleNotifications = 500;
const defaultLimit = 10;

/** Notifications bell with popup containing notification list */
export default function LastNotifications() {
  const currentTenant = useCurrentTenant();

  const [tenantId, setTenantId] = useState<string | undefined>(undefined);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [importance, setImportance] = useState<NotificationImportance | "all">("all");
  const [isRead, setIsRead] = useState<boolean | undefined>(undefined);

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [isSpecificTenant, setIsSpecificTenant] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [headMenuAnchorEl, setHeadMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const isHeadMenuOpen = Boolean(headMenuAnchorEl);

  const tenantIdComputed = tenantId || currentTenant?.id;

  const notificationCountersRequest = useApiRequest(
    apiClient.notificationsApi.apiV1NotificationsCountersGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantId: tenantIdComputed || undefined,
      importance: importance === "all" ? undefined : importance,
    },
    {
      deps: [tenantIdComputed, importance],
    },
  );
  const notificationCounters = notificationCountersRequest.data;

  const paginatedNotificationsRequest = useApiRequest(
    apiClient.notificationsApi.apiV1NotificationsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      offset,
      limit,
      tenantId: tenantIdComputed || undefined,
      importance: importance === "all" ? undefined : importance,
      isRead,
    },
    {
      deps: [offset, limit, tenantIdComputed, importance, isRead],
    },
  );
  const paginatedNotifications = paginatedNotificationsRequest.data;

  const isAnyNotifications = paginatedNotifications && paginatedNotifications.items!.length !== 0;
  const canLoadMore =
    (paginatedNotifications?.items?.length || -1) < maxVisibleNotifications &&
    (paginatedNotifications?.items?.length || -1) <
      (paginatedNotifications?.pagination?.totalCount || 0);

  useEffect(() => {
    const sub = notificationService.on2("inAppNotificationReceived", (data) => {
      notificationCountersRequest.refetch();
      paginatedNotificationsRequest.refetch();
    });
    return () => {
      sub.off();
    };
  }, []);

  const close = () => {
    setAnchorEl(null);
  };

  const closeHeadMenu = () => {
    setHeadMenuAnchorEl(null);
  };

  const markAllAsRead = async () => {
    await apiClient.notificationsApi.apiV1NotificationsAllMarkAsReadPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
    notificationCountersRequest.refetch();
    paginatedNotificationsRequest.refetch();
    closeHeadMenu();
  };

  const clearAll = async () => {
    await apiClient.notificationsApi.apiV1NotificationsAllDelete({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
    notificationCountersRequest.refetch();
    paginatedNotificationsRequest.replaceData(undefined);
    closeHeadMenu();
  };

  const refresh = async () => {
    notificationCountersRequest.refetch();
    paginatedNotificationsRequest.refetch();
    closeHeadMenu();
  };

  const loadMore = () => {
    if (canLoadMore) {
      setLimit(limit + defaultLimit);
    }
  };

  const markNotificationAsRead = async (notification: NotificationDto) => {
    await apiClient.notificationsApi.apiV1NotificationsNotificationIdMarkAsReadPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      notificationId: notification.id || "",
    });
    notificationCountersRequest.refetch();
    paginatedNotificationsRequest.refetch();
  };

  const deleteNotification = async (notification: NotificationDto) => {
    await apiClient.notificationsApi.apiV1NotificationsNotificationIdDelete({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      notificationId: notification.id || "",
    });
    notificationCountersRequest.refetch();
    paginatedNotificationsRequest.refetch();
  };

  return (
    <Box>
      {/* Bell */}
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Badge
          badgeContent={
            paginatedNotificationsRequest.isLoading
              ? `...`
              : notificationCounters?.unreadCount?.toString()
          }
          color='primary'
          overlap='rectangular'
          invisible={!!notificationCounters && notificationCounters.unreadCount === 0}
        >
          <AppIcon of='notifications' />
        </Badge>
      </IconButton>

      {/* Popup */}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ minWidth: "400px", maxWidth: { sm: "100vw", md: "400px" } }}>
          <Box sx={{ display: "flex", alignItems: "center", py: 1, px: 2 }}>
            <Typography component='div' sx={{ fontWeight: "bold" }}>
              Notifications
            </Typography>

            <Box sx={{ ml: "auto" }}>
              <Button
                variant='text'
                size='small'
                sx={{ mr: 1 }}
                onClick={() => setIsFiltersVisible(!isFiltersVisible)}
              >
                Filters
              </Button>

              <IconButton edge='end' onClick={(e) => setHeadMenuAnchorEl(e.currentTarget)}>
                <AppIcon of='moreVert' />
              </IconButton>
            </Box>

            <Menu
              anchorEl={headMenuAnchorEl}
              open={isHeadMenuOpen}
              onClose={closeHeadMenu}
              MenuListProps={{
                "aria-labelledby": "menu-button",
              }}
            >
              <MenuItem dense disabled={!isAnyNotifications} onClick={markAllAsRead}>
                Mark all as read
              </MenuItem>
              <MenuItem dense disabled={!isAnyNotifications} onClick={clearAll}>
                Clear all
              </MenuItem>
              <MenuItem dense onClick={refresh}>
                Refresh
              </MenuItem>
            </Menu>
          </Box>
          <Divider sx={{ m: 0 }} />
          <Box>
            {/* Filters */}
            {isFiltersVisible && (
              <Box sx={{ px: 3, py: 2 }}>
                <Stack direction={{ xxs: "column", md: "row" }} spacing={1} sx={{ mb: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size='small'
                          checked={isSpecificTenant}
                          onChange={(e) => {
                            const newValue = !isSpecificTenant;
                            if (!newValue) {
                              setTenantId(undefined);
                            }
                            setIsSpecificTenant(newValue);
                          }}
                        />
                      }
                      label={!isSpecificTenant ? `All companies` : `Specific company`}
                    />
                  </FormGroup>

                  {isSpecificTenant && (
                    <Box sx={{ flex: 1 }}>
                      <TenantSelector
                        tenantId={tenantId || ""}
                        onChange={(newValue) => {
                          setTenantId(newValue?.id || undefined);
                        }}
                        selectProps={{
                          size: "small",
                        }}
                      />
                    </Box>
                  )}
                </Stack>

                <Stack direction={{ xxs: "column", md: "row" }} spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size='small'
                          checked={isRead === false}
                          onChange={(e) => setIsRead(isRead === undefined ? false : undefined)}
                        />
                      }
                      label={isRead === undefined ? `Read & unread` : `Unread only`}
                    />
                  </FormGroup>

                  <Box sx={{ flex: 1 }}>
                    <FormControl sx={{ width: "100%" }} size='small'>
                      <InputLabel>Importance</InputLabel>
                      <Select
                        value={importance}
                        label='Importance'
                        onChange={(e) =>
                          setImportance(e.target.value as NotificationImportance | "all")
                        }
                      >
                        <MenuItem value='all'>All</MenuItem>
                        {Object.keys(NotificationImportance)
                          .map((x) => x as keyof typeof NotificationImportance)
                          .filter((x) => x !== "None")
                          .map((key) => (
                            <MenuItem key={key} value={NotificationImportance[key]}>
                              {key}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Box>
            )}

            <List dense sx={{ p: 0 }}>
              {paginatedNotifications?.items?.map((notification) => (
                <NotificationListItem
                  key={notification.id!}
                  notification={notification}
                  onMarkNotificationAsRead={(notification2) =>
                    markNotificationAsRead(notification2)
                  }
                  onDeleteNotification={(notification2) => deleteNotification(notification2)}
                  onActionExecuted={() => {
                    close();
                  }}
                />
              ))}
              {!isAnyNotifications && (
                <Stack
                  direction='column'
                  color='text.secondary'
                  sx={{ alignItems: "center", p: 4 }}
                >
                  <AppIcon of='notificationsActive' />
                  <Typography component='div'>No notifications</Typography>
                </Stack>
              )}
            </List>

            {paginatedNotificationsRequest.isLoading && (
              <Box sx={{ my: 1, px: 1 }}>
                <LinearProgress />
              </Box>
            )}

            <Divider sx={{ m: 0 }} />
            <Box sx={{ p: 1 }}>
              <Stack direction='row' spacing={1}>
                <Button
                  variant='text'
                  fullWidth
                  disabled={!isAnyNotifications || !canLoadMore}
                  onClick={loadMore}
                >
                  Load more
                </Button>
                <Button variant='text' fullWidth onClick={close}>
                  Close
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
