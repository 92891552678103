import _ from "lodash";

import { ChatDto, ChatType, GeneralScopeDto } from "@/core/api/generated";
import { OpenChatPlacement } from "@/store/communication/chatsSlice";

import ChatActivator from "./ChatActivator";

interface Props {
  chatScope?: GeneralScopeDto;
  chatPlacement?: OpenChatPlacement;
  withActivityIndicator?: boolean;
}

/** Displays button/icon that opens negotiation chat. */
function NegotiationChatActivator({ chatScope, chatPlacement, withActivityIndicator }: Props) {
  return (
    <ChatActivator
      chatType={ChatType.Negotiation}
      chatScope={chatScope}
      chatPlacement={chatPlacement}
      withActivityIndicator={withActivityIndicator}
    />
  );
}

export default NegotiationChatActivator;
