import _ from "lodash";
import { useMemo } from "react";

import { CurrencyCode, GeneralCurrencyDto } from "@/core/api/generated";

import { useTenantProfile } from "./entity/tenant/useTenantProfile";

/** Returns current currency from tenant or user profile, or default currency. */
export const useCurrentCurrency = ({
  useDefault = true,
}: {
  useDefault?: boolean;
} = {}): GeneralCurrencyDto | undefined => {
  const tenantProfile = useTenantProfile();

  const defaultCurrency = useMemo<GeneralCurrencyDto>(
    () => ({
      code: CurrencyCode.Usd,
      name: CurrencyCode.Usd.toUpperCase(),
    }),
    [],
  );

  if (!tenantProfile) {
    return undefined;
  }
  return _.isNil(tenantProfile) && !useDefault
    ? undefined
    : tenantProfile?.settings?.currency || defaultCurrency;
};
