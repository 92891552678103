import { Card, CardContent, Stack, SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";
import { Moment } from "moment";

import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { EntityChangedByInfoFromDto } from "@/common/ts/dto";
import { GeneralByWhoDto } from "@/core/api/generated";
import { ResponsiveStyleValueCustom } from "@/theme/types";

import Datetime from "../Datetime/Datetime";

export type Entity = EntityChangedByInfoFromDto & Record<string, any>;

export interface EntityChangedByInfoCardProps {
  dateFormat?: string | null | undefined;
  datetime: string | Moment | null | undefined;
  userId: string | null | undefined;
  who?: GeneralByWhoDto | null;
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  sx?: SxProps<Theme>;
  type?: "create" | "update" | undefined;
}

/** Shows who changed the entity */
export default function EntityChangedByInfoCard({
  type,
  dateFormat = DATETIME_FORMATS.DISPLAY_DATETIME,
  datetime,
  userId,
  who,
  sx,
}: EntityChangedByInfoCardProps) {
  return (
    <Card
      sx={{
        borderRadius: (theme) => theme.shapeCustom.borderRadiusCard,
        ...sx,
      }}
    >
      <CardContent sx={{ p: "8px !important", display: "flex", flexDirection: "column" }}>
        <Stack direction='row' spacing={2}>
          <Stack direction='column' justifyContent='center'>
            {type && (
              <Typography sx={{ fontWeight: 700, fontSize: (t) => t.typography.pxToRem(12) }}>
                {(type === "create" ? "Created" : "Updated").toUpperCase()}
              </Typography>
            )}
            <Datetime datetime={datetime} format={dateFormat} withDurationFromNow />
          </Stack>

          <InlineUser
            userId={userId}
            withAvatar
            withText={false}
            avatarProps={{
              sx: { width: 40, height: 40, borderRadius: (t) => t.shapeCustom.borderRadiusAvatar },
            }}
            withLink
            withPoper
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
