import { AutomationCreateMode, GeneralHistoryDto } from "@/core/api/generated";

export class GeneralHistoryHelper {
  public static canUpdate(entity: GeneralHistoryDto | null | undefined): boolean {
    return this.isCreatedManually(entity);
  }

  public static canDelete(entity: GeneralHistoryDto | null | undefined): boolean {
    return this.isCreatedManually(entity);
  }

  public static isCreatedManually(entity: GeneralHistoryDto | null | undefined): boolean {
    return entity?.autoCreateMode === AutomationCreateMode.Manual;
  }
}
