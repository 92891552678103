import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Fade,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";

import AppIconButton from "../Button/AppIconButton";
import AppIcon from "../Icons/AppIcon";
import CardActionAreaFacade from "../Mui/Facades/CardActionAreaFacade";

export interface EntityDataBlockProps {
  title?: string | (JSX.Element & ReactNode) | null;
  description?: string | (JSX.Element & ReactNode) | null;
  to?: string | null;
  /** Enable brief/detailed view toggle. */
  withDetailsToggle?: boolean;
  isDetailsVisible?: boolean;
  sx?: SxProps<Theme>;
  /** Content to show in folded state */
  briefContent?: JSX.Element & ReactNode;
  /** Content to show in unfolded state */
  detailedContent?: JSX.Element & ReactNode;
  /** The same as briefContent. */
  children?: JSX.Element & ReactNode;
  onDetailsVisibilityChange?: (isDetailsVisible: boolean) => void;
}

/** Block of entity data grouped together. */
export default function EntityDataBlock({
  title,
  description,
  to,
  withDetailsToggle,
  isDetailsVisible,
  sx,
  briefContent,
  detailedContent,
  children,
  onDetailsVisibilityChange,
}: EntityDataBlockProps) {
  const [_isDetailsVisible, setIsDetailsVisible] = useState<boolean | undefined>(undefined);
  // used to save the size of the content when it is folded
  const [height, setHeight] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDetailsVisible !== undefined) {
      setIsDetailsVisible(isDetailsVisible);
    }
  }, [isDetailsVisible]);

  const handleDetailsToggle = useCallback(() => {
    if (!_isDetailsVisible && containerRef.current) {
      setHeight(containerRef.current.offsetHeight);
    }
    const newValue = !_isDetailsVisible;
    setIsDetailsVisible(newValue);
    onDetailsVisibilityChange && onDetailsVisibilityChange(newValue);
  }, [containerRef, _isDetailsVisible, onDetailsVisibilityChange]);

  const contentPadding = 2;

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          ...sx,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (_isDetailsVisible) {
              setIsDetailsVisible(false);
              onDetailsVisibilityChange && onDetailsVisibilityChange(false);
            }
          }}
        >
          <ViewContentBlock
            variant='one-sided'
            sx={{
              p: 0,
              width: "100%",
              flex: 1,
              boxShadow: (th) => (_isDetailsVisible ? th.shadows[5] : "none"),
              borderColor: (theme) =>
                _isDetailsVisible ? theme.palette.secondary.main : theme.palette.divider,
              borderRadius: (theme) => theme.shapeCustom.borderRadiusCard,
              position: _isDetailsVisible ? "absolute" : "relative",
              zIndex: (th) => (_isDetailsVisible ? th.zIndex.drawer : "auto"),
              overflow: "hidden",
              ...sx,
            }}
          >
            <CardContent sx={{ py: 2, px: contentPadding }}>
              {/* Header */}
              <Stack
                sx={{ position: "relative" }}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Stack>
                  <Typography
                    component='div'
                    color={"text"}
                    variant='caption'
                    sx={{ fontWeight: 500 }}
                  >
                    <CardActionAreaFacade
                      enabled={!!to}
                      to={to || undefined}
                      sx={{ borderRadius: 0 }}
                    >
                      {title}
                    </CardActionAreaFacade>
                  </Typography>
                  <Typography
                    component='div'
                    color={_isDetailsVisible ? "primary" : "secondary"}
                    variant='caption'
                  >
                    {description}
                  </Typography>
                </Stack>

                <AppIconButton
                  size='large'
                  shape='square'
                  variant={_isDetailsVisible ? "contained" : "default"}
                  color='default'
                  onClick={handleDetailsToggle}
                  sx={{
                    color: (t) =>
                      _isDetailsVisible ? t.palette.secondary.main : t.palette.text.secondary,
                    height: 40,
                    width: 40,
                    "&:hover": {
                      boxShadow: "none",
                    },
                    position: "absolute",
                    right: -10,
                    top: -10,
                    py: 0.5,
                  }}
                >
                  {!_isDetailsVisible && <AppIcon of='expandMore' fontSize='small' />}
                  {_isDetailsVisible && <AppIcon of='expandLess' fontSize='small' />}
                </AppIconButton>
              </Stack>
            </CardContent>

            {/* Brief view */}
            {!_isDetailsVisible && (
              <Fade in={!_isDetailsVisible}>
                <CardContent sx={{ p: contentPadding, pt: 0, borderRadius: 0 }}>
                  {briefContent || children || null}
                </CardContent>
              </Fade>
            )}

            {/* Detailed view */}
            {_isDetailsVisible && (
              <Fade in={_isDetailsVisible} unmountOnExit>
                <CardContent sx={{ p: contentPadding, pt: 0 }}>
                  {detailedContent || null}
                </CardContent>
              </Fade>
            )}

            <CardContent sx={{ display: "none" }} />
          </ViewContentBlock>
        </ClickAwayListener>
      </Box>

      {_isDetailsVisible && height !== null && (
        <Box
          margin={0}
          sx={{
            height: `${height}px`,
            mt: "0px !important",
            ml: "0px !important",
          }}
        />
      )}
    </>
  );
}
