import {
  FooterPropsOverrides,
  GridFooterContainer,
  GridFooterContainerProps,
  useGridApiContext,
  useGridRootProps,
} from "@mui/x-data-grid";
import { forwardRef, useMemo } from "react";

import CustomDataGridPagination, {
  CustomDataGridPaginationProps,
} from "./CustomDataGridPagination";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    isPaginationEnabled: boolean;
  }
}

type CustomDataGridFooterProps = GridFooterContainerProps & FooterPropsOverrides;

export default forwardRef<HTMLDivElement, CustomDataGridFooterProps>(function CustomDataGridFooter(
  { isPaginationEnabled, ...otherProps }: CustomDataGridFooterProps,
  ref,
) {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const enabledList = [isPaginationEnabled];
  const isAnyEnabled = useMemo(() => enabledList.some((x) => x === true), [...enabledList]);

  if (!isAnyEnabled) {
    return null;
  }

  return (
    <GridFooterContainer ref={ref} {...otherProps}>
      {isPaginationEnabled && (
        <CustomDataGridPagination
          {...(rootProps.slotProps?.pagination as CustomDataGridPaginationProps)}
        />
      )}
    </GridFooterContainer>
  );
});
