import { IconButton, TextField } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { FilterValueInputProps } from "@/common/ts/filters";
import { FilterValueType } from "@/core/api/generated";

import AppIcon from "../../Icons/AppIcon";

interface Props extends FilterValueInputProps {}

export default function FilterDateValueInput({ item, textFieldProps, onChange }: Props) {
  if (item.valueType !== FilterValueType.Date) {
    console.error(`Invalid value type '${item.valueType}'.`);
    return null;
  }

  return (
    <MobileDateTimePicker
      ampm={false}
      label='Value'
      value={item.valueAsDateAsMoment() || null}
      format={DATETIME_FORMATS.DISPLAY_DATETIME}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      slots={{
        textField: (params) => (
          <TextField
            {...textFieldProps}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(undefined);
                  }}
                >
                  <AppIcon of='clear' color='inherit' />
                </IconButton>
              ),
            }}
          />
        ),
      }}
    />
  );
}
