import { apiClient } from "@/core/api/ApiClient";
import { EntityType, RepairOperationDto } from "@/core/api/generated";

import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: RepairOperationDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function RepairOperationsDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.RepairOperation}
      entities={entities}
      deleteFunc={(params) => {
        apiClient.repairOperationsApi.apiV1RepairOperationsBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
          },
        });
      }}
      {...otherProps}
    />
  );
}
