import GeneralHistoryPaginatedList from "@/common/components/Entity/GeneralHistory/ListView/GeneralHistoryPaginatedList";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { EntityType } from "@/core/api/generated";

import { GeneralStatusHistoryListPageQueryParams } from "../GeneralStatusHistory/GeneralStatusHistoryListPage";

export interface GeneralHistoryListPageQueryParams extends GeneralQueryParams {
  subjectEntityType?: EntityType | null;
  subjectEntityId?: string | null;
}

export default function GeneralHistoryListPage() {
  const queryParams = useQueryParams<GeneralStatusHistoryListPageQueryParams>();

  return (
    <>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: queryParams.subjectEntityType || undefined,
          subjectEntityId: queryParams.subjectEntityId || undefined,
        }}
      />
    </>
  );
}
