import { Box, Skeleton, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useNegotiationsAllowedActionsMap } from "@/common/hooks/entity/negotiation/useNegotiationsAllowedActionsMap";
import { useCurrentUserPartiesMembership } from "@/common/hooks/entity/party/useCurrentUserPartiesMembership";
import { apiClient } from "@/core/api/ApiClient";
import { NegotiationDto, NegotiationReferenceDto } from "@/core/api/generated";

import AppLink from "../../../Link/AppLink";
import NegotiationProposalComputedStatusIcon from "./NegotiationProposalComputedStatusIcon";

interface OwnProps {
  negotiations?: NegotiationDto[];
  negotiationRefs?: NegotiationReferenceDto[];
  to?: string;
  sx?: SxProps<Theme>;
}

type Props = OwnProps;

export default function NegotiationProposalComputedStatusIconsStrip({
  negotiations,
  negotiationRefs,
  to,
  sx,
}: Props) {
  const negotiationIds = useMemo(
    () => (negotiations || negotiationRefs || []).map((x) => x.id!),
    [negotiations, negotiationRefs],
  );

  const partiesMembership = useCurrentUserPartiesMembership();
  const allowedActionsMap = useNegotiationsAllowedActionsMap(negotiationIds);

  const negotiationsRequest = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsByIdsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      ids: negotiationIds,
    },
    {
      deps: [negotiationRefs],
      skip: !negotiationRefs,
    },
  );

  const isLoading = !partiesMembership || !allowedActionsMap || negotiationsRequest.isLoading;

  const _negotiations = useMemo(
    () => negotiations || negotiationsRequest.data || [],
    [negotiations, negotiationsRequest.data],
  );

  return (
    <Box sx={sx}>
      {isLoading && <Skeleton variant='text' sx={{ fontSize: "1rem" }} />}

      {!isLoading && (
        <AppLink enabled={!!to} to={to!}>
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            {_negotiations
              .map((x) => ({
                negotiation: x,
                lastProposal: x.proposals!.slice(-1)[0]!,
              }))
              .filter((x) => !!x.lastProposal && allowedActionsMap[x.negotiation.id!])
              .map((x) => (
                <NegotiationProposalComputedStatusIcon
                  key={x.lastProposal.id!}
                  negotiation={x.negotiation}
                  proposal={x.lastProposal}
                  allowedActions={allowedActionsMap[x.negotiation.id!]!}
                  partiesMembership={partiesMembership}
                />
              ))}
          </Stack>
        </AppLink>
      )}
    </Box>
  );
}
