import { Box, IconButton, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";

import DataList, {
  DataListItemColor,
  DataListItemSeverityColor,
} from "@/common/components/DataList/DataList";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";

interface TestItem {
  number: number;
  title: string;
  price: number;
}

const items: TestItem[] = [
  {
    number: 1,
    title: "Jam",
    price: 55.43,
  },
  {
    number: 2,
    title: "Tea",
    price: 11.2,
  },
  {
    number: 3,
    title: "Onion",
    price: 5,
  },
  {
    number: 4,
    title: "Tea",
    price: 35,
  },
  {
    number: 5,
    title: "Bread",
    price: 15,
  },
  {
    number: 999,
    title: "Apple (highlighted)",
    price: 9,
  },
];

export default function DevDataList() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        DataList
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        Variants
      </Typography>

      <Typography component='div' variant='h6' sx={{ my: 2 }}>
        Default
      </Typography>
      <DataList
        columns={[
          {
            field: "number",
            title: "Number",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.number}</>,
          },
          {
            field: "title",
            title: "Title",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.title}</>,
          },
          {
            field: "price",
            title: "Price",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.price}</>,
          },
        ]}
        rows={items}
        getRowId={(item) => item.number.toString()}
        rowTo={(item) => "item/test"}
        rowHighlightPropsGetter={(item) =>
          (item.number === 999 && { isHighlighted: true, duration: 1000 }) || {}
        }
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            <MenuItem>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuWithTrigger>
        )}
      />

      <Typography component='div' variant='h6' sx={{ my: 2 }}>
        Shadowed
      </Typography>
      <DataList
        headerProps={{
          variant: "shadowed",
        }}
        contentProps={{
          itemVariant: "shadowed",
        }}
        columns={[
          {
            field: "number",
            title: "Number",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.number}</>,
          },
          {
            field: "title",
            title: "Title",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.title}</>,
          },
          {
            field: "price",
            title: "Price",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.price}</>,
          },
        ]}
        rows={items}
        getRowId={(item) => item.number.toString()}
        rowTo={(item) => "item/test"}
        rowHighlightPropsGetter={(item) =>
          (item.number === 999 && { isHighlighted: true, duration: 1000 }) || {}
        }
      />

      <Typography component='div' variant='h6' sx={{ my: 2 }}>
        Bordered
      </Typography>
      <DataList
        headerProps={{
          variant: "bordered",
        }}
        contentProps={{
          itemVariant: "bordered",
        }}
        columns={[
          {
            field: "number",
            title: "Number",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.number}</>,
          },
          {
            field: "title",
            title: "Title",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.title}</>,
          },
          {
            field: "price",
            title: "Price",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.price}</>,
          },
        ]}
        rows={items}
        getRowId={(item) => item.number.toString()}
        rowTo={(item) => "item/test"}
        rowHighlightPropsGetter={(item) =>
          (item.number === 999 && { isHighlighted: true, duration: 1000 }) || {}
        }
      />

      <Typography component='div' variant='h6' sx={{ my: 2 }}>
        Compact header, item color.
      </Typography>
      <DataList
        headerProps={{
          variant: "compact",
        }}
        contentProps={{
          itemVariant: "bordered",
          itemColor: (item, i) =>
            (["none", "gray", "success", "info", "warning", "error"] as DataListItemColor[])[
              Math.min(i, 4)
            ],
        }}
        columns={[
          {
            field: "number",
            title: "Number",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.number}</>,
          },
          {
            field: "title",
            title: "Title",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.title}</>,
          },
          {
            field: "price",
            title: "Price",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.price}</>,
          },
        ]}
        rows={items}
        getRowId={(item) => item.number.toString()}
        rowTo={(item) => "item/test"}
      />

      <Typography component='div' variant='h6' sx={{ my: 2 }}>
        Compact header, item severity.
      </Typography>
      <DataList
        headerProps={{
          variant: "compact",
        }}
        contentProps={{
          itemVariant: "bordered",
          itemSeverity: (item, i) =>
            (["none", "success", "info", "warning", "error"] as DataListItemSeverityColor[])[
              Math.min(i, 4)
            ],
        }}
        columns={[
          {
            field: "number",
            title: "Number",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.number}</>,
          },
          {
            field: "title",
            title: "Title",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.title}</>,
          },
          {
            field: "price",
            title: "Price",
            xxs: true,
            md: 2,
            renderCell: (item) => <>{item.price}</>,
          },
        ]}
        rows={items}
        getRowId={(item) => item.number.toString()}
        rowTo={(item) => "item/test"}
      />
    </Box>
  );
}
