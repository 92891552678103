import { Box, Chip, Stack, Typography } from "@mui/material";

import IconChip from "@/common/components/Chip/IconChip";
import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";

export default function DevChips() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Chips
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Chips</InlineCode>
      </Typography>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={1}>
          <Chip variant='filled' label='filled' />
          <Chip variant='outlined' label='outlined' />
        </Stack>

        <Stack direction='row' spacing={1}>
          <Chip size='extraSmall' label='extraSmall' />
          <Chip size='small' label='small' />
          <Chip size='medium' label='medium' />
        </Stack>

        <Stack direction='row' spacing={1}>
          <Chip variant='filled' color='primary' label='filled primary' />
          <Chip variant='filled' color='secondary' label='filled secondary' />
          <Chip variant='filled' color='info' label='filled info' />
          <Chip variant='filled' color='error' label='filled error' />
          <Chip variant='filled' color='warning' label='filled warning' />
          <Chip variant='filled' color='success' label='filled success' />
        </Stack>

        <Stack direction='row' spacing={1}>
          <Chip variant='outlined' color='primary' label='outlined primary' />
          <Chip variant='outlined' color='secondary' label='outlined secondary' />
          <Chip variant='outlined' color='info' label='outlined info' />
          <Chip variant='outlined' color='error' label='outlined error' />
          <Chip variant='outlined' color='warning' label='outlined warning' />
          <Chip variant='outlined' color='success' label='outlined success' />
        </Stack>
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>IconChip</InlineCode>
      </Typography>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <IconChip variant='filled' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' icon={<AppIcon of='accessory' />} />
        </Stack>

        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <IconChip size='small' icon={<AppIcon of='accessory' />} />
          <IconChip size='medium' icon={<AppIcon of='accessory' />} />
        </Stack>

        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <IconChip variant='filled' color='primary' icon={<AppIcon of='accessory' />} />
          <IconChip variant='filled' color='secondary' icon={<AppIcon of='accessory' />} />
          <IconChip variant='filled' color='info' icon={<AppIcon of='accessory' />} />
          <IconChip variant='filled' color='error' icon={<AppIcon of='accessory' />} />
          <IconChip variant='filled' color='warning' icon={<AppIcon of='accessory' />} />
          <IconChip variant='filled' color='success' icon={<AppIcon of='accessory' />} />
        </Stack>

        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <IconChip variant='outlined' color='primary' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' color='secondary' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' color='info' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' color='error' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' color='warning' icon={<AppIcon of='accessory' />} />
          <IconChip variant='outlined' color='success' icon={<AppIcon of='accessory' />} />
        </Stack>
      </Stack>
    </Box>
  );
}
