import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { CustomerDto, EntityType } from "@/core/api/generated";

import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  customer: CustomerDto;
}

export default function GeneralHistoryTabContent({ customer }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <GeneralHistoryPaginatedList
          defaultValues={{
            subjectEntityType: EntityType.Customer,
            subjectEntityId: customer.id,
          }}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
          }}
        />
      </ViewContentBlock>
    </PageTabContent>
  );
}
