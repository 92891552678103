import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateRepairSpecDto,
  EntityType,
  GeneralTaxDto,
  RepairMaterialDto,
  RepairMaterialFullReferenceDto,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  RepairSpecDetalizationType,
  RepairSpecDto,
  RepairSpecItemInputDto,
  RepairSpecSparePartDto,
  RepairSpecSparePartInputDto,
  RepairWorkDto,
  RepairWorkFullReferenceDto,
  UpdateRepairSpecDto,
  VehicleType,
} from "@/core/api/generated";

import FormActions from "../../Form/FormActions";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import RepairSpecDataInputDtoFormInputs from "./RepairSpecDataInputDtoFormInputs";

type DefaultValues = {
  name?: string;
  partTypeId?: string;
  damageTypeId?: string;
  data?: RepairSpecDto & RepairSpecDataDto & RepairSpecDataInputDto;
  currency?: CreateRepairSpecDto["currency"];
};

export interface CreateUpdateRepairSpecOwnProps
  extends BaseEntityCreateUpdateInheritableProps<RepairSpecDto, DefaultValues> {
  repairSpecId?: string;
  createFunc?: (params: {
    dto: CreateRepairSpecDto;
  }) => Promise<AxiosResponse<RepairSpecDto, unknown>>;
  updateFunc?: (params: {
    repairSpecId: string;
    dto: UpdateRepairSpecDto;
  }) => Promise<AxiosResponse<RepairSpecDto, unknown>>;
}

export type CreateUpdateRepairSpecProps = CreateUpdateRepairSpecOwnProps;

export default function CreateUpdateRepairSpec({
  repairSpecId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  createFunc,
  updateFunc,
}: CreateUpdateRepairSpecProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentCurrency = useCurrentCurrency();

  const isCreate = !repairSpecId;
  const isEdit = !!repairSpecId;

  const repairSpecRequest = useApiRequest(
    apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSpecId: repairSpecId!,
    },
    {
      skip: !repairSpecId,
    },
  );
  const repairSpec = repairSpecRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: repairSpec && {
      idValue: repairSpec.id!,
      newTitle: repairSpec.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.RepairSpec}
      entityId={repairSpecId}
      entity={repairSpec}
      entityRequest={repairSpecRequest}
    >
      <Formik<
        Omit<CreateRepairSpecDto, "items"> &
          Omit<UpdateRepairSpecDto, "items"> & {
            items?: Array<
              RepairSpecItemInputDto & {
                material?: RepairMaterialDto | RepairMaterialFullReferenceDto;
                work?: RepairWorkDto | RepairWorkFullReferenceDto;
                tax?: GeneralTaxDto | null;
                sparePart?: RepairSpecSparePartDto & RepairSpecSparePartInputDto;
                isSpotFactorChanged?: boolean;
              }
            >;
            submit?: string | null;
          }
      >
        enableReinitialize={isEdit}
        initialValues={{
          currency:
            repairSpec?.currency ||
            defaultValues?.currency ||
            defaultValues?.data?.currency ||
            currentCurrency ||
            undefined,
          vehicleType:
            repairSpec?.vehicleType || defaultValues?.data?.vehicleType || VehicleType.Car,
          damageTypeId:
            repairSpec?.damageType?.id ||
            defaultValues?.damageTypeId ||
            defaultValues?.data?.damageTypeId ||
            defaultValues?.data?.damageType?.id ||
            undefined,
          partTypeId:
            repairSpec?.partType?.id ||
            defaultValues?.partTypeId ||
            defaultValues?.data?.partTypeId ||
            defaultValues?.data?.partType?.id ||
            undefined,
          name: repairSpec?.name || defaultValues?.name || defaultValues?.data?.name || undefined,
          description: repairSpec?.description || defaultValues?.data?.description || undefined,
          spotFactor: repairSpec?.spotFactor || defaultValues?.data?.spotFactor || 1,
          detalization: {
            ...repairSpec?.detalization,
            type:
              repairSpec?.detalization?.type ||
              defaultValues?.data?.detalization?.type ||
              RepairSpecDetalizationType.General,
            vehicleSize:
              repairSpec?.detalization?.vehicleSize ||
              defaultValues?.data?.detalization?.vehicleSize ||
              undefined,
            bodyType:
              repairSpec?.detalization?.bodyType ||
              defaultValues?.data?.detalization?.bodyType ||
              undefined,
            makeId:
              repairSpec?.detalization?.make?.id ||
              defaultValues?.data?.detalization?.makeId ||
              defaultValues?.data?.detalization?.make?.id ||
              undefined,
            modelId:
              repairSpec?.detalization?.model?.id ||
              defaultValues?.data?.detalization?.modelId ||
              defaultValues?.data?.detalization?.model?.id ||
              undefined,
            generationId:
              repairSpec?.detalization?.generation?.id ||
              defaultValues?.data?.detalization?.generationId ||
              defaultValues?.data?.detalization?.generation?.id ||
              undefined,
            modificationId:
              repairSpec?.detalization?.modification?.id ||
              defaultValues?.data?.detalization?.modificationId ||
              defaultValues?.data?.detalization?.modification?.id ||
              undefined,
          },
          items:
            (repairSpec?.items || defaultValues?.data?.items)?.map((x) => ({
              ...x,
              materialId: x.material?.id,
              workId: x.work?.id,
              amount: x?.amount ?? 1,
              spotFactor: x?.spotFactor ?? 1,
              material: x.material || undefined,
              work: x.work || undefined,
              sparePart:
                (x.sparePart && {
                  ...x.sparePart,
                }) ||
                undefined,
            })) || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = createFunc
                ? await createFunc({
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairSpecsApi.apiV1RepairSpecsPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    createRepairSpecDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair spec created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = updateFunc
                ? await updateFunc({
                    repairSpecId,
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    repairSpecId,
                    updateRepairSpecDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair spec updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <RepairSpecDataInputDtoFormInputs
                  mode={(isEdit && "edit") || "create"}
                  values={values || {}}
                  formikProps={{
                    errors: errors,
                    touched: touched,
                  }}
                  withGeneralValidationError
                  onChange={(newValue) => setValues({ ...newValue })}
                />
              </Box>

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
