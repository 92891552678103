import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export default function SubscriptionPlansPage() {
  const plansRequest = useApiRequest(
    apiClient.adminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansGet,
    {},
    {},
  );
  const plans = plansRequest.data;

  return (
    <ListPageLayout>
      <Stack
        direction={{ xs: "column", md: "row" }}
        flexWrap={"wrap"}
        gap={1}
        sx={{ alignItems: "flex-start" }}
      >
        {_.orderBy(plans, (x) => x.sortOrder, "asc").map((plan, index) => {
          return (
            <Card
              key={index}
              sx={{
                minWidth: 200,
                maxWidth: 300,
              }}
            >
              <CardContent>
                <Typography component='div' variant='h6' sx={{ display: "flex" }}>
                  <span>{plan.name}</span>

                  <Typography component='div' variant='body1' sx={{ ml: "auto" }}>
                    {plan.isFree && <AppIcon of='isFree' tooltipTitle='Free' />}
                    {!plan.isFree && <AppIcon of='isPaid' tooltipTitle='Free' />}
                    {plan.enabled && <AppIcon of='enabled' tooltipTitle='Enabled' />}
                    {!plan.enabled && <AppIcon of='disabled' tooltipTitle='Disabled' />}
                  </Typography>
                </Typography>
                <Typography component='div' variant='body1'>
                  {plan.description}
                </Typography>

                <Typography component='div' sx={{ mb: 1 }}>
                  Prices:
                </Typography>
                <Box component='ul' sx={{ mt: 0 }}>
                  {plan.prices!.map((price, index2) => {
                    return (
                      <Box key={index2} component='li'>
                        {price.price} per {price.period}
                      </Box>
                    );
                  })}
                </Box>

                <Typography component='div' sx={{ mb: 1 }}>
                  Features:
                </Typography>
                <Box component='ul' sx={{ mt: 0 }}>
                  {plan.modules!.map((module, index2) => {
                    return (
                      <Box key={index2} component='li'>
                        {module}
                      </Box>
                    );
                  })}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_SUBSCRIPTION_PLANS_EDIT(plan.id)}
                >
                  Edit
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </Stack>
    </ListPageLayout>
  );
}
