import { GeneralScopeDto, GeneralScopeType } from "@/core/api/generated";

import AssessmentFlowLink from "../Entity/AssessmentFlow/AssessmentFlowLink";
import DamageCostEvaluationAggregateLink from "../Entity/DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageDetectionAggregateLink from "../Entity/DamageDetectionAggregate/DamageDetectionAggregateLink";
import { BaseEntityLinkInheritableProps } from "../Entity/components/BaseEntityLink";

interface Props extends BaseEntityLinkInheritableProps {
  scope?: GeneralScopeDto;
}

export default function GeneralScopeLink({ scope, ...linkProps }: Props) {
  if (!scope || !scope.identifier || !scope.type) {
    return null;
  }

  return (
    <>
      {scope.type === GeneralScopeType.AssessmentFlow && (
        <AssessmentFlowLink entity={scope.assessmentFlow} {...linkProps} />
      )}
      {scope.type === GeneralScopeType.DamageDetectionAggregate && (
        <DamageDetectionAggregateLink entity={scope.damageDetectionAggregate} {...linkProps} />
      )}
      {scope.type === GeneralScopeType.DamageDetectionAggregateItem && (
        <DamageDetectionAggregateLink entity={scope.damageDetectionAggregate} {...linkProps} />
      )}
      {scope.type === GeneralScopeType.DamageCostEvaluationAggregate && (
        <DamageCostEvaluationAggregateLink
          entity={scope.damageCostEvaluationAggregate}
          {...linkProps}
        />
      )}
      {scope.type === GeneralScopeType.DamageCostEvaluationAggregateItem && (
        <DamageCostEvaluationAggregateLink
          entity={scope.damageCostEvaluationAggregate}
          {...linkProps}
        />
      )}
    </>
  );
}
