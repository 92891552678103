import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityType, RepairOperationDto } from "@/core/api/generated";

import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  repairOperation: RepairOperationDto;
}

export default function GeneralHistoryTabContent({ repairOperation }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <GeneralHistoryPaginatedList
          defaultValues={{
            subjectEntityType: EntityType.RepairOperation,
            subjectEntityId: repairOperation.id,
          }}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
          }}
        />
      </ViewContentBlock>
    </PageTabContent>
  );
}
