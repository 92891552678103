import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  DamageCostEvaluationAggregateDto,
  DamageCostEvaluationAggregateReferenceDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import DamageCostEvaluationAggregateInline, {
  DamageCostEvaluationAggregateInlineProps,
} from "./DamageCostEvaluationAggregateInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity:
    | DamageCostEvaluationAggregateDto
    | DamageCostEvaluationAggregateReferenceDto
    | null
    | undefined;
  entityId?: string | null;
  item?: DamageCostEvaluationItemDto | DamageCostEvaluationItemReferenceDto;
  itemId?: string | null;
  inlineProps?: Partial<DamageCostEvaluationAggregateInlineProps>;
}

export default function DamageCostEvaluationAggregateLink({
  entity,
  entityId,
  item,
  itemId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([
        AppPermission.FleetAppAccess,
        AppPermission.DamageCostEvaluationRead,
      ])}
      to={ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATE_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <DamageCostEvaluationAggregateInline
          entity={entity}
          entityId={entityId}
          item={item}
          itemId={itemId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage coste valuation aggregate"}
    />
  );
}
