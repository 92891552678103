import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { TenantPaymentProfileDto, TenantPaymentProfileInputDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

export interface PaymentsState {
  loading: {
    getPaymentProfile?: boolean;
  };
  paymentProfile?: TenantPaymentProfileDto;
}

const initialState: PaymentsState = {
  loading: {},
  paymentProfile: undefined,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<PaymentsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _paymentProfileFetched: (state, action) => {
      state.paymentProfile = action.payload;
    },
  },
});

export const { setLoading, _paymentProfileFetched } = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;

export const setPaymentProfile =
  (tenantPaymentProfileInputDto: TenantPaymentProfileInputDto): AppThunk =>
  async (dispatch) => {
    try {
      const response = await apiClient.paymentsApi.apiV1PaymentsPaymentProfilePut({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        tenantPaymentProfileInputDto,
      });
      await dispatch(_paymentProfileFetched(response.data));
    } catch (error) {
      throw error;
    }
  };
