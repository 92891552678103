import ChatIcon from "@mui/icons-material/Chat";
import FolderIcon from "@mui/icons-material/Folder";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import ChatActivator from "@/common/components/Entity/Chat/ChatActivator";
import NegotiationChatActivator from "@/common/components/Entity/Chat/NegotiationChatActivator";
import AppIcon from "@/common/components/Icons/AppIcon";
import { GeneralScopeDto, GeneralScopeType } from "@/core/api/generated";

function DevChats() {
  const parentScope: GeneralScopeDto = {
    identifier: "exampleReport1",
    subIdentifier: "description",
    parentIdentifier: null,
    type: GeneralScopeType.Dynamic,
  };
  const item1Scope: GeneralScopeDto = {
    identifier: "exampleReport1Item1",
    parentIdentifier: parentScope.identifier,
    type: GeneralScopeType.Dynamic,
  };
  const item2Scope: GeneralScopeDto = {
    identifier: "exampleReport1Item2",
    parentIdentifier: parentScope.identifier,
    type: GeneralScopeType.Dynamic,
  };

  return (
    <Box>
      <Box>
        <Typography component='div' variant='h4' sx={{ my: 2 }}>
          Comment chat
        </Typography>
        <Box>
          Activator 1 (popover) -{" "}
          <ChatActivator
            chatType='Comment'
            chatScope={{ identifier: "styleguide1" }}
            withActivityIndicator
          />
        </Box>
        <Box>
          Activator 1 (stack) -{" "}
          <ChatActivator
            chatType='Comment'
            chatScope={{ identifier: "styleguide1" }}
            chatPlacement='stack'
            withActivityIndicator
          />
        </Box>
        <Box>
          Activator 2 (stack) -{" "}
          <ChatActivator
            chatType='Comment'
            chatScope={{ identifier: "styleguide2" }}
            chatPlacement='stack'
            withActivityIndicator
          />
        </Box>
        <Box>
          Activator 3 (stack) -{" "}
          <ChatActivator
            chatType='Comment'
            chatScope={{ identifier: "styleguide3" }}
            chatPlacement='stack'
            withActivityIndicator
          />
        </Box>
      </Box>

      <Box>
        <Typography component='div' variant='h4' sx={{ my: 2 }}>
          Negotiation chat
        </Typography>
        <Box>
          <Typography component='div' variant='h6'>
            Example Report negotiation chat 1
          </Typography>
          <Typography component='div' variant='body1'>
            Description: Some text{" "}
            <NegotiationChatActivator
              chatScope={parentScope}
              chatPlacement='stack'
              withActivityIndicator
            />
          </Typography>
          <Grid container>
            <Grid item md={6}>
              <List dense>
                <ListItem
                  secondaryAction={
                    <NegotiationChatActivator
                      chatScope={item1Scope}
                      chatPlacement='stack'
                      withActivityIndicator
                    />
                  }
                >
                  <ListItemIcon>
                    <AppIcon of='folder' />
                  </ListItemIcon>
                  <ListItemText primary='Item 1' secondary={"Secondary text 1"} />
                </ListItem>
                <ListItem
                  secondaryAction={
                    <NegotiationChatActivator
                      chatScope={item2Scope}
                      chatPlacement='stack'
                      withActivityIndicator
                    />
                  }
                >
                  <ListItemIcon>
                    <AppIcon of='folder' />
                  </ListItemIcon>
                  <ListItemText primary='Item 2' secondary={"Secondary text 2"} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default DevChats;
