import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "../../Modals/AppModalTitle";
import UpdateChatUserSettings, { UpdateChatUserSettingsProps } from "./UpdateChatUserSettings";

export interface OwnProps {
  updateChatUserSettingsProps: UpdateChatUserSettingsProps;
}

type Props = OwnProps & DialogProps;

export default function UpdateChatUserSettingsModal({
  updateChatUserSettingsProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Update your chat settings
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <UpdateChatUserSettings {...updateChatUserSettingsProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
