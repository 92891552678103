import { Box, Stack } from "@mui/material";

import AppLink from "@/common/components/Link/AppLink";
import GoBackLink from "@/common/components/Link/GoBackLink";
import { ROUTE_PATH } from "@/common/constants/routing";

/** Asks user to logout before registering. */
export default function LogoutBeforeRegisteringPage() {
  return (
    <Stack spacing={2}>
      <Box>
        Please <AppLink to={ROUTE_PATH.AUTH_SIGN_OUT}>logout</AppLink> before registering a new
        account.
      </Box>
      <Box>
        <GoBackLink />
      </Box>
    </Stack>
  );
}
