import { useMemo } from "react";

import { FrontEndActionParamsInQueryStringDto } from "@/core/api/generated";

import {
  ActionParamsInQueryString,
  ActionParamsInQueryStringData,
  queryStringParamsService,
} from "../services/queryStringParams";
import { useQueryParams } from "./useQueryParams";

export type ActionParamsInQueryStringBackEndDto = FrontEndActionParamsInQueryStringDto;

/** Reads action params from URL query string.
 *  Action params can be provided by both FE and BE.
 */
export const useActionParamsInQueryString = (): ActionParamsInQueryStringData | undefined => {
  const queryParams = useQueryParams<ActionParamsInQueryString>();

  return useMemo(() => {
    const params = Object.keys(queryParams).length !== 0 ? queryParams : undefined;
    if (!params || !params.actionParamsEnc) {
      return undefined;
    }

    // decode
    return queryStringParamsService.decodeParams<ActionParamsInQueryStringData>(
      params.actionParamsEnc,
    );
  }, [queryParams]);
};
