import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  RepairSparePartDto,
  RepairSparePartFullReferenceDto,
  RepairSparePartReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import RepairSparePartInline, { RepairSparePartInlineProps } from "./RepairSparePartInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity:
    | RepairSparePartDto
    | RepairSparePartReferenceDto
    | RepairSparePartFullReferenceDto
    | null
    | undefined;
  entityId?: string | null;
  inlineProps?: Partial<RepairSparePartInlineProps>;
}

export default function RepairSparePartLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])}
      to={ROUTE_PATH.REPAIR_SPARE_PART_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <RepairSparePartInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair spare part"}
    />
  );
}
