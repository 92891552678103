import { Box, SxProps, Theme } from "@mui/material";
import { useState } from "react";
import "react-international-phone/style.css";

import UserLink from "@/common/components/Entity/User/UserLink";
import AppTypography from "@/common/components/Text/AppTypography";
import { renderIf } from "@/common/helpers/render/renderIf";
import { GeneralTagDtoUnion } from "@/common/ts/dto";
import { TagTargetType } from "@/core/api/generated";

import AccessoryLink from "../../Accessory/AccessoryLink";
import AccessoryCheckLink from "../../AccessoryCheck/AccessoryCheckLink";
import AssessmentFlowLink from "../../AssessmentFlow/AssessmentFlowLink";
import AssetLink from "../../Asset/AssetLink";
import AssetSubscriptionLink from "../../AssetSubscription/AssetSubscriptionLink";
import AssetSubscriptionPlanLink from "../../AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import ChatLink from "../../Chat/ChatLink";
import ChatParticipantLink from "../../ChatParticipant/ChatParticipantLink";
import ContractLink from "../../Contract/ContractLink";
import CustomTagLink from "../../CustomTag/CustomTagLink";
import CustomerLink from "../../Customer/CustomerLink";
import DamageCostEvaluationLink from "../../DamageCostEvaluation/DamageCostEvaluationLink";
import DamageCostEvaluationAggregateLink from "../../DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageDetectionLink from "../../DamageDetection/DamageDetectionLink";
import DamageDetectionAggregateLink from "../../DamageDetectionAggregate/DamageDetectionAggregateLink";
import DataGrantLink from "../../DataGrant/DataGrantLink";
import DepartmentLink from "../../Department/DepartmentLink";
import LocationLink from "../../Location/LocationLink";
import MaintenanceLink from "../../Maintenance/MaintenanceLink";
import NegotiationLink from "../../Negotiation/NegotiationLink";
import PoolLink from "../../Pool/PoolLink";
import RepairOperationLink from "../../RepairOperation/RepairOperationLink";
import SpotLink from "../../Spot/SpotLink";
import TenantConnectionLink from "../../TenantConnection/TenantConnectionLink";
import TenantConnectionRequestLink from "../../TenantConnectionRequest/TenantConnectionRequestLink";
import TenantRequestLink from "../../TenantRequest/TenantRequestLink";
import VehicleLink from "../../Vehicle/VehicleLink";
import VehicleDamageLink from "../../VehicleDamage/VehicleDamageLink";
import VisualInspectionLink from "../../VisualInspection/VisualInspectionLink";
import WashLink from "../../Wash/WashLink";
import WheelOperationLink from "../../WheelOperation/WheelOperationLink";
import GeneralTagDetailsPopover from "./GeneralTagDetailsPopover";
import TagDisplay, { TagDisplayProps } from "./TagDisplay";

const linkSx: SxProps<Theme> = {
  color: "inherit",
  textDecoration: "none",
};

interface Props extends Pick<TagDisplayProps, "disableHoverEffects" | "onClick" | "onDelete"> {
  tag: GeneralTagDtoUnion;
  enableLink?: boolean;
  withDetailsPopover?: boolean;
  sx?: SxProps<Theme>;
}

export default function GeneralTagDisplay({
  tag,
  enableLink = true,
  withDetailsPopover = true,
  sx,
  ...tagDisplayProps
}: Props) {
  const [detailsPopoverAnchorEl, setDetailsPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const isDetailsPopoverOpen = Boolean(detailsPopoverAnchorEl);

  const targetContent = (
    <>
      {renderIf()
        .if(tag.targetType === TagTargetType.CustomTag)
        .then(
          <CustomTagLink
            entity={tag.target?.customTag}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.User)
        .then(
          <UserLink user={tag.target?.user} enabled={enableLink} withTooltip={false} sx={linkSx} />,
        )
        .elseif(tag.targetType === TagTargetType.Vehicle)
        .then(
          <VehicleLink
            entity={tag.target?.vehicle}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Asset)
        .then(
          <AssetLink
            entity={tag.target?.asset}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Contract)
        .then(
          <ContractLink
            entity={tag.target?.contract}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.AssessmentFlow)
        .then(
          <AssessmentFlowLink
            entity={tag.target?.assessmentFlow}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Customer)
        .then(
          <CustomerLink
            entity={tag.target?.customer}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.VehicleDamage)
        .then(
          <VehicleDamageLink
            vehicleId={tag.target?.vehicleDamage?.vehicleId}
            entity={tag.target?.vehicleDamage}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Accessory)
        .then(
          <AccessoryLink
            entity={tag.target?.accessory}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.VisualInspection)
        .then(
          <VisualInspectionLink
            entity={tag.target?.visualInspection}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.DamageDetection)
        .then(
          <DamageDetectionLink
            entity={tag.target?.damageDetection}
            entityId={tag.target?.targetId}
            item={tag.subTarget?.damageDetectionItem}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.DamageDetectionAggregate)
        .then(
          <DamageDetectionAggregateLink
            entity={tag.target?.damageDetectionAggregate}
            entityId={tag.target?.targetId}
            item={tag.subTarget?.damageDetectionAggregateItem}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.DamageCostEvaluation)
        .then(
          <DamageCostEvaluationLink
            entity={tag.target?.damageCostEvaluation}
            entityId={tag.target?.targetId}
            item={tag.subTarget?.damageCostEvaluationItem}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.DamageCostEvaluationAggregate)
        .then(
          <DamageCostEvaluationAggregateLink
            entity={tag.target?.damageCostEvaluationAggregate}
            entityId={tag.target?.targetId}
            item={tag.subTarget?.damageCostEvaluationAggregateItem}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.AccessoryCheck)
        .then(
          <AccessoryCheckLink
            entity={tag.target?.accessoryCheck}
            entityId={tag.target?.targetId}
            item={tag.subTarget?.accessoryCheckItem}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.RepairOperation)
        .then(
          <RepairOperationLink
            entity={tag.target?.repairOperation}
            entityId={tag.target?.targetId}
            item={undefined}
            itemId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Wash)
        .then(
          <WashLink
            entity={tag.target?.wash}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.WheelOperation)
        .then(
          <WheelOperationLink
            entity={tag.target?.wheelOperation}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Wash)
        .then(
          <WashLink
            entity={tag.target?.wash}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Maintenance)
        .then(
          <MaintenanceLink
            entity={tag.target?.maintenance}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Chat)
        .then(
          <ChatLink
            entity={tag.target?.chat}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.ChatParticipant)
        .then(
          <ChatParticipantLink
            chatId={tag.target?.chatParticipant?.chatId}
            entity={tag.target?.chatParticipant}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Negotiation)
        .then(
          <NegotiationLink
            entity={tag.target?.negotiation}
            entityId={tag.target?.targetId}
            proposal={tag.subTarget?.proposal}
            proposalId={tag.subTarget?.subTargetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.AssetSubscriptionPlan)
        .then(
          <AssetSubscriptionPlanLink
            entity={tag.target?.assetSubscriptionPlan}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.AssetSubscription)
        .then(
          <AssetSubscriptionLink
            entity={tag.target?.assetSubscription}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.TenantConnectionRequest)
        .then(
          <TenantConnectionRequestLink
            entity={tag.target?.tenantConnectionRequest}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.TenantConnection)
        .then(
          <TenantConnectionLink
            entity={tag.target?.tenantConnection}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.TenantRequest)
        .then(
          <TenantRequestLink
            entity={tag.target?.tenantRequest}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.DataGrant)
        .then(
          <DataGrantLink
            entity={tag.target?.dataGrant}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Pool)
        .then(
          <PoolLink
            entity={tag.target?.pool}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Department)
        .then(
          <DepartmentLink
            entity={tag.target?.department}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Location)
        .then(
          <LocationLink
            entity={tag.target?.location}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .elseif(tag.targetType === TagTargetType.Spot)
        .then(
          <SpotLink
            entity={tag.target?.spot}
            entityId={tag.target?.targetId}
            enabled={enableLink}
            withTooltip={false}
            sx={linkSx}
          />,
        )
        .else(<AppTypography color='error'>Unknown tag target {tag.targetType}</AppTypography>)
        .render()}
    </>
  );

  const content = targetContent;

  return (
    <Box component='span'>
      <TagDisplay
        label={content || "???"}
        pickColorForText={tag.targetType?.toString() || tag.id || ""}
        backgroundColor={tag.target?.customTag?.styles?.backgroundColor || undefined}
        sx={sx}
        onMouseEnter={(e) => withDetailsPopover && setDetailsPopoverAnchorEl(e.currentTarget)}
        onMouseLeave={() => withDetailsPopover && setDetailsPopoverAnchorEl(null)}
        {...tagDisplayProps}
      />

      <GeneralTagDetailsPopover
        tag={tag}
        anchorEl={detailsPopoverAnchorEl}
        open={isDetailsPopoverOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </Box>
  );
}
