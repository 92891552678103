import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import WebhookCreateUpdate from "@/common/components/Entity/Webhook/WebhookCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function WebhookCreateUpdatePage() {
  const { webhookId } = useParams<{ webhookId?: string }>();
  const history = useHistory();

  const isCreate = !webhookId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new webhook" : "Edit webhook"} />}
    >
      <WebhookCreateUpdate
        webhookId={webhookId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOK_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
