import { Box, Stack, Typography } from "@mui/material";
import _ from "lodash";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import { PoolDto, PoolItemEntityType, PoolItemStatus } from "@/core/api/generated";

import PoolAvailabilityDisplay from "../../PoolAvailabilityDisplay";

interface Props {
  pool: PoolDto;
}

export default function StateTabContent({ pool }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"State"} />}
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Box>
              <PoolAvailabilityDisplay poolId={pool?.id} availability={undefined} isLoad />
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(4, 1fr)`,
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Total' iconOf='data'>
                {pool?.state?.totalCount}
              </InfoTile>
            </Box>

            <Stack spacing={1}>
              <Typography component='div' variant='subtitle1'>
                By status
              </Typography>

              {_.isEmpty(pool?.state?.countByStatusMap) && <NoDataAlert />}

              {pool?.state?.countByStatusMap && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(4, 1fr)`,
                    gridTemplateRows: "auto",
                    rowGap: 1,
                    columnGap: 1,
                  }}
                >
                  {Object.entries(pool.state.countByStatusMap).map(([key, value], i) => (
                    <InfoTile
                      key={i}
                      label={
                        <InlineApiEnumValue type='PoolItemStatus' value={key as PoolItemStatus} />
                      }
                      iconOf='data'
                    >
                      {!_.isNil(value) ? value : "-"}
                    </InfoTile>
                  ))}
                </Box>
              )}
            </Stack>

            <Stack spacing={1}>
              <Typography component='div' variant='subtitle1'>
                By entity type
              </Typography>

              {_.isEmpty(pool?.state?.countByEntityTypeMap) && <NoDataAlert />}

              {pool?.state?.countByEntityTypeMap && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(4, 1fr)`,
                    gridTemplateRows: "auto",
                    rowGap: 1,
                    columnGap: 1,
                  }}
                >
                  {Object.entries(pool.state.countByEntityTypeMap).map(([key, value], i) => (
                    <InfoTile
                      key={i}
                      label={
                        <InlineApiEnumValue
                          type='PoolItemEntityType'
                          value={key as PoolItemEntityType}
                        />
                      }
                      iconOf='data'
                    >
                      {!_.isNil(value) ? value : "-"}
                    </InfoTile>
                  ))}
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
