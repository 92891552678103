import { Box, FormHelperText, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import { renderIf } from "@/common/helpers/render/renderIf";
import { EntityType, IBaseEntityDto } from "@/core/api/generated";

import AccessoryLink from "../Accessory/AccessoryLink";
import AccessoryCheckLink from "../AccessoryCheck/AccessoryCheckLink";
import AssetLink from "../Asset/AssetLink";
import AssetSubscriptionLink from "../AssetSubscription/AssetSubscriptionLink";
import AssetSubscriptionPlanLink from "../AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import ContractLink from "../Contract/ContractLink";
import CustomerLink from "../Customer/CustomerLink";
import DamageCostEvaluationLink from "../DamageCostEvaluation/DamageCostEvaluationLink";
import DamageCostEvaluationAggregateLink from "../DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageDetectionLink from "../DamageDetection/DamageDetectionLink";
import DamageDetectionAggregateLink from "../DamageDetectionAggregate/DamageDetectionAggregateLink";
import MaintenanceLink from "../Maintenance/MaintenanceLink";
import RepairOperationLink from "../RepairOperation/RepairOperationLink";
import SpotLink from "../Spot/SpotLink";
import SupplierLink from "../Supplier/SupplierLink";
import TenantConnectionLink from "../TenantConnection/TenantConnectionLink";
import TenantConnectionRequestLink from "../TenantConnectionRequest/TenantConnectionRequestLink";
import TenantRequestLink from "../TenantRequest/TenantRequestLink";
import VehicleLink from "../Vehicle/VehicleLink";
import VehicleDamageLink from "../VehicleDamage/VehicleDamageLink";
import VisualInspectionLink from "../VisualInspection/VisualInspectionLink";
import WashLink from "../Wash/WashLink";
import WheelOperationLink from "../WheelOperation/WheelOperationLink";
import { BaseEntityLinkInheritableProps } from "./BaseEntityLink";

interface Props {
  entityType: EntityType | undefined;
  entityId: string | undefined;
  entity: IBaseEntityDto | undefined;
  linkProps?: Partial<BaseEntityLinkInheritableProps>;
  sx?: SxProps<Theme>;
}

/** Based on entity type renders corresponding entity component. */
export default function EntityLink({ entityType, entityId, entity, linkProps, sx }: Props) {
  const entityIdComputed = useMemo(() => entityId ?? entity?.id, [entityId, entity]);

  if (!entityType && !entityIdComputed) {
    return null;
  }

  return (
    <Box
      component='span'
      sx={{
        display: "inline-block",
        ...sx,
      }}
    >
      {renderIf()
        .if(entityType === EntityType.Vehicle)
        .then(<VehicleLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.VehicleDamage)
        .then(
          <VehicleDamageLink
            vehicleId={undefined}
            entity={entity}
            entityId={entityId}
            {...linkProps}
          />,
        )
        .elseif(entityType === EntityType.Contract)
        .then(<ContractLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Accessory)
        .then(<AccessoryLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.AccessoryCheck)
        .then(<AccessoryCheckLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.VisualInspection)
        .then(<VisualInspectionLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.DamageDetection)
        .then(<DamageDetectionLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.DamageDetectionAggregate)
        .then(<DamageDetectionAggregateLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.DamageCostEvaluation)
        .then(<DamageCostEvaluationLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.DamageCostEvaluationAggregate)
        .then(
          <DamageCostEvaluationAggregateLink entity={entity} entityId={entityId} {...linkProps} />,
        )
        .elseif(entityType === EntityType.RepairOperation)
        .then(<RepairOperationLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Wash)
        .then(<WashLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.WheelOperation)
        .then(<WheelOperationLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Wash)
        .then(<WashLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Maintenance)
        .then(<MaintenanceLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Asset)
        .then(<AssetLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.AssetSubscriptionPlan)
        .then(<AssetSubscriptionPlanLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.AssetSubscription)
        .then(<AssetSubscriptionLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.TenantConnectionRequest)
        .then(<TenantConnectionRequestLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.TenantConnection)
        .then(<TenantConnectionLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.TenantRequest)
        .then(<TenantRequestLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Spot)
        .then(<SpotLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Customer)
        .then(<CustomerLink entity={entity} entityId={entityId} {...linkProps} />)
        .elseif(entityType === EntityType.Supplier)
        .then(<SupplierLink entity={entity} entityId={entityId} {...linkProps} />)
        .else(<FormHelperText error>Unknown entity {entityType}</FormHelperText>)
        .render()}
    </Box>
  );
}
