import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, RepairWorkDto, RepairWorkReferenceDto } from "@/core/api/generated";

import { RepairMaterialInlineProps } from "../RepairMaterial/RepairMaterialInline";
import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import RepairWorkInline from "./RepairWorkInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: RepairWorkDto | RepairWorkReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<RepairMaterialInlineProps>;
}

export default function RepairWorkLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])}
      to={ROUTE_PATH.REPAIR_WORK_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <RepairWorkInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair work"}
    />
  );
}
