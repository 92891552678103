import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { auth0Provider } from "@/common/auth0Provider";
import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { CustomAuth0AppState, authService } from "@/common/services/auth";
import { tenantService } from "@/common/services/tenant";

/** Auth0 redirects to this page after successful login. */
export default function Auth0CallbackHandler() {
  const isHandlingRef = useRef(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (isHandlingRef.current) {
        return;
      }

      try {
        // handle callback
        console.log("Auth0CallbackHandler. Start.");
        isHandlingRef.current = true;
        await authService.handleAuthenticatedStatusChange(undefined);
        const result = await auth0Provider.auth0Client?.handleRedirectCallback();
        const isAuthenticated = await auth0Provider.auth0Client?.isAuthenticated();
        console.log(
          "Auth0 callback result:",
          {
            isAuthenticated,
          },
          JSON.stringify(result, undefined, 4),
        );

        // await _authenticateSucceeded();
        await authService.handleAuthenticatedStatusChange(isAuthenticated);

        // handle custom appState
        const appState = result?.appState as CustomAuth0AppState;
        if (appState) {
          if (appState.redirectUrl) {
            console.log("Redirect to redirectUrl: ", appState.redirectUrl);
            UrlHelper.redirectToUrl(appState.redirectUrl);
            return;
          }

          if (appState.spaRedirectUrl) {
            // restore tenant
            const tenantInfo = tenantService.resolveTenant();
            if (appState.tenantIdentifier && appState.tenantIdentifier !== tenantInfo?.identifier) {
              let tenantUrl = tenantService.getTenantUrl(appState.tenantIdentifier, false);
              tenantUrl = UrlHelper.deleteUrlSearchParams(tenantUrl);
              const tempFullSpaRedirectUrl = `https://temp.com${appState.spaRedirectUrl}`;
              const pathname = UrlHelper.getUrlPathname(tempFullSpaRedirectUrl);
              const queryParams = UrlHelper.getUrlSearchParams(tempFullSpaRedirectUrl);
              let resultRedirectUrl = UrlHelper.addUrlPathname(tenantUrl, pathname);
              resultRedirectUrl = UrlHelper.updateUrlSearchParams(resultRedirectUrl, queryParams);
              console.log(
                `Redirect to spaRedirectUrl (for tenant ${appState.tenantIdentifier}): `,
                resultRedirectUrl,
              );
              UrlHelper.redirectToUrl(resultRedirectUrl);
              return;
            }

            // redirect via react router
            console.log("Redirect to spaRedirectUrl: ", appState.spaRedirectUrl);
            // localStorage.setItem("presavedSpaRedirect", appState.spaRedirectUrl);
            await history.push(appState.spaRedirectUrl);
            return;
          }
        }

        history.push("/");
      } catch (err) {
        console.error("Auth0 handle redirect callback error:", err);
        history.push("/");
      } finally {
        isHandlingRef.current = false;
      }
    })();
  }, []);

  return (
    <Box sx={{ m: "auto" }}>
      <CircularProgress size='50px' color='primary' />
    </Box>
  );
}
