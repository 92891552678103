import { TypographyProps } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import VehicleViewPageHeader from "@/common/components/Entity/Vehicle/View/Header/VehicleViewPageHeader";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  MaintenanceDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView from "../../components/BaseEntityView";
import MaintenanceMenu from "../MaintenanceMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum MaintenanceViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};
const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};
interface Props {
  maintenanceId: string | null | undefined;
  maintenance: MaintenanceDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

export default function MaintenanceView({
  maintenanceId,
  maintenance,
  displayProps,
  headerProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Maintenance),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const maintenanceRequest = useApiRequest(
    apiClient.maintenancesApi.apiV1MaintenancesMaintenanceIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      maintenanceId: maintenanceId!,
    },
    {
      deps: [maintenanceId],
      skip: !maintenanceId,
    },
  );
  maintenance = maintenance || maintenanceRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Maintenance, maintenanceId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      maintenanceRequest.handleEntityChanged(data);
    },
  });

  const [addToContractModalOpen, setAddToContractModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: maintenance && {
      idValue: maintenance?.id || "",
      newTitle: maintenance?.localNumber || "",
    },
  });

  return (
    <BaseEntityView
      entityType={EntityType.Maintenance}
      entityId={maintenanceId}
      entity={maintenance}
      entityRequest={maintenanceRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              maintenance?.vehicle && (
                <VehicleViewPageHeader
                  vehicleId={maintenance?.vehicle?.id || ""}
                  vehicle={maintenance?.vehicle || undefined}
                  withLink
                  tags={
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <GeneralAttachedTagsDisplay
                        tags={maintenance?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.Maintenance,
                          entityId: maintenance?.id,
                          onSaved: (newValue) => {
                            maintenanceRequest.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    </AuthorizedElement>
                  }
                />
              )
            }
            primaryActions={
              maintenance && (
                <MaintenanceMenu
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue
                      ? maintenanceRequest.replaceData(newValue)
                      : maintenanceRequest.refetch()
                  }
                  entity={maintenance}
                />
              )
            }
          />
        }
      >
        <PageTabs
          tabIdsDefinition={MaintenanceViewPageTabs}
          defaultTabId={MaintenanceViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: MaintenanceViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: MaintenanceViewPageTabs.Vehicle },
            { label: "History", value: MaintenanceViewPageTabs.GeneralHistory },
            { label: "Comments", value: MaintenanceViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            maintenance && (
              <>
                {activeTab === MaintenanceViewPageTabs.Overview && (
                  <OverviewTabContent
                    onUpdated={() => maintenanceRequest.refetch()}
                    maintenance={maintenance}
                  />
                )}
                {activeTab === MaintenanceViewPageTabs.Vehicle && (
                  <VehicleTabContent maintenance={maintenance} />
                )}
                {activeTab === MaintenanceViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent maintenance={maintenance} />
                )}
                {activeTab === MaintenanceViewPageTabs.Comments && (
                  <CommentsTabContent maintenance={maintenance} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
    </BaseEntityView>
  );
}
