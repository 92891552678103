import { useApiConnectionStatus } from "@/common/hooks/useApiConnectionStatus";

import ConnectionStatusIndicator from "./ConnectionStatusIndicator";

/** Displays status of API connection. */
function ApiConnectionStatusIndicator() {
  const status = useApiConnectionStatus({ withRecurringCheck: true });

  return <ConnectionStatusIndicator connected={status.isConnected} variant='icon' />;
}

export default ApiConnectionStatusIndicator;
