import {
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import Image from "@/common/components/Images/Image";
import ImagePlaceholder from "@/common/components/Images/ImagePlaceholder";
import SimpleCardListSkeleton from "@/common/components/Skeleton/SimpleCardListSkeleton";
import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { tenantService } from "@/common/services/tenant";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, LogoSizeType, TenantDto } from "@/core/api/generated";
import * as tenantsSlice from "@/store/tenants/slice";

import CreateTenantModal from "./CreateTenantModal";

export default function SelectTenantPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const currentTenant = useAppSelector((x) => x.tenants.currentTenant);

  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState(false);

  const tenantsRequest = useApiRequest(apiClient.profileApi.apiV1ProfileTenantsGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const tenants = tenantsRequest?.data;

  const handleSelectTenant = (selectedTenant: TenantDto) => {
    thunkDispatch(tenantsSlice.selectTenant(selectedTenant));

    const tenantInfo = tenantService.resolveTenant();
    if (tenantInfo?.identifier !== selectedTenant.identifier) {
      const url = tenantService.getTenantUrl(selectedTenant.identifier!);
      UrlHelper.redirectToUrl(UrlHelper.getUrlBasePath(url) + ROUTE_PATH.DASHBOARD);
    } else {
      // redirectToRoot();
      history.push("/");
    }
  };

  return (
    <Container maxWidth='md'>
      <Stack mt={1} spacing={2}>
        <Typography component='div' variant='h1'>
          Select company:
        </Typography>

        {tenantsRequest.isLoading && <SimpleCardListSkeleton itemCount={3} />}

        {!tenantsRequest.isLoading && tenants && (
          <>
            <List>
              {tenants?.map((tenant) => (
                <ListItem key={tenant.id!} disablePadding>
                  <ListItemButton onClick={() => handleSelectTenant(tenant)}>
                    <ListItemIcon>
                      {!tenant.branding?.logo && (
                        <ImagePlaceholder
                          of='tenant'
                          sx={{ width: 50, height: 50 }}
                          iconSx={{
                            width: 25,
                            height: 25,
                          }}
                        />
                      )}
                      {tenant.branding?.logo?.logos && (
                        <Image
                          src={
                            tenant?.branding?.logo?.logos?.find(
                              (e) => e.sizeType == LogoSizeType.Logo48,
                            )?.file?.url || undefined
                          }
                          sx={{
                            height: 50,
                            borderRadius: "6px",
                            objectFit: "contains",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={tenant.name} secondary={tenant.identifier} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            {createCompanyModalOpen && (
              <AuthorizedElement permissions={[AppPermission.TenantCreate]}>
                <CreateTenantModal
                  onCompanyCreated={() => tenantsRequest.refetch()}
                  open={createCompanyModalOpen}
                  onOpen={() => setCreateCompanyModalOpen(true)}
                  onClose={() => setCreateCompanyModalOpen(false)}
                  trigger={
                    <Button variant='outlined' sx={{ width: 200 }}>
                      Create new company
                    </Button>
                  }
                />
              </AuthorizedElement>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
}
