import { Alert, Dialog, DialogContent, DialogProps, FormHelperText, Stack } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  TenantRequestDto,
  TenantRequestOperationResultInputDto,
  TenantRequestOperationType,
} from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AccessoryCheckCreateUpdate from "../AccessoryCheck/AccessoryCheckCreateUpdate";
import DamageCostEvaluationCreateUpdate from "../DamageCostEvaluation/DamageCostEvaluationCreateUpdate";
import DamageDetectionCreateUpdate from "../DamageDetection/DamageDetectionCreateUpdate";
import MaintenanceCreateUpdate from "../Maintenance/MaintenanceCreateUpdate";
import RepairOperationCreateUpdate from "../RepairOperation/RepairOperationCreateUpdate";
import VisualInspectionCreateUpdate from "../VisualInspection/VisualInspectionCreateUpdate";
import WashCreateUpdate from "../Wash/WashCreateUpdate";
import WheelOperationCreateUpdate from "../WheelOperation/WheelOperationCreateUpdate";
import TenantRequestLink from "./TenantRequestLink";

export interface OwnProps {
  tenantRequest: TenantRequestDto;
  onSave?: (newValue: TenantRequestOperationResultInputDto) => void | Promise<void>;
}

type Props = OwnProps & DialogProps;

export default function TenantRequestCreateOperationModal({
  tenantRequest,
  onSave,
  ...dialogProps
}: Props) {
  const handleSave = async (newValue: Partial<TenantRequestOperationResultInputDto>) => {
    onSave &&
      (await onSave(
        _.merge(
          { operationType: tenantRequest?.content?.operation?.operationType },
          tenantRequest.result || {},
          newValue,
        ),
      ));
  };

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Create new{" "}
        <strong>
          <InlineApiEnumValue
            type='TenantRequestOperationType'
            value={tenantRequest.content?.operation?.operationType}
            withDescription={false}
          />
        </strong>
      </AppModalTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Alert severity='info'>
            You are creating a new{" "}
            <strong>
              <InlineApiEnumValue
                type='TenantRequestOperationType'
                value={tenantRequest.content?.operation?.operationType}
                withDescription={false}
              />
            </strong>{" "}
            operation for the company request{" "}
            <strong>
              <TenantRequestLink entity={tenantRequest} />
            </strong>
            .
          </Alert>

          <Box sx={{ py: 1 }}>
            {renderIf()
              .if(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.VisualInspection,
              )
              .then(
                <VisualInspectionCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.visualInspection?.vehicleId,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ visualInspection: { visualInspectionId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.DamageDetection,
              )
              .then(
                <DamageDetectionCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.damageDetection?.vehicleId,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ damageDetection: { damageDetectionId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.DamageCostEvaluation,
              )
              .then(
                <DamageCostEvaluationCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.damageCostEvaluation?.vehicleId,
                    vehicleDamageIds:
                      tenantRequest.content?.operation?.damageCostEvaluation?.vehicleDamageIds,
                    currency: tenantRequest.content?.operation?.damageCostEvaluation?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ damageCostEvaluation: { damageCostEvaluationId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.AccessoryCheck,
              )
              .then(
                <AccessoryCheckCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.accessoryCheck?.vehicleId,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ accessoryCheck: { accessoryCheckId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.RepairOperation,
              )
              .then(
                <RepairOperationCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.repairOperation?.vehicleId,
                    vehicleDamageIds:
                      tenantRequest.content?.operation?.repairOperation?.vehicleDamageIds,
                    currency: tenantRequest.content?.operation?.repairOperation?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ repairOperation: { repairOperationId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType === TenantRequestOperationType.Wash,
              )
              .then(
                <WashCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.wash?.vehicleId,
                    currency: tenantRequest.content?.operation?.wash?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) => handleSave({ wash: { washId: newValue.id } })}
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.WheelOperation,
              )
              .then(
                <WheelOperationCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.wheelOperation?.vehicleId,
                    currency: tenantRequest.content?.operation?.wheelOperation?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) =>
                    handleSave({ wheelOperation: { wheelOperationId: newValue.id } })
                  }
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType === TenantRequestOperationType.Wash,
              )
              .then(
                <WashCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.wash?.vehicleId,
                    currency: tenantRequest.content?.operation?.wash?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) => handleSave({ wash: { washId: newValue.id } })}
                />,
              )
              .elseif(
                tenantRequest.content?.operation?.operationType ===
                  TenantRequestOperationType.Maintenance,
              )
              .then(
                <MaintenanceCreateUpdate
                  defaultValues={{
                    vehicleId: tenantRequest.content?.operation?.maintenance?.vehicleId,
                    currency: tenantRequest.content?.operation?.maintenance?.currency,
                    tenantRequestsMeta: {
                      tenantRequestIds: [tenantRequest.id!],
                    },
                  }}
                  onSave={(newValue) => handleSave({ maintenance: { maintenanceId: newValue.id } })}
                />,
              )
              .else(
                <FormHelperText error>
                  <InlineApiEnumValue
                    type='TenantRequestType'
                    value={tenantRequest?.content?.type}
                  />{" "}
                  not supported yet!
                </FormHelperText>,
              )
              .render()}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
