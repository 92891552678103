import { createContext, useContext } from "react";

import { GeneralScopeDto } from "@/core/api/generated";

import { TypedEventEmitter } from "../eventEmmiters/typedEventEmitter";

export type ChatContextEventsMap = {
  // list of supported events
  scrollToMessageInputRequested: undefined;
  scrollToMessageInputAndFocusRequested: undefined;
  createTagByScopeInMessageInputRequested: {
    scope: GeneralScopeDto | null | undefined;
    isScrollToInput?: boolean;
    isFocusInput?: boolean;
  };
};

class ChatContextValue extends TypedEventEmitter<ChatContextEventsMap> {
  public requestScrollToMessageInput(): void {
    this.emit("scrollToMessageInputRequested", undefined);
  }

  public requestScrollToMessageInputAndFocus(): void {
    this.emit("scrollToMessageInputAndFocusRequested", undefined);
  }

  public requestCreateTagByScopeInMessageInput(
    params: ChatContextEventsMap["createTagByScopeInMessageInputRequested"],
  ): void {
    this.emit("createTagByScopeInMessageInputRequested", params);
  }
}

export type ChatContextType = ChatContextValue;

export const chatContextValue = new ChatContextValue();

export const ChatContext = createContext<ChatContextType | null>(null);

export const useChatContext = (): ChatContextType => {
  const chatContext = useContext(ChatContext);

  if (!chatContext) {
    throw new Error("useChatContextRequired() has to be used within <ChatContext.Provider>");
  }

  return chatContext;
};

export const useChatContextOptional = (): ChatContextType | null => {
  const chatContext = useContext(ChatContext);
  return chatContext;
};
