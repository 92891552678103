import { GridSortDirection, GridSortModel } from "@mui/x-data-grid";

import { SortDefinitionDto, SortOrder } from "@/core/api/generated";

import { SortDefinition } from "../sorting/sortDefinition";
import { SortDefinitionItem } from "../sorting/sortDefinitionItem";
import { TypeHelper } from "./type";

export class SortHelper {
  public static parseSortOrder(
    sortOrder: string | GridSortDirection | null | undefined,
  ): SortOrder | undefined {
    switch (sortOrder) {
      case "asc":
        return SortOrder.Asc;
      case "desc":
        return SortOrder.Desc;
      case null:
      case undefined:
      default:
        return undefined;
    }
  }

  public static mapGridSortDirectionToSortOrder(
    sortDirection: GridSortDirection,
  ): SortOrder | undefined {
    return this.parseSortOrder(sortDirection);
  }

  public static mapSortOrderToGridSortDirection(
    sortOrder: SortOrder,
  ): GridSortDirection | undefined {
    switch (sortOrder) {
      case SortOrder.Asc:
        return "asc";
      case SortOrder.Desc:
        return "desc";
      case null:
      case undefined:
      default:
        return undefined;
    }
  }

  public static mapGridSortModelToSortDefinition(
    source: GridSortModel | undefined,
  ): SortDefinition | undefined {
    return source && !TypeHelper.isEmpty(source)
      ? new SortDefinition({
          items: source.map(
            (x) =>
              new SortDefinitionItem({
                field: x.field,
                sortOrder: this.mapGridSortDirectionToSortOrder(x.sort) ?? SortOrder.Asc,
              }),
          ),
        })
      : undefined;
  }

  public static mapSortDefinitionToGridSortModel(
    source: SortDefinition | undefined,
  ): GridSortModel {
    return source && source.isValid
      ? source.items.map((x) => ({
          field: x.field,
          sort: this.mapSortOrderToGridSortDirection(x.sortOrder ?? SortOrder.Asc),
        }))
      : [];
  }

  public static mapSortDefinitionToSortDefinitionDto(
    source: SortDefinition | undefined,
  ): SortDefinitionDto | undefined {
    if (!source || !source.isValid) {
      return undefined;
    }

    source = source.clone(); // clone to avoid modifying objects in use
    source.formatBeforeJsonSerialization();

    return {
      sortBy: source.items?.map((x) => x.field).join(","),
      sortOrder: source.items?.map((x) => x.sortOrder).join(","),
    };
  }
}
