import { Box, BoxProps, SxProps, Theme, styled } from "@mui/material";
import _ from "lodash";
import { forwardRef } from "react";

type WrapperProps = Pick<StrikethroughTextProps, "lineProps" | "multiline">;

const getStyleValue = (theme: Theme, lineProps?: LineProps): LineStyle =>
  (lineProps?.style &&
    (_.isFunction(lineProps?.style) ? lineProps?.style(theme) : lineProps?.style)) ||
  "solid";

const getColorValue = (theme: Theme, lineProps?: LineProps): string =>
  (lineProps?.color &&
    (_.isFunction(lineProps?.color) ? lineProps?.color(theme) : lineProps?.color)) ||
  theme.palette.divider;

const getThicknessValue = (theme: Theme, lineProps?: LineProps): number | string =>
  (lineProps?.thickness &&
    (_.isFunction(lineProps?.thickness) ? lineProps?.thickness(theme) : lineProps?.thickness)) ||
  "2px";

const DefaultWrapper = styled(Box)<WrapperProps>(({ theme, lineProps, multiline }) => ({
  textDecoration: "line-through",
  textDecorationStyle: getStyleValue(theme, lineProps),
  textDecorationColor: getColorValue(theme, lineProps),
  textDecorationThickness: getThicknessValue(theme, lineProps),
}));

const StrikeWrapper = styled(Box)<WrapperProps>(({ theme, lineProps, multiline }) => ({
  ...((!multiline && {
    position: "relative",
    // display: "inline-block",

    "&::before": {
      content: '""',
      borderBottomStyle: getStyleValue(theme, lineProps),
      borderBottomColor: getColorValue(theme, lineProps),
      borderBottomWidth: getThicknessValue(theme, lineProps),
      width: "100%",
      position: "absolute",
      right: "0",
      top: "50%",
    },
  }) ||
    {}),

  ...((multiline && {}) || {}),
}));

const CrossSingleWrapper = styled(Box)<WrapperProps>(({ theme, lineProps, multiline }) => ({
  position: "relative",
  // display: "inline-block",

  "&::before": {
    content: '""',
    width: "110%",
    position: "absolute",
    right: "-5%",
    top: "50%",

    borderBottomStyle: getStyleValue(theme, lineProps),
    borderBottomColor: getColorValue(theme, lineProps),
    borderBottomWidth: getThicknessValue(theme, lineProps),
    transform: "skewY(-10deg)",
  },
}));

const CrossDoubleWrapper = styled(Box)<WrapperProps>(({ theme, lineProps, multiline }) => ({
  position: "relative",
  // display: "inline-block",

  "&::before, &::after": {
    content: '""',
    width: "110%",
    position: "absolute",
    right: "-5%",
    top: "50%",
  },
  "&::before": {
    borderBottomStyle: getStyleValue(theme, lineProps),
    borderBottomColor: getColorValue(theme, lineProps),
    borderBottomWidth: getThicknessValue(theme, lineProps),
    transform: "skewY(-10deg)",
  },
  "&::after": {
    borderBottomStyle: getStyleValue(theme, lineProps),
    borderBottomColor: getColorValue(theme, lineProps),
    borderBottomWidth: getThicknessValue(theme, lineProps),
    transform: "skewY(10deg)",
  },
}));

type LineStyle = "dashed" | "dotted" | "double" | "solid";

interface LineProps {
  style?: LineStyle;
  color?: string | ((theme: Theme) => string);
  thickness?: number | string;
}

interface StrikethroughTextProps extends BoxProps {
  enabled?: boolean;
  variant?: "strike" | "crossSingle" | "crossDouble";
  lineProps?: LineProps;
  multiline?: boolean;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | string;
}

/** Displays striked through (crossed out, striked) text.
 * Based on - https://www.tjvantoll.com/2013/09/12/building-custom-text-strikethroughs-with-css/
 */
export default forwardRef<HTMLSpanElement, StrikethroughTextProps>(function StrikethroughText(
  {
    enabled = true,
    variant = "strike",
    lineProps,
    multiline = false,
    sx,
    children,
    ...boxProps
  }: StrikethroughTextProps,
  ref,
) {
  const Wrapper =
    (multiline && DefaultWrapper) ||
    (variant == "strike" && StrikeWrapper) ||
    (variant == "crossSingle" && CrossSingleWrapper) ||
    (variant == "crossDouble" && CrossDoubleWrapper) ||
    DefaultWrapper;

  return enabled ? (
    <Wrapper
      ref={ref}
      // component='span'
      {...boxProps}
      lineProps={lineProps}
      multiline={multiline}
      sx={{
        ...sx,
      }}
    >
      {children}
    </Wrapper>
  ) : (
    children
  );
});
