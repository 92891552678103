import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useMemo } from "react";

import Image from "@/common/components/Images/Image";
import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import { ThumbnailSizeType } from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import AppIcon from "../Icons/AppIcon";
import AttachmentCaptionInput from "./AttachmentCaptionInput";
import FileIcon from "./FileIcon";

export interface FileItemViewActions {
  enabled: boolean;
  click?: boolean;
  edit?: boolean;
  download?: boolean;
  openInNewTab?: boolean;
  remove?: boolean;
  setCaption?: boolean;
  onClick?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
  onSetCaption?: (caption: string | undefined) => void;
}

export interface FileItemViewProps {
  fileItem: FileItem;
  size?: {
    width?: number | "auto" | "unset";
    height?: number | "auto" | "unset";
  };
  useThumbnail?: boolean;
  thumbnailSizeType?: ThumbnailSizeType;
  thumbnailSize?: IImageSize;
  fullWidth?: boolean;
  withCaption?: boolean;
  sx?: SxProps<Theme>;
  imageSx?: SxProps<Theme>;
  actions?: FileItemViewActions;
}

export default function FileItemView({
  fileItem,
  size = { width: undefined, height: undefined },
  useThumbnail = false,
  thumbnailSizeType,
  thumbnailSize,
  actions,
  fullWidth,
  withCaption = true,
  sx,
  imageSx,
}: FileItemViewProps) {
  const actionsComputed = useMemo(
    () => (_.isFunction(actions) ? actions(fileItem) : actions),
    [actions],
  );

  const fileType = FileHelper.getFileType(fileItem.mimeType);
  const canShowActions = fileItem.isUploading || actionsComputed?.enabled;
  const canShowThumbnail = useThumbnail && fileItem.hasThumbnails;
  const thumbnailUrl = canShowThumbnail
    ? fileItem.getThumbnailUrl({
        sizeType: thumbnailSizeType,
        minSize: thumbnailSize,
        maxSize:
          _.isInteger(size?.width) || _.isInteger(size?.height)
            ? {
                width: _.isInteger(size?.width) ? (size!.width! as number) : undefined,
                height: _.isInteger(size?.height) ? (size!.height! as number) : undefined,
              }
            : undefined,
      })
    : undefined;

  const handleEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      actionsComputed?.onEdit && actionsComputed?.onEdit(fileItem);
    },
    [actionsComputed, fileItem],
  );

  const handleDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (fileItem.blob) {
        FileHelper.downloadBlob(fileItem.blob, fileItem.blob.name);
      } else if (fileItem.file) {
        FileHelper.downloadFileByUrl(
          fileItem.file.url!,
          fileItem.file.originalFileName || fileItem.file.fileName!,
        );
      }
    },
    [fileItem],
  );

  const handleOpenInNewTab = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (fileItem.fileUrl && fileItem.fileName) {
        FileHelper.openFileByUrl(fileItem.fileUrl, fileItem.fileName);
      }
    },
    [fileItem],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      actionsComputed?.click && actionsComputed?.onClick && actionsComputed.onClick(fileItem);
    },
    [actionsComputed, fileItem],
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      actionsComputed?.onRemove && actionsComputed?.onRemove(fileItem);
    },
    [actionsComputed, fileItem],
  );

  const handleSetCaption = useCallback(
    (caption: string | undefined) => {
      actionsComputed?.onSetCaption && actionsComputed?.onSetCaption(caption);
    },
    [actionsComputed, fileItem],
  );

  return (
    <AppTooltip
      title={withCaption && fileItem.attachment?.caption ? fileItem.attachment.caption : undefined}
    >
      <Box
        sx={{
          position: "relative",
          width: fileType.isImage ? size.width : "auto",
          height: fileType.isImage ? size.height : "auto",
          minHeight: "30px",
          minWidth: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: fileType.isImage ? (theme) => theme.shape.borderRadius : "30px",
          borderColor: (theme) =>
            fileItem.validation ? theme.palette.error.main : theme.palette.divider,
          "&:hover": {
            "& .controls": {
              visibility: "visible",
            },
            "& .validationControls": {
              visibility: "visible",
            },
          },
          cursor: actionsComputed?.click ? "pointer" : "auto",
          overflow: "hidden",
          ...(sx || {}),
        }}
        onClick={actionsComputed?.click ? (e) => handleClick(e) : undefined}
      >
        {/* Thumbnail */}
        {canShowThumbnail && (
          <Image sx={{ width: "unset", ...(imageSx || {}) }} src={thumbnailUrl} />
        )}

        {/* Image */}
        {!canShowThumbnail && fileType.isImage && (
          <Image sx={{ width: "unset", ...(imageSx || {}) }} src={fileItem.fileUrl} />
        )}

        {/* Other files */}
        {!canShowThumbnail && !fileType.isImage && (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              py: 1.5,
              px: 2.0,
              alignItems: "center",
              maxWidth: fullWidth ? "unset" : "250px",
              height: "100%",
              overflow: "hidden",
              backgroundColor: "background.default",
              borderRadius: "30px",
            }}
          >
            <FileIcon mimeType={fileItem.mimeType} fontSize='medium' />
            <Stack direction='row' spacing={2} sx={{ overflow: "hidden", alignItems: "center" }}>
              <Tooltip title={fileItem.fileName || ""} placement='top'>
                <Typography
                  component='span'
                  variant='body1'
                  color='text.secondary'
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {fileItem.fileName}
                </Typography>
              </Tooltip>

              {canShowActions && (
                <>
                  {fileItem.isUploading && (
                    <Tooltip title='Cancel upload' placement='top'>
                      <IconButton
                        sx={{ padding: "2px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          fileItem.abortUpload && fileItem.abortUpload();
                        }}
                      >
                        <ClearIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        )}

        {/* Errors */}
        {fileItem.validation && (
          <Stack
            className='validationControls'
            sx={{
              visibility: "collapse",
              position: "absolute",
              left: 5,
              top: 3,
              transform: "scale(1) translate(0%, 0%)",
              borderRadius: (t) => t.shapeCustom.borderRadiusButton,
              backgroundColor: (theme) => theme.palette.background.default,
            }}
            direction='row'
            spacing='4px'
          >
            <Tooltip title={`Upload failed. ${fileItem.validation?.generalError}`} placement='top'>
              <IconButton
                sx={{ p: 0, m: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  fileItem.showErrorDetails && fileItem.showErrorDetails(fileItem.validation!);
                }}
              >
                <AppIcon of='error' color='error' fontSize='medium' />
              </IconButton>
            </Tooltip>

            {fileItem.retryUpload && (
              <Tooltip title='Re-try uploading' placement='top'>
                <IconButton
                  sx={{ p: 0, m: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    fileItem.retryUpload && fileItem.retryUpload();
                  }}
                >
                  <AppIcon of='replay' fontSize='medium' />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}

        {/* Actions */}
        {canShowActions && (
          <Stack
            className='controls'
            sx={{
              visibility: fileItem.isUploading ? "visible" : "collapse",
              display: "flex",
              position: "absolute",
              top: 0,
              right: 0,
              transform: "scale(1) translate(0%, 0%)",
              borderRadius: "4px",
              padding: "2px",
              pt: "4px",
              pr: "4px",
              backgroundColor: (theme) => theme.palette.background.default,
            }}
            direction='row'
            spacing='4px'
          >
            {fileItem.isUploading && (
              <>
                <Stack alignItems='center' justifyContent='center'>
                  <CircularProgress size={16} />
                </Stack>
                <Tooltip title='Cancel upload' placement='top'>
                  <IconButton
                    sx={{ padding: "2px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      fileItem.abortUpload && fileItem.abortUpload();
                    }}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </>
            )}

            {!fileItem.isUploading && (
              <>
                {actionsComputed?.edit && (
                  <Tooltip title='Edit' placement='top'>
                    <IconButton size='extraSmall' onClick={handleEdit}>
                      <AppIcon of='edit' />
                    </IconButton>
                  </Tooltip>
                )}
                {actionsComputed?.download && (
                  <Tooltip title='Download' placement='top'>
                    <IconButton size='extraSmall' onClick={handleDownload}>
                      <AppIcon of='download' />
                    </IconButton>
                  </Tooltip>
                )}
                {actionsComputed?.openInNewTab && (
                  <Tooltip title='Open in new tab' placement='top'>
                    <IconButton size='extraSmall' onClick={handleOpenInNewTab}>
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {actionsComputed?.setCaption &&
                  (fileItem.attachment || fileItem.attachmentInput) && (
                    <AttachmentCaptionInput
                      key={fileItem.id}
                      defaultCaption={fileItem.caption}
                      tooltipProps={{ enabled: true, title: "Set caption", placement: "top" }}
                      onSetCaption={(value) => {
                        handleSetCaption(value);
                      }}
                    />
                  )}
                {actionsComputed?.remove && (
                  <Tooltip title='Remove' placement='top'>
                    <IconButton size='extraSmall' onClick={handleRemove}>
                      <AppIcon of='clear' />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Stack>
        )}
      </Box>
    </AppTooltip>
  );
}
