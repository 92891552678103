import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function EditIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0139 8.00913L9.99268 5.98788L11.4537 4.52613L13.4742 6.54663L12.0139 8.00913ZM6.80968 13.2186L4.57693 13.4219L4.77493 11.2049L8.98768 6.99213L11.0097 9.01413L6.80968 13.2186ZM14.5527 5.50338L14.5519 5.50263L12.4984 3.44913C11.9427 2.89488 10.9879 2.86863 10.4614 3.39738L3.71443 10.1444C3.46993 10.3881 3.31843 10.7121 3.28693 11.0549L3.00268 14.1824C2.98318 14.4036 3.06193 14.6226 3.21943 14.7801C3.36118 14.9219 3.55243 14.9999 3.74968 14.9999C3.77293 14.9999 3.79543 14.9991 3.81793 14.9969L6.94543 14.7126C7.28893 14.6811 7.61218 14.5304 7.85593 14.2866L14.6037 7.53888C15.1497 6.99138 15.1264 6.07788 14.5527 5.50338Z'
      />
      <mask
        id='mask0_215_6333'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='3'
        width='13'
        height='12'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0139 8.00913L9.99268 5.98788L11.4537 4.52613L13.4742 6.54663L12.0139 8.00913ZM6.80968 13.2186L4.57693 13.4219L4.77493 11.2049L8.98768 6.99213L11.0097 9.01413L6.80968 13.2186ZM14.5527 5.50338L14.5519 5.50263L12.4984 3.44913C11.9427 2.89488 10.9879 2.86863 10.4614 3.39738L3.71443 10.1444C3.46993 10.3881 3.31843 10.7121 3.28693 11.0549L3.00268 14.1824C2.98318 14.4036 3.06193 14.6226 3.21943 14.7801C3.36118 14.9219 3.55243 14.9999 3.74968 14.9999C3.77293 14.9999 3.79543 14.9991 3.81793 14.9969L6.94543 14.7126C7.28893 14.6811 7.61218 14.5304 7.85593 14.2866L14.6037 7.53888C15.1497 6.99138 15.1264 6.07788 14.5527 5.50338Z'
        />
      </mask>
      <g mask='url(#mask0_215_6333)'>
        <rect width='18' height='18' />
      </g>
    </SvgIcon>
  );
});
