import { useMemo } from "react";

import { ChatParticipantDto } from "@/core/api/generated";

import { useAppSelector } from "../redux";

/** Returns loaded chat participants from the store. */
export const useChatParticipants = (
  chatId?: string | null,
): {
  participants: ChatParticipantDto[];
  participantsMap: Partial<Record<string, ChatParticipantDto>>;
} => {
  const participants = useAppSelector(
    (x) => x.communication.chatParticipants.paginatedChatParticipantMap[chatId || ""]?.items || [],
  );

  const participantsMap = useMemo(() => {
    const map: Partial<Record<string, ChatParticipantDto>> = {};
    if (chatId && participants) {
      participants.forEach((p) => {
        map[p.id!] = p;
      });
    }
    return map;
  }, [participants]);

  return {
    participants,
    participantsMap,
  };
};
