import { IconButton, IconButtonProps, Stack, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { GridSortDirection, GridSortItem } from "@mui/x-data-grid";
import { useState } from "react";

import AppIcon, { AppIconProps } from "../Icons/AppIcon";
import AppTypography from "../Text/AppTypography";
import { DataListColumnModel, DataListHeaderVariant, DataListValidRowModel } from "./DataList";

interface Props<TItem extends DataListValidRowModel> {
  column: DataListColumnModel<TItem>;
  headerVariant?: DataListHeaderVariant;
  showMenuButton?: boolean;
  showSortButton?: boolean;
  sortDirection?: GridSortDirection;
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  onSortModelChange?: (sortModel: GridSortItem | undefined) => void;
}

export default function DataHeaderColumnLabel<TItem extends DataListValidRowModel>({
  column,
  headerVariant,
  children,
  showMenuButton = true,
  showSortButton = true,
  sortDirection,
  onSortModelChange = () => {},
  sx,
}: Props<TItem>) {
  const iconButtonSx: SxProps<Theme> = {
    ...(headerVariant === "compact" && {
      fontSize: "small",
      p: 0.25,
    }),
  };
  const iconButtonCommonProps: Partial<IconButtonProps> = {
    size: "small",
  };
  const iconCommonProps: Partial<AppIconProps> = {
    fontSize: "inherit",
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Stack
      direction='row'
      spacing={0}
      alignItems='center'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        height: "100%",
        justifyContent:
          (column.horizontalAlign === "start" && "flex-start") ||
          (column.horizontalAlign === "center" && "center") ||
          (column.horizontalAlign === "end" && "flex-end") ||
          undefined,
        textAlign:
          (column.horizontalAlign === "start" && "left") ||
          (column.horizontalAlign === "center" && "center") ||
          (column.horizontalAlign === "end" && "right") ||
          undefined,
        ...sx,
      }}
    >
      <AppTypography
        ellipsing={{ enabled: headerVariant !== "compact" }}
        sx={{
          minWidth: 0,
          overflow: "hidden",
          display: "block",
          ...(headerVariant !== "compact" && {
            lineHeight: "inherit",
            fontSize: "inherit",
            fontWeight: 600,
          }),
          ...(headerVariant === "compact" && {
            fontWeight: 600,
            color: (th) => th.palette.text.secondary,
          }),
          mr: 0.5,
        }}
        variant={headerVariant === "compact" ? "caption" : undefined}
      >
        {children}
      </AppTypography>

      {showSortButton && (isHovered || !!sortDirection) && (
        <>
          {!sortDirection && (
            <IconButton
              sx={iconButtonSx}
              {...iconButtonCommonProps}
              onClick={(_e) => {
                onSortModelChange({ field: column.field, sort: "asc" });
              }}
            >
              <AppIcon {...iconCommonProps} color='disabled' of='arrowUpward' />
            </IconButton>
          )}
          {sortDirection === "asc" && (
            <IconButton
              sx={iconButtonSx}
              {...iconButtonCommonProps}
              onClick={(_e) => {
                onSortModelChange({ field: column.field, sort: "desc" });
              }}
            >
              <AppIcon {...iconCommonProps} of='arrowUpward' />
            </IconButton>
          )}
          {sortDirection === "desc" && (
            <IconButton
              sx={iconButtonSx}
              {...iconButtonCommonProps}
              onClick={(_e) => {
                onSortModelChange(undefined);
              }}
            >
              <AppIcon {...iconCommonProps} of='arrowDownward' />
            </IconButton>
          )}
        </>
      )}

      {/* {showMenuButton && isHovered && (
        <>
          <MenuWithTrigger
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            trigger={
              <IconButton sx={iconButtonSx} {...iconButtonCommonProps}>
                <AppIcon {...iconCommonProps} of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => {
              return (
                <MenuItem
                  sx={{ minWidth: 250 }}
                  onClick={() => {
                    openFilterPanel(column);
                    handleClose();
                  }}
                >
                  <ListItemText>Filter</ListItemText>
                </MenuItem>
              );
            }}
          </MenuWithTrigger>
        </>
      )} */}
    </Stack>
  );
}
