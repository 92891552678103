import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, AssessmentFlowDto, AssessmentFlowReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssessmentFlowInline, { AssessmentFlowInlineProps } from "./AssessmentFlowInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: AssessmentFlowDto | AssessmentFlowReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<AssessmentFlowInlineProps>;
}

export default function AssessmentFlowLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.FlowRead])}
      to={ROUTE_PATH.ASSESSMENT_FLOW_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <AssessmentFlowInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Assessment flow"}
    />
  );
}
