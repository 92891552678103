import { Box, Stack } from "@mui/material";

import { TypeHelper } from "@/common/helpers/type";
import { FileHashDto } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import FieldValue from "../../Form/Display/FieldValue";
import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import AppTypography from "../../Text/AppTypography";

interface Props {
  value: FileHashDto | undefined | null;
}

export default function FileHashInlineDisplay({ value }: Props) {
  if (!value) {
    return null;
  }

  return (
    <Box>
      <AppPopover
        hoverBehavior={{}}
        trigger={<AppTypography decoration={{ variant: "helpText" }}>{value.hash}</AppTypography>}
      >
        <AppPopoverContent>
          <Stack spacing={1}>
            <FieldValue label='Algorithm' isEmpty={TypeHelper.isEmpty(value.algorithmType)}>
              <InlineApiEnumValue type='HashAlgorithmType' value={value.algorithmType} />
            </FieldValue>

            <FieldValue label='Hash' isEmpty={TypeHelper.isEmpty(value.hash)}>
              {value.hash}
            </FieldValue>

            <FieldValue label='Created at' isEmpty={TypeHelper.isEmpty(value.createdAt)}>
              <Datetime datetime={value.createdAt} withDurationFromNow />
            </FieldValue>
          </Stack>
        </AppPopoverContent>
      </AppPopover>
    </Box>
  );
}
