import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import {
  IBaseSelectItem,
  ItemToSelectOptionFunc,
  ItemsToSelectOptionsFunc,
  SelectOption,
} from "@/common/ts/select";

import { AppMenuItemProps } from "../../Menu/AppMenuItem";

export interface BaseSelectProps<TItem extends IBaseSelectItem> {
  /** Selected itemId or item. */
  value: TItem | string | null | undefined;
  /** All items. */
  items: TItem[] | null | undefined;
  itemToOption: ItemToSelectOptionFunc<TItem>;
  /** Must render <li><li/> */
  renderOption: (props: AppMenuItemProps, option: SelectOption<TItem>) => ReactNode;
  renderValue?: (option: SelectOption<TItem> | null | undefined) => ReactNode;
  renderValueProps?: {
    direction?: "row" | "column";
  };
  label: SelectProps["label"];
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  margin?: FormControlProps["margin"];
  placeholder?: TextFieldProps["placeholder"];
  helperText?: TextFieldProps["helperText"];
  formControlProps?: Partial<FormControlProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  selectProps?: Partial<SelectProps>;
  sx?: FormControlProps["sx"];
  onChange?: (newItem?: TItem) => void;
}

/** Interface to be used by wrapper components that utilize this component. */
export type BaseItemSelectInheritableProps<TItem extends IBaseSelectItem> = Pick<
  BaseSelectProps<TItem>,
  | "value"
  | "items"
  | "renderValueProps"
  | "label"
  | "disabled"
  | "required"
  | "error"
  | "size"
  | "fullWidth"
  | "margin"
  | "placeholder"
  | "helperText"
  | "formControlProps"
  | "inputLabelProps"
  | "selectProps"
  | "sx"
  | "onChange"
>;

export default function BaseSelect<TItem extends IBaseSelectItem>({
  value,
  items,
  itemToOption,
  renderOption,
  renderValue,
  renderValueProps,
  label,
  disabled,
  required,
  error,
  size,
  fullWidth = true,
  margin,
  placeholder,
  helperText,
  formControlProps,
  inputLabelProps,
  selectProps,
  sx,
  onChange,
}: BaseSelectProps<TItem>) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<readonly SelectOption<TItem>[] | null>([]);
  const [selectedOption, setSelectedOption] = useState<SelectOption<TItem> | null>(null);

  const itemIdComputed = useMemo(
    () => (_.isString(value) && !_.isEmpty(value) ? value : undefined),
    [value],
  );
  const itemComputed = useMemo(
    () => (_.isObject(value) && !_.isNil(value) ? (value as TItem) : undefined),
    [value],
  );
  const optionsMap = useMemo(
    () =>
      _.chain(options)
        .keyBy((x) => x.id)
        .mapValues((x) => x)
        .value(),
    [options],
  );

  const itemsToOptions = useMemo<ItemsToSelectOptionsFunc<TItem>>(
    () => (items2) => (items2 && items2.map((x) => itemToOption(x))) || [],
    [itemToOption],
  );

  // create options fom items
  useEffectWithDeepCompare(() => {
    setOptions(!_.isNil(items) ? itemsToOptions(items) : null);
  }, [items]);

  useEffect(() => {
    if (!itemComputed && !itemIdComputed) {
      setSelectedOption(null);
    } else if (itemComputed && selectedOption?.id !== itemComputed.id) {
      setOptions(_.uniqBy([itemToOption(itemComputed), ...(options || [])], (x) => x.id));
      setSelectedOption(itemToOption(itemComputed));
    } else if (itemIdComputed && selectedOption?.id !== itemIdComputed) {
      setSelectedOption(options?.find((x) => x.id === itemIdComputed) || null);
    }
  }, [itemIdComputed, itemComputed, selectedOption, options]);

  return (
    <FormControl
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      error={error}
      size={size}
      margin={margin}
      {...formControlProps}
      // sx={{ ...sx, ...formControlProps?.sx }}
    >
      <InputLabel required={required} disabled={disabled} error={error} {...inputLabelProps}>
        {label ?? selectProps?.label}
      </InputLabel>

      <Select
        {...selectProps}
        label={label ?? selectProps?.label}
        required={required ?? selectProps?.required}
        disabled={disabled ?? selectProps?.disabled}
        error={error ?? selectProps?.error}
        fullWidth={fullWidth ?? selectProps?.fullWidth}
        size={size ?? selectProps?.size}
        placeholder={placeholder ?? selectProps?.placeholder}
        native={false}
        open={isOpen}
        value={selectedOption?.id || ""}
        onChange={(e) => {
          const newOptionId = e.target.value?.toString();
          const newOption = optionsMap[newOptionId || ""];
          setSelectedOption(newOption || null);
          onChange && onChange(newOption?.data || undefined);
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        renderValue={(selectedValue) => {
          const selectedOptionId = selectedValue?.toString() || undefined;
          const option = optionsMap[selectedOptionId || ""];

          const direction = renderValueProps?.direction || "row";

          return !_.isNil(renderValue) ? (
            renderValue(option)
          ) : (
            <Stack
              direction={direction}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                ...(direction === "row" && {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }),
                ...(direction === "column" && {
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }),
                gap: 1,
              }}
            >
              {renderOption(
                {
                  sx: { p: 0, m: 0 },
                  component: "div",
                  disableAllEffects: true,
                },
                option,
              )}
            </Stack>
          );
        }}
      >
        {!required && !selectProps?.required && (
          <MenuItem value=''>
            <em>None</em>
          </MenuItem>
        )}

        {options?.map((option, i) => {
          return renderOption({ key: i, value: option.id, sx: {} }, option);
        })}
      </Select>

      {helperText && (
        <FormHelperText disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
