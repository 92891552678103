import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { EntityType, VehicleDto } from "@/core/api/generated";

interface Props {
  vehicle: VehicleDto;
}

export default function CommentsTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat entityId={vehicle.id!} entityType={EntityType.Vehicle} />
      </ViewContentBlock>
    </PageTabContent>
  );
}
