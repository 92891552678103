import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import { TextHelper } from "@/common/helpers/text";
import { TypeHelper } from "@/common/helpers/type";
import { SortDefinition } from "@/common/sorting/sortDefinition";
import { SortDefinitionItem } from "@/common/sorting/sortDefinitionItem";
import { SortSpec } from "@/common/sorting/sortSpec";
import { SortOrder } from "@/core/api/generated";

import NoDataAlert from "../AppAlerts/NoDataAlert";
import AppIconButton from "../Button/AppIconButton";
import DropdownButton from "../Button/DropdownButton";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";
import AppIcon from "../Icons/AppIcon";

const defaultMaxSortItems = 3;

export interface SortDefinitionInputProps {
  sortSpec: SortSpec;
  /** If undefined is passed the input is uncontrolled. */
  sortDefinition: SortDefinition | undefined;
  maxSortItems?: number;
  sx?: SxProps<Theme>;
  onChange: (newValue: SortDefinition | undefined) => void;
}

export default function SortDefinitionInput({
  sortSpec,
  sortDefinition,
  maxSortItems = defaultMaxSortItems,
  sx,
  onChange,
}: SortDefinitionInputProps) {
  const [_sortDefinition, _setSortDefinition] = useState<SortDefinition | undefined>(
    sortDefinition,
  );

  const sortDefinitionComputed = useMemo(
    () => sortDefinition || _sortDefinition,
    [sortDefinition, _sortDefinition],
  );

  const isMaxItemsLimitReached = useMemo(
    () => (sortDefinitionComputed?.items?.length ?? 0) >= maxSortItems,
    [maxSortItems, sortDefinitionComputed],
  );

  const handleSortDefinitionChange = (newValue: SortDefinition | undefined) => {
    _setSortDefinition(newValue);
    onChange(newValue);
  };

  const addItem = (params?: { field?: string }) => {
    // auto-select suitable field and operator
    const fieldSpec = params?.field
      ? sortSpec.getFieldOrThrow(params.field)
      : sortSpec.getFirstField();
    const sortOrder = fieldSpec?.getFirstSortOrder();

    if (fieldSpec && sortOrder) {
      const newItem = new SortDefinitionItem({
        field: fieldSpec.field,
        sortOrder: sortOrder,
      });

      const newValue = sortDefinitionComputed
        ? new SortDefinition(sortDefinitionComputed)
        : SortDefinition.newEmpty();
      newValue.addItem(newItem);
      handleSortDefinitionChange(newValue);

      // trigger change for the item
      fieldSpec.onChange && fieldSpec.onChange(newItem);
    }
  };

  const updateItem = (newItem: SortDefinitionItem) => {
    const fieldSpec = sortSpec.getFieldOrThrow(newItem.field);
    const newValue = sortDefinitionComputed
      ? new SortDefinition(sortDefinitionComputed)
      : SortDefinition.newEmpty();
    newValue.replaceItem(newItem);
    handleSortDefinitionChange(newValue);

    // trigger change for the item
    fieldSpec.onChange && fieldSpec.onChange(newItem);
  };

  const removeItem = (oldItem: SortDefinitionItem) => {
    const fieldSpec = sortSpec.getFieldOrThrow(oldItem.field);
    const newValue = sortDefinitionComputed
      ? new SortDefinition(sortDefinitionComputed)
      : SortDefinition.newEmpty();
    newValue.removeItem(oldItem);
    handleSortDefinitionChange(newValue);

    // trigger change for the item
    fieldSpec.onChange && fieldSpec.onChange(oldItem);
  };

  const removeAllItems = () => {
    handleSortDefinitionChange(undefined);
  };

  return (
    <Box sx={sx}>
      {/* Items */}
      <Stack direction='column' spacing={1}>
        {_.isEmpty(sortDefinitionComputed?.items) && (
          <NoDataAlert variant='inline' title='Sort is not applied.' />
        )}

        {sortDefinitionComputed?.items?.map((item, i) => {
          const fieldSpec = sortSpec.getFieldOrThrow(item.field);

          return (
            <Stack key={i} direction='row' spacing={1}>
              {/* Field */}
              <FormControl sx={{ minWidth: 150 }} variant='outlined' size='small'>
                <InputLabel>Field</InputLabel>
                <Select
                  value={item.field || ""}
                  label='Field'
                  onChange={(e) => {
                    const newItem = new SortDefinitionItem({ ...item });
                    newItem.field = e.target.value;
                    updateItem(newItem);
                  }}
                >
                  {sortSpec.fields.map((field, j) => (
                    <MenuItem key={j} value={field.field}>
                      {field.title || field.field}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Operator */}
              <FormControl sx={{ minWidth: 150 }} size='small' disabled={!item.field}>
                <InputLabel>Operator</InputLabel>
                <Select
                  value={item.sortOrder || ""}
                  label='Sort order'
                  onChange={(e) => {
                    const newSortOrder = (e.target.value as SortOrder) || SortOrder.Asc;
                    if (newSortOrder !== item.sortOrder) {
                      const newItem = new SortDefinitionItem({ ...item });
                      newItem.sortOrder = newSortOrder;
                      updateItem(newItem);
                    }
                  }}
                >
                  {fieldSpec.sortOrders.map((sortOrder, j) => (
                    <MenuItem key={j} value={sortOrder}>
                      <InlineApiEnumValue type='SortOrder' value={sortOrder} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Controls */}
              <Stack direction='row' sx={{ flex: 1, justifyContent: "flex-end" }}>
                {/* Remove */}
                <AppIconButton
                  sx={{ width: 40, height: 40 }}
                  tooltipProps={{ title: "Remove" }}
                  onClick={() => removeItem(item)}
                >
                  <AppIcon of='remove' />
                </AppIconButton>
              </Stack>
            </Stack>
          );
        })}

        {/* Controls */}
        <Stack spacing={1}>
          <Stack
            direction='row'
            sx={{ flex: 1, justifyContent: "space-between", alignItems: "center" }}
          >
            {isMaxItemsLimitReached && (
              <Typography variant='caption'>
                You reached the limit of {maxSortItems}{" "}
                {TextHelper.pluralizeManual("sort", maxSortItems, "sorts")}.
              </Typography>
            )}
            {!isMaxItemsLimitReached && (
              <DropdownButton
                component='button'
                variant='text'
                size='small'
                color='primary'
                autoCloseOnClick
                buttonProps={{
                  startIcon: <AppIcon of='add' />,
                }}
                dropdownContentWrapperProps={{
                  maxHeight: {
                    xxs: "90vh",
                    md: 300,
                  },
                }}
                dropdownContent={
                  <MenuList>
                    {sortSpec.fields.map((field, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          addItem({ field: field.field });
                        }}
                      >
                        <ListItemText>{field.title || field.field}</ListItemText>
                      </MenuItem>
                    ))}
                  </MenuList>
                }
              >
                Add sort
              </DropdownButton>
            )}

            {maxSortItems > 1 && !TypeHelper.isEmpty(sortDefinitionComputed?.items) && (
              <Button
                variant='text'
                size='small'
                color='primary'
                startIcon={<AppIcon of='remove' />}
                onClick={() => removeAllItems()}
              >
                Remove all
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
