import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { ContractDto } from "@/core/api/generated";

import AssetSubscriptionView from "../../../AssetSubscription/View/AssetSubscriptionView";

interface Props {
  contract: ContractDto;
}

export default function AssetSubscriptionTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <AssetSubscriptionView
          assetSubscriptionId={contract.assetSubscription?.id}
          assetSubscription={undefined}
          displayProps={{
            breadcrumbs: false,
            header: true,
            actions: true,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          headerProps={{
            withLink: true,
            typographyProps: pageTabHeaderTypographyProps.title,
          }}
        />
      </Stack>
    </PageTabContent>
  );
}
