import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { SupplierDto, SupplierGetPaginatedDto } from "@/core/api/generated";

import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";
import { supplierToOption } from "./SupplierAutocomplete";

export interface SuppliersAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<SupplierDto> {
  searchFilters?: {
    supplierId: string;
  } & Partial<Omit<SupplierGetPaginatedDto, "search" | "includeIds">>;
}

export default function SuppliersAutocomplete({
  isPreload = true,
  searchFilters,
  ...otherProps
}: SuppliersAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={supplierToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.suppliersApi.apiV1SuppliersGetPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          supplierGetPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          supplierGetPaginatedDto: {
            ...params.supplierGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Suppliers'
      placeholder='Search for suppliers...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='supplier' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2' color='secondary'>
                      <Box>{option.data?.contact?.email}</Box>
                      <Box>
                        <InlineApiEnumValueList type='SupplierType' values={option.data?.types} />
                      </Box>
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
