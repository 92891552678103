import { Box, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import FoldingTagList from "@/common/components/Entity/General/GeneralTag/FoldingTagList";
import GeneralAttachedTagsDisplay from "@/common/components/Entity/General/GeneralTag/GeneralAttachedTagsDisplay";
import GeneralTagDisplay from "@/common/components/Entity/General/GeneralTag/GeneralTagDisplay";
import TagDisplay from "@/common/components/Entity/General/GeneralTag/TagDisplay";
import TagList from "@/common/components/Entity/General/GeneralTag/TagList";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import { GeneralAttachedTagsDto, GeneralTagDto, TagTargetType } from "@/core/api/generated";

const exampleTags: GeneralTagDto[] = [
  ...Array.from({ length: 20 }).map((x, i) => ({
    targetType: TagTargetType.User,
    target: {
      user: {
        id: "test",
        name: "John Doe" + i,
        email: "example.com",
      },
    },
  })),
  {
    targetType: TagTargetType.Contract,
    target: {
      contract: {
        id: "test",
        localNumber: "localNumber",
        globalNumber: "globalNumber",
      },
    },
  },
];

const exampleAttachedTags: GeneralAttachedTagsDto = {
  tags: exampleTags,
};

export default function DevTags() {
  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h1'>
        Tags
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>TagDisplay</InlineCode>
        </Typography>
        <Box>
          <Typography component='div' variant='subtitle1' sx={{ my: 1 }}>
            Default tag
          </Typography>
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            <TagDisplay label={"Default tag"} sx={{ mb: 1 }} />
            <TagDisplay label={"With click"} onClick={() => {}} sx={{ mb: 1 }} />
            <TagDisplay label={"With delete"} onDelete={() => {}} sx={{ mb: 1 }} />
          </Stack>
        </Box>
        <Box>
          <Typography component='div' variant='subtitle1' sx={{ my: 1 }}>
            <InlineCode>{`pickColorForText={Text}`}</InlineCode>
          </Typography>
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            {exampleTags.slice(0, 3).map((x, i) => (
              <TagDisplay
                key={i}
                label={"Tag label " + i}
                pickColorForText={i + " Tag label " + i}
              />
            ))}
          </Stack>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>GeneralTagDisplay</InlineCode>
        </Typography>
        <Stack direction='column' spacing={1} flexWrap='wrap'>
          {exampleTags.slice(0, 3).map((tag, i) => (
            <GeneralTagDisplay key={i} tag={tag} />
          ))}
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>TagList</InlineCode>
        </Typography>
        <Stack direction='column' spacing={4}>
          <TagList
            tags={Array.from({ length: 20 }).map((x, i) => (
              <TagDisplay
                key={i}
                label={"Tag label " + i}
                pickColorForText={i + " Tag label " + i}
              />
            ))}
          />

          <TagList
            maxVisibleItems={3}
            tags={Array.from({ length: 20 }).map((x, i) => (
              <TagDisplay
                key={i}
                label={"Tag label " + i}
                pickColorForText={i + " Tag label " + i}
              />
            ))}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>FoldingTagList</InlineCode>
        </Typography>
        <Stack direction='column' spacing={3}>
          <FoldingTagList
            tagListProps={{
              tags: Array.from({ length: 20 }).map((x, i) => (
                <TagDisplay
                  key={i}
                  label={"Tag label " + i}
                  pickColorForText={i + " Tag label " + i}
                />
              )),
            }}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>GeneralAttachedTagsDisplay</InlineCode>
        </Typography>
        <Box>
          <GeneralAttachedTagsDisplay tags={exampleAttachedTags} />
        </Box>
      </Stack>
    </Stack>
  );
}
