import RolesSelector, { RolesSelectorProps } from "@/common/components/Entity/Role/RolesSelector";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export interface GlobalRolesSelectorProps
  extends Omit<RolesSelectorProps, "getRoles" | "roleList" | "roleAssignmentRestrictions"> {
  targetUserId?: string | null;
}

export default function GlobalRolesSelector({
  targetUserId,
  ...otherProps
}: GlobalRolesSelectorProps) {
  const roleAssignmentRestrictionsRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountGlobalRoleAssignmentsRestrictionsGet,
    {
      targetUserId: targetUserId!,
    },
    {
      deps: [targetUserId],
      skip: !targetUserId,
    },
  );
  const roleAssignmentRestrictions = roleAssignmentRestrictionsRequest?.data;

  return (
    <RolesSelector
      getRoles={async () => {
        const response = await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesGet();
        return response.data;
      }}
      roleAssignmentRestrictions={roleAssignmentRestrictions}
      {...otherProps}
    />
  );
}
