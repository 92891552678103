import { Stack } from "@mui/material";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import { PaginationDtoOfVehicleHistoryItemDto } from "@/core/api/generated";

import VehicleHistoryItem from "./VehicleHistoryItem";

interface Props {
  paginatedHistoryItems: PaginationDtoOfVehicleHistoryItemDto;
}

export default function VehicleHistoryList({ paginatedHistoryItems }: Props) {
  return (
    <Stack direction='column' spacing={1}>
      {paginatedHistoryItems?.items?.length === 0 && <NoDataAlert title='No history items yet' />}

      {paginatedHistoryItems?.items?.map((item) => (
        <VehicleHistoryItem key={item.id} item={item} />
      ))}
    </Stack>
  );
}
