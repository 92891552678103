import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import RepairWorkView from "@/common/components/Entity/RepairWork/View/RepairWorkView";

export default function RepairWorkViewPage() {
  const { repairWorkId } = useParams<{ repairWorkId?: string }>();

  return (
    <>
      <RepairWorkView
        repairWorkId={repairWorkId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
