import { User } from "@auth0/auth0-spa-js";
import { useEffect, useState } from "react";

import { auth0Provider } from "../../auth0Provider";

/** Returns currently authenticated Auth0 user. */
export const useAuth0User = (): User | undefined => {
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    auth0Provider
      .getAuth0Client()
      ?.getUser()
      .then((data) => {
        setUser(data);
      });
  }, []);

  return user;
};
