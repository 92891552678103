import { FormControl, FormHelperText, SxProps, Theme } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { AssetSubscriptionSpotInfoInputDto, SpotType } from "@/core/api/generated";

import AppTextArea from "../../Form/Input/AppTextArea";
import SpotAutocompleteOrCreate from "../Spot/SpotAutocompleteOrCreate";

interface Props {
  formikProps: CustomFormikSubProps<AssetSubscriptionSpotInfoInputDto | null | undefined>;
  productLocationId: string | undefined | null;
  spotType: SpotType | undefined | null;
  sx?: SxProps<Theme>;
}

export default function AssetSubscriptionSpotInfoInput({
  formikProps,
  productLocationId,
  spotType,
  sx,
}: Props) {
  const { values, touched, errors, setFieldValue, setFieldError } = formikProps;

  return (
    <>
      <FormControl margin='dense' fullWidth error={Boolean(touched?.date && errors?.date)}>
        <MobileDateTimePicker
          ampm={false}
          label='Date & Time'
          value={(values?.date && moment(values.date)) || null}
          format={DATETIME_FORMATS.DISPLAY_DATETIME}
          onChange={(newValue: Moment | null) => {
            setFieldValue("date", newValue?.format() || null);
          }}
        />
        <FormHelperText>{touched?.date && errors?.date}</FormHelperText>
      </FormControl>

      <FormControl margin='dense' fullWidth error={Boolean(errors?.spotId)}>
        <SpotAutocompleteOrCreate
          autocompleteProps={{
            entityId: values?.spotId,
            searchFilters: { productLocationId: productLocationId, type: spotType || undefined },
            onChange: (newValue) => {
              setFieldValue("spotId", newValue?.id);
            },
          }}
          createFormPlacement='modal'
          onCreate={(newValue) => {
            setFieldValue("spotId", newValue?.id);
          }}
        />
      </FormControl>

      <FormControl margin='dense' fullWidth>
        <AppTextArea
          mode='notes'
          name={`${productLocationId}.${spotType}.notes`}
          value={values?.notes || ""}
          onChange={(e) => {
            setFieldValue("notes", e.target.value);
          }}
        />
      </FormControl>
    </>
  );
}
