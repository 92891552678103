import { LoadingButton } from "@mui/lab";
import { Box, Divider, FormControl, FormHelperText, InputLabel, TextField } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik, getIn } from "formik";
import moment, { Moment } from "moment";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateCustomSessionDto,
  AdminTenantDto,
  UserMembershipType,
} from "@/core/api/generated";

import TenantAutocomplete from "../components/Entity/Tenant/TenantAutocomplete";
import AdminUserAutocomplete from "../components/Entity/User/UserAutocomplete";
import GlobalOrTenantRolesSelector from "../components/Role/GlobalOrTenantRolesSelector";

export default function CustomSessionCreateUpdatePage() {
  const { customSessionId } = useParams<{ customSessionId?: string }>();
  const mounted = useMounted();
  const isCreate = !customSessionId;
  const isEdit = !isCreate;
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const customSessionRequest = useApiRequest(
    apiClient.adminCustomSessionsApi.apiV1AdminCustomSessionsSessionIdGet,
    {
      sessionId: customSessionId!,
    },
    {
      deps: [customSessionId],
      skip: !customSessionId,
    },
  );
  const customSession = customSessionRequest?.data;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new custom session" : "Edit custom session"}
        />
      }
    >
      <Formik<AdminCreateCustomSessionDto & BaseFormikValues & { tenant?: AdminTenantDto }>
        enableReinitialize
        initialValues={{
          membershipType: customSession?.membershipType || UserMembershipType.Account,
          tenantId: customSession?.tenantId || undefined,
          expiresAt: customSession?.expiresAt || undefined,
          lifetime: customSession?.lifetime || undefined,
          roleIds: customSession?.roles?.map((x) => x.id!) || undefined,
          forAccount: undefined,
          forNoAccount: undefined,
          forAnonymous: undefined,

          tenant: undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("Name is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.adminCustomSessionsApi.apiV1AdminCustomSessionsPost({
                adminCreateCustomSessionDto: {
                  ...values,
                  expiresAt: moment(values.expiresAt).utc().format(),
                },
              });
              enqueueSnackbar("Custom session created.", { variant: "success" });
            } else {
              throw new Error("Not implemented!");
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
            history.goBack();
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='dense'
                  fullWidth
                  error={Boolean(touched.membershipType && errors.membershipType)}
                >
                  <InputLabel required>Membership type</InputLabel>
                  <ApiEnumSelect
                    type='UserMembershipType'
                    value={values.membershipType}
                    onChange={(newValue) => {
                      setFieldValue("membershipType", newValue);
                      setFieldValue(
                        "forAccount",
                        newValue === UserMembershipType.Account ? {} : undefined,
                      );
                      setFieldValue(
                        "forNoAccount",
                        newValue === UserMembershipType.NoAccount ? {} : undefined,
                      );
                      setFieldValue(
                        "forAnonymous",
                        newValue === UserMembershipType.Anonymous ? {} : undefined,
                      );
                    }}
                    filterOptions={(option) => option.value !== UserMembershipType.None}
                    selectProps={{
                      required: true,
                      disabled: isEdit,
                      label: "Vehicle type",
                    }}
                    optionProps={{
                      withDescription: true,
                    }}
                  />
                  <FormHelperText>{touched.membershipType && errors.membershipType}</FormHelperText>
                </FormControl>

                <FormControl fullWidth margin='dense' error={Boolean(errors.tenantId)}>
                  <TenantAutocomplete
                    entityId={values.tenantId}
                    fullWidth
                    onChange={(newValue) => {
                      setFieldValue("tenantId", newValue?.id);
                      setFieldValue("tenant", newValue);
                    }}
                  />
                  <FormHelperText error={Boolean(errors.tenantId)}>
                    {errors.tenantId}
                  </FormHelperText>
                </FormControl>

                <GlobalOrTenantRolesSelector
                  scopeType={values.tenantId ? "tenant" : "global"}
                  onScopeTypeChange={() => {
                    setFieldValue("roleIds", []);
                  }}
                  tenantId={values.tenantId || ""}
                  roleIds={values.roleIds}
                  onChange={(newValue) =>
                    setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                  }
                  textFieldProps={{
                    error: Boolean(errors.roleIds),
                    helperText: errors.roleIds,
                    required: true,
                  }}
                  displayProps={{
                    selectedRolesOverview: false,
                  }}
                />

                <FormControl fullWidth margin='dense' error={Boolean(errors.expiresAt)}>
                  <MobileDateTimePicker
                    ampm={false}
                    label='Expires at'
                    value={(values.expiresAt && moment(values.expiresAt)) || null}
                    format={DATETIME_FORMATS.DISPLAY_DATETIME}
                    onChange={(newValue: Moment | null) => {
                      setFieldValue("expiresAt", newValue?.format() || null);
                    }}
                    slots={{ textField: (params) => <TextField {...params} /> }}
                  />
                  <FormHelperText>{errors.expiresAt}</FormHelperText>
                </FormControl>

                <Divider sx={{ my: 2 }} />

                {/* Account */}
                {values.membershipType === UserMembershipType.Account && (
                  <>
                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(getIn(errors, `forAccount.userId`))}
                    >
                      <AdminUserAutocomplete
                        fullWidth
                        required
                        userId={values.forAccount?.userId}
                        searchFilters={{
                          tenantId: values.tenantId || undefined,
                        }}
                        onChange={(newValue) => setFieldValue("forAccount.userId", newValue?.id)}
                      />
                      <FormHelperText error={Boolean(getIn(errors, `forAccount.userId`))}>
                        {getIn(errors, `forAccount.userId`)}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
                {errors.forAccount && (
                  <FormHelperText error={Boolean(errors.forAccount)}>
                    {ValidationHelper.getFormikErrorsAsString(errors.forAccount)}
                  </FormHelperText>
                )}

                {/* NoAccount */}
                {errors.forNoAccount && (
                  <FormHelperText error={Boolean(errors.forNoAccount)}>
                    {ValidationHelper.getFormikErrorsAsString(errors.forNoAccount)}
                  </FormHelperText>
                )}

                {/* Anonymous */}
                {errors.forAnonymous && (
                  <FormHelperText error={Boolean(errors.forAnonymous)}>
                    {ValidationHelper.getFormikErrorsAsString(errors.forAnonymous)}
                  </FormHelperText>
                )}
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </CreateUpdatePageLayout>
  );
}
