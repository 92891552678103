import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { AssetDto, EntityType } from "@/core/api/generated";

import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  asset: AssetDto;
}

export default function GeneralHistoryTabContent({ asset }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <GeneralHistoryPaginatedList
          defaultValues={{
            subjectEntityType: EntityType.Asset,
            subjectEntityId: asset.id,
          }}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab,
          }}
        />
      </ViewContentBlock>
    </PageTabContent>
  );
}
