import {
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationUpdateDto,
  RepairSpecDataDto,
  RepairSpecDto,
} from "@/core/api/generated";

export class DamageCostEvaluationHelper {
  /** Checks if there is any currency mismatch between DCE and RepairSpec. */
  public static isCurrencyMismatchWithRepairSpec(
    entity:
      | DamageCostEvaluationDto
      | DamageCostEvaluationCreateDto
      | DamageCostEvaluationUpdateDto
      | null
      | undefined,
    repairSpec: RepairSpecDto | RepairSpecDataDto | null | undefined,
  ): boolean {
    return (
      (entity?.currency &&
        repairSpec?.currency &&
        entity.currency.code !== repairSpec.currency.code) ??
      false
    );
  }
}
