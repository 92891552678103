import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControl, FormControlLabel, Paper, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CreateWebhookDto, EntityType, UpdateWebhookDto, WebhookDto } from "@/core/api/generated";

import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  isEnabled?: CreateWebhookDto["isEnabled"];
};

export interface WebhookCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<WebhookDto, DefaultValues> {
  webhookId?: string;
}

export type WebhookCreateUpdateProps = WebhookCreateUpdateOwnProps;

export default function SpotCreateUpdate({
  webhookId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: WebhookCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !webhookId;

  const webhookRequest = useApiRequest(
    apiClient.webhooksApi.apiV1WebhooksWebhookIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookId: webhookId!,
    },
    {
      skip: !webhookId,
    },
  );
  const webhook = webhookRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: webhook && {
      idValue: webhook.id!,
      newTitle: webhook.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Webhook}
      entityId={webhookId}
      entity={webhook}
      entityRequest={webhookRequest}
    >
      <Formik<CreateWebhookDto & UpdateWebhookDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          name: webhook?.name || undefined,
          eventTypes: webhook?.eventTypes || [],
          url: webhook?.url || undefined,
          isEnabled: webhook?.isEnabled || true,
          notes: webhook?.notes || undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.webhooksApi.apiV1WebhooksPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createWebhookDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Webhook created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.webhooksApi.apiV1WebhooksWebhookIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                webhookId,
                updateWebhookDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Webhook updated", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <FormControl margin='dense' fullWidth>
                    <TextField
                      required
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl margin='dense' fullWidth>
                    <ApiEnumsAutocomplete
                      type='WebhookEventType'
                      values={values.eventTypes}
                      onChange={(newValues) => {
                        setFieldValue("eventTypes", newValues);
                      }}
                      label='Event types'
                      required
                      textFieldProps={{
                        error: Boolean(errors.eventTypes),
                        helperText: ValidationHelper.getErrorsAsString(errors.eventTypes),
                      }}
                    />
                  </FormControl>
                  <FormControl margin='dense' fullWidth>
                    <TextField
                      required
                      error={Boolean(touched.url && errors.url)}
                      fullWidth
                      helperText={touched.url && errors.url}
                      label='Endpoint'
                      margin='dense'
                      name='url'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.url || ""}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl margin='dense' fullWidth>
                    <AppTextArea
                      mode='notes'
                      fullWidth
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes || ""}
                      variant='outlined'
                    />
                  </FormControl>
                  <FormControl margin='dense' fullWidth>
                    <Paper sx={{ px: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='enabled'
                            checked={values.isEnabled}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        }
                        label='Is Enabled'
                      />
                    </Paper>
                  </FormControl>
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
