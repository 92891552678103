import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  RepairSparePartDto,
  RepairSparePartGetPaginatedDto,
} from "@/core/api/generated";

export default function RepairSparePartsPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<RepairSparePartGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.RepairSparePart),
    },
  });

  const [repairSparePartToDelete, setRepairSparePartToDelete] = useState<RepairSparePartDto | null>(
    null,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedSparePartsRequest = useApiRequest(
    apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsGetPost,
    {
      repairSparePartGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedSpareParts } = paginatedSparePartsRequest;

  const handleDeleteConfirmed = useCallback(async () => {
    if (!repairSparePartToDelete) {
      return;
    }
    try {
      await apiClient.adminRepairSparePartApi.apiV1AdminRepairSparePartsRepairSparePartIdDelete({
        repairSparePartId: repairSparePartToDelete.id!,
      });
      enqueueSnackbar("Item was successfully deleted.", { variant: "success" });
      setIsDeleteModalOpen(false);
      paginatedSparePartsRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [repairSparePartToDelete]);

  return (
    <ListPageLayout>
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={
          <Button
            variant='contained'
            color='primary'
            startIcon={<AppIcon of='add' />}
            component={RouterLink}
            to={ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_CREATE}
          >
            Create new
          </Button>
        }
      />

      <Divider sx={{ my: 2 }} />

      <Stack spacing={1}>
        <DataTabular
          columns={[
            {
              field: "localNumber",
              title: "Number",
              flex: 1,
              renderCell: (item) => item.localNumber,
            },
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => item.name,
            },
            {
              field: "brandName",
              title: "Brand name",
              flex: 1,
              renderCell: (item) => item.brandName,
            },
            {
              field: "tenantId",
              title: "Tenant#",
              flex: 1,
              renderCell: (item) => item.tenantId,
            },
          ]}
          rows={paginatedSpareParts?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <MenuWithTrigger
              trigger={
                <IconButton
                  edge='end'
                  sx={{ display: { xxs: "none", md: "inline-flex" }, mt: -0.5 }}
                >
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <MenuItem
                  key='view'
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_VIEW(item.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='view' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>View</ListItemText>
                </MenuItem>,
                <MenuItem
                  key='edit'
                  component={RouterLink}
                  to={ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_EDIT(item.id)}
                >
                  <ListItemIcon>
                    <AppIcon of='edit' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Edit</ListItemText>
                </MenuItem>,
                <MenuItem
                  key='delete'
                  onClick={() => {
                    setRepairSparePartToDelete(item);
                    setIsDeleteModalOpen(true);
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>,
              ]}
            </MenuWithTrigger>
          )}
          isLoading={paginatedSparePartsRequest.isLoading}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
        />
      </Stack>

      {/* Delete confirmation */}
      {repairSparePartToDelete && (
        <ConfirmationModal
          title='Delete the repair spare part?'
          body={
            <>
              {`You're going to delete the repair material`}{" "}
              <strong>{repairSparePartToDelete.name}</strong>. {`This action can't be undone.`}
            </>
          }
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </ListPageLayout>
  );
}
