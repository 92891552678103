import { useParams } from "react-router";

import VehicleActualStateView from "@/common/components/Entity/Vehicle/VehicleActualState/VehicleActualStateView";

export default function VehicleActualStatePage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();

  return (
    <>
      <VehicleActualStateView vehicleId={vehicleId} />
    </>
  );
}
