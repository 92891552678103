import { Stack, StackProps, Typography } from "@mui/material";
import { useCallback } from "react";
import { useHistory } from "react-router";

import AppTooltip from "@/common/components/AppTooltip";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { ContractDto, ContractFilterType } from "@/core/api/generated";

interface Props extends StackProps {
  item: ContractDto;
  filterType?: ContractFilterType;
}

export default function ContractCard({ item, filterType, ...other }: Props) {
  const history = useHistory();

  const handleCardClick = useCallback(
    (contractId: string) => {
      history.push(ROUTE_PATH.CONTRACT_VIEW(contractId));
    },
    [history],
  );
  const dateTimeByFilterType = () => {
    if (filterType === ContractFilterType.CheckOut) {
      return item.checkOutSpotInfo?.date;
    }
    if (filterType === ContractFilterType.CheckIn) {
      return item.checkInSpotInfo?.date;
    }
  };
  const addressByFilterType = () => {
    if (filterType === ContractFilterType.CheckOut) {
      return item.checkOutSpotInfo?.spot?.address;
    }
    if (filterType === ContractFilterType.CheckIn) {
      return item.checkInSpotInfo?.spot?.address;
    }
  };
  return (
    <Stack
      spacing={0.5}
      onClick={() => handleCardClick(item.id!)}
      sx={{ cursor: "pointer", minWidth: 0, overflow: "hidden" }}
      {...other}
    >
      <AppTooltip title='Vehicle'>
        <Stack direction='row' sx={{ justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "bold" }} variant='body1'>
            {item.vehicle?.plateNo?.plateNo}
          </Typography>
          <Typography color='primary'>
            {item.vehicle?.spec?.make?.name} {item.vehicle?.spec?.model?.name}
          </Typography>
        </Stack>
      </AppTooltip>

      <AppTooltip title='Customer'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='personOutline' inText />}
          ellipsing={{ enabled: true }}
        >
          {item.customer?.name}
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Contract number'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='document' inText />}
          ellipsing={{ enabled: true }}
        >
          <AppTypography ellipsing={{ enabled: true }}>
            {item.externalNumber || item.localNumber}
          </AppTypography>
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Date'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='calendar' inText />}
          ellipsing={{ enabled: true }}
        >
          {dateTimeByFilterType() && (
            <Datetime sx={{ fontWeight: 500 }} datetime={dateTimeByFilterType()} />
          )}
        </FieldValue>
      </AppTooltip>

      <AppTooltip title='Address'>
        <FieldValue
          size='compact'
          labelIcon={<AppIcon of='pinLocation' />}
          labelAlight={{
            vertical: "start",
          }}
          ellipsing={{ enabled: true }}
        >
          {addressByFilterType() && <GeneralAddressDisplay address={addressByFilterType()} />}
        </FieldValue>
      </AppTooltip>
    </Stack>
  );
}
