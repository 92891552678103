import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import DamageDetectionAggregatesPaginatedList from "@/common/components/Entity/DamageDetectionAggregate/ListView/DamageDetectionAggregatesPaginatedList";

export default function DamageDetectionAggregatesPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Vehicle visual models' />}>
      <DamageDetectionAggregatesPaginatedList />
    </ViewLayoutV2>
  );
}
