import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { RepairMaterialDto, RepairMaterialReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getRepairMaterialOptionTitle } from "./RepairMaterialAutocomplete";

export interface RepairMaterialInlineProps extends BaseEntityInlineInheritableProps {
  entity: RepairMaterialDto | RepairMaterialReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function RepairMaterialInline({
  entity,
  entityId,
  ...otherProps
}: RepairMaterialInlineProps) {
  const request = useApiRequest(
    apiClient.repairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairMaterialId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as RepairMaterialDto;
  const ref = entity as RepairMaterialReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='repairMaterial' inText />}
      content={<>{full && getRepairMaterialOptionTitle(full)} </>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair material"}
    />
  );
}
