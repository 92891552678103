import { AxiosRequestConfig, AxiosResponse } from "axios";

import { ValidationProblemDetails } from "@/core/api/generated";

class ServerBaseErrorModel extends Error {
  status: number;
  statusText: string;
  headers: any;
  data: any;
  config?: AxiosRequestConfig;
  request?: any;

  problemDetails?: ValidationProblemDetails;

  constructor(response: AxiosResponse, message: string, problemDetails?: ValidationProblemDetails) {
    super(message);

    this.data = response.data;
    this.status = response.status;
    this.statusText = response.statusText;
    this.headers = response.headers;
    this.config = response.config || undefined;
    this.request = response.request;

    this.problemDetails = problemDetails;
  }
}

export default ServerBaseErrorModel;
