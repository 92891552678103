// Global variables are defined and set here

declare global {
  /* eslint-disable */
  /** Defines empty value to be passed to api clients (generated from Open API) methods.
   *  This value is overwritten by global config in ApiClient.ts.
   */
  var EMPTY_TENANT_IDENTIFIER: string;
  /* eslint-enable */
}

global.EMPTY_TENANT_IDENTIFIER = "";

export {};
