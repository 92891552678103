import { AxiosError } from "axios";

class NetworkErrorModel extends Error {
  constructor(err: AxiosError, message: string) {
    // todo: investigate error variants and generate different messages for super
    super(message);
  }
}

export default NetworkErrorModel;
