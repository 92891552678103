import { Box, CircularProgress, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { FileDto } from "@/core/api/generated";

import FileUploadWrappingArea from "./FileUploadWrappingArea";
import { UploadFilesFunc, UploadFilesFuncRequestParameters } from "./FileUploader";

interface Props {
  defaultFile?: FileItem;
  onChange?: (newAvatar?: FileItem) => void;
  children: (obj: { handleRemove: () => void }) => React.ReactNode;
}

/** File upload used for avatars only. */
function AvatarUploader({ defaultFile, onChange, children }: Props) {
  const maxFiles = 1;

  const [files, setFiles] = useState<FileItem[]>(defaultFile ? [defaultFile] : []);
  const [uploadValidation, setUploadValidation] = useState<ValidationInfo | undefined>(undefined);
  const [fileUploading, setFileUploading] = useState(false);

  const uploadFilesFunc: UploadFilesFunc = async (
    requestParameters: UploadFilesFuncRequestParameters,
  ) => {
    const response = await apiClient.filesApi.apiV1FilesAvatarUploadPost({ ...requestParameters });
    if (response.request.status !== 200) {
      throw response;
    }
    return response.data;
  };

  const canUploadCount = Math.abs(maxFiles - files.length);

  useEffect(() => {
    onChange && onChange(files.filter((x) => !!x.file)[0] || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const clearUploads = () => {
    setFiles([]);
    setUploadValidation(undefined);
  };

  const handleUploadStarted = (fls: File[]) => {
    setFileUploading(true);
    setFiles(FileItem.createManyFrom(fls, true));
    setUploadValidation(undefined);
  };

  const handleUploadFinished = (fls: FileDto[]) => {
    setFiles(FileItem.createManyFrom(fls));
    setFileUploading(false);
  };

  const handleUploadError = (err: any, fls: File[], validation?: ValidationInfo) => {
    const newFiles = files.map((x) => {
      if (fls.some((y) => y.name === x.fileName)) {
        x.isUploading = false;
      }
      return x;
    });
    setFiles(newFiles);
    setUploadValidation(validation);
  };

  const handleFileUpload = async (fls: File[]) => {
    handleUploadStarted(fls);

    try {
      const uploadResult = await uploadFilesFunc({ files: fls });
      handleUploadFinished(uploadResult.files!);
    } catch (err) {
      const validation = ValidationHelper.handleApiErrorResponse(err);
      console.error("File upload error:", err, validation);
      handleUploadError(err, fls, validation);
    }
  };

  const handleRemove = () => {
    setFiles([]);
  };

  return (
    <Box sx={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
      <FileUploadWrappingArea
        multiple={false}
        accept={undefined}
        // disabled={!canUploadMore}
        maxFiles={maxFiles}
        onChange={handleFileUpload}
      >
        {({ triggerFileInput }) => (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              triggerFileInput();
            }}
          >
            {fileUploading && (
              <CircularProgress
                size={60}
                sx={{ padding: "2px", position: "absolute", zIndex: 3 }}
              />
            )}
            {children({ handleRemove })}
          </Box>
        )}
      </FileUploadWrappingArea>

      {uploadValidation && uploadValidation.hasErrors && (
        <Box sx={{ p: 1 }}>
          <FormHelperText sx={{ m: 0, p: 0 }} error>
            {uploadValidation.generalError}
          </FormHelperText>
        </Box>
      )}
    </Box>
  );
}

export default AvatarUploader;
