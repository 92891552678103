import { RepairSparePartDetalizationType, RepairSpecDetalizationType } from "@/core/api/generated";

/** From more general to more detailed.
 *  From less prioritized to more prioritized.
 */
export const REPAIR_SPEC_DETALIZATION_PRIORITY = {
  [RepairSpecDetalizationType.None]: 0,
  [RepairSpecDetalizationType.General]: 1,
  [RepairSpecDetalizationType.VehicleSize]: 2,
  [RepairSpecDetalizationType.BodyType]: 3,
  [RepairSpecDetalizationType.Make]: 4,
  [RepairSpecDetalizationType.Model]: 5,
  [RepairSpecDetalizationType.Generation]: 6,
  [RepairSpecDetalizationType.Modification]: 7,
};

/** From more general to more detailed.
 *  From less prioritized to more prioritized.
 */
export const REPAIR_SPARE_PART_DETALIZATION_PRIORITY = {
  [RepairSparePartDetalizationType.None]: 0,
  [RepairSparePartDetalizationType.General]: 1,
  [RepairSparePartDetalizationType.VehicleSize]: 2,
  [RepairSparePartDetalizationType.BodyType]: 3,
  [RepairSparePartDetalizationType.Make]: 4,
  [RepairSparePartDetalizationType.Model]: 5,
  [RepairSparePartDetalizationType.Generation]: 6,
  [RepairSparePartDetalizationType.Modification]: 7,
};
