import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";
import AppNav from "@/common/components/Nav/AppNav";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EnvHelper } from "@/common/helpers/env";
import { UrlHelper } from "@/common/helpers/url";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { tenantService } from "@/common/services/tenant";
import { AppPermission } from "@/core/api/generated";
import * as appCommonSlice from "@/store/appCommon/slice";

import OperationsItemGroup from "./ItemGroups/OperationsItemGroup";
import TenantToTenantItemGroup from "./ItemGroups/TenantToTenantItemGroup";

export interface AppNavToNavItemParams {
  onItemClick: (params: { to?: string }) => void;
}

export default function Nav() {
  const theme = useTheme();
  const thunkDispatch = useAppThunkDispatch();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);

  const tenantInfo = tenantService.resolveTenant();
  const isTenantSelected = !!tenantInfo?.identifier;

  const handleItemClick = useCallback(
    (params: { to?: string }) => {
      // scroll top top
      // topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });

      // on mobile auto-close nav after clicking link
      if (params.to && isMobile && isNavOpened) {
        thunkDispatch(appCommonSlice.toggleNav());
      }
    },
    [isMobile, isNavOpened],
  );

  const iconColor = "#fff";

  const adminPanelUrl = UrlHelper.addUrlPathname(
    tenantService.getDefaultTenantUrl(false),
    ROUTE_PATH.APP_AREA_ADMIN,
  );

  return (
    <AppNav
      isNavOpened={isNavOpened}
      toggleNav={() => thunkDispatch(appCommonSlice.toggleNav())}
      titleTo='/'
      items={
        <>
          {EnvHelper.isDevelopmentAny && (
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='code' />,
                content: "Styleguide (dev only)",
                to: ROUTE_PATH.DEV_STYLEGUIDE,
                onClick: handleItemClick,
              }}
            />
          )}

          <AuthorizedElement permissions={[AppPermission.AdminAppAccess]}>
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='adminPanel' />,
                content: "Admin panel",
                to: adminPanelUrl,
                onClick: handleItemClick,
              }}
            />
          </AuthorizedElement>

          {isTenantSelected && (
            <>
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='dashboard' />,
                    content: "Tasks",
                    to: ROUTE_PATH.DASHBOARD,
                    onClick: handleItemClick,
                  }}
                />
              </AuthorizedElement>

              {/* Vehicles, contracts */}
              <AuthorizedElement permissionsAny={[AppPermission.FleetAppAccess]}>
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='vehicles' />,
                    content: "Vehicles",
                    to: ROUTE_PATH.VEHICLES,
                    onClick: handleItemClick,
                  }}
                />
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='contracts' />,
                    content: "Contracts",
                    to: ROUTE_PATH.CONTRACTS,
                    onClick: handleItemClick,
                  }}
                />
              </AuthorizedElement>

              {/* Customer */}
              <AuthorizedElement permissions={[AppPermission.CustomerRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='customers' />,
                    content: "Customers",
                    to: ROUTE_PATH.CUSTOMERS,
                    onClick: handleItemClick,
                  }}
                />
              </AuthorizedElement>

              {/* Flows */}
              <AuthorizedElement permissionsAny={[AppPermission.FleetAppAccess]}>
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='flows' />,
                    content: "Flows",
                    to: undefined,
                    onClick: handleItemClick,
                    items: (
                      <>
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='assessmentFlow' />,
                            content: "Damages & approvals",
                            to: ROUTE_PATH.ASSESSMENT_FLOWS(),
                            onClick: handleItemClick,
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Operations */}
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <OperationsItemGroup
                  navParams={{
                    onItemClick: handleItemClick,
                  }}
                />
              </AuthorizedElement>

              {/* Document */}
              {/* NB: temporary disabled */}
              {/* <AuthorizedElement
                  permissions={[AppPermission.DocumentRead]}
                  permissionsAny={[AppPermission.FleetAppAccess]}
                >
                  <AppNavItem
                    type='itemGroup'
                    itemGroup={{
                      startIcon: <AppNavIcon of='documents' />,
                      content: "Documents",
                      to: undefined,
                      onClick: handleItemClick,
                      items: (
                        <>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Index",
                                to: ROUTE_PATH.DOCUMENTS(),
                                onClick: handleItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Damage detections",
                                to: ROUTE_PATH.DOCUMENTS_DAMAGE_DETECTIONS(),
                                onClick: handleItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Damage cost evaluations",
                                to: ROUTE_PATH.DOCUMENTS_DAMAGE_COST_EVALUATIONS(),
                                onClick: handleItemClick,
                              }}
                            />
                          </AuthorizedElement>
                          <AuthorizedElement permissions={[AppPermission.DocumentRead]}>
                            <AppNavItem
                              type='item'
                              item={{
                                startIcon: <AppNavIcon of='documents' />,
                                content: "Accessory checks",
                                to: ROUTE_PATH.DOCUMENTS_ACCESSORY_CHECKS(),
                                onClick: handleItemClick,
                              }}
                            />
                          </AuthorizedElement>
                        </>
                      ),
                    }}
                  />
                </AuthorizedElement> */}

              {/* Repair */}
              <AuthorizedElement
                permissions={[
                  AppPermission.FleetAppAccess,
                  AppPermission.FleetRead,
                  AppPermission.RepairCatalogRead,
                ]}
              >
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='repair' />,
                    content: "Repair",
                    to: undefined,
                    onClick: handleItemClick,
                    items: (
                      <>
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='repairMaterial' />,
                            content: "Materials",
                            to: ROUTE_PATH.REPAIR_MATERIALS,
                            onClick: handleItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='repairWork' />,
                            content: "Work",
                            to: ROUTE_PATH.REPAIR_WORK_LIST,
                            onClick: handleItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='repairSparePart' />,
                            content: "Spare parts",
                            to: ROUTE_PATH.REPAIR_SPARE_PARTS,
                            onClick: handleItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='repairSpec' />,
                            content: "Specs",
                            to: ROUTE_PATH.REPAIR_SPECS,
                            onClick: handleItemClick,
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Products */}
              <AuthorizedElement
                permissions={[
                  AppPermission.FleetAppAccess,
                  // AppPermission.FleetRead,
                  // AppPermission.ProductRead,
                ]}
              >
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='product' />,
                    content: "Products",
                    to: undefined,
                    onClick: handleItemClick,
                    items: (
                      <>
                        <AuthorizedElement permissions={[AppPermission.ProductLocationRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='productLocation' />,
                              content: "Sales locations",
                              to: ROUTE_PATH.PRODUCT_SALES_LOCATIONS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.AssetRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='asset' />,
                              content: "Assets",
                              to: ROUTE_PATH.ASSETS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.PoolRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='pool' />,
                              content: "Pools",
                              to: ROUTE_PATH.POOLS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.AssetSubscriptionPlanRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='assetSubscriptionPlan' />,
                              content: "Asset subscription plans",
                              to: ROUTE_PATH.ASSET_SUBSCRIPTION_PLANS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.AssetSubscriptionRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='assetSubscription' />,
                              content: "Asset subscriptions",
                              to: ROUTE_PATH.ASSET_SUBSCRIPTIONS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Tenant-to-Tenant */}
              <TenantToTenantItemGroup
                navParams={{
                  onItemClick: handleItemClick,
                }}
              />

              {/* Other */}
              <AuthorizedElement
                permissions={[AppPermission.FleetAppAccess]}
                permissionsAny={[
                  AppPermission.FleetRead,
                  AppPermission.AccessoryRead,
                  AppPermission.NegotiationRead,
                  AppPermission.CustomTagRead,
                ]}
              >
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='other' />,
                    content: "Other",
                    to: undefined,
                    onClick: handleItemClick,
                    items: (
                      <>
                        <AuthorizedElement permissions={[AppPermission.AccessoryRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='accessory' />,
                              content: "Accessories",
                              to: ROUTE_PATH.ACCESSORIES,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.NegotiationRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='negotiations' />,
                              content: "Negotiations",
                              to: ROUTE_PATH.NEGOTIATIONS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.CustomTagRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='customTag' />,
                              content: "Custom tags",
                              to: ROUTE_PATH.CUSTOM_TAGS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        {/* Supplier */}
                        <AuthorizedElement permissions={[AppPermission.SupplierRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='suppliers' />,
                              content: "Suppliers",
                              to: ROUTE_PATH.SUPPLIERS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Management */}
              <AuthorizedElement
                permissions={[AppPermission.FleetAppAccess]}
                permissionsAny={[
                  AppPermission.TenantManage,
                  AppPermission.TenantSubscriptionRead,
                  AppPermission.InvoiceRead,
                  AppPermission.DepartmentRead,
                  AppPermission.TenantUserRead,
                  AppPermission.TenantRoleRead,
                  AppPermission.TenantInviteRead,
                ]}
              >
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='management' />,
                    content: "Management",
                    to: undefined,
                    onClick: handleItemClick,
                    items: (
                      <>
                        <AuthorizedElement permissions={[AppPermission.TenantManage]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='companyProfile' />,
                              content: "Company profile",
                              to: ROUTE_PATH.MANAGEMENT_TENANT_PROFILE,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantManage]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='settings' />,
                              content: "Company settings",
                              to: ROUTE_PATH.TENANT_SETTINGS_EDIT,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantSubscriptionRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='paymentProfile' />,
                              content: "Payment profile",
                              to: ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantSubscriptionRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='subscription' />,
                              content: "Subscriptions",
                              to: ROUTE_PATH.MANAGEMENT_SUBSCRIPTIONS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.InvoiceRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='invoices' />,
                              content: "Invoices",
                              to: ROUTE_PATH.INVOICES,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.DepartmentRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='departments' />,
                              content: "Departments",
                              to: ROUTE_PATH.DEPARTMENTS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.LocationRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='location' />,
                              content: "Locations",
                              to: ROUTE_PATH.LOCATIONS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.SpotRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='spot' />,
                              content: "Spots",
                              to: ROUTE_PATH.SPOTS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantUserRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='users' />,
                              content: "Users",
                              to: ROUTE_PATH.TENANT_USERS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantInviteRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='invites' />,
                              content: "Invites",
                              to: ROUTE_PATH.MANAGEMENT_INVITES,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>
                        <AuthorizedElement permissions={[AppPermission.TenantRoleRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='roles' />,
                              content: "Roles",
                              to: ROUTE_PATH.TENANT_ROLES,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        <AuthorizedElement permissions={[AppPermission.ImportRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='export' />,
                              content: "Import data",
                              to: ROUTE_PATH.MANAGEMENT_IMPORTS,
                              onClick: handleItemClick,
                            }}
                          />
                        </AuthorizedElement>

                        {/* Integration */}
                        <AuthorizedElement
                          permissions={[AppPermission.FleetAppAccess]}
                          permissionsAny={[AppPermission.IntegrationApiClientRead]}
                        >
                          <AppNavItem
                            type='itemGroup'
                            itemGroup={{
                              isFromGroup: true,
                              startIcon: (fill) => (
                                <AppNavIcon sx={{ fill: fill }} of='integration' />
                              ),
                              content: "Integration",
                              to: undefined,
                              onClick: handleItemClick,
                              items: (
                                <>
                                  <AuthorizedElement
                                    permissions={[AppPermission.IntegrationApiClientRead]}
                                  >
                                    <AppNavItem
                                      type='item'
                                      item={{
                                        isFromGroup: true,
                                        startIcon: <AppNavIcon of='apiClient' />,
                                        content: "API clients",
                                        to: ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENTS,
                                        onClick: handleItemClick,
                                      }}
                                    />
                                  </AuthorizedElement>

                                  <AuthorizedElement permissions={[AppPermission.WebhookRead]}>
                                    <AppNavItem
                                      type='item'
                                      item={{
                                        startIcon: <AppNavIcon of='webApp' />,
                                        content: "Webhooks",
                                        to: ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOKS,
                                        onClick: handleItemClick,
                                      }}
                                    />
                                  </AuthorizedElement>

                                  <AuthorizedElement
                                    permissions={[AppPermission.TeslaConnectionRead]}
                                  >
                                    <AppNavItem
                                      type='item'
                                      item={{
                                        startIcon: <AppNavIcon of='teslaConnection' />,
                                        content: "Tesla connections",
                                        to: ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTIONS,
                                        onClick: handleItemClick,
                                      }}
                                    />
                                  </AuthorizedElement>
                                </>
                              ),
                            }}
                          />
                        </AuthorizedElement>
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>
            </>
          )}
        </>
      }
    />
  );
}
