import { Stack, Typography } from "@mui/material";

import AppIcon from "../Icons/AppIcon";

interface Props {
  title?: string;
  description?: string;
}

export default function NoFilesAlert({ title = "No files", description }: Props): JSX.Element {
  return (
    <Stack
      direction='column'
      spacing={1}
      color='text.secondary'
      sx={{ alignItems: "center", px: 4, py: 2 }}
    >
      <AppIcon of='noData' fontSize='medium' />
      <Typography component='div' variant='body2'>
        {title}
      </Typography>
      {description && (
        <Typography component='div' variant='caption'>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
