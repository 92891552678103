import { Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import { DataUpdatesHubClientMethodName, EntityType } from "@/core/api/generated";

import VehicleHistoryList from "./VehicleHistoryList";

export default function VehicleHistoryPage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();
  const currentTenant = useCurrentTenant();

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
    },
    {
      deps: [vehicleId],
      skip: !vehicleId,
    },
  );
  const vehicle = vehicleRequest?.data;

  const paginatedHistoryItemsRequest = useApiRequest(
    apiClient.vehicleHistoryApi.apiV1VehiclesVehicleIdHistoryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: vehicleId!,
      vehicleHistoryGetPaginatedDto: {
        offset: 0,
        limit: 100,
      },
    },
    {
      deps: [vehicleId],
      // debouncedDeps: {
      //   deps: [search],
      //   wait: 500,
      //   options: { leading: false, trailing: true },
      // },
    },
  );
  const paginatedHistoryItems = paginatedHistoryItemsRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.VehicleHistory)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedHistoryItemsRequest.handleEntityChanged(data);
    },
  });
  return (
    <ViewLayoutV2
      breadcrumbs={{
        replacements: {
          waitTimeout: 10_000,
          idBreadcrumb: {
            idValue: vehicle?.id || "",
            newTitle: vehicle?.localNumber || "",
          },
        },
      }}
      header={
        <SimpleViewPageHeader
          title={
            <span>
              <>Vehicle {vehicle?.plateNo?.plateNo || vehicle?.identificationNumber} (history)</>
            </span>
          }
        />
      }
    >
      <Stack direction='column' spacing={2}>
        {paginatedHistoryItemsRequest.isLoading && <LinearProgress />}

        {paginatedHistoryItems && (
          <VehicleHistoryList paginatedHistoryItems={paginatedHistoryItems} />
        )}
      </Stack>
    </ViewLayoutV2>
  );
}
