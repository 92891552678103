import { Stack } from "@mui/material";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";

export default function DashboardPage() {
  const authorizationInfo = useAuthorizationInfo();

  return (
    <ViewPageLayout
      goBackLayoutProps={{ displayProps: { breadcrumbs: false } }}
      header={<SimpleViewPageHeader title='Admin dashboard' />}
    >
      <Stack spacing={2}>
        <RouteCategoryIndex
          direction='row'
          childrenLinks={[
            ...(authorizationInfo?.hasAdminAppAccess
              ? [
                  {
                    icon: <AppIcon of='webApp' />,
                    title: "Admin app",
                    to: ROUTE_PATH.APP_AREA_ADMIN,
                  },
                ]
              : []),
            ...(authorizationInfo?.hasFleetAppAccess
              ? [
                  {
                    icon: <AppIcon of='webApp' />,
                    title: "Fleet app",
                    to: ROUTE_PATH.APP_AREA_FLEET_APP,
                  },
                ]
              : []),
            ...(authorizationInfo?.hasFleetCustomerAppAccess
              ? [
                  {
                    icon: <AppIcon of='webApp' />,
                    title: "Fleet customer app",
                    to: ROUTE_PATH.APP_AREA_FLEET_CUSTOMER,
                  },
                ]
              : []),
          ]}
        />
      </Stack>
    </ViewPageLayout>
  );
}
