import { Switch } from "react-router";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AppRoute from "@/common/components/Route/AppRoute";
import { ROUTE_PATH } from "@/common/constants/routing";

import CheckUserStatus from "./CheckUserStatus/CheckUserStatus";
import CreatePassword from "./CreatePassword/CreatePassword";
import EmailEnter from "./EmailEnter/EmailEnter";
import InviteAcceptance from "./InviteAcceptance/InviteAcceptance";
import InviteAlreadyAnsweredPage from "./InviteAlreadyAnsweredPage";
import InviteExpiredCheck from "./InviteExpiredCheck/InviteExpiredCheck";
import InviteExpiredPage from "./InviteExpiredPage";
import InviteNotFoundPage from "./InviteNotFoundPage";
import InviteStartPage from "./InviteStartPage";

export default function Invite() {
  return (
    <Switch>
      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_PERSONAL())}
        render={() => {
          return <InviteExpiredCheck />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_ANONYMOUS())}
        render={() => {
          return <InviteExpiredCheck />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_START())}
        render={() => {
          return <InviteStartPage />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_ENTER_EMAIL())}
        render={() => {
          return <EmailEnter />;
        }}
      />

      <AppRoute
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_ENTER_PASSWORD())}
        render={() => {
          return <CreatePassword />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_ACCEPT())}
        render={() => {
          return <InviteAcceptance />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_USER_PROFILE_CHECK())}
        render={() => {
          return <CheckUserStatus />;
        }}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_EXPIRED())}
        render={() => <InviteExpiredPage />}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_NOT_FOUND())}
        render={() => <InviteNotFoundPage />}
      />

      <AppRoute
        exact
        path={RoutePathBuilder.toDefinition(ROUTE_PATH.AUTH_INVITE_ALREADY_ANSWERED())}
        render={() => <InviteAlreadyAnsweredPage />}
      />
    </Switch>
  );
}
