import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, TenantDto, TenantReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import TenantInline, { TenantInlineProps } from "./TenantInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: TenantDto | TenantReferenceDto | null | undefined;
  entityId: string | null | undefined;
  inlineProps?: Partial<TenantInlineProps>;
}

export default function TenantLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantRead])}
      to={ROUTE_PATH.TENANT_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <TenantInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company"}
    />
  );
}
