import { Box, Stack, Typography, TypographyProps } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import AppLink from "@/common/components/Link/AppLink";
import AppTypography from "@/common/components/Text/AppTypography";

interface Props {
  image?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  primaryActions?: ReactNode;
  secondaryActions?: ReactNode;
  /** @deprecated Use PageTabs.tsx */
  tabs?: ReactNode;
  titleProps?: {
    to?: string;
    typographyProps?: Partial<TypographyProps>;
  };
  subtitleProps?: {
    to?: string;
    typographyProps?: Partial<TypographyProps>;
  };
}

export default function DetailedViewPageHeader({
  image,
  title,
  subtitle,
  primaryActions,
  secondaryActions,
  tabs,
  titleProps,
  subtitleProps,
}: Props) {
  return (
    <Stack direction='column' spacing={1} sx={{ backgroundColor: "transparent" }}>
      <Stack
        direction='row'
        spacing={1}
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
      >
        <Stack
          direction='row'
          sx={{
            minWidth: 0,
          }}
          spacing={1.5}
        >
          {image}

          <Stack
            direction='column'
            spacing={0.5}
            sx={{
              maxWidth: "100%",
            }}
          >
            <AppLink enabled={!_.isEmpty(titleProps?.to)} to={titleProps?.to || ""}>
              <AppTypography
                variant='h1'
                {...titleProps?.typographyProps}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  ...titleProps?.typographyProps?.sx,
                }}
                ellipsing={{ enabled: true, linesToStartEllipsing: 2 }}
              >
                {title}
              </AppTypography>
            </AppLink>

            {subtitle && (
              <AppLink enabled={!_.isEmpty(subtitleProps?.to)} to={subtitleProps?.to || ""}>
                <Typography
                  variant='subtitle1'
                  color='text.secondary'
                  {...subtitleProps?.typographyProps}
                >
                  {subtitle}
                </Typography>
              </AppLink>
            )}
          </Stack>
        </Stack>

        {(primaryActions || secondaryActions) && (
          <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: "flex-end" }}>
            <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
              <Box>{primaryActions}</Box>
              <Box>{secondaryActions}</Box>
            </Stack>
          </Stack>
        )}
      </Stack>

      {tabs}
    </Stack>
  );
}
