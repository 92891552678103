import {
  Badge,
  Box,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { NavDisplayVariants, useNavDisplay } from "@/common/contexts/useNavDisplay";
import { UrlHelper } from "@/common/helpers/url";
import { tenantService } from "@/common/services/tenant";

import AppNavIcon from "../Icons/AppNavIcon";

interface BaseProps {
  startIcon?: ReactNode | ((fill?: string) => ReactNode);
  endIcon?: ReactNode;
  content?: string | ReactNode;
  counters?: {
    title: string;
    counter?: number;
  }[];
  to?: string;
  textTypographyProps?: TypographyProps;
  onClick?: (props: { to?: string }) => void;
  isFromGroup?: boolean;
}

type OwnProps =
  | {
      type: "item";
      item?: BaseProps;
    }
  | {
      type: "itemGroup";
      itemGroup?: BaseProps & {
        items?: ReactNode;
      };
    };

type Props = OwnProps;

const maxItemHeight = "48px";
export default function AppNavItem(props: Props) {
  const { navDisplay } = useNavDisplay();
  const item = props.type === "item" ? props.item : undefined;
  const itemGroup = props.type === "itemGroup" ? props.itemGroup : undefined;
  const countersInfo = useMemo(() => {
    const counters = item?.counters || itemGroup?.counters;
    return {
      title: counters
        ?.map((counter) => counter.counter && `${counter.counter}: ${counter.title}`)
        .filter((x) => !_.isEmpty(x))
        .join("\n") as string | undefined,
      count: counters?.reduce((sum, current) => sum + (current.counter ?? 0), 0),
      maxVisibleCount: 99,
    };
  }, [itemGroup]);

  const startIcon = item?.startIcon || itemGroup?.startIcon;
  const endIcon = item?.endIcon || itemGroup?.endIcon;
  const content = item?.content || itemGroup?.content;
  const to = item?.to || itemGroup?.to;
  const onClick = item?.onClick || itemGroup?.onClick;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  let toWithoutQueryParams = (to || "").replace(/\?.+$/g, "");
  const tenantInfo = tenantService.resolveTenantFromBasePath();
  if (tenantInfo && toWithoutQueryParams) {
    toWithoutQueryParams = `/${tenantInfo.identifier}` + toWithoutQueryParams;
  }
  const pathname = location.pathname;
  const isCurrentRoute = !!toWithoutQueryParams && pathname.startsWith(toWithoutQueryParams);
  const textTypographyProps = ((item?.textTypographyProps ||
    itemGroup?.textTypographyProps) as TypographyProps<
    "span",
    { component?: "span" | undefined }
  >) || { variant: "body1", sx: { fontWeight: isCurrentRoute ? 700 : 500 } };

  const isGroupPreExpanded = content
    ? pathname.split("/").includes(content?.toString().toLowerCase())
    : false;
  const [isGroupExpanded, setIsGroupExpanded] = useState(isGroupPreExpanded);
  const isAbsoluteUrl = UrlHelper.checkValidHttpUrl(to);
  const _to = isAbsoluteUrl ? undefined : to;
  const _href = isAbsoluteUrl ? to : undefined;

  const activeBorderStyle = () => {
    const borderColor = isCurrentRoute
      ? theme.palette.primary.main
      : alpha(theme.palette.text.primary, 0.1);
    if (item?.isFromGroup) {
      return `2px solid ${borderColor}`;
    } else if (!item?.isFromGroup && isCurrentRoute) {
      return `2px solid ${borderColor}`;
    }
    return "";
  };

  if (item) {
    return (
      <>
        <ListItem
          sx={{ pl: 2, pr: 2, pt: 0.5, maxHeight: maxItemHeight }}
          disablePadding
          onClick={(e) => onClick && onClick({ to })}
        >
          <ListItemButton
            component={!to ? Box : (isAbsoluteUrl && Link) || RouterLink}
            to={_to}
            href={_href}
            sx={{
              borderLeft: activeBorderStyle(),
              borderRadius: "2px 10px 10px 2px",
              backgroundColor: isCurrentRoute ? (t) => t.palette.background.paper : "transparent",
              ":hover": {
                backgroundColor: (t) => t.palette.background.paper,
              },
              maxHeight: maxItemHeight,
              pr: 0,
            }}
          >
            {navDisplay === NavDisplayVariants.collapsed && (
              <>
                <ListItemIcon sx={{ my: 0.5 }}>
                  {countersInfo?.title ? (
                    <Badge
                      color='default'
                      variant='dot'
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      sx={{ "& .MuiBadge-badge": { padding: 0 } }}
                    >
                      {_.isFunction(startIcon) ? startIcon() : startIcon}
                    </Badge>
                  ) : (
                    <>{_.isFunction(startIcon) ? startIcon() : startIcon}</>
                  )}
                </ListItemIcon>
              </>
            )}

            {navDisplay === NavDisplayVariants.expanded && (
              <>
                <ListItemIcon sx={{ my: 0.5 }}>
                  {_.isFunction(startIcon) ? startIcon() : startIcon}
                </ListItemIcon>

                <ListItemText primary={content} primaryTypographyProps={textTypographyProps} />
                <Stack direction='row' spacing={0.5}>
                  {countersInfo?.title ? (
                    <Typography
                      sx={{ width: "44px", textAlign: "center" }} // width computed by "expand" icon
                      title={countersInfo.title}
                    >
                      {countersInfo?.count && countersInfo?.count > countersInfo.maxVisibleCount
                        ? `${countersInfo.maxVisibleCount}+`
                        : countersInfo?.count}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {endIcon && endIcon}
                </Stack>
              </>
            )}
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  if (itemGroup) {
    return (
      <>
        <ListItem
          sx={{ pl: 2, pr: 2, pt: 0.5, maxHeight: maxItemHeight }}
          disablePadding
          onClick={(e) => {
            setIsGroupExpanded(!isGroupExpanded);
            onClick && onClick({ to });
          }}
        >
          <ListItemButton
            component={!to ? Box : (isAbsoluteUrl && Link) || RouterLink}
            to={_to}
            href={_href}
            sx={{
              borderLeft: activeBorderStyle(),
              borderRadius: "2px 10px 10px 2px",
              ":hover": {
                backgroundColor: (t) => t.palette.background.paper,
              },
              maxHeight: maxItemHeight,
              p: 0,
            }}
          >
            <>
              <ListItemIcon sx={{ my: 1.5, ml: 2 }}>
                {countersInfo?.title ? (
                  <Badge
                    color={isGroupPreExpanded ? "secondary" : "default"}
                    variant='dot'
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    title={countersInfo.title}
                    sx={{ "& .MuiBadge-badge": { padding: 0 } }}
                  >
                    {_.isFunction(startIcon)
                      ? startIcon(
                          isGroupPreExpanded
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        )
                      : startIcon}
                  </Badge>
                ) : (
                  <>
                    {_.isFunction(startIcon)
                      ? startIcon(
                          isGroupPreExpanded
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        )
                      : startIcon}
                  </>
                )}
              </ListItemIcon>
              {navDisplay === NavDisplayVariants.expanded && (
                <>
                  <ListItemText
                    sx={{
                      color: (t) =>
                        isGroupPreExpanded ? t.palette.primary.main : t.palette.text.primary,
                    }}
                    primary={content}
                    primaryTypographyProps={textTypographyProps}
                  />

                  <Stack
                    direction='row'
                    spacing={0.5}
                    sx={{ alignItems: "center", height: "100%" }}
                  >
                    <Stack direction='row' spacing={0.5}>
                      {endIcon && endIcon}
                    </Stack>
                    <Box
                      sx={{
                        p: 1.5,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        backgroundColor: (t) =>
                          isGroupExpanded ? t.palette.background.paper : "transparent",
                      }}
                    >
                      <AppNavIcon of={isGroupExpanded ? "expandLess" : "expandMore"} />
                    </Box>
                  </Stack>
                </>
              )}
            </>
          </ListItemButton>
        </ListItem>
        <Collapse in={isGroupExpanded} timeout='auto' unmountOnExit>
          <List
            sx={{
              pl: navDisplay === NavDisplayVariants.expanded ? 2 : 0,
              transition: "padding 0.3s ease-in-out",
            }}
            component='div'
            disablePadding
          >
            {itemGroup.items}
          </List>
        </Collapse>
      </>
    );
  }

  return null;
}
