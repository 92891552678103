import { LoadingButton } from "@mui/lab";
import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { Formik, getIn } from "formik";

import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { renderIf } from "@/common/helpers/render/renderIf";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminCreateNotificationDto, NotificationChannel } from "@/core/api/generated";

import CreateNotificationFormEmailInputs from "./Inputs/EmailInputs";
import CreateNotificationFormInAppInputs from "./Inputs/InAppInputs";
import CreateNotificationFormPushInputs from "./Inputs/PushInputs";
import CreateNotificationFormSmsInputs from "./Inputs/SmsInputs";
import CreateNotificationFormWebPushInputs from "./Inputs/WebPushInputs";

export type CreateNotificationFormValues = BaseFormikValues & {
  channel: NotificationChannel;
  inApp?: AdminCreateNotificationDto["inApp"];
  email?: AdminCreateNotificationDto["email"];
  sms?: AdminCreateNotificationDto["sms"];
  push?: AdminCreateNotificationDto["push"];
  webPush?: AdminCreateNotificationDto["webPush"];
};

const CreateForm = () => {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  return (
    <Formik<CreateNotificationFormValues>
      initialValues={{
        channel: NotificationChannel.InApp,
        inApp: { users: [{ userId: "", tenantId: "" }], title: "", body: "" },
        email: {
          users: [{ to: "" }],
          subject: "",
          defaultTemplateData: {
            title: "",
            logo: { imageSource: "", text: "" },
            sections: [{ text: "" }],
          },
        },
        sms: { users: [{ to: "" }], body: "" },
        push: { users: [{ userId: "", tenantId: "" }], title: "", body: "" },
        webPush: { users: [{ userId: "", tenantId: "" }], title: "", body: "" },
        submit: "",
      }}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          await apiClient.adminNotificationsApi.apiV1AdminNotificationsPost({
            adminCreateNotificationDto: {
              channel: values.channel,
              inApp: values.inApp,
              email: values.email,
              sms: values.sms,
              push: values.push,
              webPush: values.webPush,
            },
          });
          enqueueSnackbar("Notification successfully send.", {
            variant: "success",
          });

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }

        setSubmitting(false);
      }}
    >
      {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values, setFieldValue }) => {
        return (
          <Box component='form' noValidate onSubmit={handleSubmit}>
            <FormControl margin='dense' fullWidth error={Boolean(false)}>
              <InputLabel>Channel</InputLabel>
              <ApiEnumSelect
                type='NotificationChannel'
                value={values.channel}
                onChange={(newValue) => {
                  setFieldValue("channel", newValue);
                  setFieldValue("inApp", {
                    users: [{ userId: "", tenantId: "" }],
                    title: "",
                    body: "",
                  });
                  setFieldValue("email", {
                    users: [{ to: "" }],
                    subject: "",
                    defaultTemplateData: {
                      title: "",
                      logo: { imageSource: "", text: "" },
                      sections: [{ text: "" }],
                    },
                  });
                  setFieldValue("sms", { users: [{ to: "" }], body: "" });
                  setFieldValue("push", {
                    users: [{ userId: "", tenantId: "" }],
                    title: "",
                    body: "",
                    imageUrl: undefined,
                  });
                  setFieldValue("webPush", {
                    users: [{ userId: "", tenantId: "" }],
                    title: "",
                    body: "",
                    imageUrl: undefined,
                  });
                }}
                selectProps={{
                  name: "channel",
                  onBlur: handleBlur,
                  required: true,
                }}
                filterOptions={(x) => x.value !== NotificationChannel.None}
              />
              <FormHelperText error={Boolean(touched.channel && errors.channel)}>
                {getIn(errors, "channel")}
              </FormHelperText>
            </FormControl>

            {renderIf()
              .if(values.channel === NotificationChannel.InApp)
              .then(<CreateNotificationFormInAppInputs />)
              .elseif(values.channel === NotificationChannel.Email)
              .then(<CreateNotificationFormEmailInputs />)
              .elseif(values.channel === NotificationChannel.Sms)
              .then(<CreateNotificationFormSmsInputs />)
              .elseif(values.channel === NotificationChannel.Push)
              .then(<CreateNotificationFormPushInputs />)
              .elseif(values.channel === NotificationChannel.WebPush)
              .then(<CreateNotificationFormWebPushInputs />)
              .render()}

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              sx={{ flex: 1, mt: 1 }}
              fullWidth
              color='primary'
              disabled={isSubmitting}
              loading={isSubmitting}
              type='submit'
              variant='contained'
            >
              Send
            </LoadingButton>
          </Box>
        );
      }}
    </Formik>
  );
};

export default CreateForm;
