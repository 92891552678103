import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function ContractIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 4.125C7.5 3.918 7.668 3.75 7.875 3.75H10.125C10.332 3.75 10.5 3.918 10.5 4.125V5.25H7.5V4.125ZM5.25 15.75H12.75V5.25H12V4.125C12 3.0915 11.1585 2.25 10.125 2.25H7.875C6.8415 2.25 6 3.0915 6 4.125V5.25H5.25V15.75ZM14.25 5.25V15.75C15.4905 15.75 16.5 14.7405 16.5 13.5V7.5C16.5 6.2595 15.4905 5.25 14.25 5.25ZM1.5 7.5C1.5 6.2595 2.5095 5.25 3.75 5.25V15.75C2.5095 15.75 1.5 14.7405 1.5 13.5V7.5Z'
      />
      <mask
        id='mask0_213_2852'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='2'
        width='16'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5 4.125C7.5 3.918 7.668 3.75 7.875 3.75H10.125C10.332 3.75 10.5 3.918 10.5 4.125V5.25H7.5V4.125ZM5.25 15.75H12.75V5.25H12V4.125C12 3.0915 11.1585 2.25 10.125 2.25H7.875C6.8415 2.25 6 3.0915 6 4.125V5.25H5.25V15.75ZM14.25 5.25V15.75C15.4905 15.75 16.5 14.7405 16.5 13.5V7.5C16.5 6.2595 15.4905 5.25 14.25 5.25ZM1.5 7.5C1.5 6.2595 2.5095 5.25 3.75 5.25V15.75C2.5095 15.75 1.5 14.7405 1.5 13.5V7.5Z'
        />
      </mask>
      <g mask='url(#mask0_213_2852)'>
        <rect width='18' height='18' />
      </g>
    </SvgIcon>
  );
});
