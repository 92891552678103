import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik } from "formik";
import { useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUpdateSubscriptionPlanDto, BillingPeriod } from "@/core/api/generated/api";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: (theme.shadows as string[])[1],
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  height: "100%",
  overflow: "auto",
}));

export default function SubscriptionPlanCreateUpdatePage() {
  const mounted = useMounted();
  const { subscriptionPlanId } = useParams<{ subscriptionPlanId: string }>();

  const planRequest = useApiRequest(
    apiClient.adminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansSubscriptionPlanIdGet,
    {
      subscriptionPlanId: subscriptionPlanId!,
    },
    {
      skip: !subscriptionPlanId,
    },
  );
  const plan = planRequest?.data;

  if (!plan || planRequest.isLoading) {
    return <>Loading...</>;
  }

  return (
    <Formik<AdminUpdateSubscriptionPlanDto & BaseFormikValues>
      initialValues={{
        name: plan?.name || "",
        description: plan?.description || "",
        enabled: plan?.enabled || false,
        sortOrder: plan?.sortOrder || 0,
        prices: plan?.prices || [],

        // contacts: paymentProfile.contacts || new Array<PaymentContactInputDto>(),

        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // name: Yup.string().required("Name is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await apiClient.adminSubscriptionPlansApi.apiV1AdminSubscriptionsPlansSubscriptionPlanIdPut(
            {
              subscriptionPlanId: subscriptionPlanId,
              adminUpdateSubscriptionPlanDto: {
                ...values,
                prices: values.prices,
              },
            },
          );

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: Error | any) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={<SimpleViewPageHeader title={"Edit subscription plan"} />}
          >
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    margin='normal'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name}
                    variant='outlined'
                  />
                  <AppTextArea
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    multiline={false}
                    mode='description'
                    margin='normal'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant='outlined'
                  />

                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='enabled'
                          checked={values.enabled}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label='Enabled'
                    />
                  </FormGroup>

                  <TextField
                    error={Boolean(touched.sortOrder && errors.sortOrder)}
                    fullWidth
                    helperText={touched.sortOrder && errors.sortOrder}
                    label='Sort order'
                    margin='normal'
                    name='sortOrder'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputMode='numeric'
                    value={values.sortOrder}
                    variant='outlined'
                  />

                  <Box sx={{ mt: 1 }}>
                    <Typography component='div' sx={{ fontWeight: "bold" }}>
                      Prices
                    </Typography>
                    <List>
                      {values.prices!.map((price, index) => (
                        <ListItem
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                setFieldValue(
                                  "prices",
                                  values.prices!.filter((x, i) => i !== index),
                                );
                              }}
                            >
                              <AppIcon of='delete' />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <AppIcon of='money' />
                          </ListItemIcon>
                          <Stack direction='row' spacing={1}>
                            <TextField
                              size='small'
                              label='Price'
                              margin='normal'
                              name={`prices[${index}].price`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              inputMode='decimal'
                              value={values.prices![index]?.price}
                              variant='outlined'
                              sx={{ margin: 0 }}
                            />
                            <FormControl>
                              <InputLabel>Period</InputLabel>
                              <Select
                                size='small'
                                label='Period'
                                name={`prices[${index}].period`}
                                value={values.prices![index]?.period}
                                onChange={handleChange}
                              >
                                {Object.values(BillingPeriod).map((period, index2) => (
                                  <MenuItem key={index2} value={period}>
                                    {period}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>
                        </ListItem>
                      ))}
                    </List>
                    <Button
                      sx={{ mt: 1 }}
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      onClick={() => {
                        setValues({
                          ...values,
                          prices: [
                            ...values.prices!,
                            {
                              id: null,
                              price: 0,
                              period: BillingPeriod.Month,
                            },
                          ],
                        });
                      }}
                    >
                      Add price
                    </Button>
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Button
                    sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                    color='primary'
                    disabled={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
