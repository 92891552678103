import { createContext, useContext } from "react";

import { TypedEventEmitter } from "../eventEmmiters/typedEventEmitter";

export type CustomDataGridContextEventsMap = {
  // list of supported events
  addFieldFilterRequested: {
    field: string;
  };
};

export class CustomDataGridContextValue extends TypedEventEmitter<CustomDataGridContextEventsMap> {
  public requestAddFieldFilter(
    params: CustomDataGridContextEventsMap["addFieldFilterRequested"],
  ): void {
    this.emit("addFieldFilterRequested", params);
  }
}

export type CustomDataGridContextType = CustomDataGridContextValue;

// export const customDataGridContextValue = new CustomDataGridContextValue();

export const CustomDataGridContext = createContext<CustomDataGridContextType>(
  new CustomDataGridContextValue(),
);

export const useCustomDataGridContext = (): CustomDataGridContextType => {
  const customDataGridContext = useContext(CustomDataGridContext);

  if (!customDataGridContext) {
    throw new Error(
      "useCustomDataGridContext() has to be used within <CustomDataGridContext.Provider>",
    );
  }

  return customDataGridContext;
};

export const useCustomDataGridContextOptional = (): CustomDataGridContextType | null => {
  const customDataGridContext = useContext(CustomDataGridContext);
  return customDataGridContext;
};
