import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import InlineCode from "@/common/components/Code/InlineCode";

export default function DevRadioGroups() {
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [selectedValue1, setSelectedValue1] = React.useState("a");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue1(event.target.value);
  };

  const controlProps1 = (item: string) => ({
    checked: selectedValue1 === item,
    onChange: handleChange1,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Radio Groups
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Radio Groups</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue='female'
              name='radio-buttons-group'
            >
              <FormControlLabel value='female' control={<Radio />} label='Female' />
              <FormControlLabel value='male' control={<Radio />} label='Male' />
              <FormControlLabel value='other' control={<Radio />} label='Other' />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction='row' spacing={1}>
          <FormControl>
            <FormLabel id='demo-row-radio-buttons-group-label'>Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
            >
              <FormControlLabel value='female' control={<Radio />} label='Female' />
              <FormControlLabel value='male' control={<Radio />} label='Male' />
              <FormControlLabel value='other' control={<Radio />} label='Other' />
              <FormControlLabel value='disabled' disabled control={<Radio />} label='other' />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction='row' spacing={1}>
          <Radio {...controlProps("a")} size='small' />
          <Radio {...controlProps("b")} />
          <Radio
            {...controlProps("c")}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 28,
              },
            }}
          />
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Radio {...controlProps1("a")} />
          <Radio {...controlProps1("b")} color='secondary' />
          <Radio {...controlProps1("c")} color='success' />
          <Radio {...controlProps1("d")} color='default' />
          <Radio
            {...controlProps1("e")}
            sx={{
              color: "pink",
              "&.Mui-checked": {
                color: "pink",
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
