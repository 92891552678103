import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import DamageDetectionCreateUpdate, {
  DamageDetectionCreateUpdateProps,
} from "./DamageDetectionCreateUpdate";

export interface OwnProps {
  createUpdateProps: DamageDetectionCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function DamageDetectionCreateUpdateModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.damageDetectionId;

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} damage detection
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <DamageDetectionCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
