import { Stack, SxProps, Theme } from "@mui/material";

import { GeneralApprovalStatusInfoDto } from "@/core/api/generated";

import Datetime from "../../../Datetime/Datetime";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";

export interface TagDisplayProps {
  approval?: GeneralApprovalStatusInfoDto;
  sx?: SxProps<Theme>;
}

export default function GeneralApprovalStatusInfoDisplay({ approval, sx }: TagDisplayProps) {
  if (!approval) {
    return null;
  }
  return (
    <Stack sx={sx}>
      <Stack direction='row' spacing={1}>
        <InlineApiEnumValue type='ApprovalStatus' value={approval.status} />
        <ApiEnumIcon type='ApprovalStatus' value={approval.status} />
      </Stack>
      <Datetime datetime={approval.updatedAt} />
    </Stack>
  );
}
