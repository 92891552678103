import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { AppPermission } from "@/core/api/generated";

import ContractCustomerCommunicationInfo, {
  ContractCustomerCommunicationInfoProps,
} from "./ContractCustomerCommunicationInfo";

export interface OwnProps {
  contractCustomerCommunicationInfoProps: ContractCustomerCommunicationInfoProps;
}

type Props = OwnProps & DialogProps;

export default function ContractCustomerCommunicationInfoModal({
  contractCustomerCommunicationInfoProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Customer communication info
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]} withAlert>
            <ContractCustomerCommunicationInfo {...contractCustomerCommunicationInfoProps} />
          </AuthorizedElement>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
