import { Chip, ChipProps } from "@mui/material";

import AppTooltip from "@/common/components/AppTooltip";

interface Props extends Partial<ChipProps> {
  car2DbId: string | null | undefined;
}

export default function EntitySourceIsCar2DbChip({ car2DbId, ...otherProps }: Props) {
  if (!car2DbId) {
    return null;
  }
  return (
    <AppTooltip
      title={`This entity is sourced from Car2DB (#${car2DbId}). You can edit it manually, but then sync with Car2DB will be stopped.`}
    >
      <Chip variant='outlined' size='small' color='default' label='Car2DB' {...otherProps} />
    </AppTooltip>
  );
}
