import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, PoolItemDto, PoolItemReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import PoolItemInline, { PoolItemInlineProps } from "./PoolItemInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: PoolItemDto | PoolItemReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<PoolItemInlineProps>;
}

export default function PoolItemLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.PoolRead])}
      to={ROUTE_PATH.POOL_ITEM_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <PoolItemInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
    />
  );
}
