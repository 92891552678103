import { SxProps, Theme } from "@mui/material";
import _ from "lodash";

import { GeneralCurrencyDto, GeneralCurrencyInputDto } from "@/core/api/generated";

import CurrencyCodeDisplay from "../../../Form/Display/CurrencyCodeDisplay";

interface Props {
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto;
  sx?: SxProps<Theme>;
}

export default function GeneralCurrencyDisplay({ sx, currency }: Props) {
  if (_.isNil(currency)) {
    return null;
  }
  return <CurrencyCodeDisplay currencyCode={currency?.code} sx={sx} />;
}
