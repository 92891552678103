import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission } from "@/core/api/generated";

export default function IntegrationPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Integration' />}>
      <RouteCategoryIndex
        direction='column'
        childrenLinks={[
          {
            permissions: [AppPermission.IntegrationApiClientRead],
            icon: <AppIcon of='apiClient' />,
            title: "API clients",
            to: ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENTS,
          },
          {
            permissions: [AppPermission.WebhookRead],
            icon: <AppIcon of='webApp' />,
            title: "Webhooks",
            to: ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOKS,
          },
          {
            permissions: [AppPermission.TeslaConnectionRead],
            icon: <AppIcon of='teslaConnection' />,
            title: "Tesla connections",
            to: ROUTE_PATH.MANAGEMENT_INTEGRATION_TESLA_CONNECTIONS,
          },
        ]}
      />
    </ViewLayoutV2>
  );
}
