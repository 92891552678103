import { apiClient } from "@/core/api/ApiClient";
import { EntityType, VehicleDamageDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: VehicleDamageDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function VehicleDamageDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.VehicleDamage}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) => {
        apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesVehicleDamageIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          vehicleId: params.entity.vehicle?.id || "",
          vehicleDamageId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
