import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import WebhookPaginatedList from "@/common/components/Entity/Webhook/ListView/WebhookPaginatedList";

export default function WebhooksPage() {
  return (
    <>
      <WebhookPaginatedList />
    </>
  );
}
