import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { AccessoryDto } from "@/core/api/generated";

interface Props {
  accessory: AccessoryDto;
}

export default function OverviewTabContent({ accessory }: Props) {
  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack spacing={1}>
          <FieldValue label='Type' isEmpty={!accessory.type}>
            <InlineApiEnumValue type='AccessoryType' value={accessory.type} />
          </FieldValue>

          <FieldValue label='Name' isEmpty={!accessory.name}>
            {accessory.name}
          </FieldValue>

          <FieldValue label='Description' isEmpty={!accessory.description}>
            {accessory.description}
          </FieldValue>

          <FieldValue label='Vehicle Types'>{accessory.vehicleTypes?.join(",")}</FieldValue>

          <FieldValue label='For all vehicles'>
            {accessory.isForAllVehicles ? "yes" : "no"}
          </FieldValue>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
