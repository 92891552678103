import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { MaintenanceDto } from "@/core/api/generated";

import MaintenanceAutocomplete, { MaintenanceAutocompleteProps } from "./MaintenanceAutocomplete";
import MaintenanceCreateUpdate, { MaintenanceCreateUpdateProps } from "./MaintenanceCreateUpdate";
import MaintenanceCreateUpdateModal from "./MaintenanceCreateUpdateModal";

interface OwnProps {
  autocompleteProps: MaintenanceAutocompleteProps;
  createUpdateProps?: Pick<MaintenanceCreateUpdateProps, "defaultValues">;
  createFormPlacement?: "modal" | "page";
  onCreateStart?: () => void;
  onCreate: (newValue: MaintenanceDto) => void;
}

type Props = OwnProps;

export default function MaintenanceAutocompleteOrCreate({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  const [isCreate, setIsCreate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    MaintenanceCreateUpdateProps["defaultValues"] | undefined
  >(undefined);

  return (
    <Stack spacing={2}>
      {/* 1. Search */}
      {!isCreate && (
        <Box>
          <MaintenanceAutocomplete
            {...autocompleteProps}
            withCreate
            createOptionTitle={(inputValue) => `Create new maintenance "${inputValue}"`}
            onCreate={(newOption) => {
              setDefaultValues({
                ...createUpdateProps?.defaultValues,
              });
              setIsCreate(true);
              onCreateStart && onCreateStart();
            }}
          />
        </Box>
      )}

      {/* 2. Create */}
      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && (
                <MaintenanceCreateUpdateModal
                  open={isCreate}
                  onClose={() => setIsCreate(false)}
                  createUpdateProps={{
                    defaultValues: defaultValues,
                    onCreate: onCreate,
                    onSave: (newValue) => {
                      setIsCreate(false);
                    },
                  }}
                />
              )}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new maintenance
                    </Typography>
                  </Stack>

                  <MaintenanceCreateUpdate
                    defaultValues={defaultValues}
                    onCreate={onCreate}
                    onSave={(newValue) => {
                      setIsCreate(false);
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}
