import { Box } from "@mui/material";
import { ReactNode } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { VehicleModificationDto, VehicleModificationReferenceDto } from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";

interface Props {
  modification: VehicleModificationDto | VehicleModificationReferenceDto | null | undefined;
  withIcon?: boolean;
  withLink?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

export default function VehicleModificationInline({
  modification,
  withIcon = true,
  withLink = true,
  withTooltip = false,
  tooltipTitle,
}: Props) {
  if (!modification) {
    return null;
  }

  const full = modification as VehicleModificationDto;
  const ref = modification as VehicleModificationReferenceDto;

  return (
    <AppTooltip enabled={withTooltip} title={tooltipTitle || "Vehicle modification"}>
      <AppLink
        // enabled={withLink}
        // to={
        //   (withLink &&
        //     ROUTE_PATH.VEHICLE_VISUAL_MODEL_VIEW({ vehicleVisualModificationId: visualModification.id! })) ||
        //   ""
        // }
        enabled={false}
        to=''
        sx={{
          display: "inline-flex",
          alignItems: "center",
          fontSize: "inherit",
          underline: "none",
        }}
      >
        {withIcon && <AppIcon of='vehicleModification' inText sx={{ mr: 1 }} />}

        <Box component='span'>{modification.name}</Box>
      </AppLink>
    </AppTooltip>
  );
}
