import AppIcon, { AppIconOf, AppIconProps } from "./AppIcon";

export default function AppNavIcon(iconProps: Partial<AppIconProps>) {
  return (
    <AppIcon
      fontSize='small'
      of={iconProps.of as AppIconOf}
      sx={{ fill: (t) => t.palette.text.primary }}
      {...iconProps}
    />
  );
}
