import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { CommonViewParamsHelper } from "@/common/helpers/commonViewParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import {
  CommonViewParamsState,
  CommonViewParamsStatePersistenceProps,
  CommonViewParamsTabVisibilityMap,
} from "@/common/ts/commonViewParams";
import { InternalQueryStringParamName } from "@/config/config";

import { useQueryParams } from "../useQueryParams";

const defaultStatePersistenceProps: Partial<CommonViewParamsStatePersistenceProps> = {};

interface Props {
  /** NB: values from the persisted state are prioritized over default values. */
  // defaultValues?: {
  //   tempPlaceHolder: string;
  // };
  /** State persistence is enabled if non-empty value passed. */
  statePersistence?: CommonViewParamsStatePersistenceProps;
}

export interface UseCommonViewParamsHookResult {
  isTabManagementEnabled: boolean;
  activeTabId: string | undefined;
  tabVisibilityMap: CommonViewParamsTabVisibilityMap | undefined;
  setActiveTab: (newTabId: string | undefined) => void;
  setTabVisibility: (newValue: { tabId: string; isVisible: boolean }) => void;
  setTabVisibilityMap: (newValue: CommonViewParamsTabVisibilityMap) => void;
}

/** Provides common parameters for a view (e.g. page, table/paginated/list view, entity view, component, etc). */
export function useCommonViewParams({
  // defaultValues,
  statePersistence,
}: Props = {}): UseCommonViewParamsHookResult {
  const queryParams = useQueryParams<GeneralQueryParams>({ mode: "current" });

  const statePersistenceComputed = useMemo(() => {
    if (!statePersistence) {
      return undefined;
    }
    const isEnabled = statePersistence.isEnabled ?? true;
    return {
      ...defaultStatePersistenceProps,
      ...statePersistence,
      isEnabled: isEnabled,
      // tab persistence:
      // - enable in page views and disable in tab
      // - by default persist to URL only
      isActiveTab: isEnabled && (statePersistence.isActiveTab ?? false),
      isActiveTabToUrl:
        isEnabled &&
        (statePersistence.isActiveTabToUrl ??
          (statePersistence.viewVariant &&
            statePersistence.viewVariant === ViewLayoutVariant.Page) ??
          true),
      isTabVisibility: isEnabled && (statePersistence.isTabVisibility ?? true),
    };
  }, []);

  const persistedState = useMemo<CommonViewParamsState | undefined>(
    () =>
      statePersistenceComputed?.isEnabled
        ? CommonViewParamsHelper.getPersistedState(statePersistenceComputed.persistenceKey)
        : undefined,
    [statePersistenceComputed],
  );

  const initialState = useMemo<CommonViewParamsState | undefined>(() => persistedState, []);

  const initialActiveTabId = useMemo<string | undefined>(() => {
    let tabId =
      (statePersistenceComputed?.isActiveTabToUrl ? queryParams?.activeTabId : undefined) ||
      (statePersistenceComputed?.isActiveTab ? initialState?.tab?.activeTabId : undefined) ||
      undefined;
    // ensure initial tab is visible
    if (
      tabId &&
      initialState?.tab?.visibilityMap &&
      initialState?.tab?.visibilityMap[tabId] === false
    ) {
      tabId = undefined;
    }

    return tabId;
  }, []);

  const [activeTabId, seActiveTabId] = useState<string | undefined>(initialActiveTabId);
  const [tabVisibilityMap, setTabVisibilityMap] = useState<
    CommonViewParamsTabVisibilityMap | undefined
  >(initialState?.tab?.visibilityMap ?? undefined);

  // compute state and not use useState to avoid racing conditions when setting the state in parallel
  const stateComputed = useMemo<CommonViewParamsState>(
    () => ({
      ...initialState,
      tab: {
        ...initialState?.tab,
        activeTabId: activeTabId,
        visibilityMap: tabVisibilityMap,
      },
    }),
    [activeTabId, tabVisibilityMap],
  );

  const persistState = useCallback(
    (newState: CommonViewParamsState) => {
      if (statePersistenceComputed?.isEnabled) {
        // pick only what is enabled
        const newState2: CommonViewParamsState = {
          ...newState,
          tab: newState.tab
            ? {
                activeTabId: statePersistenceComputed?.isActiveTab
                  ? newState.tab.activeTabId
                  : undefined,
                visibilityMap: statePersistenceComputed?.isTabVisibility
                  ? newState.tab.visibilityMap
                  : undefined,
              }
            : undefined,
        };

        console.log(`CommonViewParams. Persist state.`, {
          statePersistenceComputed,
          newState: newState2,
        });
        CommonViewParamsHelper.persistState(statePersistenceComputed.persistenceKey, newState2);
      }
    },
    [statePersistenceComputed],
  );

  const persistStateDebounce = useCallback(
    _.debounce(persistState, 500, { leading: false, trailing: true }),
    [persistState],
  );

  // persist state when it changes
  useEffect(() => {
    persistStateDebounce(stateComputed);
  }, [stateComputed]);

  // store active tab in URL also
  useEffect(() => {
    if (statePersistenceComputed?.isActiveTabToUrl && activeTabId !== queryParams.activeTabId) {
      const newUrl = new URL(window.location.href);
      if (activeTabId) {
        newUrl.searchParams.set(InternalQueryStringParamName.ActiveTabId, activeTabId || "");
      } else {
        newUrl.searchParams.delete(InternalQueryStringParamName.ActiveTabId);
      }
      window.history.replaceState({}, "", newUrl);
    }
  }, [statePersistenceComputed, activeTabId]);

  const handleSetActiveTab = (newTabId: string | undefined) => {
    seActiveTabId(newTabId);
  };

  const handleSetTabVisibilityMap = (newValue: CommonViewParamsTabVisibilityMap) => {
    const newValue2 = _.pickBy(newValue, (v) => v === false); // only need to persist which tabs are invisible
    setTabVisibilityMap(newValue2);
  };

  return {
    isTabManagementEnabled: statePersistenceComputed?.isTabVisibility ?? false,
    activeTabId,
    tabVisibilityMap: { ...tabVisibilityMap },
    setActiveTab: handleSetActiveTab,
    setTabVisibility: (newValue: { tabId: string; isVisible: boolean }) => {
      const newVisibilityMap = {
        ...tabVisibilityMap,
        [newValue.tabId]: newValue.isVisible,
      };
      handleSetTabVisibilityMap(newVisibilityMap);
    },
    setTabVisibilityMap: handleSetTabVisibilityMap,
  };
}
