import { AxiosResponse } from "axios";

import { ValidationProblemDetails } from "@/core/api/generated";

import ServerBaseErrorModel from "./ServerBaseErrorModel";

class ServerNotFoundErrorModel extends ServerBaseErrorModel {
  constructor(response: AxiosResponse, message: string, problemDetails?: ValidationProblemDetails) {
    super(response, message, problemDetails);
  }
}

export default ServerNotFoundErrorModel;
