import AppPopover, { AppPopoverProps } from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import SortDefinitionInput, { SortDefinitionInputProps } from "./SortDefinitionInput";

interface Props extends Omit<AppPopoverProps, "children"> {
  sortDefinitionInputProps: SortDefinitionInputProps;
}

export default function SortPopover({ sortDefinitionInputProps, ...popoverProps }: Props) {
  return (
    <AppPopover {...popoverProps}>
      <AppPopoverContent>
        <SortDefinitionInput
          {...sortDefinitionInputProps}
          sx={{
            minWidth: {
              xxs: undefined,
              md: 400,
            },
            ...sortDefinitionInputProps?.sx,
          }}
        />
      </AppPopoverContent>
    </AppPopover>
  );
}
