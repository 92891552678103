import { EncodingHelper } from "./encoding";

/** Data in query string */
export interface HighlightPropsInQueryString {
  /** base64 encoded JSON object. */
  highlightPropsEnc?: string | null;
}

/** FE data */
export type BaseHighlightProps = Record<string, string | boolean | number | null | undefined>;

export class HighlightHelper {
  /** Encodes props to be used as query string param value. */
  public static encodePropsForQueryString<HighlightProps extends BaseHighlightProps>(
    data: HighlightProps | null | undefined,
  ): string | null | undefined {
    if (!data) {
      return null;
    }

    const json = JSON.stringify(data);
    const encoded = EncodingHelper.toBase64(json);
    return encoded;
  }

  /** Decodes props from query string param value. */
  public static decodePropsFromQueryString<HighlightProps extends BaseHighlightProps>(
    encoded: string,
  ): HighlightProps | undefined {
    try {
      const json = EncodingHelper.fromBase64(encoded);
      const data = JSON.parse(json) as HighlightProps;
      return data;
    } catch (err) {
      console.error(
        `Error decoding highlight props from query string. Encoded: "${encoded}". Error:`,
        err,
      );
      return undefined;
    }
  }
}
