import UserProfileAppGuard from "./Guards/UserProfileAppGuard";

/** Renders all app guards. */
export default function AppGuards() {
  return (
    <>
      {/* Disable as it breaks invites logic by redirecting user from invite pages. */}
      {/* <UserProfileAppGuard /> */}
    </>
  );
}
