import { Box, Container, Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

/** Layout for app error pages. */
export default function ErrorLayout({ children }: Props) {
  const theme = useTheme();

  return (
    <Container maxWidth='md'>
      <Box sx={{ py: 5 }}>{children}</Box>
    </Container>
  );
}
