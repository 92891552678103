import { Box, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import TenantLink from "@/common/components/Entity/Tenant/TenantLink";
import EntityLink from "@/common/components/Entity/components/EntityLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { TypeHelper } from "@/common/helpers/type";
import { GeneralStatusHistoryDto, GeneralStatusSubjectType } from "@/core/api/generated";

import GeneralStatusEnumValueInline from "../../../common/GeneralStatusEnumValueInline";
import GeneralStatusHistoryLink from "../../GeneralStatusHistoryLink";
import { GeneralStatusHistoryViewDisplayProps } from "../GeneralStatusHistoryView";

interface Props {
  generalStatusHistory: GeneralStatusHistoryDto;
  displayProps: Partial<GeneralStatusHistoryViewDisplayProps>;
}

export default function OverviewTabContent({ generalStatusHistory, displayProps }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <Stack direction='column' spacing={1}>
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={generalStatusHistory} />}
          />

          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue
                label='ID'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.id)}
              >
                {generalStatusHistory.id}
              </FieldValue>

              <FieldValue
                label='Company'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.tenantId)}
              >
                <TenantLink entityId={generalStatusHistory.tenantId} entity={undefined} />
              </FieldValue>

              {!displayProps?.isForSubjectEntity && (
                <>
                  <FieldValue
                    label='Subject type'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.subject?.type)}
                  >
                    <InlineApiEnumValue
                      type='GeneralStatusSubjectType'
                      direction='row'
                      value={generalStatusHistory.subject?.type}
                      withHelperTooltip
                    />
                  </FieldValue>
                  {generalStatusHistory.subject?.type === GeneralStatusSubjectType.Arbitrary && (
                    <>
                      <FieldValue
                        label='Subject identifier'
                        direction='row'
                        isEmpty={TypeHelper.isEmpty(generalStatusHistory.subject?.identifier)}
                      >
                        {generalStatusHistory.subject?.identifier}
                      </FieldValue>
                    </>
                  )}
                  {generalStatusHistory.subject?.type === GeneralStatusSubjectType.Entity && (
                    <>
                      <FieldValue
                        label='Subject entity type'
                        direction='row'
                        isEmpty={TypeHelper.isEmpty(generalStatusHistory.subject?.entityType)}
                      >
                        <InlineApiEnumValue
                          type='EntityType'
                          direction='row'
                          value={generalStatusHistory.subject?.entityType}
                          withHelperTooltip
                        />
                      </FieldValue>

                      <FieldValue
                        label='Subject entity'
                        direction='row'
                        isEmpty={TypeHelper.isEmpty(generalStatusHistory.subject?.entityId)}
                      >
                        {generalStatusHistory.subject?.entityType &&
                          generalStatusHistory.subject?.entityId && (
                            <EntityLink
                              entityType={generalStatusHistory.subject?.entityType}
                              entityId={generalStatusHistory.subject?.entityId}
                              entity={undefined}
                            />
                          )}
                      </FieldValue>
                    </>
                  )}
                </>
              )}

              <FieldValue
                label='Status identifier'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.statusIdentifier)}
              >
                <GeneralStatusEnumValueInline
                  statusEnumTypeName='GeneralStatusIdentifier'
                  status={generalStatusHistory.statusIdentifier}
                />
              </FieldValue>

              <FieldValue
                label='Status'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.status)}
              >
                <GeneralStatusEnumValueInline
                  statusEnumTypeName={generalStatusHistory.statusEnumTypeName}
                  status={generalStatusHistory.status}
                />
              </FieldValue>

              <FieldValue
                label='Previous status'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.prevStatus)}
              >
                <GeneralStatusEnumValueInline
                  statusEnumTypeName={generalStatusHistory.statusEnumTypeName}
                  status={generalStatusHistory.prevStatus}
                />
              </FieldValue>

              <FieldValue
                label='Previous status history record'
                direction='row'
                isEmpty={TypeHelper.isEmpty(generalStatusHistory.prevStatusHistoryId)}
              >
                <GeneralStatusHistoryLink
                  entityId={generalStatusHistory.prevStatusHistoryId}
                  entity={undefined}
                />
              </FieldValue>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: 2,
                  rowGap: 1,
                }}
              >
                <Stack spacing={1}>
                  <FieldValue
                    label='Started at'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.startedAt)}
                  >
                    <Datetime datetime={generalStatusHistory.startedAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue
                    label='Started by'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.startedBy)}
                  >
                    <GeneralByWhoDisplay who={generalStatusHistory.startedBy} />
                  </FieldValue>

                  <FieldValue
                    label='Start reason'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.startReason)}
                  >
                    {generalStatusHistory.startReason}
                  </FieldValue>

                  <FieldValue
                    label='Start mode'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.startAutoMode)}
                  >
                    <InlineApiEnumValue
                      type='AutomationCreateMode'
                      value={generalStatusHistory.startAutoMode}
                      withHelperTooltip
                    />
                  </FieldValue>
                </Stack>

                <Stack spacing={1}>
                  <FieldValue
                    label='Ended at'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.endedAt)}
                  >
                    <Datetime datetime={generalStatusHistory.endedAt} withDurationFromNow />
                  </FieldValue>

                  <FieldValue
                    label='Ended by'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.endedBy)}
                  >
                    <GeneralByWhoDisplay who={generalStatusHistory.endedBy} />
                  </FieldValue>

                  <FieldValue
                    label='End reason'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.endReason)}
                  >
                    {generalStatusHistory.endReason}
                  </FieldValue>

                  <FieldValue
                    label='End mode'
                    direction='row'
                    isEmpty={TypeHelper.isEmpty(generalStatusHistory.endAutoMode)}
                  >
                    <InlineApiEnumValue
                      type='AutomationCreateMode'
                      value={generalStatusHistory.endAutoMode}
                      withHelperTooltip
                    />
                  </FieldValue>
                </Stack>
              </Box>

              <FieldValue label='Is current' direction='row'>
                <BooleanValue value={generalStatusHistory.isCurrent} />
              </FieldValue>

              <FieldValue label='Is ended' direction='row'>
                <BooleanValue value={generalStatusHistory.isEnded} />
              </FieldValue>
            </Stack>
          </Stack>
        </Stack>
      </ViewContentBlock>
    </PageTabContent>
  );
}
