import { forwardRef, useImperativeHandle, useRef } from "react";

import AppPopover, { AppPopoverHandle, AppPopoverProps } from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import FilterDefinitionInput, {
  FilterDefinitionInputHandle,
  FilterDefinitionInputProps,
} from "./FilterDefinitionInput";

interface FilterPopoverProps extends Omit<AppPopoverProps, "children"> {
  filterDefinitionInputProps: FilterDefinitionInputProps;
}

// export type FilterPopoverHandle = AppPopoverHandle;

export type FilterPopoverHandle = {
  addFieldFilterAndOpen: (params: { field: string }) => void;
};

export default forwardRef<FilterPopoverHandle, FilterPopoverProps>(function FilterPopover(
  { filterDefinitionInputProps, ...popoverProps }: FilterPopoverProps,
  ref,
) {
  const appPopoverRef = useRef<AppPopoverHandle>(null);
  const filterDefinitionInputRef = useRef<FilterDefinitionInputHandle>(null);
  const addFieldFilterParamsRef = useRef<{ field: string } | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      addFieldFilterAndOpen(params: { field: string }) {
        // open popover, save params and wait until popover opens and the input is mounted
        addFieldFilterParamsRef.current = params;
        appPopoverRef.current?.open();
      },
    }),
    [filterDefinitionInputProps],
  );

  return (
    <AppPopover
      ref={appPopoverRef}
      tempDebugKey='FilterPopover'
      {...popoverProps}
      onOpened={() => {
        // when input is mounted, pass saved request
        if (addFieldFilterParamsRef.current) {
          filterDefinitionInputRef.current?.addFieldFilter(addFieldFilterParamsRef.current);
          addFieldFilterParamsRef.current = null;
        }
        popoverProps?.onOpened && popoverProps?.onOpened();
      }}
    >
      <AppPopoverContent>
        <FilterDefinitionInput
          ref={filterDefinitionInputRef}
          {...filterDefinitionInputProps}
          sx={{
            minWidth: {
              xxs: undefined,
              md: 400,
            },
            ...filterDefinitionInputProps?.sx,
          }}
        />
      </AppPopoverContent>
    </AppPopover>
  );
});
