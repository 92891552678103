import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";

export const NavDisplayVariants = {
  expanded: "expanded",
  collapsed: "collapsed",
} as const;

const navDisplayWidth = {
  collapsed: "95px",
  expanded: "310px",
};

const defaultNavDisplayContext = {
  navDisplay: NavDisplayVariants.collapsed as NavDisplayVariants,
  navToggle: (props: NavToggleProps) => {},
  initialNavDisplay: localStorage.getItem("navDisplay") as NavDisplayVariants,
  isControlled: false,
  navDisplayWidth: navDisplayWidth,
};

export type NavDisplayVariants = (typeof NavDisplayVariants)[keyof typeof NavDisplayVariants];

type NavToggleVariant = "hover" | "button";

type NavDisplayContextType = typeof defaultNavDisplayContext;

type NavToggleProps = {
  variant: NavToggleVariant;
  display?: NavDisplayVariants;
};

const NavDisplayContext = createContext<NavDisplayContextType | undefined>(undefined);

export const NavDisplayProvider = ({ children }: { children: ReactNode }) => {
  const initialNavDisplay = useMemo(
    () => localStorage.getItem("navDisplay") as NavDisplayVariants,
    [],
  );
  const [navDisplay, setNavMode] = useState<NavDisplayVariants>(
    initialNavDisplay || NavDisplayVariants.collapsed,
  );
  const [isControlled, setIsControlled] = useState<boolean>(
    initialNavDisplay === NavDisplayVariants.expanded ? true : false,
  );

  const navToggle = useCallback(
    ({ variant, display }: NavToggleProps) => {
      if (variant === "button" && navDisplay) {
        localStorage.setItem(
          "navDisplay",
          navDisplay === NavDisplayVariants.collapsed
            ? NavDisplayVariants.expanded
            : NavDisplayVariants.collapsed,
        );
        setNavMode((prevState) =>
          prevState === NavDisplayVariants.collapsed
            ? NavDisplayVariants.expanded
            : NavDisplayVariants.collapsed,
        );
        setIsControlled(navDisplay === NavDisplayVariants.collapsed ? true : false);
      }
      if (variant === "hover" && navDisplay && !isControlled) {
        if (display) setNavMode(display);
        else {
          setNavMode((prevState) =>
            prevState === NavDisplayVariants.collapsed
              ? NavDisplayVariants.expanded
              : NavDisplayVariants.collapsed,
          );
        }
      }
    },
    [navDisplay],
  );

  const value = useMemo(
    () => ({
      navDisplay,
      navToggle,
      initialNavDisplay,
      isControlled,
      navDisplayWidth,
    }),
    [navDisplay, navToggle, initialNavDisplay, isControlled],
  );

  return <NavDisplayContext.Provider value={value}>{children}</NavDisplayContext.Provider>;
};

export const useNavDisplay = (): NavDisplayContextType => {
  const context = useContext(NavDisplayContext);
  if (!context) {
    return defaultNavDisplayContext;
  }
  return context;
};
