import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";

import InlineCode from "@/common/components/Code/InlineCode";
import { MiscHelper } from "@/common/helpers/misc";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";

export default function DevButtonGroups() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  const buttons = [
    <Button key='one'>One</Button>,
    <Button key='two'>Two</Button>,
    <Button key='three'>Three</Button>,
  ];

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Button Groups
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Button Groups</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          <ButtonGroup variant='contained' aria-label='outlined primary button group'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
        </Stack>
        <Stack direction='row' spacing={1}>
          <ButtonGroup variant='outlined' aria-label='outlined button group'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
          <ButtonGroup variant='text' aria-label='text button group'>
            <Button>One</Button>
            <Button>Two</Button>
            <Button>Three</Button>
          </ButtonGroup>
        </Stack>
        <Stack direction='row' spacing={1}>
          <ButtonGroup size='small' aria-label='small button group'>
            {buttons}
          </ButtonGroup>
          <ButtonGroup color='secondary' aria-label='medium secondary button group'>
            {buttons}
          </ButtonGroup>
          <ButtonGroup size='large' aria-label='large button group'>
            {buttons}
          </ButtonGroup>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Box
            sx={{
              display: "flex",
              "& > *": {
                m: 1,
              },
            }}
          >
            <ButtonGroup orientation='vertical' aria-label='vertical outlined button group'>
              {buttons}
            </ButtonGroup>
            <ButtonGroup
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='contained'
            >
              {buttons}
            </ButtonGroup>
            <ButtonGroup
              orientation='vertical'
              aria-label='vertical contained button group'
              variant='text'
            >
              {buttons}
            </ButtonGroup>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
