import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { TextHelper } from "@/common/helpers/text";

import PoolItemsCreateFromEntities, {
  PoolItemsCreateFromEntitiesProps,
} from "./PoolItemCreateManyFromEntities";

export interface OwnProps {
  createProps: PoolItemsCreateFromEntitiesProps;
}

type Props = OwnProps & DialogProps;

export default function PoolItemCreateManyFromEntitiesModal({
  createProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {`Add ${TextHelper.pluralize(
          "entities",
          createProps.entities.length,
        )} to a pool (create new pool ${TextHelper.pluralize(
          "item",
          createProps.entities.length,
        )})`}
      </AppModalTitle>
      <DialogContent>
        <Box>
          <PoolItemsCreateFromEntities {...createProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
