import {
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { DatetimeHelper } from "@/common/helpers/datetime";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { enumService } from "@/common/services/enum";
import { DurationRepresentation } from "@/common/ts/duration";
import { UnitOfTime } from "@/core/api/generated";

import DropdownButton from "../../Button/DropdownButton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

const allUnitsOfTime = Object.values(UnitOfTime).filter((x) => x !== UnitOfTime.None);

const unitOfTimeRanges: Record<UnitOfTime, { min: number; max: number }> = {
  [UnitOfTime.None]: {
    min: 0,
    max: 100,
  },
  [UnitOfTime.Year]: {
    min: 0,
    max: 100,
  },
  [UnitOfTime.Month]: {
    min: 0,
    max: 100,
  },
  [UnitOfTime.Week]: {
    min: 0,
    max: 100,
  },
  [UnitOfTime.Day]: {
    min: 0,
    max: 100,
  },
  [UnitOfTime.Hour]: {
    min: 0,
    max: 1_000,
  },
  [UnitOfTime.Minute]: {
    min: 0,
    max: 10_000,
  },
  [UnitOfTime.Second]: {
    min: 0,
    max: 100_000,
  },
  [UnitOfTime.Millisecond]: {
    min: 0,
    max: 1_000_000,
  },
};

interface Props extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment"> {
  /** ISO 8601 duration or .NET TimeSpan string. */
  value?: string | null;
  /** Format of in and out value. */
  representation?: DurationRepresentation;
  /** All by default */
  unitsOfTime?: UnitOfTime[];
  defaultUnitOfTime?: UnitOfTime;
  allowUnitOfTimeEdit?: boolean;
  onChange?: (newValue?: string | null) => void;
}

/** Input for duration that works on Moment.js Duration. */
export default function DurationInput({
  value,
  representation = DurationRepresentation.DotNetTimeSpan,
  unitsOfTime = allUnitsOfTime,
  defaultUnitOfTime = UnitOfTime.Minute,
  allowUnitOfTimeEdit = true,
  onChange,
  ...textFieldProps
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [unitOfTime, setUnitOfTime] = useState<UnitOfTime>(defaultUnitOfTime || UnitOfTime.Minute);

  const adjustValueAccordingToDurationRange = useCallback(
    (durationValue: string | undefined, unitOfTime2: UnitOfTime) => {
      let newDurationValue = durationValue;

      // validate is in range
      const range = unitOfTimeRanges[unitOfTime2];
      if (!_.isNil(durationValue) && (+durationValue < range.min || +durationValue > range.max)) {
        newDurationValue = "";
        setInputValue(newDurationValue);
        enqueueSnackbar(
          `Valid value range for '${enumService.getEnumValueName("UnitOfTime", unitOfTime2)}' is [${
            range.min
          }, ${range.max}]`,
          {
            variant: "info",
          },
        );
      }

      return newDurationValue;
    },
    [setInputValue],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue = _.isNil(e.target.value) ? undefined : e.target.value;
      setInputValue(newInputValue);
    },
    [representation, inputValue, unitOfTime, onChange],
  );

  const handleUnitOfTimeSelect = useCallback(
    (newValue?: UnitOfTime | null) => {
      setUnitOfTime(newValue || UnitOfTime.Minute);
      adjustValueAccordingToDurationRange(inputValue, newValue || UnitOfTime.Minute);
    },
    [inputValue, setUnitOfTime],
  );

  // set initial value
  useEffect(() => {
    if (!_.isNil(value) && value.length !== 0 && _.isNil(inputValue)) {
      // parses both ISO and .NET TimeSpan representations
      const parsed = DatetimeHelper.parseDurationValueAsSuggestedUnitOfTime(value);
      const newValueNumber = parsed.duration;
      const newValue = !_.isNil(newValueNumber) ? newValueNumber.toString() : undefined;
      setInputValue(newValue);
      if (parsed.unitOfTime && parsed.unitOfTime !== defaultUnitOfTime) {
        setUnitOfTime(parsed.unitOfTime);
      }
    }
  }, [value, inputValue, unitOfTime]);

  // track inputValue changes
  useEffect(() => {
    const newInputValue = adjustValueAccordingToDurationRange(inputValue, unitOfTime);
    const newInputValueNumber =
      _.isNil(newInputValue) || _.isEmpty(newInputValue) ? undefined : +newInputValue;

    let outValue: string | undefined = undefined;
    if (!_.isNil(newInputValueNumber)) {
      outValue = DatetimeHelper.toDurationString(
        {
          [unitOfTime]: newInputValueNumber,
        },
        representation,
      );
    }
    onChange && onChange(outValue);
  }, [representation, inputValue, unitOfTime]);

  return (
    <TextField
      label='Duration'
      inputMode='numeric'
      value={inputValue || ""}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <DropdownButton
              size={textFieldProps.size}
              disabled={!allowUnitOfTimeEdit}
              buttonProps={{
                variant: "text",
              }}
              dropdownContent={
                <MenuList>
                  {unitsOfTime.map((x, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        handleUnitOfTimeSelect(x);
                      }}
                    >
                      <ListItemText>
                        <InlineApiEnumValue type='UnitOfTime' value={x} />
                      </ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              }
            >
              <InlineApiEnumValue type='UnitOfTime' value={unitOfTime} />
            </DropdownButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
}
