import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { VehicleDto } from "@/core/api/generated";

import VehicleActualStateView from "../../VehicleActualState/VehicleActualStateView";

interface Props {
  vehicle: VehicleDto;
}
export default function VehicleActualStateTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <VehicleActualStateView
        vehicleId={vehicle.id || ""}
        displayProps={{ header: true, breadcrumbs: false, viewVariant: ViewLayoutVariant.Tab }}
        headerProps={{
          withLink: false,
          title: <PageTabHeader title={"Actual state"} subtitle2={undefined} />,
          typographyProps: {},
        }}
      />
    </PageTabContent>
  );
}
