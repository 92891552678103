import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode, forwardRef } from "react";

interface AppPopoverContentProps {
  /** IF `true`, default styles are used. Pass `false` to don't use any styles or provide your own styles.
   * @default true
   */
  isUseDefaultStyles?: boolean;
  sx?: SxProps<Theme>;
  children: ReactNode;
}

/** Wraps the content of popover. Provides default styling. */
export default forwardRef<HTMLDivElement, AppPopoverContentProps>(function AppPopoverContent(
  { isUseDefaultStyles = true, sx, children }: AppPopoverContentProps,
  ref,
) {
  return (
    <Box
      ref={ref}
      sx={{
        ...(isUseDefaultStyles
          ? {
              maxWidth: { xxs: "95vw" },
              maxHeight: { xxs: "95vh" },
              overflow: "auto",
              py: 1,
              px: 2,
            }
          : undefined),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});
