import { PasswordPolicyDto, PasswordValidateResultDto } from "@/core/api/generated";

export function makePasswordPolicyErrorIfExists(
  validateResult: PasswordValidateResultDto,
  policy?: PasswordPolicyDto,
) {
  if (!policy) {
    policy = {
      maxLength: 10,
      minLength: 6,
      allowedSpecialChars: "!@#$%^&*",
    };
  }

  if (validateResult.isValid) return "";

  if (!validateResult.minLength)
    return `Password must contain at least ${policy.minLength} symbols`;

  if (!validateResult.maxLength) return `Password may contain maximum ${policy.maxLength} symbols`;
  if (!validateResult.withLowerCase) return `Password must contain symbols in lower case`;
  if (!validateResult.withUpperCase) return `Password must contain symbols in upper case`;
  if (!validateResult.withNumbers) return `Password must contain numbers`;
  if (!validateResult.withSpecialChars)
    return `Password may only contain the following special characters '${policy.allowedSpecialChars}'`;

  return "";
}
