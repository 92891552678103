import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AdminUserView from "@/common/components/Entity/User/AdminView/AdminUserView";

export default function UserViewPage() {
  const { userId } = useParams<{ userId?: string }>();

  return (
    <ViewPageLayout>
      <AdminUserView
        userId={userId}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </ViewPageLayout>
  );
}
