import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AssetSubscriptionPlanCreateUpdate from "@/common/components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlanCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function AssetSubscriptionPlanCreateUpdatePage() {
  const { assetSubscriptionPlanId } = useParams<{ assetSubscriptionPlanId?: string }>();
  const history = useHistory();

  const isCreate = !assetSubscriptionPlanId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new asset subscription plan" : "Edit asset subscription plan"}
        />
      }
    >
      <AssetSubscriptionPlanCreateUpdate
        assetSubscriptionPlanId={assetSubscriptionPlanId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.ASSET_SUBSCRIPTION_PLAN_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
