import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useCallback, useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ProductLocationDto, SpotDto, SpotGetPaginatedDto } from "@/core/api/generated";

import ProductLocationAddSpotsModal from "../../ProductLocationAddSpotsModal";

interface Props {
  productLocation: ProductLocationDto;
}

export default function SpotsTabContent({ productLocation }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<SpotGetPaginatedDto>({});

  const [isAddSpotsModalOpen, setIsAddSpotsModelOpen] = useState(false);
  const [isRemoveSpotModalOpen, setIsRemoveSpotModalOpen] = useState(false);
  const [spotToRemove, setSpotToRemove] = useState<SpotDto | undefined>(undefined);

  const paginatedSpotsRequest = useApiRequest(
    apiClient.spotsApi.apiV1SpotsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotGetPaginatedDto: {
        productLocationId: productLocation.id!,
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [productLocation.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedSpots = paginatedSpotsRequest?.data;

  const handleSpotRemovalConfirmed = useCallback(
    async (spot: SpotDto) => {
      try {
        await apiClient.productLocationsApi.apiV1ProductLocationsProductLocationIdSpotsSpotIdDelete(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            productLocationId: productLocation?.id || "",
            spotId: spot.id || "",
          },
        );
        enqueueSnackbar("Spot removed from the sales location.", { variant: "success" });
        paginatedSpotsRequest.refetch();
        setIsRemoveSpotModalOpen(false);
      } catch (err) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      }
    },
    [productLocation],
  );

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Spots"}
            primaryAction={
              <Button
                variant='contained'
                color='primary'
                onClick={() => setIsAddSpotsModelOpen(true)}
              >
                Add spots
              </Button>
            }
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <DataTabular
            columns={[
              {
                field: "localNumber",
                title: "Number",
                flex: 1,
                renderCell: (item) => item.localNumber,
              },
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => item.name,
              },
              {
                field: "description",
                title: "Description",
                flex: 1,
                renderCell: (item) => item.description,
              },
              {
                field: "address",
                title: "Address",
                flex: 1,
                renderCell: (item) => (
                  <GeneralAddressDisplay address={item.address} direction='row' />
                ),
              },
            ]}
            isLoading={paginatedSpotsRequest.isLoading}
            rows={paginatedSpots?.items}
            getRowId={(item) => item.id!}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            renderRowAction={(actionParams) => (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    setSpotToRemove(actionParams.item);
                    setIsRemoveSpotModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='remove' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Remove from sales location</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )}
          />
        </Stack>

        <ProductLocationAddSpotsModal
          productLocation={productLocation}
          open={isAddSpotsModalOpen}
          onClose={() => {
            setIsAddSpotsModelOpen(false);
          }}
          onAdded={() => {
            paginatedSpotsRequest.refetch();
          }}
        />

        {/* Confirm spot removal */}
        {spotToRemove && (
          <ConfirmationModal
            title='Remove spot from the productLocation?'
            body={
              <>
                {`You're going to remove spot`} <strong>{spotToRemove.name}</strong> from the
                productLocation <strong>{productLocation.name}</strong>.{" "}
                {`This action can't be undone.`}
              </>
            }
            open={isRemoveSpotModalOpen}
            onClose={() => setIsRemoveSpotModalOpen(false)}
            onCancel={() => setIsRemoveSpotModalOpen(false)}
            onConfirm={() => handleSpotRemovalConfirmed(spotToRemove)}
          />
        )}
      </ViewLayout>
    </PageTabContent>
  );
}
