import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useState } from "react";

import ConfirmationModal, {
  ConfirmationModalProps,
} from "@/common/components/Modals/ConfirmationModal";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminFileDto } from "@/core/api/generated";

export interface OwnProps {
  entity: AdminFileDto;
  onUpdate: (newValue: AdminFileDto) => void;
}

type Props = OwnProps & ConfirmationModalProps;

export default function FileUndeleteModal({ entity, onUpdate, ...dialogProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isForce, setIsForce] = useState(false);

  return (
    <ConfirmationModal
      title='Undelete the file?'
      body={
        <Stack spacing={2}>
          <Box>File entity and blob will be undeleted.</Box>

          <FormControlLabel
            control={<Checkbox checked={isForce} onChange={(e) => setIsForce(e.target.checked)} />}
            label='Force'
          />
        </Stack>
      }
      onCancel={() => dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown")}
      onConfirm={async () => {
        try {
          const response = await apiClient.adminFilesApi.apiV1AdminFilesFileIdUndeletePost({
            fileId: entity.id || "",
            adminFileUndeleteDto: {
              isForce: isForce,
            },
          });
          enqueueSnackbar("File was successfully undeleted.", { variant: "success" });
          onUpdate
            ? onUpdate(response.data)
            : dialogProps?.onClose && dialogProps.onClose({}, "escapeKeyDown");
        } catch (err) {
          const validation2 = ValidationHelper.handleApiErrorResponse(err);
          validation2.hasErrors &&
            enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      }}
      {...dialogProps}
    />
  );
}
