import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemReferenceDto,
  DamageCostEvaluationReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import DamageCostEvaluationInline, {
  DamageCostEvaluationInlineProps,
} from "./DamageCostEvaluationInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: DamageCostEvaluationDto | DamageCostEvaluationReferenceDto | null | undefined;
  entityId?: string | null;
  item?: DamageCostEvaluationItemDto | DamageCostEvaluationItemReferenceDto;
  itemId?: string | null;
  inlineProps?: Partial<DamageCostEvaluationInlineProps>;
}

export default function DamageCostEvaluationLink({
  entity,
  entityId,
  item,
  itemId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([
        AppPermission.FleetAppAccess,
        AppPermission.DamageCostEvaluationRead,
      ])}
      to={ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <DamageCostEvaluationInline
          entity={entity}
          entityId={entityId}
          item={item}
          itemId={itemId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage cost evaluation"}
    />
  );
}
