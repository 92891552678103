import _ from "lodash";
import { Moment } from "moment";
import moment from "moment-timezone";

import { UnitOfTime } from "@/core/api/generated";

import { DATETIME_FORMATS } from "../constants/common";
import { DurationRepresentation } from "../ts/duration";
import { TimeSpan } from "../ts/timespan";

const minDate = new Date(-8640000000000000);
const maxDate = new Date(8640000000000000);

const iso8601DurationRegex = /^P(\d+Y)?(\d+M)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/;
const dotNetTimeSpamRegex =
  /^(?<days>\d+(\.|,|:){1})?(?<time>\d+:\d+:\d+)(?<milliseconds>(\.|,){1}\d+)?$/;

export class DatetimeHelper {
  /** Returns min date value. */
  public static getMinDate(): Date {
    return minDate;
  }

  /** Returns min date value. */
  public static getMinDateAsMoment(): Moment {
    return moment(minDate);
  }

  /** Returns max date value. */
  public static getMaxDate(): Date {
    return maxDate;
  }

  /** Returns max date value. */
  public static getMaxDateAsMoment(): Moment {
    return moment(maxDate);
  }

  /** Formats datetime. */
  public static formatDatetime(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }
    return moment(date).format(DATETIME_FORMATS.DISPLAY_DATETIME);
  }

  /** Formats datetime. */
  public static formatDate(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }
    return moment(date).format(DATETIME_FORMATS.DISPLAY_DATE);
  }

  /** Returns duration (always positive) between two dates in user-friendly format.
   *  E.g.: a few seconds, 2 hours, 1 minute, 3 minutes, etc */
  public static humanizeDateRangeDuration(
    date1?: string | Moment | null,
    date2?: string | Moment | null,
  ): string {
    if (!date1 || !date2) {
      return "";
    }

    const date1M = moment(date1);
    const date2M = moment(date2);

    const start = date1M.isSameOrBefore(date2M) ? date1M.clone() : date2M.clone();
    const end = date1M.isAfter(date2M) ? date1M.clone() : date2M.clone();

    return end.from(start, true);
  }

  /** Returns duration (always positive) between specified date and now in user-friendly format.
   *  Support both past and future date.
   *  E.g.: a few seconds ago, 2 hours ago, in 1 minute, in 3 minutes, etc */
  public static humanizeDateRangeDurationFromNow(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }

    const dateM = moment(date);
    const nowDateM = moment();

    const isNowDate = dateM.isSame(nowDateM);
    const isPastDate = dateM.isBefore(nowDateM);
    const isFutureDate = dateM.isAfter(nowDateM);

    if (isNowDate) {
      return "just now";
    }

    const start = isPastDate ? dateM : nowDateM;
    const end = isFutureDate ? dateM : nowDateM;

    const duration = end.from(start, true);
    return isPastDate ? `${duration} ago` : `in ${duration}`;
  }

  public static humanizeDateDayFromNow(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }

    const dateM = moment.utc(date).local().startOf("day");
    const todayDateM = moment().startOf("day");

    const diffDays = todayDateM.diff(dateM, "days");
    const diffYears = todayDateM.diff(dateM, "years");

    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffYears === 0) {
      return dateM.format("MMMM D");
    } else {
      return dateM.format("MMMM D, YYYY");
    }
  }

  //#region Duration

  /** Detects DurationRepresentation from provided value.
   *  E.g. either ISO 8601 duration or .NET TimeSpan.
   */
  public static detectDurationRepresentation(value: string): DurationRepresentation {
    if (this.isISO8601Duration(value)) {
      return DurationRepresentation.ISO8601;
    } else if (this.isDotNetTimeSpan(value)) {
      return DurationRepresentation.DotNetTimeSpan;
    } else {
      throw new Error(`Invalid duration value '${value}'. Representation can't be detected.`);
    }
  }

  /** Checks provided value is ISO 8601 duration string.
   *  E.g. P1Y2M10DT2H30M.
   *  https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   *  https://momentjs.com/docs/#/durations/as-iso-string/
   */
  public static isISO8601Duration(value: string | null | undefined): boolean {
    return (
      !_.isNil(value) &&
      value.length > 0 &&
      iso8601DurationRegex.test(value) &&
      value !== "P" &&
      value !== "PT"
    );
  }

  /** Checks provided value is .NET TImeSpan string.
   * E.g. 7.23:59:59.999, 14:30:15, 1:14:30:15,0000000
   *  https://learn.microsoft.com/en-us/dotnet/api/system.timespan.tostring?view=net-7.0
   */
  public static isDotNetTimeSpan(value: string | null | undefined): boolean {
    return !_.isNil(value) && value.length > 0 && dotNetTimeSpamRegex.test(value);
  }

  public static toDurationString(
    duration: Partial<Record<UnitOfTime, number | undefined>>,
    representation: DurationRepresentation,
  ): string {
    if (representation === DurationRepresentation.ISO8601) {
      return DatetimeHelper.toISO8601DurationString(duration);
    } else if (representation === DurationRepresentation.DotNetTimeSpan) {
      return DatetimeHelper.toDotNetTimeSpanString(duration);
    } else {
      throw new Error(`Unknown duration representation '${representation}'.`);
    }
  }

  /** E.g. P1Y2M10DT2H30M. */
  public static toISO8601DurationString(
    duration: Partial<Record<UnitOfTime, number | undefined>>,
  ): string {
    const year = duration[UnitOfTime.Year];

    const dateParts = [
      duration[UnitOfTime.Year] ? `${duration[UnitOfTime.Year]}Y` : null,
      duration[UnitOfTime.Month] ? `${duration[UnitOfTime.Month]}M` : null,
      duration[UnitOfTime.Day] ? `${duration[UnitOfTime.Day]}D` : null,
    ]
      .filter((x) => !!x)
      .map((x) => x!);

    const timeParts = [
      duration[UnitOfTime.Hour] ? `${duration[UnitOfTime.Hour]}H` : null,
      duration[UnitOfTime.Minute] ? `${duration[UnitOfTime.Minute]}M` : null,
      duration[UnitOfTime.Second] ? `${duration[UnitOfTime.Second]}S` : null,
    ]
      .filter((x) => !!x)
      .map((x) => x!);

    return `P${dateParts.join("")}${timeParts.length !== 0 ? `T${timeParts.join("")}` : ""}`;
  }

  /** Always outputs in the similar format: 7.23:59:59.999 (dots and semicolons are used). */
  public static toDotNetTimeSpanString(
    duration: Partial<Record<UnitOfTime, number | undefined>>,
  ): string {
    return TimeSpan.fromDurationInput(duration).toString();
  }

  /** Parses duration string and converts to number in specified units. */
  public static parseDurationValueAsUnitOfTime(
    duration: string | null | undefined,
    unitOfTime: UnitOfTime,
  ): number | null {
    if (_.isNil(duration) || _.isEmpty(duration)) {
      return null;
    }

    if (this.isISO8601Duration(duration)) {
      // moment.duration parses both ISO and .NET TimeSpan representations
      // NB: has issues with converting 30 days to months. I.e. 90 days != 3 months.
      const momentDuration = moment.duration(duration);
      return TimeSpan.fromMomentDuration(momentDuration).toNumber(unitOfTime);
    } else if (this.isDotNetTimeSpan(duration)) {
      return TimeSpan.fromDurationValue(duration).toNumber(unitOfTime);
    } else {
      throw new Error(`Unknown duration value '${duration}'.`);
    }
  }

  /** Parses duration string, suggest the most suitable units and converts to number in suggested units. */
  public static parseDurationValueAsSuggestedUnitOfTime(duration: string | null | undefined): {
    duration: number | null;
    unitOfTime: UnitOfTime | null;
  } {
    if (_.isNil(duration) || _.isEmpty(duration)) {
      return { duration: null, unitOfTime: null };
    }

    const timeSpan =
      (this.isISO8601Duration(duration)
        ? TimeSpan.fromMomentDuration(moment.duration(duration))
        : undefined) ||
      (this.isDotNetTimeSpan(duration) ? TimeSpan.fromDurationValue(duration) : undefined) ||
      undefined;
    if (!timeSpan) {
      throw new Error(`Unknown duration value '${duration}'.`);
    }

    const unitOfTime = timeSpan.suggestUnitOfTime();

    return { duration: timeSpan.toNumber(unitOfTime), unitOfTime: unitOfTime };
  }

  // public static toDotNetTimeSpanStringFromMomentDuration(duration: moment.Duration): string {
  //   throw new Error("Not implemented");
  // }

  //#endregion
}
