import { Alert, AlertTitle, Box, Stack, SxProps, Theme } from "@mui/material";

import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import { RepairSpecHelper } from "@/common/helpers/entity/repairSpec";
import {
  CreateRepairSpecDto,
  RepairMaterialDto,
  RepairSpecDto,
  RepairSpecSparePartDto,
  RepairWorkDto,
  UpdateRepairSpecDto,
} from "@/core/api/generated";

export interface OwnProps {
  repairSpec: RepairSpecDto | CreateRepairSpecDto | UpdateRepairSpecDto | null | undefined;
  repairMaterial?: RepairMaterialDto | null;
  repairWork?: RepairWorkDto | null;
  repairSparePart?: RepairSpecSparePartDto | null;
  sx?: SxProps<Theme>;
}

type Props = OwnProps;

export default function CurrencyMismatchAlert({
  repairSpec,
  repairMaterial,
  repairWork,
  repairSparePart,
  sx,
}: Props) {
  return RepairSpecHelper.isCurrencyMismatch({
    repairSpec,
    repairMaterial,
    repairWork,
    repairSparePart,
  }) ? (
    <Alert severity='warning' sx={sx}>
      <AlertTitle>Currency mismatch detected!</AlertTitle>
      <Stack>
        <Box>
          Repair spec uses{" "}
          <GeneralCurrencyDisplay currency={repairSpec?.currency} sx={{ color: "inherit" }} />, but{" "}
          {(repairMaterial && "repair material") ||
            (repairWork && "repair work") ||
            (repairSparePart && "repair spare part") ||
            "-"}{" "}
          uses{" "}
          <GeneralCurrencyDisplay
            currency={repairMaterial?.currency || repairWork?.currency || repairSparePart?.currency}
            sx={{ color: "inherit" }}
          />
          . {`Prices won't be converted automatically between different currencies.`}{" "}
          {`You need to resolve the mismatch manually.`}{" "}
          <>
            If not,{" "}
            <GeneralCurrencyDisplay currency={repairSpec?.currency} sx={{ color: "inherit" }} />{" "}
            will be used.
          </>
        </Box>
      </Stack>
    </Alert>
  ) : null;
}
