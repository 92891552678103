import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import {
  NegotiationAllowedActionsDto,
  NegotiationChangedDto,
  NegotiationDto,
  PaginationDtoOfNegotiationDto,
  RespondOnManyProposalsInfoDto,
} from "@/core/api/generated";
import { AppThunk } from "@/store";

export interface ChatNegotiationsState {
  loading: {
    getPaginated?: boolean;
    get?: boolean;
    updateNegotiation?: boolean;
    getChatCurrentNegotiation?: boolean;
    createNegotiationProposal?: boolean;
    updateNegotiationProposal?: boolean;
    getRespondOnAllProposalsInfo?: boolean;
    respondOnAllProposals?: boolean;
  };

  paginatedNegotiations?: PaginationDtoOfNegotiationDto;
  negotiation?: NegotiationDto;

  /** chatId:value */
  currentNegotiationsMap: Record<string, NegotiationDto[] | undefined>;

  /** negotiationId: value */
  negotiationAllowedActionsMap: Record<string, NegotiationAllowedActionsDto | undefined>;
}

const initialState: ChatNegotiationsState = {
  loading: {},
  paginatedNegotiations: undefined,
  negotiation: undefined,
  currentNegotiationsMap: {},
  negotiationAllowedActionsMap: {},
};

const tenantsSlice = createSlice({
  name: "negotiations",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatNegotiationsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    resetNegotiation: (state, action: PayloadAction<void>) => {
      state.negotiation = undefined;
    },
    resetChatNegotiations: (state, action: PayloadAction<{ chatId?: string | null }>) => {
      const negotiations = state.currentNegotiationsMap[action.payload.chatId || ""] || [];
      negotiations.forEach((negotiation) => {
        state.negotiationAllowedActionsMap[negotiation.id || ""] = undefined;
      });
      state.currentNegotiationsMap[action.payload.chatId || ""] = undefined;
    },
    _paginatedNegotiationsFetched: (
      state,
      action: PayloadAction<PaginationDtoOfNegotiationDto>,
    ) => {
      state.paginatedNegotiations = action.payload;
    },
    _negotiationFetched: (state, action: PayloadAction<NegotiationDto>) => {
      state.negotiation = action.payload;
    },
    _negotiationUpdated: (state, action: PayloadAction<NegotiationDto>) => {
      if (state.paginatedNegotiations) {
        const index = state.paginatedNegotiations.items!.findIndex(
          (x) => x.id === action.payload.id,
        );
        index !== -1 && state.paginatedNegotiations.items?.splice(index, 1, action.payload);
      }

      if (state.negotiation?.id === action.payload.id) {
        state.negotiation = action.payload;
      }

      action.payload.chats!.forEach((chat) => {
        const negotiations = state.currentNegotiationsMap[chat.id!] || [];
        const index = negotiations.findIndex((x) => x.id === action.payload!.id);
        index !== -1 && negotiations.splice(index, 1, action.payload);
      });
    },
    _chatAllCurrentNegotiationsFetched: (
      state,
      action: PayloadAction<{ chatId: string; negotiations: NegotiationDto[] }>,
    ) => {
      state.currentNegotiationsMap[action.payload.chatId] = action.payload.negotiations;
    },
    _negotiationAllowedActionsFetched: (
      state,
      action: PayloadAction<{
        negotiationId: string;
        allowedActions: NegotiationAllowedActionsDto;
      }>,
    ) => {
      state.negotiationAllowedActionsMap[action.payload.negotiationId] =
        action.payload.allowedActions;
    },
    negotiationCreated: (state, action: PayloadAction<NegotiationChangedDto>) => {
      if (state.paginatedNegotiations) {
        const index = state.paginatedNegotiations.items!.findIndex(
          (x) => x.id === action.payload.entity!.id,
        );
        index === -1 && state.paginatedNegotiations.items!.unshift(action.payload.entity!);
      }
    },
    negotiationUpdated: (state, action: PayloadAction<NegotiationChangedDto>) => {
      if (state.paginatedNegotiations) {
        const index = state.paginatedNegotiations.items!.findIndex(
          (x) => x.id === action.payload.entity!.id,
        );
        index === -1 && state.paginatedNegotiations.items!.splice(index, 1, action.payload.entity!);
      }

      if (state.negotiation?.id === action.payload.entity!.id) {
        state.negotiation = action.payload.entity;
      }

      action.payload.entity!.chats!.forEach((chat) => {
        const negotiations = state.currentNegotiationsMap[chat.id!] || [];
        const index = negotiations.findIndex((x) => x.id === action.payload!.entity!.id);
        index !== -1 && negotiations.splice(index, 1, action.payload.entity!);
      });
    },
    negotiationDeleted: (state, action: PayloadAction<NegotiationChangedDto>) => {
      if (state.paginatedNegotiations) {
        const index = state.paginatedNegotiations.items!.findIndex(
          (x) => x.id === action.payload.entityId,
        );
        index !== -1 && state.paginatedNegotiations.items!.splice(index, 1);
      }

      if (state.negotiation?.id === action.payload.entityId) {
        state.negotiation = undefined;
      }
    },
  },
});

export const {
  setLoading,
  resetNegotiation,
  resetChatNegotiations,
  _paginatedNegotiationsFetched,
  _negotiationFetched,
  _negotiationUpdated,
  _chatAllCurrentNegotiationsFetched,
  _negotiationAllowedActionsFetched,
  negotiationCreated,
  negotiationUpdated,
  negotiationDeleted,
} = tenantsSlice.actions;

export const negotiationsReducer = tenantsSlice.reducer;

export const getPaginatedNegotiations =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsGetPost>
  ): AppThunk<Promise<PaginationDtoOfNegotiationDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getPaginated: true,
      }),
    );

    try {
      const response = await apiClient.negotiationsApi.apiV1NegotiationsGetPost(...arg);
      await dispatch(_paginatedNegotiationsFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getPaginated: false,
        }),
      );
    }
  };

export const getNegotiation =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet>
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        get: true,
      }),
    );

    try {
      const response = await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet(...arg);
      await dispatch(_negotiationFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          get: false,
        }),
      );
    }
  };

export const getNegotiationAllowedActions =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdAllowedActionsGet
    >
  ): AppThunk<Promise<NegotiationAllowedActionsDto>> =>
  async (dispatch) => {
    const response =
      await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdAllowedActionsGet(...arg);

    await dispatch(
      _negotiationAllowedActionsFetched({
        negotiationId: arg[0].negotiationId,
        allowedActions: response.data,
      }),
    );

    return response.data;
  };

export const getNegotiationsAllowedActions =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsAllowedActionsGet>
  ): AppThunk<Promise<NegotiationAllowedActionsDto[]>> =>
  async (dispatch) => {
    const response = await apiClient.negotiationsApi.apiV1NegotiationsAllowedActionsGet(...arg);

    response.data.forEach((x) => {
      dispatch(
        _negotiationAllowedActionsFetched({
          negotiationId: x.negotiationId!,
          allowedActions: x,
        }),
      );
    });

    return response.data;
  };

export const updateNegotiation =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdPut>
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        updateNegotiation: true,
      }),
    );

    try {
      const response = await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdPut(...arg);
      await dispatch(_negotiationUpdated(response.data));
      await dispatch(
        getNegotiationAllowedActions({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: arg[0].negotiationId,
        }),
      );
      return response.data;
    } finally {
      dispatch(
        setLoading({
          updateNegotiation: false,
        }),
      );
    }
  };

export const getChatCurrentNegotiations =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsChatsChatIdCurrentAllGet>
  ): AppThunk<Promise<NegotiationDto[]>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getChatCurrentNegotiation: true,
      }),
    );

    try {
      const response = await apiClient.negotiationsApi.apiV1NegotiationsChatsChatIdCurrentAllGet(
        ...arg,
      );
      await dispatch(
        _chatAllCurrentNegotiationsFetched({
          chatId: arg[0].chatId,
          negotiations: response.data,
        }),
      );
      // response.data.forEach((negotiation) => {
      //   dispatch(getNegotiationAllowedActions(EMPTY_TENANT_IDENTIFIER, negotiation.id!));
      // });
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getChatCurrentNegotiation: false,
        }),
      );
    }
  };

export const createNegotiationProposal =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdProposalsPost>
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        createNegotiationProposal: true,
      }),
    );

    try {
      const response = await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdProposalsPost(
        ...arg,
      );
      await dispatch(_negotiationUpdated(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          createNegotiationProposal: false,
        }),
      );
    }
  };

export const updateNegotiationProposal =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdProposalsProposalIdPut
    >
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    const negotiationId = arg[1];

    dispatch(
      setLoading({
        updateNegotiationProposal: true,
      }),
    );

    try {
      const response =
        await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdProposalsProposalIdPut(
          ...arg,
        );
      await dispatch(_negotiationUpdated(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          updateNegotiationProposal: false,
        }),
      );
    }
  };

export const respondOnNegotiationProposal =
  (
    ...arg: Parameters<typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondPost>
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    const response = await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondPost(
      ...arg,
    );
    await dispatch(_negotiationUpdated(response.data));
    return response.data;
  };

export const respondOnNegotiationProposalWithAgree =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondAgreePost
    >
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    const response = await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondAgreePost(
      ...arg,
    );
    await dispatch(_negotiationUpdated(response.data));
    return response.data;
  };

export const respondOnNegotiationProposalWithDisagree =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondDisagreePost
    >
  ): AppThunk<Promise<NegotiationDto>> =>
  async (dispatch) => {
    const response =
      await apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdRespondDisagreePost(...arg);
    await dispatch(_negotiationUpdated(response.data));
    return response.data;
  };

export const getRespondOnManyProposalsInfo =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsProposalsRespondOnManyInfoGetPost
    >
  ): AppThunk<Promise<RespondOnManyProposalsInfoDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getRespondOnAllProposalsInfo: true,
      }),
    );

    try {
      const response =
        await apiClient.negotiationsApi.apiV1NegotiationsProposalsRespondOnManyInfoGetPost(...arg);
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getRespondOnAllProposalsInfo: false,
        }),
      );
    }
  };

export const respondOnManyProposals =
  (
    ...arg: Parameters<
      typeof apiClient.negotiationsApi.apiV1NegotiationsProposalsRespondOnManyNegotiationsPost
    >
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        respondOnAllProposals: true,
      }),
    );

    try {
      const response =
        await apiClient.negotiationsApi.apiV1NegotiationsProposalsRespondOnManyNegotiationsPost(
          ...arg,
        );
      response.data.forEach((x) => dispatch(_negotiationUpdated(x)));
    } finally {
      dispatch(
        setLoading({
          respondOnAllProposals: false,
        }),
      );
    }
  };
