import { LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import AppIcon from "@/common/components/Icons/AppIcon";
import { UrlHelper } from "@/common/helpers/url";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { apiClient } from "@/core/api/ApiClient";

export type InviteExpiredPageQueryParams = GeneralQueryParams;

export default function InviteExpiredPage() {
  const { inviteId } = useParams<{ inviteId: string }>();
  const { ...generalQueryParams } = useQueryParams<InviteExpiredPageQueryParams>();

  const inviteRequest = useApiRequest(
    apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet,
    {
      inviteId: inviteId!,
    },
    {
      skip: !inviteId,
    },
  );
  const invite = inviteRequest?.data;

  // redirect to target URL
  useEffect(() => {
    if (generalQueryParams.redirectUrl) {
      UrlHelper.redirectToUrl(generalQueryParams.redirectUrl);
    }
  }, [generalQueryParams.redirectUrl]);

  return (
    <Stack spacing={2}>
      {inviteRequest.isLoading && <LinearProgress />}

      {invite && (
        <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
          <AppIcon of='expired' fontSize='large' color='text' />

          <Typography component='div' variant='h6'>
            This invitation has expired.
          </Typography>

          <Typography component='div' variant='body1'>
            You can request another invitation from your admin to join the{" "}
            <strong>{invite.tenant?.name}</strong> company.
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
