import { Box } from "@mui/material";
import { useRef, useState } from "react";

import { ChatMessageNodeBuilder } from "@/common/builders/chatMessageNode";
import GeneralTagSearchCandidatesPopover, {
  GeneralTagSearchCandidatesTabs,
} from "@/common/components/Entity/General/GeneralTag/GeneralTagSearchCandidatesPopover";
import { ChatMessageHelper } from "@/common/helpers/entity/chatMessage";
import { StringHelper } from "@/common/helpers/string";
import {
  ChatMessageNodeDto,
  ChatMessageNodeType,
  ChatMessageRootNodeDto,
  GeneralTagCandidateDto,
} from "@/core/api/generated";

interface Props {
  chatId: string;
  disabled?: boolean;
  onMessageChange: (newValue: { rootNode: ChatMessageRootNodeDto }) => void;
  children: (props: {
    handleMessageChange: (
      rootNode: ChatMessageRootNodeDto,
      oldSelection: CustomCursorSelection,
      newSelection: CustomCursorSelection,
      newBody: string,
    ) => { isHandled: boolean };
    handleKeyDown: (e: React.KeyboardEvent<HTMLElement>) => { isHandled: boolean };
  }) => React.ReactNode;
}

/** Handles tags in message input. E.g. Hello, @John */
export default function ChatMessageTagHandler({
  chatId,
  disabled,
  onMessageChange,
  children,
}: Props) {
  const _rootNode = useRef<ChatMessageRootNodeDto>({
    nodes: [],
  });
  const messageBody = useRef("");
  const isTagStarted = useRef(false);
  const enteredTagText = useRef<string | undefined>(undefined);
  const tagStartIndex = useRef<number | undefined>(undefined);
  const tagEndIndex = useRef<number | undefined>(undefined);
  const tagNodeId = useRef<string | undefined>(undefined);

  const [isTagCandidatesPopoverOpen, setIsTagCandidatesPopoverOpen] = useState(false);
  const tagCandidatesPopoverAnchorEl = useRef<HTMLElement | null | undefined>(null);

  const reset = () => {
    _rootNode.current = {
      nodes: [],
    };
    messageBody.current = "";
    isTagStarted.current = false;
    enteredTagText.current = undefined;
    tagStartIndex.current = undefined;
    tagEndIndex.current = undefined;
    tagNodeId.current = undefined;

    setIsTagCandidatesPopoverOpen(false);
  };

  const handleMessageChange = (
    rootNode: ChatMessageRootNodeDto,
    oldSelection: CustomCursorSelection,
    newSelection: CustomCursorSelection,
    newBody: string,
  ): { isHandled: boolean } => {
    if (disabled) {
      return { isHandled: false };
    }

    _rootNode.current = rootNode;
    messageBody.current = newBody;

    // tag starts when @ is typed after space or in the start of the line
    const { isTextEntered } = ChatMessageHelper.detectTextChangeFromSelections(
      oldSelection,
      newSelection,
    );
    const enteredText = newBody[newSelection.startIndex! - 1];
    const isAtSignEntered = enteredText === "@";
    const isNewWordOrStartOfLine =
      newBody[newSelection.startIndex! - 2] === " " || newSelection.startIndex! - 1 === 0;
    const isTagJustStarted = isTextEntered && isAtSignEntered && isNewWordOrStartOfLine;
    isTagStarted.current = isTagStarted.current || isTagJustStarted;

    // handle edit of existing or uncompleted tag
    if (!isTagStarted.current) {
      const existingTagNode =
        _rootNode.current.nodes!.find(
          (x) =>
            x.type === ChatMessageNodeType.Tag &&
            ChatMessageHelper.getNodeIntersectionWithSelection(x, {
              startIndex: oldSelection.startIndex,
              endIndex: newSelection.endIndex,
            }).isIntersects,
        ) ||
        _rootNode.current.nodes!.find(
          (x) =>
            x.type === ChatMessageNodeType.Tag &&
            ChatMessageHelper.getNodeIntersectionWithSelection(x, {
              startIndex: Math.max(oldSelection.startIndex! - 1, 0),
              endIndex: newSelection.endIndex,
            }).isIntersects,
        );
      if (existingTagNode) {
        isTagStarted.current = true;
        tagStartIndex.current = existingTagNode.startIndex;
        tagEndIndex.current = existingTagNode.endIndex;
        tagNodeId.current = existingTagNode.id!;
      }
    }

    // end tag if space typed. e.g. @Joh ...
    if (isTagStarted.current && enteredText === " ") {
      // console.log("end tag (space entered after the tag).");
      reset();
      return {
        isHandled: false,
      };
    }

    if (!isTagStarted.current) {
      return {
        isHandled: false,
      };
    }

    if (isTagJustStarted) {
      tagStartIndex.current = Math.max(newSelection.startIndex! - 1, 0);

      // check whether tag starts in the middle of existing node,
      // if yes - split it and insert tag node in between.
      const selection: CustomCursorSelection = {
        startIndex: oldSelection.startIndex,
        endIndex: newSelection.endIndex,
      };
      const intersectionNode = _rootNode.current.nodes!.find(
        (x) => ChatMessageHelper.getNodeIntersectionWithSelection(x, selection).isIntersects,
      );
      let insertTagAfter: ChatMessageNodeDto | undefined = undefined;
      if (intersectionNode) {
        if (intersectionNode.type !== ChatMessageNodeType.Text) {
          return {
            isHandled: false,
          };
        }

        const splitResult = ChatMessageHelper.splitNodeBySelection(
          _rootNode.current,
          intersectionNode,
          selection,
        );
        insertTagAfter = splitResult.node1;
      }

      // insert separate node with '@'
      const newNode = ChatMessageNodeBuilder.newTagNode()
        .withIndexRange({
          startIndex: tagStartIndex.current!,
          endIndex: tagStartIndex.current! + 1,
        })
        .withText({
          text: "@",
        })
        .withTag({})
        .build();
      insertTagAfter
        ? ChatMessageHelper.insertNodeAfter(_rootNode.current, insertTagAfter.id!, newNode)
        : ChatMessageHelper.insertNode(_rootNode.current, newNode);
      tagNodeId.current = newNode.id!;

      // remove '@' from prev text node
      const prevTextNode = _rootNode.current.nodes!.find((x) => {
        return (
          x.id !== newNode.id &&
          x.type === ChatMessageNodeType.Text &&
          newNode.startIndex! < x.endIndex! &&
          x.text!.text!.endsWith("@")
        );
      });

      if (prevTextNode) {
        prevTextNode!.endIndex! = Math.max(prevTextNode!.endIndex! - 1, prevTextNode!.startIndex!);
        prevTextNode!.text!.text! = StringHelper.removeLastChar(prevTextNode!.text!.text!);
      }

      setIsTagCandidatesPopoverOpen(true);

      onMessageChange({
        rootNode: _rootNode.current,
      });

      return {
        isHandled: true,
      };
    } else if (isTagStarted.current) {
      tagEndIndex.current = newSelection.startIndex!;

      const handleResult = ChatMessageHelper.handleTagChange({
        rootNode: _rootNode.current,
        tagNodeId: tagNodeId.current,
        oldSelection,
        newSelection,
        change: {
          value: newBody,
        },
      });

      const tagNode = _rootNode.current.nodes!.find((x) => x.id === tagNodeId.current);
      enteredTagText.current = tagNode?.text?.text || undefined;

      // console.log("handleResult", handleResult.isHandled, {
      //   handleResult,
      //   _rootNode: _rootNode.current,
      //   tagNodeId: tagNodeId.current,
      //   tagNode,
      // });

      if (handleResult.isHandled) {
        setIsTagCandidatesPopoverOpen(true);
        onMessageChange({
          rootNode: _rootNode.current,
        });
      }

      const isTagJustDeleted = isTagStarted.current && !tagNode;
      isTagJustDeleted && reset();

      return {
        isHandled: handleResult.isHandled,
      };
    }

    return {
      isHandled: false,
    };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (disabled) {
      return { isHandled: false };
    }

    if (!isTagCandidatesPopoverOpen) {
      return { isHandled: false };
    }

    return { isHandled: false };
  };

  const handleTagCandidatePopoverClose = () => {
    // when tag candidates popover is closed, it means that nothing was selected.
    // we should replace current tag node with plain text node
    const tagNode = _rootNode.current.nodes!.find((x) => x.id === tagNodeId.current);
    if (tagNode) {
      const newTextNode = ChatMessageNodeBuilder.newTextNode()
        .withText({
          text: tagNode.text?.text || "",
        })
        .withIndexRange({
          startIndex: tagNode.startIndex,
          endIndex: tagNode.endIndex,
        })
        .build();
      ChatMessageHelper.replaceNode(_rootNode.current, tagNode.id!, newTextNode);
      ChatMessageHelper.sortNodes(_rootNode.current);
    }

    setIsTagCandidatesPopoverOpen(false);
    reset();
  };

  const handleTagCandidateSelected = (candidate: GeneralTagCandidateDto) => {
    if (tagStartIndex.current === undefined) {
      return;
    }

    const tagNode = _rootNode.current.nodes!.find((x) => x.id === tagNodeId.current);
    if (!tagNode) {
      return;
    }

    const tagText = `@${candidate.tag!.title || candidate.tag!.targetType}`;
    const newTagNode: ChatMessageNodeDto = {
      ...tagNode,
      startIndex: tagStartIndex.current!,
      endIndex: tagStartIndex.current! + tagText.length,
      text: {
        text: tagText,
      },
      tag: {
        tag: candidate.tag!,
      },
    };
    ChatMessageHelper.replaceNode(_rootNode.current, tagNode.id!, newTagNode);
    ChatMessageHelper.insertNodeAfter(
      _rootNode.current,
      newTagNode.id!,
      ChatMessageNodeBuilder.newTextNode()
        .withText({
          text: " ",
        })
        .build(),
    );
    ChatMessageHelper.sortNodes(_rootNode.current);

    onMessageChange({
      rootNode: _rootNode.current,
    });

    setIsTagCandidatesPopoverOpen(false);
    reset();
  };

  return (
    <Box>
      <Box>
        <Box ref={tagCandidatesPopoverAnchorEl} component='span'></Box>
      </Box>

      <Box>
        {children({
          handleMessageChange,
          handleKeyDown,
        })}
      </Box>

      {/* Search tag candidates popover */}
      <GeneralTagSearchCandidatesPopover
        anchorEl={tagCandidatesPopoverAnchorEl.current}
        open={isTagCandidatesPopoverOpen}
        defaultTab={GeneralTagSearchCandidatesTabs.USER}
        onClose={handleTagCandidatePopoverClose}
        onTagSelected={handleTagCandidateSelected}
      />
    </Box>
  );
}
