import { Box } from "@mui/material";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AuthenticatedGuard from "@/common/components/Auth/AuthenticatedGuard";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedGuard from "@/common/components/Auth/AuthorizedGuard";
import AppRoute from "@/common/components/Route/AppRoute";
import { AppPermission } from "@/core/api/generated";

import { ROUTE_PATH } from "../../common/constants/routing";
import GlobalAlertsForAllAppAreas from "../BaseApp/GlobalAlerts/GlobalAlertsForAllAppAreas";
import Profile from "../BaseApp/Profile/Profile";
import Dashboard from "../FleetCustomerApp/Dashboard/Dashboard";
import RootRouteEntry from "../FleetCustomerApp/RootRouteEntry";
import FleetCustomerAppLayout from "../Layouts/FleetCustomerAppLayout";
import AssessmentFlowChatPage from "../MainAppView/Flows/Assessment/AssessmentFlowChatPage";
import AssessmentFlowViewPage from "../MainAppView/Flows/Assessment/AssessmentFlowViewPage";
import AssessmentFlowsPage from "../MainAppView/Flows/Assessment/AssessmentFlowsPage";

export default function FleetCustomerAppContent() {
  return (
    <AuthenticatedGuard>
      <AuthorizedGuard permissions={[AppPermission.FleetCustomerAppAccess]}>
        {/* Global alerts */}
        <Box sx={{ width: "100%" }}>
          <GlobalAlertsForAllAppAreas />
        </Box>

        <FleetCustomerAppLayout>
          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_CUSTOMER_PROFILE)}
            render={() => <Profile />}
          />

          <AppRoute
            exact
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_CUSTOMER_DASHBOARD)}
            render={() => <Dashboard />}
          />

          <AuthorizedElement permissions={[]}>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_CUSTOMER_ASSESSMENT_FLOWS())}
              render={() => <AssessmentFlowsPage />}
            />
          </AuthorizedElement>
          <AuthorizedElement permissions={[]}>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(ROUTE_PATH.FLEET_CUSTOMER_ASSESSMENT_FLOW_VIEW())}
              render={() => <AssessmentFlowViewPage />}
            />
          </AuthorizedElement>
          <AuthorizedElement permissions={[]}>
            <AppRoute
              exact
              path={RoutePathBuilder.toDefinition(
                ROUTE_PATH.FLEET_CUSTOMER_ASSESSMENT_FLOW_CHAT_VIEW(),
              )}
              render={() => <AssessmentFlowChatPage />}
            />
          </AuthorizedElement>

          <AppRoute
            exact
            path={ROUTE_PATH.APP_AREA_FLEET_CUSTOMER}
            render={() => <RootRouteEntry />}
          />
        </FleetCustomerAppLayout>
      </AuthorizedGuard>
    </AuthenticatedGuard>
  );
}
