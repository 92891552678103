import { Divider } from "@mui/material";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppTypography from "@/common/components/Text/AppTypography";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, TenantGetPaginatedDto } from "@/core/api/generated";

export default function TenantsPage() {
  const commonRequestParams = useCommonRequestParams<TenantGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.Company),
    },
  });

  const paginatedTenantsRequest = useApiRequest(
    apiClient.adminTenantsApi.apiV1AdminTenantsGetPost,
    {
      adminTenantGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const { data: paginatedTenants } = paginatedTenantsRequest;

  return (
    <ListPageLayout>
      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => item.name,
          },
          {
            field: "identifier",
            title: "Identifier",
            flex: 1,
            renderCell: (item) => item.identifier,
          },
          {
            field: "id",
            title: "Id",
            flex: 1,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }}>{item.id}</AppTypography>
            ),
          },
          {
            field: "description",
            title: "Description",
            flex: 1,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }}>{item.description}</AppTypography>
            ),
          },
        ]}
        rows={paginatedTenants?.items}
        rowTo={(item) => ROUTE_PATH.ADMIN_TENANT_VIEW(item.id!)}
        getRowId={(item) => item.id!}
        isLoading={paginatedTenantsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ListPageLayout>
  );
}
