import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import UpdateContractReminder, { UpdateContractReminderProps } from "./UpdateContractReminder";

export interface OwnProps {
  updateContractReminderProps: UpdateContractReminderProps;
}

type Props = OwnProps & DialogProps;

export default function UpdateContractReminderModal({
  updateContractReminderProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Edit reminder
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <UpdateContractReminder {...updateContractReminderProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
