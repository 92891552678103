import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import DamageDetectionMigrateToNewVisualModel, {
  DamageDetectionMigrateToNewVisualModelProps,
} from "./DamageDetectionMigrateToNewVisualModel";

export interface OwnProps {
  migrateProps: DamageDetectionMigrateToNewVisualModelProps;
}

type Props = OwnProps & DialogProps;

export default function DamageDetectionMigrateToNewVisualModelModal({
  migrateProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='xl' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Migrate damage detection to new visual model
      </AppModalTitle>
      <DialogContent>
        <DamageDetectionMigrateToNewVisualModel {...migrateProps} />
      </DialogContent>
    </Dialog>
  );
}
