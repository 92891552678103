import {
  Avatar,
  AvatarProps,
  Badge,
  Box,
  CircularProgress,
  Popover,
  SxProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useMemo, useState } from "react";

import { ColorHelper } from "@/common/helpers/color";
import { FileHelper } from "@/common/helpers/file";
import {
  ChatParticipantDto,
  GeneralByWhoDto,
  GeneralInspectorDto,
  PartyMemberReferenceDto,
  PartyReferenceDto,
  ProfileDto,
  UserDto,
  VehicleDto,
} from "@/core/api/generated";

const OnlineStatusBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    opacity: 0,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    zIndex: 0,
  },
}));

export interface AppAvatarProps extends AvatarProps {
  profile?: ProfileDto;
  user?: UserDto | null;
  participant?: ChatParticipantDto | null;
  inspector?: GeneralInspectorDto | null;
  party?: PartyReferenceDto;
  partyMember?: PartyMemberReferenceDto;
  who?: GeneralByWhoDto | null;
  size?: "small" | "medium" | "large" | number;
  withOnlineStatus?: boolean;
  isOnline?: boolean;
  isLoading?: boolean;
  withPopover?: boolean;
  sx?: AvatarProps["sx"];
}

export default function AppAvatar({
  profile,
  user,
  participant,
  inspector,
  party,
  partyMember,
  who,
  size,
  withOnlineStatus,
  isOnline,
  isLoading,
  withPopover,
  sx,
}: AppAvatarProps) {
  const width =
    (sx as any)?.width ||
    (_.isString(size) ? { small: 26, medium: 40, large: 60 }[size || "medium"] : size || 40);
  const height = (sx as any)?.height || width;
  const fontSizeMap = { 20: "0.7rem", 40: "1.25rem", 60: "1.25rem" };
  const fontSize =
    Object.entries(fontSizeMap)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .find((x) => width <= +x.key)?.value || "1.25rem";

  const name =
    profile?.personName?.name ||
    profile?.email ||
    user?.email ||
    user?.email ||
    participant?.personName?.name ||
    participant?.email ||
    inspector?.personName?.name ||
    inspector?.email ||
    partyMember?.name ||
    partyMember?.email ||
    party?.type ||
    who?.personName?.name ||
    who?.personName?.name ||
    who?.email ||
    "?";
  const email = profile?.email || user?.email || participant?.email || partyMember?.email;
  const avatarUrl =
    FileHelper.getFileThumbnailUrlOrDefault(
      profile?.avatar?.file || user?.avatar?.file || participant?.avatar?.file,
      {
        width,
        height,
      },
    ) || undefined;

  const [avatarPopoverOpenAnchorEl, setAvatarPopoverOpenAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const isAvatarPopoverOpen = Boolean(avatarPopoverOpenAnchorEl);

  const nameAbbreviations = useMemo(() => {
    const parts = _.flatten(name.split(" ").map((x) => x.substring(0, 1)));
    return parts
      .slice(0, 2)
      .filter((x) => !!x && x.length !== 0)
      .map((x) => x.substring(0, 1).toUpperCase())
      .join("");
  }, [name]);

  if (isLoading) {
    return <CircularProgress size={width} />;
  }

  return (
    <OnlineStatusBadge
      overlap='circular'
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant='dot'
      color='success'
      sx={{
        "& .MuiBadge-badge": {
          display: withOnlineStatus ? "flex" : "none",
          opacity: isOnline ? 1 : 0,
        },
      }}
    >
      <Avatar
        sx={{
          bgcolor: (theme) => theme.palette.info.main,
          width: width,
          height: height,
          fontSize: fontSize,
          ...sx,
        }}
        component='span'
        alt={name}
        src={avatarUrl}
        onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) =>
          withPopover && setAvatarPopoverOpenAnchorEl(e.currentTarget!)
        }
        onMouseLeave={() => withPopover && setAvatarPopoverOpenAnchorEl(null)}
      >
        {nameAbbreviations}
      </Avatar>

      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={isAvatarPopoverOpen}
        anchorEl={avatarPopoverOpenAnchorEl}
        onClose={() => setAvatarPopoverOpenAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <Box sx={{ maxWidth: { sm: "95vw" }, p: 1 }}>
          <Typography component='div' variant='subtitle1'>
            {name}
          </Typography>
          <Typography component='div' variant='body2' color='text.secondary'>
            {email}
          </Typography>
        </Box>
      </Popover>
    </OnlineStatusBadge>
  );
}
