import uuid4 from "uuid4";

/** Represents subscription on SignalR Hub group.
 *  When client disconnected and then reconnected, connectionId changes,
 *  so we need to re-subscribe to keep receiving data from that group.
 */
export class HubSubscription {
  public identifier?: string;
  public subscribe: () => void | Promise<void>;
  public unsubscribe: () => void | Promise<void>;
  public connectionId?: string | null;

  constructor(params: {
    identifier?: string;
    subscribe: () => void | Promise<void>;
    unsubscribe: () => void | Promise<void>;
  }) {
    this.identifier = params.identifier || uuid4();
    this.subscribe = params.subscribe;
    this.unsubscribe = params.unsubscribe;
  }
}
