import { Box } from "@mui/material";
import { ReactNode } from "react";

import { useOverflowDetector } from "@/common/hooks/useOverflowDetector";

import AppLink from "../Link/AppLink";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";

export interface TableCellContentProps {
  /** If true, tracks cell overflow and displays full cell content on popover. */
  isHandleOverflow?: boolean;
  /** Link to path/URL. */
  to?: string;
  /** Renders table cell content. */
  renderContent: () => ReactNode;
  /** Renders additional details of the cell. Displayed in popover. */
  renderDetails?: () => ReactNode;
}

/** Custom wrapper around content of table cell.
 *  Helps with formatting content.
 *  NB: Table cell size (width, height) is handled by table component (e.g. MUI DataGrid).
 *  This component is supposed to be rendered in already sized table cell and inherit its size.
 */
export default function TableCellContent({
  isHandleOverflow,
  to,
  renderContent,
  renderDetails,
}: TableCellContentProps) {
  const overflowDetector = useOverflowDetector({ enabled: isHandleOverflow });
  const isPopoverEnabled = isHandleOverflow && overflowDetector.isOverflows;

  // console.log(5, {
  //   isHandleOverflow,
  //   isOverflows: overflowDetector.isOverflows,
  //   isPopoverEnabled,
  //   isPopoverOpen,
  // });

  // NB: ensure that every element that wraps cell content fits the cell by setting the width.
  return (
    <>
      {/* Popover with detailed cell content  */}
      <AppPopover
        enabled={isPopoverEnabled}
        trigger={
          // Cell content
          <AppLink
            enabled={!!to}
            to={to || ""}
            isInheritStyles
            disableLinkStyles
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",

              // fix styles inherited from MuiDataGrid-cell
              lineHeight: "normal",

              // styles for better overflow handling
              whiteSpace: "inherit",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
              minWidth: "100%",
              maxWidth: "100%",
            }}
          >
            <Box
              ref={overflowDetector.ref}
              className='TableCellContent' // TODO: for debug
              component='div'
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",

                // fix styles inherited from MuiDataGrid-cell
                lineHeight: "normal",

                // styles for better overflow handling
                whiteSpace: "inherit",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "100%",
                minWidth: "100%",
                maxWidth: "100%",

                // ensure ellipsis is shown when text overflows any descendant element
                // NB: yes, this shows ellipsis for any descendant element, but it prevents useOverflowDetector from detecting overflow and
                // therefore we are unable to figure out when to show popover, so use this setting only when popover is not needed.
                // ...(isHandleOverflow
                //   ? undefined
                //   : {
                //       "& *": {
                //         whiteSpace: "inherit",
                //         textOverflow: "ellipsis",
                //         overflow: "hidden",
                //       },
                //     }),
              }}
            >
              {renderContent()}
            </Box>
          </AppLink>
        }
        hoverBehavior={{}}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -10, // show popover at some distance from the anchor
          horizontal: "left",
        }}
        disableRestoreFocus
        contentSx={{
          maxWidth: 400,
        }}
      >
        <AppPopoverContent>{renderDetails ? renderDetails() : renderContent()}</AppPopoverContent>
      </AppPopover>
    </>
  );
}
