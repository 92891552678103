import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateRepairMaterialDto,
  EntityType,
  RepairMaterialDto,
  UpdateRepairMaterialDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: CreateRepairMaterialDto["name"];
  description?: CreateRepairMaterialDto["description"];
  currency?: CreateRepairMaterialDto["currency"];
};

export interface CreateUpdateRepairMaterialOwnProps
  extends BaseEntityCreateUpdateInheritableProps<RepairMaterialDto, DefaultValues> {
  repairMaterialId?: string;

  createFunc?: (params: {
    dto: CreateRepairMaterialDto;
  }) => Promise<AxiosResponse<RepairMaterialDto, unknown>>;
  updateFunc?: (params: {
    repairMaterialId: string;
    dto: UpdateRepairMaterialDto;
  }) => Promise<AxiosResponse<RepairMaterialDto, unknown>>;
}

export type CreateUpdateRepairMaterialProps = CreateUpdateRepairMaterialOwnProps;

export default function CreateUpdateRepairMaterial({
  repairMaterialId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  createFunc,
  updateFunc,
}: CreateUpdateRepairMaterialProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentCurrency = useCurrentCurrency();

  const isCreate = !repairMaterialId;
  const isEdit = !!repairMaterialId;

  const repairMaterialRequest = useApiRequest(
    apiClient.repairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairMaterialId: repairMaterialId!,
    },
    {
      skip: !repairMaterialId,
    },
  );
  const repairMaterial = repairMaterialRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: repairMaterial && {
      idValue: repairMaterial.id!,
      newTitle: repairMaterial.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.RepairMaterial}
      entityId={repairMaterialId}
      entity={repairMaterial}
      entityRequest={repairMaterialRequest}
    >
      <Formik<
        BaseFormikValues &
          CreateRepairMaterialDto &
          UpdateRepairMaterialDto & {
            total?: number;
          }
      >
        enableReinitialize={isEdit}
        initialValues={{
          repairCatalogId: repairMaterial?.repairCatalogId || undefined,
          name: defaultValues?.name || repairMaterial?.name || undefined,
          description: defaultValues?.description || repairMaterial?.description || undefined,
          currency:
            defaultValues?.currency || repairMaterial?.currency || currentCurrency || undefined,
          brandName: repairMaterial?.brandName || undefined,
          unit: repairMaterial?.unit || undefined,
          price: repairMaterial?.price || undefined,
          isDividable: repairMaterial?.isDividable || false,
          discount: repairMaterial?.discount || undefined,
          tax: repairMaterial?.tax || undefined,
          total: repairMaterial?.total || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = createFunc
                ? await createFunc({
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairMaterialsApi.apiV1RepairMaterialsPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    createRepairMaterialDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair material created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = updateFunc
                ? await updateFunc({
                    repairMaterialId,
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    repairMaterialId,
                    updateRepairMaterialDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair material updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <AppTextArea
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  mode='description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description || ""}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(touched.brandName && errors.brandName)}
                  fullWidth
                  helperText={touched.brandName && errors.brandName}
                  label='Brand name (manufacturer)'
                  margin='dense'
                  name='brandName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.brandName || ""}
                  variant='outlined'
                />

                <FormControl fullWidth margin='dense' error={Boolean(touched.unit && errors.unit)}>
                  <InputLabel required>Unit</InputLabel>
                  <ApiEnumSelect
                    type='MeasurementUnit'
                    value={values.unit}
                    onChange={(newValue) => setFieldValue("unit", newValue)}
                    selectProps={{
                      label: "Unit",
                      required: true,
                    }}
                  />
                  <FormHelperText>{touched.unit && errors.unit}</FormHelperText>
                </FormControl>

                <FormControl fullWidth margin='dense'>
                  <PriceSummaryInput
                    values={{
                      currency: values.currency,
                      subTotal: values.price,
                      discount: values.discount,
                      tax: values.tax,
                      total: values.total,
                    }}
                    formikProps={{
                      errors: {
                        subTotal: errors.price,
                        discount: errors.discount,
                        tax: errors.tax,
                        total: errors.total,
                      },
                    }}
                    displayProps={{
                      insurance: false,
                      calcExplanation: true,
                    }}
                    inputsProps={{
                      all: {
                        margin: "none",
                      },
                      subTotal: {
                        label: "Price",
                        required: true,
                      },
                      total: {
                        required: true,
                      },
                    }}
                    onChange={(newValue) => {
                      setFieldValue(`currency`, newValue?.currency);
                      setFieldValue(`price`, newValue?.subTotal);
                      setFieldValue(`discount`, newValue?.discount);
                      setFieldValue(`tax`, newValue?.tax);
                      setFieldValue(`total`, newValue?.total);
                    }}
                  />
                </FormControl>

                <FormControl
                  margin='dense'
                  error={Boolean(touched.isDividable && errors.isDividable)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isDividable}
                        name='isDividable'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Typography component='span' sx={{ display: "flex" }}>
                        Dividable?
                      </Typography>
                    }
                  />
                  <FormHelperText>
                    Whether this material can be divided on parts or pieces.
                  </FormHelperText>
                  <FormHelperText error={Boolean(touched.isDividable && errors.isDividable)}>
                    {touched.isDividable && errors.isDividable}
                  </FormHelperText>
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
