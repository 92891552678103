import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useOperationsCounters } from "@/common/hooks/entity/operation/operationsCounters";
import { AppPermission } from "@/core/api/generated";

import { AppNavToNavItemParams } from "../Nav";

interface Props {
  navParams: AppNavToNavItemParams;
}

export default function OperationsItemGroup({ navParams }: Props) {
  const counters = useOperationsCounters();

  return (
    <AuthorizedElement
      permissions={[AppPermission.FleetAppAccess]}
      permissionsAny={[
        AppPermission.VisualInspectionRead,
        AppPermission.DamageDetectionRead,
        AppPermission.DamageCostEvaluationRead,
        AppPermission.AccessoryCheckRead,
        AppPermission.RepairOperationRead,
        AppPermission.WashRead,
        AppPermission.MaintenanceRead,
      ]}
    >
      <AppNavItem
        type='itemGroup'
        itemGroup={{
          startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='operations' />,
          content: "Operations",
          to: undefined,
          counters: [
            {
              title: "Damage cost evaluations: Draft",
              counter: counters?.damageCostEvaluations?.draftCount,
            },
            {
              title: "Damage cost evaluations: InProgress",
              counter: counters?.damageCostEvaluations?.inProgressCount,
            },
            {
              title: "Repair operations: Draft",
              counter: counters?.repairOperations?.draftCount,
            },
            {
              title: "Repair operations: InProgress",
              counter: counters?.repairOperations?.inProgressCount,
            },
            {
              title: "Washes: Draft",
              counter: counters?.washes?.draftCount,
            },
            {
              title: "Washes: InProgress",
              counter: counters?.washes?.inProgressCount,
            },
            {
              title: "Maintenances: Draft",
              counter: counters?.maintenances?.draftCount,
            },
            {
              title: "Maintenances: Scheduled",
              counter: counters?.maintenances?.scheduledCount,
            },
            {
              title: "Maintenances: InProgress",
              counter: counters?.maintenances?.inProgressCount,
            },
          ],
          items: (
            <>
              <AuthorizedElement permissions={[AppPermission.VisualInspectionRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='visualInspections' />,
                    content: "Visual inspections",
                    to: ROUTE_PATH.VISUAL_INSPECTIONS,
                    onClick: navParams.onItemClick,
                  }}
                />
              </AuthorizedElement>
              <AuthorizedElement permissions={[AppPermission.DamageDetectionRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='damageDetections' />,
                    content: "Damage detections",
                    to: ROUTE_PATH.DAMAGE_DETECTIONS,
                    onClick: navParams.onItemClick,
                  }}
                />
              </AuthorizedElement>
              <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='damageCostEvaluations' />,
                    content: "Damage cost evaluations",
                    to: ROUTE_PATH.DAMAGE_COST_EVALUATIONS,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Damage cost evaluations: Draft",
                        counter: counters?.damageCostEvaluations?.draftCount,
                      },
                      {
                        title: "Damage cost evaluations: InProgress",
                        counter: counters?.damageCostEvaluations?.inProgressCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>
              <AuthorizedElement permissions={[AppPermission.AccessoryCheckRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='accessoryChecks' />,
                    content: "Accessory checks",
                    to: ROUTE_PATH.ACCESSORY_CHECKS,
                    onClick: navParams.onItemClick,
                  }}
                />
              </AuthorizedElement>
              <AuthorizedElement permissions={[AppPermission.RepairOperationRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='repairOperations' />,
                    content: "Repairs",
                    to: ROUTE_PATH.REPAIR_OPERATIONS,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Repair operations: Draft",
                        counter: counters?.repairOperations?.draftCount,
                      },
                      {
                        title: "Repair operations: InProgress",
                        counter: counters?.repairOperations?.inProgressCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>
              <AuthorizedElement permissions={[AppPermission.WashRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='wash' />,
                    content: "Washes",
                    to: ROUTE_PATH.WASHES,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Washes: Draft",
                        counter: counters?.washes?.draftCount,
                      },
                      {
                        title: "Washes: InProgress",
                        counter: counters?.washes?.inProgressCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>
              <AppNavItem
                type='item'
                item={{
                  isFromGroup: true,
                  startIcon: <AppNavIcon of='wheelOperation' />,
                  content: "Wheel operations",
                  to: ROUTE_PATH.WHEEL_OPERATIONS,
                  onClick: navParams.onItemClick,
                  counters: [
                    {
                      title: "Wheel operations: Draft",
                      counter: counters?.wheelOperations?.draftCount,
                    },
                    {
                      title: "Wheel operations: InProgress",
                      counter: counters?.wheelOperations?.inProgressCount,
                    },
                  ],
                }}
              />
              <AuthorizedElement permissions={[AppPermission.MaintenanceRead]}>
                <AppNavItem
                  type='item'
                  item={{
                    isFromGroup: true,
                    startIcon: <AppNavIcon of='maintenance' />,
                    content: "Maintenances",
                    to: ROUTE_PATH.MAINTENANCES,
                    onClick: navParams.onItemClick,
                    counters: [
                      {
                        title: "Maintenances: Draft",
                        counter: counters?.maintenances?.draftCount,
                      },
                      {
                        title: "Maintenances: Scheduled",
                        counter: counters?.maintenances?.scheduledCount,
                      },
                      {
                        title: "Maintenances: InProgress",
                        counter: counters?.maintenances?.inProgressCount,
                      },
                    ],
                  }}
                />
              </AuthorizedElement>
            </>
          ),
        }}
      />
    </AuthorizedElement>
  );
}
