import { Button, Stack, TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  LocationDto,
} from "@/core/api/generated";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import LocationMenu from "../LocationMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import SpotsTabContent from "./Tabs/SpotsTabContent";
import UsersTabContent from "./Tabs/UsersTabContent";

export enum LocationViewPageTabs {
  Overview = "Overview",
  Spots = "Spots",
  Users = "Users",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<LocationDto> {
  locationId: string | null | undefined;
  location: LocationDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function LocationView({
  locationId,
  location,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Location),
      viewVariant: displayProps.viewVariant,
    },
  });

  const locationRequest = useApiRequest(
    apiClient.locationsApi.apiV1LocationsLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      locationId: locationId!,
    },
    {
      deps: [locationId],
      skip: !locationId || !!location,
    },
  );
  location = location || locationRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Location, location?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      locationRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        location && {
          idValue: location.id!,
          newTitle: location.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Location}
      entityId={locationId}
      entity={location}
      entityRequest={locationRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Location ${location?.name} (${location?.localNumber})`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.LOCATION_VIEW(location?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.LocationManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.LOCATION_EDIT(location?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                location && (
                  <LocationMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? locationRequest.replaceData(newValue) : locationRequest.refetch()
                    }
                    entity={location}
                    displayProps={{
                      actions: { edit: false, delete: true, createSalesLocation: true },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          <PageTabs
            tabIdsDefinition={LocationViewPageTabs}
            defaultTabId={LocationViewPageTabs.Overview}
            isTabManagementEnabled={false}
            commonViewParams={commonViewParams}
            tabs={[
              { label: "Overview", value: LocationViewPageTabs.Overview, isHideable: false },
              { label: "Users", value: LocationViewPageTabs.Users },
              { label: "Spots", value: LocationViewPageTabs.Spots },
            ]}
          >
            {({ activeTabId: activeTab }) =>
              location && (
                <>
                  {activeTab === LocationViewPageTabs.Overview && (
                    <OverviewTabContent location={location} />
                  )}
                  {activeTab === LocationViewPageTabs.Spots && (
                    <SpotsTabContent location={location} />
                  )}
                  {activeTab === LocationViewPageTabs.Users && (
                    <UsersTabContent location={location} />
                  )}
                </>
              )
            }
          </PageTabs>
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
