import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairSpec from "@/common/components/Entity/RepairSpec/CreateUpdateRepairSpec";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAppDispatch } from "@/common/hooks/redux";

export default function CreateUpdateRepairSpecPage() {
  const { repairSpecId } = useParams<{ repairSpecId?: string }>();
  const isCreate = !repairSpecId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new repair spec" : "Edit repair spec"} />
      }
    >
      <CreateUpdateRepairSpec
        repairSpecId={repairSpecId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.REPAIR_SPEC_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
