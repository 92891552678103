import { useEffect, useRef, useState } from "react";

import { ApiConnectionStatus, apiClient } from "@/core/api/ApiClient";

export interface IApiConnectionStatusInfo {
  status: ApiConnectionStatus;
  isConnected: boolean;
  isDisconnected: boolean;
  isJustConnected: boolean;
  isJustDisconnected: boolean;
  getStatus: () => ApiConnectionStatus;
}

/** Returns status of connection to API servers.
 *  withRecurringCheck - if disconnected start checking periodically for the connection status.
 */
export const useApiConnectionStatus = (params?: {
  /** Ping API server if disconnect detected. */
  withRecurringCheck: boolean;
}): IApiConnectionStatusInfo => {
  const withRecurringCheck = params?.withRecurringCheck || false;

  const [status, setStatus] = useState<ApiConnectionStatus>(apiClient.connectionStatus);
  const prevStatusRef = useRef<ApiConnectionStatus>(apiClient.connectionStatus);
  const pingIntervalRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    const handler = (newStatus: ApiConnectionStatus) => {
      prevStatusRef.current = status;
      setStatus(newStatus);
      if (newStatus === ApiConnectionStatus.Connected) {
        clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = undefined;
      }
    };

    apiClient.on("connectionStateChange", handler);

    return () => {
      apiClient.off("connectionStateChange", handler);
    };
  }, []);

  // periodically ping API servers to know its status
  useEffect(() => {
    if (withRecurringCheck && !pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = setInterval(() => {
        apiClient.pingApi.apiV1PingGet();
      }, 5000);

      return () => {
        clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = undefined;
      };
    }
  }, [withRecurringCheck]);

  return {
    status,
    isConnected: status === ApiConnectionStatus.Connected,
    isDisconnected: status === ApiConnectionStatus.Disconnected,
    isJustConnected:
      status === ApiConnectionStatus.Connected &&
      prevStatusRef.current === ApiConnectionStatus.Disconnected,
    isJustDisconnected:
      status === ApiConnectionStatus.Disconnected &&
      prevStatusRef.current === ApiConnectionStatus.Connected,
    getStatus: () => apiClient.connectionStatus,
  };
};
