import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import VehicleVisualModelsPaginatedList from "@/common/components/Entity/VehicleVisualModel/ListView/VehicleVisualModelsPaginatedList";

export default function VehicleVisualModelsPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Vehicle visual models' />}>
      <VehicleVisualModelsPaginatedList />
    </ViewLayoutV2>
  );
}
