import { Button, IconButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import IntegrationApiClientDeleteModal from "@/common/components/Entity/IntegrationApiClient/IntegrationApiClientDeleteModal";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  IntegrationApiClientDto,
  IntegrationApiClientGetPaginatedDto,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};

interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function IntegrationApiClientsPage({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<IntegrationApiClientGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.IntegrationApiClient,
    },
  });

  const [integrationApiClient, setIntegrationApiClient] = useState<IntegrationApiClientDto | null>(
    null,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedIntegrationApiClientsRequest = useApiRequest(
    apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      integrationApiClientGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.IntegrationApiClient),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedIntegrationApiClientsRequest.handleEntityChanged(data);
    },
  });
  const paginatedIntegrationApiClients = paginatedIntegrationApiClientsRequest.data;

  return (
    <ViewLayout
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title={"API clients"}
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.IntegrationApiClientManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_CREATE}
                >
                  Create new API Client
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => <>{item.name}</>,
            },
            {
              field: "keys",
              title: "Keys",
              flex: 1,
              renderCell: (item) => <>{item.keys?.length}</>,
              isSortable: false,
              isColumnMenuDisabled: true,
            },
          ]}
          isLoading={paginatedIntegrationApiClientsRequest.isLoading}
          rows={paginatedIntegrationApiClients?.items}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_VIEW(item.id)}
          renderRowAction={(actionParams) => (
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedMenuItem
                permissions={[AppPermission.IntegrationApiClientManage]}
                component={RouterLink}
                to={ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_EDIT(actionParams.item.id)}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.IntegrationApiClientManage]}
                onClick={() => {
                  setIntegrationApiClient(actionParams.item);
                  setIsDeleteModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
        />

        {integrationApiClient && (
          <IntegrationApiClientDeleteModal
            entity={integrationApiClient}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => paginatedIntegrationApiClientsRequest.refetch()}
          />
        )}
      </Stack>
    </ViewLayout>
  );
}
