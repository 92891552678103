import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, CustomerDto, CustomerReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import CustomerInline, { CustomerInlineProps } from "./CustomerInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: CustomerDto | CustomerReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<CustomerInlineProps>;
}

export default function CustomerLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.CustomerRead])}
      to={ROUTE_PATH.CUSTOMER_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <CustomerInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Customer"}
    />
  );
}
