import { Box, Tabs, TabsProps, styled } from "@mui/material";
import { forwardRef } from "react";

export type AppTabsProps = TabsProps;

const TabsStyled = styled(Tabs)<AppTabsProps>(({ theme }) => ({
  minHeight: "unset",
}));

/** Extends MUI Tabs. */
export default forwardRef<HTMLDivElement, AppTabsProps>(function AppTabs(
  { children, ...otherProps }: AppTabsProps,
  ref,
) {
  return (
    <Box ref={ref}>
      <TabsStyled {...otherProps}>{children}</TabsStyled>
    </Box>
  );
});
