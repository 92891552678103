import { AutocompleteProps, FormControlProps, SelectProps, TextFieldProps } from "@mui/material";
import { Moment } from "moment";

import {
  FilterValueType,
  GeneralAttachedTagsInputDto,
  GeneralAttachedTagsQueryDto,
} from "@/core/api/generated";

import { BaseEntitySearchAutocompleteInheritableProps } from "../components/Entity/components/BaseEntitySearchAutocomplete";
import { FilterDefinitionItem } from "../filters/filterDefinitionItem";
import { FilterFieldOperatorSpec } from "../filters/filterFieldOperatorSpec";
import { FilterFieldSpec } from "../filters/filterFieldSpec";
import { ApiEnumName } from "../services/enum";

// export const FilterType = {
//   /** Defined and handled manually via static API parameters. */
//   Static: "Static",
//   /** Defined and handled dynamically via dynamic API parameters. */
//   Dynamic: "Dynamic",
// } as const;
// export type FilterType = (typeof FilterType)[keyof typeof FilterType];

export const FilterFieldType = FilterValueType;
export type FilterFieldType = (typeof FilterFieldType)[keyof typeof FilterFieldType];

/** Additional meta about field type. */
export interface FilterSpecFieldTypeMeta {
  enum?: {
    enumName?: ApiEnumName;
  };
}

export type FilterValue =
  | null
  | undefined
  | string
  | number
  | boolean
  | Date
  | Moment
  | string[]
  | GeneralAttachedTagsInputDto
  | GeneralAttachedTagsQueryDto;

export type FilterValueDate = Date | Moment | string;

export interface FilterValueInputProps {
  fieldSpec: FilterFieldSpec;
  operatorSpec: FilterFieldOperatorSpec | undefined;
  item: FilterDefinitionItem;
  formControlProps: Partial<FormControlProps> | undefined;
  textFieldProps: Partial<TextFieldProps> | undefined;
  selectProps: Partial<SelectProps> | undefined;
  autocompleteProps: Partial<AutocompleteProps<any, any, any, any, any>> | undefined;
  baseEntitySearchAutocompleteProps:
    | Partial<BaseEntitySearchAutocompleteInheritableProps<any>>
    | undefined;
  onChange: (newValue: FilterValue) => void;
}
