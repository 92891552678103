import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CreateUpdateRepairMaterial from "@/common/components/Entity/RepairMaterial/CreateUpdateRepairMaterial";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function CreateUpdateRepairMaterialPage() {
  const { repairMaterialId } = useParams<{ repairMaterialId?: string }>();
  const isCreate = !repairMaterialId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new repair material" : "Edit repair material"}
        />
      }
    >
      <CreateUpdateRepairMaterial
        repairMaterialId={repairMaterialId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.REPAIR_MATERIAL_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
