import { Button, Typography, TypographyProps } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import VehicleViewPageHeader from "@/common/components/Entity/Vehicle/View/Header/VehicleViewPageHeader";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
  VisualInspectionDto,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView from "../../components/BaseEntityView";
import VisualInspectionMenu from "../VisualInspectionMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import ContractTabContent from "./Tabs/ContractTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum VisualInspectionViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Contract = "Contract",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};
const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};
interface Props {
  visualInspectionId: string | null | undefined;
  visualInspection: VisualInspectionDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

export default function VisualInspectionView({
  visualInspectionId,
  visualInspection,
  displayProps,
  headerProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.VisualInspection),
      viewVariant: displayProps?.viewVariant,
    },
  });

  const visualInspectionRequest = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      visualInspectionId: visualInspectionId!,
    },
    {
      deps: [visualInspectionId],
      skip: !visualInspectionId,
    },
  );
  visualInspection = visualInspection || visualInspectionRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.VisualInspection,
        visualInspectionId || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      visualInspectionRequest.handleEntityChanged(data);
    },
  });

  const [addToContractModalOpen, setAddToContractModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: visualInspection && {
      idValue: visualInspection?.id || "",
      newTitle: visualInspection?.localNumber || "",
    },
  });

  return (
    <BaseEntityView
      entityType={EntityType.VisualInspection}
      entityId={visualInspectionId}
      entity={visualInspection}
      entityRequest={visualInspectionRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              visualInspection?.vehicle && (
                <VehicleViewPageHeader
                  vehicleId={visualInspection?.vehicle?.id || ""}
                  vehicle={visualInspection?.vehicle || undefined}
                  withLink
                  primaryActions={
                    visualInspection?.contract?.localNumber && (
                      <Button
                        variant='outlined'
                        color='text'
                        sx={{ ml: "auto" }}
                        component={AppLink}
                        to={ROUTE_PATH.CONTRACT_VIEW(visualInspection?.contract.id)}
                      >
                        <AppIcon of='documents' />
                        <Typography mr={1} ml={1}>
                          Contract
                        </Typography>
                        <Typography>{visualInspection?.contract?.localNumber}</Typography>
                      </Button>
                    )
                  }
                  tags={
                    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                      <GeneralAttachedTagsDisplay
                        tags={visualInspection?.tags}
                        defaultIsFolded={false}
                        edit={{
                          entityType: TagEntityType.VisualInspection,
                          entityId: visualInspection?.id,
                          onSaved: (newValue) => {
                            visualInspectionRequest.updateData((x) => {
                              x.tags = newValue || undefined;
                            });
                          },
                        }}
                      />
                    </AuthorizedElement>
                  }
                />
              )
            }
            primaryActions={
              visualInspection && (
                <VisualInspectionMenu
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue
                      ? visualInspectionRequest.replaceData(newValue)
                      : visualInspectionRequest.refetch()
                  }
                  entity={visualInspection}
                />
              )
            }
          />
        }
      >
        <PageTabs
          tabIdsDefinition={VisualInspectionViewPageTabs}
          defaultTabId={VisualInspectionViewPageTabs.Overview}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: VisualInspectionViewPageTabs.Overview, isHideable: false },
            { label: "Vehicle", value: VisualInspectionViewPageTabs.Vehicle },
            ...(visualInspection?.contract?.id
              ? [
                  {
                    label: "Contract",
                    value: VisualInspectionViewPageTabs.Contract,
                  },
                ]
              : []),
            { label: "History", value: VisualInspectionViewPageTabs.GeneralHistory },
            { label: "Comments", value: VisualInspectionViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            visualInspection && (
              <>
                {activeTab === VisualInspectionViewPageTabs.Overview && (
                  <OverviewTabContent
                    onUpdate={visualInspectionRequest.refetch}
                    visualInspection={visualInspection}
                  />
                )}
                {activeTab === VisualInspectionViewPageTabs.Vehicle && (
                  <VehicleTabContent visualInspection={visualInspection} />
                )}
                {activeTab === VisualInspectionViewPageTabs.Contract && (
                  <ContractTabContent visualInspection={visualInspection} />
                )}
                {activeTab === VisualInspectionViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent visualInspection={visualInspection} />
                )}
                {activeTab === VisualInspectionViewPageTabs.Comments && (
                  <CommentsTabContent visualInspection={visualInspection} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewLayout>
    </BaseEntityView>
  );
}
