import { LoadingButton } from "@mui/lab";
import { Box, ListItemText, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";

import { renderIf } from "@/common/helpers/render/renderIf";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  DamageDetectionAggregateDto,
  DamageDetectionDto,
  VehicleDamageDto,
} from "@/core/api/generated";

import DropdownButton from "../../Button/DropdownButton";
import ContractsAutocomplete from "../Contract/ContractsAutocomplete";
import DamageDetectionsAutocomplete, {
  DamageDetectionsAutocompleteProps,
} from "../DamageDetection/DamageDetectionsAutocomplete";
import DamageDetectionAggregatesAutocomplete, {
  DamageDetectionAggregatesAutocompleteProps,
} from "../DamageDetectionAggregate/DamageDetectionAggregatesAutocomplete";
import VehicleDamagesAutocomplete, {
  VehicleDamagesAutocompleteProps,
} from "./VehicleDamagesAutocomplete";

export const VehicleDamagesSelectionMethod = {
  Autocomplete: "Autocomplete",
  Contract: "Contract",
  DamageDetection: "Damage Detection",
  DamageDetectionAggregate: "Damage Detection Aggregate",
} as const;
export type VehicleDamagesSelectionMethod =
  (typeof VehicleDamagesSelectionMethod)[keyof typeof VehicleDamagesSelectionMethod];

export interface Props {
  entities: VehicleDamageDto[] | null | undefined;
  entityIds?: string[] | null;
  searchFilters: {
    vehicleId: string;
  };
  selectionMethods?: VehicleDamagesSelectionMethod[];
  autocompleteProps?: Partial<VehicleDamagesAutocompleteProps> &
    Partial<DamageDetectionsAutocompleteProps> &
    Partial<DamageDetectionAggregatesAutocompleteProps>;
  onChange?: (newValues: VehicleDamageDto[] | undefined) => void;
}

export default function VehicleDamageAutocompleteOrByRelatedEntities({
  entities,
  entityIds,
  searchFilters,
  selectionMethods,
  autocompleteProps,
  onChange,
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [currentSelectionMethod, setCurrentSelectionMethod] =
    useState<VehicleDamagesSelectionMethod>(VehicleDamagesSelectionMethod.Autocomplete);
  const [contracts, setContracts] = useState<ContractDto[] | undefined>(undefined);
  const [damageDetections, setDamageDetections] = useState<DamageDetectionDto[] | undefined>(
    undefined,
  );
  const [damageDetectionAggregates, setDamageDetectionAggregates] = useState<
    DamageDetectionAggregateDto[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setContracts(undefined);
    setDamageDetections(undefined);
    setDamageDetectionAggregates(undefined);
  }, [currentSelectionMethod]);

  const handleChange = async () => {
    const vehicleDamagesByContract = !_.isEmpty(contracts)
      ? (
          await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByContractsGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            vehicleId: searchFilters.vehicleId,
            contractIds: _.uniq(contracts?.map((x) => x.id!) || []),
          })
        ).data
      : [];

    const vehicleDamageIds = [
      ..._.flatten(
        _.flatten(
          damageDetections?.map((x) =>
            x
              .items!.map((y) => y.damage?.id)
              .filter((y) => !!y)
              .map((y) => y!),
          ) || [],
        ),
      ),
      ..._.flatten(
        _.flatten(
          damageDetectionAggregates?.map((x) =>
            x
              .items!.map((y) => y.item?.damage?.id)
              .filter((y) => !!y)
              .map((y) => y!),
          ) || [],
        ),
      ),
    ];

    setIsLoading(true);
    try {
      let vehicleDamages = !_.isEmpty(vehicleDamageIds)
        ? (
            await apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              vehicleId: searchFilters.vehicleId,
              vehicleDamageIds: _.uniq(vehicleDamageIds),
            })
          ).data
        : [];
      vehicleDamages = _.uniqBy([...vehicleDamagesByContract, ...vehicleDamages], (x) => x.id);
      if (_.isEmpty(vehicleDamages)) {
        enqueueSnackbar("No vehicle damages found.", { variant: "info" });
      }

      onChange && onChange(_.isEmpty(vehicleDamages) ? undefined : vehicleDamages);
      setCurrentSelectionMethod(VehicleDamagesSelectionMethod.Autocomplete);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={1}>
      <Typography variant='body1'>
        <Stack direction='row' sx={{ alignItems: "center" }}>
          <span>Select vehicle damages by</span>{" "}
          <DropdownButton
            sx={{
              display: "inline-flex",
              fontWeight: "inherit",
            }}
            size='small'
            disabled={autocompleteProps?.disabled}
            buttonProps={{
              variant: "text",
              color: "secondary",
            }}
            dropdownContent={
              <MenuList>
                {Object.entries(VehicleDamagesSelectionMethod).map(([key, value], i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      setCurrentSelectionMethod(value);
                      onChange && onChange(undefined);
                    }}
                  >
                    <ListItemText>{value}</ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            }
          >
            {currentSelectionMethod}
          </DropdownButton>
          :
        </Stack>
      </Typography>

      {renderIf()
        .if(currentSelectionMethod === VehicleDamagesSelectionMethod.Autocomplete)
        .then(
          <Box>
            <VehicleDamagesAutocomplete
              entities={entities || undefined}
              entityIds={entityIds || undefined}
              searchFilters={{
                ...searchFilters,
                ...autocompleteProps?.searchFilters,
              }}
              onChange={onChange}
              {...autocompleteProps}
            />
          </Box>,
        )
        .elseif(currentSelectionMethod === VehicleDamagesSelectionMethod.Contract)
        .then(
          <Stack spacing={1}>
            <ContractsAutocomplete
              entities={contracts}
              entityIds={undefined}
              searchFilters={{
                vehicleId: searchFilters.vehicleId,
              }}
              onChange={(newValues) => {
                setContracts(newValues);
              }}
              {...autocompleteProps}
            />

            <Box>
              <LoadingButton
                variant='outlined'
                size='small'
                color='text'
                loading={isLoading}
                onClick={() => handleChange()}
              >
                Apply
              </LoadingButton>
            </Box>
          </Stack>,
        )
        .elseif(currentSelectionMethod === VehicleDamagesSelectionMethod.DamageDetection)
        .then(
          <Stack spacing={1}>
            <DamageDetectionsAutocomplete
              entities={damageDetections}
              entityIds={undefined}
              searchFilters={{
                vehicleId: searchFilters.vehicleId,
              }}
              onChange={(newValues) => {
                setDamageDetections(newValues);
              }}
              {...autocompleteProps}
            />

            <Box>
              <LoadingButton
                variant='outlined'
                size='small'
                color='text'
                loading={isLoading}
                onClick={() => handleChange()}
              >
                Apply
              </LoadingButton>
            </Box>
          </Stack>,
        )
        .elseif(currentSelectionMethod === VehicleDamagesSelectionMethod.DamageDetectionAggregate)
        .then(
          <Stack spacing={1}>
            <DamageDetectionAggregatesAutocomplete
              entities={damageDetectionAggregates}
              entityIds={undefined}
              searchFilters={{
                vehicleId: searchFilters.vehicleId,
              }}
              onChange={(newValues) => {
                setDamageDetectionAggregates(newValues);
              }}
              {...autocompleteProps}
            />

            <Box>
              <LoadingButton
                variant='outlined'
                size='small'
                color='text'
                loading={isLoading}
                onClick={() => handleChange()}
              >
                Apply
              </LoadingButton>
            </Box>
          </Stack>,
        )
        .render()}
    </Stack>
  );
}
