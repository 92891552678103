import { Box, SxProps, Theme } from "@mui/material";

export interface ColorInputProps {
  color: string | undefined;
  size?: number;
  width?: number;
  height?: number;
  isClickable?: boolean;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

/** Box with specified color as background. */
export default function ColorPreviewBox({
  color,
  size = 40,
  width,
  height,
  isClickable,
  sx,
  onClick,
}: ColorInputProps) {
  return (
    <Box
      sx={{
        width: width ?? size,
        height: height ?? size,
        backgroundColor: color,
        borderStyle: "solid",
        borderWidth: 0,
        borderColor: (th) => th.palette.divider,
        borderRadius: (th) => th.shapeCustom.borderRadiusCard,
        boxShadow: `0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)`,
        cursor: isClickable || onClick ? "pointer" : undefined,
        ...sx,
      }}
      onClick={onClick}
    ></Box>
  );
}
