import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import {
  TeslaConnectionDto,
  TeslaVehicleGetPaginatedDto,
  TeslaVehicleListItemDto,
} from "@/core/api/generated/v0.1-demo";

interface Props {
  teslaConnection: TeslaConnectionDto;
  viewVariant?: ViewLayoutVariant;
}

export default function VehiclesTabContent({ teslaConnection, viewVariant }: Props) {
  const commonRequestParams = useCommonRequestParams<TeslaVehicleGetPaginatedDto>({});

  const teslaPaginatedVehiclesRequest = useApiRequest(
    apiClient.teslaVehiclesApi.apiV01DemoProvidersTeslaVehiclesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      teslaVehicleGetPaginatedDto: {
        teslaConnectionId: teslaConnection.id || "",
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
      },
    },
    {
      deps: [teslaConnection.id, ...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      skip: !teslaConnection.id,
      commonRequestParams: commonRequestParams,
    },
  );
  const teslaPaginatedVehicles = teslaPaginatedVehiclesRequest.data;

  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        <DataTabular<TeslaVehicleListItemDto>
          columns={[
            {
              field: getTypedPath<TeslaVehicleListItemDto>().id.$path,
              title: "ID",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.id || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().vehicle_id.$path,
              title: "Vehicle ID",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.vehicle_id || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().vin.$path,
              title: "VIN",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.vin || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().access_type.$path,
              title: "Access type",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.access_type || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().display_name.$path,
              title: "Display name",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.display_name || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().state.$path,
              title: "State",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.state || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().api_version.$path,
              title: "API version",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => item.api_version || "-",
            },
            {
              field: getTypedPath<TeslaVehicleListItemDto>().fetchedAt.$path,
              title: "Fetched at",
              width: 140,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) =>
                item.fetchedAt ? <Datetime datetime={item.fetchedAt} withDurationFromNow /> : "-",
            },
          ]}
          rows={teslaPaginatedVehicles?.items}
          isLoading={teslaPaginatedVehiclesRequest.isLoading}
          getRowId={(item) => item.id?.toString() || item.vehicle_id?.toString() || ""}
          pagination={commonRequestParams.dataTabularProps.pagination}
        />
      </Stack>
    </PageTabContent>
  );
}
