import { Box, Dialog, DialogContent, DialogProps } from "@mui/material";

import TenantConnectionRequestView, {
  TenantConnectionRequestViewProps,
} from "./TenantConnectionRequestView";

export interface OwnProps {
  viewProps: TenantConnectionRequestViewProps;
}

type Props = OwnProps & DialogProps;

export default function TenantConnectionRequestViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <DialogContent>
        <Box sx={{ pb: 2 }}>
          <TenantConnectionRequestView {...viewProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
