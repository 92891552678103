import { SxProps, Theme, Typography } from "@mui/material";
import _ from "lodash";

import { CurrencyCode } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  currencyCode?: CurrencyCode | null;
  sx?: SxProps<Theme>;
}

/** Displays currency info. */
export default function CurrencyCodeDisplay({ sx, currencyCode }: Props) {
  if (_.isNil(currencyCode)) {
    return null;
  }
  return (
    <Typography component='span' color='text.secondary' sx={sx}>
      <InlineApiEnumValue type='CurrencyCode' value={currencyCode} />
    </Typography>
  );
}
