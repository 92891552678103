import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { ReactNode } from "react";

interface OwnProps {
  primaryTitle: ReactNode;
  secondaryTitle?: ReactNode;
  icon?: ReactNode;
}

type Props = OwnProps;

export default function HeaderMenuItem({ primaryTitle, secondaryTitle, icon }: Props) {
  return (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        sx={{ m: 0, p: 0 }}
        primary={
          <Typography component='div' variant='body2' color='text.secondary' sx={{ m: 0, p: 0 }}>
            {primaryTitle}
          </Typography>
        }
        secondary={secondaryTitle}
      />
    </ListItem>
  );
}
