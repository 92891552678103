import { ReactNode, Ref } from "react";
import {
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchProps,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

interface Props extends ReactZoomPanPinchProps {
  wrapperRef?: Ref<ReactZoomPanPinchContentRef>;
  children: ReactNode;
}

export default function ZoomableBlock({ wrapperRef, children, ...otherProps }: Props) {
  return (
    <TransformWrapper ref={wrapperRef} {...otherProps}>
      <TransformComponent contentStyle={{ position: "relative" }}>{children}</TransformComponent>
    </TransformWrapper>
  );
}
