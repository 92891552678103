import { Box, SxProps, Theme } from "@mui/material";

import { AccessoryDto, AccessoryReferenceDto } from "@/core/api/generated";

import AccessoryDisplay from "./AccessoryDisplay";

interface AccessoryListProps {
  accessories: AccessoryDto[] | AccessoryReferenceDto[] | null | undefined;
  columnCount?: number;
  rowSpacing?: number;
  columnSpacing?: number;
  sx?: SxProps<Theme>;
}

export default function AccessoryGrid({
  accessories,
  columnCount = 3,
  rowSpacing = 1,
  columnSpacing = 1,
  sx,
}: AccessoryListProps) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
        gridTemplateRows: "auto",
        rowGap: rowSpacing,
        columnGap: columnSpacing,
        ...sx,
      }}
    >
      {accessories?.map((accessory) => (
        <Box key={accessory.id!}>
          <AccessoryDisplay accessory={accessory} />
        </Box>
      ))}
    </Box>
  );
}
