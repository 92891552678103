import {
  Alert,
  Box,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import EntityUpdatedByInfoDisplay from "@/common/components/EntityInfo/EntityUpdatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import TextFieldForSecret from "@/common/components/Form/Input/TextFieldForSecret";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { appCommonConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  IntegrationApiClientDto,
  IntegrationApiClientKeyDto,
  IntegrationApiKeyStatus,
} from "@/core/api/generated";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import IntegrationApiClientCreateUpdateKeyModal from "../IntegrationApiClientCreateUpdateKeyModal";
import IntegrationApiClientDeleteModal from "../IntegrationApiClientDeleteModal";

export enum IntegrationApiClientViewPageTabs {
  Overview = "Overview",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<IntegrationApiClientDto> {
  integrationApiClientId: string | null | undefined;
  integrationApiClient: IntegrationApiClientDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function IntegrationApiClientView({
  integrationApiClientId,
  integrationApiClient,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();
  const { enqueueSnackbar } = useAppSnackbar();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateUpdateKeyModalOpen, setIsCreateUpdateKeyModalOpen] = useState(false);
  const [isRevokeKeyModalOpen, setIsRevokeKeyModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<IntegrationApiClientKeyDto | undefined>(undefined);

  const integrationApiClientRequest = useApiRequest(
    apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      integrationApiClientId: integrationApiClientId!,
    },
    {
      deps: [integrationApiClientId],
      skip: !integrationApiClientId || !!integrationApiClient,
    },
  );
  integrationApiClient = integrationApiClient || integrationApiClientRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.IntegrationApiClient,
        integrationApiClient?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      integrationApiClientRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        integrationApiClient && {
          idValue: integrationApiClient.id!,
          newTitle: integrationApiClient.localNumber || "",
        }) ||
      undefined,
  });

  const handleRevokeKeyConfirmed = useCallback(async () => {
    if (!integrationApiClient || !selectedKey) {
      return;
    }
    try {
      const response =
        await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdRevokePost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            integrationApiClientId: integrationApiClient.id!,
            keyId: selectedKey.id!,
          },
        );
      enqueueSnackbar("API Key was revoked.", { variant: "success" });
      setIsRevokeKeyModalOpen(false);
      integrationApiClientRequest.replaceData(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [integrationApiClient, selectedKey]);

  return (
    <BaseEntityView
      entityType={EntityType.IntegrationApiClient}
      entityId={integrationApiClientId}
      entity={integrationApiClient}
      entityRequest={integrationApiClientRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`API Client ${integrationApiClient?.name} (${integrationApiClient?.localNumber})`}
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_VIEW(integrationApiClient?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.IntegrationApiClientManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.MANAGEMENT_INTEGRATION_API_CLIENT_EDIT(
                        integrationApiClient?.id,
                      )}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                integrationApiClient && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <AppMenuItem
                      permissions={[AppPermission.IntegrationApiClientManage]}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AppMenuItem>
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          {integrationApiClient && (
            <Stack spacing={2}>
              <Alert severity='info'>
                Check out the{" "}
                <Link href={appCommonConfig.nexusOpsPublicDocsUrl} target='_black'>
                  documentation
                </Link>{" "}
                on how to use API keys
              </Alert>

              <Stack spacing={1}>
                <FieldValue label='Scope' isEmpty={!integrationApiClient?.scope?.type}>
                  <InlineApiEnumValue
                    type='IntegrationApiScopeType'
                    value={integrationApiClient.scope?.type}
                    withDescription
                  />
                </FieldValue>

                <FieldValue label='Name' isEmpty={!integrationApiClient?.name}>
                  {integrationApiClient?.name}
                </FieldValue>

                <FieldValue label='Description' isEmpty={!integrationApiClient?.description}>
                  {integrationApiClient?.description}
                </FieldValue>

                <FieldValue label='Expires at' isEmpty={!integrationApiClient.expiresAt}>
                  <Datetime datetime={integrationApiClient.expiresAt} withDurationFromNow />
                </FieldValue>

                <FieldValue label='Created' isEmpty={!integrationApiClient.createdAt}>
                  <EntityCreatedByInfoDisplay entity={integrationApiClient} />
                </FieldValue>

                <FieldValue label='Updated' isEmpty={!integrationApiClient.updatedAt}>
                  <EntityUpdatedByInfoDisplay entity={integrationApiClient} />
                </FieldValue>
              </Stack>

              {/* Keys */}
              <Stack spacing={1}>
                <Typography variant='h4'>API keys</Typography>

                <DataTabular
                  columns={[
                    {
                      field: "name",
                      title: "Name",
                      flex: 1,
                      renderCell: (item) => <>{item.name}</>,
                    },
                    {
                      field: "status",
                      title: "Status",
                      flex: 1,
                      renderCell: (item) => (
                        <InlineApiEnumValue type='IntegrationApiKeyStatus' value={item.status} />
                      ),
                    },
                    {
                      field: "expiresAt",
                      title: "Expires at",
                      flex: 1,
                      renderCell: (item) => <Datetime datetime={item.expiresAt} />,
                    },
                    {
                      field: "revokedAt",
                      title: "Revoked at",
                      flex: 1,
                      renderCell: (item) => <Datetime datetime={item.revokedAt} />,
                    },
                    {
                      field: "expiredAt",
                      title: "Expired at",
                      flex: 1,
                      renderCell: (item) => <Datetime datetime={item.expiredAt} />,
                    },
                    {
                      field: "value",
                      title: "Value",
                      flex: 1.5,
                      renderCell: (item) => (
                        <TextFieldForSecret
                          size='small'
                          value='API Key value placeholder'
                          onViewValueClick={async () => {
                            const response =
                              await apiClient.integrationApiClientsApi.apiV1IntegrationApiClientsIntegrationApiClientIdKeysKeyIdValueGet(
                                {
                                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                                  integrationApiClientId: integrationApiClient?.id || "",
                                  keyId: item.id!,
                                },
                              );
                            return { value: response.data.keyValue || "" };
                          }}
                        />
                      ),
                    },
                  ]}
                  rows={integrationApiClient?.keys}
                  getRowId={(item) => item.id!}
                  renderRowAction={(actionParams) => (
                    <MenuWithTrigger
                      withAuthCloseOnClick
                      trigger={
                        <IconButton>
                          <AppIcon of='moreVert' />
                        </IconButton>
                      }
                    >
                      <AppMenuItem
                        permissions={[AppPermission.IntegrationApiClientManage]}
                        disabled={actionParams.item.status === IntegrationApiKeyStatus.Revoked}
                        onClick={() => {
                          setSelectedKey(actionParams.item);
                          setIsCreateUpdateKeyModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='edit' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </AppMenuItem>

                      <AppMenuItem
                        permissions={[AppPermission.IntegrationApiClientManage]}
                        disabled={actionParams.item.status === IntegrationApiKeyStatus.Revoked}
                        onClick={() => {
                          setSelectedKey(actionParams.item);
                          setIsRevokeKeyModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='cancel' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Revoke</ListItemText>
                      </AppMenuItem>
                    </MenuWithTrigger>
                  )}
                />

                <Box>
                  <AuthorizedElement permissions={[AppPermission.IntegrationApiClientManage]}>
                    <Button
                      variant='outlined'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      onClick={() => {
                        setSelectedKey(undefined);
                        setIsCreateUpdateKeyModalOpen(true);
                      }}
                    >
                      Create new API Key
                    </Button>
                  </AuthorizedElement>
                </Box>
              </Stack>
            </Stack>
          )}

          {/* Delete */}
          {integrationApiClient && (
            <IntegrationApiClientDeleteModal
              entity={integrationApiClient}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => history.goBack()}
            />
          )}

          {/* Edit key */}
          {integrationApiClient && (
            <IntegrationApiClientCreateUpdateKeyModal
              open={isCreateUpdateKeyModalOpen}
              onClose={() => setIsCreateUpdateKeyModalOpen(false)}
              createUpdateProps={{
                integrationApiClient: integrationApiClient,
                clientKey: selectedKey,
                onSave: (newValue) => {
                  setIsCreateUpdateKeyModalOpen(false);
                  integrationApiClientRequest.replaceData(newValue);
                },
              }}
            />
          )}

          {/* Revoke key */}
          {integrationApiClient && selectedKey && (
            <ConfirmationModal
              title='Revoke the API Key?'
              body={
                <>
                  {`If you proceed this API key stop work and existing code clients using it won't be able to call the NexusOps API.`}
                </>
              }
              open={isRevokeKeyModalOpen}
              onClose={() => setIsRevokeKeyModalOpen(false)}
              onCancel={() => setIsRevokeKeyModalOpen(false)}
              onConfirm={handleRevokeKeyConfirmed}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
