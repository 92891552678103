import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "@/common/components/Entity/components/BaseEntityInline";
import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleDto, VehicleFullReferenceDto, VehicleReferenceDto } from "@/core/api/generated";

export interface VehicleInlineProps extends BaseEntityInlineInheritableProps {
  entity: VehicleDto | VehicleReferenceDto | VehicleFullReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function VehicleInline({ entity, entityId, ...otherProps }: VehicleInlineProps) {
  const request = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as VehicleDto | VehicleFullReferenceDto;
  const ref = entity as VehicleReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='vehicle' inText />}
      content={
        <>
          {StringHelper.joinIntoString(
            [
              entity?.plateNo?.plateNo || entity?.identificationNumber,
              entity?.spec?.make?.name,
              entity?.spec?.model?.name,
              full?.spec?.generation?.name,
              full?.spec?.modification?.name,
              full?.spec?.manufactureYear?.toString(),
              enumService.getEnumValueName("VehicleBodyType", full?.spec?.bodyType),
              enumService.getEnumValueName("VehicleFuelType", full?.spec?.fuelType),
            ],
            ", ",
            { skipEmpty: true },
          )}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Vehicle"}
    />
  );
}
