import { API_HTTP_HEADER_NAME, API_HTTP_QUERY_STRING_PARAM_NAME } from "./common";

/** Tenant identifiers used by the web app */
export const APP_TENANT_IDENTIFIERS = {
  APP: "app",
  APP_BASE_PATH: "t",
};

/** HTTP header name used to pass tenant identifier in API requests */
export const TENANT_IDENTIFIER_HEADER_NAME = API_HTTP_HEADER_NAME.TENANT_IDENTIFIER;
export const TENANT_IDENTIFIER_QUERY_PARAMETER_NAME =
  API_HTTP_QUERY_STRING_PARAM_NAME.TENANT_IDENTIFIER;

/** Tenant identifiers reserved by the app (the list must be synced with the server config MultitenancyConfig.cs) */
export const RESERVED_TENANT_IDENTIFIERS = [
  "localhost",
  "loc",
  "development",
  "dev",
  "staging",
  "stage",
  "production",
  "prod",
  "test",
  "testing",
  "qa",
  "qc",
  "qaqc",

  "quantafleet",
  "quanta",
  "qfleet",
  "qf",
  "qt",
  "q",
  "nexusops",
  "nops",
  "no",
  "ops",
  "nexusfleet",
  "nxsfleet",
  "nfleet",
  "nf",
  "n",
  "fl",
  "fleet",
  "supplier",
  "driver",
  "customer",
  "location",
  "gps",
  "track",
  "tracking",
  "estimate",
  "estimation",
  "sys",
  "system",
  "config",
  "setup",
  "run",
  "exe",

  "a",
  "app",
  "application",
  "app-developmentlocalhost",
  "app-devloc",
  "app-development",
  "app-dev",
  "app-staging",
  "app-stage",
  "app-production",
  "app-prod",
  "app-testing",
  "app-test",

  "api",
  "api-developmentlocalhost",
  "api-devloc",
  "api-development",
  "api-dev",
  "api-staging",
  "api-stage",
  "api-production",
  "api-prod",
  "api-testing",
  "api-test",
  "api-integration",

  "integration",
  "integrations",
  "integration-api",
  "integration-api-developmentlocalhost",
  "integration-api-devloc",
  "integration-api-development",
  "integration-api-dev",
  "integration-api-staging",
  "integration-api-stage",
  "integration-api-production",
  "integration-api-prod",
  "integration-api-testing",
  "integration-api-test",
  "integration-api",

  "jobrunner",
  "jobrunner-developmentlocalhost",
  "jobrunner-devloc",
  "jobrunner-development",
  "jobrunner-dev",
  "jobrunner-staging",
  "jobrunner-stage",
  "jobrunner-production",
  "jobrunner-prod",
  "jobrunner-testing",
  "jobrunner-test",
  "jobrunner-integration",

  "dec",
  "dec-developmentlocalhost",
  "dec-devloc",
  "dec-development",
  "dec-dev",
  "dec-staging",
  "dec-stage",
  "dec-production",
  "dec-prod",
  "dec-testing",
  "dec-test",
  "dec-integration",

  "hc",
  "hc-developmentlocalhost",
  "hc-devloc",
  "hc-development",
  "hc-dev",
  "hc-staging",
  "hc-stage",
  "hc-production",
  "hc-prod",
  "hc-testing",
  "hc-test",
  "hc-integration",

  "healthcheckui",
  "healthcheckui-developmentlocalhost",
  "healthcheckui-devloc",
  "healthcheckui-development",
  "healthcheckui-dev",
  "healthcheckui-staging",
  "healthcheckui-stage",
  "healthcheckui-production",
  "healthcheckui-prod",
  "healthcheckui-testing",
  "healthcheckui-test",
  "healthcheckui-integration",

  "healthcheckdashboard",
  "healthcheckdashboard-developmentlocalhost",
  "healthcheckdashboard-devloc",
  "healthcheckdashboard-development",
  "healthcheckdashboard-dev",
  "healthcheckdashboard-staging",
  "healthcheckdashboard-stage",
  "healthcheckdashboard-production",
  "healthcheckdashboard-prod",
  "healthcheckdashboard-testing",
  "healthcheckdashboard-test",
  "healthcheckdashboard-integration",

  "spa",
  "react",
  "vue",
  "angular",
  "tenant",
  "t",
  "auth",
  "authentication",
  "authorization",
  "session",
  "jwt",
  "bearer",
  "cookie",
  "admin",
  "superadmin",
  "saas",
  "billing",
  "payment",
  "example",
  "panel",
  "portal",
  "control",
  "manage",
  "management",
  "go",
  "branding",
  "company",
  "feedback",
  "respond",
  "response",
  "comment",
  "review",
  "share",
  "cache",
  "sync",
  "queue",

  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
