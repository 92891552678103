/** Based on:
 * - https://github.com/h-kanazawa/react-detectable-overflow.
 * - https://stackoverflow.com/questions/42012130/how-to-detect-overflow-of-react-component-without-reactdom
 *  Custom implementation is added to avoid installing unnecessary packages with 1-3 files.
 * */
import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useResizeDetector } from "react-resize-detector";

export interface useOverflowDetectorProps {
  enabled?: boolean;
  onChange?: (params: { isOverflows: boolean }) => void;
}

/** Detects content overflow in HTML element. */
export function useOverflowDetector({ enabled = true, onChange }: useOverflowDetectorProps): {
  isOverflows: boolean;
  ref: MutableRefObject<HTMLElement | null | undefined>;
} {
  const [isOverflows, setIsOverflows] = useState(false);
  const ref = useRef<HTMLElement>(null);

  const updateState = useCallback(
    (ref2: MutableRefObject<HTMLElement | null | undefined>) => {
      if (!enabled) {
        return;
      }
      if (!ref2.current) {
        return;
      }
      // calc overflow
      const newState =
        ref2.current.offsetWidth < ref2.current.scrollWidth ||
        ref2.current.offsetHeight < ref2.current.scrollHeight;

      // console.log(1, ref2.current, {
      //   el: ref2.current,
      //   isOverflows,
      //   newState,
      //   clientWidth: ref2.current.clientWidth,
      //   offsetWidth: ref2.current.offsetWidth,
      //   scrollWidth: ref2.current.scrollWidth,
      //   clientHeight: ref2.current.clientHeight,
      //   offsetHeight: ref2.current.offsetHeight,
      //   scrollHeight: ref2.current.scrollHeight,
      //   parent: {
      //     clientWidth: ref2.current.parentElement?.clientWidth,
      //     offsetWidth: ref2.current.parentElement?.offsetWidth,
      //     scrollWidth: ref2.current.parentElement?.scrollWidth,
      //     clientHeight: ref2.current.parentElement?.clientHeight,
      //     offsetHeight: ref2.current.parentElement?.offsetHeight,
      //     scrollHeight: ref2.current.parentElement?.scrollHeight,
      //   },
      // });

      if (newState === isOverflows) {
        return;
      }
      setIsOverflows(newState);
      onChange && onChange({ isOverflows: newState });
    },
    [enabled, onChange, setIsOverflows, isOverflows],
  );

  const resizeDetectorResult = useResizeDetector<HTMLElement>({
    targetRef: ref as MutableRefObject<HTMLElement>,
    onResize: () => {
      // console.log("resize");
      updateState(ref);
    },
  });

  useEffect(() => {
    updateState(ref);
  });

  return {
    isOverflows,
    ref: ref,
  };
}
