import _ from "lodash";
import moment from "moment";

import { LocalStorageKey } from "../constants/localStorage";
import {
  CommonViewParamsState,
  CommonViewParamsStateAggregatedLocalStorageModel,
} from "../ts/commonViewParams";
import { DatetimeHelper } from "./datetime";
import { LocalStorageHelper } from "./localStorage";
import { StringHelper } from "./string";
import { TypeHelper } from "./type";

export class CommonViewParamsHelper {
  // #region State

  public static maxItemSizeInLocalStorageInBytes = Math.min(
    100 * 1024,
    LocalStorageHelper.maxItemSizeInBytes,
  ); // 100 KiB. size per 1 entity is < 1KiB

  public static persistState(persistenceKey: string, state: CommonViewParamsState): void {
    this.persistStateToLocalStorage(persistenceKey, state);
  }

  public static getPersistedState(persistenceKey: string): CommonViewParamsState | undefined {
    return this.getPersistedStateFromLocalStorage(persistenceKey);
  }

  public static persistStateToLocalStorage(
    persistenceKey: string,
    state: CommonViewParamsState,
  ): void {
    state.lastPersistedAt = moment().utc().format();

    const persisted =
      LocalStorageHelper.getTypedJsonItem<CommonViewParamsStateAggregatedLocalStorageModel>(
        LocalStorageKey.viewParamsStateAggregated,
      ) || {};
    persisted.byKeyMap ??= {};
    persisted.byKeyMap[persistenceKey] = state;
    this.handleSizeLimitsForLocalStorage(persisted, { ignoreKeys: [persistenceKey] });
    LocalStorageHelper.setJsonItem(LocalStorageKey.viewParamsStateAggregated, persisted);
  }

  public static handleSizeLimitsForLocalStorage(
    model: CommonViewParamsStateAggregatedLocalStorageModel,
    options?: { ignoreKeys?: string[] },
  ): void {
    const oldModel = _.cloneDeep(model);
    const ignoreKeysMap = _.chain(options?.ignoreKeys || [])
      .mapKeys((x) => x)
      .mapValues((x) => true)
      .value();
    const serialized = JSON.stringify(model);
    const maxSizeBytes = this.maxItemSizeInLocalStorageInBytes;
    const sizeBytes = StringHelper.getSizeInBytes(serialized);
    console.log("CommonViewParamsHelper.handleSizeLimitsForLocalStorage.", {
      maxSizeBytes,
      sizeBytes,
      isLimitExceeded: sizeBytes > maxSizeBytes,
      model,
    });

    // cleanup old records to free space
    if (sizeBytes > maxSizeBytes) {
      console.log("CommonViewParamsHelper.handleSizeLimitsForLocalStorage. Size limit exceeded.", {
        maxSizeBytes,
        sizeBytes,
      });
      model.byKeyMap ??= {};
      let byKeyMapEntries = Object.entries(model.byKeyMap)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((x) => !ignoreKeysMap[x.key])
        .filter((x) => Boolean(x.value));
      byKeyMapEntries = _.orderBy(
        byKeyMapEntries,
        (x) => x.value.lastPersistedAt || DatetimeHelper.getMinDateAsMoment().utc().format(),
        "asc",
      ); // from old to new

      // remove N entries until the resulting object size satisfies the limit
      const removePerStepCount = 3;
      const removedByKeyMapEntries = [] as typeof byKeyMapEntries;
      let newSizeBytes = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const byKeyMapEntriesToRemove = byKeyMapEntries.splice(0, removePerStepCount);
        byKeyMapEntriesToRemove.forEach((x) => {
          delete model.byKeyMap![x.key];
        });
        removedByKeyMapEntries.push(...byKeyMapEntriesToRemove);
        const newSerialized = JSON.stringify(model);
        newSizeBytes = StringHelper.getSizeInBytes(newSerialized);
        if (newSizeBytes <= maxSizeBytes || TypeHelper.isEmpty(model.byKeyMap)) {
          break;
        }
      }
      console.log("CommonViewParamsHelper.handleSizeLimitsForLocalStorage. Cleanup completed.", {
        maxSizeBytes,
        oldSizeBytes: sizeBytes,
        newSizeBytes,
        removedByKeyMapEntries,
        oldModel,
        newModel: model,
      });
    }
  }

  public static getPersistedStateFromLocalStorage(
    persistenceKey: string,
  ): CommonViewParamsState | undefined {
    const persisted =
      LocalStorageHelper.getTypedJsonItem<CommonViewParamsStateAggregatedLocalStorageModel>(
        LocalStorageKey.viewParamsStateAggregated,
      );
    const rawState =
      persisted && persisted.byKeyMap ? persisted.byKeyMap[persistenceKey] : undefined;
    const state = rawState ? { ...rawState } : undefined;

    // restore class instances
    // ...

    return state;
  }

  // #endregion
}
