import { Redirect, useParams } from "react-router-dom";

import { AssessmentFlowViewPageTabs } from "@/common/components/Entity/AssessmentFlow/View/AssessmentFlowView";
import { ROUTE_PATH } from "@/common/constants/routing";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface AssessmentFlowChatPageQueryParams extends GeneralQueryParams {
  chatId?: string | null;
}

export default function AssessmentFlowChatPage() {
  const { assessmentFlowId } = useParams<{ assessmentFlowId?: string }>();

  // redirect to approvals tab(new design logic), as we cannot just remove this route
  // because it is used in emails and etc. already
  return (
    <Redirect
      to={ROUTE_PATH.ASSESSMENT_FLOW_VIEW(assessmentFlowId, {
        activeTabId: AssessmentFlowViewPageTabs.Approvals,
      })}
    />
  );
}
