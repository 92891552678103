import { useParams } from "react-router-dom";

import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import PartyView from "@/common/components/Entity/Party/View/PartyView";

export default function PartyViewPage() {
  const { partyId } = useParams<{ partyId?: string }>();

  return (
    <ViewLayoutV2>
      <PartyView
        partyId={partyId}
        party={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </ViewLayoutV2>
  );
}
