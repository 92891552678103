import InfoTile from "@/common/components/Form/Display/InfoTile";
import AppIcon from "@/common/components/Icons/AppIcon";
import { AccessoryDto, AccessoryReferenceDto } from "@/core/api/generated";

interface AccessoryDisplayProps {
  accessory?: AccessoryDto | AccessoryReferenceDto | null;
}

export default function AccessoryDisplay({ accessory }: AccessoryDisplayProps) {
  return (
    <InfoTile
      label={accessory?.name}
      icon={<AppIcon of='accessory' />}
      isEmpty={!accessory?.description}
    >
      {accessory?.description}
    </InfoTile>
  );
}
