import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import CustomerCreateUpdate, { CustomerCreateUpdateProps } from "./CustomerCreateUpdate";

export interface OwnProps {
  createUpdateProps: CustomerCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function CustomerCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps.customerId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} customer
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CustomerCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
