import { SortOrder } from "@/core/api/generated";

import { IdHelper } from "../helpers/id";
import { TypeHelper } from "../helpers/type";

/** NB: must be serializable to/from JSON. */
export class SortDefinitionItem {
  public readonly id: string;
  public field: string;
  public sortOrder: SortOrder;

  constructor(params: { id?: string; field: string; sortOrder?: SortOrder }) {
    this.id = params.id || IdHelper.newId();
    this.field = params.field;
    this.sortOrder = params.sortOrder ?? SortOrder.Asc;
  }

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.field) && !TypeHelper.isEmpty(this.sortOrder);
  }

  public clone(): SortDefinitionItem {
    return new SortDefinitionItem({ ...this });
  }

  /** Converts internal values to valid format before serialization to JSON. */
  public formatBeforeJsonSerialization(): void {}
}
