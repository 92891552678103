import {
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { FileHelper } from "@/common/helpers/file";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import { DataUpdatesHubClientMethodName, EntityType } from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function InvoiceView({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { invoiceId } = useParams<{ invoiceId?: string }>();
  const thunkDispatch = useAppThunkDispatch();
  const currentTenant = useCurrentTenant();

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const invoiceRequest = useApiRequest(
    apiClient.invoicesApi.apiV1InvoicesInvoiceIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      invoiceId: invoiceId!,
    },
    {
      skip: !invoiceId,
    },
  );
  const invoice = invoiceRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Invoice, invoice?.id),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      invoiceRequest.handleEntityChanged(data);
    },
  });
  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDownloadPdf = useCallback(async () => {
    try {
      setIsPdfLoading(true);
      const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
        apiClient.invoicesApi.apiV1InvoicesInvoiceIdDownloadPdfGet(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            invoiceId: invoice!.id!,
          },
          {
            responseType: "blob",
          },
        ),
      );
      FileHelper.downloadBlobFromApiResult(downloadResult);
    } finally {
      setIsPdfLoading(false);
    }
  }, [invoice]);

  if (invoiceRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!invoice) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewLayout
      displayProps={displayProps}
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: invoice && {
            idValue: invoice.id!,
            newTitle: invoice.localNumber || "",
          },
        },
      }}
      header={
        <SimpleViewPageHeader
          title={`Invoice ${invoice.localNumber}`}
          actions={
            invoice && (
              <MenuWithTrigger
                trigger={
                  <IconButton onClick={handleMoreClick} sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem
                  onClick={() => {
                    handleDownloadPdf();
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    {!isPdfLoading && <AppIcon of='download' fontSize='small' />}
                    {isPdfLoading && <CircularProgress />}
                  </ListItemIcon>
                  <ListItemText>Download PDF</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            )
          }
        />
      }
    >
      <Stack direction='column'>
        <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Status'>
                <InlineApiEnumValue type='InvoiceStatus' value={invoice.status} withDescription />
              </FieldValue>
              <FieldValue label='Payment method'>
                <InlineApiEnumValue
                  type='PaymentMethod'
                  value={invoice.paymentMethod}
                  withDescription
                />
              </FieldValue>
              <FieldValue label='Currency'>
                <GeneralCurrencyDisplay currency={invoice.currency} />
              </FieldValue>
              <FieldValue label='Description'>{invoice.description}</FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Date'>
                <Datetime datetime={invoice.date} withDurationFromNow />
              </FieldValue>
              <FieldValue label='Due date'>
                <Datetime datetime={invoice.dueAt} withDurationFromNow />
              </FieldValue>
              <FieldValue label='Sent at'>
                <Datetime datetime={invoice.sentAt} withDurationFromNow />
              </FieldValue>
              {invoice.pendingAt && (
                <FieldValue label='Pending at'>
                  <Datetime datetime={invoice.pendingAt} withDurationFromNow />
                </FieldValue>
              )}
              {invoice.paidAt && (
                <FieldValue label='Paid at'>
                  <Datetime datetime={invoice.paidAt} withDurationFromNow />
                </FieldValue>
              )}
              {invoice.cancelledAt && (
                <FieldValue label='Cancelled at'>
                  <Datetime datetime={invoice.cancelledAt} withDurationFromNow />
                </FieldValue>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Business info
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Name'>{invoice.businessInfo?.name}</FieldValue>
              <FieldValue label='Email'>{invoice.businessInfo?.email}</FieldValue>
              <FieldValue label='Phone number'>{invoice.businessInfo?.phoneNumber}</FieldValue>
              <FieldValue label='Web site URL'>{invoice.businessInfo?.webSiteUrl}</FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              {invoice.businessInfo?.address && (
                <>
                  <FieldValue label='Country'>{invoice.businessInfo?.address?.country}</FieldValue>
                  <FieldValue label='City'>{invoice.businessInfo?.address?.city}</FieldValue>
                  <FieldValue label='State'>{invoice.businessInfo?.address?.state}</FieldValue>
                  <FieldValue label='Line 1'>{invoice.businessInfo?.address?.line1}</FieldValue>
                  <FieldValue label='Line 2'>{invoice.businessInfo?.address?.line2}</FieldValue>
                  <FieldValue label='Postal code'>
                    {invoice.businessInfo?.address?.postalCode}
                  </FieldValue>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Customer business info
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={1} sx={{ mb: 2 }}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Business name'>
                {invoice.customerBusinessInfo?.businessName}
              </FieldValue>
              <FieldValue label='Name'>{invoice.customerBusinessInfo?.personName?.name}</FieldValue>
              <FieldValue label='Email'>{invoice.customerBusinessInfo?.email}</FieldValue>
              <FieldValue label='Phone number'>
                {invoice.customerBusinessInfo?.phoneNumber}
              </FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              {invoice.customerBusinessInfo?.address && (
                <>
                  <FieldValue label='Country'>
                    {invoice.customerBusinessInfo?.address?.country}
                  </FieldValue>
                  <FieldValue label='City'>
                    {invoice.customerBusinessInfo?.address?.city}
                  </FieldValue>
                  <FieldValue label='State'>
                    {invoice.customerBusinessInfo?.address?.state}
                  </FieldValue>
                  <FieldValue label='Line 1'>
                    {invoice.customerBusinessInfo?.address?.line1}
                  </FieldValue>
                  <FieldValue label='Line 2'>
                    {invoice.customerBusinessInfo?.address?.line2}
                  </FieldValue>
                  <FieldValue label='Postal code'>
                    {invoice.customerBusinessInfo?.address?.postalCode}
                  </FieldValue>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Typography component='div' variant='h6' sx={{ mb: 2 }}>
          Items
        </Typography>
        <DataTabular
          // DataListProps={{
          //   sx: {
          //     mb: 2,
          //   },
          // }}
          columns={[
            {
              field: "name",
              title: "Name",
              flex: 1,
              renderCell: (item) => (
                <>
                  <Typography component='div' variant='body1'>
                    {item.name}
                  </Typography>
                  <Typography component='div' variant='body2'>
                    {item.description}
                  </Typography>
                </>
              ),
            },
            {
              field: "amount",
              title: "Amount",
              flex: 1,
              renderCell: (item) => <>{item.amount}</>,
            },
            {
              field: "price",
              title: "Price",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.price} currency={invoice.currency} />
              ),
            },
            {
              field: "subtotal",
              title: "Sub total",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.subtotal} currency={invoice.currency} />
              ),
            },
            {
              field: "discount",
              title: "Discount",
              flex: 1,
              renderCell: (item) => (
                <GeneralDiscountDisplay discount={item.discount} currency={invoice.currency} />
              ),
            },
            {
              field: "tax",
              title: "Tax",
              flex: 1,
              renderCell: (item) => (
                <GeneralTaxDisplay tax={item.tax} currency={invoice.currency} />
              ),
            },
            {
              field: "total",
              title: "Total",
              flex: 1,
              renderCell: (item) => (
                <CurrencyValue value={item.total} currency={invoice.currency} />
              ),
            },
          ]}
          rows={invoice?.lineItems}
          getRowId={(item) => item.id!}
        />

        {/* Summary */}
        {invoice.lineItems?.length !== 0 && (
          <Stack direction='row' justifyContent='flex-end'>
            <GeneralPriceSummaryDisplay
              summary={{
                currency: invoice.currency,
                subTotal: invoice.subTotal,
                subTotalIncDiscount: invoice.subTotalIncDiscount,
                discount: invoice.discount,
                tax: invoice.tax,
                total: invoice.total,
              }}
            />
          </Stack>
        )}
      </Stack>
    </ViewLayout>
  );
}
