import { Box } from "@mui/material";

import { useUserProfile } from "@/common/hooks/useUserProfile";
import {
  PartyDto,
  PartyMemberDto,
  PartyMemberReferenceDto,
  PartyReferenceDto,
} from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  party: PartyDto | PartyReferenceDto | null | undefined;
  partyMember: PartyMemberDto | PartyMemberReferenceDto | null | undefined;
}

/** Displays party member name or fallbacks to party. */
export default function PartyMemberNameDisplay({ party, partyMember }: Props) {
  const profile = useUserProfile();
  const isCurrentUser = profile && partyMember?.userId === profile?.id;

  return (
    <Box component='span'>
      {partyMember && (
        <>
          {partyMember?.name || partyMember?.email} (
          {party?.name || <InlineApiEnumValue type='PartyType' value={party?.type} />})
        </>
      )}
      {!partyMember && (
        <>{party?.name || <InlineApiEnumValue type='PartyType' value={party?.type} />}</>
      )}{" "}
      {isCurrentUser ? "(you)" : ""}
    </Box>
  );
}
