import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import CreateContractCustomerCommunicationEntry, {
  CreateContractCustomerCommunicationEntryProps,
} from "./CreateContractCustomerCommunicationEntry";

export interface OwnProps {
  createEntryProps: CreateContractCustomerCommunicationEntryProps;
}

type Props = OwnProps & DialogProps;

export default function CreateContractCustomerCommunicationEntryModal({
  createEntryProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Create new customer communication entry
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CreateContractCustomerCommunicationEntry {...createEntryProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
