import { SpotDto } from "@/core/api/generated";

import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "../components/BaseEntityAutocompleteOrCreate";
import SpotCreateUpdate, { SpotCreateUpdateProps } from "./SpotCreateUpdate";
import SpotCreateUpdateModal from "./SpotCreateUpdateModal";
import SpotsAutocomplete, { SpotsAutocompleteProps } from "./SpotsAutocomplete";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  SpotDto,
  SpotCreateUpdateProps["defaultValues"],
  SpotsAutocompleteProps,
  Pick<SpotCreateUpdateProps, "defaultValues">
>;

export default function SelectOrCreateSpot({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      SpotDto,
      SpotCreateUpdateProps["defaultValues"],
      SpotsAutocompleteProps,
      Pick<SpotCreateUpdateProps, "defaultValues">
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <SpotsAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new spot "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <SpotCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <SpotCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}
