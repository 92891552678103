import { Moment } from "moment";
import { useState } from "react";

interface HookProps {
  defaultFrom?: DateFromType;
  defaultTo?: DateToType;
}
declare type DateFromType = Moment | undefined | null;
declare type DateToType = Moment | undefined | null;

export const useDateRangeRequest = ({ defaultFrom = null, defaultTo = null }: HookProps = {}) => {
  const [from, setFrom] = useState<DateFromType>(defaultFrom);
  const [to, setTo] = useState<DateToType>(defaultTo);

  return { from, setFrom, to, setTo };
};
