import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";

import AppIcon from "../components/Icons/AppIcon";

const useAppSnackbar = () => {
  const { enqueueSnackbar: notistackEnqueueSnackbar, closeSnackbar } = useSnackbar();

  const defaultAction = (key: SnackbarKey) => (
    <IconButton onClick={() => closeSnackbar(key)}>
      {/* t.palette.primary.contrastText because notistack use #fffff for message */}
      <AppIcon sx={{ fill: (t) => t.palette.primary.contrastText }} of='close' />
    </IconButton>
  );
  const enqueueSnackbar = (message: SnackbarMessage, options: OptionsObject = {}) => {
    const action = options.action
      ? (key: SnackbarKey) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {_.isFunction(options.action) ? options.action(key) : options.action}
            {defaultAction(key)}
          </Box>
        )
      : defaultAction;

    notistackEnqueueSnackbar(message, {
      ...options,
      action,
    });
  };

  return { enqueueSnackbar };
};

export default useAppSnackbar;
