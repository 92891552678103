import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import DamageDetectionCreateUpdate from "@/common/components/Entity/DamageDetection/DamageDetectionCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface DamageDetectionCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  contractId?: string | null;
}

export default function DamageDetectionCreateUpdatePage() {
  const { damageDetectionId } = useParams<{ damageDetectionId?: string }>();
  const { vehicleId, contractId } = useQueryParams<DamageDetectionCreateUpdateQueryParams>();
  const history = useHistory();
  const isCreate = !damageDetectionId;
  const isEdit = !!damageDetectionId;

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new damage detection" : "Edit damage detection"}
        />
      }
    >
      <DamageDetectionCreateUpdate
        damageDetectionId={damageDetectionId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
          contractId: contractId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.DAMAGE_DETECTION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
