import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { AppArea, ROUTE_PARTS, ROUTE_PATH } from "@/common/constants/routing";
import { RoutingHelper } from "@/common/helpers/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenantInfo } from "@/common/hooks/entity/tenant/useCurrentTenantInfo";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

/** Handles navigation to the root route '/'.
 *  Can perform redirects to appropriate app area based on user permissions or other conditions.
 */
export default function RootRouteEntry() {
  const generalQueryParams = useQueryParams<GeneralQueryParams>();
  const history = useHistory();
  const tenantInfo = useCurrentTenantInfo();
  const authorizationInfo = useAuthorizationInfo();
  const isRedirectingRef = useRef(false);

  useEffect(() => {
    (async () => {
      const appArea = RoutingHelper.resolveAppArea();

      if (!authorizationInfo.isPermissionsReady) {
        return;
      }
      if (isRedirectingRef.current) {
        return;
      }

      // if admin user visits root route '/' redirect to admin dashboard
      if (!appArea && authorizationInfo.hasAdminAppAccess) {
        console.log("Redirecting to admin dashboard...");
        isRedirectingRef.current = true;
        history.push(ROUTE_PATH.ADMIN_DASHBOARD);
        return;
      }

      // ensure user is at right app area
      // NB: currently fleet customer uses the same area as fleet
      if (authorizationInfo.isPermissionsReady) {
        // redirect to fleet area if no area
        if (
          !appArea &&
          (authorizationInfo.hasFleetAppAccess || authorizationInfo.hasFleetCustomerAppAccess)
        ) {
          isRedirectingRef.current = true;
          RoutingHelper.redirectToAppArea(AppArea.Fleet, ROUTE_PARTS.DASHBOARD);
          return;
        }

        // fleet customer must be only at fleet customer app area
        // if (
        //   !authorizationInfo.hasFleetAppAccess &&
        //   authorizationInfo.hasFleetCustomerAppAccess &&
        //   appArea !== AppArea.FleetCustomer
        // ) {
        //   isRedirectingRef.current = true;
        //   RoutingHelper.redirectToAppArea(AppArea.FleetCustomer);
        //   return;
        // }
      }

      // redirect to select tenant if no tenant
      if (!tenantInfo?.identifier) {
        console.log("Redirecting to tenant select...");
        isRedirectingRef.current = true;
        history.push(ROUTE_PATH.SELECT_TENANT);
        return;
      }
    })();
  }, [tenantInfo, authorizationInfo]);

  return null;
}
