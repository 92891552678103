import { IconButton, MenuItem, Typography } from "@mui/material";
import { useState } from "react";

import VehicleDamageViewModal from "@/common/components/Entity/VehicleDamage/View/VehicleDamageViewModal";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleDamageDto,
  VehicleDamageFullReferenceDto,
  VehicleDamageReferenceDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getVehicleDamageOptionTitle } from "./VehicleDamageAutocomplete";

export interface VehicleDamageInlineProps extends BaseEntityInlineInheritableProps {
  entity:
    | VehicleDamageDto
    | VehicleDamageReferenceDto
    | VehicleDamageFullReferenceDto
    | null
    | undefined;
  entityId?: string | null;
  withMenu?: boolean;
}

export default function VehicleDamageInline({
  entity,
  entityId,
  withMenu = false,
  ...otherProps
}: VehicleDamageInlineProps) {
  const [isVehicleDamageViewModalOpen, setIsVehicleDamageViewModalOpen] = useState(false);

  const request = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehicleDamagesVehicleDamageIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleDamageId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as VehicleDamageDto;
  const ref = entity as VehicleDamageReferenceDto;

  return (
    <>
      <BaseEntityInline
        entity={entity}
        request={request}
        icon={<AppIcon of='vehicleDamage' inText />}
        content={
          <>
            {full && getVehicleDamageOptionTitle(full)}{" "}
            {withMenu && (
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton size='small' sx={{ ml: 0.5, p: 0.25 }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <MenuItem dense onClick={() => setIsVehicleDamageViewModalOpen(true)}>
                  <AppIcon of='view' fontSize='small' sx={{ mr: 1 }} />
                  <Typography component='span'>View</Typography>
                </MenuItem>
              </MenuWithTrigger>
            )}
          </>
        }
        {...otherProps}
        tooltipTitle={otherProps?.tooltipTitle || "Vehicle damage"}
      />

      {/* Vehicle damage view */}
      {entity && (
        <VehicleDamageViewModal
          open={isVehicleDamageViewModalOpen}
          onClose={() => {
            setIsVehicleDamageViewModalOpen(false);
          }}
          viewProps={{
            vehicleId: full?.vehicle?.id || ref?.vehicleId,
            vehicleDamageId: entity.id,
          }}
        />
      )}
    </>
  );
}
