import { Alert, LinearProgress, Stack } from "@mui/material";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import PoolPaginatedList from "@/common/components/Entity/Pool/ListView/PoolPaginatedList";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AccessoryDto, PoolItemEntityType } from "@/core/api/generated";

import AssetLink from "../../../Asset/AssetLink";

interface Props {
  accessory: AccessoryDto;
}

export default function ItemsTabContent({ accessory }: Props) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(accessory), [accessory]);

  const paginatedForEntityRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: PoolItemEntityType.Accessory,
      entityId: accessory?.id || "",
      isIncludeAssetPools: true,
    },
    {
      deps: [accessory.id],
    },
  );
  const paginatedForEntity = paginatedForEntityRequest?.data;

  return (
    <PageTabContent>
      <Stack sx={{ px: 1.5 }} direction='column' spacing={1}>
        {entityMeta?.assetMeta?.assetId && (
          <Alert severity='info'>
            Showing also pools for the asset{" "}
            <AssetLink entity={undefined} entityId={entityMeta.assetMeta?.assetId} withTooltip />.
          </Alert>
        )}

        {paginatedForEntityRequest.isLoading && <LinearProgress />}
      </Stack>

      <PoolPaginatedList
        shouldFetch={false}
        paginated={paginatedForEntity}
        displayProps={{
          filters: false,
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}
