import { Alert, AlertTitle, Box, Stack } from "@mui/material";

import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { UrlHelper } from "@/common/helpers/url";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { UserStatus } from "@/core/api/generated";

export default function GlobalAlertsForAllAppAreas() {
  const profile = useUserProfile();

  const isInviteIncomplete = profile?.status === UserStatus.InviteIncomplete;
  const isInfoIncomplete = profile?.status === UserStatus.InfoIncomplete;
  const isAccountSuspended = profile?.status === UserStatus.Suspended;
  const isAnyAlert = isInviteIncomplete || isInfoIncomplete || isAccountSuspended || false;

  return (
    <Stack spacing={1} sx={{ textAlign: "left", mb: isAnyAlert ? 2 : undefined }}>
      {/* Profile invite incomplete */}
      {isInviteIncomplete && (
        <>
          {/* Disable as InviteIncomplete is not handled separately for now. */}
          {/* <Alert severity='error'>
              <AlertTitle>Your profile is not active!</AlertTitle>
              <Box>
                {`You haven't completed onboarding process via the invite.`} Try to request a new
                invite from your admin or the person who invited you.
              </Box>
            </Alert> */}

          <Alert severity='error'>
            <AlertTitle>Your profile is incomplete!</AlertTitle>
            <Box>
              Please fill out missing info -{" "}
              <AppLink
                to={ROUTE_PATH.AUTH_COMPLETE_INFO({ redirectUrl: UrlHelper.getCurrentUrl() })}
              >
                Complete my profile
              </AppLink>
              .
            </Box>
          </Alert>
        </>
      )}

      {/* Profile info incomplete */}
      {isInfoIncomplete && (
        <Alert severity='error'>
          <AlertTitle>Your profile is incomplete!</AlertTitle>
          <Box>
            Please fill out missing info -{" "}
            <AppLink to={ROUTE_PATH.AUTH_COMPLETE_INFO({ redirectUrl: UrlHelper.getCurrentUrl() })}>
              Complete my profile
            </AppLink>
            .
          </Box>
        </Alert>
      )}

      {/* Account suspended */}
      {isAccountSuspended && (
        <Alert severity='error'>
          <AlertTitle>Your account is suspended!</AlertTitle>
          <Box>{`This means that you can't use the app normally until you account resumed.`}</Box>
        </Alert>
      )}
    </Stack>
  );
}
