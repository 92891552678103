import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  DamageCostEvaluationAggregateDto,
  DamageCostEvaluationAggregateReferenceDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemReferenceDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDamageCostEvaluationAggregateTitle } from "./DamageCostEvaluationAggregateAutocomplete";

export interface DamageCostEvaluationAggregateInlineProps extends BaseEntityInlineInheritableProps {
  entity:
    | DamageCostEvaluationAggregateDto
    | DamageCostEvaluationAggregateReferenceDto
    | null
    | undefined;
  item?: DamageCostEvaluationItemDto | DamageCostEvaluationItemReferenceDto;
  itemId?: string | null;
  entityId?: string | null;
}

export default function DamageCostEvaluationAggregateInline({
  entity,
  entityId,
  item,
  itemId,
  ...otherProps
}: DamageCostEvaluationAggregateInlineProps) {
  const request = useApiRequest(
    apiClient.damageCostEvaluationAggregatesApi.apiV1DamageCostEvaluationsAggregatesAggregateIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      aggregateId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DamageCostEvaluationAggregateDto;
  const ref = entity as DamageCostEvaluationAggregateReferenceDto;

  item = item || (itemId ? full?.items?.find((x) => x.id === itemId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='damageCostEvaluationAggregate' inText />}
      content={
        <>
          {full && getDamageCostEvaluationAggregateTitle(full)}
          {item && <> (#{item.localNumber})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage cost evaluation aggregate"}
    />
  );
}
