import { Box, BoxProps } from "@mui/material";
import _, { DebounceSettings } from "lodash";
import { useCallback, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";

const defaultVisibilityObservableProps: BoxProps = {
  sx: {
    width: "1px",
    height: "1px",
    visibility: "hidden",
    background: "none",
    color: "transparent",
  },
};

interface Props {
  debounceParams?: {
    waitMs?: number;
    options?: DebounceSettings;
    /** Do not call on change callback with isVisible=true if element was visible. but then got invisible after waitMs. */
    isCancelWhenInvisible?: boolean;
  };
  onChange?: (isVisible: boolean) => void;
  /** Observable element (of which visibility is observed).
   *  Must be non empty element with non zero width and height.
   *  Use render function as children when you want to use default empty observable element. */
  children?: React.ReactNode | ((props: BoxProps) => React.ReactNode);
}

/** Facade for custom or lib implementation.
 *  Sensor component that notifies when it goes in or out of the window viewport.
 *  https://www.npmjs.com/package/react-visibility-sensor
 */
export default function AppVisibilitySensor({ debounceParams, onChange, children }: Props) {
  const isVisibleRef = useRef(false);

  const _onChangeDebounce = useCallback(
    _.debounce(
      (isVisible: boolean) => {
        if (isVisible && !isVisibleRef.current && debounceParams?.isCancelWhenInvisible) {
          onChange && onChange(isVisibleRef.current);
          return;
        }
        onChange && onChange(isVisible);
      },
      debounceParams?.waitMs,
      debounceParams?.options,
    ),
    [debounceParams, onChange],
  );

  const _onChange = useCallback(
    (isVisible: boolean) => {
      isVisibleRef.current = isVisible;

      if (debounceParams) {
        _onChangeDebounce(isVisible);
      } else {
        onChange && onChange(isVisible);
      }
    },
    [debounceParams, onChange],
  );

  return (
    <VisibilitySensor onChange={_onChange}>
      {(children && !_.isFunction(children) && children) ||
        (children && _.isFunction(children) && children(defaultVisibilityObservableProps))}
    </VisibilitySensor>
  );
}
