import { Box, SxProps, Theme } from "@mui/material";
import { ReactElement, useEffect, useRef } from "react";

interface ScrollIntoViewProps {
  scrollOptions?: ScrollIntoViewOptions;
  /** Set to true to start scroll.  */
  shouldScroll?: boolean;
  scrollDelayMs?: number;
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
  sx?: SxProps<Theme>;
  children?: ReactElement;
}

/** Scrolls view to this element on request. */
export default function ScrollIntoView({
  scrollOptions,
  shouldScroll,
  scrollDelayMs = 0,
  onScrollStart,
  onScrollEnd,
  sx,
  children,
}: ScrollIntoViewProps): JSX.Element {
  const scrollTargetAnchorRef = useRef<HTMLElement | null>(null);
  const isScrolling = useRef<boolean>(false);

  useEffect(() => {
    if (isScrolling.current || !shouldScroll) {
      return;
    }

    isScrolling.current = true;
    onScrollStart && onScrollStart();
    setTimeout(() => {
      scrollTargetAnchorRef.current?.scrollIntoView(
        scrollOptions || {
          behavior: "auto",
          block: "nearest",
          inline: "nearest",
        },
      );
      isScrolling.current = false;
      onScrollEnd && onScrollEnd();
    }, scrollDelayMs || undefined);
  }, [shouldScroll, scrollTargetAnchorRef.current]);

  return (
    <Box ref={scrollTargetAnchorRef} sx={sx}>
      {children}
    </Box>
  );
}
