import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { EntityType, TenantRequestDto } from "@/core/api/generated";

interface Props {
  companyRequest: TenantRequestDto;
}

export default function CommentsTabContent({ companyRequest }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat entityId={companyRequest.id!} entityType={EntityType.TenantRequest} />
      </ViewContentBlock>
    </PageTabContent>
  );
}
