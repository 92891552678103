import {
  Alert,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { GridSortItem } from "@mui/x-data-grid";
import { useState } from "react";

import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";

interface TestItem {
  id: number;
  number: number;
  title: string;
  price: number;
}

const rows: TestItem[] = [
  {
    id: 1,
    number: 1,
    title: "Jam",
    price: 55.43,
  },
  {
    id: 2,
    number: 2,
    title: "Tea",
    price: 11.2,
  },
  {
    id: 3,
    number: 3,
    title: "Onion",
    price: 5,
  },
  {
    id: 999,
    number: 999,
    title: "Apple (highlighted)",
    price: 9,
  },
];

export default function DevDataTabular() {
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(25);
  const [sortModel, setSortModel] = useState<GridSortItem | undefined>(undefined);
  const [number, setNumber] = useState<number>(0);

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        DataTabular
      </Typography>

      <Typography component='div' variant='subtitle1' sx={{ mt: 4, mb: 2 }}>
        Datagrid
      </Typography>
      <DataTabular<TestItem>
        variant={"datagrid"}
        columns={[
          {
            title: "Number",
            renderCell: (item) => <>{item.number}</>,
            field: "number",
            flex: 1,
          },
          {
            title: "Title",
            renderCell: (item) => <>{item.title}</>,
            field: "title",
            flex: 1,
          },
          {
            title: "Price",
            renderCell: (item) => <>{item.price}</>,
            field: "price",
            flex: 1,
          },
          {
            title: "Text overflow",
            renderCell: (item) => (
              <>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis cumque
                cupiditate quasi at placeat architecto molestias similique dolor, culpa in adipisci
                deleniti vel illum! Asperiores officiis consequatur nobis fuga in?
              </>
            ),
            field: "textOverflow",
            flex: 1,
          },
          {
            title: "Content overflow",
            renderCell: (item) => (
              <Stack spacing={1}>
                <Alert severity='warning'>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis cumque
                  cupiditate quasi at placeat architecto molestias similique dolor, culpa in
                  adipisci deleniti vel illum! Asperiores officiis consequatur nobis fuga in?
                </Alert>

                <AppLink to='#'>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis cumque
                  cupiditate quasi at placeat architecto molestias similique dolor, culpa in
                  adipisci deleniti vel illum! Asperiores officiis consequatur nobis fuga in?
                </AppLink>
              </Stack>
            ),
            field: "contentOverflow",
            flex: 1,
          },
        ]}
        rows={rows.slice(0, 3)}
        getRowId={(item) => item.number.toString()}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            <MenuItem>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </MenuWithTrigger>
        )}
        // DataListProps={{
        //   rowHighlightPropsGetter: (item) =>
        //     (item.number === 999 && { isHighlighted: true, duration: 1000 }) || {},
        // }}
        // DataGridProps={
        //   {
        //     // getRowHeight: (params) => "auto",
        //   }
        // }
      />
    </Box>
  );
}
