import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import EntityCommentsChat from "@/App/MainAppView/Chats/components/EntityCommentsChat";
import { AccessoryCheckDto, EntityType } from "@/core/api/generated";

interface Props {
  accessoryCheck: AccessoryCheckDto;
}

export default function CommentsTabContent({ accessoryCheck }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock spacing={2}>
        <PageTabHeader title={"Comments"} />
        <EntityCommentsChat entityId={accessoryCheck.id!} entityType={EntityType.AccessoryCheck} />
      </ViewContentBlock>
    </PageTabContent>
  );
}
