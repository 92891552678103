import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";
import LoadingMenuItem from "@/common/components/Menu/LoadingMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { MiscHelper } from "@/common/helpers/misc";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";

export default function DevMenus() {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isConfirmation1ModalOpen, setIsConfirmation1ModalOpen] = useState(false);

  const successfulActionWithDelay = async () => {
    await MiscHelper.wait(2000);
    enqueueSnackbar("Action completed.", { variant: "success" });
    setIsConfirmation1ModalOpen(false);
  };

  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Menus
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MenuWithTrigger</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <MenuWithTrigger
          trigger={
            <IconButton>
              <AppIcon of='moreVert' />
            </IconButton>
          }
        >
          <MenuItem>
            <ListItemText>Item 1</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>Item 2</ListItemText>
          </MenuItem>
        </MenuWithTrigger>

        <MenuWithTrigger
          trigger={
            <Button variant='contained' onClick={() => setIsConfirmation1ModalOpen(true)}>
              Trigger button
            </Button>
          }
        >
          <MenuItem>
            <ListItemText>Item 1</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>Item 2</ListItemText>
          </MenuItem>
        </MenuWithTrigger>

        <MenuWithTrigger trigger={<Typography component='div'>Trigger text</Typography>}>
          <MenuItem>
            <ListItemText>Item 1</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText>Item 2</ListItemText>
          </MenuItem>
        </MenuWithTrigger>
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>LoadingMenuItem</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <MenuWithTrigger
          trigger={
            <IconButton>
              <AppIcon of='moreVert' />
            </IconButton>
          }
        >
          <MenuItem>
            <ListItemText>Normal Item</ListItemText>
          </MenuItem>
          <LoadingMenuItem
            onClick={async () => {
              await MiscHelper.wait(3000);
            }}
          >
            <ListItemIcon>
              <AppIcon of='download' fontSize='small' />
            </ListItemIcon>
            <ListItemText>Loading item</ListItemText>
          </LoadingMenuItem>
        </MenuWithTrigger>
      </Stack>
    </Box>
  );
}
