import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateVehicleModelDto,
  AdminUpdateVehicleModelDto,
  VehicleType,
} from "@/core/api/generated";

import VehicleMakeAutocomplete from "../../components/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

export default function VehicleModelCreateUpdatePage() {
  const { modelId } = useParams<{ modelId?: string }>();
  const mounted = useMounted();
  const history = useHistory();

  const isCreate = !modelId;
  const isEdit = !!modelId;

  const modelRequest = useApiRequest(
    apiClient.adminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsModelIdGet,
    {
      modelId: modelId!,
    },
    {
      skip: !modelId,
    },
  );
  const model = modelRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: model && {
      idValue: model.id!,
      newTitle: model.name || model.globalNumber || "",
    },
  });

  return (
    <Formik<AdminCreateVehicleModelDto & AdminUpdateVehicleModelDto & BaseFormikValues>
      enableReinitialize={!isCreate}
      initialValues={{
        vehicleType: model?.vehicleType || VehicleType.Car,
        makeId: model?.make?.id || "",
        name: model?.name || "",
        vehicleSize: model?.vehicleSize || undefined,
        isEnabled: model?.isEnabled ?? true,
        sortOrder: model?.sortOrder || 0,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleType: Yup.string().required("Vehicle type is required"),
        // name: Yup.string().required("Name is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsPost({
              adminCreateVehicleModelDto: {
                ...values,
              },
            });
          } else {
            await apiClient.adminVehicleModelsApi.apiV1AdminReferenceDataVehiclesModelsModelIdPut({
              modelId,
              adminUpdateVehicleModelDto: {
                ...values,
              },
            });
          }

          if (mounted.current) {
            setStatus({ success: true });
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={<SimpleViewPageHeader title={isCreate ? "Create new model" : "Edit model"} />}
          >
            <form noValidate onSubmit={handleSubmit}>
              {model && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={model.isBuiltIn ?? false}
                  car2DbId={model.car2DbId}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                  overflow: "auto",
                }}
              >
                <FormControl margin='normal'>
                  <ApiEnumAutocomplete
                    type='VehicleType'
                    value={values.vehicleType}
                    label='Vehicle type'
                    required
                    disabled={isEdit}
                    textFieldProps={{
                      error: Boolean(errors.vehicleType),
                      helperText: ValidationHelper.getFormikErrorsAsString(errors.vehicleType),
                    }}
                    onChange={(newValue) => setFieldValue("vehicleType", newValue)}
                  />
                </FormControl>

                <FormControl margin='normal'>
                  <VehicleMakeAutocomplete
                    disabled={isEdit}
                    entityId={values.makeId}
                    searchFilters={{
                      vehicleType: values.vehicleType,
                    }}
                    required
                    textFieldProps={{
                      error: Boolean(errors.makeId),
                      helperText: ValidationHelper.getFormikErrorsAsString(errors.makeId),
                    }}
                    onChange={(newValue) => setFieldValue("makeId", newValue?.id || undefined)}
                  />
                </FormControl>

                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='normal'
                  error={Boolean(touched.vehicleSize && errors.vehicleSize)}
                >
                  <InputLabel>Vehicle size</InputLabel>
                  <ApiEnumSelect
                    type='VehicleSize'
                    value={values.vehicleSize}
                    onChange={(newValue) => setFieldValue("vehicleSize", newValue)}
                    selectProps={{
                      required: true,
                      label: "Vehicle size",
                    }}
                  />
                  <FormHelperText>{touched.vehicleSize && errors.vehicleSize}</FormHelperText>
                </FormControl>

                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
