import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name: Icon/Fill/arrow-ios-down
export default forwardRef<SVGSVGElement, SvgIconProps>(function ExpandMoreIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='17'
      height='16'
      viewBox='0 0 17 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99709 10.6664C9.14909 10.6664 9.30042 10.615 9.42376 10.5117L13.4238 7.17836C13.7064 6.94303 13.7451 6.52236 13.5091 6.23969C13.2738 5.95703 12.8538 5.91903 12.5704 6.15436L8.98976 9.13836L5.41509 6.26169C5.12842 6.03103 4.70842 6.07636 4.47776 6.36303C4.24709 6.6497 4.29242 7.06903 4.57909 7.30036L8.57909 10.519C8.70109 10.617 8.84909 10.6664 8.99709 10.6664Z'
      />
      <mask id='mask0_1_858' maskUnits='userSpaceOnUse' x='4' y='6' width='10' height='5'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.99709 10.6664C9.14909 10.6664 9.30042 10.615 9.42376 10.5117L13.4238 7.17836C13.7064 6.94303 13.7451 6.52236 13.5091 6.23969C13.2738 5.95703 12.8538 5.91903 12.5704 6.15436L8.98976 9.13836L5.41509 6.26169C5.12842 6.03103 4.70842 6.07636 4.47776 6.36303C4.24709 6.6497 4.29242 7.06903 4.57909 7.30036L8.57909 10.519C8.70109 10.617 8.84909 10.6664 8.99709 10.6664Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_858)'>
        <rect
          width='16'
          height='16'
          transform='matrix(-1 8.74228e-08 8.74228e-08 1 16.9971 -0.000305176)'
        />
      </g>
    </SvgIcon>
  );
});
