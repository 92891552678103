import {
  Box,
  Chip,
  FormGroup,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  EnumDto,
  VehicleArea,
  VehiclePartCategory,
  VehiclePartType,
  VehicleProjection,
} from "@/core/api/generated";

interface Props {
  /** Specifies enumType and filters (based on other enum values).  */
  enumProps:
    | {
        enumType: "VehiclePartCategory";
        area?: VehicleArea;
        projection?: VehicleProjection | null;
      }
    | {
        enumType: "VehiclePartType";
        area?: VehicleArea | null;
        projection?: VehicleProjection | null;
        category?: VehiclePartCategory | null;
      }
    | {
        enumType: "VehiclePartDescriptor";
        area?: VehicleArea | null;
        projection?: VehicleProjection | null;
        category?: VehiclePartCategory | null;
        type?: VehiclePartType | null;
        types?: VehiclePartType[] | null;
      }
    | {
        enumType: "VehicleArea";
        projection?: VehicleProjection | null;
      }
    | {
        enumType: "VehicleProjection";
        area?: VehicleArea | null;
      };
  values?: string[] | null;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  allowNone?: boolean;
  selectProps?: SelectProps<string[]>;
  onChange?: (newValues?: string[] | null, newValueDtos?: EnumDto[] | null) => void;
}

/** Select comp for back-end enums. Info about enums retrieved from API endpoints. */
export default function ApiEnumDtoMultiselect({
  enumProps,
  values,
  label,
  disabled,
  required,
  allowNone,
  selectProps,
  onChange,
}: Props) {
  const vehiclePartCategoriesRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehiclepartcategoryGet,
    {
      ...(enumProps.enumType === "VehiclePartCategory"
        ? {
            area: enumProps.area || undefined,
            projection: enumProps.projection || undefined,
          }
        : { area: undefined, projection: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehiclePartCategory",
    },
  );
  const vehiclePartCategories = vehiclePartCategoriesRequest.data || [];

  const vehiclePartTypesRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleparttypeGet,
    {
      ...(enumProps.enumType === "VehiclePartType"
        ? {
            area: enumProps.area || undefined,
            projection: enumProps.projection || undefined,
            category: enumProps.category || undefined,
          }
        : { area: undefined, projection: undefined, category: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehiclePartType",
    },
  );
  const vehiclePartTypes = vehiclePartTypesRequest.data || [];

  const vehiclePartDescriptorsRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehiclepartdescriptorGet,
    {
      ...(enumProps.enumType === "VehiclePartDescriptor"
        ? {
            area: enumProps.area || undefined,
            projection: enumProps.projection || undefined,
            category: enumProps.category || undefined,
            type: enumProps.type || undefined,
            types: enumProps.types || undefined,
          }
        : {
            area: undefined,
            projection: undefined,
            category: undefined,
            type: undefined,
            types: undefined,
          }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehiclePartDescriptor",
    },
  );
  const vehiclePartDescriptors = vehiclePartDescriptorsRequest.data || [];

  const vehicleAreasRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleareaGet,
    {
      ...(enumProps.enumType === "VehicleArea"
        ? { projection: enumProps.projection || undefined }
        : { projection: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehicleArea",
    },
  );
  const vehicleAreas = vehicleAreasRequest.data || [];

  const vehicleProjectionsRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleprojectionGet,
    {
      ...(enumProps.enumType === "VehicleProjection"
        ? { area: enumProps.area || undefined }
        : { area: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehicleProjection",
    },
  );
  const vehicleProjections = vehicleProjectionsRequest.data || [];

  let options: EnumDto[] = [];
  if (enumProps.enumType === "VehiclePartCategory") {
    options = vehiclePartCategories || [];
  } else if (enumProps.enumType === "VehiclePartType") {
    options = vehiclePartTypes || [];
  } else if (enumProps.enumType === "VehiclePartDescriptor") {
    options = vehiclePartDescriptors || [];
  } else if (enumProps.enumType === "VehicleArea") {
    options = vehicleAreas || [];
  } else if (enumProps.enumType === "VehicleProjection") {
    options = vehicleProjections || [];
  }

  const _onChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const newValues = options
        .filter((x) => x.value === e.target.value || e.target.value.includes(x.value!))
        .map((x) => x.value!);
      const selectedOptions = options.filter((x) => newValues.includes(x.value!)) || null;
      onChange && onChange(newValues, selectedOptions);
    },
    [onChange],
  );

  const values2 = values || [];

  return (
    <FormGroup>
      <Select<string[]>
        multiple
        label={label}
        displayEmpty={false}
        value={values2}
        disabled={disabled}
        required={required}
        onChange={_onChange}
        {...selectProps}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
      >
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
        {options
          .filter((x) => (allowNone ? true : x.value !== "None")) // I don't know what is being filtered.
          .map((option, index) => (
            <MenuItem key={index} value={option.value!}>
              <Stack>
                <Typography component='div' variant='body1'>
                  {option.name || option.description || option.shortName || option.value}{" "}
                  <Typography component='span' variant='caption' sx={{ ml: 1 }}>
                    ({option.value})
                  </Typography>
                </Typography>
                {option.description && (
                  <Typography component='div' variant='body2'>
                    {option.description}
                  </Typography>
                )}
              </Stack>
            </MenuItem>
          ))}
      </Select>
    </FormGroup>
  );
}
