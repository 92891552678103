import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, RepairMaterialDto, RepairMaterialReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import RepairMaterialInline, { RepairMaterialInlineProps } from "./RepairMaterialInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: RepairMaterialDto | RepairMaterialReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<RepairMaterialInlineProps>;
}

export default function RepairMaterialLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])}
      to={ROUTE_PATH.REPAIR_MATERIAL_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <RepairMaterialInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Repair material"}
    />
  );
}
