import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import RoleListItem from "@/common/components/Entity/Role/RoleListItem";
import SimpleCardListSkeleton from "@/common/components/Skeleton/SimpleCardListSkeleton";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserDto, ProfileDto, RoleDto, UserDto } from "@/core/api/generated";

interface Props {
  user?: AdminUserDto | UserDto | ProfileDto | null;
}

export default function UserRolesDisplay({ user }: Props) {
  const profile = useUserProfile();

  const [roles, setRoles] = useState<RoleDto[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (user && !roles && !isLoading) {
        const roleIds = (user as AdminUserDto).roleIds ?? (user as UserDto).roleIds;
        try {
          if (roleIds) {
            setIsLoading(true);
            const result = await apiClient.tenantAccountApi.apiV1TenantAccountRolesByIdsGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              roleIds: roleIds,
            });
            setRoles(result.data!);
          } else if (profile?.id === user.id) {
            setIsLoading(true);
            const result = await apiClient.profileApi.apiV1ProfileRolesGet({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            });
            setRoles(result.data.roles!);
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [user, profile]);

  if (isLoading) {
    return <SimpleCardListSkeleton />;
  }
  if (!roles && !isLoading) {
    return null;
  }

  return (
    <Box>
      {roles && (
        <Box>
          {roles.map((role) => (
            <RoleListItem key={role.id} role={role} withDetailsToggle isDetailsVisible={false} />
          ))}
        </Box>
      )}
    </Box>
  );
}
