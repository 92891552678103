import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AccessoryCheckView from "@/common/components/Entity/AccessoryCheck/View/AccessoryCheckView";

export default function AccessoryCheckViewPage() {
  const { accessoryCheckId } = useParams<{ accessoryCheckId?: string }>();

  return (
    <>
      <AccessoryCheckView
        accessoryCheckId={accessoryCheckId}
        withRealtimeDataUpdates
        headerProps={{
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
