import { TabProps, TabsProps } from "@mui/material";
import { ReactNode } from "react";

import { PaginationInfoDto } from "@/core/api/generated";

import { DataListRenderFuncResult } from "../components/DataList/DataList";
import { FilterDefinition } from "../filters/filterDefinition";
import { FilterFieldOperatorSpec } from "../filters/filterFieldOperatorSpec";
import { FilterSpec } from "../filters/filterSpec";
import { UseCommonRequestParamsHookResultRefetch } from "../hooks/api/useCommonRequestParams";
import { SortDefinition } from "../sorting/sortDefinition";
import { SortSpec } from "../sorting/sortSpec";
import { FilterFieldType, FilterSpecFieldTypeMeta } from "./filters";

export type TabularDisplayVariantType = "datalist" | "datagrid";

export type TabularRowActionsVariant = "iconButton" | "arbitrary";

export type TabularBorderVariant = "none" | "shadowed" | "bordered";

export type TabularValidRowModel = {
  [key: string]: any;
};

export type TabularRenderRowActionFunc<TItem extends TabularValidRowModel> = (params: {
  item: TItem;
}) => DataListRenderFuncResult;

export type TabularRowActionsProps<TItem extends TabularValidRowModel> = {
  renderActions: TabularRenderRowActionFunc<TItem>;
  variant?: TabularRowActionsVariant;
  columnProps?: Partial<TabularColumnModel<TItem>>;
};

export type TabularColumnModel<TItem extends TabularValidRowModel> =
  // CustomDataGridColumnModel<TItem> &
  //   DataListColumnDefinition<TItem> &
  {
    /** Field path. */
    field: string;
    title: string;
    description?: string;
    /** Sets the width of the column. */
    width?: number;
    /** Sets the minimum width of a column. */
    minWidth?: number;
    /** Sets the maximum width of a column. */
    maxWidth?: number;
    /** If set, it indicates that a column has fluid width. Range [0, ∞). */
    flex?: number;
    /** If the column is rendered at all. Can't be overwritten by any means.
     * E.g., use it to hide columns based on permissions. */
    if?: boolean;
    /** If the column is visible initially. Can be overwritten by user settings.
     * @default true
     */
    isVisible?: boolean;
    /** If the column visibility can be changed (hide/show).
     * @default true
     */
    isHideable?: boolean;
    /** If can sort by this column.
     * @default false
     */
    isSortable?: boolean;
    /** If can filter by this column.
     * @default false
     */
    isFilterable?: boolean;
    /** If the column can be resized.
     * @default true
     */
    isResizable?: boolean;
    /** If the column menu disabled. */
    isColumnMenuDisabled?: boolean;
    isIgnoreCellOverflow?: boolean;
    /** By default - data. */
    purpose?: TabularColumnPurpose;
    /** Cell link. Overwrites the row link. */
    to?: string | ((item: TItem) => string);
    /** Disables cell link. */
    isToDisabled?: boolean;
    /** If non-empty, column is considered as having filters enabled. */
    filters?: TabularColumnFilterProps;
    renderTitle?: () => ReactNode;
    /** Renders cell content for the item. Must fit the table cell. */
    renderCell: (item: TItem) => ReactNode;
    /** Renders extended/full/detailed cell content for the item.
     *  May not fit the table cell as displayed in popover by request. */
    renderCellDetails?: (item: TItem) => DataListRenderFuncResult;
  };

export interface TabularColumnFilterProps {
  fieldType: FilterFieldType;
  /** Info about the field type. Some field types like `Enum` require additional info. */
  fieldTypeMeta?: FilterSpecFieldTypeMeta;
  /** If empty and `isUseDefaultOperators` is `true`, auto-infers operators based on the field type. */
  operators?: FilterFieldOperatorSpec[];
  /** If `true`, auto-infers operators based on the field type. */
  isUseDefaultOperators?: boolean;
  /** If `true`, user can make only one filter for target field */
  isUseSingleFilter?: boolean;
}

/** Describes valid row/item. */
export type TabularRowModel = {
  [key: string | symbol]: any;
};
// export type TabularRowModel = DataListValidRowModel & GridValidRowModel;

/** Describes for what aim/purpose the column is used.*/
export const TabularColumnPurpose = {
  /** Column contains data. */
  Data: "Data",
  /** Column contains actions. */
  Actions: "Actions",
} as const;
export type TabularColumnPurpose = (typeof TabularColumnPurpose)[keyof typeof TabularColumnPurpose];

export interface TabularStatePersistenceProps {
  /** Used to identify the state during persist/restore. */
  persistenceKey: string;
  /** If state persistence is enabled.
   * @default false
   */
  isEnabled?: boolean;
  /** Persistence strategy to use. */
  strategy?: TabularStatePersistenceStrategy;
}

export interface TabularState {
  column?: TabularColumnState;
}

export interface TabularInitialState {
  column?: TabularColumnState;
}

export interface TabularColumnState {
  visibilityMap?: TabularColumnVisibilityMap;
  widthMap?: TabularColumnWidthMap;
}

export type TabularColumnVisibilityMap = Record<string, boolean>;

export type TabularColumnWidthMap = Record<string, number>;

export const TabularStatePersistenceStrategy = {
  /** State is persisted to the local storage. */
  LocalStorage: "LocalStorage",
} as const;
export type TabularStatePersistenceStrategy =
  (typeof TabularStatePersistenceStrategy)[keyof typeof TabularStatePersistenceStrategy];

/** Describes selected rows - {[RowId]: boolean}. */
export type TabularRowSelectionMap = Record<string, boolean>;

export interface TabularTabsProps extends TabsProps {
  tabs: TabProps[];
}

export interface TabularPaginationProps {
  /** Initial pagination info before the fetch request to the server is sent. */
  initialPaginationInfo: PaginationInfoDto | undefined;
  /** Current pagination info after the fetch request to the server was finished. */
  currentPaginationInfo: PaginationInfoDto | undefined;
  onChange: (newValue: PaginationInfoDto | undefined) => void;
}

export interface TabularSortProps {
  /** If `undefined`, the spec will be inferred from the columns. */
  sortSpec?: SortSpec;
  initialSortDefinition: SortDefinition | undefined;
  sortDefinition: SortDefinition | undefined;
  maxSortItems?: number;
  onChange: (newValue: SortDefinition | undefined) => void;
}

export interface TabularQuickFilterProps {
  initialSearch?: string;
  search: string | undefined;
  onChange: (newValue: string | undefined) => void;
}

export interface TabularFilterProps {
  /** If `undefined`, the spec will be inferred from the columns.  */
  filterSpec: FilterSpec | undefined;
  initialFilterDefinition: FilterDefinition | undefined;
  filterDefinition: FilterDefinition | undefined;
  onChange: (newValue: FilterDefinition | undefined) => void;
}

/** Stores all states in a single aggregated object to reduce clutter in local storage. */
export interface TabularStateAggregatedLocalStorageModel {
  /** {[persistenceKey]: [state]} */
  byKeyMap?: Record<string, TabularState>;
}

export interface TabularRefetchProps extends UseCommonRequestParamsHookResultRefetch {}
