import { ChatParticipantDto } from "@/core/api/generated";
import { selectChatCurrentParticipant } from "@/store/communication/selectors";

import { useAppSelector } from "../redux";
import { useUserProfile } from "../useUserProfile";

/** Returns chat participant for currently authenticated user. */
export const useChatCurrentParticipant = (
  chatId: string | null | undefined,
): ChatParticipantDto | undefined => {
  const profile = useUserProfile();
  const currentParticipant = useAppSelector((x) =>
    selectChatCurrentParticipant(x, chatId, profile?.id),
  );
  return currentParticipant;
};
