import { Box, Stack, Typography } from "@mui/material";

import AppLink from "@/common/components/Link/AppLink";
import GoBackLink from "@/common/components/Link/GoBackLink";
import { ROUTE_PATH } from "@/common/constants/routing";

function Forbidden() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        Forbidden
      </Typography>
      <Box>You don&apos;t have enough permissions to access the resource</Box>
      <Stack direction='row' spacing={2} justifyContent='center'>
        <GoBackLink />
        <AppLink to={ROUTE_PATH.ROOT}>Go home</AppLink>
      </Stack>
    </Box>
  );
}

export default Forbidden;
