import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleGenerationDto, VehicleGenerationSearchPaginatedDto } from "@/core/api/generated";

export type VehicleGenerationAutocompleteOption = BaseAutocompleteOption<VehicleGenerationDto>;

export interface VehicleGenerationAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<VehicleGenerationDto> {
  searchFilters?: Partial<Omit<VehicleGenerationSearchPaginatedDto, "search" | "includeIds">>;
}

export const entityToOption = (
  data: VehicleGenerationDto,
): VehicleGenerationAutocompleteOption => ({
  id: data.id!,
  title: StringHelper.joinIntoString([data.name], ", ", {
    skipEmpty: true,
  }),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export default function VehicleGenerationAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: VehicleGenerationAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc:
          apiClient.vehicleGenerationsApi.apiV1ReferenceDataVehiclesGenerationsSearchPost,
        limit: 25,
        parameters: {
          vehicleGenerationSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          ...newParams,
          vehicleGenerationSearchPaginatedDto: {
            ...params.vehicleGenerationSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Generation'
      placeholder='Search for generation...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AppIcon of='data' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component='div' variant='body1'>
                  {option.title}
                </Typography>
              }
              secondary={
                <Typography component='div' variant='body2'>
                  ({option.data?.startYear} - {option.data?.endYear})
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
