import { LinearProgress, Stack, Typography } from "@mui/material";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import GoBackButton from "@/common/components/Button/GoBackButton";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AdminNotificationDto } from "@/core/api/generated";

interface Props {
  notificationId: AdminNotificationDto["id"];
}

const NotificationViewPage = ({ notificationId }: Props) => {
  const notificationByIdRequest = useApiRequest(
    apiClient.adminNotificationsApi.apiV1AdminNotificationsNotificationIdGet,
    {
      notificationId: notificationId || "",
    },
    {
      deps: [notificationId],
    },
  );
  const notification = notificationByIdRequest.data;

  if (notificationByIdRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!notification) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewPageLayout header={<>Notification {notification.id}</>}>
      <Stack direction='column' spacing={2}>
        <FieldValue label='Id'>{notification.id || "-"}</FieldValue>
        <FieldValue label='UserId'>{notification.userId || "-"}</FieldValue>
        <FieldValue label='Channel'>
          <InlineApiEnumValue type='NotificationChannel' value={notification.channel} />
        </FieldValue>
        <FieldValue label='Importance'>
          <InlineApiEnumValue type='NotificationImportance' value={notification.importance} />
        </FieldValue>
        <FieldValue label='Is Sent'>{notification.isSent ? "True" : "False"}</FieldValue>
        <FieldValue label='Sent At'>
          <Datetime datetime={notification.sentAt} />
        </FieldValue>
        <FieldValue label='Is Read'>{notification.isRead ? "True" : "False"}</FieldValue>
      </Stack>
    </ViewPageLayout>
  );
};

export default NotificationViewPage;
