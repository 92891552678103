import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, AssetDto, AssetReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssetInline, { AssetInlineProps } from "./AssetInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: AssetDto | AssetReferenceDto | null;
  entityId?: string | null;
  inlineProps?: Partial<AssetInlineProps>;
}

export default function AssetLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.AssetRead])}
      to={ROUTE_PATH.ASSET_VIEW(entityId || entity?.id || "")}
      icon={undefined}
      content={<AssetInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset"}
    />
  );
}
