import { Alert, AlertTitle, Box, Stack, SxProps, Theme } from "@mui/material";

import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import { DamageCostEvaluationHelper } from "@/common/helpers/entity/damageCostEvaluation";
import {
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemUpdateDto,
  DamageCostEvaluationUpdateDto,
  RepairSpecDto,
} from "@/core/api/generated";

interface OwnProps {
  entity:
    | DamageCostEvaluationDto
    | DamageCostEvaluationCreateDto
    | DamageCostEvaluationUpdateDto
    | null
    | undefined;
  item:
    | DamageCostEvaluationItemDto
    | (DamageCostEvaluationItemUpdateDto & { repairSpec?: RepairSpecDto });
  sx?: SxProps<Theme>;
}

export type CurrencyMismatchWithRepairSpecAlertProps = OwnProps;

export default function CurrencyMismatchWithRepairSpecAlert({
  entity,
  item,
  sx,
}: CurrencyMismatchWithRepairSpecAlertProps) {
  return DamageCostEvaluationHelper.isCurrencyMismatchWithRepairSpec(entity, item?.repairSpec) ? (
    <Alert severity='warning' sx={sx}>
      <AlertTitle>Currency mismatch detected!</AlertTitle>
      <Stack>
        <Box>
          Damage cost evaluation uses{" "}
          <GeneralCurrencyDisplay currency={entity?.currency} sx={{ color: "inherit" }} />, but
          repair spec uses{" "}
          <GeneralCurrencyDisplay currency={item.repairSpec?.currency} sx={{ color: "inherit" }} />.{" "}
          {`Prices won't be converted automatically between different currencies.`}{" "}
          {`You need to resolve the mismatch manually.`}{" "}
          <>
            If not, <GeneralCurrencyDisplay currency={entity?.currency} sx={{ color: "inherit" }} />{" "}
            will be used.
          </>
        </Box>
      </Stack>
    </Alert>
  ) : null;
}
