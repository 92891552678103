import { Divider, Stack, TextField } from "@mui/material";
import { useState } from "react";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export default function CurrenciesPage() {
  const [search, setSearch] = useState("");

  const currenciesRequest = useApiRequest(
    apiClient.adminGeneralReferenceDataApi.apiV1AdminReferenceDataGeneralCurrenciesGet,
    {
      search,
    },
    {
      deps: [],
      debouncedDeps: {
        deps: [search],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const { data: currencies } = currenciesRequest;

  return (
    <ListPageLayout>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ alignItems: { xs: "stretch", md: "center" }, mb: 2 }}
      >
        <TextField
          label='Search'
          size='small'
          margin='normal'
          type='text'
          variant='outlined'
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ margin: 0, minWidth: 300, maxWidth: "100%" }}
        />
      </Stack>

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => item.name,
          },
          {
            field: "code",
            title: "Code",
            flex: 1,
            renderCell: (item) => item.code,
          },
        ]}
        rows={currencies}
        getRowId={(item) => item.code!}
        rowTo={undefined}
      />
    </ListPageLayout>
  );
}
