import { Button, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import AddUserToTenantModal from "./Modal/AddUserToTenantModal";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import UsersTabContent from "./Tabs/UsersTabContent";

export enum AdminTenantViewPageTabs {
  Overview = "Overview",
  Users = "Users",
}

export default function TenantViewPage() {
  const { tenantId } = useParams<{ tenantId?: string }>();

  const [addUserToTenantModalOpen, setAddUserToTenantModalOpen] = useState(false);

  const tenantRequest = useApiRequest(
    apiClient.adminTenantsApi.apiV1AdminTenantsTenantIdGet,
    {
      tenantId: tenantId!,
    },
    {
      deps: [tenantId],
      skip: !tenantId,
    },
  );
  const tenant = tenantRequest.data;

  return (
    <ViewPageLayout>
      <Stack spacing={2}>
        <DetailedViewPageHeader
          image={undefined}
          title={`Tenant ${tenant?.name}`}
          secondaryActions={[
            <Button
              key={0}
              variant='contained'
              color='primary'
              onClick={() => setAddUserToTenantModalOpen(true)}
            >
              <ListItemIcon>
                <AppIcon of='add' />
              </ListItemIcon>
              <ListItemText>Add user to tenant</ListItemText>
            </Button>,
          ]}
        />

        <PageTabs
          tabIdsDefinition={AdminTenantViewPageTabs}
          defaultTabId={AdminTenantViewPageTabs.Overview}
          tabs={[
            { label: "Overview", value: AdminTenantViewPageTabs.Overview },
            { label: "Users", value: AdminTenantViewPageTabs.Users },
          ]}
        >
          {({ activeTabId: activeTab }) => (
            <>
              {activeTab === AdminTenantViewPageTabs.Overview && (
                <OverviewTabContent tenant={tenant} />
              )}
              {activeTab === AdminTenantViewPageTabs.Users && (
                <UsersTabContent tenantId={tenantId!} />
              )}
            </>
          )}
        </PageTabs>

        <AddUserToTenantModal
          open={addUserToTenantModalOpen}
          onClose={() => setAddUserToTenantModalOpen(false)}
          tenantId={tenantId!}
        />
      </Stack>
    </ViewPageLayout>
  );
}
