import pluralize from "pluralize";

export class TextHelper {
  public static getCodeFromText(
    text: string,
    codeLength: number,
    codeCase: "upper" | "lower" | "original",
  ): string {
    const code = text.substring(0, codeLength);
    return codeCase === "original"
      ? code
      : codeCase === "upper"
        ? code.toLocaleUpperCase()
        : code.toLocaleLowerCase();
  }

  /** Transforms word to plural form if count is greater than 1 by using provided plural value. */
  public static pluralizeManual(word: string, count: number, plural = word + "s") {
    return [1, -1].includes(Number(count)) ? word : plural;
  }

  /** Transforms word to plural form if count is greater than 1. */
  public static pluralize(word: string, count: number) {
    return pluralize(word, count);
  }

  /** Transforms word to singular form if word is in plural form. */
  public static unpluralize(word: string) {
    return pluralize.singular(word);
  }

  /** Converts string to human readable format.
   *  Examples:
      this is a test -> This is a test
      foo Bar Baz    -> Foo bar baz
      foo_bar        -> Foo bar
      foo_bar_baz    -> Foo bar baz
      foo-bar        -> Foo-bar
      fooBarBaz      -> FooBarBaz
   */
  public static humanize(str: string): string {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_-\s]+/g, " ")
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  public static toCamelCase(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  public static toPascalCase(str: string): string {
    return str
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(new RegExp(/\s+(.)(\w*)/, "g"), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  }
}
