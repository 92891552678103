import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import WashCreateUpdate, { WashCreateUpdateProps } from "./WashCreateUpdate";

export interface OwnProps {
  createUpdateProps: WashCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function WashCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps.washId;

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} wash
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <WashCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
