import { TypedUseSelectorHook, shallowEqual, useDispatch, useSelector } from "react-redux";

import type { AppDispatchType, AppRootStateType, AppThunkDispatchType } from "@/store";

// Define react-redux typed hooks
// Use throughout your app instead of plain `useDispatch` and `useSelector`
// https://react-redux.js.org/tutorials/typescript-quick-start#define-typed-hooks
export const useAppDispatch: () => AppDispatchType = useDispatch<AppDispatchType>;
export const useAppThunkDispatch: () => AppThunkDispatchType = useDispatch<AppThunkDispatchType>;
export const useAppSelector: TypedUseSelectorHook<AppRootStateType> = useSelector;

export function useShallowEqualSelector<TSelected>(
  selector: (state: AppRootStateType) => TSelected,
) {
  return useAppSelector(selector, shallowEqual);
}
