import { useHistory } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import VehicleCreateManySimilar from "@/common/components/Entity/Vehicle/VehicleCreateManySimilar";

export default function VehicleCreateManyPage() {
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={"Create many similar vehicles"} />}
    >
      <VehicleCreateManySimilar
        onSave={(newValues) => {
          history.goBack();
        }}
      />
    </CreateUpdatePageLayout>
  );
}
