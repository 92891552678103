import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { I18nHelper } from "@/common/helpers/i18n";
import { ProfileDto } from "@/core/api/generated";
import { AppRootStateType } from "@/store";

import { _profileFetched, _profileUpdated, profileUpdated } from "./slice";

export const profileMiddleware: Middleware<
  any,
  AppRootStateType,
  ThunkDispatch<any, any, AnyAction>
> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    // when profile fetched/updated ensure user i18n settings are stored locally
    if (
      action.type === _profileFetched.type ||
      action.type === _profileUpdated.type ||
      action.type === profileUpdated.type
    ) {
      const typedAction = action as PayloadAction<ProfileDto>;
      const profile = typedAction.payload;

      I18nHelper.saveUserI18nSettingsLocally({
        cultureName: profile?.settings?.culture?.name,
        tzId: profile?.settings?.tzId,
      });
    }

    return next(action);
  };
