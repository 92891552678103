import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleOperations from "@/common/components/EntityComposed/Operations/VehicleOperations";
import { VehicleDto } from "@/core/api/generated";

interface Props {
  vehicle: VehicleDto;
}

export default function OperationsTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <VehicleOperations
        vehicleId={vehicle.id}
        displayProps={{ viewVariant: ViewLayoutVariant.Tab, header: false }}
      />
    </PageTabContent>
  );
}
