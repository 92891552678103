import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ChatParticipantDto, ChatParticipantReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface ChatParticipantInlineProps extends BaseEntityInlineInheritableProps {
  chatId: string | null | undefined;
  entity: ChatParticipantDto | ChatParticipantReferenceDto | null | undefined;
  entityId?: string | null;
}

export default function ChatParticipantInline({
  chatId,
  entity,
  entityId,
  ...otherProps
}: ChatParticipantInlineProps) {
  const request = useApiRequest(
    apiClient.chatParticipantsApi.apiV1ChatsChatIdParticipantsParticipantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      chatId: chatId || "",
      participantId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as ChatParticipantDto;
  const ref = entity as ChatParticipantReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='chat' inText />}
      content={<>{entity?.personName?.name || entity?.email}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Chat participant"}
    />
  );
}
