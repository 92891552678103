import { Box } from "@mui/material";
import { ReactNode } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { VehicleMakeDto, VehicleMakeReferenceDto } from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";

interface Props {
  make: VehicleMakeDto | VehicleMakeReferenceDto | null | undefined;
  withIcon?: boolean;
  withLink?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

export default function VehicleMakeInline({
  make,
  withIcon = true,
  withLink = true,
  withTooltip = false,
  tooltipTitle,
}: Props) {
  if (!make) {
    return null;
  }

  const full = make as VehicleMakeDto;
  const ref = make as VehicleMakeReferenceDto;

  return (
    <AppTooltip enabled={withTooltip} title={tooltipTitle || "Vehicle make"}>
      <AppLink
        // enabled={withLink}
        // to={
        //   (withLink &&
        //     ROUTE_PATH.VEHICLE_VISUAL_MODEL_VIEW({ vehicleVisualModelId: visualModel.id! })) ||
        //   ""
        // }
        enabled={false}
        to=''
        sx={{
          display: "inline-flex",
          alignItems: "center",
          fontSize: "inherit",
          underline: "none",
        }}
      >
        {withIcon && <AppIcon of='vehicleMake' inText sx={{ mr: 1 }} />}

        <Box component='span'>{make.name}</Box>
      </AppLink>
    </AppTooltip>
  );
}
