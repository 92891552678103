import { Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import FileItemView from "@/common/components/Files/FileItemView";
import FileListView from "@/common/components/Files/FileListView";
import { FileItem } from "@/common/fileItem";
import { GeneralLogoResizedDto } from "@/core/api/generated";

interface Props {
  logo: GeneralLogoResizedDto | null | undefined;
}

export default function GeneralLogoResizedDisplay({ logo }: Props) {
  const logosComputed = useMemo(() => {
    const logosOrdered = _.orderBy(
      logo?.logos || [],
      (x) => (x.size?.width ?? 0) + (x.size?.height ?? 0),
      "asc",
    );
    return logosOrdered;
  }, [logo]);

  return (
    <Stack spacing={1}>
      {_.isNil(logo) && _.isEmpty(logosComputed) && <NoDataAlert title='No logo' />}

      {logo && (
        <Stack alignItems='flex-start'>
          <Typography variant='subtitle1'>Original size</Typography>

          <FileItemView fileItem={FileItem.createFrom(logo, false)} />
        </Stack>
      )}

      <FoldableBlock
        defaultIsFolded
        trigger={{
          label: <Typography variant='subtitle1'>Other sizes</Typography>,
        }}
      >
        {_.isEmpty(logosComputed) && <NoDataAlert title='No logos' />}

        {logosComputed?.map((item, i) => (
          <Stack key={i} alignItems='flex-start'>
            <Typography>
              {item.size?.width}x{item.size?.height}
            </Typography>

            <FileItemView fileItem={FileItem.createFrom(item, false)} />
          </Stack>
        ))}
      </FoldableBlock>
    </Stack>
  );
}
