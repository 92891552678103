import { Box, Card, CardContent, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";

import InlineCode from "@/common/components/Code/InlineCode";
import AppTab, { AppTabProps } from "@/common/components/Tab/AppTab";
import AppTabs from "@/common/components/Tab/AppTabs";

import PageTabs from "../Layouts/PageBody/PageTabs";

export enum ExamplePageTabs {
  Overview = "Overview",
  Attachments = "Attachments",
  Misc = "Misc",
}

export default function DevTabs() {
  const [activeTab1, setActiveTab1] = useState(0);

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h1'>
        Tabs
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>Mui Tabs</InlineCode>
        </Typography>
        <Box>
          <Tabs
            value={activeTab1}
            variant='scrollable'
            onChange={(e, newTab) => {
              setActiveTab1(newTab);
            }}
          >
            {Array.from({ length: 5 }).map((tab, i) => (
              <Tab key={i} label={`Tab ${i + 1}`} value={i} />
            ))}
          </Tabs>
          <Box>{`Tab ${activeTab1 + 1}`}</Box>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>AppTabs</InlineCode>
        </Typography>

        <Stack spacing={2}>
          <Typography component='div' variant='h4'>
            Default
          </Typography>

          <Box>
            <AppTabs
              value={activeTab1}
              variant='scrollable'
              onChange={(e, newTab) => {
                setActiveTab1(newTab);
              }}
            >
              {Array.from({ length: 5 }).map((tab, i) => (
                <AppTab key={i} label={`Tab ${i + 1}`} value={i} />
              ))}
            </AppTabs>
            <Box>{`Tab ${activeTab1 + 1} content`}</Box>
          </Box>
        </Stack>

        <Stack direction='column' spacing={1}>
          <Typography component='div' variant='h4'>
            Variants
          </Typography>

          <Stack spacing={2}>
            {(
              [undefined, "default", "contained", "outlined"] as Array<AppTabProps["variant2"]>
            ).map((variant2, i) => {
              return (
                <Stack key={i} spacing={1}>
                  <Typography component='div' variant='h6'>
                    variant2={variant2 || "undefined"}
                  </Typography>
                  <Stack spacing={1}>
                    {(["extraSmall", "small", "medium"] as Array<AppTabProps["size"]>).map(
                      (size, j) => (
                        <Stack key={j} spacing={1}>
                          <Card>
                            <CardContent>
                              <AppTabs
                                value={activeTab1}
                                onChange={(e, newTab) => {
                                  setActiveTab1(newTab);
                                }}
                              >
                                {Array.from({ length: 5 }).map((tab, z) => (
                                  <AppTab
                                    key={z}
                                    variant2={variant2}
                                    size={size}
                                    label={`Tab ${z + 1}`}
                                    value={z}
                                  />
                                ))}
                              </AppTabs>
                              <Box>
                                {`Tab ${activeTab1 + 1} content`}. variant2=
                                {variant2 || "undefined"}, size=
                                {size || "undefined"}
                              </Box>
                            </CardContent>
                          </Card>
                        </Stack>
                      ),
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h2'>
          <InlineCode>PageTabs</InlineCode>
        </Typography>
        <Box>
          <PageTabs
            tabIdsDefinition={ExamplePageTabs}
            defaultTabId={ExamplePageTabs.Overview}
            tabs={[
              { label: "Overview", value: ExamplePageTabs.Overview },
              { label: "Attachments", value: ExamplePageTabs.Attachments },
              { label: "Misc", value: ExamplePageTabs.Misc },
            ]}
          >
            {({ activeTabId: activeTab }) => <Box>Tav - {activeTab}</Box>}
          </PageTabs>
        </Box>
      </Stack>
    </Stack>
  );
}
