import { Box, SxProps, Theme, Typography } from "@mui/material";

import { PaginationInfoDto } from "@/core/api/generated";

interface Props {
  info?: PaginationInfoDto;
  sx?: SxProps<Theme>;
}

export default function PaginationInfoDisplay({ info, sx }: Props) {
  if (!info) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      <Typography component='span' variant='body2'>
        Showing results {info.offset ?? 0} -{" "}
        {Math.min((info.offset ?? 0) + (info.limit ?? 0), info.totalCount ?? 0)} of{" "}
        {info.totalCount ?? 0}
      </Typography>
    </Box>
  );
}
