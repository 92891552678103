import { Box, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import { PriceHelper } from "@/common/helpers/price";
import {
  GeneralPriceSummaryDisplayProps,
  GeneralPriceSummaryDtoDetailedLocal,
} from "@/common/ts/lineItems";

import GeneralDiscountDisplay from "../Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import {
  default as GeneralDiscountCalcExplanation,
  default as GeneralDiscountExplanation,
} from "../Entity/General/GeneralDiscount/GeneralDiscountExplanation";
import GeneralInsuranceDisplay from "../Entity/General/GeneralInsurance/GeneralInsuranceDisplay";
import GeneralTaxDisplay from "../Entity/General/GeneralTax/GeneralTaxDisplay";
import GeneralTaxExplanation from "../Entity/General/GeneralTax/GeneralTaxExplanation";
import CurrencyValue from "../Form/Display/CurrencyValue";
import FieldValue from "../Form/Display/FieldValue";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import AppTypography from "../Text/AppTypography";
import GeneralPriceSummaryInline from "./GeneralPriceSummaryInline";

const defaultDisplayProps: GeneralPriceSummaryDisplayProps = {
  subTotal: true,
  subTotalIncDiscount: true,
  discount: true,
  tax: true,
  insurance: false,
  total: true,
  calcExplanation: true,
};

interface Props {
  summary: GeneralPriceSummaryDtoDetailedLocal;
  direction?: "row" | "column";
  displayProps?: Partial<typeof defaultDisplayProps>;
  sx?: SxProps<Theme>;
}

/** Summary for line items (e.g. invoice). */
export default function GeneralPriceSummaryDisplay({
  summary,
  direction = "column",
  displayProps,
  sx,
}: Props): JSX.Element {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const summaryComputed = useMemo(
    () => PriceHelper.calcPriceSummaryFromDetailedSummary({ summary, calcType: null }),
    [summary],
  );

  const columnCount = useMemo(
    () =>
      (displayProps?.subTotal ? 1 : 0) +
      (displayProps?.subTotalIncDiscount ? 1 : 0) +
      (displayProps?.discount ? 1 : 0) +
      (displayProps?.tax ? 1 : 0) +
      (displayProps?.insurance ? 1 : 0) +
      (displayProps?.total ? 1 : 0),
    [displayProps],
  );
  const fieldValueDirection = direction === "column" ? "row" : "column";

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: direction === "column" ? "1fr" : `repeat(${columnCount}, 1fr)`,
          gap: 1,
          minWidth: { xxs: "100%", md: "200px" },
        }}
      >
        {displayProps?.subTotal && (
          <FieldValue
            label='Sub total'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
            isEmpty={_.isNil(summaryComputed.subTotal)}
          >
            <CurrencyValue value={summaryComputed.subTotal} currency={summaryComputed.currency} />
          </FieldValue>
        )}

        {displayProps?.discount && (
          <FieldValue
            label='Discount'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
            isEmpty={!summaryComputed.discount}
          >
            <AppPopover
              enabled={displayProps?.calcExplanation}
              trigger={
                <AppTypography
                  decoration={{
                    enabled: displayProps?.calcExplanation,
                    variant: "helpText",
                  }}
                >
                  <GeneralDiscountDisplay
                    discount={summaryComputed.discount}
                    currency={summaryComputed.currency || summaryComputed.discount?.currency}
                    detailsPlacement='tooltip'
                  />
                </AppTypography>
              }
              hoverBehavior={{}}
            >
              <AppPopoverContent>
                <Box component='span'>
                  <GeneralDiscountCalcExplanation
                    forValue={{ subTotal: summaryComputed.subTotal ?? 0 }}
                    discount={summaryComputed.discount}
                    currency={summaryComputed.currency}
                  />
                </Box>
              </AppPopoverContent>
            </AppPopover>
          </FieldValue>
        )}

        {displayProps?.subTotalIncDiscount && (
          <FieldValue
            label='Sub total (inc. discount)'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
            isEmpty={_.isNil(summaryComputed.subTotalIncDiscount)}
          >
            <AppPopover
              enabled={displayProps?.calcExplanation}
              trigger={
                <AppTypography
                  decoration={{
                    enabled: displayProps?.calcExplanation,
                    variant: "helpText",
                  }}
                >
                  <CurrencyValue
                    value={summaryComputed.subTotalIncDiscount}
                    currency={summaryComputed.currency}
                  />
                </AppTypography>
              }
              hoverBehavior={{}}
            >
              <AppPopoverContent>
                <Box component='span'>
                  <GeneralDiscountExplanation
                    forValue={{ subTotal: summaryComputed.subTotal ?? 0 }}
                    discount={summaryComputed.discount}
                    currency={summaryComputed.currency}
                  />
                </Box>
              </AppPopoverContent>
            </AppPopover>
          </FieldValue>
        )}

        {displayProps?.tax && (
          <FieldValue
            label='Tax'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
            isEmpty={!summaryComputed.tax}
          >
            <AppPopover
              enabled={displayProps?.calcExplanation}
              trigger={
                <AppTypography
                  decoration={{
                    enabled: displayProps?.calcExplanation,
                    variant: "helpText",
                  }}
                >
                  <Box component='span'>
                    <GeneralTaxDisplay
                      tax={summaryComputed.tax}
                      currency={summaryComputed.currency || summaryComputed.tax?.currency}
                      detailsPlacement='inline'
                    />
                  </Box>
                </AppTypography>
              }
              hoverBehavior={{}}
            >
              <AppPopoverContent>
                <GeneralTaxExplanation
                  forValue={{
                    subTotal: summaryComputed.subTotalIncDiscount ?? summaryComputed.subTotal ?? 0,
                  }}
                  tax={summaryComputed.tax}
                  currency={summaryComputed.currency}
                />
              </AppPopoverContent>
            </AppPopover>
          </FieldValue>
        )}

        {displayProps?.insurance && (
          <FieldValue
            label='Insurance'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
            isEmpty={!summaryComputed.insurance}
          >
            <GeneralInsuranceDisplay
              insurance={summaryComputed.insurance}
              currency={summaryComputed.currency || summaryComputed.insurance?.currency}
            />
          </FieldValue>
        )}

        {displayProps?.total && (
          <FieldValue
            label='Total'
            direction={fieldValueDirection}
            valueAlight={{ horizontal: "start" }}
          >
            {!_.isNil(summaryComputed.total) && (
              <AppPopover
                enabled={displayProps?.calcExplanation}
                trigger={
                  <AppTypography
                    decoration={{
                      enabled: displayProps?.calcExplanation,
                      variant: "helpText",
                    }}
                  >
                    <CurrencyValue
                      value={summaryComputed.total}
                      currency={summaryComputed.currency}
                    />
                  </AppTypography>
                }
                hoverBehavior={{}}
              >
                <AppPopoverContent>
                  <GeneralPriceSummaryInline summary={summary} displayProps={displayProps} />
                </AppPopoverContent>
              </AppPopover>
            )}
            {_.isNil(summaryComputed.total) && "-"}
          </FieldValue>
        )}
      </Box>
    </Box>
  );
}
