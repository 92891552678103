import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { FileHelper } from "@/common/helpers/file";
import { GeneralTagHelper } from "@/common/helpers/generalTag";
import { ObjectHelper } from "@/common/helpers/object";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssessmentFlowDto,
  TagTargetType,
  TenantRequestDto,
  TenantRequestOperationType,
  TenantRequestStage,
  TenantRequestType,
} from "@/core/api/generated";

import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import TenantRequestCreateUpdate from "../TenantRequest/TenantRequestCreateUpdate";
import TenantRequestLink from "../TenantRequest/TenantRequestLink";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import VehicleDamageInline from "../VehicleDamage/VehicleDamageInline";

const stepNumber = {
  vehicleDamages: 0,
  tenantRequestReceiver: 1,
  tenantRequest: 2,
  done: 3,
};

const steps = [
  {
    label: "Select damages to asses",
  },
  {
    label: "Select receiver company (assessor)",
  },
  {
    label: "Review & Send company request",
  },
  {
    label: "Done",
  },
];

interface OwnProps {
  assessmentFlow: AssessmentFlowDto;
  onSent: (newValue: TenantRequestDto) => void;
}

type Props = OwnProps & DialogProps;

export default function SendDamagesToAssessmentModal({
  assessmentFlow,
  onSent,
  ...modalProps
}: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentCurrency = useCurrentCurrency();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedVehicleDamageIdsMap, setSelectedVehicleDamageIdsMap] = useState<
    Record<string, boolean>
  >({});
  const [receiverTenantId, setReceiverTenantId] = useState<string | null | undefined>(undefined);
  const [createdTenantRequest, setCreatedTenantRequest] = useState<
    TenantRequestDto | null | undefined
  >(undefined);

  const selectedVehicleDamageIds = Object.keys(selectedVehicleDamageIdsMap);

  const ddAggregateRequest = useApiRequest(
    apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      aggregateId: assessmentFlow.damageDetectionAggregateId || "",
    },
    {
      deps: [assessmentFlow.damageDetectionAggregateId],
      skip: !assessmentFlow.damageDetectionAggregateId,
    },
  );
  const ddAggregate = ddAggregateRequest?.data;

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: assessmentFlow.vehicle?.id || "",
    },
    {
      deps: [assessmentFlow.vehicle?.id],
      skip: !assessmentFlow.vehicle?.id,
    },
  );
  const vehicle = vehicleRequest?.data;

  const contractRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: assessmentFlow.contract?.id || "",
    },
    {
      deps: [assessmentFlow.contract?.id],
      skip: !assessmentFlow.contract?.id,
    },
  );
  const contract = contractRequest?.data;

  const vehicleId = assessmentFlow.vehicle?.id || "";
  const vehicleDamageIds = ddAggregate?.items?.map((x) => x.item?.damage?.id || "");

  const vehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: assessmentFlow.vehicle?.id || "",
      vehicleDamageIds: vehicleDamageIds || [],
    },
    {
      deps: [assessmentFlow.vehicle?.id, vehicleDamageIds],
      skip: !assessmentFlow.vehicle?.id || !vehicleDamageIds,
    },
  );
  const vehicleDamages = vehicleDamagesRequest?.data;

  // auto-select all damages
  useEffect(() => {
    if (_.isEmpty(selectedVehicleDamageIdsMap) && vehicleDamages) {
      const newMap = _.cloneDeep(selectedVehicleDamageIdsMap);
      vehicleDamages.forEach((x) => {
        newMap[x.id || ""] = true;
      });
      setSelectedVehicleDamageIdsMap(newMap);
    }
  }, [selectedVehicleDamageIdsMap, vehicleDamages]);

  const reset = () => {
    setActiveStep(0);
    setReceiverTenantId(undefined);
    setSelectedVehicleDamageIdsMap({});
    ddAggregateRequest.reset();
    vehicleRequest.reset();
    contractRequest.reset();
    vehicleDamagesRequest.reset();
  };

  const closeModal = () => {
    modalProps.onClose && modalProps.onClose({}, "escapeKeyDown");
    reset();
  };

  return (
    <Dialog
      maxWidth='lg'
      fullWidth
      keepMounted={false}
      {...modalProps}
      onClose={(event, reason) => {
        modalProps.onClose && modalProps.onClose(event, reason);
        reset();
      }}
    >
      <AppModalTitle
        onCloseClicked={() => {
          closeModal();
          reset();
        }}
      >
        Send damages for assessment to external assessor
      </AppModalTitle>

      <DialogContent>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {activeStep === stepNumber.vehicleDamages && (
                  <Stack spacing={2}>
                    {(ddAggregateRequest.isLoading || vehicleDamagesRequest.isLoading) && (
                      <LinearProgress />
                    )}
                    {vehicleDamagesRequest.isEnded && _.isEmpty(vehicleDamages) && (
                      <NoDataAlert title='No damages' />
                    )}
                    {!_.isEmpty(vehicleDamages) && (
                      <Stack spacing={2}>
                        <FormGroup>
                          {vehicleDamages?.map((item) => {
                            return (
                              <FormControlLabel
                                key={item.id}
                                control={
                                  <Checkbox
                                    checked={selectedVehicleDamageIdsMap[item.id || ""] ?? false}
                                    onChange={(e) => {
                                      setSelectedVehicleDamageIdsMap(
                                        ObjectHelper.setOrRemoveKey(
                                          { ...selectedVehicleDamageIdsMap },
                                          item.id || "",
                                          e.target.checked,
                                        ),
                                      );
                                    }}
                                  />
                                }
                                label={<VehicleDamageInline entity={item} />}
                              />
                            );
                          })}
                        </FormGroup>
                      </Stack>
                    )}

                    <Stack direction='row' spacing={1}>
                      <Button
                        variant='outlined'
                        color='text'
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant='contained'
                        color='primary'
                        disabled={
                          vehicleDamagesRequest.isLoading || _.isEmpty(selectedVehicleDamageIdsMap)
                        }
                        onClick={() => {
                          setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Stack>
                  </Stack>
                )}

                {activeStep === stepNumber.tenantRequestReceiver && (
                  <Stack spacing={2}>
                    <Box>
                      <FormControl margin='dense' fullWidth>
                        <TenantAutocomplete
                          entityId={receiverTenantId}
                          searchFilters={{
                            isInMyConnections: true,
                          }}
                          required
                          label='Receiver company'
                          onChange={(newValue) => setReceiverTenantId(newValue?.id)}
                        />
                      </FormControl>
                    </Box>

                    <Stack direction='row' spacing={1}>
                      <Button
                        variant='outlined'
                        color='text'
                        onClick={() => {
                          setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
                        }}
                      >
                        Back
                      </Button>

                      <Button
                        variant='contained'
                        color='primary'
                        disabled={
                          vehicleDamagesRequest.isLoading || _.isEmpty(selectedVehicleDamageIdsMap)
                        }
                        onClick={() => {
                          setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Stack>
                  </Stack>
                )}

                {activeStep === stepNumber.tenantRequest && (
                  <Box>
                    <TenantRequestCreateUpdate
                      defaultValues={{
                        receiverTenantId: receiverTenantId || undefined,
                        name: StringHelper.joinIntoString(
                          [
                            contract?.externalNumber || contract?.localNumber,
                            vehicle ? getVehicleOptionTitle(vehicle) : undefined,
                          ],
                          " / ",
                          { skipEmpty: true },
                        ),
                        content: {
                          type: TenantRequestType.Operation,
                          operation: {
                            operationType: TenantRequestOperationType.DamageCostEvaluation,
                            damageCostEvaluation: {
                              vehicleId: vehicleId,
                              vehicleDamageIds: selectedVehicleDamageIds,
                              currency: currentCurrency,
                            },
                          },
                          attachments: FileHelper.toGeneralAttachmentInputDtoList(
                            ddAggregate?.attachments,
                            { mustBeCopied: true },
                          ),
                        },
                        tags: {
                          tags: [
                            GeneralTagHelper.createTagInput(
                              TagTargetType.Vehicle,
                              assessmentFlow.vehicle?.id || "",
                            ),
                            GeneralTagHelper.createTagInput(
                              TagTargetType.Contract,
                              assessmentFlow.contract?.id || "",
                            ),
                            GeneralTagHelper.createTagInput(
                              TagTargetType.AssessmentFlow,
                              assessmentFlow.id || "",
                            ),
                          ],
                        },
                        contractId: assessmentFlow.contract?.id,
                        assessmentFlowId: assessmentFlow.id,
                        newStage: {
                          newStage: TenantRequestStage.Pending,
                        },
                      }}
                      onSave={(newValue) => {
                        setCreatedTenantRequest(newValue);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        onSent(newValue);
                      }}
                      customization={{
                        secondaryActions: (
                          <Button
                            variant='outlined'
                            color='text'
                            onClick={() => {
                              setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
                            }}
                          >
                            Back
                          </Button>
                        ),
                      }}
                    />
                  </Box>
                )}

                {activeStep === stepNumber.done && (
                  <Stack spacing={1}>
                    <Typography>
                      Company request <TenantRequestLink entity={createdTenantRequest} /> has been
                      created and sent.
                    </Typography>

                    <Box>
                      <Button
                        variant='outlined'
                        color='text'
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Close
                      </Button>
                    </Box>
                  </Stack>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}
