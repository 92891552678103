import {
  Box,
  Fade,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  PopoverProps,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppTab from "@/common/components/Tab/AppTab";
import AppTabs from "@/common/components/Tab/AppTabs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { usePopoverOpenStatus } from "@/common/hooks/usePopoverOpenStatus";
import { enumService } from "@/common/services/enum";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  GeneralTagCandidateDto,
  GeneralTagCandidatesRequestDto,
  GeneralTagDto,
  GeneralTagInputDto,
  TagTargetType,
} from "@/core/api/generated";

import NoDataAlert from "../../../AppAlerts/NoDataAlert";
import AppMountedSensor from "../../../AppMountedSensor";
import AppIcon from "../../../Icons/AppIcon";
import CustomTagCreateUpdateModal from "../../CustomTag/CustomTagCreateUpdateModal";
import GeneralTagDisplay from "./GeneralTagDisplay";

export const GeneralTagSearchCandidatesTabs = {
  CUSTOM: "CUSTOM",
  USER: "USER",
  ENTITY: "ENTITY",
} as const;
export type GeneralTagSearchCandidatesTabs =
  (typeof GeneralTagSearchCandidatesTabs)[keyof typeof GeneralTagSearchCandidatesTabs];

export interface OwnProps {
  filters?: Omit<GeneralTagCandidatesRequestDto, "offset" | "limit" | "search" | "targetType">;
  excludeTags?: GeneralTagInputDto[] | GeneralTagDto[] | null;
  defaultTab?: GeneralTagSearchCandidatesTabs;
  onTagSelected?: (tagCandidate: GeneralTagCandidateDto) => void | Promise<void>;
}

type Props = OwnProps & PopoverProps;

/** Search tag candidate (what can be tagged). */
export default function GeneralTagSearchCandidatesPopover({
  filters,
  excludeTags,
  defaultTab = GeneralTagSearchCandidatesTabs.CUSTOM,
  onTagSelected,
  ...popoverProps
}: Props) {
  const openStatus = usePopoverOpenStatus(popoverProps);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const defaultTagTargetTypeComputed =
    defaultTab === GeneralTagSearchCandidatesTabs.CUSTOM
      ? TagTargetType.CustomTag
      : defaultTab === GeneralTagSearchCandidatesTabs.USER
        ? TagTargetType.User
        : undefined;

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [targetType, setTargetType] = useState<TagTargetType | undefined>(
    defaultTagTargetTypeComputed,
  );
  const [secondaryRequestData, setSecondaryRequestData] = useState<GeneralTagCandidatesRequestDto>(
    {},
  );
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [isCreateCustomTagLoading, setIsCreateCustomTagLoading] = useState(false);
  const [isCreateCustomTagModalOpen, setIsCreateCustomTagModalOpen] = useState(false);

  const [tagSubItemOpenMap, setTagSubItemOpenMap] = useState<Record<string, boolean>>({});

  const requestData: GeneralTagCandidatesRequestDto = {
    offset,
    limit,
    search,
    targetType,
    ...secondaryRequestData,
  };

  const [tagCandidatesPopoverAnchorEl, setTagCandidatesPopoverAnchorEl] =
    useState<HTMLElement | null>(null);

  const tagTargetTypes = useMemo(
    () => enumService.getEnumValues("TagTargetType").filter((x) => x !== TagTargetType.None),
    [],
  );
  const tagTargetTypesFiltered = useMemo(
    () =>
      tagTargetTypes.filter(
        (x) =>
          !filters?.chatParticipant &&
          x !== TagTargetType.ChatParticipant &&
          !filters?.vehicleDamage &&
          x !== TagTargetType.VehicleDamage &&
          x !== TagTargetType.User,
      ),
    [tagTargetTypes, filters],
  );

  const getPaginatedTagCandidatesRequest = useApiRequest(
    apiClient.generalTagsApi.apiV1TagsGeneralCandidatesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalTagCandidatesRequestDto: {
        ...requestData,
      },
    },
    {
      skip: !openStatus.isOpen || !targetType,
      deps: [openStatus, targetType],
      debouncedDeps: {
        deps: [search],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const paginatedTagCandidates = getPaginatedTagCandidatesRequest?.data;

  const tagCandidates = useMemo(
    () =>
      paginatedTagCandidates?.items?.filter(
        (x) =>
          (excludeTags
            ? !excludeTags.some((y) => y.id === x.id || y.target?.targetId === x.tag?.targetId)
            : true) && (targetType ? x.tag?.targetType === targetType : true),
      ),
    [excludeTags, paginatedTagCandidates, targetType],
  );

  const reset = () => {
    setSearch(undefined);
    setTagSubItemOpenMap({});
    setSelectedTab(defaultTab);
    setTargetType(defaultTagTargetTypeComputed);
  };

  useEffect(() => {
    openStatus.isClosing && reset();
  }, [openStatus]);

  useEffect(
    () =>
      filters &&
      setSecondaryRequestData({
        ...secondaryRequestData,
        ...filters,
      }),
    [filters],
  );

  const handleTagSelected = useCallback(
    (tagCandidate: GeneralTagCandidateDto) => {
      onTagSelected && onTagSelected(tagCandidate);

      // close second popover
      setTagCandidatesPopoverAnchorEl(null);

      // let the parent comp to close main popover
    },
    [onTagSelected],
  );

  const renderCandidatesWithSearch = () => {
    return (
      <Stack spacing={1}>
        <TextField
          inputRef={searchInputRef}
          variant='outlined'
          size='small'
          placeholder='Type to search...'
          value={search || ""}
          onChange={(e) => {
            setSearch(e.target.value || undefined);
          }}
          InputProps={{
            endAdornment: targetType === TagTargetType.CustomTag && (
              <AuthorizedElement permissions={[AppPermission.CustomTagManage]}>
                <InputAdornment position='end'>
                  <AppIconButton
                    variant='outlined'
                    size='extraSmall'
                    color='text'
                    tooltipProps={{ title: "Create a new tag" }}
                    loading={isCreateCustomTagLoading}
                    onClick={(e) => {
                      setIsCreateCustomTagModalOpen(true);
                    }}
                  >
                    <AppIcon of='add' />
                  </AppIconButton>
                </InputAdornment>
              </AuthorizedElement>
            ),
          }}
        />

        {/* Auto focus search field on mount */}
        <AppMountedSensor
          onMounted={() => {
            setTimeout(() => {
              searchInputRef?.current?.focus();
            }, 100);
          }}
        />

        {getPaginatedTagCandidatesRequest.isLoading && <LinearProgress />}

        {_.isEmpty(tagCandidates) && <NoDataAlert title='No data' />}

        {/* Select tag candidate */}
        {!_.isEmpty(tagCandidates) && (
          <>
            <List sx={{ m: 0, p: 0, maxHeight: 300, overflowY: "auto", overflowX: "hidden" }}>
              {tagCandidates?.map((candidate, i) => (
                <ListItem key={i} disablePadding dense>
                  <Stack direction='column' spacing={0.25} sx={{ flex: 1 }}>
                    <Stack direction='row' spacing={0.25} sx={{ flex: 1 }}>
                      <ListItemButton
                        sx={{ px: 0, py: 0.25 }}
                        onClick={() => handleTagSelected(candidate)}
                      >
                        <GeneralTagDisplay
                          key={i}
                          tag={candidate.tag!}
                          enableLink={false}
                          withDetailsPopover={false}
                          disableHoverEffects
                          sx={{ flex: 1, cursor: "pointer" }}
                        />
                      </ListItemButton>

                      {/* Tag sub item */}
                      {candidate.subCandidates && candidate.subCandidates.length !== 0 && (
                        <Tooltip title='Tag sub item'>
                          <IconButton
                            edge='end'
                            size='small'
                            onClick={() =>
                              setTagSubItemOpenMap({
                                [candidate.id!]: !tagSubItemOpenMap[candidate.id!],
                              })
                            }
                          >
                            <AppIcon of='subItem' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>

                    {/* Tag sub item */}
                    <Fade unmountOnExit in={tagSubItemOpenMap[candidate.id!]}>
                      <List
                        sx={{
                          m: 0,
                          p: 0,
                          pl: 1,
                          overflowX: "hidden",
                        }}
                      >
                        {candidate.subCandidates &&
                          candidate.subCandidates.map((subCandidate, j) => (
                            <ListItem key={j} disablePadding dense>
                              <ListItemButton
                                sx={{ px: 0, py: 0.25 }}
                                onClick={() => handleTagSelected(subCandidate)}
                              >
                                {/* <ListItemText primary={} /> */}
                                <GeneralTagDisplay
                                  tag={subCandidate.tag!}
                                  enableLink={false}
                                  withDetailsPopover={false}
                                  sx={{ flex: 1, cursor: "pointer" }}
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                      </List>
                    </Fade>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Stack>
    );
  };

  return (
    <Box>
      {/* 1. Select TagTargetType */}
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        {...popoverProps}
        onClose={(event, reason) => {
          popoverProps?.onClose && popoverProps?.onClose(event, reason);
        }}
      >
        <Box
          sx={{
            maxWidth: { xxs: "95vw", md: "400px" },
            maxHeight: { xxs: "95vw", md: "400px" },
            p: 1,
          }}
        >
          <Stack spacing={1}>
            <Box>
              <AppTabs
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                }}
              >
                <AppTab
                  label='Custom'
                  size='small'
                  value={GeneralTagSearchCandidatesTabs.CUSTOM}
                  onClick={() => {
                    setTargetType(TagTargetType.CustomTag);
                  }}
                />
                <AppTab
                  label='User'
                  size='small'
                  value={GeneralTagSearchCandidatesTabs.USER}
                  onClick={() => {
                    setTargetType(TagTargetType.User);
                  }}
                />
                <AppTab
                  label='Entity'
                  size='small'
                  value={GeneralTagSearchCandidatesTabs.ENTITY}
                  onClick={() => {
                    setTargetType(undefined);
                  }}
                />
              </AppTabs>
            </Box>

            {selectedTab === GeneralTagSearchCandidatesTabs.CUSTOM && renderCandidatesWithSearch()}

            {selectedTab === GeneralTagSearchCandidatesTabs.USER && renderCandidatesWithSearch()}

            {selectedTab === GeneralTagSearchCandidatesTabs.ENTITY && (
              <Stack spacing={1}>
                {/* Select TagTargetType */}
                <MenuList sx={{ maxHeight: 300, overflowY: "auto" }} disablePadding>
                  {tagTargetTypesFiltered.map((tType, i) => (
                    <MenuItem
                      key={i}
                      // disablePadding
                      dense
                      onClick={(e) => {
                        setTagCandidatesPopoverAnchorEl(e.currentTarget);
                        setTargetType(tType);
                      }}
                      sx={{ pr: 0.25 }}
                    >
                      <ListItemIcon>
                        <AppIcon of='entity' />
                      </ListItemIcon>
                      <ListItemText>
                        {enumService.getEnumValueName("TagTargetType", tType)}
                      </ListItemText>
                      <AppIcon of='chevronRight' fontSize='small' />
                    </MenuItem>
                  ))}
                </MenuList>
              </Stack>
            )}
          </Stack>
        </Box>
      </Popover>

      {/* 2. Search and select tag candidates popover */}
      <Popover
        anchorEl={tagCandidatesPopoverAnchorEl}
        open={Boolean(tagCandidatesPopoverAnchorEl)}
        onClose={() => {
          setTagCandidatesPopoverAnchorEl(null);
          setSearch(undefined);
          setTargetType(undefined);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{ vertical: "top", horizontal: -20 }}
      >
        <Box
          sx={{
            maxWidth: { xxs: "95vw", md: "400px" },
            maxHeight: { xxs: "95vw", md: "400px" },
            p: 1,
          }}
        >
          {renderCandidatesWithSearch()}
        </Box>
      </Popover>

      {/* Create CustomTag modal */}
      <CustomTagCreateUpdateModal
        open={isCreateCustomTagModalOpen}
        onClose={() => {
          setIsCreateCustomTagModalOpen(false);
        }}
        createUpdateProps={{
          defaultValues: {
            name: search,
          },
          onSave: async (newValue) => {
            setIsCreateCustomTagModalOpen(false);
            setIsCreateCustomTagLoading(true);
            try {
              const response =
                await apiClient.generalTagsApi.apiV1TagsGeneralCandidatesGetByCustomTagCustomTagIdGet(
                  {
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    customTagId: newValue.id || "",
                  },
                );
              handleTagSelected(response.data);
            } finally {
              setIsCreateCustomTagLoading(false);
            }
          },
        }}
      />
    </Box>
  );
}
