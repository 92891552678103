import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AccessoryCheckCreateUpdate from "@/common/components/Entity/AccessoryCheck/AccessoryCheckCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface AccessoryCheckCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  contractId?: string | null;
}

export default function AccessoryCheckCreateUpdatePage() {
  const { accessoryCheckId } = useParams<{ accessoryCheckId?: string }>();
  const queryParams = useQueryParams<AccessoryCheckCreateUpdateQueryParams>();
  const isCreate = !accessoryCheckId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new accessory check" : "Edit accessory check"}
        />
      }
    >
      <AccessoryCheckCreateUpdate
        accessoryCheckId={accessoryCheckId}
        defaultValues={{
          vehicleId: queryParams?.vehicleId || undefined,
          contractId: queryParams?.contractId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.ACCESSORY_CHECK_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
