import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalV2, { AppModalV2Props } from "@/common/components/Modals/AppModalV2";
import {
  GeneralStatusMetaDto,
  GeneralStatusSubjectDto,
  IBaseEntityDto,
} from "@/core/api/generated";

import EntityLink from "../../../components/EntityLink";
import GeneralStatusHistoryPaginatedList, {
  GeneralStatusHistoryPaginatedListProps,
} from "./GeneralStatusHistoryPaginatedList";

export interface OwnProps {
  listProps: GeneralStatusHistoryPaginatedListProps;
  subject: GeneralStatusSubjectDto | undefined;
  subjectEntity: IBaseEntityDto | undefined;
  statusMeta: GeneralStatusMetaDto | undefined;
}

type Props = OwnProps & AppModalV2Props;

export default function GeneralStatusHistoryPaginatedListModal({
  listProps,
  subject,
  subjectEntity,
  statusMeta,
  ...dialogProps
}: Props) {
  return (
    <AppModalV2
      fullWidth
      maxWidth='lg'
      keepMounted={false}
      titleProps={{
        title: (
          <>
            Status history{" "}
            {subject && subject.entityType && subject.entityId && (
              <>
                for{" "}
                <EntityLink
                  entityType={subject.entityType}
                  entityId={subject.entityId}
                  entity={subjectEntity}
                  linkProps={{ withIcon: false }}
                />
              </>
            )}
          </>
        ),
      }}
      {...dialogProps}
    >
      <AppModalContent>
        <GeneralStatusHistoryPaginatedList
          {...listProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...listProps?.displayProps,
          }}
          defaultValues={{
            ...listProps?.defaultValues,
            subjectEntityType: subject?.entityType || listProps?.defaultValues?.subjectEntityType,
            subjectEntityId: subject?.entityId || listProps?.defaultValues?.subjectEntityId,
            statusIdentifier:
              statusMeta?.statusIdentifier || listProps?.defaultValues?.statusIdentifier,
          }}
        />
      </AppModalContent>
    </AppModalV2>
  );
}
