import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function PlateNoIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_281_9745)'>
        <path d='M21.5947 7.7124H2.40525C1.07902 7.7124 0 8.79137 0 10.1176V13.8823C0 15.2086 1.07902 16.2876 2.40525 16.2876H21.5948C22.921 16.2876 24 15.2086 24 13.8823V10.1176C24 8.79137 22.921 7.7124 21.5947 7.7124ZM22.4314 13.8823C22.4314 14.3436 22.056 14.7189 21.5947 14.7189H2.40525C1.94395 14.7189 1.56867 14.3436 1.56867 13.8823V10.1176C1.56867 9.65631 1.944 9.28103 2.40525 9.28103H21.5948C22.0561 9.28103 22.4314 9.65636 22.4314 10.1176L22.4314 13.8823Z' />
        <path d='M5.40972 13.3058L4.52537 11.9409L5.37395 10.5812C5.40976 10.5249 5.42509 10.4738 5.42509 10.4227C5.42509 10.208 5.13376 10.0444 4.90881 10.0444C4.7964 10.0444 4.71967 10.0853 4.66853 10.1774L3.98865 11.3634L3.30883 10.1774C3.25768 10.0854 3.18104 10.0444 3.06854 10.0444C2.84359 10.0444 2.55226 10.208 2.55226 10.4227C2.55226 10.4739 2.56759 10.5249 2.6034 10.5812L3.45198 11.9409L2.56764 13.3058C2.53693 13.3569 2.52161 13.4028 2.52161 13.4489C2.52161 13.6687 2.83347 13.8425 3.05322 13.8425C3.13501 13.8425 3.20664 13.8169 3.24236 13.7607L3.98865 12.493L4.73495 13.7607C4.76565 13.8169 4.84234 13.8425 4.92409 13.8425C5.14389 13.8425 5.45575 13.6687 5.45575 13.4489C5.45575 13.4029 5.44037 13.3569 5.40972 13.3058Z' />
        <path d='M7.94492 10.0747C7.81719 10.0747 7.76605 10.1258 7.71491 10.2229L7.00438 11.5366L6.28874 10.2229C6.23253 10.1258 6.1865 10.0747 6.05872 10.0747C5.83892 10.0747 5.51685 10.2128 5.51685 10.4018C5.51685 10.4172 5.51685 10.4377 5.52711 10.4581L6.58522 12.2114C6.59549 12.2267 6.60571 12.2523 6.60571 12.2829V13.566C6.60571 13.7296 6.80506 13.8113 7.00438 13.8113C7.20374 13.8113 7.40314 13.7296 7.40314 13.566V12.2829C7.40314 12.2523 7.41336 12.2267 7.42358 12.2114L8.47667 10.4581C8.48689 10.4376 8.48689 10.4172 8.48689 10.4018C8.48685 10.2127 8.16477 10.0747 7.94492 10.0747Z' />
        <path d='M10.9661 13.1162H9.57566L11.0172 10.5655C11.0734 10.4632 11.104 10.3661 11.104 10.2843C11.104 10.1616 11.0274 10.0747 10.874 10.0747H8.90089C8.73219 10.0747 8.66061 10.2536 8.66061 10.4223C8.66061 10.6063 8.74752 10.7699 8.90089 10.7699H10.0869L8.64538 13.3207C8.58917 13.4178 8.55847 13.52 8.55847 13.6019C8.55847 13.7245 8.62494 13.8114 8.78849 13.8114H10.9661C11.1195 13.8114 11.2064 13.6274 11.2064 13.4639C11.2063 13.3002 11.1193 13.1162 10.9661 13.1162Z' />
        <path d='M14.599 10.0747C13.8935 10.0747 13.3466 10.4018 13.3466 11.2811V12.6357C13.3466 13.5149 13.8935 13.8421 14.599 13.8421C15.3044 13.8421 15.8565 13.5149 15.8565 12.6357V11.2811C15.8565 10.4018 15.3044 10.0747 14.599 10.0747ZM15.059 12.6357C15.059 12.9884 14.8852 13.1468 14.599 13.1468C14.3127 13.1468 14.144 12.9884 14.144 12.6357V11.2811C14.144 10.9283 14.3127 10.7699 14.599 10.7699C14.8852 10.7699 15.059 10.9283 15.059 11.2811V12.6357Z' />
        <path d='M17.5074 10.0747C16.802 10.0747 16.255 10.4018 16.255 11.2811V12.6357C16.255 13.5149 16.802 13.8421 17.5074 13.8421C18.2128 13.8421 18.7649 13.5149 18.7649 12.6357V11.2811C18.7649 10.4018 18.2128 10.0747 17.5074 10.0747ZM17.9675 12.6357C17.9675 12.9884 17.7937 13.1468 17.5074 13.1468C17.2211 13.1468 17.0524 12.9884 17.0524 12.6357V11.2811C17.0524 10.9283 17.2212 10.7699 17.5074 10.7699C17.7936 10.7699 17.9675 10.9283 17.9675 11.2811V12.6357Z' />
        <path d='M20.4159 10.0747C19.7104 10.0747 19.1635 10.4018 19.1635 11.2811V12.6357C19.1635 13.5149 19.7104 13.8421 20.4159 13.8421C21.1213 13.8421 21.6734 13.5149 21.6734 12.6357V11.2811C21.6734 10.4018 21.1213 10.0747 20.4159 10.0747ZM20.8759 12.6357C20.8759 12.9884 20.7021 13.1468 20.4159 13.1468C20.1296 13.1468 19.9609 12.9884 19.9609 12.6357V11.2811C19.9609 10.9283 20.1296 10.7699 20.4159 10.7699C20.7021 10.7699 20.8759 10.9283 20.8759 11.2811V12.6357Z' />
      </g>
      <defs>
        <clipPath id='clip0_281_9745'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
