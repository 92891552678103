import { useParams } from "react-router-dom";

import VehicleVisualModelView from "@/common/components/Entity/VehicleVisualModel/View/VehicleVisualModelView";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface VehicleVisualModelViewPageRouteParams {
  vehicleVisualModelId?: string;
}

export type VehicleVisualModelViewPageQueryParams = GeneralQueryParams;

export default function VehicleVisualModelViewPage() {
  const { vehicleVisualModelId } = useParams<VehicleVisualModelViewPageRouteParams>();
  const queryParams = useQueryParams<VehicleVisualModelViewPageQueryParams>();

  return (
    <>
      <VehicleVisualModelView
        vehicleVisualModelId={vehicleVisualModelId}
        vehicleVisualModel={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
