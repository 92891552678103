import { createAuth0Client } from "@auth0/auth0-spa-js";
import { PayloadAction } from "@reduxjs/toolkit";
import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { auth0Provider } from "@/common/auth0Provider";
import { ROUTE_PARTS } from "@/common/constants/routing";
import { authService } from "@/common/services/auth";
import { queryStringParamsService } from "@/common/services/queryStringParams";
import { tenantService } from "@/common/services/tenant";
import { AuthorizationProfileDto } from "@/core/api/generated";

import { auth0Config } from "../../../config/config";
import { appInitialized, prerenderConfigurationsStarted } from "../../appCommon/slice";
import { _authorizationProfileFetched } from "../slice";

export const authMiddleware: Middleware<any, any, ThunkDispatch<any, any, AnyAction>> = ({
  dispatch,
  getState,
}) => {
  return (next) => async (action) => {
    // don't block execution flow with awaits
    (async () => {
      switch (action.type) {
        case prerenderConfigurationsStarted.type: {
          let connection = auth0Config.defaultConnection;

          const redirectUrl =
            tenantService.strategy === "basepath"
              ? `${window.location.origin}/${tenantService.defaultTenantIdentifier}/${ROUTE_PARTS.AUTH0_CALLBACK}`
              : `${window.location.origin}/${ROUTE_PARTS.AUTH0_CALLBACK}`;

          const authParams = queryStringParamsService.getCurrentAuthParams();
          if (
            authParams?.preferredAuth0Connection &&
            Object.values(auth0Config.connections).includes(authParams.preferredAuth0Connection)
          ) {
            console.log("Auth params detected in query string:", authParams);
            connection = authParams.preferredAuth0Connection;
          }

          try {
            const auth0Client = await createAuth0Client({
              domain: auth0Config.domain,
              clientId: auth0Config.client_id,
              authorizationParams: {
                redirect_uri: redirectUrl,
                audience: auth0Config.audience,
                connection: connection,
              },
              httpTimeoutInSeconds: 10,
              cacheLocation: "localstorage",
            });
            auth0Provider.setAuth0Client(auth0Client);
          } catch (err) {
            console.error("Create Auth0 client error: ", err);
          }

          await authService.checkAuthenticatedStatus();
          break;
        }

        case appInitialized.type: {
          await authService.checkAuthenticatedStatus();
          break;
        }

        case _authorizationProfileFetched.type: {
          const typedAction = action as PayloadAction<AuthorizationProfileDto>;
          authService.setAuthorizationState(typedAction.payload.authorizationState);
          break;
        }

        default:
          break;
      }
    })();

    return next(action);
  };
};
