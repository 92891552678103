import { Alert, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  sx?: SxProps<Theme>;
}

export default function NotAuthorizedTenantAlert({ sx }: Props): ReactNode {
  return (
    <Alert sx={sx} severity='error'>{`You can't access the resource from other company!`}</Alert>
  );
}
