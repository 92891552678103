import { useParams } from "react-router-dom";

import SupplierView from "@/common/components/Entity/Supplier/View/SupplierView";

export default function SupplierViewPage() {
  const { supplierId } = useParams<{ supplierId?: string }>();

  return (
    <>
      <SupplierView
        supplierId={supplierId}
        supplier={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
