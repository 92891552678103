import AppIcon from "@/common/components/Icons/AppIcon";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { TenantConnectionDto, TenantConnectionReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface TenantConnectionInlineProps extends BaseEntityInlineInheritableProps {
  entity: TenantConnectionDto | TenantConnectionReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function TenantConnectionInline({
  entity,
  entityId,
  ...otherProps
}: TenantConnectionInlineProps) {
  const request = useApiRequest(
    apiClient.tenantConnectionsApi.apiV1TenantToTenantTenantConnectionsTenantConnectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantConnectionId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as TenantConnectionDto;
  const ref = entity as TenantConnectionReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      icon={<AppIcon of='company' inText />}
      content={<>{StringHelper.joinIntoString([full?.globalNumber], ", ", { skipEmpty: true })}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company connection"}
    />
  );
}
