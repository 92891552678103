import { FormikErrors } from "formik";

import { problemDetailsConfig } from "@/config/problemDetails";

import { ValidationInfo } from "../validation";

export const backEndGeneralErrorKey = problemDetailsConfig.errors.general;

export const GeneralErrorKey = {
  /* Set on BE. **/
  "": "",
  /* Can be set anywhere. **/
  general: "general",
  /* Set on FE. **/
  submit: "submit",
} as const;
export type GeneralErrorKey = (typeof GeneralErrorKey)[keyof typeof GeneralErrorKey];

export const generalErrorKeys: GeneralErrorKey[] = Object.values(GeneralErrorKey);

/** Describes base custom properties for FormikValues. */
export interface BaseFormikValues {
  /** Used for general validation errors. */
  submit?: string | null;
  /** Full validation info. */
  validationInfo?: ValidationInfo | null;
}

export const BaseFormikValuesKey = {
  submit: "submit",
  validationInfo: "validationInfo",
} as const;
export type BaseFormikValuesKey = (typeof BaseFormikValuesKey)[keyof typeof BaseFormikValuesKey];

export type ValidationErrorMessageValue = string | string[] | null | undefined;

/** Describes validation errors object for some Values object.
 *  Compatible with both Formik errors and BE API errors.
 */
export type ValidationErrors<Values = Record<string, unknown>> = {
  // [Key in keyof BaseFormikValuesKey]?: ValidationErrorMessageValue;
} & {
  // [Key in keyof GeneralErrorKey]?: ValidationErrorMessageValue;
} & (
    | FormikErrors<Values>
    | {
        [Key in keyof Values]?: ValidationErrorMessageValue;
      }
  );
