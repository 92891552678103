import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { Formik, getIn } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { NotificationChannel, NotificationSendTestDto } from "@/core/api/generated";

interface Props {
  channel: NotificationChannel | null | undefined;
  disableChannelEdit?: boolean;
  sx?: SxProps<Theme>;
}

export default function NotificationSendTest({ channel, disableChannelEdit, sx }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();

  return (
    <Stack spacing={2} sx={sx}>
      <Formik<NotificationSendTestDto & BaseFormikValues & { isSendToMe?: boolean }>
        enableReinitialize
        initialValues={{
          channel: channel || NotificationChannel.InApp,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            await apiClient.notificationsApi.apiV1NotificationsTestSendPost({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              notificationSendTestDto: {
                ..._.omit(values, "submit"),
              },
            });
            enqueueSnackbar("Notification was sent.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
            }
          } catch (err) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;

          return (
            <Stack component='form' spacing={1} noValidate onSubmit={handleSubmit}>
              <Box>
                <FormControl fullWidth>
                  <ApiEnumAutocomplete
                    type='NotificationChannel'
                    value={values?.channel}
                    label='Channel'
                    required
                    disabled={disableChannelEdit}
                    textFieldProps={{
                      error: Boolean(errors?.channel),
                      helperText: errors?.channel,
                    }}
                    onChange={(newValue) => {
                      setFieldValue("channel", newValue);
                    }}
                  />
                </FormControl>

                {values.channel === NotificationChannel.Email && (
                  <>
                    <FormControl margin='dense' fullWidth>
                      <TextField
                        required
                        type='email'
                        label='To'
                        placeholder='Enter email'
                        variant='outlined'
                        error={Boolean(getIn(errors, "email.toEmail"))}
                        helperText={ValidationHelper.getFormikErrorsAsString(
                          getIn(errors, "email.toEmail"),
                        )}
                        value={values?.email?.toEmail || ""}
                        onChange={(e) =>
                          setFieldValue("email.toEmail", e.target.value || undefined)
                        }
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isSendToMe ?? false}
                            onChange={(e) => {
                              setFieldValue("isSendToMe", e.target.checked);
                              setFieldValue("email.toEmail", profile?.email);
                            }}
                          />
                        }
                        label='Send to me'
                      />
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <TextField
                        label='Subject'
                        variant='outlined'
                        error={Boolean(getIn(errors, "email.subject"))}
                        helperText={ValidationHelper.getFormikErrorsAsString(
                          getIn(errors, "email.subject"),
                        )}
                        value={values?.email?.subject || ""}
                        onChange={(e) =>
                          setFieldValue("email.subject", e.target.value || undefined)
                        }
                      />
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <TextField
                        multiline
                        maxRows={2}
                        label='Body'
                        variant='outlined'
                        error={Boolean(getIn(errors, "email.body"))}
                        helperText={ValidationHelper.getFormikErrorsAsString(
                          getIn(errors, "email.body"),
                        )}
                        value={values?.email?.body || ""}
                        onChange={(e) => setFieldValue("email.body", e.target.value || undefined)}
                      />
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.email, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              </Box>

              <GeneralValidationError errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Send
                </LoadingButton>
              </FormActions>
            </Stack>
          );
        }}
      </Formik>
    </Stack>
  );
}
