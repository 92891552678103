import {
  Box,
  Button,
  Chip,
  DialogContentText,
  Divider,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModalWithTrigger from "@/common/components/Modals/ConfirmationModalWithTrigger";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission } from "@/core/api/generated";

export default function RolesPage() {
  const { enqueueSnackbar } = useAppSnackbar();

  const paginatedRolesRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesGet,
    {},
    {
      deps: [],
      debouncedDeps: {
        deps: [],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const paginatedRoles = paginatedRolesRequest.data;

  return (
    <ListPageLayout>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ alignItems: { xs: "stretch", md: "center" }, m: 1 }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={1}
          sx={{ flex: 1, justifyContent: { xs: "stretch", md: "flex-end" } }}
        >
          <AuthorizedElement permissions={[AppPermission.GlobalRoleManage]}>
            <Button
              variant='contained'
              color='primary'
              startIcon={<AppIcon of='add' />}
              component={RouterLink}
              to={ROUTE_PATH.ADMIN_ROLE_CREATE}
            >
              Create new global role
            </Button>
          </AuthorizedElement>
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => (
              <>
                {item.name}
                {item.isBuiltIn && (
                  <Chip
                    size='small'
                    variant='outlined'
                    color='primary'
                    label='Built-in'
                    sx={{ ml: 1 }}
                  />
                )}
              </>
            ),
          },
          {
            field: "description",
            title: "Description",
            flex: 1,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }}>{item.description}</AppTypography>
            ),
          },
          {
            field: "permissions",
            title: "Permissions",
            flex: 1,
            renderCell: (item) => (
              <AppTypography ellipsing={{ enabled: true }}>
                {item.permissions?.map((x) => x)?.join(", ")}
              </AppTypography>
            ),
          },
        ]}
        rows={paginatedRoles}
        getRowId={(item) => item.id!}
        isLoading={paginatedRolesRequest.isLoading}
        rowTo={(item) => ROUTE_PATH.ADMIN_ROLE_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              {({ handleClose }) => [
                <AuthorizedElement key='edit' permissions={[AppPermission.GlobalRoleManage]}>
                  <MenuItem component={RouterLink} to={ROUTE_PATH.ADMIN_ROLE_EDIT(item.id)}>
                    <ListItemIcon>
                      <AppIcon of='edit' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                </AuthorizedElement>,
                <ConfirmationModalWithTrigger
                  key='delete'
                  trigger={
                    <MenuItem>
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  }
                  title='Delete role?'
                  body={({ error }) => {
                    return (
                      <DialogContentText>
                        {`You're going to delete the role`} <strong>{item.name}</strong>.{" "}
                        {`This action can't be undone.`}
                        {error?.generalError && (
                          <Box sx={{ mt: 1 }}>
                            <FormHelperText error>{error.generalError}</FormHelperText>
                          </Box>
                        )}
                      </DialogContentText>
                    );
                  }}
                  onConfirm={async () => {
                    await apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdDelete({
                      roleId: item.id!,
                    });
                    enqueueSnackbar("Role deleted.", { variant: "success" });
                    await paginatedRolesRequest.refetch();
                  }}
                />,
              ]}
            </MenuWithTrigger>
          </>
        )}
      />
    </ListPageLayout>
  );
}
