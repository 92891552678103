import { useParams } from "react-router-dom";

import DamageDetectionView from "@/common/components/Entity/DamageDetection/View/DamageDetectionView";

export default function DamageDetectionViewPage() {
  const { damageDetectionId } = useParams<{ damageDetectionId?: string }>();

  return (
    <>
      <DamageDetectionView
        damageDetectionId={damageDetectionId}
        damageDetection={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
