import { Box, Stack } from "@mui/material";
import { useMemo } from "react";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import Image from "@/common/components/Images/Image";
import { MakeLogoHelper } from "@/common/helpers/makeLogo";
import { StringHelper } from "@/common/helpers/string";
import { AppPermission, MimeBaseType, TagEntityType, VehicleDto } from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../../General/GeneralTag/GeneralAttachedTagsDisplay";
import VehicleLink from "../../VehicleLink";

interface Props {
  vehicleId: string;
  vehicle: VehicleDto;
  primaryActions?: React.ReactNode;
  secondaryActions?: React.ReactNode;
  tags?: React.ReactNode;
  withLink?: boolean;
  onUpdate?: (newValue: VehicleDto) => void;
}

export default function VehicleViewPageHeader({
  vehicleId,
  vehicle,
  primaryActions,
  secondaryActions,
  tags,
  withLink,
  onUpdate,
}: Props) {
  const vehicleImageUrl = useMemo(() => {
    const imageAttachment = vehicle?.attachments?.find(
      (x) =>
        x.file?.mimeBaseType === MimeBaseType.Image || x.file?.contentType?.indexOf("image") !== -1,
    );
    return imageAttachment?.file?.url;
  }, [vehicleId, vehicle]);
  const vehicleMakeUrl = useMemo(() => {
    return MakeLogoHelper.getMakeLogoByIdentifier(vehicle?.spec?.make?.identifier);
  }, [vehicleId, vehicle]);

  return (
    <Box>
      <DetailedViewPageHeader
        image={
          <>
            {vehicleImageUrl && (
              <Image
                src={vehicleImageUrl}
                sx={{
                  height: "92px",
                  width: "auto",
                  borderRadius: "6px",
                  objectFit: "cover",
                }}
              />
            )}
            {!vehicleImageUrl && vehicleMakeUrl && (
              <Image
                src={vehicleMakeUrl}
                sx={{
                  height: "92px",
                  width: "92px",
                  borderRadius: "6px",
                  objectFit: "contain",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: (th) => th.palette.divider,
                }}
              />
            )}
            {!vehicleImageUrl && !vehicleMakeUrl && (
              <Box
                sx={{
                  width: "92px",
                  height: "92px",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "background.gray",
                }}
              >
                <AppIcon
                  of='vehicle'
                  sx={{ width: "46px", height: "46px", fill: (th) => th.palette.divider }}
                />
              </Box>
            )}
          </>
        }
        title={
          <>
            {vehicle?.spec?.make?.name} {vehicle?.spec?.model?.name}{" "}
            {vehicle?.spec?.manufactureYear && `[${vehicle?.spec?.manufactureYear}]`}
            <EntityChipList entity={vehicle} variant='normal' />
          </>
        }
        subtitle={
          <Stack spacing={0.5}>
            <Box>
              {withLink ? (
                <VehicleLink entity={vehicle} />
              ) : (
                StringHelper.joinIntoString(
                  [
                    vehicle?.spec?.generation?.name,
                    vehicle?.spec?.modification?.name,
                    vehicle?.spec?.manufactureYear?.toString(),
                    vehicle?.plateNo?.plateNo,
                    vehicle?.identificationNumber,
                  ],
                  " • ",
                  { skipEmpty: true },
                )
              )}
            </Box>

            {tags || (
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <GeneralAttachedTagsDisplay
                  tags={vehicle?.tags}
                  defaultIsFolded={false}
                  edit={{
                    entityType: TagEntityType.Vehicle,
                    entityId: vehicle?.id,
                    onSaved: (newValue) => {
                      onUpdate && onUpdate({ ...vehicle, tags: newValue || undefined });
                    },
                  }}
                />
              </AuthorizedElement>
            )}
          </Stack>
        }
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
      />
    </Box>
  );
}
