import { Box, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import StrikethroughText from "@/common/components/Text/StrikethroughText";

export default function DevText() {
  const test =
    "Long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long text to truncate";

  return (
    <>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Text
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>StrikethroughText</InlineCode>
      </Typography>
      <Box>
        <Typography component='div' variant='subtitle1' sx={{ my: 1 }}>
          Single line
        </Typography>
        <Stack
          spacing={1}
          sx={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <StrikethroughText>
            Variant: <InlineCode>default</InlineCode>
          </StrikethroughText>
          <StrikethroughText variant='strike'>
            Variant: <InlineCode>strike</InlineCode>
          </StrikethroughText>
          <StrikethroughText variant='crossSingle'>
            Variant: <InlineCode>crossSingle</InlineCode>
          </StrikethroughText>
          <StrikethroughText variant='crossDouble'>
            Variant: <InlineCode>crossDouble</InlineCode>
          </StrikethroughText>
        </Stack>
      </Box>
      <Box>
        <Typography component='div' variant='subtitle1' sx={{ my: 1 }}>
          Line customization
        </Typography>
        <Stack
          spacing={1}
          sx={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <StrikethroughText
            variant='strike'
            lineProps={{
              color: (th) => th.palette.warning.main,
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='crossSingle'
            lineProps={{
              color: (th) => th.palette.success.main,
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='crossDouble'
            lineProps={{
              color: (th) => th.palette.info.main,
              thickness: 4,
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='strike'
            lineProps={{
              style: "dashed",
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='strike'
            lineProps={{
              style: "dotted",
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='strike'
            lineProps={{
              style: "double",
            }}
          >
            Some text
          </StrikethroughText>
          <StrikethroughText
            variant='strike'
            lineProps={{
              style: "solid",
            }}
          >
            Some text
          </StrikethroughText>
        </Stack>
      </Box>
      <Box>
        <Typography component='div' variant='subtitle1' sx={{ my: 1 }}>
          Multiple lines
        </Typography>
        <Stack
          spacing={1}
          sx={{ width: "200px", justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <StrikethroughText sx={{ flex: 1 }} multiline>
            Variant: <InlineCode>default</InlineCode>. {test}
          </StrikethroughText>
          <StrikethroughText sx={{ flex: 1 }} variant='strike' multiline>
            Variant: <InlineCode>strike</InlineCode>. {test}
          </StrikethroughText>
          <StrikethroughText sx={{ flex: 1 }} variant='crossSingle' multiline>
            Variant: <InlineCode>crossSingle</InlineCode>. {test}
          </StrikethroughText>
          <StrikethroughText sx={{ flex: 1 }} variant='crossDouble' multiline>
            Variant: <InlineCode>crossDouble</InlineCode>. {test}
          </StrikethroughText>
        </Stack>
      </Box>
    </>
  );
}
