import { apiClient } from "@/core/api/ApiClient";
import { GeneralTimeZoneDto } from "@/core/api/generated";

import { useApiRequest } from "../api/useApiRequest";

/** Returns default time zone that is used when user haven't specified it explicitly in the settings. */
export const useDefaultTimeZone = (): GeneralTimeZoneDto | undefined | null => {
  const request = useApiRequest(
    apiClient.generalReferenceDataApi.apiV1ReferenceDataGeneralTimeZonesDefaultGet,
    {},
  );
  return request.data;
};
