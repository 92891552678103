import { apiClient } from "@/core/api/ApiClient";
import { EntityType, WashDto } from "@/core/api/generated";

import BaseEntitiesDeleteModal, {
  BaseEntitiesDeleteModalInheritableProps,
} from "../components/BaseEntitiesDeleteModal";

export interface OwnProps {
  entities: WashDto[];
}

type Props = OwnProps & BaseEntitiesDeleteModalInheritableProps;

export default function WashesDeleteModal({ entities, ...otherProps }: Props) {
  return (
    <BaseEntitiesDeleteModal
      cascadeActionDisplay={true}
      entityType={EntityType.Wash}
      entities={entities}
      deleteFunc={(params) => {
        apiClient.washesApi.apiV1WashesBulkDeleteDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          entityBulkDeleteDto: {
            ids: params.entityIds,
            relatedEntitiesTypes: params.relatedEntitiesTypes,
          },
        });
      }}
      {...otherProps}
    />
  );
}
