import { ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ReactNode } from "react";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import EntityMenu from "@/common/components/EntityMenu/EntityMenu";
import AppIcon from "@/common/components/Icons/AppIcon";
import { AdminFileDto, AppPermission } from "@/core/api/generated";

import FileDeleteModal from "./FileDeleteModal";
import FileUndeleteModal from "./FileUndeleteModal";

enum ActionFlags {
  Delete = "Delete",
  Undelete = "Undelete",
}

const defaultDisplayProps = {
  actions: {
    delete: true,
    undelete: true,
  },
};

interface TriggersProps {
  entity?: AdminFileDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: AdminFileDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: AdminFileDto) => void) | undefined;
  onDelete?: ((newValue: AdminFileDto) => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode | Element;
}

export default function FileMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}

          {displayProps?.actions?.delete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.AdminFileManage]}
              onClick={() => {
                startAction(ActionFlags.Delete);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='delete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Delete (soft)</ListItemText>
            </AuthorizedMenuItem>
          )}

          {displayProps?.actions?.undelete && (
            <AuthorizedMenuItem
              permissions={[AppPermission.AdminFileManage]}
              onClick={() => {
                startAction(ActionFlags.Undelete);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='undelete' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Undelete (soft-deleted)</ListItemText>
            </AuthorizedMenuItem>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {entity && (
            <FileDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={(params) => {
                onDelete && onDelete(params.entity);
                completeAction();
              }}
            />
          )}

          {entity && (
            <FileUndeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Undelete}
              onClose={() => cancelAction()}
              onUpdate={(newValue) => {
                onUpdate && onUpdate(newValue);
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
