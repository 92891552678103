import { useParams } from "react-router";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AssetSubscriptionView from "@/common/components/Entity/AssetSubscription/View/AssetSubscriptionView";

export default function AssetSubscriptionViewPage() {
  const { assetSubscriptionId } = useParams<{ assetSubscriptionId?: string }>();

  return (
    <>
      <AssetSubscriptionView
        assetSubscriptionId={assetSubscriptionId}
        assetSubscription={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
