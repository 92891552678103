import _ from "lodash";

export class StringHelper {
  /** Creates new string with replaced value at index. */
  public static replaceAt(str: string, index: number, replacement?: string | null) {
    if (index > str.length - 1) {
      return str;
    }

    replacement = replacement || "";
    return str.substring(0, index) + replacement + str.substring(index, str.length);
  }

  /** Creates new string with replaced value at index range. */
  public static replaceAtRange(
    str: string,
    rangeStartIndex: number, // inclusive
    rangeEndIndex: number, // exclusive
    replacement: string,
  ) {
    rangeStartIndex = Math.max(rangeStartIndex, 0);
    rangeEndIndex = Math.min(rangeEndIndex, str.length);

    return (
      str.substring(0, rangeStartIndex) + replacement + str.substring(rangeEndIndex, str.length)
    );
  }

  /** Creates new string with specified value inserted at the given index.
   *  This is equal to: newStr = subStr1 + value + subStr2.
   */
  public static insertAt(str: string, index: number, value: string) {
    return str.substring(0, index) + value + str.substring(index, str.length);
  }

  public static removeLastChar(str: string): string {
    return str.slice(0, str.length - 1);
  }

  public static joinIntoString(
    values: Array<string | null | undefined>,
    separator?: string,
    options: { skipEmpty: boolean } = { skipEmpty: true },
  ) {
    return values
      .filter((x) => (options?.skipEmpty === true ? !_.isEmpty(x) : true))
      .join(separator);
  }

  /** Returns string size. */
  public static getSizeInBytes(str: string | null | undefined): number {
    if (!str) {
      return 0;
    }
    const textEncoder = new TextEncoder();
    return textEncoder.encode(str).length;
  }

  /** Returns string size. */
  public static getSizeInKiB(str: string, precision: number = 2): number {
    const bytes = this.getSizeInBytes(str);
    return _.round(bytes / 1024, precision);
  }

  /** Returns string size. */
  public static getSizeInMiB(str: string, precision: number = 2): number {
    const bytes = this.getSizeInBytes(str);
    return _.round(bytes / 1024 / 1024, precision);
  }
}
