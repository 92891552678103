import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import DurationDisplay from "@/common/components/Form/Display/DurationDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import {
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  SubscriptionPlanDurationDto,
} from "@/core/api/generated";

import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "../../components/BaseEntityInline";

interface Props extends BaseEntityInlineInheritableProps {
  duration: SubscriptionPlanDurationDto | null | undefined;
  currency: GeneralCurrencyDto | GeneralCurrencyInputDto | null | undefined;
}

/** Inline brief information about the entity. */
export default function SubscriptionPlanDurationInline({
  duration,
  currency,
  ...otherProps
}: Props) {
  return (
    <BaseEntityInline
      entity={duration}
      icon={<AppIcon of='duration' inText />}
      content={
        <>
          Min <DurationDisplay value={duration?.minDuration} />{" "}
          {duration?.discount && (
            <>
              + discount{" "}
              <GeneralDiscountDisplay discount={duration?.discount} currency={currency} />
            </>
          )}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Subscription plan duration"}
    />
  );
}
