import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  VisualInspectionDto,
  VisualInspectionReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import VisualInspectionInline, { VisualInspectionInlineProps } from "./VisualInspectionInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: VisualInspectionDto | VisualInspectionReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<VisualInspectionInlineProps>;
}

export default function VisualInspectionLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.VisualInspectionRead])}
      to={ROUTE_PATH.VISUAL_INSPECTION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <VisualInspectionInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Visual inspection"}
    />
  );
}
