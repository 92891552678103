import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ExampleImportFileDownloadForm from "./ExampleImportFileDownloadForm";

export default function ExampleImportFileDownloadModal({ ...dialogProps }: DialogProps) {
  return (
    <Dialog maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Download example import file
      </AppModalTitle>
      <DialogContent>
        <Stack sx={{ py: 1 }} spacing={1}>
          <ExampleImportFileDownloadForm
            onDownloadDone={() => dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown")}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
