import { HubConnectionState } from "@microsoft/signalr";
import { useEffect, useState } from "react";

import { chatHubService } from "@/common/realtime/chatHubService";

export interface RealtimeStatus {
  connected: boolean;
  state: HubConnectionState;
  connectionId?: string;
}

/** Returns status of SignalR connection to ChatHub. */
export const useRealtimeChatConnectionStatus = (): RealtimeStatus => {
  const [status, setStatus] = useState<RealtimeStatus>({
    connected: chatHubService?.connectionState === HubConnectionState.Connected,
    state: chatHubService?.connectionState || HubConnectionState.Disconnected,
  });

  useEffect(() => {
    function handler(state: HubConnectionState) {
      setStatus({
        ...status,
        connected: state === HubConnectionState.Connected,
        state,
      });
    }

    chatHubService.on("connectionStateChange", handler);

    return () => {
      chatHubService.off("connectionStateChange", handler);
    };
  }, []);

  return status;
};
