import { Box, Fade, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useNegotiationsAllowedActionsMap } from "@/common/hooks/entity/negotiation/useNegotiationsAllowedActionsMap";
import { useCurrentUserPartiesMembership } from "@/common/hooks/entity/party/useCurrentUserPartiesMembership";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { useActionParamsInQueryString } from "@/common/hooks/useActionParamsInQueryString";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import {
  AppPermission,
  ChatDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  FrontEndActionTypeInQueryString,
  GeneralScopeType,
  NegotiationDto,
  NegotiationStatus,
  ProposalStatus,
} from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import NoDataAlert from "../../../AppAlerts/NoDataAlert";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import AppIcon from "../../../Icons/AppIcon";
import NegotiationProposal from "../../Negotiation/NegotiationProposal/NegotiationProposal";
import RespondOnManyNegotiationProposalsControl from "../../Negotiation/NegotiationProposal/RespondOnManyNegotiationProposalsControl";
import ChatPinnedNegotiationsProposalList from "./ChatPinnedNegotiationsProposalList";

export interface OwnProps {
  chat: ChatDto;
}

type Props = OwnProps;

export default function ChatPinnedNegotiations({ chat }: Props) {
  const thunkDispatch = useAppThunkDispatch();
  const actionParams = useActionParamsInQueryString();
  const currentTenant = useCurrentTenant();

  const chatNegotiations = useAppSelector(
    (x) => x.communication.negotiations.currentNegotiationsMap[chat.id!],
  );
  const isLoading = useAppSelector(
    (x) => x.communication.negotiations.loading.getChatCurrentNegotiation,
  );

  const partiesMembership = useCurrentUserPartiesMembership();
  const allowedActionsMap = useNegotiationsAllowedActionsMap(chatNegotiations?.map((x) => x.id!));

  const getChatCurrentNegotiations = useCallback(
    (...args: Parameters<typeof negotiationsSlice.getChatCurrentNegotiations>) =>
      thunkDispatch(negotiationsSlice.getChatCurrentNegotiations(...args)),
    [chat],
  );
  const getChatCurrentNegotiationsThrottle = useCallback(
    _.throttle(getChatCurrentNegotiations, 500, {
      leading: true,
      trailing: false,
    }),
    [getChatCurrentNegotiations],
  );
  const getChatCurrentNegotiationsDebounce = useCallback(
    _.debounce(getChatCurrentNegotiations, 500, {
      leading: false,
      trailing: true,
    }),
    [getChatCurrentNegotiations],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: chatNegotiations?.map((x) =>
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Negotiation, x.id || ""),
    ),
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: () => {
      getChatCurrentNegotiationsDebounce({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId: chat.id!,
      });
    },
  });

  const [isFolded, setIsFolded] = useState<Record<string, boolean>>({});
  const [isShowOpenDamageNegotiations, setIsShowOpenDamageNegotiations] = useState(false);
  const [isShowOpenDamageCostNegotiations, setIsShowOpenDamageCostNegotiations] = useState(false);

  const damageNegotiations = useMemo(
    () =>
      chatNegotiations?.filter(
        (x) =>
          x.scope?.type &&
          (
            [
              GeneralScopeType.DamageDetectionAggregate,
              GeneralScopeType.DamageDetectionAggregateItem,
            ] as GeneralScopeType[]
          ).includes(x.scope!.type!),
      ),
    [chatNegotiations],
  );
  const damageNegotiationsFiltered = useMemo(
    () =>
      damageNegotiations?.filter(
        (x) =>
          (isShowOpenDamageNegotiations ? x.status !== NegotiationStatus.Resolved : true) &&
          x.proposals!.length !== 0,
      ),
    [damageNegotiations, isShowOpenDamageNegotiations],
  );

  const damageCostNegotiations = useMemo(
    () =>
      chatNegotiations?.filter(
        (x) =>
          x.scope?.type &&
          (
            [
              GeneralScopeType.DamageCostEvaluationAggregate,
              GeneralScopeType.DamageCostEvaluationAggregateItem,
            ] as GeneralScopeType[]
          ).includes(x.scope!.type!),
      ),
    [chatNegotiations, isShowOpenDamageCostNegotiations],
  );
  const damageCostNegotiationsFiltered = useMemo(
    () =>
      damageCostNegotiations?.filter(
        (x) =>
          (isShowOpenDamageCostNegotiations ? x.status !== NegotiationStatus.Resolved : true) &&
          x.proposals!.length !== 0,
      ),
    [damageCostNegotiations, isShowOpenDamageCostNegotiations],
  );

  useEffect(() => {
    if (chat) {
      getChatCurrentNegotiationsThrottle({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId: chat.id!,
      });
    }
  }, [chat]);

  const renderNegotiations = useCallback(
    (negotiations: NegotiationDto[]) => (
      <Stack direction='column' spacing={1}>
        {negotiations.map((negotiation) => (
          <Stack direction='column' spacing={1} key={negotiation.id!}>
            {negotiation.proposals!.slice(-1).map((proposal) => {
              const allowedActions = allowedActionsMap[negotiation.id!];
              return (
                <Box key={proposal.id!}>
                  {allowedActions && (
                    <NegotiationProposal
                      negotiation={negotiation}
                      proposal={proposal}
                      allowedActions={allowedActions}
                      partiesMembership={partiesMembership}
                      displayProps={{
                        avatar: false,
                      }}
                      onNegotiationDelete={() => {
                        getChatCurrentNegotiationsThrottle({
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          chatId: chat.id!,
                        });
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Stack>
        ))}
      </Stack>
    ),
    [chat, allowedActionsMap, partiesMembership],
  );

  const renderNegotiations2 = useCallback(
    (negotiations: NegotiationDto[]) => {
      const pairs = _.flatten(
        negotiations.map((negotiation) =>
          negotiation.proposals!.slice(-1).map((proposal) => ({
            negotiation,
            proposal: proposal,
            allowedActions: allowedActionsMap[negotiation.id!],
            partiesMembership: partiesMembership,
          })),
        ),
      );

      return (
        <ChatPinnedNegotiationsProposalList
          items={pairs}
          onNegotiationDelete={(negotiation) => {
            getChatCurrentNegotiationsThrottle({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              chatId: chat.id!,
            });
          }}
          onProposalDelete={(proposal) => {}}
        />
      );
    },
    [chat, allowedActionsMap, partiesMembership],
  );

  if (isLoading && !chatNegotiations) {
    return (
      <Box>
        <Skeleton variant='text' sx={{ fontSize: "1rem" }} />
        <Skeleton variant='rectangular' width={"100%"} height={40} />
        <Skeleton variant='text' sx={{ fontSize: "1rem" }} />
        <Skeleton variant='rectangular' width={"100%"} height={40} />
      </Box>
    );
  }
  if (!chatNegotiations && !isLoading) {
    return null;
  }

  return (
    <Stack direction='column' spacing={2}>
      {/* Damages */}
      {damageNegotiations && (
        <Stack direction='column' spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Box>
              <IconButton
                sx={{ p: 0 }}
                onClick={() =>
                  setIsFolded({
                    ...isFolded,
                    ["damageNegotiations"]: !isFolded["damageNegotiations"],
                  })
                }
              >
                <AppIcon
                  of={isFolded["damageNegotiations"] ? "expandMore" : "expandLess"}
                  fontSize='small'
                />
              </IconButton>{" "}
              <Typography component='span' variant='subtitle1'>
                Negotiations on damages{" "}
                <Typography component='span' variant='body2'>
                  {damageNegotiations!.length > 0
                    ? `(${damageNegotiationsFiltered!.length} of ${damageNegotiations!.length})`
                    : "(No data)"}
                </Typography>{" "}
                {!isFolded["damageNegotiations"] && (
                  <>
                    <Tooltip title='View all or only active items'>
                      <AppIcon
                        of={isShowOpenDamageNegotiations ? "visibility" : "visibilityOff"}
                        color='text'
                        inText
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setIsShowOpenDamageNegotiations(!isShowOpenDamageNegotiations)
                        }
                      />
                    </Tooltip>{" "}
                  </>
                )}
              </Typography>
            </Box>

            {/* Respond on all */}
            {damageNegotiations?.filter((x) => x.status !== NegotiationStatus.Resolved).length >
              1 && (
              <AuthorizedElement permissions={[AppPermission.FleetCustomerAppAccess]}>
                <RespondOnManyNegotiationProposalsControl
                  disabled={damageNegotiations.length === 0}
                  variant='manyProposalsInManyNegotiations'
                  byNegotiationAndProposalId={_.flatten(
                    damageNegotiations.map((n) =>
                      n.proposals!.map((p) => ({
                        first: n!.id,
                        second: p.id,
                      })),
                    ),
                  )}
                  // onlyForProposalsWithoutPartyMemberResponse
                  onlyForProposalsWithStatuses={[ProposalStatus.Open]}
                  autoClickOnceOnMountOn={
                    (actionParams?.actionType ===
                      FrontEndActionTypeInQueryString.ApproveAllDamageNegotiationProposals &&
                      "approveAll") ||
                    (actionParams?.actionType ===
                      FrontEndActionTypeInQueryString.DeclineAllDamageNegotiationProposals &&
                      "declineAll") ||
                    undefined
                  }
                />
              </AuthorizedElement>
            )}
          </Stack>

          <Fade in={!isFolded["damageNegotiations"]} unmountOnExit>
            <Box>
              {damageNegotiationsFiltered!.length === 0 && (
                <NoDataAlert title='No data yet' fullWidth />
              )}

              {/* {renderNegotiations(damageNegotiationsFiltered!)} */}
              {renderNegotiations2(damageNegotiationsFiltered!)}
            </Box>
          </Fade>
        </Stack>
      )}

      {/* Damage cost */}
      {damageCostNegotiations && (
        <Stack direction='column' spacing={1}>
          <Stack
            direction='row'
            spacing={1}
            sx={{ width: "100%", justifyContent: "space-between" }}
          >
            <Box>
              <IconButton
                sx={{ p: 0 }}
                onClick={() =>
                  setIsFolded({
                    ...isFolded,
                    ["damageCostNegotiations"]: !isFolded["damageCostNegotiations"],
                  })
                }
              >
                <AppIcon
                  of={isFolded["damageCostNegotiations"] ? "expandMore" : "expandLess"}
                  fontSize='small'
                />
              </IconButton>{" "}
              <Typography component='span' variant='subtitle1'>
                Negotiations on damages cost{" "}
                <Typography component='span' variant='body2'>
                  {damageCostNegotiations!.length > 0
                    ? `(${damageCostNegotiationsFiltered!.length} of ${
                        damageCostNegotiations.length
                      })`
                    : "(No data)"}
                </Typography>{" "}
                {!isFolded["damageCostNegotiations"] && (
                  <>
                    <Tooltip title='View all or only active items'>
                      <AppIcon
                        of={isShowOpenDamageCostNegotiations ? "visibility" : "visibilityOff"}
                        color='text'
                        inText
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          setIsShowOpenDamageCostNegotiations(!isShowOpenDamageCostNegotiations)
                        }
                      />
                    </Tooltip>{" "}
                  </>
                )}
              </Typography>
            </Box>

            {/* Respond on all */}
            {damageCostNegotiations?.filter((x) => x.status !== NegotiationStatus.Resolved).length >
              1 && (
              <AuthorizedElement permissions={[AppPermission.FleetCustomerAppAccess]}>
                <RespondOnManyNegotiationProposalsControl
                  disabled={damageCostNegotiations.length === 0}
                  variant='manyProposalsInManyNegotiations'
                  byNegotiationAndProposalId={_.flatten(
                    damageCostNegotiations.map((n) =>
                      n.proposals!.map((p) => ({
                        first: n!.id,
                        second: p.id,
                      })),
                    ),
                  )}
                  // onlyForProposalsWithoutPartyMemberResponse
                  onlyForProposalsWithStatuses={[ProposalStatus.Open]}
                  autoClickOnceOnMountOn={
                    (actionParams?.actionType ===
                      FrontEndActionTypeInQueryString.ApproveAllDamageCostNegotiationProposals &&
                      "approveAll") ||
                    (actionParams?.actionType ===
                      FrontEndActionTypeInQueryString.DeclineAllDamageCostNegotiationProposals &&
                      "declineAll") ||
                    undefined
                  }
                />
              </AuthorizedElement>
            )}
          </Stack>

          <Fade in={!isFolded["damageCostNegotiations"]} unmountOnExit>
            <Box>
              {damageCostNegotiationsFiltered!.length === 0 && (
                <NoDataAlert title='No data yet' fullWidth />
              )}

              {/* {renderNegotiations(damageCostNegotiationsFiltered!)} */}
              {renderNegotiations2(damageCostNegotiationsFiltered!)}
            </Box>
          </Fade>
        </Stack>
      )}
    </Stack>
  );
}
