import { Box, SxProps, Theme } from "@mui/material";

import { FileItem } from "@/common/fileItem";

import FileItemView, { FileItemViewActions } from "./FileItemView";

export interface FileUploadListProps {
  files: FileItem[];
  itemActions?: (file: FileItem) => FileItemViewActions;
  sx?: SxProps<Theme>;
}

/** List of uploaded files. */

export default function FileUploadList({ files, itemActions, sx }: FileUploadListProps) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          ...sx,
        }}
      >
        {files?.map((file, i) => {
          return (
            <Box
              key={i}
              sx={{
                mt: 2,
                mr: 2,
              }}
            >
              <FileItemView
                fileItem={file}
                size={{
                  width: 100,
                }}
                actions={itemActions?.(file)}
                imageSx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
