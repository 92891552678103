import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import FileUploadSpecDisplay, { FileUploadSpecDisplayProps } from "./FileUploadSpecDisplay";

export interface OwnProps {
  displayProps: FileUploadSpecDisplayProps;
}

type Props = OwnProps & DialogProps;

export default function FileUploadSpecDisplayModal({ displayProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        File upload spec
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <FileUploadSpecDisplay {...displayProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
