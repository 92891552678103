import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  DamageDetectionDto,
  DamageDetectionItemDto,
  DamageDetectionItemReferenceDto,
  DamageDetectionReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import DamageDetectionInline, { DamageDetectionInlineProps } from "./DamageDetectionInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: DamageDetectionDto | DamageDetectionReferenceDto | null | undefined;
  entityId?: string | null;
  item?: DamageDetectionItemDto | DamageDetectionItemReferenceDto;
  itemId?: string | null;
  inlineProps?: Partial<DamageDetectionInlineProps>;
}

export default function DamageDetectionLink({
  entity,
  entityId,
  item,
  itemId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.DamageDetectionRead])}
      to={ROUTE_PATH.DAMAGE_DETECTION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <DamageDetectionInline
          entity={entity}
          entityId={entityId}
          item={item}
          itemId={itemId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Damage detection"}
    />
  );
}
