import { Box, Stack, SxProps, Theme } from "@mui/material";

import FieldValue from "@/common/components/Form/Display/FieldValue";
import { GeneralBrandingDto } from "@/core/api/generated";

import GeneralLogoResizedDisplay from "./GeneralLogoResizedDisplay";

interface Props {
  branding: GeneralBrandingDto | null | undefined;
  sx?: SxProps<Theme>;
}

export default function GeneralBrandingDisplay({ branding, sx }: Props) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xxs: "1fr",
          lg: "1fr 1fr",
        },
        gap: 2,
        ...sx,
      }}
    >
      <Stack spacing={1}>
        <FieldValue label='Full legal name' isEmpty={!branding?.fullLegalName}>
          {branding?.fullLegalName}
        </FieldValue>
        <FieldValue label='Short name' isEmpty={!branding?.shortName}>
          {branding?.shortName}
        </FieldValue>
        <FieldValue label='Disclaimer' isEmpty={!branding?.disclaimer}>
          {branding?.disclaimer}
        </FieldValue>
      </Stack>

      <Box>
        <GeneralLogoResizedDisplay logo={branding?.logo} />
      </Box>
    </Box>
  );
}
