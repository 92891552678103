import { Box, BoxProps, Stack, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import AppIcon, { AppIconOf } from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";

interface Props {
  /** Of container. */
  sx?: SxProps<Theme>;
  label?: string | ReactNode;
  // value?: string | ReactNode;
  placeholder?: string | ReactNode;
  isEmpty?: boolean;
  iconOf?: AppIconOf;
  icon?: ReactNode;
  // used when the tile should not contain '-' if empty
  withoutPayload?: boolean;
  onClick?: BoxProps["onClick"];
  children?: ReactNode;
}

export default function InfoTile({
  sx,
  label,
  placeholder,
  isEmpty,
  iconOf,
  icon,
  withoutPayload,
  onClick,
  children,
}: Props) {
  return (
    <Stack
      direction='row'
      sx={{
        alignItems: "center",
        borderRadius: "5px",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        p: 1,
        cursor: onClick ? "pointer" : "default",
        minWidth: 0,
        ...sx,
      }}
      onClick={onClick}
    >
      {/* Icon */}
      {(icon || iconOf) && (
        <Box sx={{ ml: 1, mr: 2, fontSize: "1.5rem", display: "flex" }}>
          {icon && icon}
          {!icon && iconOf && <AppIcon of={iconOf} />}
        </Box>
      )}

      <Stack
        direction='column'
        sx={{
          alignItems: "flex-start",
          flex: 1,
          overflow: "hidden",
          ...(withoutPayload ? { justifyContent: "center" } : {}),
        }}
      >
        {label && (
          <AppTypography
            ellipsing={{ enabled: !withoutPayload, linesToStartEllipsing: 1 }}
            component='div'
            variant='subtitle1'
            color='text.secondary'
            title={_.isString(label) ? `${label}` : ""}
          >
            {label}
          </AppTypography>
        )}
        {!withoutPayload && (
          <AppTypography
            ellipsing={{ enabled: true, linesToStartEllipsing: 1 }}
            component='div'
            variant='body1'
            title={_.isString(children) ? `${children}` : ""}
          >
            {!isEmpty && !_.isNil(children) ? children : placeholder || <>&nbsp;</>}
            {isEmpty && (placeholder || "-" || <>&nbsp;</>)}
          </AppTypography>
        )}
      </Stack>
    </Stack>
  );
}
