import { Alert, Stack } from "@mui/material";
import _ from "lodash";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleOperations, {
  VehicleOperationsTabs,
} from "@/common/components/EntityComposed/Operations/VehicleOperations";
import { DamageCostEvaluationDto } from "@/core/api/generated";

interface Props {
  damageCostEvaluation: DamageCostEvaluationDto;
}

export default function RelatedOperationsTabContent({ damageCostEvaluation }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column'>
        <Alert severity='info'>
          Here you can see related operations. For instance, operations for the same damages or
          operations that are in the same context.
        </Alert>

        <VehicleOperations
          vehicleId={damageCostEvaluation.vehicle?.id}
          anyVehicleDamageIds={_.flatten(
            damageCostEvaluation.items
              ?.map((x) => x.damages?.map((y) => y.damage?.id || "") || [])
              .filter(Boolean),
          )}
          displayProps={{
            header: false,
            filters: false,
            viewVariant: ViewLayoutVariant.Tab,
          }}
          excludeTabs={[
            VehicleOperationsTabs.DamageCostEvaluations,
            VehicleOperationsTabs.AccessoryChecks,
          ]}
        />
      </Stack>
    </PageTabContent>
  );
}
