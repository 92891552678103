import { Box, Popover, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, GeneralStrictEntityRelationDto } from "@/core/api/generated";

import VehicleShortSpecDisplay from "../Vehicle/VehicleShortSpecDisplay";

interface Props {
  value: GeneralStrictEntityRelationDto | null | undefined;
}

export default function GeneralEntitySpecInline({ value }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isPopoverOpen = Boolean(anchorEl);

  const isVehicleBased =
    value?.entityType === EntityType.Vehicle ||
    (value?.entityType === EntityType.Asset && value?.asset?.entityType === EntityType.Vehicle);

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId:
        (value?.entityType === EntityType.Vehicle
          ? value.entityId
          : value?.entityType === EntityType.Asset && value.asset?.entityType === EntityType.Vehicle
            ? value.asset.entity?.vehicle?.id
            : undefined) || "",
    },
    {
      skip: !isVehicleBased || !value?.entityId,
    },
  );
  const fetchedVehicle = useMemo(
    () => (vehicleRequest.isLoading ? null : vehicleRequest.data),
    [vehicleRequest],
  );

  return (
    <>
      <Stack
        direction='row'
        spacing={1}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={(e) => setAnchorEl(null)}
      >
        <AppIcon of='info' />
        <Typography variant='body2'>Info</Typography>
      </Stack>
      <Popover
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
        sx={{ pointerEvents: "none" }}
      >
        <Box sx={{ p: 2 }}>
          {renderIf()
            .if(value?.entityType === EntityType.Vehicle)
            .then(
              <VehicleShortSpecDisplay
                make={value?.vehicle?.spec?.make?.name}
                model={value?.vehicle?.spec?.model?.name}
                plateNo={value?.vehicle?.plateNo?.plateNo}
                purchasePrice={
                  fetchedVehicle?.spec?.purchasePrice || {
                    price: fetchedVehicle?.spec?.purchasingCost ?? undefined,
                  }
                }
              />,
            )
            .elseif(
              value?.entityType === EntityType.Asset &&
                value?.asset?.entityType === EntityType.Vehicle,
            )
            .then(
              <VehicleShortSpecDisplay
                make={value?.asset?.entity?.vehicle?.spec?.make?.name}
                model={value?.asset?.entity?.vehicle?.spec?.model?.name}
                plateNo={value?.asset?.entity?.vehicle?.plateNo?.plateNo}
                purchasePrice={
                  fetchedVehicle?.spec?.purchasePrice || {
                    price: fetchedVehicle?.spec?.purchasingCost ?? undefined,
                  }
                }
              />,
            )
            .elseif(
              value?.entityType !== EntityType.Vehicle && value?.entityType !== EntityType.Asset,
            )
            .then(<NoDataAlert />)
            .render()}
        </Box>
      </Popover>
    </>
  );
}
