import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function ModificationIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_281_10080)'>
        <path d='M7.85328 19.2903C7.60373 19.0408 7.20021 19.0408 6.95067 19.2903C6.70113 19.5398 6.70113 19.9434 6.95067 20.1929L7.67275 20.915H4.74194C2.83585 20.915 1.29081 19.3646 1.29081 17.4638V15.3241C1.29081 14.9737 1.0041 14.687 0.653673 14.687C0.30325 14.687 0.0165405 14.9737 0.0165405 15.3241V17.4638C0.0165405 20.0708 2.13501 22.1893 4.74194 22.1893H7.67275L6.95067 22.9113C6.70113 23.1609 6.70113 23.5644 6.95067 23.8139C7.07279 23.9361 7.23738 23.9998 7.40197 23.9998C7.56657 23.9998 7.72585 23.9361 7.85328 23.8139L9.66379 22.0034C9.91334 21.7539 9.91334 21.3504 9.66379 21.1008L7.85328 19.2903Z' />
        <path d='M11.1664 0.00146484H0.701464C0.351041 0.00146484 0.0643311 0.288175 0.0643311 0.638598V11.1035C0.0643311 11.4539 0.351041 11.7406 0.701464 11.7406H11.1664C11.5168 11.7406 11.8035 11.4539 11.8035 11.1035V0.638598C11.8035 0.288175 11.5221 0.00146484 11.1664 0.00146484ZM10.5292 10.4664H1.3386V1.27573H10.5292V10.4664Z' />
        <path d='M19.2526 1.81185H16.3218L17.0439 1.08976C17.2934 0.840219 17.2934 0.436702 17.0439 0.187158C16.7944 -0.0623859 16.3908 -0.0623859 16.1413 0.187158L14.3361 1.99768C14.0865 2.24722 14.0865 2.65074 14.3361 2.90028L16.1466 4.7108C16.2687 4.83292 16.4333 4.89663 16.5979 4.89663C16.7625 4.89663 16.9218 4.83292 17.0492 4.7108C17.2988 4.46126 17.2988 4.05774 17.0492 3.8082L16.3271 3.08611H19.2579C21.164 3.08611 22.7091 4.63647 22.7091 6.53725V8.67695C22.7091 9.02738 22.9958 9.31409 23.3462 9.31409C23.6966 9.31409 23.9833 9.02738 23.9833 8.67695V6.53725C23.9833 3.93031 21.8596 1.81185 19.2526 1.81185Z' />
        <path d='M23.2984 22.7256C23.1285 22.7256 22.964 22.7946 22.8471 22.9114C22.7303 23.0282 22.6613 23.1928 22.6613 23.3627C22.6613 23.5273 22.7303 23.6972 22.8471 23.814C22.964 23.9308 23.1285 23.9999 23.2984 23.9999C23.4684 23.9999 23.6329 23.9308 23.7498 23.814C23.8666 23.6972 23.9356 23.5326 23.9356 23.3627C23.9356 23.1928 23.8666 23.0282 23.7498 22.9114C23.6276 22.7946 23.463 22.7256 23.2984 22.7256Z' />
        <path d='M20.6809 22.7256C20.3305 22.7256 20.0438 23.0123 20.0438 23.3627C20.0438 23.7131 20.3305 23.9999 20.6809 23.9999C21.0313 23.9999 21.318 23.7131 21.318 23.3627C21.318 23.0123 21.0313 22.7256 20.6809 22.7256Z' />
        <path d='M15.4458 22.7256C15.0954 22.7256 14.8087 23.0123 14.8087 23.3627C14.8087 23.7131 15.0954 23.9999 15.4458 23.9999C15.7962 23.9999 16.0829 23.7131 16.0829 23.3627C16.0829 23.0123 15.8015 22.7256 15.4458 22.7256Z' />
        <path d='M18.0633 22.7256C17.7129 22.7256 17.4262 23.0123 17.4262 23.3627C17.4262 23.7131 17.7129 23.9999 18.0633 23.9999C18.4138 23.9999 18.7005 23.7131 18.7005 23.3627C18.7005 23.0123 18.4138 22.7256 18.0633 22.7256Z' />
        <path d='M12.8336 22.7256C12.6637 22.7256 12.4991 22.7946 12.3823 22.9114C12.2655 23.0282 12.1965 23.1928 12.1965 23.3627C12.1965 23.5273 12.2655 23.6972 12.3823 23.814C12.4991 23.9308 12.6637 23.9999 12.8336 23.9999C12.9982 23.9999 13.1681 23.9308 13.2849 23.814C13.4017 23.6972 13.4707 23.5326 13.4707 23.3627C13.4707 23.1928 13.4017 23.0282 13.2849 22.9114C13.1681 22.7946 12.9981 22.7256 12.8336 22.7256Z' />
        <path d='M12.8336 20.1084C12.4832 20.1084 12.1965 20.3951 12.1965 20.7455C12.1965 21.096 12.4832 21.3827 12.8336 21.3827C13.184 21.3827 13.4707 21.096 13.4707 20.7455C13.4707 20.3951 13.184 20.1084 12.8336 20.1084Z' />
        <path d='M12.8336 17.4907C12.4832 17.4907 12.1965 17.7774 12.1965 18.1279C12.1965 18.4783 12.4832 18.765 12.8336 18.765C13.184 18.765 13.4707 18.4783 13.4707 18.1279C13.4707 17.7774 13.184 17.4907 12.8336 17.4907Z' />
        <path d='M12.8336 14.873C12.4832 14.873 12.1965 15.1598 12.1965 15.5102C12.1965 15.8606 12.4832 16.1473 12.8336 16.1473C13.184 16.1473 13.4707 15.8606 13.4707 15.5102C13.4707 15.1598 13.184 14.873 12.8336 14.873Z' />
        <path d='M12.8336 12.2607C12.6637 12.2607 12.4991 12.3298 12.3823 12.4466C12.2655 12.5634 12.1965 12.728 12.1965 12.8979C12.1965 13.0678 12.2655 13.2324 12.3823 13.3492C12.4991 13.466 12.6637 13.535 12.8336 13.535C12.9982 13.535 13.1681 13.466 13.2849 13.3492C13.4017 13.2324 13.4707 13.0678 13.4707 12.8979C13.4707 12.7333 13.4017 12.5634 13.2849 12.4466C13.1627 12.3245 12.9981 12.2607 12.8336 12.2607Z' />
        <path d='M20.6809 12.2607C20.3305 12.2607 20.0438 12.5475 20.0438 12.8979C20.0438 13.2483 20.3305 13.535 20.6809 13.535C21.0313 13.535 21.318 13.2483 21.318 12.8979C21.318 12.5475 21.0313 12.2607 20.6809 12.2607Z' />
        <path d='M18.0633 12.2607C17.7129 12.2607 17.4262 12.5475 17.4262 12.8979C17.4262 13.2483 17.7129 13.535 18.0633 13.535C18.4138 13.535 18.7005 13.2483 18.7005 12.8979C18.7005 12.5475 18.4138 12.2607 18.0633 12.2607Z' />
        <path d='M15.4458 12.2607C15.0954 12.2607 14.8087 12.5475 14.8087 12.8979C14.8087 13.2483 15.0954 13.535 15.4458 13.535C15.7962 13.535 16.0829 13.2483 16.0829 12.8979C16.0829 12.5475 15.8015 12.2607 15.4458 12.2607Z' />
        <path d='M23.2984 12.2607C23.1285 12.2607 22.964 12.3298 22.8471 12.4466C22.7303 12.5634 22.6613 12.728 22.6613 12.8979C22.6613 13.0678 22.7303 13.2324 22.8471 13.3492C22.964 13.466 23.1285 13.535 23.2984 13.535C23.4684 13.535 23.6329 13.466 23.7498 13.3492C23.8666 13.2324 23.9356 13.0678 23.9356 12.8979C23.9356 12.7333 23.8666 12.5634 23.7498 12.4466C23.6276 12.3298 23.463 12.2607 23.2984 12.2607Z' />
        <path d='M23.2984 14.873C22.948 14.873 22.6613 15.1598 22.6613 15.5102C22.6613 15.8606 22.948 16.1473 23.2984 16.1473C23.6489 16.1473 23.9356 15.8606 23.9356 15.5102C23.9356 15.1598 23.6489 14.873 23.2984 14.873Z' />
        <path d='M23.2984 20.1084C22.948 20.1084 22.6613 20.3951 22.6613 20.7455C22.6613 21.096 22.948 21.3827 23.2984 21.3827C23.6489 21.3827 23.9356 21.096 23.9356 20.7455C23.9356 20.3951 23.6489 20.1084 23.2984 20.1084Z' />
        <path d='M23.2984 17.4907C22.948 17.4907 22.6613 17.7774 22.6613 18.1279C22.6613 18.4783 22.948 18.765 23.2984 18.765C23.6489 18.765 23.9356 18.4783 23.9356 18.1279C23.9356 17.7774 23.6489 17.4907 23.2984 17.4907Z' />
      </g>
      <defs>
        <clipPath id='clip0_281_10080'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

/**
 *
 */
