import { Box, Button, FormHelperText, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useAppDispatch, useAppSelector } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CompanyIncompleteRegistrationDto } from "@/core/api/generated";
import { resetSavedCompanyRegistrationInfo } from "@/store/auth/slice";

/** Checks whether there is some incomplete registration saved. */
export default function TenantRegisterCheckIncompleteRegistrationPage() {
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const savedCompanyRegistrationInfo = useAppSelector((x) => x.auth.savedCompanyRegistrationInfo);

  const [isLoading, setIsLoading] = useState(true);
  const [incompleteRegistration, setIncompleteRegistration] = useState<
    CompanyIncompleteRegistrationDto | undefined
  >(undefined);

  useEffect(() => {
    async function check() {
      setIsLoading(true);

      try {
        const result =
          await apiClient.accountApi.apiV1AccountRegisterCompanyGetIncompleteRegistrationGet({
            email: savedCompanyRegistrationInfo!.email,
            companyId: savedCompanyRegistrationInfo!.companyId,
          });
        if (!result.data.isFoundIncompleteRegistration) {
          history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_INFO);
        } else {
          setIncompleteRegistration(result.data);
        }
        // enqueueSnackbar("A verification code is sent to your email", { variant: "success" });
      } catch (err: Error | any) {
        const validation2 = ValidationHelper.handleApiErrorResponse(err);
        validation2.hasErrors &&
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    }

    if (savedCompanyRegistrationInfo) {
      check();
    } else {
      history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_INFO);
    }
  }, [savedCompanyRegistrationInfo]);

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!incompleteRegistration) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Box>
        <Typography component='div' variant='subtitle1'>
          Do you want to continue the incomplete registration of the company{" "}
          <strong>
            {incompleteRegistration?.company?.name ?? incompleteRegistration?.company?.identifier}
          </strong>
          ?
        </Typography>
        <FormHelperText>
          Date saved for the company{" "}
          {incompleteRegistration?.company?.name ?? incompleteRegistration?.company?.identifier}{" "}
          will be auto-deleted after some period of time if registration {`won't`} be completed.
        </FormHelperText>
      </Box>
      <Stack direction='row' spacing={1}>
        <Button
          variant='outlined'
          onClick={() => {
            dispatch(resetSavedCompanyRegistrationInfo());
            history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_INFO);
          }}
        >
          No, start from scratch
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant='contained'
          onClick={() => {
            history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_INFO);
          }}
        >
          Yes, continue
        </Button>
      </Stack>
    </Stack>
  );
}
