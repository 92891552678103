import {
  Box,
  ButtonBase,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import { useChatContextOptional } from "@/common/contexts/chat";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useActionParamsInQueryString } from "@/common/hooks/useActionParamsInQueryString";
import {
  AppPermission,
  ApprovalResponseType,
  GeneralScopeDto,
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationType,
  ProposalStatus,
  UserPartiesMembershipDto,
} from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import DataList from "../../../DataList/DataList";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";
import AppIcon from "../../../Icons/AppIcon";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import TotalPriceExplanationModal from "../../../PriceSummary/TotalPriceExplanationModal";
import GeneralScopeDisplayModal from "../../General/Display/GeneralScopeDisplayModal";
import GeneralTagDisplay from "../../General/GeneralTag/GeneralTagDisplay";
import TagListModal from "../../General/GeneralTag/TagListModal";
import GeneralEventLogsModal from "../../GeneralEventLog/GeneralEventLogsModal";
import NegotiationDeleteModal from "../../Negotiation/DataGrantDeleteModal";
import CreateNegotiationProposalModal from "../../Negotiation/NegotiationProposal/CreateNegotiationProposalModal";
import EditNegotiationProposalModal from "../../Negotiation/NegotiationProposal/EditNegotiationProposalModal";
import NegotiationProposalComputedStatusIcon from "../../Negotiation/NegotiationProposal/NegotiationProposalComputedStatusIcon";
import NegotiationProposalDetailsModal from "../../Negotiation/NegotiationProposal/NegotiationProposalDetailsModal";
import NegotiationProposalValueInline from "../../Negotiation/NegotiationProposal/NegotiationProposalValueInline";
import RespondOnNegotiationProposalControl from "../../Negotiation/NegotiationProposal/RespondOnNegotiationProposalControl";
import RespondOnNegotiationProposalModal from "../../Negotiation/NegotiationProposal/RespondOnNegotiationProposalModal";
import NegotiationViewModal from "../../Negotiation/NegotiationViewModal";

type ItemData = {
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
  allowedActions: NegotiationAllowedActionsDto | undefined;
  partiesMembership: UserPartiesMembershipDto | undefined;
};

export interface OwnProps {
  items: Array<ItemData>;
  onNegotiationDelete?: (negotiation: NegotiationDto) => void;
  onProposalDelete?: (proposal: NegotiationProposalDto) => void;
}

type Props = OwnProps;

export default function ChatPinnedNegotiationsProposalList({
  items,
  onNegotiationDelete,
  onProposalDelete,
}: Props) {
  const thunkDispatch = useAppThunkDispatch();
  const actionParams = useActionParamsInQueryString();
  const currentTenant = useCurrentTenant();
  const chatContext = useChatContextOptional();
  const { hasPermissions } = useAuthorizationInfo();

  const [itemForModal, setItemForModal] = useState<ItemData | undefined>(undefined);
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const [isNegotiationModalOpen, setIsNegotiationModalOpen] = useState(false);
  const [isNegotiationDeleteModalOpen, setIsNegotiationDeleteModalOpen] = useState(false);
  const [isCreateProposalModalOpen, setIsCreateProposalModalOpen] = useState(false);
  const [isEditProposalModalOpen, setIsEditProposalModalOpen] = useState(false);
  const [isRespondOnNegotiationProposalModalOpen, setIsRespondOnNegotiationProposalModalOpen] =
    useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  const [isNegotiationEventLogModalOpen, setIsNegotiationEventLogModalOpen] = useState(false);
  const [isViewTagsModalOpen, setIsViewTagsModalOpen] = useState(false);
  const [isViewScopeModalOpen, setIsViewScopeModalOpen] = useState(false);
  const [isPriceExplanationModalOpen, setIsPriceExplanationModalOpen] = useState(false);
  const [scopeToView, setScopeToView] = useState<GeneralScopeDto | undefined>(undefined);

  const isAnyValueNegotiation = useMemo(
    () => items.some((x) => x.negotiation.type === NegotiationType.Value),
    [items],
  );

  return (
    <Box>
      <DataList
        size='small'
        rowSpacing={0.5}
        columnSpacing={1}
        headerProps={{
          variant: "compact",
        }}
        contentProps={{
          itemVariant: "filled",
          itemColor: (item, i) => {
            const computedStatus = NegotiationHelper.getProposalComputedStatus(
              item.proposal,
              item.partiesMembership,
            );
            return (
              (computedStatus.isOtherPartyRespondedAndWaitsForMyPartyResponse && "warning") ||
              (computedStatus.isMyPartyApprovedOrNotRespondedAndOtherPartyDeclined && "error") ||
              // (computedStatus.isConsensus && "success") ||
              // (computedStatus.isForcedConsensus && "success") ||
              "gray"
            );
          },
        }}
        columns={[
          {
            field: "",
            title: "Status",
            xxs: true,
            md: 1,
            isSortable: false,
            isColumnMenuDisabled: true,
            renderCell: (item) => (
              <>
                {/* Computed status icon */}
                {item.allowedActions && (
                  <NegotiationProposalComputedStatusIcon
                    negotiation={item.negotiation}
                    proposal={item.proposal}
                    allowedActions={item.allowedActions}
                    partiesMembership={item.partiesMembership}
                    iconProps={{
                      fontSize: "medium",
                    }}
                  />
                )}
              </>
            ),
          },
          {
            field: "",
            title: "Part + Type of damage",
            xxs: true,
            md: true,
            isSortable: false,
            isColumnMenuDisabled: true,
            renderCell: (item) => (
              <>
                {/* Statement */}
                <ButtonBase
                  onClick={() => {
                    setIsViewScopeModalOpen(true);
                    setScopeToView(item.proposal.scope || item.negotiation.scope);
                    setItemForModal(item);
                  }}
                >
                  <Box component='span'>{item.proposal.statement}</Box>
                </ButtonBase>
              </>
            ),
          },

          // Proposal value
          {
            field: "",
            title: "Cost",
            xxs: true,
            md: 2.5,
            isSortable: false,
            isColumnMenuDisabled: true,
            if: isAnyValueNegotiation,
            renderCell: (item) => {
              const canCreateProposal = NegotiationHelper.canCreateProposal(
                item.negotiation,
                item.allowedActions,
              );

              return (
                <>
                  {item.negotiation.type === NegotiationType.Value && item.proposal.value && (
                    <Box>
                      {item.proposal.value.price && (
                        <Stack
                          spacing={0}
                          sx={{ cursor: "pointer", alignItems: "flex-start" }}
                          onClick={() => {
                            setIsPriceExplanationModalOpen(true);
                            setItemForModal(item);
                          }}
                        >
                          <Stack direction='row' spacing={0.5} sx={{ width: "100%" }}>
                            {/* Price */}
                            <NegotiationProposalValueInline
                              negotiation={item.negotiation}
                              proposal={item.proposal}
                            />

                            {/* Propose new price */}
                            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                              {canCreateProposal.value && (
                                <Tooltip title='Propose new price'>
                                  <IconButton
                                    sx={{ p: 0.25 }}
                                    size='small'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setItemForModal(item);
                                      setIsCreateProposalModalOpen(true);
                                    }}
                                  >
                                    <AppIcon of='edit' />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </AuthorizedElement>
                          </Stack>
                        </Stack>
                      )}
                    </Box>
                  )}
                </>
              );
            },
          },

          // My party response
          {
            field: "",
            title: "Action",
            xxs: true,
            md: 2.5,
            isSortable: false,
            isColumnMenuDisabled: true,
            horizontalAlign: "center",
            renderCell: (item) => {
              const myParty = NegotiationHelper.getMyNegotiationParty(
                item.negotiation,
                item.partiesMembership,
              );
              const isMyPartyIsInitiatorParty =
                item.negotiation.initiatorParty?.id === myParty?.id && !_.isNil(myParty);
              const isMyPartyResponded = NegotiationHelper.isRespondedOnProposal(
                item.proposal,
                item.partiesMembership,
              );
              const canRespond = NegotiationHelper.canRespondOnProposal({
                proposal: item.proposal,
                allowedActions: item.allowedActions,
                partiesMembership: item.partiesMembership,
              });

              return (
                <>
                  {/* My party response */}
                  <Stack direction='row' spacing={2} alignItems='center'>
                    {renderIf()
                      // Respond action
                      .if(
                        !isMyPartyResponded &&
                          item.proposal.status === ProposalStatus.Open &&
                          !!item.allowedActions,
                      )
                      .then(
                        <RespondOnNegotiationProposalControl
                          variant='full'
                          negotiation={item.negotiation}
                          proposal={item.proposal}
                          allowedActions={item.allowedActions}
                          partiesMembership={item.partiesMembership}
                        />,
                      )
                      // My party response
                      .elseif(isMyPartyResponded && isMyPartyIsInitiatorParty && canRespond)
                      .then(
                        <RespondOnNegotiationProposalControl
                          variant='compact'
                          negotiation={item.negotiation}
                          proposal={item.proposal}
                          allowedActions={item.allowedActions}
                          partiesMembership={item.partiesMembership}
                          compactVariantProps={{
                            // dropdownButtonContent: <>Action</>,
                            dropdownButtonProps: {
                              buttonProps: {
                                variant: "outlined",
                                color: "text",
                                size: "small",
                              },
                              sx: {
                                px: 0,
                              },
                            },
                          }}
                        />,
                      )
                      .elseif(isMyPartyResponded && !isMyPartyIsInitiatorParty)
                      .then(
                        <RespondOnNegotiationProposalControl
                          variant='compact'
                          negotiation={item.negotiation}
                          proposal={item.proposal}
                          allowedActions={item.allowedActions}
                          partiesMembership={item.partiesMembership}
                          compactVariantProps={{
                            dropdownButtonProps: {
                              buttonProps: {
                                variant: "text",
                                color: "text",
                                size: "small",
                              },
                              sx: {
                                px: 0,
                              },
                            },
                          }}
                        />,
                      )
                      .else(
                        <AppTooltip title='No action needed'>
                          <AppIcon of='none' fontSize='medium' />
                        </AppTooltip>,
                      )
                      .render()}
                  </Stack>
                </>
              );
            },
          },

          // Other party response
          {
            field: "",
            title: "Customer's answer",
            xxs: true,
            md: 1,
            isSortable: false,
            isColumnMenuDisabled: true,
            horizontalAlign: "center",
            if: hasPermissions([AppPermission.FleetAppAccess]),
            renderCell: (item) => {
              const myParty = NegotiationHelper.getMyNegotiationParty(
                item.negotiation,
                item.partiesMembership,
              );
              const otherParties = NegotiationHelper.getOtherNegotiationParties(
                item.negotiation,
                item.partiesMembership,
              );
              const partiesResponseMap = NegotiationHelper.getProposalPartiesResponseMap(
                item.negotiation,
                item.proposal,
              );

              const isMyPartyIsInitiatorParty =
                item.negotiation.initiatorParty?.id === myParty?.id && !_.isNil(myParty);

              return (
                <>
                  {/* Other party response */}
                  {isMyPartyIsInitiatorParty && (
                    <Stack>
                      {otherParties?.map((otherParty, j) => {
                        const otherPartyResponse = partiesResponseMap[otherParty.id!];
                        const otherPartyResponseType =
                          otherPartyResponse?.responseType || ApprovalResponseType.None;

                        return (
                          <Stack
                            key={j}
                            direction='row'
                            spacing={1}
                            sx={{ justifyContent: "center" }}
                          >
                            <AppTooltip
                              enabled={!!otherPartyResponseType}
                              title={
                                <>
                                  {otherParty?.type} response is{" "}
                                  <strong>
                                    <InlineApiEnumValue
                                      type='ApprovalResponseType'
                                      value={otherPartyResponseType}
                                    />
                                  </strong>
                                </>
                              }
                            >
                              <ApiEnumIcon
                                sx={{ color: (t) => t.palette.text.main }}
                                type='ApprovalResponseType'
                                value={otherPartyResponseType}
                                withTooltip={false}
                                inText
                                fontSize='medium'
                              />
                            </AppTooltip>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}
                </>
              );
            },
          },

          // Result
          {
            field: "",
            title: "Conclusion",
            xxs: true,
            md: 1,
            isSortable: false,
            isColumnMenuDisabled: true,
            horizontalAlign: "center",
            if: hasPermissions([AppPermission.FleetAppAccess]),
            renderCell: (item) => {
              const myParty = NegotiationHelper.getMyNegotiationParty(
                item.negotiation,
                item.partiesMembership,
              );

              const isMyPartyIsInitiatorParty =
                item.negotiation.initiatorParty?.id === myParty?.id && !_.isNil(myParty);
              const isMyPartyIsTargetParty =
                item.negotiation.targetParty?.id === myParty?.id && !_.isNil(myParty);

              const isMyPartyResponded = NegotiationHelper.isRespondedOnProposal(
                item.proposal,
                item.partiesMembership,
              );

              return (
                <>
                  {(item.proposal.status !== ProposalStatus.Open ||
                    (isMyPartyResponded && isMyPartyIsInitiatorParty)) && (
                    <Stack direction='row' spacing={0.5} sx={{ justifyContent: "center" }}>
                      <ApiEnumIcon
                        type='ApprovalStatus'
                        value={item.proposal.approvalStatus}
                        fontSize='medium'
                        withTooltip
                        inText
                      />
                    </Stack>
                  )}
                </>
              );
            },
          },
          {
            field: "",
            title: "Chat",
            xxs: true,
            md: 1,
            isSortable: false,
            isColumnMenuDisabled: true,
            horizontalAlign: "center",
            renderCell: (item) => (
              <>
                {chatContext && (
                  <Tooltip title='Go to the chat and tag this item'>
                    <IconButton
                      sx={{ p: 0 }}
                      color='text'
                      size='medium'
                      onClick={() => {
                        chatContext.requestCreateTagByScopeInMessageInput({
                          scope: item.proposal.scope || item.negotiation.scope,
                          isScrollToInput: true,
                          isFocusInput: true,
                        });
                      }}
                    >
                      <AppIcon of='chat' />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            ),
          },
        ]}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
              withAuthCloseOnClick
            >
              <ListItem sx={{ pt: 0 }}>
                <ListItemText
                  sx={{ m: 0, p: 0 }}
                  primary={
                    <Typography
                      component='div'
                      variant='body2'
                      color='text.secondary'
                      sx={{ m: 0, p: 0 }}
                    >
                      Proposal
                    </Typography>
                  }
                />
              </ListItem>
              <MenuItem
                dense
                onClick={() => {
                  setIsProposalModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='view' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View proposal</ListItemText>
              </MenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                dense
                onClick={() => {
                  setIsEditProposalModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit proposal</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                dense
                onClick={() => {
                  setIsEventLogModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='history' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Proposal event log</ListItemText>
              </AuthorizedMenuItem>

              <ListItem sx={{ pt: 0 }}>
                <ListItemText
                  sx={{ m: 0, p: 0 }}
                  primary={
                    <Typography
                      component='div'
                      variant='body2'
                      color='text.secondary'
                      sx={{ m: 0, p: 0 }}
                    >
                      Negotiation
                    </Typography>
                  }
                />
              </ListItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                dense
                onClick={() => {
                  setIsNegotiationModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='view' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View negotiation</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                dense
                onClick={() => {
                  setIsViewTagsModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='tag' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View negotiation tags</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                dense
                onClick={() => {
                  setIsNegotiationEventLogModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='history' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Negotiation event log</ListItemText>
              </AuthorizedMenuItem>
              <AuthorizedMenuItem
                permissions={[AppPermission.FleetAppAccess]}
                onClick={() => {
                  setIsNegotiationDeleteModalOpen(true);
                  setItemForModal(item);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete negotiation</ListItemText>
              </AuthorizedMenuItem>
            </MenuWithTrigger>
          </>
        )}
        rows={items}
        getRowId={(item) => item.proposal.id!}
        // rowTo={(item) => ROUTE_PATH.NEGOTIATION_VIEW(item.id)}
      />

      {/* GeneralScope */}
      {scopeToView && (
        <GeneralScopeDisplayModal
          open={isViewScopeModalOpen}
          onClose={() => {
            setIsViewScopeModalOpen(false);
            setScopeToView(undefined);
            setItemForModal(undefined);
          }}
          scope={scopeToView}
          title='Proposal scope'
          description='This is the scope of the negotiation'
        />
      )}

      {/* Proposal details */}
      {itemForModal?.allowedActions && (
        <NegotiationProposalDetailsModal
          open={isProposalModalOpen}
          onClose={() => {
            setIsProposalModalOpen(false);
            setItemForModal(undefined);
          }}
          negotiation={itemForModal?.negotiation}
          allowedActions={itemForModal?.allowedActions}
          proposal={itemForModal?.proposal}
        />
      )}

      {/* View negotiation */}
      {itemForModal?.negotiation && (
        <NegotiationViewModal
          open={isNegotiationModalOpen}
          onClose={() => {
            setIsNegotiationModalOpen(false);
            setItemForModal(undefined);
          }}
          onDelete={() => {
            setIsNegotiationModalOpen(false);
            onNegotiationDelete && onNegotiationDelete(itemForModal.negotiation);
          }}
          negotiation={itemForModal.negotiation}
        />
      )}

      {/* Delete negotiation */}
      {itemForModal?.negotiation && (
        <NegotiationDeleteModal
          entity={itemForModal.negotiation}
          open={isNegotiationDeleteModalOpen}
          onClose={() => {
            setIsNegotiationDeleteModalOpen(false);
            setItemForModal(undefined);
          }}
          onDelete={() => {
            setIsNegotiationDeleteModalOpen(false);
            onNegotiationDelete && onNegotiationDelete(itemForModal.negotiation);
          }}
        />
      )}

      {/* Create new proposal */}
      <CreateNegotiationProposalModal
        open={isCreateProposalModalOpen}
        onClose={() => {
          setIsCreateProposalModalOpen(false);
          setItemForModal(undefined);
        }}
        negotiation={itemForModal?.negotiation}
        createFromProposal={itemForModal?.proposal}
      />

      {/* Edit proposal */}
      {itemForModal?.negotiation && (
        <EditNegotiationProposalModal
          open={isEditProposalModalOpen}
          onClose={() => {
            setIsEditProposalModalOpen(false);
            setItemForModal(undefined);
          }}
          negotiationId={itemForModal.negotiation.id!}
          proposal={itemForModal?.proposal}
          onSave={() => setIsEditProposalModalOpen(false)}
        />
      )}

      {/* Respond on proposal */}
      {itemForModal?.proposal && (
        <RespondOnNegotiationProposalModal
          open={isRespondOnNegotiationProposalModalOpen}
          onClose={() => {
            setIsRespondOnNegotiationProposalModalOpen(false);
            setItemForModal(undefined);
          }}
          proposal={itemForModal?.proposal}
        />
      )}

      {/* Event log */}
      {itemForModal?.proposal && (
        <GeneralEventLogsModal
          open={isEventLogModalOpen}
          onClose={() => {
            setIsEventLogModalOpen(false);
            setItemForModal(undefined);
          }}
          entityId={itemForModal.proposal.id!}
        />
      )}
      {itemForModal?.negotiation && (
        <GeneralEventLogsModal
          open={isNegotiationEventLogModalOpen}
          onClose={() => {
            setIsNegotiationEventLogModalOpen(false);
            setItemForModal(undefined);
          }}
          entityId={itemForModal.negotiation.id!}
        />
      )}

      {/* View tags */}
      {itemForModal?.negotiation.tags && (
        <TagListModal
          tagListProps={{
            tags: itemForModal?.negotiation.tags.tags!.map((tag, i) => (
              <GeneralTagDisplay key={i} tag={tag} />
            )),
          }}
          open={isViewTagsModalOpen}
          onClose={() => {
            setIsViewTagsModalOpen(false);
            setItemForModal(undefined);
          }}
        />
      )}

      {/* Price explanation */}
      {itemForModal?.proposal.value?.price && (
        <TotalPriceExplanationModal
          open={isPriceExplanationModalOpen}
          onClose={() => {
            setIsPriceExplanationModalOpen(false);
            setItemForModal(undefined);
          }}
          totalPriceExplanationProps={{
            summary: {
              currency: itemForModal?.proposal.value.price.price?.currency,
              subTotal: itemForModal?.proposal.value.price.subTotal,
              discount: itemForModal?.proposal.value.price.discount,
              tax: itemForModal?.proposal.value.price.tax,
              total: itemForModal?.proposal.value.price.total,
            },
          }}
        />
      )}
    </Box>
  );
}
