import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { NegotiationDto } from "@/core/api/generated";

import AppModalTitle from "../../Modals/AppModalTitle";
import NegotiationView from "./View/NegotiationView";

export interface OwnProps {
  negotiationId?: string | null;
  negotiation?: NegotiationDto | null;
  onDelete?: () => void;
}

type Props = OwnProps & DialogProps;

export default function NegotiationViewModal({
  negotiationId,
  negotiation,
  onDelete,
  ...dialogProps
}: Props) {
  return (
    <Box>
      <Dialog maxWidth='lg' fullWidth {...dialogProps}>
        <AppModalTitle
          title=''
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        />

        <DialogContent>
          <NegotiationView
            negotiationId={negotiationId}
            negotiation={negotiation}
            dialogProps={dialogProps}
            displayProps={{
              viewVariant: ViewLayoutVariant.Modal,
            }}
            headerProps={{
              withLink: true,
            }}
            onDelete={() => {
              dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
              onDelete && onDelete();
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
