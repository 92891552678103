import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityType, VehicleDto } from "@/core/api/generated";

import GeneralStatusHistoryPaginatedList from "../../../GeneralStatus/GeneralStatusHistory/ListView/GeneralStatusHistoryPaginatedList";

interface Props {
  vehicle: VehicleDto;
}

export default function GeneralStatusHistoryTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <GeneralStatusHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.Vehicle,
          subjectEntityId: vehicle.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
          isForSubjectEntity: true,
        }}
      />
    </PageTabContent>
  );
}
