import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { NegotiationAllowedActionsDto } from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";

export type NegotiationsAllowedActionsMap = Record<
  string,
  NegotiationAllowedActionsDto | undefined
>;

export const useNegotiationsAllowedActionsMap = (
  negotiationIds?: string[] | null,
): NegotiationsAllowedActionsMap => {
  const dispatch = useAppThunkDispatch();
  const allowedActionsMap = useAppSelector(
    (x) => x.communication.negotiations.negotiationAllowedActionsMap,
  );

  const [_negotiationIds, setNegotiationIds] = useState<string[]>([]);

  const dispatchDebounce = useCallback(
    _.debounce(dispatch, 1000, { leading: true, trailing: false }),
    [dispatch],
  );

  const allowedActionsMapResult = useMemo(
    () =>
      _.chain(allowedActionsMap)
        .pickBy((v, k) => _negotiationIds.includes(k))
        .value(),
    [[..._negotiationIds], allowedActionsMap],
  );

  // track props change
  useEffect(() => {
    let newValue = (negotiationIds || []).filter((x) => !!x && x.length > 0).map((x) => x!);
    newValue = _.orderBy(newValue, (x) => x);
    if (!_.isEqual(newValue, _negotiationIds)) {
      setNegotiationIds(newValue);
    }
  }, [negotiationIds]);

  useEffect(() => {
    (async () => {
      if (
        _negotiationIds.length !== 0 &&
        _negotiationIds.some((id) => !allowedActionsMapResult[id])
      ) {
        await dispatchDebounce(
          negotiationsSlice.getNegotiationsAllowedActions({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            negotiationIds: _negotiationIds,
          }),
        );
      }
    })();
  }, [[_negotiationIds]]);

  return allowedActionsMapResult;
};
