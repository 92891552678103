import { AxiosResponse } from "axios";
import _ from "lodash";

type HeadersType = Record<string, any> | AxiosResponse["headers"];

export interface ParsedContentHeaders {
  contentType?: string;
  contentLength?: number;
  contentDisposition?: string;

  /** See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition */
  contentDispositionInfo?: {
    type: "inline" | "attachment" | "form-data";
    directives: {
      name?: string;
      filename?: string;
      filenameAsterix?: string;
    };
  };
}

export class HttpHelper {
  public static getHeaderValue(headers: HeadersType, name: string): string | undefined {
    return headers[name] || headers[name.toLocaleLowerCase()] || headers[name.toUpperCase()];
  }

  /** Parses Content-Type, Content-Length, Content-Disposition response headers */
  public static parseContentHeaders(headers: HeadersType): ParsedContentHeaders {
    const contentType = this.getHeaderValue(headers, "Content-Type");
    const contentLength = this.getHeaderValue(headers, "Content-Length");
    const contentDisposition = this.getHeaderValue(headers, "Content-Disposition");

    const result: ParsedContentHeaders = {
      contentType,
      contentLength: contentLength ? +contentLength : undefined,
      contentDisposition,
    };

    if (contentDisposition) {
      // example values:
      // attachment; filename=Invoice-2022-07-22.pdf; filename*=UTF-8''Invoice-2022-07-22.pdf
      // attachment; filename="cool.html"
      // form-data; name="field2"; filename="example.txt"
      const values = contentDisposition.split(";").map((x) => x.replace(/ /, "").split("="));
      const type = values[0][0] as any;
      values.shift();

      const replaceSpaces = (value: string) => value.replace(/^ /, "").replace(/ $/, "");
      const replaceQuotes = (value: string) =>
        value.replace(/^"/, "").replace(/"$/, "").replace(/^'/, "").replace(/'$/, "");

      const valuesObj = values.map(([key, value]) => ({
        key: replaceQuotes(replaceSpaces(key)),
        value: replaceQuotes(replaceSpaces(value)),
      }));

      result.contentDispositionInfo = {
        type: type,
        directives: {
          name: valuesObj.find((x) => x.key === "name")?.value,
          filename: valuesObj.find((x) => x.key === "filename")?.value,
          filenameAsterix: valuesObj.find((x) => x.key === "filename*")?.value,
        },
      };
    }

    return result;
  }
}
