import { Box } from "@mui/material";

import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppTypography from "@/common/components/Text/AppTypography";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  GeneralHistoryDto,
  GeneralHistoryEventType,
  GeneralHistoryEventTypeOfVehicle,
  GeneralHistoryEventTypeOfWheelOperation,
  GeneralHistoryType,
} from "@/core/api/generated";

interface Props {
  item: GeneralHistoryDto;
}

export default function GeneralHistoryItemTitle({ item }: Props) {
  return (
    <Box>
      {renderIf()
        .if(item.type === GeneralHistoryType.Log)
        .then(<Box>{item.log?.message}</Box>)
        .elseif(item.type === GeneralHistoryType.Event)
        .then(
          <>
            {renderIf()
              .if(item.event?.type === GeneralHistoryEventType.Arbitrary)
              .then(<Box>{item.event?.arbitrary?.name}</Box>)
              .elseif(item.event?.type === GeneralHistoryEventType.Vehicle)
              .then(
                <Box>
                  {renderIf()
                    .if(
                      item.event?.vehicle?.type === GeneralHistoryEventTypeOfVehicle.MileageChanged,
                    )
                    .then(
                      <>
                        Mileage changed from {item.event?.vehicle?.mileageChanged?.oldMileage} to{" "}
                        {item.event?.vehicle?.mileageChanged?.newMileage}
                      </>,
                    )

                    .elseif(
                      item.event?.vehicle?.type ===
                        GeneralHistoryEventTypeOfVehicle.WheelOperationCompleted,
                    )
                    .then(
                      <>
                        Wheel operation completed
                        {item.event?.vehicle?.wheelOperationCompleted?.serviceTypes && (
                          <>
                            {" "}
                            (
                            <InlineApiEnumValueList
                              type='WheelServiceType'
                              values={item.event?.vehicle?.wheelOperationCompleted?.serviceTypes}
                              direction='row'
                            />
                            )
                          </>
                        )}
                      </>,
                    )
                    .else(
                      <InlineApiEnumValue
                        type='GeneralHistoryEventTypeOfVehicle'
                        value={item.event?.vehicle?.type}
                      />,
                    )
                    .render()}
                </Box>,
              )
              .elseif(item.event?.type === GeneralHistoryEventType.VehicleDamage)
              .then(
                <Box>
                  <InlineApiEnumValue
                    type='GeneralHistoryEventTypeOfVehicleDamage'
                    value={item.event?.vehicleDamage?.type}
                  />
                </Box>,
              )
              .elseif(item.event?.type === GeneralHistoryEventType.WheelOperation)
              .then(
                <Box>
                  {renderIf()
                    .if(
                      item.event?.wheelOperation?.type ===
                        GeneralHistoryEventTypeOfWheelOperation.Completed,
                    )
                    .then(
                      <>
                        Wheel operation completed
                        {item.event?.wheelOperation?.completed?.serviceTypes && (
                          <>
                            {" "}
                            (
                            <InlineApiEnumValueList
                              type='WheelServiceType'
                              values={item.event?.wheelOperation?.completed?.serviceTypes}
                              direction='row'
                            />
                            )
                          </>
                        )}
                      </>,
                    )
                    .elseif(
                      item.event?.wheelOperation?.type ===
                        GeneralHistoryEventTypeOfWheelOperation.StageChanged,
                    )
                    .then(
                      <>
                        Stage changed from{" "}
                        <InlineApiEnumValue
                          type='WheelOperationStage'
                          value={item.event?.wheelOperation?.stageChanged?.oldStage}
                        />{" "}
                        to{" "}
                        <InlineApiEnumValue
                          type='WheelOperationStage'
                          value={item.event?.wheelOperation?.stageChanged?.newStage}
                        />
                      </>,
                    )
                    .render()}
                </Box>,
              )
              .else(
                <AppTypography color='error'>
                  History event of type {item.event?.type} is not supported.
                </AppTypography>,
              )
              .render()}
          </>,
        )
        .else(
          <AppTypography color='error'>
            History item of type {item.type} is not supported.
          </AppTypography>,
        )
        .render()}{" "}
      <EntityChipList entity={item} variant='compact' />
    </Box>
  );
}
