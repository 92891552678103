import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { apiClient } from "@/core/api/ApiClient";
import {
  ChatAcknowledgeResultDto,
  ChatAcknowledgedDto,
  ChatEventDto,
  ChatHistoryItemDto,
  ChatMessageAcknowledgedDto,
  ChatMessageDeletedDto,
  ChatMessageDto,
  ChatMessageSentDto,
  ChatMessageUpdatedDto,
  ChatMessagesReadDto,
  MarkChatMessagesAsReadResultDto,
  NegotiationProposalDto,
} from "@/core/api/generated";
import { AppThunk } from "@/store";

import * as chatHistorySlice from "./chatHistorySlice";

function getNewHistoryItem(data: {
  message?: ChatMessageDto;
  event?: ChatEventDto;
  proposal?: NegotiationProposalDto;
}): ChatHistoryItemDto {
  const list = Object.values(data).filter((x) => !!x);

  return {
    id: list[0]?.id || undefined,
    participantId: list[0]?.participantId || undefined,
    createdAt: list[0]?.createdAt || undefined,
    ...data,
  };
}

export interface ChatMessagesState {
  loading: {
    sendMessage?: boolean;
    updateMessage?: boolean;
    deleteMessage?: boolean;
    acknowledgeMessage?: boolean;
    markMessageAsRead?: boolean;
  };
}

const initialState: ChatMessagesState = {
  loading: {},
};

const tenantsSlice = createSlice({
  name: "chatMessages",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatMessagesState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _chatMessageSent: (state, action: PayloadAction<ChatMessageDto>) => {},
    newChatMessageReceived: (state, action: PayloadAction<ChatMessageSentDto>) => {},
    _chatMessageUpdated: (
      state,
      action: PayloadAction<{ chatId: string; message: ChatMessageDto }>,
    ) => {},
    chatMessageUpdated: (state, action: PayloadAction<ChatMessageUpdatedDto>) => {},
    _chatMessageDeleted: (
      state,
      action: PayloadAction<{ chatId: string; messageId: string }>,
    ) => {},
    chatMessageDeleted: (state, action: PayloadAction<ChatMessageDeletedDto>) => {},

    _chatMessageAcknowledged: (
      state,
      action: PayloadAction<{ chatId: string; message: ChatMessageDto }>,
    ) => {},
    chatMessageAcknowledged: (state, action: PayloadAction<ChatMessageAcknowledgedDto>) => {},

    chatMessagesRead: (
      state,
      action: PayloadAction<MarkChatMessagesAsReadResultDto | ChatMessagesReadDto>,
    ) => {},

    chatAcknowledgedByMe: (state, action: PayloadAction<ChatAcknowledgeResultDto>) => {},
    chatAcknowledged: (state, action: PayloadAction<ChatAcknowledgedDto>) => {},
  },
});

export const {
  setLoading,
  _chatMessageSent,
  newChatMessageReceived,
  _chatMessageUpdated,
  chatMessageUpdated,
  _chatMessageDeleted,
  chatMessageDeleted,
  chatAcknowledgedByMe,
  chatMessagesRead,
  chatMessageAcknowledged,
  chatAcknowledged,
} = tenantsSlice.actions;

export const chatMessagesReducer = tenantsSlice.reducer;

export const sendChatMessage =
  (
    ...arg: Parameters<typeof apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesPost>
  ): AppThunk<Promise<ChatMessageDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        sendMessage: true,
      }),
    );

    try {
      const response = await apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesPost(...arg);
      await dispatch(_chatMessageSent(response.data));
      await dispatch(chatHistorySlice.chatMessageSent(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          sendMessage: false,
        }),
      );
    }
  };

export const updateChatMessage =
  (
    ...arg: Parameters<typeof apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdPut>
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        updateMessage: true,
      }),
    );

    try {
      const response = await apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdPut(...arg);
      await dispatch(
        _chatMessageUpdated({ chatId: response.data.chatId!, message: response.data }),
      );
      await dispatch(chatHistorySlice.chatMessageUpdated(response.data));
    } finally {
      dispatch(
        setLoading({
          updateMessage: false,
        }),
      );
    }
  };

export const deleteChatMessage =
  (
    ...arg: Parameters<typeof apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdDelete>
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        deleteMessage: true,
      }),
    );

    try {
      await apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdDelete(...arg);
      await dispatch(_chatMessageDeleted({ chatId: arg[0].chatId, messageId: arg[0].messageId }));
      await dispatch(
        chatHistorySlice.chatMessageDeleted({
          chatId: arg[0].chatId,
          messageId: arg[0].messageId,
        }),
      );
    } finally {
      dispatch(
        setLoading({
          deleteMessage: false,
        }),
      );
    }
  };

export const acknowledgeChatMessage =
  (
    ...arg: Parameters<
      typeof apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdAcknowledgePost
    >
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        acknowledgeMessage: true,
      }),
    );

    try {
      const response =
        await apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMessageIdAcknowledgePost(...arg);
      await dispatch(
        _chatMessageUpdated({ chatId: response.data.chatId!, message: response.data }),
      );
      await dispatch(
        chatHistorySlice.chatMessageUpdated({
          chatId: response.data.chatId!,
          message: response.data,
        }),
      );
    } finally {
      dispatch(
        setLoading({
          acknowledgeMessage: false,
        }),
      );
    }
  };

export const markChatMessagesAsRead =
  (
    ...args: Parameters<typeof apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMarkAsReadPost>
  ): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        markMessageAsRead: true,
      }),
    );

    try {
      const response = await apiClient.chatMessagesApi.apiV1ChatsChatIdMessagesMarkAsReadPost(
        ...args,
      );
      await dispatch(chatMessagesRead(response.data));
      await dispatch(chatHistorySlice.chatMessagesRead(response.data));
    } finally {
      dispatch(
        setLoading({
          markMessageAsRead: false,
        }),
      );
    }
  };
