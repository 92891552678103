import { Button, LinearProgress, Stack, SxProps, Theme } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import PaginatedListToolbar from "@/common/components/DataTabular/PaginatedListToolbar";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPagination from "@/common/components/Pagination/AppPagination";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { FilterFieldSpec } from "@/common/filters/filterFieldSpec";
import { FilterSpec } from "@/common/filters/filterSpec";
import { PaginationHelper } from "@/common/helpers/pagination";
import { TypeHelper } from "@/common/helpers/type";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { SortFieldSpec } from "@/common/sorting/sortFieldSpec";
import { SortSpec } from "@/common/sorting/sortSpec";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  GeneralHistoryDto,
  GeneralHistoryGetPaginatedDto,
} from "@/core/api/generated";

import GeneralHistoryCreateUpdateModal from "../GeneralHistoryCreateUpdateModal";
import GeneralHistoryListItem from "./GeneralHistoryListItem";

const defaultDisplayProps = {
  header: true,
  filters: true,
  counters: true,
  create: true,
  edit: true,
  delete: true,
  pagination: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};

export type GeneralHistoryPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface GeneralHistoryPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: Partial<GeneralHistoryGetPaginatedDto>;
  propagatedDeps?: PropagatedDeps<GeneralHistoryPaginatedListPropagatedDepsMap>;
}

export type GeneralHistoryPaginatedListProps = GeneralHistoryPaginatedListOwnProps;

export default function GeneralHistoryPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
}: GeneralHistoryPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<GeneralHistoryGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.GeneralHistory,
    },
    defaultValues: {
      limit: defaultValues?.limit ?? PaginationHelper.defaultLimit,
      params: {
        ...defaultValues,
      },
    },
  });

  const topAnchorRef = useRef<HTMLElement | null>(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const paginatedRequest = useApiRequest(
    apiClient.generalHistoryApi.apiV1GeneralHistoryGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalHistoryGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginated = paginatedRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.GeneralHistory)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title={undefined}
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissions={[AppPermission.GeneralHistoryManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    onClick={() => {
                      setIsCreateModalOpen(true);
                    }}
                  >
                    Create new record
                  </Button>
                </AuthorizedElement>
              )
            }
          />
        )
      }
    >
      <Stack direction='column' spacing={2}>
        {paginatedRequest.isLoading && <LinearProgress />}

        {/* Entity for which history is displayed */}
        {/* {displayProps.viewVariant === ViewLayoutVariant.Page && (
            <Alert severity='info'>
              Displaying history record for entity{" "}
              <GeneralStrictEntityRelationLink
                value={{
                  entityType: commonRequestParams.params?.subjectEntityType,
                  entityId: commonRequestParams.params?.subjectEntityId,
                }}
              />
            </Alert>
          )} */}

        <Stack direction='column' spacing={1}>
          <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

          <PaginatedListToolbar
            sort={{
              ...commonRequestParams.dataTabularProps.sort,
              sortSpec: new SortSpec({
                fields: [
                  new SortFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().raisedAt.$path,
                    title: "Rased at",
                  }),
                  new SortFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().createdAt.$path,
                    title: "Created at",
                  }),
                  new SortFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().updatedAt.$path,
                    title: "Updated at",
                  }),
                ],
              }),
              // initialSortDefinition: undefined,
              // sortDefinition: commonRequestParams.sortDefinition,
              // onChange: (newValue) => {
              //   commonRequestParams.setSortDefinition(newValue);
              // },
            }}
            quickFilter={{
              ...commonRequestParams.dataTabularProps.quickFilter,
              // initialSearch: commonRequestParams.search,
              // search: commonRequestParams.search,
              // onChange: (newValue) => {
              //   commonRequestParams.setSearch(newValue);
              // },
            }}
            filters={{
              ...commonRequestParams.dataTabularProps.filters,
              filterSpec: new FilterSpec({
                fields: [
                  // //TODO: defining filter by multiple fields is tricky, find solution how to handle it properly
                  // new FilterFieldSpec({
                  //   field: getTypedPath<GeneralHistoryDto>().type.$path,
                  //   title: "Subject entity",
                  //   fieldType: FilterFieldType.Arbitrary,
                  //   fieldTypeMeta: {
                  //     enum: {
                  //       enumName: ApiEnumName.GeneralHistoryType,
                  //     },
                  //   },
                  //   operators: [
                  //     new FilterFieldOperatorSpec({
                  //       operator: FilterOperator.Equal,
                  //       filterType: FilterType.Static,
                  //       valueType: FilterValueType.Arbitrary,
                  //       valueInput: (params) => (
                  //         <GeneralStrictEntityRelationInput
                  //           // value={params.item.value}
                  //           value={{
                  //             entityType:
                  //               commonRequestParams.params?.subjectEntityType || undefined,
                  //             entityId: commonRequestParams.params?.subjectEntityId || undefined,
                  //           }}
                  //           entityTypeAutocompleteProps={params.autocompleteProps as any} // TODO: avoid any
                  //           entityAutocompleteProps={params.autocompleteProps as any} // TODO: avoid any
                  //           onChange={(newValue) => {
                  //             params.onChange({
                  //               subjectEntityType: newValue?.entityType,
                  //               subjectEntityId: newValue?.entityId,
                  //             });
                  //           }}
                  //         />
                  //       ),
                  //       onChange: (newValue) => {
                  //         commonRequestParams.setManyParams({
                  //           subjectEntityType: newValue?.entityType,
                  //           subjectEntityId: newValue?.entityId,
                  //         });
                  //       },
                  //     }),
                  //   ],
                  // }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().type.$path,
                    title: "Type",
                    fieldType: FilterFieldType.Enum,
                    fieldTypeMeta: {
                      enum: {
                        enumName: ApiEnumName.GeneralHistoryType,
                      },
                    },
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().autoCreateMode.$path,
                    title: "Create mode",
                    fieldType: FilterFieldType.Enum,
                    fieldTypeMeta: {
                      enum: {
                        enumName: ApiEnumName.AutomationCreateMode,
                      },
                    },
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().event.type.$path,
                    title: "Event type",
                    fieldType: FilterFieldType.Enum,
                    fieldTypeMeta: {
                      enum: {
                        enumName: ApiEnumName.GeneralHistoryEventType,
                      },
                    },
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().event.vehicle.type.$path,
                    title: "Event type of vehicle",
                    fieldType: FilterFieldType.Enum,
                    fieldTypeMeta: {
                      enum: {
                        enumName: ApiEnumName.GeneralHistoryEventTypeOfVehicle,
                      },
                    },
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().event.vehicleDamage.type.$path,
                    title: "Event type of vehicle damage",
                    fieldType: FilterFieldType.Enum,
                    fieldTypeMeta: {
                      enum: {
                        enumName: ApiEnumName.GeneralHistoryEventTypeOfVehicleDamage,
                      },
                    },
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().raisedAt.$path,
                    title: "Raised at",
                    fieldType: FilterFieldType.Date,
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().raisedBy.userId.$path,
                    title: "Raised by",
                    fieldType: FilterFieldType.Id,
                    operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().createdAt.$path,
                    title: "Created at",
                    fieldType: FilterFieldType.Date,
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().updatedAt.$path,
                    title: "Updated at",
                    fieldType: FilterFieldType.Date,
                    isUseDefaultOperators: true,
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().createdBy.$path,
                    title: "Created by",
                    fieldType: FilterFieldType.Id,
                    operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                  }),
                  new FilterFieldSpec({
                    field: getTypedPath<GeneralHistoryDto>().updatedBy.$path,
                    title: "Updated by",
                    fieldType: FilterFieldType.Id,
                    operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
                  }),
                ],
              }),
              // initialFilterDefinition: undefined,
              // filterDefinition: commonRequestParams.filterDefinition,
              // onChange: (newValue) => {
              //   commonRequestParams.setFilterDefinition(newValue);
              // },
            }}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />

          {TypeHelper.isEmpty(paginated?.items) && paginatedRequest.isEnded && (
            <NoDataAlert title='No records yet' />
          )}

          {paginated?.items?.map((item, i) => (
            <GeneralHistoryListItem
              key={i}
              item={item}
              viewVariant={displayProps?.viewVariant ?? ViewLayoutVariant.Page}
              onUpdated={() => {
                paginatedRequest.refetch();
              }}
              onDeleted={() => {
                paginatedRequest.refetch();
              }}
            />
          ))}
        </Stack>

        {displayProps?.pagination && (
          <AppPagination
            paginationInfo={commonRequestParams?.paginationInfo}
            onChange={(newValue) => {
              commonRequestParams.setPaginationInfo(newValue);
              topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        )}
      </Stack>

      {/* Create modal */}
      <GeneralHistoryCreateUpdateModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        createUpdateProps={{
          defaultValues: {
            subject: {
              entityType: commonRequestParams.params?.subjectEntityType,
              entityId: commonRequestParams.params?.subjectEntityId,
            },
          },
          onSave: (newValue) => {
            paginatedRequest.refetch();
            setIsCreateModalOpen(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
