import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import FileUploader from "@/common/components/Files/FileUploader";
import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { FileItem } from "@/common/fileItem";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralAttachmentInputDto, VehicleDto } from "@/core/api/generated";

interface Props extends Omit<AppModalProps, "children"> {
  vehicle: VehicleDto;
  onMileageChanged?: () => void;
}

export default function UpdateMileageModal({
  trigger,
  vehicle,
  open,
  onOpen = () => {},
  onClose = () => {},
  onMileageChanged = () => {},
}: Props) {
  const [mileage, setMileage] = useState(0);
  const [notes, setNotes] = useState("");
  const [attachments, setAttachments] = useState<GeneralAttachmentInputDto[]>([]);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const [isAttachmentFilesHaveErrors, setIsAttachmentFilesHaveErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<ValidationInfo | null>(null);

  useEffect(() => {
    if (vehicle) {
      setMileage(vehicle.mileage!);
    }
  }, [vehicle]);

  useEffect(() => {
    if (vehicle) {
      setMileage(vehicle!.mileage!);
    }
    setNotes("");
    setAttachments([]);
    setIsAttachmentFilesUploading(false);
    setErrors(null);
  }, [open]);

  const handleChangeMileage = (value: number) => {
    if (value < 0) return;
    setMileage(value);
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      //not required now
      // if (attachments.length == 0) {
      //   throw new Error("You need to attach photo of the speedometer");
      // }
      await apiClient.vehiclesApi.apiV1VehiclesVehicleIdMileagePut({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        vehicleId: vehicle.id!,
        updateVehicleMileageDto: {
          mileage: mileage,
          notes: notes,
          attachments: attachments,
        },
      });
      onMileageChanged();
      handleClose();
    } catch (e: any) {
      const errorsInfo = ValidationHelper.handleApiErrorResponse(e);
      setErrors(errorsInfo);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal
      fullWidth
      maxWidth='md'
      trigger={trigger}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <AppModalTitle title={"Update vehicle mileage"} withCloseIcon onCloseClicked={handleClose} />

      <DialogContent>
        <FormControl fullWidth margin='dense'>
          <TextField
            error={Boolean(errors?.errors.mileage)}
            inputMode='decimal'
            label='Mileage'
            value={mileage || ""}
            onChange={(e) => handleChangeMileage(+e.target.value)}
          />
        </FormControl>
        <FormHelperText error={Boolean(errors?.errors.mileage)}>
          {errors?.errors.mileage}
        </FormHelperText>

        <FormControl fullWidth margin='dense'>
          <AppTextArea
            mode='notes'
            error={Boolean(errors?.errors.notes)}
            rows={4}
            value={notes}
            name='notes'
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormControl>
        <FormHelperText error={Boolean(errors?.errors.notes)}>
          {errors?.errors.notes}
        </FormHelperText>

        <FormControl fullWidth margin='dense'>
          <FileUploader
            multiple
            defaultFiles={[]}
            onChange={(files: FileItem[]) =>
              setAttachments(FileItem.toManyGeneralAttachmentInputDto(files))
            }
            onUploadStarted={() => {
              setIsAttachmentFilesUploading(true);
            }}
            onUploadFinished={() => {
              setIsAttachmentFilesUploading(false);
            }}
            onValidationStatusChange={(filesValidationStatus) => {
              if (filesValidationStatus)
                setIsAttachmentFilesHaveErrors(
                  Object.values(filesValidationStatus).some((x) => x === false),
                );
            }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions sx={{ mb: 1 }}>
        <Button
          variant='outlined'
          color='text'
          sx={{ minWidth: 200 }}
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={isSubmitting}
          disabled={isAttachmentFilesUploading || isAttachmentFilesHaveErrors}
          sx={{ minWidth: 200 }}
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </AppModal>
  );
}
