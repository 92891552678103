import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { SubscriptionPlanOptionDto } from "@/core/api/generated";

import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "../../components/BaseEntityInline";

interface Props extends BaseEntityInlineInheritableProps {
  option: SubscriptionPlanOptionDto | null | undefined;
}

/** Inline brief information about the entity. */
export default function SubscriptionPlanOptionInline({ option, ...otherProps }: Props) {
  return (
    <BaseEntityInline
      entity={option}
      icon={<AppIcon of='data' inText />}
      content={
        <>
          {option?.name} (
          <InlineApiEnumValue type='SubscriptionPlanOptionType' value={option?.type} />
          {option?.forVehicle && (
            <>
              ,{" "}
              <InlineApiEnumValue
                type='SubscriptionPlanOptionForVehicleType'
                value={option?.forVehicle?.type}
              />
            </>
          )}
          )
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Subscription plan option"}
    />
  );
}
