import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import uuid4 from "uuid4";

import ServerBaseErrorModel from "@/common/models/api/ServerBaseErrorModel";
import { chatHubService } from "@/common/realtime/chatHubService";
import { apiClient } from "@/core/api/ApiClient";
import {
  ChatAcknowledgeResultDto,
  ChatAcknowledgedDto,
  ChatDto,
  ChatParticipantDto,
  ChatReopenedDto,
  ChatResolvedDto,
  ChatType,
  ChatUpdatedDto,
  GeneralScopeDto,
  GeneralScopeType,
  GetOrCreateCommentChatDto,
  PaginationDtoOfChatDto,
  PaginationDtoOfChatParticipantDto,
} from "@/core/api/generated";
import { AppThunk } from "@/store";
import * as chatHistorySlice from "@/store/communication/chatHistorySlice";
import * as chatParticipantsSlice from "@/store/communication/chatParticipantsSlice";
import * as chatUserSettingsSlice from "@/store/communication/chatUserSettingsSlice";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import {
  selectChatCurrentParticipant,
  selectOpenedChat,
  selectOpenedChatInChatsState,
  selectOpenedChatWithAnyPlacement,
  selectOpenedChatsWithAnyPlacement,
} from "./selectors";

export interface CommentChatParams {
  name?: GetOrCreateCommentChatDto["name"];
  description?: GetOrCreateCommentChatDto["description"];
}

export function getChatStoreKeyByScope(scope?: GeneralScopeDto | null) {
  return scope?.identifier || "" + scope?.subIdentifier || "";
}

export function getChatStoreKey(chat?: ChatDto | null) {
  if (!chat) {
    return "";
  }
  return chat.scope ? getChatStoreKeyByScope(chat.scope) : chat.id!;
}

export function getChatStoreKeyById(chatId?: string | null) {
  if (!chatId) {
    return "";
  }
  return chatId;
}

export function isChatScopesEqual(a?: GeneralScopeDto | null, b?: GeneralScopeDto | null): boolean {
  if (_.isNil(a) && _.isNil(b)) {
    return true;
  }

  return (
    (a?.identifier || null) === (b?.identifier || null) &&
    (a?.subIdentifier || null) === (b?.subIdentifier || null)
  );
}

export function isOpenChatInfoEqual(
  a?: OpenChatInfo | null,
  b?: OpenChatInfo | null,
  { comparePlacement = true } = {},
): boolean {
  if (_.isNil(a) && _.isNil(b)) {
    return true;
  }

  return (
    (a?.id || null) === (b?.id || null) ||
    ((comparePlacement ? (a?.placement || null) === (b?.placement || null) : true) &&
      (a?.chatType || null) === (b?.chatType || null) &&
      (a?.chatId || null) === (b?.chatId || null) &&
      isChatScopesEqual(a?.scope, b?.scope))
  );
}

function setChat(state: ChatsState, chat?: ChatDto | null, isReset?: boolean) {
  if (chat) {
    const keys = [getChatStoreKeyById(chat.id), getChatStoreKeyByScope(chat.scope)].filter(
      (x) => !!x,
    );
    keys.forEach((key) => {
      state.chatMap[key] = isReset ? undefined : chat;
    });
  }
}

export type OpenChatPlacement = "popover" | "stack" | "page";

export interface OpenChatInfo {
  id?: string;
  placement?: OpenChatPlacement;
  chatType: ChatType;
  chatId?: string | null;
  scope?: GeneralScopeDto | null;
  isLoading?: boolean;
  isOpen?: boolean;
  /** Whether chat message input or its area is focused. */
  isEnterMessageAreaFocused?: boolean;
}

export interface ChatsState {
  loading: {
    getPaginated?: boolean;
    getChat?: boolean;
    getChatByScope?: boolean;
    getOrCreateCommentChat?: boolean;
    getOrCreateNegotiationChat?: boolean;
    updateChat?: boolean;
    resolveChat?: boolean;
    reopenChat?: boolean;
    acknowledgeChat?: boolean;
    openChat?: Record<string, boolean>;
    closeChat?: Record<string, boolean>;
  };
  paginatedChats?: PaginationDtoOfChatDto;
  chatMap: Record<string, ChatDto | undefined>;
  openedChats?: OpenChatInfo[];
}

const initialState: ChatsState = {
  loading: {
    openChat: {},
    closeChat: {},
  },
  paginatedChats: undefined,
  chatMap: {},
  openedChats: undefined,
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ChatsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    resetChat: (state, action: PayloadAction<ChatDto | undefined>) => {
      setChat(state, action.payload, true);
    },
    _chatsFetched: (state, action: PayloadAction<PaginationDtoOfChatDto>) => {
      state.paginatedChats = action.payload;
    },
    joinedChat: (state, action: PayloadAction<ChatDto>) => {
      setChat(state, action.payload);
    },
    _chatFetched: (state, action: PayloadAction<ChatDto>) => {
      setChat(state, action.payload);
    },
    _getOrCreateCommentChatSucceeded: (state, action: PayloadAction<ChatDto>) => {
      setChat(state, action.payload);
    },
    _getOrCreateNegotiationChatSucceeded: (state, action: PayloadAction<ChatDto>) => {
      setChat(state, action.payload);
    },
    _chatUpdated: (state, action: PayloadAction<ChatDto>) => {
      const commentChat = state.chatMap[getChatStoreKey(action.payload)];
      if (commentChat) {
        setChat(state, action.payload);
      }
    },
    chatUpdated: (state, action: PayloadAction<ChatUpdatedDto>) => {
      const chat = state.chatMap[getChatStoreKey(action.payload.chat)];
      if (chat) {
        setChat(state, action.payload.chat);
      }
    },
    _chatResolved: (state, action: PayloadAction<ChatDto>) => {
      const chat = state.chatMap[getChatStoreKey(action.payload)];
      if (chat) {
        chat.isResolved = action.payload.isResolved;
        chat.resolvedAt = action.payload.resolvedAt;
        chat.resolvedByParticipantId = action.payload.resolvedByParticipantId;
      }
    },
    chatResolved: (state, action: PayloadAction<ChatResolvedDto>) => {
      const chat = state.chatMap[getChatStoreKey(action.payload.chat)];

      if (chat) {
        setChat(state, action.payload.chat);
      }
    },
    _chatReopened: (state, action: PayloadAction<ChatDto>) => {
      const chat = state.chatMap[getChatStoreKey(action.payload)];
      if (chat) {
        setChat(state, action.payload);
      }
    },
    chatReopened: (state, action: PayloadAction<ChatReopenedDto>) => {
      const chat = state.chatMap[getChatStoreKey(action.payload.chat)];

      if (chat) {
        setChat(state, action.payload.chat);
      }
    },
    _chatAcknowledged: (state, action: PayloadAction<ChatAcknowledgeResultDto>) => {},
    chatAcknowledged: (state, action: PayloadAction<ChatAcknowledgedDto>) => {},
    addOpenedChat: (state, action: PayloadAction<OpenChatInfo>) => {
      state.openedChats = state.openedChats || [];

      let openedChat = selectOpenedChatInChatsState(state, action.payload);
      if (!openedChat) {
        openedChat = {
          ...action.payload,
          id: action.payload.id || uuid4(),
          isLoading: true,
          isOpen: false,
        };
        state.openedChats.push(openedChat);
      }
    },
    confirmChatOpened: (state, action: PayloadAction<OpenChatInfo | undefined>) => {
      if (!action.payload) {
        return;
      }

      const openedChat = state.openedChats?.find((x) => x.id === action.payload!.id);
      if (openedChat) {
        openedChat.isLoading = false;
        openedChat.isOpen = true;
      }
    },
    setChatIsEnterMessageAreaFocused: (
      state,
      action: PayloadAction<{ chatId: string; isEnterMessageAreaFocused: boolean }>,
    ) => {
      const openedChat = state.openedChats?.find((x) => x.chatId === action.payload.chatId);
      if (openedChat) {
        openedChat.isEnterMessageAreaFocused = action.payload.isEnterMessageAreaFocused;
      }
    },
    removeOpenedChat: (state, action: PayloadAction<OpenChatInfo | undefined>) => {
      if (!action.payload) {
        return;
      }

      state.openedChats = state.openedChats || [];
      const index = state.openedChats.findIndex((x) => x.id === action.payload!.id);
      index !== -1 && state.openedChats.splice(index, 1);

      if (state.openedChats.length === 0) {
        state.openedChats = undefined;
      }
    },
  },
});

export const {
  setLoading,
  resetChat,
  _chatsFetched,
  joinedChat,
  _chatFetched,
  _getOrCreateCommentChatSucceeded,
  _getOrCreateNegotiationChatSucceeded,
  _chatUpdated,
  chatUpdated,
  _chatResolved,
  chatResolved,
  _chatReopened,
  chatReopened,
  _chatAcknowledged,
  chatAcknowledged,
  addOpenedChat,
  confirmChatOpened,
  setChatIsEnterMessageAreaFocused,
  removeOpenedChat,
} = chatsSlice.actions;

export const chatsReducer = chatsSlice.reducer;

export const getChats =
  (...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsGet>): AppThunk =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getPaginated: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsGet(...arg);
      await dispatch(_chatsFetched(response.data));
    } finally {
      dispatch(
        setLoading({
          getPaginated: false,
        }),
      );
    }
  };

export const getChat =
  (...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsChatIdGet>): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsChatIdGet(...arg);
      await dispatch(_chatFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getChat: false,
        }),
      );
    }
  };

export const getChatByScope =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsByScopeGet>
  ): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getChatByScope: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsByScopeGet(...arg);
      await dispatch(_chatFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getChatByScope: false,
        }),
      );
    }
  };

export const getOrCreateCommentChat =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsCommentGetOrCreatePost>
  ): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getOrCreateCommentChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsCommentGetOrCreatePost(...arg);
      await dispatch(_getOrCreateCommentChatSucceeded(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getOrCreateCommentChat: false,
        }),
      );
    }
  };

export const getOrCreateNegotiationChat =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsNegotiationGetOrCreatePost>
  ): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getOrCreateNegotiationChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsNegotiationGetOrCreatePost(...arg);
      await dispatch(_getOrCreateNegotiationChatSucceeded(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getOrCreateNegotiationChat: false,
        }),
      );
    }
  };

export const updateChat =
  (...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsChatIdPut>): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        updateChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsChatIdPut(...arg);
      await dispatch(_chatUpdated(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          updateChat: false,
        }),
      );
    }
  };

export const resolveChat =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsChatIdResolvePost>
  ): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        resolveChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsChatIdResolvePost(...arg);
      await dispatch(_chatResolved(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          resolveChat: false,
        }),
      );
    }
  };

export const reopenChat =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsChatIdReopenPost>
  ): AppThunk<Promise<ChatDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        reopenChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsChatIdReopenPost(...arg);
      await dispatch(_chatReopened(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          reopenChat: false,
        }),
      );
    }
  };

export const acknowledgeChat =
  (
    ...arg: Parameters<typeof apiClient.chatsApi.apiV1ChatsChatIdAcknowledgePost>
  ): AppThunk<Promise<ChatAcknowledgeResultDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        acknowledgeChat: true,
      }),
    );

    try {
      const response = await apiClient.chatsApi.apiV1ChatsChatIdAcknowledgePost(...arg);
      await dispatch(_chatAcknowledged(response.data));
      await dispatch(chatHistorySlice.chatAcknowledgedByMe(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          acknowledgeChat: false,
        }),
      );
    }
  };

export const openChat =
  (params: {
    placement: OpenChatPlacement;
    chatType?: ChatType | null;
    chatId?: string | null;
    scope?: GeneralScopeDto | null;
    commentChatParams?: CommentChatParams;
  }): AppThunk<Promise<OpenChatInfo>> =>
  async (dispatch, getState) => {
    console.log("openChat", 1, moment().format(), { params });

    const setChatLoading = (chatId: string | null | undefined, isLoading: boolean) => {
      dispatch(
        setLoading({
          openChat: {
            ...(getState().communication.chats.loading.openChat || {}),
            [chatId || ""]: isLoading,
          },
        }),
      );
    };

    const getParticipants = async (chatId: string) => {
      const participants = await dispatch(
        chatParticipantsSlice.getChatParticipants({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chatId!,
          chatParticipantGetPaginatedDto: {
            offset: 0,
            limit: 100,
          },
        }),
      );
      const currentParticipant = participants.items!.find(
        (x) => x.userId === getState().profile.profile?.id,
      );
      return { participants, currentParticipant };
    };

    let chatId: string | undefined | null = params.chatId || "";
    const scope = (params.scope && { ...params.scope }) || undefined;
    let loadedChat: ChatDto | undefined | null = undefined;
    let participants: PaginationDtoOfChatParticipantDto | undefined | null = undefined;
    let currentParticipant: ChatParticipantDto | undefined | null = undefined;
    setChatLoading(chatId, true);

    try {
      if (scope) {
        scope.type = scope.type || GeneralScopeType.Dynamic;
      }

      // try to resolve chatId by scope
      if (!params.chatId && scope) {
        try {
          const chatByScope = await dispatch(
            getChatByScope({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              identifier: scope.identifier || undefined,
              subIdentifier: scope.subIdentifier || undefined,
              type: scope.type || undefined,
            }),
          );
          chatId = chatByScope.id;
          loadedChat = chatByScope;
          console.log("openChat", 2, moment().format(), { params, chatByScope, loadedChat });
        } catch (err) {
          const baseErr = err as Partial<ServerBaseErrorModel>;
          if (baseErr.status !== 404) {
            throw err;
          }
        }
      }

      const isScopedChat = !!scope;
      const isValidScope = isScopedChat ? !!scope?.identifier : true;

      console.log("openChat", 3, moment().format(), {
        params,
        loadedChat,
        isScopedChat,
        isValidScope,
      });

      // get chat by id or scope
      if (isScopedChat && isValidScope) {
        try {
          if (params.chatType === "Comment") {
            const returnedChat = await dispatch(
              getOrCreateCommentChat({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                getOrCreateCommentChatDto: {
                  ...(params.commentChatParams || {}),
                  scope: scope!,
                },
              }),
            );
            chatId = returnedChat.id;
            loadedChat = returnedChat;
          } else if (params.chatType === "Negotiation") {
            const returnedChat = await dispatch(
              getOrCreateNegotiationChat({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                generalScopeRequestDto: scope!,
              }),
            );
            chatId = returnedChat.id;
            loadedChat = returnedChat;
          }

          // get chat participants
          if (loadedChat) {
            const participantsResult = await getParticipants(loadedChat.id!);
            participants = participantsResult.participants;
            currentParticipant = participantsResult.currentParticipant;
          }

          // try to join if not participant
          if (loadedChat && !currentParticipant) {
            try {
              const loadedChatResult = await dispatch(
                chatParticipantsSlice.joinChat({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  chatId: loadedChat.id!,
                }),
              );
              const participantsResult = await getParticipants(loadedChat.id!);
              loadedChat = loadedChatResult;
              participants = participantsResult.participants;
              currentParticipant = participantsResult.currentParticipant;
            } catch (err2) {
              throw err2;
            }
          }
        } catch (err) {
          throw err;
        }
      } else if (params.chatId) {
        // try get chat and join if chat not found
        chatId = params.chatId;
        try {
          let [loadedChatResult, participantsResult] = await Promise.all([
            dispatch(
              getChat({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                chatId: params.chatId!,
              }),
            ),
            getParticipants(chatId),
          ]);
          loadedChat = loadedChatResult;
          participants = participantsResult.participants;
          currentParticipant = participantsResult.currentParticipant;

          // try to join if not participant
          if (loadedChat && !currentParticipant) {
            try {
              loadedChatResult = await dispatch(
                chatParticipantsSlice.joinChat({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  chatId: loadedChat.id!,
                }),
              );
              participantsResult = await getParticipants(loadedChat.id!);
              loadedChat = loadedChatResult;
              participants = participantsResult.participants;
              currentParticipant = participantsResult.currentParticipant;
            } catch (err2) {
              throw err2;
            }
          }
        } catch (err) {
          const baseErr = err as Partial<ServerBaseErrorModel>;

          // might be allowed to join, so try to do it
          if (baseErr.status !== undefined && baseErr.status !== 200) {
            try {
              const loadedChatResult = await dispatch(
                chatParticipantsSlice.joinChat({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  chatId: params.chatId,
                }),
              );
              const participantsResult = await getParticipants(chatId);
              loadedChat = loadedChatResult;
              participants = participantsResult.participants;
              currentParticipant = participantsResult.currentParticipant;
            } catch (err2) {
              throw err2;
            }
          }
        }
      }

      console.log("openChat", 4, moment().format(), {
        params,
        loadedChat,
        isScopedChat,
        isValidScope,
      });

      if (!loadedChat) {
        console.error(`Can't load specified chat:`, params);
        throw new Error(`Can't load specified chat.`);
      }
      if (params.chatType && params.chatType !== loadedChat.type) {
        throw new Error(`Invalid chatType.`);
      }

      console.log("openChat", 5, moment().format(), {
        params,
        loadedChat,
        isScopedChat,
        isValidScope,
        participants,
        currentParticipant,
      });

      dispatch(
        chatUserSettingsSlice.getChatUserSettings({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chatId!,
        }),
      );

      const newOpenedChat = {
        id: uuid4(),
        placement: params.placement,
        chatType: loadedChat.type!,
        chatId: chatId,
        scope: scope,
      };

      const openedChatWithAnyPlacement = selectOpenedChatWithAnyPlacement(
        getState(),
        newOpenedChat,
      );
      const openedChat = selectOpenedChat(getState(), newOpenedChat);

      console.log("openChat", 6, moment().format(), {
        params,
        loadedChat,
        isScopedChat,
        isValidScope,
        participants,
        currentParticipant,
        openedChatWithAnyPlacement,
        openedChat,
        openedChats: getState().communication.chats.openedChats,
      });

      // if (openedChatWithAnyPlacement && openedChatWithAnyPlacement.isOpen) {
      //   if (!openedChat) {
      //     await dispatch(addOpenedChat(newOpenedChat));
      //     return newOpenedChat;
      //   }
      //   return openedChat;
      // }
      // if (openedChat && !openedChat.isOpen) {
      //   await dispatch(confirmChatOpened(openedChat));
      //   return openedChat;
      // }

      await dispatch(addOpenedChat(newOpenedChat));

      console.log("openChat", 9, moment().format(), "chatHubService.connectToChat()");
      await chatHubService.connectToChat({
        chatId,
        participantId: currentParticipant?.id,
      });

      await dispatch(confirmChatOpened(openedChat));

      return newOpenedChat;
    } finally {
      setChatLoading(chatId, false);
    }
  };

export const closeChat =
  (params: { openedChat?: OpenChatInfo; chat?: ChatDto }): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    console.log("closeChat", 1, moment().format(), { params });

    const chatId = params.openedChat?.chatId || params.chat?.id;

    dispatch(
      setLoading({
        closeChat: {
          ...(getState().communication.chats.loading.closeChat || {}),
          [chatId || ""]: true,
        },
      }),
    );

    try {
      const currentParticipant = selectChatCurrentParticipant(getState(), chatId);

      const anyOpenedChats = selectOpenedChatsWithAnyPlacement(getState(), params.openedChat);

      console.log("closeChat", 2, moment().format(), {
        params,
        chatId,
        currentParticipant,
        anyOpenedChats,
      });

      await dispatch(removeOpenedChat(params.openedChat));

      // cleanup chat if no more open chats left (same chats)
      if (anyOpenedChats.length <= 1) {
        await dispatch(resetChat(params.chat));
        await dispatch(
          negotiationsSlice.resetChatNegotiations({
            chatId,
          }),
        );
        await dispatch(chatParticipantsSlice.resetChatParticipants({ chatId }));
        await dispatch(chatHistorySlice.resetChatHistory({ chatId }));
        await dispatch(chatUserSettingsSlice.resetChatUserSettings({ chatId }));

        if (chatId) {
          await chatHubService.disconnectFromChat({
            chatId: chatId,
            participantId: currentParticipant?.id,
          });
        }
      }
    } finally {
      dispatch(
        setLoading({
          closeChat: {
            ...(getState().communication.chats.loading.closeChat || {}),
            [params.openedChat?.chatId || ""]: false,
          },
        }),
      );
    }
  };

export const closeAllChats = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const promises =
    getState().communication.chats.openedChats?.map((x) =>
      dispatch(
        closeChat({
          openedChat: x,
        }),
      ),
    ) || [];
  await Promise.all(promises);
};
