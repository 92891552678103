import { LoadingButton } from "@mui/lab";
import { DialogContent, FormControl, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import UserAutocomplete from "@/App/MainAppView/components/User/UserAutocomplete";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { LocationDto, UserDto } from "@/core/api/generated";

interface Props extends Omit<AppModalProps, "children"> {
  location: LocationDto;
  onAdded?: () => void;
}

export default function LocationAddUserModal({ location, onAdded, ...modalProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDto | undefined>(undefined);

  useEffect(() => {
    if (!modalProps.open) {
      setSelectedUser(undefined);
    }
  }, [modalProps.open]);

  const handleAdd = async () => {
    setIsSubmitting(true);

    try {
      await apiClient.locationsApi.apiV1LocationsLocationIdUsersUserIdPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        locationId: location.id!,
        userId: selectedUser!.id!,
      });
      enqueueSnackbar("User added to department", {
        variant: "success",
      });
      modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
      onAdded && onAdded();
      setSelectedUser(undefined);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add users to department"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack sx={{ mt: 1 }} spacing={2}>
          <FormControl margin='dense' fullWidth>
            <UserAutocomplete
              entityId={selectedUser?.id}
              onChange={(user) => setSelectedUser(user)}
            />
          </FormControl>

          <LoadingButton
            fullWidth
            variant='contained'
            disabled={!selectedUser}
            loading={isSubmitting}
            onClick={handleAdd}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}
