import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "@/common/components/Entity/components/BaseEntityDeleteModal";
import { apiClient } from "@/core/api/ApiClient";
import { AdminFileDto, EntityType } from "@/core/api/generated";

export interface OwnProps {
  entity: AdminFileDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function FileDeleteModal({ entity, ...otherProps }: Props) {
  const [isForce, setIsForce] = useState(false);

  return (
    <BaseEntityDeleteModal
      entityType={EntityType.File}
      entity={entity}
      entityId={entity.id}
      deleteFunc={async (params) => {
        const response = await apiClient.adminFilesApi.apiV1AdminFilesFileIdDelete({
          fileId: params.entityId,
          adminFileDeleteDto: {
            isForce: isForce,
          },
        });
        return { newEntity: response.data };
      }}
      cascadeActionDisplay={false}
      body={<Box>File entity and blob will be soft-deleted.</Box>}
      bodyAfter={
        <FormControlLabel
          control={<Checkbox checked={isForce} onChange={(e) => setIsForce(e.target.checked)} />}
          label='Force'
        />
      }
      {...otherProps}
    />
  );
}
