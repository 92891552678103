import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { GridCheckIcon } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useState } from "react";

import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import ExpiredAlert from "@/common/components/AppAlerts/ExpiredAlert";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import NoFilesAlert from "@/common/components/AppAlerts/NoFilesAlert";
import InlineCode from "@/common/components/Code/InlineCode";
import ColorInput from "@/common/components/Color/ColorInput";
import ConnectionStatusIndicator from "@/common/components/Connection/ConnectionStatusIndicator";
import AppIcon from "@/common/components/Icons/AppIcon";
import { MiscHelper } from "@/common/helpers/misc";
import { ColorSpaceType, RgbColorType } from "@/common/ts/color";

export default function DevColorInput() {
  const [color, setColor] = useState<string | undefined>(undefined);

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h2'>
        ColorInput
      </Typography>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          Default
        </Typography>

        <Box>
          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='Default'
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>

          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='With predefined colors'
              predefinedColors={["#f00", "#f50", "#f57", "#070", "#007"]}
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>
        </Box>
      </Stack>

      <Stack spacing={2}>
        <Typography component='div' variant='h3'>
          RGB
        </Typography>

        <Box>
          <FormControl margin='dense' fullWidth>
            <ColorInput
              colorSpaceType={ColorSpaceType.RGB}
              rgbColorType={RgbColorType.HEX}
              value={color || ""}
              label='Color'
              onChange={(newValue) => {
                setColor(newValue);
              }}
            />
          </FormControl>
        </Box>
      </Stack>
    </Stack>
  );
}
