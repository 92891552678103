import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { EntityType, WheelOperationDto } from "@/core/api/generated";

import GeneralHistoryPaginatedList from "../../../GeneralHistory/ListView/GeneralHistoryPaginatedList";

interface Props {
  wheelOperation: WheelOperationDto;
}

export default function GeneralHistoryTabContent({ wheelOperation }: Props) {
  return (
    <PageTabContent>
      <GeneralHistoryPaginatedList
        defaultValues={{
          subjectEntityType: EntityType.WheelOperation,
          subjectEntityId: wheelOperation.id,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
        }}
      />
    </PageTabContent>
  );
}
