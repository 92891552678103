import {
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback } from "react";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { EnumDto, VehicleArea, VehiclePartCategory, VehicleProjection } from "@/core/api/generated";

interface Props {
  /** Specifies enumType and filters (based on other enum values).  */
  enumProps:
    | {
        enumType: "VehiclePartCategory";
        area?: VehicleArea;
        projection?: VehicleProjection;
      }
    | {
        enumType: "VehiclePartType";
        area?: VehicleArea;
        projection?: VehicleProjection;
        category?: VehiclePartCategory;
      }
    | {
        enumType: "VehicleArea";
        projection?: VehicleProjection;
      }
    | {
        enumType: "VehicleProjection";
        area?: VehicleArea;
      };
  value?: string | null;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  allowNone?: boolean;
  selectProps?: SelectProps;
  onChange?: (newValue?: string | null, newValueDto?: EnumDto | null) => void;
}

/** Select comp for back-end enums. Info about enums retrieved from API endpoints. */
export default function ApiEnumDtoSelect({
  enumProps,
  value,
  label,
  disabled,
  required,
  allowNone,
  selectProps,
  onChange,
}: Props) {
  const vehiclePartCategoriesRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehiclepartcategoryGet,
    {
      ...(enumProps.enumType === "VehiclePartCategory"
        ? {
            area: enumProps.area || undefined,
            projection: enumProps.projection || undefined,
          }
        : { area: undefined, projection: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehiclePartCategory",
    },
  );
  const vehiclePartCategories = vehiclePartCategoriesRequest.data || [];

  const vehiclePartTypesRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleparttypeGet,
    {
      ...(enumProps.enumType === "VehiclePartType"
        ? {
            area: enumProps.area || undefined,
            projection: enumProps.projection || undefined,
            category: enumProps.category || undefined,
          }
        : { area: undefined, projection: undefined, category: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehiclePartType",
    },
  );
  const vehiclePartTypes = vehiclePartTypesRequest.data || [];

  const vehicleAreasRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleareaGet,
    {
      ...(enumProps.enumType === "VehicleArea"
        ? { projection: enumProps.projection || undefined }
        : { projection: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehicleArea",
    },
  );
  const vehicleAreas = vehicleAreasRequest.data || [];

  const vehicleProjectionsRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsByTypeNameVehicleprojectionGet,
    {
      ...(enumProps.enumType === "VehicleProjection"
        ? { area: enumProps.area || undefined }
        : { area: undefined }),
    },
    {
      deps: [enumProps],
      skip: enumProps.enumType !== "VehicleProjection",
    },
  );
  const vehicleProjections = vehicleProjectionsRequest.data || [];

  let options: EnumDto[] = [];
  if (enumProps.enumType === "VehiclePartCategory") {
    options = vehiclePartCategories || [];
  } else if (enumProps.enumType === "VehiclePartType") {
    options = vehiclePartTypes || [];
  } else if (enumProps.enumType === "VehicleArea") {
    options = vehicleAreas || [];
  } else if (enumProps.enumType === "VehicleProjection") {
    options = vehicleProjections || [];
  }

  const _onChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newValue = e.target.value?.toString() || null;
      const selectedOption = options.find((x) => x.value === newValue) || null;
      onChange && onChange(newValue, selectedOption);
    },
    [onChange],
  );

  return (
    <FormGroup>
      <Select<any>
        label={label}
        displayEmpty={false}
        value={value?.toString() || ""}
        disabled={disabled}
        required={required}
        onChange={_onChange}
        {...selectProps}
      >
        {options
          .filter((x) => (allowNone ? true : x.value !== "None")) // I don't know what is being filtered.
          .map((option, index) => {
            return (
              <MenuItem key={index} value={option.value!}>
                <Stack>
                  <Typography component='div' variant='body1'>
                    {option.name || option.description || option.shortName || option.value}{" "}
                    <Typography component='span' variant='caption' sx={{ ml: 1 }}>
                      ({option.value})
                    </Typography>
                  </Typography>
                  {option.description && (
                    <Typography component='div' variant='body2'>
                      {option.description}
                    </Typography>
                  )}
                </Stack>
              </MenuItem>
            );
          })}
      </Select>
    </FormGroup>
  );
}
