import React, { ReactElement, ReactNode } from "react";
import { Redirect, useLocation } from "react-router";
import { JsxElement } from "typescript";

import { ROUTE_PARTS, ROUTE_PATH } from "@/common/constants/routing";
import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useAppDispatch, useAppSelector } from "@/common/hooks/redux";
import { AppPermission } from "@/core/api/generated";

interface AuthorizedGuardProps {
  /** List of permissions user must have (all) */
  permissions?: AppPermission[];
  /** List of permissions user must have (at least one from the list) */
  permissionsAny?: AppPermission[];
  children: JsxElement | ReactElement | React.ComponentType | ReactNode;
}

/** Renders children if user has required permissions, otherwise redirects to forbidden route */
export default function AuthorizedGuard({
  permissions,
  permissionsAny,
  children,
}: AuthorizedGuardProps) {
  const { isAuthenticated } = useAuthenticationInfo();
  const { isPermissionsReady, userPermissions } = useAuthorizationInfo();
  const dispatch = useAppDispatch();

  const pathname = useLocation().pathname;
  // const pathname = useAppSelector((x) => x.router.location.pathname);
  // const requestedLocation = useAppSelector((x) => x.redirect.requestedLocation);

  if (!isPermissionsReady || !userPermissions) {
    return null;
  }
  if (!permissions && !permissionsAny) {
    return null;
  }
  if (isAuthenticated === undefined || isAuthenticated === false) {
    return null;
  }

  const hasPermissionsRequired = (permissions || []).every((x) => userPermissions.includes(x));
  const hasPermissionsAny = permissionsAny
    ? permissionsAny.some((x) => userPermissions.includes(x))
    : true;
  const hasPermissions = hasPermissionsRequired && hasPermissionsAny;

  if (!hasPermissions) {
    // if (pathname !== requestedLocation) {
    //   dispatch(redirectSlice.setRequestedLocation(pathname));
    // }

    console.log(
      `Redirection to ${ROUTE_PARTS.FORBIDDEN} from location ${window.location.href}...`,
      { href: window.location.href, permissions, permissionsAny, userPermissions },
    );
    return <Redirect to={ROUTE_PATH.AUTH_FORBIDDEN} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  // if (requestedLocation && pathname !== requestedLocation) {
  //   dispatch(redirectSlice.setRequestedLocation(null));
  //   return <Redirect to={requestedLocation} />;
  // }

  return <>{children}</>;
}
