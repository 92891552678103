import { Stack, Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

import AppIcon, { AppIconProps } from "../Icons/AppIcon";

interface Props {
  title?: string;
  description?: string;
  icon?: ReactNode;
  titleProps?: TypographyProps<"div">;
  descriptionProps?: TypographyProps<"div">;
  iconProps?: Partial<AppIconProps>;
}

export default function EntityNotFoundAlert({
  title = "Not found",
  description = "The requested entity might be deleted, does not exist or you don't have access to it.",
  icon,
  titleProps,
  descriptionProps,
  iconProps,
}: Props): JSX.Element {
  return (
    <Stack direction='column' spacing={1} sx={{ alignItems: "center", px: 4, py: 2 }}>
      {icon || <AppIcon of='notFound' fontSize='medium' {...iconProps} />}

      <Typography component='div' variant='body2' {...titleProps}>
        {title}
      </Typography>

      {description && (
        <Typography component='div' variant='caption' {...descriptionProps}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
