import { TenantDto, TenantRequestDto, TenantRequestStage } from "@/core/api/generated";

export class TenantRequestHelper {
  public static canBeEdited(tenantRequest: TenantRequestDto | null | undefined): boolean {
    return tenantRequest?.stageHistory?.stage === TenantRequestStage.Draft;
  }

  public static canBeDeleted(tenantRequest: TenantRequestDto | null | undefined): boolean {
    return tenantRequest?.stageHistory?.stage === TenantRequestStage.Draft;
  }

  public static isSentByMe(
    currentTenant: TenantDto | null | undefined,
    tenantRequest: TenantRequestDto | null | undefined,
  ): boolean {
    return (
      !!currentTenant && !!tenantRequest && currentTenant.id === tenantRequest.senderTenant?.id
    );
  }

  public static isReceivedByMe(
    currentTenant: TenantDto | null | undefined,
    tenantRequest: TenantRequestDto | null | undefined,
  ): boolean {
    return (
      !!currentTenant && !!tenantRequest && currentTenant.id === tenantRequest.receiverTenant?.id
    );
  }
}
