export const ColorSpaceType = {
  RGB: "RGB",
  HSL: "HSL",
} as const;
export type ColorSpaceType = (typeof ColorSpaceType)[keyof typeof ColorSpaceType];

/** Describes type of RGB color string. */
export const RgbColorType = {
  /** RGB: rgb(255 0 153), rgb(160, 34, 42) */
  RGB: "RGB",
  /** RGBA: rgba(255 0 153 / 50%), rgba(160, 34, 42, 0.5) */
  RGBA: "RGBA",
  /** RGB Hexadecimal: #RGB, #RRGGBB */
  HEX: "HEX",
  /** RGBA Hexadecimal: #RGBA, #RRGGBBAA */
  HEXA: "HEXA",
} as const;
export type RgbColorType = (typeof RgbColorType)[keyof typeof RgbColorType];

/** Describes color lightness side.
 * Lightness is a percentage (at least in HSL color space);
 * 0% is black, 100% is white.
 */
export const ColorLightnessSide = {
  Black: "Black",
  White: "White",
} as const;
export type ColorLightnessSide = (typeof ColorLightnessSide)[keyof typeof ColorLightnessSide];
