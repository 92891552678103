import { Box, Stack } from "@mui/material";

import { TypeHelper } from "@/common/helpers/type";
import { FileUsageLockDto, FileUsageLockSubjectType } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import FieldValue from "../../Form/Display/FieldValue";
import TimeSpanDisplay from "../../Form/Display/TimeSpanDisplay";
import FileUsageLockSubjectLink from "./FileUsageLockSubjectLink";

interface Props {
  value: FileUsageLockDto | undefined | null;
}

export default function FileUsageLockDisplay({ value }: Props) {
  if (!value) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <FieldValue label='Subject' isEmpty={TypeHelper.isEmpty(value.subject)}>
        <Box>
          <InlineApiEnumValue type='FileUsageLockSubjectType' value={value.subject?.type} />
        </Box>

        {value.subject?.type == FileUsageLockSubjectType.Entity && (
          <>
            <Box>
              <InlineApiEnumValue type='EntityType' value={value.subject?.entityType} />#{" "}
              {value.subject?.entityId}
            </Box>
            <Box>
              <FileUsageLockSubjectLink value={value.subject} />
            </Box>
          </>
        )}
      </FieldValue>

      <FieldValue label='Locked at' isEmpty={TypeHelper.isEmpty(value.lockedAt)}>
        <Datetime datetime={value.lockedAt} withDurationFromNow />
      </FieldValue>

      <FieldValue label='Unlocked at' isEmpty={TypeHelper.isEmpty(value.unlockedAt)}>
        <Datetime datetime={value.unlockedAt} withDurationFromNow />
      </FieldValue>

      <FieldValue label='Lock duration' isEmpty={TypeHelper.isEmpty(value.lockDuration)}>
        <TimeSpanDisplay value={value.lockDuration} />
      </FieldValue>
    </Stack>
  );
}
