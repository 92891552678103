import { Box, Button, Checkbox, FormControlLabel, Popover, Stack } from "@mui/material";
import { getIn } from "formik";
import { useCallback, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import PriceSummaryInput from "@/common/components/PriceSummary/PriceSummaryInput";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { VehiclePriceInputDto, VehiclePriceWithSettingsInputDto } from "@/core/api/generated";

interface Props {
  formikProps: CustomFormikSubProps<
    | (VehiclePriceWithSettingsInputDto & { price?: VehiclePriceInputDto & { total?: number } })
    | null
    | undefined
  >;
}

export default function VehiclePurchasePriceInput({ formikProps, ...textFieldProps }: Props) {
  const { values, errors, setFieldValue, setFieldError } = formikProps;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box>
      <Stack direction='row' alignItems='start'>
        <PriceSummaryInput
          values={{
            currency: values?.price?.currency,
            subTotal: values?.price?.price,
            discount: values?.price?.discount,
            tax: values?.price?.tax,
            total: values?.price?.total,
          }}
          formikProps={{
            errors: {
              currency: getIn(errors, `currency`),
              subTotal: getIn(errors, `price`),
              discount: getIn(errors, `discount`),
              tax: getIn(errors, `tax`),
              total: getIn(errors, `total`),
            },
          }}
          displayProps={{
            insurance: false,
            calcExplanation: false,
          }}
          inputsProps={{
            all: {
              margin: "none",
            },
            subTotal: {
              label: "Purchase price",
              required: false,
            },
            total: {
              required: false,
            },
          }}
          onChange={(newValue) => {
            setFieldValue(`price.currency`, newValue?.currency);
            setFieldValue(`price.price`, newValue?.subTotal);
            setFieldValue(`price.discount`, newValue?.discount);
            setFieldValue(`price.tax`, newValue?.tax);
            setFieldValue(`price.total`, newValue?.total);
          }}
        />

        <Button
          variant='outlined'
          color='text'
          size='small'
          startIcon={<AppIcon of='settings' />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ ml: 1 }}
        >
          Settings
        </Button>
      </Stack>

      {/* Settings popover */}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack direction='column' sx={{ width: 200, p: 3 }}>
          {!(
            values?.settings?.isForAllWithSameMake ||
            values?.settings?.isForAllWithSameModel ||
            values?.settings?.isForAllWithSameGeneration ||
            values?.settings?.isForAllWithSameModification
          ) && (
            <FormControlLabel
              label='For all similar vehicles'
              control={
                <Checkbox
                  checked={values?.settings?.isForAllSimilarVehicles}
                  onChange={(e) =>
                    setFieldValue(`settings.isForAllSimilarVehicles`, e.target.checked)
                  }
                />
              }
            />
          )}

          {!values?.settings?.isForAllSimilarVehicles && (
            <>
              <FormControlLabel
                label='Make'
                control={
                  <Checkbox
                    checked={values?.settings?.isForAllWithSameMake}
                    onChange={(e) =>
                      setFieldValue(`settings.isForAllWithSameMake`, e.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label='Model'
                control={
                  <Checkbox
                    checked={values?.settings?.isForAllWithSameModel}
                    onChange={(e) =>
                      setFieldValue(`settings.isForAllWithSameModel`, e.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label='Generation'
                control={
                  <Checkbox
                    checked={values?.settings?.isForAllWithSameGeneration}
                    onChange={(e) =>
                      setFieldValue(`settings.isForAllWithSameGeneration`, e.target.checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label='Modification'
                control={
                  <Checkbox
                    checked={values?.settings?.isForAllWithSameModification}
                    onChange={(e) =>
                      setFieldValue(`settings.isForAllWithSameModification`, e.target.checked)
                    }
                  />
                }
              />
            </>
          )}
        </Stack>
      </Popover>
    </Box>
  );
}
