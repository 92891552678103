import { Box } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import TimeSpanDisplay from "@/common/components/Form/Display/TimeSpanDisplay";
import AppLink from "@/common/components/Link/AppLink";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import { getTypedPath } from "@/common/helpers/typedPath";
import { AdminFileDto, FileThumbnailDto } from "@/core/api/generated";

interface Props {
  file: AdminFileDto;
}

export default function ThumbnailsTabContent({ file }: Props) {
  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Thumbnails"} />}
      >
        <ViewContentBlock>
          <DataTabular
            columns={[
              {
                field: getTypedPath<FileThumbnailDto>().sizeType.$path,
                title: "Size type",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <InlineApiEnumValue type='ThumbnailSizeType' value={item.sizeType} />
                ),
              },
              {
                field: getTypedPath<FileThumbnailDto>().size.$path,
                title: "Size",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <Box>
                    {item.size?.width}x{item.size?.height}
                  </Box>
                ),
              },
              {
                field: "filePreview",
                title: "Preview",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) => (
                  <TableCellContentOfAttachments
                    attachments={item.url ? [{ file: { ...item, size: undefined } }] : undefined}
                  />
                ),
              },
              {
                field: getTypedPath<FileThumbnailDto>().url.$path,
                title: "URL",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) =>
                  item.url ? (
                    <AppLink to={undefined} href={item.url || undefined} target='_blank'>
                      {item.url}
                    </AppLink>
                  ) : (
                    "-"
                  ),
              },
              {
                field: getTypedPath<FileThumbnailDto>().urlLifetime.$path,
                title: "URL lifetime",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) =>
                  item.urlLifetime ? <TimeSpanDisplay value={item.urlLifetime} /> : "-",
              },
              {
                field: getTypedPath<FileThumbnailDto>().urlExpiresAt.$path,
                title: "URL expires at",
                width: 200,
                isVisible: true,
                isHideable: true,
                isSortable: false,
                isFilterable: false,
                renderCell: (item) =>
                  item.urlExpiresAt ? (
                    <Datetime datetime={item.urlExpiresAt} withDurationFromNow />
                  ) : (
                    "-"
                  ),
              },
            ]}
            rows={file.thumbnails}
            getRowId={(item) => item.url!}
          />
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
