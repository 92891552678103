import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { FileHelper } from "@/common/helpers/file";
import { HttpHelper } from "@/common/helpers/http";
import { MimeTypeHelper } from "@/common/helpers/mimeType";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ExportEntityType } from "@/core/api/generated";

export interface ExportDataProps {
  entityType?: ExportEntityType;
  ids?: string[];
  onExportDone?: () => void;

  restrictSelectMime?: boolean;
  restrictSelectProperties?: boolean;
  restrictSelectExportMode?: boolean;
}

enum ExportMode {
  CurrentPage = "CurrentPage",
  AllItems = "AllItems",
}

export default function DataExportForm({
  entityType,
  ids,
  restrictSelectMime = false,
  restrictSelectProperties = false,
  restrictSelectExportMode = false,
  onExportDone,
}: ExportDataProps) {
  const [mime, setMime] = useState<string | undefined>(
    MimeTypeHelper.getSpreadsheetFormats().filter((x) => x.extension === "csv")[0].mimeType,
  );
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [exportMode, setExportMode] = useState<ExportMode>(ExportMode.CurrentPage);
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);

  const exportPropertiesRequest = useApiRequest(
    apiClient.tenantExportApi.apiV1TenantExportsPropertiesGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: entityType,
    },
    {
      deps: [entityType],
      skip: !entityType,
    },
  );

  useEffect(() => {
    if (exportPropertiesRequest.data) {
      setSelectedProperties(exportPropertiesRequest.data?.map((field) => field!) || []);
    }
  }, [exportPropertiesRequest.data]);

  const handleExport = useCallback(async () => {
    let response = null;
    try {
      setIsExportInProgress(true);
      response = await apiClient.tenantExportApi.apiV1TenantExportsPost(
        {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          exportOptionsDto: {
            entityType: entityType,
            mimeType: mime,
            properties: selectedProperties,
            isExportAll: exportMode === ExportMode.AllItems,
            ids: ids,
          },
        },
        {
          responseType: "blob",
        },
      );
    } finally {
      setIsExportInProgress(false);
    }

    const parsedContent = HttpHelper.parseContentHeaders(response!.headers);
    const downloadedFile = {
      blob: response!.data as Blob,
      contentType: parsedContent.contentType!,
      filename: parsedContent.contentDispositionInfo?.directives?.filename,
    };
    await FileHelper.downloadBlobFromApiResult(downloadedFile);
    onExportDone && onExportDone();
  }, [entityType, mime, selectedProperties, exportMode, onExportDone]);

  return (
    <Stack direction='column' spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
      {!restrictSelectMime && (
        <FormControl sx={{ minWidth: 400 }}>
          <InputLabel>File format</InputLabel>
          <Select label='File format' value={mime} onChange={(e) => setMime(e.target.value)}>
            {MimeTypeHelper.getSpreadsheetFormats().map((format, index) => (
              <MenuItem key={index} value={format.mimeType}>
                {format.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {exportPropertiesRequest.isLoading && <LinearProgress />}
      {!exportPropertiesRequest.isLoading && !restrictSelectProperties && (
        <FormControl sx={{ width: 700 }}>
          <InputLabel>Properties</InputLabel>
          <Select
            multiple
            label='Properties'
            value={selectedProperties}
            renderValue={() => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selectedProperties.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setSelectedProperties(selectedProperties.filter((x) => x !== value));
                    }}
                  />
                ))}
              </Box>
            )}
            onChange={(e) => {
              if (e.target.value)
                setSelectedProperties(
                  typeof e.target.value === "string"
                    ? (e.target.value as string).split(",")
                    : (e.target.value as string[]),
                );
            }}
          >
            {exportPropertiesRequest.data?.map((field, index) => (
              <MenuItem key={index} value={field!}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {!restrictSelectExportMode && (
        <FormControl>
          <FormLabel>Export mode</FormLabel>
          <RadioGroup row value={exportMode} onChange={(e, v) => setExportMode(v as ExportMode)}>
            <FormControlLabel
              value={ExportMode.CurrentPage}
              control={<Radio />}
              label='Current page'
            />
            <FormControlLabel value={ExportMode.AllItems} control={<Radio />} label='All items' />
          </RadioGroup>
        </FormControl>
      )}

      <LoadingButton
        loading={isExportInProgress}
        variant='contained'
        onClick={handleExport}
        disabled={!entityType || !mime || selectedProperties.length === 0}
        sx={{ width: 200 }}
      >
        Export
      </LoadingButton>
    </Stack>
  );
}
