import { Dialog, DialogContent, DialogProps, LinearProgress, Stack } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import AssetCreateUpdate, { AssetCreateUpdateProps } from "./AssetCreateUpdate";

export interface OwnProps {
  createUpdateProps: AssetCreateUpdateProps;
  isLoading?: boolean;
}

type Props = OwnProps & DialogProps;

export default function AssetCreateUpdateModal({
  createUpdateProps,
  isLoading,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.entityId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} asset
      </AppModalTitle>
      <DialogContent>
        <Stack sx={{ py: 1 }} spacing={1}>
          {isLoading && <LinearProgress />}

          <AssetCreateUpdate {...createUpdateProps} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
