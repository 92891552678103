import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import _ from "lodash";

import { VisualInspectionSettingsDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import BooleanValue from "../../Form/Display/BooleanValue";
import InfoTile from "../../Form/Display/InfoTile";

interface Props {
  settings: VisualInspectionSettingsDto | null | undefined;
  title?: string | null | undefined;
}

export default function VisualInspectionSettingsDisplay({ settings, title }: Props) {
  if (!settings) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Typography component='div' variant='h6'>
        {title || "Settings"}
      </Typography>

      <Typography component='span' variant='subtitle1'>
        Per vehicle area
      </Typography>

      {settings?.perArea?.map((item, index) => (
        <Card key={index}>
          <CardContent>
            <Stack spacing={2}>
              <Typography component='span' variant='subtitle1'>
                <InlineApiEnumValue type='VehicleArea' value={item.area} />
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xxs: "1fr",
                    md: "repeat(4, 1fr)",
                  },
                  gridTemplateRows: "auto",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                <InfoTile label='Is inspection required' iconOf='check'>
                  <BooleanValue value={item?.isRequired} />
                </InfoTile>

                <InfoTile
                  label='Min attachments'
                  iconOf='attachment'
                  isEmpty={_.isNil(item?.minAttachmentCount)}
                >
                  {item?.minAttachmentCount}
                </InfoTile>

                <InfoTile label='Min photos' iconOf='photo' isEmpty={_.isNil(item?.minPhotoCount)}>
                  {item?.minPhotoCount}
                </InfoTile>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}
