import { Box, Card, CardContent, Stack } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import FileHashInlineDisplay from "@/common/components/Entity/File/FileHashInlineDisplay";
import FileUsageLockDisplay from "@/common/components/Entity/File/FileUsageLockDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import TimeSpanDisplay from "@/common/components/Form/Display/TimeSpanDisplay";
import AppLink from "@/common/components/Link/AppLink";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TypeHelper } from "@/common/helpers/type";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import { AdminFileDto, AdminFileGetPaginatedDto, EntityType } from "@/core/api/generated";

import TenantLink from "../../Tenant/TenantLink";
import FileMenu from "../FileMenu";

const defaultDisplayProps = {
  filters: true,
  counters: true,
  delete: true,
  undelete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};

export type FilePaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface FilePaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    tenantId?: string;
  };
  propagatedDeps?: PropagatedDeps<FilePaginatedListPropagatedDepsMap>;
}

export type FilePaginatedListProps = FilePaginatedListOwnProps;

export default function FilePaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
}: FilePaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const commonRequestParams = useCommonRequestParams<AdminFileGetPaginatedDto>({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityInAdminArea(EntityType.File),
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const paginatedFilesRequest = useApiRequest(
    apiClient.adminFilesApi.apiV1AdminFilesGetPost,
    {
      adminFileGetPaginatedDto: {
        isIncludeDeleted: true,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedFiles = useMemo(() => paginatedFilesRequest?.data, [paginatedFilesRequest?.data]);

  return (
    <ViewLayoutV2 displayProps={displayProps} header={<SimpleViewPageHeader title='Files' />}>
      <DataTabular
        columns={[
          {
            field: getTypedPath<AdminFileDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().parentFileId.$path,
            title: "Parent file ID",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (item.parentFileId ? item.parentFileId : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().copiedFromFileId.$path,
            title: "Copied from file ID",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (item.copiedFromFileId ? item.copiedFromFileId : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().tenantId.$path,
            title: "Company",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.tenantId ? <TenantLink entityId={item.tenantId} entity={undefined} /> : "-",
          },
          {
            field: getTypedPath<AdminFileDto>().storageAccountIdentifier.$path,
            title: "Storage account identifier",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.storageAccountIdentifier,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().containerName.$path,
            title: "Container name",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.containerName,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().blobName.$path,
            title: "Blob name",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.blobName,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().path.$path,
            title: "Path",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.path,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().originalFileName.$path,
            title: "Original file name",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.originalFileName,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().fileName.$path,
            title: "File name",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.fileName,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },

          {
            field: getTypedPath<AdminFileDto>().contentType.$path,
            title: "Content type",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.contentType,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().mimeBaseType.$path,
            title: "MIME base type",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='MimeBaseType' value={item.mimeBaseType} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.MimeBaseType,
                },
              },
            },
          },
          {
            field: getTypedPath<AdminFileDto>().extension.$path,
            title: "Extension",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.extension || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().originalSize?.bytes.$path,
            title: "Original size bytes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.originalSize?.bytes,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().originalSize.asKiB.$path,
            title: "Original size KiB",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.originalSize?.asKiB,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().originalSize.asMiB.$path,
            title: "Original size MiB",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.originalSize?.asMiB,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().size?.bytes.$path,
            title: "Size bytes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.size?.bytes,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().size.asKiB.$path,
            title: "Size KiB",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.size?.asKiB,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().size.asMiB.$path,
            title: "Size MiB",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.size?.asMiB,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().compressionRatio.$path,
            title: "Compression ratio",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (!_.isNil(item.compressionRatio) ? item.compressionRatio : "-"),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().compressionRate.$path,
            title: "Compression rate",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              !_.isNil(item.compressionRate) ? <PercentValue value={item.compressionRate} /> : "-",
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().accessLevel.$path,
            title: "Access level",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue type='FileAccessLevel' value={item.accessLevel} />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.FileAccessLevel,
                },
              },
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isThumbnail.$path,
            title: "Is thumbnail",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isThumbnail} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().thumbnails.$path,
            title: "Thumbnails",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.thumbnails ? (
                <TableCellContentOfCount
                  count={item.thumbnails?.length}
                  popoverContent={
                    <Box>
                      {item.thumbnails.map((thumbnail, i) => (
                        <Card key={i}>
                          <CardContent>
                            <Stack spacing={1}>
                              <FieldValue
                                label='Size type'
                                isEmpty={TypeHelper.isEmpty(thumbnail.sizeType)}
                              >
                                <InlineApiEnumValue
                                  type='ThumbnailSizeType'
                                  value={thumbnail.sizeType}
                                />
                              </FieldValue>

                              <FieldValue label='Size' isEmpty={TypeHelper.isEmpty(thumbnail.size)}>
                                {thumbnail?.size?.width}x{thumbnail?.size?.height}
                              </FieldValue>

                              <FieldValue label='URL' isEmpty={TypeHelper.isEmpty(thumbnail.url)}>
                                <AppLink
                                  to={undefined}
                                  href={thumbnail.url || undefined}
                                  target='_blank'
                                >
                                  {thumbnail.url}
                                </AppLink>
                              </FieldValue>

                              <FieldValue
                                label='URL lifetime'
                                isEmpty={TypeHelper.isEmpty(thumbnail.urlLifetime)}
                              >
                                <TimeSpanDisplay value={thumbnail.urlLifetime} />
                              </FieldValue>

                              <FieldValue
                                label='URL expires at'
                                isEmpty={TypeHelper.isEmpty(thumbnail.urlExpiresAt)}
                              >
                                <Datetime datetime={thumbnail.urlExpiresAt} withDurationFromNow />
                              </FieldValue>
                            </Stack>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  }
                />
              ) : (
                "-"
              ),
          },
          {
            field: getTypedPath<AdminFileDto>().originalHash.$path,
            title: "Original hash",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.originalHash ? <FileHashInlineDisplay value={item.originalHash} /> : "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().hash.$path,
            title: "Hash",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (item.hash ? <FileHashInlineDisplay value={item.hash} /> : "-"),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().usageVersion.$path,
            title: "Usage version",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.usageVersion ? (
                <InlineApiEnumValue type='FileUsageVersion' value={item.usageVersion} />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.FileUsageVersion,
                },
              },
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isUsed.$path,
            title: "Is used",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isUsed} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().obsoleteAt.$path,
            title: "Obsolete at",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.obsoleteAt ? <Datetime datetime={item.obsoleteAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().usageLocks.$path,
            title: "Usage locks",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) =>
              !TypeHelper.isEmpty(item.usageLocks) ? (
                <TableCellContentOfCount
                  count={item.usageLocks?.length}
                  popoverContent={
                    <Stack spacing={4} sx={{ maxHeight: 400 }}>
                      {item.usageLocks?.map((usageLock, i) => (
                        <Box key={i}>
                          <FileUsageLockDisplay value={usageLock} />
                        </Box>
                      ))}
                    </Stack>
                  }
                />
              ) : (
                "-"
              ),
          },
          {
            field: getTypedPath<AdminFileDto>().usageLockHistory.$path,
            title: "Usage lock history",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) =>
              !TypeHelper.isEmpty(item.usageLockHistory) ? (
                <TableCellContentOfCount
                  count={item.usageLockHistory?.length}
                  popoverContent={
                    <Stack spacing={4} sx={{ maxHeight: 400 }}>
                      {item.usageLockHistory?.map((usageLock, i) => (
                        <Box key={i}>
                          <FileUsageLockDisplay value={usageLock} />
                        </Box>
                      ))}
                    </Stack>
                  }
                />
              ) : (
                "-"
              ),
          },
          {
            field: "filePreview",
            title: "Preview",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <TableCellContentOfAttachments
                attachments={item.url ? [{ file: { ...item } }] : undefined}
              />
            ),
          },
          {
            field: getTypedPath<AdminFileDto>().url.$path,
            title: "URL",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) =>
              item.url ? (
                <AppLink to={undefined} href={item.url || undefined} target='_blank'>
                  {item.url}
                </AppLink>
              ) : (
                "-"
              ),
          },
          {
            field: getTypedPath<AdminFileDto>().urlLifetime.$path,
            title: "URL lifetime",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.urlLifetime ? <TimeSpanDisplay value={item.urlLifetime} /> : "-",
          },
          {
            field: getTypedPath<AdminFileDto>().urlExpiresAt.$path,
            title: "URL expires at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.urlExpiresAt ? (
                <Datetime datetime={item.urlExpiresAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isDeleted.$path,
            title: "Is deleted",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isDeleted} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isBlobDeleted.$path,
            title: "Is blob deleted",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isBlobDeleted} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isBlobSoftDeleted.$path,
            title: "Is blob soft-deleted",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isBlobSoftDeleted} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().isBlobHardDeleted.$path,
            title: "Is blob hard-deleted",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <BooleanValue value={item.isBlobHardDeleted} />,
            filters: {
              fieldType: FilterFieldType.Boolean,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().deletedAt.$path,
            title: "Deleted at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.deletedAt ? <Datetime datetime={item.deletedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().restoredAt.$path,
            title: "Restored at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.restoredAt ? <Datetime datetime={item.restoredAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().blobDeletedAt.$path,
            title: "Blob deleted at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.blobDeletedAt ? (
                <Datetime datetime={item.blobDeletedAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().blobSoftDeletedAt.$path,
            title: "Blob soft-deleted at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.blobSoftDeletedAt ? (
                <Datetime datetime={item.blobSoftDeletedAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().blobHardDeletedAt.$path,
            title: "Blob hard-deleted at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.blobHardDeletedAt ? (
                <Datetime datetime={item.blobHardDeletedAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().estBlobSoftDeleteAt.$path,
            title: "Est. blob soft-delete at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.estBlobSoftDeleteAt ? (
                <Datetime datetime={item.estBlobSoftDeleteAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().estBlobHardDeleteAt.$path,
            title: "Est. blob hard-delete at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.estBlobHardDeleteAt ? (
                <Datetime datetime={item.estBlobHardDeleteAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().uploadedAt.$path,
            title: "Uploaded at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.uploadedAt ? <Datetime datetime={item.uploadedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<AdminFileDto>().uploadedBy.$path,
            title: "Uploaded by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.uploadedBy ? <InlineUser userId={item.uploadedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AdminFileDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AdminFileDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AdminFileDto>().deletedBy.$path,
            title: "Deleted by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.deletedBy ? <InlineUser userId={item.deletedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<AdminFileDto>().restoredBy.$path,
            title: "Restored by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.restoredBy ? <InlineUser userId={item.restoredBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
        ]}
        rows={paginatedFiles?.items}
        isLoading={paginatedFilesRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.ADMIN_FILE_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <FileMenu
            entity={item}
            onDelete={() => paginatedFilesRequest.refetch()}
            onUpdate={(newValue) => {
              paginatedFilesRequest.updateData((data) => {
                data.items = data.items?.map((x) => (x.id === newValue.id ? newValue : x));
              });
            }}
            displayProps={{
              actions: {
                delete: displayProps?.delete || false,
                undelete: displayProps?.undelete || false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}
