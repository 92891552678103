import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import DamageCostEvaluationCreateUpdate from "@/common/components/Entity/DamageCostEvaluation/DamageCostEvaluationCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export interface DamageCostEvaluationCreateUpdateQueryParams extends GeneralQueryParams {
  vehicleId?: string | null;
  contractId?: string | null;
}

export default function DamageCostEvaluationCreateUpdatePage() {
  const { damageCostEvaluationId } = useParams<{ damageCostEvaluationId?: string }>();
  const { vehicleId, contractId } = useQueryParams<DamageCostEvaluationCreateUpdateQueryParams>();
  const isCreate = !damageCostEvaluationId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new damage cost evaluation" : "Edit damage cost evaluation"}
        />
      }
    >
      <DamageCostEvaluationCreateUpdate
        damageCostEvaluationId={damageCostEvaluationId}
        defaultValues={{
          vehicleId: vehicleId || undefined,
          contractId: contractId || undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
