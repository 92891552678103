import { Chip, ChipProps, SxProps, Theme, Typography, darken, useTheme } from "@mui/material";
import { useMemo } from "react";

import { ColorHelper } from "@/common/helpers/color";

export interface TagDisplayProps {
  label?: React.ReactNode;
  /** When set, color will be automatically picked up for the provided text. */
  pickColorForText?: string;
  /** Chip color. */
  color?: ChipProps["color"];
  backgroundColor?: string;
  disableHoverEffects?: boolean;
  onClick?: ChipProps["onClick"];
  onDelete?: ChipProps["onDelete"];
  onMouseEnter?: ChipProps["onMouseEnter"];
  onMouseLeave?: ChipProps["onMouseLeave"];
  sx?: SxProps<Theme>;
}

export default function TagDisplay({
  label,
  pickColorForText,
  color,
  backgroundColor,
  disableHoverEffects,
  onClick,
  onDelete,
  onMouseEnter,
  onMouseLeave,
  sx,
}: TagDisplayProps) {
  const theme = useTheme();

  const backgroundColorHsl = useMemo(() => {
    if (!backgroundColor) {
      return undefined;
    }
    const parsed = ColorHelper.parseCssColor(backgroundColor);
    if (!parsed.rgba) {
      return undefined;
    }
    return ColorHelper.rgbaToHsla(parsed.rgba);
  }, [backgroundColor, theme.palette.mode]);

  // pick bg and text color based on string value,
  // pick dark bg color for white text
  const isPickColorForText = !!pickColorForText && !backgroundColorHsl;
  const hueRange = { min: 30, max: 330 }; // from orange to pink (exclude red)
  const isThemeModeDark = theme.palette.mode === "dark";
  const saturation = isThemeModeDark ? 100 : 90;
  const bgLightness = 80;

  const bgColorHsl = useMemo(
    () =>
      backgroundColorHsl ||
      (isPickColorForText
        ? ColorHelper.stringToHslaColor(
            pickColorForText || "",
            { s: saturation, l: bgLightness },
            hueRange,
            isThemeModeDark ? 0.9 : 0.5,
          )
        : undefined),
    [
      saturation,
      bgLightness,
      theme.palette.mode,
      backgroundColorHsl,
      pickColorForText,
      isPickColorForText,
    ],
  );

  const hoverBgColorHsl = useMemo(
    () => (bgColorHsl ? ColorHelper.darkenHsla(bgColorHsl, 0.1) : undefined),
    [bgColorHsl],
  );

  // pick text color based on bg
  const textColorHsl = useMemo(
    () =>
      bgColorHsl ? ColorHelper.pickTextHslColorSimilarToBgHslColor(bgColorHsl, 60) : undefined,
    [pickColorForText, isPickColorForText, bgColorHsl, hoverBgColorHsl],
  );

  const hoverTextColor = useMemo(
    () =>
      isPickColorForText && hoverBgColorHsl
        ? ColorHelper.pickContrastTextHexColorForBgHslColor(hoverBgColorHsl)
        : undefined,
    [hoverBgColorHsl, isPickColorForText],
  );

  return (
    <Chip
      label={
        <Typography component='div' variant='body2'>
          {label}
        </Typography>
      }
      size='small'
      variant='filled'
      color={color}
      onClick={onClick}
      onDelete={onDelete}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        borderWidth: 1,
        borderStyle: "solid",
        // borderColor: (th) => th.palette.divider,
        border: "none",
        background: bgColorHsl?.hsla,
        color: textColorHsl?.hsl,
        // fontWeight: 500,
        "&:hover": disableHoverEffects
          ? {}
          : {
              background: hoverBgColorHsl?.hsla,
              color: hoverTextColor,

              "& .MuiChip-deleteIcon": {
                color: hoverTextColor,
              },
            },
        ...sx,
      }}
    />
  );
}
