import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import ColorPreviewBox from "@/common/components/Color/ColorPreviewBox";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  CustomTagDto,
  DataUpdatesHubClientMethodName,
  EntityType,
} from "@/core/api/generated";

import TagDisplay from "../../General/GeneralTag/TagDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import CustomTagDeleteModal from "../CustomTagDeleteModal";

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<CustomTagDto> {
  customTagId: string | null | undefined;
  customTag: CustomTagDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function CustomTagView({
  customTagId,
  customTag,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const customTagRequest = useApiRequest(
    apiClient.customTagsApi.apiV1TagsCustomCustomTagIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customTagId: customTagId!,
    },
    {
      deps: [customTagId],
      skip: !customTagId || !!customTag,
    },
  );
  customTag = customTag || customTagRequest?.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.CustomTag, customTag?.id || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      customTagRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        customTag && {
          idValue: customTag.id!,
          newTitle: customTag.name || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.CustomTag}
      entityId={customTagId}
      entity={customTag}
      entityRequest={customTagRequest}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Custom tag ${customTag?.name}`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.CUSTOM_TAG_VIEW(customTag?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.CustomTagManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.CUSTOM_TAG_EDIT(customTag?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                customTag && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    <AuthorizedMenuItem
                      permissions={[AppPermission.CustomTagManage]}
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </AuthorizedMenuItem>
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <Stack spacing={2}>
          {customTag && (
            <Stack spacing={2}>
              <Stack spacing={1}>
                <FieldValue label='Name' isEmpty={!customTag?.name}>
                  {customTag?.name}
                </FieldValue>

                <FieldValue label='Description' isEmpty={!customTag?.description}>
                  {customTag?.description}
                </FieldValue>
              </Stack>

              {/* Styles */}
              <FoldableBlock
                defaultIsFolded={false}
                trigger={{
                  label: <Typography variant='h6'>Styles</Typography>,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xxs: "1fr", md: "1fr 1fr" },
                    gap: 2,
                  }}
                >
                  <Box>
                    <Stack spacing={1}>
                      <FieldValue
                        label='Background color'
                        isEmpty={!customTag?.styles?.backgroundColor}
                      >
                        <ColorPreviewBox
                          color={customTag?.styles?.backgroundColor || undefined}
                          size={40}
                        />
                      </FieldValue>
                    </Stack>
                  </Box>

                  <Box>
                    <Stack spacing={1}>
                      <Typography variant='subtitle1'>Preview:</Typography>

                      <Stack direction='row' spacing={1}>
                        <TagDisplay
                          label='Test label'
                          backgroundColor={customTag?.styles?.backgroundColor || undefined}
                        />

                        <TagDisplay
                          label='Test label'
                          backgroundColor={customTag?.styles?.backgroundColor || undefined}
                          onDelete={() => {}}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </FoldableBlock>
            </Stack>
          )}

          {customTag && (
            <CustomTagDeleteModal
              entity={customTag}
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={() => history.goBack()}
            />
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
