import moment, { Duration } from "moment";

import { teslaConfig } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import { TeslaAuthorizationCodeExchangeResponseDto } from "@/core/api/generated/v0.1-demo";

import { LocalStorageKey } from "../constants/localStorage";
import { IdHelper } from "../helpers/id";
import { LocalStorageHelper } from "../helpers/localStorage";
import { UrlHelper } from "../helpers/url";
import { CustomAuth0AppState } from "./auth";
import { tenantService } from "./tenant";

/** Auth endpoint params for https://auth.tesla.com/oauth2/v3/authorize.
 *  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
 */
export interface TeslaAuthAuthorizeQueryParams {
  response_type: "code";
  prompt: "login";
  /** Partner application client ID. */
  client_id: string;
  /** Partner application callback url. */
  redirect_uri: string;
  /** Space delimited list of scopes, include openid and offline_access to obtain a refresh token.
   *  Doc - https://developer.tesla.com/docs/fleet-api/authentication/overview#scopes.
   */
  scope: string;
  /** Random value used for validation.
   *  The allowed length for state is not unlimited. If you get the error 414 Request-URI Too Large, try a smaller value.
   */
  state: string;
  /** Random value used for replay prevention. */
  nonce?: string;
  locale?: string;
}

/** Custom implementation similar to app state in Auth0.
 *  (they match random nonce/state in the request URL to app state object stored locally (in cookies, session, or local storage)).
 *  Docs - https://auth0.com/docs/secure/attack-protection/state-parameters.
 */
export interface CustomTeslaAuth0AppState extends CustomAuth0AppState {}

export interface TeslaAuthRedirectLoginResult {
  codeExchangeResult: TeslaAuthorizationCodeExchangeResponseDto;
  /** State stored when the redirect request was made. */
  appState?: CustomTeslaAuth0AppState;
}

export interface TeslaAuthInLocalStorage {
  /** Requests to /oauth2/v3/authorize endpoint.
   * Use array of requests to handle multiple browser tabs or user clicking auth button many times.
   * */
  authorizeRequests?: TeslaAuthAuthorizeRequestInLocalStorage[];
}

export interface TeslaAuthAuthorizeRequestInLocalStorage {
  id: string;
  createdAt: string;
  expiresAt: string;
  requestParams: TeslaAuthAuthorizeQueryParams;
  appState?: CustomTeslaAuth0AppState | null | undefined;
}

export class TeslaService {
  private _logPrefix = "TeslaService.";

  constructor() {
    // super();
  }

  //#region Auth

  private _authRequestDuration: Duration = moment.duration(1, "hour");

  /** Login as Tesla user via authorization_code grant flow to generate a token on behalf of a user.
   *  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
   */
  public async loginAsUserWithRedirect(options?: {
    appState?: CustomTeslaAuth0AppState;
  }): Promise<void> {
    // build auth URL where to redirect the user
    const authUrl = teslaConfig.authUrl;
    const endpointPath = "/oauth2/v3/authorize";
    let url = UrlHelper.addUrlPathname(authUrl, endpointPath);
    const scopes = [
      "openid",
      "offline_access",
      "user_data",
      "vehicle_device_data",
      "vehicle_cmds",
      "vehicle_charging_cmds",
    ];
    const queryParams: TeslaAuthAuthorizeQueryParams = {
      response_type: "code",
      prompt: "login",
      client_id: teslaConfig.partnerApplicationClientId,
      redirect_uri: teslaConfig.partnerApplicationCallbackUrl,
      scope: scopes.join(" "),
      state: IdHelper.newUuid4(),
      nonce: IdHelper.newUuid4(),
      locale: "en-US",
      // TODO: add custom appState like in Auth0 to restore URI, etc
    };
    url = UrlHelper.updateUrlSearchParams(url, { ...queryParams });

    // build app state
    const tenantInfo = tenantService.resolveTenant();
    const appState: CustomTeslaAuth0AppState = {
      ...(options?.appState || {}),
      // save tenant identifier that was resolved before redirect, so we can restore it after login.
      tenantIdentifier: options?.appState?.tenantIdentifier || tenantInfo?.identifier,
    };

    this.persistAuthRequest({
      requestParams: queryParams,
      appState: appState,
    });

    console.log(this._logPrefix, "Redirecting to Tesla Auth url:", url, appState);
    UrlHelper.redirectToUrl(url);
  }

  /** Handles redirect callback for login via authorization_code grant flow.
   *  Docs - https://developer.tesla.com/docs/fleet-api/authentication/third-party-tokens.
   */
  public async handleAuthRedirectCallback(): Promise<TeslaAuthRedirectLoginResult> {
    // extract the code URL parameter
    const url = UrlHelper.getCurrentUrl();
    const queryParams = UrlHelper.getUrlSearchParamsTyped<{
      state?: string;
      nonce?: string;
      code?: string;
    }>(url);
    queryParams.state ||= undefined;
    queryParams.nonce ||= undefined;
    console.log(this._logPrefix, "handleAuthRedirectCallback.", url, { queryParams });
    if (!queryParams.state) {
      throw new Error("No state found in auth redirect callback.");
    }
    if (!queryParams.code) {
      throw new Error("No code found in auth redirect callback.");
    }

    const authorizeRequest = this.getPersistedAuthRequest({
      state: queryParams.state,
      nonce: queryParams.nonce,
    });
    if (!authorizeRequest) {
      throw new Error("No authorize request found for the auth redirect callback.");
    }

    // validate the state and nonce to prevent CSRF attacks
    if (queryParams.state !== authorizeRequest.requestParams.state) {
      throw new Error(
        "State in the auth request doesn't match with the state in the auth redirect callback. Possible CSRF attack!",
      );
    }
    // NB: nonce might be missing for some reason, so skip if empty even if we sent it
    if (queryParams.nonce && queryParams.nonce !== authorizeRequest.requestParams.nonce) {
      throw new Error(
        "Nonce in the auth request doesn't match with the nonce in the auth redirect callback. Possible CSRF attack!",
      );
    }

    // execute a code exchange call to generate an access token
    console.log(this._logPrefix, "Request code exchange.", { authorizationCode: queryParams.code });
    const codeExchangeResponse =
      await apiClient.teslaAuthApi.apiV01DemoProvidersTeslaAuthCodeExchangeRequestPost({
        nexusOpsTenant: authorizeRequest.appState?.tenantIdentifier || EMPTY_TENANT_IDENTIFIER,
        teslaAuthAuthorizationCodeExchangeRequestDto: {
          scope: authorizeRequest.requestParams.scope,
          redirectUri: authorizeRequest.requestParams.redirect_uri,
          authorizationCode: queryParams.code,
        },
      });
    const codeExchangeResult = codeExchangeResponse.data;
    if (codeExchangeResponse.status !== 200) {
      console.error(
        this._logPrefix,
        "Authorization code exchange failed.",
        "Response:",
        codeExchangeResponse,
        codeExchangeResponse.data,
      );
      throw new Error("Authorization code exchange failed.");
    }
    console.log(this._logPrefix, "Authorization code exchange result:", codeExchangeResult);

    this.deletePersistedAuthRequest(authorizeRequest);

    return {
      codeExchangeResult: codeExchangeResult,
      appState: authorizeRequest.appState || undefined,
    };
  }

  public persistAuthRequest(params: {
    requestParams: TeslaAuthAuthorizeQueryParams;
    appState?: CustomTeslaAuth0AppState | null | undefined;
  }): void {
    const persisted =
      LocalStorageHelper.getTypedJsonItem<TeslaAuthInLocalStorage>(LocalStorageKey.teslaAuth) || {};

    // check exists
    let authorizeRequest =
      persisted?.authorizeRequests?.find(
        (x) =>
          x.requestParams.state === params.requestParams.state ||
          x.requestParams.nonce === params.requestParams.nonce,
      ) || null;
    if (authorizeRequest) {
      return;
    }

    authorizeRequest = {
      id: IdHelper.newUuid4(),
      createdAt: moment().utc().format(),
      expiresAt: moment().utc().add(this._authRequestDuration).format(),
      requestParams: params.requestParams,
      appState: params.appState,
    };

    persisted.authorizeRequests ||= [];
    persisted.authorizeRequests.push(authorizeRequest);

    // remove expired requests
    persisted.authorizeRequests = persisted.authorizeRequests.filter((x) =>
      moment.utc().isBefore(moment.utc(x.expiresAt)),
    );

    LocalStorageHelper.setJsonItem(LocalStorageKey.teslaAuth, persisted);
  }

  public getPersistedAuthRequest(params: {
    state: string;
    nonce?: string;
  }): TeslaAuthAuthorizeRequestInLocalStorage | null {
    const persisted = LocalStorageHelper.getTypedJsonItem<TeslaAuthInLocalStorage>(
      LocalStorageKey.teslaAuth,
    );
    const authorizeRequest =
      persisted?.authorizeRequests?.find(
        (x) => x.requestParams.state === params.state || x.requestParams.nonce === params.nonce,
      ) || null;
    if (!persisted || !authorizeRequest) {
      return null;
    }

    // discard expired requests
    const isExpired = moment.utc().isAfter(moment.utc(authorizeRequest?.expiresAt));
    if (isExpired) {
      persisted.authorizeRequests = persisted.authorizeRequests?.filter(
        (x) => x.requestParams.state !== params.state && x.requestParams.nonce !== params.nonce,
      );
      LocalStorageHelper.setJsonItem(LocalStorageKey.teslaAuth, persisted);
      return null;
    }

    return authorizeRequest;
  }

  public deletePersistedAuthRequest(
    authorizeRequest: TeslaAuthAuthorizeRequestInLocalStorage,
  ): void {
    const persisted =
      LocalStorageHelper.getTypedJsonItem<TeslaAuthInLocalStorage>(LocalStorageKey.teslaAuth) || {};
    persisted.authorizeRequests = persisted.authorizeRequests?.filter(
      (x) => x.id !== authorizeRequest.id,
    );
    LocalStorageHelper.setJsonItem(LocalStorageKey.teslaAuth, persisted);
  }

  //#endregion Auth
}

export const teslaService = new TeslaService();
