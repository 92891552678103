import { Box, StackProps } from "@mui/material";

interface Props extends StackProps {}

const FormActions = ({ children }: Props) => {
  return (
    <Box
      sx={{
        py: 1,
        display: "grid",
        gridTemplateColumns: {
          xxs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
        gap: 1,
        direction: "rtl",
        "& > *": {
          direction: "ltr",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default FormActions;
