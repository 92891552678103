import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/** useEffect that runs one time if condition is true. */
export const useEffectOnceIf = (
  condition: boolean,
  effect: EffectCallback,
  deps?: DependencyList,
): void => {
  const isRunRef = useRef(false);

  useEffect(() => {
    if (condition && !isRunRef.current) {
      isRunRef.current = true;
      effect();
    }
  }, [condition, ...(deps || [])]);
};
