import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationProposalReferenceDto,
  NegotiationReferenceDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";

export interface NegotiationInlineProps extends BaseEntityInlineInheritableProps {
  entity: NegotiationDto | NegotiationReferenceDto | null | undefined;
  entityId?: string | null;
  proposal?: NegotiationProposalDto | NegotiationProposalReferenceDto;
  proposalId?: string | null;
}

export default function NegotiationInline({
  entity,
  entityId,
  proposal,
  proposalId,
  ...otherProps
}: NegotiationInlineProps) {
  const request = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      negotiationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as NegotiationDto;
  const ref = entity as NegotiationReferenceDto;

  proposal =
    proposal || (proposalId ? full?.proposals?.find((x) => x.id === proposalId) : undefined);

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='negotiation' inText />}
      content={
        <>
          {full?.name || entity?.localNumber}
          {proposal && <> (#{proposal.id})</>}
        </>
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Negotiation"}
    />
  );
}
