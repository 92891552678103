import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AssetSpecDisplay from "@/common/components/Entity/Asset/AssetSpecDisplay";
import { AssetDto } from "@/core/api/generated";

interface Props {
  asset: AssetDto;
}

export default function SpecTabContent({ asset }: Props) {
  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Spec"} />}
      >
        <ViewContentBlock>
          {!asset?.spec && <NoDataAlert />}

          <AssetSpecDisplay spec={asset?.spec} />
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
