import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import RepairMaterialView from "@/common/components/Entity/RepairMaterial/View/RepairMaterialView";

export default function RepairMaterialViewPage() {
  const { repairMaterialId } = useParams<{ repairMaterialId?: string }>();

  return (
    <>
      <RepairMaterialView
        repairMaterialId={repairMaterialId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        displayProps={{
          breadcrumbs: true,
        }}
      />
    </>
  );
}
