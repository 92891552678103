import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Fade,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { RoleDto } from "@/core/api/generated";

import BlockApiEnumValue from "../../Enum/BlockApiEnumValue";

interface Props {
  role: RoleDto;
  withDetailsToggle?: boolean;
  isDetailsVisible?: boolean;
}

/** Displays role dto. */
export default function RoleListItem({
  role,
  withDetailsToggle = true,
  isDetailsVisible = false,
}: Props) {
  const [_isDetailsVisible, setIsDetailsVisible] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isDetailsVisible !== undefined) {
      setIsDetailsVisible(isDetailsVisible);
    }
  }, [isDetailsVisible]);

  return (
    <Card>
      {/* <CardActionArea
        component={RouterLink}
        to={ROUTE_PATH.TENANT_ROLE_VIEW}
        onClick={(e: any) => isMenuOpen && e.preventDefault()}
      > */}
      <CardHeader
        title={
          <Typography component='div' variant='subtitle1'>
            <span>{role.name}</span>
            {role.isBuiltIn && (
              <Tooltip title="Built-in roles are defined by the system and can't be changed.">
                <Chip
                  size='small'
                  variant='outlined'
                  color='primary'
                  label='Built-in'
                  sx={{ ml: 1 }}
                />
              </Tooltip>
            )}
          </Typography>
        }
        subheader={role.description}
        action={
          <>
            {withDetailsToggle && (
              <Tooltip title='View details'>
                <IconButton
                  onClick={() => setIsDetailsVisible(!_isDetailsVisible)}
                  sx={{ ml: "auto" }}
                >
                  {!_isDetailsVisible && <AppIcon of='expandMore' fontSize='medium' />}
                  {_isDetailsVisible && <AppIcon of='expandLess' fontSize='medium' />}
                </IconButton>
              </Tooltip>
            )}

            {/* <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
              {!role.isBuiltIn && (
                <MenuItem
                  component={RouterLink}
                  to={}
                >
                  Edit
                </MenuItem>
              )}
              {!role.isBuiltIn && (
                <MenuItem onClick={() => setIsDeleteModalOpen(true)}>Delete</MenuItem>
              )}
            </Menu> */}
          </>
        }
      />

      {/* Detailed view */}
      {_isDetailsVisible && (
        <Fade in={_isDetailsVisible}>
          <CardContent sx={{ pt: 0 }}>
            <Typography component='div' variant='subtitle2' sx={{ mb: 1 }}>
              Role permissions
            </Typography>
            <Stack direction='column' spacing={1}>
              {role.permissions!.map((perm, i) => (
                <Box key={i}>
                  <BlockApiEnumValue
                    type='AppPermission'
                    value={perm}
                    direction='row'
                    withValue
                    withName
                    withDescription
                  />
                </Box>
              ))}
            </Stack>
          </CardContent>
        </Fade>
      )}
      {/* </CardActionArea> */}
    </Card>
  );
}
