import { useEffect } from "react";

import { ProfileDto } from "@/core/api/generated";
import * as profileSlice from "@/store/profile/slice";

import { useAppSelector, useAppThunkDispatch } from "./redux";

/** Returns currently authenticated user profile (loads it if not loaded). */
export const useUserProfile = (): ProfileDto | undefined => {
  const dispatch = useAppThunkDispatch();
  const profile = useAppSelector((x) => x.profile.profile);
  const isAuthenticated = useAppSelector((x) => x.auth.isAuthenticated);

  useEffect(() => {
    if (!profile && isAuthenticated) {
      dispatch(profileSlice.getProfileThrottle());
    }
  }, [profile, isAuthenticated]);

  return profile;
};
