import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { PasswordPolicyDto, PasswordValidateResultDto } from "@/core/api/generated";

function PolicyRenderer({ isValid = false, text = "" }) {
  return (
    <Typography
      component='div'
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      color={isValid ? "success.main" : "error.main"}
    >
      {text}
    </Typography>
  );
}

interface PasswordValidationHintProps {
  passwordPolicy?: PasswordPolicyDto;
  passwordValidationResult?: PasswordValidateResultDto;
}

export default function PasswordValidationHint({
  passwordPolicy,
  passwordValidationResult,
}: PasswordValidationHintProps) {
  const {
    minLength: minLengthPolicy,
    maxLength: maxLengthPolicy,
    allowedSpecialChars,
  } = passwordPolicy || {};

  const { minLength, maxLength, withNumbers, withUpperCase, withLowerCase, withSpecialChars } =
    passwordValidationResult || {
      minLength: false,
      maxLength: false,
      withNumbers: false,
      withUpperCase: false,
      withLowerCase: false,
      withSpecialChars: false,
    };

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: { xs: 40, md: -70 },
        left: { xs: "unset", md: "180%" },
        right: { xs: -12, md: "unset" },
        p: 2,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
        borderRadius: "14px",
        zIndex: 1,
        boxShadow: (theme) => (theme.shadows as string[])[5],
      }}
    >
      <PolicyRenderer text={`Min length(${minLengthPolicy}): ${minLength}`} isValid={minLength} />

      <PolicyRenderer text={`Max length(${maxLengthPolicy}): ${maxLength}`} isValid={maxLength} />

      <PolicyRenderer text={`Numbers: ${withNumbers}`} isValid={withNumbers} />

      <PolicyRenderer text={`Upper case: ${withUpperCase}`} isValid={withUpperCase} />

      <PolicyRenderer text={`Lower case: ${withLowerCase}`} isValid={withLowerCase} />

      <PolicyRenderer
        text={`Special symbols(${allowedSpecialChars}): ${withSpecialChars}`}
        isValid={withSpecialChars}
      />
    </Box>
  );
}
