import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  CustomTagDto,
  CustomTagFullReferenceDto,
  CustomTagReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import CustomTagInline, { CustomTagInlineProps } from "./CustomTagInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: CustomTagDto | CustomTagFullReferenceDto | CustomTagReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<CustomTagInlineProps>;
}

export default function CustomTagLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.CustomTagRead])}
      to={ROUTE_PATH.CUSTOM_TAG_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <CustomTagInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Custom tag"}
    />
  );
}
