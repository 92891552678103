import { Tooltip, TooltipProps } from "@mui/material";

export interface TooltipFacadeProps extends TooltipProps {
  /** If true - children are wrapped, false - children are rendered directly. */
  enabled?: boolean;
  children: TooltipProps["children"];
}

/** @deprecated Use AppTooltip */
export default function TooltipFacade({
  enabled = true,
  children,
  ...originalProps
}: TooltipFacadeProps) {
  if (!enabled) {
    return children ? <>{children}</> : null;
  }
  return <Tooltip {...originalProps}>{children}</Tooltip>;
}
