import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import TenantConnectionPaginatedList from "@/common/components/Entity/TenantConnection/ListView/TenantConnectionPaginatedList";

interface Props {}

export default function ConnectionsTabContent({}: Props) {
  return (
    <PageTabContent>
      <ViewLayout displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <Stack direction='column' spacing={2}>
          <TenantConnectionPaginatedList
            displayProps={{
              breadcrumbs: true,
              filters: true,
              viewVariant: ViewLayoutVariant.Tab,
            }}
          />
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
