import { BooleanStatusDisplay } from "@/common/components/StatusDisplay/BooleanStatusDisplay";
import {
  GeneralStatusMetaTypedDtoOfUtilizationStatus,
  IBaseEntityDto,
  UtilizationStatus,
} from "@/core/api/generated";

import GeneralStatusEnumValueInline from "./GeneralStatusEnumValueInline";
import GeneralStatusMetaDisplay, {
  GeneralStatusMetaDisplayProps,
} from "./GeneralStatusMetaDisplay";

interface Props {
  value: GeneralStatusMetaTypedDtoOfUtilizationStatus | undefined | null;
  subjectEntity: IBaseEntityDto | undefined;
  statusDisplayProps?: Partial<GeneralStatusMetaDisplayProps>;
  onMetaUpdated: (newValue: GeneralStatusMetaTypedDtoOfUtilizationStatus) => void;
}

export default function GeneralStatusMetaDisplayOfUtilizationStatus({
  value,
  subjectEntity,
  statusDisplayProps,
  onMetaUpdated,
}: Props) {
  if (!value) {
    return null;
  }

  return (
    <GeneralStatusMetaDisplay
      value={value}
      subjectEntity={subjectEntity}
      mainContent={
        <BooleanStatusDisplay
          value={value.statusTyped === UtilizationStatus.Utilized}
          statusProps={{
            variant: "default",
            title: (
              <GeneralStatusEnumValueInline
                statusEnumTypeName={value.statusEnumTypeName}
                status={value.status}
              />
            ),
          }}
        />
      }
      onMetaUpdated={(newValue) => {
        onMetaUpdated({ ...value, ...newValue });
      }}
      {...statusDisplayProps}
    />
  );
}
