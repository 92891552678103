import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  ContractSpotInfoDto,
  ContractSpotInfoInputDto,
  SpotType,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import ContractSpotInfoInput from "./ContractSpotInfoInput";

export interface ContractUpdateSpotInfoOwnProps {
  contract: ContractDto;
  spotInfo: ContractSpotInfoDto | null | undefined;
  spotInfoType: "CheckOut" | "CheckIn";
  onSave?: (newValue: ContractDto) => void;
}

export type ContractUpdateSpotInfoProps = ContractUpdateSpotInfoOwnProps;

export default function ContractUpdateSpotInfo({
  contract,
  spotInfo,
  spotInfoType,
  onSave,
}: ContractUpdateSpotInfoProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  return (
    <Stack spacing={2}>
      <Formik<BaseFormikValues & ContractSpotInfoInputDto>
        enableReinitialize
        initialValues={{
          ...spotInfo,
          spotId: spotInfo?.spot?.id,
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            date: values.date && moment(values.date).utc().format(),
          };

          try {
            const response =
              (spotInfoType === "CheckOut"
                ? await apiClient.contractsApi.apiV1ContractsContractIdVehicleCheckOutSpotInfoPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    contractId: contract?.id || "",
                    contractSpotInfoInputDto: {
                      ...values2,
                    },
                  })
                : undefined) ||
              (spotInfoType === "CheckIn"
                ? await apiClient.contractsApi.apiV1ContractsContractIdVehicleCheckInSpotInfoPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    contractId: contract?.id || "",
                    contractSpotInfoInputDto: {
                      ...values2,
                    },
                  })
                : undefined) ||
              undefined;
            if (!response) {
              throw new Error(`${spotInfoType} is not supported.`);
            }
            enqueueSnackbar("Saved.", { variant: "success" });
            onSave && onSave(response.data);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          } = formikProps;

          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <ContractSpotInfoInput
                    contractId={contract.id}
                    spotInfo={spotInfo}
                    formikProps={formikProps}
                    spotType={SpotType.CheckOut}
                  />
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
}
