import _ from "lodash";

export class NumberHelper {
  /**
   * Scales number x from its old range to a new range.
   * E.g. x=5 in [0, 10], scale to [0, 100] -> x=50.
   * Scale formula: https://stats.stackexchange.com/questions/281162/scale-a-number-between-a-range
   */
  public static scaleNumberIntoRange(
    x: number,
    oldRange: { min: number; max: number },
    newRange: { min: number; max: number },
  ): number {
    if (oldRange.min > oldRange.max) {
      throw new Error(`Invalid oldRange provided.`);
    }
    if (newRange.min > newRange.max) {
      throw new Error(`Invalid newRange provided.`);
    }
    if (x < oldRange.min || x > oldRange.max) {
      throw new Error(`x=${x} must be in range [${oldRange.min}, ${oldRange.max}].`);
    }
    const x2 =
      ((x - oldRange.min) / (oldRange.max - oldRange.min)) * (newRange.max - newRange.min) +
      newRange.min;
    return x2;
  }

  /** Normalizes provided percent value to [0; 1] range, if not already normalized.
   *  E.g, 85.76 -> 0.8576, -1 -> -1, 101 -> 101.
   */
  public static normalizePercentToZeroOne(percent: number, precision = 2): number {
    return _.round(percent / 100, precision);
  }

  /** Normalizes provided percent value to [0; 100] range, if not already normalized.
   *  E.g, 0.8576 -> 85.76, 0.1 -> 10, -1 -> -1, 101 -> 101
   */
  public static normalizePercentToZeroHundred(percent: number, precision = 2): number {
    return _.round(percent * 100, precision);
  }
}
