import { Box } from "@mui/material";

import { VehiclePriceDto } from "@/core/api/generated";

import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import TotalPriceExplanation from "../../PriceSummary/TotalPriceExplanation";
import AppTypography from "../../Text/AppTypography";
import GeneralPriceDisplay from "../General/GeneralPrice/GeneralPriceDisplay";

interface Props {
  price: VehiclePriceDto | null | undefined;
  fallbackPrice?: VehiclePriceDto | null | undefined;
  withDetailsPopover?: boolean;
}

export default function VehiclePriceDisplay({
  price,
  fallbackPrice,
  withDetailsPopover = true,
}: Props) {
  const priceComputed = price || fallbackPrice;

  if (!price) {
    return null;
  }
  return (
    <Box>
      <AppPopover
        enabled={withDetailsPopover}
        hoverBehavior={{}}
        trigger={
          <AppTypography decoration={{ variant: "helpText" }}>
            <GeneralPriceDisplay
              price={{
                price: priceComputed?.price ?? undefined,
                currency: priceComputed?.currency,
              }}
            />
          </AppTypography>
        }
      >
        <AppPopoverContent>
          <TotalPriceExplanation
            summary={{
              currency: priceComputed?.currency,
              subTotal: priceComputed?.price,
              discount: priceComputed?.discount,
              tax: priceComputed?.tax,
              total: priceComputed?.total,
            }}
          />
        </AppPopoverContent>
      </AppPopover>
    </Box>
  );
}
