import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralStatusHistoryDto } from "@/core/api/generated";

import BaseEntityInline, {
  BaseEntityInlineInheritableProps,
} from "../../components/BaseEntityInline";

export interface GeneralStatusHistoryInlineProps extends BaseEntityInlineInheritableProps {
  entity: GeneralStatusHistoryDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function GeneralStatusHistoryInline({
  entity,
  entityId,
  ...otherProps
}: GeneralStatusHistoryInlineProps) {
  const request = useApiRequest(
    apiClient.generalStatusHistoryApi.apiV1GeneralStatusHistoryGeneralStatusHistoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalStatusHistoryId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as GeneralStatusHistoryDto;

  return (
    <BaseEntityInline
      entity={entity}
      icon={<AppIcon of='history' inText />}
      content={<>{full?.id}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Status history record"}
    />
  );
}
