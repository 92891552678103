import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import FileItemView from "@/common/components/Files/FileItemView";
import FullScreenFileViewerV2 from "@/common/components/Images/FullScreenFileViewerV2";
import { FileItem } from "@/common/fileItem";
import { ChatMessageDto } from "@/core/api/generated";

interface Props {
  message: ChatMessageDto;
}

function ChatMessageAttachments({ message }: Props) {
  const [isFolded, setIsFolded] = useState(false);

  // FullScreen
  const [fsFiles, setFsFiles] = useState<FileItem[]>([]);
  const [fsFileId, setFsFileId] = useState<string | undefined>(undefined);

  const files = useMemo(
    () => FileItem.createManyFrom(message.attachments || []),
    [message.attachments],
  );

  const handleFileClick = useCallback(
    (file: FileItem) => {
      setFsFiles(files);
      setFsFileId(file.id);
    },
    [files],
  );

  if (!message.attachments || message.attachments.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography component='div' variant='caption' color='text.secondary'>
        {message.attachments.length} {message.attachments.length > 1 ? "attachments" : "attachment"}
        <IconButton sx={{ p: 0, ml: 1 }} onClick={() => setIsFolded(!isFolded)}>
          {isFolded ? <ArrowRightIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
        </IconButton>
      </Typography>
      <Fade in={!isFolded} unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {files.map((f, i) => (
            <Box
              key={i}
              sx={{
                mt: 1,
                mr: 1,
              }}
            >
              <FileItemView
                fileItem={f}
                size={{
                  width: 120,
                }}
                useThumbnail
                actions={{
                  enabled: true,
                  click: true,
                  download: true,
                  openInNewTab: false,
                  onClick: () => handleFileClick(f),
                }}
                imageSx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Box>
      </Fade>
      {/* Fullscreen file viewer */}
      <FullScreenFileViewerV2
        files={fsFiles}
        selectedFileId={fsFileId}
        onSelectFile={(file) => {
          setFsFileId(file.id);
        }}
        onClose={() => {
          setFsFiles([]);
          setFsFileId(undefined);
        }}
      />
    </Box>
  );
}

export default ChatMessageAttachments;
