import { useCallback, useState } from "react";

/** Triggers re-render on demand in imperative style. */
export const useTriggerRender = (): {
  triggerRender: () => void;
} => {
  const [state, setState] = useState({});

  const triggerRender = useCallback(() => setState({}), []);

  return {
    triggerRender,
  };
};
