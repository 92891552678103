import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { CountryIso2 } from "react-international-phone";

import { GeneralPlateNoDto, GeneralPlateNoInputDto } from "@/core/api/generated";

import CountrySelectInputAdornment from "./InputAdornment/CountrySelectInputAdornment";

interface PlateNoInputProps
  extends Omit<TextFieldProps, "value" | "onChange" | "inputRef" | "InputProps"> {
  value: GeneralPlateNoInputDto | GeneralPlateNoDto | null | undefined;
  defaultCountryCode?: CountryIso2 | null;
  allowCountryCodeChange?: boolean;
  onChange?: (newValue: GeneralPlateNoInputDto | null | undefined) => void;
}

export default function PlateNoInput({
  value,
  defaultCountryCode = "NO",
  allowCountryCodeChange = true,
  onChange,
  ...props
}: PlateNoInputProps) {
  const changePlateNo = useCallback(
    (newValue: string | null | undefined) =>
      onChange && onChange({ ...value, plateNo: newValue || undefined }),
    [value, onChange],
  );
  const changeCountryCode = useCallback(
    (newValue: string | null | undefined) =>
      onChange && onChange({ ...value, countryCode: newValue || undefined }),
    [value, onChange],
  );

  return (
    <TextField
      {...props}
      value={value?.plateNo || ""}
      onChange={(e) => {
        changePlateNo(e.target.value.toUpperCase());
      }}
      InputProps={{
        // sx: {
        //   "& .MuiOutlinedInput-notchedOutline": {
        //     top: 0,
        //     "& legend": { display: "none" },
        //   },
        // },
        startAdornment: (
          <InputAdornment position='start'>
            <CountrySelectInputAdornment
              value={value ? { alpha2Code: value.countryCode?.toUpperCase() } : undefined}
              defaultCountryCode={defaultCountryCode}
              dropdownIconButtonProps={{
                disabled: props.disabled || !allowCountryCodeChange,
                size: "medium",
              }}
              onChange={(newValue) => {
                changeCountryCode(newValue?.alpha2Code?.toUpperCase());
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
