import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationProposalReferenceDto,
  NegotiationReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import NegotiationInline, { NegotiationInlineProps } from "./NegotiationInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: NegotiationDto | NegotiationReferenceDto | null | undefined;
  entityId?: string | null;
  proposal?: NegotiationProposalDto | NegotiationProposalReferenceDto;
  proposalId?: string | null;
  inlineProps?: Partial<NegotiationInlineProps>;
}

export default function NegotiationLink({
  entity,
  entityId,
  proposal,
  proposalId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.NegotiationRead])}
      to={ROUTE_PATH.NEGOTIATION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <NegotiationInline
          entity={entity}
          entityId={entityId}
          proposal={proposal}
          proposalId={proposalId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Negotiation"}
    />
  );
}
