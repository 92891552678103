import { typedPath } from "typed-path";

/**
 * Typed path refers to retrieving string path to object key safely by utilizing TS.
 * E.g.
 * (x => x.a.b.c) => "a.b.c"
 * (x => x.a.b.c[0].d[5]) => "a.b.c[0].d[5]"
 * Packages:
 * https://www.npmjs.com/package/typed-path
 * https://www.npmjs.com/package/property-string-path
 */

/** Returns value that helps to resolve typed path. */
export const getTypedPath = typedPath;

export class TypedPathHelper {
  /** Returns value that helps to resolve typed path. */
  public static getPath<TObj extends object>() {
    return typedPath<TObj>();
  }
}
