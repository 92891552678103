import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { ProductLocationDto, ProductLocationReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getProductLocationTitle } from "./ProductLocationAutocomplete";

export interface ProductLocationInlineProps extends BaseEntityInlineInheritableProps {
  entity: ProductLocationDto | ProductLocationReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function ProductLocationInline({
  entity,
  entityId,
  ...otherProps
}: ProductLocationInlineProps) {
  const request = useApiRequest(
    apiClient.productLocationsApi.apiV1ProductLocationsProductLocationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      productLocationId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as ProductLocationDto;
  const ref = entity as ProductLocationReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='productLocation' inText />}
      content={<>{full && getProductLocationTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Sales location"}
    />
  );
}
