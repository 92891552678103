import {
  CSSObject,
  CircularProgress,
  IconButton,
  IconButtonProps,
  styled,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { forwardRef, useMemo, useState } from "react";

import AppTooltip, { AppTooltipProps } from "../AppTooltip";

export interface AppIconButtonProps extends IconButtonProps {
  /**
   * default - default MUI IconButton styles
   * text - to display in text, size is adjusted to inherited font-size (size props is ignored)
   * contained - the same as MUI Button contained
   * outlined - the same as MUI Button outlined
   */
  variant?: "default" | "text" | "contained" | "outlined";
  shape?: "round" | "square";
  loading?: boolean;
  active?: boolean;
  tooltipProps?: Omit<AppTooltipProps, "children">;
  /** Whether to auto-track loading state based on Promise returned by onClick.
   * @default true
   */
  isAutoTrackLoading?: boolean;
  /** If Promise returned, loading is auto-set to true. */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}

const IconButtonStyled = styled(IconButton)<AppIconButtonProps>(({ theme, shape }) => ({
  // fix ripple effect to be squere not circle
  "& .MuiTouchRipple-root": {
    "& .MuiTouchRipple-ripple": {
      "& .MuiTouchRipple-child": {
        borderRadius:
          shape === "square"
            ? theme.shapeCustom.borderRadiusSquareAppIconButton
            : theme.shapeCustom.borderRadiusRoundAppIconButton,
      },
    },
  },
}));

/** Extends MUI IconButton. */
export default forwardRef<HTMLButtonElement, AppIconButtonProps>(function AppIconButton(
  {
    variant = "default",
    loading,
    size = "medium",
    shape = "square",
    tooltipProps,
    sx,
    children,
    isAutoTrackLoading = true,
    onClick,
    ...otherProps
  }: AppIconButtonProps,
  ref,
) {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const isLoadingComputed = loading ?? isLoading;

  // compute loader icon size from IconButton icon size or fallback
  // (works only if theme explicitly defines components.MuiIconButton.variants for all sizes)
  const circularProgressSize = useMemo(() => {
    if (variant === "text") {
      return 16;
    }

    const variantInTheme = theme.components?.MuiIconButton?.variants?.find((x) =>
      !_.isFunction(x.props) ? x.props.size === size : false,
    );
    const styleComputed = _.isFunction(variantInTheme?.style)
      ? variantInTheme?.style({ theme })
      : variantInTheme?.style;
    const styleComputed2 = _.isObject(styleComputed) ? (styleComputed as CSSObject) : undefined;
    const sizeComputed =
      styleComputed2?.fontSize &&
      (_.isNumber(styleComputed2?.fontSize) || _.isString(styleComputed2?.fontSize))
        ? styleComputed2?.fontSize.toString()
        : 16;

    return sizeComputed;
  }, [theme, size]);

  return (
    <AppTooltip
      enabled={!_.isNil(tooltipProps)}
      title={tooltipProps?.title || ""}
      {...tooltipProps}
    >
      <IconButtonStyled
        ref={ref}
        sx={{
          ...sx,
        }}
        variant={variant}
        size={size}
        shape={shape}
        {...otherProps}
        onClick={
          onClick
            ? async (e) => {
                isAutoTrackLoading && setIsLoading(true);
                try {
                  await onClick(e);
                } finally {
                  isAutoTrackLoading && setIsLoading(false);
                }
              }
            : undefined
        }
      >
        {!isLoadingComputed && children}

        {isLoadingComputed && (
          <CircularProgress
            size={circularProgressSize}
            sx={{
              color: "inherit",
            }}
          />
        )}
      </IconButtonStyled>
    </AppTooltip>
  );
});
