import { Auth0Client } from "@auth0/auth0-spa-js";

export class Auth0Provider {
  private _auth0: Auth0Client | undefined;

  public get auth0Client() {
    return this._auth0;
  }

  public setAuth0Client(auth0Client: Auth0Client) {
    this._auth0 = auth0Client;
  }

  public getAuth0Client() {
    return this._auth0;
  }
}

export const auth0Provider = new Auth0Provider();
