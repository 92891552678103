import { CustomColor } from "../classes/customColor";
import { ColorLightnessSide } from "../ts/color";
import { MiscHelper } from "./misc";
import { NumberHelper } from "./number";

/** Matches:
 * RGB Hexadecimal: #RGB, #RRGGBB
 * RGBA Hexadecimal: #RGBA, #RRGGBBAA
 */
export const rgbAndRgbaHexRegex =
  /^#((?<red>[0-9a-f]{1})(?<green>[0-9a-f]{1})(?<blue>[0-9a-f]{1})(?<alpha>[0-9a-f]{1})?|(?<red2>[0-9a-f]{2})(?<green2>[0-9a-f]{2})(?<blue2>[0-9a-f]{2})(?<alpha2>[0-9a-f]{2})?)$/i;

/** Matches:
 * RGB: rgb(255 0 153), rgb(160, 34, 42)
 * RGBA: rgba(255 0 153 / 50%), rgba(160, 34, 42, 0.5)
 */
export const rgbAndRgbaRegex =
  /^rgba?\((?<red>\d+)( |, ?)(?<green>\d+)( |, ?)(?<blue>\d+)(( \/ |, ?)(?<alpha>\d+%|\d+\.\d+))?\)$/i;

export interface RgbColor {
  r: number;
  g: number;
  b: number;
}

export interface RgbaColor extends RgbColor {
  a: number;
}

export interface HslColor {
  /** Hue is a degree on the color wheel from 0 to 360. 0 is red, 120 is green, 240 is blue. */
  h: number;
  /** Saturation is a percentage value; 0% means a shade of gray and 100% is the full color. */
  s: number;
  /** Lightness is also a percentage; 0% is black, 100% is white. */
  l: number;
  /** Format: hsl(${h}deg, ${s}%, ${l}%) */
  hsl: string;
}

export interface HslaColor extends HslColor {
  a: number;

  /** Format: hsla(${h}deg, ${s}%, ${l}%, ${a}) */
  hsla: string;
}

export class ColorHelper {
  /** Generates HEX color based on the string value.
   *  Source: https://mui.com/material-ui/react-avatar/#main-content
   * */
  public static stringToHexColor(str: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  /** Generates HSL color based on the string value.
   *  Source: https://codepen.io/sergiopedercini/pen/RLJYLj
   * */
  public static stringToHslColor(
    str: string,
    sl: Pick<HslColor, "s" | "l"> = { s: 50, l: 50 },
    hRange?: { min: number; max: number },
  ): HslColor {
    const { s, l } = sl;

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = Math.abs(hash) % 360;

    // normalize H to provided range
    if (hRange) {
      h = NumberHelper.scaleNumberIntoRange(
        h,
        { min: 0, max: 360 },
        { min: hRange.min, max: hRange.max },
      );
    }

    return {
      h,
      s,
      l,
      hsl: `hsl(${h}deg, ${s}%, ${l}%)`,
    };
  }

  public static stringToHslaColor(
    str: string,
    sl: Pick<HslaColor, "s" | "l"> = { s: 50, l: 50 },
    hRange?: { min: number; max: number },
    alpha?: number,
  ): HslaColor {
    const { s, l } = sl;

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = Math.abs(hash) % 360;

    // normalize H to provided range
    if (hRange) {
      h = NumberHelper.scaleNumberIntoRange(
        h,
        { min: 0, max: 360 },
        { min: hRange.min, max: hRange.max },
      );
    }

    return {
      h,
      s,
      l,
      a: alpha || 1,
      hsl: `hsl(${h}deg, ${s}%, ${l}%`,
      hsla: `hsla(${h}deg, ${s}%, ${l}%, ${alpha || 1})`,
    };
  }

  public static getRandomColorRgb(): RgbColor {
    const r = MiscHelper.getRandomInt(0, 255);
    const g = MiscHelper.getRandomInt(0, 255);
    const b = MiscHelper.getRandomInt(0, 255);
    return { r, g, b };
  }

  public static getRandomColorHex() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  }

  // HSL (Hue, Saturation, Lightness)
  // https://webdesign.tutsplus.com/tutorials/generate-random-background-colors-javascript--cms-37030
  public static getRandomColorHsl(): HslColor {
    const h = MiscHelper.getRandomInt(0, 360);
    const s = MiscHelper.getRandomInt(0, 100);
    const l = MiscHelper.getRandomInt(0, 100);
    return {
      h,
      s,
      l,
      hsl: `hsl(${h}deg, ${s}%, ${l}%)`,
    };
  }

  /** Returns color lightness side - whether the color is closer to black or white. */
  public static getHslColorLightnessSide(color: HslColor): ColorLightnessSide {
    return color.l <= 50 ? ColorLightnessSide.Black : ColorLightnessSide.White;
  }

  /** Picks readable contrast text color for provided HSL background color.
   *  Uses L (Lightness) to pick appropriate color.
   */
  public static pickContrastTextHexColorForBgHslColor(
    bgColor: HslColor,
    customColors?: Partial<Record<ColorLightnessSide, string>>,
  ): string {
    const defaultColors = {
      [ColorLightnessSide.White]: "#000",
      [ColorLightnessSide.Black]: "#fff",
    };

    const bgColorSide = bgColor.l < 50 ? ColorLightnessSide.Black : ColorLightnessSide.White;
    return (customColors || {})[bgColorSide] || defaultColors[bgColorSide];
  }

  /** Picks readable similar text color (darker, lighter) for provided HSL background color.
   *  Uses L (Lightness) to pick appropriate color.
   *  E.g. blue background -> dark blue text.
   * @param lDiff Desired lightness difference between text and background color.
   */
  public static pickTextHslColorSimilarToBgHslColor(bgColor: HslColor, lDiff = 50): HslColor {
    // pick lighter text for darker bg and vice versa
    const lightnessSide = this.getHslColorLightnessSide(bgColor);
    const lDiffMultiplier = lightnessSide === ColorLightnessSide.Black ? 1 : -1;
    const { h, s } = bgColor;
    const l = Math.max(Math.min(bgColor.l + lDiff * lDiffMultiplier, 100), 0);
    const textColor: HslColor = {
      h,
      s,
      l,
      hsl: `hsl(${h}deg, ${s}%, ${l}%)`,
    };
    return textColor;
  }

  // #region HSL

  /** Darkens HSL color (by changing L channel). */
  public static darkenHsl(color: HslColor, coefficient: number): HslColor {
    const l = Math.max(Math.min(color.l - color.l * coefficient, 100), 0);
    return {
      ...color,
      l,
      hsl: `hsl(${color.h}deg, ${color.s}%, ${l}%)`,
    };
  }

  /** Darkens HSLA color (by changing L channel). */
  public static darkenHsla(color: HslaColor, coefficient: number): HslaColor {
    const l = Math.max(Math.min(color.l - color.l * coefficient, 100), 0);
    return {
      ...color,
      l,
      hsla: `hsla(${color.h}deg, ${color.s}%, ${l}%, ${color.a})`,
    };
  }

  /** Lightens HSL color (by changing L channel). */
  public static lightenHsl(color: HslColor, coefficient: number): HslColor {
    const l = Math.max(Math.min(color.l + color.l * coefficient, 100), 0);
    return {
      ...color,
      l,
      hsl: `hsl(${color.h}deg, ${color.s}%, ${l}%)`,
    };
  }

  // #endregion

  // #region Color conversions

  /** Source: https://css-tricks.com/converting-color-spaces-in-javascript/ */
  public static rgbToHex(color: RgbColor): string {
    let r = color.r.toString(16);
    let g = color.g.toString(16);
    let b = color.b.toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;

    return "#" + r + g + b;
  }

  /** Source: https://css-tricks.com/converting-color-spaces-in-javascript/ */
  public static rgbaToHexa(color: RgbaColor) {
    let r = color.r.toString(16);
    let g = color.g.toString(16);
    let b = color.b.toString(16);
    let a = Math.round(color.a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
  }

  /** Source: https://css-tricks.com/converting-color-spaces-in-javascript/ */
  public static rgbToHsl(color: RgbColor): HslColor {
    // Make r, g, and b fractions of 1
    const r = color.r / 255;
    const g = color.g / 255;
    const b = color.b / 255;

    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    // Calculate hue
    // No difference
    if (delta == 0) h = 0;
    // Red is max
    else if (cmax == r) h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax == g) h = (b - r) / delta + 2;
    // Blue is max
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0) h += 360;

    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l, hsl: `hsl(${h}deg, ${s}%, ${l}%)` };
  }

  /** Source: https://css-tricks.com/converting-color-spaces-in-javascript/ */
  public static rgbaToHsla(color: RgbaColor): HslaColor {
    const hsl = this.rgbToHsl(color);
    return { ...hsl, a: color.a, hsla: `hsla(${hsl.h}deg, ${hsl.s}%, ${hsl.l}%, ${color.a})` };
  }

  /** Source: https://css-tricks.com/converting-color-spaces-in-javascript/ */
  public static hexToHsl(hex: string): HslColor {
    // Convert hex to RGB first
    let r: any = 0,
      g: any = 0,
      b: any = 0;
    if (hex.length == 4) {
      r = "0x" + hex[1] + hex[1];
      g = "0x" + hex[2] + hex[2];
      b = "0x" + hex[3] + hex[3];
    } else if (hex.length == 7) {
      r = "0x" + hex[1] + hex[2];
      g = "0x" + hex[3] + hex[4];
      b = "0x" + hex[5] + hex[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    if (delta == 0) h = 0;
    else if (cmax == r) h = ((g - b) / delta) % 6;
    else if (cmax == g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l, hsl: `hsl(${h}deg, ${s}%, ${l}%)` };
  }

  // #endregion

  /**
   * Parses CSS color string.
   * Supported CSS color syntaxes:
   * Named colors: -
   * RGB Hexadecimal: #RGB, #RRGGBB
   * RGBA Hexadecimal: #RGBA, #RRGGBBAA
   * RGB: rgb(255 0 153), rgb(160, 34, 42)
   * RGBA: rgba(255 0 153 / 50%), rgba(160, 34, 42, 0.5)
   * HSL: -
   * HWB: -
   * LAB: -
   * LCH: -
   * Oklab: -
   * Oklch : -
   * @param cssColor Color used in CSS - https://developer.mozilla.org/en-US/docs/Web/CSS/color_value
   */
  public static parseCssColor(cssColor: string): CustomColor {
    return CustomColor.parseCssColor(cssColor);
  }
}

// Temp test
// console.log("rgb(255 0 153)", ColorHelper.parseCssColor("rgb(255 0 153)"));
// console.log("rgb(160, 34, 42)", ColorHelper.parseCssColor("rgb(160, 34, 42)"));
// console.log("rgba(255 0 153 / 50%)", ColorHelper.parseCssColor("rgba(255 0 153 / 50%)"));
// console.log("rgba(160, 34, 42, 0.5)", ColorHelper.parseCssColor("rgba(160, 34, 42, 0.5)"));
// console.log("#f09", ColorHelper.parseCssColor("#f09"));
// console.log("#ff0099", ColorHelper.parseCssColor("#ff0099"));
// console.log("#f09a", ColorHelper.parseCssColor("#f09a"));
// console.log("#ff0099aa", ColorHelper.parseCssColor("#ff0099aa"));
