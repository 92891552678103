import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, RoleDto, RoleReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import RoleInline, { RoleInlineProps } from "./RoleInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: RoleDto | RoleReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<RoleInlineProps>;
}

export default function RoleLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantRoleRead])}
      to={ROUTE_PATH.TENANT_ROLE_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={<RoleInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Role"}
    />
  );
}
