import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

import AppIcon from "../Icons/AppIcon";

interface Props {
  variant?: "block" | "inline";
  title?: string;
  description?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

export default function NoDataAlert({
  variant = "block",
  title,
  description,
  fullWidth,
  sx,
}: Props) {
  if (variant === "block") {
    return (
      <Stack
        direction='column'
        spacing={1}
        color='text.secondary'
        sx={{ alignItems: "center", px: 4, py: 2, width: fullWidth ? "100%" : undefined, ...sx }}
      >
        <AppIcon of='notFound' fontSize='medium' />
        <Typography component='div' variant='body2'>
          {title || "No data"}
        </Typography>
        {description && (
          <Typography component='div' variant='caption'>
            {description}
          </Typography>
        )}
      </Stack>
    );
  } else if (variant === "inline") {
    return (
      <Box component='span' sx={{ ...sx }}>
        <AppIcon of='notFound' inText />{" "}
        <Typography component='span' variant='body2'>
          {title || "No data"}
        </Typography>
        {description && (
          <Typography component='span' variant='caption'>
            {description}
          </Typography>
        )}
      </Box>
    );
  } else {
    return null;
  }
}
