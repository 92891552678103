import { Card, CardContent, Grid, Stack, SxProps, Theme, useTheme } from "@mui/material";
import { GridSortItem } from "@mui/x-data-grid";
import _ from "lodash";

import TooltipFacade from "../Mui/Facades/TooltipFacade";
import DataHeaderColumnLabel from "./DataHeaderColumnLabel";
import {
  DataListDefinition,
  DataListHeaderVariant,
  DataListPaddings,
  DataListValidRowModel,
} from "./DataList";
import DataListRowAction from "./DataListRowAction";

export interface DataListHeaderOwnProps<TItem extends DataListValidRowModel> {
  variant?: DataListHeaderVariant;
  stickToTop?: boolean;
  sortModel?: GridSortItem | undefined;
  onSortModelChange?: (sortModel: GridSortItem | undefined) => void;
}

type Props<TItem extends DataListValidRowModel> = DataListHeaderOwnProps<TItem> &
  DataListDefinition<TItem> & {
    paddings?: DataListPaddings;
    sx?: SxProps<Theme>;
  };

export default function DataListHeader<TItem extends DataListValidRowModel>({
  variant = "bordered",
  stickToTop,
  columns,
  columnSpacing = 2,
  rowSpacing = 1,
  renderRowAction,
  sx,
  onSortModelChange = () => {},
  sortModel,
  paddings,
}: Props<TItem>) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: 0,
        borderWidth: 1,
        borderColor: (th) => th.palette.divider,
        borderStyle: "solid",

        ...((stickToTop && {
          position: "sticky",
          top: 0,
          zIndex: 1,
          backdropFilter: "blur(10px)",
          background: "none",
        }) ||
          {}),
        ...(variant === "bordered" && {
          borderColor: (th) => th.palette.divider,
        }),
        ...(variant === "shadowed" && {
          borderColor: "transparent",
        }),
        ...(variant === "compact" && {
          borderColor: "transparent",
          boxShadow: "none",
        }),
        ...sx,
      }}
      variant={variant === "bordered" ? "outlined" : "elevation"}
    >
      <Stack
        direction={{ xxs: "column", md: "row" }}
        spacing={0}
        sx={{ width: "100%", overflowX: "hidden" }}
      >
        <CardContent
          sx={{
            // p: "0 !important",
            px: 0,
            py: 0,
            flex: 1,

            ...(variant !== "compact" && {
              minHeight: "46px",
              maxHeight: "46px",
              lineHeight: "46px",
            }),
            ...(variant === "compact" && {
              border: "none",
              boxShadow: "none",
            }),
          }}
        >
          <Grid
            container
            sx={{ width: "100%", height: "100%" }}
            columnSpacing={columnSpacing}
            rowSpacing={rowSpacing}
          >
            {columns
              .filter((column) => _.isNil(column.if) || column.if === true)
              .map((column, i) => (
                <Grid
                  key={i}
                  item
                  width={column.width}
                  minWidth={column.minWidth}
                  maxWidth={column.maxWidth}
                  // sx={{ "&.MuiGrid-item": { px: 0, pt: 0, height: "100%" } }}
                  {..._.pick(column, theme.breakpoints.keys)} // apply breakpoint (xxs, xs, ...) props
                >
                  <TooltipFacade enabled={!!column.description} title={column.description || ""}>
                    <DataHeaderColumnLabel<TItem>
                      sx={{
                        px: 1,
                        backgroundColor:
                          column.field === sortModel?.field
                            ? theme.palette.primary.light
                            : "transparent",
                        // ...(variant !== "compact" && {
                        //   pl: 1.5,
                        // }),
                        // ...(variant === "compact" && {
                        //   pl: 1.5,
                        // }),
                      }}
                      column={column}
                      headerVariant={variant}
                      showSortButton={column.isSortable}
                      showMenuButton={!column.isColumnMenuDisabled}
                      sortDirection={
                        column.field === sortModel?.field ? sortModel?.sort : undefined
                      }
                      onSortModelChange={onSortModelChange}
                    >
                      {column.title || (column.renderTitle && column.renderTitle())}
                    </DataHeaderColumnLabel>
                  </TooltipFacade>
                </Grid>
              ))}
          </Grid>
        </CardContent>

        {/* Action column placeholder*/}
        {renderRowAction && (
          <DataListRowAction isPlaceholder paddings={paddings}></DataListRowAction>
        )}

        {/* Fix last item padding */}
        <CardContent sx={{ display: "none" }}></CardContent>
      </Stack>
    </Card>
  );
}
