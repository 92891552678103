import { DialogContent } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export default function AppModalContent({ children }: Props) {
  return <DialogContent>{children}</DialogContent>;
}
