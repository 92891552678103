import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import {
  AppPermission,
  TenantConnectionDto,
  TenantConnectionReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import TenantConnectionInline, { TenantConnectionInlineProps } from "./TenantConnectionInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: TenantConnectionDto | TenantConnectionReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<TenantConnectionInlineProps>;
}

export default function TenantConnectionLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantConnectionRead])}
      to={ROUTE_PATH.TENANT_CONNECTION_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <TenantConnectionInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company connection"}
    />
  );
}
