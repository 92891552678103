import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { ContractDto } from "@/core/api/generated";

import CustomerView from "../../../Customer/View/CustomerView";

interface Props {
  contract: ContractDto;
}

export default function CustomerTabContent({ contract }: Props) {
  return (
    <PageTabContent>
      <CustomerView
        customerId={contract.customer?.id}
        customer={undefined}
        displayProps={{
          breadcrumbs: false,
          header: false,
          actions: true,
          viewVariant: ViewLayoutVariant.Tab,
        }}
        headerProps={{
          withLink: true,
          typographyProps: pageTabHeaderTypographyProps.title,
        }}
      />
    </PageTabContent>
  );
}
