import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { FileItem } from "@/common/fileItem";
import { FormikHelper } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  GeneralHistoryCreateDto,
  GeneralHistoryDto,
  GeneralHistoryEventType,
  GeneralHistorySubjectType,
  GeneralHistoryType,
  GeneralHistoryUpdateDto,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import AppTypography from "../../Text/AppTypography";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import GeneralHistoryEventInput from "./GeneralHistoryEventInput";

type DefaultValues = Partial<GeneralHistoryCreateDto>;

export interface GeneralHistoryCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<GeneralHistoryDto, DefaultValues> {
  generalHistoryId?: string;
}

export type GeneralHistoryCreateUpdateProps = GeneralHistoryCreateUpdateOwnProps;

export default function GeneralHistoryCreateUpdate({
  generalHistoryId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: GeneralHistoryCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !generalHistoryId;
  const isEdit = !!generalHistoryId;

  const generalHistoryRequest = useApiRequest(
    apiClient.generalHistoryApi.apiV1GeneralHistoryGeneralHistoryIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      generalHistoryId: generalHistoryId!,
    },
    {
      skip: !generalHistoryId,
    },
  );
  const generalHistory = generalHistoryRequest?.data;

  // useBreadcrumbReplacements({
  //   waitTimeout: 10_000,
  //   idBreadcrumb: generalHistory && {
  //     idValue: generalHistory.id!,
  //     newTitle: generalHistory.localNumber || "",
  //   },
  // });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.GeneralHistory}
      entityId={generalHistoryId}
      entity={generalHistory}
      entityRequest={generalHistoryRequest}
    >
      <Formik<
        GeneralHistoryCreateDto &
          GeneralHistoryUpdateDto &
          BaseFormikValues & {
            initialAttachments: GeneralHistoryCreateDto["attachments"];
            isAttachmentsUploading: boolean;
            isAttachmentFilesHaveErrors: boolean;
          }
      >
        enableReinitialize={true}
        initialValues={{
          isAttachmentFilesHaveErrors: false,
          type: defaultValues?.type || generalHistory?.type || GeneralHistoryType.Event,
          raisedAt: defaultValues?.raisedAt || generalHistory?.raisedAt || moment().utc().format(),
          subject: {
            type: GeneralHistorySubjectType.Entity,
            ...defaultValues?.subject,
            ...generalHistory?.subject,
          },
          event: defaultValues?.event ||
            generalHistory?.event || {
              type: GeneralHistoryEventType.Arbitrary,
            },
          initialAttachments:
            defaultValues?.attachments || generalHistory?.attachments || undefined,
          attachments: defaultValues?.attachments || generalHistory?.attachments || undefined,
          tags: generalHistory?.tags || undefined,
          isAttachmentsUploading: false,
          submit: "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, { setFieldValue, setFieldError, setStatus, setSubmitting }) => {
          try {
            const values2 = {
              ...values,
              raisedAt: values.raisedAt && moment(values.raisedAt).utc().format(),
            };

            if (isCreate) {
              const response = await apiClient.generalHistoryApi.apiV1GeneralHistoryPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                generalHistoryCreateDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("History record created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response =
                await apiClient.generalHistoryApi.apiV1GeneralHistoryGeneralHistoryIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  generalHistoryId,
                  generalHistoryUpdateDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("History record updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
            setStatus,
            setSubmitting,
            setFieldError,
          } = formikProps;

          return (
            <>
              <Stack spacing={2}>
                <Box>
                  {/* Tags */}
                  <FormControl margin='dense' fullWidth>
                    <GeneralAttachedTagsInput
                      value={values.tags}
                      onChange={(newValue) => {
                        setFieldValue("tags", newValue);
                      }}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                        isIncludeNested: false,
                      })}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Stack spacing={2}>
                  <FormControl margin='none' fullWidth>
                    <MobileDateTimePicker
                      ampm={false}
                      label='Raised at'
                      value={(values.raisedAt && moment(values.raisedAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue) => {
                        setFieldValue("raisedAt", newValue?.format() || null);
                      }}
                      slots={{
                        textField: (params) => (
                          <TextField
                            {...params}
                            required
                            error={Boolean(errors.raisedAt)}
                            helperText={errors.raisedAt}
                          />
                        ),
                      }}
                    />
                  </FormControl>

                  <FormControl margin='none' fullWidth error={Boolean(errors.type)}>
                    <ApiEnumAutocomplete
                      type='GeneralHistoryType'
                      value={values.type}
                      label='Record type'
                      required
                      onlyEnumValues={[GeneralHistoryType.Event]}
                      textFieldProps={{
                        error: Boolean(errors.type),
                        helperText: errors.type,
                      }}
                      onChange={(newValue) => setFieldValue("type", newValue)}
                    />
                  </FormControl>
                </Stack>

                {/* Input based on type */}
                {values.type && (
                  <Card>
                    <CardContent>
                      <Typography variant='h4' sx={{ mb: 2 }}>
                        <InlineApiEnumValue type='GeneralHistoryType' value={values.type} /> data
                      </Typography>

                      {renderIf()
                        .if(values.type === GeneralHistoryType.Event)
                        .then(
                          <GeneralHistoryEventInput
                            formikProps={FormikHelper.getSubProps(
                              formikProps,
                              "event",
                              (x) => x.event,
                            )}
                          />,
                        )
                        .else(
                          <AppTypography color='error'>
                            History record of type {values.type} is not supported.
                          </AppTypography>,
                        )
                        .render()}
                    </CardContent>
                  </Card>
                )}

                <FormControl fullWidth margin='none'>
                  <FileUploader
                    multiple
                    defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                    onChange={(newFiles) => {
                      setFieldValue(
                        `attachments`,
                        FileItem.toManyGeneralAttachmentInputDto(newFiles),
                      );
                    }}
                    onUploadStarted={() => {
                      setFieldValue("isAttachmentsUploading", true);
                    }}
                    onUploadFinished={() => {
                      setFieldValue("isAttachmentsUploading", false);
                    }}
                    onValidationStatusChange={(filesValidationStatus) => {
                      if (filesValidationStatus)
                        setFieldValue(
                          "isAttachmentFilesHaveErrors",
                          Object.values(filesValidationStatus).some((x) => x === false),
                        );
                    }}
                  />
                </FormControl>
              </Stack>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  onClick={() => handleSubmit()}
                  color='primary'
                  loading={isSubmitting}
                  disabled={values.isAttachmentsUploading || values.isAttachmentFilesHaveErrors}
                  fullWidth
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
