import { Box, FormControl, FormHelperText, FormLabel, Stack, TextField } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import { HexColorPicker } from "react-colorful";

import { renderIf } from "@/common/helpers/render/renderIf";
import { ColorSpaceType, RgbColorType } from "@/common/ts/color";

import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import ColorPreviewBox from "./ColorPreviewBox";

function getValidHexColorFromInput(color: string | undefined): string | undefined {
  // handle react-colorful bug (appears when changing hue for undefined color)
  if (color === "#NaNNaNNaN") {
    return undefined;
  }
  return color || undefined;
}

export interface ColorInputProps {
  /** Only RGB is supported currently. */
  colorSpaceType: ColorSpaceType;
  /** Only HEX, HEXA is supported currently. */
  rgbColorType: RgbColorType;
  value: string | undefined;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  size?: number;
  /** List of predefined color to be selected. */
  predefinedColors?: string[];
  onChange?: (newValue: string | undefined) => void;
}

/** RGB color input. */
export default function ColorInput({
  colorSpaceType,
  rgbColorType,
  value,
  label,
  required,
  disabled,
  error,
  helperText,
  size = 40,
  predefinedColors,
  onChange,
}: ColorInputProps) {
  if (colorSpaceType !== ColorSpaceType.RGB) {
    throw new Error(`Only ${ColorSpaceType.RGB} color space is supported!`);
  }
  if (rgbColorType !== RgbColorType.HEX && rgbColorType !== RgbColorType.HEXA) {
    throw new Error(
      `Only ${RgbColorType.HEX}, ${RgbColorType.HEXA} RGB color types are supported!`,
    );
  }

  return (
    <Box>
      <AppPopover
        clickBehavior={{}}
        trigger={
          <FormControl margin='none' required={required} disabled={disabled} error={error}>
            {label && (
              <FormLabel sx={{ mb: 0.5 }} required={required} disabled={disabled}>
                {label}
              </FormLabel>
            )}

            <ColorPreviewBox color={value} size={40} isClickable />

            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
          </FormControl>
        }
        contentSx={{
          maxWidth: { xxs: "95vw", md: "300px" },
        }}
      >
        <AppPopoverContent>
          <Stack spacing={2}>
            {/* Color picker */}
            <Box
              sx={{
                // react-colorful style customization
                "& .react-colorful": {
                  width: "100%",
                  height: "200px",
                },
                "& .react-colorful__saturation": {},
                "& .react-colorful__hue": {},
                "& .react-colorful__pointer": {},
                "& .react-colorful__saturation-pointer": {},
                "& .react-colorful__hue-pointer": {},
              }}
            >
              {renderIf()
                .if(colorSpaceType === ColorSpaceType.RGB)
                .then(
                  <>
                    {/* {rgbColorType === RgbColorType.RGB && (
                  <RgbColorPicker
                    color={value}
                    onChange={(newValue) => {
                      onChange && onChange();
                    }}
                  />
                )}
                {rgbColorType === RgbColorType.RGBA && (
                  <RgbaColorPicker
                    color={value}
                    onChange={(newValue) => {
                      onChange && onChange();
                    }}
                  />
                )} */}
                    {rgbColorType === RgbColorType.HEX && (
                      <HexColorPicker
                        color={value}
                        onChange={(newValue) => {
                          onChange && onChange(getValidHexColorFromInput(newValue));
                        }}
                      />
                    )}
                    {rgbColorType === RgbColorType.HEXA && (
                      <HexColorPicker
                        color={value}
                        onChange={(newValue) => {
                          onChange && onChange(getValidHexColorFromInput(newValue));
                        }}
                      />
                    )}
                  </>,
                )
                .render()}
            </Box>

            {/* Color input */}
            <Box>
              <TextField
                variant='outlined'
                size='small'
                type='text'
                label='Color'
                margin='none'
                disabled
                fullWidth
                value={value ?? ""}
                onChange={(e) => {}}
              />
            </Box>

            {/* Predefined colors */}
            {!_.isEmpty(predefinedColors) && (
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {predefinedColors?.map((color, i) => (
                  <ColorPreviewBox
                    key={i}
                    sx={{ mr: 1, mb: 1 }}
                    color={color}
                    size={30}
                    onClick={() => {
                      onChange && onChange(color);
                    }}
                  />
                ))}
              </Box>
            )}
          </Stack>
        </AppPopoverContent>
      </AppPopover>
    </Box>
  );
}
