import { EntityType } from "@/core/api/generated";

/** This is reflection of DataUpdatesChannelName.cs on BE. */
export class DataUpdatesChannelName {
  public static Entities(tenantId: string | null | undefined, entityType: EntityType) {
    return `TenantId:${tenantId || ""}:EntityType:${entityType}`;
  }
  public static Entity(
    tenantId: string | null | undefined,
    entityType: EntityType,
    entityId: string | null | undefined,
  ) {
    return `TenantId:${tenantId || ""}:EntityType:${entityType}:EntityId:${entityId || ""}`;
  }
}
