import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import DataTabular, { TabularProps } from "@/common/components/DataTabular/DataTabular";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, VehicleDamageDto } from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import Datetime from "../../Datetime/Datetime";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import ContractInline from "../Contract/ContractInline";
import GeneralInspectorDisplay from "../General/Display/GeneralInspectorDisplay";
import { RepairOperationAddVehicleDamagesModal } from "../RepairOperation/RepairOperationAddVehicleDamagesModal";
import VehicleInline from "../Vehicle/VehicleInline";
import VehicleVisualModelInline from "../VehicleVisualModel/VehicleVisualModelInline";
import { VehicleDamageStatus } from "./VehicleDamageStatus";

const defaultDisplayProps = {
  vehicleColumn: true,
};

interface OwnProps {
  vehicleDamages: VehicleDamageDto[];
  displayProps?: Partial<typeof defaultDisplayProps>;
  dataTabularProps?: Partial<TabularProps<VehicleDamageDto>>;
}

type Props = OwnProps;

export default function VehicleDamageList({
  vehicleDamages,
  displayProps = defaultDisplayProps,
  dataTabularProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [damageToAddToRepairOperation, setDamageToAddToRepairOperation] = useState<
    VehicleDamageDto | undefined
  >(undefined);

  return (
    <>
      <DataTabular<VehicleDamageDto>
        columns={[
          {
            field: "localNumber",
            title: "Number",
            flex: 1,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span> <EntityChipList entity={item} variant='compact' />
              </Stack>
            ),
          },
          {
            field: "vehicle",
            title: "Vehicle",
            flex: 1,
            if: !!displayProps?.vehicleColumn,
            renderCell: (item) => (
              <Stack spacing={0.5}>
                <VehicleInline entity={item.vehicle} />
                {item.contract && (
                  <Typography component='div' variant='body2'>
                    <ContractInline entity={item.contract} />
                  </Typography>
                )}
              </Stack>
            ),
          },
          {
            field: "partType.name",
            title: "Part type",
            flex: 1,
            renderCell: (item) => (
              <Stack>
                <Box>{item.partType?.name}</Box>
                <Typography component='div' variant='body2' color='text.secondary'>
                  <InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                  <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                </Typography>
              </Stack>
            ),
          },
          {
            field: "damageType.name",
            title: "Damage type",
            flex: 1,
            renderCell: (item) => <>{item.damageType?.name}</>,
          },
          {
            field: "state",
            title: "State",
            flex: 1,
            renderCell: (item) => <VehicleDamageStatus vehicleDamage={item} sx={{ ml: 0.5 }} />,
          },
          {
            field: "inspectedAt",
            title: "Inspected",
            flex: 1,
            renderCell: (item) => (
              <Stack direction='column' alignItems='flex-start'>
                <GeneralInspectorDisplay
                  inspector={item.inspector}
                  inlineUserProps={{
                    withLink: false,
                  }}
                />
                <Datetime datetime={item.inspectedAt} direction='column' />
              </Stack>
            ),
          },
          {
            field: "visualModel.id",
            title: "Visual model",
            flex: 1,
            renderCell: (item) => <VehicleVisualModelInline entity={item.visualModel} withIcon />,
          },
        ]}
        rows={vehicleDamages}
        getRowId={(item) => item.id!}
        rowTo={(item) =>
          ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
            vehicleId: item.vehicle?.id || undefined,
            vehicleDamageId: item.id || undefined,
          })
        }
        renderRowAction={(actionParams) => (
          <>
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                <MenuItem
                  onClick={() => {
                    setIsAddToRepairOperationModalOpen(true);
                    setDamageToAddToRepairOperation(actionParams.item);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='add' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Add to repair operation</ListItemText>
                </MenuItem>
              </AuthorizedElement>
            </MenuWithTrigger>
          </>
        )}
        {...dataTabularProps}
      />

      {/* Add to repair operation */}
      {damageToAddToRepairOperation && (
        <RepairOperationAddVehicleDamagesModal
          open={isAddToRepairOperationModalOpen}
          onClose={() => setIsAddToRepairOperationModalOpen(false)}
          damages={[damageToAddToRepairOperation]}
        />
      )}
    </>
  );
}
