import React, { ReactNode } from "react";

export class ReactHelper {
  /** Checks whether provided object is React class component. */
  public static isClassComponent(obj: any | null | undefined): boolean {
    return typeof obj === "function" && !!(obj as any).prototype.isReactComponent;
  }

  /** Checks whether provided object is React function component. */
  public static isFunctionComponent(obj: any | null | undefined): boolean {
    return typeof obj === "function" && String(obj).includes("return React.createElement");
  }

  /** Checks whether provided object is React component. */
  public static isComponent(obj: any | null | undefined): boolean {
    return this.isClassComponent(obj) || this.isFunctionComponent(obj);
  }

  /** Checks whether provided object is React element (either a component or a DOM element). */
  public static isElement(obj: any | null | undefined): boolean {
    return React.isValidElement(obj);
  }

  /** Creates new string with replaced value at index. */
  public static isEmptyNode(node: ReactNode | null | undefined) {
    throw new Error("Not implemented");
  }
}
