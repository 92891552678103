import { Box, BoxProps, SxProps, Theme, Tooltip } from "@mui/material";
import { forwardRef } from "react";

import { GeneralPlateNoDto, GeneralPlateNoInputDto } from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import AppIcon from "../../../Icons/AppIcon";
import CountryFlagIcon from "../../../Icons/CountryFlagIcon";

interface GeneralPlateNoDisplayProps {
  plateNo?: GeneralPlateNoDto | GeneralPlateNoInputDto | null;
  withIcon?: boolean;
  withCountryCode?: boolean;
  withCountryIcon?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
  sx?: SxProps<Theme>;
}

export default forwardRef<HTMLSpanElement, GeneralPlateNoDisplayProps>(
  function GeneralPlateNoDisplay(
    {
      plateNo,
      withIcon = false,
      withCountryCode = true,
      withCountryIcon = false,
      withTooltip = false,
      tooltipText = "Plate number",
      sx,
    }: GeneralPlateNoDisplayProps,
    ref,
  ) {
    if (!plateNo) {
      return null;
    }

    return (
      <AppTooltip
        enabled={withTooltip && !!tooltipText}
        title={tooltipText || ""}
        wrapperProps={{
          component: "span",
        }}
      >
        <Box ref={ref} component='span' sx={sx}>
          {withIcon && <AppIcon of='plateNo' inText />}
          {plateNo.plateNo}
          {(withCountryCode || withCountryIcon) && (
            <>
              {" "}
              (
              {withCountryIcon && (
                <CountryFlagIcon countryCode={plateNo.countryCode} sx={{ width: 24 }} />
              )}
              {withCountryIcon && withCountryCode && " "}
              {withCountryCode && `${plateNo.countryCode}`})
            </>
          )}
        </Box>
      </AppTooltip>
    );
  },
);
