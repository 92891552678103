import { SxProps, Theme } from "@mui/material";

import { DataGrantDto } from "@/core/api/generated";

import GeneralStrictEntityRelationLink from "../General/Display/GeneralStrictEntityRelationLink";
import { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";

interface Props {
  value: DataGrantDto | null | undefined;
  linkProps?: Partial<BaseEntityLinkInheritableProps>;
  sx?: SxProps<Theme>;
}

export default function DataGrantEntityLink({ value, linkProps, sx }: Props) {
  if (!value) {
    return null;
  }
  return (
    <GeneralStrictEntityRelationLink
      value={{ entityType: value.entityType, entityId: value.entityId }}
      linkProps={linkProps}
      sx={sx}
    />
  );
}
