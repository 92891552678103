import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import RepairSpecView from "@/common/components/Entity/RepairSpec/View/RepairSpecView";
import { apiClient } from "@/core/api/ApiClient";

function RepairSpecViewPage() {
  const { repairSpecId } = useParams<{ repairSpecId?: string }>();

  return (
    <Stack direction='column'>
      <RepairSpecView
        repairSpecId={repairSpecId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        getFunc={async (params) =>
          await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdGet({
            repairSpecId: params.repairSpecId,
          })
        }
        deleteFunc={async (params) =>
          await apiClient.adminRepairSpecsApi.apiV1AdminRepairSpecsRepairSpecIdDelete({
            repairSpecId: params.repairSpecId,
          })
        }
      />
    </Stack>
  );
}

export default RepairSpecViewPage;
