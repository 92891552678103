import { useEffect } from "react";

export function useOnOutsideClick(
  ref: React.RefObject<HTMLElement>,
  handler = (event: Event) => {},
  additionalIgnoreConditionGenerator = (event: Event) => {
    return false;
  },
) {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref?.current ||
        ref?.current.contains(event.target as Node) ||
        additionalIgnoreConditionGenerator(event)
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
