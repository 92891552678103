import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { SpotDto, SpotReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getSpotOptionTitle } from "./SpotAutocomplete";

export interface SpotInlineProps extends BaseEntityInlineInheritableProps {
  entity: SpotDto | SpotReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function SpotInline({ entity, entityId, ...otherProps }: SpotInlineProps) {
  const request = useApiRequest(
    apiClient.spotsApi.apiV1SpotsSpotIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  const refRequest = useApiRequest(
    apiClient.spotsApi.apiV1SpotsSpotIdReferenceGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity || (request.isLoading && !request.isEnded),
    },
  );
  entity = request?.data || entity || refRequest?.data;

  const full = entity as SpotDto;
  const ref = entity as SpotReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request.data ? request : refRequest}
      icon={<AppIcon of='spot' inText />}
      content={<>{full && getSpotOptionTitle(full)} </>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Spot"}
    />
  );
}
