import { Alert, Button, Stack, SxProps, Theme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  SpotDto,
  SpotGetPaginatedDto,
} from "@/core/api/generated";

import GeneralAddressDisplay from "../../General/Display/GeneralAddressDisplay";
import LocationInline from "../../Location/LocationInline";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import SpotDeleteModal from "../SpotDeleteModal";
import SpotMenu from "../SpotMenu";
import SpotsDeleteModal from "../SpotsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  Delete = "Delete",
}
const defaultDisplayProps = {
  hints: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type SpotPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;

export interface SpotPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  defaultValues?: {
    limit?: number;
    locationId?: string | null;
    productLocationId?: string | null;
  };
  propagatedDeps?: PropagatedDeps<SpotPaginatedListPropagatedDepsMap>;
  sx?: SxProps<Theme>;
}

export type SpotPaginatedListProps = SpotPaginatedListOwnProps;

export default function SpotPaginatedList({
  displayProps,
  defaultValues,
  propagatedDeps,
  sx,
}: SpotPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<SpotGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.Spot,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
  });

  const [spot, setSpot] = useState<SpotDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const paginatedSpotsRequest = useApiRequest(
    apiClient.spotsApi.apiV1SpotsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, propagatedDeps?.depsMap["refetch"]],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedSpots = paginatedSpotsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Spot)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedSpotsRequest.handleEntityChanged(data);
    },
  });

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <>
            {displayProps?.hints && (
              <Alert severity='info' sx={{ mb: 2 }}>
                Spots are one of the levels of the company structure hierarchy. They describe
                specific address (point on the map).
              </Alert>
            )}

            <SimpleViewPageHeader
              title='Spots'
              primaryActions={
                displayProps?.create && (
                  <AuthorizedElement permissions={[AppPermission.SpotManage]}>
                    <Button
                      variant='contained'
                      color='primary'
                      startIcon={<AppIcon of='add' />}
                      component={RouterLink}
                      to={ROUTE_PATH.SPOT_CREATE({
                        locationId: commonRequestParams.params?.locationId,
                        productLocationId: commonRequestParams.params?.productLocationId,
                      })}
                    >
                      Create new spot
                    </Button>
                  </AuthorizedElement>
                )
              }
            />
          </>
        }
      >
        <Stack direction='column' spacing={1}>
          <DataTabular
            columns={[
              {
                field: "name",
                title: "Name",
                flex: 1,
                renderCell: (item) => <>{item.name}</>,
              },
              {
                field: "id",
                title: "Types",
                flex: 1,
                renderCell: (item) => (
                  <InlineApiEnumValueList type='SpotType' values={item.types} direction='row' />
                ),
              },
              {
                field: "address",
                title: "Address",
                flex: 2,
                renderCell: (item) => (
                  <AppTypography ellipsing={{ enabled: true }} component='div'>
                    <GeneralAddressDisplay address={item.address} direction='row' />
                  </AppTypography>
                ),
              },
              {
                field: "location.id",
                title: "Location",
                flex: 1,
                renderCell: (item) => <LocationInline entity={item.location} />,
              },
              {
                field: "productLocation.id",
                title: "Sales location",
                flex: 1,
                renderCell: (item) => <LocationInline entity={item.productLocation} />,
              },
            ]}
            isLoading={paginatedSpotsRequest.isLoading}
            rows={paginatedSpots?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.SPOT_VIEW(item.id)}
            renderRowAction={(actionParams) => (
              <SpotMenu
                entity={actionParams.item}
                onDelete={() => paginatedSpotsRequest.refetch()}
                onUpdate={() => paginatedSpotsRequest.refetch()}
                displayProps={{
                  actions: {
                    edit: displayProps?.edit || false,
                    delete: displayProps?.delete || false,
                  },
                }}
              />
            )}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
            bulkActions={{
              enabled: true,
              definition: BulkActionFlags,
              actionTriggers: ({ currentAction, startAction, selectedIds }) => (
                <>
                  <AppIconButton
                    tooltipProps={{ title: "Update affiliation" }}
                    onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
                  >
                    <AppIcon of='department' />
                  </AppIconButton>
                  <AppIconButton
                    tooltipProps={{ title: "Delete" }}
                    onClick={() => startAction(BulkActionFlags.Delete)}
                  >
                    <AppIcon of='delete' />
                  </AppIconButton>
                </>
              ),
              actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => (
                <>
                  <TenantStructureMembershipOfEntityEditModal
                    open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                    onClose={() => cancelAction()}
                    tenantStructureMembershipOfEntityEditProps={{
                      entityType: EntityType.Spot,
                      entities: EntityHelper.filterEntitiesByIds(
                        paginatedSpots?.items || [],
                        selectedIds as string[],
                      ),
                      onSave: () => {
                        completeAction();
                        paginatedSpotsRequest.refetch();
                      },
                    }}
                  />
                  <SpotsDeleteModal
                    entities={EntityHelper.filterEntitiesByIds(
                      paginatedSpots?.items || [],
                      selectedIds as string[],
                    )}
                    open={currentAction === BulkActionFlags.Delete}
                    onClose={() => cancelAction()}
                    onDelete={() => {
                      completeAction();
                      paginatedSpotsRequest.refetch();
                    }}
                  />
                </>
              ),
            }}
          />
        </Stack>

        {/* Delete confirmation */}
        {spot && (
          <SpotDeleteModal
            entity={spot}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => paginatedSpotsRequest.refetch()}
          />
        )}
      </ViewLayout>
    </Box>
  );
}
