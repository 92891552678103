import { SxProps, Theme } from "@mui/material";

import { DATETIME_FORMATS } from "@/common/constants/common";

import EntityChangedByInfoDisplay, { Entity } from "./EntityChangedByInfoDisplay";

export interface EntityUpdatedByInfoDisplayProps {
  entity: Entity | null | undefined;
  dateFormat?: string | null | undefined;
  sx?: SxProps<Theme>;
}

/** Shows who created the entity. */
export default function EntityUpdatedByInfoDisplay({
  entity,
  dateFormat = DATETIME_FORMATS.DISPLAY_DATETIME,
  sx,
}: EntityUpdatedByInfoDisplayProps) {
  return (
    <EntityChangedByInfoDisplay
      dateFormat={dateFormat}
      datetime={entity?.createdAt}
      userId={entity?.createdBy}
      sx={sx}
    />
  );
}
