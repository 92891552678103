import { Box, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import AccessoriesSelectOrCreate from "@/common/components/Entity/Accessory/AccessoriesSelectOrCreate";
import FileUploader from "@/common/components/Files/FileUploader";
import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import PlateNoInput from "@/common/components/Form/Input/PlateNoInput";
import { FileItem } from "@/common/fileItem";
import { FormikHelper } from "@/common/helpers/formik";
import { ValidationHelper } from "@/common/validation";

import EntityAffiliationInput from "../../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../../General/GeneralTag/GeneralAttachedTagsInput";
import GeneralStatusInput from "../../GeneralStatus/common/GeneralStatusInput";
import { VehicleFormikValues } from "../VehicleCreateUpdate";
import DefaultVehicleVisualModelInput from "./DefaultVehicleVisualModelInput";
import VehicleSpecInput from "./VehicleSpecInput";

interface Props {
  isCreate: boolean;
}

export default function VehicleGeneralInputs({ isCreate }: Props) {
  const formikContext = useFormikContext<VehicleFormikValues>();
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formikContext;

  const specFormikProps = FormikHelper.getSubProps(formikContext, "spec", (v) => v.spec);
  const statusFormikProps = FormikHelper.getSubProps(formikContext, "status", (v) => v.status);

  return (
    <Stack spacing={1}>
      <ViewContentBlock>
        <Box>
          {/* Tags */}
          <FormControl margin='dense' fullWidth>
            <GeneralAttachedTagsInput
              value={values.tags}
              onChange={(newValue) => {
                setFieldValue("tags", newValue);
              }}
            />
            <FormHelperText error>
              {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                isIncludeNested: false,
              })}
            </FormHelperText>
          </FormControl>
        </Box>

        {/* Plate, VIN */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <PlateNoInput
              fullWidth
              error={Boolean(errors?.plateNo)}
              helperText={ValidationHelper.getFormikErrorsAsString(errors?.plateNo)}
              label='Plate No'
              margin='dense'
              name='plateNo'
              onBlur={handleBlur}
              type='text'
              variant='outlined'
              color='primary'
              placeholder='AB 1234'
              value={values.plateNo}
              onChange={(newValue) => {
                setFieldValue("plateNo", newValue);
              }}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              error={Boolean(touched.identificationNumber && errors.identificationNumber)}
              helperText={touched.identificationNumber && errors.identificationNumber}
              label='Identification number (VIN)'
              margin='dense'
              name='identificationNumber'
              onBlur={handleBlur}
              onChange={handleChange}
              type='text'
              value={values.identificationNumber || ""}
              variant='outlined'
            />
          </Box>
        </Stack>

        {/* Entity affiliation */}
        <EntityAffiliationInput
          department={{
            departmentId: values.departmentId,
            onChange: (d) => {
              setFieldValue("departmentId", d?.id);
              setFieldValue("locationId", undefined);
            },
            error: errors.departmentId,
          }}
          location={{
            locationId: values.locationId,
            onChange: (l) => {
              setFieldValue("locationId", l?.id);
            },
            searchFilters: { departmentId: values.departmentId },
            createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
            isAutoSelectSingleOption: false,
            error: errors.locationId,
            disabled: !values.departmentId,
          }}
        />
      </ViewContentBlock>

      <Box>
        <FormControl margin='dense' fullWidth>
          <GeneralStatusInput
            statusEnumTypeName='VehicleStatus'
            currentValue={values.currentStatus}
            formikProps={statusFormikProps}
          />
          {/* <GeneralStatusDisplayAndInput value={values.newStatus} /> */}
          <FormHelperText error>
            {ValidationHelper.getFormikErrorsAsString(errors.status, {
              isIncludeNested: false,
            })}
          </FormHelperText>
        </FormControl>
      </Box>

      {/* Spec */}
      <ViewContentBlock>
        <FoldableBlock
          defaultIsFolded={false}
          trigger={{
            label: (
              <Typography component='span' variant='subtitle1'>
                Spec *
              </Typography>
            ),
          }}
        >
          <VehicleSpecInput formikProps={specFormikProps} />
        </FoldableBlock>
      </ViewContentBlock>

      {/* Other fields */}
      <ViewContentBlock>
        <FoldableBlock
          defaultIsFolded
          trigger={{
            label: (
              <Typography component='span' variant='subtitle1'>
                Other
              </Typography>
            ),
          }}
        >
          <TextField
            fullWidth
            error={Boolean(touched.mileage && errors.mileage)}
            helperText={touched.mileage && errors.mileage}
            label='Mileage'
            margin='dense'
            name='mileage'
            onBlur={handleBlur}
            onChange={handleChange}
            inputMode='decimal'
            value={values.mileage || ""}
            variant='outlined'
          />

          <FormControl margin='dense' fullWidth error={Boolean(errors.accessoryIds)}>
            <AccessoriesSelectOrCreate
              autocompleteProps={{
                withCreate: true,
                entityIds: values.accessoryIds,
                entities: undefined,
                onChange: (newValues) =>
                  setFieldValue("accessoryIds", newValues?.map((x) => x.id!) || []),
              }}
              onCreate={(newValue) => {
                setFieldValue("accessoryIds", [...values.accessoryIds!, newValue.id]);
              }}
            />
            <FormHelperText>
              {errors.accessoryIds && ValidationHelper.getFormikErrorsAsString(errors.accessoryIds)}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth margin='dense'>
            <DefaultVehicleVisualModelInput
              defaultVisualModelId={values.defaultVisualModelId}
              isDefaultVisualModelSelectedManually={values.isDefaultVisualModelSelectedManually}
              spec={values.spec}
              onChange={(newValue) => {
                setFieldValue("defaultVisualModelId", newValue?.defaultVisualModel?.id);
                setFieldValue(
                  "isDefaultVisualModelSelectedManually",
                  newValue?.isDefaultVisualModelSelectedManually,
                );
              }}
            />
          </FormControl>

          <FormControl fullWidth margin='dense'>
            <FileUploader
              multiple
              defaultFiles={FileItem.createManyFrom(
                values.uploadedAttachments || values.initialAttachments || values.attachments,
              )}
              onChange={(newFiles) => {
                setFieldValue("attachments", FileItem.toManyGeneralAttachmentInputDto(newFiles));
                setFieldValue("uploadedAttachments", newFiles);
              }}
              onValidationStatusChange={(filesValidationStatus) => {
                if (filesValidationStatus)
                  setFieldValue(
                    "isAttachmentFilesHaveErrors",
                    Object.values(filesValidationStatus).some((x) => x === false),
                  );
              }}
              onUploadStarted={() => {
                setFieldValue("isAttachmentFilesUploading", true);
              }}
              onUploadFinished={() => {
                setFieldValue("isAttachmentFilesUploading", false);
              }}
            />
          </FormControl>

          <FormControl margin='dense' fullWidth error={Boolean(touched.notes && errors.notes)}>
            <AppTextArea
              error={Boolean(touched.notes && errors.notes)}
              fullWidth
              helperText={touched.notes && errors.notes}
              mode='notes'
              name='notes'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.notes}
              variant='outlined'
            />
          </FormControl>
        </FoldableBlock>
      </ViewContentBlock>
    </Stack>
  );
}
