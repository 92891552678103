import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission } from "@/core/api/generated";

export default function ManagementPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Management' />}>
      <RouteCategoryIndex
        direction='column'
        childrenLinks={[
          {
            icon: <AppIcon of='companyProfile' />,
            title: "Company profile",
            to: ROUTE_PATH.MANAGEMENT_TENANT_PROFILE,
          },
          {
            icon: <AppIcon of='settings' />,
            title: "Company settings",
            to: ROUTE_PATH.TENANT_SETTINGS_EDIT,
          },
          {
            icon: <AppIcon of='paymentProfile' />,
            title: "Payment profile",
            to: ROUTE_PATH.MANAGEMENT_PAYMENT_PROFILE,
          },
          {
            icon: <AppIcon of='subscription' />,
            title: "Subscriptions",
            to: ROUTE_PATH.MANAGEMENT_SUBSCRIPTIONS,
          },
          {
            icon: <AppIcon of='invoices' />,
            title: "Invoices",
            to: ROUTE_PATH.INVOICES,
          },
          {
            icon: <AppIcon of='departments' />,
            title: "Departments",
            to: ROUTE_PATH.DEPARTMENTS,
          },
          {
            icon: <AppIcon of='location' />,
            title: "Locations",
            to: ROUTE_PATH.LOCATIONS,
          },
          {
            icon: <AppIcon of='spot' />,
            title: "Spots",
            to: ROUTE_PATH.SPOTS,
          },
          {
            icon: <AppIcon of='users' />,
            title: "Users",
            to: ROUTE_PATH.TENANT_USERS,
          },
          {
            icon: <AppIcon of='invites' />,
            title: "Invites",
            to: ROUTE_PATH.MANAGEMENT_INVITES,
          },
          {
            icon: <AppIcon of='roles' />,
            title: "Roles",
            to: ROUTE_PATH.TENANT_ROLES,
          },
          {
            icon: <AppIcon of='settings' />,
            title: "Settings",
            to: ROUTE_PATH.TENANT_SETTINGS_EDIT,
          },
          {
            icon: <AppIcon of='export' />,
            title: "Import data",
            to: ROUTE_PATH.MANAGEMENT_IMPORTS,
            permissions: [AppPermission.ImportRead],
          },
          {
            icon: <AppIcon of='integration' />,
            title: "Integration",
            to: ROUTE_PATH.MANAGEMENT_INTEGRATION,
          },
          {
            icon: <AppIcon of='webApp' />,
            title: "Webhooks",
            to: ROUTE_PATH.MANAGEMENT_INTEGRATION_WEBHOOKS,
          },
        ]}
      />
    </ViewLayoutV2>
  );
}
