import { Box, Stack, Typography } from "@mui/material";

import InlineCode from "@/common/components/Code/InlineCode";
import ChatHistorySkeleton from "@/common/components/Skeleton/ChatHistorySkeleton";
import ChatMessageSkeleton from "@/common/components/Skeleton/ChatMessageSkeleton";
import ChatSkeleton from "@/common/components/Skeleton/ChatSkeleton";
import SimpleCardListSkeleton from "@/common/components/Skeleton/SimpleCardListSkeleton";
import SimpleCardSkeleton from "@/common/components/Skeleton/SimpleCardSkeleton";

export default function DevSkeletons() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Skeletons
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>SimpleCardSkeleton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={1}>
        <SimpleCardSkeleton />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>SimpleCardListSkeleton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <SimpleCardListSkeleton />
        <SimpleCardListSkeleton itemCount={5} />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ChatMessageSkeleton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={1}>
        <ChatMessageSkeleton />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ChatHistorySkeleton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <ChatHistorySkeleton />
        <ChatHistorySkeleton itemCount={5} />
        <ChatHistorySkeleton itemCount={10} />
      </Stack>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>ChatSkeleton</InlineCode>
      </Typography>
      <Stack direction='row' spacing={2}>
        <ChatSkeleton />
      </Stack>
    </Box>
  );
}
