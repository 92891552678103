import { Card, CardHeader, Typography } from "@mui/material";

import { ChatEventDto, ChatParticipantDto } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import InlineChatParticipant from "../ChatParticipant/InlineChatParticipant";

interface Props {
  event: ChatEventDto;
  participant?: ChatParticipantDto | null;
}

export default function ChatEventListItem({ event, participant }: Props) {
  return (
    <Card>
      <CardHeader
        sx={{ py: 1 }}
        title={
          <>
            <Typography component='div' variant='subtitle2'>
              {event.body}
            </Typography>
            {/* <Box>
              <Chip
                size='small'
                color='primary'
                variant={"outlined"}
                label={event.category}
                
              />
              <Chip
                size='small'
                color='primary'
                variant={"outlined"}
                label={event.type}
                sx={{ ml: 1 }}
              />
            </Box> */}
          </>
        }
        subheader={
          <Typography component='span' variant='body2' color='text.secondary'>
            <Datetime datetime={event.createdAt} withDurationFromNow />
            {participant && (
              <>
                {" "}
                by{" "}
                <InlineChatParticipant
                  participant={participant}
                  withAvatar
                  avatarProps={{ size: 20 }}
                />
              </>
            )}
          </Typography>
        }
      />
      {/* <CardContent sx={{ pt: 0 }}>
      </CardContent> */}
    </Card>
  );
}
