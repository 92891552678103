import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import DataGrantCreateUpdate from "@/common/components/Entity/DataGrant/DataGrantCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";

export type DataGrantCreateUpdatePageQueryParams = GeneralQueryParams & {
  tenantRequestId?: string;
};

export default function DataGrantCreateUpdatePage() {
  const { dataGrantId } = useParams<{ dataGrantId?: string }>();
  const queryParams = useQueryParams<DataGrantCreateUpdatePageQueryParams>();
  const isCreate = !dataGrantId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader title={isCreate ? "Create new data grant" : "Edit data grant"} />
      }
    >
      <DataGrantCreateUpdate
        dataGrantId={dataGrantId}
        defaultValues={{
          tenantRequestsMeta: queryParams?.tenantRequestId
            ? {
                tenantRequestIds: [queryParams.tenantRequestId],
              }
            : undefined,
        }}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.DATA_GRANT_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
