import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { useCallback, useState } from "react";

import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { ContractDto, ContractReminderDto, ContractReminderUpdatedDto } from "@/core/api/generated";

import UpdateContractReminderModal from "./UpdateContractReminderModal";

export interface OwnProps {
  contract: ContractDto;
  reminderInfo: ContractReminderDto;
  onUpdate?: (newValue: ContractReminderUpdatedDto) => void;
  sx?: SxProps<Theme>;
}

export type ContractReminderInfoProps = OwnProps;

export default function ContractReminderInfo({
  contract,
  reminderInfo,
  onUpdate,
  sx,
}: ContractReminderInfoProps) {
  const { enqueueSnackbar } = useAppSnackbar();
  const thunkDispatch = useAppThunkDispatch();

  const [isUpdateReminderModalOpen, setIsUpdateReminderModalOpen] = useState(false);
  const [isDisableReminderConfirmationModalOpen, setIsDisableReminderConfirmationModalOpen] =
    useState(false);

  const handleDisableReminderConfirmed = useCallback(async () => {
    try {
      const response =
        await apiClient.contractsApi.apiV1ContractsContractIdRemindersReminderIdEnabledTogglePut({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractId: contract.id!,
          reminderId: reminderInfo.id!,
        });
      enqueueSnackbar("Reminder disabled.", { variant: "success" });
      setIsDisableReminderConfirmationModalOpen(false);
      onUpdate && onUpdate(response.data);
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  }, [contract, reminderInfo]);

  return (
    <Box sx={sx}>
      <ListItem
        sx={{ margin: 0, py: 0 }}
        secondaryAction={
          <MenuWithTrigger
            withAuthCloseOnClick
            trigger={
              <IconButton edge='end'>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            <MenuItem onClick={() => setIsUpdateReminderModalOpen(true)}>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit reminder</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setIsDisableReminderConfirmationModalOpen(true)}>
              <ListItemIcon>
                <AppIcon
                  of={reminderInfo.isEnabled ? "reminderOff" : "reminderOn"}
                  fontSize='small'
                />
              </ListItemIcon>
              <ListItemText>{reminderInfo.isEnabled ? "Disable" : "Enable"} reminder</ListItemText>
            </MenuItem>
          </MenuWithTrigger>
        }
      >
        <ListItemIcon>
          <AppIcon of='reminder' />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction='row' spacing={1} sx={{ width: "fit-content" }} flexWrap={"wrap"}>
              <InlineApiEnumValue
                type='ContractReminderType'
                value={reminderInfo.type}
                direction='column'
                withHelperTooltip
                sx={{ width: "fit-content" }}
              />
              <Chip
                size='small'
                color={reminderInfo.isEnabled ? "secondary" : "error"}
                variant={"outlined"}
                label={reminderInfo.isEnabled ? "Enabled" : "Disabled"}
              />
              <Chip
                size='small'
                color={reminderInfo.isFired ? "success" : "secondary"}
                variant={"outlined"}
                label={reminderInfo.isFired ? "Sent" : "Not sent"}
              />
            </Stack>
          }
          secondary={<Datetime datetime={reminderInfo.nextFireAt} withDurationFromNow />}
        />
      </ListItem>

      {/* Edit reminder */}
      <UpdateContractReminderModal
        open={isUpdateReminderModalOpen}
        onClose={() => setIsUpdateReminderModalOpen(false)}
        updateContractReminderProps={{
          contractId: contract.id!,
          reminder: reminderInfo,
          onUpdate: (newValue) => {
            setIsUpdateReminderModalOpen(false);
            onUpdate && onUpdate(newValue);
          },
        }}
      />

      {/* Disable reminder confirmation */}
      <ConfirmationModal
        title={`${reminderInfo.isEnabled ? "Disable" : "Enable"} the reminder?`}
        body={
          <>
            Disable this and all future reminders of type{" "}
            <InlineApiEnumValue
              type='ContractReminderType'
              value={reminderInfo.type}
              sx={{ fontWeight: "bold" }}
            />
          </>
        }
        open={isDisableReminderConfirmationModalOpen}
        onClose={() => setIsDisableReminderConfirmationModalOpen(false)}
        onCancel={() => setIsDisableReminderConfirmationModalOpen(false)}
        onConfirm={handleDisableReminderConfirmed}
      />
    </Box>
  );
}
