import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIcon from "@/common/components/Icons/AppIcon";
import RouteCategoryIndex from "@/common/components/Route/RouteCategoryIndex";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function FlowsPage() {
  return (
    <ViewLayoutV2 header={<SimpleViewPageHeader title='Flows' />}>
      <RouteCategoryIndex
        direction='row'
        childrenLinks={[
          {
            icon: <AppIcon of='flows' />,
            title: "Assessment flows",
            to: ROUTE_PATH.ASSESSMENT_FLOWS(),
          },
        ]}
      />
    </ViewLayoutV2>
  );
}
