import BaseEntityLink, {
  BaseEntityLinkInheritableProps,
} from "@/common/components/Entity/components/BaseEntityLink";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AdminTenantDto, AppPermission, TenantDto } from "@/core/api/generated";

import TenantInline, { TenantInlineProps } from "./TenantInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: TenantDto | AdminTenantDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<TenantInlineProps>;
}

export default function TenantLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.AdminTenantManage])}
      to={ROUTE_PATH.ADMIN_TENANT_VIEW(entity?.id || entityId || "")}
      icon={<AppIcon of='entity' inText />}
      content={
        <TenantInline
          entity={entity}
          entityId={entityId}
          withIcon={false}
          withTooltip={false}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company"}
    />
  );
}
