import { Box, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

import AppVisibilitySensor from "./AppVisibilitySensor";
import ScrollIntoView from "./Scroll/ScrollIntoView";

export interface HighlightElementProps {
  isHighlighted?: boolean;
  /** Duration of highlight */
  durationMs?: number;
  /** Automatically scrolls to the highlighted element (if should be highlighted). */
  withAutoScroll?: boolean;
}

interface Props extends HighlightElementProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

/** Highlights provided component. */
export default function HighlightElement({
  isHighlighted,
  durationMs,
  withAutoScroll,
  children,
  sx,
}: Props) {
  const [_isHighlighted, setIsHighlighted] = useState(false);
  const [_isVisible, setIsVisible] = useState(false);
  const hideTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const shouldAutoScroll = isHighlighted && withAutoScroll;

  // highlight if visible
  useEffect(() => {
    if (!_.isNil(isHighlighted)) {
      setIsHighlighted(isHighlighted && _isVisible);
      if (!_isVisible) {
        clearTimeout(hideTimeoutRef.current);
      }
    }
  }, [isHighlighted, _isVisible]);

  // hide after timeout
  useEffect(() => {
    if (_isHighlighted && _isVisible && _.isNumber(durationMs)) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = setTimeout(() => setIsHighlighted(false), durationMs);
    }
  }, [durationMs, _isHighlighted, _isVisible]);

  return (
    <Box sx={sx}>
      <ScrollIntoView
        shouldScroll={shouldAutoScroll}
        scrollDelayMs={300}
        scrollOptions={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <AppVisibilitySensor
          onChange={(isVisible) => {
            setIsVisible(isVisible);
          }}
        >
          <Box
            sx={{
              ...((_isHighlighted && { backgroundColor: (th) => th.palette.highlight.main }) || {}),
              transition: "background-color 0.5s ease",
            }}
          >
            {children}
          </Box>
        </AppVisibilitySensor>
      </ScrollIntoView>
    </Box>
  );
}
