import { LoadingButton } from "@mui/lab";
import { Box, FormControl, Stack, TextField } from "@mui/material";
import { Formik, getIn } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import AdminTenantAutocomplete from "@/common/components/Entity/Tenant/AdminTenantAutocomplete";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { AdminUserCompanyDto, AdminUserDto, UpdateAdminUserDto } from "@/core/api/generated";

export interface AdminUserEditPageQueryParams extends GeneralQueryParams {
  tenantId?: string;
}

export default function UserEditPage() {
  const { userId } = useParams<{ userId?: string }>();
  const { tenantId } = useQueryParams<AdminUserEditPageQueryParams>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const userRequest = useApiRequest(
    apiClient.adminUsersApi.apiV1AdminUsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      skip: !userId,
    },
  );
  const user = userRequest?.data;

  const selectedUserProfile = useMemo<AdminUserDto | AdminUserCompanyDto | undefined>(
    () =>
      tenantId ? user?.companies?.find((x) => x.id === tenantId) || undefined : user || undefined,
    [user, tenantId],
  );

  return (
    <CreateUpdatePageLayout
      breadcrumbs={{
        replacements: {
          isWaitInfinitely: true,
          idBreadcrumb: user && {
            idValue: user.id || "",
            newTitle: `${user?.email}`,
          },
        },
      }}
      header={<SimpleViewPageHeader title={"Edit user info"} />}
    >
      <Formik<BaseFormikValues & UpdateAdminUserDto & { email?: string }>
        enableReinitialize
        initialValues={{
          email: user?.email || "",
          personName: {
            firstName: selectedUserProfile?.personName?.firstName || "",
            lastName: selectedUserProfile?.personName?.lastName || "",
          },
          phoneNumber: selectedUserProfile?.phoneNumber || "",

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // firstName: Yup.string().required("First name is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            await apiClient.adminUsersApi.apiV1AdminUsersUserIdPut({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              userId: user!.id!,
              updateAdminUserDto: {
                tenantId: tenantId || undefined,
                personName: values.personName,
                phoneNumber: values.phoneNumber,
              },
            });
            enqueueSnackbar("User updated.", { variant: "success" });

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <AppAvatar user={user} size={100} />
                </Box>

                <Box>
                  <FormControl margin='dense' fullWidth>
                    <AdminTenantAutocomplete entityId={tenantId} disabled fullWidth />
                  </FormControl>

                  <TextField
                    disabled
                    fullWidth
                    required
                    label='Email'
                    margin='dense'
                    type='email'
                    value={values.email}
                    variant='outlined'
                  />

                  <FormControl margin='dense' fullWidth>
                    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2 }}>
                      <TextField
                        autoFocus
                        error={Boolean(
                          getIn(touched, "personName.firstName") &&
                            getIn(errors, "personName.firstName"),
                        )}
                        fullWidth
                        required
                        helperText={
                          getIn(touched, "personName.firstName") &&
                          getIn(errors, "personName.firstName")
                        }
                        label='First name'
                        margin='none'
                        name='personName.firstName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.personName?.firstName}
                        variant='outlined'
                      />

                      <TextField
                        error={Boolean(
                          getIn(touched, "personName.lastName") &&
                            getIn(errors, "personName.lastName"),
                        )}
                        fullWidth
                        required
                        helperText={
                          getIn(touched, "personName.lastName") &&
                          getIn(errors, "personName.lastName")
                        }
                        label='Last name'
                        margin='none'
                        name='personName.lastName'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.personName?.lastName}
                        variant='outlined'
                      />
                    </Box>
                  </FormControl>

                  <PhoneInput
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    label='Phone number'
                    margin='dense'
                    variant='outlined'
                    fullWidth
                    required
                    color='primary'
                    placeholder='Phone number'
                    onBlur={handleBlur}
                    value={values.phoneNumber || undefined}
                    onChange={(e, ph, iso) => {
                      setFieldValue(`phoneNumber`, ph);
                    }}
                  />
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  loading={isSubmitting}
                  disabled={
                    !values.personName?.firstName ||
                    !values.personName?.lastName ||
                    !values.phoneNumber
                  }
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </CreateUpdatePageLayout>
  );
}
