// Source:
// https://stackoverflow.com/a/3809435/5168794
// https://regexr.com/37i6s
const httpOrHttpsUrlRegex =
  /(?<url>https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/g;

// Source: http://jsfiddle.net/ghvj4gy9/

const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// https://stackoverflow.com/a/13653180/5168794
const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const uuidSearchRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;

// https://stackoverflow.com/a/24573236/5168794
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const guidSearchRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;

// https://stackoverflow.com/a/20988824/5168794
const objectIdRegex = /^[a-f\d]{24}$/i;
const objectIdSearchRegex = /[a-f\d]{24}/i;

export class RegexHelper {
  /** Checks if string is HTTP or HTTPS URL. */
  public static testHttpUrl(str: string): boolean {
    return new RegExp(httpOrHttpsUrlRegex).test(str);
  }

  /** Returns HTTP or HTTPS URLs. */
  public static getHttpUrls(str: string): Array<{ url: string }> {
    const matches = str.matchAll(httpOrHttpsUrlRegex); // !!! requires global regex

    return Array.from(matches)
      .map((x) => ({
        url: (x.groups && x.groups["url"]) || "",
      }))
      .filter((x) => !!x.url);
  }

  /** Checks if string is email. */
  public static isEmail(str: string): boolean {
    return new RegExp(emailRegex).test(str);
  }

  /** Checks if string is id (UUID, GUID, ObjectId). */
  public static isId(str: string): boolean {
    return (
      new RegExp(uuidRegex).test(str) ||
      new RegExp(guidRegex).test(str) ||
      new RegExp(objectIdRegex).test(str)
    );
  }

  /** Replaces first id in string. */
  public static replaceId(str: string, replaceValue: string): string {
    return str
      .replace(new RegExp(uuidSearchRegex), replaceValue)
      .replace(new RegExp(guidSearchRegex), replaceValue)
      .replace(new RegExp(objectIdSearchRegex), replaceValue);
  }

  /** Replaces all ids in string. */
  public static replaceAllIds(str: string, replaceValue: string): string {
    return str
      .replaceAll(new RegExp(uuidSearchRegex, "g"), replaceValue)
      .replaceAll(new RegExp(guidSearchRegex, "g"), replaceValue)
      .replaceAll(new RegExp(objectIdSearchRegex, "g"), replaceValue);
  }
}
