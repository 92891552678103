import { IBasePageLayout } from "@/common/ts/layout";

import ViewLayoutV2 from "../ViewLayoutV2";

interface Props extends IBasePageLayout {}

export default function CreateUpdatePageLayout({ header, body, children }: Props) {
  return (
    <ViewLayoutV2 header={header} sx={{ pb: 10 }}>
      {body || children}
    </ViewLayoutV2>
  );
}
