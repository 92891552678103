import { AnyAction, Middleware } from "redux";
import { ThunkDispatch } from "redux-thunk";

export const paymentsMiddleware: Middleware<any, any, ThunkDispatch<any, any, AnyAction>> =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      default:
        break;
    }

    return await next(action);
  };
