import { GeneralTagInputDto, TagTargetType } from "@/core/api/generated";

export class GeneralTagHelper {
  static createTagInput(targetType: TagTargetType, targetId: string): GeneralTagInputDto {
    return {
      targetType: targetType,
      target: {
        targetId: targetId,
      },
    };
  }
}
