import BaseEntityAutocompleteOrCreate, {
  BaseEntityAutocompleteOrCreateInheritableProps,
} from "@/common/components/Entity/components/BaseEntityAutocompleteOrCreate";
import { VehicleDto } from "@/core/api/generated";

import VehicleAutocomplete, { VehicleAutocompleteProps } from "./VehicleAutocomplete";
import VehicleCreateUpdate, { VehicleCreateUpdateProps } from "./VehicleCreateUpdate";
import VehicleCreateUpdateModal from "./VehicleCreateUpdateModal";

type Props = BaseEntityAutocompleteOrCreateInheritableProps<
  VehicleDto,
  VehicleCreateUpdateProps["defaultValues"],
  VehicleAutocompleteProps,
  VehicleCreateUpdateProps
>;

/** VehicleAutocomplete + CreateVehicleAndCustomer */
export default function VehicleAutocompleteOrCreate({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  return (
    <BaseEntityAutocompleteOrCreate<
      VehicleDto,
      VehicleCreateUpdateProps["defaultValues"],
      VehicleAutocompleteProps,
      VehicleCreateUpdateProps
    >
      createFormPlacement={createFormPlacement}
      onCreateStart={onCreateStart}
      onCreate={onCreate}
      renderAutocomplete={(params) => (
        <VehicleAutocomplete
          {...autocompleteProps}
          withCreate
          createOptionTitle={(inputValue) => `Create new vehicle "${inputValue}"`}
          onCreate={(newOption) => {
            params.setDefaultValues({
              ...createUpdateProps?.defaultValues,
              plateNo:
                (newOption?.inputValue && {
                  plateNo: newOption?.inputValue,
                }) ||
                undefined,
            });
            params.setIsCreate(true);
            params.onCreateStart && params.onCreateStart();
          }}
        />
      )}
      renderCreateUpdate={(params) => (
        <VehicleCreateUpdate
          defaultValues={params.defaultValues}
          onCreate={params.onCreate}
          onSave={(newValue) => {
            params.setIsCreate(false);
          }}
        />
      )}
      renderCreateUpdateModal={(params) => (
        <VehicleCreateUpdateModal
          open={params.isCreate}
          onClose={() => params.setIsCreate(false)}
          createUpdateProps={{
            defaultValues: params.defaultValues,
            onCreate: params.onCreate,
            onSave: (newValue) => {
              params.setIsCreate(false);
            },
          }}
        />
      )}
    />
  );
}
