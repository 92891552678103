import _ from "lodash";

import { PoolItemDto } from "@/core/api/generated";

export class PoolItemHelper {
  public static canUpdate(poolItem: PoolItemDto | null | undefined): boolean {
    return _.isNil(poolItem?.usedByEntity);
  }

  public static canUpdateStatus(poolItem: PoolItemDto | null | undefined): boolean {
    return _.isNil(poolItem?.usedByEntity);
  }

  public static canDelete(poolItem: PoolItemDto | null | undefined): boolean {
    return _.isNil(poolItem?.usedByEntity);
  }
}
