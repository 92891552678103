import { Button, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import UserRolesDisplay from "@/App/MainAppView/Management/Users/components/UserRolesDisplay";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import { AppPermission, UserDto } from "@/core/api/generated";

interface Props {
  user: UserDto;
}

export default function RolesAndPermissionsTabContent({ user }: Props) {
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={<PageTabHeader title={"Roles and Permissions"} />}
      >
        <Stack direction='column' spacing={2}>
          <AuthorizedElement permissions={[AppPermission.TenantUserRead]}>
            <UserRolesDisplay user={user} />{" "}
          </AuthorizedElement>
        </Stack>
      </ViewLayout>
    </PageTabContent>
  );
}
