import { GeneralScopeDto } from "@/core/api/generated";

export class GeneralScopeHelper {
  static serializeScopeToString(scope?: GeneralScopeDto | null): string | null {
    if (!scope) {
      return null;
    }
    return JSON.stringify(scope);
  }

  static deserializeScopeFromString(scopeStr?: string | null): GeneralScopeDto | null {
    if (!scopeStr) {
      return null;
    }
    return JSON.parse(scopeStr);
  }
}
