import { Alert, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleOperations, {
  VehicleOperationsTabs,
} from "@/common/components/EntityComposed/Operations/VehicleOperations";
import { RepairOperationDto } from "@/core/api/generated";

interface Props {
  repairOperation: RepairOperationDto;
}

export default function RelatedOperationsTabContent({ repairOperation }: Props) {
  return (
    <PageTabContent spacing={0}>
      <Stack direction='column' sx={{ p: 2, backgroundColor: (t) => t.palette.background.paper }}>
        <Alert severity='info'>
          Here you can see related operations. For instance, operations for the same damages or
          operations that are in the same context.
        </Alert>
      </Stack>

      <VehicleOperations
        vehicleId={repairOperation.vehicle?.id}
        anyVehicleDamageIds={repairOperation.items
          ?.map((x) => x.damage?.id || "")
          .filter((x) => !!x)}
        displayProps={{
          header: false,
          filters: false,
          viewVariant: ViewLayoutVariant.Tab,
        }}
        excludeTabs={[
          VehicleOperationsTabs.RepairOperations,
          VehicleOperationsTabs.AccessoryChecks,
        ]}
      />
    </PageTabContent>
  );
}
