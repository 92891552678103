import { LinearProgress, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { EntityType, VehicleDto } from "@/core/api/generated";

import AssetSubscriptionPaginatedList from "../../../AssetSubscription/ListView/AssetSubscriptionPaginatedList";

interface Props {
  vehicle: VehicleDto;
}

export default function AssetSubscriptionsTabContent({ vehicle }: Props) {
  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsByEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: EntityType.Vehicle,
      entityId: vehicle.id || "",
    },
    {
      deps: [vehicle.id],
      skip: !vehicle.id,
    },
  );
  const asset = assetRequest?.data;

  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        {assetRequest.isLoading && <LinearProgress />}

        {!assetRequest.isLoading && assetRequest.isEnded && !asset && <EntityNotFoundAlert />}

        {asset && (
          <AssetSubscriptionPaginatedList
            defaultValues={{
              assetId: asset.id!,
            }}
            displayProps={{
              viewVariant: ViewLayoutVariant.Tab,
              breadcrumbs: false,
              header: false,
              filters: true,
            }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
}
