import ContactSelect, { ContactSelectProps } from "@/common/components/Contact/ContactSelect";
import { CustomerContactDto, CustomerDto } from "@/core/api/generated";

import CustomerContactCard from "./CustomerContactCard";

interface Props extends Omit<ContactSelectProps<CustomerContactDto>, "options" | "renderContact"> {
  customer?: CustomerDto | null;
}

export default function CustomerContactSelect({ customer, ...contactSelectProps }: Props) {
  return (
    <ContactSelect
      options={
        customer?.contacts!.map((contact) => ({
          id: contact.id!,
          email: contact.email,
          name: contact.personName?.name,
          phoneNumber: contact.phoneNumber,
          data: contact,
        })) || []
      }
      renderContact={(option) => <CustomerContactCard contact={option.data} />}
      {...contactSelectProps}
    />
  );
}
