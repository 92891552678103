import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

/** Element for displaying program code */
export default function BlockCode({ sx, children }: Props): JSX.Element {
  return (
    <Box
      component='code'
      sx={{
        display: "block",
        backgroundColor: "#f2f3fc",
        color: (theme) => theme.palette.primary.main,
        boxShadow: (theme) => (theme.shadows as string[])[1],
        paddingY: 1,
        paddingX: 2,
        borderRadius: "2px",
        whiteSpace: "pre",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
