import { createSlice } from "@reduxjs/toolkit";

import { AppThunk } from "../..";
import { apiClient } from "../../../core/api/ApiClient";
import { CheckoutManualDto, SubscriptionPlanDto } from "../../../core/api/generated";

export interface SubscriptionPlansState {
  plans?: SubscriptionPlanDto[];
}

const initialState: SubscriptionPlansState = {
  plans: undefined,
};

export const subscriptionPlansSlice = createSlice({
  name: "subscriptionPlans",
  initialState,
  reducers: {},
});

export const {} = subscriptionPlansSlice.actions;

export const subscriptionPlansReducer = subscriptionPlansSlice.reducer;

export const selectSubscriptionPlan =
  (checkoutManualDto: CheckoutManualDto): AppThunk =>
  async () => {
    await apiClient.paymentsApi.apiV1PaymentsCheckoutManualPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      checkoutManualDto,
    });
  };
