/** Mirrors ProblemDetailsConfig.cs on BE. */
export const problemDetailsConfig = {
  /** Describes members of ValidationProblemDetails.Errors. */
  errors: {
    /** General validation error. E.g. for the whole form or DTO. */
    general: "",
  },
  /** Describes custom members of ProblemDetails added via ProblemDetails.Extensions on BE. */
  extensions: {
    /** Stores additional debug info including full exception details with StackTrace. */
    debug: "debug",
    /** Id of operation during which the error occurred.
     *  For API, usual operation is in HTTP request.
     *  Used for tracking operation related telemetry (requests, logs, errors, etc).
     */
    operationId: "operationId",
    /** Id of HTTP request generated by BE. */
    requestId: "requestId",
  },
};
