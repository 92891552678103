import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

import AppLink, { AppLinkProps } from "@/common/components/Link/AppLink";

export interface BaseEntityLinkProps extends Omit<AppLinkProps, "content"> {
  content: ReactNode;
  withIcon?: boolean;
}

/** Props to be inherited by specific implementations. */
export interface BaseEntityLinkInheritableProps {
  enabled?: boolean;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: string;
  sx?: SxProps<Theme>;
  onClick?: AppLinkProps["onClick"];
}

export default function BaseEntityLink({
  content,
  icon,
  withIcon = true,
  withTooltip = false,
  ...otherProps
}: BaseEntityLinkProps) {
  return (
    <AppLink withTooltip={withTooltip} {...otherProps}>
      {withIcon && icon && (
        <Box component='span' sx={{ mr: 1 }}>
          {icon}
        </Box>
      )}
      {/* By default one link should be in one row */}
      {content}
    </AppLink>
  );
}
