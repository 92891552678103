import { Box, FormHelperText, Stack, Typography } from "@mui/material";

import FoldableBlock from "@/common/components/Display/FoldableBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationType,
  NegotiationValueType,
} from "@/core/api/generated";

import GeneralDiscountDisplay from "../../General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";

interface OwnProps {
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
}

type Props = OwnProps;

export default function NegotiationProposalValue({ negotiation, proposal }: Props) {
  return negotiation.type === NegotiationType.Value && proposal.value ? (
    <Box>
      {renderIf()
        .if(proposal.value.valueType === NegotiationValueType.Price)
        .then(
          <FoldableBlock
            defaultIsFolded
            trigger={{
              variant: "outlined",
              label: <Typography variant='h6'>Price</Typography>,
            }}
          >
            <Stack spacing={1}>
              {proposal.value.price && (
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <FieldValue label='Price'>
                      <GeneralPriceDisplay price={proposal.value.price.price} />
                    </FieldValue>

                    <FieldValue label='Min price' isEmpty={!proposal.value.price.minPrice}>
                      <GeneralPriceDisplay price={proposal.value.price.minPrice} />
                    </FieldValue>

                    <FieldValue label='Max price' isEmpty={!proposal.value.price.maxPrice}>
                      <GeneralPriceDisplay price={proposal.value.price.maxPrice} />
                    </FieldValue>

                    <FieldValue label='Discount' isEmpty={!proposal.value.price.discount}>
                      <GeneralDiscountDisplay discount={proposal.value.price.discount} />
                    </FieldValue>

                    <FieldValue label='Tax' isEmpty={!proposal.value.price.tax}>
                      <GeneralTaxDisplay tax={proposal.value.price.tax} />
                    </FieldValue>

                    <GeneralPriceSummaryDisplay
                      direction='row'
                      summary={{
                        currency:
                          proposal.value.price?.currency || proposal.value.price.price?.currency,
                        subTotal: proposal.value.price.subTotal,
                        subTotalIncDiscount: proposal.value.price.subTotalIncDiscount,
                        discount: proposal.value.price?.discount,
                        tax: proposal.value.price?.tax,
                        total: proposal.value.price?.total,
                      }}
                      displayProps={{
                        calcExplanation: true,
                      }}
                    />
                  </Stack>

                  {proposal.value.price.prev && (
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        variant: "outlined",
                        label: <Typography variant='h6'>Previous</Typography>,
                      }}
                    >
                      <Stack spacing={1}>
                        <FieldValue label='Price'>
                          <GeneralPriceDisplay price={proposal.value.price.prev.price} />
                        </FieldValue>

                        <FieldValue label='Min price' isEmpty={!proposal.value.price.prev.minPrice}>
                          <GeneralPriceDisplay price={proposal.value.price.prev.minPrice} />
                        </FieldValue>

                        <FieldValue label='Max price' isEmpty={!proposal.value.price.prev.maxPrice}>
                          <GeneralPriceDisplay price={proposal.value.price.prev.maxPrice} />
                        </FieldValue>

                        <FieldValue label='Discount' isEmpty={!proposal.value.price.prev.discount}>
                          <GeneralDiscountDisplay discount={proposal.value.price.prev.discount} />
                        </FieldValue>

                        <FieldValue label='Tax' isEmpty={!proposal.value.price.prev.tax}>
                          <GeneralTaxDisplay tax={proposal.value.price.prev.tax} />
                        </FieldValue>

                        <GeneralPriceSummaryDisplay
                          direction='row'
                          summary={{
                            currency:
                              proposal.value.price.prev.currency ||
                              proposal.value.price.prev.price?.currency,
                            subTotal: proposal.value.price.prev.subTotal,
                            subTotalIncDiscount: proposal.value.price.prev.subTotalIncDiscount,
                            discount: proposal.value.price.prev.discount,
                            tax: proposal.value.price.prev.tax,
                            total: proposal.value.price.prev.total,
                          }}
                          displayProps={{
                            calcExplanation: true,
                          }}
                        />
                      </Stack>
                    </FoldableBlock>
                  )}

                  {proposal.value.price.initial && (
                    <FoldableBlock
                      defaultIsFolded
                      trigger={{
                        variant: "outlined",
                        label: <Typography variant='h6'>Initial</Typography>,
                      }}
                    >
                      <Stack spacing={1}>
                        <FieldValue label='Price'>
                          <GeneralPriceDisplay price={proposal.value.price.initial.price} />
                        </FieldValue>

                        <FieldValue
                          label='Min price'
                          isEmpty={!proposal.value.price.initial.minPrice}
                        >
                          <GeneralPriceDisplay price={proposal.value.price.initial.minPrice} />
                        </FieldValue>

                        <FieldValue
                          label='Max price'
                          isEmpty={!proposal.value.price.initial.maxPrice}
                        >
                          <GeneralPriceDisplay price={proposal.value.price.initial.maxPrice} />
                        </FieldValue>

                        <FieldValue
                          label='Discount'
                          isEmpty={!proposal.value.price.initial.discount}
                        >
                          <GeneralDiscountDisplay
                            discount={proposal.value.price.initial.discount}
                          />
                        </FieldValue>

                        <FieldValue label='Tax' isEmpty={!proposal.value.price.initial.tax}>
                          <GeneralTaxDisplay tax={proposal.value.price.initial.tax} />
                        </FieldValue>

                        <GeneralPriceSummaryDisplay
                          direction='row'
                          summary={{
                            currency:
                              proposal.value.price.initial.currency ||
                              proposal.value.price.initial.price?.currency,
                            subTotal: proposal.value.price.initial.subTotal,
                            subTotalIncDiscount: proposal.value.price.initial.subTotalIncDiscount,
                            discount: proposal.value.price.initial.discount,
                            tax: proposal.value.price.initial.tax,
                            total: proposal.value.price.initial.total,
                          }}
                          displayProps={{
                            calcExplanation: true,
                          }}
                        />
                      </Stack>
                    </FoldableBlock>
                  )}
                </Stack>
              )}
            </Stack>
          </FoldableBlock>,
        )
        .else(
          <FormHelperText error>
            Proposal value type {proposal.value.valueType} is not supported yet!
          </FormHelperText>,
        )
        .render()}
    </Box>
  ) : undefined;
}
