import {
  Box,
  Card,
  CardContent,
  FormControl,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import { GeneralHistoryEventInputDto, GeneralHistoryEventType } from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppTypography from "../../Text/AppTypography";
import GeneralHistoryEventOfArbitraryInput from "./GeneralHistoryEventOfArbitraryInput";

interface Props {
  formikProps: CustomFormikSubProps<GeneralHistoryEventInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function GeneralHistoryEventInput({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue, setFieldError } = formikProps;

  return (
    <Box sx={sx}>
      <Stack spacing={2}>
        <FormControl margin='none' fullWidth error={Boolean(errors?.type)}>
          <ApiEnumAutocomplete
            type='GeneralHistoryEventType'
            value={values?.type}
            onlyEnumValues={[GeneralHistoryEventType.Arbitrary]}
            label='Event type'
            required
            textFieldProps={{
              error: Boolean(errors?.type),
              helperText: errors?.type,
            }}
            onChange={(newValue) => setFieldValue("type", newValue)}
          />
        </FormControl>

        {/* Input based on type */}
        {values?.type && (
          <Card>
            <CardContent>
              <Typography variant='h4' sx={{ mb: 2 }}>
                <InlineApiEnumValue type='GeneralHistoryEventType' value={values?.type} /> event
                data
              </Typography>

              {renderIf()
                .if(values?.type === GeneralHistoryEventType.Arbitrary)
                .then(
                  <GeneralHistoryEventOfArbitraryInput
                    formikProps={FormikHelper.getSubProps(
                      formikProps,
                      "arbitrary",
                      (x) => x.arbitrary,
                    )}
                  />,
                )
                .else(
                  <AppTypography color='error'>
                    History event of type {values.type} is not supported.
                  </AppTypography>,
                )
                .render()}
            </CardContent>
          </Card>
        )}
      </Stack>
    </Box>
  );
}
