import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import CustomTagCreateUpdate, { CustomTagCreateUpdateProps } from "./CustomTagCreateUpdate";

export interface OwnProps {
  createUpdateProps: CustomTagCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function CustomTagCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps.customTagId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} custom tag
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <CustomTagCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
