import { useParams } from "react-router-dom";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import GeneralEventLogsEmbeddedView from "@/common/components/Entity/GeneralEventLog/GeneralEventLogsEmbeddedView";

type OwnProps = Record<string, never>;
type Props = OwnProps;

export default function GeneralEventLogsPage(props: Props) {
  const { entityId } = useParams<{ entityId?: string }>();

  return (
    <>
      <GeneralEventLogsEmbeddedView entityId={entityId} />
    </>
  );
}
