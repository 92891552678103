import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";

import GeneralHistoryView, { GeneralHistoryViewProps } from "./GeneralHistoryView";

export interface OwnProps {
  viewProps: GeneralHistoryViewProps;
}

type Props = OwnProps & DialogProps;

export default function GeneralHistoryViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <GeneralHistoryView
          onDeleted={() => {
            dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown");
          }}
          {...viewProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...viewProps?.displayProps,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
