import axios, { AxiosResponse } from "axios";

import {
  FileDto,
  FileReferenceDto,
  GeneralAttachmentDto,
  GeneralAttachmentInputDto,
  MimeBaseType,
} from "@/core/api/generated";
import { IDownloadFileApiResult } from "@/core/api/types/downloadFileApiResult";

import { HttpHelper } from "./http";
import { UrlHelper } from "./url";

const baseMimeTypeMap: Record<string, MimeBaseType> = {
  application: MimeBaseType.Application,
  audio: MimeBaseType.Audio,
  image: MimeBaseType.Image,
  message: MimeBaseType.Message,
  model: MimeBaseType.Model,
  multipart: MimeBaseType.Multipart,
  text: MimeBaseType.Text,
  video: MimeBaseType.Video,
};

export class FileHelper {
  /** Converts blob into a Blob URL (a special url that points to an object in the browser's memory). */
  public static getBlobUrl(blob: Blob | File | null | undefined): string | undefined {
    if (!blob) {
      return undefined;
    }
    return URL.createObjectURL(blob);
  }

  /** Creates File from Blob. */
  public static createFileFromBlob(blob: Blob, fileName: string, mimeType?: string): File {
    return new File([blob], fileName, {
      type: blob.type || mimeType,
    });
  }

  /** Get Blob from API response. */
  public static async getDownloadFileApiResult(
    downloadFileFunc: () => Promise<AxiosResponse<any, any>>,
  ): Promise<IDownloadFileApiResult> {
    const response = await downloadFileFunc();
    const parsedContent = HttpHelper.parseContentHeaders(response.headers);

    const downloadResult: IDownloadFileApiResult = {
      blob: response.data as Blob,
      contentType: parsedContent.contentType!,
      filename: parsedContent.contentDispositionInfo?.directives?.filename,
    };
    return downloadResult;
  }

  /** Initiates browser download of specified Blob. */
  public static downloadBlob(blob: Blob, filename: string) {
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = filename;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    // Remove link from body
    document.body.removeChild(link);
  }

  /** Initiates browser download of specified Blob. */
  public static async downloadBlobFromApiResult(result: IDownloadFileApiResult) {
    FileHelper.downloadBlob(result.blob, result.filename || "file.unknown");
  }

  /** Initiates browser download of file from specified file URL. */
  public static downloadFileByUrl(fileUrl: string, filename: string) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = filename;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    document.body.removeChild(link);
  }

  public static openFileByUrl(fileUrl: string, filename: string, openInNewTab = true) {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = filename;
    link.target = openInNewTab ? "_blank" : "";

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    document.body.removeChild(link);
  }

  /** E.g. image/jpeg -> image */
  public static getBaseMimeType(mimeType?: string): MimeBaseType {
    if (!mimeType) {
      return MimeBaseType.None;
    }

    const baseType = mimeType.split("/")[0];

    return baseMimeTypeMap[baseType] || MimeBaseType.Unknown;
  }

  public static getFileType(mimeType?: string) {
    const baseMimeType = FileHelper.getBaseMimeType(mimeType);

    const result = {
      isApplication: baseMimeType === MimeBaseType.Application,
      isAudio: baseMimeType === MimeBaseType.Audio,
      isImage: baseMimeType === MimeBaseType.Image,
      isVideo: baseMimeType === MimeBaseType.Video,
      isOther: false,
    };
    result.isOther = [result.isApplication, result.isAudio, result.isImage, result.isVideo].every(
      (x) => x === false,
    );

    return result;
  }

  /** Returns file extension (with leading dot by default). */
  public static getFileExtension(
    filename?: string | null,
    withLeadingDot = true,
  ): string | undefined {
    if (!filename) {
      return undefined;
    }
    if (UrlHelper.checkValidHttpUrl(filename)) {
      filename = new URL(filename).pathname;
    }
    return filename && /[.]/.test(filename)
      ? (withLeadingDot ? /\.[^.]+$/ : /[^.]+$/).exec(filename)![0]
      : undefined;
  }

  /** Returns matching thumbnail URL or file URL. */
  public static getFileThumbnailUrlOrDefault(
    file?: FileDto | FileReferenceDto | null,
    desiredSize?: IImageSize,
    sizeDelta: IImageSize = {
      width: 50,
      height: 50,
    },
  ): string | null | undefined {
    if (!file) {
      return null;
    }

    // return original URL if no desired size
    if (!desiredSize) {
      return file.url;
    }

    const thumbnail = file.thumbnails?.find(
      (x) =>
        x.size!.width! >= Math.max(desiredSize.width - sizeDelta.width, 0) &&
        x.size!.width! <= desiredSize.width + sizeDelta.width &&
        x.size!.height! >= Math.max(desiredSize.height - sizeDelta.height, 0) &&
        x.size!.height! <= desiredSize.height + sizeDelta.height,
    );
    return thumbnail?.url || file.url;
  }

  /** Sends HTTP HEAD request to see if file is available at the URL. */
  public static async checkFileExistsByUrl(fileUrl: string | null | undefined): Promise<boolean> {
    if (!fileUrl) {
      return false;
    }
    const response = await axios.head(fileUrl);
    return response.status >= 200 && response.status <= 299;
  }

  // #region Attachments

  public static toGeneralAttachmentInputDtoList(
    attachments: Array<GeneralAttachmentDto | GeneralAttachmentInputDto> | null | undefined,
    options?: { mustBeCopied?: boolean },
  ): GeneralAttachmentInputDto[] | undefined {
    return attachments?.map((x) => ({ ...x, mustBeCopied: options?.mustBeCopied ?? false }));
  }

  // #endregion
}
