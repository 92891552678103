import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DamageCostEvaluationPaginatedList from "@/common/components/Entity/DamageCostEvaluation/ListView/DamageCostEvaluationPaginatedList";

export default function DamageCostEvaluationsPage() {
  return (
    <>
      <DamageCostEvaluationPaginatedList />
    </>
  );
}
