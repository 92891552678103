import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function AppInitErrorPage() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography component='div' variant='h4'>
        Application init error
      </Typography>
      <Box>Unable to start the application.</Box>
      <Box>
        <Link component={RouterLink} to={"/"}>
          Go home
        </Link>
      </Box>
    </Box>
  );
}
