import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AccessoryDto, AccessoryReferenceDto, AppPermission } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AccessoryInline, { AccessoryInlineProps } from "./AccessoryInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: AccessoryDto | AccessoryReferenceDto;
  entityId?: string | null;
  inlineProps?: Partial<AccessoryInlineProps>;
}

export default function AccessoryLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.AccessoryRead])}
      to={ROUTE_PATH.ACCESSORY_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={<AccessoryInline entity={entity} entityId={entityId} {...inlineProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Accessory"}
    />
  );
}
