import { Paper, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

import { TextHelper } from "@/common/helpers/text";
import { TabularRowSelectionMap } from "@/common/ts/dataTabular";

import AppIconButton from "../Button/AppIconButton";
import AppIcon from "../Icons/AppIcon";

interface ActionTriggersProps<TBulkActionIdentifier extends string> {
  currentAction: TBulkActionIdentifier | undefined;
  startAction: (props: TBulkActionIdentifier) => void;
  selectedIds?: GridRowSelectionModel;
}

interface ActionHandlersProps<TBulkActionIdentifier extends string> {
  selectedIds?: GridRowSelectionModel;
  currentAction: TBulkActionIdentifier | undefined;
  cancelAction: () => void;
  completeAction: () => void;
}

export interface BulkActionsOwnProps<TBulkActionIdentifier extends string> {
  definition: Record<TBulkActionIdentifier, string>;
  actionTriggers: (props: ActionTriggersProps<TBulkActionIdentifier>) => ReactNode;
  actionHandlers: (props: ActionHandlersProps<TBulkActionIdentifier>) => ReactNode;
  rowSelection: {
    rowSelectionMap: TabularRowSelectionMap | undefined;
    setRowSelectionMap: Dispatch<SetStateAction<TabularRowSelectionMap>> | undefined;
  };
}

export interface BulkActionsExternalProps<TBulkActionIdentifier extends string> {
  enabled: boolean;
  definition: Record<TBulkActionIdentifier, string>;
  actionTriggers: (props: ActionTriggersProps<TBulkActionIdentifier>) => ReactNode;
  actionHandlers: (props: ActionHandlersProps<TBulkActionIdentifier>) => ReactNode;
}

export interface BulkActionsInternalProps<TBulkActionIdentifier extends string> {
  rowSelection: {
    rowSelectionMap: TabularRowSelectionMap | undefined;
    setRowSelectionMap: Dispatch<SetStateAction<TabularRowSelectionMap>> | undefined;
  };
}

export default function BulkActions<TBulkActionIdentifier extends string>({
  rowSelection,
  definition,
  actionTriggers,
  actionHandlers,
}: BulkActionsOwnProps<TBulkActionIdentifier>) {
  const [currentAction, setCurrentAction] = useState<TBulkActionIdentifier | undefined>(undefined);

  const selectedCount = useMemo(
    () => Object.keys(rowSelection?.rowSelectionMap || {}).length,
    [rowSelection?.rowSelectionMap],
  );
  const isVisible = useMemo(
    () => Object.keys(rowSelection?.rowSelectionMap || {}).length !== 0,
    [rowSelection?.rowSelectionMap],
  );

  const message = `${selectedCount} ${TextHelper.pluralize("row", selectedCount || 0)} selected`;

  return (
    isVisible && (
      <>
        {/* Bulk action panel */}
        <Paper
          sx={{
            position: "absolute",
            bottom: "4%",
            left: "45%",
            margin: "0 auto",
            maxWidth: "600px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            pl: 2,
            pr: 2,
            borderRadius: (t) => t.shapeCustom.borderRadius,
          }}
        >
          <Typography mr={4} variant='body1'>
            {message}
          </Typography>

          {/** Bulk actions buttons */}
          {actionTriggers &&
            actionTriggers({
              currentAction: currentAction,
              selectedIds: Object.keys(rowSelection?.rowSelectionMap || {}),
              startAction: (actionDefinition) => setCurrentAction(actionDefinition),
            })}

          {/** Default actions */}
          <AppIconButton
            tooltipProps={{
              title: "Clear selection",
            }}
            onClick={() => rowSelection?.setRowSelectionMap && rowSelection.setRowSelectionMap({})}
          >
            <AppIcon of='close' />
          </AppIconButton>
        </Paper>

        {/** Bulk actions handlers */}
        {actionHandlers &&
          actionHandlers({
            selectedIds: Object.keys(rowSelection?.rowSelectionMap || {}),
            currentAction: currentAction,
            cancelAction: () => setCurrentAction(undefined),
            completeAction: () => {
              setCurrentAction(undefined);
              rowSelection?.setRowSelectionMap && rowSelection.setRowSelectionMap({});
            },
          })}
      </>
    )
  );
}
