import { Typography } from "@mui/material";

import GoBackButton from "@/common/components/Button/GoBackButton";

import CreateForm from "./CreateForm";

const CreatePage = () => {
  return (
    <>
      <Typography
        component='div'
        variant='h4'
        sx={{ display: "flex", alignItems: "center", mb: 2 }}
      >
        <GoBackButton />
        Send notification manually
      </Typography>

      <CreateForm />
    </>
  );
};

export default CreatePage;
