import { Box, ClickAwayListener, Grow, Paper, Popper, SxProps, Theme } from "@mui/material";
import _ from "lodash";
import { ReactNode, forwardRef, useRef, useState } from "react";

import AppIconButton, { AppIconButtonProps } from "./AppIconButton";

export interface DropdownIconButtonProps
  extends Omit<AppIconButtonProps, "sx" | "children" | "ref"> {
  sx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  dropdownContent: ReactNode | ((params: { handleClose: () => void }) => ReactNode);
  autoCloseOnClick?: boolean;
  /** Primary button content */
  children?: ReactNode;
}

/** Inline element for displaying program code */
export default forwardRef<HTMLButtonElement, DropdownIconButtonProps>(function DropdownIconButton(
  {
    sx,
    paperSx,
    dropdownContent,
    autoCloseOnClick = true,
    children,
    ...buttonProps
  }: DropdownIconButtonProps,
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <AppIconButton
        ref={(r) => {
          anchorRef.current = r;
          ref && (_.isFunction(ref) ? ref(r) : (ref.current = r));
        }}
        sx={{
          // paddingRight: 1,
          ...sx,
        }}
        // NB: if needed to be disabled, then disable and add comment why
        // disableFocusRipple
        // disableRipple
        // disableTouchRipple
        {...buttonProps}
        onClick={(e) => {
          setIsOpen((prevOpen) => !prevOpen);
          buttonProps?.onClick && buttonProps?.onClick(e);
        }}
      >
        <Box component='span' sx={{ display: "inline-flex" }}>
          {children}

          {/* Vadym's note: need UI/UX designer help with displaying expand icon gracefully. */}
          {/* {children}
          {isOpen && <AppIcon of='arrowDropUp' fontSize='small' />}
          {!isOpen && <AppIcon of='arrowDropDown' fontSize='small' />} */}

          {/* <Badge
            sx={{
              "& .MuiBadge-badge": {
                top: "50%",
                p: 0,
              },
            }}
            badgeContent={
              <Box component='span'>
                {isOpen ? (
                  <AppIcon of='arrowDropUp' fontSize='small' />
                ) : (
                  <AppIcon of='arrowDropDown' fontSize='small' />
                )}
              </Box>
            }
            overlap='circular'
            // color='transparent'
            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "right",
            // }}
          >
            {children}
          </Badge> */}
        </Box>
      </AppIconButton>

      {/* Dropdown content */}
      <Popper
        sx={{
          zIndex: (th) => th.zIndex.modal, // ensure visible in modals
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ ...paperSx }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box onClick={() => autoCloseOnClick && handleClose()}>
                  {_.isFunction(dropdownContent) && dropdownContent({ handleClose })}
                  {!_.isFunction(dropdownContent) && dropdownContent}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});
