import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, TenantRequestDto, TenantRequestReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import TenantRequestInline, { TenantRequestInlineProps } from "./TenantRequestInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: TenantRequestDto | TenantRequestReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<TenantRequestInlineProps>;
}

export default function TenantRequestLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantRequestRead])}
      to={ROUTE_PATH.TENANT_REQUEST_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <TenantRequestInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company request"}
    />
  );
}
