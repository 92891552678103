import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { SpotDto } from "@/core/api/generated";

import SpotAutocomplete, { SpotAutocompleteProps } from "./SpotAutocomplete";
import SpotCreateUpdate, { SpotCreateUpdateProps } from "./SpotCreateUpdate";
import SpotCreateUpdateModal from "./SpotCreateUpdateModal";

interface OwnProps {
  autocompleteProps: SpotAutocompleteProps;
  createUpdateProps?: Pick<SpotCreateUpdateProps, "defaultValues">;
  createFormPlacement?: "modal" | "page";
  onCreateStart?: () => void;
  onCreate: (newValue: SpotDto) => void;
}

type Props = OwnProps;

export default function SpotAutocompleteOrCreate({
  autocompleteProps,
  createUpdateProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  const [isCreate, setIsCreate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    SpotCreateUpdateProps["defaultValues"] | undefined
  >(undefined);

  return (
    <Stack spacing={2}>
      {/* 1. Search */}
      {!isCreate && (
        <Box>
          <SpotAutocomplete
            {...autocompleteProps}
            withCreate
            createOptionTitle={(inputValue) => `Create new spot "${inputValue}"`}
            onCreate={(newOption) => {
              setDefaultValues({
                ...createUpdateProps?.defaultValues,
                name: newOption?.inputValue || createUpdateProps?.defaultValues?.name || undefined,
              });
              setIsCreate(true);
              onCreateStart && onCreateStart();
            }}
          />
        </Box>
      )}

      {/* 2. Create */}
      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && (
                <SpotCreateUpdateModal
                  open={isCreate}
                  onClose={() => setIsCreate(false)}
                  createUpdateProps={{
                    defaultValues: defaultValues,
                    onCreate: onCreate,
                    onSave: (newValue) => {
                      setIsCreate(false);
                    },
                  }}
                />
              )}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='text'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new spot
                    </Typography>
                  </Stack>

                  <SpotCreateUpdate
                    defaultValues={defaultValues}
                    onCreate={onCreate}
                    onSave={(newValue) => {
                      setIsCreate(false);
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}
