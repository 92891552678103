import { AssessmentFlowDto, TenantRequestOperationType } from "@/core/api/generated";

import { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssessmentFlowRelatedTenantRequests, {
  AssessmentFlowRelatedTenantRequestsProps,
} from "./AssessmentFlowRelatedTenantRequests";

interface Props
  extends BaseEntityLinkInheritableProps,
    Omit<AssessmentFlowRelatedTenantRequestsProps, "operationType"> {
  assessmentFlow: AssessmentFlowDto;
}

/** TenantRequests related to the AssessmentFlow. */
export default function AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation({
  assessmentFlow,
}: Props) {
  return (
    <AssessmentFlowRelatedTenantRequests
      assessmentFlow={assessmentFlow}
      operationType={TenantRequestOperationType.DamageCostEvaluation}
    />
  );
}
