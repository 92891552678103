import { Box, SxProps, Theme } from "@mui/material";
import { useMemo, useState } from "react";

import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { ROUTE_PATH } from "@/common/constants/routing";
import { renderIf } from "@/common/helpers/render/renderIf";
import { GeneralByWhoDto, WhoType } from "@/core/api/generated";

import AppAvatar, { AppAvatarProps } from "../../../Avatar/AppAvatar";
import AppLink from "../../../Link/AppLink";
import GeneralByWhoDetailsPopover from "./GeneralByWhoDetailsPopover";

const defaultDisplayProps = {
  avatar: true,
  info: true,
  detailsPopover: true,
};

interface Props {
  who: GeneralByWhoDto | null | undefined;
  name?: string;
  email?: string;
  displayProps?: typeof defaultDisplayProps;
  avatarProps?: Partial<AppAvatarProps>;
  sx?: SxProps<Theme>;
}

export default function GeneralByWhoDisplay({
  who,
  name,
  email,
  displayProps,
  avatarProps,
  sx,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [poperOpenAnchorEl, setPoperOpenAnchorEl] = useState<HTMLElement | null>(null);
  const isPoperOpen = Boolean(poperOpenAnchorEl);

  const isInfoPresent = useMemo(() => who && who.personName, [who]);

  const title =
    name ||
    email ||
    (who?.type === WhoType.System && "System") ||
    who?.personName?.name ||
    who?.email ||
    who?.phone ||
    (who?.type === WhoType.User && "User") ||
    "";

  return (
    <Box component='span' sx={{ display: "inline-flex", ...sx }}>
      <Box
        component='span'
        onMouseEnter={(e) => displayProps?.detailsPopover && setPoperOpenAnchorEl(e.currentTarget)}
        onMouseLeave={() => displayProps?.detailsPopover && setPoperOpenAnchorEl(null)}
      >
        {renderIf()
          .if(!!isInfoPresent)
          .then(
            <AppLink
              enabled={!!who?.userId}
              to={ROUTE_PATH.USER_VIEW(who?.userId)}
              underline='none'
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              <Box component='span'>
                {displayProps?.avatar && (
                  <AppAvatar
                    who={who}
                    size={20}
                    withPopover={false}
                    sx={{ mr: 0.5 }}
                    {...avatarProps}
                  />
                )}

                {displayProps?.info && <Box component='span'>{title}</Box>}
              </Box>
            </AppLink>,
          )
          .elseif(!!who?.userId)
          .then(
            <InlineUser
              userId={who?.userId}
              withAvatar={displayProps?.avatar}
              withLink
              withLoading
              withTooltip={false}
              withPoper={false}
            />,
          )
          .else(
            <Box component='span' sx={{ display: "inline-flex", alignItems: "center" }}>
              {displayProps?.avatar && <Box component='span'></Box>}

              {displayProps?.info && <Box component='span'>{title}</Box>}
            </Box>,
          )
          .render()}
      </Box>

      {/* Popover */}
      {displayProps?.detailsPopover && (
        <GeneralByWhoDetailsPopover
          who={who}
          open={isPoperOpen}
          anchorEl={poperOpenAnchorEl}
          onClose={() => setPoperOpenAnchorEl(null)}
        />
      )}
    </Box>
  );
}
