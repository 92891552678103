import {
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  TextFieldProps,
} from "@mui/material";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useRef } from "react";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { enumService } from "@/common/services/enum";
import { ValidationHelper } from "@/common/validation";
import { CurrencyCode, GeneralInsuranceDto, GeneralInsuranceInputDto } from "@/core/api/generated";

import DropdownButton from "../../../Button/DropdownButton";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";

export interface GeneralInsuranceInputProps
  extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment" | "endAdornment"> {
  value?: GeneralInsuranceInputDto | GeneralInsuranceDto | null;
  defaultCurrencyCode?: CurrencyCode;
  allowCurrencyEdit?: boolean;
  /** Select currency from tenant or user settings by default; */
  useCurrentCurrencyByDefault?: boolean;
  onChange?: (newValue?: GeneralInsuranceInputDto | null) => void;
}

export default function GeneralInsuranceInput({
  value,
  defaultCurrencyCode = CurrencyCode.Usd,
  allowCurrencyEdit = true,
  useCurrentCurrencyByDefault,
  onChange,
  ...textFieldProps
}: GeneralInsuranceInputProps) {
  const currentCurrency = useCurrentCurrency();

  const currencyComputed = value?.currency ||
    currentCurrency || {
      code: defaultCurrencyCode || CurrencyCode.Usd,
    };

  const isUsedCurrentCurrencyByDefaultRef = useRef(false);

  // ensure currency is always selected
  useEffect(() => {
    if (
      !isUsedCurrentCurrencyByDefaultRef.current &&
      !value?.currency &&
      currentCurrency &&
      useCurrentCurrencyByDefault
    ) {
      onChange &&
        onChange({
          ...value,
          currency: currentCurrency || {
            code: defaultCurrencyCode || CurrencyCode.Usd,
          },
        });
      isUsedCurrentCurrencyByDefaultRef.current = true;
    }
  }, [value?.currency, useCurrentCurrencyByDefault, currentCurrency]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue =
        _.isNil(e.target.value) || _.isEmpty(e.target.value) ? undefined : +e.target.value;

      onChange &&
        onChange({
          price: newInputValue,
          currency: value?.currency || currencyComputed,
        });
    },
    [onChange, value],
  );

  const handleSelectCurrency = useCallback((newCurrencyCode?: CurrencyCode) => {
    onChange &&
      onChange({
        ...value,
        currency: {
          ...value?.currency,
          code: newCurrencyCode,
        },
      });
  }, []);

  return (
    <TextField
      label='Insurance'
      inputMode='decimal'
      value={value?.price ?? ""}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position='end'>
              <DropdownButton
                disabled={!allowCurrencyEdit || textFieldProps.disabled}
                variant='text'
                size='extraSmall'
                color='text'
                dropdownContent={
                  <MenuList>
                    {enumService
                      .getEnumDtos("CurrencyCode")
                      .filter((x) => x.value !== CurrencyCode.None)
                      .map((x, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            handleSelectCurrency(x.value as CurrencyCode);
                          }}
                        >
                          <ListItemText>
                            <InlineApiEnumValue
                              type='CurrencyCode'
                              value={x.value as CurrencyCode}
                            />
                          </ListItemText>
                        </MenuItem>
                      ))}
                  </MenuList>
                }
              >
                <InlineApiEnumValue
                  type='CurrencyCode'
                  value={value?.currency?.code || CurrencyCode.Usd}
                />
              </DropdownButton>
            </InputAdornment>
          </>
        ),
      }}
      {..._.omit(textFieldProps, ["helperText"])}
      helperText={
        textFieldProps.helperText && ValidationHelper.isValidErrors(textFieldProps.helperText)
          ? ValidationHelper.getFormikErrorsAsString(textFieldProps.helperText)
          : textFieldProps.helperText
      }
    />
  );
}
