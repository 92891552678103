import { DependencyList, useCallback, useMemo, useRef } from "react";
import uuid4 from "uuid4";

import { useTriggerRender } from "./useTriggerRender";

// export enum DefaultDepsMapKeys {
//   Refetch = "Refetch",
// }

export type DefaultDepsMapForRefetch = {
  refetch: DepsMapValue;
};

export type DefaultDepsMapForChange = {
  change: DepsMapValue;
};

export type DepsMapValue = any;
export type DepsMap = Record<string, DepsMapValue>;
export type DepsMapKey<T extends DepsMap> = string & keyof T;

interface Props<TDepsMap extends DepsMap> {
  defaultDepsMap?: TDepsMap;
  /** Fires when the specified dep key changed/triggered. */
  onChange?: (params: { depKey: DepsMapKey<TDepsMap> }) => void;
}

export interface PropagatedDeps<TDepsMap extends DepsMap = DepsMap> {
  /** List of all deps. */
  deps: DependencyList;
  /** Deps token. Changes when any of the deps changes. */
  depsToken: string;
  /** {[dep key]: [dep token]} */
  depsMap: TDepsMap;
  /** Triggers change on specified dep key. */
  trigger: (depKey: DepsMapKey<TDepsMap>) => void;
}

/** Allows to define and pass custom deps object which can be listened by children components.
 * Internally stores unique objects that can trigger deps change.
 * Works with deep compare.
 */
export function usePropagatedDeps<TDepsMap extends DepsMap>({
  defaultDepsMap,
  onChange,
}: Props<TDepsMap> = {}): PropagatedDeps<TDepsMap> {
  const { triggerRender } = useTriggerRender();
  const depsTokenRef = useRef<DepsMapValue>({});
  const depsMapRef = useRef<TDepsMap>(defaultDepsMap || ({} as TDepsMap));

  const trigger = useCallback((depKey: DepsMapKey<TDepsMap>) => {
    depsTokenRef.current = uuid4(); // use id because deep compare can be used
    depsMapRef.current[depKey] = uuid4() as DepsMapValue; // use id because deep compare can be used
    triggerRender();
    onChange && onChange({ depKey: depKey });
  }, []);

  //#region Result

  // always return the same instance of the result object (same reference),
  // to allow callers to capture the result once and access the same instance regardless of re-renders.

  const computeResult = (): PropagatedDeps<TDepsMap> => ({
    deps: [depsTokenRef.current],
    depsToken: depsTokenRef.current,
    depsMap: depsMapRef.current,
    trigger,
  });

  const initialResult = useMemo(() => computeResult(), []);
  const result = useRef(initialResult);
  Object.assign(result.current, computeResult());

  //#endregion

  return result.current;
}
