import { LoadingButton } from "@mui/lab";
import { Box, DialogContent, FormControl, Stack } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import LocationsAutocompleteOrCreate from "@/common/components/Entity/Location/LocationsAutocompleteOrCreate";
import AppModal, { AppModalProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { TextHelper } from "@/common/helpers/text";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto } from "@/core/api/generated";

interface Props extends Omit<AppModalProps, "children"> {
  department: DepartmentDto;
  onAdded: () => void;
}

export default function DepartmentAddLocationsModal({ department, onAdded, ...modalProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedLocationIds, setSelectedLocationIds] = useState<string[] | undefined>(undefined);

  const reset = () => {
    setSelectedLocationIds(undefined);
  };

  const handleAdd = async () => {
    setIsSubmitting(true);

    try {
      await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdLocationsPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        departmentId: department.id!,
        addDepartmentLocationsDto: {
          locationIds: selectedLocationIds,
        },
      });
      enqueueSnackbar(
        `${TextHelper.pluralize("Location", selectedLocationIds?.length || 0)} added to department`,
        {
          variant: "success",
        },
      );
      modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
      onAdded && onAdded();
      reset();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal fullWidth keepMounted={false} {...modalProps}>
      <AppModalTitle
        title={"Add locations to department"}
        withCloseIcon
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      />
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <FormControl margin='dense' fullWidth>
              <LocationsAutocompleteOrCreate
                autocompleteProps={{
                  fullWidth: true,
                  entityIds: selectedLocationIds,
                  entities: undefined,
                  searchFilters: {
                    notForDepartmentId: department?.id || undefined,
                    isWithDepartment: false,
                  },
                  onChange: (newValues) =>
                    setSelectedLocationIds(newValues?.map((x) => x.id!) || undefined),
                }}
                createUpdateProps={{
                  defaultValues: { departmentId: department.id, address: department.address },
                }}
                inputDisplayProps={{
                  withDepartmentInput: false,
                }}
                onCreate={(newValue) => {
                  setSelectedLocationIds([...(selectedLocationIds || []), newValue.id!]);
                  modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown");
                  onAdded && onAdded();
                  reset();
                }}
              />
            </FormControl>
          </Box>

          <LoadingButton
            fullWidth
            variant='contained'
            disabled={_.isEmpty(selectedLocationIds)}
            loading={isSubmitting}
            onClick={handleAdd}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogContent>
    </AppModal>
  );
}
