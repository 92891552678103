import { useFormikContext } from "formik";

import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";

export interface FormikComputedFieldProps<Values, TComputed> {
  /** If `false`, compute func won't be called.
    @default true
 */
  isEnabled?: boolean;
  /** List of dependencies which trigger recalculation of the computed field value. */
  deps: ReadonlyArray<unknown>;
  /** Function that calculates the new value. */
  compute: (values: Values) => TComputed | Promise<TComputed>;
  /** Handle new computed value */
  onComputed: (newValue: TComputed) => void;
}

/** Allows to recalculate computed/dynamic field in Formik form values. */
export default function FormikComputedField<Values, TComputed>({
  isEnabled = true,
  deps,
  compute,
  onComputed,
}: FormikComputedFieldProps<Values, TComputed>) {
  const { values } = useFormikContext<Values>();

  useEffectWithDeepCompare(
    () => {
      (async function () {
        if (isEnabled) {
          const computed = await compute(values);
          onComputed(computed);
        }
      })();
    },
    [...deps], // don't include callbacks in deps as they can be new functions every time
  );

  return null;
}
