import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import { VehicleDto } from "@/core/api/generated";

import AssetView from "../../../Asset/View/AssetView";

interface Props {
  vehicle: VehicleDto;
}

export default function AssetTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column'>
        {!vehicle.assetMeta?.assetId && <EntityNotFoundAlert />}

        {vehicle.assetMeta?.assetId && (
          <AssetView
            assetId={vehicle.assetMeta?.assetId}
            asset={undefined}
            displayProps={{
              breadcrumbs: false,
              header: true,
              actions: true,
              viewVariant: ViewLayoutVariant.Tab,
            }}
            headerProps={{
              withLink: true,
              typographyProps: { variant: "h1" },
            }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
}
