import { TypographyProps } from "@mui/material";
import { useHistory } from "react-router";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import BaseEntityView, {
  BaseEntityViewInheritableProps,
} from "@/common/components/Entity/components/BaseEntityView";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { apiClient } from "@/core/api/ApiClient";
import { AdminFileDto, EntityType, FileDto } from "@/core/api/generated";

import FileMenu from "../FileMenu";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import ThumbnailsTabContent from "./Tabs/ThumbnailsTabContent";
import UsageLockHistoryTabContent from "./Tabs/UsageLockHistoryTabContent";
import UsageLocksTabContent from "./Tabs/UsageLocksTabContent";

export enum FileViewPageTabs {
  Overview = "Overview",
  Thumbnails = "Thumbnails",
  UsageLocks = "UsageLocks",
  UsageLockHistory = "UsageLockHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<FileDto> {
  fileId: string | null | undefined;
  file: AdminFileDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function FileView({
  fileId,
  file,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityViewInAdminArea(EntityType.File),
      viewVariant: displayProps.viewVariant,
    },
  });

  const fileRequest = useApiRequest(
    apiClient.adminFilesApi.apiV1AdminFilesFileIdGet,
    {
      fileId: fileId!,
      isIncludeDeleted: true,
    },
    {
      deps: [fileId],
      skip: !fileId || !!file,
    },
  );
  file = file || fileRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        file && {
          idValue: file.id!,
          newTitle: file.id || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.File}
      entityId={fileId}
      entity={file}
      entityRequest={fileRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`File ${file?.id}`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.ADMIN_FILE_VIEW(file?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              secondaryActions={
                displayProps.actions &&
                file && (
                  <FileMenu
                    entity={file}
                    onDelete={(newValue) =>
                      newValue ? fileRequest.replaceData(newValue) : history.goBack()
                    }
                    onUpdate={(newValue) =>
                      newValue ? fileRequest.replaceData(newValue) : fileRequest.refetch()
                    }
                    displayProps={{
                      actions: {
                        delete: true,
                        undelete: true,
                      },
                    }}
                  />
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={FileViewPageTabs}
          defaultTabId={FileViewPageTabs.Overview}
          viewVariant={displayProps.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: FileViewPageTabs.Overview, isHideable: false },
            { label: "Thumbnails", value: FileViewPageTabs.Thumbnails, isHideable: false },
            { label: "Usage locks", value: FileViewPageTabs.UsageLocks },
            { label: "Usage lock history", value: FileViewPageTabs.UsageLockHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            file && (
              <>
                {activeTab === FileViewPageTabs.Overview && <OverviewTabContent file={file} />}
                {activeTab === FileViewPageTabs.Thumbnails && <ThumbnailsTabContent file={file} />}
                {activeTab === FileViewPageTabs.UsageLocks && <UsageLocksTabContent file={file} />}
                {activeTab === FileViewPageTabs.UsageLockHistory && (
                  <UsageLockHistoryTabContent file={file} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
