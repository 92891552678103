import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { FocusEventHandler, useEffect, useMemo, useState } from "react";

import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";

import AppIcon from "../Icons/AppIcon";

export type ApiEnumAutocompleteOption = BaseAutocompleteOption<ApiEnumValue<ApiEnumName>>;

const apiEnumToOption = <TEnumName extends ApiEnumName>(
  type: TEnumName,
  enumValue: ApiEnumValue<TEnumName>,
): ApiEnumAutocompleteOption => ({
  id: enumValue,
  title: enumService.getEnumValueName(type, enumValue),
  optionType: AutocompleteOptionType.Normal,
  data: enumValue,
});

export interface ApiEnumsAutocompleteProps<TEnumName extends ApiEnumName> {
  type: TEnumName;
  values: Array<ApiEnumValue<TEnumName>> | null | undefined;
  allowNone?: boolean;
  /** Auto-select option if it's the single option in the list and the input is required. */
  isAutoSelectSingleOption?: boolean;
  disabledEnumValues?: Array<ApiEnumValue<TEnumName>> | null;
  onlyEnumValues?: Array<ApiEnumValue<TEnumName>> | null;
  excludeEnumValues?: Array<ApiEnumValue<TEnumName>> | null;
  onChange?: (newValues?: Array<ApiEnumValue<TEnumName>> | null) => void;

  // autocomplete specific props
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  label?: string;
  textFieldProps?: TextFieldProps;
  onBlur?: FocusEventHandler;
}

/** Autocomplete for enums generated from Open API spec of WebApi. */
export default function ApiEnumsAutocomplete<TEnumName extends ApiEnumName>({
  type,
  values,
  disabled,
  required,
  size,
  label,
  textFieldProps,
  onBlur,
  allowNone = false,
  isAutoSelectSingleOption = true,
  disabledEnumValues,
  onlyEnumValues,
  excludeEnumValues,
  onChange,
}: ApiEnumsAutocompleteProps<TEnumName>) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ApiEnumAutocompleteOption[] | null>(null);
  const [inputValue, setInputValue] = useState("");

  const keyValuePairs = useMemo(() => enumService.getEnumObjKeyValuePairs(type), [type]);
  const onlyEnumValuesMap = useMemo(
    () =>
      onlyEnumValues && !_.isEmpty(onlyEnumValues)
        ? _.chain(onlyEnumValues)
            .keyBy((x) => x)
            .mapValues((x) => true)
            .value()
        : undefined,
    [onlyEnumValues],
  );
  const excludeEnumValuesMap = useMemo(
    () =>
      excludeEnumValues && !_.isEmpty(excludeEnumValues)
        ? _.chain(excludeEnumValues)
            .keyBy((x) => x)
            .mapValues((x) => true)
            .value()
        : undefined,
    [excludeEnumValues],
  );

  const options = useMemo(
    () =>
      keyValuePairs
        .filter((kvp) => (allowNone ? true : kvp.value !== "None"))
        .filter((kvp) =>
          onlyEnumValuesMap ? onlyEnumValuesMap[kvp.key.toString()] === true : true,
        )
        .filter((kvp) => (excludeEnumValuesMap ? !excludeEnumValuesMap[kvp.key.toString()] : true))
        .map((kvp) => ({ value: kvp.value }))
        .map((x) => apiEnumToOption(type, x.value)),
    [keyValuePairs, onlyEnumValuesMap, excludeEnumValuesMap],
  );

  useEffect(() => {
    if (_.isNil(values) || _.isEmpty(values)) {
      setSelectedOptions(null);
    } else if (values && !selectedOptions) {
      const values2 = values;
      setSelectedOptions(values2.map((x) => apiEnumToOption<typeof type>(type, x)));
    }
  }, [values, selectedOptions, options]);

  // handle isAutoSelectSingleOption
  useEffect(() => {
    const singleOption = options.length === 1 ? options[0] : undefined;
    if (
      isAutoSelectSingleOption &&
      required &&
      !isLoading &&
      _.isEmpty(values) &&
      !selectedOptions &&
      singleOption
    ) {
      setSelectedOptions([singleOption]);
      onChange &&
        onChange(singleOption.data ? [singleOption.data as ApiEnumValue<TEnumName>] : undefined);
    }
  }, [isAutoSelectSingleOption, required, isLoading, values, selectedOptions, options]);

  return (
    <Autocomplete
      sx={{ minWidth: 200 }}
      disabled={disabled}
      size={size}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value2) => option.id === value2.id}
      getOptionLabel={(option) => option.title.toString()}
      getOptionDisabled={
        (disabledEnumValues &&
          ((option) =>
            (option.data && disabledEnumValues.includes(option.data as ApiEnumValue<TEnumName>)) ||
            false)) ||
        undefined
      }
      options={options}
      loading={isLoading}
      multiple
      autoComplete
      includeInputInList
      filterSelectedOptions={false}
      blurOnSelect={false}
      disableCloseOnSelect
      value={selectedOptions || []}
      inputValue={inputValue}
      onBlur={onBlur}
      onChange={(event, newValues) => {
        setSelectedOptions(newValues);
        onChange &&
          onChange(
            newValues?.map((x) => x.data as ApiEnumValue<TEnumName>).filter((x) => !_.isNil(x)),
          );
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          label={label || type}
          placeholder='Search...'
          fullWidth
          required={required}
          value={inputValue}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <ListItem {...props} key={option.id}>
            <ListItemIcon>
              <AppIcon of='data' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography component='div' variant='body1'>
                  {option.title}
                </Typography>
              }
              secondary={
                <>
                  {enumService.getEnumValueDescription(
                    type,
                    option.data as ApiEnumValue<TEnumName>,
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
    />
  );
}
