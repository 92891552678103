import { useParams } from "react-router-dom";

import VehicleView from "@/common/components/Entity/Vehicle/View/VehicleView";

export default function VehicleViewPage() {
  const { vehicleId } = useParams<{ vehicleId?: string }>();

  return (
    <VehicleView
      vehicleId={vehicleId}
      vehicle={undefined}
      displayProps={{
        breadcrumbs: true,
        header: true,
        actions: true,
      }}
    />
  );
}
