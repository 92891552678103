import { DurationRepresentation } from "@/common/ts/duration";

import DurationDisplay from "./DurationDisplay";

interface Props {
  /** .NET TimeSpan string. */
  value?: string | null;
}

export default function TimeSpanDisplay({ value }: Props) {
  return <DurationDisplay value={value} representation={DurationRepresentation.DotNetTimeSpan} />;
}
