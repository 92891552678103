import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import TenantRequestUpdateResult, {
  TenantRequestUpdateResultProps,
} from "./TenantRequestUpdateResult";

export interface OwnProps {
  updateProps: TenantRequestUpdateResultProps;
}

type Props = OwnProps & DialogProps;

export default function TenantRequestUpdateResultModal({ updateProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Update company request result
      </AppModalTitle>
      <DialogContent>
        <TenantRequestUpdateResult {...updateProps} />
      </DialogContent>
    </Dialog>
  );
}
