import { Stack } from "@mui/material";
import Box from "@mui/material/Box";

import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { ROUTE_PATH } from "@/common/constants/routing";
import {
  GeneralInspectorDto,
  UserDto,
  UserReferenceDto,
  UserSuspensionInfoDto,
} from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import FieldValue from "../../Form/Display/FieldValue";
import AppIcon from "../../Icons/AppIcon";
import AppLink from "../../Link/AppLink";
import { AppEntityLinkProps } from "../../Link/types";

interface Props extends AppEntityLinkProps {
  info: UserSuspensionInfoDto | null | undefined;
}

export default function UserSuspensionInfoDisplay({ info }: Props) {
  if (!info) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xxd: "1fr",
            md: "1fr 1fr",
          },
          gap: 2,
        }}
      >
        <FieldValue label='Status before suspension' isEmpty={!info?.statusBeforeSuspension}>
          <InlineApiEnumValue
            type='UserStatus'
            value={info?.statusBeforeSuspension}
            withDescription
          />
        </FieldValue>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xxd: "1fr",
            md: "1fr 1fr",
          },
          gap: 2,
        }}
      >
        <Stack spacing={1}>
          <FieldValue label='Suspended at' isEmpty={!info?.suspendedAt}>
            <Datetime datetime={info?.suspendedAt} withDurationFromNow />
          </FieldValue>
          <FieldValue label='Suspended by' isEmpty={!info?.suspendedBy}>
            <InlineUser userId={info?.suspendedBy} withLink />
          </FieldValue>
          <FieldValue label='Reason' isEmpty={!info?.suspendReason}>
            {info?.suspendReason}
          </FieldValue>
        </Stack>

        <Stack spacing={1}>
          <FieldValue label='Resumed at' isEmpty={!info?.unsuspendedAt}>
            <Datetime datetime={info?.unsuspendedAt} withDurationFromNow />
          </FieldValue>
          <FieldValue label='Resumed by' isEmpty={!info?.unsuspendedBy}>
            <InlineUser userId={info?.unsuspendedBy} withLink />
          </FieldValue>
          <FieldValue label='Reason' isEmpty={!info?.unsuspendReason}>
            {info?.unsuspendReason}
          </FieldValue>
        </Stack>
      </Box>
    </Stack>
  );
}
