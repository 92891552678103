import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitiesSearchAutocomplete, {
  InheritableBaseEntitiesSearchAutocompleteProps,
} from "@/common/components/Entity/components/BaseEntitiesSearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AutocompleteOptionType } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralCountryDto,
  GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest,
} from "@/core/api/generated";

import { entityToOption } from "./CountryAutocomplete";

export interface CountriesAutocompleteProps
  extends InheritableBaseEntitiesSearchAutocompleteProps<GeneralCountryDto> {
  searchFilters?: Partial<GeneralReferenceDataApiApiV1ReferenceDataGeneralCurrenciesGetRequest>;
}

export default function CountriesAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: CountriesAutocompleteProps) {
  return (
    <BaseEntitiesSearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={entityToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.generalReferenceDataApi.apiV1ReferenceDataGeneralCountriesGet,
        parameters: {},
        combineParameters: (params, newParams) => ({
          ...params,
          ...newParams,
        }),
        deps: [searchFilters],
      }}
      label='Countries'
      placeholder='Search for countries...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='country' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                </>
              }
              secondary={
                <>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option.data?.alpha2Code}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
