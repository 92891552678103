import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppTooltip from "@/common/components/AppTooltip";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import ContractBlock from "@/common/components/Entity/Contract/ContractBlock";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import FileUploader from "@/common/components/Files/FileUploader";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import DamagePointsByVisualModelViewer from "@/common/components/Images/DamagePointsByVisualModelViewer";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AttachmentsUpdateDto,
  DamageDetectionDto,
  DamageDetectionItemDto,
} from "@/core/api/generated";

import DamageDetectionItemAttachment from "../../DamageDetectionItemAttachment";

interface Props {
  damageDetection: DamageDetectionDto;
  onDamageDetectionUpdated: (damageDetection?: DamageDetectionDto) => any | void;
}

export default function OverviewTabContent({ damageDetection, onDamageDetectionUpdated }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const mounted = useMounted();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [toView, setToView] = useState<DamageDetectionItemDto | null | undefined>(undefined);

  const visualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: damageDetection?.vehicleVisualModel?.id || "",
    },
    {
      deps: [damageDetection.id],
      skip: !damageDetection?.vehicleVisualModel?.id,
    },
  );
  const visualModel = visualModelRequest?.data;

  const formik = useFormik<
    AttachmentsUpdateDto & {
      initialAttachments: DamageDetectionDto["attachments"];
      uploadedAttachments?: FileItem[];
      submit: string;
    }
  >({
    enableReinitialize: true,
    initialValues: {
      initialAttachments: damageDetection?.attachments || undefined,
      attachments:
        damageDetection?.attachments?.map((a) => ({
          caption: a.caption,
          notes: a.notes,
          file: {
            id: a.file?.id,
          },
        })) || undefined,
      submit: "",
    },
    onSubmit: async (values, { setSubmitting, setStatus, setFieldError }) => {
      try {
        const response =
          await apiClient.adminDamageDetectionsApi.apiV1AdminDamageDetectionsDamageDetectionIdAttachmentsPut(
            {
              damageDetectionId: damageDetection.id!,
              tenantId: damageDetection?.tenantId,
              attachmentsUpdateDto: values,
            },
          );
        onDamageDetectionUpdated && onDamageDetectionUpdated(response.data);
        enqueueSnackbar("Damage detection attachments updated", { variant: "success" });

        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    },
  });

  const { values, isSubmitting, setFieldValue, handleSubmit } = formik;
  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={damageDetection} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <Stack direction={{ xxs: "column", md: "row" }} alignItems='flex-start' spacing={2}>
              <VehicleBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                vehicle={damageDetection.vehicle}
                withDetailsToggle
                withLink
              />

              <ContractBlock
                sx={{ maxWidth: { md: "400px" } }}
                variant='reference'
                contract={damageDetection.contract}
                withDetailsToggle
                withLink
              />
            </Stack>
            <Stack
              direction='column'
              sx={{
                p: 2,
                // border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: "8px",
              }}
              spacing={1}
            >
              <Stack direction='row' justifyContent='space-between'>
                <Typography component='div' variant='h6'>
                  Damages
                </Typography>
              </Stack>

              <DamagePointsByVisualModelViewer
                visualModelId={visualModel?.id}
                damages={damageDetection?.items || []}
              />

              {damageDetection.attachments!.length !== 0 && (
                <Box sx={{ mt: 2 }}>
                  <FileListView
                    files={FileItem.createManyFrom(damageDetection.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={45}
                  />
                </Box>
              )}
              {damageDetection.notes && (
                <Box sx={{ mt: 2 }}>
                  <FieldValue label='Notes'>{damageDetection.notes}</FieldValue>
                </Box>
              )}
            </Stack>

            <DataTabular
              columns={[
                {
                  field: "partType.name",
                  title: "Part type",
                  flex: 1,
                  renderCell: (item) => (
                    <Stack>
                      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                        <Box>{item.partType?.name}</Box>
                        <Typography component='span' variant='body2' color='text.secondary'>
                          (<InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                          {(item.projection && (
                            <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                          )) ||
                            "-"}
                          )
                        </Typography>
                      </Stack>
                    </Stack>
                  ),
                },
                {
                  field: "damageType.name",
                  title: "Damage type",
                  flex: 1,
                  renderCell: (item) => item.damageType?.name,
                },
                {
                  field: "damage.id",
                  title: "Damage",
                  flex: 1,
                  renderCell: (item) =>
                    item.damage ? (
                      <VehicleDamageLink
                        vehicleId={item.damage?.vehicleId}
                        entity={item.damage}
                        withIcon
                      />
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "notes",
                  title: "Notes",
                  width: 200,
                  renderCell: (item) =>
                    item.notes ? (
                      <AppTooltip title={item.notes || ""}>
                        <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                      </AppTooltip>
                    ) : (
                      "-"
                    ),
                },
                {
                  field: "attachments",
                  title: "Attachments",
                  width: 150,
                  renderCell: (item) => (
                    <>
                      {item.attachments!.length !== 0 && (
                        <FileListView
                          files={FileItem.createManyFrom(item.attachments)}
                          maxVisibleFiles={2}
                          itemWidth={45}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              rows={damageDetection.items!}
              rowHeight={65}
              getRowId={(item) => item.id!}
              borderVariant='bordered'
              isColumnSelectorEnabled={false}
              isColumnMenuEnabled={false}
              isPaginationEnabled={false}
              renderRowAction={({ item }) => (
                <>
                  <MenuWithTrigger
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {({ handleClose }) => [
                      <MenuItem
                        key={0}
                        onClick={async () => {
                          setToView(item);
                          setIsViewModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='view' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>View attachment</ListItemText>
                      </MenuItem>,
                    ]}
                  </MenuWithTrigger>
                </>
              )}
            />
          </Stack>
        </Stack>

        <ViewContentBlock>
          <PageTabHeader
            title={"Attachments"}
            subtitle2={<EntityCreatedByInfoDisplay entity={damageDetection} />}
          />
          <FormControl fullWidth margin='dense'>
            <FileUploader
              multiple
              defaultFiles={FileItem.createManyFrom(
                values.uploadedAttachments || values.initialAttachments || values.attachments,
              )}
              onChange={(newFiles) => {
                setFieldValue("attachments", FileItem.toManyGeneralAttachmentInputDto(newFiles));
                setFieldValue("uploadedAttachments", newFiles);
              }}
              fileUploadListProps={{
                itemActions: (item) => ({
                  enabled: true,
                  setCaption: true,
                  onSetCaption: (caption) => {
                    setFieldValue(
                      "attachments",
                      values.attachments?.map((a) =>
                        a.file?.id === item.id ? { ...a, caption } : a,
                      ),
                    );
                  },
                }),
              }}
            />
          </FormControl>

          <Stack direction='row' justifyContent='end'>
            <LoadingButton
              variant='contained'
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              sx={{ width: 150 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </ViewContentBlock>

        {toView && (
          <Dialog fullWidth maxWidth='lg' open={isViewModalOpen}>
            <AppModalTitle onCloseClicked={() => setIsViewModalOpen(false)}></AppModalTitle>
            <AppModalContent>
              <DamageDetectionItemAttachment
                damageDetectionId={damageDetection.id}
                tenantId={damageDetection.tenantId}
                damageDetectionItem={toView}
                onDamageDetectionUpdated={onDamageDetectionUpdated}
                onClose={() => setIsViewModalOpen(false)}
              />
            </AppModalContent>
          </Dialog>
        )}
      </ViewLayout>
    </PageTabContent>
  );
}
