import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface InlineCodeProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

/** Inline element for displaying program code */
export default function InlineCode({ sx, children }: InlineCodeProps): JSX.Element {
  return (
    <Box
      component='code'
      sx={{
        backgroundColor: "#f2f3fc",
        color: (theme) => theme.palette.primary.main,
        boxShadow: (theme) => (theme.shadows as string[])[1],
        padding: "2px 6px",
        borderRadius: "2px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
