import { useParams } from "react-router";

import AssetView from "@/common/components/Entity/Asset/View/AssetView";

export default function AssetViewPage() {
  const { assetId } = useParams<{ assetId?: string }>();

  return (
    <>
      <AssetView
        assetId={assetId}
        asset={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
