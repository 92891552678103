import { LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import UserView from "@/common/components/Entity/User/View/UserView";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export default function UserViewPage() {
  const { userId } = useParams<{ userId?: string }>();

  const userRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersUserIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userId: userId!,
    },
    {
      skip: !userId,
    },
  );
  const user = userRequest?.data;

  if (userRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!user) {
    return <EntityNotFoundAlert title='User not found.' />;
  }

  return (
    <>
      <UserView
        userId={userId}
        user={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
