import { Dialog, DialogContent, DialogProps } from "@mui/material";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ContractVehicleCheckIn, { ContractVehicleCheckInProps } from "./ContractVehicleCheckIn";

export interface OwnProps {
  checkInProps: ContractVehicleCheckInProps;
}

type Props = OwnProps & DialogProps;

export default function ContractVehicleCheckInModal({ checkInProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Check-in vehicle
      </AppModalTitle>
      <DialogContent>
        <ContractVehicleCheckIn {...checkInProps} />
      </DialogContent>
    </Dialog>
  );
}
