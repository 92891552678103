import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import Datetime from "@/common/components/Datetime/Datetime";
import FileHashInlineDisplay from "@/common/components/Entity/File/FileHashInlineDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AttachmentListView from "@/common/components/Files/AttachmentListView";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import TimeSpanDisplay from "@/common/components/Form/Display/TimeSpanDisplay";
import AppLink from "@/common/components/Link/AppLink";
import { TypeHelper } from "@/common/helpers/type";
import { AdminFileDto } from "@/core/api/generated";

import TenantLink from "../../../Tenant/TenantLink";

interface Props {
  file: AdminFileDto;
}

export default function OverviewTabContent({ file }: Props) {
  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={file} />}
          />
        }
      >
        <ViewContentBlock>
          <FieldValue label='ID' direction='row' isEmpty={TypeHelper.isEmpty(file.id)}>
            {file.id}
          </FieldValue>

          <FieldValue label='Preview' direction='row' isEmpty={TypeHelper.isEmpty(file.url)}>
            <AttachmentListView
              attachments={[{ file: { ...file } }]}
              fileListViewProps={{
                itemWidth: 140,
                itemHeight: 140,
              }}
            />
          </FieldValue>

          <FieldValue
            label='Parent file ID'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.parentFileId)}
          >
            {file.parentFileId}
          </FieldValue>

          <FieldValue
            label='Copied from file ID'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.copiedFromFileId)}
          >
            {file.copiedFromFileId}
          </FieldValue>

          <FieldValue label='Company' direction='row' isEmpty={TypeHelper.isEmpty(file.tenantId)}>
            <TenantLink entityId={file.tenantId} entity={undefined} />#{file.tenantId}
          </FieldValue>

          <FieldValue
            label='Storage account identifier'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.storageAccountIdentifier)}
          >
            {file.storageAccountIdentifier}
          </FieldValue>

          <FieldValue
            label='Container name'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.containerName)}
          >
            {file.containerName}
          </FieldValue>

          <FieldValue label='Blob name' direction='row' isEmpty={TypeHelper.isEmpty(file.blobName)}>
            {file.blobName}
          </FieldValue>

          <FieldValue label='Path' direction='row' isEmpty={TypeHelper.isEmpty(file.path)}>
            {file.path}
          </FieldValue>

          <FieldValue
            label='Original file name'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.originalFileName)}
          >
            {file.originalFileName}
          </FieldValue>

          <FieldValue label='File name' direction='row' isEmpty={TypeHelper.isEmpty(file.fileName)}>
            {file.fileName}
          </FieldValue>

          <FieldValue
            label='Content type'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.contentType)}
          >
            {file.contentType}
          </FieldValue>

          <FieldValue
            label='MIME base type'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.mimeBaseType)}
          >
            <InlineApiEnumValue type='MimeBaseType' value={file.mimeBaseType} />
          </FieldValue>

          <FieldValue
            label='Extension'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.extension)}
          >
            {file.extension}
          </FieldValue>

          <FieldValue
            label='Original size'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.originalSize)}
          >
            {file.originalSize?.bytes} bytes / {file.originalSize?.asKiB} KiB /{" "}
            {file.originalSize?.asMiB} MiB
          </FieldValue>

          <FieldValue label='Size' direction='row' isEmpty={TypeHelper.isEmpty(file.size)}>
            {file.size?.bytes} bytes / {file.size?.asKiB} KiB / {file.size?.asMiB} MiB
          </FieldValue>

          <FieldValue
            label='Compression ratio'
            direction='row'
            isEmpty={TypeHelper.isNil(file.compressionRatio)}
          >
            {file.compressionRatio}
          </FieldValue>

          <FieldValue
            label='Compression rate'
            direction='row'
            isEmpty={TypeHelper.isNil(file.compressionRate)}
          >
            <PercentValue value={file.compressionRate} />
          </FieldValue>

          <FieldValue
            label='Access level'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.accessLevel)}
          >
            <InlineApiEnumValue type='FileAccessLevel' value={file.accessLevel} />
          </FieldValue>

          <FieldValue label='Is thumbnail' direction='row'>
            <BooleanValue value={file.isThumbnail} />
          </FieldValue>

          <FieldValue
            label='Original hash'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.originalHash)}
          >
            <FileHashInlineDisplay value={file.originalHash} />
          </FieldValue>

          <FieldValue label='Hash' direction='row' isEmpty={TypeHelper.isEmpty(file.hash)}>
            <FileHashInlineDisplay value={file.hash} />
          </FieldValue>

          <FieldValue
            label='Usage version'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.usageVersion)}
          >
            <InlineApiEnumValue type='FileUsageVersion' value={file.usageVersion} />
          </FieldValue>

          <FieldValue label='Is used' direction='row' isEmpty={TypeHelper.isEmpty(file.isUsed)}>
            <BooleanValue value={file.isUsed} />
          </FieldValue>

          <FieldValue
            label='Obsolete at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.obsoleteAt)}
          >
            <Datetime datetime={file.obsoleteAt} withDurationFromNow />
          </FieldValue>

          <FieldValue label='URL' direction='row' isEmpty={TypeHelper.isEmpty(file.url)}>
            <AppLink to={undefined} href={file.url || undefined} target='_blank'>
              {file.url}
            </AppLink>
          </FieldValue>

          <FieldValue
            label='URL lifetime'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.urlLifetime)}
          >
            <TimeSpanDisplay value={file.urlLifetime} />
          </FieldValue>

          <FieldValue
            label='URL expires at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.urlExpiresAt)}
          >
            <Datetime datetime={file.urlExpiresAt} withDurationFromNow />
          </FieldValue>

          <FieldValue label='Is deleted' direction='row'>
            <BooleanValue value={file.isDeleted} />
          </FieldValue>

          <FieldValue label='Is blob deleted' direction='row'>
            <BooleanValue value={file.isBlobDeleted} />
          </FieldValue>

          <FieldValue label='Is blob soft-deleted' direction='row'>
            <BooleanValue value={file.isBlobSoftDeleted} />
          </FieldValue>

          <FieldValue label='Is blob hard-deleted' direction='row'>
            <BooleanValue value={file.isBlobHardDeleted} />
          </FieldValue>

          <FieldValue
            label='Deleted at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.deletedAt)}
          >
            <Datetime datetime={file.deletedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Restored at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.restoredAt)}
          >
            <Datetime datetime={file.restoredAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Blob deleted at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.blobDeletedAt)}
          >
            <Datetime datetime={file.blobDeletedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Blob soft-deleted at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.blobSoftDeletedAt)}
          >
            <Datetime datetime={file.blobSoftDeletedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Blob hard-deleted at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.blobHardDeletedAt)}
          >
            <Datetime datetime={file.blobHardDeletedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Est. blob soft-delete at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.estBlobSoftDeleteAt)}
          >
            <Datetime datetime={file.estBlobSoftDeleteAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Est. blob hard-delete at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.estBlobHardDeleteAt)}
          >
            <Datetime datetime={file.estBlobHardDeleteAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Uploaded at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.uploadedAt)}
          >
            <Datetime datetime={file.uploadedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Created at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.createdAt)}
          >
            <Datetime datetime={file.createdAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Updated at'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.updatedAt)}
          >
            <Datetime datetime={file.updatedAt} withDurationFromNow />
          </FieldValue>

          <FieldValue
            label='Uploaded by'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.uploadedBy)}
          >
            <InlineUser userId={file.uploadedBy} withAvatar />
          </FieldValue>

          <FieldValue
            label='Created by'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.createdBy)}
          >
            <InlineUser userId={file.createdBy} withAvatar />
          </FieldValue>

          <FieldValue
            label='Updated by'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.updatedBy)}
          >
            <InlineUser userId={file.updatedBy} withAvatar />
          </FieldValue>

          <FieldValue
            label='Deleted by'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.deletedBy)}
          >
            <InlineUser userId={file.deletedBy} withAvatar />
          </FieldValue>

          <FieldValue
            label='Restored by'
            direction='row'
            isEmpty={TypeHelper.isEmpty(file.restoredBy)}
          >
            <InlineUser userId={file.restoredBy} withAvatar />
          </FieldValue>
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
