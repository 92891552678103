import { Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import VehicleGenerationAutocomplete from "@/common/components/Entity/ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleMakeAutocomplete from "@/common/components/Entity/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModel/VehicleModelAutocomplete";
import VehicleModificationAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModification/VehicleModificationAutocomplete";
import RepairSparePartMenu from "@/common/components/Entity/RepairSparePart/RepairSparePartMenu";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import FilterEnumValueInput from "@/common/components/Filters/ValueInputs/FilterEnumValueInput";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { FilterFieldOperatorSpec } from "@/common/filters/filterFieldOperatorSpec";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  FilterOperator,
  FilterType,
  FilterValueType,
  RepairSparePartDto,
  RepairSparePartGetPaginatedDto,
  VehicleBodyType,
  VehicleSize,
} from "@/core/api/generated";

import InlineUser from "../../components/User/InlineUser";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  create: true,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

function RepairSparePartsPage({ displayProps = defaultDisplayProps }: Props) {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<RepairSparePartGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.RepairSparePart,
    },
    initParams: (filterDefinition) => {
      if (!filterDefinition) {
        return {};
      }

      const vehicleSize = filterDefinition.filterDefinition?.items
        ?.find(
          (x) => x.field === getTypedPath<RepairSparePartDto>().detalizations[0].vehicleSize.$path,
        )
        ?.valueAsEnumTyped("VehicleSize");
      const bodyType = filterDefinition?.filterDefinition?.items
        ?.find(
          (x) => x.field === getTypedPath<RepairSparePartDto>().detalizations[0].bodyType.$path,
        )
        ?.valueAsEnumTyped("VehicleBodyType");
      const makeId = filterDefinition?.filterDefinition?.items
        ?.find((x) => x.field === getTypedPath<RepairSparePartDto>().detalizations[0].make.id.$path)
        ?.valueAsString();
      const modelId = filterDefinition?.filterDefinition?.items
        ?.find(
          (x) => x.field === getTypedPath<RepairSparePartDto>().detalizations[0].model.id.$path,
        )
        ?.valueAsString();
      const generationId = filterDefinition?.filterDefinition?.items
        ?.find(
          (x) =>
            x.field === getTypedPath<RepairSparePartDto>().detalizations[0].generation.id.$path,
        )
        ?.valueAsString();
      const modificationId = filterDefinition?.filterDefinition?.items
        ?.find(
          (x) =>
            x.field === getTypedPath<RepairSparePartDto>().detalizations[0].modification.id.$path,
        )
        ?.valueAsString();

      const result: RepairSparePartGetPaginatedDto = {
        vehicleSize: vehicleSize,
        bodyType: bodyType,
        makeId: makeId,
        modelId: modelId,
        generationId: generationId,
        modificationId: modificationId,
      };
      return result;
    },
  });

  const paginatedRepairSparePartsRequest = useApiRequest(
    apiClient.repairSparePartApi.apiV1RepairSparePartsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairSparePartGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        vehicleSize: commonRequestParams.params?.vehicleSize,
        bodyType: commonRequestParams.params?.bodyType,
        makeId: commonRequestParams.params?.makeId,
        modelId: commonRequestParams.params?.modelId,
        generationId: commonRequestParams.params?.generationId,
        modificationId: commonRequestParams.params?.modificationId,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedRepairSpareParts = paginatedRepairSparePartsRequest.data;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.RepairSparePart)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedRepairSparePartsRequest.handleEntityChanged(data);
    },
  });

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <SimpleViewPageHeader
          title='Spare parts'
          primaryActions={
            displayProps?.create && (
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<AppIcon of='add' />}
                  component={RouterLink}
                  to={ROUTE_PATH.REPAIR_SPARE_PART_CREATE}
                >
                  Create new
                </Button>
              </AuthorizedElement>
            )
          }
        />
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<RepairSparePartDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<RepairSparePartDto>().name.$path,
            title: "Name",
            width: 100,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.name || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().partNumber.$path,
            title: "Part number",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.partNumber || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().brandName.$path,
            title: "Brand name",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.brandName || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().unit.$path,
            title: "Unit",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <InlineApiEnumValue type='MeasurementUnit' value={item.unit} />,
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.MeasurementUnit,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].vehicleSize.$path,
            title: "Vehicle sizes",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => {
              const vehicleSizes =
                item.detalizations
                  ?.flatMap((detail) => detail.vehicleSize)
                  .filter((size): size is VehicleSize => size !== undefined && size !== null) || [];

              return vehicleSizes.length > 0 ? (
                <InlineApiEnumValueList direction='row' type='VehicleSize' values={vehicleSizes} />
              ) : (
                "-"
              );
            },
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: false,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Enum,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({
                      vehicleSize: newValue.valueAsEnumTyped("VehicleSize"),
                    });
                  },
                  valueInput: (props) => <FilterEnumValueInput {...props} />,
                }),
              ],
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.VehicleSize,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].bodyType.$path,
            title: "Body types",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => {
              const bodyTypes =
                item.detalizations
                  ?.flatMap((detail) => detail.bodyType)
                  .filter(
                    (bodyType): bodyType is VehicleBodyType =>
                      bodyType !== undefined && bodyType !== null,
                  ) || [];

              return bodyTypes.length > 0 ? (
                <InlineApiEnumValueList direction='row' type='VehicleBodyType' values={bodyTypes} />
              ) : (
                "-"
              );
            },
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: false,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Enum,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({
                      bodyType: newValue.valueAsEnumTyped("VehicleBodyType"),
                    });
                  },
                  valueInput: (props) => <FilterEnumValueInput {...props} />,
                }),
              ],
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.VehicleBodyType,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].make.id.$path,
            title: "Makes",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.detalizations
                ?.map((d) => d.make?.name)
                .filter((name) => name)
                .join(", ") || "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Id,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({ makeId: newValue.valueAsString() });
                  },
                  valueInput: (props) => (
                    <VehicleMakeAutocomplete
                      {...props.baseEntitySearchAutocompleteProps}
                      entityId={props.item.valueAsId()}
                      onChange={(newValue) => {
                        props.onChange(newValue?.id);
                      }}
                    />
                  ),
                }),
              ],
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].model.id.$path,
            title: "Models",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.detalizations
                ?.map((d) => d.model?.name)
                .filter((name) => name)
                .join(", ") || "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Id,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({ modelId: newValue.valueAsString() });
                  },
                  valueInput: (props) => (
                    <VehicleModelAutocomplete
                      {...props.baseEntitySearchAutocompleteProps}
                      entityId={props.item.valueAsId()}
                      onChange={(newValue) => {
                        props.onChange(newValue?.id);
                      }}
                    />
                  ),
                }),
              ],
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].generation.id.$path,
            title: "Generations",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.detalizations
                ?.map((d) => d.generation?.name)
                .filter((name) => name)
                .join(", ") || "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Id,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({
                      generationId: newValue.valueAsString(),
                    });
                  },
                  valueInput: (props) => (
                    <VehicleGenerationAutocomplete
                      {...props.baseEntitySearchAutocompleteProps}
                      entityId={props.item.valueAsId()}
                      onChange={(newValue) => {
                        props.onChange(newValue?.id);
                      }}
                    />
                  ),
                }),
              ],
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().detalizations[0].modification.id.$path,
            title: "Modifications",
            width: 160,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.detalizations
                ?.map((d) => d.modification?.name)
                .filter((name) => name)
                .join(", ") || "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: [
                new FilterFieldOperatorSpec({
                  operator: FilterOperator.AnyOf,
                  filterType: FilterType.Static,
                  valueType: FilterValueType.Id,
                  onChange: (newValue) => {
                    commonRequestParams.setOneParam2({
                      modificationId: newValue.valueAsString(),
                    });
                  },
                  valueInput: (props) => (
                    <VehicleModificationAutocomplete
                      {...props.baseEntitySearchAutocompleteProps}
                      entityId={props.item.valueAsId()}
                      onChange={(newValue) => {
                        props.onChange(newValue?.id);
                      }}
                    />
                  ),
                }),
              ],
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().currency.code.$path,
            title: "Currency",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.currency ? <GeneralCurrencyDisplay currency={item.currency} /> : "-",
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.CurrencyCode,
                },
              },
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().price.$path,
            title: "Price",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <CurrencyValue value={item.price} currency={item.currency} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().subTotal.$path,
            title: "Sub total",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <CurrencyValue value={item.subTotal} currency={item.currency} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().discount.$path,
            title: "Discount",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <GeneralDiscountDisplay
                discount={item.discount}
                currency={item.currency}
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: getTypedPath<RepairSparePartDto>().subTotalIncDiscount.$path,
            title: "Sub total (inc. discount)",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().tax.$path,
            title: "Tax",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
          },
          {
            field: getTypedPath<RepairSparePartDto>().total.$path,
            title: "Total",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <AppPopover
                hoverBehavior={{}}
                trigger={
                  <AppTypography decoration={{ variant: "helpText" }}>
                    <CurrencyValue value={item.total} currency={item.currency} />
                  </AppTypography>
                }
              >
                <AppPopoverContent>
                  <GeneralPriceSummaryDisplay
                    direction='column'
                    summary={{
                      currency: item.currency,
                      subTotal: item.subTotal,
                      discount: item.discount || undefined,
                      tax: item.tax || undefined,
                      total: item.total || 0,
                    }}
                  />
                </AppPopoverContent>
              </AppPopover>
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<RepairSparePartDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
        ]}
        rows={paginatedRepairSpareParts?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.REPAIR_SPARE_PART_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <RepairSparePartMenu
            entity={item}
            onUpdate={() => paginatedRepairSparePartsRequest.refetch()}
            onDelete={() => paginatedRepairSparePartsRequest.refetch()}
          />
        )}
        isLoading={paginatedRepairSparePartsRequest.isLoading}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
      />
    </ViewLayoutV2>
  );
}

export default RepairSparePartsPage;
