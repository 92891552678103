import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { ArrayHelper } from "@/common/helpers/array";
import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  PoolItemCreateDto,
  PoolItemCreateManyDto,
  PoolItemDto,
  PoolItemSettingsInputDto,
  PoolItemStatus,
  PoolItemType,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppIcon from "../../Icons/AppIcon";
import GeneralEntitySubTypeInput from "../General/Input/GeneralEntitySubTypeInput";
import { PoolAutocompleteProps } from "../Pool/PoolAutocomplete";
import PoolAutocompleteOrCreate from "../Pool/PoolAutocompleteOrCreate";
import PoolLink from "../Pool/PoolLink";
import PoolItemCandidateEntityAutocompleteOrCreate from "./PoolItemCandidateEntityAutocompleteOrCreate";

export interface PoolItemCreateManyOwnProps {
  defaultValues: {
    poolId?: string;
    entityType?: PoolItemCreateDto["entityType"];
    entitySubType?: PoolItemCreateDto["entitySubType"];
  };
  searchFilters?: {
    pool?: PoolAutocompleteProps["searchFilters"];
  };
  entities?: PoolItemCreateDto[];
  onCreate?: (newValues: PoolItemDto[]) => void;
  onSave?: (newValues: PoolItemDto[]) => void;
}

export type PoolItemCreateManyProps = PoolItemCreateManyOwnProps;

export default function PoolItemCreateMany({
  defaultValues,
  entities,
  searchFilters,
  onCreate,
  onSave,
}: PoolItemCreateManyProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const poolId = defaultValues.poolId;
  const isCreate = true;
  const isEdit = false;

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
    },
    {
      skip: !poolId,
    },
  );
  const pool = poolRequest.data;

  return (
    <Formik<
      PoolItemCreateManyDto &
        BaseFormikValues & { settings?: PoolItemSettingsInputDto; poolId?: string }
    >
      enableReinitialize
      initialValues={{
        poolId: poolId,
        items: entities
          ? entities
          : [
              {
                type: pool?.itemType || undefined,
                entityType: pool?.itemEntityType || defaultValues?.entityType || undefined,
                entitySubType: defaultValues?.entitySubType || undefined,
                status: PoolItemStatus.Available,
                statusReason: undefined,
                entity:
                  pool && pool.itemType === PoolItemType.Specific && pool?.itemEntityType
                    ? {
                        entityType: pool?.itemEntityType || undefined,
                        entityId: undefined,
                      }
                    : undefined,
              },
            ],
        settings: undefined,

        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // vehicleId: Yup.string().required("Vehicle is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const response = await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsCreateManyPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            poolId: values.poolId || "",
            poolItemCreateManyDto: {
              ...values,
              items: values?.items?.map((x) => ({ ...x, settings: values.settings })),
            },
          });
          enqueueSnackbar(`Pool ${TextHelper.pluralize("item", response.data.length)} created.`, {
            variant: "success",
          });
          onCreate && onCreate(response.data);
          onSave && onSave(response.data);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              {poolRequest.isLoading && <LinearProgress />}

              <FormControl margin='dense' fullWidth>
                <PoolAutocompleteOrCreate
                  autocompleteProps={{
                    entityId: values.poolId,
                    isPreload: isCreate,
                    disabled: !!defaultValues?.poolId,
                    searchFilters: {
                      ...searchFilters?.pool,
                    },
                    onChange: (newValue) => setFieldValue("poolId", newValue?.id),
                  }}
                  createFormPlacement='modal'
                  onCreate={(newValue) => setFieldValue("poolId", newValue?.id)}
                />
              </FormControl>

              {poolId && (
                <>
                  {/** Items */}
                  <Stack spacing={2}>
                    <Typography component='div' variant='subtitle1'>
                      Items
                    </Typography>

                    {values.items && values.items.length !== 0 && (
                      <Stack spacing={1}>
                        {values.items?.map((item, i) => (
                          <Box key={i}>
                            <Stack
                              direction={{
                                xxs: "column",
                                md: "row",
                              }}
                              spacing={{
                                xxs: 0,
                                md: 1,
                              }}
                            >
                              {/* PoolItemType.Specific - select item entity */}
                              {item.type === PoolItemType.Specific && (
                                <Box sx={{ flex: 1 }}>
                                  {pool && (
                                    <FormControl margin='dense' fullWidth>
                                      <PoolItemCandidateEntityAutocompleteOrCreate
                                        pool={pool}
                                        value={item.entity}
                                        onlyEntityTypes={[
                                          ...((pool?.itemEntityType && [pool.itemEntityType]) ||
                                            []),
                                          ...(pool?.itemEntityTypes || []),
                                        ]}
                                        entityTypeInputProps={{
                                          disabled: !_.isNil(pool?.itemEntityType),
                                        }}
                                        entityAutocompleteOrCreateProps={{
                                          autocompleteProps: {
                                            required: true,
                                            disabled: false,
                                            size: "small",
                                            textFieldProps: {
                                              error: Boolean(getIn(errors, `items[${i}].entity`)),
                                              helperText: ValidationHelper.getFormikErrorsAsString(
                                                getIn(errors, `items[${i}].entity`),
                                              ),
                                            },
                                            searchFilters: {
                                              excludeIds:
                                                values.items
                                                  ?.filter(
                                                    (x) =>
                                                      x.entity?.entityId &&
                                                      x.entity.entityId !== item.entity?.entityId,
                                                  )
                                                  ?.map((x) => x.entity!.entityId!) || undefined,
                                            },
                                          },
                                        }}
                                        onChange={(newValue) => {
                                          setFieldValue(`items[${i}].entity`, newValue);
                                          setFieldValue(
                                            `items[${i}].entityType`,
                                            newValue?.entityType,
                                          );
                                        }}
                                      />

                                      {pool?.itemEntityType && (
                                        <FormHelperText>
                                          This pool supports only items of type{" "}
                                          <InlineApiEnumValue
                                            type='PoolItemEntityType'
                                            value={pool.itemEntityType}
                                          />
                                          .
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  )}
                                </Box>
                              )}

                              {/* PoolItemType.Abstract - select item entity type */}
                              {item.type === PoolItemType.Abstract && (
                                <>
                                  {/* PoolItemEntityType */}
                                  <Box sx={{ flex: 1 }}>
                                    <FormControl margin='dense' fullWidth>
                                      <ApiEnumAutocomplete
                                        type='PoolItemEntityType'
                                        value={item.entityType}
                                        onlyEnumValues={[
                                          ...((pool?.itemEntityType && [pool.itemEntityType]) ||
                                            []),
                                          ...(pool?.itemEntityTypes || []),
                                        ]}
                                        onChange={(newValue) => {
                                          setFieldValue(`items[${i}].entityType`, newValue);
                                          setFieldValue(`items[${i}].entitySubType`, undefined);
                                        }}
                                        label='Entity type'
                                        size='small'
                                        required
                                        disabled={isEdit || !_.isNil(pool?.itemEntityType)}
                                        textFieldProps={{
                                          error: Boolean(getIn(errors, `items[${i}].entityType`)),
                                          helperText: ValidationHelper.getFormikErrorsAsString(
                                            getIn(errors, `items[${i}].entityType`),
                                          ),
                                        }}
                                      />
                                    </FormControl>
                                  </Box>

                                  {/* EntitySubType */}
                                  {item.entityType && (
                                    <Box sx={{ flex: 1 }}>
                                      <FormControl margin='dense' fullWidth>
                                        <GeneralEntitySubTypeInput
                                          entityType={item.entityType || EntityType.None}
                                          value={item.entitySubType}
                                          onChange={(newValue) =>
                                            setFieldValue(`items[${i}].entitySubType`, newValue)
                                          }
                                          autocompleteProps={{
                                            size: "small",
                                            required: true,
                                            disabled: isEdit || _.isNil(item.entityType),
                                            textFieldProps: {
                                              error: Boolean(
                                                getIn(errors, `items[${i}].entitySubType`),
                                              ),
                                              helperText: ValidationHelper.getFormikErrorsAsString(
                                                getIn(errors, `items[${i}].entitySubType`),
                                              ),
                                            },
                                          }}
                                        />
                                      </FormControl>
                                    </Box>
                                  )}
                                </>
                              )}

                              <Box sx={{ flex: 1 }}>
                                <FormControl
                                  margin='dense'
                                  fullWidth
                                  required
                                  error={Boolean(getIn(errors, `items[${i}].status`))}
                                >
                                  <ApiEnumAutocomplete
                                    type='PoolItemStatus'
                                    value={values.items?.at(i)?.status}
                                    onChange={(newValue) =>
                                      setFieldValue(`items[${i}].status`, newValue)
                                    }
                                    label='Status'
                                    size='small'
                                    required
                                    textFieldProps={{
                                      error: Boolean(getIn(errors, `items[${i}].status`)),
                                      helperText:
                                        getIn(errors, `items[${i}].status`) &&
                                        ValidationHelper.getErrorsAsString(
                                          getIn(errors, `items[${i}].status`),
                                        ),
                                    }}
                                  />
                                </FormControl>
                              </Box>

                              <Box sx={{ flex: 1 }}>
                                <TextField
                                  error={Boolean(getIn(errors, `items[${i}].statusReason`))}
                                  fullWidth
                                  multiline
                                  helperText={getIn(errors, `items[${i}].statusReason`)}
                                  label='Status reason'
                                  margin='dense'
                                  name='statusReason'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type='text'
                                  value={values.items?.at(i)?.statusReason || ""}
                                  variant='outlined'
                                  size='small'
                                />
                              </Box>

                              <Box>
                                <IconButton
                                  onClick={() => {
                                    setFieldValue(
                                      "items",
                                      ArrayHelper.removeByIndex([...(values.items || [])], i),
                                    );
                                  }}
                                >
                                  <AppIcon of='remove' />
                                </IconButton>
                              </Box>
                            </Stack>

                            <Box>
                              {getIn(errors, `items[${i}.type]`) && (
                                <FormHelperText error={Boolean(getIn(errors, `items[${i}.type]`))}>
                                  {ValidationHelper.getErrorsAsString(
                                    getIn(errors, `items[${i}.type]`),
                                  )}
                                </FormHelperText>
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Stack>
                    )}
                    {getIn(errors, `items`) && (
                      <FormHelperText error>
                        {ValidationHelper.getErrorsAsString(getIn(errors, `items`))}
                      </FormHelperText>
                    )}

                    <Box>
                      <Button
                        variant='outlined'
                        color='text'
                        size='small'
                        startIcon={<AppIcon of='add' />}
                        onClick={() => {
                          const entityType =
                            pool?.itemEntityType || _.last(values.items || [])?.entityType;

                          setFieldValue("items", [
                            ...(values.items || []),
                            {
                              type: pool?.itemType,
                              entityType: entityType,
                              entitySubType: undefined,
                              status: PoolItemStatus.Available,
                              entity: {
                                entityType: entityType,
                              },
                            } as PoolItemCreateDto,
                          ]);
                        }}
                      >
                        Add item
                      </Button>
                    </Box>
                  </Stack>
                  {/* Settings */}
                  <FormControl margin='dense' fullWidth>
                    <Stack spacing={1}>
                      <FormLabel required={false}>
                        <Typography component='span' variant='subtitle1'>
                          Settings
                        </Typography>
                      </FormLabel>
                      <FormHelperText>Applied to all new items.</FormHelperText>

                      <FormControl margin='dense'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.settings?.isEnsureEntityBelongToSinglePool || false}
                              onBlur={handleBlur}
                              onChange={(e) =>
                                setFieldValue(
                                  "settings.isEnsureEntityBelongToSinglePool",
                                  e.target.checked,
                                )
                              }
                            />
                          }
                          label='Ensure entity belongs to a single pool'
                        />
                        <FormHelperText>
                          <Box>
                            When checked, ensures that single entity, for instance, Vehicle or
                            Asset, can be added max to one pool.
                          </Box>
                          <Box>
                            NB: This setting overrides the same setting set in the pool{" "}
                            <PoolLink entity={pool}></PoolLink>.
                          </Box>
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </FormControl>
                </>
              )}
              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  disabled={!values.poolId}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}
