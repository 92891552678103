import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import RepairOperationCreateUpdate, {
  RepairOperationCreateUpdateProps,
} from "./RepairOperationCreateUpdate";

export interface OwnProps {
  createUpdateProps: RepairOperationCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function RepairOperationCreateUpdateModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.repairOperationId;

  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} repair operation
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <RepairOperationCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
