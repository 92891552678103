import { MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { useCallback, useMemo } from "react";

import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";

import InlineApiEnumValue, { InlineApiEnumValueProps } from "./InlineApiEnumValue";

interface ApiEnumSelectProps<TEnumName extends ApiEnumName> {
  type: TEnumName;
  defaultValue?: ApiEnumValue<TEnumName> | null;
  value?: ApiEnumValue<TEnumName> | null;
  allowNone?: boolean;
  sx?: SelectProps["sx"];
  selectProps?: Omit<SelectProps, "defaultValue" | "value" | "onChange">;
  optionProps?: Partial<Pick<InlineApiEnumValueProps<any>, "direction" | "withDescription">>;
  onChange?: (newValue?: ApiEnumValue<TEnumName> | null) => void;
  filterOptions?: (option: { value: ApiEnumValue<TEnumName> | null }) => boolean;
}

/** Select for enums generated from Open API spec of WebApi. */
export default function ApiEnumSelect<TEnumName extends ApiEnumName>({
  type,
  defaultValue,
  value,
  allowNone = false,
  sx,
  selectProps,
  optionProps,
  onChange,
  filterOptions,
}: ApiEnumSelectProps<TEnumName>) {
  const keyValuePairs = enumService.getEnumObjKeyValuePairs(type);

  const options = useMemo(
    () =>
      keyValuePairs
        .filter((kvp) => (allowNone ? true : kvp.value !== "None"))
        .map((kvp) => ({ value: kvp.value }))
        .filter((x) => (filterOptions ? filterOptions(x) : true)),
    [keyValuePairs, filterOptions],
  );

  const _onChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      onChange && onChange(e.target.value as ApiEnumValue<TEnumName> | null | undefined);
    },
    [onChange],
  );

  return (
    <Select
      displayEmpty={false}
      // autoWidth
      defaultValue={defaultValue || ""}
      value={value || ""}
      onChange={_onChange}
      sx={sx}
      {...selectProps}
    >
      {!selectProps?.required && (
        <MenuItem value=''>
          <em>None</em>
        </MenuItem>
      )}

      {options.map((option, index) => {
        return (
          <MenuItem key={index} value={option.value}>
            <InlineApiEnumValue
              type={type}
              value={option.value}
              {...optionProps}
              direction={optionProps?.direction ?? "column"}
              withDescription={optionProps?.withDescription ?? true}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
}
