import { Dialog, DialogProps } from "@mui/material";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AppModalContent from "@/common/components/Modals/AppModalContent";

import GeneralStatusHistoryView, {
  GeneralStatusHistoryViewProps,
} from "./GeneralStatusHistoryView";

export interface OwnProps {
  viewProps: GeneralStatusHistoryViewProps;
}

type Props = OwnProps & DialogProps;

export default function GeneralStatusHistoryViewModal({ viewProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='lg' {...dialogProps}>
      <AppModalContent>
        <GeneralStatusHistoryView
          onDeleted={() => {
            dialogProps.onClose && dialogProps.onClose({}, "escapeKeyDown");
          }}
          {...viewProps}
          displayProps={{
            viewVariant: ViewLayoutVariant.Tab, // TODO: must be Modal
            ...viewProps?.displayProps,
          }}
        />
      </AppModalContent>
    </Dialog>
  );
}
