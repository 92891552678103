import { Alert, Box, LinearProgress } from "@mui/material";
import { ReactNode, useMemo } from "react";

import { EntityHelper } from "@/common/helpers/entity";
import { renderIf } from "@/common/helpers/render/renderIf";
import { UseApiRequestResult } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { DataGrantPermission, EntityType, IBaseEntityDto } from "@/core/api/generated";

import EntityNotFoundAlert from "../../AppAlerts/EntityNotFoundAlert";

export interface BaseEntityCreateUpdateOwnProps<TEntity extends IBaseEntityDto> {
  entityType: EntityType;
  entityId: string | null | undefined;
  entity: TEntity | null | undefined;
  /** Entity get request. */
  entityRequest: UseApiRequestResult<any, any, any, any, any> | null | undefined; // use any as we don't care about generic arguments
  /** Whether initialization is done and form shouldn't be rendered yet. */
  isIniting?: boolean;
  /** Create/update form. */
  children: ReactNode;
}

export type BaseEntityCreateUpdateProps<TEntity extends IBaseEntityDto> =
  BaseEntityCreateUpdateOwnProps<TEntity>;

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntityCreateUpdateInheritableProps<
  TEntity extends IBaseEntityDto,
  TDefaultValues,
> {
  entityId?: string | null;
  defaultValues?: TDefaultValues | null;
  onCreate?: (newValue: TEntity) => void;
  onUpdate?: (newValue: TEntity) => void;
  onSave?: (newValue: TEntity) => void;
}

export default function BaseEntityCreateUpdate<TEntity extends IBaseEntityDto = IBaseEntityDto>({
  entityType,
  entityId,
  entity,
  entityRequest,
  isIniting,
  children,
}: BaseEntityCreateUpdateProps<TEntity>) {
  const currentTenant = useCurrentTenant();

  const isCreate = !entityId;
  const isEdit = !!entityId;

  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);
  const canEdit = useMemo(
    () =>
      !entityMeta?.grantsMeta ||
      entityMeta.grantsMeta.isIssuedByMe ||
      (entityMeta.grantsMeta.isConsumedByMe &&
        !!currentTenant?.id &&
        !!entityMeta.grantsMeta.tenantPermissionsMap &&
        !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id] &&
        !!entityMeta.grantsMeta.tenantPermissionsMap[currentTenant.id][DataGrantPermission.Write]),
    [entityMeta],
  );

  const isLoading = useMemo(
    () => (entityRequest && entityRequest.isLoading && !entity) || isIniting,
    [entityRequest?.isLoading, isIniting],
  );

  // ensure crucial data is loaded and init is done before rendering the form
  if (isLoading) {
    return <LinearProgress />;
  }
  if (isEdit && entityRequest && entityRequest.isEnded && !entity) {
    return <EntityNotFoundAlert />;
  }

  return (
    <Box>
      {renderIf()
        .if(isCreate)
        .then(children)
        .elseif(isEdit)
        .then(
          <Box>
            {!canEdit && <Alert severity='error'>You are not allowed to edit this entity.</Alert>}
            {canEdit && children}
          </Box>,
        )
        .render()}
    </Box>
  );
}
