import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import AccessoryCreateUpdate from "@/common/components/Entity/Accessory/AccessoryCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function AccessoryCreateUpdatePage() {
  const { accessoryId } = useParams<{ accessoryId?: string }>();
  const history = useHistory();
  const isCreate = !accessoryId;

  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={isCreate ? "Create new accessory" : "Edit accessory"} />}
    >
      <AccessoryCreateUpdate
        entityId={accessoryId}
        onSave={(newValue) => {
          history.replace(ROUTE_PATH.ACCESSORY_VIEW(newValue.id));
        }}
      />
    </CreateUpdatePageLayout>
  );
}
