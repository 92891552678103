import { combineReducers } from "@reduxjs/toolkit";

import { chatActivityOverviewReducer } from "./chatActivityOverviewSlice";
import { chatActivityReducer } from "./chatActivitySlice";
import { chatEventsReducer } from "./chatEventsSlice";
import { chatHistoryReducer } from "./chatHistorySlice";
import { chatMessagesReducer } from "./chatMessagesSlice";
import { chatParticipantsReducer } from "./chatParticipantsSlice";
import { chatUserSettingsReducer } from "./chatUserSettingsSlice";
import { chatsReducer } from "./chatsSlice";
import { negotiationsReducer } from "./negotiationsSlice";

export const communicationReducer = combineReducers({
  chats: chatsReducer,
  negotiations: negotiationsReducer,
  chatParticipants: chatParticipantsReducer,
  chatHistory: chatHistoryReducer,
  chatMessages: chatMessagesReducer,
  chatEvents: chatEventsReducer,
  chatActivityOverviews: chatActivityOverviewReducer,
  chatActivities: chatActivityReducer,
  chatUserSettings: chatUserSettingsReducer,
});
