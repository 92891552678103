import { Stack, SxProps, Theme } from "@mui/material";

import TextLineSkeleton from "./TextLineSkeleton";

export interface TextLineListSkeletonProps {
  itemCount?: number;
  sx?: SxProps<Theme>;
}

export default function TextLineListSkeleton({ itemCount = 3, sx }: TextLineListSkeletonProps) {
  return (
    <Stack spacing={1} sx={{ width: "100%", ...sx }}>
      {Array.from({ length: itemCount }).map((x, i) => (
        <TextLineSkeleton key={i} />
      ))}
    </Stack>
  );
}
