import DamageTypesAutocomplete from "@/App/AdminAppView/components/ReferenceData/DamageTypesSelector";
import UserAutocomplete from "@/App/MainAppView/components/User/UserAutocomplete";
import UsersAutocomplete from "@/App/MainAppView/components/User/UsersAutocomplete";
import {
  FilterOperator,
  FilterType,
  FilterValueType,
  GeneralAttachedTagsInputDto,
} from "@/core/api/generated";

import AssetAutocomplete from "../components/Entity/Asset/AssetAutocomplete";
import AssetsAutocomplete from "../components/Entity/Asset/AssetsAutocomplete";
import AssetSubscriptionAutocomplete from "../components/Entity/AssetSubscription/AssetSubscriptionAutocomplete";
import AssetSubscriptionsAutocomplete from "../components/Entity/AssetSubscription/AssetSubscriptionsAutocomplete";
import AssetSubscriptionPlanAutocomplete from "../components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlanAutocomplete";
import AssetSubscriptionPlansAutocomplete from "../components/Entity/AssetSubscriptionPlan/AssetSubscriptionPlansAutocomplete";
import ContractAutocomplete from "../components/Entity/Contract/ContractAutocomplete";
import ContractsAutocomplete from "../components/Entity/Contract/ContractsAutocomplete";
import CustomerAutocomplete from "../components/Entity/Customer/CustomerAutocomplete";
import CustomersAutocomplete from "../components/Entity/Customer/CustomersAutocomplete";
import GeneralAttachedTagsInput from "../components/Entity/General/GeneralTag/GeneralAttachedTagsInput";
import ProductLocationAutocomplete from "../components/Entity/ProductLocation/ProductLocationAutocomplete";
import ProductLocationsAutocomplete from "../components/Entity/ProductLocation/ProductLocationsAutocomplete";
import DamageTypeAutocomplete from "../components/Entity/ReferenceData/DamageType/DamageTypeAutocomplete";
import VehicleGenerationAutocomplete from "../components/Entity/ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleGenerationsAutocomplete from "../components/Entity/ReferenceData/VehicleGeneration/VehicleGenerationsAutocomplete";
import VehicleMakeAutocomplete from "../components/Entity/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleMakesAutocomplete from "../components/Entity/ReferenceData/VehicleMake/VehicleMakesAutocomplete";
import VehicleModelAutocomplete from "../components/Entity/ReferenceData/VehicleModel/VehicleModelAutocomplete";
import VehicleModelsAutocomplete from "../components/Entity/ReferenceData/VehicleModel/VehicleModelsAutocomplete";
import VehicleModificationAutocomplete from "../components/Entity/ReferenceData/VehicleModification/VehicleModificationAutocomplete";
import VehicleModificationsAutocomplete from "../components/Entity/ReferenceData/VehicleModification/VehicleModificationsAutocomplete";
import VehiclePartTypeAutocomplete from "../components/Entity/ReferenceData/VehiclePartType/VehiclePartTypeAutocomplete";
import VehiclePartTypesAutocomplete from "../components/Entity/ReferenceData/VehiclePartType/VehiclePartTypesAutocomplete";
import RepairWorkAutocomplete from "../components/Entity/RepairWork/RepairWorkAutocomplete";
import RepairWorksAutocomplete from "../components/Entity/RepairWork/RepairWorksAutocomplete";
import SupplierAutocomplete from "../components/Entity/Supplier/SupplierAutocomplete";
import SuppliersAutocomplete from "../components/Entity/Supplier/SuppliersAutocomplete";
import AdminTenantAutocomplete from "../components/Entity/Tenant/AdminTenantAutocomplete";
import TenantAutocomplete from "../components/Entity/Tenant/TenantAutocomplete";
import TenantsAutocomplete from "../components/Entity/Tenant/TenantsAutocomplete";
import VehicleAutocomplete from "../components/Entity/Vehicle/VehicleAutocomplete";
import VehiclesAutocomplete from "../components/Entity/Vehicle/VehiclesAutocomplete";
import VehicleVisualModelAutocomplete from "../components/Entity/VehicleVisualModel/VehicleVisualModelAutocomplete";
import VehicleVisualModelsAutocomplete from "../components/Entity/VehicleVisualModel/VehicleVisualModelsAutocomplete";
import FilterArrayOfEnumValueInput from "../components/Filters/ValueInputs/FilterArrayOfEnumValueInput";
import FilterBooleanValueInput from "../components/Filters/ValueInputs/FilterBooleanValueInput";
import FilterDateValueInput from "../components/Filters/ValueInputs/FilterDateValueInput";
import FilterEnumValueInput from "../components/Filters/ValueInputs/FilterEnumValueInput";
import FilterNumberValueInput from "../components/Filters/ValueInputs/FilterNumberValueInput";
import FilterStringValueInput from "../components/Filters/ValueInputs/FilterStringValueInput";
import { FilterFieldType, FilterValue } from "../ts/filters";
import { FilterFieldOperatorSpec } from "./filterFieldOperatorSpec";
import { FilterFieldSpec } from "./filterFieldSpec";

export class FilterCatalog {
  // #region Operators

  public static getDynamicEmptyOperator(): FilterFieldOperatorSpec {
    return new FilterFieldOperatorSpec({
      operator: FilterOperator.Empty,
      filterType: FilterType.Dynamic,
      valueType: FilterValueType.None,
      valueInput: undefined,
    });
  }

  public static getDynamicNotEmptyOperator(): FilterFieldOperatorSpec {
    return new FilterFieldOperatorSpec({
      operator: FilterOperator.NotEmpty,
      filterType: FilterType.Dynamic,
      valueType: FilterValueType.None,
      valueInput: undefined,
    });
  }

  public static getOperatorsForFieldSpec(fieldSpec: FilterFieldSpec): FilterFieldOperatorSpec[] {
    switch (fieldSpec.fieldType) {
      case FilterFieldType.String:
        return this.getOperatorsForStringField();
      case FilterFieldType.Number:
        return this.getOperatorsForNumberField();
      case FilterFieldType.Boolean:
        return this.getOperatorsForBooleanField();
      case FilterFieldType.Date:
        return this.getOperatorsForDateField();
      case FilterFieldType.Enum:
        return this.getOperatorsForEnumField();
      case FilterFieldType.Id:
        return this.getOperatorsForIdField();
      case FilterFieldType.ArrayOfString:
        throw new Error(`${FilterFieldType.ArrayOfString} is not supported yet.`);
      case FilterFieldType.ArrayOfEnum:
        return this.getOperatorsForArrayOfEnumField();
      case FilterFieldType.ArrayOfId:
        throw new Error(`${FilterFieldType.ArrayOfId} is not supported yet.`);
      default:
        return [];
    }
  }

  public static getOperatorsForStringField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Contain,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotContain,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForNumberField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.LessThan,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.LessThanOrEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.GreaterThan,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.GreaterThanOrEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Number,
        valueInput: (props) => <FilterNumberValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForBooleanField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Boolean,
        valueInput: (props) => <FilterBooleanValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Boolean,
        valueInput: (props) => <FilterBooleanValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForDateField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.LessThan,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.LessThanOrEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.GreaterThan,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.GreaterThanOrEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Date,
        valueInput: (props) => <FilterDateValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForEnumField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Contain,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotContain,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Enum,
        valueInput: (props) => <FilterEnumValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Enum,
        valueInput: (props) => <FilterEnumValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfEnum,
        valueInput: (props) => <FilterArrayOfEnumValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfEnum,
        valueInput: (props) => <FilterArrayOfEnumValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.String,
        valueInput: (props) => <FilterStringValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForArrayOfEnumField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfEnum,
        valueInput: (props) => <FilterArrayOfEnumValueInput {...props} />,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfEnum,
        valueInput: (props) => <FilterArrayOfEnumValueInput {...props} />,
      }),
      this.getDynamicEmptyOperator(),
      this.getDynamicNotEmptyOperator(),
    ];
  }

  //#region Specific operators

  public static getOperatorsForIdFieldOfUser(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <UserAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <UserAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <UsersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <UsersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicle(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehiclesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehiclesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfAsset(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfContract(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <ContractAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <ContractAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <ContractsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <ContractsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfCustomer(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <CustomerAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <CustomerAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <CustomersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <CustomersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfSupplier(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <SupplierAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <SupplierAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <SuppliersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <SuppliersAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfAssetSubscriptionPlan(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetSubscriptionPlanAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetSubscriptionPlanAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetSubscriptionPlansAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetSubscriptionPlansAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfAssetSubscription(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetSubscriptionAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AssetSubscriptionAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetSubscriptionsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <AssetSubscriptionsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicleVisualModel(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleVisualModelAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleVisualModelAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleVisualModelsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleVisualModelsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicleMake(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleMakeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleMakeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleMakesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleMakesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicleModel(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleModelAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleModelAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleModelsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleModelsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicleGeneration(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleGenerationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleGenerationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleGenerationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleGenerationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehicleModification(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleModificationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehicleModificationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleModificationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehicleModificationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfRepairWork(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <RepairWorkAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <RepairWorkAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <RepairWorksAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <RepairWorksAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfVehiclePartType(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehiclePartTypeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            vehiclePartTypeId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <VehiclePartTypeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            vehiclePartTypeId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehiclePartTypesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <VehiclePartTypesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfDamageType(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <DamageTypeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            damageTypeId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <DamageTypeAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            damageTypeId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <DamageTypesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <DamageTypesAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfProductLocation(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <ProductLocationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <ProductLocationAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <ProductLocationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <ProductLocationsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfTenant(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <TenantAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <TenantAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <TenantsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.ArrayOfId,
        valueInput: (props) => (
          <TenantsAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityIds={props.item.valueAsArrayOfId()}
            entities={undefined}
            onChange={(newValue) => {
              props.onChange(newValue?.map((x) => x.id || ""));
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForIdFieldOfAdminTenant(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Equal,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AdminTenantAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEqual,
        filterType: FilterType.Dynamic,
        valueType: FilterValueType.Id,
        valueInput: (props) => (
          <AdminTenantAutocomplete
            {...props.baseEntitySearchAutocompleteProps}
            entityId={props.item.valueAsId()}
            onChange={(newValue) => {
              props.onChange(newValue?.id);
            }}
          />
        ),
      }),
      FilterCatalog.getDynamicEmptyOperator(),
      FilterCatalog.getDynamicNotEmptyOperator(),
    ];
  }

  public static getOperatorsForTagsField(): FilterFieldOperatorSpec[] {
    return [
      new FilterFieldOperatorSpec({
        operator: FilterOperator.AnyOf,
        filterType: FilterType.Static,
        valueType: FilterValueType.Arbitrary,
        valueInput: (props) => (
          <GeneralAttachedTagsInput
            {...props}
            onChange={(newValue) =>
              props.onChange(
                (newValue && newValue.tags && newValue.tags.length !== 0
                  ? newValue
                  : undefined) as FilterValue,
              )
            }
            value={props.item.value as GeneralAttachedTagsInputDto}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NoneOf,
        filterType: FilterType.Static,
        valueType: FilterValueType.Arbitrary,
        valueInput: (props) => (
          <GeneralAttachedTagsInput
            {...props}
            onChange={(newValue) =>
              props.onChange(
                (newValue && newValue.tags && newValue.tags.length !== 0
                  ? newValue
                  : undefined) as FilterValue,
              )
            }
            value={props.item.value as GeneralAttachedTagsInputDto}
          />
        ),
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.Empty,
        filterType: FilterType.Static,
        valueType: FilterValueType.Arbitrary,
        valueInput: () => <></>,
      }),
      new FilterFieldOperatorSpec({
        operator: FilterOperator.NotEmpty,
        filterType: FilterType.Static,
        valueType: FilterValueType.Arbitrary,
        valueInput: () => <></>,
      }),
    ];
  }

  //#endregion

  // #endregion
}
