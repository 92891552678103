import { User } from "@auth0/auth0-spa-js";

import { useAppSelector } from "../redux";
import { useAuth0User } from "./useAuth0User";

/** Returns authentication info for currently authenticated user. */
export const useAuthenticationInfo = (): {
  isAuthenticated?: boolean;
  auth0User?: User;
} => {
  const auth0User = useAuth0User();
  const isAuthenticated = useAppSelector((x) => x.auth.isAuthenticated);

  return {
    isAuthenticated,
    auth0User,
  };
};
