import uuid4 from "uuid4";

export class IdHelper {
  /** Generates a unique ID. */
  public static newId(): string {
    return this.newUuid4();
  }

  /** Generates a unique UUID4. */
  public static newUuid4(): string {
    return uuid4();
  }
}
