import { Button, Stack } from "@mui/material";
import { useState } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import Datetime from "@/common/components/Datetime/Datetime";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover, { AppPopoverProps } from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import AppTypography from "@/common/components/Text/AppTypography";
import { TypeHelper } from "@/common/helpers/type";
import {
  AppPermission,
  GeneralStatusMetaDto,
  IBaseEntityDto,
  TagEntityType,
} from "@/core/api/generated";

import GeneralByWhoDisplay from "../../General/Display/GeneralByWhoDisplay";
import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import GeneralStatusHistoryPaginatedListModal from "../GeneralStatusHistory/ListView/GeneralStatusHistoryPaginatedListModal";
import GeneralStatusHistoryViewModal from "../GeneralStatusHistory/View/GeneralStatusHistoryViewModal";
import GeneralStatusEnumValueInline from "./GeneralStatusEnumValueInline";

export interface GeneralStatusMetaDetailsPopoverProps extends Omit<AppPopoverProps, "children"> {
  value: GeneralStatusMetaDto | undefined | null;
  subjectEntity: IBaseEntityDto | undefined;
  onMetaUpdated: (newValue: GeneralStatusMetaDto) => void;
}

export default function GeneralStatusMetaDetailsPopover({
  value,
  subjectEntity,
  onMetaUpdated,
  ...otherProps
}: GeneralStatusMetaDetailsPopoverProps) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  if (!value) {
    return null;
  }

  return (
    <>
      <AuthorizedElement permissions={[AppPermission.GeneralStatusHistoryRead]}>
        <AppPopover {...otherProps}>
          <AppPopoverContent sx={{ minWidth: 300 }}>
            <Stack spacing={1}>
              <AppTypography variant='h5'>Current status</AppTypography>

              <FieldValue label='Status' direction='row' isEmpty={TypeHelper.isEmpty(value.status)}>
                <GeneralStatusEnumValueInline
                  statusEnumTypeName={value.statusEnumTypeName}
                  status={value.status}
                />
              </FieldValue>

              <FieldValue
                label='Previous status'
                direction='row'
                isEmpty={TypeHelper.isEmpty(value.prevStatus)}
              >
                <GeneralStatusEnumValueInline
                  statusEnumTypeName={value.statusEnumTypeName}
                  status={value.prevStatus}
                />
              </FieldValue>

              <FieldValue
                label='Started at'
                direction='row'
                isEmpty={TypeHelper.isEmpty(value.startedAt)}
              >
                <Datetime datetime={value.startedAt} withDurationFromNow />
              </FieldValue>

              <FieldValue
                label='Started by'
                direction='row'
                isEmpty={TypeHelper.isEmpty(value.startedBy)}
              >
                <GeneralByWhoDisplay who={value.startedBy} />
              </FieldValue>

              <FieldValue
                label='Start reason'
                direction='row'
                isEmpty={TypeHelper.isEmpty(value.startReason)}
              >
                {value.startReason}
              </FieldValue>

              <FieldValue
                label='Start mode'
                direction='row'
                isEmpty={TypeHelper.isEmpty(value.startAutoMode)}
              >
                <InlineApiEnumValue
                  type='AutomationCreateMode'
                  value={value.startAutoMode}
                  withHelperTooltip
                />
              </FieldValue>

              <FieldValue label='Tags' direction='row' isEmpty={TypeHelper.isEmpty(value.status)}>
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={value.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.GeneralStatusHistory,
                      entityId: value.statusHistoryId,
                      onSaved: (newValue) => {
                        onMetaUpdated({
                          ...value,
                          tags: newValue || undefined,
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              </FieldValue>

              <Stack direction='row' spacing={1}>
                <Button
                  variant='outlined'
                  size='small'
                  color='info'
                  startIcon={<AppIcon of='details' />}
                  onClick={() => {
                    setIsDetailsModalOpen(true);
                  }}
                >
                  View status details
                </Button>

                <Button
                  variant='outlined'
                  size='small'
                  color='info'
                  startIcon={<AppIcon of='history' />}
                  onClick={() => {
                    setIsHistoryModalOpen(true);
                  }}
                >
                  View status history
                </Button>
              </Stack>
            </Stack>
          </AppPopoverContent>
        </AppPopover>
      </AuthorizedElement>

      {value && isDetailsModalOpen && (
        <GeneralStatusHistoryViewModal
          open={isDetailsModalOpen}
          onClose={() => setIsDetailsModalOpen(false)}
          viewProps={{
            generalStatusHistoryId: value.statusHistoryId,
            generalStatusHistory: undefined,
            displayProps: {
              isForSubjectEntity: true,
            },
          }}
        />
      )}

      {value && isHistoryModalOpen && (
        <GeneralStatusHistoryPaginatedListModal
          open={isHistoryModalOpen}
          onClose={() => setIsHistoryModalOpen(false)}
          subject={value.subject}
          subjectEntity={subjectEntity}
          statusMeta={value}
          listProps={{
            displayProps: {
              isForSubjectEntity: true,
            },
          }}
        />
      )}
    </>
  );
}
