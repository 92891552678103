import { useMemo } from "react";

import { apiClient } from "@/core/api/ApiClient";
import { CascadeActionSpecDto, CascadeActionType, EntityType } from "@/core/api/generated";

import { useApiRequest } from "../../api/useApiRequest";

export default function useCascadeActionSpec(
  entityType: EntityType,
  actionType: CascadeActionType,
): CascadeActionSpecDto | null | undefined {
  const request = useApiRequest(
    apiClient.cascadeActionsApi.apiV1CascadeActionsSpecGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      actionType: actionType,
      baseEntityType: entityType,
    },
    {
      deps: [entityType, actionType],
      skip: !entityType || !actionType,
    },
  );

  const spec = useMemo(() => request.data, [request.data]);

  return spec;
}
