import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { LANGUAGE_CODES } from "./common/constants/common";
import languageEN from "./locate/en/translate.json";

export const resources = {
  [LANGUAGE_CODES.EN]: languageEN,
} as const;

export const defaultNS = "translation";

i18n.use(initReactI18next).init({
  lng: "en",
  ns: ["translation"],
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false,
  },
  react: {
    nsMode: "default",
  },
  // debug: true,
});

export default i18n;
