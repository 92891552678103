import { LinearProgress, Stack } from "@mui/material";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import ViewLayout from "@/App/Layouts/ViewLayout";
import SplitDropdownButton from "@/common/components/Button/SplitDropdownButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EnvHelper } from "@/common/helpers/env";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import ProfilePageHeader from "./ProfilePageHeader";
import DevInfoTabContent from "./Tabs/DevInfoTabContent";
import MyDepartmentsTabContent from "./Tabs/MyDepartmentsTabContent";
import MyPermissionsTabContent from "./Tabs/MyPermissionsTabContent";
import MyRolesTabContent from "./Tabs/MyRolesTabContent";
import PersonalInfoTabContent from "./Tabs/PersonalInfoTabContent";
import SettingsTabContent from "./Tabs/SettingsTabContent";

export enum ProfileTabs {
  PersonalInfo = "PersonalInfo",
  Settings = "Settings",
  MyDepartments = "MyDepartments",
  MyRoles = "MyRoles",
  MyPermissions = "MyPermissions",
  DevInfo = "DevInfo",
}

export default function Profile() {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileRequest = useApiRequest(apiClient.profileApi.apiV1ProfileGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const profile = useMemo(() => profileRequest.data, [profileRequest.data]);

  return (
    <ViewLayout
      header={
        <ProfilePageHeader
          secondaryActions={
            <SplitDropdownButton
              isSelectAndTriggerAction
              buttonProps={{
                variant: "contained",
                color: "primary",
                startIcon: <AppIcon of='edit' />,
              }}
              options={[
                {
                  content: "Edit personal info",
                  onClick: () => history.push(ROUTE_PATH.FLEET_PROFILE_PERSONAL_INFO_EDIT),
                },
                {
                  content: "Edit settings",
                  onClick: () => history.push(ROUTE_PATH.FLEET_PROFILE_SETTINGS_EDIT),
                },
              ]}
            />
          }
        />
      }
    >
      <Stack spacing={1}>
        {profileRequest.isLoading && <LinearProgress />}

        <PageTabs
          tabIdsDefinition={ProfileTabs}
          defaultTabId={ProfileTabs.PersonalInfo}
          tabs={[
            { label: "Personal Info", value: ProfileTabs.PersonalInfo },
            { label: "Settings", value: ProfileTabs.Settings },
            { label: "My Departments", value: ProfileTabs.MyDepartments },
            { label: "My Roles", value: ProfileTabs.MyRoles },
            { label: "My Permissions", value: ProfileTabs.MyPermissions },
            ...(EnvHelper.isDevelopmentAny
              ? [{ label: "Dev Info", value: ProfileTabs.DevInfo }]
              : []),
          ]}
        >
          {({ activeTabId: activeTab }) => (
            <>
              {activeTab === ProfileTabs.PersonalInfo && profile && (
                <PersonalInfoTabContent profile={profile} />
              )}
              {activeTab === ProfileTabs.Settings && profile && (
                <SettingsTabContent profile={profile} />
              )}
              {activeTab === ProfileTabs.MyDepartments && profile && (
                <MyDepartmentsTabContent profile={profile} />
              )}
              {activeTab === ProfileTabs.MyRoles && <MyRolesTabContent />}
              {activeTab === ProfileTabs.MyPermissions && <MyPermissionsTabContent />}
              {activeTab === ProfileTabs.DevInfo && profile && (
                <DevInfoTabContent profile={profile} />
              )}
            </>
          )}
        </PageTabs>
      </Stack>
    </ViewLayout>
  );
}
