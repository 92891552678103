import _ from "lodash";

import { ValidationProblemDetails } from "@/core/api/generated";

import { ValidationProblemDetailsWithExtensions } from "../ts/problemDetails";

export class ProblemDetailsHelper {
  public static getWithExtensions(
    problemDetails: ValidationProblemDetails,
  ): ValidationProblemDetailsWithExtensions {
    return problemDetails as ValidationProblemDetailsWithExtensions;
  }
}
