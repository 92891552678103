import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useMemo, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import ContractInline from "@/common/components/Entity/Contract/ContractInline";
import DamageDetectionAggregateDeleteModal from "@/common/components/Entity/DamageDetectionAggregate/DamageDetectionAggregateDeleteModal";
import VehicleInline from "@/common/components/Entity/Vehicle/VehicleInline";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageDetectionAggregateDto,
  DamageDetectionAggregateGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  GeneralAttachedTagsInputDto,
  TagEntityType,
} from "@/core/api/generated";

import DamageDetectionInline from "../../DamageDetection/DamageDetectionInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import StartDamageCostEvalModal from "../StartDamageCostEvalModal";

type DamageDetectionAggregateGetPaginatedInputDto = Omit<
  DamageDetectionAggregateGetPaginatedDto,
  "tags"
> & {
  tags?: TagStaticFilterInputDto;
};
export default function DamageDetectionAggregatesPaginatedList() {
  const { enqueueSnackbar } = useAppSnackbar();
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<DamageDetectionAggregateGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.DamageDetectionAggregate,
    },
    initParams: (filterDefinition) => {
      const tagFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<DamageDetectionAggregateDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagFilter?.operator,
          query: tagFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  // menu, dialogs
  const [isStartDamageCostEvaluationModalOpen, setIsStartDamageCostEvaluationModalOpen] =
    useState(false);
  const [aggregate, setAggregate] = useState<DamageDetectionAggregateDto | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const paginatedDamageDetectionAggregatesRequest = useApiRequest(
    apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageDetectionAggregateGetPaginatedDto: {
        ..._.omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<DamageDetectionAggregateDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps, commonRequestParams.filterDefinition],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedDamageDetectionAggregates = useMemo(
    () => paginatedDamageDetectionAggregatesRequest?.data,
    [paginatedDamageDetectionAggregatesRequest.data],
  );

  const damageDetectionAggregateIds = useMemo(
    () => paginatedDamageDetectionAggregates?.items?.map((item) => item.id!),
    [paginatedDamageDetectionAggregates],
  );

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageDetectionAggregate),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedDamageDetectionAggregatesRequest.handleEntityChanged(data);
    },
  });

  return (
    <Stack spacing={2}>
      {/* <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ justifyContent: 'space-between'}}>
      </Stack> */}
      <SimpleViewPageHeader
        title={undefined}
        primaryActions={undefined}
        secondaryActions={
          <AuthorizedElement permissions={[AppPermission.ExportManage]}>
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                <Typography>Export aggregates</Typography>
              </MenuItem>
            </MenuWithTrigger>
          </AuthorizedElement>
        }
      />

      <Divider sx={{ my: 2 }} />

      <Stack direction='column' spacing={1}>
        <DataTabular
          columns={[
            {
              field: getTypedPath<DamageDetectionAggregateDto>().id.$path,
              title: "ID",
              width: 250,
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => item.id,
              filters: {
                fieldType: FilterFieldType.Id,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().localNumber.$path,
              title: "Number",
              isVisible: true,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) => (
                <Stack direction='row' spacing={0.5}>
                  <span>{item.localNumber}</span>
                </Stack>
              ),
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: "Info badges", // maybe should find better name
              title: "Info badges",
              width: 200,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().vehicle.id.$path,
              title: "Vehicle",
              width: 300,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => <VehicleInline entity={item.vehicle} />,
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().contract.id.$path,
              title: "Contract",
              width: 300,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.contract ? <ContractInline entity={item.contract} /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().notes.$path,
              title: "Notes",
              width: 100,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) => item.notes || "-",
              filters: {
                fieldType: FilterFieldType.String,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().createdAt.$path,
              title: "Created at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().updatedAt.$path,
              title: "Updated at",
              isVisible: false,
              isHideable: true,
              isSortable: true,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
              filters: {
                fieldType: FilterFieldType.Date,
                isUseDefaultOperators: true,
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().createdBy.$path,
              title: "Created by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().updatedBy.$path,
              title: "Updated by",
              isVisible: false,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              renderCell: (item) =>
                item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
              filters: {
                fieldType: FilterFieldType.Id,
                operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
              },
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().items.$path,
              title: "Items",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().sourceDamageDetections.$path,
              title: "Source damage detections",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <TableCellContentOfCount
                  count={item.sourceDamageDetections?.length}
                  popoverContent={
                    <Stack>
                      {item.sourceDamageDetections?.map((x, i) => (
                        <DamageDetectionInline key={i} entity={x} />
                      ))}
                    </Stack>
                  }
                />
              ),
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().attachments.$path,
              title: "Attachments",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <TableCellContentOfAttachments attachments={item.attachments} />
              ),
            },
            {
              field: "affiliation",
              title: "Affiliation",
              description: "Affiliation to departments & locations",
              width: 120,
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: false,
              isToDisabled: true,
              renderCell: (item) => (
                <AffiliationInfoDisplay
                  variant='compact'
                  tenantId={item.tenantId}
                  departmentIds={item.departmentIds}
                  locationIds={item.locationIds}
                />
              ),
            },
            {
              field: getTypedPath<DamageDetectionAggregateDto>().tags.$path,
              title: "Tags",
              isVisible: true,
              isHideable: true,
              isSortable: false,
              isFilterable: true,
              isColumnMenuDisabled: false,
              isToDisabled: true,
              renderCell: (item) => (
                <EntityTagsInfoDisplay
                  entityType={TagEntityType.DamageDetectionAggregate}
                  entity={item}
                  noDataPlaceholder='-'
                  edit={{
                    onSaved: (newValue) =>
                      paginatedDamageDetectionAggregatesRequest.updateData((data) => {
                        data.items?.forEach((item2) => {
                          if (item2.id === item.id) {
                            item2.tags = newValue || undefined;
                          }
                        });
                      }),
                  }}
                />
              ),
              filters: {
                fieldType: FilterFieldType.Arbitrary,
                isUseSingleFilter: true,
                operators: FilterCatalog.getOperatorsForTagsField(),
              },
            },
          ]}
          rows={paginatedDamageDetectionAggregates?.items}
          isLoading={paginatedDamageDetectionAggregatesRequest.isLoading}
          getRowId={(item) => item.id!}
          rowTo={(item) => ROUTE_PATH.DAMAGE_DETECTION_AGGREGATE_VIEW(item.id)}
          renderRowAction={({ item }) => (
            <>
              <MenuWithTrigger
                withAuthCloseOnClick
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                <AuthorizedMenuItem
                  permissions={[AppPermission.DamageDetectionManage]}
                  onClick={() => {
                    setAggregate(item);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='delete' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </AuthorizedMenuItem>
              </MenuWithTrigger>
            </>
          )}
          statePersistence={commonRequestParams.dataTabularProps.statePersistence}
          pagination={commonRequestParams.dataTabularProps.pagination}
          sort={commonRequestParams.dataTabularProps.sort}
          quickFilter={commonRequestParams.dataTabularProps.quickFilter}
          filters={commonRequestParams.dataTabularProps.filters}
          refetch={commonRequestParams.dataTabularProps.refetch}
        />
      </Stack>

      {/* Start cost eval */}
      {aggregate && (
        <StartDamageCostEvalModal
          open={isStartDamageCostEvaluationModalOpen}
          onClose={() => setIsStartDamageCostEvaluationModalOpen(false)}
          aggregate={aggregate}
        />
      )}

      {/* Delete modal */}
      {aggregate && (
        <DamageDetectionAggregateDeleteModal
          entity={aggregate}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={() => paginatedDamageDetectionAggregatesRequest.refetch()}
        />
      )}

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.DamageDetectionAggregate,
          ids: damageDetectionAggregateIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </Stack>
  );
}
