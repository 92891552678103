import { Chip, Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { TenantConnectionRequestDto } from "@/core/api/generated";

import TenantLink from "../../../Tenant/TenantLink";

interface Props {
  tenantConnectionRequest: TenantConnectionRequestDto;
}

export default function OverviewTabContent({ tenantConnectionRequest }: Props) {
  const currentTenant = useCurrentTenant();

  return (
    <PageTabContent>
      <ViewLayoutV2 displayProps={{ viewVariant: ViewLayoutVariant.Tab }}>
        <ViewContentBlock>
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={tenantConnectionRequest} />}
          />

          <FieldValue label='Number'>{tenantConnectionRequest?.globalNumber}</FieldValue>

          <FieldValue label='Sender'>
            <TenantLink entity={tenantConnectionRequest?.senderTenant} entityId={undefined} />{" "}
            {currentTenant?.id === tenantConnectionRequest?.senderTenant?.id && (
              <Chip size='small' variant='outlined' color='default' label='You' />
            )}
          </FieldValue>

          <FieldValue label='Receiver'>
            <TenantLink entity={tenantConnectionRequest?.receiverTenant} entityId={undefined} />{" "}
            {currentTenant?.id === tenantConnectionRequest?.receiverTenant?.id && (
              <Chip size='small' variant='outlined' color='default' label='You' />
            )}
          </FieldValue>

          <FieldValue label='Status'>
            <InlineApiEnumValue
              type='TenantConnectionRequestStatus'
              value={tenantConnectionRequest?.status}
            />
          </FieldValue>
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
