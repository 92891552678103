import { FilterDefinition } from "../filters/filterDefinition";
import { SortDefinition } from "../sorting/sortDefinition";

export const CommonRequestParamsStatePersistenceStrategy = {
  /** State is persisted to the local storage. */
  LocalStorage: "LocalStorage",
  /** State is persisted to the URL. */
  Url: "Url",
} as const;
export type CommonRequestParamsStatePersistenceStrategy =
  (typeof CommonRequestParamsStatePersistenceStrategy)[keyof typeof CommonRequestParamsStatePersistenceStrategy];

export interface CommonRequestParamsStatePersistenceProps {
  /** Used to identify the state during persist/restore. */
  persistenceKey: string;
  /** If state persistence is enabled.
   * @default true
   */
  isEnabled?: boolean;
  /** Persistence strategy to use. */
  strategy?: CommonRequestParamsStatePersistenceStrategy;
}

export interface CommonRequestParamsState {
  /** UTC date when the state object was persisted last time. */
  lastPersistedAt?: string;
  pagination?: CommonRequestParamsPaginationState;
  sort?: SortDefinition;
  filter?: FilterDefinition;
}

export interface CommonRequestParamsPaginationState {
  limit?: number;
}

/** Stores all states in a single aggregated object to reduce clutter in local storage. */
export interface CommonRequestParamsStateAggregatedLocalStorageModel {
  /** {[persistenceKey]: [state]} */
  byKeyMap?: Record<string, CommonRequestParamsState>;
}
