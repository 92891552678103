import { Box, SxProps, Theme } from "@mui/material";
import { forwardRef } from "react";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralInsuranceDto,
  GeneralInsuranceInputDto,
} from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import AppIcon from "../../../Icons/AppIcon";

interface GeneralInsuranceDisplayProps {
  insurance?: GeneralInsuranceDto | GeneralInsuranceInputDto | null;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
  sx?: SxProps<Theme>;
}

/** Displays general insurance value. */
export default forwardRef<HTMLSpanElement, GeneralInsuranceDisplayProps>(
  function GeneralInsuranceDisplay(
    {
      insurance,
      currency,
      currencyCode,
      withIcon = false,
      withTooltip = false,
      tooltipText = "Insurance",
      sx,
    }: GeneralInsuranceDisplayProps,
    ref,
  ) {
    const currentCurrency = useCurrentCurrency();
    const _currency =
      currency ||
      (currencyCode && ({ code: currencyCode } as GeneralCurrencyDto)) ||
      insurance?.currency ||
      currentCurrency;
    const _currencyCode =
      currency?.code || currencyCode || insurance?.currency?.code || currentCurrency?.code;

    if (!insurance) {
      return null;
    }

    return (
      <AppTooltip
        enabled={withTooltip && !!tooltipText}
        title={tooltipText || ""}
        wrapperProps={{
          component: "span",
        }}
      >
        <Box ref={ref} component='span' sx={sx}>
          {withIcon && <AppIcon of='price' inText />}
          <CurrencyValue
            value={insurance.price}
            currency={_currency}
            currencyCode={_currencyCode}
          />
        </Box>
      </AppTooltip>
    );
  },
);
