import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";

import { appCommonConfig } from "@/config/config";

import { auth0Provider } from "../auth0Provider";
import {
  TENANT_IDENTIFIER_HEADER_NAME,
  TENANT_IDENTIFIER_QUERY_PARAMETER_NAME,
} from "../constants/multitenancy";
import { TypedEventEmitter } from "../eventEmmiters/typedEventEmitter";
import { UrlHelper } from "../helpers/url";
import { tenantService } from "../services/tenant";
import { HubSubscription } from "./hubSubscription";

export class BaseHubService extends TypedEventEmitter<{
  // list of supported events
  connectionStateChange: HubConnectionState;
  initialized: { connection: HubConnection };
  connected: { connectionId?: string | null };
  disconnected: undefined;
  reconnecting: undefined;
  reconnected: { connectionId?: string | null };
}> {
  protected hubName: string;
  protected logPrefix: string;
  protected showLogs: boolean;
  protected logSignalR: boolean;
  protected logIncomingMessages: boolean;
  protected logOutcomingMessages: boolean;

  protected connection?: HubConnection;
  protected isConnected?: boolean;
  private hubSubscriptions: HubSubscription[] = [];

  constructor(params: {
    hubName: string;
    showLogs?: boolean;
    logSignalR?: boolean;
    logIncomingMessages?: boolean;
    logOutcomingMessages?: boolean;
  }) {
    super();
    this.hubName = params.hubName;
    this.logPrefix = `${params.hubName}.`;
    this.showLogs = params.showLogs || false;
    this.logSignalR = this.showLogs && (params.logSignalR || false);
    this.logIncomingMessages = this.showLogs && (params.logIncomingMessages || false);
    this.logOutcomingMessages = this.showLogs && (params.logOutcomingMessages || false);

    this.on("reconnected", async ({ connectionId }) => {
      const promises = this.hubSubscriptions
        .filter((x) => x.connectionId !== connectionId)
        .map((x) => {
          x.connectionId = connectionId;
          return x.subscribe();
        });
      await Promise.all(promises);
      this.showLogs &&
        console.log(this.logPrefix, `Restored ${promises.length} hub subscriptions.`);
    });
  }

  public get connectionId(): string | null | undefined {
    return this.connection?.connectionId;
  }

  public get connectionState(): HubConnectionState | undefined {
    return this.connection?.state;
  }

  protected async connectToHub(hubPath: string): Promise<void> {
    // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio

    const tenantInfo = tenantService.resolveTenant();
    const tenantIdentifier = tenantInfo?.identifier || "";

    let url = UrlHelper.updateUrlPathname(appCommonConfig.apiUrl!, hubPath);
    url = UrlHelper.updateUrlSearchParams(url, {
      [TENANT_IDENTIFIER_QUERY_PARAMETER_NAME]: tenantIdentifier,
    });
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => auth0Provider.auth0Client?.getTokenSilently() || "",
        withCredentials: true,
        headers: {
          [TENANT_IDENTIFIER_HEADER_NAME]: tenantIdentifier,
        },
      })
      .withAutomaticReconnect([
        0, 2000, 2000, 2000, 5000, 5000, 5000, 5000, 5000, 10_000, 10_000, 10_000, 10_000, 10_000,
        20_000, 30_000, 40_000, 50_000, 60_000, 100_000, 300_000, 300_000, 300_000, 300_000,
      ]) // change the default behavior
      .configureLogging(this.logSignalR ? LogLevel.Information : LogLevel.Error)
      .build();
    this.connection = connection;

    connection.onclose((err) => {
      this.showLogs && console.log(this.logPrefix, "Connection is closed. Error:", err);
      this.isConnected = false;
      this.emit("connectionStateChange", this.connection?.state || HubConnectionState.Disconnected);
      this.emit("disconnected", undefined);
    });
    connection.onreconnecting((err) => {
      this.showLogs && console.log(this.logPrefix, "Connection is reconnecting. Error:", err);
      this.isConnected = false;
      this.emit("connectionStateChange", this.connection?.state || HubConnectionState.Disconnected);
      this.emit("reconnecting", undefined);
    });
    connection.onreconnected((connectionId) => {
      this.showLogs &&
        console.log(this.logPrefix, "Connection reconnected. connectionId:", connectionId);
      this.isConnected = true;
      this.emit("connectionStateChange", this.connection?.state || HubConnectionState.Disconnected);
      this.emit("connected", { connectionId });
      this.emit("reconnected", { connectionId });
    });

    this.emit("initialized", { connection });

    await this.connectWithInitialRetry(connection);
  }

  protected async disconnectFromHub(): Promise<void> {
    await this.connection?.stop();
  }

  private async connectWithInitialRetry(connection: HubConnection, tryNumber = 1) {
    const maxInitialRetries = -1;
    const initialRetriesTimeout = 5000;

    if (connection && connection.state !== HubConnectionState.Disconnected) {
      return;
    }

    try {
      this.showLogs &&
        tryNumber > 1 &&
        console.log(this.logPrefix, "Initial connect number: ", tryNumber);

      await connection.start();
      this.isConnected = true;

      this.emit("connected", { connectionId: connection.connectionId });
      this.showLogs &&
        console.log(this.logPrefix, "Connected.", "connectionId:", connection.connectionId);
    } catch (err) {
      console.error(this.logPrefix, "Error connecting to", this.hubName, err);

      // withAutomaticReconnect won't configure the HubConnection to retry initial start failures,
      // so start failures need to be handled manually
      if (maxInitialRetries === -1 || tryNumber < maxInitialRetries) {
        setTimeout(
          () => this.connectWithInitialRetry(connection, tryNumber + 1),
          initialRetriesTimeout,
        );
      } else {
        console.error(
          this.logPrefix,
          "Unable to establish initial connection to",
          this.hubName,
          ". Stopping  trying.",
        );
      }
    }
  }

  /** If not connected, wait until connected or timeout. */
  protected ensureConnectedToHub(timeoutMs = 60 * 1000): Promise<void> {
    if (this.isConnected) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      let intevalHandle: NodeJS.Timeout | undefined = undefined;
      let timeoutHandle: NodeJS.Timeout | undefined = undefined;

      intevalHandle = setInterval(() => {
        if (this.isConnected) {
          clearInterval(intevalHandle);
          clearTimeout(timeoutHandle);
          resolve();
        }
      }, 1000);

      timeoutHandle = setTimeout(() => {
        clearInterval(intevalHandle);
        reject("Timed out.");
      }, timeoutMs);
    });
  }

  protected registerMethodHandler(methodName: string, handler: (...args: any[]) => void) {
    this.connection?.on(methodName, (...args) => {
      this.logIncomingMessages &&
        console.log(this.logPrefix, `on ${methodName}.`, "args:", ...args);
      handler(...args);
    });
  }

  protected async send(methodName: string, ...args: any[]): Promise<void> {
    try {
      await this.connection?.send(methodName, ...args);
      this.logOutcomingMessages &&
        console.log(this.logPrefix, `Invoked server method '${methodName}'. Args:`, ...args);
    } catch (err) {
      console.error(
        this.logPrefix,
        `Error invoking server method '${methodName}' with args:`,
        ...args,
      );
      throw err;
    }
  }

  protected async addSubscription(subscriptionInfo: HubSubscription) {
    const index = this.hubSubscriptions.findIndex(
      (x) => x.identifier === subscriptionInfo.identifier,
    );
    if (index === -1) {
      this.hubSubscriptions.push(subscriptionInfo);
    } else {
      const subscription = this.hubSubscriptions[index];
      await subscription.unsubscribe();
      this.hubSubscriptions.splice(index, 1, subscriptionInfo);
    }

    const subscription = subscriptionInfo;
    subscription.connectionId = this.connection?.connectionId;
    await subscription.subscribe();
  }

  protected async removeSubscription(subscriptionInfo: Pick<HubSubscription, "identifier">) {
    const index = this.hubSubscriptions.findIndex(
      (x) => x.identifier === subscriptionInfo.identifier,
    );

    if (index !== -1) {
      const subscription = this.hubSubscriptions[index];
      await subscription.unsubscribe();
      this.hubSubscriptions.splice(index, 1);
    }
  }
}
