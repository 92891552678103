import { Box, SxProps, Theme } from "@mui/material";

import AppIcon, { AppIconOf } from "../Icons/AppIcon";

interface Props extends React.DOMAttributes<HTMLImageElement> {
  of?: AppIconOf;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}

export default function ImagePlaceholder({ of, sx, iconSx }: Props) {
  return (
    <Box
      sx={{
        width: "92px",
        height: "92px",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "background.gray",
        ...sx,
      }}
    >
      <AppIcon
        of={of || "image"}
        sx={{ width: "46px", height: "46px", fill: (th) => th.palette.divider, ...iconSx }}
      />
    </Box>
  );
}
