import { Box, Dialog, Stack, SxProps, Theme } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ResponsiveStyleValue } from "@mui/system";
import { useMemo, useState } from "react";

import FieldValue from "@/common/components/Form/Display/FieldValue";
import { GeneralAddressDto } from "@/core/api/generated";

import AppModalTitle from "../../../Modals/AppModalTitle";

interface Props {
  address?: GeneralAddressDto;
  direction?: ResponsiveStyleValue<"row" | "column" | "column-reverse" | "row-reverse">;
  sx?: SxProps<Theme>;
}

export default function GeneralAddressDisplay({ address, direction = "column", sx }: Props) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const textLine1 = useMemo(
    () => [address?.line1, address?.line2].filter((x) => !!x).join(", "),
    [address],
  );
  const textLine2 = useMemo(
    () => [address?.city, address?.state, address?.postalCode].filter((x) => !!x).join(", "),
    [address],
  );
  const textLine3 = useMemo(() => [address?.country].filter((x) => !!x).join(", "), [address]);

  return (
    <Box sx={sx}>
      <Box sx={{ cursor: "pointer" }} onClick={() => setIsDetailsModalOpen(true)}>
        <Stack direction={direction} spacing={direction == "row" ? 1 : 0}>
          {textLine1 && <Box>{textLine1}</Box>}
          {textLine2 && <Box>{textLine2}</Box>}
          {textLine3 && <Box>{textLine3}</Box>}
        </Stack>
      </Box>

      <Dialog
        maxWidth='md'
        fullWidth
        open={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
      >
        <AppModalTitle onCloseClicked={() => setIsDetailsModalOpen(false)}>
          Address details
        </AppModalTitle>
        <DialogContent>
          <Stack spacing={1}>
            <FieldValue label='Country' isEmpty={!address?.country}>
              {address?.country}
            </FieldValue>
            <FieldValue label='City' isEmpty={!address?.city}>
              {address?.city}
            </FieldValue>
            <FieldValue label='State' isEmpty={!address?.state}>
              {address?.state}
            </FieldValue>
            <FieldValue label='Line 1' isEmpty={!address?.line1}>
              {address?.line1}
            </FieldValue>
            <FieldValue label='Line 2' isEmpty={!address?.line2}>
              {address?.line2}
            </FieldValue>
            <FieldValue label='Postal code' isEmpty={!address?.postalCode}>
              {address?.postalCode}
            </FieldValue>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={() => setIsDetailsModalOpen(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
