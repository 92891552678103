import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import RolesSelector, { RolesSelectorProps } from "./RolesSelector";

export interface TenantRolesSelectorProps
  extends Omit<RolesSelectorProps, "getRoles" | "roleList" | "roleAssignmentRestrictions"> {
  tenantIdentifier?: string | null;
  targetUserId?: string | null;
}

export default function TenantRolesSelector({
  tenantIdentifier,
  targetUserId,
  ...otherProps
}: TenantRolesSelectorProps) {
  const roleAssignmentRestrictionsRequest = useApiRequest(
    apiClient.tenantAccountApi.apiV1TenantAccountRoleAssignmentsRestrictionsGet,
    {
      nexusOpsTenant: tenantIdentifier || EMPTY_TENANT_IDENTIFIER,
      targetUserId: targetUserId || undefined,
    },
    {
      deps: [tenantIdentifier, targetUserId],
    },
  );
  const roleAssignmentRestrictions = roleAssignmentRestrictionsRequest?.data;

  return (
    <RolesSelector
      getRoles={async () => {
        const response = await apiClient.tenantAccountApi.apiV1TenantAccountRolesGet({
          nexusOpsTenant: tenantIdentifier || EMPTY_TENANT_IDENTIFIER,
        });
        return response.data;
      }}
      roleAssignmentRestrictions={roleAssignmentRestrictions}
      {...otherProps}
    />
  );
}
