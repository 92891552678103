import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AccessoryView from "@/common/components/Entity/Accessory/View/AccessoryView";

export default function AccessoryViewPage() {
  const { accessoryId } = useParams<{ accessoryId?: string }>();

  return (
    <>
      <AccessoryView
        accessoryId={accessoryId}
        accessory={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
