import { useEffect, useRef } from "react";

import { tenantService } from "@/common/services/tenant";
import { TenantProfileDto } from "@/core/api/generated";
import { fetchTenantProfile } from "@/store/tenantProfile/slice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";

/** Returns currently resolved tenant profile (loads it if not loaded). */
export const useTenantProfile = (): TenantProfileDto | undefined => {
  const dispatch = useAppThunkDispatch();
  const tenantInfo = tenantService.resolveTenant();
  const profile = useAppSelector((x) => x.tenantProfile.profile);
  const isLoadingStore = useAppSelector((x) => x.tenantProfile.loading.getProfile);
  const isLoadingRef = useRef(false);
  const isLoading = isLoadingStore || isLoadingRef.current;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    (async () => {
      if (profile === undefined && tenantInfo?.identifier) {
        isLoadingRef.current = true;
        try {
          await dispatch(fetchTenantProfile());
        } finally {
          isLoadingRef.current = false;
        }
      }
    })();
  }, [dispatch, profile, tenantInfo, isLoading]);

  return profile;
};
