import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EnumDto } from "@/core/api/generated";

export interface EnumsState {
  loading: {
    getVehiclePartCategories?: boolean;
    getVehiclePartTypes?: boolean;
    getVehiclePartDescriptors?: boolean;
    getVehicleAreas?: boolean;
    getVehicleProjections?: boolean;
  };
  vehicleAreas?: EnumDto[];
  vehicleProjections?: EnumDto[];
  vehiclePartCategories?: EnumDto[];
  vehiclePartTypes?: EnumDto[];
  vehiclePartDescriptors?: EnumDto[];
}

const initialState: EnumsState = {
  loading: {},
  vehicleAreas: undefined,
  vehicleProjections: undefined,
  vehiclePartCategories: undefined,
  vehiclePartTypes: undefined,
  vehiclePartDescriptors: undefined,
};

const enumsSlice = createSlice({
  name: "enums",
  initialState,
  reducers: {
    _vehicleAreasFetched: (state, action: PayloadAction<EnumDto[]>) => {
      state.vehicleAreas = action.payload;
    },
    _vehicleProjectionsFetched: (state, action: PayloadAction<EnumDto[]>) => {
      state.vehicleProjections = action.payload;
    },
    _vehiclePartCategoriesFetched: (state, action: PayloadAction<EnumDto[]>) => {
      state.vehiclePartCategories = action.payload;
    },
    _vehiclePartTypesFetched: (state, action: PayloadAction<EnumDto[]>) => {
      state.vehiclePartTypes = action.payload;
    },
    _vehiclePartDescriptorsFetched: (state, action: PayloadAction<EnumDto[]>) => {
      state.vehiclePartDescriptors = action.payload;
    },
    setLoading: (state, action: PayloadAction<EnumsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
});

export const {
  _vehiclePartCategoriesFetched,
  _vehiclePartTypesFetched,
  _vehicleAreasFetched,
  _vehicleProjectionsFetched,
  _vehiclePartDescriptorsFetched,
  setLoading,
} = enumsSlice.actions;

export const enumsReducer = enumsSlice.reducer;
