import { ExternalQueryStringParamName } from "@/config/config";
import {
  FrontEndActionTypeInQueryString,
  FrontEndAuthParamsInQueryStringDto,
} from "@/core/api/generated";

import { EncodingHelper } from "../helpers/encoding";
import { URLSearchParamsObject, UrlHelper } from "../helpers/url";

/** Data in query string */
export interface ActionParamsInQueryString extends URLSearchParamsObject {
  [ExternalQueryStringParamName.ActionParamsEnc]?: string | null;
}

/** Data in query string */
export interface AuthParamsInQueryString extends URLSearchParamsObject {
  [ExternalQueryStringParamName.AuthParamsEnc]?: string | null;
}

/** Data built from query string */
export interface ActionParamsInQueryStringData {
  actionType?: FrontEndActionTypeInQueryString | string;
}

/** Data built from query string */
export type AuthParamsInQueryStringData = FrontEndAuthParamsInQueryStringDto;

export class QueryStringParamsService {
  public getCurrentActionParams(): ActionParamsInQueryStringData | undefined {
    const queryParams = UrlHelper.getCurrentUrlSearchParamsTyped<ActionParamsInQueryString>();
    if (!queryParams || !queryParams.actionParamsEnc) {
      return undefined;
    }

    const paramsDecoded = this.decodeParams<ActionParamsInQueryStringData>(
      queryParams.actionParamsEnc,
    );
    return paramsDecoded;
  }

  public getCurrentAuthParams(): AuthParamsInQueryStringData | undefined {
    const queryParams = UrlHelper.getCurrentUrlSearchParamsTyped<AuthParamsInQueryString>();
    if (!queryParams || !queryParams.authParamsEnc) {
      return undefined;
    }

    const paramsDecoded = this.decodeParams<AuthParamsInQueryStringData>(queryParams.authParamsEnc);
    return paramsDecoded;
  }

  public decodeParams<TDecoded>(paramsEncoded: string): TDecoded | undefined {
    try {
      const json = EncodingHelper.fromBase64(paramsEncoded);
      const data = JSON.parse(json) as TDecoded;
      return data;
    } catch (err) {
      console.error(
        `Error decoding action params from query string. Encoded: "${paramsEncoded}". Error:`,
        err,
      );
      return undefined;
    }
  }
}

export const queryStringParamsService = new QueryStringParamsService();
