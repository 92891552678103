import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";

import AppModalTitle from "./AppModalTitle";

interface OwnProps {
  title?: DialogProps["title"] | ReactNode;
  body?: ReactNode;
  withReasonText?: boolean;
  defaultReasonText?: string | null;
  isCancelLoading?: boolean;
  isConfirmLoading?: boolean;
  isConfirmEnabled?: boolean;
  onReasonTextChange?: (newValue?: string) => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  onConfirm?: (params: { reasonText?: string }) => void | Promise<void>;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonProps?: LoadingButtonProps;
  cancelButtonProps?: LoadingButtonProps;
  // children?: ReactNode;
}

export type ConfirmationModalProps = OwnProps & Omit<DialogProps, "title">;

/** Modal with cancel/confirm buttons. */
export default function ConfirmationModal({
  title,
  body,
  withReasonText,
  defaultReasonText,
  isCancelLoading,
  isConfirmLoading,
  onReasonTextChange,
  onCancel,
  onConfirm,
  isConfirmEnabled = true,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  confirmButtonProps,
  cancelButtonProps,
  ...dialogProps
}: ConfirmationModalProps) {
  const [_isCancelLoading, _setIsCancelLoading] = useState(isCancelLoading);
  const [_isConfirmLoading, _setIsConfirmLoading] = useState(isConfirmLoading);
  const [_reasonText, _setReasonText] = useState(defaultReasonText);

  useEffect(() => {
    if (isCancelLoading !== _isCancelLoading) {
      _setIsCancelLoading(isCancelLoading);
    }
    if (isConfirmLoading !== _isConfirmLoading) {
      _setIsConfirmLoading(isCancelLoading);
    }
  }, [isCancelLoading, isConfirmLoading]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      _setIsCancelLoading(true);
      try {
        await onCancel();
      } finally {
        _setIsCancelLoading(false);
      }
    } else {
      dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");
    }
  }, [onCancel]);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      _setIsConfirmLoading(true);
      try {
        await onConfirm({ reasonText: _reasonText || undefined });
      } finally {
        _setIsConfirmLoading(false);
      }
    }
  }, [onConfirm, _reasonText]);

  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        withCloseIcon
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        <Box component='div'>{title || "Do you confirm the action?"}</Box>
      </AppModalTitle>

      {(body || withReasonText) && (
        <DialogContent>
          <Stack spacing={2}>
            {body && <Box>{body}</Box>}{" "}
            {withReasonText && (
              <Box>
                <FormControl fullWidth margin='none'>
                  <TextField
                    type='text'
                    variant='outlined'
                    fullWidth
                    multiline
                    maxRows={2}
                    label='Reason'
                    value={_reasonText || ""}
                    onChange={(e) => {
                      _setReasonText(e.target.value);
                      onReasonTextChange && onReasonTextChange(e.target.value);
                    }}
                  />
                </FormControl>
              </Box>
            )}
          </Stack>
        </DialogContent>
      )}

      <DialogActions>
        <LoadingButton
          {...cancelButtonProps}
          className='ConfirmModalCancelButton'
          variant='text'
          loading={_isCancelLoading}
          onClick={handleCancel}
        >
          {cancelButtonText}
        </LoadingButton>
        <LoadingButton
          {...confirmButtonProps}
          className='ConfirmModalConfirmButton'
          variant='text'
          disabled={!isConfirmEnabled}
          loading={_isConfirmLoading}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
