import { VehicleDamageDto } from "@/core/api/generated";

import { StatusDisplay, StatusDisplayProps } from "../../StatusDisplay/StatusDisplay";

interface Props {
  vehicleDamage: VehicleDamageDto | null | undefined;
  statusProps?: Partial<StatusDisplayProps>;
  sx?: StatusDisplayProps["sx"];
}

export const VehicleDamageStatus = ({ vehicleDamage, statusProps, sx }: Props) => {
  return (
    <StatusDisplay
      color={
        vehicleDamage?.state === "Repaired"
          ? (theme) => theme.palette.success.main
          : (theme) => theme.palette.error.main
      }
      size={8}
      title={vehicleDamage?.state}
      {...statusProps}
      sx={sx}
    />
  );
};
