import { LoadingButton } from "@mui/lab";
import { LinearProgress, TextField, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import InviteInfo from "@/App/MainAppView/components/Invite/InviteInfo";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthenticationInfo } from "@/common/hooks/auth/useAuthenticationInfo";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import ServerBaseErrorModel from "@/common/models/api/ServerBaseErrorModel";
import { authService } from "@/common/services/auth";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    width: "30%",
    height: "fit-content",
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export type EmailEnterQueryParams = GeneralQueryParams;

export default function EmailEnter() {
  const { t } = useTranslation();
  const { inviteId } = useParams<{ inviteId?: string }>();
  const { ...generalQueryParams } = useQueryParams<EmailEnterQueryParams>();

  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const { isAuthenticated } = useAuthenticationInfo();

  const [isCheckingEmail, setIsCheckingEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useMounted();

  const inviteRequest = useApiRequest(
    apiClient.invitesApi.apiV1InvitesInviteIdAnonymouslyGet,
    {
      inviteId: inviteId!,
    },
    {
      skip: !inviteId,
    },
  );
  const invite = inviteRequest?.data;
  const redirectUrl = invite?.redirectUrl || generalQueryParams.redirectUrl;

  useEffect(() => {
    (async () => {
      if (inviteId && invite) {
        // if logged in redirect to accept page
        if (await authService.isAuthenticated()) {
          console.log("Redirect to invite accept (user is logged in)...");
          history.push(
            ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
              ...generalQueryParams,
            }),
          );
          return;
        }

        if (invite?.userInfo?.email) {
          setIsLoading(true);
          try {
            const { data } = await apiClient.invitesApi.apiV1InvitesUserExistsAnonymouslyPost({
              inviteCheckUserExistsDto: {
                inviteId,
                email: invite?.userInfo?.email,
              },
            });

            if (data.exists) {
              console.log("Redirect to existing user login (user exists)...");
              authService.loginWithRedirect({
                loginHint: invite?.userInfo?.email,
                appState: {
                  spaRedirectUrl: ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
                    ...generalQueryParams,
                  }),
                },
              });
            } else {
              console.log("Redirect to invite enter password...");
              history.push(
                ROUTE_PATH.AUTH_INVITE_ENTER_PASSWORD(inviteId, {
                  email: invite?.userInfo?.email,
                  ...generalQueryParams,
                }),
              );
            }
          } catch (err) {
            const baseError = err as ServerBaseErrorModel;
            if (baseError.status === 404) {
              console.log("Redirect to invite not found...");
              history.push(
                ROUTE_PATH.AUTH_INVITE_NOT_FOUND(inviteId, {
                  ...generalQueryParams,
                }),
              );
            } else {
              const validation2 = ValidationHelper.handleApiErrorResponse(err);
              validation2.hasErrors &&
                enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
            }
          } finally {
            setIsLoading(false);
            setIsCheckingEmail(false);
          }
        } else {
          setIsCheckingEmail(false);
        }
      }
    })();
  }, [invite]);

  if (isCheckingEmail || isLoading) {
    return <LinearProgress />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        enteredEmail: invite?.userInfo?.email || "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // enteredEmail: Yup.string().email().required("Email is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const { data } = await apiClient.invitesApi.apiV1InvitesUserExistsAnonymouslyPost({
            inviteCheckUserExistsDto: {
              inviteId,
              email: values.enteredEmail,
            },
          });
          if (data.exists) {
            authService.loginWithRedirect({
              loginHint: invite?.userInfo?.email,
              appState: {
                spaRedirectUrl: ROUTE_PATH.AUTH_INVITE_ACCEPT(inviteId, {
                  ...generalQueryParams,
                }),
              },
            });
          } else {
            history.push(
              ROUTE_PATH.AUTH_INVITE_ENTER_PASSWORD(inviteId, {
                email: values.enteredEmail,
                ...generalQueryParams,
              }),
            );
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <>
          {invite && <InviteInfo invite={invite} hasRedirectUrl={!!redirectUrl} />}
          <form noValidate onSubmit={handleSubmit}>
            <Box
              sx={{
                borderRadius: (theme) => theme.shape.borderRadius,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 2,
                width: "100%",
                mt: "auto",
              }}
            >
              <Typography component='div' variant='h4'>
                Enter your email
              </Typography>
            </Box>

            <TextField
              InputProps={{
                readOnly: !!invite?.userInfo?.email,
              }}
              error={Boolean(touched.enteredEmail && errors.enteredEmail)}
              fullWidth
              helperText={touched.enteredEmail && errors.enteredEmail}
              label='Email'
              margin='normal'
              name='enteredEmail'
              onBlur={handleBlur}
              onChange={handleChange}
              type='email'
              value={values.enteredEmail}
              variant='outlined'
            />

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              loading={isSubmitting}
              sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
              color='primary'
              disabled={isSubmitting}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              {t("common.buttons.submit")}
            </LoadingButton>
          </form>
        </>
      )}
    </Formik>
  );
}
