import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogProps,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { PoolItemDto, PoolItemUpdateStatusDto } from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";

export interface OwnProps {
  poolId: string;
  poolItemId: string | null | undefined;
  poolItem: PoolItemDto | null | undefined;
  onSave?: (newValue: PoolItemDto) => void;
}

type Props = OwnProps & DialogProps;

export default function PoolItemUpdateStatusModal({
  poolId,
  poolItemId,
  poolItem,
  onSave,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const poolItemRequest = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId,
      poolItemId: poolItemId!,
    },
    {
      skip: !poolItemId || !_.isNil(poolItem),
    },
  );

  const poolItemComputed = poolItem || poolItemRequest?.data;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Edit pool item status
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <Formik<PoolItemUpdateStatusDto & BaseFormikValues>
            enableReinitialize
            initialValues={{
              status: poolItemComputed?.status || undefined,
              statusReason: poolItemComputed?.statusReason || undefined,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // vehicleId: Yup.string().required("Vehicle is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const response =
                  await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdStatusPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    poolId,
                    poolItemId: poolItemComputed!.id!,
                    poolItemUpdateStatusDto: {
                      ...values,
                    },
                  });
                enqueueSnackbar("Pool item status updated.", { variant: "success" });
                onSave && onSave(response.data);

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    {poolItemRequest.isLoading && <LinearProgress />}

                    <Box>
                      <FormControl margin='dense' fullWidth required error={Boolean(errors.status)}>
                        <ApiEnumAutocomplete
                          type='PoolItemStatus'
                          value={values.status}
                          onChange={(newValue) => setFieldValue("status", newValue)}
                          label='Status'
                          required
                          textFieldProps={{
                            error: Boolean(errors.status),
                            helperText:
                              errors.status && ValidationHelper.getErrorsAsString(errors.status),
                          }}
                        />
                      </FormControl>

                      <TextField
                        error={Boolean(errors.statusReason)}
                        fullWidth
                        multiline
                        minRows={2}
                        helperText={errors.statusReason}
                        label='Status reason'
                        margin='dense'
                        name='statusReason'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.statusReason || ""}
                        variant='outlined'
                      />
                    </Box>

                    <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                    <LoadingButton
                      sx={{ mt: { xs: "auto", md: 2 } }}
                      color='primary'
                      loading={isSubmitting}
                      fullWidth
                      type='submit'
                      variant='contained'
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
