import { useParams } from "react-router";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import CustomTagView from "@/common/components/Entity/CustomTag/View/CustomTagView";

export default function CustomTagViewPage() {
  const { customTagId } = useParams<{ customTagId?: string }>();

  return (
    <>
      <CustomTagView
        customTagId={customTagId}
        customTag={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
