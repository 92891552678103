import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import RepairWorkView from "@/common/components/Entity/RepairWork/View/RepairWorkView";
import { apiClient } from "@/core/api/ApiClient";

function RepairWorkViewPage() {
  const { repairWorkId } = useParams<{ repairWorkId?: string }>();

  return (
    <Stack direction='column'>
      <RepairWorkView
        repairWorkId={repairWorkId}
        headerProps={{
          withGoBack: true,
          withLink: false,
        }}
        getFunc={async (params) =>
          await apiClient.adminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdGet({
            repairWorkId: params.repairWorkId,
          })
        }
        deleteFunc={async (params) =>
          await apiClient.adminRepairWorkApi.apiV1AdminRepairWorkRepairWorkIdDelete({
            repairWorkId: params.repairWorkId,
          })
        }
      />
    </Stack>
  );
}

export default RepairWorkViewPage;
