import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

// Figma name: Icon/Outline/checkmark-circle
export default forwardRef<SVGSVGElement, SvgIconProps>(function CheckCircleOutlineIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 8.00003C11 7.44803 11.448 7.00003 12 7.00003C12.552 7.00003 13 7.44803 13 8.00003C13 8.55203 12.552 9.00003 12 9.00003C11.448 9.00003 11 8.55203 11 8.00003ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.477 2.00003 2 6.47703 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.47703 17.522 2.00003 12 2.00003Z'
      />
      <mask id='mask0_1_727' maskUnits='userSpaceOnUse' x='2' y='2' width='20' height='20'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11 8.00003C11 7.44803 11.448 7.00003 12 7.00003C12.552 7.00003 13 7.44803 13 8.00003C13 8.55203 12.552 9.00003 12 9.00003C11.448 9.00003 11 8.55203 11 8.00003ZM11 11C11 10.448 11.448 10 12 10C12.552 10 13 10.448 13 11V16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16V11ZM12 20C7.589 20 4 16.411 4 12C4 7.58903 7.589 4.00003 12 4.00003C16.411 4.00003 20 7.58903 20 12C20 16.411 16.411 20 12 20ZM12 2.00003C6.477 2.00003 2 6.47703 2 12C2 17.523 6.477 22 12 22C17.522 22 22 17.523 22 12C22 6.47703 17.522 2.00003 12 2.00003Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1_727)'>
        <rect y='3.05176e-05' width='24' height='24' />
      </g>
    </SvgIcon>
  );
});
