import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import TenantRequestCreateUpdate from "@/common/components/Entity/TenantRequest/TenantRequestCreateUpdate";
import { ROUTE_PATH } from "@/common/constants/routing";

export default function TenantRequestCreateUpdatePage() {
  const { tenantRequestId } = useParams<{ tenantRequestId?: string }>();
  const isCreate = !tenantRequestId;
  const history = useHistory();

  return (
    <CreateUpdatePageLayout
      header={
        <SimpleViewPageHeader
          title={isCreate ? "Create new company request" : "Edit company request"}
        />
      }
    >
      <TenantRequestCreateUpdate
        tenantRequestId={tenantRequestId}
        onSave={(newValue) => {
          isCreate ? history.push(ROUTE_PATH.TENANT_REQUEST_VIEW(newValue.id)) : history.goBack();
        }}
      />
    </CreateUpdatePageLayout>
  );
}
