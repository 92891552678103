import { Stack } from "@mui/material";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppIconButton from "@/common/components/Button/AppIconButton";
import GeneralAddressDisplay from "@/common/components/Entity/General/Display/GeneralAddressDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { CustomerDto, EntityType } from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import CustomerContactCard from "../../CustomerContactCard";

interface Props {
  customer: CustomerDto;
  onUpdate?: () => void;
}

export default function OverviewTabContent({ customer, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={customer} />}
          />
        }
      >
        <ViewContentBlock>
          <FieldValue label='Type'>
            <InlineApiEnumValue type='CustomerType' value={customer?.type} withDescription />
          </FieldValue>

          <FieldValue label='Name'>{customer?.name}</FieldValue>

          <FieldValue label='Address' isEmpty={!customer?.address || customer?.address.isEmpty}>
            <GeneralAddressDisplay address={customer?.address} />
          </FieldValue>

          <FieldValue label='Notes'>{customer?.notes}</FieldValue>
        </ViewContentBlock>

        {/* Affiliation */}
        <ViewContentBlock>
          <ViewContentBlockHeader>
            Affiliation{" "}
            <AppIconButton
              tooltipProps={{ title: "Edit entity affiliation" }}
              onClick={() => setIsUpdateAffiliationModalOpened(true)}
            >
              <AppIcon of='edit' fontSize='small' />
            </AppIconButton>
          </ViewContentBlockHeader>
          <AffiliationInfoDisplay
            withoutTenant
            departmentIds={customer.departmentIds}
            locationIds={customer.locationIds}
            sx={{ mt: 1 }}
          />
          <UpdateEntityAffiliationCascadeModal
            open={isUpdateAffiliationModalOpened}
            onClose={() => setIsUpdateAffiliationModalOpened(false)}
            entityType={EntityType.Customer}
            entityId={customer.id}
            onUpdate={onUpdate}
          />
        </ViewContentBlock>

        <ViewContentBlock>
          <ViewContentBlockHeader>Contacts</ViewContentBlockHeader>
          <Stack spacing={1}>
            {customer?.contacts!.map((contact) => (
              <CustomerContactCard key={contact.id} contact={contact} sx={{ minWidth: 300 }} />
            ))}
          </Stack>
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
