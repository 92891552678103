import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ContractCreateUpdate, { ContractCreateUpdateProps } from "./ContractCreateUpdate";

export interface OwnProps {
  createUpdateProps: ContractCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function ContractCreateUpdateModal({ createUpdateProps, ...dialogProps }: Props) {
  const isCreate = !createUpdateProps.contractId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} contract
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <ContractCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
