import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import TenantIdentifierInput from "@/common/components/Form/Input/TenantIdentifierInput";
import { ROUTE_PATH } from "@/common/constants/routing";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { tenantService } from "@/common/services/tenant";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { RegisterDto } from "@/core/api/generated";
import * as authSlice from "@/store/auth/slice";

const StyledForm = styled("form")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05);",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3),
  [theme.breakpoints.up("sm")]: {
    width: "40%",
    height: "fit-content",
    borderRadius: theme.shape.borderRadius,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
  },
}));

export default function TenantRegisterInfoPage() {
  const { t } = useTranslation();
  const mounted = useMounted();
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const savedCompanyRegistrationInfo = useAppSelector((x) => x.auth.savedCompanyRegistrationInfo);
  const thunkDispatch = useAppThunkDispatch();

  const isIdentifierEditedRef = useRef(false);

  const validateCompanyIdentifierDebounce = useCallback(
    _.debounce(
      async (...args: Parameters<typeof authSlice.validateCompanyIdentifier>) =>
        thunkDispatch(authSlice.validateCompanyIdentifier(...args)),
      300,
    ),
    [],
  );

  useEffect(() => {
    if (savedCompanyRegistrationInfo) {
      history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_EMAIL_VERIFICATION);
    }
  }, [savedCompanyRegistrationInfo]);

  return (
    <Formik<BaseFormikValues & RegisterDto & { tenantUrl: string }>
      initialValues={{
        companyIdentifier: undefined,
        companyName: undefined,
        companyDescription: undefined,
        personName: {
          firstName: undefined,
          lastName: undefined,
        },
        phoneNumber: undefined,
        email: undefined,

        tenantUrl: "",
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // companyIdentifier: Yup.string()
        //   .required("Company identifier is required")
        //   .matches(
        //     /^[a-z0-9-]{3,20}$/,
        //     'Company identifier format should be: length: 3-20, symbols: a-z, 0-9, "-"',
        //   ),
        // companyName: Yup.string().required("Company name is required"),
        // firstName: Yup.string().required("First name is required"),
        // lastName: Yup.string().required("Last name is required"),
        // phoneNumber: Yup.string().required("Phone number is required"),
        // email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, { setErrors, setFieldError, setStatus, setSubmitting }) => {
        setSubmitting(true);

        try {
          await thunkDispatch(
            authSlice.registerCompanyInfo({
              companyIdentifier: values.companyIdentifier,
              companyName: values.companyName,
              companyDescription: values.companyDescription,
              personName: values.personName,
              phoneNumber: values.phoneNumber,
              email: values.email,
            }),
          );
          await thunkDispatch(authSlice.sendEmailVerificationToken({ email: values.email }));
          enqueueSnackbar("A verification code is sent to your email", { variant: "success" });
          history.push(ROUTE_PATH.AUTH_SIGN_UP_TENANT_EMAIL_VERIFICATION);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: Error | any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldError,
        setFieldValue,
      }) => (
        <StyledForm noValidate onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Box
              sx={{
                borderRadius: (theme) => theme.shape.borderRadius,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                mt: "auto",
              }}
            >
              <Typography component='div' variant='h1'>
                Register new company
              </Typography>
            </Box>

            <Box>
              <Stack direction='column' spacing={2}>
                <TextField
                  autoFocus
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  required
                  helperText={touched.companyName && errors.companyName}
                  label='Company name'
                  margin='none'
                  name='companyName'
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("companyName", e.target.value);
                    if (!isIdentifierEditedRef.current) {
                      setFieldValue(
                        "companyIdentifier",
                        tenantService.formatNameAsTenantIdentifier(e.target.value),
                      );
                    }
                  }}
                  type='text'
                  value={values.companyName}
                  variant='outlined'
                />

                <TenantIdentifierInput
                  error={Boolean(touched.companyIdentifier && errors.companyIdentifier)}
                  helperText={touched.companyIdentifier && errors.companyIdentifier}
                  fullWidth
                  required
                  margin='none'
                  name='companyIdentifier'
                  label='Company identifier'
                  onBlur={handleBlur}
                  onChange={async (e) => {
                    setFieldError("companyIdentifier", undefined);
                    handleChange(e);
                    isIdentifierEditedRef.current = true;
                  }}
                  onError={(err) => {
                    const validation = ValidationHelper.handleApiErrorResponseFormik(
                      err,
                      setFieldError,
                    );
                    setFieldError("companyIdentifier", validation.getFieldError("identifier"));
                  }}
                  type='text'
                  value={values.companyIdentifier || ""}
                />

                <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={2}>
                  <TextField
                    error={Boolean(
                      getIn(touched, "personName.firstName") &&
                        getIn(errors, "personName.firstName"),
                    )}
                    fullWidth
                    required
                    helperText={
                      getIn(touched, "personName.firstName") &&
                      getIn(errors, "personName.firstName")
                    }
                    label={t("auth.firstName")}
                    margin='none'
                    name='personName.firstName'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.personName?.firstName}
                    variant='outlined'
                  />

                  <TextField
                    error={Boolean(
                      getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName"),
                    )}
                    fullWidth
                    required
                    helperText={
                      getIn(touched, "personName.lastName") && getIn(errors, "personName.lastName")
                    }
                    label={t("auth.lastName")}
                    margin='none'
                    name='personName.lastName'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.personName?.lastName}
                    variant='outlined'
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={2}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    required
                    helperText={touched.email && errors.email}
                    label={t("auth.email")}
                    margin='none'
                    name='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='email'
                    value={values.email}
                    variant='outlined'
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={2}>
                  <PhoneInput
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={
                      touched.phoneNumber &&
                      errors.phoneNumber &&
                      ValidationHelper.getFormikErrorsAsString(errors.phoneNumber)
                    }
                    fullWidth
                    required
                    label='Phone number'
                    margin='normal'
                    name='phoneNumber'
                    value={values.phoneNumber || ""}
                    variant='outlined'
                    color='primary'
                    placeholder='Phone number'
                    onBlur={handleBlur}
                    onChange={(e, ph, iso) => {
                      setFieldValue(`phoneNumber`, ph);
                    }}
                  />
                </Stack>
              </Stack>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  mr: 2,
                }}
              ></Box>

              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}></Box>
            </Box>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <LoadingButton
              sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
              color='primary'
              fullWidth
              loading={isSubmitting}
              size='medium'
              type='submit'
              variant='contained'
            >
              {t("common.buttons.continue")}
            </LoadingButton>
          </Stack>
        </StyledForm>
      )}
    </Formik>
  );
}
