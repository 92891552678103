import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { ChangeEvent, ReactNode } from "react";

import { ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";

import ContactCard, { ContactCardData } from "./ContactCard";

export interface ContactSelectOption<TOptionData> extends ContactCardData {
  id: string;
  data?: TOptionData;
}

export interface ContactSelectProps<TOptionData> {
  options: ContactSelectOption<TOptionData>[];
  /** contact id */
  value?: string | null;
  errors?: ValidationErrors<any>;
  onChange?: (e: ChangeEvent<HTMLInputElement>, newValue: ContactSelectOption<TOptionData>) => void;
  renderContact?: (option: ContactSelectOption<TOptionData>) => ReactNode;
  sx?: SxProps<Theme>;
}

export default function ContactSelect<TOptionData>({
  options,
  value,
  errors,
  onChange,
  renderContact,
  sx,
}: ContactSelectProps<TOptionData>): JSX.Element {
  return (
    <Box sx={sx}>
      <Stack direction='column' spacing={1}>
        {options.map((option, index) => (
          <FormGroup key={index}>
            <FormControlLabel
              sx={{ margin: 0 }}
              disableTypography
              control={
                <Checkbox
                  checked={!!value && option.id === value}
                  onChange={(e) => {
                    onChange && onChange(e, option);
                  }}
                />
              }
              label={
                <>
                  {renderContact && renderContact(option)}
                  {!renderContact && (
                    <ContactCard contact={option} sx={{ width: "100%", minWidth: 300 }} />
                  )}
                </>
              }
            />
          </FormGroup>
        ))}
      </Stack>
      <FormHelperText error={Boolean(errors?.contactIds)}>
        {errors && ValidationHelper.getErrorsAsString(errors)}
      </FormHelperText>
    </Box>
  );
}
