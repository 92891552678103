import {
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FormikProps, getIn, setIn } from "formik";
import _ from "lodash";
import { useCallback, useEffect } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import DropdownButton from "@/common/components/Button/DropdownButton";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import CurrencyAutocomplete from "@/common/components/Entity/ReferenceData/CurrencyAutocomplete";
import VehicleGenerationAutocomplete from "@/common/components/Entity/ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleMakeAutocomplete from "@/common/components/Entity/ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModel/VehicleModelAutocomplete";
import VehicleModificationAutocomplete from "@/common/components/Entity/ReferenceData/VehicleModification/VehicleModificationAutocomplete";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import PercentInput from "@/common/components/Form/Input/PercentInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { REPAIR_SPEC_DETALIZATION_PRIORITY } from "@/common/constants/enum";
import { ArrayHelper } from "@/common/helpers/array";
import { PriceHelper } from "@/common/helpers/price";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { useRunOnceOnMount } from "@/common/hooks/mount/useRunOnceOnMount";
import { ValidationHelper } from "@/common/validation";
import {
  GeneralDiscountInputDto,
  GeneralTaxInputDto,
  RepairMaterialDto,
  RepairMaterialFullReferenceDto,
  RepairSpecDataDto,
  RepairSpecDataInputDto,
  RepairSpecDetalizationType,
  RepairSpecDto,
  RepairSpecItemDataInputDto,
  RepairSpecItemType,
  RepairSpecSparePartDto,
  RepairSpecSparePartInputDto,
  RepairWorkDto,
  RepairWorkFullReferenceDto,
  VehicleGenerationDto,
  VehicleMakeDto,
  VehicleModelDto,
  VehicleModificationDto,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import BooleanValue from "../../Form/Display/BooleanValue";
import AppTextArea from "../../Form/Input/AppTextArea";
import GeneralPriceSummaryDisplay from "../../PriceSummary/GeneralPriceSummaryDisplay";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import GeneralDiscountDisplay from "../General/GeneralDiscount/GeneralDiscountDisplay";
import DamageTypeAutocomplete from "../ReferenceData/DamageType/DamageTypeAutocomplete";
import VehiclePartTypeAutocomplete from "../ReferenceData/VehiclePartType/VehiclePartTypeAutocomplete";
import SelectOrCreateRepairMaterial from "../RepairMaterial/SelectOrCreateRepairMaterial";
import SelectOrCreateRepairSparePart from "../RepairSparePart/SelectOrCreateRepairSparePart";
import SelectOrCreateRepairWork from "../RepairWork/SelectOrCreateRepairWork";
import CurrencyMismatchAlert from "./CurrencyMismatchAlert";

export function mapRepairSpecDtoToInputDto(
  source: RepairSpecDto & RepairSpecDataDto & RepairSpecDataInputDto,
): RepairSpecDataInputDto {
  return {
    ...source,
    damageTypeId: source?.damageType?.id || source?.damageTypeId || undefined,
    partTypeId: source?.partType?.id || source?.partTypeId || undefined,
    spotFactor: source?.spotFactor || 1,
    detalization: {
      ...source?.detalization,
      type: source?.detalization?.type || RepairSpecDetalizationType.General,
      vehicleSize: source?.detalization?.vehicleSize || undefined,
      bodyType: source?.detalization?.bodyType || undefined,
      makeId: source?.detalization?.make?.id || source?.detalization?.makeId || undefined,
      modelId: source?.detalization?.model?.id || source?.detalization?.modelId || undefined,
      generationId:
        source?.detalization?.generation?.id || source?.detalization?.generationId || undefined,
      modificationId:
        source?.detalization?.modification?.id || source?.detalization?.modificationId || undefined,
    },
    items:
      source.items?.map((x) => ({
        ...x,
        materialId: x.material?.id,
        workId: x.work?.id,
        sparePart:
          (x.sparePart && {
            sparePartId: x.sparePart?.sparePart?.id || undefined,
            currency: x.sparePart?.currency || x.sparePart?.sparePart?.currency || undefined,
            price: (x.sparePart?.price || x.sparePart?.sparePart?.price) ?? undefined,
            discount: x.sparePart?.discount || x.sparePart?.sparePart?.discount || undefined,
            tax: x.sparePart?.tax || x.sparePart?.sparePart?.tax || undefined,
            total: (x.sparePart?.total || x.sparePart?.sparePart?.total) ?? undefined,
          }) ||
          undefined,
        amount: x?.amount ?? 1,
        spotFactor: x?.spotFactor ?? 1,
      })) || undefined,
  };
}

const defaultDisplayProps = {
  mainInputs: true,
  itemsHeader: true,
  items: true,
  itemInputs: true,
  lineItemsSummary: true,
};

type ComputedItemValuesType = {
  id?: string | null;
  isDividable?: boolean;
  discount?: GeneralDiscountInputDto | null;
  tax?: GeneralTaxInputDto | null;
  subTotal?: number | null;
  subTotalIncDiscount?: number | null;
  total?: number | null;
};

type ComputedValuesType = {
  discount?: GeneralDiscountInputDto | null;
  tax?: GeneralTaxInputDto | null;
  subTotal?: number | null;
  subTotalIncDiscount?: number | null;
  total?: number | null;
};

type ValuesType = Omit<RepairSpecDataInputDto, "detalization" | "items"> &
  ComputedValuesType & {
    detalization?: RepairSpecDataInputDto["detalization"] & {
      make?: VehicleMakeDto | null;
      model?: VehicleModelDto | null;
      generation?: VehicleGenerationDto | null;
      modification?: VehicleModificationDto | null;
    };
    items?: Array<
      RepairSpecItemDataInputDto &
        ComputedItemValuesType & {
          material?: RepairMaterialDto | RepairMaterialFullReferenceDto;
          work?: RepairWorkDto | RepairWorkFullReferenceDto;
          isSpotFactorChanged?: boolean;
          sparePart?: RepairSpecSparePartDto & RepairSpecSparePartInputDto;
        }
    >;

    // ...computed

    submit?: string | null;
  };

interface OwnProps {
  mode: "create" | "edit";
  values: ValuesType;
  formikProps?: Partial<{
    errors: FormikProps<ValuesType>["errors"];
    touched: FormikProps<ValuesType>["touched"];
  }>;
  defaultValues?: Partial<ValuesType>;
  withGeneralValidationError?: boolean;
  disabled?: boolean;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onChange: (newValue: ValuesType) => void;
}

type Props = OwnProps;

export default function RepairSpecDataInputDtoFormInputs({
  mode,
  values,
  formikProps,
  defaultValues,
  withGeneralValidationError = true,
  disabled = false,
  displayProps = defaultDisplayProps,
  onChange,
}: Props) {
  const { errors, touched } = formikProps || {};
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const isCreate = mode === "create";
  const isEdit = mode === "edit";

  const tenantProfile = useTenantProfile();

  const evalAndSetComputedValues = (newValues: ValuesType): ValuesType => {
    // compute
    const computedItems: ComputedItemValuesType[] = (newValues.items || []).map((item) => {
      const computedDiscount =
        item.material?.discount || item.work?.discount || item.sparePart?.discount || undefined;
      const computedTax = item.material?.tax || item.work?.tax || item.sparePart?.tax || undefined;
      const computedSubtotal = +(
        (item.amount || 0) *
        (item.material?.subTotal || item.work?.subTotal || item.sparePart?.price || 0) *
        (item.spotFactor || 1)
      ).toFixed(2);
      const computedSubTotalIncDiscount = !_.isNil(computedSubtotal)
        ? PriceHelper.applyDiscount(computedSubtotal, computedDiscount)
        : undefined;
      const computedTotal = PriceHelper.applyTax(
        PriceHelper.applyDiscount(computedSubtotal, computedDiscount),
        computedTax,
      );

      return {
        isDividable: item.material?.isDividable ?? item.work?.isDividable ?? undefined,
        discount: computedDiscount,
        tax: computedTax,
        subTotal: computedSubtotal,
        subTotalIncDiscount: computedSubTotalIncDiscount,
        total: computedTotal,
      };
    });
    const computedSummary: ComputedValuesType = PriceHelper.calcLineItemsSummary(computedItems);

    // set
    newValues.items?.forEach((item, i) => _.assign(item, computedItems[i] || {}));
    newValues.discount = computedSummary.discount;
    newValues.tax = computedSummary.tax;
    newValues.subTotal = computedSummary.subTotal;
    newValues.subTotalIncDiscount = computedSummary.subTotalIncDiscount;
    newValues.total = computedSummary.total;

    return newValues;
  };

  /** Analog of Formik setValues */
  const setValues = useCallback(
    (newValues: ValuesType) => {
      const newValues2 = evalAndSetComputedValues({
        ...defaultValues,
        ...values,
        ...newValues,
      });
      onChange && onChange(newValues2);
    },
    [values, onChange],
  );

  /** Analog of Formik setFieldValue */
  const setFieldValue = useCallback(
    (path: string, value: any) => {
      let newValues = {
        ...defaultValues,
        ...values,
      };
      newValues = evalAndSetComputedValues(setIn(newValues, path, value));
      onChange && onChange(newValues);
    },
    [values, onChange],
  );

  /** Similar of Formik setFieldValue, but it not totally overrides the field, rather complements it. */
  const setFieldValuePartially = useCallback(
    (path: string, value: any) => {
      const pathValue = getIn(values, path);
      const newPathValue =
        !_.isNil(value) && (_.isObject(pathValue) || _.isObject(value))
          ? {
              ...pathValue,
              ...value,
            }
          : value;
      let newValues = {
        ...defaultValues,
        ...values,
      };
      newValues = evalAndSetComputedValues(setIn(newValues, path, newPathValue));
      onChange && onChange(newValues);
    },
    [values, onChange],
  );

  // trigger change for defaultValues on mount
  useRunOnceOnMount(() => {
    setValues({
      ...defaultValues,
      ...values,
    });
  });

  // ensure ids are populated correctly
  useEffect(() => {
    const isIdsNotPopulated =
      (!values.detalization?.makeId && values.detalization?.make) ||
      (!values.detalization?.modelId && values.detalization?.model) ||
      (!values.detalization?.generationId && values.detalization?.generation) ||
      (!values.detalization?.modificationId && values.detalization?.modification) ||
      values.items?.some(
        (x) =>
          (!x.materialId && x.material) ||
          (!x.workId && x.work) ||
          (!x.sparePart?.sparePartId && x.sparePart?.sparePart?.id),
      ) ||
      false;
    if (isIdsNotPopulated) {
      setValues({
        ...values,
        detalization:
          (values.detalization && {
            ...values.detalization,
            makeId: values.detalization.makeId || values.detalization.make?.id,
            modelId: values.detalization.modelId || values.detalization.model?.id,
            generationId: values.detalization.generationId || values.detalization.generation?.id,
            modificationId:
              values.detalization.modificationId || values.detalization.modification?.id,
          }) ||
          undefined,
        items: values.items?.map((x) => ({
          ...x,
          materialId: x.materialId || x.material?.id,
          workId: x.workId || x.work?.id,
          sparePart:
            (x.sparePart && {
              ...x.sparePart,
              sparePartId: x.sparePart?.sparePartId || x.sparePart?.sparePart?.id || undefined,
            }) ||
            undefined,
        })),
      });
    }
  }, [values]);

  const shouldShowInputsForDetalization = (
    selected: RepairSpecDetalizationType | null | undefined,
    toCheck: RepairSpecDetalizationType,
  ): boolean => {
    return (
      !!selected &&
      REPAIR_SPEC_DETALIZATION_PRIORITY[toCheck] <= REPAIR_SPEC_DETALIZATION_PRIORITY[selected]
    );
  };
  return (
    <Stack spacing={1}>
      <Stack spacing={0}>
        {displayProps?.mainInputs && (
          <>
            <TextField
              error={Boolean(touched?.name && errors?.name)}
              fullWidth
              required
              disabled={disabled}
              helperText={touched?.name && errors?.name}
              label='Name'
              margin='dense'
              name='name'
              //onBlur={handleBlur}
              onChange={(e) => setFieldValue("name", e.target.value)}
              type='text'
              value={values.name || ""}
              variant='outlined'
            />

            <AppTextArea
              sx={{ minWidth: 200 }}
              error={Boolean(touched?.description && errors?.description)}
              fullWidth
              disabled={disabled}
              helperText={touched?.description && errors?.description}
              mode='description'
              margin='dense'
              name='description'
              //onBlur={handleBlur}
              onChange={(e) => setFieldValue("description", e.target.value)}
              value={values.description || ""}
              variant='outlined'
            />

            <FormControl
              sx={{ minWidth: 200 }}
              margin='dense'
              fullWidth
              required
              error={Boolean(touched?.vehicleType && errors?.vehicleType)}
            >
              <InputLabel required>Vehicle type</InputLabel>
              <ApiEnumSelect
                type='VehicleType'
                value={values.vehicleType}
                onChange={(newValue) => setFieldValue("vehicleType", newValue || undefined)}
                selectProps={{
                  label: "Vehicle type",
                  required: true,
                  disabled: disabled,
                }}
              />
              <FormHelperText>{touched?.vehicleType && errors?.vehicleType}</FormHelperText>
            </FormControl>

            {/* Part & Damage */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <FormControl
                margin='dense'
                fullWidth
                required
                error={Boolean(touched?.partTypeId && errors?.partTypeId)}
              >
                <VehiclePartTypeAutocomplete
                  required
                  disabled={disabled}
                  isPreload={isCreate}
                  vehiclePartTypeId={values.partTypeId}
                  onChange={(newValue) => setFieldValue("partTypeId", newValue?.id || undefined)}
                />
                <FormHelperText>{errors?.partTypeId}</FormHelperText>
              </FormControl>
              <FormControl
                margin='dense'
                fullWidth
                required
                error={Boolean(touched?.damageTypeId && errors?.damageTypeId)}
              >
                <DamageTypeAutocomplete
                  required
                  disabled={disabled}
                  isPreload={isCreate}
                  damageTypeId={values.damageTypeId}
                  searchFilters={{
                    vehiclePartTypeId: values.partTypeId || undefined,
                  }}
                  onChange={(newValue) => setFieldValue("damageTypeId", newValue?.id || undefined)}
                />
                <FormHelperText>{errors?.damageTypeId}</FormHelperText>
              </FormControl>
            </Box>

            <FormControl margin='normal' fullWidth required error={Boolean(errors?.currency)}>
              <CurrencyAutocomplete
                required
                disabled={disabled}
                entity={values.currency}
                onChange={(newValue) => setFieldValue("currency", newValue || undefined)}
                textFieldProps={{
                  label: "Currency",
                }}
              />
              <FormHelperText>
                {errors?.currency && ValidationHelper.getFormikErrorsAsString(errors?.currency)}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ minWidth: 200 }}
              margin='dense'
              fullWidth
              required
              error={Boolean(getIn(errors, "detalization.type"))}
            >
              <InputLabel required>Detalization</InputLabel>
              <ApiEnumSelect
                type='RepairSpecDetalizationType'
                value={values.detalization?.type}
                onChange={(newValue) => {
                  const isDecreasedDetalization =
                    REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None] <
                    REPAIR_SPEC_DETALIZATION_PRIORITY[
                      values.detalization?.type || RepairSpecDetalizationType.None
                    ];
                  setFieldValue(
                    "detalization",
                    isDecreasedDetalization
                      ? { type: newValue || undefined }
                      : { ...values.detalization, type: newValue || undefined },
                  );

                  // NB from Vadym: This logic tries to provide better UX, but it's very complex and hard to maintain.
                  // Leaving it for now in case it's needed again. Will remove it if not used.
                  // const newPartialValue: Partial<RepairSpecDetalizationInputDto> = {
                  //   type: newValue || undefined,
                  // };
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.VehicleSize] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.vehicleSize = undefined;
                  // }
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.BodyType] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.bodyType = undefined;
                  // }
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.Make] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.makeId = undefined;
                  // }
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.Make] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.modelId = undefined;
                  // }
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.Make] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.generationId = undefined;
                  // }
                  // if (
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[RepairSpecDetalizationType.Make] >
                  //   REPAIR_SPEC_DETALIZATION_PRIORITY[newValue || RepairSpecDetalizationType.None]
                  // ) {
                  //   newPartialValue.modificationId = undefined;
                  // }
                  // setFieldValuePartially("detalization", newPartialValue);
                }}
                selectProps={{
                  label: "Detalization",
                  required: true,
                  disabled: disabled,
                }}
                optionProps={{ withDescription: true }}
              />
              <FormHelperText>
                {getIn(errors, "detalization.type") &&
                  ValidationHelper.getErrorsAsString(getIn(errors, "detalization.type"))}
              </FormHelperText>
            </FormControl>

            {/* Detalization inputs */}
            {values.detalization &&
              values.detalization?.type !== RepairSpecDetalizationType.None &&
              values.detalization?.type !== RepairSpecDetalizationType.General && (
                <Box sx={{ my: 2 }}>
                  <Typography component='div' sx={{ mb: 1 }} variant='subtitle1'>
                    Detalization for{" "}
                    <InlineApiEnumValue
                      type='RepairSpecDetalizationType'
                      value={values.detalization?.type}
                    />
                  </Typography>

                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.Make,
                      ) && (
                        <FormControl
                          margin='none'
                          fullWidth
                          error={Boolean(getIn(errors, "detalization.makeId"))}
                        >
                          <VehicleMakeAutocomplete
                            entityId={values.detalization?.makeId}
                            entity={undefined}
                            searchFilters={{
                              vehicleType: values.vehicleType,
                            }}
                            label='Make'
                            onChange={(newValue) => {
                              setFieldValuePartially("detalization", {
                                makeId: newValue?.id,
                                make: newValue || undefined,
                                modelId: undefined,
                                generationId: undefined,
                                modificationId: undefined,
                              });
                            }}
                            required={_.includes(
                              [
                                RepairSpecDetalizationType.Make,
                                RepairSpecDetalizationType.Model,
                                RepairSpecDetalizationType.Generation,
                                RepairSpecDetalizationType.Modification,
                              ],
                              values.detalization?.type,
                            )}
                            disabled={!values.vehicleType || disabled}
                            textFieldProps={{
                              error: Boolean(getIn(errors, "detalization.makeId")),
                              helperText: ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.makeId"),
                              ),
                            }}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.Model,
                      ) && (
                        <FormControl
                          margin='none'
                          fullWidth
                          error={Boolean(
                            getIn(touched, "detalization.modelId") &&
                              getIn(errors, "detalization.modelId"),
                          )}
                        >
                          <VehicleModelAutocomplete
                            searchFilters={{
                              vehicleType: values.vehicleType,
                              makeId: values.detalization.makeId!,
                            }}
                            entityId={values.detalization?.modelId}
                            label='Model'
                            onChange={(newValue) => {
                              setFieldValuePartially("detalization", {
                                modelId: newValue?.id,
                                model: newValue || undefined,
                                generationId: undefined,
                                modificationId: undefined,
                              });
                            }}
                            disabled={
                              !values.vehicleType || !values.detalization?.makeId || disabled
                            }
                            required={_.includes(
                              [
                                RepairSpecDetalizationType.Model,
                                RepairSpecDetalizationType.Generation,
                                RepairSpecDetalizationType.Modification,
                              ],
                              values.detalization?.type,
                            )}
                          />
                          <FormHelperText>
                            {getIn(errors, "detalization.modelId") &&
                              ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.modelId"),
                              )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.Generation,
                      ) && (
                        <FormControl
                          margin='none'
                          fullWidth
                          error={Boolean(
                            getIn(touched, "detalization.generationId") &&
                              getIn(errors, "detalization.generationId"),
                          )}
                        >
                          <VehicleGenerationAutocomplete
                            searchFilters={{
                              vehicleType: values.vehicleType,
                              makeId: values.detalization!.makeId!,
                              modelId: values.detalization!.modelId!,
                            }}
                            entityId={values.detalization?.generationId}
                            label='Generation'
                            onChange={(newValue) => {
                              setFieldValuePartially("detalization", {
                                generationId: newValue?.id,
                                generation: newValue || undefined,
                                modificationId: undefined,
                              });
                            }}
                            disabled={!values.vehicleType || disabled}
                            required={
                              values.detalization?.type === RepairSpecDetalizationType.Generation
                            }
                          />
                          <FormHelperText>
                            {getIn(errors, "detalization.generationId") &&
                              ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.generationId"),
                              )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.Modification,
                      ) && (
                        <FormControl
                          margin='none'
                          fullWidth
                          error={Boolean(
                            getIn(touched, "detalization.modificationId") &&
                              getIn(errors, "detalization.modificationId"),
                          )}
                        >
                          <VehicleModificationAutocomplete
                            entityId={values.detalization?.modificationId}
                            searchFilters={{
                              vehicleType: values.vehicleType,
                              makeId: values.detalization.makeId!,
                              modelId: values.detalization.modelId!,
                              generationId: values.detalization?.generationId || undefined,
                            }}
                            label='Modification'
                            onChange={(newValue) => {
                              setFieldValuePartially("detalization", {
                                modificationId: newValue?.id,
                                modification: newValue || undefined,
                              });
                            }}
                            disabled={!values.vehicleType || disabled}
                            required={
                              values.detalization?.type === RepairSpecDetalizationType.Modification
                            }
                          />
                          <FormHelperText>
                            {getIn(errors, "detalization.modificationId") &&
                              ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.modificationId"),
                              )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.VehicleSize,
                      ) && (
                        <FormControl
                          sx={{ minWidth: 200 }}
                          margin='none'
                          fullWidth
                          error={Boolean(
                            getIn(touched, "detalization.vehicleSize") &&
                              getIn(errors, "detalization.vehicleSize"),
                          )}
                        >
                          <ApiEnumAutocomplete
                            type='VehicleSize'
                            value={values.detalization?.vehicleSize}
                            onChange={(newValue) =>
                              setFieldValue("detalization.vehicleSize", newValue)
                            }
                            label='Vehicle size'
                            disabled={disabled}
                            required={
                              values.detalization?.type === RepairSpecDetalizationType.VehicleSize
                            }
                          />
                          <FormHelperText error>
                            {getIn(errors, "detalization.vehicleSize") &&
                              ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.vehicleSize"),
                              )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xxs={12} md={6}>
                      {shouldShowInputsForDetalization(
                        values.detalization?.type,
                        RepairSpecDetalizationType.BodyType,
                      ) && (
                        <FormControl
                          sx={{ minWidth: 200 }}
                          margin='none'
                          fullWidth
                          disabled={disabled}
                          error={Boolean(
                            getIn(touched, "detalization.bodyType") &&
                              getIn(errors, "detalization.bodyType"),
                          )}
                        >
                          <ApiEnumAutocomplete
                            type='VehicleBodyType'
                            value={values.detalization?.bodyType}
                            onChange={(newValue) =>
                              setFieldValue("detalization.bodyType", newValue)
                            }
                            label='Body type'
                            disabled={disabled}
                            required={
                              values.detalization?.type === RepairSpecDetalizationType.BodyType
                            }
                          />
                          <FormHelperText>
                            {getIn(errors, "detalization.bodyType") &&
                              ValidationHelper.getErrorsAsString(
                                getIn(errors, "detalization.bodyType"),
                              )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}

            <PercentInput
              error={Boolean(touched?.spotFactor && errors?.spotFactor)}
              fullWidth
              required
              disabled={disabled}
              helperText={
                (touched?.spotFactor && errors?.spotFactor) || (
                  <Box>
                    <Box>{`Default factor that is applied to all items.`}</Box>
                    <Box>{`Spot repair - SpotFactor < 1. Full repair - SpotFactor >= 1.`}</Box>
                  </Box>
                )
              }
              label='Spot factor'
              margin='dense'
              name={`spotFactor`}
              //onBlur={handleBlur}
              onChange={(e) => {
                const newValue = e.target.value ? +e.target.value : 1;
                setValues({
                  ...values,
                  spotFactor: newValue,
                  items: values.items?.map((x) => ({
                    ...x,
                    spotFactor: x.isDividable && !x.isSpotFactorChanged ? newValue : x.spotFactor,
                  })),
                });
              }}
              value={values.spotFactor || 0}
              variant='outlined'
            />
          </>
        )}

        {/* Items */}
        {displayProps?.items && (
          <Box sx={{ my: 2 }}>
            {displayProps?.itemsHeader && (
              <Typography component='div' sx={{ mb: 1 }} variant='subtitle1'>
                Items
              </Typography>
            )}

            {displayProps?.itemInputs && (
              <>
                {(!values.items || values.items.length === 0) && (
                  <NoDataAlert title='No items yet' />
                )}

                <Stack direction='column' spacing={1}>
                  {values.items?.map((item, i) => (
                    <Card key={i}>
                      <CardContent>
                        {/* Currency mismatch alert */}
                        <Box sx={{ mb: 2 }}>
                          {values.currency && (
                            <CurrencyMismatchAlert
                              repairSpec={values}
                              repairMaterial={item.material}
                              repairWork={item.work}
                              repairSparePart={item.sparePart}
                            />
                          )}
                        </Box>

                        <Grid container rowSpacing={1} columnSpacing={0.5}>
                          {/* Item selector */}
                          <Grid item xxs={12} md={6}>
                            <Stack>
                              <Box>
                                {item.type === RepairSpecItemType.Material && (
                                  <FormControl
                                    margin='none'
                                    fullWidth
                                    error={Boolean(getIn(errors, `items[${i}].materialId`))}
                                  >
                                    <SelectOrCreateRepairMaterial
                                      autocompleteProps={{
                                        entity: undefined,
                                        entityId: item.materialId || item.material?.id,
                                        required: true,
                                        disabled: disabled,
                                        size: "small",
                                        isPreload: isCreate,
                                        onChange: (newValue) => {
                                          setFieldValuePartially(`items[${i}]`, {
                                            materialId: newValue?.id,
                                            material: newValue,
                                            discount: newValue?.discount,
                                            tax: newValue?.tax,
                                          });
                                        },
                                      }}
                                      createUpdateRepairMaterialProps={{
                                        defaultValues: {
                                          currency: values.currency,
                                        },
                                      }}
                                      createFormPlacement='modal'
                                      onCreate={(newValue) => {
                                        setFieldValuePartially(`items[${i}]`, {
                                          materialId: newValue?.id,
                                          material: newValue,
                                          discount: newValue?.discount,
                                          tax: newValue?.tax,
                                        });
                                      }}
                                    />
                                    <FormHelperText>
                                      {getIn(errors, `items[${i}].materialId`)}
                                    </FormHelperText>
                                  </FormControl>
                                )}

                                {item.type === RepairSpecItemType.Work && (
                                  <FormControl
                                    margin='none'
                                    fullWidth
                                    error={Boolean(getIn(errors, `items[${i}].workId`))}
                                  >
                                    <SelectOrCreateRepairWork
                                      autocompleteProps={{
                                        entity: undefined,
                                        entityId: item.workId || item.work?.id,
                                        required: true,
                                        disabled: disabled,
                                        size: "small",
                                        isPreload: isCreate,
                                        onChange: (newValue) => {
                                          setFieldValuePartially(`items[${i}]`, {
                                            workId: newValue?.id,
                                            work: newValue,
                                            discount: newValue?.discount,
                                            tax: newValue?.tax,
                                          });
                                        },
                                      }}
                                      createUpdateRepairWorkProps={{
                                        defaultValues: { currency: values.currency },
                                      }}
                                      createFormPlacement='modal'
                                      onCreate={(newValue) => {
                                        setFieldValuePartially(`items[${i}]`, {
                                          workId: newValue?.id,
                                          work: newValue,
                                          discount: newValue?.discount,
                                          tax: newValue?.tax,
                                        });
                                      }}
                                    />
                                    <FormHelperText>
                                      {getIn(errors, `items[${i}].workId`)}
                                    </FormHelperText>
                                  </FormControl>
                                )}

                                {item.type === RepairSpecItemType.SparePart && (
                                  <FormControl
                                    margin='none'
                                    fullWidth
                                    error={Boolean(
                                      getIn(errors, `items[${i}].sparePart.sparePartId`),
                                    )}
                                  >
                                    <SelectOrCreateRepairSparePart
                                      autocompleteProps={{
                                        entity: undefined,
                                        entityId:
                                          item.sparePart?.sparePartId ||
                                          item.sparePart?.sparePart?.id,
                                        required: true,
                                        disabled: disabled,
                                        size: "small",
                                        isPreload: isCreate,
                                        searchFilters: {
                                          // use match to query best suggestions
                                          match: {
                                            partTypeId: values.partTypeId || undefined,
                                            vehicleSize:
                                              values.detalization?.vehicleSize || undefined,
                                            bodyType: values.detalization?.bodyType || undefined,
                                            makeId:
                                              values.detalization?.makeId ||
                                              values.detalization?.make?.id ||
                                              undefined,
                                            modelId:
                                              values.detalization?.modelId ||
                                              values.detalization?.model?.id ||
                                              undefined,
                                            generationId:
                                              values.detalization?.generationId ||
                                              values.detalization?.generation?.id ||
                                              undefined,
                                            modificationId:
                                              values.detalization?.modificationId ||
                                              values.detalization?.modification?.id ||
                                              undefined,
                                          },
                                        },
                                        onChange: (newValue) => {
                                          setFieldValuePartially(`items[${i}]`, {
                                            sparePart: {
                                              ...item.sparePart,
                                              sparePartId: newValue?.id,
                                              sparePart: newValue,
                                              price: newValue?.price,
                                              currency: newValue?.currency,
                                              discount: newValue?.discount,
                                              tax: newValue?.tax,
                                              total: newValue?.total,
                                            },
                                          });
                                        },
                                      }}
                                      createUpdateRepairSparePartProps={{
                                        defaultValues: { currency: values.currency },
                                      }}
                                      createFormPlacement='modal'
                                      onCreate={(newValue) => {
                                        setFieldValuePartially(`items[${i}]`, {
                                          sparePart: {
                                            ...item.sparePart,
                                            sparePartId: newValue?.id,
                                            sparePart: newValue,
                                            price: newValue?.price,
                                            currency: newValue?.currency,
                                            discount: newValue?.discount,
                                            tax: newValue?.tax,
                                            total: newValue?.total,
                                          },
                                        });
                                      }}
                                    />
                                    <FormHelperText>
                                      {getIn(errors, `items[${i}].sparePart.sparePartId`)}
                                    </FormHelperText>
                                  </FormControl>
                                )}
                              </Box>
                            </Stack>
                          </Grid>

                          <Grid item xxs={12} md={6}>
                            <Stack direction='row' spacing={0.5}>
                              <Box sx={{ flex: 1 }}>
                                <TextField
                                  sx={{ flex: 1 }}
                                  fullWidth
                                  required
                                  disabled={disabled}
                                  error={Boolean(getIn(errors, `items[${i}].amount`))}
                                  helperText={getIn(errors, `items[${i}].amount`)}
                                  label='Amount'
                                  margin='none'
                                  size='small'
                                  inputMode='decimal'
                                  name={`items[${i}].amount`}
                                  //onBlur={handleBlur}
                                  onChange={(e) => {
                                    const newValue = e.target.value ? +e.target.value : 1;
                                    setFieldValuePartially(`items[${i}]`, {
                                      amount: newValue,
                                    });
                                  }}
                                  value={item.amount}
                                  variant='outlined'
                                />
                              </Box>

                              <Box sx={{ flex: 1 }}>
                                <PercentInput
                                  sx={{ flex: 1 }}
                                  fullWidth
                                  required
                                  disabled={disabled || !item.isDividable}
                                  error={Boolean(getIn(errors, `items[${i}].spotFactor`))}
                                  helperText={getIn(errors, `items[${i}].spotFactor`)}
                                  label='Spot factor'
                                  margin='none'
                                  size='small'
                                  name={`items[${i}].spotFactor`}
                                  //onBlur={handleBlur}
                                  onChange={(e) => {
                                    const newValue = e.target.value ? +e.target.value : 1;
                                    setFieldValuePartially(`items[${i}]`, {
                                      spotFactor: newValue,
                                      isSpotFactorChanged: newValue !== values.spotFactor,
                                    });
                                  }}
                                  value={item.spotFactor}
                                  variant='outlined'
                                />
                                {!item.isDividable && (
                                  <FormHelperText>
                                    {`Spot factor is not applicable to this item as it's not dividable.`}
                                  </FormHelperText>
                                )}
                              </Box>

                              {/* Controls */}
                              {!disabled && (
                                <Stack
                                  direction='row'
                                  alignItems='flex-start'
                                  justifyContent='flex-end'
                                >
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        "items",
                                        ArrayHelper.removeByIndex(values.items, i),
                                      )
                                    }
                                  >
                                    <AppIcon of='delete' />
                                  </IconButton>
                                </Stack>
                              )}
                            </Stack>
                          </Grid>

                          {/* Item details */}

                          {(item.sparePart?.sparePartId || item.sparePart?.sparePart) &&
                            !disabled && (
                              <FormControl fullWidth margin='dense'>
                                <PriceSummaryInput
                                  values={{
                                    currency: item?.sparePart?.currency,
                                    subTotal: item?.sparePart?.price || 0,
                                    discount: item?.sparePart?.discount,
                                    tax: item?.sparePart?.tax,
                                    total: item?.total || item?.sparePart?.total || 0,
                                  }}
                                  formikProps={{
                                    errors: {
                                      subTotal: getIn(errors, `items[${i}].sparePart.subTotal`),
                                      discount: getIn(errors, `items[${i}].sparePart.discount`),
                                      tax: getIn(errors, `items[${i}].sparePart.tax`),
                                      total: getIn(errors, `items[${i}].sparePart.total`),
                                    },
                                  }}
                                  displayProps={{
                                    insurance: false,
                                    calcExplanation: true,
                                  }}
                                  inputsProps={{
                                    all: {
                                      margin: "none",
                                    },
                                    subTotal: {
                                      label: "Price",
                                      required: true,
                                    },
                                    total: {
                                      required: true,
                                    },
                                  }}
                                  onChange={(newValue) => {
                                    setFieldValuePartially(`items[${i}]`, {
                                      sparePart: {
                                        ...item.sparePart,
                                        currency: newValue?.currency,
                                        price: newValue?.subTotal,
                                        discount: newValue?.discount,
                                        tax: newValue?.tax,
                                        total: newValue?.total,
                                      },
                                      discount: newValue?.discount,
                                      tax: newValue?.tax,
                                    });
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mt: 1,
                                  }}
                                >
                                  <FieldValue
                                    label='Unit'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{
                                      alignItems: "center",
                                      maxWidth: "300px",
                                    }}
                                  >
                                    <InlineApiEnumValue
                                      type='MeasurementUnit'
                                      value={item.sparePart?.sparePart?.unit}
                                      withDescription={false}
                                      sx={{
                                        ml: "auto",
                                      }}
                                    />
                                  </FieldValue>
                                </Box>
                              </FormControl>
                            )}

                          {(item.sparePart?.sparePartId || item.sparePart?.sparePart) &&
                            disabled && (
                              <FormControl fullWidth margin='dense'>
                                <GeneralPriceSummaryDisplay
                                  direction='row'
                                  summary={{
                                    currency: item?.sparePart?.currency || item.sparePart?.currency,
                                    subTotal: item?.sparePart?.price || item.sparePart?.price || 0,
                                    discount:
                                      item?.sparePart?.discount ||
                                      item.sparePart?.discount ||
                                      undefined,
                                    tax: item?.sparePart?.tax || item.sparePart?.tax || undefined,
                                    total: item?.total || item.sparePart?.total || 0,
                                  }}
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    mt: 1,
                                  }}
                                >
                                  <FieldValue
                                    label='Unit'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{
                                      alignItems: "center",
                                      maxWidth: "300px",
                                    }}
                                  >
                                    <InlineApiEnumValue
                                      type='MeasurementUnit'
                                      value={item.sparePart?.sparePart?.unit}
                                      withDescription={false}
                                      sx={{
                                        ml: "auto",
                                      }}
                                    />
                                  </FieldValue>
                                </Box>
                              </FormControl>
                            )}

                          {(item.material || item.work) && (
                            <Grid item container xxs={12} md={12}>
                              {/* Max 7-8 columns */}
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: {
                                    xxs: "repeat(1, 1fr)",
                                    sm: "repeat(2, 1fr)",
                                    md: "repeat(3, 1fr)",
                                    lg: "repeat(4, 1fr)",
                                    xl: "repeat(8, 1fr)",
                                  },
                                  gridTemplateRows: {
                                    xxs: "repeat(8, auto)",
                                    sm: "repeat(4, auto)",
                                    md: "repeat(3, auto)",
                                    lg: "repeat(2, auto)",
                                    xl: "auto",
                                  },
                                  gap: 1,
                                  width: "100%",
                                }}
                              >
                                <Box>
                                  <FieldValue
                                    label='Unit'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <InlineApiEnumValue
                                      type='MeasurementUnit'
                                      value={item?.material?.unit || item?.work?.unit}
                                      withDescription={false}
                                    />
                                  </FieldValue>
                                </Box>
                                <Box>
                                  <FieldValue
                                    label='Price'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <CurrencyValue
                                      value={item?.material?.price || item?.work?.price || 0}
                                      currency={item?.material?.currency || item?.work?.currency}
                                    />
                                  </FieldValue>
                                </Box>
                                <Box>
                                  <FieldValue
                                    label='Dividable?'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <BooleanValue value={item?.isDividable || false} />
                                  </FieldValue>
                                </Box>
                                {item.work && (
                                  <Box>
                                    <FieldValue
                                      label='Expendables'
                                      direction={{ xxs: "row", md: "column" }}
                                      sx={{ flex: 1 }}
                                    >
                                      <PercentValue value={item?.work?.expendablesPercent || 0} />
                                    </FieldValue>
                                  </Box>
                                )}
                                <Box>
                                  <FieldValue
                                    label='Sub total'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <CurrencyValue
                                      value={item?.subTotal || 0}
                                      currency={item?.material?.currency || item?.work?.currency}
                                    />
                                  </FieldValue>
                                </Box>
                                <Box>
                                  <FieldValue
                                    label='Discount'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <GeneralDiscountDisplay
                                      discount={item?.discount}
                                      currency={item?.material?.currency || item?.work?.currency}
                                    />
                                  </FieldValue>
                                </Box>
                                <Box>
                                  <FieldValue
                                    label='Tax'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <GeneralTaxDisplay
                                      tax={item?.tax}
                                      currency={item?.material?.currency || item?.work?.currency}
                                    />
                                  </FieldValue>
                                </Box>
                                <Box>
                                  <FieldValue
                                    label='Total'
                                    direction={{ xxs: "row", md: "column" }}
                                    sx={{ flex: 1 }}
                                  >
                                    <CurrencyValue
                                      value={item?.total || 0}
                                      currency={item?.material?.currency || item?.work?.currency}
                                    />
                                  </FieldValue>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>

                {/* Add item */}
                <DropdownButton
                  sx={{ mt: 1 }}
                  disabled={disabled}
                  buttonProps={{
                    color: "secondary",
                    size: "small",
                    variant: "outlined",
                    startIcon: <AppIcon of='add' />,
                  }}
                  dropdownContent={
                    <MenuList>
                      {[
                        RepairSpecItemType.Material,
                        RepairSpecItemType.Work,
                        RepairSpecItemType.SparePart,
                      ].map((type) => (
                        <MenuItem
                          key={type}
                          onClick={() => {
                            setFieldValue("items", [
                              ...(values.items || []),
                              {
                                type: type,
                                amount: 1,
                                spotFactor: 1,
                                tax: {
                                  ...tenantProfile?.settings?.tax,
                                  currency: tenantProfile?.settings?.currency,
                                },
                              },
                            ]);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='add' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>
                            <InlineApiEnumValue type='RepairSpecItemType' value={type} />
                          </ListItemText>
                        </MenuItem>
                      ))}
                    </MenuList>
                  }
                >
                  Add item
                </DropdownButton>
              </>
            )}
          </Box>
        )}

        {/* Summary */}
        {displayProps?.lineItemsSummary && (
          <Box sx={{ my: 2 }}>
            <Stack direction='row' justifyContent='flex-end'>
              <GeneralPriceSummaryDisplay
                sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                summary={{
                  currency: values.currency,
                  subTotal: values.subTotal || 0,
                  subTotalIncDiscount: values.subTotalIncDiscount || 0,
                  discount: values.discount,
                  tax: values.tax,
                  total: values.total || 0,
                }}
              />
            </Stack>
          </Box>
        )}

        {withGeneralValidationError && (
          <FormHelperText error={!!errors?.items && _.isString(errors?.items)}>
            {_.isString(errors?.items) && errors?.items}
          </FormHelperText>
        )}
      </Stack>

      {errors?.submit && (
        <Box>{errors?.submit && <FormHelperText error>{errors?.submit}</FormHelperText>}</Box>
      )}
    </Stack>
  );
}
