import { AppArea } from "../constants/routing";
import { tenantService } from "../services/tenant";
import { UrlHelper } from "./url";

export class RoutingHelper {
  /** Resolves app area from current URL.
   *  E.g. https://example.com/tenantId/admin -> admin.
   *  E.g. https://example.com/admin -> admin.
   */
  public static resolveAppArea(): AppArea | null {
    const url = window.location.href;
    let pathname = UrlHelper.getUrlPathname(url);
    const tenantInfo = tenantService.resolveTenantFromBasePath();
    if (tenantInfo) {
      pathname = pathname.replace(`/${tenantInfo.identifier}`, "");
    }
    const parts = pathname.split("/").filter((x) => !!x);
    const area = parts[0] || null;

    return Object.values(AppArea).includes(area as AppArea) ? (area as AppArea) : null;
  }

  /** Redirects to new app area URL with full page reload.
   *  E.g. https://example.com/admin/a/b/c -> https://example.com/app
   */
  public static redirectToAppArea(newArea: AppArea, path: string | undefined = undefined): void {
    const currentArea = this.resolveAppArea();
    if (currentArea !== newArea) {
      const tenantInfo = tenantService.resolveTenantFromBasePath();
      const url = window.location.origin; // drop pathname and query string
      const newPathname =
        "/" +
        [...(tenantInfo ? [tenantInfo.identifier] : []), newArea.toString(), path]
          .filter(Boolean)
          .join("/");
      const newUrl = UrlHelper.updateUrlPathname(url, newPathname);
      console.log("Redirect to app area:", newArea);

      UrlHelper.redirectToUrl(newUrl);
    }
  }
}
