import { SxProps, Theme } from "@mui/material";

import InlineUser, { InlineUserProps } from "@/App/MainAppView/components/User/InlineUser";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, GeneralInspectorDto } from "@/core/api/generated";

import AuthorizedElement from "../../../Auth/AuthorizedElement";

interface Props {
  inspector: GeneralInspectorDto | null | undefined;
  inlineUserProps?: Omit<InlineUserProps, "userId" | "user" | "inspector">;
  sx?: SxProps<Theme>;
}

export default function GeneralInspectorDisplay({ inspector, inlineUserProps, sx }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
      <InlineUser
        withAvatar
        {...inlineUserProps}
        inspector={inspector}
        withLink={
          (inlineUserProps?.withLink || false) &&
          hasPermissions([AppPermission.FleetAppAccess, AppPermission.TenantUserRead])
        }
        sx={{ ...sx, ...inlineUserProps?.sx } as SxProps<Theme>}
      />
    </AuthorizedElement>
  );
}
