import { Stack, SxProps, Theme, Typography } from "@mui/material";

import ApiEnumIcon from "@/common/components/Icons/ApiEnumIcon";
import { AccessoryCheckItemDataDto, AccessoryCheckItemDto } from "@/core/api/generated";

interface Props {
  item?: AccessoryCheckItemDto | AccessoryCheckItemDataDto;
  sx?: SxProps<Theme>;
}

export default function AccessoryCheckItem({ item, sx }: Props) {
  if (!item) {
    return null;
  }

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{
        backgroundColor: "background.gray",
        py: 1.5,
        px: 2,
        alignItems: "center",
        ...(sx || {}),
      }}
    >
      <Typography component='span' variant='body1'>
        {item?.accessory?.name}
      </Typography>

      {item?.accessory?.description && (
        <Typography component='span' variant='body2'>
          {item?.accessory?.description}
        </Typography>
      )}

      <Stack direction='row' spacing={1} sx={{ flex: 1, justifyContent: "flex-end" }}>
        <ApiEnumIcon type='AccessoryStatus' value={item.status} withTooltip fontSize='medium' />
      </Stack>
    </Stack>
  );
}
