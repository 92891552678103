import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, ChatDto, ChatReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import ChatInline, { ChatInlineProps } from "./ChatInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: ChatDto | ChatReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<ChatInlineProps>;
}

export default function ChatLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.ChatRead])}
      to={ROUTE_PATH.CHAT_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={<ChatInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Chat"}
    />
  );
}
