import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useCallback } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { ArrayHelper } from "@/common/helpers/array";

import { CreateNotificationFormValues } from "../CreateForm";

const CreateNotificationFormEmailInputs = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<CreateNotificationFormValues>();

  const onEmailChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
      setFieldValue(`email.users[${index}].to`, e.target.value);
    },
    [],
  );
  const onSubjectChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`email.subject`, e.target.value);
    },
    [],
  );
  const onTitleChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`email.defaultTemplateData.title`, e.target.value);
    },
    [],
  );
  const onTextChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldValue(`email.defaultTemplateData.sections[0].text`, e.target.value);
    },
    [],
  );
  return (
    <>
      <Stack spacing={1}>
        <Typography component='div' variant='subtitle1'>
          Recipient emails
        </Typography>
        <Stack direction='column' spacing={1}>
          {values.email?.users?.map((item, index) => {
            return (
              <Card key={index}>
                <CardContent>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      type='email'
                      error={Boolean(
                        getIn(touched, `email.users[${index}].to`) &&
                          getIn(errors, `email.users[${index}].to`),
                      )}
                      variant='outlined'
                      fullWidth
                      label='Recipient email'
                      value={item?.to || ""}
                      onChange={(e) => onEmailChanged(e, index)}
                    />
                    <FormHelperText
                      error={Boolean(
                        getIn(touched, `email.users[${index}].to`) &&
                          getIn(errors, `email.users[${index}].to`),
                      )}
                    >
                      {getIn(errors, `email.users[${index}].to`)}
                    </FormHelperText>
                  </FormControl>

                  <Grid item xxs={12} sm={12} md={12} lg={12}>
                    {/* Controls */}
                    <Stack
                      direction='row'
                      sx={{
                        flex: 1,
                        ml: "auto",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Tooltip title='Delete'>
                        <IconButton
                          onClick={() =>
                            setFieldValue(
                              "users",
                              ArrayHelper.removeByIndex(values.email?.users, index),
                            )
                          }
                        >
                          <AppIcon of='delete' />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                </CardContent>
              </Card>
            );
          })}
        </Stack>

        {/* Add item */}
        <Button
          sx={{ flex: 0, alignSelf: "flex-start" }}
          variant='outlined'
          color='text'
          size='small'
          startIcon={<AppIcon of='add' />}
          onClick={() => {
            setFieldValue("email.users", [...(values.email?.users || []), {}]);
          }}
        >
          Add recipient email
        </Button>
      </Stack>

      <FormControl fullWidth margin='dense' sx={{ mt: 2 }}>
        <TextField
          error={Boolean(getIn(touched, "email.subject") && getIn(errors, "email.subject"))}
          variant='outlined'
          fullWidth
          label='Subject'
          value={values?.email?.subject || ""}
          onChange={onSubjectChanged}
        />
        <FormHelperText
          error={Boolean(getIn(touched, "email.subject") && getIn(errors, "email.subject"))}
        >
          {getIn(errors, "email.subject")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(
            getIn(touched, "email.defaultTemplateData.title") &&
              getIn(errors, "email.defaultTemplateData.title"),
          )}
          variant='outlined'
          fullWidth
          label='Title'
          value={values?.email?.defaultTemplateData?.title || ""}
          onChange={onTitleChanged}
        />
        <FormHelperText
          error={Boolean(
            getIn(touched, "email.defaultTemplateData.title") &&
              getIn(errors, "email.defaultTemplateData.title"),
          )}
        >
          {getIn(errors, "email.defaultTemplateData.title")}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth margin='dense'>
        <TextField
          error={Boolean(
            getIn(touched, "email.defaultTemplateData.sections") &&
              getIn(errors, "email.defaultTemplateData.sections"),
          )}
          multiline
          variant='outlined'
          fullWidth
          label='Text'
          value={values?.email?.defaultTemplateData?.sections?.at(0)?.text || ""}
          onChange={onTextChanged}
        />
        <FormHelperText
          error={Boolean(
            getIn(touched, "email.defaultTemplateData.sections") &&
              getIn(errors, "email.defaultTemplateData.sections"),
          )}
        >
          {getIn(errors, "email.defaultTemplateData.sections")}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CreateNotificationFormEmailInputs;
