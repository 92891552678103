import { Box, Popover, PopoverProps, Stack, Typography } from "@mui/material";

import BlockUser from "@/App/MainAppView/components/User/BlockUser";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import { GeneralTagDtoUnion } from "@/common/ts/dto";
import { TagTargetType } from "@/core/api/generated";

import Datetime from "../../../Datetime/Datetime";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import AccessoryCheckLink from "../../AccessoryCheck/AccessoryCheckLink";
import AssessmentFlowLink from "../../AssessmentFlow/AssessmentFlowLink";
import AssetSubscriptionLink from "../../AssetSubscription/AssetSubscriptionLink";
import AssetSubscriptionPlanLink from "../../AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import ChatLink from "../../Chat/ChatLink";
import ChatParticipantLink from "../../ChatParticipant/ChatParticipantLink";
import ContractBlock from "../../Contract/ContractBlock";
import CustomTagLink from "../../CustomTag/CustomTagLink";
import CustomerBlock from "../../Customer/CustomerBlock";
import DamageCostEvaluationLink from "../../DamageCostEvaluation/DamageCostEvaluationLink";
import DamageCostEvaluationAggregateLink from "../../DamageCostEvaluationAggregate/DamageCostEvaluationAggregateLink";
import DamageDetectionLink from "../../DamageDetection/DamageDetectionLink";
import DamageDetectionAggregateLink from "../../DamageDetectionAggregate/DamageDetectionAggregateLink";
import MaintenanceLink from "../../Maintenance/MaintenanceLink";
import NegotiationBlock from "../../Negotiation/NegotiationBlock";
import TenantConnectionLink from "../../TenantConnection/TenantConnectionLink";
import TenantConnectionRequestLink from "../../TenantConnectionRequest/TenantConnectionRequestLink";
import TenantRequestLink from "../../TenantRequest/TenantRequestLink";
import VehicleBlock from "../../Vehicle/VehicleBlock";
import VehicleDamageBlock from "../../VehicleDamage/VehicleDamageBlock";
import WashLink from "../../Wash/WashLink";

export interface OwnProps {
  tag: GeneralTagDtoUnion;
}

type Props = OwnProps & PopoverProps;

/** Shows tag details on hover. */
export default function GeneralTagDetailsPopover({ tag, ...popoverProps }: Props) {
  const withLink = false;
  const withTooltip = false;

  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...popoverProps}
    >
      <Box sx={{ minWidth: "200px", maxWidth: { sm: "95vw", md: "400px" }, p: 1 }}>
        <Stack spacing={1}>
          <Typography variant='subtitle1'>Tag info:</Typography>

          {/* What is tagged */}
          <Box>
            <>
              {tag.targetType === TagTargetType.CustomTag && (
                <CustomTagLink
                  entity={tag.target?.customTag}
                  entityId={tag.target?.customTag?.id}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.User && (
                <BlockUser
                  variant='reference'
                  user={tag.target?.user}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                />
              )}
              {tag.targetType === TagTargetType.Vehicle && (
                <VehicleBlock
                  variant='reference'
                  vehicle={tag.target?.vehicle}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                />
              )}
              {tag.targetType === TagTargetType.Contract && (
                <ContractBlock
                  variant='reference'
                  contract={tag.target?.contract}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                />
              )}
              {tag.targetType === TagTargetType.AssessmentFlow && (
                <AssessmentFlowLink
                  entity={tag.target?.assessmentFlow}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.Customer && (
                <CustomerBlock
                  variant='reference'
                  customer={tag.target?.customer}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                />
              )}
              {tag.targetType === TagTargetType.VehicleDamage && (
                <VehicleDamageBlock
                  variant='reference'
                  vehicleDamage={tag.target?.vehicleDamage}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                />
              )}
              {tag.targetType === TagTargetType.DamageDetection && (
                <DamageDetectionLink
                  entity={tag.target?.damageDetection}
                  item={tag.subTarget?.damageDetectionItem}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.DamageDetectionAggregate && (
                <DamageDetectionAggregateLink
                  entity={tag.target?.damageDetectionAggregate}
                  item={tag.subTarget?.damageDetectionAggregateItem}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.DamageCostEvaluation && (
                <DamageCostEvaluationLink
                  entity={tag.target?.damageCostEvaluation}
                  item={tag.subTarget?.damageCostEvaluationItem}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.DamageCostEvaluationAggregate && (
                <DamageCostEvaluationAggregateLink
                  entity={tag.target?.damageCostEvaluationAggregate}
                  item={tag.subTarget?.damageCostEvaluationAggregateItem}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.AccessoryCheck && (
                <AccessoryCheckLink
                  entity={tag.target?.accessoryCheck}
                  item={tag.subTarget?.accessoryCheckItem}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.Chat && (
                <ChatLink entity={tag.target?.chat} withTooltip={false} sx={{ color: "inherit" }} />
              )}
              {tag.targetType === TagTargetType.ChatParticipant && (
                <ChatParticipantLink
                  chatId={tag.target?.chatParticipant?.chatId}
                  entity={tag.target?.chatParticipant}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.Negotiation && (
                <NegotiationBlock
                  variant='reference'
                  negotiation={tag.target?.negotiation}
                  withLink={withLink}
                  withDetailsToggle={false}
                  isDetailsVisible={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.AssetSubscriptionPlan && (
                <AssetSubscriptionPlanLink
                  entity={tag.target?.assetSubscriptionPlan}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.AssetSubscription && (
                <AssetSubscriptionLink
                  entity={tag.target?.assetSubscription}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.TenantConnectionRequest && (
                <TenantConnectionRequestLink
                  entity={tag.target?.tenantConnectionRequest}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.TenantConnection && (
                <TenantConnectionLink
                  entity={tag.target?.tenantConnection}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.TenantRequest && (
                <TenantRequestLink
                  entity={tag.target?.tenantRequest}
                  enabled={withLink}
                  withTooltip={withTooltip}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.Wash && (
                <WashLink
                  entity={tag.target?.wash}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
              {tag.targetType === TagTargetType.Maintenance && (
                <MaintenanceLink
                  entity={tag.target?.maintenance}
                  enabled={withLink}
                  withTooltip={false}
                  sx={{ color: "inherit" }}
                />
              )}
            </>
          </Box>

          {/* What, When, GeneralByWhoDisplay tagged */}
          <Box>
            <Box>
              <Box>
                Tagged <InlineApiEnumValue type='TagTargetType' value={tag.targetType} />
                {tag.subTargetType && (
                  <>
                    {" "}
                    (#
                    <InlineApiEnumValue type='TagSubTargetType' value={tag.subTargetType} />)
                  </>
                )}
                .
              </Box>
            </Box>

            <Typography variant='body1'>
              Tagged at <Datetime datetime={tag.taggedAt} />
              {tag.taggedBy && (
                <>
                  {" "}
                  by{" "}
                  {tag.taggedBy.user && <InlineUser user={tag.taggedBy.user} withAvatar withLink />}
                </>
              )}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Popover>
  );
}
