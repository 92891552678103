import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useCallback, useEffect } from "react";
import { CountryIso2 } from "react-international-phone";

import {
  GeneralNationalIdentityNumberDto,
  GeneralNationalIdentityNumberInputDto,
  TaxType,
  TaxValueType,
} from "@/core/api/generated";

import CountryCodeSelectInputAdornment from "../../../Form/Input/InputAdornment/CountryCodeSelectInputAdornment";
import CountrySelectInputAdornment from "../../../Form/Input/InputAdornment/CountrySelectInputAdornment";

interface Props extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment"> {
  value:
    | GeneralNationalIdentityNumberInputDto
    | GeneralNationalIdentityNumberDto
    | null
    | undefined;
  defaultCountryCode?: CountryIso2 | null;
  allowCountryCodeChange?: boolean;
  disabled?: boolean;
  onChange?: (newValue?: GeneralNationalIdentityNumberInputDto | null) => void;
}

export default function GeneralNationalIdentityNumberInput({
  value,
  defaultCountryCode = undefined,
  allowCountryCodeChange = true,
  disabled,
  onChange,
  ...textFieldProps
}: Props) {
  const changeNumber = useCallback(
    (newValue: string | null | undefined) =>
      onChange && onChange({ ...value, number: newValue || undefined }),
    [value, onChange],
  );
  const changeCountryCode = useCallback(
    (newValue: string | null | undefined) =>
      onChange && onChange({ ...value, countryCode: newValue || undefined }),
    [value, onChange],
  );

  return (
    <TextField
      label='National identity number'
      type='text'
      value={value?.number || ""}
      placeholder=''
      onChange={(e) => {
        changeNumber(e.target.value);
      }}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <CountrySelectInputAdornment
              value={value ? { alpha2Code: value.countryCode } : undefined}
              defaultCountryCode={defaultCountryCode}
              dropdownIconButtonProps={{
                disabled: disabled || !allowCountryCodeChange,
                size: "medium",
              }}
              onChange={(newValue) => {
                changeCountryCode(newValue?.alpha2Code?.toUpperCase());
              }}
            />
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  );
}
