import { apiClient } from "@/core/api/ApiClient";
import { EntityType, TenantRequestDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: TenantRequestDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function TenantRequestDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.TenantRequest}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) => {
        apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          tenantRequestId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
