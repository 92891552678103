import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, GeneralStatusHistoryDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../../components/BaseEntityLink";
import GeneralStatusHistoryInline, {
  GeneralStatusHistoryInlineProps,
} from "./GeneralStatusHistoryInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: GeneralStatusHistoryDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<GeneralStatusHistoryInlineProps>;
}

export default function GeneralStatusHistoryLink({
  entity,
  entityId,
  inlineProps,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([
        AppPermission.FleetAppAccess,
        AppPermission.GeneralStatusHistoryRead,
      ])}
      to={ROUTE_PATH.GENERAL_STATUS_HISTORY_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <GeneralStatusHistoryInline
          entity={entity}
          entityId={entityId}
          {...inlineProps}
          {...otherProps}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Status history record"}
    />
  );
}
