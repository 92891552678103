import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";

import { GeneralPriceSummaryDtoDetailedLocal } from "@/common/ts/lineItems";

import GeneralPriceSummaryDisplay from "./GeneralPriceSummaryDisplay";
import GeneralPriceSummaryInline from "./GeneralPriceSummaryInline";

export interface TotalPriceExplanationProps {
  summary: GeneralPriceSummaryDtoDetailedLocal;
  sx?: SxProps<Theme>;
}

/** Displays explanation that total = subTotal + tax + etc */
export default function TotalPriceExplanation({ summary, sx }: TotalPriceExplanationProps) {
  return (
    <Stack spacing={1} sx={sx}>
      <GeneralPriceSummaryDisplay summary={summary} />

      <Box>
        <Typography component='div' variant='subtitle1'>
          Calculation
        </Typography>
        <GeneralPriceSummaryInline summary={summary} />
      </Box>
    </Stack>
  );
}
