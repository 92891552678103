import { Box, Stack, SxProps, Theme } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";
import moment, { Moment } from "moment";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { DatetimeHelper } from "@/common/helpers/datetime";

import AppTypography from "../Text/AppTypography";

const defaultFormat = DATETIME_FORMATS.DISPLAY_DATETIME;

interface DatetimeProps {
  datetime?: string | Moment | null;
  /** Moment format syntax */
  format?: string | null;
  direction?: ResponsiveStyleValue<"column" | "row" | "row-reverse" | "column-reverse">;
  withFallbackToNow?: boolean;
  withDurationFromNow?: boolean;
  sx?: SxProps<Theme>;
}

/** Displays datetime */
export default function Datetime({
  datetime,
  format,
  direction = "row",
  withFallbackToNow = false,
  withDurationFromNow = false,
  sx,
}: DatetimeProps) {
  if (!datetime) {
    return null;
  }

  const datetimeM = (datetime && moment(datetime)) || (withFallbackToNow ? moment() : null);
  const formatted = datetimeM ? datetimeM.format(format || defaultFormat) : "";

  return (
    <Stack
      component='span'
      direction={direction}
      spacing={direction === "row" ? 0.5 : 0}
      sx={{
        display: "inline-flex",
        ...((direction === "row" && { alignItems: "center", justifyContent: "start" }) || {}),
        ...((direction === "column" && { alignItems: "start", justifyContent: "start" }) || {}),
        ...sx,
      }}
    >
      <Box component='span'>{formatted}</Box>{" "}
      {withDurationFromNow && (
        <AppTypography
          ellipsing={{ enabled: true }}
          component='span'
          color='text.secondary'
          isInheritFontStyle
        >
          ({DatetimeHelper.humanizeDateRangeDurationFromNow(datetimeM)})
        </AppTypography>
      )}
    </Stack>
  );
}
