import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderIcon from "@mui/icons-material/Folder";
import PageviewIcon from "@mui/icons-material/Pageview";
import { Avatar, AvatarGroup, Box, Stack, Typography } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";

import InlineCode from "@/common/components/Code/InlineCode";
import AppIcon from "@/common/components/Icons/AppIcon";

export default function DevAvatars() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        Avatars
      </Typography>

      <Typography component='div' variant='h5' sx={{ my: 2 }}>
        <InlineCode>MUI Avatars</InlineCode>
      </Typography>
      <Stack direction='column' spacing={1}>
        <Stack direction='row' spacing={1}>
          {" "}
          <Avatar>H</Avatar>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
          <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar>
          <Avatar src='/broken-image.jpg' />
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Stack direction='row' spacing={2}>
            <Avatar sx={{ width: 24, height: 24 }}>N</Avatar>
            <Avatar>H</Avatar>
            <Avatar sx={{ width: 56, height: 56 }}>H</Avatar>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <Stack direction='row' spacing={2}>
            <Avatar>
              <AppIcon of='folder' />
            </Avatar>
            <Avatar sx={{ bgcolor: "pink" }}>
              <AppIcon of='pageView' />
            </Avatar>
            <Avatar sx={{ bgcolor: "green" }}>
              <AppIcon of='repairSpec' />
            </Avatar>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1}>
          {" "}
          <AvatarGroup max={4}>
            <Avatar>H</Avatar>
            <Avatar>F</Avatar>
            <Avatar>GDF</Avatar>
            <Avatar>Ss</Avatar>
            <Avatar>FG</Avatar>
          </AvatarGroup>
        </Stack>
      </Stack>
    </Box>
  );
}
