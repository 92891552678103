import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { NegotiationDto, PaginationDtoOfNegotiationDto } from "@/core/api/generated";

import { DataListProps } from "../../DataList/DataList";
import DataTabular from "../../DataTabular/DataTabular";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import EditNegotiationModal from "./EditNegotiationModal";

interface Props {
  paginated?: PaginationDtoOfNegotiationDto;
  onPaginationChange?: DataListProps<NegotiationDto>["onPaginationChange"];
}

export default function PaginatedNegotiationList({ paginated }: Props) {
  const [negotiation, setNegotiation] = useState<NegotiationDto | undefined>(undefined);
  const [isEditNegotiationModalOpen, setIsEditNegotiationModalOpen] = useState(false);

  return (
    <Box>
      <DataTabular
        columns={[
          {
            field: "localNumber",
            title: "Number",
            flex: 1,
            renderCell: (item) => item.localNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => (
              <Stack>
                {item.name && (
                  <Typography component='div' variant='body1'>
                    {item.name}
                  </Typography>
                )}
                {item.description && (
                  <Typography component='div' variant='body2'>
                    {item.description}
                  </Typography>
                )}
              </Stack>
            ),
          },
          {
            field: "scope.type",
            title: "Scope type",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='GeneralScopeType' value={item.scope?.type} />
            ),
          },
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => <InlineApiEnumValue type='NegotiationType' value={item.type} />,
          },
          {
            field: "status",
            title: "Status",
            flex: 1,
            renderCell: (item) => (
              <InlineApiEnumValue type='NegotiationStatus' value={item.status} />
            ),
          },
          {
            field: "proposals.length",
            title: "Proposals",
            flex: 1,
            renderCell: (item) => <>{item.proposals?.length}</>,
          },
          {
            field: "result.approvalStatus",
            title: "Result",
            flex: 1,
            renderCell: (item) => (
              <>
                <InlineApiEnumValue type='ApprovalStatus' value={item.result?.approvalStatus} />
              </>
            ),
          },
        ]}
        rows={paginated?.items}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.NEGOTIATION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <>
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <MenuItem component={RouterLink} to={ROUTE_PATH.NEGOTIATION_VIEW(item.id)}>
                <ListItemIcon>
                  <AppIcon of='view' fontSize='small' />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
              <MenuItem
                // component={RouterLink}
                // to={ROUTE_PATH.NEGOTIATION_EDIT(item.id)}
                onClick={() => {
                  setNegotiation(item);
                  setIsEditNegotiationModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
            </MenuWithTrigger>
          </>
        )}
        pagination={{
          initialPaginationInfo: paginated?.pagination,
          currentPaginationInfo: paginated?.pagination,
          onChange: () => {},
        }}
      />

      {/* Edit modal */}
      {negotiation && (
        <EditNegotiationModal
          negotiation={negotiation}
          open={isEditNegotiationModalOpen}
          onClose={() => setIsEditNegotiationModalOpen(false)}
        />
      )}
    </Box>
  );
}
