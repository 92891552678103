import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, FormHelperText, IconButton, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useState } from "react";

import InlineCode from "@/common/components/Code/InlineCode";
import ChatFileUploader from "@/common/components/Files/ChatFileUploader";
import ImageUploader from "@/common/components/Files/ImageUploader";
import { apiClient } from "@/core/api/ApiClient";

import FileUploader from "../../common/components/Files/FileUploader";

export default function DevDataGrid() {
  return (
    <Box>
      <Typography component='div' variant='h4' sx={{ my: 2 }}>
        DataGrid
      </Typography>
    </Box>
  );
}
