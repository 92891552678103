import { Stack } from "@mui/material";

import { PaginationDtoOfGeneralEventLogDto } from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import GeneralEventLogItem from "./GeneralEventLogItem";

type OwnProps = {
  paginatedEventLogs: PaginationDtoOfGeneralEventLogDto;
};

type Props = OwnProps;

/** @deprecated Use GeneralHistory. */
export default function GeneralEventLogList({ paginatedEventLogs }: Props) {
  return (
    <Stack direction='column' spacing={1}>
      {paginatedEventLogs && paginatedEventLogs.items!.length === 0 && (
        <NoDataAlert title='No logs yet' />
      )}

      {paginatedEventLogs?.items?.map((item, index) => {
        return <GeneralEventLogItem key={index} item={item} />;
      })}
    </Stack>
  );
}
