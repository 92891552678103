import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AccessoryCheckDto, AccessoryCheckSearchPaginatedDto } from "@/core/api/generated";

import ContractInline from "../Contract/ContractInline";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import VehicleInline from "../Vehicle/VehicleInline";

export type AccessoryCheckAutocompleteOption = BaseAutocompleteOption<AccessoryCheckDto>;

export const getAccessoryCheckTitle = (data: AccessoryCheckDto): string =>
  StringHelper.joinIntoString(
    [data.vehicle ? getVehicleOptionTitle(data.vehicle) : data.localNumber],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const accessoryCheckToOption = (
  data: AccessoryCheckDto,
): AccessoryCheckAutocompleteOption => ({
  id: data.id!,
  title: getAccessoryCheckTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AccessoryCheckAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AccessoryCheckDto> {
  searchFilters?: Partial<Omit<AccessoryCheckSearchPaginatedDto, "search" | "includeIds">>;
}

export default function AccessoryCheckAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AccessoryCheckAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={accessoryCheckToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.accessoryChecksApi.apiV1AccessoryChecksSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          ...searchFilters,
        },
        combineParameters: (params, newParams) => ({
          ...params,
          accessoryCheckSearchPaginatedDto: {
            ...params.accessoryCheckSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Accessory check'
      placeholder='Search...'
      renderOption={(props, option: AccessoryCheckAutocompleteOption) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='accessoryCheck' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option?.data?.vehicle && (
                        <VehicleInline entity={option.data.vehicle} withIcon={false} />
                      )}
                      {option?.data?.contract && (
                        <ContractInline entity={option.data.contract} withIcon={false} />
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
