import { useMemo } from "react";

import { FileItem } from "@/common/fileItem";
import { GeneralAttachmentDto } from "@/core/api/generated";

import FileListView, { FileListViewProps } from "./FileListView";

export interface Props {
  attachments: GeneralAttachmentDto[];
  fileListViewProps?: Partial<Omit<FileListViewProps, "files">>;
}

export default function AttachmentListView({ attachments, fileListViewProps }: Props) {
  const files = useMemo(() => FileItem.createManyFrom(attachments || []), [attachments]);

  return (
    <FileListView
      files={files}
      maxVisibleFiles={10}
      itemWidth={80}
      itemHeight={80}
      {...fileListViewProps}
    />
  );
}
