import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import RoleListItem from "@/common/components/Entity/Role/RoleListItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export default function MyRolesTabContent() {
  const profileRolesRequest = useApiRequest(apiClient.profileApi.apiV1ProfileRolesGet, {
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  });
  const profileRoles = useMemo(() => profileRolesRequest.data, [profileRolesRequest.data]);

  return (
    <PageTabContent>
      <Box sx={{ mb: 2 }}>
        <PageTabHeader title='My Roles' />
      </Box>

      {profileRolesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

      {profileRoles && (
        <Stack direction='column' spacing={2}>
          <Box>
            <Typography component='div' variant='h4' sx={{ mb: 1 }}>
              Global roles
            </Typography>

            {_.isEmpty(profileRoles.globalRoles) && <NoDataAlert title='No global roles' />}

            {profileRoles.globalRoles!.map((role) => (
              <RoleListItem key={role.id} role={role} />
            ))}
          </Box>

          <Box>
            <Typography component='div' variant='h4' sx={{ mb: 1 }}>
              Company roles
            </Typography>

            {_.isEmpty(profileRoles.tenantRoles) && <NoDataAlert title='No company roles' />}

            {profileRoles.tenantRoles!.map((role) => (
              <RoleListItem key={role.id} role={role} />
            ))}
          </Box>
        </Stack>
      )}
    </PageTabContent>
  );
}
