import { renderIf } from "@/common/helpers/render/renderIf";
import { FileUsageLockSubjectDto, FileUsageLockSubjectType } from "@/core/api/generated";

import AppTypography from "../../Text/AppTypography";
import EntityLink from "../components/EntityLink";

interface Props {
  value: FileUsageLockSubjectDto | undefined | null;
}

export default function FileUsageLockSubjectLink({ value }: Props) {
  if (!value) {
    return null;
  }

  return renderIf()
    .if(value.type === FileUsageLockSubjectType.Entity)
    .then(<EntityLink entityType={value.entityType} entityId={value.entityId} entity={undefined} />)
    .else(
      <AppTypography color='error'>Subject type {value.type} is not supported yet!</AppTypography>,
    )
    .render();
}
