import { ENTITY_TYPE_GENERAL_SCOPE_TYPE_MAP } from "@/common/constants/entityComments";
import { EntityType, GeneralScopeType } from "@/core/api/generated";

export function mapEntityTypeToGeneralScopeType(
  entityType: EntityType,
  allowDynamic: boolean = false,
): GeneralScopeType {
  let value = ENTITY_TYPE_GENERAL_SCOPE_TYPE_MAP[entityType];
  if (value === undefined && allowDynamic) {
    value = GeneralScopeType.Dynamic;
  }
  if (value === undefined) {
    throw new Error(`GeneralScopeType for EntityType ${entityType} is not defined`);
  }
  return value;
}
