export enum THEMES {
  LIGHT = "light",
  DARK = "dark",
}

export const LANGUAGE_CODES = {
  EN: "en",
};

/** Date and time formats passed to moment.format() */
export const DATETIME_FORMATS = {
  DISPLAY_DATETIME: "YYYY-MM-DD HH:mm",
  DISPLAY_DATETIME_DETAILED: "YYYY-MM-DD HH:mm:ss",
  DISPLAY_DATE: "YYYY-MM-DD",
  DISPLAY_DATE__YEAR: "YYYY",
  DISPLAY_TIME: "HH:mm",
};

export const NUMBER_PRECISION = {
  /** Price or amount in some currency */
  MONEY: 2,

  /** For [0,1] percent value */
  PERCENT: 4,
};

/** Additional and custom HTTP headers accepted by the API. */
export const API_HTTP_HEADER_NAME = {
  APP_VERSION: "NexusOps-App-Version",
  TENANT_IDENTIFIER: "NexusOps-Tenant",
  AUTHORIZATION_STATE: "NexusOps-Authorization-State",
  CUSTOM_SESSION_IDENTIFIER: "NexusOps-CS-ID",
  SIGNALR_CHATHUB_CONNECTIONID: "NexusOps-SignalR-ChatHub-ConnectionId",
  SIGNALR_NOTIFICATIONHUB_CONNECTIONID: "NexusOps-SignalR-NotificationHub-ConnectionId",
  SIGNALR_DATAUPDATESHUB_CONNECTIONID: "NexusOps-SignalR-DataUpdatesHub-ConnectionId",
  CULTURE_NAME: "NexusOps-Culture",
  TIME_ZONE_ID: "NexusOps-Tz",
};

/** HTTP query string parameter names accepted by the API. */
export const API_HTTP_QUERY_STRING_PARAM_NAME = {
  TENANT_IDENTIFIER: "NexusOps-Tenant",
};
