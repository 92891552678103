import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import _ from "lodash";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  CreateWheelOperationDto,
  EntityType,
  ScheduleType,
  UpdateWheelOperationDto,
  WheelOperationDto,
} from "@/core/api/generated";

import ApiEnumSelect from "../../Enum/ApiEnumSelect";
import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import AppTextArea from "../../Form/Input/AppTextArea";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import AppIcon from "../../Icons/AppIcon";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import SupplierAutocompleteOrCreate from "../Supplier/SupplierAutocompleteOrCreate";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: CreateWheelOperationDto["vehicleId"];
  supplierId?: CreateWheelOperationDto["supplierId"];
  departmentId?: CreateWheelOperationDto["departmentId"];
  locationId?: CreateWheelOperationDto["locationId"];
  tenantRequestsMeta?: CreateWheelOperationDto["tenantRequestsMeta"];
  scheduleType?: ScheduleType;
  currency?: CreateWheelOperationDto["currency"];
};

export interface WheelOperationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<WheelOperationDto, DefaultValues> {
  wheelOperationId?: string;
}

export type WheelOperationCreateUpdateProps = WheelOperationCreateUpdateOwnProps;

export default function WheelOperationCreateUpdate({
  wheelOperationId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: WheelOperationCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const history = useHistory();
  const currentCurrency = useCurrentCurrency();
  const isCreate = !wheelOperationId;
  const isEdit = !!wheelOperationId;

  const [contract, setContract] = useState<ContractDto | undefined>(undefined);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const wheelOperationRequest = useApiRequest(
    apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      wheelOperationId: wheelOperationId!,
    },
    {
      deps: [wheelOperationId],
      skip: !wheelOperationId,
    },
  );
  const wheelOperation = wheelOperationRequest?.data;

  const { departments, locations } = useUserAffiliation();

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: wheelOperation && {
      idValue: wheelOperation.id!,
      newTitle: wheelOperation.localNumber || "",
    },
  });

  const vehicleIdComputed = defaultValues?.vehicleId || wheelOperation?.vehicle?.id;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.WheelOperation}
      entityId={wheelOperationId}
      entity={wheelOperation}
      entityRequest={wheelOperationRequest}
    >
      <Formik<
        BaseFormikValues &
          CreateWheelOperationDto &
          UpdateWheelOperationDto & {
            vehicle?: WheelOperationDto["vehicle"];
            supplier?: WheelOperationDto["supplier"];
            initialAttachments: WheelOperationDto["attachments"];
            total?: number;
          }
      >
        initialValues={{
          vehicleId: wheelOperation?.vehicle?.id || defaultValues?.vehicleId || undefined,
          supplierId: wheelOperation?.supplier?.id || defaultValues?.supplierId || undefined,
          departmentId:
            defaultValues?.departmentId ||
            (wheelOperation?.departmentIds || []).at(0) ||
            (departments && departments[0] && departments[0].id) ||
            undefined,
          locationId:
            defaultValues?.locationId ||
            (wheelOperation?.locationIds || []).at(0) ||
            (locations && locations[0] && locations[0].id) ||
            undefined,
          responsibleUser: {
            isCurrentUser: true,
            ...(wheelOperation?.responsibleUser || {}),
          },
          scheduledAt: wheelOperation?.scheduledAt || undefined,
          scheduleType: wheelOperation?.scheduleType || defaultValues?.scheduleType || undefined,
          serviceTypes: wheelOperation?.serviceTypes || undefined,
          mileage: wheelOperation?.mileage ?? 0,
          fuelLevel: wheelOperation?.fuelLevel ?? undefined,
          notes: wheelOperation?.notes || "",
          currency:
            defaultValues?.currency || wheelOperation?.currency || currentCurrency || undefined,
          price: wheelOperation?.price || 0,
          discount: wheelOperation?.discount || undefined,
          tax: wheelOperation?.tax || undefined,
          initialAttachments: wheelOperation?.attachments || undefined,
          attachments: !_.isEmpty(wheelOperation?.attachments)
            ? wheelOperation?.attachments
            : undefined,
          tenantRequestsMeta:
            wheelOperation?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: wheelOperation?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // contractId: Yup.string().required("Contract is required"),
          // vehicleId: Yup.string().required("Vehicle is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
          };

          try {
            if (isCreate) {
              const response = await apiClient.wheelOperationsApi.apiV1WheelOperationsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createWheelOperationDto: { ...values2 },
              });
              enqueueSnackbar("Wheel operation created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.wheelOperationsApi.apiV1WheelOperationsWheelOperationIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  wheelOperationId,
                  updateWheelOperationDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("Wheel operation updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Stack spacing={2}>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    {/* Vehicle */}
                    <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                      <VehicleAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          disabled: !isCreate || !_.isEmpty(vehicleIdComputed),
                          entityId: values.vehicleId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`vehicleId`, newValue?.id);
                            if (newValue?.id) {
                              if (!values.departmentId) {
                                setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                              }
                              if (!values.locationId) {
                                setFieldValue("locationId", newValue?.locationIds?.at(0));
                              }
                            }
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          if (newValue?.id) {
                            if (!values.departmentId) {
                              setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                            }
                            if (!values.locationId) {
                              setFieldValue("locationId", newValue?.locationIds?.at(0));
                            }
                          }
                        }}
                      />
                      <FormHelperText>{errors.vehicleId}</FormHelperText>
                    </FormControl>

                    <FormControl margin='dense' fullWidth error={Boolean(errors.supplierId)}>
                      <SupplierAutocompleteOrCreate
                        autocompleteProps={{
                          required: true,
                          entityId: values.supplierId,
                          isPreload: isCreate,
                          onChange: (newValue) => {
                            setFieldValue(`supplierId`, newValue?.id);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`supplierId`, newValue?.id);
                        }}
                      />
                      <FormHelperText>{errors.supplierId}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />
                    {/* Schedule Type */}
                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(touched.scheduleType && errors.scheduleType)}
                    >
                      <InputLabel required>Schedule type</InputLabel>
                      <ApiEnumSelect
                        type='ScheduleType'
                        value={values.scheduleType}
                        onChange={(newValue) => {
                          setFieldValue("scheduleType", newValue),
                            setFieldValue("scheduledAt", undefined);
                        }}
                        selectProps={{
                          label: "ScheduleType",
                          required: true,
                        }}
                      />
                      <FormHelperText>{touched.scheduleType && errors.scheduleType}</FormHelperText>
                    </FormControl>
                    {/* Schedule At */}
                    {values.scheduleType === ScheduleType.Scheduled && (
                      <FormControl
                        margin='dense'
                        fullWidth
                        error={Boolean(touched.scheduledAt && errors.scheduledAt)}
                      >
                        <MobileDateTimePicker
                          ampm={false}
                          label='Schedule At'
                          value={(values.scheduledAt && moment(values.scheduledAt)) || null}
                          format={DATETIME_FORMATS.DISPLAY_DATETIME}
                          onChange={(newValue: Moment | null) => {
                            setFieldValue("scheduledAt", newValue?.format() || null);
                          }}
                          slots={{ textField: (params) => <TextField {...params} /> }}
                        />
                        <FormHelperText>{touched.scheduledAt && errors.scheduledAt}</FormHelperText>
                      </FormControl>
                    )}

                    {/* Service Types */}
                    <FormControl
                      fullWidth
                      margin='dense'
                      error={Boolean(touched.scheduleType && errors.scheduleType)}
                    >
                      <ApiEnumsAutocomplete
                        required
                        label='Service types'
                        type='WheelServiceType'
                        values={values.serviceTypes}
                        onChange={(newValue) => setFieldValue("serviceTypes", newValue)}
                        textFieldProps={{
                          error: Boolean(errors.serviceTypes),
                        }}
                      />
                      <FormHelperText>{touched.scheduleType && errors.scheduleType}</FormHelperText>
                    </FormControl>

                    {/* Mileage */}
                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.mileage && errors.mileage)}
                    >
                      <Typography component='div' gutterBottom>
                        Mileage
                      </Typography>
                      <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
                        <AppIcon of='addRoad' />
                        <Input
                          name='mileage'
                          value={values.mileage || ""}
                          size='small'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: 10000000,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </Stack>
                      <FormHelperText>{touched.mileage && errors.mileage}</FormHelperText>
                    </FormControl>

                    {/* Fuel level */}
                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                      sx={{ mb: 4 }}
                    >
                      <Typography component='div' gutterBottom>
                        Fuel level
                      </Typography>

                      <FuelLevelInput
                        onBlur={handleBlur}
                        onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                        value={values.fuelLevel ?? undefined}
                      />
                      <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                    </FormControl>
                    {/* Cost */}
                    <FormControl fullWidth margin='dense'>
                      <PriceSummaryInput
                        values={{
                          currency: values.currency,
                          subTotal: values.price,
                          discount: values.discount,
                          tax: values.tax,
                          total: values.total,
                        }}
                        formikProps={{
                          errors: {
                            subTotal: errors.price,
                            discount: errors.discount,
                            tax: errors.tax,
                            total: errors.total,
                          },
                        }}
                        displayProps={{
                          insurance: false,
                          calcExplanation: true,
                        }}
                        inputsProps={{
                          all: {
                            margin: "none",
                          },
                          subTotal: {
                            label: "Price",
                            required: true,
                          },
                          total: {
                            required: true,
                          },
                        }}
                        onChange={(newValue) => {
                          setFieldValue(`currency`, newValue?.currency);
                          setFieldValue(`price`, newValue?.subTotal);
                          setFieldValue(`discount`, newValue?.discount);
                          setFieldValue(`tax`, newValue?.tax);
                          setFieldValue(`total`, newValue?.total);
                        }}
                      />
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <AppTextArea
                        error={Boolean(touched.notes && errors.notes)}
                        fullWidth
                        helperText={touched.notes && errors.notes}
                        mode='notes'
                        margin='dense'
                        name='notes'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.notes}
                        variant='outlined'
                      />
                    </FormControl>

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                      />
                    </FormControl>
                  </Box>
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <LoadingButton
                  sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                  color='primary'
                  disabled={isAttachmentFilesUploading}
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
