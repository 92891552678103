import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ProductLocationCreateUpdate, {
  ProductLocationCreateUpdateProps,
} from "./ProductLocationCreateUpdate";

export interface OwnProps {
  createUpdateProps: ProductLocationCreateUpdateProps;
}

type Props = OwnProps & DialogProps;

export default function ProductLocationCreateUpdateModal({
  createUpdateProps,
  ...dialogProps
}: Props) {
  const isCreate = !createUpdateProps.productLocationId;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        {isCreate ? "Create new" : "Edit"} sales location
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <ProductLocationCreateUpdate {...createUpdateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
