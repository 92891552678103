import { apiClient } from "@/core/api/ApiClient";
import { EntityType, PoolDto } from "@/core/api/generated";

import BaseEntityDeleteModal, {
  BaseEntityDeleteModalInheritableProps,
} from "../components/BaseEntityDeleteModal";

export interface OwnProps {
  entity: PoolDto;
}

type Props = OwnProps & BaseEntityDeleteModalInheritableProps;

export default function PoolDeleteModal({ entity, ...otherProps }: Props) {
  return (
    <BaseEntityDeleteModal
      entityType={EntityType.Pool}
      entity={entity}
      entityId={entity.id}
      deleteFunc={(params) => {
        apiClient.poolsApi.apiV1PoolsPoolIdDelete({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          poolId: params.entityId,
        });
      }}
      {...otherProps}
    />
  );
}
