import { Box, Divider, FormControl, Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";

import ListPageLayout from "@/App/Layouts/Pages/ListPageLayout";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import InlineEntityChangedByInfoDisplay from "@/common/components/EntityInfo/InlineEntityChangedByInfoDisplay";
import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { VehicleType } from "@/core/api/generated";

import EntitySourceIsCar2DbChip from "../../components/Entity/EntitySourceIsCar2DbChip";

interface VehicleTypesProps {}

export default function VehicleTypesPage({}: VehicleTypesProps) {
  const topAnchorRef = useRef<HTMLElement | null>(null);

  const [search, setSearch] = useState("");
  const [vehicleType, setVehicleType] = useState<VehicleType | undefined>(undefined);

  const vehicleTypesRequest = useApiRequest(
    apiClient.adminVehicleTypesApi.apiV1AdminReferenceDataVehiclesTypesGetPost,
    {
      adminVehicleTypeGetPaginatedDto: {
        search,
        type: vehicleType || undefined,
      },
    },
    {
      deps: [vehicleType],
      debouncedDeps: {
        deps: [search],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const { data: vehicleTypes } = vehicleTypesRequest;

  return (
    <ListPageLayout>
      <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

      <Stack direction={{ xs: "column", md: "row" }} spacing={1} sx={{ margin: 0 }}>
        <TextField
          label='Search'
          size='small'
          margin='normal'
          type='text'
          variant='outlined'
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ margin: 0, minWidth: 300, maxWidth: "100%" }}
        />

        <FormControl size='small' sx={{ minWidth: 200 }}>
          <ApiEnumAutocomplete
            type='VehicleType'
            value={vehicleType}
            label='Vehicle type'
            size='small'
            onChange={(newValue) => setVehicleType(newValue || undefined)}
          />
        </FormControl>
      </Stack>

      <Divider sx={{ my: 2 }} />

      <DataTabular
        columns={[
          {
            field: "globalNumber",
            title: "Global number",
            flex: 1,
            renderCell: (item) => item.globalNumber,
          },
          {
            field: "name",
            title: "Name",
            flex: 1,
            renderCell: (item) => item.name,
          },
          {
            field: "type",
            title: "Type",
            flex: 1,
            renderCell: (item) => <>{item.type}</>,
          },
          {
            field: "isBuiltIn",
            title: "Built-in",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isBuiltIn} />,
          },
          {
            field: "isEnabled",
            title: "Enabled",
            flex: 1,
            renderCell: (item) => <BooleanValue value={item.isEnabled} />,
          },
          {
            field: "sortOrder",
            title: "Sort order",
            flex: 1,
            renderCell: (item) => <>{item.sortOrder}</>,
          },
          {
            field: "updatedBy",
            title: "Changed by",
            flex: 1,
            renderCell: (item) => <InlineEntityChangedByInfoDisplay entity={item} />,
          },
          {
            field: "source",
            title: "Source",
            flex: 1,
            isColumnMenuDisabled: true,
            renderCell: (item) =>
              item.car2DbId ? <EntitySourceIsCar2DbChip car2DbId={item.car2DbId} /> : "-",
          },
        ]}
        rows={vehicleTypes?.items}
        getRowId={(item) => item.id!}
        isLoading={vehicleTypesRequest.isLoading}
      />
    </ListPageLayout>
  );
}
