import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetSubscriptionDto,
  AssetSubscriptionReferenceDto,
} from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssetSubscriptionInline from "./AssetSubscriptionInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity?: AssetSubscriptionDto | AssetSubscriptionReferenceDto;
  entityId?: string | null;
}

export default function AssetSubscriptionLink({ entity, entityId, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  if (!entity && !entityId) {
    return null;
  }

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.AssetSubscriptionRead])}
      to={ROUTE_PATH.ASSET_SUBSCRIPTION_VIEW(entity?.id || "")}
      icon={<AppIcon of='entity' inText />}
      content={
        <AssetSubscriptionInline
          entity={entity}
          entityId={entityId}
          withIcon={false}
          withTooltip={false}
        />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Asset subscription"}
    />
  );
}
