import { DependencyList, useMemo } from "react";

import { useValueMemoWithDeepCompare } from "./useValueMemoWithDeepCompare";

/** useMemo with deep compare for deps array.
 *  Source: https://stackoverflow.com/a/54096391/5168794
 */
export function useMemoWithDeepCompare<T>(factory: () => T, deps: DependencyList | undefined): T {
  return useMemo(factory, deps?.map(useValueMemoWithDeepCompare) as DependencyList);
}
