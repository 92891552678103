import { Button, TextField, TextFieldProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CountrySelector, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface PhoneInputProps
  extends Omit<TextFieldProps, "value" | "onChange" | "inputRef" | "InputProps"> {
  value?: string | null;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    phone: string | undefined,
    country: string,
  ) => any;
}

const useStyles = makeStyles({
  container: {
    "& .react-international-phone-country-selector-dropdown": { zIndex: 10 },
  },
});

function PhoneInput({ value = "", onChange, ...props }: PhoneInputProps) {
  const { phone, country, handlePhoneValueChange, setCountry, inputRef } = usePhoneInput({
    value: value || "",
    defaultCountry: "no",
  });
  const classes = useStyles();

  return (
    <TextField
      {...props}
      value={phone}
      onChange={(e) => {
        const newPhone = handlePhoneValueChange(e);
        e.target.value = newPhone;
        onChange && onChange(e, newPhone, country);
      }}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <CountrySelector
            selectedCountry={country}
            onSelect={(c) => {
              setCountry(c.iso2);
              // onChange && onChange(e, `+${c.dialCode}`, c.iso2);

              // reset phone and trigger change
              if (inputRef.current) {
                inputRef.current.value = "";
              }
            }}
            className={classes.container}
            renderButtonWrapper={({ children, rootProps }) => (
              <Button
                onClick={rootProps.onClick}
                color='primary'
                sx={{
                  margin: "0 4px 0 -4px",
                  padding: "2px",
                  minWidth: "0",
                }}
              >
                {children}{" "}
                <Typography
                  component='span'
                  variant='body2'
                  sx={{ fontWeight: "bold", color: (th) => th.palette.text.primary }}
                >
                  {country?.toUpperCase()}
                </Typography>
              </Button>
            )}
          />
        ),
      }}
    />
  );
}

export default React.memo(PhoneInput);
