import {
  Alert,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TypographyProps,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AlertMenuItem from "@/common/components/Menu/AlertMenuItem";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { TenantRequestHelper } from "@/common/helpers/entity/tenantRequest";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
  TenantRequestDto,
  TenantRequestStage,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import GeneralAttachedTagsOfEntityEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntityEditModal";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import TenantRequestDeleteModal from "../TenantRequestDeleteModal";
import TenantRequestStagePrimaryActions from "../TenantRequestStagePrimaryActions";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import DataGrantsTabContent from "./Tabs/DataGrantsTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum TenantRequestViewTabs {
  Overview = "Overview",
  DataGrants = "DataGrants",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<TenantRequestDto> {
  tenantRequestId: string | null | undefined;
  tenantRequest: TenantRequestDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
  onSave?: (newValue: TenantRequestDto) => void;
}

export type TenantRequestViewProps = OwnProps;

export default function TenantRequestView({
  tenantRequestId,
  tenantRequest,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
  onSave,
}: TenantRequestViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useHistory();
  const currentTenant = useCurrentTenant();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(false);

  const tenantRequestRequest = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestId: tenantRequestId!,
    },
    {
      deps: [tenantRequestId],
      skip: !tenantRequestId || !!tenantRequest,
    },
  );
  tenantRequest = tenantRequestRequest?.data || tenantRequest;

  const dataUpdatesSub = useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(null, EntityType.TenantRequest, tenantRequest?.id || ""),
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.TenantRequest,
        tenantRequest?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      tenantRequestRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        tenantRequest && {
          idValue: tenantRequest.id!,
          newTitle: tenantRequest.globalNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.TenantRequest}
      entityId={tenantRequestId}
      entity={tenantRequest}
      entityRequest={tenantRequestRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Company request ${tenantRequest?.globalNumber}`}
              subtitle={
                <Stack>
                  {/* Tags */}
                  {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
                    tenantRequest?.tags && (
                      <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                        <GeneralAttachedTagsDisplay
                          tags={tenantRequest.tags}
                          defaultIsFolded={false}
                          edit={{
                            entityType: TagEntityType.TenantRequest,
                            entityId: tenantRequest.id,
                            onSaved: (newValue) => {
                              tenantRequestRequest.updateData((x) => {
                                x.tags = newValue || undefined;
                              });
                            },
                          }}
                        />
                      </AuthorizedElement>
                    )}

                  {/* Alerts */}
                  {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Draft && (
                      <Alert severity='info'>
                        The request is on{" "}
                        <InlineApiEnumValue
                          type='TenantRequestStage'
                          value={tenantRequest?.stageHistory?.stage}
                          sx={{ fontWeight: "bold" }}
                        />{" "}
                        stage. You still can edit it. Complete and send it, so the receiver can see
                        it and respond. Once sent, you won&apos;t be able to edit the request.
                      </Alert>
                    )}
                  {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) &&
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Declined && (
                      <Alert severity='info'>
                        The sender <strong>declined</strong> this request. You can edit it and send
                        once again.
                      </Alert>
                    )}
                  {TenantRequestHelper.isReceivedByMe(currentTenant, tenantRequest) &&
                    tenantRequest?.stageHistory?.stage === TenantRequestStage.Declined && (
                      <Alert severity='info'>
                        You <strong>declined</strong> this request. The sender can update it and
                        send once again.
                      </Alert>
                    )}
                </Stack>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.TENANT_REQUEST_VIEW(tenantRequest?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                tenantRequest && (
                  <TenantRequestStagePrimaryActions
                    tenantRequest={tenantRequest}
                    onUpdate={(newValue) => {
                      tenantRequestRequest.replaceData(newValue);
                      onSave && onSave(newValue);
                    }}
                  />
                )
              }
              secondaryActions={
                tenantRequest &&
                displayProps.actions &&
                TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                  <MenuWithTrigger
                    withAuthCloseOnClick
                    trigger={
                      <IconButton sx={{ ml: "auto" }}>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                  >
                    {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                      <AuthorizedElement permissions={[AppPermission.TenantRequestManage]}>
                        <AlertMenuItem title='Edit and delete allowed only on Draft stage.' />
                      </AuthorizedElement>
                    )}
                    {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                      <AuthorizedMenuItem
                        permissions={[AppPermission.TenantRequestManage]}
                        disabled={!TenantRequestHelper.canBeEdited(tenantRequest)}
                        component={RouterLink}
                        to={ROUTE_PATH.TENANT_REQUEST_EDIT(tenantRequest.id)}
                      >
                        <ListItemIcon>
                          <AppIcon of='edit' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                      </AuthorizedMenuItem>
                    )}
                    {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                      <AuthorizedMenuItem
                        permissions={[AppPermission.FleetAppAccess]}
                        dense
                        onClick={() => {
                          setIsEditTagsModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='tag' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Edit tags</ListItemText>
                      </AuthorizedMenuItem>
                    )}
                    {TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                      <AuthorizedMenuItem
                        permissions={[AppPermission.TenantRequestManage]}
                        disabled={!TenantRequestHelper.canBeDeleted(tenantRequest)}
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <ListItemIcon>
                          <AppIcon of='delete' fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </AuthorizedMenuItem>
                    )}
                  </MenuWithTrigger>
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={TenantRequestViewTabs}
          defaultTabId={TenantRequestViewTabs.Overview}
          tabs={[
            { label: "Overview", value: TenantRequestViewTabs.Overview },
            { label: "Data grants", value: TenantRequestViewTabs.DataGrants },
            ...(TenantRequestHelper.isSentByMe(currentTenant, tenantRequest)
              ? [{ label: "History", value: TenantRequestViewTabs.GeneralHistory }]
              : []),
            { label: "Comments", value: TenantRequestViewTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            tenantRequest && (
              <>
                {activeTab === TenantRequestViewTabs.Overview && (
                  <OverviewTabContent
                    tenantRequest={tenantRequest}
                    onUpdate={(newValue) => tenantRequestRequest.replaceData(newValue)}
                  />
                )}
                {activeTab === TenantRequestViewTabs.DataGrants && (
                  <DataGrantsTabContent tenantRequest={tenantRequest} />
                )}
                {activeTab === TenantRequestViewTabs.GeneralHistory &&
                  TenantRequestHelper.isSentByMe(currentTenant, tenantRequest) && (
                    <GeneralHistoryTabContent tenantRequest={tenantRequest} />
                  )}
                {activeTab === TenantRequestViewTabs.Comments && (
                  <CommentsTabContent companyRequest={tenantRequest} />
                )}
              </>
            )
          }
        </PageTabsV2>

        {/* Delete */}
        {tenantRequest && (
          <TenantRequestDeleteModal
            entity={tenantRequest}
            open={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={() => history.goBack()}
          />
        )}

        {/* Edit tags */}
        {tenantRequest && (
          <GeneralAttachedTagsOfEntityEditModal
            open={isEditTagsModalOpen}
            onClose={() => setIsEditTagsModalOpen(false)}
            entityType={TagEntityType.TenantRequest}
            entityId={tenantRequest.id!}
            onSaved={(newValue) => {
              tenantRequestRequest.updateData((x) => {
                x.tags = newValue || undefined;
              });
            }}
          />
        )}
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
