import { Box, Link, Typography } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink, { AppLinkProps } from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, InvoiceDto } from "@/core/api/generated";

import { AppEntityLinkProps } from "./types";

interface Props extends AppEntityLinkProps {
  invoice?: InvoiceDto;
}

export default function InvoiceLink({
  invoice,
  withIcon = true,
  withTooltip = true,
  ...otherProps
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <AppLink
      enabled={hasPermissions([AppPermission.InvoiceRead])}
      to={ROUTE_PATH.INVOICE_VIEW(invoice?.id || "")}
      tooltipTitle='Invoice'
      withTooltip={withTooltip}
      {...otherProps}
    >
      {withIcon && <AppIcon of='invoice' fontSize='inherit' color='inherit' sx={{ mr: 1 }} />}
      <Box component='span'>{invoice?.localNumber}</Box>
    </AppLink>
  );
}
