import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { getIn } from "formik";
import _ from "lodash";

import { ArrayHelper } from "@/common/helpers/array";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { enumService } from "@/common/services/enum";
import { VehicleArea, VisualInspectionSettingsInputDto } from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import DropdownButton from "../../Button/DropdownButton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import AppIcon from "../../Icons/AppIcon";

interface Props {
  formikProps: CustomFormikSubProps<VisualInspectionSettingsInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function VisualInspectionSettingsInput({ formikProps, sx }: Props) {
  const { values, touched, errors, setFieldValue, setFieldError } = formikProps;

  const areasToSelect = enumService.getEnumValues("VehicleArea", {
    exceptNone: true,
    except: values?.perArea?.map((x) => x.area || VehicleArea.None),
  });

  return (
    <Stack spacing={2}>
      <Alert severity='info'>
        Settings for visual inspection operation (will be applied to all new operations).
      </Alert>

      <Typography component='span' variant='subtitle1'>
        Per vehicle area
      </Typography>

      {_.isEmpty(values?.perArea) && <NoDataAlert />}

      <Stack direction='column' spacing={1}>
        {values?.perArea?.map((item, index) => {
          return (
            <Card key={index}>
              <CardContent>
                <Stack spacing={2}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 0fr",
                      columnGap: 2,
                    }}
                  >
                    <Typography component='span' variant='subtitle1'>
                      <InlineApiEnumValue type='VehicleArea' value={item.area} />
                    </Typography>

                    <Stack direction='row'>
                      <IconButton
                        onClick={() =>
                          setFieldValue(
                            "perArea",
                            ArrayHelper.removeByIndex([...(values.perArea || [])], index),
                          )
                        }
                      >
                        <AppIcon of='delete' />
                      </IconButton>
                    </Stack>
                  </Box>

                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2fr 2fr",
                      columnGap: 2,
                    }}
                  >
                    <FormControl margin='dense'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.isRequired}
                            onChange={(e) =>
                              setFieldValue(
                                `perArea[${index}].isRequired`,
                                e.target.checked || false,
                              )
                            }
                          />
                        }
                        label={
                          <>
                            Require <InlineApiEnumValue type='VehicleArea' value={item.area} />{" "}
                            inspection{" "}
                          </>
                        }
                      />
                    </FormControl>

                    <TextField
                      error={Boolean(getIn(errors, `perArea[${index}].minAttachmentCount`))}
                      fullWidth
                      helperText={getIn(errors, `perArea[${index}].minAttachmentCount`)}
                      label='Min attachments'
                      margin='dense'
                      onChange={(e) =>
                        setFieldValue(
                          `perArea[${index}].minAttachmentCount`,
                          e.target.value || undefined,
                        )
                      }
                      inputMode='numeric'
                      value={item.minAttachmentCount}
                      variant='outlined'
                    />

                    <TextField
                      error={Boolean(getIn(errors, `perArea[${index}].minPhotoCount`))}
                      fullWidth
                      helperText={getIn(errors, `perArea[${index}].minPhotoCount`)}
                      label='Min photos'
                      margin='dense'
                      onChange={(e) =>
                        setFieldValue(
                          `perArea[${index}].minPhotoCount`,
                          e.target.value || undefined,
                        )
                      }
                      inputMode='numeric'
                      value={item.minPhotoCount}
                      variant='outlined'
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          );
        })}
      </Stack>

      {/* Add item */}
      <DropdownButton
        disabled={_.isEmpty(areasToSelect)}
        buttonProps={{
          color: "secondary",
          size: "small",
          variant: "outlined",
          startIcon: <AppIcon of='add' />,
        }}
        dropdownContent={
          <MenuList>
            {areasToSelect.map((area) => (
              <MenuItem
                key={area}
                onClick={() => {
                  setFieldValue("perArea", [
                    ...(values?.perArea || []),
                    {
                      area: area,
                    },
                  ]);
                }}
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>
                  <InlineApiEnumValue type='VehicleArea' value={area} />
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        }
      >
        Add vehicle area settings
      </DropdownButton>
    </Stack>
  );
}
