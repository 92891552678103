export class CookieHelper {
  private static getAllCookies(documentCookie?: string): Record<string, string | null> {
    const cookieSource = documentCookie || document.cookie;
    const cookies: Record<string, string | null> = {};
    cookieSource.split(";").forEach((el) => {
      const [k, v] = el.split("=");
      cookies[k.trim()] = v ? decodeURIComponent(v) : null;
    });
    return cookies;
  }

  private static getCookieValue(name: string, documentCookie?: string): string | null {
    const cookies = this.getAllCookies(documentCookie);
    return cookies[name] || null;
  }

  /**
   * Handles cookie that is split into chunks because of its value exceeding limit (usualy up to 4050 bytes).
   * Cookie chunked on server by Microsoft.AspNetCore.Authentication.Cookies ChunkingCookieManager.
   * Excpected chunked cookies:
   * {CookieName}: chunks{NumberOfChunks}
   * {CookieName}C{ChunkNumber starting from 1}: {ChunkValue}
   * ...
   * {CookieName}C{ChunkNumber starting from 1}: {ChunkValue}
   */
  private static handleChunkedCookie(name: string, value: string | null): string | null {
    if (!value) {
      return value;
    }

    const regex = /^chunks-(?<numberOfChunks>\d+)$/;
    const isChunkedCookie = regex.test(value);
    if (!isChunkedCookie) {
      return value;
    }

    const match = value.match(regex);
    if (!match) {
      return null;
    }

    const numberOfChunks = +match.groups!.numberOfChunks;
    const chunkedValues = Array.from({ length: numberOfChunks })
      .map((x, i) => this.getCookieValue(`${name}C${i + 1}`))
      .filter((x) => !!x) as string[];
    if (numberOfChunks !== chunkedValues.length) {
      throw new Error(
        `Expected ${numberOfChunks} chunked cookies with names '${name}C{ChunkNumber}'`,
      );
    }

    const restoredValue = chunkedValues.reduce((accum, curr) => accum + curr, "");
    return restoredValue;
  }

  /** Returns all cookies that can be accessed by JS */
  static getAll(documentCookie?: string): Record<string, string | null> {
    return this.getAllCookies(documentCookie);
  }

  static get(name: string, documentCookie?: string): string | null {
    const value = this.getCookieValue(name, documentCookie);
    return this.handleChunkedCookie(name, value);
  }

  static set(
    name: string,
    value: string | null | undefined,
    options: { domain?: string; path?: string; expires?: Date } = {},
  ): void {
    options.path = options?.path || "/";

    const path = "; path=" + options.path;
    let domain = "";
    let expires = "";
    if (options?.domain) {
      domain = "; domain=" + domain;
    }
    if (options?.expires) {
      expires = "; expires=" + options.expires.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + domain + path + expires;
  }

  static remove(name: string, options: { domain?: string; path?: string } = {}) {
    if (this.get(name)) {
      const tenYearsAgo = new Date(Date.now() - 10 * 12 * 30 * 24 * 60 * 60 * 1000);
      this.set(name, "", {
        ...options,
        expires: tenYearsAgo,
      });
    }
  }
}
