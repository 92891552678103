import { useParams } from "react-router-dom";

import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ChatType } from "@/core/api/generated";

import EmbeddedChat from "./components/EmbeddedChat";

interface RouteParams {
  chatId?: string;
}

export interface ChatViewQueryParams extends GeneralQueryParams {
  chatType?: ChatType;
  chatScopeStr?: string | null;
}

export default function ChatViewPage() {
  const { chatId } = useParams<RouteParams>();
  const { chatType, chatScopeStr } = useQueryParams<ChatViewQueryParams>();

  return (
    <ViewLayout
      displayProps={{
        viewVariant: ViewLayoutVariant.Page,
      }}
    >
      <EmbeddedChat chatId={chatId} chatType={chatType} chatScopeStr={chatScopeStr} />
    </ViewLayout>
  );
}
