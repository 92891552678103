import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Formik, getIn } from "formik";
import moment, { Moment } from "moment";
import { useState } from "react";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataGrantCreateDto,
  DataGrantDto,
  DataGrantEntityType,
  DataGrantPermission,
  DataGrantUpdateDto,
  EntityType,
} from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumMultiselect from "../../Enum/ApiEnumMultiselect";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppLink from "../../Link/AppLink";
import GeneralStrictEntityRelationInput from "../General/Input/GeneralStrictEntityRelationInput";
import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import TenantRequestsAutocompleteOrCreate from "../TenantRequest/TenantRequestsAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  consumerTenantId?: DataGrantCreateDto["consumerTenantId"];
  grantEntityType?: DataGrantCreateDto["grantEntityType"];
  entityId?: DataGrantCreateDto["entityId"];
  tenantRequestsMeta?: DataGrantCreateDto["tenantRequestsMeta"];
};

export interface DataGrantCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<DataGrantDto, DefaultValues> {
  dataGrantId?: string;
}

export type DataGrantCreateUpdateProps = DataGrantCreateUpdateOwnProps;

export default function DataGrantCreateUpdate({
  dataGrantId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: DataGrantCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !dataGrantId;
  const isEdit = !!dataGrantId;

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const dataGrantRequest = useApiRequest(
    apiClient.dataGrantsApi.apiV1DataGrantsDataGrantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      dataGrantId: dataGrantId!,
    },
    {
      skip: !dataGrantId,
    },
  );
  const dataGrant = dataGrantRequest?.data;

  // useBreadcrumbReplacements({
  //   waitTimeout: 10_000,
  //   idBreadcrumb: dataGrant && {
  //     idValue: dataGrant.id!,
  //     newTitle: dataGrant.id || "",
  //   },
  // });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.DataGrant}
      entityId={dataGrantId}
      entity={dataGrant}
      entityRequest={dataGrantRequest}
    >
      <Stack spacing={1}>
        <Formik<DataGrantCreateDto & DataGrantUpdateDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            consumerTenantId:
              dataGrant?.consumerTenant?.id || defaultValues?.consumerTenantId || undefined,
            grantEntityType: dataGrant?.grantEntityType || undefined,
            entityId: dataGrant?.entityId || undefined,
            permissions: dataGrant?.permissions || [DataGrantPermission.Read],
            expiresAt: dataGrant?.expiresAt || undefined,
            tenantRequestsMeta:
              dataGrant?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,

            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // vehicleId: Yup.string().required("Vehicle is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              if (isCreate) {
                const response = await apiClient.dataGrantsApi.apiV1DataGrantsPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  dataGrantCreateDto: {
                    ...values,
                  },
                });
                enqueueSnackbar("Data grant created.", {
                  variant: "success",
                });
                onCreate && onCreate(response.data);
                onSave && onSave(response.data);
              } else {
                const response = await apiClient.dataGrantsApi.apiV1DataGrantsDataGrantIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  dataGrantId,
                  dataGrantUpdateDto: {
                    ...values,
                  },
                });
                enqueueSnackbar("Data grant updated.", { variant: "success" });
                onUpdate && onUpdate(response.data);
                onSave && onSave(response.data);
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Box>
                    <FormControl margin='dense' fullWidth>
                      <TenantAutocomplete
                        entityId={values.consumerTenantId}
                        searchFilters={{
                          isInMyConnections: true,
                        }}
                        required
                        disabled={isEdit}
                        label='Consumer company'
                        onChange={(newValue) => setFieldValue("consumerTenantId", newValue?.id)}
                        textFieldProps={{
                          error: Boolean(errors.consumerTenantId),
                          helperText: errors.consumerTenantId,
                        }}
                      />
                      <FormHelperText>
                        Company from your{" "}
                        <AppLink to={ROUTE_PATH.TENANT_CONNECTIONS_INDEX}>connections</AppLink> to
                        who you are issuing data access.
                      </FormHelperText>
                    </FormControl>

                    {/* Entity */}
                    <FormControl margin='dense' fullWidth required>
                      <GeneralStrictEntityRelationInput
                        onlyEntityTypes={Object.values(DataGrantEntityType)}
                        value={
                          values.grantEntityType || values.entityId
                            ? { entityType: values.grantEntityType, entityId: values.entityId }
                            : undefined
                        }
                        disabled={isEdit}
                        entityTypeAutocompleteProps={{
                          required: true,
                          textFieldProps: {
                            error: Boolean(errors?.grantEntityType),
                            helperText: ValidationHelper.getFormikErrorsAsString(
                              errors?.grantEntityType,
                            ),
                          },
                        }}
                        entityAutocompleteProps={{
                          label: "Entity",
                          required: true,
                          textFieldProps: {
                            error: Boolean(errors?.entityId),
                            helperText: ValidationHelper.getFormikErrorsAsString(errors?.entityId),
                          },
                          searchFilters: {},
                        }}
                        onChange={async (newValue) => {
                          setFieldValue(`grantEntityType`, newValue?.entityType);
                          setFieldValue(`entityId`, newValue?.entityId);
                        }}
                      />
                    </FormControl>

                    <FormControl fullWidth margin='dense'>
                      <ApiEnumMultiselect
                        type='DataGrantPermission'
                        values={values.permissions}
                        disabledValues={[DataGrantPermission.Read]}
                        required
                        fullWidth
                        label='Permissions'
                        error={Boolean(getIn(errors, "permissions"))}
                        errorHelperText={ValidationHelper.getErrorsAsString(
                          getIn(errors, "permissions"),
                        )}
                        onChange={(newValues) => {
                          setFieldValue("permissions", newValues);
                        }}
                      />
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <MobileDateTimePicker
                        ampm={false}
                        label='Expires at'
                        value={(values.expiresAt && moment(values.expiresAt)) || null}
                        format={DATETIME_FORMATS.DISPLAY_DATETIME}
                        onChange={(newValue: Moment | null) => {
                          setFieldValue("expiresAt", newValue?.format() || null);
                        }}
                        slots={{ textField: (params) => <TextField {...params} fullWidth /> }}
                      />
                      {errors.expiresAt && (
                        <FormHelperText error>{errors.expiresAt}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>

                  {/* Other */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: (
                        <Typography component='span' variant='subtitle1'>
                          Other
                        </Typography>
                      ),
                    }}
                  >
                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(errors.tenantRequestsMeta)}
                    >
                      <TenantRequestsAutocompleteOrCreate
                        autocompleteProps={{
                          withCreate: true,
                          entityIds: values.tenantRequestsMeta?.tenantRequestIds,
                          entities: undefined,
                          onChange: (newValues) =>
                            setFieldValue(
                              "tenantRequestsMeta.tenantRequestIds",
                              newValues?.map((x) => x.id!) || [],
                            ),
                        }}
                        onCreate={(newValue) => {
                          setFieldValue("tenantRequestsMeta.tenantRequestIds", [
                            ...(values.tenantRequestsMeta?.tenantRequestIds || []),
                            newValue.id,
                          ]);
                        }}
                      />
                      <FormHelperText>
                        {errors.tenantRequestsMeta &&
                          ValidationHelper.getFormikErrorsAsString(errors.tenantRequestsMeta)}
                      </FormHelperText>
                    </FormControl>
                  </FoldableBlock>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <FormActions>
                    <LoadingButton
                      color='primary'
                      loading={isSubmitting}
                      disabled={isUploadingFiles}
                      fullWidth
                      type='submit'
                      variant='contained'
                    >
                      Save
                    </LoadingButton>
                  </FormActions>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </BaseEntityCreateUpdate>
  );
}
