import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { DepartmentDto, DepartmentReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getDepartmentTitle } from "./DepartmentAutocomplete";

export interface DepartmentInlineProps extends BaseEntityInlineInheritableProps {
  entity?: DepartmentDto | DepartmentReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function DepartmentInline({
  entity,
  entityId,
  ...otherProps
}: DepartmentInlineProps) {
  const request = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  const refRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdReferenceGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity || (request.isLoading && !request.isEnded),
    },
  );
  entity = request?.data || entity || refRequest?.data;

  const full = entity as DepartmentDto;
  const ref = entity as DepartmentReferenceDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request.data ? request : refRequest}
      icon={<AppIcon of='department' inText />}
      content={<>{full && getDepartmentTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Department"}
    />
  );
}
