import { useEffect, useRef } from "react";

import GlobalRolesSelector, { GlobalRolesSelectorProps } from "./GlobalRolesSelector";
import TenantRolesSelector, { TenantRolesSelectorProps } from "./TenantRolesSelector";

type ScopeType = "global" | "tenant";

type Props = (GlobalRolesSelectorProps & TenantRolesSelectorProps) & {
  scopeType: ScopeType;
  onScopeTypeChange?: (params?: {
    oldValue?: ScopeType | null;
    newValue?: ScopeType | null;
  }) => void;
};

export default function GlobalOrTenantRolesSelector({
  scopeType,
  onScopeTypeChange,
  ...otherProps
}: Props) {
  const prevScopeType = useRef<ScopeType | undefined>(scopeType);

  useEffect(() => {
    if (scopeType !== prevScopeType.current) {
      onScopeTypeChange &&
        onScopeTypeChange({ oldValue: prevScopeType.current, newValue: scopeType });
      prevScopeType.current = scopeType;
    }
  }, [scopeType]);

  if (scopeType === "global") {
    return <GlobalRolesSelector {...otherProps} />;
  }
  if (scopeType === "tenant") {
    return <TenantRolesSelector {...otherProps} />;
  }
  return null;
}
