import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ContractReminderSettingsDisplay, {
  ContractReminderSettingsDisplayProps,
} from "./ContractReminderSettingsDisplay";

export interface OwnProps {
  reminderSettingsDisplayProps: ContractReminderSettingsDisplayProps;
}

type Props = OwnProps & DialogProps;

export default function ContractReminderSettingsModal({
  reminderSettingsDisplayProps,
  ...dialogProps
}: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Contract reminders
      </AppModalTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <ContractReminderSettingsDisplay {...reminderSettingsDisplayProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
