import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { apiClient } from "@/core/api/ApiClient";
import { OperationCountersDto } from "@/core/api/generated";

import { AppDispatchType, AppThunk } from "..";

interface OperationsState {
  loading: {
    getCounters?: boolean;
  };
  counters?: OperationCountersDto;
}

const initialState: OperationsState = {
  loading: {},
  counters: undefined,
};

const slice = createSlice({
  name: "operations",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<OperationsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _countersFetched: (state, action: PayloadAction<OperationCountersDto>) => {
      state.counters = action.payload;
    },
  },
});

export const { setLoading, _countersFetched } = slice.actions;

export const operationsReducer = slice.reducer;

const _getCounters = async (dispatch: AppDispatchType): Promise<OperationCountersDto> => {
  dispatch(
    setLoading({
      getCounters: true,
    }),
  );
  try {
    const response = await apiClient.operationsApi.apiV1OperationsCountersGet({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
    await dispatch(_countersFetched(response.data));
    return response.data;
  } finally {
    dispatch(
      setLoading({
        getCounters: false,
      }),
    );
  }
};
const _getCountersDebounce = _.debounce(_getCounters, 1000, {
  leading: false,
  trailing: true,
});
const _getCountersThrottle = _.throttle(_getCounters, 1000, {
  leading: true,
  trailing: false,
});

export const getCounters = (): AppThunk<Promise<OperationCountersDto>> => async (dispatch) => {
  return await _getCounters(dispatch);
};
export const getCountersDebounce =
  (): AppThunk<Promise<OperationCountersDto>> => async (dispatch) => {
    return (await _getCountersDebounce(dispatch))!;
  };
export const getCountersThrottle =
  (): AppThunk<Promise<OperationCountersDto>> => async (dispatch) => {
    return (await _getCountersThrottle(dispatch))!;
  };
