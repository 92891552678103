import moment, { Moment } from "moment";

import {
  FilterDefinitionDto,
  FilterOperator,
  FilterType,
  FilterValueType,
} from "@/core/api/generated";

import { FilterDefinition } from "../filters/filterDefinition";
import { FilterFieldOperatorSpec } from "../filters/filterFieldOperatorSpec";
import { ApiEnumName, ApiEnumValue, enumService } from "../services/enum";
import { FilterValue, FilterValueDate } from "../ts/filters";
import { TypeHelper } from "./type";

export class FilterHelper {
  public static getOperatorDisplayName(operator: FilterOperator): string {
    return (
      (operator === FilterOperator.Equal ? "Equals" : undefined) ??
      (operator === FilterOperator.NotEqual ? "Does not equal" : undefined) ??
      (operator === FilterOperator.Contain ? "Contains" : undefined) ??
      (operator === FilterOperator.NotContain ? "Does not contain" : undefined) ??
      (operator === FilterOperator.LessThan ? "Less than" : undefined) ??
      (operator === FilterOperator.LessThanOrEqual ? "Less than or equals" : undefined) ??
      (operator === FilterOperator.GreaterThan ? "Greater than" : undefined) ??
      (operator === FilterOperator.GreaterThanOrEqual ? "Greater than or equals" : undefined) ??
      (operator === FilterOperator.AnyOf ? "Any of" : undefined) ??
      (operator === FilterOperator.NoneOf ? "None of" : undefined) ??
      (operator === FilterOperator.Empty ? "Is empty" : undefined) ??
      (operator === FilterOperator.NotEmpty ? "Is not empty" : undefined) ??
      enumService.getEnumValueName("FilterOperator", operator)
    );
  }

  /** Converts filter value to valid format according to the value type. */
  public static formatValue(
    operatorSpec: FilterFieldOperatorSpec,
    value: FilterValue,
  ): FilterValue {
    let newValue: FilterValue = undefined;
    switch (operatorSpec.valueType) {
      case FilterValueType.None:
        newValue = undefined;
        break;
      case FilterValueType.Arbitrary:
        newValue = value;
        break;
      case FilterValueType.String:
        newValue = this.valueAsString(value);
        break;
      case FilterValueType.Number:
        newValue = this.valueAsNumber(value);
        break;
      case FilterValueType.Boolean:
        newValue = this.valueAsBoolean(value);
        break;
      case FilterValueType.Date:
        newValue = this.valueAsDate(value);
        break;
      case FilterValueType.Enum:
        newValue = this.valueAsEnum(value);
        break;
      case FilterValueType.Id:
        newValue = this.valueAsId(value);
        break;
      case FilterValueType.ArrayOfString:
        newValue = this.valueAsArrayOfString(value);
        break;
      case FilterValueType.ArrayOfEnum:
        newValue = this.valueAsArrayOfEnum(value);
        break;
      case FilterValueType.ArrayOfId:
        newValue = this.valueAsArrayOfId(value);
        break;
      default:
        throw new Error(
          `Filter value '${value}' is invalid for value type '${operatorSpec.valueType}' specified in the spec.`,
        );
    }
    // console.log("formatValue.", { value, newValue });

    return newValue;
  }

  public static valueAsString(value: FilterValue): string | undefined {
    return TypeHelper.isPrimitive(value) ? value?.toString() : undefined;
  }

  public static valueAsNumber(value: FilterValue): number | undefined {
    return TypeHelper.isPrimitive(value)
      ? TypeHelper.isNumber(value)
        ? value
        : TypeHelper.isString(value)
          ? Number(value)
          : undefined
      : undefined;
  }

  public static valueAsBoolean(value: FilterValue): boolean | undefined {
    return TypeHelper.isPrimitive(value)
      ? TypeHelper.isBoolean(value)
        ? value
        : TypeHelper.isString(value)
          ? TypeHelper.parseBoolean(value)
          : undefined
      : undefined;
  }

  public static valueAsDate(value: FilterValue): FilterValueDate | undefined {
    return TypeHelper.isPrimitive(value)
      ? TypeHelper.isString(value)
        ? value
        : undefined
      : TypeHelper.isMoment(value)
        ? value
        : TypeHelper.isDate(value)
          ? value
          : undefined;
  }

  public static valueAsDateAsMoment(value: FilterValue): Moment | undefined {
    return TypeHelper.isPrimitive(value)
      ? TypeHelper.isString(value)
        ? moment(value)
        : undefined
      : TypeHelper.isMoment(value)
        ? value
        : TypeHelper.isDate(value)
          ? moment(value)
          : undefined;
  }

  public static valueAsDateAsString(value: FilterValue): string | undefined {
    return TypeHelper.isPrimitive(value)
      ? TypeHelper.isString(value)
        ? value
        : undefined
      : TypeHelper.isMoment(value)
        ? value.format()
        : TypeHelper.isDate(value)
          ? moment(value).format()
          : undefined;
  }

  public static valueAsId(value: FilterValue): string | undefined {
    return this.valueAsString(value);
  }

  public static valueAsEnum(value: FilterValue): string | undefined {
    return this.valueAsString(value);
  }

  public static valueAsEnumTyped<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    value: FilterValue,
  ): ApiEnumValue<TEnumName> | undefined {
    const str = this.valueAsString(value);
    if (!str) {
      return undefined;
    }
    const tempEnumValue = str as ApiEnumValue<TEnumName>;
    const enumValues = enumService.getEnumValues(enumName);
    if (!enumValues.includes(tempEnumValue)) {
      return undefined;
    }
    return tempEnumValue;
  }

  public static valueAsArrayOfString(value: FilterValue): string[] | undefined {
    return TypeHelper.isArray(value)
      ? value.map((x) => this.valueAsString(x)!).filter((x) => !TypeHelper.isEmpty(x))
      : undefined;
  }

  public static valueAsArrayOfEnum(value: FilterValue): string[] | undefined {
    return this.valueAsArrayOfString(value);
  }

  public static valueAsArrayOfEnumTyped<TEnumName extends ApiEnumName>(
    enumName: TEnumName,
    value: FilterValue,
  ): Array<ApiEnumValue<TEnumName>> | undefined {
    return TypeHelper.isArray(value)
      ? value.map((x) => this.valueAsEnumTyped(enumName, x)!).filter((x) => !TypeHelper.isEmpty(x))
      : undefined;
  }

  public static valueAsArrayOfId(value: FilterValue): string[] | undefined {
    return this.valueAsArrayOfString(value);
  }

  //#region Mapping

  public static mapFilterDefinitionToFilterDefinitionDto(
    source: FilterDefinition | undefined,
    options?: { filterType?: FilterType },
  ): FilterDefinitionDto | undefined {
    source?.cleanup();
    if (!source || !source.isValid) {
      return undefined;
    }

    source = source.clone(); // clone to avoid modifying objects in use
    source.formatBeforeJsonSerialization();

    return {
      items: source.items
        .filter((x) => (options?.filterType ? x.type === options.filterType : true))
        .map((x) => ({
          type: x.type,
          field: x.field,
          operator: x.operator,
          valueType: x.valueType,
          value: x.value,
        })),
    };
  }

  public static serializeFilterDefinitionIntoString(
    source: FilterDefinition | undefined,
    options?: { filterType?: FilterType },
  ): string | undefined {
    source?.cleanup();
    if (!source || !source.isValid) {
      return undefined;
    }

    const dto = this.mapFilterDefinitionToFilterDefinitionDto(source, options);
    const json = JSON.stringify(dto);
    return json;
  }

  //#endregion
}
