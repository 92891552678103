import { SxProps, Theme } from "@mui/material";

import { DATETIME_FORMATS } from "@/common/constants/common";

import EntityChangedByInfoCard, { Entity } from "./EntityChangedByInfoCard";

export interface EntityCreatedByInfoDisplayProps {
  entity: Entity | null | undefined;
  dateFormat?: string | null | undefined;
  sx?: SxProps<Theme>;
}

/** Shows who created the entity. */
export default function EntityCreatedByInfoDisplay({
  entity,
  dateFormat = DATETIME_FORMATS.DISPLAY_DATETIME,
  sx,
}: EntityCreatedByInfoDisplayProps) {
  return (
    <EntityChangedByInfoCard
      type='create'
      dateFormat={dateFormat}
      datetime={entity?.createdAt}
      userId={entity?.createdBy}
      sx={sx}
    />
  );
}
