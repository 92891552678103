import { Chip } from "@mui/material";

import { RoleDto, RoleReferenceDto } from "@/core/api/generated";

interface Props {
  role: RoleDto | RoleReferenceDto | null | undefined;
}

export default function InlineRoleDisplay({ role }: Props) {
  return (
    (role && (
      <Chip size='small' color='default' variant={"outlined"} label={role.name} sx={{ ml: 1 }} />
    )) ||
    null
  );
}
