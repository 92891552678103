import { Box, SxProps, Theme } from "@mui/material";
import { forwardRef } from "react";

import { GeneralPriceDto, GeneralPriceInputDto } from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import AppIcon from "../../../Icons/AppIcon";

interface GeneralPriceDisplayProps {
  price?: GeneralPriceDto | GeneralPriceInputDto | null;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipText?: string;
  sx?: SxProps<Theme>;
}

/** Displays general price value along with currency info. */
export default forwardRef<HTMLSpanElement, GeneralPriceDisplayProps>(function GeneralPriceDisplay(
  {
    price,
    withIcon = false,
    withTooltip = false,
    tooltipText = "Price",
    sx,
  }: GeneralPriceDisplayProps,
  ref,
) {
  if (!price) {
    return null;
  }

  return (
    <AppTooltip
      enabled={withTooltip && !!tooltipText}
      title={tooltipText || ""}
      wrapperProps={{
        component: "span",
      }}
    >
      <Box ref={ref} component='span' sx={sx}>
        {withIcon && <AppIcon of='price' inText />}
        <CurrencyValue value={price.price} currencyCode={price.currency?.code} />
      </Box>
    </AppTooltip>
  );
});
