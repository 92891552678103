import { useParams } from "react-router-dom";

import GeneralHistoryView from "@/common/components/Entity/GeneralHistory/View/GeneralHistoryView";

export default function GeneralHistoryViewPage() {
  const { generalHistoryId } = useParams<{ generalHistoryId?: string }>();

  return (
    <>
      <GeneralHistoryView generalHistoryId={generalHistoryId} generalHistory={undefined} />
    </>
  );
}
