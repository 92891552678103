import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

export interface PageFooterProps {
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export default function PageFooter({ children, sx }: PageFooterProps) {
  if (!children) {
    return null;
  }
  return <Box sx={{ px: 3, ...sx }}>{children}</Box>;
}
