import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

import Datetime from "@/common/components/Datetime/Datetime";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import {
  ContractCustomerCommunicationEntryDto,
  ContractDto,
  CustomerDto,
} from "@/core/api/generated";

import CustomerContactCard from "../Customer/CustomerContactCard";

interface Props {
  contract: ContractDto;
  customer: CustomerDto;
  entry: ContractCustomerCommunicationEntryDto;
  sx?: SxProps<Theme>;
}

export default function ContractCustomerCommunicationEntry({
  contract,
  customer,
  entry,
  sx,
}: Props) {
  const contact = useMemo(
    () => customer.contacts!.find((x) => x.id === entry.customerContactId),
    [customer, entry],
  );

  return (
    <Card variant='outlined' sx={sx}>
      <CardContent sx={{ py: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xxs={12} md={6}>
            <Stack spacing={1}>
              <FieldValue label='Subject types'>
                <Stack direction='row' spacing={1} flexWrap='wrap'>
                  {entry.subjectTypes?.map((subjectType, i) => (
                    <InlineApiEnumValue
                      key={i}
                      type='ContractCommunicationSubjectType'
                      value={subjectType}
                    />
                  ))}
                </Stack>
              </FieldValue>
              <FieldValue label='Subject' isEmpty={!entry.subject}>
                {entry.subject}
              </FieldValue>
              <FieldValue label='Contact channel'>
                <InlineApiEnumValue type='ContactChannel' value={entry.contactChannel} />
              </FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12} md={6}>
            <Stack spacing={1}>
              <FieldValue label='Contacted at'>
                <Datetime datetime={entry.contactedAt} withDurationFromNow />
              </FieldValue>
              <FieldValue label='Handled by'>
                <GeneralByWhoDisplay who={entry.handledBy} sx={{ display: "inline-flex" }} />
              </FieldValue>
              <FieldValue label='Notes' isEmpty={!entry.notes}>
                {entry.notes}
              </FieldValue>
              <FieldValue label='Is auto'>
                <BooleanValue value={entry.isAuto} />
              </FieldValue>
            </Stack>
          </Grid>
          <Grid item xxs={12}>
            <CustomerContactCard contact={contact} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
