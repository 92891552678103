import { Dialog, DialogContent, DialogProps } from "@mui/material";
import { Box } from "@mui/system";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";

import ContractReallocateVehicle, {
  ContractReallocateVehicleProps,
} from "./ContractReallocateVehicle";

export interface OwnProps {
  updateProps: ContractReallocateVehicleProps;
}

type Props = OwnProps & DialogProps;

export default function ContractReallocateVehicleModal({ updateProps, ...dialogProps }: Props) {
  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Reallocate vehicle for the contract {updateProps.contract.localNumber}
      </AppModalTitle>
      <DialogContent>
        <Box>
          <ContractReallocateVehicle {...updateProps} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
