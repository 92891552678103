import { LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import WebhookView from "@/common/components/Entity/Webhook/View/WebhookView";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export default function WebhookViewPage() {
  const { webhookId } = useParams<{ webhookId?: string }>();

  const webhookRequest = useApiRequest(
    apiClient.webhooksApi.apiV1WebhooksWebhookIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      webhookId: webhookId!,
    },
    {
      skip: !webhookId,
    },
  );
  const webhook = webhookRequest?.data;

  if (webhookRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!webhook) {
    return <EntityNotFoundAlert title='Webhook not found.' />;
  }

  return (
    <>
      <WebhookView
        webhookId={webhookId}
        webhook={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
