import { Box, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import { PriceHelper } from "@/common/helpers/price";
import {
  GeneralPriceSummaryDisplayProps,
  GeneralPriceSummaryDtoDetailedLocal,
} from "@/common/ts/lineItems";

import GeneralInsuranceDisplay from "../Entity/General/GeneralInsurance/GeneralInsuranceDisplay";
import CurrencyValue from "../Form/Display/CurrencyValue";
import PercentValue from "../Form/Display/PercentValue";

const defaultDisplayProps: GeneralPriceSummaryDisplayProps = {
  subTotal: true,
  subTotalIncDiscount: false,
  discount: true,
  tax: true,
  insurance: false,
  total: true,
  calcExplanation: true,
};

export interface TotalPriceExplanationInlineProps {
  summary: GeneralPriceSummaryDtoDetailedLocal;
  displayProps?: Partial<typeof defaultDisplayProps>;
  sx?: SxProps<Theme>;
}

/** Displays line items summary explanation:
 *  Normal: total = subTotal + tax
 *  Reversed: subTotal + tax = total
 * */
export default function GeneralPriceSummaryInline({
  summary,
  displayProps,
  sx,
}: TotalPriceExplanationInlineProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const summaryComputed = useMemo(
    () => PriceHelper.calcPriceSummaryFromDetailedSummary({ summary, calcType: null }),
    [summary],
  );

  const discountExplanation = useMemo(
    () =>
      summaryComputed?.discount
        ? PriceHelper.getDiscountExplanation({
            forValue: {
              subTotal: summaryComputed.subTotal || 0,
            },
            discount: summaryComputed.discount,
            currency: summaryComputed.currency,
          })
        : undefined,
    [summaryComputed],
  );

  const taxExplanation = useMemo(
    () =>
      summaryComputed?.tax
        ? PriceHelper.getTaxExplanation({
            forValue: {
              subTotal: summaryComputed.subTotal || 0,
            },
            tax: summaryComputed.tax,
            currency: summaryComputed.currency,
          })
        : undefined,
    [summaryComputed],
  );

  return (
    <Box component='span' sx={sx}>
      {displayProps?.total && (
        <>
          {" "}
          <CurrencyValue value={summaryComputed.total} currency={summaryComputed.currency} /> ={" "}
        </>
      )}
      {displayProps?.subTotal && (
        <CurrencyValue value={summaryComputed.subTotal} currency={summaryComputed.currency} />
      )}
      {displayProps?.discount && summary.discount && (
        <>
          {" "}
          - <PercentValue value={discountExplanation?.discountPercent} /> /{" "}
          <CurrencyValue
            value={discountExplanation?.discountValue}
            currency={discountExplanation?.currency}
          />{" "}
          <Box component='span' sx={{ color: "text.secondary" }}>
            (discount)
          </Box>
        </>
      )}
      {displayProps?.tax && summary.tax && (
        <>
          {" "}
          + <PercentValue value={taxExplanation?.taxPercent} /> /{" "}
          <CurrencyValue value={taxExplanation?.taxValue} currency={taxExplanation?.currency} />{" "}
          <Box component='span' sx={{ color: "text.secondary" }}>
            (tax)
          </Box>
        </>
      )}
      {displayProps?.insurance && summary.insurance && (
        <>
          {" "}
          +{" "}
          <GeneralInsuranceDisplay
            insurance={summary.insurance}
            currency={summaryComputed.currency}
          />{" "}
          <Box component='span' sx={{ color: "text.secondary" }}>
            (insurance)
          </Box>
        </>
      )}
    </Box>
  );
}
