import { useParams } from "react-router";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import IntegrationApiClientView from "@/common/components/Entity/IntegrationApiClient/View/IntegrationApiClientView";

export default function IntegrationApiClientViewPage() {
  const { integrationApiClientId } = useParams<{ integrationApiClientId?: string }>();

  return (
    <>
      <IntegrationApiClientView
        integrationApiClientId={integrationApiClientId}
        integrationApiClient={undefined}
        displayProps={{
          breadcrumbs: true,
          header: true,
          actions: true,
        }}
      />
    </>
  );
}
