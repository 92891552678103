import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AdminCreateDamageTypeDto,
  AdminUpdateDamageTypeDto,
  DamageTypeCategory,
} from "@/core/api/generated";

import ReferenceDataSyncStopAlert from "../ReferenceDataSyncStopAlert";

export default function DamageTypeCreateUpdatePage() {
  const { damageTypeId } = useParams<{ damageTypeId?: string }>();
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();

  const isCreate = !damageTypeId;
  const isEdit = !!damageTypeId;

  const damageTypeRequest = useApiRequest(
    apiClient.adminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdGet,
    {
      damageTypeId: damageTypeId!,
    },
    {
      skip: !damageTypeId,
    },
  );
  const damageType = damageTypeRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: damageType && {
      idValue: damageType.id!,
      newTitle: damageType.name || damageType.globalNumber || "",
    },
  });

  return (
    <Formik<AdminCreateDamageTypeDto & AdminUpdateDamageTypeDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        category: (damageType?.category || "") as DamageTypeCategory,
        name: damageType?.name || "",
        description: damageType?.description || "",
        isCategoryRoot: damageType?.isCategoryRoot || false,
        isEnabled: damageType?.isEnabled ?? true,
        isEnabledForDamageDetection: damageType?.isEnabledForDamageDetection ?? false,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // category: Yup.string().required("Category is required"),
        // name: Yup.string().required("Name is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          if (isCreate) {
            await apiClient.adminDamageTypesApi.apiV1AdminReferenceDataDamageTypesPost({
              adminCreateDamageTypeDto: {
                ..._.omit(values, "submit"),
                category: values.category || undefined,
              },
            });
            enqueueSnackbar("Damage type created.", { variant: "success" });
          } else {
            await apiClient.adminDamageTypesApi.apiV1AdminReferenceDataDamageTypesDamageTypeIdPut({
              damageTypeId,
              adminUpdateDamageTypeDto: {
                ..._.omit(values, "submit"),
                category: values.category || undefined,
              },
            });
            enqueueSnackbar("Damage type updated.", { variant: "success" });
          }

          if (mounted.current) {
            setStatus({ success: true });
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout
            header={
              <SimpleViewPageHeader
                title={isCreate ? "Create new damage type" : "Edit damage type"}
              />
            }
          >
            <form noValidate onSubmit={handleSubmit}>
              {damageType && isEdit && (
                <ReferenceDataSyncStopAlert
                  sx={{ mb: 2 }}
                  isBuiltIn={damageType.isBuiltIn ?? false}
                  car2DbId={undefined}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                  overflow: "auto",
                }}
              >
                <FormControl
                  sx={{ minWidth: 200 }}
                  margin='normal'
                  error={Boolean(touched.category && errors.category)}
                >
                  <InputLabel>Category</InputLabel>
                  <ApiEnumSelect
                    type='DamageTypeCategory'
                    value={values.category}
                    onChange={(newValue) => setFieldValue("category", newValue)}
                    selectProps={{
                      label: "Category",
                    }}
                  />
                  <FormHelperText>{touched.category && errors.category}</FormHelperText>
                </FormControl>

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isCategoryRoot'
                        checked={values.isCategoryRoot}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label={
                      <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                        <span>Category root?</span>
                        <Tooltip
                          title={`If true - damage type represents damage category, meaning it's the most basic/generic in this category. 
              For instance, root damage type 'Scratch' represents 'Scratch' damage type category.`}
                        >
                          <InfoIcon fontSize='inherit' />
                        </Tooltip>
                      </Stack>
                    }
                  />
                </FormGroup>

                <TextField
                  autoFocus
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <AppTextArea
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  mode='description'
                  margin='normal'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant='outlined'
                />

                <TextField
                  autoFocus
                  rows={2}
                  error={Boolean(touched.sortOrder && errors.sortOrder)}
                  fullWidth
                  helperText={touched.sortOrder && errors.sortOrder}
                  label='SortOrder'
                  margin='normal'
                  name='sortOrder'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputMode='numeric'
                  value={values.sortOrder || 0}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0, max: 10000000 } }}
                />

                <FormControl error={Boolean(errors.isEnabled)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabled'
                        checked={values.isEnabled}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled'
                  />
                  <FormHelperText>
                    Whether this reference data entity is enabled for referencing by other entities.
                    When disabled, the entity is unavailable for paginated/search/list requests but
                    available by id for old entities already referencing it.
                  </FormHelperText>
                  {errors.isEnabled && <FormHelperText error>{errors.isEnabled}</FormHelperText>}
                </FormControl>

                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='isEnabledForDamageDetection'
                        checked={values.isEnabledForDamageDetection}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    }
                    label='Enabled for damage detection'
                  />
                </FormGroup>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                color='primary'
                disabled={!values.name}
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
