import RolesSelector, { RolesSelectorProps } from "@/common/components/Entity/Role/RolesSelector";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

export interface TenantRolesSelectorProps
  extends Omit<RolesSelectorProps, "getRoles" | "roleList" | "roleAssignmentRestrictions"> {
  tenantId: string;
  targetUserId?: string | null;
}

export default function TenantRolesSelector({
  tenantId,
  targetUserId,
  ...otherProps
}: TenantRolesSelectorProps) {
  const roleAssignmentRestrictionsRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountTenantsTenantIdRoleAssignmentsRestrictionsGet,
    {
      tenantId: tenantId,
      targetUserId: targetUserId || undefined,
    },
    {
      deps: [tenantId, targetUserId],
    },
  );
  const roleAssignmentRestrictions = roleAssignmentRestrictionsRequest?.data;

  return (
    <RolesSelector
      getRoles={async () => {
        const response = await apiClient.adminAccountApi.apiV1AdminAccountTenantsTenantIdRolesGet({
          tenantId: tenantId,
        });
        return response.data;
      }}
      roleAssignmentRestrictions={roleAssignmentRestrictions}
      {...otherProps}
    />
  );
}
