import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

import { THEMES } from "../../common/constants/common";
import { AppThunk } from "./../index";

export interface AppSettings {
  theme: THEMES;
}

export interface AppCommonState {
  isAppInitialized: boolean;
  settings: AppSettings;
  isNavOpened: boolean;
}

const initialState: AppCommonState = {
  isAppInitialized: false,
  settings: {
    theme: localStorage.getItem("theme")
      ? JSON.parse(localStorage.getItem("theme") || "")
      : THEMES.LIGHT,
  },
  isNavOpened: !isMobile,
};

export const appCommonSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appInitialized: (state) => {
      state.isAppInitialized = true;
    },
    prerenderConfigurationsStarted: () => {},
    _navEnabled: (state) => {
      state.isNavOpened = true;
    },
    _navDisabled: (state) => {
      state.isNavOpened = false;
    },
    _themeChange: (state, action: PayloadAction<THEMES>) => {
      state.settings.theme = action.payload;
      localStorage.setItem("theme", JSON.stringify(action.payload));
    },
  },
});

export const {
  prerenderConfigurationsStarted,
  appInitialized,
  _navEnabled,
  _navDisabled,
  _themeChange,
} = appCommonSlice.actions;

export const appCommonReducer = appCommonSlice.reducer;

export const toggleNav = (): AppThunk => (dispatch, getState) => {
  const {
    app: { isNavOpened },
  } = getState();
  if (isNavOpened) {
    dispatch(_navDisabled());
  } else {
    dispatch(_navEnabled());
  }
};

export const hideNav = (): AppThunk => (dispatch) => {
  dispatch(_navDisabled());
};
