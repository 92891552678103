import { Badge, Box, Chip, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import CardList from "@/common/components/CardList/CardList";
import AppIcon from "@/common/components/Icons/AppIcon";
import TextLineSkeleton from "@/common/components/Skeleton/TextLineSkeleton";
import { showCountMaxOrDefault } from "@/common/helpers/common";
import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useEffectOnce } from "@/common/hooks/effect/useEffectOnce";
import { useDateRangeRequest } from "@/common/hooks/useDateRangeRequest";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { apiClient } from "@/core/api/ApiClient";
import {
  AllocationStatus,
  AssetSubscriptionDto,
  ContractCountersDto,
  ContractDto,
  ContractFilterType,
  DateRangeInputDto,
} from "@/core/api/generated";

import AssetSubscriptionCard from "./AssetSubscriptionCard";
import ContractCard from "./ContractCard";

enum DashboardDateRangeType {
  Today = "Today",
  ThisWeek = "ThisWeek",
  ThisMonth = "ThisMonth",
  ThisYear = "ThisYear",
  All = "All",
}

type DateRangeCounters = {
  type: DashboardDateRangeType;
  range: DateRangeInputDto;
  counters?: ContractCountersDto;
};

const dateRanges: DateRangeCounters[] = [
  {
    type: DashboardDateRangeType.Today,
    range: {
      from: moment().startOf("day").utc().format(),
      to: moment().endOf("day").utc().format(),
    },
  },
  {
    type: DashboardDateRangeType.ThisWeek,
    range: {
      from: moment().startOf("week").utc().format(),
      to: moment().endOf("week").utc().format(),
    },
  },
  {
    type: DashboardDateRangeType.ThisMonth,
    range: {
      from: moment().startOf("month").utc().format(),
      to: moment().endOf("month").utc().format(),
    },
  },
  {
    type: DashboardDateRangeType.ThisYear,
    range: {
      from: moment().startOf("year").utc().format(),
      to: moment().endOf("year").utc().format(),
    },
  },
  {
    type: DashboardDateRangeType.All,
    range: {
      from: undefined,
      to: undefined,
    },
  },
];

export default function Dashboard() {
  const { from, to, setFrom, setTo } = useDateRangeRequest({
    defaultFrom: moment().startOf("day"),
    defaultTo: moment().endOf("day"),
  });
  const [selectedTab, setSelectedTab] = useState<DashboardDateRangeType>(
    DashboardDateRangeType.Today,
  );
  const [dateRangeCounters, setDteRangeCounters] = useState<DateRangeCounters[] | undefined>(
    undefined,
  );
  const [isReservationsVisible, setIsReservationsVisible] = useState<boolean>(true);
  const [isCheckInsVisible, setIsCheckInsVisible] = useState<boolean>(true);
  const [isCheckOutsVisible, setIsCheckOutsVisible] = useState<boolean>(true);
  const profile = useUserProfile();

  const dateRangeCountersByTypeMap = useMemo(
    () =>
      _.chain(dateRangeCounters)
        .keyBy((x) => x.type)
        .mapValues((x) => x)
        .value() as Record<DashboardDateRangeType, DateRangeCounters>,
    [dateRangeCounters],
  );

  // todo: unified for any entity
  // sort all contracts without check-in/check-out date
  const sortByEmptyField = (contracts: ContractDto[] | null, field: keyof ContractDto) => {
    return contracts?.sort((a, b) => {
      const fieldA = a[field];
      const fieldB = b[field];
      if (fieldA && !fieldB) {
        return -1;
      } else if (!fieldA && fieldB) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const assetSubscriptionRequest = useApiRequest(
    apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionGetPaginatedDto: {
        offset: 0,
        limit: 1000,
        allocationStatuses: [AllocationStatus.NotAllocated, AllocationStatus.Allocating],
        sortDefinition: { sortBy: "checkOutSpotInfo.date,createdAt", sortOrder: "Asc,Asc" },
      },
    },
    {
      deps: [from, to],
    },
  );

  const assetSubscriptions = useMemo(
    () => sortByEmptyField(assetSubscriptionRequest.data?.items || [], "checkOutSpotInfo"),
    [assetSubscriptionRequest.data],
  );

  const contractsCheckInRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractGetPaginatedDto: {
        offset: 0,
        limit: 1000,
        filterType: ContractFilterType.CheckIn,
        sortDefinition: { sortBy: "checkInSpotInfo.date", sortOrder: "Asc" },
        rangeByFilterTypeMap: {
          [ContractFilterType.CheckIn]: {
            from: from?.clone()?.utc()?.format() || undefined,
            to: to?.clone()?.utc()?.format() || undefined,
          },
        },
      },
    },
    {
      deps: [from, to],
    },
  );
  const checkInContracts = useMemo(
    () => sortByEmptyField(contractsCheckInRequest.data?.items || [], "checkInSpotInfo"),
    [contractsCheckInRequest.data],
  );

  const contractsCheckOutRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractGetPaginatedDto: {
        offset: 0,
        limit: 1000,
        filterType: ContractFilterType.CheckOut,
        sortDefinition: { sortBy: "checkOutSpotInfo.date", sortOrder: "Asc" },
        rangeByFilterTypeMap: {
          [ContractFilterType.CheckOut]: {
            from: from?.clone()?.utc()?.format() || undefined,
            to: to?.clone()?.utc()?.format() || undefined,
          },
        },
      },
    },
    {
      deps: [from, to],
    },
  );

  const checkOutContracts = useMemo(
    () => sortByEmptyField(contractsCheckOutRequest.data?.items || [], "checkOutSpotInfo"),
    [contractsCheckOutRequest.data],
  );

  const headerTextContent = useMemo(() => {
    const checkIns = contractsCheckInRequest.data?.pagination?.totalCount || 0;
    const checkouts = contractsCheckOutRequest.data?.pagination?.totalCount || 0;
    const reservations = assetSubscriptionRequest.data?.pagination?.totalCount || 0;
    return (
      <Typography variant='subtitle1' sx={{ fontWeight: 400 }}>
        {`you have `}
        {checkIns ? (
          <>
            <Typography variant='subtitle1' sx={{ display: "inline" }}>
              {checkIns} {TextHelper.pluralize("check-in", checkIns)}
            </Typography>
            {`, `}
          </>
        ) : (
          <></>
        )}
        {checkouts ? (
          <Typography variant='subtitle1' sx={{ display: "inline" }}>
            {checkouts} {TextHelper.pluralize("check-out", checkouts)}
          </Typography>
        ) : (
          <></>
        )}
        {!checkIns && !checkouts && !reservations ? <> nothing</> : <></>}
        {` planned ${
          (selectedTab === DashboardDateRangeType.Today && "today") ||
          (selectedTab === DashboardDateRangeType.ThisWeek && "this week") ||
          (selectedTab === DashboardDateRangeType.ThisMonth && "this month") ||
          (selectedTab === DashboardDateRangeType.ThisYear && "this year") ||
          (selectedTab === DashboardDateRangeType.All && "total")
        }`}
        {!checkIns && !checkouts && !reservations ? (
          <></>
        ) : (
          <>
            {` and `}
            <Typography variant='subtitle1' sx={{ display: "inline" }}>
              {reservations} {TextHelper.pluralize("car", reservations)}
            </Typography>
            {` needs to be `}
            <Typography variant='subtitle1' sx={{ display: "inline" }}>
              allocated
            </Typography>
            {` for the new subscriptions`}
          </>
        )}
      </Typography>
    );
  }, [contractsCheckInRequest.data, contractsCheckOutRequest.data, assetSubscriptionRequest.data]);
  const dateTextContent = useMemo(() => moment().format("MMMM DD, YYYY"), []);

  const getDateRangeCounters = useCallback(async () => {
    const results = await Promise.all(
      dateRanges.map(async (range) => {
        const response = await apiClient.contractsApi.apiV1ContractsCountersGetPost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          contractGetCountersDto: {
            rangeByFilterTypeMap: {
              [ContractFilterType.CheckOut]: range.range,
              [ContractFilterType.CheckIn]: range.range,
            },
          },
        });
        return {
          ...range,
          counters: response.data,
        };
      }),
    );
    setDteRangeCounters(results);
  }, [dateRanges]);

  useEffectOnce(() => {
    getDateRangeCounters();
  }, []);

  const getDateRangeCounterLabel = useCallback(
    (rangeType: DashboardDateRangeType) => {
      const counters = dateRangeCountersByTypeMap[rangeType]?.counters;
      return (counters?.checkOutCount ?? 0) + (counters?.checkInCount ?? 0);
    },
    [dateRangeCountersByTypeMap],
  );

  const handleTabChange = useCallback((e: SyntheticEvent<Element, Event>, newValue: string) => {
    const range =
      dateRanges.find((x) => x.type === newValue) ||
      dateRanges.find((x) => x.type === DashboardDateRangeType.All);
    setFrom(range?.range?.from ? moment(range?.range?.from) : undefined);
    setTo(range?.range?.to ? moment(range?.range?.to) : undefined);
    setSelectedTab(newValue as DashboardDateRangeType);
  }, []);

  return (
    <Stack direction='column'>
      {/* Date range tabs */}
      <Stack
        sx={{ background: (t) => t.palette.background.paper, px: 3, py: 2 }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Stack direction='row'>
          <Tabs value={selectedTab} onChange={(e, newValue) => handleTabChange(e, newValue)}>
            <Tab
              sx={{ color: (t) => t.palette.text.primary }}
              label={
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <span>Today</span>{" "}
                  <Chip
                    variant='filled'
                    size='small'
                    label={showCountMaxOrDefault({
                      count: getDateRangeCounterLabel(DashboardDateRangeType.Today),
                    })}
                    sx={{ backgroundColor: (th) => th.palette.background.gray }}
                  ></Chip>
                </Stack>
              }
              value={DashboardDateRangeType.Today}
            />
            <Tab
              sx={{ color: (t) => t.palette.text.primary }}
              label={
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <span>Week</span>{" "}
                  <Chip
                    variant='filled'
                    size='small'
                    label={showCountMaxOrDefault({
                      count: getDateRangeCounterLabel(DashboardDateRangeType.ThisWeek),
                    })}
                    sx={{ backgroundColor: (th) => th.palette.background.gray }}
                  ></Chip>
                </Stack>
              }
              value={DashboardDateRangeType.ThisWeek}
            />
            <Tab
              sx={{ color: (t) => t.palette.text.primary }}
              label={
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <span>Month</span>{" "}
                  <Chip
                    variant='filled'
                    size='small'
                    label={showCountMaxOrDefault({
                      count: getDateRangeCounterLabel(DashboardDateRangeType.ThisMonth),
                    })}
                    sx={{ backgroundColor: (th) => th.palette.background.gray }}
                  ></Chip>
                </Stack>
              }
              value={DashboardDateRangeType.ThisMonth}
            />
            <Tab
              sx={{ color: (t) => t.palette.text.primary }}
              label={
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <span>Year</span>{" "}
                  <Chip
                    variant='filled'
                    size='small'
                    label={showCountMaxOrDefault({
                      count: getDateRangeCounterLabel(DashboardDateRangeType.ThisYear),
                    })}
                    sx={{ backgroundColor: (th) => th.palette.background.gray }}
                  ></Chip>
                </Stack>
              }
              value={DashboardDateRangeType.ThisYear}
            />
            <Tab
              sx={{ color: (t) => t.palette.text.primary }}
              label={
                <Stack direction='row' spacing={0.5} alignItems='center'>
                  <span>All</span>{" "}
                  <Chip
                    variant='filled'
                    size='small'
                    label={showCountMaxOrDefault({
                      count: getDateRangeCounterLabel(DashboardDateRangeType.All),
                    })}
                    sx={{ backgroundColor: (th) => th.palette.background.gray }}
                  ></Chip>
                </Stack>
              }
              value={DashboardDateRangeType.All}
            />
          </Tabs>
        </Stack>
        <Typography sx={{ fontWeight: 700 }} variant='h6'>
          {dateTextContent}
        </Typography>
      </Stack>

      {/* Header */}
      <Stack
        direction='row'
        spacing={2}
        sx={{
          background: (t) => t.palette.background.paper,
          px: 3,
          py: 2,
          mb: 3,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction='column' sx={{ flex: 1 }}>
          <Typography variant='h1'>
            {!profile && <TextLineSkeleton />}
            {profile && <>Hi {`${profile?.personName?.name || profile?.email || "User"},`}</>}
          </Typography>

          <Typography variant='body1'>
            {assetSubscriptionRequest.isLoading ||
            contractsCheckInRequest.isLoading ||
            contractsCheckOutRequest.isLoading ? (
              <TextLineSkeleton />
            ) : (
              headerTextContent
            )}
          </Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xxs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
          gridTemplateRows: "auto",
          rowGap: 4,
          columnGap: 1,
          px: 3,
          pb: 7,
        }}
      >
        {/* Reservations/AssetSubscriptions */}
        <CardList<AssetSubscriptionDto>
          isLoading={assetSubscriptionRequest.isLoading}
          isFolded={!isReservationsVisible}
          header={
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ backgroundColor: (th) => th.palette.primary.main, borderRadius: 3, p: 0.5 }}
              >
                <AppIcon of='vehicle' fontSize='small' sx={{ mt: 0.5, fill: "white" }} />
                <AppIcon
                  of='expandLess'
                  fontSize='small'
                  sx={{ fill: "white", transform: "rotate(90deg)" }}
                />
                <AppIcon of='document' fontSize='small' sx={{ fill: "white" }} />
              </Stack>

              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                sx={{ p: 0.5 }}
                spacing={1}
              >
                <AppTooltip
                  variant='helpText'
                  title='Reservations are all new asset subscriptions that are not allocated yet.'
                >
                  <Typography variant='body1'>Reservations</Typography>
                </AppTooltip>

                <Chip
                  variant='filled'
                  size='extraSmall'
                  label={showCountMaxOrDefault({
                    count: assetSubscriptionRequest.data?.pagination?.totalCount,
                  })}
                  sx={{ backgroundColor: (th) => th.palette.background.gray }}
                />
              </Stack>
              <Stack>
                {assetSubscriptions?.length ? (
                  <IconButton onClick={() => setIsReservationsVisible(!isReservationsVisible)}>
                    <AppIcon
                      of={isReservationsVisible ? "expandMore" : "expandLess"}
                      fontSize='medium'
                    />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          }
          items={assetSubscriptions}
        >
          {(item, index) => <AssetSubscriptionCard item={item} />}
        </CardList>

        {/* Check-outs */}
        <CardList<ContractDto>
          isLoading={contractsCheckOutRequest.isLoading}
          isFolded={!isCheckOutsVisible}
          header={
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  backgroundColor: (th) => th.palette.background.paper,
                  borderRadius: 3,
                  p: 0.5,
                  border: (th) => `1px solid ${th.palette.primary.main}`,
                }}
              >
                <AppIcon
                  of='vehicle'
                  fontSize='small'
                  sx={{ mt: 0.5, fill: (th) => th.palette.primary.main }}
                />
                <AppIcon
                  of='expandLess'
                  fontSize='small'
                  sx={{ fill: (th) => th.palette.primary.main, transform: "rotate(90deg)" }}
                />
                <AppIcon
                  of='person'
                  fontSize='small'
                  sx={{ fill: (th) => th.palette.primary.main }}
                />
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                sx={{ p: 0.5 }}
                spacing={1}
              >
                <Typography variant='body1'>Check-Outs</Typography>
                <Chip
                  variant='filled'
                  size='extraSmall'
                  label={showCountMaxOrDefault({
                    count: contractsCheckOutRequest.data?.pagination?.totalCount,
                  })}
                  sx={{ backgroundColor: (th) => th.palette.background.gray }}
                />
              </Stack>
              <Stack>
                {checkOutContracts?.length ? (
                  <IconButton onClick={() => setIsCheckOutsVisible(!isCheckOutsVisible)}>
                    <AppIcon
                      of={isCheckOutsVisible ? "expandMore" : "expandLess"}
                      fontSize='medium'
                    />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          }
          items={checkOutContracts}
        >
          {(item, index) => <ContractCard filterType={ContractFilterType.CheckOut} item={item} />}
        </CardList>

        {/* Check-ins */}
        <CardList<ContractDto>
          isLoading={contractsCheckInRequest.isLoading}
          isFolded={!isCheckInsVisible}
          items={checkInContracts}
          header={
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{
                  backgroundColor: (th) => th.palette.background.paper,
                  borderRadius: 3,
                  border: (th) => `1px solid ${th.palette.primary.main}`,
                  p: 0.5,
                }}
              >
                <AppIcon
                  of='person'
                  fontSize='small'
                  sx={{ fill: (th) => th.palette.primary.main }}
                />
                <AppIcon
                  of='expandLess'
                  fontSize='small'
                  sx={{ fill: (th) => th.palette.primary.main, transform: "rotate(90deg)" }}
                />
                <AppIcon
                  of='vehicle'
                  fontSize='small'
                  sx={{ mt: 0.5, fill: (th) => th.palette.primary.main }}
                />
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                sx={{ p: 0.5 }}
                spacing={1}
              >
                <Typography variant='body1'>Check-Ins</Typography>
                <Chip
                  variant='filled'
                  size='extraSmall'
                  label={showCountMaxOrDefault({
                    count: contractsCheckInRequest.data?.pagination?.totalCount,
                  })}
                  sx={{ backgroundColor: (th) => th.palette.background.gray }}
                />
              </Stack>
              <Stack>
                {checkInContracts?.length ? (
                  <IconButton onClick={() => setIsCheckInsVisible(!isCheckInsVisible)}>
                    <AppIcon
                      of={isCheckInsVisible ? "expandMore" : "expandLess"}
                      fontSize='medium'
                    />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          }
        >
          {(item, index) => <ContractCard filterType={ContractFilterType.CheckIn} item={item} />}
        </CardList>
      </Box>
    </Stack>
  );
}
