import { Box, Skeleton, SkeletonProps, Stack, SxProps, Theme } from "@mui/material";

interface Props {
  avatarProps?: SkeletonProps;
  senderProps?: SkeletonProps;
  bodyProps?: SkeletonProps;
  sx?: SxProps<Theme>;
}

export default function ChatMessageSkeleton({ avatarProps, senderProps, bodyProps, sx }: Props) {
  return (
    <Box sx={{ width: "100%", ...sx }}>
      <Stack direction='row' spacing={2}>
        <Box>
          <Skeleton variant='circular' width={40} height={40} {...avatarProps} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Skeleton variant='text' sx={{ fontSize: "1rem" }} {...senderProps} />
          <Skeleton variant='rectangular' width={"100%"} height={40} {...bodyProps} />
        </Box>
      </Stack>
    </Box>
  );
}
