import { SvgIcon, SvgIconProps } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type SvgIconType = typeof SvgIcon;

export default forwardRef<SVGSVGElement, SvgIconProps>(function NexusIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='30'
      height='30'
      viewBox='0 0 30 30'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.923 15.5203V0.0652466H24.9287C24.9287 0.0652466 22.602 0.0803547 20.6077 1.89334C18.6134 3.70633 18.6134 6.54667 18.6134 6.54667V17.1173L18.6136 17.1174V17.1822C18.6136 17.1822 19.1121 23.1651 17.7826 24.827C16.4531 26.4889 11.6336 21.6038 11.6336 21.6038C11.6336 21.6038 12.9631 24.3284 14.2926 25.3255C16.4531 27.2543 20.6079 26.9874 20.6079 26.9874C20.6079 26.9874 22.9345 26.9723 24.9288 25.1594C26.9231 23.3464 26.9231 20.506 26.9231 20.506V15.5203H26.923Z'
        fill='url(#paint0_linear_379_1850)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.30951 15.3831L1.82809 3.05707L0.664758 3.72183L0.166187 3.98864L-3.68894e-06 4.71897V22.7494C-3.17374e-05 22.7557 -4.57764e-05 22.762 -4.57764e-05 22.7683C-4.57764e-05 22.7746 -3.17374e-05 22.7809 -3.68894e-06 22.7872V22.9343C-3.68894e-06 22.9343 0.00107746 22.9342 0.00319778 22.934C0.0901782 25.1518 1.9156 26.9231 4.15471 26.9231C6.39416 26.9231 8.21979 25.1513 8.30627 22.933C8.30842 22.9339 8.30951 22.9343 8.30951 22.9343V15.3831Z'
        fill='url(#paint1_linear_379_1850)'
      />
      <path
        d='M1.32952 3.72182C0.166191 3.55562 0 4.88515 0 4.88515C0 4.88515 0 2.89086 0.997142 1.56134C1.99428 0.231816 3.65619 0.0656261 3.65619 0.0656261C3.65619 0.0656261 6.31523 -0.266666 8.30952 0.564197C10.3038 1.39506 11.4671 3.88801 11.4671 3.88801L18.6133 17.1832C18.6133 17.1832 19.2781 24.43 17.45 25.2609C14.4586 26.4243 11.4671 21.338 11.4671 21.338L2.82524 5.21753C2.82524 5.21753 2.32667 4.0542 1.32952 3.72182Z'
        fill='url(#paint2_linear_379_1850)'
      />
      <path
        opacity='0.7'
        d='M8.09164 6.57918L3.83942 7.12468L7.11102 13.1759L11.3601 12.708C11.9765 12.6402 12.5264 12.2903 12.8491 11.7608L14.3825 9.24459L11.1111 3.19166L9.52139 5.67401C9.20222 6.17242 8.67868 6.50387 8.09164 6.57918Z'
        fill='url(#paint3_linear_379_1850)'
      />
      <path
        opacity='0.7'
        d='M12.0681 13.8943L7.81074 14.4583L11.082 20.4879L15.3312 20.02C15.9475 19.9522 16.4975 19.6023 16.8201 19.0728L18.3535 16.5566L15.0821 10.5037L13.4897 12.9902C13.1721 13.4862 12.652 13.817 12.0681 13.8943Z'
        fill='url(#paint4_linear_379_1850)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_379_1850'
          x1='19.2784'
          y1='0.0652466'
          x2='19.2784'
          y2='27.0001'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4EC0FF' />
          <stop offset='1' stopColor='#78BEFF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_379_1850'
          x1='4.15471'
          y1='3.05707'
          x2='4.15471'
          y2='27.5206'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#071EDB' />
          <stop offset='1' stopColor='#0823A5' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_379_1850'
          x1='9.36559'
          y1='0'
          x2='9.36559'
          y2='25.4339'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#286AFC' />
          <stop offset='1' stopColor='#2866FC' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_379_1850'
          x1='3.65574'
          y1='7.31247'
          x2='13.6272'
          y2='7.47866'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FC0FF' />
          <stop offset='1' stopColor='#2867FD' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_379_1850'
          x1='7.64455'
          y1='14.4583'
          x2='17.4498'
          y2='14.2921'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FC0FF' />
          <stop offset='1' stopColor='#2867FD' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
});
